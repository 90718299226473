import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getInstitutionTranslator } from '../../actions/TranslateAction';
import SectionHeader from '../../components/page_component/SectionHeader';
import TranslateCard from './components/TranslateCard';
export class TranslatorInstitutions extends Component {
    componentDidMount(){
        this.props.getInstitutionTranslator()
    }
    getAvailableData(data, item){
        // console.log(data)
        let array = [];
        for(const property in data.default){
         
          if(property == 'name' || property == 'description'){
            let x = []
            x.push(
              `${property}`, data[item]&&data[item][property]?data[item][property]:''
            )
          array.push(x)
    
          }
        }
    
        return array
      }
      getAfterSave = () =>{
        this.props.getInstitutionTranslator()
      }
  render() {
    const {institutionTranslate, user} = this.props;

    return (
        <div className='container'>
        {
          user != ''?
          <SectionHeader  title={`Translate Universities From English to ${user?.lang?.main_lang?.name}`} />:null
        }
        <ul>
          {
            institutionTranslate.map((data, i)=>{
              return(
                <TranslateCard getAfterSave={this.getAfterSave} url={`setInstituionTranslate/${institutionTranslate[i].default.institutions_id}`} key={i} data={this.getAvailableData(data, 'default')} translate={this.getAvailableData(data, 'translate')} />
              )
            })
          }
        </ul>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    institutionTranslate: state.Data.institutionTranslate,
    user: state.Data.user,
});
const mapDispatchToProps = {getInstitutionTranslator}
export default connect(mapStateToProps, mapDispatchToProps)(TranslatorInstitutions)