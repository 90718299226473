import React, { Component } from 'react'

export class AutoConpleteText extends Component {
  render() {
    const {text = ''} = this.props
    return (
      <div className='relative auto_complete_cont'>
           
            <span className="line-clamp-1">{text}</span>
            {
                text.length>25?
                <span className='auto-complete-hover bg-white z-10' >{text}</span>:null
            }
            
      </div>
    )
  }
}

export default AutoConpleteText