import React, { Component } from 'react'
import LoadMoreBtn from '../../../page_component/LoadMoreBtn'
import SectionHeader from '../../../page_component/SectionHeader'
import MainCard from '../components/MainCard'
import {mainDashboardTranslate, getUnirepRoleTranslate} from '../../../../actions/TranslateAction'
import { connect } from 'react-redux'

export class Cities extends Component {
    state={
        page:1
    }
    componentDidMount(){
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.mainDashboardTranslate(this.props.languageId);
    }

    loadMoreFunction=(page)=>{
        this.setState({
            page: page
        })
    }
  render() {
    const {cities, mainTransateData, editData} = this.props;
    const {page} = this.state

    return (
        <div className={`nc-SectionSliderNewCategories mt-20`} >
            <div className={`class="SectionSliderNewCategories__PageHome_s2nc__rv_ flow-root glide--ltr glide--slider glide--swipeable`}>
            <SectionHeader  arialLabelDesc="mainTransateData.city_desc" arialLabelTitle={'mainTransateData.city_title'} title={mainTransateData?.city_title} description={mainTransateData?.city_desc} />
            <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides mini_grid_container" >
                {cities.map((item, index) => {
                    if(index < 5*page){
                    return(
                        <MainCard 
                            url={`/city/${item.city_id}`} 
                            title={item.name} 
                            image={item?.city?.image_full_url} 
                            key={index}
                            uniCount={item?.universities}
                            item={item}
                            editData={editData}
                            type={'cities'}
                        />
                    )
                    }
                })}
                </ul>
            </div>
            {
                
                cities.length >5?
                <LoadMoreBtn loadMoreFunction={this.loadMoreFunction} position={[...cities].splice(0, page*5)?.length} count={5}  />:null
            }
           
            
            </div>
        </div>
    )
  }
}

const mapStateToProps = (state) =>({
    languageId: state.Data.languageId,
    mainTransateData: state.Data.mainTransateData,
});
const mapDispatchToProps = {mainDashboardTranslate, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(Cities)