import React, { Component } from 'react'

export class DownloadSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#1E398F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M12 7v7m0 0 3-3m-3 3-3-3"
        />
        <path
          stroke="#1E398F"
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M16 17H8"
        />
        <path
          stroke="#1C274C"
          strokeWidth={1.5}
          d="M2 12c0-4.714 0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12Z"
          opacity={0.5}
        />
      </svg>
    )
  }
}

export default DownloadSvg
