import React, { Component } from 'react'
import { connect } from 'react-redux';
import { registerByWorkerAction} from '../../../../actions/MainAction';
import { getContentUsers, addUpdateData } from '../../../../actions/WorkerActions';
import Select from 'react-select';
export class AddContetModal extends Component {
    state={
        id: this.props.data.id,
        first_name: this.props.data.first_name,
        last_name: this.props.data.last_name,
        email: this.props.data.email,
        password: this.props.data.password,
        role_id: '6',
        selectedCountries: []
    }
    componentDidMount(){
      
        let countries = [];
        for(const property of this.props.data?.content_countries){
            
            const check = this.props.allCountries.find(item=>item?.countries_id == property?.country_id);
            if(check){
                countries.push({
                    label: check?.name,
                    value: check?.countries_id
                })
            }
        }
        this.setState({selectedCountries:countries})
    }
    changeInpValue(e){
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    registerManual(data, student_id, e){
        e.preventDefault()
        if(data.first_name !== '' && data.last_name !== '' && data.email !== '' && data.password !== ''){
            let countries = [];
            for(const item of data?.selectedCountries){
                countries.push(item.value)
            }
            if(this.state.id == ''){
               
                this.props.registerByWorkerAction({...data, countries})
                .then(resp=>{
                    this.props.getContentUsers()
                    this.props.closeModal()
                })
            }else{
                let formData = new FormData();
                formData.append('_method', 'put')
                formData.append('first_name', data.first_name)
                formData.append('last_name', data.last_name)
                formData.append('email', data.email)
                formData.append('password', data.password)
                formData.append('countries', countries)


                let url = `users/${data.id}`;
                this.props.addUpdateData(url, formData)
                .then(resp=>{
                    this.props.getContentUsers()
                    this.props.closeModal()
                })
            }
           

        }
    }
    closeModal(e){
        if(e.target.className === 'background_shadow'){
            this.props.closeModal()
        }
    }

    selectCountreis=(data)=>{
        console.log(data)
        this.setState({selectedCountries:data})
    }
  render() {
    const {student_id = '', allCountries, data} = this.props;
    const {first_name, last_name, email, password, selectedCountries} = this.state;
 
    let countriesOptions = [];
    for(const country of [...allCountries].sort((x, y)=> {  return y?.countries?.institution_count - x?.countries?.institution_count})){
      const params = {
        label: country.name,
        value: country.countries_id
      }
      countriesOptions.push(params)
    }
    
    return (
        <div className='background_shadow' onClick={this.closeModal.bind(this)}>
            <div className="login_popup_container">
                <div>
                    <h2 className="my-2 flex items-center  mb-3 text-3xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        Add a Content User
                    </h2>
                    <form className="grid grid-cols-1 gap-6" action="#" method="post">
                    <div className='grid grid-cols-2 gap-3'>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                                First Name
                            </span>
                            
                            <input type={'text'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="First name"
                            value={first_name}
                            name="first_name"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                            Last Name
                            </span>
                            
                            <input type={'text'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="Last Name"
                            value={last_name}
                            name="last_name"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        </div>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                            Email address
                            </span>
                            
                            <input type={'email'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="example@example.com"
                            value={email}
                            name="email"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        <div className='mt-5 mb-5 ' style={{zIndex: '100', position: 'relative'}}>
                            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                            Select Country
                            </label>
                            <Select
                                isMulti={true}
                                value={selectedCountries}
                                onChange={this.selectCountreis}
                                options={countriesOptions}
                                placeholder={'Select Country'}
                                className="rounded-2xl "
                                
                            />
                        </div>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                                Password
                            </span>
                            
                            <input type={'password'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="*********"
                            value={password}
                            name="password"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        
                        <button onClick={this.registerManual.bind(this, this.state, student_id)} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                            text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>
                            Sign Up
                        </button>
                    </form>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({allCountries: state.Data.allCountries});
const mapDispatchToProps = {registerByWorkerAction, getContentUsers, addUpdateData}
export default connect(mapStateToProps, mapDispatchToProps)(AddContetModal)