import React, { Component } from 'react'
import { getCountries } from '../../actions/MainAction'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SectionHeader from '../../components/page_component/SectionHeader';
import LoadMoreBtn from '../../components/page_component/LoadMoreBtn';
import { getDocumentCategory, searchDocument } from '../../actions/WorkerActions';
import { changeStateValue } from '../../redux/MainReducer';
import SkeletonCard from '../../components/page_component/SkeletonCard';
export class DocumentsCountry extends Component {
    state={
        keyword: ''
    }
    componentDidMount(){
        this.props.getCountries(5, '', this.props.languageId);
        this.props.getDocumentCategory();
    }
    loadMoreFunction=(page)=>{
        this.props.getCountries(page, '', this.props.languageId)
    }

    searchInp(e){
        this.setState({keyword:e.target.value})
        if(e.target.value.length >=3){
             this.props.searchDocument(e.target.value)
        }else{
            this.props.changeStateValue({
                name: 'documentSearch',
                value: []
            })
        }
       
    }
    componentWillUnmount(){
        this.props.changeStateValue({
            name: 'documentSearch',
            value: []
        })
    }
  render() {
    const {countries, documentCategories, documentSearch} = this.props;
    const {keyword} = this.state
    return (
      <div className='container'>
        <SectionHeader title="Document Countries" />
        <div className='relative'>
            <input value={keyword} type='text' className='w-96' placeholder='Search' onChange={this.searchInp.bind(this)} />
            {
                documentSearch?.length != 0?
                <div className='bg-white absolute p-3 w-96 max-h-96 overflow-y-auto z-50 border rounded-xl shadow-sm mt-3 flex flex-col gap-2'>
                    {
                        documentSearch.map((data, i)=>{
                            return(
                                <Link key={i} style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }} to={`/document-data/${data?.id}`}>{data?.title}</Link>
                            )
                        })
                    }
                    
                </div>:null
            }
            
        </div>
        <div className="glide__slides countries_container mt-5" >
            {
                [...countries].sort((x, y)=> {  return y?.universities - x?.universities}).map((data, i) =>{
                    return(
                        <div key={i} className='bg-white border p-5 rounded-xl'>
                            <img src={data?.countries?.image_full_url} className='w-20 h-20 rounded-full ml-auto mr-auto' />
                            <h3 className='text-sm text-center mt-2 font-semibold'>{data?.name}</h3>
                            <div className='flex flex-col items-center gap-2 mt-3'>
                                {
                                    documentCategories?.map((item, j)=>{
                                        return(
                                            <Link key={j} to={`/document/${data?.countries_id}/${item?.id}`} className='bg-blue-700 p-2 rounded-xl text-white text-sm font-semibold' >{item?.title}</Link>
                                        )
                                    })
                                }
                                
                            </div>
                        </div>
                    )
                })
            }
            {
            countries?.length == 0?
            <>
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            </>:null
            }
        </div>
        {/* {
             countries.length >=20?
             <LoadMoreBtn position={countries.length} loadMoreFunction={this.loadMoreFunction} />:null
        } */}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    languageId: state.Data.languageId,
    countries: state.Data.countries,
    documentCategories: state.Data.documentCategories,
    documentSearch: state.Data.documentSearch,
});
const mapDispatchToProps = {getCountries, getDocumentCategory, searchDocument, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentsCountry)
