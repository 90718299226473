import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer'; 
export class RadioType extends Component {
    changeInputValue(e){
       
        this.props.changeStateValue({
          name: e.target.name,
          value: e.target.value
        })
      }
  render() {
    const {label, value, value1="Male", value2="Female", name, ariaLabel, unirepRoleTranslate} = this.props;

    return (
        <div>
        <label aria-label={ariaLabel} className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
        {label}
        </label>
        {/* <input name={name} onChange={this.changeInputValue.bind(this)} type={type}
            className={`block w-full border-neutral-200 focus:border-primary-300 
            focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
            dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
            dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
            placeholder={placeholder}
            value={value}
        />  */}
       <div className='mt-4 flex items-center gap-12'>
        <div className='flex items-center gap-3'>
            <label htmlFor='maleGender' style={{ cursor: 'pointer' }} aria-label="unirepRoleTranslate.male" >{unirepRoleTranslate?.male}</label>
            <input checked={value == value1? true: false} onChange={this.changeInputValue.bind(this)} id='maleGender' value={value1} style={{ cursor: 'pointer' }} type={'radio'} name={name} />
        </div>

        <div className='flex items-center gap-3' >
            <label htmlFor='femaleGender' style={{ cursor: 'pointer' }} aria-label="unirepRoleTranslate.female">{unirepRoleTranslate?.female}</label>
            <input checked={value == value2? true: false} onChange={this.changeInputValue.bind(this)} id='femaleGender' value={value2} style={{ cursor: 'pointer' }} type={'radio'} name={name} />
        </div>
       </div>
    </div>
    )
  }
}
const mapStateToProps =(state) =>({
  unirepRoleTranslate: state.Data.unirepRoleTranslate
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(RadioType)