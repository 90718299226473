import React, { Component } from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { changeTranslateData } from '../../../actions/TranslateAction'
export class Input extends Component {
    state={
        text: ''
    }
    getInpValue(e){
        this.setState({
            text: e.target.value
        })
    }

    saveTranslateData(text,property,url, e){
       
        if(text.trim() != ''){
            let formData = new FormData();
            formData.append('property', property)
            formData.append('text', text)
            this.props.changeTranslateData(url, formData)
            .then(resp=>{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                  })
                  
                  Toast.fire({
                    icon: 'success',
                    title: 'Data Added successfully'
                  })
                  this.props.getAfterSave()
            })
        }
    }
  render() {
    const {value = '', property, url} = this.props;
    const {text} = this.state;
    return (
        <div className='flex gap-3 items-center'>
            <textarea onChange={this.getInpValue.bind(this)} value={text == ''? value: text} type={'text'} style={{ width: '400px', borderRadius: '2px' }} ></textarea>
            <button  style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3' onClick={this.saveTranslateData.bind(this, text, property, url)}>Save</button>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeTranslateData}
export default connect(mapStateToProps, mapDispatchToProps)(Input)