
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getStudents } from '../../../actions/WorkerActions';
import LoadMoreBtn from '../../../components/page_component/LoadMoreBtn';
import Card from './Card';
import EditModal from '../../edu_consultant_pages/EditModal';
import { getOneStudentApplys } from '../../../actions/WorkerActions';
import ChangeAppModal from './ChangeAppModal';
import { changeStateValue } from '../../../redux/MainReducer';
export class AssignedStudents extends Component {
    state={
        page:1,
        modalPosition:false
    }
    componentDidMount(){
        window.scrollTo(0, 0)

        this.props.getStudents('assign_student', 1, '')
    }
    loadMoreFunction=(page)=>{
        this.setState({
            page: page
        })
        this.props.getStudents('assign_student', page, '')
    }
    componentDidUpdate(prevProps, prevState){
      
        if(this.props.searchPr != prevProps.searchPr){
            this.props.getStudents('assign_student', this.state.page, this.props.searchPr)
        }
    }
    getAfterEdit=()=>{
        this.props.getStudents('assign_student', this.state.page, this.props.searchPr)
    }
    changeApplicationModal=(data) =>{
       
        this.props.getOneStudentApplys(data?.id)
        .then(resp=>{
            this.setState({modalPosition:true})
        })
    }
  render() {
    const {unirepStudents, editUserState} = this.props;
    const {modalPosition} = this.state;
    return (
        <div>
            <div className={`grid mt-10 gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 `}>
                {
                unirepStudents.map((data, i)=>{
                    return(
                        <Card changeApplicationModal={this.changeApplicationModal} button={false} key={i} data={data}/>
                    )
                })
                }
                
            </div>
            {
                unirepStudents.length >= 30?
                <LoadMoreBtn count={30} position={unirepStudents.length} loadMoreFunction={this.loadMoreFunction} />:null
            }
             {
                editUserState.position?
                <EditModal getAfterEdit={this.getAfterEdit} />:null
            }
            {
                modalPosition?
                <ChangeAppModal closeMainModal={()=>{this.setState({modalPosition:false}); this.props.changeStateValue({name:'oneStudentApplys', value:[]})}} />:null
            }
        </div>
        
    )
  }
}
const mapStateToProps = (state) =>({
    unirepStudents: state.Data.unirepStudents,
    editUserState: state.Data.editUserState,
});
const mapDispatchToProps = {getStudents, getOneStudentApplys, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AssignedStudents)
