import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import { getAllGradingScheme, getAllGradeConverter } from '../../../actions/WorkerActions';
import Maincard from './components/Maincard';
import MainModal from './modals/MainModal';
export class GradeConverter extends Component {
    componentDidMount(){
        this.props.getAllGradingScheme('1');
        this.props.getAllGradeConverter('1');
    }
    editDataBtn=(data)=>{
      this.props.changeStateValue({
        name: 'newCountryData',
        value: {
          id: data?.id? data?.id: '',
          position: true,
          grading_schemes_id: data.grading_schemes_id,
          max_value: data?.max_value?data?.max_value:'',
          min_value: data?.min_value?data?.min_value:'',
          grade: data.grade?data.grade:'',
          other_exam: data.other_exam
        }
      })
      if(data.other_exam){
        this.props.changeStateValue({
          name: 'otherExamPosition',
          value: true
        })
      }
    }
    getAfterChange=()=>{
      this.props.getAllGradeConverter('1');
    }
    getPlaceholder(name, id, arr){
      if(id == '' || !id){
          return name
      }else{
          const check = arr.find(data=> data.value == id);
          if(check){
              return check.label
          }else{
             return name
          }
      }
  }
  render() {
    const {allGradingScheme, gradeConverter, newCountryData} = this.props;
    // console.log(allGradingScheme)
    let gradeSchemeOpt = [{
      label: 'Other',
      value: null
    }]
    for(const data of allGradingScheme){
      gradeSchemeOpt.push({
        label: `${data?.country_name} ${data.education_degree} (${data.min_value} - ${data.max_value})`,
        value:data.id
      })
    }
    return (
      <div>
         <button onClick={this.editDataBtn.bind(this, '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a Grade Converter</button>
        <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 sm:gap-6 md:gap-8`}>
        {
            gradeConverter.map((item ,i)=>{
                return(
                    <Maincard
                        key={i}
                        name={ `${item.education_degree?item.education_degree:item.other_exam} `+` (${ item.min_value+ "-"+item.max_value })` }
                        id={item.id}
                        countries_id={item.countries_id}
                        grading_schemes_id={item.grading_schemes_id}
                        grade={item.grade}
                        other_exam={item.other_exam}
                        min_value={item.min_value}
                        max_value={item.max_value}
                        editDataBtn={this.editDataBtn}
                    />
                )
            })
        }
        </div>
        {newCountryData?.position ? <MainModal 
                                        drop1option={gradeSchemeOpt} 
                                        drop1Title={"Select a Grading scheme"} 
                                        drop1Name="grading_schemes_id"
                                        drop1placeholder={this.getPlaceholder("Select a Grading Scheme", newCountryData.grading_schemes_id, gradeSchemeOpt)}
                                        // drop2option={degreeOpt} 
                                        // drop2Title={"Select a Edu Degree"} 
                                        // drop2Name="education_degree_id"
                                        // drop2placeholder={this.getPlaceholder("Select a Edu Degree", newCountryData.education_degree_id, degreeOpt)}
                                        // drop3option={countDegreeOpt} 
                                        // drop3Title={"Select a Country Edu Degree"} 
                                        // drop3Name="education_sub_degree_id"
                                        // drop3placeholder={this.getPlaceholder("Select a Country Edu Degree", newCountryData.education_sub_degree_id, countDegreeOpt)}
                                        stateName="newCountryData"
                                        stateData={newCountryData}
                                        required={["min_value","max_value", "grade"]}
                                        url={'/grade-converter'}
                                        getAfterChange={this.getAfterChange}
                                    />:null}
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    allGradingScheme: state.Data.allGradingScheme,
    gradeConverter: state.Data.gradeConverter,
    newCountryData: state.Data.newCountryData,
});
const mapDispatchToProps = {changeStateValue, getAllGradingScheme, getAllGradeConverter}
export default connect(mapStateToProps, mapDispatchToProps)(GradeConverter)