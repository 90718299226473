import axios from 'axios';
import { MAIN_API } from '../MAIN_API';
import {changeStateValue} from '../redux/MainReducer';

export const loginAction = (username, password)=> async (dispatch)=>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API}/login`,{username, password}, {
        "Content-Type": "application/json",
        "Accept": "application/json",
    }).
    then(response=>{
        localStorage.setItem('token', response.data.token)
        dispatch(changeStateValue({
            name: 'user',
            value: response.data.user
        }))

        return 'success';
    }).catch(err=>{console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}
export const loginSocialsAction = (username)=> async (dispatch)=>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/login-socials`,{username}).
    then(response=>{
        localStorage.setItem('token', response.data.token)
        dispatch(changeStateValue({
            name: 'user',
            value: response.data.user
        }))
   
        return 'success';
    }).catch(err=>{console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}
export const logOutAction = ()=> async (dispatch)=>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/logOut`,{}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).
    then(response=>{
        localStorage.removeItem('token')
        dispatch(changeStateValue({
            name: 'user',
            value: ''
        }))
        return 'success';
    }).catch(err=>{
        return err.response.data.message
    })
}
export const registerAction = (params)=> async (dispatch)=>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/register`,{...params}).
    then(response=>{
     
        // localStorage.setItem('token', response.data.token)
        // dispatch(changeStateValue({
        //     name: 'user',
        //     value: response.data.user
        // }))
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success';
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}


export const checkConfirmCode = (params, path)=> async (dispatch)=>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/check-confirm-code`, {...params}).
    then(response=>{
        if(path != '/students'){
            localStorage.setItem('token', response.data.token)
            dispatch(changeStateValue({
                name: 'user',
                value: response.data.user
            }))
        }
        
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success';

    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}
export const resendConfirmCodeAction = (params)=> async (dispatch)=>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/resendEmail`, {...params}).
    then(response=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        
        return 'success';

    }).catch(err=>{

        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}



export const registerByWorkerAction = (params)=> async (dispatch)=>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/register-by-worker`,params, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).
    then(response=>{
        console.log(response.data)
        // localStorage.setItem('token', response.data.token)
        // dispatch(changeStateValue({
        //     name: 'user',
        //     value: response.data.user
        // }))
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success';
    }).catch(err=>{console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return err.response.data.message
    })
}

export const loggedInUserInfo = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/current-user`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
  
        dispatch(changeStateValue({
            name: 'user',
            value: resp.data
        }))
        return "success"
    }).catch(err=>{console.log(err.response); return "error";})
}





export const registerSocialsAction = (params)=> async (dispatch)=>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
   
    return await axios.post(`${MAIN_API}/register-socials`,{...params}).
    then(response=>{
      
        localStorage.setItem('token', response.data.token)
        dispatch(changeStateValue({
            name: 'user',
            value: response.data.user
        }))
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        console.log(response.data)
        return 'success';
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}

 
export const getAllCountries = (page, keyword, lang_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    
    return await axios.get(`${MAIN_API}/public-countries`, {
    params: {page: '', keyword: keyword, lang_id: lang_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'allCountries', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}



export const getProgramByCountry= (country_id, lang_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-programs-by-country`, {
    params: {country_id: country_id, lang_id: lang_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'programsByCountry', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getAllDisciplines= (lang_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-disciplines`, {
    params: { lang_id: lang_id, query_type: 'all'},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'allDisciplines', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}


export const getPrograms= (page = 1, lang_id, institutionId = '', disciplineId="", url= 'public-programs', country = '', state = '', city = '', filterByLevel = '', keyword = '') => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLongLoader'})


    return await axios.get(`${MAIN_API}/${url}?page=${page}&keyword=${keyword}
    &filterByCountry=${country}&filterByState=${state}&filterByCity=${city}
    &filterByStatus=1&filterByInstitution=${institutionId}&lang_id=${lang_id}
    &filterByLevel=&filterByDegree=${filterByLevel}&filterByDiscipline=${disciplineId}
    &filterByDuration=&filterByFee=`, {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({type: 'MAIN_SLICE/hideLongLoader'})
       
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'programs', value: response.data}
        })
        return response.data
    }).catch(err=>{
        dispatch({type: 'MAIN_SLICE/hideLongLoader'})

        console.log(err.response)
    })
}

export const getProgramData= (lang_id, program_id,url = 'public-program-data') => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    
    return await axios.get(`${MAIN_API}/${url}`, {
        params: {lang_id: lang_id, program_id: program_id},
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'programData', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}





export const getCountries= (page, keyword, lang_id, type = '', url="public-countries") => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
   
    return await axios.get(`${MAIN_API}/${url}`, {
    params:{page: page, keyword: keyword, lang_id: lang_id, type: type},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
      
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'countries', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getCountriesFAQ= (country_id, lang_id, type = '') => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-country-faqs`, {
        params : {lang_id: lang_id, country_id: country_id, type:type},
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'countryFAQ', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getCountryData= (id, lang_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-country-data`, {
    params:{country_id: id, lang_id: lang_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'countryData', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getStateByCountry = (page, lang_id, country_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-state`, {
    params:{page: page, keyword: '', lang_id: lang_id, country_id: country_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'stateByCountry', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getStateData = (lang_id, id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-state/${id}`, {
    params:{ lang_id: lang_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'stateData', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getCityData = (lang_id, id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-city/${id}`, {
    params:{ lang_id: lang_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'cityData', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getCityByCountry = (page, lang_id, country_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-cities`, {
    params:{page: page, keyword: '', lang_id: lang_id, filterByCountry: country_id, filterByState: ''},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'cityByCountry', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}



export const getInstitutionData = ( lang_id, institution_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-institution-data`, {
    params:{ lang_id: lang_id, institution_id: institution_id, },
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'institutionData', value: response.data}
        })
        return 200
    }).catch(err=>{
        console.log(err.response)
        return err?.response?.status
    })
}
















export const getExams = () => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-exams`, {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'examsData', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getDisciplines = (page, keyword, lang_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-disciplines`, {
    params:{page: page, keyword: keyword, lang_id: lang_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'disciplines', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getDisciplineData = (id, lang_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-discipline-data`, {
    params:{id:id, lang_id: lang_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'disciplineData', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}


export const getInstitutions = (page, keyword, lang_id, countryId, city_id = '', state_id = '', type = '') => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    
    return await axios.get(`${MAIN_API}/public-institution?page=${page}&keyword=${keyword}&filterByCountry=${countryId}&filterByType=&filterByState=${state_id}&filterByCity=${city_id}&lang_id=${lang_id}&query_type=${type}`, {
    params:{page: page, keyword: keyword, lang_id: lang_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'institutions', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}



// /////////////////////////////////////////////////////////////////////////////////////



export const getcityByCountryAndState = (lang_id, country_id, state_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    
    return await axios.get(`${MAIN_API}/public-cities`, {
        params:{lang_id: lang_id, country_id: country_id, state_id: state_id, query_type:'state_country'},
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'citiesByCountryAndState', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getcityBState = (lang_id,  state_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-cities`, {
        params:{lang_id: lang_id, state_id: state_id},
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'citiesByCountryAndState', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getEducationDegree = (lang_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
  
    return await axios.get(`${MAIN_API}/public-educationDegree`, {
        params:{lang_id: lang_id},
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'educationDegree', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getEducationDegreeByUni = (id, lang_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
  
    return await axios.get(`${MAIN_API}/public-educationDegree-by-uni`, {
        params:{lang_id: lang_id, uni_id:id},
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'educationDegreeByUni', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getCountryEducationDegree = (lang_id, country_id, degree_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
  
    return await axios.get(`${MAIN_API}/public-country-education-degree`, {
        params:{lang_id: lang_id, country_id: country_id,education_degree_id:degree_id },
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'countryEducationDegree', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getGradingScheme = (lang_id, country_id, degree_id, type, education_sub_degree_id = '') => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
 
    return await axios.get(`${MAIN_API}/public-gradingScheme`, {
        params:{lang_id: lang_id, country_id: country_id, education_degree_id:degree_id, education_sub_degree_id: education_sub_degree_id, type: type },
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
      
        if(response.data.length === 1){
            dispatch({
                type: "MAIN_SLICE/changeStateValue",
                payload: {name: 'filter.filterByGradingScheme', value: `${response.data[0].min_value}-${response.data[0].max_value}`}
            })
            dispatch({
                type: "MAIN_SLICE/changeStateValue",
                payload: {name: 'filter.gradingSchemeId', value: response.data[0].id}
            })
            if(localStorage.getItem('simulator')){
                const ls = JSON.parse(localStorage.getItem('simulator'));
                ls['filterByGradingScheme'] = `${response.data[0].min_value}-${response.data[0].max_value}`;
                ls['gradingSchemeId'] = response.data[0].id;
                localStorage.setItem('simulator', JSON.stringify(ls))
            }
            dispatch(getConverters(response.data[0].id))
        }else if(response.data.length == 0){
            dispatch({
                type: "MAIN_SLICE/changeStateValue",
                payload: {name: 'filter.filterByGradingScheme', value: `${0}-${100}`}
            })
            if(localStorage.getItem('simulator')){
                const ls = JSON.parse(localStorage.getItem('simulator'));
                ls['filterByGradingScheme'] = `${0}-${100}`;
                localStorage.setItem('simulator', JSON.stringify(ls))

            }
        }
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'gradingScheme', value: response.data}
        })

        return response.data;
        // return 'success';
    }).catch(err=>{console.log(err.response)})
}


export const getSimulatorPrograms= (params, page = 1, url = 'public-programs') => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLongLoader'})
    
    return await axios.get(`${MAIN_API}/${url}`, {
        params:{...params},
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        dispatch({type: 'MAIN_SLICE/hideLongLoader'})
       
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'simulatorPrograms', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getExtraActivities= (lang_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
 
    return await axios.get(`${MAIN_API}/public-extra-curricular-activities`, {
        params:{lang_id},
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'extraActivities', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getOlympiadMedals= (lang_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
 
    return await axios.get(`${MAIN_API}/public-international-olympiad`, {
        params:{lang_id},
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'internationalOlympiad', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getConverters= (id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
   
    return await axios.get(`${MAIN_API}/public-grade-converter`, {
        params:{id: id},
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'gradeConverters', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getAllPublicConverters= () => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
   
    return await axios.get(`${MAIN_API}/public-all-grade-converter`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'allPublicConverters', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}


export const registerApply = (data) => async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/add-student-data`, data)
    .then(resp=>{
        console.log("sss", resp)
        if(resp?.data?.data){
            localStorage.setItem('token', resp?.data?.data?.token)
            dispatch(changeStateValue({
                name: 'user',
                value:  resp?.data?.data?.user
            }))
        }
        

        
        dispatch(changeStateValue({
            name: 'simulatorLogin',
            value:  false
        }))
        
        
        return 'success';
    }).catch(err=>{
 
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}

export const updateStudentDataAfterLogin = (data) => async dispatch =>{
    // dispatch({type: 'MAIN_SLICE/setLoader'})
   
    return await axios.post(`${MAIN_API}/update-student-data`, data, {
        headers:{
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    .then(resp=>{
        console.log("resp", resp.data)
        return 'success';
    }).catch(err=>{
        console.log("err", err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}
export const getStudentInfo = (id = '')=> async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/student-filter-data?student_id=${id}`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        // console.log(response.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return response.data
    }).catch(err=>{console.log(err.response)})
}

export const updateStudentData = (data) => async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/student-main-data/1`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        }
    })
    .then(resp=>{
        console.log(resp.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
    }).catch(err=>{console.log(err.response);dispatch({type: 'MAIN_SLICE/hideLoader'})})
}
export const applyToProogram = (data, modalPer=true) => async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/student-apply`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        }
    })
    .then(resp=>{
        
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        if(modalPer){
            dispatch(changeStateValue({
                name: 'afterApplyModalVisible',
                value:  true
            }))
        }
        
        return 'success'
        
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'err'
    })
}

// ///comments
export const sendComment = (url, data) => async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/${url}`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        }
    })
    .then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
        
    }).catch(err=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'err'
    })
}

export const getComments = (uni_id)=> async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/get-comments?institution_id=${uni_id}`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        // console.log(response.data)
        dispatch(changeStateValue({
            name: 'universityComments',
            value:  response.data
        }))
        return response.data
    }).catch(err=>{console.log(err.response)})
}


export const getScholarships= (url='public-program-schoolarship', page, lang_id, countryId = '', stateId = '', cityId = '', disciplineId = '', degree='', institutionId='') => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLongLoader'})
   
    return await axios.get(`${MAIN_API}/${url}`, {
    params:{page: page, keyword: '', lang_id: lang_id, filterByCountry: countryId, filterByState: stateId, filterByCity: cityId,filterByDiscipline:disciplineId, filterByLevel:degree, filterByInstitution:institutionId },
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({type: 'MAIN_SLICE/hideLongLoader'})

        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'scholarshipPrograms', value: response.data}
        })
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLongLoader'})

    })
}

export const getStudentWishList= (page, lang_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/wish-list-get`, {
    params:{page: page, keyword: '', lang_id: lang_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'studentWishList', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getStudentPublicWishList= (page, lang_id, id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-wish-list-get`, {
    params:{page: page, keyword: '', lang_id: lang_id, wish_list:id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'studentWishList', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getRelatedPrograms= (page, lang_id, id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/related-programs`, {
    params:{page: page, lang_id: lang_id, program_id:id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'relatedPrograms', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getRelatedUniversity= (page, lang_id, id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/related-institutions`, {
    params:{page: page, lang_id: lang_id, university_id:id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'relatedUniversity', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getMessagesUsers= ( id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/msg-users`, {
    params:{id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'messagesUsers', value: response.data}
        })
        return response.data
    }).catch(err=>{console.log(err.response)})
}
export const getUniByCities= (id, lang_id=1) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-institution-by-city`, {
    params:{city_id: id, lang_id: lang_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'uniByCities', value: response.data}
        })
        return response.data
    }).catch(err=>{console.log(err.response)})
}


export const addToWishList = (url, program_id, type) => async dispatch =>{
    return await axios.post(`${MAIN_API}/${url}`, {program_id: program_id, type: type}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        return 'success'
    }).catch(err=>{console.log(err.response); return 'error'})
}


export const homeAppliedStudents = (type, page, lang_id, countryId='', disciplineId='', degreeId='', companyId='') => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLongLoader'})
   console.log(disciplineId)
    return await axios.get(`${MAIN_API}/accepted-students`, {
    params:{type: type, lang_id: lang_id, page:page, countryId, disciplineId, degreeId, companyId},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'appliedStudents', value: response.data}
        })
        dispatch({type: 'MAIN_SLICE/hideLongLoader'})
        return response.data
    }).catch(err=>{ dispatch({type: 'MAIN_SLICE/hideLongLoader'});console.log(err.response)})
}
export const getCompanies = () => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/companies`, {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'companies', value: response.data}
        })
        return response.data
    }).catch(err=>{console.log(err.response)})
}

// gvet messages
export const getMessagesUserList = (lang_id) => async (dispatch) => {
    // dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/messages?query_type=user_list&lang_id=${lang_id}`, {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'messagesUserList', value: response.data}
        })
        return response.data
    }).catch(err=>{console.log(err.response)})
}
export const getMessagesBox = (student_id, program_id) => async (dispatch) => {
    // dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/messages?query_type=chat_box&student_id=${student_id}&program_id=${program_id}`, {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'messagesBoxData', value: response.data}
        })
        return response.data
    }).catch(err=>{console.log(err.response)})
}

export const insertMessage = (data) => async dispatch =>{
    return await axios.post(`${MAIN_API}/messages`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=> {
        
        return 'success'
    }).catch(err=>{console.log(err.response); return 'error'})
}

export const getFooterCountries= (page, keyword, lang_id, type = '') => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-countries`, {
    params:{page: page, keyword: keyword, lang_id: lang_id, type: type},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
      
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'footerCountries', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getFooterInstitutions = (page, keyword, lang_id, countryId, city_id = '', state_id = '', type = '') => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
 
    return await axios.get(`${MAIN_API}/public-institution?page=${page}&keyword=${keyword}&filterByCountry=${countryId}&filterByType=&filterByState=${state_id}&filterByCity=${city_id}&lang_id=${lang_id}&query_type=${type}`, {
    params:{page: page, keyword: keyword, lang_id: lang_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'footerInstitutions', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getSingleAcceptedStudent = (id, lang_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
 
    return await axios.get(`${MAIN_API}/acpt-student/${id}`, {
    params:{lang_id: lang_id},
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'singleAcptStudent', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const sendResetUrl = (data) => async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API}/send-reset-email`, data)
    .then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}
export const resetPasswordAction = (data) => async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API}/reset-password`, data)
    .then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}
export const addCountryInfo = (data) => async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API}/update-all-country`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    })
    .then(resp=>{
        console.log(resp.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}
export const addUniInfo = (data) => async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API}/update-all-uni`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    })
    .then(resp=>{
        console.log(resp.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}


export const chatGptApi = (data) => async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`https://api.openai.com/v1/chat/completions`, data, {
        headers:{
            "Authorization":"Bearer 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",
            "Content-Type": "application/json"
        }
    })
    .then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return {msg:'success', data:resp.data}
    }).catch(err=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return {msg:'error', data:''}
    })
}



export const getEduconStudentsForApply = (program_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/students-for-apply?program_id=${program_id}`, {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'educonStudentForApply', value: response.data}
        })
        return response.data
    }).catch(err=>{console.log(err.response)})
}