import React, { Component } from 'react'
import Card from './Card'

export class FaqsContainer extends Component {
  render() {
    const {data} = this.props;
  
    return (
        <div  className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
          {
            data.map((item, i)=>{
              return(
                 <Card item={item} key={i} />
              )
            })
          }
           
        </div>
    )
  }
}

export default FaqsContainer