import React, { Component } from 'react'

export class UserImg extends Component {
    getRnd(){
        return Math.floor( Math.random()*155)+1
    }
    getColor(){
        return `rgb(${this.getRnd()}, ${this.getRnd()}, ${this.getRnd()})`
    }
  render() {
    const {imgUrl, userName} = this.props;
    return (
        <div
        className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-10 w-10 text-lg ring-1 ring-white dark:ring-neutral-900`}
        style={{ backgroundColor: imgUrl? '': this.getColor()}}
      >
        {imgUrl ? (
          <img
            className={`absolute inset-0 w-full h-full object-cover rounded-full`}
            src={imgUrl}
            alt={userName}
          />
        ):
        <span className="wil-avatar__name">{userName.substring(0, 1)}</span>
    }
        
      </div>
    )
  }
}

export default UserImg