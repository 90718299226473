import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getStudentDocuments } from '../../../../../actions/WorkerActions';
import { withHooks } from '../../../../../actions/withHooks';
import DeleteSvg from '../../../../../components/svg/DeleteSvg';
import DownloadSvg from '../../../../../components/svg/DownloadSvg';
import { uploadStudentDocuments } from '../../../../../actions/WorkerActions';
import Swal from 'sweetalert2';
import { deleteStudentDocument } from '../../../../../actions/WorkerActions';
import { changeStateValue } from '../../../../../redux/MainReducer';
export class Documents extends Component {
    state={
        title:'',
        file:'',
        btnDisable:false
    }
    componentDidMount(){
        this.props.getStudentDocuments(this.props.params.id)
    }

    getDocumentType=(array, type)=>{
        return array.filter(x=>x.type == type)
    }

    deleteDocument(id, e){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.props.deleteStudentDocument(id)
                .then(resp=>{
                    this.props.getStudentDocuments(this.props.params.id)
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                })
              
            }
          })
    }
    addBtn=(title, file)=>{
        if(title !== '' && file !== ''){
            this.props.changeStateValue({name:"loaderLong", value:true})
            this.setState({
                btnVisible: true
            })
            let formData = new FormData();
            formData.append('file', file);
            formData.append('title', title);
            formData.append('type', this.props.user.role);

            formData.append('student_id', this.props.params.id);
           this.props.uploadStudentDocuments(formData) 
           .then(resp=>{
            this.props.changeStateValue({name:"loaderLong", value:false})
                this.props.getStudentDocuments(this.props.params.id)
                this.setState({
                    title:'',
                    file:'',
                    btnDisable:false
                })
           })
        }else{
            
        }
    }
  render() {
    const {studentDocuments} = this.props;
    const {title, file, btnDisable} = this.state
   
    return (
        <div className='flex flex-col gap-[20px] mt-[20px] grid grid-cols-2 max-[840px]:grid-cols-1 items-start'>
            <div className='bg-white p-[20px] rounded-[10px] shadow-md'>
                <h3 className='text-[15px] font-[400] '>Worker</h3>
                <div className='flex  gap-[10px]'>
                    <input onChange={(e)=>this.setState({title: e.target.value})} value={title} type='text' className='outline-none rounded-[10px] font-[400]  h-[35px]' placeholder='Title' />
                    <input onChange={(e)=>this.setState({file: e.target.files[0]})} type='file' id='docUptFBtnId' className='hidden' />
                    <div className='flex justify-between gap-[10px]'>
                        <label htmlFor='docUptFBtnId' className='border h-[35px] rounded-[10px] text-[13px] p-[5px_12px] font-[400] cursor-pointer inline-flex justify-between'>
                            <svg width={18} fill={file == ''? '': '#4f46e5'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456c13.3 0 24-10.7 24-24s-10.7-24-24-24s-24 10.7-24 24s10.7 24 24 24z"/>
                            </svg>
                        {
                            file !=""?
                            <button onClick={(e)=>{e.preventDefault(); this.setState({file:''})}}>
                            <DeleteSvg />
                            </button>:null
                        }
                        
                        </label>
                        <button disabled={btnDisable} onClick={this.addBtn.bind(this, title, file)} className='border h-[35px] rounded-[10px] text-[13px] text-left pl-[12px] pr-[12px] font-[400] cursor-pointer'>
                            Add
                        </button>
                    </div>
                    </div>
                <div>
                    <div className='bg-gray-100 rounded-[10px] mt-[10px] flex flex-col gap-[10px] p-[4px]'>
                        {
                        this.getDocumentType(studentDocuments, "edu_consultant")
                        .map((data, i)=>{
                            return(
                            <div key={i} className='p-[10px] bg-white rounded-[10px] flex justify-between '>
                                <span className='w-[80%] text-[14px] font-[500] border-r'>{data?.title}</span>
                                <div className='w-[20%] flex items-center gap-[10px] justify-center'>
                                    <button onClick={this.deleteDocument.bind(this, data.id)}>
                                    <DeleteSvg />
                                    </button>
                                    <a target="_blank" href={data.file_full_url}>
                                    <DownloadSvg />
                                    </a>
                                </div>
                            </div>
                            )
                        })
                        }
                    
                    </div>
                </div>
            </div>
            <div className='bg-white p-[20px] rounded-[10px] shadow-md'>
                <h3 className='text-[15px] font-[400] '>Student</h3>

                <div>
                    <div className='bg-gray-100 rounded-[10px] mt-[10px] flex flex-col gap-[10px] p-[4px]'>
                        {
                        this.getDocumentType(studentDocuments, "student")
                        .map((data, i)=>{
                            return(
                            <div key={i} className='p-[10px] bg-white rounded-[10px] flex justify-between '>
                                <span className='w-[80%] text-[14px] font-[500] border-r'>{data?.title}</span>
                                <div className='w-[20%] flex items-center gap-[10px] justify-center'>
                                    <a target="_blank" href={data.file_full_url}>
                                        <DownloadSvg />
                                    </a>
                                </div>
                            </div>
                            )
                        })
                        }
                    
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentDocuments: state.Data.studentDocuments,
    user: state.Data.user,
});
const mapDispatchToProps = {getStudentDocuments, uploadStudentDocuments, deleteStudentDocument, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Documents))
