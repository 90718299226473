import React, { Component } from 'react'
import { connect } from 'react-redux';
import FooterTabs from './FooterTabs'
import SocialList from './SocialList'
import { getFooterCountries, getFooterInstitutions, getAllDisciplines } from '../../actions/MainAction';
import Logo from '../header_component/Logo';
import appStore from '../../images/social/appStore.png'
import playStore from '../../images/social/playStore.png'


export class Footer extends Component {
    componentDidMount(){
        // this.props.getFooterCountries(1, '',this.props.languageId, 'home_page');
        // this.props.getFooterInstitutions(1, '',this.props.languageId, '', '', '', 'home_page');
        // this.props.getAllDisciplines(this.props.languageId);
    }
  render() {
    const {footerCountries, footerInstitutions, allDisciplines, mainTransateData} = this.props;
    let countryArray = [];
    let instArray = [];
    let disciplineArray = [];
    
    for(const data of footerCountries){
        countryArray.push({
            id: data.countries_id,
            name:data.name
        })
    }
    for(const data of footerInstitutions){
        instArray.push({
            id: data.institutions_id,
            name:data.name
        })
    }
    for(const data of allDisciplines){
        disciplineArray.push({
            id: data.discipline_id,
            name:data.name
        })
    }
    return (
        <div className="nc-Footer relative lg:py-10 border-t border-neutral-200 dark:border-neutral-700 mt-10">
            <div className="container grid gap-x-5 sm:gap-x-8 md:grid-cols-3 lg:grid-cols-3 lg:gap-x-10 ">
                <div className="grid grid-cols-3 gap-5 col-span-2 md:col-span-3 lg:md:col-span-1 lg:flex lg:flex-col">
                    <div className="col-span-1 md:col-span-1">
                        <Logo />
                        <h1 className=''>Your study dream is our mission</h1>
                        <p className='mt-3'>Every year, we change the lives of millions of students.</p>
                    </div>
                    
                </div>
                <div className="col-span-1 md:col-span-1 items-center flex flex-col gap-3">
                        <div className='flex gap-4 flex-col'>
                            <h3>Sign up for a newsletter</h3>
                            <input className='rounded-xl' placeholder='example@gmail.com' type='email' />
                            <button className='border text-sm font-semibold pl-3 pr-3 rounded-xl bg-blue-700 text-white cursor-pointer p-3'>SUBSCRIBE</button>
                        </div>
                </div>
                <div className="flex flex-col items-center">
                    <a target='_blank' href="https://apps.apple.com/az/app/celtgo-com/id6468026848">
                      <img style={{ minWidth: '250px', width: '250px', maxWidth:'250px', height: '90px' }} alt='App Store' src={appStore} />
                    </a>
                    <a target='_blank' href="https://play.google.com/store/apps/details?id=com.zakirabd.goapp">
                      <img  style={{ minWidth: '215px',width: '215px', maxWidth:'215px',height: '80px' }} alt='Play Store' src={playStore} />
                    </a>
                </div>
               
                </div>
            </div>
    )
  }
}
const mapStateToProps = (state) =>({
    languageId: state.Data.languageId,
    footerCountries: state.Data.footerCountries,
    footerInstitutions: state.Data.footerInstitutions,
    allDisciplines: state.Data.allDisciplines,
    mainTransateData: state.Data.mainTransateData,
});
const mapDispatchToProps = {getFooterCountries, getFooterInstitutions, getAllDisciplines}
export default connect(mapStateToProps, mapDispatchToProps)(Footer)