import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getOlympiadMedals } from '../../../actions/MainAction';
import MainModal from '../../../components/modals/MainModal';
import { changeStateValue } from '../../../redux/MainReducer';
import Maincard from './components/Maincard';
export class InternatinalOlympiads extends Component {
  componentDidMount(){
    this.props.getOlympiadMedals('1');
    
  }

  editDataBtn = (data) => {
    this.props.changeStateValue({
      name: 'newCountryData',
      value: {
        id: data?.id? data?.id: '',
        position: true,
        name: data?.name?data?.name:'',
        description: '',
        active: 1,
        lang_id: 1,
        translate_id: data.translate_id
      }
    })
    this.props.changeStateValue({
      name: 'otherExamPosition',
      value: false
    })
  }
  getAfterSubmit=()=>{
    this.props.getOlympiadMedals('1');
  }
  render() {
    const {internationalOlympiad, newCountryData} = this.props;

    return (
      <div>
        <button onClick={this.editDataBtn.bind(this, '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a Int. Olymp</button>
        <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 sm:gap-6 md:gap-8`}>
        {
            internationalOlympiad.map((item ,i)=>{
                return(
                    <Maincard
                        key={i}
                        name={item.title}
                        id={item.olympiad_id}
                        translate_id={item.id}
                        editDataBtn={this.editDataBtn}
                    />
                )
            })
        }
        </div>
        {newCountryData.position?<MainModal getAfterChange={this.getAfterSubmit} url={'/international-olympiad'} stateName="newCountryData" modalData={newCountryData} />:null}

      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    newCountryData: state.Data.newCountryData,
    internationalOlympiad: state.Data.internationalOlympiad,
});
const mapDispatchToProps = {changeStateValue, getOlympiadMedals}
export default connect(mapStateToProps, mapDispatchToProps)(InternatinalOlympiads)