import React, { Component } from 'react'
import Slider from "rc-slider";
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
export class SliderCard extends Component {
    state={
        range: 0
    }
    setSliderValue(  e){
     
        this.setState({
            range: e
        })
        this.props.changeStateValue({
            name: 'filterExamGrade.'+this.props.property,
            value: e
        });

    //    this.props.refreshForExams()
    }
    removeExam(id, data, e){
        let newArray = [...data];
        const index = newArray.findIndex(ids=> ids == id);
        newArray.splice(index, 1);
        this.props.changeStateValue({
            name: 'filterExamIds',
            value: newArray
        });
        this.props.changeStateValue({
            name: 'filterExamGrade.'+this.props.property,
            value: ''
        });
    //    this.props.refreshForExams()

    }
    changeInp(e){
        if(this.props.property == 'ielts'){
            if(e.target.value >=0 && e.target.value <=this.props.max_value){
               
                if(e.target.value == 3 || 
                    e.target.value == 3.5 || 
                    e.target.value ==4 || 
                    e.target.value == 4.5 || 
                    e.target.value == 5 || 
                    e.target.value == 5.5 || 
                    e.target.value == 6 || 
                    e.target.value ==6.5 ||
                    e.target.value == 7 ||
                    e.target.value == 7.5 ||
                    e.target.value == 8 ||
                    e.target.value == 8.5 ||
                    e.target.value == 9
                    ){
                     
                        this.setState({
                            range: e.target.value
                        })
                        this.props.changeStateValue({
                            name: 'filterExamGrade.'+this.props.property,
                            value: e.target.value
                        });
                    }
                
            }else if(e.target.value <0){
                this.setState({
                    range: ''
                })
                this.props.changeStateValue({
                    name: 'filterExamGrade.'+this.props.property,
                    value: ''
                });
            }
        }else{
            if(e.target.value >=0 && e.target.value <=this.props.max_value){
                this.setState({
                    range: e.target.value
                })
                this.props.changeStateValue({
                    name: 'filterExamGrade.'+this.props.property,
                    value: e.target.value
                });
            }else if(e.target.value <0){
                this.setState({
                    range: ''
                })
                this.props.changeStateValue({
                    name: 'filterExamGrade.'+this.props.property,
                    value: ''
                });
            }
        }
        
        
    }
  render() {
    const {range} = this.state;
    const { min_value, max_value, step, value, title, id, filterExamIds} = this.props;
    
    return (
        <div className="space-y-3 border p-3 rounded-2xl">
           <div className='slider_range_header_container'>
                <span className="font-medium flex items-center gap-1">{title}: 
                    {/* <span className='font-medium text-neutral-400' style={{ fontSize: '14px' }}>{value}</span> */}
                    <input type='number' placeholder='Score' onChange={this.changeInp.bind(this)} style={{ width: '70px', height: '30px' }} value={value} />
                </span>
                <svg onClick={this.removeExam.bind(this,  id, filterExamIds)} width="10" height="10" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.32867 7.50003L14.8284 1.00029C15.0572 0.771452 15.0572 0.400437 14.8284 0.171628C14.5995 -0.0571802 14.2285 -0.0572095 13.9997 0.171628L7.49998 6.67137L1.00029 0.171628C0.77145 -0.0572095 0.400436 -0.0572095 0.171628 0.171628C-0.05718 0.400466 -0.0572093 0.771482 0.171628 1.00029L6.67133 7.5L0.171628 13.9997C-0.0572093 14.2286 -0.0572093 14.5996 0.171628 14.8284C0.286032 14.9428 0.436002 15 0.585972 15C0.735943 15 0.885884 14.9428 1.00032 14.8284L7.49998 8.32869L13.9997 14.8284C14.1141 14.9428 14.2641 15 14.414 15C14.564 15 14.7139 14.9428 14.8284 14.8284C15.0572 14.5996 15.0572 14.2286 14.8284 13.9997L8.32867 7.50003Z" fill="#979797"/>
                </svg>

           </div>
            <Slider
                
                min={min_value}
                max={max_value}
                step={step}
                allowCross={false}
                onChange={this.setSliderValue.bind(this)}
                value={value}
            />
            <div className='filter_slider_minmax'>
                <span style={{ fontSize: '14px' }} className='font-medium text-neutral-400'>{min_value}</span>
                <span style={{ fontSize: '14px' }} className='font-medium text-neutral-400'>{max_value}</span>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=> ({
    filterExamIds: state.Data.filterExamIds
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SliderCard)