import React, { Component } from 'react'
import Buttons from './components/Buttons'
import CountryEduDegree from './CountryEduDegree';
import CountryWiseEducation from './CountryWiseEducation';
import CurrencyPage from './CurrencyPage';
import EducationDegree from './EducationDegree';
import EducationLanguage from './EducationLanguage';
import ExtraCurricualActivity from './ExtraCurricualActivity';
import GradeConverter from './GradeConverter';
import GradingScheme from './GradingScheme';
import InstitutionsType from './InstitutionsType';
import InternatinalOlympiads from './InternatinalOlympiads';
import DocumentCategory from './DocumentCategory';

export class WorkerOtherPages extends Component {
    state={
        page: 'edu_degree'
    }
    componentDidMount(){
        window.scrollTo(0, 0)
    }
    
  render() {
    const {page} = this.state;
  
    return (
      <div className='container relative'>
            <Buttons onChangePage={(page)=>this.setState({page})} />
           <div className='mt-4'>
                {
                    page == 'edu_degree'?
                    <EducationDegree />:
                    page == 'currency'?
                    <CurrencyPage />:
                    page == 'internatial_olymp'?
                    <InternatinalOlympiads />:
                    page == 'extra_curricular'?
                    <ExtraCurricualActivity />:
                    page == 'edu_lang'?
                    <EducationLanguage />:
                    page == 'inst_type'?
                    <InstitutionsType />:
                    page == 'count_edu_deg'?
                    <CountryEduDegree />:
                    page == 'grading_scheme'?
                    <GradingScheme />:
                    page == 'grade_converter'?
                    <GradeConverter />:
                    page == 'country_wise'?
                    <CountryWiseEducation />:
                    page == 'document_category'?
                    <DocumentCategory />:null
                }
           </div>
      </div>
    )
  }
}

export default WorkerOtherPages