import React, { Component } from 'react'
import Swal from 'sweetalert2';
import { insertNewData } from '../../../../../../actions/WorkerActions';
import { connect } from 'react-redux';

export class WentToBtn extends Component {

    changewenttoStatus=(id="")=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it'
          }).then((result) => {
            if (result.isConfirmed) {
                this.setState({btnVisible:true})
                let formData = new FormData();
            
                formData.append("_method", "put");
                this.props.insertNewData(`/user-went-to/${id}`, formData)
                .then(resp=>{
                    // this.setState({btnVisible:false})
                    this.props.getAfterChange()
                    if(resp.msg == 'success'){
                        Swal.fire(
                            'Changed!',
                            'Archive status changed successfully.',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                        )
                    }
                })
             
            }
          })
    }
  render() {
    const {id, wentto} = this.props;
    // console.log(program_id, student_id, wentto)
    // console.log(wentto)
    return (
      <label htmlFor='wentToBtn'>
        {/* <input onChange={this.changewenttoStatus.bind(this,id)} checked={wentto?true:null} id="wentToBtn" type='checkbox' className='cursor-pointer' /> */}
        <button  onClick={this.changewenttoStatus.bind(this,id)} className='w-[20px] h-[20px] min-w-[20px] border rounded-[4px] border-blue-600 inline-flex justify-center items-center'>
         {wentto? <span className='inline-block w-[10px] h-[10px] bg-blue-600'></span>:null }
        </button>
        {/* <span>Will receive commission?</span> */}
      </label>
    )
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(WentToBtn)




