import React, { Component } from 'react'
import Editor from './Editor'

export class TranslatorCard2 extends Component {
  state={
    editorVisible: false
  }

  openEditor(){
    this.setState({
      editorVisible: true
    })
  }

  closeEditor = () => {
    this.setState({
      editorVisible: false
    })
  }
  render() {
    const {data, url, translate, getAfterSave} = this.props;
    const {editorVisible} = this.state;
    console.log(translate)
    return (
        <li style={{ borderBottom: '2px solid gray' }} className='border-b mb-3 '>
        {
          data.map((item, i)=>{
              return(
                  <div key={i} className={`flex items-center justify-between ${i != data.length-1? 'translater_main_card':''}`}> 
                      <div className="mb-2 " style={{ maxWidth: '600px' }}>
                          <span dangerouslySetInnerHTML={{ __html: item[1]}}></span>
                      </div>
                     
                  </div>
               
              )
          })
        }
        {
          !editorVisible?
          <div className='flex items-center justify-center mb-3'>
            <button onClick={this.openEditor.bind(this)} style={{ color: 'white', backgroundColor: translate[0][1] != '' && translate[1][1] != ''? 'green':'' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                  nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                              text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3' >Translate</button>
          </div>:null
        }
        
        {
          editorVisible?
          <Editor getAfterSave={getAfterSave} closeEditor={this.closeEditor} url={url} data={translate} />:null
        }
       
        
        </li>
    )
  }
}

export default TranslatorCard2