import React, { Component } from 'react'
import { connect } from 'react-redux';
import Searchable from 'react-searchable-dropdown'; 
import MessagesBox from '../messages/MessagesBox';
import DocumentsBtn from './DocumentsBtn';
import FinanceBtn from './FinanceBtn';
import TrackingBtn from './TrackingBtn';
// import io from "socket.io-client";
// import { NODE_API } from '../../../../MAIN_API';
import ScholarshipTracking from './ScholarshipTracking';
import { useParams } from 'react-router-dom';
// const socket = io.connect(NODE_API);
function withHooks(Component){
    return props => <Component {...props} params={useParams()} />
}
export class ChatBox extends Component {
    selectDropValue = (id)=>{
// remove-room'
        // socket.emit('remove-room', this.props.user.id)
        this.props.setProgram(id)
    }
  render() {
    const {programs, program_id, worker_id, user} = this.props;

    let options = [];
    for(const data of programs){
        options.push({
            label: data?.translate?.name.length > 20? `${data?.translate?.name.substring(0, 20)}...`: data?.translate?.name,
            value: data?.program_id
        })
    }
  
    return (
        <div className="block flex-grow lg:mt-0">
        <div className="mt-0 sticky top-28">
            <div className='flex justify-end mb-3'>
                
                <label htmlFor='app_data_check' className='app_info_label_btn border'>View</label>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-2 justify-between">
                <div  className={`block w-full border-neutral-200  
                    focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                    dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                    dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                > 
                    <Searchable 
                        options={options}
                        onSelect={this.selectDropValue.bind( this.label)}
                        placeholder={options[0]?.label}
                    />
                </div>
                <TrackingBtn program_id={program_id} />
                <DocumentsBtn />
                {
                    user !== '' && user.role == 'edu_consultant' ||
                    user !== '' && user.role == 'uni_rep'?
                    <FinanceBtn />:null
                }
                 <ScholarshipTracking  program_id={program_id} student_id={user?.role == 'student'? user.id : this.props.params.id}  />
            </div>
            <div className=" shadow-xl mt-10">
                <MessagesBox worker_id={worker_id}  program_id={program_id}/>
            </div>
        </div>
    </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user
})
export default connect(mapStateToProps)(withHooks(ChatBox))