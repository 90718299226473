import React, { Component } from 'react'
import CheckBox from './CheckBox';
import FooterBtns from './FooterBtns';
import InputFilter from './InputFilter';
import MultiDropDown from './MultiDropDown';
import RadioType from './RadioType';
import SliderRange from './SliderRange';
import SvgDown from './SvgDown'
import SvgUp from './SvgUp';
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
export class DropDown extends Component {
    state={
        position: true,
        checkBoxValue: []
    }

    changeDropDownPosition(position, e){
     
        this.setState({
            position: !position
        })
    }

    getCheckedValue = (id, checkBoxValue)=> {
     
        const check = checkBoxValue.findIndex(data => data == id);
       
        if(check === -1){
            // checkBoxValue.push(id)
            if(this.props.property == 'filterByCountry'){
                checkBoxValue.splice(0, 1);
                checkBoxValue.push(id)
                if(localStorage.getItem('simulator')){
                    let local = JSON.parse(localStorage.getItem('simulator'));
                   
                    local['filterByCity'] = '';
                 
                    local['filterByState'] = '';
                    local['filterByInstitution'] = '';
                   
                    localStorage.setItem('simulator', JSON.stringify(local))
                    this.props.changeStateValue({
                        name: 'filter',
                        value: local
                      })
                }
                 this.props.reloadAfterCountry()
            
            }else if(this.props.property == 'filterByState'){
                checkBoxValue.splice(0, 1);
                checkBoxValue.push(id)
                if(localStorage.getItem('simulator')){
                    let local = JSON.parse(localStorage.getItem('simulator'));
                   
                    local['filterByCity'] = '';
                 
                
                    local['filterByInstitution'] = '';
                   
                    localStorage.setItem('simulator', JSON.stringify(local))
                    this.props.changeStateValue({
                        name: 'filter',
                        value: local
                      })
                }
                 this.props.reloadAfterState()
            
            }else if(this.props.property == 'filterByCity'){
                checkBoxValue.splice(0, 1);
                checkBoxValue.push(id)
                if(localStorage.getItem('simulator')){
                    let local = JSON.parse(localStorage.getItem('simulator'));
                   
                 
                 
                
                    local['filterByInstitution'] = '';
                   
                    localStorage.setItem('simulator', JSON.stringify(local))
                    this.props.changeStateValue({
                        name: 'filter',
                        value: local
                      })
                }
                 this.props.reloadAfterState()
            
            }else{
                checkBoxValue.push(id)
            }
            
        }else{
        
            if(this.props.property == 'filterByCountry'){
                if(localStorage.getItem('simulator')){
                    let local = JSON.parse(localStorage.getItem('simulator'));
                   
                    local['filterByCity'] = '';
                 
                    local['filterByState'] = '';
                    local['filterByInstitution'] = '';
                   
                    localStorage.setItem('simulator', JSON.stringify(local))
                    this.props.changeStateValue({
                        name: 'filter',
                        value: local
                      })
                }
                 this.props.reloadAfterCountry()


            }else if(this.props.property == 'filterByState'){
                if(localStorage.getItem('simulator')){
                    let local = JSON.parse(localStorage.getItem('simulator'));
                  
                    local['filterByCity'] = '';

                    localStorage.setItem('simulator', JSON.stringify(local))
                    this.props.changeStateValue({
                        name: 'filter',
                        value: local
                      })
                }
                this.props.reloadAfterState()

            }else if(this.props.property == 'filterByCity'){
                if(localStorage.getItem('simulator')){
                    let local = JSON.parse(localStorage.getItem('simulator'));
                  
                    local['filterByInstitution'] = '';

                    localStorage.setItem('simulator', JSON.stringify(local))
                    this.props.changeStateValue({
                        name: 'filter',
                        value: local
                      })
                }
                this.props.reloadAfterState()

            }
            checkBoxValue.splice(check, 1);
        }
        if(localStorage.getItem('simulator')){
            let local = JSON.parse(localStorage.getItem('simulator'));
            if(checkBoxValue.length == 0){
                local[this.props.property] = ''
            }else{
             
                local[this.props.property] = checkBoxValue.join(',')
            }
            localStorage.setItem('simulator', JSON.stringify(local))
            this.props.changeStateValue({
                name: 'filter',
                value: local
              })
        }
  

        this.setState({
            checkBoxValue: checkBoxValue
        })
    }
    resetCheckBox =()=>{
        this.setState({
            checkBoxValue: []
        })
    }
    closeBody = ()=>{
        
        this.setState({
            position: false
        })
    }

    componentDidMount(){
        this.setState({
            position: this.props.positionDefault
        })
    }
  render() {
    const {position, checkBoxValue} = this.state;
    const {body, title, array = [], property, defaultCheck, min_value = 0, max_value = 100, refreshForExams, ariaLabel, positionDefault = true } = this.props;
    
    

    let defaultArray = [];
    if(checkBoxValue.length === 0){
        
       if(typeof defaultCheck == 'number'){
        defaultArray = [defaultCheck]
        
       }else if(typeof defaultCheck == 'string' && defaultCheck !== ''){
        defaultArray = defaultCheck.split(',')
      
       }else if(typeof defaultCheck == 'string' && defaultCheck === ''){
        defaultArray = []
      
       }else{
        defaultArray = [...defaultCheck]

       }
    }else{
        defaultArray = checkBoxValue
    }
   

    
    return (
        <div  className='filter_list_container'>
            <div onClick={this.changeDropDownPosition.bind(this, position)} className={`filter_list_header  ${position ? 'active_simulator': ''}`}>
                <h3 aria-label={ariaLabel}>{title}</h3>
                {
                    position ?
                    <SvgUp />:
                    <SvgDown />
                }
                
            </div>
            {
                position?
                <div className='filter_list_body'>
                    {
                        body === 'checkbox'?
                        <CheckBox closeBody={this.closeBody} property={property} resetCheckBox={this.resetCheckBox} checkBoxValue={defaultArray} getCheckedValue={this.getCheckedValue} array={array} />:
                        body === 'slider'?
                        <SliderRange refreshForExams={refreshForExams} />:
                        body === 'input'?
                        <InputFilter defaultValue={defaultCheck} property={property} title={title} min_value={min_value} max_value={max_value} />:
                        body === 'multiDropDown'?
                        <MultiDropDown />:
                        body === 'radio'?
                        <RadioType  property={property} array={array} name={property} />:null
                    }
                    
                    
                </div>:null
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  stateByCountry: state.Data.stateByCountry,
  citiesByCountryAndState: state.Data.citiesByCountryAndState,
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(DropDown)