import React, { Component, Fragment } from 'react'
import { Dialog, Popover, Transition } from "@headlessui/react";
import Checkbox from './Checkbox';
import { connect } from 'react-redux';
import { getInstitutions, getAllCountries } from '../../../actions/MainAction';
import { getInstitutions as workerInst } from '../../../actions/WorkerActions';

export class CheckFilter extends Component {
  state={
    countries: [],
    position: false
  }
  checkDataProps = (id) => {
    const countries = this.state.countries;
    const findData = countries.findIndex(data=> data == id)
    
    if(findData === -1){
      countries.splice(0, 1)
      countries.push(`${id}`)
    }else{
      countries.splice(findData, 1)
    }
    this.setState({
      countries: countries
    })
    if(this.props.user != '' && this.props.user.role == 'uni_rep' || this.props.user != '' && this.props.user.role == 'content'){
      this.props.workerInst(1, countries.join(), this.props.languageId)
    }else{
      this.props.getInstitutions(1, '', this.props.languageId, countries.join(), '', '', this.props.type)
    }
    this.props.getAfterFilter(countries)
   
    localStorage.setItem('instCountries', JSON.stringify(countries))
   
  }

  checkChecked(array, id){
    if(array.find(data=> data == id)){
      return true
    }else{
      return false
    }
  }

  cleraArray(){
    if(this.props.user != '' && this.props.user.role == 'uni_rep' || this.props.user != '' && this.props.user.role == 'content'){
      this.props.workerInst(1, '', this.props.languageId)
    }else{
      this.props.getInstitutions(1, '', this.props.languageId, '', '', '', this.props.type)
    }
    
    this.setState({
      countries: []
    })
  }

  applyData(countries, e){
   
    this.props.getInstitutions(1, '', this.props.languageId, countries)

    this.setState({
      position:false
    })
    this.props.getAfterFilter(countries)
  }
  openModal(){
    this.setState({
      position:true
    })
    
  }
  componentDidMount(){
    this.props.getAllCountries(1, '', this.props.languageId);
    if(localStorage.getItem('instCountries')){
      const countries = JSON.parse(localStorage.getItem('instCountries'));
      this.props.getAfterFilter(countries);
      if(this.props.user != '' && this.props.user.role == 'uni_rep' || this.props.user != '' && this.props.user.role == 'content'){
        this.props.workerInst(1, countries.join(), this.props.languageId)
      }else{
        this.props.getInstitutions(1, '', this.props.languageId, countries.join(), '', '', this.props.type)
      }
      localStorage.setItem('instCountries', JSON.stringify(countries));
      this.setState({
        countries:countries
      })
    }
  }

  removeData(countries, index){
    // console.log(countries, index)
    countries.splice(index, 1);
    this.setState({
      countries:countries
    })
    this.props.getAfterFilter(countries);
    if(this.props.user != '' && this.props.user.role == 'uni_rep' || this.props.user != '' && this.props.user.role == 'content'){
      this.props.workerInst(1, countries.join(), this.props.languageId)
    }else{
      this.props.getInstitutions(1, '', this.props.languageId, countries.join(), '', '', this.props.type)
    }
    localStorage.setItem('instCountries', JSON.stringify(countries))
  }
  render() {
   const {countries, position} = this.state;
    
    const {allCountries, mainTransateData, type=''} = this.props;
    let countriesOptions = [];
    for(const country of [...allCountries].sort((x, y)=> {  return y?.countries?.institution_count - x?.countries?.institution_count})){
      const params = {
        name: country.name,
        id: country.countries_id
      }
      countriesOptions.push(params)
    }


    return (
        <div className='flex gap-4'>
          <Popover >
                
                <Popover.Button 
                onClick={this.openModal.bind(this)}
                className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                  position ? "!border-primary-500 " : ""
                }`}
                >
                <span><span aria-label='mainTransateData.countries'>{mainTransateData?.countries}</span> ({countries.length})</span>
                <i className="las la-angle-down ml-2"></i>
                </Popover.Button>
                {
                  position?
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                  <div id='popover_container'>     
                <Popover.Panel className=" z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                    <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                    <div className="relative flex flex-col px-5 py-6 space-y-5 " style={{ maxHeight: '300px', overflow: 'auto' }}>
                        {countriesOptions.map((item, i) => (
                        <div key={i} className="">
                            <Checkbox
                            name={item.name}
                            id={item.id}
                            checked={this.checkChecked(countries, item.id)}
                            checkDataProps={this.checkDataProps}
                            // label={item.name}
                            // subLabel={item.description}
                            />
                        </div>
                        ))}
                    </div>
                    <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                        
                        <button onClick={this.cleraArray.bind(this)} className='ttnc-ButtonThird text-neutral-700 border 
                                            border-neutral-200 dark:text-neutral-200 
                                            dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                            items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                            px-4 py-2 sm:px-5' aria-label='mainTransateData.clear'  >
                            {mainTransateData?.clear}
                        </button>
                        {/* <button style={{ color: 'white' }} onClick={this.applyData.bind(this, countries)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-2 sm:px-5  '>
                            {mainTransateData?.apply}
                        </button> */}
                    </div>
                    </div>
                </Popover.Panel></div>
                </Transition>:null
                }
                
        
        </Popover>

        {
          countries.map((country, i)=>{
            const check = allCountries.find(data=> data.countries_id == country);
            if(check){
              return(
                <button key={i} className='bg-slate-50 p-1 pl-2 pr-2 rounded-2xl border relative'>
                  <span onClick={this.removeData.bind(this, countries, i)} style={{ marginTop: '-10px', marginRight: '-10px' }} className='absolute right-0 top-0'>
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="none"
                    stroke="#000"
                    viewBox="0 0 64 64"
                  >
                  <path d="m16 16 32 32M48 16 16 48" />
                  </svg>
                  </span>
                <span>{check.name}</span>
              </button>
              )
            }
          })
        }
        </div>
    )
  }
}
const mapStateToProps = (state)=> ({
  allCountries: state.Data.allCountries,
  languageId: state.Data.languageId,
  mainTransateData: state.Data.mainTransateData,
  user: state.Data.user,
});
const mapDispatchToProps = {getInstitutions, getAllCountries, workerInst}
export default connect(mapStateToProps, mapDispatchToProps)(CheckFilter)