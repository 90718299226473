import React, { Component, Fragment } from 'react'
import { Dialog, Popover, Transition } from "@headlessui/react";
// import Checkbox from './Checkbox';
import { connect } from 'react-redux';
import Checkbox from '../../../page_component/institutions/Checkbox';
import {getAllDisciplines, getPrograms} from '../../../../actions/MainAction'
import {getWorkerPrograms} from '../../../../actions/WorkerActions';
import { useParams } from 'react-router-dom';
function withHooks (Component){
  return props => <Component {...props} params={useParams()} />
}
export class FilterProgramByDiscipline extends Component {
  state={
    disciplines: [],
    position: false
  }
  checkDataProps = (id) => {
    const disciplines = this.state.disciplines;
    const findData = disciplines.findIndex(data=> data == id)
    
    if(findData === -1){
      disciplines.push(id)
    }else{
      disciplines.splice(findData, 1)
    }
    this.setState({
      disciplines: disciplines
    })
    this.props.setFilterDiscipline(disciplines)
    if(localStorage.getItem('token')){
      this.props.getWorkerPrograms(this.props.page,this.props.languageId, this.props.params.id, disciplines, this.props.filterDegree)

    }else{
      this.props.getPrograms(this.props.page, this.props.languageId, this.props.params.id, disciplines, 'public-programs', '', '', '', this.props.filterDegree)
    }

  }

  checkChecked(array, id){
    if(array.find(data=> data == id)){
      return true
    }else{
      return false
    }
  }

  cleraArray(){
    // this.props.getInstitutions(1, '', this.props.languageId, '')
    this.setState({
      disciplines: []
    });
    if(localStorage.getItem('token')){
      this.props.getWorkerPrograms(this.props.page ,this.props.languageId, this.props.params.id, '', this.props.filterDegree)

    }else{
      this.props.getPrograms(this.props.page, this.props.languageId, this.props.params.id, '', 'public-programs', '', '', '', this.props.filterDegree)
    }
    this.props.setFilterDiscipline('')

  }

  applyData(disciplines, e){
    this.props.setFilterDiscipline(disciplines)
    if(localStorage.getItem('token')){
      this.props.getWorkerPrograms(this.props.page,this.props.languageId, this.props.params.id, disciplines, this.props.filterDegree)

    }else{
      this.props.getPrograms(this.props.page, this.props.languageId, this.props.params.id, disciplines, 'public-programs', '', '', '', this.props.filterDegree)
    }
  }
  openModal(){
    this.setState({
      position:true
    })
    
  }
  componentDidMount(){
    this.props.getAllDisciplines(1, '', this.props.languageId)
  }
  render() {
   const {disciplines, position} = this.state;
  
    const {allDisciplines, mainTransateData} = this.props;
   
    let disciplinesOption = []
    for(const data of allDisciplines){
      disciplinesOption.push({
        name: data.name,
        id: data.discipline_id
      })
    }
    return (
        <Popover >
                
            <Popover.Button 
            onClick={this.openModal.bind(this)}
            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
              position ? "!border-primary-500 " : ""
            }`}
            >
            <span><span aria-label='mainTransateData.disciplines'>{mainTransateData?.disciplines}</span> ({disciplines.length})</span>
            <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            {
              position?
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
              <div id='popover_container'> 
            <Popover.Panel className=" z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                <div className="relative flex flex-col px-5 py-6 space-y-5 " style={{ maxHeight: '300px', overflow: 'auto' }}>
                    {disciplinesOption.map((item, i) => (
                    <div key={i} className="">
                        <Checkbox
                        name={item.name}
                        id={item.id}
                        checked={this.checkChecked(disciplines, item.id)}
                        checkDataProps={this.checkDataProps}
                        // label={item.name}
                        // subLabel={item.description}
                        />
                    </div>
                    ))}
                </div>
                <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    
                    <button aria-label="mainTransateData.clear" onClick={this.cleraArray.bind(this)} className='ttnc-ButtonThird text-neutral-700 border 
                                        border-neutral-200 dark:text-neutral-200 
                                        dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                        items-center justify-center rounded-full transition-colors
                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                        px-4 py-2 sm:px-5'   >
                        {mainTransateData?.clear}
                    </button>
                    {/* <button style={{ color: 'white' }} aria-label="mainTransateData.apply" onClick={this.applyData.bind(this, disciplines)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-2 sm:px-5  '>
                        {mainTransateData?.apply}
                    </button> */}
                </div>
                </div>
            </Popover.Panel></div>
            </Transition>:null
            }
            
    
    </Popover>
    )
  }
}
const mapStateToProps = (state)=> ({
  languageId: state.Data.languageId,
  mainTransateData: state.Data.mainTransateData,
  allDisciplines: state.Data.allDisciplines,
});
const mapDispatchToProps = {getAllDisciplines, getWorkerPrograms, getPrograms}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(FilterProgramByDiscipline))

