import React, { Component } from 'react'
import MainNav from './MainNav';
export class Header extends Component {
  render() {
    return (
      <div className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg `}>
      <MainNav />
    </div>
    )
  }
}

export default Header