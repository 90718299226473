import React, { Component } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sendComment, getComments } from '../../../../../actions/MainAction';
import {changeStateValue} from '../../../../../redux/MainReducer'
function withHooks (Component){
  return props => <Component {...props} params={useParams()} />
}
export class Button extends Component {
  state={
    visible: false
  }
  sendCommentBtn(data, e){
    if(data.comment !== ''){
      this.setState({
        visible: true
      })
      let url = 'university-comments';
      let formData = new FormData();

      if(data.id != ''){
        url = `university-comments/${data.id}`
        formData.append('_method', 'put')
      }
      for(const proeprty in data){
        formData.append(proeprty, data[proeprty])
      }
     
      formData.append('institution_id', this.props.params.id)

      this.props.sendComment(url, formData)
      .then(resp=>{
        this.props.getComments(this.props.params.id)
        this.setState({
          visible: false
        });
        this.props.changeStateValue({
          name: 'commentData.comment',
          value: ''
        })
        this.props.changeStateValue({
          name: 'commentData.raiting',
          value: 5
        })
      })
    }
  }
  render() {
    const {commentData} = this.props;
    const {visible} = this.state;
    return (
        <button disabled={visible} onClick={this.sendCommentBtn.bind(this, commentData)} className={
            `ttnc-ButtonCircle flex items-center justify-center rounded-full !leading-none 
            disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 
            text-neutral-50 absolute right-2 top-1/2 transform -translate-y-1/2 w-12 h-12 `} >
            <svg className='w-5 h-5' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" >
                <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3">
                </path>
            </svg>
        </button>
    )
  }
}
const mapStateToProps = (state) =>({
  commentData: state.Data.commentData
});
const mapDispatchToProps = {sendComment, getComments, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Button))