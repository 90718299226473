import React, { Component } from 'react'
import Slider from "rc-slider";

export class SliderCard extends Component {

  render() {
    const {value, label, min_value, max_value, step = 1, name, changeSliderValue, index, data, removeExam, date, setDate, getInpValue} = this.props

    return (
      <div className="p-5 rounded-2xl border">
        <div className='flex justify-between'>
            <label style={{ display: 'flex', textAlign: 'center', alignItems: 'center', gap:'4px'}} className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-5`} data-nc-id="Label" >
                {label }: 
                <input value={value}  onChange={(e)=>getInpValue(e, label, min_value, max_value, index, data)} type='number' placeholder='score' style={{ height: '30px', width: '70px' }} />
            </label>
            <svg onClick={()=>removeExam(index, data)} style={{ cursor: 'pointer' }} width="10" height="10" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.32867 7.50003L14.8284 1.00029C15.0572 0.771452 15.0572 0.400437 14.8284 0.171628C14.5995 -0.0571802 14.2285 -0.0572095 13.9997 0.171628L7.49998 6.67137L1.00029 0.171628C0.77145 -0.0572095 0.400436 -0.0572095 0.171628 0.171628C-0.05718 0.400466 -0.0572093 0.771482 0.171628 1.00029L6.67133 7.5L0.171628 13.9997C-0.0572093 14.2286 -0.0572093 14.5996 0.171628 14.8284C0.286032 14.9428 0.436002 15 0.585972 15C0.735943 15 0.885884 14.9428 1.00032 14.8284L7.49998 8.32869L13.9997 14.8284C14.1141 14.9428 14.2641 15 14.414 15C14.564 15 14.7139 14.9428 14.8284 14.8284C15.0572 14.5996 15.0572 14.2286 14.8284 13.9997L8.32867 7.50003Z" fill="#979797"/>
            </svg>
        </div>
        <Slider
            min={min_value}
            max={max_value}
            step={step}
            allowCross={false}
            onChange={(e)=>changeSliderValue(index, data, e)}
            value={value}
        />
        <div className='flex justify-between mt-6 items-center gap-10'>
            <span>{min_value}</span>
            <input onChange={(e)=>setDate(index, data, e.target.value)} type={'date'} value={date}/>
            <span>{max_value}</span>
        </div>
      </div>
    )
  }
}

export default SliderCard