import React, { Component } from 'react'
import { connect } from 'react-redux';
import {registerByWorkerAction} from '../../../../actions/MainAction';
import { getEduConsultantStudents } from '../../../../actions/WorkerActions';
export class RegisterModal extends Component {
    state={
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        role_id: '3'
    }
    changeInpValue(e){
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    registerManual(data, student_id, e){
        e.preventDefault()
        if(data.first_name !== '' && data.last_name !== '' && data.email !== '' && data.password !== ''){
            if(student_id !== ''){
                data.role_id = '5';
                data.student_id = student_id;
            }
            this.props.registerByWorkerAction(data)
            .then(resp=>{
                this.props.getEduConsultantStudents()
                this.props.closeModal()
            })

        }
    }
    closeModal(e){
        if(e.target.className === 'background_shadow'){
            this.props.closeModal()
        }
    }
  render() {
    const {student_id = '', mainTransateData} = this.props;
    const {first_name, last_name, email, password} = this.state;
    return (
        <div className='background_shadow' >
            <div className="login_popup_container">
                <div>
                    <h2 aria-label='mainTransateData.sign_up' className="my-2 flex items-center  mb-3 text-3xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        {mainTransateData?.sign_up}
                    </h2>
                    <form className="grid grid-cols-1 gap-6" action="#" method="post">
                        <label className="block">
                            <span aria-label='mainTransateData.first_name' className="text-neutral-800 dark:text-neutral-200">
                                {mainTransateData?.first_name}
                            </span>
                            
                            <input type={'text'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder={mainTransateData?.first_name}
                            value={first_name}
                            name="first_name"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        <label className="block">
                            <span aria-label='mainTransateData.last_name' className="text-neutral-800 dark:text-neutral-200">
                            {mainTransateData?.last_name}
                            </span>
                            
                            <input type={'text'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder={mainTransateData?.last_name}
                            value={last_name}
                            name="last_name"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        <label className="block">
                            <span aria-label='mainTransateData.email' className="text-neutral-800 dark:text-neutral-200">
                            {mainTransateData?.email}
                            </span>
                            
                            <input type={'email'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="example@example.com"
                            value={email}
                            name="email"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        <label className="block">
                            <span aria-label='mainTransateData.password' className="text-neutral-800 dark:text-neutral-200">
                                {mainTransateData?.password}
                            </span>
                            
                            <input type={'password'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="*********"
                            value={password}
                            name="password"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        
                        <button aria-label='mainTransateData.sign_up' onClick={this.registerManual.bind(this, this.state, student_id)} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                            text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>
                            {mainTransateData?.sign_up}
                        </button>
                    </form>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    mainTransateData: state.Data.mainTransateData
});
const mapDispatchToProps = {registerByWorkerAction, getEduConsultantStudents}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal)