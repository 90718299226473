import React, { Component, Fragment , FC } from 'react'
import { Popover, Transition } from "@headlessui/react";
import { NavLink, } from 'react-router-dom';
export class DropDownMenu extends Component {
    state={
        isHover: false
    }
  render() {
    const {isHover} = this.state;
    const {navs, label, translate} = this.props
    return (
      <div className='relative'>
        <Popover
            as="li" 
            onMouseEnter={() =>this.setState({isHover: true})}
            onMouseLeave={() =>this.setState({isHover: false})}
        >
        {() => (
          <>
            <Popover.Button as={Fragment}>
            <button  className="inline-flex items-center text-sm xl:text-base font-normal text-neutral-700 dark:text-neutral-300 py-2 px-4 xl:px-5 rounded-full hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"  >
                <span aria-label={label}>{translate}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="ml-1 -mr-1 h-4 w-4 text-neutral-400">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
            </button>
            </Popover.Button>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className="sub-menu nc-will-change-transform absolute transform z-10 w-56 pt-3 left-0"
              >
                <ul className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10 text-sm relative bg-white dark:bg-neutral-900 py-4 grid space-y-1">
                   {
                    navs.map((nav, index)=>{
                        return(
                            <li key={index} className={`px-2 `}  >
                            <NavLink
                                rel="noopener noreferrer"
                                className="flex items-center font-normal text-neutral-6000 dark:text-neutral-300 py-2 px-4 rounded-md hover:text-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200"
                                to={nav.href}
                                // activeClassName="!font-medium  dark:!text-neutral-100"
                                aria-label={nav.label}
                            >
                            {nav.name}
                            </NavLink>
                        </li>
                        )
                    })
                   }
                    
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      </div>
    )
  }
}

export default DropDownMenu