import React, { Component } from 'react';
import { connect } from 'react-redux';
import Description from './components/Description';
import DropDown from './components/DropDown';
import Input from './components/Input';
import UploadImage from './components/UploadImage';
import {getInstitutionData, getInstitutionTypes, getStatesByCountry, getCityByCountry, insertNewData, getCityByState} from '../../actions/WorkerActions';
import {getAllCountries} from '../../actions/MainAction';
import {changeStateValue} from '../../redux/MainReducer'
import { Link, useNavigate } from 'react-router-dom';
import {getUnirepRoleTranslate, mainDashboardTranslate} from '../../actions/TranslateAction';
function withParams(Component){
    return props=><Component {...props}  navigate={useNavigate()} />
}
export class CreateUniversity extends Component {
    state={
        images: [],
        btnVisible: false
    }
    componentDidMount(){
        window.scrollTo(0, 0)

        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.mainDashboardTranslate(this.props.languageId);

        const data = {
          id: '',
          name: '',
          description: '',
          image: '',
          logo: '',
          active: '0',
          lang_id: 1,
          institutions:{
              active: '0',
              city_id: '',
              country_id: '',
              image_full_url: null,
              logo_full_url: null,
              international_ranking: '',
              national_ranking: '',
              state_id: null,
              type: '',
              video_link: '',
          }
        }
        this.props.changeStateValue({
          name: 'editInstitution',
          value: data
        })
        this.props.getInstitutionTypes(this.props.languageId)
        this.props.getAllCountries('', '',this.props.languageId)
    }
    getPlaceholder(array, id, placeholder){
        if(id && array.length !== 0){
            if(array.find(data=> data.value == id)){
                return array.find(data=> data.value == id).label
            }else{
                return placeholder
            }
        }else{
            return placeholder
        }
    }

    getOtherData = (type, id)=>{
        if(type == 'country'){
            this.props.changeStateValue({
                name: 'editInstitution.institutions.state_id',
                value: null
            })
            this.props.changeStateValue({
                name: 'editInstitution.institutions.city_id',
                value: null
            })
            this.props.changeStateValue({
                name: 'stateByCountry',
                value: []
            })
            this.props.changeStateValue({
                name: 'cityByCountry',
                value: []
            })
            this.props.getStatesByCountry(id, this.props.languageId)
            this.props.getCityByCountry(id, this.props.languageId)

        }else if(type == 'state'){
            this.props.changeStateValue({
                name: 'editInstitution.institutions.city_id',
                value: null
            })
            this.props.changeStateValue({
                name: 'cityByCountry',
                value: []
            })
            this.props.getCityByState(id, this.props.languageId)
        }
    }
    saveChanges(data, e){
        
        if(data?.name !== '' && 
            // data?.description !== '' &&
            data?.institutions?.country_id !== '' &&
            data?.institutions?.city_id !== '' &&
            data?.institutions?.type !== '' && this.state.images.length != 0 &&
            // data?.institutions?.national_ranking !== '' &&
            // data?.institutions?.international_ranking !== '' &&
            // data?.image !== '' &&
            data?.logo !== ''
        ){
            this.setState({
                btnVisible: true
            })
          const params = {...data}
            let formData = new FormData();
            params.institutions_json = JSON.stringify(params?.institutions);
            // params.state_id = 0;
            params.user_id = this.props.user.id;
            for(const property in params){
                formData.append(property, params[property])
            }
            let deletedImages = [];
            if(this.state.images){
               
                this.state.images.forEach((image, i)=>{
                    if(!image.delete && image.file !== ''){
                        formData.append(`image${i+1}`, image.file)
                    }else if(image.delete && image.id !== ''){
                        deletedImages.push(image.id)
                    }
                    
                })
            }
            formData.append('institutions_json', JSON.stringify(params?.institutions))
            formData.append('deleted_img', JSON.stringify(deletedImages))
            
            this.props.insertNewData(`/institution`, formData)
            .then(resp=>{
            //   if( resp === 'success'){
               this.props.navigate('/institutions')
               this.setState({
                    btnVisible: false
                })
            //   }
            })
        }
    }

    setImages = (images) => {
        this.setState({
            images: images
        })
    }
  render() {
    const {editInstitution, institutionTypes, allCountries, stateByCountry, cityByCountry, unirepRoleTranslate, } = this.props;
 
    const {btnVisible} = this.state;
    const instTypeOption = [];
    const countriesOption = [];
    const citiesOption = [];
    const stateOption = [];

    for(const property of stateByCountry){
        const data={
            label: property.name,
            value: property.state_id
        };
        stateOption.push(data)
    }
    for(const property of cityByCountry){
        const data={
            label: property.name,
            value: property.city_id
        };
        citiesOption.push(data)
    }
    for(const property of institutionTypes){
        const data={
            label: property.type,
            value: property.institutional_type_id
        };
        instTypeOption.push(data)
    }
    for(const property of allCountries){
        const data={
            label: property.name,
            value: property.countries_id
        };
        countriesOption.push(data)
    }
    return (
        <div  className={`nc-PageAddListing1 px-4 max-w-6xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
        data-nc-id="PageAddListing1"  >
            <div className="space-y-11">
                <div>
                    <span className="text-4xl font-semibold">{unirepRoleTranslate?.add_uni}</span>{" "}
                    {/* <span className="text-lg text-neutral-500 dark:text-neutral-400">
                        / 10
                    </span> */}
                </div>
                {
                    editInstitution !== ''?
                    <div className="listingSection__wrap ">
                        <UploadImage 
                            dbImages={[]}
                            translate={unirepRoleTranslate}
                            setImages={this.setImages}
                            defaultImage={editInstitution?.institutions?.image_full_url}
                            defaultLogo={editInstitution?.institutions?.logo_full_url}
                            logoName={'editInstitution.logo'}
                            imageName={'editInstitution.image'}
                        />
                            <div className="w-auto border-b border-neutral-200 dark:border-neutral-700"></div>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
                            <Input name="editInstitution.name" value={editInstitution?.name} placeholder={unirepRoleTranslate?.inst_title} label={unirepRoleTranslate?.inst_title} />
                            <DropDown getOtherData={this.getOtherData} type={'type'} name="editInstitution.institutions.type" option={instTypeOption} placeholder={this.getPlaceholder(instTypeOption, editInstitution?.institutions?.type, unirepRoleTranslate?.type_inst )} label={unirepRoleTranslate?.type_inst} />
                            <DropDown getOtherData={this.getOtherData} type={'country'} name="editInstitution.institutions.country_id" option={countriesOption} placeholder={this.getPlaceholder(countriesOption, editInstitution?.institutions?.country_id, unirepRoleTranslate?.country )} label={unirepRoleTranslate?.country} />
                            {
                                stateOption.length !== 0?
                                <DropDown getOtherData={this.getOtherData} type={'state'} name="editInstitution.institutions.state_id" placeholder={this.getPlaceholder(stateOption, editInstitution?.institutions?.state_id, unirepRoleTranslate?.state )} option={stateOption}  label={unirepRoleTranslate?.state} />:null
                                
                            }
                            {
                                citiesOption.length !== 0?
                                <DropDown getOtherData={this.getOtherData}  type={'city'} name="editInstitution.institutions.city_id" placeholder={this.getPlaceholder(citiesOption, editInstitution?.institutions?.city_id, unirepRoleTranslate?.city )} option={citiesOption} label={unirepRoleTranslate?.city} />:null
                            }
                            
                            {/* <Input name="editInstitution.institutions.national_ranking" type="number" value={editInstitution?.institutions?.national_ranking} placeholder={unirepRoleTranslate?.qs_ranking} label={unirepRoleTranslate?.qs_ranking} /> */}
                            {/* <Input name="editInstitution.institutions.international_ranking" type="number" value={editInstitution?.institutions?.international_ranking} placeholder={unirepRoleTranslate?.qlobal_ranking} label={unirepRoleTranslate?.qlobal_ranking} /> */}
                        
                        </div>
                        {/* <Description name="editInstitution.description" value={editInstitution?.description} placeholder={unirepRoleTranslate?.description} label={unirepRoleTranslate?.description} /> */}
                        {/* <Input name="editInstitution.institutions.video_link" value={editInstitution?.institutions?.video_link} placeholder={unirepRoleTranslate?.youtube_url} label={unirepRoleTranslate?.youtube_url} /> */}

                    </div>:null
                }
                
                <div className="flex justify-between items-center" >
                    
                    <Link  to={`/institutions`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                    {unirepRoleTranslate?.back}
                    </Link>
                    <button disabled={btnVisible} onClick={this.saveChanges.bind(this, editInstitution)}  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                    {unirepRoleTranslate?.add}
                    </button>
                </div>

            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    editInstitution: state.Data.editInstitution,
    institutionTypes: state.Data.institutionTypes,
    languageId: state.Data.languageId,
    allCountries: state.Data.allCountries,
    stateByCountry: state.Data.stateByCountry,
    cityByCountry: state.Data.cityByCountry,
    user: state.Data.user,
    unirepRoleTranslate: state.Data.unirepRoleTranslate,
})

const mapDispatchToProps = {getInstitutionData, 
                            getInstitutionTypes, 
                            getAllCountries, 
                            getStatesByCountry, 
                            getCityByCountry, 
                            changeStateValue,
                            insertNewData,
                            getUnirepRoleTranslate,
                            mainDashboardTranslate,
                            getCityByState,
                        }

export default connect(mapStateToProps, mapDispatchToProps)(withParams(CreateUniversity))