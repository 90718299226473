import React, { Component } from 'react'

export class Loading extends Component {
  render() {
    return (
      <div style={{ zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='loading_background'>
         <p style={{ marginTop: '-60px', fontWeight: '600' }}>Prosessing...</p>
        <div className="loading-container">
         
          <div className="dash uno"></div>
          <div className="dash dos"></div>
          <div className="dash tres"></div>
          <div className="dash cuatro"></div>
        </div>
      </div>
    )
  }
}

export default Loading