import LangDropdown from '../header_component/LangDropdown';
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import Logo from '../header_component/Logo';
import { connect } from 'react-redux';
import { mainDashboardTranslate, getUnirepRoleTranslate } from '../../actions/TranslateAction';
export class NavMobile2 extends Component {
state ={ 
    navs: [
        {
          id: 1,
          href: "/",
          name: "University",
          // type: "dropdown",
          // children: demoChildMenus,
          // isNew: true,
        },
        {
          id: 2,
          href: "/program",
          name: "Program",
          // type: "megaMenu",
          // megaMenu: megaMenuDemo,
        },
        {
          id: 3,
          href: "/country",
          name: "Country",
          
        },
        {
          id: 4,
          href: "/schoolarship",
          name: "Scholarship",
        },
      
       
      ],
    socials: [
        { name: "Facebook", icon: "lab la-facebook-square", href: "#" },
        { name: "Twitter", icon: "lab la-twitter", href: "#" },
        { name: "Youtube", icon: "lab la-youtube", href: "#" },
        { name: "Instagram", icon: "lab la-instagram", href: "#" },
      ]
}

componentDidMount(){
  this.props.mainDashboardTranslate(this.props.languageId);
  this.props.getUnirepRoleTranslate(this.props.languageId);
}

onClickClose(){
    this.props.handleCloseMenu()
}
  render() {
    const {socials} = this.state;
    const {user, mainTransateData, unirepRoleTranslate} = this.props;
  
    const navigations = [
      {
        id: 1,
        href: "/institutions",
        name: mainTransateData?.nav_1,
        label:'mainTransateData.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 2,
        href: "/programs",
        name: mainTransateData?.nav_3,
        label:'mainTransateData.nav_3'
        // type: "megaMenu",
        // megaMenu: megaMenuDemo,
      },
      {
        id: 3,
        href: "/countries",
        name: mainTransateData?.nav_4,
        label:'mainTransateData.nav_4'
        
      },
      {
        id: 4,
        href: "/schoolarships",
        name: mainTransateData?.nav_2,
        label:'mainTransateData.nav_2'
      },
      {
        id: 5,
        href: "/disciplines",
        name: mainTransateData?.nav_6,
        label:'mainTransateData.nav_6'
      },
      {
        id: 6,
        href: "/accepted-students",
        name: mainTransateData?.accepted_students,
        label:'mainTransateData.accepted_students'
      },
    
     
    ];
    const unirepNavigations =[
      {
        id: 1,
        href: "/",
        name: mainTransateData?.inbox_nav,
        label:'mainTransateData.inbox_nav'
        // name: unirepRoleTranslate?.nav_2,
        // label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 1,
        href: "/applications",
        name: unirepRoleTranslate?.nav_12,
        label:'unirepRoleTranslate.nav_12'
        // name: unirepRoleTranslate?.nav_2,
        // label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 2,
        href: "/institutions",
        name: unirepRoleTranslate?.nav_1,
        label:'unirepRoleTranslate.nav_1'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 7,
        href: "/assign-student",
        name: unirepRoleTranslate?.nav_3,
        label:'unirepRoleTranslate.nav_3'
      },
      {
        id: 7,
        href: "/edu-con",
        name: unirepRoleTranslate?.nav_11,
        label:'unirepRoleTranslate.nav_11'
      },
      {
        id: 3,
        href: "/programs",
        name: unirepRoleTranslate?.nav_4,
        label:'unirepRoleTranslate.nav_4'
        // type: "megaMenu",
        // megaMenu: megaMenuDemo,
      },
    ];
    const studentNavigation =[
      {
        id: 1,
        href: "/",
        name: mainTransateData?.inbox_nav,
        label:'mainTransateData.inbox_nav'
        // name: unirepRoleTranslate?.nav_2,
        // label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 7,
        href: "/simulator",
        name: unirepRoleTranslate?.nav_9,
        label:'unirepRoleTranslate.nav_9'
      },
    
      {
        id: 2,
        href: "/application-data",
        name: unirepRoleTranslate?.nav_8,
        label:'unirepRoleTranslate.nav_8'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 1,
        href: "/applications",
        name: unirepRoleTranslate?.nav_2,
        label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 3,
        href: "/institutions",
        name: unirepRoleTranslate?.nav_10,
        label:'unirepRoleTranslate.nav_10'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 4,
        href: "/programs",
        name: unirepRoleTranslate?.nav_4,
        label:'unirepRoleTranslate.nav_4'
        // type: "megaMenu",
        // megaMenu: megaMenuDemo,
      },
      {
        id: 5,
        href: "/countries",
        name: unirepRoleTranslate?.nav_5,
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 6,
        href: "/schoolarships",
        name: unirepRoleTranslate?.nav_6,
        label:'unirepRoleTranslate.nav_6'
      },
      {
        id: 7,
        href: "/accepted-students",
        name: mainTransateData?.accepted_students,
        label:'mainTransateData.accepted_students'
      },
      
    ];

    const eduConsNavigation = [
      {
        id: 1,
        href: "/",
        name: unirepRoleTranslate?.nav_2,
        label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
    
      {
        id: 3,
        href: "/institutions",
        name: unirepRoleTranslate?.nav_10,
        label:'unirepRoleTranslate.nav_10'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 4,
        href: "/programs",
        name: unirepRoleTranslate?.nav_4,
        label:'unirepRoleTranslate.nav_4'
        // type: "megaMenu",
        // megaMenu: megaMenuDemo,
      },
      {
        id: 5,
        href: "/countries",
        name: unirepRoleTranslate?.nav_5,
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 6,
        href: "/schoolarships",
        name: unirepRoleTranslate?.nav_6,
        label:'unirepRoleTranslate.nav_6'
      },
      {
        id: 2,
        href: "/students",
        name: unirepRoleTranslate?.nav_3,
        label:'unirepRoleTranslate.nav_3'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 6,
        href: "/parents",
        name: mainTransateData?.nav_7,
        label:'mainTransateData.nav_7'
      },

    ];

    const parentNavigation = [
      {
        id: 1,
        href: "/",
        name: mainTransateData?.inbox_nav,
        label:'mainTransateData.inbox_nav'
        // name: unirepRoleTranslate?.nav_2,
        // label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 1,
        href: "/applications",
        name: unirepRoleTranslate?.nav_2,
        label:'unirepRoleTranslate.nav_2'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
    
      {
        id: 3,
        href: "/institutions",
        name: unirepRoleTranslate?.nav_10,
        label:'unirepRoleTranslate.nav_10'
        // type: "dropdown",
        // children: demoChildMenus,
        // isNew: true,
      },
      {
        id: 4,
        href: "/programs",
        name: unirepRoleTranslate?.nav_4,
        label:'unirepRoleTranslate.nav_4'
        // type: "megaMenu",
        // megaMenu: megaMenuDemo,
      },
      {
        id: 5,
        href: "/countries",
        name: unirepRoleTranslate?.nav_5,
        label:'unirepRoleTranslate.nav_5'
        
      },
      {
        id: 6,
        href: "/schoolarships",
        name: unirepRoleTranslate?.nav_6,
        label:'unirepRoleTranslate.nav_6'
      },
      {
        id: 7,
        href: "/accepted-students",
        name: mainTransateData?.accepted_students,
        label:'mainTransateData.accepted_students'
      },
    ]
    let navs = [];
    if(user === ''){
      navs = navigations
    }else if(user.role == 'uni_rep'){
      navs = unirepNavigations
    }else if(user.role == 'student'){
      navs = studentNavigation
    }else if(user.role == 'edu_consultant'){
      navs = eduConsNavigation
    }else if(user.role == 'parent'){
      navs = parentNavigation
    }
   
    return (
        <div className="overflow-y-auto w-full max-w-sm h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
        <div className="py-6 px-5">
          <Logo />
          <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
            <span>
              Discover the most outstanding articles on all topics of life. Write
              your stories and share them
            </span>
  
            <div className="flex justify-between items-center mt-4">
              {/* <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" /> */}
              <nav
                className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 `}
                data-nc-id="SocialsList"
              >
                {socials.map((item, i) => (
                  <a
                    key={i}
                    className={`w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300`}
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={item.name}
                  >
                    <i className={item.icon}></i>
                  </a>
                ))}
              </nav>
             
            </div>
          </div>
          <span className="absolute right-2 top-2 p-1">
            {/* <ButtonClose onClick={onClickClose} /> */}
            <button
                className={
                    `w-8 h-8 flex items-center justify-center rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700  ` +
                    ''
                }
                onClick={this.onClickClose.bind(this)}
                >
                <span className="sr-only">Close</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
          </span>
        </div>
        <ul className="flex flex-col py-6 px-2 space-y-1">
          {navs.map((item, i)=>{
            return(
              <NavLink
                className="flex w-full px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                to={item.href}
                key={i}
              >
                <span  >
                  {item.name}
                </span>
            </NavLink>
            )
          })}
        </ul>
        <div className="flex items-center justify-between py-6 px-5">
          <a
            className="inline-block"
            href="https://themeforest.net/item/chisfis-online-booking-react-template/33515927"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <ButtonPrimary>Get Template</ButtonPrimary> */}
          </a>
  
          <LangDropdown panelClassName="z-10 w-screen max-w-[280px] px-4 mb-3 -right-3 bottom-full sm:px-0" />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user,
  mainTransateData: state.Data.mainTransateData,
  unirepRoleTranslate: state.Data.unirepRoleTranslate,
  languageId: state.Data.languageId,
});
const mapDispatchToProps = {mainDashboardTranslate, getUnirepRoleTranslate }
export default connect(mapStateToProps, mapDispatchToProps)(NavMobile2)