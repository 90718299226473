import React, { Component } from 'react'
import DropDown from './DropDown'
import { getAllCountries, getEducationDegree,getCountryEducationDegree, getGradingScheme, getSimulatorPrograms, updateStudentData } from '../../../../actions/MainAction';
import { connect } from 'react-redux';
import { aLevelOption } from './aLevelOption';
import { apOptions } from './apOptions';
import { changeStateValue } from '../../../../redux/MainReducer';
import { data } from 'autoprefixer';
import Input from './Input';
import Swal from 'sweetalert2';
export class SimulatorModalForEducon extends Component {
    componentDidMount(){
        this.props.getAllCountries('','', this.props.languageId );
        this.props.getEducationDegree(this.props.languageId);

    }
    optionClick(type, data){

        if(localStorage.getItem('simulator')){
            const lsFilter = JSON.parse(localStorage.getItem('simulator'));
            lsFilter[type] = data;

           

            if(type == 'filterByLevel'){
                this.props.getCountryEducationDegree(this.props.languageId, lsFilter['filterStudiedCountry'], data);
                this.props.getGradingScheme(this.props.languageId, lsFilter['filterStudiedCountry'], data, 'education_degree', '');
                this.props.changeStateValue({
                    name: 'gradingSchemeType',
                    value: data
                })
                this.props.changeStateValue({
                    name: 'gradingScheme',
                    value: []
                })
                lsFilter['filterByGradingScheme'] = ''
                lsFilter['filterByCountryLevel'] = ''
                lsFilter['gradingSchemeId'] = '';
                lsFilter['filterByGPA'] = ''
                lsFilter['filterByOSSD'] = ''
                lsFilter['filterByIB'] = ''
                lsFilter['filterByAP'] = ''
                lsFilter['filterByALevel'] = ''

            }else if(type == 'filterByCountryLevel'){
                if( data !== 'a_level' && 
                    data !== 'ib_diploma' && 
                    data !== 'ossd' && 
                    data !== 'ap'){
                        this.props.getGradingScheme(this.props.languageId, lsFilter['filterStudiedCountry'], lsFilter['filterByLevel'], 'education_sub_degree', data);
                        this.props.changeStateValue({
                            name: 'gradingSchemeType',
                            value: data
                        })
                    lsFilter['filterByGPA'] = ''
                    lsFilter['filterByOSSD'] = ''
                        lsFilter['filterByIB'] = ''
                        lsFilter['filterByAP'] = ''
                        lsFilter['filterByALevel'] = ''

                    }else{
                        this.props.changeStateValue({
                            name: 'gradingScheme',
                            value: []
                        })
                        lsFilter['filterByGradingScheme'] = ''
                        lsFilter['gradingSchemeId'] = ''
                        lsFilter['filterByGPA'] = ''
                        lsFilter['filterByOSSD'] = ''
                        lsFilter['filterByIB'] = ''
                        lsFilter['filterByAP'] = ''
                        lsFilter['filterByALevel'] = ''
                    } 
            }else if(type == 'filterByGradingScheme'){
                lsFilter['filterByGradingScheme'] =  `${data.min_value}-${data.max_value}`;
                lsFilter['gradingSchemeId'] = data.id
            }

 
            localStorage.setItem('simulator', JSON.stringify(lsFilter))
            this.props.changeStateValue({
                name: 'filter',
                value: lsFilter
            }); 
        }
        
    }

    saveChanges(){
        if(localStorage.getItem('token')){
            const simulator = JSON.parse(localStorage.getItem('simulator'));
      
            const obj = {
              filterByCountryLevel: simulator['filterByCountryLevel'],
              filterByGPA: simulator['filterByGPA'],
              filterByGradingScheme: simulator['filterByGradingScheme'],
              filterByIB: simulator['filterByIB'],
              filterByLevel: simulator['filterByLevel'],
              filterByOSSD: simulator['filterByOSSD'],
              gradingSchemeId: simulator['gradingSchemeId'],
              filterByAP: simulator['filterByAP'],
              filterByALevel: simulator['filterByALevel'],
              filterStudiedCountry: simulator['filterStudiedCountry'],
            }
            // console.log(obj)
            
            let formData = new FormData();
            for(const property in obj){
              formData.append(property, obj[property])
            }
            formData.append('_method', 'put');
            formData.append('type', 'personalinfo');
            formData.append('student_id', simulator.student_id)
            
            this.props.updateStudentData(formData)
            .then(resp=>{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                  })
                  
                  Toast.fire({
                    icon: 'success',
                    title: 'Education BackgroundSaved Successfully'
                  })
            })
        }
        localStorage.removeItem('modalSimulator')
        this.props.changeStateValue({
            name: 'simulatorModal', 
            value: false
        })
        if(localStorage.getItem('simulator')){
            const filterData = JSON.parse(localStorage.getItem('simulator'));
           
        }
        
    }

    closeModalBtn(){
        localStorage.removeItem('modalSimulator')
        this.props.changeStateValue({
            name: 'simulatorModal', 
            value: false
        })
        localStorage.removeItem('simulator')
    }
    getPlch(arr, id, title){
        if(id == ''){
            return title;
        }else {
            const check = arr.find(item=>item.value == id);
            if(check){
                return check.label
            }else{
                return title
            }
        }
    }
  render() {
    const {allCountries,educationDegree, countryEducationDegree, filter, gradingScheme, mainTransateData} = this.props;
    const countriesOption = [];
    const degreeOption = [];
    const countryDegreeOption = [];
    const gradingSchemeOption = [];

    console.log(filter)

    for(const obj of allCountries){
        const params = {
          label: obj.name,
          value: obj.countries_id,
        }
        if(params.value == 1  ){
            countriesOption.push(params)
        }
    }
   
    for(const obj of allCountries){
        const params = {
          label: obj.name,
          value: obj.countries_id,
        }
        if( params.value == 5 ){
            countriesOption.push(params)
        }
    }
    for(const obj of allCountries){
        const params = {
          label: obj.name,
          value: obj.countries_id,
        }
        if( params.value == 3 ){
            countriesOption.push(params)
        }
    }
    for(const obj of allCountries){
        const params = {
          label: obj.name,
          value: obj.countries_id,
        }
        if( params.value == 33 ){
            countriesOption.push(params)
        }
    }
    for(const obj of allCountries){
        const params = {
          label: obj.name,
          value: obj.countries_id,
        }
        if( params.value == 34 ){
            countriesOption.push(params)
        }
    }
   
    for(const obj of educationDegree){
        if(obj.education_degree_id != 6 && obj.education_degree_id != 7 && obj.education_degree_id != 8){
            const params = {
                label: obj.education_type,
                value: obj.education_degree_id,
            }
            degreeOption.push(params)
        }
        
    }
    for(const obj of countryEducationDegree){
        const params = {
            label: obj.name,
            value: obj.country_education_degree_id,
        }
  
        countryDegreeOption.push(params)
    }

    for(const obj of gradingScheme){
        const params = {
          label: `${obj.min_value}-${obj.max_value}`,
          value: obj
        }
  
        gradingSchemeOption.push(params)
      }
    if(filter.filterByLevel == 1 ){
        const degre = [
          {
            value: 'a_level',
            label: 'A Level'
          },
          {
            value: 'ib_diploma',
            label: 'IB Diploma'
          },
          {
            value: 'ossd',
            label: 'OSSD'
          },
          {
            value: 'ap',
            label: 'Advanced Placement'
          },
        ];
        countryDegreeOption.push(...degre)
    }
    return (
      <div  className='background_shadow'>
        <div className='simulator_modal_body'>
            <div>
                <h2 className='simulator_modal_title' aria-label='mainTransateData.simulator_modal_title'>{mainTransateData?.simulator_modal_title}</h2>
            </div>
            <div className='grid mt-5 grid-cols-1 md:grid-cols-2 gap-8 md:gap-5 justify-between'>
                <DropDown 
                    ariaLabel="mainTransateData.studiet_country"
                    label={mainTransateData?.studiet_country} 
                    placeholder={this.getPlch(countriesOption,filter?.filterStudiedCountry, mainTransateData?.studiet_country)} 
                    options={countriesOption}
                    zIndex={111}
                    name="filterStudiedCountry"
                    value={filter?.filterStudiedCountry}
                    optionClick={this.optionClick.bind(this, 'filterStudiedCountry')}
                />
                {
                    filter.filterStudiedCountry != ''?
                    <DropDown 
                        ariaLabel="mainTransateData.education_degree"
                        label={mainTransateData?.education_degree} 
                        placeholder={this.getPlch(degreeOption,filter?.filterByLevel, mainTransateData?.education_degree)} 
                        options={degreeOption}
                        zIndex={111}
                        optionClick={this.optionClick.bind(this, 'filterByLevel')}
                        value={filter?.filterByLevel}
                    />:null
                }
                {
                    countryDegreeOption.length >0?
                    <DropDown 
                        ariaLabel="mainTransateData.country_edu_deg"
                        label={mainTransateData?.country_edu_deg} 
                        placeholder={ this.getPlch(countryDegreeOption,filter?.filterByCountryLevel, mainTransateData?.country_edu_deg)} 
                        options={countryDegreeOption}
                        zIndex={100}
                        optionClick={this.optionClick.bind(this, 'filterByCountryLevel')}
                        value={filter?.filterByCountryLevel}
                    />:null
                }
                
                {
                    filter.filterByCountryLevel == 'a_level'?
                    <DropDown 
                        ariaLabel="mainTransateData.select_a_level"
                        label={mainTransateData?.select_a_level} 
                        placeholder={ this.getPlch(aLevelOption,filter?.filterByALevel, mainTransateData?.select_a_level)} 
                        options={aLevelOption}
                        zIndex={100}
                        optionClick={this.optionClick.bind(this, 'filterByALevel')}
                        value={filter?.filterByALevel}

                    />:null
                }
                {
                    filter.filterByCountryLevel == 'ap'?
                    <DropDown 
                        ariaLabel="mainTransateData.select_ap"
                        label={mainTransateData?.select_ap}
                        placeholder={this.getPlch(apOptions,filter?.filterByAP, mainTransateData?.select_ap)}
                        options={apOptions}
                        zIndex={100}
                        optionClick={this.optionClick.bind(this, 'filterByAP')}
                        value={filter?.filterByAP}

                    />:null
                }
                {
                     filter.filterByCountryLevel == 'ib_diploma'?
                     <Input 
                        ariaLabel="mainTransateData.select_ib"
                        label={mainTransateData?.select_ib}
                        placeholder={mainTransateData?.select_ib}
                        minValue={1}
                        maxValue={45}
                        name="filterByIB"
                        value={filter.filterByIB}
                        
                    />:null
                }
                {
                     filter.filterByCountryLevel == 'ossd'?
                     <Input 
                        ariaLabel="mainTransateData.select_ossd"
                        label={mainTransateData?.select_ossd}
                        placeholder={mainTransateData?.select_ossd}
                        minValue={1}
                        maxValue={100}
                        name="filterByOSSD"
                        value={filter.filterByOSSD}
                    />:null
                }
                {
                    gradingSchemeOption.length >1 ?
                    <DropDown 
                        ariaLabel="mainTransateData.grading_schema"
                        label={mainTransateData?.grading_schema} 
                        placeholder={ this.getPlch(gradingSchemeOption,filter?.filterByGradingScheme, mainTransateData?.grading_schema)}
                        options={gradingSchemeOption}
                        zIndex={1}
                        optionClick={this.optionClick.bind(this, 'filterByGradingScheme')}
                        value={filter?.filterByGradingScheme}
                    />:null
                }
                {
                    filter.filterByCountryLevel != 'a_level' &&
                    filter.filterByCountryLevel != 'ap' &&
                    filter.filterByCountryLevel != 'ib_diploma' &&
                    filter.filterByCountryLevel != 'ossd' && filter.filterByGradingScheme != ''&&
                    countryDegreeOption.length >0 && filter.filterByCountryLevel != '' ||
                    filter.filterByCountryLevel != 'a_level' &&
                    filter.filterByCountryLevel != 'ap' &&
                    filter.filterByCountryLevel != 'ib_diploma' &&
                    filter.filterByCountryLevel != 'ossd' && filter.filterByGradingScheme != ''&& countryDegreeOption.length == 0?
                    <Input 
                        ariaLabel="mainTransateData.gpa"
                        label={mainTransateData?.gpa}
                        placeholder={mainTransateData?.gpa}
                        minValue={filter.filterByGradingScheme.split('-')[0]}
                        maxValue={filter.filterByGradingScheme.split('-')[1]}
                        name="filterByGPA"
                        value={filter.filterByGPA}
                    />:null
                }
                
            </div>
            <div className='mt-5 flex justify-between'>
                <button onClick={this.closeModalBtn.bind(this)} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-red-600 hover:bg-red-700 text-neutral-50
                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                    text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200' aria-label="mainTransateData.show_my_score">
                        Close
                    </button>
            {
                filter.filterByOSSD !='' ||
                filter.filterByIB !='' ||
                filter.filterByAP !='' ||
                filter.filterByALevel !='' ||
                filter.filterByGPA !='' ?
               
                    <button onClick={this.saveChanges.bind(this)} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                    text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200' aria-label="mainTransateData.show_my_score">
                        Save
                    </button>
               :null
            }
            </div>
            
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    allCountries: state.Data.allCountries,
    languageId: state.Data.languageId,
    educationDegree: state.Data.educationDegree,
    filter: state.Data.filter,
    countryEducationDegree: state.Data.countryEducationDegree,
    gradingScheme: state.Data.gradingScheme,
    mainTransateData: state.Data.mainTransateData,
});
const mapDispatchToProps = {getAllCountries, getEducationDegree, changeStateValue, getCountryEducationDegree, getGradingScheme, getSimulatorPrograms, updateStudentData}
export default connect(mapStateToProps, mapDispatchToProps)(SimulatorModalForEducon)

