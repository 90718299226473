import React, { Component } from 'react'

export class EditBtn extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            xmlSpace="preserve"
        width={30}
        >
            <path d="M49.757 3.642a.996.996 0 0 0-1.39.249L25.353 36.952a.992.992 0 0 0-.169.427l-1.518 10.432a.997.997 0 0 0 1.466 1.02l9.256-5.044a.98.98 0 0 0 .34-.307l23.016-33.063a.998.998 0 0 0-.249-1.389l-7.737-5.387zM33.224 42.148l-7.296 3.976 1.197-8.223 22.31-32.05 6.1 4.246-22.31 32.05z" />
            <path d="M56.217 23.625a.998.998 0 0 0-.998.998V58.12a3.888 3.888 0 0 1-3.884 3.884H9.186a3.888 3.888 0 0 1-3.884-3.884V15.971a3.888 3.888 0 0 1 3.884-3.884H34.03a.998.998 0 1 0 0-1.997H9.186a5.888 5.888 0 0 0-5.88 5.88v42.15A5.888 5.888 0 0 0 9.186 64h42.149a5.888 5.888 0 0 0 5.88-5.88V24.622a.998.998 0 0 0-.998-.998zM60.25 5.507 52.242.168a.997.997 0 1 0-1.108 1.661l8.008 5.34a.997.997 0 1 0 1.108-1.662z" />
        </svg>
    )
  }
}

export default EditBtn