import React, { Component } from 'react'
import Searchable from 'react-searchable-dropdown'; 

export class DropDown extends Component {
    selectDropValue = (data) =>{
       
        this.props.optionClick(data)
    }
  render() {
    const {zIndex, label, options, placeholder, ariaLabel, value=''} = this.props;
    return (
        <div style={{ zIndex: zIndex }}>
            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} aria-label={ariaLabel} data-nc-id="Label" >
                {label}
            </label>
            <div style={value ==''? {border: '1px solid red'}:{}} className={`block w-full border-neutral-200  
                focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border border-red`}
            > 
                <Searchable 
                    options={options}
                    onSelect={this.selectDropValue.bind(this)}
                    placeholder={placeholder}
                />
            </div>
        </div>
    )
  }
}

export default DropDown