import React, { Component } from 'react'
import { connect } from 'react-redux';
import { insertNewData, deleteData } from '../../../actions/WorkerActions';
import Swal from 'sweetalert2';
export class BlogParentCard extends Component {
    state={
        visible:false,
        addSub:false,
        subTitle:'',
        id:'',
        disable:false
    }
    addSubTopicBtn=(title, topic_id, id)=>{
        if(title.trim() != ""){
            this.setState({disable:false})

            let formData = new FormData();
            formData.append("title", title);
            formData.append("topic_id", topic_id);
            formData.append("lang_id", 1);
            let url = "/blog-sub-topic"
            if(id != ''){
                url=`/blog-sub-topic/${id}`;
                formData.append("_method", "put")
            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({disable:false})
                if(resp?.msg === "success"){
                    this.props.getAfterChange()
                    this.setState({ id:'',  subTitle:'',  addSub:false});
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.onmouseenter = Swal.stopTimer;
                          toast.onmouseleave = Swal.resumeTimer;
                        }
                      });
                      Toast.fire({
                        icon: "success",
                        title: "Blog Sub Topic created successfully"
                      });
                }else{
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.onmouseenter = Swal.stopTimer;
                          toast.onmouseleave = Swal.resumeTimer;
                        }
                      });
                      Toast.fire({
                        icon: "error",
                        title: "Something went wrong"
                      });
                }
            })
        }
    }
    onClickDelete(url){
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
          }).then((result) => {
            if (result.isConfirmed) {
                this.props.deleteData(url)
                .then(resp=>{
                    this.props.getAfterChange()
                    if(resp === "success"){
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                          });
                    }else{
                        Swal.fire({
                            title: "Error!",
                            text: "Something went wrong",
                            icon: "error"
                          });
                    }
                })
              
            }
          });
    }
  render() {
    const {data, onClickEdit=()=>{}} = this.props;
    const {visible, addSub, subTitle, id, disable} = this.state;
    return (
      <div className='bg-white shadow-sm p-[20px] rounded-[12px]'>
        <div className='flex justify-between items-center '>
            <h2 onClick={()=>this.setState({visible: !visible})} className='w-[90%] cursor-pointer'>{data?.title}</h2>
            <div className="flex gap-[10px]">
                <button onClick={()=>onClickEdit(data)} className='bg-orange-500 text-[12px] text-white pl-[10px] pr-[10px] pt-[6px] pb-[6px] font-semibold rounded-[12px]'>Edit</button>
                <button onClick={()=>this.onClickDelete(`blog-topic/${data?.id}`)} className='bg-red-700 text-[12px] text-white pl-[10px] pr-[10px] pt-[6px] pb-[6px] font-semibold rounded-[12px]'>Delete</button>
            </div>
        </div>
        {
            visible?
            <div>
                <div className='mt-[12px] flex items-center gap-[20px]'>
                {
                    addSub?
                      <div className='flex items-center gap-[10px]'>
                        <input placeholder='Sub topic' value={subTitle} className='w-[500px]' onChange={(e)=>this.setState({subTitle:e.target.value})} type='text' />
                        <button disabled={disable} onClick={this.addSubTopicBtn.bind(this, subTitle, data.id, id)} className='bg-orange-500 text-[12px] text-white pl-[10px] pr-[10px] pt-[6px] pb-[6px] font-semibold rounded-[12px]'>{id === ""?"Add":"Update"}</button>
                    </div>:null
                }
                    <button disabled={disable} onClick={()=>this.setState({addSub:!addSub, subTitle:'', id:''})} className='bg-orange-500 text-[12px] text-white pl-[10px] pr-[10px] pt-[6px] pb-[6px] font-semibold rounded-[12px]'>{addSub?"Cancel":"Add Sub Topic"}</button>
                </div>
                <div className='bg-gray-100 p-[10px] mt-[10px] rounded-[12px] flex flex-col gap-[10px]'>
                    {
                        data?.sub_topics.map((item, j)=>{
                            return(
                                <div key={j} className='bg-white p-[12px] rounded-[12px] flex justify-between items-center'>
                                    <h3>{item?.title}</h3>
                                   <div className="flex gap-[10px]">
                                         <button disabled={disable} onClick={()=>this.setState({addSub:true, subTitle:item?.title, id: item?.id,})} className='bg-orange-500 text-[12px] text-white pl-[10px] pr-[10px] pt-[6px] pb-[6px] font-semibold rounded-[12px]'>Edit</button>
                                         <button onClick={()=>this.onClickDelete(`blog-sub-topic/${data?.id}`)} className='bg-red-700 text-[12px] text-white pl-[10px] pr-[10px] pt-[6px] pb-[6px] font-semibold rounded-[12px]'>Delete</button>

                                   </div>

                                </div>
                            )
                        })
                    }
                    
                </div>
            </div>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {insertNewData, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(BlogParentCard)
