export const aLevelOption =[
    {
      label: 'A*A*A*',
      value: 'A*A*A*'
    },
    {
      label: 'A*AA',
      value: 'A*AA'
    },
    {
      label: 'AAA',
      value: 'AAA'
    },
    {
      label: 'AAB',
      value: 'AAB'
    },
    {
      label: 'AAC',
      value: 'AAC'
    },
    {
      label: 'ABB',
      value: 'ABB'
    },
    {
      label: 'ABC',
      value: 'ABC'
    },
    {
      label: 'BBB',
      value: 'BBB'
    },
    {
      label: 'BBC',
      value: 'BBC'
    },
    {
      label: 'CCC',
      value: 'CCC'
    },
    {
      label: 'DDD',
      value: 'DDD'
    },
    {
      label: 'EEE',
      value: 'EEE'
    },
  ];