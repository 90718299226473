import React, { Component } from 'react'
import Input from '../../../../components/modals/components/Input'
import DropDown from './DropDown'
import Footer from './Footer'
import { changeStateValue } from '../../../../redux/MainReducer'
import { connect } from 'react-redux'
export class MainModal extends Component {
  getInpValue = (name, value) => {
   
    this.props.changeStateValue({
        name: this.props.stateName+"."+name,
        value: value
    })
    if(name == 'image'){
        this.getBase64(value)
        .then(resp=>{
            this.props.changeStateValue({
                name: this.props.stateName+".image_full_url",
                value: resp
            })
        })
       
    }
}
getPlaceholder(id, arr){
  if(id){
    const check = arr.find(data=> data.value == id);
    if(check){
      return check.label
    }else{
      return "Other Exam"
    }
  }else{
    return "Other Exam"
  }
}
  render() {
    const {
          drop1option = [],
          drop1Title="",
          drop1Name="",
          drop1placeholder="",
          drop2option=[],
          drop2Title="",
          drop2Name="",
          drop2placeholder="",
          drop3option=[],
          drop3Title="",
          drop3Name="",
          drop3placeholder="",
          drop4option=[],
          drop4Title="",
          drop4Name="",
          drop4placeholder="",
          drop5option=[],
          drop5Title="",
          drop5Name="",
          drop5placeholder="",
          stateName="",
          stateData,
          required=[],
          url,
          getAfterChange,
          otherExamPosition
          } = this.props;

          let otherExamOpt = [
            {
              label: 'OSSD',
              value: 'ossd'
            },
            {
              label: 'A Level',
              value: 'a_level'
            },
            {
              label: 'IB',
              value: 'ib_diploma'
            },
            {
              label: 'AP',
              value: 'advanced_placement'
            },
          ]
    return (
        <div className='modal_background_shadow'>
            <div className='add-edit_modal' style={{ overflow: 'visible' }} >
                <h2 className='text-center text-xl font-semibold'>Add Data</h2>
                <div className='grid grid-cols-2 gap-5 mb-5 mt-5' >
                  {
                    drop1option.length != 0?
                    <DropDown zIndex={222} value={stateData[drop1Name]} option={drop1option} name={drop1Name} placeholder={drop1placeholder} title={drop1Title} />:null
                  }
                  {
                    drop2option.length != 0?
                    <DropDown zIndex={222} value={stateData[drop2Name]} option={drop2option} name={drop2Name} placeholder={drop2placeholder} title={drop2Title}/>:null
                  }
                  {
                    drop3option.length != 0?
                    <DropDown zIndex={221} value={stateData[drop3Name]} option={drop3option} name={drop3Name} placeholder={drop3placeholder} title={drop3Title}/>:null
                  }
                  {
                    drop4option.length != 0?
                    <DropDown zIndex={220} value={stateData[drop4Name]} option={drop4option} name={drop4Name} placeholder={drop4placeholder} title={drop4Title}/>:null
                  }
                  {
                    drop5option.length != 0?
                    <DropDown zIndex={220} value={stateData[drop5Name]} option={drop5option} name={drop5Name} placeholder={drop5placeholder} title={drop5Title}/>:null
                  }
                  {
                   otherExamPosition?
                    <DropDown zIndex={220} value={stateData["other_exam"]} option={otherExamOpt} name={"other_exam"} placeholder={this.getPlaceholder(stateData["other_exam"], otherExamOpt)} title={"Other Exams"}/>:null
                  }
                  {
                    stateData.name || stateData.name == ''?
                    <Input getInpValue={this.getInpValue} name="name" value={stateData.name} title="Name" />:null
                  }
                  {
                    stateData.grade || stateData.grade == ''?
                    <Input getInpValue={this.getInpValue} name="grade" value={stateData.grade} title="Grade" />:null
                  }
                  {
                    stateData.min_value  || stateData.min_value == ''?
                    <Input type="number" getInpValue={this.getInpValue} name="min_value" value={stateData.min_value} title="Min Value" />:null
                  }
                  {
                    stateData.max_value ||  stateData.max_value == ''?
                    <Input type="number" getInpValue={this.getInpValue} name="max_value" value={stateData.max_value} title="Max Value" />:null
                  }
                   
                </div>
                <Footer getAfterChange={getAfterChange} url={url} required={required}  />
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  otherExamPosition: state.Data.otherExamPosition
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(MainModal) 