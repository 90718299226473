import React, { Component } from 'react'

export class Buttons extends Component {
    state={
        page: 'edu_degree'
    }
    changePage(page){
        this.setState({page})
        this.props.onChangePage(page)
    }
  render() {
    const {page} = this.state
    return (
      <div className='flex gap-2'>
        <button onClick={this.changePage.bind(this, 'edu_degree')} className={`border rounded-2xl text-sm pl-2 pr-2 pt-1 pb-1 ${page == 'edu_degree'?'bg-blue-700 text-white':''}`}>Edu. deg</button>
        <button onClick={this.changePage.bind(this, 'currency')} className={`border rounded-2xl text-sm pl-2 pr-2 pt-1 pb-1  ${page == 'currency'?'bg-blue-700 text-white':''}`}>Curency</button>
        <button onClick={this.changePage.bind(this, 'edu_lang')} className={`border rounded-2xl text-sm pl-2 pr-2 pt-1 pb-1  ${page == 'edu_lang'?'bg-blue-700 text-white':''}`}>Edu Lang</button>
        <button onClick={this.changePage.bind(this, 'extra_curricular')} className={`border rounded-2xl text-sm pl-2 pr-2 pt-1 pb-1  ${page == 'extra_curricular'?'bg-blue-700 text-white':''}`}>Ext. Curr. Act</button>
        <button onClick={this.changePage.bind(this, 'internatial_olymp')} className={`border rounded-2xl text-sm pl-2 pr-2 pt-1 pb-1  ${page == 'internatial_olymp'?'bg-blue-700 text-white':''}`}>Int. Olymp.</button>
        <button onClick={this.changePage.bind(this, 'inst_type')} className={`border rounded-2xl text-sm pl-2 pr-2 pt-1 pb-1  ${page == 'inst_type'?'bg-blue-700 text-white':''}`}>Inst. Type</button>
        <button onClick={this.changePage.bind(this, 'count_edu_deg')} className={`border rounded-2xl text-sm pl-2 pr-2 pt-1 pb-1  ${page == 'count_edu_deg'?'bg-blue-700 text-white':''}`}>Country Edu Deg.</button>
        <button onClick={this.changePage.bind(this, 'grading_scheme')} className={`border rounded-2xl text-sm pl-2 pr-2 pt-1 pb-1  ${page == 'grading_scheme'?'bg-blue-700 text-white':''}`}>Grading Schm</button>
        <button onClick={this.changePage.bind(this, 'grade_converter')} className={`border rounded-2xl text-sm pl-2 pr-2 pt-1 pb-1  ${page == 'grade_converter'?'bg-blue-700 text-white':''}`}>Grading Converter</button>
        <button onClick={this.changePage.bind(this, 'country_wise')} className={`border rounded-2xl text-sm pl-2 pr-2 pt-1 pb-1  ${page == 'country_wise'?'bg-blue-700 text-white':''}`}>Country Wise Edu</button>
        <button onClick={this.changePage.bind(this, 'document_category')} className={`border rounded-2xl text-sm pl-2 pr-2 pt-1 pb-1  ${page == 'document_category'?'bg-blue-700 text-white':''}`}>Doc Category</button>
      </div>
    )
  }
}

export default Buttons