import React, { Component } from 'react'

export class SvgDown extends Component {
  render() {
    return (
        <svg width="10" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.03498 9.1589C7.7435 9.46982 7.2565 9.46982 6.96427 9.1589L0.221417 1.98493C-0.0738057 1.67002 -0.0738057 1.15933 0.221417 0.845227C0.51664 0.530323 0.996159 0.530323 1.29138 0.845227L7.49997 7.44932L13.7078 0.844429C14.0038 0.529525 14.4826 0.529525 14.7785 0.844429C15.0737 1.15933 15.0737 1.67002 14.7785 1.98413L8.03498 9.1589Z" fill="black"/>
        </svg>
    )
  }
}

export default SvgDown