import React, { Component } from 'react'
import { connect } from 'react-redux';
import Searchable from 'react-searchable-dropdown'; 
import { changeStateValue } from '../../../../redux/MainReducer';
import { getCountryEducationDegree } from '../../../../actions/MainAction';
export class DropDown extends Component {
  selectDropValue=( name, data) => {
    
    this.props.changeStateValue({
      name: 'newCountryData.'+name,
      value: data
    })
    if(name == 'education_degree_id'){

      this.props.getCountryEducationDegree('1', this.props.newCountryData.countries_id, data)
    }
    if(name == 'residental_degree_id'){

      this.props.getCountryEducationDegree('1', this.props.newCountryData.residental_country_id, data)
    }
    if(name == 'grading_schemes_id' ){
      if(data == null){
        this.props.changeStateValue({
          name: 'otherExamPosition',
          value: true
        })
      }else{
        // console.log("else")
        this.props.changeStateValue({
          name: 'otherExamPosition',
          value: false
        })
        this.props.changeStateValue({
          name: 'other_exam',
          value: null
        })
      }
      
    }
  }
  render() {
    const {option, placeholder, name, title, value='', zIndex} = this.props
    return (
      <div style={{ zIndex: zIndex, position: 'relative' }}>
            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
              {title}
            </label>
            <div  className={`block w-full border-neutral-200  
                focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
            > 
                <Searchable 
                    options={option}
                    onSelect={this.selectDropValue.bind(this,name)}
                    placeholder={placeholder}
                />
                 
              {
                value == ''?
                <p className='text-red-700 text-xs text-bold'>This field is required</p>:null
              }
              
        
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  newCountryData: state.Data.newCountryData
});
const mapDispatchToProps = {changeStateValue, getCountryEducationDegree}
export default connect(mapStateToProps, mapDispatchToProps)(DropDown)