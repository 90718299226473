import React, { Component } from 'react'
import GetMsg from './GetMsg'
import SendMsg from './SendMsg'
// import io from "socket.io-client";
import { connect } from 'react-redux';
// import { NODE_API } from '../../../../../MAIN_API';
// const socket = io.connect(NODE_API);
export class MessagesBody extends Component {
    // state={
    //     messages: [],
    // }
    componentDidMount(){
        console.log(this.props.user)
        // socket.on('get-all-msg', (data)=>{
               
        //     this.setState({
        //         messages: data
        //     })
        // })
    }
  render() {
    // const {messages } = this.state;
    const {user, messagesBoxData} = this.props;
    return (
        <div className="msg-body">
            {
                messagesBoxData.map((message, i)=>{
                    if(message.user_id == user.id){
                        return(
                            <SendMsg status={0} key={i} message={message.message} date={message.created_at} />
                        )
                    }else{
                        return(
                            <GetMsg name={message?.user?.full_name} role={message.role_id} key={i} message={message.message} date={message.created_at} />
                        )
                    }
                })
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user,
    messagesBoxData: state.Data.messagesBoxData,
})
export default connect(mapStateToProps)(MessagesBody)