export const initialState = {
    loader: false,
    loaderLong: false,
    languageId: 1,
    afterApplyModalVisible: false,
    user: '',
    allCountries: [],
    programsByCountry: [],
    allDisciplines: [],
    programs: [],
    programData: '',

    countries: [],
    countryFAQ: [],
    countryData: '',
    stateByCountry: [],
    stateData: '',
    cityData: '',

    cityByCountry: [],

    disciplines: [],
    disciplineData: "",
    
    institutions: [],
    institutionData: '',

    simulatorLogin: false,
    simulatorPrograms: [],
    filter: {
        page:1,
        keyword: '' ,
        filterByCountry: '',
        filterByState:'',
        filterByCity:'',
        filterByStatus:1,
        filterByInstitution:'',
        lang_id: 1,
        filterByLevel:'',
        filterByDiscipline:'',
        filterByDuration:'',
        filterByFee: '',
        filterStudiedCountry: '',
        filterByCountryLevel: '',
        filterByGPA: '',
        filterByGradingScheme: '',
        filterByALevel: '',
        filterByOSSD: '',
        filterByIB: '',
        filterByAP: '',
        filterByExtraActivities: '',
        filterByInternationalOlympiads: '',
        gpaConverter: '',
        gradingSchemeId: '',
        filterByDegree: '',
        filterByLanguage: ''
    },
    citiesByCountryAndState: [],
    examsData: [],
    educationDegree: [],
    countryEducationDegree: [],
    gradingScheme: [],
    gradingSchemeType: '',
    filterExamIds:[],
    filterExamGrade:{
        ielts: '',
        toefl: '',
        pte: '',
        duolingo: '',
        sat: '',
        gre: '',
        gmat: ''
    },
    extraActivities: [],
    internationalOlympiad: [],
    gradeConverters: [],


    // ///////////  UNIREP STATES ////////////////////
    appliedPrograms: [],
    workerInstitutions: [],

    editInstitution: '',
    institutionsImages: [],
    institutionTypes: [],
    stateByCountry: [],
    cityByCountry: [],

    editProgram: '',
    educationDegree: [],
    educationLanguage:[],
    studyDurationValue: '',
    currency: [],

    extraCurricularActivity: [],
    internationalMedals: [],

    examsData: [],
    allExams: [],

    trackingData: [],
    studentTrackings: [],
    studentDocuments: [],


    // //////////translates
    mainTransateData:'',
    unirepRoleTranslate:'',

    eduConsultantStudents: [],

    universityComments: [],

    commentData:{
        id: '',
        institution_id: '',
        comment: '',
        raiting: 5
    },

    countryInfoModal:{
        id: '',
        position: false,
        country_fags:{
            countries_id: '',
            type: '',
        },
        lang_id: 1,
        question: '',
        answer: '',
        active: '1'
    },
    countryInfoData: [],
    institutionInfoModal:{
        id: '',
        position: false,
        institution_faqs:{
            institution_id: '',
            type: '',
        },
        lang_id: 1,
        question: '',
        answer: '',
        active: '1'
    },
    institutionsInfoData: [],


    studentProfileData: '',
    studentProfilePercentage: {
        education_background: 0,
        personal_info: 0,
        student_exam: 0,
    },
    studentEduBackground: '',
    studentExams: [],
    studentActivities:{
        activities: [],
        olympiads: [],
    },
    studentDestinations:{
        destination_countres: [],
        destination_disciplines: [],
    },
    unirepStudents: [],
    eduConsultants: [],
    assignStudentModal:{
        position: false,
        student_id: ''
    },
    parentStudents: [],
    parentPersonalInfo: '',

    editUserState:{
        position: false,
        id:'',
        first_name: '',
        last_name: '',
        email: '',
        password:'',
        image: ''
    },
    eduConsultantStudents: [],
    eduConsultantParents: [],
    simulatorModal: false,
    simulatorType: 'program',
    studentFinances: [],

    messages: [],

    translateModal: {
        position: false,
        url: '',
        title: '',
        text: ''
    },
    scholarshipPrograms: [],
    studentWishList: [],
    relatedPrograms: [],
    relatedUniversity: [],
    messagePage: {
        groupName: '',
        userName: '',
        program_id: '',
        student_id: '',
        messages: []
    },
    typingMsg: '',
    message: '',
    seachmsgUsers: '',
    messagesUsers: [],

    contentUsers: [],
    translatorUsers: [],
    allLanguages: [],
    translatorDashboardData: '',
    translatorUnirepData: '',



    countriesTranslatorData: [],
    countriesFaqTranslatorData: [],

    institutionTranslate: [],
    institutionFaqTranslate: [],

    accTrackTranslate: [],
    appTrackTranslate: [],
    visaTrackTranslate: [],

    eduDegreeTranslate: [],
    extraActivityTranslate: [],
    intOlympiadTranslate: [],


    confirmEmail: {
        email: '',
        sentTime: '',
        deadline: '',
        path: ''
    },
    teachers: [],
    uniByCities: [],
    scholarhipTrackings: [],
    studentScholarhipTrackings: '',
    postStudyTracking: [],
    studentPostStudy: '',
    appliedStudents: [],
    companies: [],

    messagesUserList:[],
    messagesBoxData:[],

    inactivePrograms: 0,

    newCountryData:{
        id: '',
        position: false,
        image_full_url: null,
        name: '',
        description: '',
        active: 1,
        lang_id: 1

    },
    countEduDegree: [],
    allGradingScheme:[],
    gradeConverter: [],
    otherExamPosition: false,
    countryWiseEdu: [],
    instApproveData: '',
    footerCountries: [],
    footerInstitutions:[],
    allPublicConverters:[],



    allInst: [],
    allRoboInst:[],
    matchedUni: [],

    studentNoteModal: {
        position: false,
        student_id:'',
        id:'',
        note:''
    },
    studentNotes:[],
    singleAcptStudent:'',

    documentCategories:[],
    documents:{
        category:'',
        country: '',
        data: []
    },
    documentsModal: {
        id:'',
        position:false,
        title: '',
        country_id:'',
        category_id:'',
        description:'.',
        status:0,
        file:''
    },
    documentData:'',
    documentSearch:[],
    companyInOuts:[],
    oneCompanyInOuts:[],
    studentFinanceLock:'',
    commissionForMessage:'',
    comissionForManager:[],

    workerInOuts:[],
    workerFinanceInOuts:[],
    educationDegreeByUni:[],

    educonStudentForApply:[],
    educonApplyModal: {
        position: false,
        program_id: ''
    },
    oneStudentApplys:[],
    blogTopics:[],
    adminBlogs:[],
    blogModalData:{
        position:false,
        id:'',
        description:'',
        title:'',
        
    },
    studentPrograms:[],

    studentNewModal:{
        position:false
    },
    studentArchivePrograms:[],
    uniChangeModal:{
        position:false,
        uni_id:'',
        uni_name:''
    },
    freeInstitutions:[],
    studentTotalIncomes:[],
    studentTotalIncModal:{
        position:false,
        id:'',
        student_id:'',
        worker_id:'',
        amount:'',
        title:'',
        file:'',
        file_full_url:''
    },
    studentFreeUni:''
}
