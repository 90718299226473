import React, { Component } from 'react'

export class MainCard extends Component {
  render() {
    const {img = '', title, subtitle, subTitle2=''} = this.props
    return (
        <div className='flex items-center gap-3 shadow-md p-2 rounded-xl mb-3'>
            {
                img != ''?
                <img className='w-10 h-10 rounded-full' src={img} />:null
            }
            
            <div>
            <h4 className='text-lg font-medium'>{title}</h4>
            <p className='text-sm font-light'>{subtitle}</p>
            {
                subTitle2 != ''?
                <p className='text-sm font-light'>{subTitle2}</p>:null
            }
            
            </div>
        </div>
    )
  }
}

export default MainCard
