import React, { Component } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetPasswordAction } from '../actions/MainAction';
import Swal from 'sweetalert2';
function withHooks(Component){
    return props => <Component {...props} navigate={useNavigate()} />
}
export class ResetPassword extends Component {
    state={
        password: '',
        confirmPassword: '',
        email:'',
        confErr: false,
        btnDisable:false
    }
    sendBtn(password, confirmPassword){
        this.setState({confErr:false})
        if(password != '' && confirmPassword != ''){
            if(password == confirmPassword){
                this.setState({btnDisable:true})
                this.props.resetPasswordAction({email:this.state.email, password})
                .then(resp=>{
                    this.setState({btnDisable:false})
                    if(resp == 'success'){
                        if(resp == 'success'){
                            Swal.fire({
                              position: 'top-end',
                              icon: 'success',
                              title: 'Password reseted successfully',
                              showConfirmButton: false,
                              timer: 1500
                            })
                            this.props.navigate('/login')
                          }else{
                            Swal.fire({
                              position: 'top-end',
                              icon: 'error',
                              title: 'Something went wrong',
                              showConfirmButton: false,
                              timer: 1500
                            })
                          }
                    }
                })
            }else{
                this.setState({confErr:true})
            }
        }
    }
    componentDidMount(){
        const header = window.location?.search.split('?token=')[1];
        if(header){
            const email = header.split('hjtlksaad')[0];
            try{
                const decode1 = atob(email);
                const decode2 = atob(decode1);
              
                this.setState({email:decode2});
            }catch(err){
                setTimeout(()=>{this.props.navigate('/login')}, 500)
            }
            
        }else{
            setTimeout(()=>{this.props.navigate('/login')}, 500)
        }
       
    }
  render() {
    const {password, confirmPassword, email, confErr, btnDisable} = this.state;
   
    return (
        <div style={{ height: '60vh' }} className='container flex items-center justify-center'>
        <div style={{ flexDirection: 'column', width: '400px' }} className='flex gap-3 items-center border pt-10 pl-10 pr-10 pb-10 rounded-xl shadow-xl'>
             <h2 className='text-2xl font-semibold'>Change your password</h2>
            <input value={password} onChange={(e)=>this.setState({password:e.target.value})} type='password' placeholder="Password" />
            <input  style={confErr?{border: '1px solid red'}:{}} value={confirmPassword} onChange={(e)=>this.setState({confirmPassword:e.target.value})} type='password' placeholder="Confirm password" />
            <button disabled={btnDisable} onClick={this.sendBtn.bind(this, password,confirmPassword )} className='text-sm border rounded-xl p-2 bg-blue-500 text-white font-semibold cursor-pointer'>
                Reset password
            </button>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {resetPasswordAction}

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ResetPassword))
