import React, { Component } from 'react'
import { connect } from 'react-redux';
import Searchable from 'react-searchable-dropdown'; 
import { getEduCons, assignStudent, getStudents } from '../../../actions/WorkerActions';
import { changeStateValue } from '../../../redux/MainReducer';
export class AssignModal extends Component {
    state={
        worker_id: '',
        btnVisible: false
    }
    componentDidMount(){
        this.props.getEduCons()
    }
    selectDropValue = (data)=>{
     
        this.setState({
            worker_id: data
        })
    }

    assignBtn(student_id, worker_id, e){
        if(student_id != '' && worker_id != ''){
            let formData = new FormData();
            formData.append('student_id', student_id);
            formData.append('worker_id', worker_id);
            this.props.assignStudent(formData)
            .then(resp=>{
                this.props.getStudents('non_assign')
                this.props.getStudents('assign_student')

                this.closeModal();
            })
        }
        // console.log(student_id, worker_id)
    }

    closeModal(){
        this.props.changeStateValue({
            name: 'assignStudentModal',
            value: {
                position: false,
                student_id: ''
            }
        })
    }
    closeBackground(e){
        if(e.target.className == 'background_shadow'){
            this.closeModal();
        }
    }
  render() {
    const {eduConsultants, assignStudentModal} = this.props;
    const {worker_id, btnVisible} = this.state;
    const option = [];
    for(const data of eduConsultants){
        if(!assignStudentModal.worker.find(item=>item.worker_id == data.id)){
            option.push({
            label: data.full_name,
            value: data.id,
            })
        }
        
    }
    return (
      <div className='background_shadow' onClick={this.closeBackground.bind(this)}> 
            <div className='assign_modal_container'>
                <h2>Assign to worker</h2>
                <div style={{ zIndex: 200 }} className="mt-5">
                   
                    <div  className={`block w-full border-neutral-200  
                        focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                        dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                        dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                    > 
                        <Searchable 
                            options={option}
                            onSelect={this.selectDropValue.bind( this.label)}
                            placeholder={'Select a worker'}
                        />
                    </div>
                </div>
                <div className='mt-5 flex justify-between'>
                    <button disabled={btnVisible} onClick={this.closeModal.bind(this, )}  className={`ttnc-ButtonThird text-neutral-700 border 
                border-neutral-200 dark:text-neutral-200 
                dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                items-center justify-center rounded-full transition-colors
                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200 
                px-4 py-1 sm:px-3  `} style={{ fontSize: '12px', fontWeight:'400',}}>
                                
                    close</button>
                    <button disabled={btnVisible} onClick={this.assignBtn.bind(this, assignStudentModal.student_id, worker_id)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                               nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                               text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' 
                               style={{ fontSize: '12px', color: 'white' }}   >
                                
                    Assign</button>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    eduConsultants: state.Data.eduConsultants,
    assignStudentModal: state.Data.assignStudentModal,
});
const mapDispatchToProps = {getEduCons, changeStateValue, assignStudent, getStudents}
export default connect(mapStateToProps, mapDispatchToProps)(AssignModal)