import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
export class AfterApplyModal extends Component {
    closeModal(e){
        if(e.target.className == 'background_shadow'){
            this.props.changeStateValue({
                name: 'afterApplyModalVisible',
                value: false
            })
        }
    }
    componentDidMount(){
        setTimeout(()=>{
            this.props.changeStateValue({
                name: 'afterApplyModalVisible',
                value: false
            })
        }, 2000)
    }
  render() {
    return (
      <div className='background_shadow' onClick={this.closeModal.bind(this)} style={{ zIndex: '999' }}>
        <div className='after_apply_modal'>
            <p className='apply_modal_text'>Thanks for your interest. You have submitted your interest in the program. Our admission team will check your profile and will get back to you. Please ensure your email is correct to receive emails from us or check your profile regularly.
                We will get back to you within a working day.</p>
            <div className='regrets_container'>
                <span> Regards, </span>
                <span>CELT Abroad Admissions Team</span>
            </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AfterApplyModal)