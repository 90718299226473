import React, { Component } from 'react'
import { connect } from 'react-redux';
import avatar1 from "../../../images/default.jpg";
import File from './File';
import { changeStateValue } from '../../../redux/MainReducer';
export class UploadImage extends Component {
  state={
    logo: '',
    images: [],
    defaultImg: '',
    imagesFiles: []
  }

  uploadLogo(e){
    let reader = new FileReader();

    reader.onload = ()=>{
      this.setState({
        logo: reader.result
      })
    }
    this.props.changeStateValue({
      name: this.props.logoName,
      value: e.target.files[0]
    })
    reader.readAsDataURL(e.target.files[0])
  }

  uploadImages = (file, array) => {
    let newImages = array;
    let reader = new FileReader();
 
    reader.onload = ()=>{
      newImages.push({
        id: '',
        image_full_url: reader.result,
        file: file
      })

      this.setState({
        images: newImages,
        defaultImg: reader.result
      })
      this.props.setImages(newImages)

    }
    reader.readAsDataURL(file)
    

  }
  setAsDefualt(img, e){
    this.setState({
      defaultImg: img
    })
  }

  deleteImage(index, allImg, e){
    
    allImg[index].delete = true;
    this.setState({
      images: allImg
    })
    if(this.state.defaultImg == allImg[index].image_full_url){
      this.setState({
        defaultImg: allImg.filter(x=>!x.delete)[0].image_full_url
      })
    }
    this.props.setImages(allImg)
  }
  render() {
    const {logo, images, defaultImg, } = this.state;
    const { defaultLogo, dbImages,  translate} = this.props;

    let allImages = [];
    if(images.length == 0){
      for(const img of dbImages?dbImages:[]){
        allImages.push({
          id: img.id,
          image_full_url: img.image_full_url,
          file: ''
        })
      }
    }else{
      allImages = [...images]
    }

    return (
      <div  className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
            
        <div className='grid grid-cols-1 md:grid-cols-1 gap-8 md:gap-5'>
            <div className='image_container'>
              {
                defaultImg !== ''?
                <img className='main_img' src={defaultImg} />:
                allImages.length !== 0?
                <img className='main_img' src={allImages[0].image_full_url} />:
                <img className='main_img' src={avatar1} />
              }
            
            <label className='logo_img' htmlFor='uploadLogo'>
              {
                logo !== ''?
                <img src={logo}  alt="logo" />:
                defaultLogo && defaultLogo !== ''?
                <img src={defaultLogo}  alt="logo" />:
                <img src={avatar1}  alt="logo" />
              }
              
            </label>
                      
                <input id='uploadLogo' accept='image/jpg, image/png, image/jpeg, image/webp'  onChange={this.uploadLogo.bind(this)} type={'file'} style={{ display: 'none' }} />
            </div>
            <div className='mini_img_container '>
              {
                allImages.map((img, i)=>{
                  if(!img.delete){
                    return(
                      <div key={i} className='mini_img'>
                        <img onClick={this.setAsDefualt.bind(this, img.image_full_url)} alt="image" src={img.image_full_url}  />
                        <button onClick={this.deleteImage.bind(this, i, allImages)}>
                          <svg width={'12px'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path fill='white' d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                          </svg>
                        </button>
                      </div>
                    )
                  }
                })
              }
            </div>
        </div>
        <div>
        <File translate={translate} images={allImages} uploadImages={this.uploadImages} />
        
        </div>
        
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  institutionsImages: state.Data.institutionsImages
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(UploadImage)