function getALevelGrade(grade){
    if(grade == 'A*A*A*'){
        return 100
    }else if(grade == 'A*AA'){
        return 89
    }
    else if(grade == 'AAA'){
        return 85

    }
    else if(grade == 'AAB'){
        return 82

    }
    else if(grade == 'AAC'){
        return 81

    }
    else if(grade == 'ABB'){

        return 79
    }
    else if(grade == 'ABC'){
        return 78

    }
    else if(grade == 'BBB'){
        return 75

    }
    else if(grade == 'BBC'){
        return 74

    }
    else if(grade == 'CCC'){
        return 71

    }
    else if(grade == 'DDD'){
        return 64

    }
    else if(grade == 'EEE'){
        return 64
    }
}


function getPercentage(programData, filter, exams,type, allPublicConverters=[]){
    
    let program = programData[type]

    let percentage = 0;
    if(localStorage.getItem('filterExamGrade')){
        // let exams = JSON.parse(localStorage.getItem('filterExamGrade'));
        // let exams = this.props.filterExamGrade;
      
        if(program?.specialty_exams.length !== 0){
            let x = 0;
            let y = 0;
            program?.specialty_exams.forEach(exam=>{
              
                if(exam?.exam?.exam_type == 'IELTS' && exams.ielts !== '' && +exam?.over_all <= +exams.ielts ||
                    exam?.exam?.exam_type == 'TOEFL' && exams.toefl !== '' &&  +exam?.over_all <= +exams.toefl ||
                    exam?.exam?.exam_type == 'Duolingo' && exams.duolingo !== '' &&  +exam?.over_all <= +exams.duolingo ||
                    exam?.exam?.exam_type == 'PTE' && exams.pte !== '' &&  +exam?.over_all <= +exams.pte 
                ){
                    x++
                    if(x==1){
                        percentage+= +exam?.percentage
                    }
                    
                
                }
                if(exam?.exam?.exam_type == 'SAT' && exams.sat !== '' &&  +exam?.over_all <= +exams.sat ||
                    exam?.exam?.exam_type == 'GRE' && exams.gre !== '' &&  +exam?.over_all <= +exams.gre ||
                    exam?.exam?.exam_type == 'GMAT' && exams.gmat !== '' &&  +exam?.over_all <= +exams.gmat 
                ){
                    y++;
                    if(y == 1){
                        percentage+= +exam?.percentage
                    }
                    
                }
                // if(exam?.exam?.exam_type == 'TOEFL' && exams.toefl !== '' &&  +exam?.over_all <= +exams.toefl){
                //     percentage+= +exam?.percentage
                // }
                // if(exam?.exam?.exam_type == 'Duolingo' && exams.duolingo !== '' &&  +exam?.over_all <= +exams.duolingo){
                //     percentage+= +exam?.percentage
                // }
                // if(exam?.exam?.exam_type == 'PTE' && exams.pte !== '' &&  +exam?.over_all <= +exams.pte){
                //     percentage+= +exam?.percentage
                // }
                // if(exam?.exam?.exam_type == 'GRE' && exams.gre !== '' &&  +exam?.over_all <= +exams.gre){
                //     percentage+= +exam?.percentage
                // }
                // if(exam?.exam?.exam_type == 'GMAT' && exams.gmat !== '' &&  +exam?.over_all <= +exams.gmat){
                //     percentage+= +exam?.percentage
                // }
                
            })
        }
    }
    if(localStorage.getItem('simulator')){
        // const filter = this.props.filter;
        if(filter){
            if(filter.filterByExtraActivities && filter.filterByExtraActivities !== ''){
                const activities = filter.filterByExtraActivities.split(',');
                if(program?.activity.length !== 0){
                    let x = 0
                    program?.activity.forEach((activity=>{
                        if(activities.find(data=> +data == activity.activity_id)){
                            x++;
                            // if(x == 1){
                                percentage+= +activity.percentage;
                            // }
                             
                        }
                        

                    }))
                }
            }
            if(filter.filterByInternationalOlympiads && filter.filterByInternationalOlympiads !== ''){
                const olympiads = filter.filterByInternationalOlympiads.split(',');
                if(program?.olympiad.length !== 0){
                    let x = 0
                    program?.olympiad.forEach((olympiad=>{
                        if(olympiads.find(data=> +data == olympiad.olympiad_id)){
                            x++;
                            // if(x == 1){
                                percentage+= +olympiad.percentage;
                            // }
                             
                        }
                        

                    }))
                }
            }
            if(+filter.filterByLevel>2){
                if(filter.filterByGPA !== '' && program?.gpa !== ''){
                    if(+filter.gpaConverter >= +program?.gpa){
                        
                        percentage+= +program?.gpa_percentage
                    }
                }
            }else {
                if(programData?.program?.exam_check == 1){
                    // if(filter.filterByGPA !== '' && program?.gpa !== ''){
                    //     if(+filter.gpaConverter >= +program?.gpa){
                            
                    //         percentage+= +program?.gpa_percentage
                    //     }
                    // }
                  
                    if(filter.filterByALevel !== '' && program?.a_level !== '' && program?.a_level){
                        const check = allPublicConverters.find(grrade=> grrade.other_exam == 'a_level' && grrade.grade == filter.filterByALevel)
                        if(check && check?.max_value){
                            if(+check?.max_value >= +program?.a_level_grade){
                                percentage+= +program?.a_level_percentage
                            }
                        }
                        
                    }
                    if(filter.filterByAP !== '' && program?.advanced_placement !== '' && program?.ap_grade){
                        if(+filter.filterByAP >= +program?.ap_grade){
                            percentage+= +program?.ap_percentage
                        }
                    }
                    if(filter.filterByOSSD !== '' && program?.ossd !== '' && program?.ossd_grade){
                        if(+filter.filterByOSSD >= +program?.ossd_grade){
                            percentage+= +program?.ossd_percentage
                        }
                    }
                    if(filter.filterByIB !== '' && program?.ib_diploma !== '' && program?.ib_grade){
                        if(+filter.filterByIB >= +program?.ib_grade){
                            percentage+= +program?.ib_percentage
                        }
                    }
                }else{
                 
                    if(filter.filterByGPA !== '' && program?.gpa !== ''){
                        if(+filter.gpaConverter >= +program?.gpa){
                            
                            percentage+= +program?.gpa_percentage
                        }
                    }
        
                    if(filter.filterByALevel !== ''){
                        const check = allPublicConverters.find(grrade=> grrade.other_exam == 'a_level' && grrade.grade == filter.filterByALevel);
                       
                        if(check && check?.max_value){
                            if(+check?.max_value >= +program?.gpa){
                                percentage+= +program?.gpa_percentage
                            }
                        }
                        
                    }
                    if(filter.filterByAP !== '' ){
                        const check = allPublicConverters.find(grrade=> grrade.other_exam == 'advanced_placement' && grrade.grade == filter.filterByAP)
                        if(check && check?.max_value){
                            if(+check?.max_value >= +program?.gpa){
                                percentage+= +program?.gpa_percentage
                            }
                        }
                        
                    }
                    if(filter.filterByOSSD !== '' ){
                        if(+filter.filterByOSSD >= +program?.gpa){
                            percentage+= +program?.gpa_percentage
                        }
                    }
                    if(filter.filterByIB !== '' ){
                        const check = allPublicConverters.find(grrade=> grrade.other_exam == 'ib_diploma' && grrade.grade == filter.filterByIB)
                        if(check && check?.max_value){
                            if(+check?.max_value >= +program?.gpa){
                            percentage+= +program?.gpa_percentage
                        }
                        }
                        
                    }
                }
            }
            
            
            
            // if(filter.filterByIB !== '' && program?.program?.ib_diploma !== '' && program?.program?.ib_grade){
            //     if(+filter.filterByIB >= +program?.program?.ib_grade){
            //         percentage+= +program?.program?.ib_percentage
            //     }
            // }
        }
    }
// console.log(percentage)
    return{...programData, percentage}

   
}

export function programs(programs, filter, exams, sorting = '', type = 'program', allPublicConverters){

    let lastprograms = [];
    programs.forEach(program=>{
        lastprograms.push(getPercentage(program, filter, exams, type, allPublicConverters))
    })
   
    if(sorting === ''){
         return lastprograms;
    }else if(sorting === 'desc'){
        return lastprograms.sort((a, b)=>b.percentage - a.percentage )
    }else if(sorting === 'asc'){
        return lastprograms.sort((a, b)=>a.percentage - b.percentage )
    }
   
}