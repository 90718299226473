import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
export class InputSelect extends Component {
  changeInpValue(e){
    // this.props.changeStateValue({
    //   name: e.target.name,
    //   value: e.target.value
    // })
    this.props.changeInpValue(e.target.name,  e.target.value)
  }
  render() {
    const {label = '', value = '', type = 'number', name="", placeholder="", selectValue='', options = [], selectName = ''} = this.props
    return (
        <div>
            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
            {label}
            </label>
            <div className='multiple_drop_input border rounded-2xl h-11  '>
                <input onChange={this.changeInpValue.bind(this)} value={value} type={type} name={name} placeholder={placeholder} /> 
                <select onChange={this.changeInpValue.bind(this)} value={selectValue} name={selectName} className='rounded-2xl'>
                  {
                    options.map((opt, i)=>{
                      return(
                        <option key={i} value={opt.value}>{opt.label}</option>
                      )
                    })
                  }
                  
                </select>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToprops = {changeStateValue}

export default connect(mapStateToProps, mapDispatchToprops)(InputSelect)