import React, { Component } from 'react'

import { connect } from 'react-redux';
import JoditEditor from "jodit-react"; 
import { insertNewData, getStudentNotes, deleteStudentTracking } from '../../actions/WorkerActions';
import { changeStateValue } from '../../redux/MainReducer';
import Swal from 'sweetalert2';
export class StudentNoteModal extends Component {
    state={
        noteText: this.props.studentNoteModal.note,
        visible:false,
        id:this.props.studentNoteModal.id
    }
    closeModal(){
        this.props.changeStateValue({
            name: 'studentNoteModal',
            value: {
                position:false,
                student_id: ''
            }
        })
    }   
    config = {
		zIndex: 0,
        readonly: false,
        activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
        toolbarButtonSize: 'middle',
        theme: 'default',
        saveModeInCookie: false,
        spellcheck: true,
        editorCssClass: false,
        triggerChangeEvent: true,
        width: 'auto',
        height: 300,
        minHeight: 100,
        direction: '',
        language: 'auto',
        debugLanguage: false,
        i18n: 'en',
        tabIndex: -1,
        toolbar: true,
        enter: "P",
        useSplitMode: false,
        colors: {
            greyscale:  ['#000000', '#ff0000', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
            palette:    ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
            full: [
                '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
                '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
                '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
                '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
            ]
        },
        colorPickerDefaultTab: 'text',
        imageDefaultWidth: 300,
        removeButtons: [
            'source', 
            'fullsize'
        ],
        disablePlugins: [],
        extraButtons: [
            'strikethrough',
            'underline',
            'hr'
        ],
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        
        buttons: [
           
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent',  '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'video',
            'table',
            'link', '|',
            'align', 'undo', 'redo', '|',
            'hr',
            'eraser',
            'copyformat', '|',
            'symbol',
            'print',
            'about'
        ],
        buttonsXS: [
            'bold',
            'image', '|',
            'brush',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots',
        ],
        events: {},
        textIcons: false,
        showWordsCounter: false,
        showCharsCounter: false
    }
    changeValue = (e)=>{
        this.setState({
            noteText: e
        })
    }
    saveData(text, id){
        if(text != ''){
            this.setState({visible:true});
            let formData = new FormData();
            formData.append('student_id', this.props.studentNoteModal?.student_id);
            formData.append('note', text);

            let url = '/student-note';
            if(id != ''){
                url = `/student-note/${id}`;
                formData.append("_method", "put")
            }

            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({visible:false, noteText:''});
                this.props.getStudentNotes(this.props.studentNoteModal?.student_id)
                this.closeModal()
            })
        }
    }

    editNoteBtn(data){
        this.setState({
            noteText: data?.note,
            id:data?.id
        })

        
    }

    deleteNoteBtn(id){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.props.deleteStudentTracking(`student-note/${id}`)
                .then(resp=>{
                    Swal.fire(
                        resp =='success'? 'Deleted!':"Error!" ,
                        resp =='success'? 'Your note deleted!':"Something went wronge!" ,
                        resp
                      )
                      this.props.getStudentNotes(this.props.studentNoteModal?.student_id)
                      this.closeModal()
                })
             
            }
          })
    }
  render() {
    const {noteText, visible, id} = this.state;
    const {studentNotes} = this.props;
    console.log(studentNotes)
    return (
        <div className='note_shadow'>
            <div className='note_modal_body'>
                <div className='flex items-center justify-between'>
                    <h3>Notes</h3>
                    <div className='flex justify-end mb-3 mr-5'>
                            <span className="sr-only">Close</span>
                            <svg onClick={this.closeModal.bind(this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5 cursor-pointer"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </div>
                </div>
          
                <div className='mt-10'>
                    <JoditEditor
                        config={this.config}
                        value={noteText}
                        onChange={this.changeValue.bind(this)}
                    />
                    <div className='flex items-center justify-between mt-3'>
                        
                        <button onClick={this.closeModal.bind(this)}  className='ttnc-ButtonPrimary disabled:bg-opacity-70
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors border
                                        text-sm sm:text-base font-medium  dark:text-neutral-200  px-4 py-1 sm:px-3' >
                            Close
                        </button>
                        <button disabled={visible} onClick={this.saveData.bind(this, noteText, id)}  style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3' >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
    studentNoteModal: state.Data.studentNoteModal,
    studentNotes: state.Data.studentNotes,
});
const mapDispatchToProps = {insertNewData, getStudentNotes,changeStateValue,  deleteStudentTracking}
export default connect(mapStateToProps, mapDispatchToProps)(StudentNoteModal)
