import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCountriesTranslator } from '../../actions/TranslateAction';
import SectionHeader from '../../components/page_component/SectionHeader';
import TranslateCard from './components/TranslateCard';
export class TranslatorCountries extends Component {

  componentDidMount(){
    this.props.getCountriesTranslator()
  }

  getAvailableData(data, item){
    // console.log(data)
    let array = [];
    for(const property in data.default){
     
      if(property == 'name' || property == 'description'){
        let x = []
        x.push(
          `${property}`, data[item]&&data[item][property]?data[item][property]:''
        )
      array.push(x)

      }
    }

    return array
  }
  getAfterSave = () =>{
    this.props.getCountriesTranslator()
  }

  render() {

    const {countriesTranslatorData, user} = this.props;
    // console.log(countriesTranslatorData)
    return (
      <div className='container'>
        {
          user != ''?
          <SectionHeader  title={`Translate Countries From English to ${user?.lang?.main_lang?.name}`} />:null
        }
        <ul>
          {
            countriesTranslatorData.map((data, i)=>{
              return(
                <TranslateCard  getAfterSave={this.getAfterSave} url={`setCountryTranslate/${countriesTranslatorData[i].default.countries_id}`} key={i} data={this.getAvailableData(data, 'default')} translate={this.getAvailableData(data, 'translate')} />
              )
            })
          }
        </ul>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  countriesTranslatorData: state.Data.countriesTranslatorData,
  user: state.Data.user,
});
const mapDispatchToProps = {getCountriesTranslator}
export default connect(mapStateToProps, mapDispatchToProps)(TranslatorCountries)