import React, { Component } from 'react'
import { connect } from 'react-redux';
import DropDown from './components/DropDown';
import Input from './components/Input';
import InputPercentage from './components/InputPercentage'
import InputSelect from './components/InputSelect'
import MultiSelect from './components/MultiSelect';
import SelectBox from './components/SelectBox';
import { changeStateValue } from '../../redux/MainReducer';
import { getEducationDegree, getEducationLanguage, getCurrency,  getExtraCurricularActivity, getInternationalMedals, insertNewData } from '../../actions/WorkerActions';
import StudyDuration from './components/StudyDuration';
import ExamsInput from './components/ExamsInput';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {getUnirepRoleTranslate, mainDashboardTranslate} from '../../actions/TranslateAction';

import HighSchoolExams from './components/HighSchoolExams';
import HighSchoolSchExams from './components/HighSchoolSchExams';
import moment from 'moment';
import { changeProgramStatus, getAllRoboUniversity } from '../../actions/AutoLoadActions';
import Swal from 'sweetalert2';
function withParams(Component){
    return props=><Component {...props} params={useParams()} navigate={useNavigate()} />
}
export class CardBody extends Component {
  state={
    part1Visible: false,
    part2Visible: false,
    part3Visible: false,
    part4Visible: false,
    exams: [],
    activityData: [],
    olympiadData: [],
    sch_exams:[],
    sch_activities:[],
    sch_olympiads: [],
    highSchoolExamVisible: false,
    btnVisible: false,
    addDate: [],
    setAsRequired: false,
    editProgram:'',
    defaultExams: [],
    deadlines:[]

  }

  getFee(fee){
   
    if(fee){
      const checkStar = fee.includes('*');
      let feeAmount;
      if(checkStar){
        feeAmount = fee.split('*')[0]
      }else{
        feeAmount = fee;
      }
  
      let lastData = feeAmount.substring(1, feeAmount.length);
      if(lastData.includes(' ')){
        return lastData.split(' ')[0].replaceAll(',', '')
      }else{
        return lastData
      }
    }else{
      return ''
    }
    
  }

  getCurrency(data){
    if(data){
      const currencySymbl = data.substring(0, 1);
      let currency = this.props.currency?.find(item=> item?.currency == currencySymbl);
    
      return currency?.id
    }else{
      return ''
    }
    
  }

  getDuration(duration){
    if(duration){
      const splt = duration.split(' ')[0];
      let data = splt;
      if(duration.toLowerCase().includes('year')){
      
        data+="|1"
      }else if(duration.toLowerCase().includes('month')){
        data+="|2"
      }else if(duration.toLowerCase().includes('week')){
        data+="|3"
      }
    
      return data
    }else{
      return ''
    }
  }

  getProgramFormat(text){
    if(text){
      if(text.toLowerCase().includes("full time") || text.toLowerCase().includes("full-time")){
        return "Full Time"
      }else if(text.toLowerCase().includes("online") || text.toLowerCase().includes("distance")){
        return "Online"
      }else if(text.toLowerCase().includes("part-time") || text.toLowerCase().includes("par time")){
        return "Part Time"
      }else{
        return ""
      }
    }else{
      return ''
    }
  }

  setExamsDeafult(examsData){
    let examsOption = [];
    let selectExams = [];
    for(const proprty of this.props.allExams){
        examsOption.push({
            exam_type: proprty.exam_type,
            exam_id: proprty.id,
            sub_sections: proprty.sub_sections,
            all_sub_sections: proprty.sub_sections,
            over_all: '',
            percentage: this.props.examPercentage,
            visible: false
        })
    }
    for(const property of examsData){
      const findExam = examsOption.find(exam=>property?.title.toLowerCase().includes(exam.exam_type.toLowerCase()));
      if(findExam){
        findExam.over_all = property?.grade;
        findExam.visible = true;
      }
      
    }
    this.setState({exams: examsOption})
    
    for(const property of examsData){
      const findExam = examsOption.find(exam=>property?.title.toLowerCase().includes(exam.exam_type.toLowerCase()));
      if(findExam){
        selectExams.push({
        
          exam: {
            exam_type: findExam?.exam_type
          },
          exam_id: findExam?.exam_id,
          sub_sections: findExam.sub_sections,
          all_sub_sections: findExam.all_sub_sections,
          over_all: property?.grade,
          percentage: this.props.examPercentage,
          visible: true
        })
      }
      
    }
    this.setState({defaultExams: selectExams})
  //  
  }
  getIB(grade){
    if(grade){
      return grade?.split(' ')[0]
    }else{
      return ''
    }
  }

  getDeadline(date, startDates, deadlines){
    let dates = [];
    if(date){
      const checkDef = date?.includes('-');
      const checkOther = date?.includes(';');
      let newDate;
      if(checkDef){
        let rep = date.replaceAll('-', ',')
        newDate = rep
      }else if(checkOther){
        let rep = date.replaceAll(';', ',')
        newDate = rep
      }else {
        newDate = date
      }
      const check = newDate.split(',');
      
      for(const data of check){
        if(data.toLowerCase().includes('jan') || 
          data.toLowerCase().includes('feb') || 
          data.toLowerCase().includes('mar') || 
          data.toLowerCase().includes('apr') || 
          data.toLowerCase().includes('may') || 
          data.toLowerCase().includes('jun') || 
          data.toLowerCase().includes('aug') || 
          data.toLowerCase().includes('jul') || 
          data.toLowerCase().includes('sep') || 
          data.toLowerCase().includes('oct') || 
          data.toLowerCase().includes('nov') || 
          data.toLowerCase().includes('dec')){
          
            dates.push(moment(new Date(data.trim())).format('2023-MM-DD'))
          }
      }
    }
    
   
    let arr = [];


    if(startDates?.length == 1 &&  startDates?.includes('')){
      for(let i = 0; i< dates.length;i++){
        if(i>0){
          arr.push({
            id: '',
            start_date: '',
            deadline: dates[i]?dates[i]:''
          });
        }
      }
    }else{
      for(let i = 0; i< startDates.length;i++){
        if(i>0){
          if(arr[i]){
            arr[i].start_date = startDates[i];
            arr[i].deadline =  dates[i]?dates[i]:''
          }else{
            arr.push({
              id: '',
              start_date: startDates[i],
              deadline: dates[i]?dates[i]:''
            });
          }
          
        }
      }
    }
    if(deadlines?.length > 0 &&  !deadlines?.includes('')){
     
      for(let i = 0; i< deadlines.length;i++){
        
          console.log("deadlines2", arr)
          if(arr[i]){
            arr[i].deadline = deadlines[i+1];
          }
         
        
      }
    }
    
  
    
    this.setState({
      addDate: arr
    })
    
    return dates;
  }


  componentDidMount(){
    // window.scrollTo(0, 0)
    this.setState({deadlines:this.getDeadline(this.props?.programData?.deadline, this.props.startDates, this.props.deadlines)})
    

    if(this.props.programData?.a_level || this.props.programData.ib){
      this.setState({highSchoolExamVisible:true})
    }
    this.setExamsDeafult(this.props.programData?.exams)
    if(this.props.examsAll.length != 0){
      this.setState({defaultExams:this.props.examsAll})
    }


    // 
    const params ={
      id: '',
      description: 'a',
      name: this.props.programData?.name,
      program_format: this.getProgramFormat(this.props?.programData?.study_mode),
      user_id: '',
      lang_id: '1',
      active: '0',
      program_id: '',
      disciplines: this.props.programData?.discipline.replaceAll('\n', ''),
      olympiad:[],
      activity: [],
      sch_olympiad:[],
      sch_activity: [],
      exams: this.props.examsAll,
      program:{
          country_id: '', 
          state_id: '',
          city_id: '',
          institution_id: +this.props.institution_id,
          fee_amount: this.props.feeAmountAll != ''?this.props.feeAmountAll: this.getFee(this.props?.programData?.international_fee),
          fee_currency_id: this.props.currencyAll != ''?this.props.currencyAll : this.getCurrency(this.props?.programData?.international_fee),
          education_degree_id: this.props?.programData?.qualification_type,
          study_duration: this.props.studyDuration != ''?this.props.studyDuration: this.getDuration(this.props.programData?.duration),
          start_date: this.props.startDates[0],
          deadline: this.props.deadlines.length == 1 && this.props.deadlines.includes('') ? this.getDeadline(this.props?.programData?.deadline, this.props.startDates)?.length != 0? this.getDeadline(this.props?.programData?.deadline, this.props.startDates)[0]:'': this.props.deadlines[0],
          gpa: this.props.gpa,
          schoolarship_option: '',
          user_id: '',
          video_link: '',
          active: '0',
          education_language_id: this.props.eduLangid,
          application_fee: this.props.applicationFee,
          fee_type: this.props.feeType,
          schoolarship_type: 'amount',
          discipline: this.props.disciplineAll,
          ib_diploma: this.props.highSchExAll?.ib_diploma != ''?this.props.highSchExAll?.ib_diploma : this.getIB(this.props.programData?.ib),
          a_level: this.props.highSchExAll?.a_level != ''?this.props.highSchExAll?.a_level:this.props.programData?.a_level,
          advanced_placement:this.props.highSchExAll?.advanced_placement != ''?this.props.highSchExAll?.advanced_placement: '',
          ossd: this.props.highSchExAll?.ossd != ''?this.props.highSchExAll?.ossd :'',
          required_education_level: this.props.requiredEduDegree,
          olympiad:[],
          activity: [],
          ib_percentage:this.props.highSchExAll?.ib_percentage != ''?this.props.highSchExAll?.ib_percentage : this.props.gpaPercentage,
          ib_grade: '',
          a_level_percentage:this.props.highSchExAll?.a_level_percentage != ''?this.props.highSchExAll?.a_level_percentage : this.props.gpaPercentage,
          a_level_grade: null,
          ossd_percentage:this.props.highSchExAll?.ossd_percentage != ''?this.props.highSchExAll?.ossd_percentage: this.props.gpaPercentage,
          ossd_grade: '',
          exam_check: this.props.examCheckStatus,
          ap_percentage: this.props.highSchExAll?.ap_percentage != ''?this.props.highSchExAll?.ap_percentage : this.props.gpaPercentage,
          ap_grade: null,
          gpa_percentage: this.props.gpaPercentage,
          sch_requirements:{
            id: '',
            gpa: '',
            required_education_level: '0',
            ib_diploma: '',
            a_level: '',
            advanced_placement: '',
            ossd: '',
            a_level_grade: '',
            ossd_grade: '',
            ib_grade: '',
            ap_grade: '',
            a_level_percentage: 0,
            ossd_percentage: 0,
            ib_percentage: 0,
            ap_percentage: 0,
            gpa_percentage: 0,
          },
      },
      
    } 
    // this.props.changeStateValue({
    //   name: 'editProgram',
    //   value: params
    // })
    this.setState({editProgram:params})
    // this.props.selectAllPrograms(params, this.state.addDate)
    setTimeout(()=>{
      this.addToArrayBtnAll(params, this.state.addDate)
    }, 1000)

  }
  changeVisible(name, visible, e){
    this.setState({
      [name]: !visible
    })
  }

  saveChanges(data,dates, type,  e){

    if(
        data.name !== '' &&
        data.program_format !== '' &&
        data?.program?.gpa !== '' &&
        data?.program?.required_education_level !== '' &&
        data?.program?.start_date !== '' &&
        data?.program?.deadline !== '' &&
        // data?.program?.application_fee !== '' &&
        data?.program?.education_degree_id !== '' &&
        data?.program?.education_language_id !== '' &&
        // data?.program?.fee_amount !== '' &&
        data?.program?.fee_currency_id !== ''
    ){
      this.setState({
        btnVisible: true
      })
        const params = {...data};
        
        

        if(this.state.activityData.length !== 0){
        params.activity_json = JSON.stringify(this.state.activityData);
        }else{
        params.activity_json = undefined
        }
        if(this.state.olympiadData.length !== 0){
        params.olympiad_json = JSON.stringify(this.state.olympiadData);
        }else{
        params.olympiad_json = undefined
        }
        // params.
        params.user_id = this.props.user.id
       
        if(this.state.exams){
        params.json_exams = JSON.stringify(this.state.exams);

        }
        params.institution_id = +this.props.institution_id
           
        params.program_json = JSON.stringify(params.program)

        if(!this.state.setAsRequired){
          if(this.state.sch_activities.length !== 0){
            params.activity_sch_json = JSON.stringify(this.state.sch_activities);
          }else{
            params.activity_sch_json = undefined
          }
  
          if(this.state.sch_olympiads.length !== 0){
            params.olympiad_sch_json = JSON.stringify(this.state.sch_olympiads);
          }else{
            params.olympiad_sch_json = undefined
          }
          if(this.state.sch_exams){
            params.json_sch_exams = JSON.stringify(this.state.sch_exams);
          }
        }else{
          params.activity_sch_json =  params.activity_json
          params.olympiad_sch_json =  params.olympiad_json
          params.json_sch_exams =  params.json_exams
        }
        let formData = new FormData();
        for(const property in params){
            formData.append(property, params[property])
        }
        formData.append('setAsRequired', this.state.setAsRequired)

        if(dates.length !== 0){
          formData.append('dates', JSON.stringify(dates))
        }else{
          formData.append('dates', '')
        }
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Added it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.props.insertNewData('/programs', formData)
            .then(resp=>{
              this.setState({
                btnVisible: false
              })
                if(resp.msg === 'success'){
                  Swal.fire(
                    'Added!',
                    'Program Added successfully.',
                    'success'
                  )
                  let formData = new FormData()
                  formData.append('added_status', '1');
                  formData.append("_method", "put")
                  this.props.changeProgramStatus(`change-status/${this.props.programData?.id}`, formData)
                  .then(resp=>{
                    this.props.getAllRoboUniversity();
                  })
                    // if(type === 'dublicate'){
                    //   // this.props.navigate(`/dublicate-program/${resp.id}`)
                    // }else{
                    //   this.props.navigate(`/institution/${this.props.params.id}`)
                    //   this.props.changeStateValue({
                    //     name: 'editProgram',
                    //     value: ''
                    //   })
                    // }
                }

            })
            
          }
        })
        
    }
    
  }
  addToArrayBtn(data, dates, type, e){
    const params = {...data};
        if(this.state.activityData.length !== 0){
        params.activity_json = JSON.stringify(this.state.activityData);
        }else{
        params.activity_json = undefined
        }
        if(this.state.olympiadData.length !== 0){
        params.olympiad_json = JSON.stringify(this.state.olympiadData);
        }else{
        params.olympiad_json = undefined
        }
        // params.
        params.user_id = this.props.user.id
       
        if(this.state.defaultExams){
        params.json_exams = JSON.stringify(this.state.defaultExams);

        }
        params.institution_id = +this.props.institution_id
           
        params.program_json = JSON.stringify(params.program)

        if(!this.state.setAsRequired){
          if(this.state.sch_activities.length !== 0){
            params.activity_sch_json = JSON.stringify(this.state.sch_activities);
          }else{
            params.activity_sch_json = undefined
          }
  
          if(this.state.sch_olympiads.length !== 0){
            params.olympiad_sch_json = JSON.stringify(this.state.sch_olympiads);
          }else{
            params.olympiad_sch_json = undefined
          }
          if(this.state.sch_exams){
            params.json_sch_exams = JSON.stringify(this.state.sch_exams);
          }
        }else{
          params.activity_sch_json =  params.activity_json
          params.olympiad_sch_json =  params.olympiad_json
          params.json_sch_exams =  params.json_exams
        }

        let lastDataArr={
          params: params,
          setAsRequired: this.state.setAsRequired,
          id: this.props.programData.id
        }
        if(dates.length !== 0){
         
          lastDataArr={
            ...lastDataArr,
            dates:JSON.stringify(dates)
          }
        }else{
          lastDataArr={
            ...lastDataArr,
            dates: ''
          }
        }

      this.props.setDataToArr(lastDataArr)
  }
  addToArrayBtnAll(data, dates, type, e){
    const params = {...data};
        if(this.state.activityData.length !== 0){
        params.activity_json = JSON.stringify(this.state.activityData);
        }else{
        params.activity_json = undefined
        }
        if(this.state.olympiadData.length !== 0){
        params.olympiad_json = JSON.stringify(this.state.olympiadData);
        }else{
        params.olympiad_json = undefined
        }
        // params.
        params.user_id = this.props.user.id
       
        if(this.state.defaultExams){
        params.json_exams = JSON.stringify(this.state.defaultExams);

        }
        params.institution_id = +this.props.institution_id
           
        params.program_json = JSON.stringify(params.program)

        if(!this.state.setAsRequired){
          if(this.state.sch_activities.length !== 0){
            params.activity_sch_json = JSON.stringify(this.state.sch_activities);
          }else{
            params.activity_sch_json = undefined
          }
  
          if(this.state.sch_olympiads.length !== 0){
            params.olympiad_sch_json = JSON.stringify(this.state.sch_olympiads);
          }else{
            params.olympiad_sch_json = undefined
          }
          if(this.state.sch_exams){
            params.json_sch_exams = JSON.stringify(this.state.sch_exams);
          }
        }else{
          params.activity_sch_json =  params.activity_json
          params.olympiad_sch_json =  params.olympiad_json
          params.json_sch_exams =  params.json_exams
        }

        let lastDataArr={
          params: params,
          setAsRequired: this.state.setAsRequired,
          id: this.props.programData.id
        }
        if(dates.length !== 0){
         
          lastDataArr={
            ...lastDataArr,
            dates:JSON.stringify(dates)
          }
        }else{
          lastDataArr={
            ...lastDataArr,
            dates: ''
          }
        }

      this.props.selectAllPrograms(lastDataArr)
  }
  setExams = (data, name)=>{
    this.setState({
      [name]: data
    })
  }

  setExtraActivity = (name, value) => {
    this.setState({
      [name]: value
    })
  }
  getOtherData = (type, id) =>{
    
    if(type === 'required'){
        if(id == 1 || id == 2){
            this.setState({
                highSchoolExamVisible: true
            })
        }else{
            this.setState({
                highSchoolExamVisible: false
            })
            this.changeInpValue('editProgram.program.ib_percentage', 0)
            this.changeInpValue('editProgram.program.ap_percentage', 0)
            this.changeInpValue('editProgram.program.ossd_percentage', 0)
            this.changeInpValue('editProgram.program.a_level_percentage', 0)
            this.changeInpValue('editProgram.program.ossd', '')
            this.changeInpValue('editProgram.program.advanced_placement', '')
            this.changeInpValue('editProgram.program.a_level', '')
            this.changeInpValue('editProgram.program.ib_diploma', '')
        }
    }else if(type === 'sch_required'){
      this.changeInpValue('editProgram.program.sch_requirements.ib_percentage', 0)
      this.changeInpValue('editProgram.program.sch_requirements.ap_percentage', 0)
      this.changeInpValue('editProgram.program.sch_requirements.ossd_percentage', 0)
      this.changeInpValue('editProgram.program.sch_requirements.a_level_percentage', 0)
      this.changeInpValue('editProgram.program.sch_requirements.ossd', '')
      this.changeInpValue('editProgram.program.sch_requirements.advanced_placement', '')
      this.changeInpValue('editProgram.program.sch_requirements.a_level', '')
      this.changeInpValue('editProgram.program.sch_requirements.ib_diploma', '')
  
  }
  }

  addDateBtn(data, e){
    let arr = [...data];
    arr.push({
      id: '',
      start_date: '',
      deadline: ''
    });
    this.setState({
      addDate: arr
    })
  }

  removeDate(data,index, e){
    let arr = [...data];
    arr[index].deleted = true;
    arr[index].start_date = '';
    arr[index].deadline = '';
    this.setState({
      addDate: arr
    })
  }

  setOtherDate(index, data, type, e){
    let arr = [...data];
    arr[index][type] = e.target.value;
   
    this.setState({
      addDate: arr
    })
  }

  setAsRequired(e){
    this.setState({
      setAsRequired: e.target.checked
    })
  
  }
  changeExamCheck(value){
    
  this.changeInpValue('editProgram.program.exam_check', value)
}
getPlaceholder(array, id, placeholder){
  if(id && array.length !== 0){
      if(array.find(data=> data.value == id)){
          return array.find(data=> data.value == id).label
      }else{
          return placeholder
      }
  }else{
      return placeholder
  }
}
changeInpValue = (name, value) =>{
  let obj = {...this.state.editProgram};
  const check = name.includes('.');
  if(check){
    const groups = name.split('.');
    if(groups.length == 2){
      obj[groups[1]] = value
    }else if(groups.length == 3){
      obj[groups[1]][groups[2]] = value
    }
    
  }else{
    obj[name] = value
  }
  // this.props.selectAllPrograms(obj, this.state.addDate)
  this.addToArrayBtnAll(obj, this.state.addDate)
  this.setState({
    editProgram:obj
  })
}

  selectAllData=()=>{
    return 'salam'
  }
  render() {
    const {part1Visible, part2Visible, part3Visible, highSchoolExamVisible, btnVisible, addDate, part4Visible, setAsRequired, editProgram, exams, defaultExams, deadlines} = this.state;
    const {cardVisibile,allUploadProg, educationDegree, educationLanguage, currency, extraCurricularActivity, internationalMedals, unirepRoleTranslate, selectAllPrograms} = this.props;
    const degreeOption = [];
    const languageOption = [];
    const currencyOption=[];
    const activityOption=[];
    const olympiadOption=[];
    degreeOption.push({
      label: 'Clear',
      value: ''
    })
    for(const property of internationalMedals){
      olympiadOption.push({
        label: property?.title,
        value: property?.olympiad_id
      })
    }
    for(const property of extraCurricularActivity){
      activityOption.push({
        label: property?.title,
        value: property?.activity_id
      })
    }
    for(const property of currency){
      currencyOption.push({
        label: property?.currency,
        value: property?.id
      })
    }
   
    for(const property of educationDegree){
      degreeOption.push({
        label: property?.education_type,
        value: property?.education_degree_id
      })
    }
    for(const property of educationLanguage){
      languageOption.push({
        label: property?.language,
        value: property?.education_language_id
      })
    }
    let program_format = [
      {
        label: unirepRoleTranslate?.online,
        value: unirepRoleTranslate?.online
      },
      {
          label: unirepRoleTranslate?.full_time,
          value: unirepRoleTranslate?.full_time
      },
      {
          label: unirepRoleTranslate?.part_time,
          value: unirepRoleTranslate?.part_time
      },
      {
          label: unirepRoleTranslate?.nsu,
          value: unirepRoleTranslate?.nsu
      },
  ]
  let fee_type_options = [
    
    {
      label: unirepRoleTranslate?.yearly,
      value: '0'
    },
    {
      label: unirepRoleTranslate?.monthly,
      value: '1'
    },
  ]
  let scholarship_type =[
    {
        label: unirepRoleTranslate?.in_amount,
        value: 'amount'
    },
    {
        label: unirepRoleTranslate?.in_percentage,
        value: 'percentage'
    }
  ];



    return (
      <div  className={`nc-PageAddListing1  max-w-6xl mx-auto  `}  data-nc-id="PageAddListing1"  >
        <button style={{ backgroundColor: allUploadProg.find(item=>item.id == this.props.programData.id)?'green':'' }} onClick={this.addToArrayBtn.bind(this, editProgram, addDate)} className='border rounded-2xl text-sm bg-blue-700 text-white p-2 cursor-pointer'>
          {
            allUploadProg.find(item=>item.id == this.props.programData.id)?
            'Selected':'Select'
          } 
            
        </button>
        {
          cardVisibile?
          
      
       <div>
       {
          editProgram != ''?
          <div className="space-y-3">
            
            <div className="listingSection__wrap ">
              <div className={`listingSection__wrap ${!part1Visible? ' h-5 pt-2':''}`}>
                <div onClick={this.changeVisible.bind(this, 'part1Visible', part1Visible)}>
                  <h3>{unirepRoleTranslate?.part_1}</h3>
                </div>
                {
                  part1Visible?
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5 justify-between">
                      <Input changeInpValue={this.changeInpValue} name="editProgram.name" value={editProgram?.name} placeholder={unirepRoleTranslate?.name} label={unirepRoleTranslate?.name} />
                      <DropDown changeInpValue={this.changeInpValue} zIndex={101} type={'type'} name="editProgram.program.education_degree_id" option={degreeOption} placeholder={this.getPlaceholder(degreeOption, editProgram.program.education_degree_id, unirepRoleTranslate?.education_degree )} label={unirepRoleTranslate?.education_degree} />  
                    </div>
                    {/* <MultiSelect editProgram={editProgram} changeInpValue={this.changeInpValue} translate={unirepRoleTranslate?.disciplines} /> */}
                    <div className='mt-3 mb-3'>
                      <span className='text-sm font-semibold'>Disciplines</span>
                      <p className='border rounded-xl p-2'>{editProgram?.disciplines}</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
                  
                      <DropDown changeInpValue={this.changeInpValue} type={'type'} name="editProgram.program_format" option={program_format} placeholder={this.getPlaceholder(program_format, editProgram.program_format, unirepRoleTranslate?.program_format )} label={`${unirepRoleTranslate?.program_format} (${this.props.programData?.study_mode})`} />
                      <DropDown changeInpValue={this.changeInpValue} type={'type'} name="editProgram.program.education_language_id" option={languageOption} placeholder={this.getPlaceholder(languageOption, editProgram.program.education_language_id, unirepRoleTranslate?.education_language )} label={unirepRoleTranslate?.education_language} />
                      {/* <InputSelect /> */}
                      <StudyDuration editProgram={editProgram} changeInpValue={this.changeInpValue} value={editProgram?.program?.study_duration} />
                      <Input changeInpValue={this.changeInpValue} name="editProgram.program.start_date" value={editProgram?.program?.start_date} type="date" placeholder={unirepRoleTranslate?.start_date} label={unirepRoleTranslate?.start_date} />
                      <Input changeInpValue={this.changeInpValue} name="editProgram.program.deadline" value={editProgram?.program?.deadline } type="date" placeholder={unirepRoleTranslate?.deadline} label={unirepRoleTranslate?.deadline} />
                      {
                        addDate?.length >1?
                        <button onClick={this.addDateBtn.bind(this, addDate)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 mt-8
                                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '19px', color: 'white' ,  width: '25px', height: '25px' }}>
                        +
                        </button>:null
                      }
                     
                        {
                          addDate.map((data, i)=>{
                            if(!data.deleted){
                              return(
                                < >
                                <div key={i+Math.random()}>
                                  <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                                  {unirepRoleTranslate?.start_date}
                                  </label>
                                  <input value={data.start_date}  onChange={this.setOtherDate.bind(this, i, addDate, 'start_date')}  type={'date'}
                                      className={`block w-full border-neutral-200 focus:border-primary-300 
                                      focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                                      dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                                      dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                                      
                                  /> 
                                </div>
                                <div  key={i+Math.random()}>
                                  <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                                  {unirepRoleTranslate?.deadline}
                                  </label>
                                  <input value={data.deadline} onChange={this.setOtherDate.bind(this, i, addDate, 'deadline')} type={'date'}
                                      className={`block w-full border-neutral-200 focus:border-primary-300 
                                      focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                                      dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                                      dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                                      
                                  /> 
                              </div>
                              <button  key={i+Math.random()} onClick={this.removeDate.bind(this, addDate, i)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 mt-8
                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '19px', color: 'white' ,  width: '25px', height: '25px' }}>
                                -
                                </button>
                                </>
                              )
                            }
                           
                          })
                        }
                     
                      {/* <InputPercentage /> */}
                      
                    </div>
                  </div>:null
                }
                
              </div>
              <div className={`listingSection__wrap ${!part2Visible? ' h-5 pt-2':''}`}>
                <div onClick={this.changeVisible.bind(this, 'part2Visible', part2Visible)}>
                <h3 className='flex gap-5 items-center'>{unirepRoleTranslate?.part_2} 
                  <div className='flex gap-2 items-center'>
                    <input onChange={this.changeExamCheck.bind(this, editProgram?.program?.exam_check==1?0:1 )} type='checkbox' id='examCheckTypeBtn' defaultChecked={editProgram?.program?.exam_check == 1? true:false} />
                    <label htmlFor='examCheckTypeBtn'>Exam Requirement</label>
                  </div>
                  </h3>
                </div>
                {
                  part2Visible?
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12 md:gap-2">
                      <DropDown changeInpValue={this.changeInpValue} zIndex={1}  getOtherData={this.getOtherData} type={'required'} name="editProgram.program.required_education_level" option={degreeOption} placeholder={this.getPlaceholder(degreeOption, editProgram.program.required_education_level, unirepRoleTranslate?.required_edu_degree )} label={unirepRoleTranslate?.required_edu_degree} />  
                      <InputPercentage
                        label={unirepRoleTranslate?.required_gpa}
                        value={editProgram?.program?.gpa}
                        placeholder={unirepRoleTranslate?.required_gpa}
                        name="editProgram.program.gpa"
                        perValue={editProgram?.program?.gpa_percentage}
                        perName={'editProgram.program.gpa_percentage'}
                        changeInpValue={this.changeInpValue}
                      /> 
                     <ExamsInput examPercentage={this.props.examPercentage} value={[...defaultExams]} name="exams" setExams={this.setExams} />
                    
                    </div>
                    <div  className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-12 mt-5">
                      <SelectBox editProgram={editProgram} setExtraActivity={this.setExtraActivity} name="activityData" options={activityOption} label={unirepRoleTranslate?.extra_act} />
                      <SelectBox editProgram={editProgram} setExtraActivity={this.setExtraActivity} name="olympiadData" options={olympiadOption} label={unirepRoleTranslate?.olymp_medal} />
                    </div>
                    <div className="w-auto border-b border-neutral-200 dark:border-neutral-700 mt-5"></div>

                    {
                        highSchoolExamVisible && editProgram?.program?.exam_check == 1?
                        <HighSchoolExams aLevelGrade={this.props.programData?.a_level} changeInpValue={this.changeInpValue} editProgram={editProgram}/>:null
                    }
                    
                  </div>:null
                }
                
              </div>
              <div className={`listingSection__wrap ${!part3Visible? ' h-5 pt-2':''}`}>
                <div onClick={this.changeVisible.bind(this, 'part3Visible', part3Visible)}>
                  <h3>{unirepRoleTranslate?.part_3}</h3>
                </div>
                {
                  part3Visible ?
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-12">
                      <DropDown changeInpValue={this.changeInpValue} type={'type'} name="editProgram.program.fee_currency_id" option={currencyOption} placeholder={this.getPlaceholder(currencyOption, editProgram.program.fee_currency_id, unirepRoleTranslate?.currency )} label={unirepRoleTranslate?.currency} />  
                      <InputSelect 
                        label={unirepRoleTranslate?.fee_amount}
                        value={editProgram?.program?.fee_amount}
                        name="editProgram.program.fee_amount"
                        placeholder={unirepRoleTranslate?.fee_amount}
                        selectValue={editProgram?.program?.fee_type}
                        selectName={'editProgram.program.fee_type'}
                        options={fee_type_options}
                        changeInpValue={this.changeInpValue}
                      />
                    </div>
                    <div className="grid grid-cols-1 mt-5 md:grid-cols-2 gap-12 md:gap-12">
                      <InputSelect 
                        label={unirepRoleTranslate?.schoolarship_option}
                        value={editProgram?.program?.schoolarship_option}
                        name="editProgram.program.schoolarship_option"
                        placeholder={unirepRoleTranslate?.schoolarship_option}
                        selectValue={editProgram?.program?.schoolarship_type}
                        selectName={'editProgram.program.schoolarship_type'}
                        options={scholarship_type}
                        changeInpValue={this.changeInpValue}
                      />
                      <Input changeInpValue={this.changeInpValue} name="editProgram.program.application_fee" value={editProgram?.program?.application_fee} type="number" placeholder={unirepRoleTranslate?.application_fee} label={unirepRoleTranslate?.application_fee} />
                    </div>
                  </div>:null
                }
                
                
              </div>
              <div className={`listingSection__wrap ${!part4Visible? ' h-5 pt-2':''}`}>
                <div className='flex items-center gap-10' onClick={this.changeVisible.bind(this, 'part4Visible', part4Visible)}>
                  <h3>part 4</h3>
                 <div className='flex gap-4 items-center'>
                  <label htmlFor='setAsRequired'>Same with required</label>
                  <input onChange={this.setAsRequired.bind(this)} type={'checkbox'} id="setAsRequired" />
                 </div>
                </div>
                {
                  part4Visible && !setAsRequired?
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12 md:gap-2">
                      <DropDown changeInpValue={this.changeInpValue} zIndex={1}  getOtherData={this.getOtherData} type={'sch_required'} name="editProgram.program.sch_requirements.required_education_level" option={degreeOption} placeholder={unirepRoleTranslate?.required_edu_degree} label={unirepRoleTranslate?.required_edu_degree} />  
                      <InputPercentage
                        label={unirepRoleTranslate?.required_gpa}
                        value={editProgram?.program?.sch_requirements.gpa}
                        placeholder={unirepRoleTranslate?.required_gpa}
                        name="editProgram.program.sch_requirements.gpa"
                        perValue={editProgram?.program?.sch_requirements.gpa_percentage}
                        perName={'editProgram.program.sch_requirements.gpa_percentage'}
                        changeInpValue={this.changeInpValue}
                      /> 
                     <ExamsInput value={[]} name="sch_exams" setExams={this.setExams} />
                    
                    </div>
                    <div  className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-12 mt-5">
                      <SelectBox setExtraActivity={this.setExtraActivity} name="sch_activities" options={activityOption} label={unirepRoleTranslate?.extra_act} />
                      <SelectBox setExtraActivity={this.setExtraActivity} name="sch_olympiads" options={olympiadOption} label={unirepRoleTranslate?.olymp_medal} />
                    </div>
                    <div className="w-auto border-b border-neutral-200 dark:border-neutral-700 mt-5"></div>

                    {
                         editProgram.program.sch_requirements.required_education_level == 1 || editProgram.program.sch_requirements.required_education_level == 2?
                        <HighSchoolSchExams changeInpValue={this.changeInpValue} editProgram={editProgram}/>:null
                    }
                    
                  </div>:null
                }
                
              </div>
            </div>
            <div className="flex justify-between items-center" >
                    
                    {/* <Link  to={`/institution/${this.props.params.id}`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                    {unirepRoleTranslate?.back}
                    </Link>
                    <button  disabled={btnVisible} onClick={this.saveChanges.bind(this, editProgram,addDate, 'dublicate')} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                    {unirepRoleTranslate?.submit_clone}
                    </button> */}
                    <button disabled={btnVisible}  onClick={this.saveChanges.bind(this, editProgram, addDate)}  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                    {unirepRoleTranslate?.submit}
                    </button>
                </div>
        </div>:null
        }
       </div>:null}
        
      </div>
    )
  }
}

const mapStateToProps = (state)=>({
  editProgram: state.Data.editProgram,
  educationDegree: state.Data.educationDegree,
  languageId: state.Data.languageId,
  educationLanguage: state.Data.educationLanguage,
  currency: state.Data.currency,
  internationalMedals: state.Data.internationalMedals,
  extraCurricularActivity: state.Data.extraCurricularActivity,
  studyDurationValue: state.Data.studyDurationValue,
  user: state.Data.user,
  unirepRoleTranslate: state.Data.unirepRoleTranslate,
  allExams: state.Data.allExams,

})
const mapDispatchToprops = {changeStateValue, 
  getEducationDegree, 
  getEducationLanguage, 
  getCurrency, 
  getExtraCurricularActivity,
   getInternationalMedals, 
   insertNewData,
   getUnirepRoleTranslate,
   mainDashboardTranslate,
   changeProgramStatus, getAllRoboUniversity
  }
export default connect(mapStateToProps, mapDispatchToprops)(withParams(CardBody))
