import React, { Component } from 'react'
import defuser from "../../images/user.jpg"
import CardLine from './CardLine'
import CardPhoneSvg from '../svg/CardPhoneSvg'
import CardEmailSvg from '../svg/CardEmailSvg'
import CardEditSvg from '../svg/CardEditSvg'
import { Link } from 'react-router-dom'
import CardMoreSvg from '../svg/CardMoreSvg'
import EduSvg from '../../pagess/edu_consultant_pages/students/student_info/EduSvg'
import RejectSvg from './RejectSvg'
export class UserCard extends Component {
  render() {
    const {name, image, phone="", email="", onClickEdit=()=>{},onClickEditBg=()=>{}, info=false, blank=false, rejectBtn=false, onClickReject=()=>{}, rejectStatus=0} = this.props
    return (
      <div className={`bg-white rounded-[20px] shadow-sm border border-gray-100`}>
        {
          rejectBtn?
          <div className='flex justify-end items-center'>
            <button onClick={()=>onClickReject()} className='mr-[10px] mt-[10px]'>
              <RejectSvg color={rejectStatus==0?"#333":"red"} />
            </button>
          </div>:null
        }
       
         <div className='flex flex-col justify-center items-center mt-[20px]'>
            <img className='w-[61px] h-[61px] rounded-full' src={!image || image === ""? defuser:image} alt={name} />
            <h3 className='font-semibold text-[14px] mt-[10px] txt-light-black'>{name}</h3>
        </div>
        <div className='flex ml-[20px] flex-col mt-[14px] gap-[9px] pr-[20px]'>
        {
            email !== ""?
            <CardLine rounded={true} svg={<CardEmailSvg />} title={email} />:null
        }
        {
            phone !== ""?
            <CardLine rounded={true} svg={<CardPhoneSvg />} title={phone} />:null
        }
        </div>
        <div className='h-[1px] w-full bg-gray-200 mt-[27px]'></div>
        <div className='flex'>
            <button onClick={()=>onClickEditBg()} className='w-[50%] h-[38px] border-r inline-flex items-center justify-center'>
                <EduSvg />
              </button>
            <button onClick={()=>onClickEdit()} className='w-[50%] h-[38px] border-r inline-flex items-center justify-center'>
                <CardEditSvg />
              </button>
            {
                info?
                <Link target={blank?"_blank":""} to={info}  className='w-[50%] h-[38px]  inline-flex items-center justify-center'><CardMoreSvg /> </Link>:null
            }
        </div>
      </div>
    )
  }
}

export default UserCard
