import React, { Component } from 'react'

export class CountryHeaderImg extends Component {
  render() {
    const {title, description, image,} = this.props;

    return (
        <div className='container'>
            <div className='header_img_container'>
            <img className='header_img_blur' alt={title} src={image} />
            <img className='header_img' alt={title} src={image} />
            <div className='shadow'>
            <div style={{ justifyContent: 'center' }}  className="absolute  w-full md:flex md:items-start  md:justify-start bottom-10 flex justify-center text-neutral-500 cursor-pointer z-10" id='header_title'
              >
                <div className='px-9 py-6 rounded-xl bg-neutral-100 bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 ' >
                    <h1 style={{ fontSize: '30px', fontWeight: '600', lineHeight: '30px' }} className="ml-auto  text-neutral-800 text-sm " >
                        {title}
                        </h1>
                   
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
  }
}

export default CountryHeaderImg