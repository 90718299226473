import React, { Component } from 'react'

import StudentCard from './StudentCard'

export class Students extends Component {
  render() {
    const {students} = this.props
    return (
        <div  className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
        {
            students.map((data, i)=>{
                return(
                    <StudentCard
                        key={i}
                        data={data}
                    />
                )
            })
            }
        </div>
    )
  }
}

export default Students
