import React, { Component, Fragment } from 'react'
import { Popover, Transition } from "@headlessui/react";
import CheckBox from './CheckBox';
import { connect } from 'react-redux';
import { getTracings, setStudentTracking, getStudentTracking } from '../../../../actions/WorkerActions'; 
import { useParams } from 'react-router-dom';
function withParams(Component){
    return props => <Component {...props} params={useParams()} />
}
export class TrackingBtn extends Component {
    state={
        position: false,
        activeBtn: 'applications',
        applications: [],
        accomadation: [],
        visa: [],
        applicationsPosition: false,
        accomadationPosition: false,
        visaPosition: false,
        manualRender: 0
    }
    openModal(){
        this.setState({
          position:true
        })
        
    }
    changeBtn(type, e){
        this.setState({
            activeBtn: type
        })
    }

    componentDidMount(){
        this.props.getTracings(this.props.languageId)
    }

    setData = (data, type) =>{
       
        this.setState({
            [type]:data,
            [type+'Position']:true,
        })
       

    }
    applyData(type, e){
        if(this.state[type].length !== 0){
           
            let formData = new FormData();
            formData.append('type', type);
            formData.append('student_id', this.props.params.id?this.props.params.id:this.props.student_id);
            formData.append('program_id', this.props.program_id);
            formData.append('data', JSON.stringify(this.state[type]));
           
            this.props.setStudentTracking(formData)
            .then(resp=>{
                this.props.getStudentTracking(this.props.params.id?this.props.params.id:this.props.student_id,this.props.languageId, this.props.program_id)
                this.setState({
                    applications: [],
                    accomadation: [],
                    visa: [],
                    applicationsPosition: false,
                    accomadationPosition: false,
                    visaPosition: false,
                })
            })
        }
    }

    clearAllData(type, array, e){
        if(array.length !== 0){
            array.map((data)=>{
                data.deleted = true
            })
            let formData = new FormData();
            formData.append('type', type);
            formData.append('student_id', this.props.params.id?this.props.params.id:this.props.student_id);
            formData.append('program_id', this.props.program_id);
            formData.append('data', JSON.stringify(array));
            this.props.setStudentTracking(formData).then(resp=>{
                this.props.getStudentTracking(this.props.params.id?this.props.params.id:this.props.student_id,this.props.languageId, this.props.program_id)
                this.setState({
                    applications: [],
                    accomadation: [],
                    visa: [],
                    applicationsPosition: false,
                    accomadationPosition: false,
                    visaPosition: false,
                })
            })

            this.setState({
                manualRender:1
            })
            setTimeout(()=>{
                this.setState({
                    manualRender:0
                })
            }, 100)
        }

       
    }
  render() {
   const { position, activeBtn, visaPosition, accomadationPosition, applicationsPosition, manualRender} = this.state;
    const {trackingData, studentTrackings, user, unirepRoleTranslate, program_id} = this.props;

    let filterData = trackingData[activeBtn];
    let cehckBoxtitle = unirepRoleTranslate?.select_app;
    let trackingLine = []
    let betweenLine = []
    if(activeBtn == 'applications'){
        cehckBoxtitle = unirepRoleTranslate?.select_app;
        
    }else if(activeBtn == 'accomadation'){
        cehckBoxtitle = unirepRoleTranslate?.select_acc;
    }else if(activeBtn == 'visa'){
        cehckBoxtitle = unirepRoleTranslate?.select_visa;
    }
    let checkBoxType = activeBtn;
    if(this.state[activeBtn].length == 0 && studentTrackings[activeBtn] && !this.state[activeBtn+'Position']){
        studentTrackings[activeBtn].forEach(item=>{
            betweenLine.push(item.track_id)
            trackingLine.push({
                id: item.id,
                program_id: item.program_id,
                student_id: item.student_id,
                track_id: item.track_id,
            })
        })
    }else{
        trackingLine = [...this.state[activeBtn]]
        this.state[activeBtn].map((data, i)=>{
            if(!data.deleted){
                betweenLine.push(data.track_id)
            }
        })
    }

    // console.log('ss',manualRender)
    return (
        <Popover >
                
            <Popover.Button 
            
            onClick={this.openModal.bind(this)}
            className={`flex items-center justify-between px-4  w-full h-11 py-2 mr-0 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
            position ? "!border-primary-500 " : ""
            }`}
            >
            <span>{unirepRoleTranslate?.tracking}</span>
            <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
        
            {
            position?
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
            <div id='popover_container'> 
            <Popover.Panel className=" z-10 w-screen max-w-sm px-4 mt-3  sm:px-0 lg:max-w-md">
                
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                    <div className='flex p-1 space-x-1 bg-primary-900/10 rounded-xl mt-1 ml-1 mr-1'>
                        <button onClick={this.changeBtn.bind(this, 'applications')}
                        className={`w-full py-2.5 text-sm leading-5 font-medium text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                        ${
                            activeBtn == 'applications'?
                            'bg-white dark:bg-neutral-800':
                            ' hover:bg-white/[0.15] dark:hover:bg-neutral-800'
                        }
                        
                        `}
                        aria-label="unirepRoleTranslate.applications"
                        >{unirepRoleTranslate?.applications} </button>
                        <button onClick={this.changeBtn.bind(this, 'accomadation')}
                        className={`w-full py-2.5 text-sm leading-5 font-medium text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                        ${
                            activeBtn == 'accomadation'?
                            'bg-white dark:bg-neutral-800':
                            ' hover:bg-white/[0.15] dark:hover:bg-neutral-800'
                        }
                        `}
                        aria-label="unirepRoleTranslate.accomadation"
                        >{unirepRoleTranslate?.accomadation}</button>
                        <button onClick={this.changeBtn.bind(this, 'visa')}
                        className={`w-full py-2.5 text-sm leading-5 font-medium text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                        ${
                            activeBtn == 'visa'?
                            'bg-white dark:bg-neutral-800':
                            ' hover:bg-white/[0.15] dark:hover:bg-neutral-800'
                        }
                        `}
                        aria-label="unirepRoleTranslate.visa"
                        >{unirepRoleTranslate?.visa}</button>
                    </div>
                    {
                        user !== '' &&user.role == 'uni_rep' && manualRender ==0|| user !== '' &&user.role == 'edu_consultant' && manualRender ==0?
                        <CheckBox student_id={this.props.params.id?this.props.params.id:this.props.student_id} program_id={program_id} array={filterData} title={cehckBoxtitle} type={checkBoxType} defaultData={trackingLine} setData={this.setData} />
                        :null
                    }
                    <div className="relative flex flex-col px-5 py-6 space-y-5 " style={{ maxHeight: '300px', overflow: 'auto', minHeight: '200px' }}>
                        <ul className='track_container' >
                            {filterData?
                                filterData.map((data, i)=>{
                                    if(trackingLine.find(line=> +line.track_id == data.track_id)){
                                        if(!trackingLine.find(line=> +line.track_id == data.track_id).deleted){
                                            return (
                                                <div  key={i}>
                                                 <li className='track_title'><span className='tranck_point active_point'></span>{data.title}</li>
                                                 {data.track_id != Math.max(...betweenLine) && !trackingLine.find(line=> +line.track_id == data.track_id).deleted?
                                                 <span className='track_vertical active_line'></span> :null }
                                                </div>
                                             )
                                        }
                                    }
                                }):null
                            }
                           
                        </ul>
                    </div>
                    {
                         user !== '' &&user.role == 'uni_rep' || user !== '' &&user.role == 'edu_consultant'?
                         <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                        
                            <button aria-label='unirepRoleTranslate.clear' onClick={this.clearAllData.bind(this, checkBoxType,trackingLine)} className='ttnc-ButtonThird text-neutral-700 border 
                                                border-neutral-200 dark:text-neutral-200 
                                                dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                                items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                                px-4 py-2 sm:px-5'   >
                                {unirepRoleTranslate?.clear}
                            </button>
                            <button aria-label='unirepRoleTranslate.apply' onClick={this.applyData.bind(this, checkBoxType)} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-2 sm:px-5  '>
                                {unirepRoleTranslate?.apply}
                            </button>
                        </div>:null
                    }
                    
                </div>
            </Popover.Panel></div> 
            </Transition>:null
            }
            

    </Popover>
    )
  }
}
const mapStateToProps = (state) =>({
    trackingData: state.Data.trackingData,
    languageId: state.Data.languageId,
    studentTrackings: state.Data.studentTrackings,
    user: state.Data.user,
    unirepRoleTranslate: state.Data.unirepRoleTranslate
});
const mapDispatchToProps = {getTracings, setStudentTracking, getStudentTracking}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(TrackingBtn))