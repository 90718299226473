import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

export class ViewAllBtn extends Component {
  render() {
    const {href = '/', mainTransateData} = this.props;
    return (
        <div className="flex items-center justify-between mt-10">
            <span className="hidden sm:block flex-shrink-0 ml-auto mr-auto">
                <Link to={href} className='ttnc-ButtonSecondary font-medium border 
                                    bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 
                                    dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800
                                    !leading-none nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-neutral-700 dark:text-neutral-200 px-4 py-3 sm:px-6 text-sm sm:text-base font-medium
                                    '>
                    <span aria-label='mainTransateData.view_all'>{mainTransateData?.view_all}</span>
                    <i className="ml-3 las la-arrow-right text-xl"></i>
                </Link>
            </span>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  mainTransateData: state.Data.mainTransateData
})
export default connect(mapStateToProps)(ViewAllBtn)