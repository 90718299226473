import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getStudentDestinations, addNewData } from '../../../actions/StudentActions';
import { getAllCountries, getAllDisciplines } from '../../../actions/MainAction';
import DropDowns from './DropDowns';
export class Destination extends Component {
    state={
        countryState: [],
        disciplineState: [],
        positionCountry: false,
        positionDiscipline: false,
        buttonVisible: false
    }
    componentDidMount(){
        this.props.getStudentDestinations(this.props.student_id)
        this.props.getAllCountries('', '', this.props.languageId)
        this.props.getAllDisciplines('', '', this.props.languageId)
    }
    getOtherData = (id, type, data) =>{
        const check = data.find(item=> item == id);
        if(!check){
            data.push(id)
        }
        this.setState({
            [type]: data,
        })
       
    }

    removeItem(id, data, type, position, e){
        const check = data.findIndex(item => item == id);
        data.splice(check, 1);
      
        this.setState({
            [type]: data,
            [position]: true
        })
        
    }

    saveChanges(disciplines, countries, e){
        this.setState({
            buttonVisible: true
          });
          let formData = new FormData();
          formData.append('student_id', this.props.student_id)
          formData.append('countries', countries)
          formData.append('disciplines', disciplines)
          let url = `update-destinations`;
           
          this.props.addNewData(url, formData)
          .then(resp=>{
              this.setState({
                  buttonVisible: false
              });
              this.props.getStudentDestinations(this.props.student_id);
    
          })
    }
  render() {
    const {studentDestinations, allCountries, allDisciplines, unirepRoleTranslate} = this.props;
    const {countryState, disciplineState, positionCountry, positionDiscipline, buttonVisible} = this.state;
    const countriesOption = [];
    const disciplineOption = [];
 
    let selectedCountry = []
    let selectedDiscipline = []
    for(const item of allCountries){
        countriesOption.push({
            label: item.name,
            value: item.countries_id,
        })
    }
    for(const item of allDisciplines){
        disciplineOption.push({
            label: item.name,
            value: item.discipline_id,
        })
    }

    if(countryState.length == 0 && studentDestinations.destination_countres.length != 0 && !positionCountry){
        studentDestinations.destination_countres.map(data=>{
            selectedCountry.push(data.country_id)
        })
    }else if(countryState.length != 0){
        selectedCountry = [...countryState]
    }

    if(disciplineState.length == 0 && studentDestinations.destination_disciplines.length != 0 && !positionDiscipline){
        studentDestinations.destination_disciplines.map(data=>{
            selectedDiscipline.push(data.discipline_id)
        })
    }else if(disciplineState.length != 0){
        selectedDiscipline = [...disciplineState]
    }
    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5 justify-between mt-5">
                <div>
                    <DropDowns 
                        ariaLabel="unirepRoleTranslate.dest_country"
                        placeholder = {unirepRoleTranslate?.dest_country}
                        label = {unirepRoleTranslate?.dest_country}
                        option={countriesOption}
                        name="exams"
                        type="countryState"
                        getOtherData={this.getOtherData}
                        zIndex={100}
                        data={selectedCountry}
                    />
                    <div className='mt-5'>
                        {
                            allCountries.map((data, i)=>{
                                if(selectedCountry.find(item=> item == data.countries_id)){
                                    return(
                                        <div key={i} className='flex justify-between mt-3'>
                                            <span>{data.name}</span>
                                            <svg onClick={this.removeItem.bind(this, data.countries_id, selectedCountry, 'countryState', 'positionCountry')} style={{ cursor: 'pointer' }} width="10" height="10" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.32867 7.50003L14.8284 1.00029C15.0572 0.771452 15.0572 0.400437 14.8284 0.171628C14.5995 -0.0571802 14.2285 -0.0572095 13.9997 0.171628L7.49998 6.67137L1.00029 0.171628C0.77145 -0.0572095 0.400436 -0.0572095 0.171628 0.171628C-0.05718 0.400466 -0.0572093 0.771482 0.171628 1.00029L6.67133 7.5L0.171628 13.9997C-0.0572093 14.2286 -0.0572093 14.5996 0.171628 14.8284C0.286032 14.9428 0.436002 15 0.585972 15C0.735943 15 0.885884 14.9428 1.00032 14.8284L7.49998 8.32869L13.9997 14.8284C14.1141 14.9428 14.2641 15 14.414 15C14.564 15 14.7139 14.9428 14.8284 14.8284C15.0572 14.5996 15.0572 14.2286 14.8284 13.9997L8.32867 7.50003Z" fill="#979797"/>
                                            </svg>
                                        </div>
                                    )
                                }
                            })
                        }
                    
                    </div>
                </div>
                <div>
                    <DropDowns 
                        ariaLabel="unirepRoleTranslate.dest_discipline"
                        placeholder = {unirepRoleTranslate?.dest_discipline}
                        label = {unirepRoleTranslate?.dest_discipline}
                        option={disciplineOption}
                        name="exams"
                        type="disciplineState"
                        getOtherData={this.getOtherData}
                        zIndex={100}
                        data={selectedDiscipline}

                    />
                    <div className='mt-5'>
                        {
                            allDisciplines.map((data, i)=>{
                                if(selectedDiscipline.find(item=> item == data.discipline_id)){
                                    return(
                                        <div key={i} className='flex justify-between mt-3'>
                                            <span>{data.name}</span>
                                            <svg onClick={this.removeItem.bind(this, data.discipline_id, selectedDiscipline, 'disciplineState', 'positionDiscipline')} style={{ cursor: 'pointer' }} width="10" height="10" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.32867 7.50003L14.8284 1.00029C15.0572 0.771452 15.0572 0.400437 14.8284 0.171628C14.5995 -0.0571802 14.2285 -0.0572095 13.9997 0.171628L7.49998 6.67137L1.00029 0.171628C0.77145 -0.0572095 0.400436 -0.0572095 0.171628 0.171628C-0.05718 0.400466 -0.0572093 0.771482 0.171628 1.00029L6.67133 7.5L0.171628 13.9997C-0.0572093 14.2286 -0.0572093 14.5996 0.171628 14.8284C0.286032 14.9428 0.436002 15 0.585972 15C0.735943 15 0.885884 14.9428 1.00032 14.8284L7.49998 8.32869L13.9997 14.8284C14.1141 14.9428 14.2641 15 14.414 15C14.564 15 14.7139 14.9428 14.8284 14.8284C15.0572 14.5996 15.0572 14.2286 14.8284 13.9997L8.32867 7.50003Z" fill="#979797"/>
                                            </svg>
                                        </div>
                                    )
                                }
                            })
                        }
                    
                    </div>
                </div>
                
            </div>
            <div className="flex justify-center items-center mt-10" >
                    
                <button aria-label="unirepRoleTranslate.update" disabled={buttonVisible} onClick={this.saveChanges.bind(this, selectedDiscipline, selectedCountry)}  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                    {unirepRoleTranslate?.update}
                </button>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentDestinations: state.Data.studentDestinations,
    languageId: state.Data.languageId,
    allDisciplines: state.Data.allDisciplines,
    allCountries: state.Data.allCountries,
    unirepRoleTranslate: state.Data.unirepRoleTranslate,
});
const mapDispatchToProps = {getStudentDestinations, getAllCountries, getAllDisciplines, addNewData}
export default connect(mapStateToProps, mapDispatchToProps)(Destination)