import React, { Component } from 'react'
import { connect } from 'react-redux'
import InstitutionsCard from '../../../page_component/institutions/InstitutionsCard'
import SectionHeader from '../../../page_component/SectionHeader'
import ViewAllBtn from '../../../page_component/ViewAllBtn'
import LoadMoreBtn from '../../../page_component/LoadMoreBtn'
import { getInstitutions } from '../../../../actions/MainAction'
export class Universities extends Component {
  state={
    page:1
  }
  loadMoreFunction=(page)=>{
    this.setState({page:page})
    this.props.getInstitutions(page,'', this.props.languageId, this.props.countryId)
  }
  getAfterDelete=()=>{
    
    this.props.getInstitutions(this.state.page,'', this.props.languageId, this.props.countryId)
  }
  render() {
    const {institutions, mainTransateData} = this.props;
    return (
        <div className={`nc-SectionGridFilterCard pb-24 lg:pb-28`} data-nc-id="SectionGridFilterCard" >
            
      {
        institutions.length != 0?
        <>
        {/* <SectionHeader  arialLabelDesc="mainTransateData.country_uni_desc" arialLabelTitle={'mainTransateData.country_uni_title'} title={mainTransateData?.country_uni_title} description={mainTransateData?.country_uni_desc} /> */}
        
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
            {
                institutions && institutions.map((data, i)=> {
                    // if(i < 20){
                        return(
                            <InstitutionsCard getAfterDelete={this.getAfterDelete} key={i} institutions={data} />
                        )
                    // }
                })
            }
            
        </div>
        
        {/* <ViewAllBtn href="/institutions" /> */}
        {
            institutions.length >=20?
            <LoadMoreBtn loadMoreFunction={this.loadMoreFunction} position={institutions.length} count={20}  />:null
        }
         
       </>:null
      }
        
          
    </div>
    )
  }
}
const mapStateToProps = (state) =>({
  mainTransateData: state.Data.mainTransateData,
  languageId: state.Data.languageId,
});
const mapDispatchToProps = {getInstitutions}
export default connect(mapStateToProps, mapDispatchToProps)(Universities)