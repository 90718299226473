import axios from 'axios';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { uploadJSON, getAllUniversity , getAllRoboUniversity, matchData, getMatchUni, updateRanks, } from '../actions/AutoLoadActions';
import { getEducationDegree, getExams,  } from '../actions/MainAction';
import Swal from 'sweetalert2';
import Searchable from 'react-searchable-dropdown'; 
import { getProgram } from '../components/setProgramsAutomation';
import { addAllPrograms } from '../actions/WorkerActions';
import { changeStatusAllPrograms } from '../actions/WorkerActions';
import Loading from '../components/page_component/Loading';
import { ranks } from './auto_add/rank';
export class UploadAutoMations extends Component {
    state={
        data:'',
        btnDisable:false,
        instId: '',
        roboInstId: '',
        degreeId:'',
        rankings:''
    }
    getValue(e){
        // console.log(e.target.files[0])
        var reader = new FileReader();
        reader.onload = this.onReaderLoad;
        reader.readAsText(e.target.files[0]);
    }
    onReaderLoad=(event)=>{
        // console.log(event.target.result);
        var obj = JSON.parse(event.target.result);
        // console.log(obj?.results)
        this.setState({data: obj})
    }
    getValueRank(e){
        // console.log(e.target.files[0])
        var reader = new FileReader();
        reader.onload = this.onReaderLoadRank;
        reader.readAsText(e.target.files[0]);
    }
    onReaderLoadRank=(event)=>{
        // console.log(event.target.result);
        var obj = JSON.parse(event.target.result);
        // console.log(obj?.results)
        this.setState({rankings: obj})
    }

    uploadData(data, degreeId){
        if(data != '' && degreeId != ''){
            this.setState({btnDisable:true})
            
          
           this.props.uploadJSON(data, degreeId)
           .then(resp=> {
                this.setState({btnDisable:false, data:''})
                
                Swal.fire({
                    position: 'top-end',
                    icon: resp,
                    title: resp == 'success'?'Your work has been saved': 'Something went wrong.',
                    showConfirmButton: false,
                    timer: 1500
                  })
           })
        }
        
    }

    componentDidMount(){
        this.props.getAllUniversity()
        this.props.getAllRoboUniversity();
        this.props.getEducationDegree(1);
        this.props.getMatchUni();
        this.props.getExams();
    }
    selectFromSystem=( name, data) => {
       
        this.setState({[name]:data})
    }

    matchBtn(instId, roboInstId){
    
        if(instId != '' && roboInstId != ''){
            const systemUni = this.props.allInst?.find(data=> data.institutions_id == instId);
            const roboUni = this.props.allRoboInst?.find(data=> data.id == roboInstId);
            Swal.fire({
                title: 'Are you sure?',
                html:  `${systemUni?.name} <br></br> ${roboUni?.name}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, match it!'
              }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({btnDisable:true})
                    this.props.matchData({main_uni_id: instId, uni_id:roboInstId})
                    .then(resp=>{
                        this.setState({
                            instId: '',
                            roboInstId: ''
                        })
                        this.props.getMatchUni();
                        this.setState({btnDisable:false})
                        Swal.fire({
                            position: 'top-end',
                            icon: resp,
                            title: resp == 'success'?'Your work has been saved': 'Something went wrong.',
                            showConfirmButton: false,
                            timer: 1500
                          })
                    })
                
                }
              })
        }
       
    }
    getRankFormat(data){
        // console.log(data)
        if(String(data)?.includes('-')){
            return String(data)?.split("-")[0]
        }else if(String(data)?.includes('+')){
            return String(data)?.replaceAll("+", "")
        }else{
            return String(data)
        }
    }
    uploadDataRanks=()=>{
        // console.log(ranks)
        let final = [];
        for(const x of ranks){
            final.push({
                name:x?.Column3,
                rank: this.getRankFormat(x?.Column2)
            })
        }
        console.log(final)
        // console.log(ranks)
        // if(ranks != ''){
            this.setState({btnDisable:true});
            this.props.updateRanks(final)
            .then(resp=>{
                this.setState({btnDisable:false});

                Swal.fire({
                    position: 'top-end',
                    icon: resp,
                    title: resp == 'success'?'Your work has been saved': 'Something went wrong.',
                    showConfirmButton: false,
                    timer: 1500
                  })
            })
        // }
    }

    addUniWithPrograms(instId, roboInstId, allRoboInst){
        Swal.fire({
            title: 'Are you sure?',
           
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, match it!'
          }).then((result) => {
            if (result.isConfirmed) {
                const programs = allRoboInst?.find(item=>item?.id == roboInstId)?.programs;
   
                let allPrograms = [];
                let ids = [];
                for(let i = 0; i < programs.length; i++){
                    allPrograms.push(getProgram(programs[i], instId, this.props.examsData));
                    ids.push(programs[i].id)
                }

                console.log(allPrograms)
                this.props.matchData({main_uni_id: instId, uni_id:roboInstId})
               
                this.props.addAllPrograms(JSON.stringify(allPrograms))
                .then(resp=>{
                    if(resp == 'success'){
                        this.props.changeStatusAllPrograms(ids)
                        .then(resp=>{
                            Swal.fire({
                               title: 'Added!',
                               text: 'Programs Added successfully.',
                               icon: 'success',
                               timer: 1000
                            } )
                        })
                    }
                    
                })
            
            }
          })
    }
  render() {
    const {data, btnDisable,  instId,  roboInstId, degreeId, rankings} = this.state;
    const {allInst, allRoboInst, matchedUni, educationDegree, examsData, loader , loaderLong} = this.props;
    const degreeOption = [];
    for(const property of educationDegree){
        degreeOption.push({
          label: property?.education_type,
          value: property?.education_degree_id
        })
      }
    let instOpt = [];
    for(const data of allInst){
        const check = matchedUni.find(item=> item?.main_uni_id == data?.institutions_id);
        if(!check){
            instOpt.push({
                label: data?.name,
                value: data?.institutions_id
            })
        }
        
    }
    
    let roboInstOpt = [];
    for(const data of allRoboInst){
        const check = matchedUni.find(item=> item?.uni_id == data?.id);
        if(!check){
          roboInstOpt.push({
                label: data?.name,
                value: data?.id
            })  
        }
        
    }
    // console.log(roboInstOpt)
    return (
        <div className='container'>
            <div className=' flex gap-10 items-center border rounded-2xl p-3'>
                <label style={{ color: data == ''? 'black': 'blue' }} className='cursor-pointer' htmlFor='jsonInp'>Upload JSON file</label>
                <input type='file' id='jsonInp' style={{ display: 'none' }} onChange={this.getValue.bind(this)} />
                <div >
                    <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                       Select Education Degree
                    </label>
                    <div style={{ width: '300px' }} className={`block w-full border-neutral-200  
                        focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                        dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                        dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                    > 
                        <Searchable 
                            options={degreeOption}
                            onSelect={this.selectFromSystem.bind(this, 'degreeId')}
                            placeholder={"Select Education Degree"}
                            value={instId}
                        />
                    </div>
                </div>
                <button disabled={btnDisable} className='bg-blue-500 p-2 rounded-2xl text-white mt-5' onClick={this.uploadData.bind(this, data, degreeId)}>upload</button>
            </div>
            <div className=' flex gap-10 items-center border rounded-2xl p-3 mt-4'>
                <label style={{ color: rankings == ''? 'black': 'blue' }} className='cursor-pointer' htmlFor='jsonRankInp'>Upload Ranking JSON</label>
                <input type='file' id='jsonRankInp' style={{ display: 'none' }} onChange={this.getValueRank.bind(this)} />
                
                <button disabled={btnDisable} className='bg-blue-500 p-2 rounded-2xl text-white mt-5' onClick={this.uploadDataRanks.bind(this,rankings)}>upload</button>
            </div>
            <div className='flex justify-between mt-20 items-center'>
                <div >
                    <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                       Select Uni From system
                    </label>
                    <div style={{ width: '400px' }} className={`block w-full border-neutral-200  
                        focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                        dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                        dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                    > 
                        <Searchable 
                            options={instOpt}
                            onSelect={this.selectFromSystem.bind(this, 'instId')}
                            placeholder={"Select Uni From system"}
                            value={instId}
                        />
                    </div>
                </div>
                <div >
                    <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                       Select Uni From Robot
                    </label>
                    <div style={{ width: '400px' }} className={`block w-full border-neutral-200  
                        focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                        dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                        dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                    > 
                        <Searchable 
                            options={roboInstOpt}
                            onSelect={this.selectFromSystem.bind(this, 'roboInstId')}
                            placeholder={"Select Uni From Robot"}
                            value={roboInstId}
                        />
                    </div>
                </div>
                <button disabled={btnDisable} className='bg-blue-500 p-2 rounded-2xl text-white mt-6' style={{ width: '300px' }} onClick={this.matchBtn.bind(this, instId, roboInstId)}>Match</button>
                <button disabled={btnDisable} className='bg-blue-500 p-2 rounded-2xl text-white mt-6' style={{ width: '300px' }} onClick={this.addUniWithPrograms.bind(this, instId, roboInstId, allRoboInst)}>Add with programs</button>

            </div>
            {
                loader || loaderLong? 
                <Loading />:null
            }
        </div>
      
    )
  }
}
const mapStateToProps = (state) => ({
    allInst: state.Data.allInst,
    allRoboInst: state.Data.allRoboInst,
    matchedUni: state.Data.matchedUni,
    educationDegree: state.Data.educationDegree,
    examsData: state.Data.examsData,
    loader: state.Data.loader,
    loaderLong: state.Data.loaderLong,
});
const mapDispatchToProps = {uploadJSON, getAllUniversity,updateRanks, getAllRoboUniversity,changeStatusAllPrograms,  matchData, getMatchUni, getEducationDegree, getExams, addAllPrograms}
export default connect(mapStateToProps, mapDispatchToProps)(UploadAutoMations)
