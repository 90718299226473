import React, { Component } from 'react'
import { connect } from 'react-redux'
import BgGlassmorphism from '../../../components/page_component/BgGlassmorphism'
import SectionHeader from '../../../components/page_component/SectionHeader'
import RegisterModal from './components/RegisterModal'
import { getEduConsultantStudents } from '../../../actions/WorkerActions'
import { mainDashboardTranslate, getUnirepRoleTranslate } from '../../../actions/TranslateAction'
import { changeStateValue } from '../../../redux/MainReducer'
import { Link } from 'react-router-dom'
import avatar1 from '../../../images/default.jpg'
import EditSvg from '../../../components/page_component/EditSvg'
import EditModal from '../EditModal'
import AddParentModal from './components/AddParentModal'
import SkeletonCard from '../../../components/page_component/SkeletonCard'
import { getStudentInfo } from '../../../actions/MainAction'
import { getObject } from '../../../actions/makeFilterObj'
import SimulatorModalForEducon from '../../../components/page_component/simulator_components/modal/SimulatorModalForEducon';
import { getCountryEducationDegree } from '../../../actions/MainAction'
export class Students extends Component {
    state={
        registerVisible: false,
        student_id: '',
        parents: [],
        type: 'student',
        search:'',
        eduYear:true
    }
    componentDidMount(){
        window.scrollTo(0, 0)

        this.props.mainDashboardTranslate(this.props.languageId)
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.getEduConsultantStudents()
    }
    getAfterEdit = () =>{
        this.props.getEduConsultantStudents(this.state.search)
    }
    registerStudent(id,parent, type,  e){
        if(type == 'parent'){
            this.setState({eduYear:false})
        }
        this.setState({
            registerVisible: true,
            student_id: id,
            parents: parent,
            type: type
        })
    }

    closeModal = () => {
        this.setState({
            registerVisible: false
        })
    }
    resetData(id, type, e){
        const state={
            page:1,
            keyword: '' ,
            filterByCountry: '',
            filterByState:'',
            filterByCity:'',
            filterByStatus:'',
            filterByInstitution:'',
            lang_id: this.props.languageId,
            filterByLevel:'',
            filterByDiscipline:'',
            filterByDuration:'',
            filterByFee: '',
            filterStudiedCountry: '',
            filterByCountryLevel: '',
            filterByGPA: '',
            filterByGradingScheme: '',
            filterByALevel: '',
            filterByOSSD: '',
            filterByIB: '',
            filterByAP: '',
            filterByExtraActivities:'',
            filterByInternationalOlympiads: '',
            gpaConverter:'',
            gradingSchemeId: '',
            student_id: id,
            filterType: type,
            filterByDegree: '',
            filterByLanguage: ''
          }
        localStorage.removeItem('filterExamIds')
        localStorage.removeItem('filterExamGrade')
        localStorage.setItem('simulator', JSON.stringify(state))
        this.props.changeStateValue({
          name: 'filterExamIds',
          value: []
        })
        this.props.changeStateValue({
            name: 'simulatorType',
            value: type
          })
          localStorage.setItem('simulatorType', type)
        this.props.changeStateValue({
          name: 'filterExamGrade',
          value: {
            ielts: '',
            toefl: '',
            pte: '',
            duolingo: '', 
            sat: '',
            gre: '',
            gmat: ''
          }
        })
        localStorage.setItem('modalSimulator', 'true')

    }

    editSvgBtn = (data) =>{
       
        this.props.changeStateValue({
            name: 'editUserState',
            value:{
                position: true,
                id: data.id,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                password: '',
                image: data.image_full_url,
                education_year: data?.education_year,
                phone_number: data?.phone_number,
            }
        }) 
    }

    searchUser(e){
       this.setState({search:e.target.value});
       this.props.getEduConsultantStudents(e.target.value)
    }

    setEduBack(student){
      
        if(localStorage.getItem('token')){
            this.props.getStudentInfo(student)
            .then(resp=>{
                this.props.changeStateValue({
                    name: 'simulatorModal',
                    value: true
                  })
              const data = getObject(resp);
              localStorage.setItem('filterExamIds', JSON.stringify(data.examId))
              localStorage.setItem('filterExamGrade', JSON.stringify(data.exams))
              data.obj.student_id = student
              localStorage.setItem('simulator', JSON.stringify(data.obj))
              this.props.changeStateValue({
                name: 'filter',
                value: data.obj
              })
              if(data?.obj?.filterByCountryLevel && data?.obj?.filterByCountryLevel != ''){
                this.props.getCountryEducationDegree(1, data?.obj?.filterStudiedCountry, data?.obj?.filterByLevel)
              }
              this.props.changeStateValue({
                name: 'filterExamGrade',
                value: data.exams
              })
              this.props.changeStateValue({
                name: 'filterExamIds',
                value: data.examId
              })
             
            })
          }
    }
  render() {
    const {registerVisible, student_id, parents, type, search, eduYear} = this.state;
    const {eduConsultantStudents, editUserState, mainTransateData,user, simulatorModal} = this.props;
    console.log(eduConsultantStudents)
    return (
        <div className="nc-PageHome relative overflow-hidden">
            <BgGlassmorphism />
            <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
                <div className="relative py-16">
                    <div className={`nc-SectionGridFilterCard pb-24 lg:pb-28`} data-nc-id="SectionGridFilterCard" >
                    
                        <SectionHeader arialLabelTitle={'mainTransateData.student_title'} title={mainTransateData?.student_title} />
                        <div className="mb-8 lg:mb-11">
                            <div className="flex lg:space-x-4">
                                <div className="hidden lg:flex space-x-3">
                                    <button onClick={this.registerStudent.bind(this, '',[], 'student')} className='ttnc-ButtonThird text-neutral-700 border 
                                                        border-neutral-200 dark:text-neutral-200 
                                                        dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                                        items-center justify-center rounded-full transition-colors
                                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                                        px-4 py-1 sm:px-3' style={{ fontSize: '14px', fontWeight: '400', width: '250px' }}  aria-label="mainTransateData.add_student" >
                                        {mainTransateData?.add_student}
                                    </button>
                                    <input value={search} onChange={this.searchUser.bind(this)} placeholder='Search' style={{ height:'40px' }} type='text'  />
                                </div>
                               
                            </div>
                        </div>

                        <div  className={`grid mt-10 gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 `} >

                        
                       {
                        eduConsultantStudents?.map((data, i)=>{
                            return(
                                <div key={i}
                                        className={`nc-CardAuthorBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}
                                        data-nc-id="CardAuthorBox"
                                    >
                                        {
                                            data?.finance_lock == 1?
                                            <div className='w-5 absolute left-3 top-3' >
                                                <EditSvg editSvgBtn={this.editSvgBtn.bind(this, data)} />
                                            </div>:null
                                        }
                                        
                                        <div className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full w-20 h-20 text-2xlring-1 ring-white dark:ring-neutral-900`}>
                                            <img
                                            className={`absolute inset-0 w-full h-full object-cover rounded-full`}
                                            src={data.image_full_url&& data.image_full_url != '' ? data.image_full_url:avatar1}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <h2 className={`text-base font-medium`}>
                                                <span className="line-clamp-1">{data.full_name}</span>
                                            </h2>
                                            <span className={`block mt-1.5  text-sm text-neutral-500 dark:text-neutral-400 wrap`} >
                                            {data.email.length > 20? `${data.email.substring(0, 20)}...`:data.email}
                                            </span>
                                            <div className={"p-4 space-y-4"}>
                                               <hr />
                                                <ul>
                                                    {
                                                        data?.teacher?.map((item, j)=>{
                                                            return(
                                                                <li style={{ fontSize:'13px', lineHeight: '14px', marginTop:'5px', borderBottom: '1px dashed gray' }} key={j}>{item?.teacher?.full_name?.length>19?`${item?.teacher?.full_name?.substring(0, 19)}...`:item?.teacher?.full_name}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                                
                                        </div>
                                        {
                                            user != '' && user.role == 'edu_consultant' ||  user != '' && user.role == 'teacher'?
                                            <div style={{ flexDirection: 'column' }} className="flex  gap-5">
                                                {
                                                     data?.finance_lock == 1?
                                                    <Link aria-label='mainTransateData.admis_chances' onClick={this.resetData.bind(this, data.id, 'program')} to={`/check-info/${data.id}`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                                    {mainTransateData?.admis_chances} 
                                                    </Link>:
                                                    <button  aria-label='mainTransateData.admis_chances'  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                                    text-sm sm:text-base font-medium cursor-not-allow text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white', cursor:'not-allowed' }}>
                                                    {mainTransateData?.admis_chances} 
                                                    </button>
                                                }
                                                {
                                                     data?.finance_lock == 1? 
                                                     <Link aria-label='mainTransateData.sch_chances' onClick={this.resetData.bind(this, data.id, 'scholarship')} to={`/check-info/${data.id}`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                                        {mainTransateData?.sch_chances} 
                                                        </Link>:
                                                     <button aria-label='mainTransateData.sch_chances' to={`/check-info/${data.id}`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white', cursor:'not-allowed' }}>
                                                        {mainTransateData?.sch_chances} 
                                                        </button>
                                                }
                                               {
                                                data?.finance_lock == 1? 
                                                <button aria-label='mainTransateData.add_parent' onClick={this.registerStudent.bind(this, data.id, data.parent, 'parent')} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                                {mainTransateData?.add_parent}
                                                </button>:
                                                <button aria-label='mainTransateData.add_parent'  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white', cursor:'not-allowed' }}>
                                                {mainTransateData?.add_parent}
                                                </button>
                                               }
                                                
                                                {
                                                      data?.finance_lock == 1? 
                                                    user?.role == "edu_consultant"?
                                                    <button aria-label='mainTransateData.add_parent' onClick={this.setEduBack.bind(this, data.id)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                                    Set Edu. Back
                                                    </button>:null:
                                                    user?.role == "edu_consultant"?
                                                    <button aria-label='mainTransateData.add_parent'  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white', cursor:'not-allowed' }}>
                                                    Set Edu. Back
                                                    </button>:null
                                                }
                                               
                                            </div> :null
                                        }
                                        {/* {
                                            user != '' && user.role == 'teacher'?
                                            <div style={{ flexDirection: 'column' }} className="flex  gap-5">
                                                <Link aria-label='mainTransateData.edit_student'  to={`/student-info/${data.id}`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                                {mainTransateData?.edit_student} 
                                                </Link>
                                                
                                            </div> :null
                                        }   */}
                                    </div>
                               
                            )
                        })
                       }
                       {
                        eduConsultantStudents?.length == 0?
                        <>
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        </>:null
                        }
                        </div>
                    </div>
                </div>
            </div>
            {
                registerVisible?
                <AddParentModal eduYear={eduYear}  type={type} parents={parents} student_id={student_id} closeModal={this.closeModal} />:null
            }
            
            {
                editUserState.position?
                <EditModal eduYear={true} getAfterEdit={this.getAfterEdit} />:null
            }
             {
          simulatorModal?
          <SimulatorModalForEducon />:null
        }
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
    eduConsultantStudents: state.Data.eduConsultantStudents,
    languageId: state.Data.languageId,
    user: state.Data.user,
    editUserState: state.Data.editUserState,
    mainTransateData: state.Data.mainTransateData,
    simulatorModal: state.Data.simulatorModal,
});
const mapDispatchToProps = {getEduConsultantStudents,getStudentInfo, changeStateValue, mainDashboardTranslate,getCountryEducationDegree, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(Students)