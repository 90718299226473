import React, { Component, Fragment } from 'react'
import ChangeSvg from '../../../../../../components/svg/ChangeSvg'
import { Popover, Transition } from '@headlessui/react'
import { getCurrency } from '../../../../../../actions/WorkerActions';
import { getScholarhipTracking, getStudentScholarhipTracking, addStudentTrancking, deleteStudentTracking } from '../../../../../../actions/WorkerActions';
import { connect } from 'react-redux';
import DropDowns from '../../../../../student_pages/components/DropDowns';
import { mixinAlert } from '../../../../../../actions/Alerts';
export class SchBtn extends Component {
    state={
        inpPosition:false,
        currencyId:'',
        amount:'',
        trackId:'',
        studentTracking:''
    }
    componentDidMount(){
        this.props.getCurrency();
        this.props.getScholarhipTracking();
        this.props.getStudentScholarhipTracking(this.props.student_id, this.props.program_id)
        .then(resp=>{this.setState({studentTracking:resp})})
    }

    onClearBtn=(id)=>{
        let url = `scholarship-tracking/${id}`;
        this.props.deleteStudentTracking(url)
        .then(resp=>{
            this.props.getStudentScholarhipTracking(this.props.student_id, this.props.program_id)
            .then(resp=>{this.setState({studentTracking:resp})})
        })
    }

    selectSchBtn=(data)=>{
        this.setState({trackId: data?.id})
        if(data?.type == "1"){
            this.setState({inpPosition:true})
        }else{
            this.setState({inpPosition:false, currencyId:'', amount:''})
            this.applyFunc(data?.id)
        }
    }
    applyFunc=(trackId)=>{
        let formData = new FormData();
        formData.append("student_id", this.props.student_id);
        formData.append("program_id", this.props.program_id)
        formData.append("track_id", trackId);
        formData.append("amount", this.state.amount);
        formData.append("currency", this.state.currencyId);
        let url = "scholarship-tracking";
        this.props.addStudentTrancking(url, formData)
        .then(resp=>{
            this.props.getStudentScholarhipTracking(this.props.student_id, this.props.program_id)
            .then(resp=>{this.setState({studentTracking:resp})})
            this.setState({
                inpPosition:false,
                currencyId:'',
                amount:'',
                trackId:''
            })
        })
    }
    selectCurrency=(data)=>{
        this.setState({
            currencyId: data
        })
    }

    onApplyBtn=(currency, amount)=>{
        if(currency !== "" && amount !==""){
            this.applyFunc(this.state.trackId)
        }else{
            mixinAlert("warning","Please, fill the amount and currency")
        }
    }
    getFeeAmount(x){
        var parts = x.toString().split(".");
        parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");
        return parts.join(",");
    }
  render() {
    const {scholarhipTrackings, studentScholarhipTrackings, currency, notPermision=false} = this.props;
    const {inpPosition, currencyId, amount, studentTracking} = this.state;
    let currencyOpt=[];
    for(const data of currency){
        currencyOpt.push({
            label: data?.currency,
            value: data?.currency
        })
    }
    return (
        <Popover s>
            {
                !notPermision?
                <Popover.Button className={'outline-none flex items-center justify-start w-full gap-[10px]'}>
                <ChangeSvg />
                <span>{studentTracking?.tracking?.type == "1"?this.getFeeAmount(studentTracking?.amount)+" "+studentTracking?.currency : studentTracking !==""?"State Scholarship Granted":null}</span>
            </Popover.Button>: <span>{studentTracking?.tracking?.type == "1"?this.getFeeAmount(studentTracking?.amount)+" "+studentTracking?.currency : studentTracking !==""?"State Scholarship Granted":null}</span>
            }
          
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
            <div id='popover_container'> 
                <Popover.Panel className=" z-10 w-screen max-w-[300px] px-4 mt-3  sm:px-0">
                    <div className='rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700 p-[4px] '>
                        {/* <div className='bg-gray-100 rounded-xl h-[40px] p-[4px]'>
                            <div className='w-full h-full bg-white rounded-xl flex items-center justify-center'>
                                <span className='text-blue-800 font-[400] text-[14px] '>Applications</span>
                            </div>
                        </div> */}
                        <div className='  p-[7px]'>
                            <ul className='flex flex-col gap-[10px]'>
                                {/* {
                                    scholarhipTrackings?.map((data, i)=>{
                                        return(
                                            <li onClick={this.selectSchBtn.bind(this, data)} key={i} className='flex items-center gap-[10px] cursor-pointer'>
                                                <label className='cursor-pointer  w-full'>{data?.type == "1"?"State Scholarship Granted": "Add Scholarships"}</label>
                                            </li>
                                        )
                                    })
                                } */}
                                 <li onClick={this.selectSchBtn.bind(this, {id:2, type:0})}  className='flex items-center gap-[10px] cursor-pointer'>
                                    <label className='cursor-pointer  w-full'>State Scholarship Granted</label>
                                </li>
                                <li className='pr-[20px] text-center mt-[-10px] mb-[-10px]'>Or</li>
                                 <li onClick={this.selectSchBtn.bind(this, {id:1, type:1})}  className='flex items-center gap-[10px] cursor-pointer'>
                                    <label className='cursor-pointer  w-full'>Add Scholarships (Amount)</label>
                                </li>
                                
                            </ul>
                            {
                                inpPosition?
                                <div className='flex items-center gap-[40px]'>
                                    <input placeholder='Amount' className='w-[60%]' value={amount} onChange={(e)=>this.setState({amount: e.target.value})} type='number' />
                                    <DropDowns name="" placeholder="Currency" option={currencyOpt} type="exams" getOtherData={this.selectCurrency.bind(this)} />
                                </div>:null
                            }
                        </div>
                        <div className='flex justify-between items-center p-[10px]'>
                            <button onClick={this.onClearBtn.bind(this, studentTracking?.id)} className='border rounded-2xl text-[13px] font-[400] p-[4px_7px]'>Clear</button>
                            {
                                inpPosition?
                                <button onClick={this.onApplyBtn.bind(this, currencyId, amount)} className='bg-blue-800 rounded-2xl text-[13px] font-[400] p-[4px_7px] text-white'>Add</button>:null
                            }
                           
                        </div>
                    </div>
                </Popover.Panel>
            </div>
            </Transition>
      </Popover>
    )
  }
}
const mapStateToProps = (state)=>({
    scholarhipTrackings: state.Data.scholarhipTrackings,
    studentScholarhipTrackings: state.Data.studentScholarhipTrackings,
    currency: state.Data.currency,
});
const mapDispatchToProps = {getCurrency, getScholarhipTracking, getStudentScholarhipTracking, addStudentTrancking, deleteStudentTracking}
export default connect(mapStateToProps, mapDispatchToProps)(SchBtn)
