import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../../../actions/withHooks';
import { getStudentPrograms } from '../../../../../actions/WorkerActions';
import ChangeSvg from '../../../../../components/svg/ChangeSvg';
import TrackingBtn from './components/TrackingBtn';
import { getTracings } from '../../../../../actions/WorkerActions';
import SchBtn from './components/SchBtn';
import ComissionBtn from './components/ComissionBtn';
import ArchiveBtn from './components/ArchiveBtn';
import WentToBtn from './components/WentToBtn';
export class Programs extends Component {
    componentDidMount(){
        this.props.getStudentPrograms(this.props.params.id);
        this.props.getTracings(1)
        
    }

    getStatus(applications){
        let arr = [...applications]?.sort((x, y)=>y?.track_id - x?.track_id);
        return arr[0]?.trackings?.translate?.title
    }
    getAfterChange=()=>{
        this.props.getStudentPrograms(this.props.params.id);
    }
  render() {
    const {studentPrograms, trackingData} = this.props;
    console.log(studentPrograms)
    return (
        <div className=' mt-[20px] flex flex-col gap-[20px]'>
            {
                studentPrograms?.map((data, i)=>{
                    return(
                        <div key={i} className='bg-white pt-[1px] rounded-[10px]'>
                            <h3 className='mt-[10px] ml-[10px] text-[14px] font-semibold'>{data?.program?.institution?.translate?.name}</h3>
                            <table className='w-full bg-white rounded-[10px] shadow-sm border-t'>
                                <thead >
                                    <tr>
                                        <th className='text-[13px] text-start p-[10px] w-[300px] font-[600] border-r'>Program</th>
                                        <th className='text-[13px] text-start p-[10px]  w-[150px] font-[600] border-r'>Degree</th>
                                        <th className='text-[13px] text-start p-[10px] w-[200px] font-[600] border-r'>Status</th>
                                        <th className='text-[13px] text-start p-[10px] w-[300px] font-[600] border-r'>Scholarship</th>
                                        <th className='text-[13px] text-start p-[10px] font-[600] border-r'>Commissions</th>
                                        <th className='text-[13px] text-start p-[10px] font-[600] border-r'>Archive</th>
                                        <th className='text-[13px] text-start p-[10px] font-[600]'>Went to</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='border-t'>
                                        <td className='text-[13px] text-start p-[10px] w-[300px] border-r'>{data?.program?.translate?.name}</td>
                                        <td className='text-[13px] text-start p-[10px] w-[150px] border-r'>{data?.program?.degree?.translate?.education_type}</td>
                                        <td className='text-[13px] text-start p-[10px] flex w-[200px] items-center w-full gap-[10px] border-r'>
                                         
                                            <TrackingBtn getAfterChange={this.getAfterChange.bind(this)} selectArray={[...data?.program?.applications]} options={trackingData['applications']} program_id={data?.program.id} student_id={this.props.params.id} />
                                            {this.getStatus(data?.program?.applications)}
                                        </td>
                                        <td  className='text-[13px] text-start p-[10px] w-[300px] border-r'>
                                            <SchBtn  program_id={data?.program.id} student_id={this.props.params.id} />
                                        </td>
                                        <td  className='text-[13px] text-start p-[10px] border-r'>
                                            <ComissionBtn key={i} getAfterChange={this.getAfterChange.bind(this)} comission={data?.program?.comission}  program_id={data?.program.id} student_id={this.props.params.id} />
                                        </td>
                                        <td  className='text-[13px] text-start p-[10px] border-r'>
                                            <ArchiveBtn key={i} getAfterChange={this.getAfterChange.bind(this)} archive={false}  id={data?.id} />
                                        </td>
                                        <td  className='text-[13px] text-start p-[10px] '>
                                            <WentToBtn key={i} getAfterChange={this.getAfterChange.bind(this)} wentto={data?.went_to}  id={data?.id} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                })
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentPrograms: state.Data.studentPrograms,
    trackingData: state.Data.trackingData,
});
const mapDispatchToProps = {getStudentPrograms, getTracings}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Programs))
