import React, { Component } from 'react'
import { connect } from 'react-redux';
import Searchable from 'react-searchable-dropdown'; 
import { changeStateValue } from '../../../redux/MainReducer';
import { getSimulatorPrograms } from '../../../actions/MainAction';
export class DegreeFilter extends Component {
    selectDropValue  =(id) =>{

        if(localStorage.getItem('simulator')){
            const filterData = JSON.parse(localStorage.getItem('simulator'));
            filterData['filterByDegree'] = id;
            localStorage.setItem('simulator', JSON.stringify(filterData));
            this.props.changeStateValue({
                name: 'filter',
                value: filterData
            });
            if(localStorage.getItem('token')){
                this.props.getSimulatorPrograms(filterData, 1, 'programs');
            }else{
                this.props.getSimulatorPrograms(filterData);
            }
        }
    }

    getPlaceholder(option){
        if(localStorage.getItem('simulator')){
            const filterData = JSON.parse(localStorage.getItem('simulator'));
            if(option.find(data=> data.value == filterData.filterByDegree)){
                return option.find(data=> data.value == filterData.filterByDegree).label
            }else{
                return 'Filter By Degree';
            }
        }else{
            return 'Filter By Degree';
        }
        
    }
  render() {
    const {option, filter} = this.props;
   
    const degreeOption = [];
    for(const data of option){
        if(filter.filterByLevel == 1){
            if(data.education_degree_id == 1 || data.education_degree_id == 2 || data.education_degree_id == 3){
                degreeOption.push({
                    label: data.education_type,
                    value: data.education_degree_id
                })
            }
        }else if(filter.filterByLevel == 2){
            if( data.education_degree_id == 2 || data.education_degree_id == 3){
                degreeOption.push({
                    label: data.education_type,
                    value: data.education_degree_id
                })
            }
        }else if(filter.filterByLevel == 3){
            if( data.education_degree_id == 4 || data.education_degree_id == 5){
                degreeOption.push({
                    label: data.education_type,
                    value: data.education_degree_id
                })
            }
        }else if(filter.filterByLevel == 4){
            if(data.education_degree_id == 4 || data.education_degree_id == 5  ){
                degreeOption.push({
                    label: data.education_type,
                    value: data.education_degree_id
                })
            }
        }else if(filter.filterByLevel == 5){
            if(data.education_degree_id == 5 || data.education_degree_id == 6  ){
                degreeOption.push({
                    label: data.education_type,
                    value: data.education_degree_id
                })
            }
        }
       
    }
    return (
      <div  className={`block border-neutral-200  
      focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
      dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
      dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}>
        <Searchable 
            options={degreeOption}
            onSelect={this.selectDropValue.bind( this.label )}
            placeholder={this.getPlaceholder(degreeOption)}
        />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    filter: state.Data.filter
});
const mapDispatchToProps = {changeStateValue, getSimulatorPrograms}
export default connect(mapStateToProps, mapDispatchToProps)(DegreeFilter)