import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../../../redux/MainReducer';
export class Input extends Component {

    writeComment(e){
        this.props.changeStateValue({
            name: 'commentData.comment',
            value: e.target.value
        })
    }
  render() {
    const {commentData} = this.props
    return (
        <input
           
            type={'text'}
            className={`block w-full border-neutral-200 
            focus:border-primary-300 focus:ring focus:ring-primary-200 
            focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 
            dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-3xl h-16 px-4 py-3 `}
            onChange={this.writeComment.bind(this)} value={commentData.comment}
        />
    )
  }
}
const mapStateToProps = (state) =>({
    commentData: state.Data.commentData
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Input)