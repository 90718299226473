import React, { Component } from 'react'

export class SelectDefault extends Component {
    state={
        optionPosition:false,
        value:''
    }
    
  render() {
    const {options=[], onSelect=()=>{}, onChangeText=()=>{}, placeholder="Select"} = this.props;
    const {optionPosition, value} = this.state;
    return (
      <div className='relative'>
        <input value={value} onChange={(e)=>{this.setState({value:e.target.value}); onChangeText(e.target.value)}} onFocus={()=>this.setState({optionPosition:true})} onBlur={()=>{setTimeout(()=>{this.setState({optionPosition:false})}, 150)}} placeholder={placeholder} type='text' className='mt-[4px]' />
        {
            optionPosition && options.filter(x=>x?.label?.toLowerCase().trim().includes(value.toLowerCase().trim())).length !=0?
            <ul className='absolute bg-white p-[10px] rounded-[6px] flex flex-col max-h-[200px] overflow-auto w-full mt-[10px] gap-[4px]'>
                {
                    options.map((data, i)=>{
                        if(data?.label?.toLowerCase().trim().includes(value.toLowerCase().trim())){
                            return(
                                <li  onClick={()=>{this.setState({value: data?.label}); onSelect(data?.value)}} className='text-[13px] cursor-pointer p-[2px_0]' key={i}>{data?.label}</li> 
                             )
                        }
                    })
                }
            </ul>:null
        }
        {options.filter(x=>x?.label?.toLowerCase().trim().includes(value.toLowerCase().trim())).length ==0 && value !="" && optionPosition?
            <div  className='absolute bg-white p-[10px] rounded-[6px] flex flex-col max-h-[200px] overflow-auto w-full mt-[10px] gap-[4px]'>
                <button className='text-[12px] p-[2px_4px] text-white bg-blue-700 rounded-[5px]'>
                Nothing found.  Click here to type the name.
                </button>
            </div> :null
        }
      </div>
    )
  }
}

export default SelectDefault
