import React, { Component } from 'react'
import { connect } from 'react-redux';
import { mainDashboardTranslate, getUnirepRoleTranslate } from '../actions/TranslateAction'
import SectionHeader from '../components/page_component/SectionHeader';
import { getStudentWishList, getStudentPublicWishList } from '../actions/MainAction';
import ProgramCard from '../components/page_component/programs/ProgramCard';
import LoadMoreBtn from '../components/page_component/LoadMoreBtn';
export class WishListPage extends Component {
    state={
        pageCount: 1
      }
    componentDidMount(){
        window.scrollTo(0, 0);
        this.props.mainDashboardTranslate(this.props.languageId)
        this.props.getUnirepRoleTranslate(this.props.languageId);
        if(localStorage.getItem('token')){
            this.props.getStudentWishList(1, this.props.languageId)
        }else{
            if(localStorage.getItem('lsWishList')){
                this.props.getStudentPublicWishList(1, this.props.languageId, JSON.parse(localStorage.getItem('lsWishList')))
            }  
        }
        
    }
    getAfterApply = () =>{
        if(localStorage.getItem('token')){
            this.props.getStudentWishList(this.state.pageCount, this.props.languageId)
        }else{
            if(localStorage.getItem('lsWishList')){
                this.props.getStudentPublicWishList(1, this.props.languageId, JSON.parse(localStorage.getItem('lsWishList')))
            }  
        }
        
    }
    loadMoreFunction = (page) =>{
        this.setState({pageCount: page})
        if(localStorage.getItem('token')){
            this.props.getStudentWishList(page, this.props.languageId)
        }else{
            if(localStorage.getItem('lsWishList')){
                this.props.getStudentPublicWishList(1, this.props.languageId, JSON.parse(localStorage.getItem('lsWishList')))
            }  
        }
        
    }

    reloadAfterLike = () =>{
        if(localStorage.getItem('token')){
            this.props.getStudentWishList(this.state.pageCount, this.props.languageId)
        }else{
            if(localStorage.getItem('lsWishList')){
                this.props.getStudentPublicWishList(1, this.props.languageId, JSON.parse(localStorage.getItem('lsWishList')))
            }  
        }
        

      }
  render() {
    const {mainTransateData, studentWishList} = this.props;
   
    return (
      <div className='container'>
        <div className="flex flex-col mb-8 relative">
            <SectionHeader arialLabelTitle="mainTransateData.wish_list_title"  title={mainTransateData?.wish_list_title} />
            
        </div>
        <div  className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
            {
             
              studentWishList &&
              studentWishList.map((program, i)=>{
                  return(
                    <ProgramCard reloadAfterLike={this.reloadAfterLike} getAfterApply={this.getAfterApply} key={i} program={program} />
                  )
              })
            }
            
          </div>
          {
             studentWishList.length >= 20?
             <LoadMoreBtn position={studentWishList.length} loadMoreFunction={this.loadMoreFunction}  />:null
          }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    languageId: state.Data.languageId,
    mainTransateData: state.Data.mainTransateData,
    studentWishList: state.Data.studentWishList,
});
const mapDispatchToProps = {mainDashboardTranslate, getUnirepRoleTranslate, getStudentWishList, getStudentPublicWishList }
export default connect(mapStateToProps,mapDispatchToProps)(WishListPage)