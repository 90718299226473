import React, { Component } from 'react'
import SectionHeader from '../../../../components/page_component/SectionHeader'
import { connect } from 'react-redux';
import { withHooks } from '../../../../actions/withHooks';
import { getUserData } from '../../../../actions/WorkerActions';
import Programs from './pages/Programs';
import Finance from './pages/Finance';
import Documents from './pages/Documents';
import Notes from './pages/Notes';
import ProgramApplication from './pages/ProgramApplication';
import Loading from '../../../../components/page_component/Loading';
import ArchivePrograms from './pages/ArchivePrograms';
import SimulatorModalForEducon from '../../../../components/page_component/simulator_components/modal/SimulatorModalForEducon';
import { changeStateValue } from '../../../../redux/MainReducer';
import { getCountryEducationDegree, getStudentInfo } from '../../../../actions/MainAction';
import { getObject } from '../../../../actions/makeFilterObj';
import FinancePrevPage from './pages/FinancePrevPage';
export class StudentInfo extends Component {
    state={
        studentData:'',
        pageIndex:0
    }

    componentDidMount(){
        this.props.getUserData(this.props.params.id)
        .then(resp=>{
            this.setState({
                studentData: resp
            })
        })
    }
    openEduBg=(student)=>{
        if(localStorage.getItem('token')){
            // console.log("sss")
            this.props.getStudentInfo(student)
            .then(resp=>{
                this.props.changeStateValue({
                    name: 'simulatorModal',
                    value: true
                  })
              const data = getObject(resp);
              localStorage.setItem('filterExamIds', JSON.stringify(data.examId))
              localStorage.setItem('filterExamGrade', JSON.stringify(data.exams))
              data.obj.student_id = student
              localStorage.setItem('simulator', JSON.stringify(data.obj))
              this.props.changeStateValue({
                name: 'filter',
                value: data.obj
              })
              if(data?.obj?.filterByCountryLevel && data?.obj?.filterByCountryLevel != ''){
                this.props.getCountryEducationDegree(1, data?.obj?.filterStudiedCountry, data?.obj?.filterByLevel)
              }
              this.props.changeStateValue({
                name: 'filterExamGrade',
                value: data.exams
              })
              this.props.changeStateValue({
                name: 'filterExamIds',
                value: data.examId
              })
             
            })
          }
    }
  render() {
    const {studentData, pageIndex} = this.state;
    const {loader, loaderLong, simulatorModal} = this.props;
    // console.log(studentData)
    return (
      <div className='w-full min-h-[100vh] bg-[#F1F1F4] pt-[1px] pb-[100px]'>
             <div className="container relative ">
                <div className='mt-[50px]'>
                    <SectionHeader arialLabelTitle={'mainTransateData.student_title'} title={studentData?.full_name} />
                </div>
                <div className=' flex flex-wrap gap-[20px]'>
                    <button onClick={()=>this.setState({pageIndex:0})} className={`text-[14px] font-[500]  bg-white rounded-[10px] p-[7px_7px] shadow-md ${pageIndex==0? 'border border-blue-600 border-[0.5px]':''}`}>Applications</button>
                    <button onClick={()=>this.setState({pageIndex:1})} className={`text-[14px] font-[500]  bg-white rounded-[10px] p-[7px_7px] shadow-md ${pageIndex==1? 'border border-blue-600 border-[0.5px]':''}`}>Finance</button>
                    <button onClick={()=>this.setState({pageIndex:2})} className={`text-[14px] font-[500]  bg-white rounded-[10px] p-[7px_7px] shadow-md ${pageIndex==2? 'border border-blue-600 border-[0.5px]':''}`}>Documents</button>
                    <button onClick={()=>this.setState({pageIndex:3})} className={`text-[14px] font-[500]  bg-white rounded-[10px] p-[7px_7px] shadow-md ${pageIndex==3? 'border border-blue-600 border-[0.5px]':''}`}>Notes</button>
                    <button onClick={()=>this.setState({pageIndex:4})} className={`text-[14px] font-[500]  bg-white rounded-[10px] p-[7px_7px] shadow-md ${pageIndex==4? 'border border-blue-600 border-[0.5px]':''}`}>Apply now</button>
                    <button onClick={()=>this.setState({pageIndex:5})} className={`text-[14px] font-[500]  bg-white rounded-[10px] p-[7px_7px] shadow-md ${pageIndex==5? 'border border-blue-600 border-[0.5px]':''}`}>Archive</button>
                    <button onClick={this.openEduBg.bind(this, studentData?.id)} className={`text-[14px] font-[500]  bg-white rounded-[10px] p-[7px_7px] shadow-md ${pageIndex==6? 'border border-blue-600 border-[0.5px]':''}`}>Profile</button>
                </div>
                {
                    pageIndex==0?
                    <Programs />:
                    pageIndex==5?
                    <ArchivePrograms />:
                    pageIndex == 1?
                    // <FinancePrevPage finance_lock={studentData?.finance_lock} />:
                    <Finance finance_lock={studentData?.finance_lock} />:
                    pageIndex == 2?
                    <Documents />:
                    pageIndex == 3?
                    <Notes />:
                    pageIndex == 4?
                    <ProgramApplication onClick={()=>this.setState({pageIndex:0})} student_id={this.props.params.id} />:null
                }
               
            </div>
        {
             loader || loaderLong? 
        <Loading />:null
       } 
        {
          simulatorModal?
          <SimulatorModalForEducon />:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    loader: state.Data.laoder,
    loaderLong: state.Data.loaderLong,
    simulatorModal: state.Data.simulatorModal,
});
const mapDispatchToProps = {getUserData, changeStateValue, getCountryEducationDegree, getStudentInfo}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StudentInfo))
