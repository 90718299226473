import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDocumentData, insertNewData, deleteData } from '../../../actions/WorkerActions';
import SectionHeader from '../../../components/page_component/SectionHeader';
import EditSvg from '../../../components/page_component/EditSvg';
import { changeStateValue } from '../../../redux/MainReducer';
import DocumentModal from './components/DocumentModal';
import Swal from 'sweetalert2';
import DeleteSvg from '../student_information/components/DeleteSvg';
import DeleteIcon from './components/DeleteIcon';

function withHooks(Component){
    return props => <Component {...props} params={useParams()} />
}

export class DocumentData extends Component {
    state={
        commentTitle: '',
        btnVisible: false
    }
    componentDidMount(){
        this.props.getDocumentData(this.props.params.id)
    }
    openEditModal(data){
       
        this.props.changeStateValue({
            name: 'documentsModal',
            value:{
                id:data?.id,
                title:data?.title,
                description:data?.description,
                position:true,
                country_id: data?.country_id,
                category_id: data?.category_id,
                status: data?.status,
                file: data?.file
            }
        })
    }
    approveBtn(data){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, approve it!'
          }).then((result) => {
            if (result.isConfirmed) {
                let formData = new FormData();
                formData.append('_method', 'put');
                formData.append('status', '1');
                let url = `/document/${data?.id}`;
                this.props.insertNewData(url, formData)
                .then(resp=>{
                    this.props.getDocumentData(this.props.params.id)
                    if(resp?.msg == 'success'){
                        Swal.fire(
                            'Approved!',
                            'Document approved successfully',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                        )
                    }
                })
              
            }
          })
        
    }

    sendComment(data, value, e){
        e.preventDefault();
        if(value.trim() != ''){
            this.setState({btnVisible:true});
            let url= '/add-document-comment';
            let formData = new FormData();
            formData.append('title', value)
            formData.append('document_id', data?.id);
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({commentTitle: ''});
                this.setState({btnVisible:false});
                this.props.getDocumentData(this.props.params.id)
            })
        }
    }
    deleteComment(id){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.deleteData(`delete-document-comment/${id}`)
                .then(resp=>{
                    this.props.getDocumentData(this.props.params.id)
                    if(resp == 'success'){
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                        )
                    }
                })
            
            }
        })
    }
  render() {
    const {documentData, user, documentsModal} = this.props;
    const {commentTitle, btnVisible} = this.state;
    return (
      <div className='container'>
        {
            documentData != ''?
            <div>
                <SectionHeader title={documentData?.title} />
                
                <div>
                    <div className='flex gap-4 items-center'>
                        <img className='w-10 h-10 rounded-full' src='https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/340px-Default_pfp.svg.png?20220226140232' />
                        <h4 className='text-lg font-semibold'>{documentData?.user?.full_name}</h4>
                        {
                            user?.role == 'uni_rep'?
                            <button onClick={this.openEditModal.bind(this, documentData)} className='w-6 h-6 cursor-pointer'>
                                <EditSvg />
                            </button>:null
                        }
                        {
                            user?.role == 'uni_rep' && documentData?.status == 0?
                            <button onClick={this.approveBtn.bind(this, documentData)} className='p-1 text-sm font-semibold border rounded-xl text-white bg-blue-700 cursor-pointer'>
                                Approve
                            </button>:null
                        }
                        
                    </div>
                    {/* <div className='mt-4' dangerouslySetInnerHTML={{ __html: documentData?.description}}></div> */}
                    <a className='bg-blue-600 p-2 mt-4 inline-block rounded-xl text-sm font-bold text-white' href={documentData?.file_full_url} target='_blank'>Download File</a>
                    <div className='bg-slate-100 mt-10 p-3 rounded-xl'>
                        <form className='flex justify-between items-center gap-4'>
                            <input value={commentTitle} onChange={(e)=>this.setState({commentTitle:e.target.value})} type='text' placeholder='Write' />
                            <button disabled={btnVisible} onClick={this.sendComment.bind(this, documentData, commentTitle)} className='bg-blue-500 text-white p-2 rounded-xl cursor-pointer text-sm font-semibold'>Send</button>
                        </form>
                        <div className='flex flex-col gap-3 p-2 max-h-96 overflow-y-auto'>
                            {
                                documentData?.comments?.map((data, i)=>{
                                    return(
                                        <div key={i} className='bg-white p-2 rounded-xl '>
                                            <div className='flex items-center gap-3'>
                                                <h3 className='text-sm font-semibold'>{data?.user?.full_name}</h3>
                                                {
                                                    user?.role == 'uni_rep' || user?.id == data?.user_id?
                                                    <button onClick={this.deleteComment.bind(this, data?.id)} className='cursor-pointer'>
                                                        <DeleteIcon />
                                                    </button>:null
                                                }
                                                
                                            </div>
                                            <p className='text-sm '>{data?.title}</p>
                                        </div>
                                    )
                                })
                            }
                            
                        </div>
                    </div>
                    {
                        documentsModal?.position?
                        <DocumentModal />:null
                    }
                </div>
            </div>:null
        }
        
            
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    documentData: state.Data.documentData,
    documentsModal: state.Data.documentsModal,
    user: state.Data.user,
});
const mapDispatchToProps = {getDocumentData, changeStateValue, insertNewData, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(DocumentData))
