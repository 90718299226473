import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAllCountries, getStateByCountry, getcityByCountryAndState, getUniByCities, getPrograms, getEducationDegree } from '../../../../../actions/MainAction';
import Searchable from 'react-searchable-dropdown'; 
import SelectDefault from './components/SelectDefault';
import { changeStateValue } from '../../../../../redux/MainReducer';
import { insertNewData } from '../../../../../actions/WorkerActions';
import Swal from 'sweetalert2';
export class ProgramApplication extends Component {
  state={
    country_id:'',
    state_id:'',
    city_id:'',
    city_name:'',
    university_id:'',
    university_name:'',
    program_id:'',
    program_name:'',
    degree_id:'',
    student_id: this.props.student_id,
    btnDisable:false
  }
  componentDidMount(){
    this.props.getAllCountries('','', 1);
    this.props.getEducationDegree(1)
  }
  setCountry=(id)=>{
    this.setState({country_id:id, state_id:'', city_id:'', university_id:'', university_name:'', program_id:'', program_name:"", degree_id:''})
    this.props.getStateByCountry(null, 1, id);
    this.props.getcityByCountryAndState(1, id, "");
    this.props.changeStateValue({name:"stateByCountry", value:[]})
    this.props.changeStateValue({name:"citiesByCountryAndState", value:[]})
    this.props.changeStateValue({name:"uniByCities", value:[]})
    this.props.changeStateValue({name:"programs", value:[]})
  }
  setStates=(id)=>{
    this.props.getcityByCountryAndState(1, this.state.country_id, id);
    this.setState({state_id:id, city_id:'', university_id:'', university_name:'', program_id:'', program_name:'', degree_id:''})
    this.props.changeStateValue({name:"citiesByCountryAndState", value:[]})
    this.props.changeStateValue({name:"uniByCities", value:[]})
    this.props.changeStateValue({name:"programs", value:[]})
  }
  setCity=(id)=>{
    this.setState({city_id:id, university_id:'', university_name:'', program_id:'', program_name:'', degree_id:''});
    this.props.getUniByCities([id])
    this.props.changeStateValue({name:"uniByCities", value:[]})
    this.props.changeStateValue({name:"programs", value:[]})
  }
 

  setUni=(id)=>{
    this.setState({university_id:id, program_id:'', program_name:'', degree_id:''});
    this.props.changeStateValue({name:"programs", value:[]})
  }
  setDegree=(id)=>{
    this.setState({degree_id:id, program_id:'', program_name:''});
    if(this.state.university_id !=""){
      this.props.getPrograms(100, 1, this.state.university_id, "", "public-programs", "", "", "", id);
    }
    this.props.changeStateValue({name:"programs", value:[]})
  }
  setUniName=(value)=>{
    this.setState({university_name:value, program_id:'', program_name:''})
  }
  setProgram=(id)=>{
    this.setState({program_id:id, program_name:''});
  }
  setProgramName=(value)=>{
    this.setState({program_name:value});
  }
  setCityName=(value)=>{
    this.setState({city_name:value});
  }

  applyBtn=(data)=>{
    this.props.changeStateValue({name:"loaderLong", value:true})
    this.setState({btnDisable:true})
    let formData = new FormData();
    for(const property in data){
      formData.append(property, data[property])
    }
    this.props.insertNewData("/student-auto-apply", formData)
    .then(resp=>{
      this.props.changeStateValue({name:"loaderLong", value:false})
      this.setState({btnDisable:false})
      if(resp?.msg === "success"){
        this.props.onClick()
        Swal.fire(
            'Success',
            'Applied successfully',
            'success'
          )
    }else{
        Swal.fire(
            'Error',
            'Something went wrong',
            'error'
          )
    }
    })
  }
  render() {
    const {allCountries, stateByCountry, citiesByCountryAndState, uniByCities, programs, educationDegree} = this.props;
    const {country_id, state_id, city_id, university_id, university_name, program_id, program_name, degree_id, btnDisable, city_name} = this.state;
    let countriesOption = [];
    for(const data of allCountries){
      countriesOption.push({
        label: data?.name,
        value: data?.countries_id
      })
    }
    let stateOption = [];
    for(const data of stateByCountry){
      stateOption.push({
        label: data?.name,
        value: data?.state_id
      })
    }
    let cityOption = [];
    for(const data of citiesByCountryAndState){
      cityOption.push({
        label: data?.name,
        value: data?.city_id
      })
    }
    let uniOption = [];
    for(const data of uniByCities){
      uniOption.push({
        label: data?.name,
        value: data?.institutions_id
      })
    }
    let programOption = [];
    for(const data of programs){
      programOption.push({
        label: data?.name,
        value: data?.program_id
      })
    }
    let degreeOption = [];
    for(const data of educationDegree){
      degreeOption.push({
        label: data?.education_type,
        value: data?.education_degree_id
      })
    }

    return (
      <div className='mt-[20px]'>
        <div className='grid grid-cols-3 gap-[20px]'>
          <div className='bg-white rounded-[6px]'>
              <Searchable 
                  options={countriesOption}
                  onSelect={this.setCountry.bind(this)}
                  placeholder={ "Select a country"}
              />
          </div>
          {
            stateOption.length !=0?
            <div className='bg-white rounded-[6px]'>
                <Searchable 
                    options={stateOption}
                    onSelect={this.setStates.bind(this)}
                    placeholder={ "Select a state"}
                />
            </div>:null
          }
          {
            country_id !=""?
            <div className='bg-white rounded-[6px]'>
                <SelectDefault 
                    options={cityOption}
                    onSelect={this.setCity.bind(this)}
                    onChangeText={this.setCityName.bind(this)}
                    placeholder={ "Select a city or write name"}
                />
            </div>:null
          }
          {/* {
            cityOption.length !=0?
            <div className='bg-white rounded-[6px]'>
                <Searchable 
                    options={cityOption}
                    onSelect={this.setCity.bind(this)}
                    placeholder={ "Select a city"}
                />
            </div>:null
          } */}
         
          {
            city_id !="" || city_name !=""?
            <div className='bg-white rounded-[6px]'>
              <SelectDefault 
                  options={uniOption}
                  onSelect={this.setUni.bind(this)}
                  onChangeText={this.setUniName.bind(this)}
                  placeholder={ "Select a university or write name"}
              />
          </div>:null
          }
           {
             university_id !="" || university_name !=""?
            <div className='bg-white rounded-[6px]'>
                <Searchable 
                    options={degreeOption}
                    onSelect={this.setDegree.bind(this)}
                    placeholder={ "Select a degree"}
                />
            </div>:null
          }
          {
           degree_id !=""?
          <div className='bg-white rounded-[6px]'>
                <SelectDefault 
                    options={programOption}
                    onSelect={this.setProgram.bind(this)}
                    onChangeText={this.setProgramName.bind(this)}
                    placeholder={ "Select a program or write name"}
                />
            </div>:null
          }
          
        </div>
        <div className='flex justify-end items-center mt-[10px]'>
          {
            program_id !="" || program_name !=""?
            <button disabled={btnDisable} onClick={this.applyBtn.bind(this, this.state)} className='bg-blue-700 text-white text-[12px] p-[2px_5px] rounded-[6px]'>
              Apply
            </button>:
             <button  className='bg-gray-500 text-white text-[12px] p-[2px_5px] rounded-[6px]'>
              Apply
            </button>
          }
           
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  allCountries: state.Data.allCountries,
  stateByCountry: state.Data.stateByCountry,
  citiesByCountryAndState: state.Data.citiesByCountryAndState,
  uniByCities: state.Data.uniByCities,
  programs: state.Data.programs,
  educationDegree: state.Data.educationDegree,
});
const mapDispatchToProps = {getAllCountries, changeStateValue, getStateByCountry,insertNewData, getcityByCountryAndState, getUniByCities, getPrograms, getEducationDegree}
export default connect(mapStateToProps, mapDispatchToProps)(ProgramApplication)
