import React, { Component } from 'react'
import GoogleLogin from 'react-google-login'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loginAction, getPrograms, getStudentInfo, getSimulatorPrograms, getStateByCountry, getcityByCountryAndState, loginSocialsAction } from '../../../../../actions/MainAction';
import { getObject } from '../../../../../actions/makeFilterObj';
import { getWorkerPrograms } from '../../../../../actions/WorkerActions';
import { GOOGLE_CLIENT_ID } from '../../../../../MAIN_API';
import { changeStateValue } from '../../../../../redux/MainReducer';
import AppleIdLogin from '../../../../page_component/AppleIdLogin';
import EmailSvg from './EmailSvg';
function withParams (Component){
    return props => <Component {...props} params={useParams()} />
}
export class Login extends Component {
    state={
        clientId : GOOGLE_CLIENT_ID,
        username: '',
        password:'',
        errorState: false,
        errorStateSocial: false
    }
     onSuccess = (res) =>{
   
        if(res.profileObj.email){
    
          this.props.loginSocialsAction(res.profileObj.email).then(resp=>{
            if(resp === 'success'){
                this.props.changeStateValue({
                    name: 'simulatorLogin',
                    value:  false
                })
            }else{
                this.setState({
                    errorStateSocial: true
                })
            }
          })
        }
        
      }

    changeInpValue(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    loginBtn(e){
        e.preventDefault();
        if(this.state.username !== '' && this.state.password !== ''){
            this.props.loginAction(this.state.username, this.state.password)
            .then(resp=>{
               if(resp == 'success'){
                this.props.changeStateValue({
                    name: 'simulatorLogin',
                    value:  false
                })
               }else{
                this.setState({
                    errorState: true
                })
               }
                
            })
            
        }
        
    }
    showManualInput(visible, e){
        this.setState({
            visible: !visible
        })
    }
  render() {
    const {clientId, username, password, visible, errorStateSocial, errorState} = this.state
    const {mainTransateData} = this.props
    return (
      <div>
        <h2 aria-label='mainTransateData.sign_in' className="my-2 flex items-center  mb-3 text-3xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            {mainTransateData?.sign_in}
        </h2>
        <div className="grid gap-3" id='google_login'>
        {/* <div className="grid gap-3">
            <AppleIdLogin />
        </div> */}
        <GoogleLogin 
            clientId={clientId}
            buttonText="Continue with Google"
            onSuccess={this.onSuccess.bind(this)}
            // onFailure={this.onFailure()}
            cookiePolicy="single_host_origin"
            isSignedIn={false}
            theme='dark'
        />
        {
                errorStateSocial?
                <p style={{ marginTop: '-10px' }} className='invalid_login'>Invalid incredintials</p>:null
            }
        </div>
        <div className="relative text-center">
            <span aria-label='mainTransateData.or' className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                {mainTransateData.or}
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
        </div>
        <div className='manual_login_btn_container' onClick={this.showManualInput.bind(this, visible)}>
            <EmailSvg />
            <span aria-label='mainTransateData.signin_email'>{mainTransateData?.signin_email}</span>
        </div>
        {
            visible?
            <form className="grid grid-cols-1 gap-6" action="#" method="post">
           
            <label className="block">
                <span aria-label='mainTransateData.email' className="text-neutral-800 dark:text-neutral-200">
                {mainTransateData?.email}
                </span>
                
                <input type={'email'}
                onChange={this.changeInpValue.bind(this)}
                value={username}
                name="username"
                className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                placeholder="example@example.com"
                /> 
            </label>
            <label className="block">
                <span aria-label='mainTransateData.password' className="text-neutral-800 dark:text-neutral-200">
                    {mainTransateData?.password}
                </span>
                
                <input type={'password'}
                onChange={this.changeInpValue.bind(this)}
                value={password}
                name="password"
                className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                placeholder="*********"
                />
            </label>
            {
                errorState?
                <p style={{ marginTop: '-20px' }} className='invalid_login'>Invalid incredintials</p>:null
              }
            <button aria-label='mainTransateData.sign_up' onClick={this.loginBtn.bind(this)} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
            text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>
                {mainTransateData?.sign_up}
            </button>
           
            {/* <ButtonPrimary type="submit">Continue</ButtonPrimary> */}
        </form>:null
        }
        
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              <span aria-label='mainTransateData.new_user'>{mainTransateData?.new_user}</span>{` `}
              <button onClick={()=>this.props.changePage('register')} aria-label="mainTransateData.create_acc">{mainTransateData?.create_acc}</button>
            </span>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    languageId: state.Data.languageId,
    mainTransateData: state.Data.mainTransateData,
});
const mapDispatchToProps = {loginAction, changeStateValue, getPrograms, getWorkerPrograms, getStudentInfo, getSimulatorPrograms, getStateByCountry, getcityByCountryAndState, loginSocialsAction}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(Login))
