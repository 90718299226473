import React, { Component } from 'react'
import CardBody from './CardBody';
import { connect } from 'react-redux';
import { changeProgramStatus, getAllRoboUniversity } from '../../actions/AutoLoadActions';
import Swal from 'sweetalert2';
export class ProgramCard extends Component {
  state={
    visibile: false,
    cardref: React.createRef()
  }
  deleteProgram(id){

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        let formData = new FormData()
        formData.append('deleted_status', '1');
        formData.append("_method", "put")
        this.props.changeProgramStatus(`change-status/${id}`, formData)
        .then(resp=>{
          this.props.getAllRoboUniversity();
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        })
       
      }
    })
    
  }

  getExams(arr){
    let str = '';
    for(const property of arr){
      str+= `${property?.title}: ${property?.grade}; `
    }
    return str;
  }

  render() {
    const {data, institution_id,examsAll=[],deadlines, selectAllPrograms, studyDuration,startDates=[],examCheckStatus=false,highSchExAll,setDataToArr=()=>{},allUploadProg=[], feeAmountAll='',feeType='0',currencyAll='', eduLangid, deadline, requiredEduDegree,disciplineAll=[], applicationFee, checkExists, gpa='0', gpaPercentage=0, examPercentage=''} = this.props;
    const {visibile, cardref} = this.state
    return (
      <div className='border p-2 mt-4 rounded-2xl' style={checkExists?{border: '2px solid green'}: {}}>
       
          <div className='flex justify-between items-center' onClick={()=>this.setState({visibile: !visibile})}>
            <h2 style={{ color:  data?.deleted_status == 1? 'red': '' }} className='cursor-pointer'>{data?.name} ({data?.deadline}) ({data?.discipline}) <br /> {this.getExams(data?.exams)}</h2>
            <div className='flex items-center gap-3'>
              <p>({data?.study_mode})</p>
            {
              checkExists?
              <p>Exists</p>: <p>Not Found</p>
            }
            {
              data?.added_status == 0?
              <button onClick={this.deleteProgram.bind(this, data?.id)} className='bg-red-700 p-2 cursor-pointer rounded-2xl text-white text'>Delete</button>:
              data?.deleted_status == 1?
              <p className='p-1 rounded-2xl text-white bg-red-700'>Deleted</p>:
              data?.added_status == 1?
              <p className='p-1 rounded-2xl text-white bg-lime-700'>Added</p>:null
            }
            </div>
            
          </div>
          {
             data?.added_status == 0 && data?.deleted_status == 0 ?
            <CardBody 
            setDataToArr={setDataToArr}
            cardVisibile={visibile}
            examPercentage={examPercentage} 
            gpaPercentage={gpaPercentage} 
            gpa={gpa} applicationFee={applicationFee} 
            deadline={deadline} 
            requiredEduDegree={requiredEduDegree} 
            eduLangid={eduLangid} 
            institution_id={institution_id}
            disciplineAll={disciplineAll}
            examsAll={examsAll}
            currencyAll={currencyAll}
            feeAmountAll={feeAmountAll}
            feeType={feeType}
            highSchExAll={highSchExAll}
            allUploadProg={allUploadProg}
            examCheckStatus={examCheckStatus}
            startDates={startDates}
            deadlines={deadlines}
            studyDuration={studyDuration}
            selectAllPrograms={selectAllPrograms}
             programData={data} />:null
          }
           
      </div>
    )
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {changeProgramStatus, getAllRoboUniversity}
export default connect(mapStateToProps, mapDispatchToProps)(ProgramCard)
