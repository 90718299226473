import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAppTrackTranslator } from '../../actions/TranslateAction';
import SectionHeader from '../../components/page_component/SectionHeader';
import TranslateCard from './components/TranslateCard';
export class TranslatorApplication extends Component {
    componentDidMount(){
        this.props.getAppTrackTranslator()
    }

    getAvailableData(data, item){
        // console.log(data)
        let array = [];
        for(const property in data.default){
         
          if(property == 'title'){
            let x = []
            x.push(
              `${property}`, data[item]&&data[item][property]?data[item][property]:''
            )
          array.push(x)
    
          }
        }
    
        return array
      }
      getAfterSave = () =>{
        this.props.getAppTrackTranslator()

      }
  render() {
    const {appTrackTranslate, user} = this.props;

    return (
      <div className='container'>
        {
          user != ''?
          <SectionHeader  title={`Translate Application From English to ${user?.lang?.main_lang?.name}`} />:null
        }
        <ul>
          {
            appTrackTranslate.map((data, i)=>{
              return(
                <TranslateCard getAfterSave={this.getAfterSave} url={`setAppTrackTranslate/${appTrackTranslate[i].default.track_id}`} key={i} data={this.getAvailableData(data, 'default')} translate={this.getAvailableData(data, 'translate')} />
              )
            })
          }
        </ul>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    appTrackTranslate: state.Data.appTrackTranslate,
    user: state.Data.user,
});
const mapDispatchToProps = { getAppTrackTranslator }
export default connect(mapStateToProps, mapDispatchToProps)(TranslatorApplication)