import React, { Component } from 'react'

export class BlogsCard extends Component {
    state={
        readMore:false
    }
  render() {
    const {data, onClickEdit=()=>{}} = this.props;
    const {readMore} = this.state;
    console.log(data)
    return (
        <div className='border p-[20px] rounded-[20px] '>
            <h1 className='text-center text-[22px] font-bold'>{data?.title}</h1>
            <p className='text-[15px] mt-[20px]'>{data?.description}</p>
            <div className='mt-[20px]'>
                {
                    data?.paragraph?.map((item, j)=>{
                        return(
                            <div className='mt-[30px]' key={j}>
                                 <h2 className='text-[20px] font-semibold'>{item?.title}</h2>
                                 <p  className='text-[15px] mt-[20px]'>{item?.introduction}</p>
                            </div>
                        )
                    })
                }
               
            </div>
            {/* <button className='bg-blue-600 text-white text-[14px] font-semibold pl-[10px] pr-[10px] pt-[6px] pb-[6px] rounded-[10px]' onClick={()=>onClickEdit()}>
                Edit
            </button>
            <div style={readMore?{ height:'auto' }:{height:'200px'}} className='overflow-hidden transition testBlogCont' dangerouslySetInnerHTML={{ __html: data?.description }}></div>
            <div className='flex justify-center mt-[20px]'>
                <button onClick={()=>this.setState({readMore: !readMore})} className='bg-blue-600 text-white text-[14px] font-semibold pl-[10px] pr-[10px] pt-[6px] pb-[6px] rounded-[10px]'>{readMore?"Show Less":"Read More"}</button>
            </div> */}
        </div>
    )
  }
}

export default BlogsCard
