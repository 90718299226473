import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {getCityData, getInstitutions} from '../actions/MainAction'
import InstitutionsCard from '../components/page_component/institutions/InstitutionsCard';
import SectionHeader from '../components/page_component/SectionHeader';
import ViewAllBtn from '../components/page_component/ViewAllBtn';
import {mainDashboardTranslate,getUnirepRoleTranslate } from '../actions/TranslateAction';

function withParams(Component){
    return props => <Component {...props} params={useParams()} />
}
export class City extends Component {
    componentDidMount(){
        window.scrollTo(0, 0)
        this.props.getCityData(this.props.languageId, this.props.params.id)
        this.props.getInstitutions(1, '', this.props.languageId, '', this.props.params.id, '');
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.mainDashboardTranslate(this.props.languageId);
    }
  render() {
    const {cityData, institutions, mainTransateData} = this.props;
   
    return (
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
            <div className="relative py-16">
                <div className={`nc-SectionGridFilterCard pb-24 lg:pb-28`} data-nc-id="SectionGridFilterCard" >
                    
                    <SectionHeader arialLabelTitle="mainTransateData.city_single_title" title={`${mainTransateData?.city_single_title} ${cityData.name}`} description={cityData.description} />
                    
                    <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                        {
                            institutions && institutions.map((data, i)=> {
                                if(i < 8){
                                    return(
                                        <InstitutionsCard key={i} institutions={data} />
                                    )
                                }
                            })
                        }
                        
                    </div>
                    {
                        <ViewAllBtn href="/institutions" />
                    }
                    
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps  = (state) => ({
    cityData: state.Data.cityData,
    languageId: state.Data.languageId,
    institutions: state.Data.institutions,
    mainTransateData: state.Data.mainTransateData,
});
const mapDispatchToProps = {getCityData, getInstitutions, mainDashboardTranslate,getUnirepRoleTranslate }
export default connect(mapStateToProps, mapDispatchToProps)(withParams(City))