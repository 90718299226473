import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import avatar1 from "../../../images/default.jpg";
import { changeStateValue } from '../../../redux/MainReducer';
import { removeAssign, getStudents } from '../../../actions/WorkerActions';
import EditSvg from '../../../components/page_component/EditSvg';
export class Card extends Component {

  addAssignBtn(id, staff, e){
    this.props.changeStateValue({
      name: 'assignStudentModal',
      value:{
        position: true,
        student_id: id,
        worker: staff?staff: []
      }
    })
  }

  removeWorker(id, e){

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      
        if (result.isConfirmed) {
         this.props.removeAssign(id)
         .then(resp=>{
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
          this.props.getStudents('assign_student')
        })
        }
      
     
    })
  }

  editSvgBtn = (data) =>{
       
    this.props.changeStateValue({
        name: 'editUserState',
        value:{
            position: true,
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            password: '',
            image: data.image_full_url
        }
    }) 
}
  render() {
    const {data, button, changeApplicationModal} = this.props
    return (
        <div
        // to={'/assign-student'}
        className={`nc-CardAuthorBox relative flex flex-col  text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}
        data-nc-id="CardAuthorBox"
      >
        <div className='w-5 absolute left-3 top-3' >
            <EditSvg editSvgBtn={this.editSvgBtn.bind(this, data)} />
        </div>
         <div className={`wil-avatar relative flex-shrink-0 inline-flex ml-auto mr-auto items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full w-20 h-20 text-2xlring-1 ring-white dark:ring-neutral-900`}>
            <img
              className={` inset-0 w-full h-full object-cover rounded-full`}
              src={data.image_full_url&& data.image_full_url != '' ? data.image_full_url:avatar1}
            />
        </div>
        <div className="mt-3">
            <h2 className={`text-base font-medium`}>
                <span className="line-clamp-1">{data.full_name}</span>
            </h2>
            <span className={`block mt-1.5  text-sm text-neutral-500 dark:text-neutral-400 wrap`} >
              {data.email.length > 20? `${data.email.substring(0, 20)}...`:data.email}
            </span>
            {
              data.staff?
              <>
              <div className="w-14 mt-3 border-b border-neutral-200 dark:border-neutral-700"></div>

              <ul >
              {
                data.staff.map((user, index)=>{
                  return(
                    <li className='block mt-1.5 text-start text-sm text-neutral-500 dark:text-neutral-400 wrap flex items-center justify-between' key={index}>
                      <span>{user.user.full_name.length >16?`${user.user.full_name.substring(0, 16)}...`: user.user.full_name}</span>
                      <svg onClick={this.removeWorker.bind(this, user.id)} style={{ cursor: 'pointer' }} width="10" height="10" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.32867 7.50003L14.8284 1.00029C15.0572 0.771452 15.0572 0.400437 14.8284 0.171628C14.5995 -0.0571802 14.2285 -0.0572095 13.9997 0.171628L7.49998 6.67137L1.00029 0.171628C0.77145 -0.0572095 0.400436 -0.0572095 0.171628 0.171628C-0.05718 0.400466 -0.0572093 0.771482 0.171628 1.00029L6.67133 7.5L0.171628 13.9997C-0.0572093 14.2286 -0.0572093 14.5996 0.171628 14.8284C0.286032 14.9428 0.436002 15 0.585972 15C0.735943 15 0.885884 14.9428 1.00032 14.8284L7.49998 8.32869L13.9997 14.8284C14.1141 14.9428 14.2641 15 14.414 15C14.564 15 14.7139 14.9428 14.8284 14.8284C15.0572 14.5996 15.0572 14.2286 14.8284 13.9997L8.32867 7.50003Z" fill="#979797"/>
                      </svg>
                    </li>
                  )
                })
              }
              {
                data.teacher?.length != 0?
                <div className="w-14 mt-3 border-b border-neutral-200 dark:border-neutral-700"></div>:null
              }
              
              {
                data.teacher.map((user, index)=>{
                  return(
                    <li className='block mt-1.5 text-start text-sm text-neutral-500 dark:text-neutral-400 wrap flex items-center justify-between' key={index}>
                      <span>{user.teacher.full_name.length >16?`${user.teacher.full_name.substring(0, 16)}...`: user.teacher.full_name}</span>
                      
                    </li>
                  )
                })
              }
            </ul>
            </>:null
            }
            
            
                <button onClick={this.addAssignBtn.bind(this, data.id, data.staff)} className='mt-3 ttnc-ButtonThird text-neutral-700 border 
                border-neutral-200 dark:text-neutral-200 
                dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                items-center justify-center rounded-full transition-colors
                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                px-4 py-1 sm:px-3 bg-hover-primary dark:hover:text-primary-100 hover:text-primary-900 hover:bg-primary-100 dark:hover:bg-primary-800' style={{ fontSize: '12px' }}  >
                Assign to worker
                </button>
            {
              !button?
            <button onClick={()=>changeApplicationModal(data)} className='text-[12px] font-semibold border p-1 mt-2 rounded-full text-gray-600'>Change Application</button>            
              :null
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue, removeAssign, getStudents}
export default connect(mapStateToProps, mapDispatchToProps)(Card)