import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import { getStudentNotes } from '../../actions/WorkerActions';
export class StudentNote extends Component {
    openModal(id){
        this.props.changeStateValue({
            name: 'studentNoteModal',
            value: {
                position:true,
                student_id:id,
                note:'',
                id:''
            }
        })
        this.props.getStudentNotes(id)
    }
  render() {
    return (
      <div>
        <button onClick={this.openModal.bind(this, this.props.student_id)} className='border rounded-3xl p-3 text-sm ml-3 mt-4'>
           Add Note
        </button>
        
      </div>
    )
  }
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {changeStateValue, getStudentNotes}

export default connect(mapStateToProps, mapDispatchToProps)(StudentNote)
