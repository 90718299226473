import React, { Component } from 'react'
import Swal from 'sweetalert2';
import { insertNewData } from '../../../../../../actions/WorkerActions';
import { connect } from 'react-redux';

export class ArchiveBtn extends Component {

    changearchiveStatus=(id="")=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it'
          }).then((result) => {
            if (result.isConfirmed) {
                this.setState({btnVisible:true})
                let formData = new FormData();
            
                formData.append("_method", "put");
                this.props.insertNewData(`/archive-programs/${id}`, formData)
                .then(resp=>{
                    // this.setState({btnVisible:false})
                    this.props.getAfterChange()
                    if(resp.msg == 'success'){
                        Swal.fire(
                            'Changed!',
                            'Archive status changed successfully.',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                        )
                    }
                })
             
            }
          })
    }
  render() {
    const {id, archive} = this.props;
    // console.log(program_id, student_id, archive)
    // console.log(archive)
    return (
      <label htmlFor='comBtnId'>
        {/* <input onChange={this.changearchiveStatus.bind(this,id)} checked={archive} idcomBtnId type='checkbox' className='cursor-pointer' /> */}
        <button  onClick={this.changearchiveStatus.bind(this,id)} className='w-[20px] h-[20px] min-w-[20px] border rounded-[4px] border-blue-600 inline-flex justify-center items-center'>
         {archive? <span className='inline-block w-[10px] h-[10px] bg-blue-600'></span>:null }
        </button>
        {/* <span>Will receive commission?</span> */}
      </label>
    )
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(ArchiveBtn)


