import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { img_path } from '../../../MAIN_API';
import { getMessagesUserList } from '../../../actions/MainAction';
import ArchiveSVG from './ArchiveSVG';
export class UserList extends Component {
  state={
    archiveStatus: 0
  }
  componentDidMount(){
    this.props.getMessagesUserList(this.props.languageId)
    .then(data=>{
     
      if(data?.length >0){
        if(this.props.user?.role == "student"){
          const {user} = this.props
          this.props.joinChatRoom(data[0].student_id, data[0].program_id, user != '' && user?.role != 'student'? data[0].student?.full_name:data[0]?.program?.translate?.name, user != '' && user?.role != 'student'? data[0].program?.translate?.name:data[0]?.program?.institution?.translate?.name,user != '' && user?.role != 'student'?  data[0]?.student?.image_full_url &&  data[0]?.student?.image_full_url != ''?data[0]?.student?.image_full_url: '': data[0]?.program?.institution?.logo_full_url,   user != '' && user?.role == "edu_consultant"?data[0]?.program?.institution?.translate?.name:'')
        }
      }
     
    })
  }
  render() {
    const {data, joinChatRoom, search, messagePage, messagesUserList, user} = this.props;
    const {archiveStatus}= this.state;

    return (
      <div className='usr_list_cont' style={{ height: '60vh', overflow: 'auto' }}>

        {user?.role == 'edu_consultant'? messagesUserList?.find(item=>item?.archive_status == 1) && archiveStatus == 0?
          <div className='flex justify-center'>
            <p onClick={()=>this.setState({archiveStatus:1})} className='flex border w-full justify-center h-8 items-center cursor-pointer'>Go to Archives  <ArchiveSVG /></p>
            
          </div>:null:null
      }
        {user?.role == 'edu_consultant'? archiveStatus == 1?
          <div className='flex justify-center'>
            <p onClick={()=>this.setState({archiveStatus:0})} className='flex border w-full justify-center h-8 items-center cursor-pointer'>Go to Inbox </p>
            
          </div>:null:null
      }
        <ul className='messages_user_list'>
          {
            messagesUserList.map((item, i)=>{
              if(item?.archive_status== archiveStatus ){
             
                  if(search.trim()  == '' || (search.trim() != '' && item?.student?.full_name.toLowerCase().includes(search.trim().toLowerCase()) || item?.program?.translate?.name.toLowerCase().includes(search.trim().toLowerCase()))){
                    return(
                      <li className={item?.program_id == messagePage?.program_id && item?.student_id == messagePage?.student_id? 'active_chat':''} key={i} onClick={()=>joinChatRoom(item.student_id, item.program_id, user != '' && user?.role != 'student'? item.student?.full_name:item?.program?.translate?.name, user != '' && user?.role != 'student'? item.program?.translate?.name:item?.program?.institution?.translate?.name,user != '' && user?.role != 'student'?  item?.student?.image_full_url &&  item?.student?.image_full_url != ''?item?.student?.image_full_url: '': item?.program?.institution?.logo_full_url,   user != '' && user?.role == "edu_consultant"?item?.program?.institution?.translate?.name:'',item?.id, item?.student?.finance_lock)}>
                        {
                          user != '' && user?.role != "student"?
                          item?.student?.image_full_url &&  item?.student?.image_full_url != ''?
                          <img src={item?.student?.image_full_url} />:
                          <img src='https://i.pinimg.com/280x280_RS/2e/45/66/2e4566fd829bcf9eb11ccdb5f252b02f.jpg' alt="User" />:
                          <img src={item?.program?.institution?.logo_full_url} alt={item?.program?.institution?.translate?.name} />
                        }
                        
                        <div className='msg_user_info'>
                          <p>
                            {
                               user != '' && user?.role != "student"?
                               <span className='msg_pr_name'>{item?.student?.full_name}</span>:
                               <span className='msg_pr_name'>{item?.program?.translate?.name}</span>
                            }
                           
                            <span className='msg_time'>{moment(item.updated_at).format('HH:mm')}</span>
                          </p>
                          <p>
                            {
                               user != '' && user?.role != "student"?
                               <span className='msg_user_name'>{item?.program?.translate?.name}</span>:
                               <span className='msg_user_name'>{item?.program?.institution?.translate?.name}</span>
                            }
                            
                            {
                              item.unread != 0?
                              <span className='msg_count'>
                             {item.unread}
                            </span>:null
                            }
                           
                          </p>
                         <p>
                         {
                               user != '' && user?.role == "edu_consultant"?
                               <span className='msg_user_name'>{item?.program?.institution?.translate?.name?.length >30? `${item?.program?.institution?.translate?.name.substring(0, 30)}...`:item?.program?.institution?.translate?.name}</span>:null
                            }
                           
                         </p>
                        </div>
                      </li>
                    )
                  }
                
              }
            })
          }
        </ul>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  messagesUserList: state.Data.messagesUserList,
  languageId: state.Data.languageId,
  user: state.Data.user,
});
const mapDispatchToProps = {getMessagesUserList}
export default connect(mapStateToProps, mapDispatchToProps)(UserList)