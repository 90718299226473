import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
export class Input extends Component {
    changeInputValue(e){

        this.props.changeStateValue({
          name: e.target.name,
          value: e.target.value
        })
      }
  render() {
    const {label, type="text", placeholder="", value="", name, ariaLabel} = this.props;
    return (
        <div>
            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" aria-label={ariaLabel}>
            {label}
            </label>
            <input name={name} onChange={this.changeInputValue.bind(this)} type={type}
                className={`block w-full border-neutral-200 focus:border-primary-300 
                focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                placeholder={placeholder}
                value={value}
            /> 
        </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Input)