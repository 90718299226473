import React, { Component } from 'react'
import SectionHeader from '../../../components/page_component/SectionHeader'
import Modals from './Modals'
import { connect } from 'react-redux'
import { getBlogs, generateBlogs } from '../../../actions/WorkerActions'
import BlogParentCard from './BlogParentCard'
export class BlogTopics extends Component {
    state={
        modalData: {
            position:false
        }
    }
    componentDidMount(){
        this.props.getBlogs()
    }
    openModal=(data) =>{
        this.setState({
            modalData: {
                position:true,
                id: data?.id?data?.id:'',
                title: data?.title?data?.title:'',
                lang_id: data?.lang_id?data?.lang_id:'',
                prompt: data?.prompt?data?.prompt:'',
            }
        })
    }
  render() {
    const {modalData} = this.state;
    const {blogTopics} = this.props;
    return (
        <div className='container'>
            <div className="flex flex-col mt-10 relative">
                <SectionHeader arialLabelTitle="mainTransateData.content_title"  title={"Blog Topics"} />
            </div>
            <button onClick={()=>{this.props.generateBlogs()}} className='bg-blue-700 p-[8px]  text-[15px] rounded-full text-white font-semibold'>Generate</button>

            <div className='flex justify-end'>
                <button onClick={this.openModal.bind(this, '')} className='bg-blue-700 p-[8px]  text-[15px] rounded-full text-white font-semibold'>Add Topics</button>
            </div>
            <div className='mt-[20px] bg-gray-100 p-[20px] rounded-[12px] gap-[10px] flex flex-col'>
                {
                    blogTopics.map((data, i)=>{
                        return(
                            <BlogParentCard 
                                key={i}
                                data={data}
                                onClickEdit={this.openModal.bind(this)}
                                getAfterChange={()=>this.props.getBlogs()}
                            />
                        )
                    })
                }
            </div>
            {
                modalData?.position? <Modals onClose={()=>{ this.props.getBlogs();this.setState({modalData:{position:false}})}} data={modalData} />:null
            }
            
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    blogTopics: state.Data.blogTopics
});
const mapDispatchToProps = {getBlogs, generateBlogs}
export default connect(mapStateToProps, mapDispatchToProps)(BlogTopics)
