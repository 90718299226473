import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCountryEduDegree } from '../../../actions/WorkerActions';
import { changeStateValue } from '../../../redux/MainReducer';
import Maincard from './components/Maincard';
import  MainModal from './modals/MainModal'
import { getAllCountries, getEducationDegree } from '../../../actions/MainAction';
export class CountryEduDegree extends Component {
    componentDidMount(){
        this.props.getCountryEduDegree('1')
        this.props.getEducationDegree('1')
        this.props.getAllCountries('', '', '1')
    }
    getAfterChange=()=>{
        this.props.getCountryEduDegree('1')
    }
    editDataBtn=(data)=>{
        this.props.changeStateValue({
            name: 'newCountryData',
            value: {
              id: data?.id? data?.id: '',
              position: true,
              name: data?.name?data?.name:'',
              description: '',
              active: 1,
              lang_id: 1,
              translate_id: data.translate_id,
              countries_id: data.countries_id,
              education_degree_id: data.education_degree_id,
            }
          })
          this.props.changeStateValue({
            name: 'otherExamPosition',
            value: false
          })
    }
    getPlaceholder(name, id, arr){
        if(id == '' || !id){
            return name
        }else{
            const check = arr.find(data=> data.value == id);
            if(check){
                return check.label
            }else{
               return name
            }
        }
    }
  render() {
    const {countEduDegree, newCountryData, allCountries, educationDegree} = this.props;
    let countryOpt = [];
    let degreeOpt = [];
    for(const data of allCountries){
        countryOpt.push({
            label: data.name,
            value: data.countries_id
        })
    }
    for(const data of educationDegree){
        degreeOpt.push({
            label: data.education_type,
            value: data.education_degree_id
        })
    }

    return (
      <div>
        <button onClick={this.editDataBtn.bind(this, '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a Country Edu Degree</button>
        <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 sm:gap-6 md:gap-8`}>
        {
            countEduDegree.map((item ,i)=>{
                return(
                    <Maincard 
                        key={i}
                        name={item.name}
                        country={item.country}
                        degree={item.main_degree}
                        id={item.country_education_degree_id}
                        translate_id={item.id}
                        countries_id={item.country_education_degree.countries_id}
                        education_degree_id={item.country_education_degree.education_degree_id}
                        editDataBtn={this.editDataBtn}
                    />
                )
            })
        }
        </div>
        {newCountryData?.position ? <MainModal  
                                        drop1option={countryOpt} 
                                        drop1Title={"Select a Country"} 
                                        drop1Name="countries_id"
                                        drop1placeholder={this.getPlaceholder("Select a Country", newCountryData.countries_id, countryOpt)}
                                        drop2option={degreeOpt} 
                                        drop2Title={"Select a Edu Degree"} 
                                        drop2Name="education_degree_id"
                                        drop2placeholder={this.getPlaceholder("Select a Edu Degree", newCountryData.education_degree_id, degreeOpt)}
                                        stateName="newCountryData"
                                        stateData={newCountryData}
                                        required={["name", "countries_id", "education_degree_id"]}
                                        url={'/country-education-degree'}
                                        getAfterChange={this.getAfterChange}
                                    />:null}
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    countEduDegree: state.Data.countEduDegree,
    newCountryData: state.Data.newCountryData,
    allCountries: state.Data.allCountries,
    educationDegree: state.Data.educationDegree,
});
const mapDispatchToProps = {getCountryEduDegree, changeStateValue, getAllCountries, getEducationDegree}
export default connect(mapStateToProps, mapDispatchToProps)(CountryEduDegree)