import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getEducationDegree, getPrograms, getAllDisciplines } from '../../actions/MainAction';
import Searchable from 'react-searchable-dropdown'; 
import Loading from '../../components/page_component/Loading';
import PrgoramCard from './PrgoramCard';
import { useParams } from 'react-router-dom';
import { deleteProgram , updateProgrUni} from '../../actions/WorkerActions';
import Swal from 'sweetalert2';
function withHooks(Component){
    return props => <Component {...props} params={useParams()} />
}
export class UpdateAllProgramUni extends Component {
    state={
        degreeId:'',
        updatedPrograms: [],
        allProgramSelected:[],
        btnDisable: false,
        autoRender:true
    }
    componentDidMount(){
        this.props.getEducationDegree(1);
        this.props.getAllDisciplines(1)
    }
    selectFromSystem=( name, data)=>{
        this.setState({[name]:data})
        this.setState({autoRender:false})
        this.setState({allProgramSelected:[]})
        setTimeout(()=>{
            this.setState({autoRender:true})
        }, 100)
    }
    fetchPrograms(degreeId){
        if(degreeId != ''){
            this.setState({autoRender:false})
            this.setState({allProgramSelected:[]})
            setTimeout(()=>{
                this.setState({autoRender:true})
            }, 100)
          this.props.getPrograms(100, 1, this.props.params.id, '', 'public-programs', '', '', '', degreeId, '')
          .then(resp=>{this.setState({programs:[...resp]})})
        }
    }
    deleteProgram=(id)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.props.deleteProgram(id)
                .then(resp=>{

                    // let arr = [...this.state.allProgramSelected];
                    // const  findPr = arr.findIndex(item=> item.program_id == id);
                    // if(findPr != -1){
                    //     arr.splice(findPr, 1);
                    //     this.setState({allProgramSelected:arr})
                    // }
                    this.setState({allProgramSelected:[]})
                    this.setState({autoRender:false})
                    setTimeout(()=>{
                        this.setState({autoRender:true})
                    }, 100)
                    this.props.getPrograms(100, 1, this.props.params.id, '', 'public-programs', '', '', '', this.state.degreeId, '')
                    if(resp == 'success'){
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', Swal.stopTimer)
                              toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                          })
                          
                          Toast.fire({
                            icon: 'success',
                            title: 'delete successfully'
                          })
                    }else{
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', Swal.stopTimer)
                              toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                          })
                          
                          Toast.fire({
                            icon: 'error',
                            title: 'Delete error'
                          })
                    }
                })
               
            }
          })
        
    }

    selectAllPrograms=(index = -1, data)=>{
        console.log(index)
        let arr = this.state.allProgramSelected;
        if(index == -1){
            arr.push(data)
        }else{
            arr[index] = data
        }
        
        this.setState({allProgramSelected: arr})
    }

    updateAllBtn(programs){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, update it!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.setState({btnDisable: true})
                this.props.updateProgrUni(JSON.stringify({programs: programs}))
                .then(resp=>{
                    this.setState({allProgramSelected:[]})
                    this.setState({autoRender:false})
                    setTimeout(()=>{
                        this.setState({autoRender:true})
                    }, 100)
                    this.setState({btnDisable: false});
                    this.props.getPrograms(100, 1, this.props.params.id, '', 'public-programs', '', '', '', this.state.degreeId, '')
                    if(resp == "success"){
                        Swal.fire(
                            'Updated!',
                            'Your file has been updated.',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Your file has not been updated.',
                            'error'
                        )
                    }
                })
              
            }
          })

    }

    getFilter(programs){
        return [...programs].sort(function(a, b) {
            return a.name.localeCompare(b.name);
         });
    }
  render() {
    const {educationDegree, programs, loader, loaderLong, allDisciplines} = this.props;
    const {degreeId, allProgramSelected, btnDisable, autoRender} = this.state;
    const degreeOption = [];
    const disciplinesOption = [];
    for(const property of educationDegree){
        degreeOption.push({
          label: property?.education_type,
          value: property?.education_degree_id
        })
    }
    for(const property of allDisciplines){
        disciplinesOption.push({
          label: property?.name,
          value: property?.discipline_id
        })
    }

    // programs.sort(function(a, b) {
    //     var textA = a.name.toUpperCase();
    //     var textB = b.name.toUpperCase();
    //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    // });
    
  
    return (
      <div className='container'>
         <div className='flex items-center gap-3'>
                  <div >
                    <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                       Select Education Degree
                    </label>
                    <div style={{ width: '300px' }} className={`block w-full border-neutral-200  
                        focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                        dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                        dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                    > 
                        <Searchable 
                            options={degreeOption}
                            onSelect={this.selectFromSystem.bind(this, 'degreeId')}
                            placeholder={"Select Education Degree"}
                            
                        />
                    </div>
                </div>
                <button className='bg-blue-500 p-2 rounded-2xl text-white mt-5' onClick={this.fetchPrograms.bind(this, degreeId)}>Fetch programs</button>
          </div>
          <div className='flex items-center gap-3 mt-5'>
            <h3>Selected Programs: ({allProgramSelected.length})</h3>
            <button disabled={btnDisable} className='bg-blue-500 p-2 rounded-2xl text-white' onClick={this.updateAllBtn.bind(this, allProgramSelected)}>Update</button>
          </div>
          <div>
            {autoRender?
                this.getFilter(programs)?.map((data, i) =>{
                    return(
                        <PrgoramCard 
                            key={i}
                            programData={data}
                            disciplinesOption={disciplinesOption}
                            index={i}
                            selectAllPrograms={this.selectAllPrograms}
                            deleteProgram={this.deleteProgram}
                        />
                    )
                }):null
            }
          </div>
          {
          loader || loaderLong? 
          <Loading />:null
      }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    educationDegree: state.Data.educationDegree,
    programs: state.Data.programs,
    loader: state.Data.loader,
    loaderLong: state.Data.loaderLong,
    allDisciplines: state.Data.allDisciplines,
});
const mapDispatchToProps = {getEducationDegree, getPrograms, getAllDisciplines, deleteProgram, updateProgrUni}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(UpdateAllProgramUni))
