import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getStudentExams, addNewData } from '../../../actions/StudentActions';
import SliderCard from './SliderCard';
import { getExams } from '../../../actions/MainAction';
import DropDowns from './DropDowns'
import ExamDrop from './ExamDrop';
export class Exams extends Component {
    state={
        stateExams: [],
        buttonVisible: false
    }
    componentDidMount(){
        this.props.getStudentExams(this.props.languageId, this.props.student_id);
        this.props.getExams()
    }

    getOtherData = (id,  data) =>{
        
        const checkExists = data.find(item=> item.exam_id == id);
        const checkIndex = data.findIndex(item=> item.exam_id == id);
        if(checkExists && checkExists.deleted == true){
            checkExists.deleted = false;
            data[checkIndex] = checkExists
        }else if(!checkExists){
            const exam = this.props.examsData.find(item=> item.id == id)
            const params = {
                id: '',
                name: exam?.exam_type,
                over_all: 0,
                exam_id: id,
                date: '',
            }
            data.push(params)
        }

        this.setState({
            stateExams: data
        })
    }

    changeSliderValue = (index, data, value)=>{
       
        data[index].over_all = value;
        this.setState({
            stateExams: data
        })
    }
    setDate = (index, data, value)=>{
       
        data[index].date = value;
        this.setState({
            stateExams: data
        })
    }
    removeExam = (index, data)=>{
       
        data[index].deleted = true
        data[index].over_all = 0
        data[index].date = ''
        this.setState({
            stateExams: data
        })
    }

    saveChanges(data, e){
        if(this.state.stateExams.length != 0){
            this.setState({
                buttonVisible: true
            })
    
            // update-exams
            let url = `update-exams`;
            let formData = new FormData();
            formData.append('exams', JSON.stringify(data))
            formData.append('student_id', this.props.student_id)
            this.props.addNewData(url, formData)
            .then(resp=>{
                this.setState({
                    buttonVisible: false,
                    stateExams: []
                });
                this.props.getStudentExams(this.props.languageId, this.props.student_id);
      
            })
        }
        
      
    }
    getInpValue=(e, label, min_value, max_value, index, data)=>{
        if(label== 'IELTS'){
            if(e.target.value >=0 && e.target.value <=max_value){
               
                if(e.target.value == 3 || 
                    e.target.value == 3.5 || 
                    e.target.value ==4 || 
                    e.target.value == 4.5 || 
                    e.target.value == 5 || 
                    e.target.value == 5.5 || 
                    e.target.value == 6 || 
                    e.target.value ==6.5 ||
                    e.target.value == 7 ||
                    e.target.value == 7.5 ||
                    e.target.value == 8 ||
                    e.target.value == 8.5 ||
                    e.target.value == 9
                    ){
                     
                        data[index].over_all = e.target.value;
                        this.setState({
                            stateExams: data
                        })
                    }
                
            }else if(e.target.value <0){
                data[index].over_all = '';
                this.setState({
                    stateExams: data
                })
            }
        }else{
            if(e.target.value >=0 && e.target.value <=max_value){
                data[index].over_all = e.target.value;
                this.setState({
                    stateExams: data
                })
            }else if(e.target.value <0){
                data[index].over_all = '';
                this.setState({
                    stateExams: data
                })
            }
        }
    }
  render() {
    const {studentExams, examsData, unirepRoleTranslate} = this.props;
    const {stateExams, buttonVisible} = this.state;
    let selectedExams = []
   
    const examOption=[];
    for(const item of examsData){
        examOption.push({
            label: item.exam_type,
            value: item.id
        })
    }

    if(stateExams.length == 0 && studentExams.length != 0){
        studentExams.forEach((item)=>{
        
            selectedExams.push({
                id: item.id,
                name: item?.name?.exam_type,
                over_all: item.over_all,
                exam_id: item.exam_id,
                date: item.date,
            })
        })
    }else if(stateExams.length != 0){
        selectedExams = [...stateExams]
    }
    const minmAxValue = [
        {
            title: 'IELTS',
            min_value: 3,
            max_value: 9,
            step: 0.5
        },
        {
            title: 'TOEFL',
            min_value: 0,
            max_value: 120,
            step: 1
        },
        {
            title: 'PTE',
            min_value: 10,
            max_value: 90,
            step: 1
        },
        {
            title: 'Duolingo',
            min_value: 10,
            max_value: 160,
            step: 1
        },
        {
            title: 'SAT',
            min_value: 400,
            max_value: 1600,
            step: 1
        },
        {
            title: 'GRE',
            min_value: 0,
            max_value: 340,
            step: 1
        },
        {
            title: 'GMAT',
            min_value: 200,
            max_value: 800,
            step: 1
        },
    ]
    
    return (
        <div>
            {/* <DropDowns
                ariaLabel="unirepRoleTranslate.add_exam"
                placeholder = {unirepRoleTranslate?.add_exam}
                label = {unirepRoleTranslate?.add_exam}
                option={examOption}
                name="exams"
                type="exams"
                getOtherData={this.getOtherData}
                zIndex={100}
                data={selectedExams}
            /> */}
            <ExamDrop 
                ariaLabel="unirepRoleTranslate.add_exam"
                placeholder = {unirepRoleTranslate?.add_exam}
                option={examOption}
               
                getOtherData={this.getOtherData}
             
                data={selectedExams}
            />
            <div style={{ gap: '20px' }} className="grid grid-cols-2 md:grid-cols-2 gap-0 md:gap-0 justify-between mt-5">
                {
                    selectedExams.map((data, i)=>{
                        if(!data.deleted){
                            return(
                                <SliderCard 
                                    key={i}
                                    value={data.over_all} 
                                    label={data.name}
                                    min_value={minmAxValue.find(item => item.title == data.name)?.min_value} 
                                    max_value={minmAxValue.find(item => item.title == data.name)?.max_value} 
                                    step={minmAxValue.find(item => item.title == data.name)?.step} 
                                    index={i}
                                    data={selectedExams}
                                    changeSliderValue={this.changeSliderValue}
                                    removeExam={this.removeExam}
                                    date={data.date}
                                    setDate={this.setDate}
                                    getInpValue={this.getInpValue}
                                />
                            )
                        }
                        
                    })
                }
           
            </div>
            <div className="flex justify-center items-center mt-10" >
                  
                <button aria-label='unirepRoleTranslate.update' disabled={buttonVisible} onClick={this.saveChanges.bind(this, selectedExams)}  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                {unirepRoleTranslate?.update}
                </button>
            </div>
        </div>

    )
  }
}
const mapStateToProps = (state) =>({
    studentExams: state.Data.studentExams,
    languageId: state.Data.languageId,
    examsData: state.Data.examsData,
    unirepRoleTranslate: state.Data.unirepRoleTranslate,
    unirepRoleTranslate: state.Data.unirepRoleTranslate,
});
const mapDispatchToProps = {getStudentExams, getExams, addNewData}
export default connect(mapStateToProps, mapDispatchToProps)(Exams)