import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../../redux/MainReducer';
export class Input extends Component {
    changeInputValue(min, max,  e){
        
        // if(e.target.value == '' || e.target.value >= +minValue && e.target.value  <= +maxValue){
      
            
        //     const filter = JSON.parse(localStorage.getItem('simulator'))
        //     filter[e.target.name] = e.target.value;
          
        //     this.props.changeStateValue({
        //         name: 'filter',
        //         value: filter
        //     })

        //     localStorage.setItem('simulator', JSON.stringify(filter))
        // }
        if(e.target.value >= +min && e.target.value <= +max){
            
            if(localStorage.getItem('simulator')){
              const filterData = JSON.parse(localStorage.getItem('simulator'));
              filterData[e.target.name] = e.target.value;
              
             
              // this.props.getSimulatorPrograms(filterData)
              // this.props.getStateByCountry(null, this.props.languageId, filterData.filterByCountry);
              // this.props.getcityByCountryAndState(this.props.languageId, filterData.filterByCountry, filterData.filterByState);
          
        
    
              if(filterData.filterByGradingScheme.includes('-') && filterData.filterByGradingScheme.split('-')[1] == '100'){
               
                filterData['gpaConverter'] = e.target.value
              }else{
                if(this.props.gradeConverters){
                    const convert = this.props.gradeConverters.find(grade=> +grade.grade == e.target.value);
                    if(convert){
                        filterData['gpaConverter'] = convert.max_value
                    }
                }
              }
              this.props.changeStateValue({
                name: 'filter',
                value: filterData
              })
              localStorage.setItem('simulator', JSON.stringify(filterData));
            }
            
        }else if(e.target.value <= min ){
            if(localStorage.getItem('simulator')){
                const filterData = JSON.parse(localStorage.getItem('simulator'));
                filterData[e.target.name] ='';
               
                if(filterData.filterByGradingScheme.includes('-') && filterData.filterByGradingScheme.split('-')[1] == '100'){
                    filterData['gpaConverter'] = e.target.value
                 
                  }else{
                    if(this.props.gradeConverters){
                        const convert = this.props.gradeConverters.find(grade=> +grade.grade == e.target.value);
                        if(convert){
                            filterData['gpaConverter'] = convert.max_value
                        }
                    }
                  }
                this.props.changeStateValue({
                    name: 'filter',
                    value: filterData
                })
                localStorage.setItem('simulator', JSON.stringify(filterData));

                // this.props.getSimulatorPrograms(filterData)
                // this.props.getStateByCountry(null, this.props.languageId, filterData.filterByCountry);
                // this.props.getcityByCountryAndState(this.props.languageId, filterData.filterByCountry, filterData.filterByState);
        
               
            }
        }
        
    }
  render() {
    const {label, type="number", placeholder="", value="", name, minValue, maxValue, filter, ariaLabel} = this.props;
    return (
        <div>
        <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
        <span aria-label={ariaLabel}>{label}</span> ({minValue} - {maxValue})
        </label>
        <input style={value ==''? {border: '1px solid red'}:{}} name={name} onChange={this.changeInputValue.bind(this, minValue, maxValue)} type={type}
            className={`block w-full border-neutral-200 focus:border-primary-300 
            focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
            dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
            dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
            placeholder={placeholder}
            value={value}
        /> 
    </div>
    )
  }
}
const mapStateToProps = (state) =>({
    filter: state.Data.filter,
    gradeConverters: state.Data.gradeConverters,
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Input)