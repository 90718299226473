import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../../../actions/withHooks';
import { getStudentArchivePrograms } from '../../../../../actions/WorkerActions';
import ChangeSvg from '../../../../../components/svg/ChangeSvg';
import TrackingBtn from './components/TrackingBtn';
import { getTracings } from '../../../../../actions/WorkerActions';
import SchBtn from './components/SchBtn';
import ComissionBtn from './components/ComissionBtn';
import ArchiveBtn from './components/ArchiveBtn';
export class ArchivePrograms extends Component {
    componentDidMount(){
        this.props.getStudentArchivePrograms(this.props.params.id);
        this.props.getTracings(1)
        
    }

    getStatus(applications){
        let arr = [...applications]?.sort((x, y)=>y?.track_id - x?.track_id);
        return arr[0]?.trackings?.translate?.title
    }
    getAfterChange=()=>{
        this.props.getStudentArchivePrograms(this.props.params.id);
    }
  render() {
    const {studentArchivePrograms, trackingData} = this.props;
    console.log(studentArchivePrograms)
    return (
        <div className=' mt-[20px] flex flex-col gap-[20px]'>
            {
                studentArchivePrograms?.map((data, i)=>{
                    return(
                        <div key={i} className='bg-white pt-[1px] rounded-[10px]'>
                            <h3 className='mt-[10px] ml-[10px] text-[14px] font-semibold'>{data?.program?.institution?.translate?.name}</h3>
                            <table className='w-full bg-white rounded-[10px] shadow-sm border-t'>
                                <thead >
                                    <tr>
                                        <th className='text-[13px] text-start p-[10px] font-[600] w-1/4 border-r'>Program</th>
                                        <th className='text-[13px] text-start p-[10px] font-[600] w-1/4 border-r'>Degree</th>
                                        <th className='text-[13px] text-start p-[10px] font-[600] w-1/4 border-r'>Status</th>
                                        <th className='text-[13px] text-start p-[10px] font-[600] w-1/4 border-r'>Scholarship</th>
                                        <th className='text-[13px] text-start p-[10px] font-[600] w-1/4 border-r'>Comission</th>
                                        <th className='text-[13px] text-start p-[10px] font-[600] w-1/4'>Archive</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='border-t'>
                                        <td className='text-[13px] text-start p-[10px] w-1/4 border-r'>{data?.program?.translate?.name}</td>
                                        <td className='text-[13px] text-start p-[10px] w-1/4 border-r'>{data?.program?.degree?.translate?.education_type}</td>
                                        <td className='text-[13px] text-start p-[10px] flex items-center w-full gap-[10px] border-r'>
                                         
                                            {/* <TrackingBtn getAfterChange={this.getAfterChange.bind(this)} selectArray={[...data?.program?.applications]} options={trackingData['applications']} program_id={data?.program.id} student_id={this.props.params.id} /> */}
                                            {this.getStatus(data?.program?.applications)}
                                        </td>
                                        <td  className='text-[13px] text-start p-[10px] border-r'>
                                            <SchBtn key={i} notPermision={true}  program_id={data?.program.id} student_id={this.props.params.id} />
                                        </td>
                                        <td  className='text-[13px] text-start p-[10px] border-r'>
                                            <ComissionBtn key={i}  notPermision={true} getAfterChange={this.getAfterChange.bind(this)} comission={data?.program?.comission}  program_id={data?.program.id} student_id={this.props.params.id} />
                                        </td>
                                        <td  className='text-[13px] text-start p-[10px] '>
                                            <ArchiveBtn key={i} getAfterChange={this.getAfterChange.bind(this)} archive={data?.archive_status==1?true:false}  id={data?.id} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )
                })
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentArchivePrograms: state.Data.studentArchivePrograms,
    trackingData: state.Data.trackingData,
});
const mapDispatchToProps = {getStudentArchivePrograms, getTracings}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ArchivePrograms))


