import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getSimulatorPrograms, getCountryEducationDegree, getGradingScheme } from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
export class RadioType extends Component {
    selectSingleValue(id, name, e){
       
        // if(localStorage.getItem('simulator')){
        //     const filterData = JSON.parse(localStorage.getItem('simulator'));
          
        //     filterData[name] = id;
        //     localStorage.setItem('simulator', JSON.stringify(filterData));
        //     this.props.changeStateValue({
        //       name: 'filter',
        //       value: filterData
        //     })
        //     this.props.getSimulatorPrograms(filterData);
        //     if(filterData['filterStudiedCountry'] !== '' && filterData['filterByLevel'] !== ''){
        //         this.props.getCountryEducationDegree(this.props.languageId, filterData['filterStudiedCountry'], filterData['filterByLevel']);
        //         this.props.getGradingScheme(this.props.languageId, filterData['filterStudiedCountry'], filterData['filterByLevel'], 'education_degree', '');
        //         this.props.changeStateValue({
        //             name: 'gradingSchemeType',
        //             value: id
        //         })
        //     }
        //     if(filterData['filterStudiedCountry'] !== '' && 
        //         filterData['filterByLevel'] !== '' && 
        //         filterData['filterByCountryLevel'] &&
        //         id !== 'a_level' && 
        //         id !== 'ib_diploma' && 
        //         id !== 'ossd' && 
        //         id !== 'ap'
        //     ){
        //         this.props.getGradingScheme(this.props.languageId, filterData['filterStudiedCountry'], filterData['filterByLevel'], 'education_sub_degree', filterData['filterByCountryLevel']);
        //         this.props.changeStateValue({
        //             name: 'gradingSchemeType',
        //             value: id
        //         })
        //     }else if(filterData['filterStudiedCountry'] !== '' && 
        //             filterData['filterByLevel'] !== '' && 
        //             filterData['filterByCountryLevel'] 
        //     ){
        //         if(
        //             id !== 'a_level' ||
        //             id !== 'ib_diploma' || 
        //             id !== 'ossd' ||
        //             id !== 'ap'
        //         ){
        //             this.props.changeStateValue({
        //                 name: 'gradingSchemeType',
        //                 value: id
        //             })
        //         }
        //     }
            
        //   }
       
    }
    getChecked(name, filter, id){
        if(filter[name] !== ''){
            if(filter[name] == id){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }
  render() {
    const {name, array, filter} = this.props;
  
    return (
        <div>
            <ul className='filter_checkbox_container'>
                {
                    array.map((data, i)=> {
                        return(
                            <li key={i}>
                                <label >
                                    <input checked={this.getChecked(name, filter, data.id)} onChange={this.selectSingleValue.bind(this, data.id, name)} name={name} type={'radio'} />
                                    <span>{data.name}</span>
                                </label>
                            </li>
                        )
                    })
                }
               
            </ul>
        {/* <FooterBtns resetData={this.resetData} updateData={this.updateData} /> */}
        </div>
    )
  }
}
const mapStateToProps = (state)=> ({
    filter: state.Data.filter,
    languageId: state.Data.languageId,
});
const mapDispatchToProps = {getSimulatorPrograms, changeStateValue, getCountryEducationDegree, getGradingScheme}
export default connect(mapStateToProps, mapDispatchToProps)(RadioType)