import moment from 'moment';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import Editbtn from './Editbtn';
import StarIcon from './StarIcon'
import UserImg from './UserImg'
export class CommentList extends Component {
  render() {
    const {comment, user} = this.props;
    
    return (
        <div
        className={`nc-CommentListing flex space-x-4 py-8`}
        data-nc-id="CommentListing"
      >
        <div className="pt-0.5">
          <UserImg 
            imgUrl={comment?.user?.image_full_url}
            userName={comment?.user?.full_name}
          />
        </div>
        <div className="flex-grow">
          <div className="flex justify-between space-x-3">
            <div className="flex flex-col">
              <div className="text-sm font-semibold">
                <span>{comment?.user?.full_name}</span>
                
              </div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5">
                {/* {moment(comment.created_at).format('DD/MM/YYYY hh:mm')} */}
              </span>
            </div>
            <div className="flex ">
               {
                
                    [1, 2, 3 ,4, 5].map((data, i)=>{
                       return(
                           <StarIcon 
                           key={i} 
                           className={comment.raiting>= data?"text-red-500 w-4 h-4":'w-4 h-4 text-gray-500 '}
                           />
                       )
                    })
                  
               }
             
            </div>
          </div>
          
          <span className="block mt-3 text-neutral-6000 dark:text-neutral-300">
            {comment.comment} 
            {
              user !== '' && user.id == comment.student_id?
              <Editbtn data={comment} />:null
            }
            
          </span>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user
})
export default connect(mapStateToProps)(CommentList)