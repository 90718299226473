import React, { Component, Fragment } from 'react'
import { Popover, Transition } from "@headlessui/react";
import { connect } from 'react-redux';
import {uploadStudentDocuments, getStudentDocuments, deleteStudentDocument } from '../../../../actions/WorkerActions'; 
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
function withParams(Component){
    return props => <Component {...props} params={useParams()} />
}
export class DocumentsBtn extends Component {
    state={
        position: false,
        activeBtn: 'uni_rep',
        file: '',
        title: '',
        btnVisible: false,
        tooLargeFile:false,
        errorMsg: false
    }
    openModal(){
        this.setState({
          position:true
        })
        
    }
    changeBtn(type, e){
        this.setState({
            activeBtn: type
        })
    }

    componentDidMount(){
        this.props.getStudentDocuments(this.props.params.id?this.props.params.id:this.props.student_id)
    }
    uploadFile(e){
        if(e.target.files[0].size < 10000000){
            this.setState({
                file: e.target.files[0]
            })
            this.setState({
                tooLargeFile: false
            })
        }else{
            this.setState({
                tooLargeFile: true
            })
        }
        
    }
    changeTitle(e){
        this.setState({
            title: e.target.value,
            errorMsg: false
        })
    }
    clearData(){
        this.setState({
            file: '',
            title: ''
        })
    }
    uploadData(title, file, e){

        if(title !== '' && file !== ''){
            this.setState({
                btnVisible: true
            })
            let formData = new FormData();
            formData.append('file', file);
            formData.append('title', title);
            formData.append('type', this.props.user.role);

            if(this.props.user.role == 'student'){
                formData.append('student_id', this.props.user.id);
            }else{
                formData.append('student_id', this.props.params.id?this.props.params.id:this.props.student_id);
            }
           this.props.uploadStudentDocuments(formData) 
           .then(resp=>{
                this.props.getStudentDocuments(this.props.params.id?this.props.params.id:this.props.student_id)
                this.clearData()
                this.setState({
                    btnVisible: false
                })
           })
        }else{
            this.setState({errorMsg: true})
        }
    }
    deleteDocument(id, e){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.props.deleteStudentDocument(id)
                .then(resp=>{
                    this.props.getStudentDocuments(this.props.params.id?this.props.params.id:this.props.student_id)
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                })
              
            }
          })
    }
  render() {
   const { position, activeBtn, title, file, btnVisible, tooLargeFile, errorMsg} = this.state;
    const { user, studentDocuments, unirepRoleTranslate} = this.props;


   
  
    return (
        <Popover >
                
            <Popover.Button 
            onClick={this.openModal.bind(this)}
            className={`flex items-center justify-between px-4  w-full h-11 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
            position ? "!border-primary-500 " : ""
            }`}
            >
            <span aria-label="unirepRoleTranslate.documents">{unirepRoleTranslate?.documents}</span>
            <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
        
            {
            position?
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
             <div id='popover_container'>     
            <Popover.Panel className=" z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                    <div className='flex p-1 space-x-1 bg-primary-900/10 rounded-xl mt-1 ml-1 mr-1'>
                        <button onClick={this.changeBtn.bind(this, 'uni_rep')}
                        className={`w-full py-2.5 text-sm leading-5 font-medium text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                        ${
                            activeBtn == 'uni_rep'?
                            'bg-white dark:bg-neutral-800':
                            ' hover:bg-white/[0.15] dark:hover:bg-neutral-800'
                        }
                        
                        `}
                        aria-label="unirepRoleTranslate.worker"
                        >{unirepRoleTranslate?.worker} </button>
                        <button onClick={this.changeBtn.bind(this, 'student')}
                        className={`w-full py-2.5 text-sm leading-5 font-medium text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                        ${
                            activeBtn == 'student'?
                            'bg-white dark:bg-neutral-800':
                            ' hover:bg-white/[0.15] dark:hover:bg-neutral-800'
                        }
                        `}
                        aria-label="unirepRoleTranslate.student"
                        >{unirepRoleTranslate?.student}</button>
                        
                    </div>
                    {
                        user.role == activeBtn || user.role == 'edu_consultant' && activeBtn != 'student'?
                        <div className='flex mt-3 mx-3 gap-3'> 
                            <div className="w-full" >
                                <input value={title} type={'text'} className="w-full" onChange={this.changeTitle.bind(this)} placeholder="Title" />
                                {
                                    tooLargeFile?
                                    <span style={{ color: 'red', fontSize: '12px' }} aria-label="unirepRoleTranslate.file_large">{unirepRoleTranslate?.file_large}</span>:null

                                }
                                {
                                    errorMsg?
                                    <span style={{ color: 'red', fontSize: '12px' }} aria-label="unirepRoleTranslate.title_required">{unirepRoleTranslate?.title_required}</span>:null
                                }
                            </div>
                            <label htmlFor='uploadDocumnt' className='mt-1'>
                                <svg width={25} fill={file == ''? '': '#4f46e5'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456c13.3 0 24-10.7 24-24s-10.7-24-24-24s-24 10.7-24 24s10.7 24 24 24z"/>
                                </svg>
                            </label>
                            <input accept='image/png, image/jpeg, image/jpg, application/pdf' type={'file'} onChange={this.uploadFile.bind(this)}  id="uploadDocumnt" style={{ display: 'none' }} />
                        </div>:null
                    }
                  
                    <div className="relative flex flex-col px-5 py-6 space-y-5 " style={{ maxHeight: '300px', overflow: 'auto' }}>
                        <ul>
                            {
                                studentDocuments.map((data, i)=>{
                                    if(user.role == activeBtn && data.upload_user_id == user.id || data.upload_user_id == user.id &&user.role == 'edu_consultant' && activeBtn != 'student'){
                                        return(
                                            <li key={i} className='flex justify-between mt-3'>
                                                <span>{data.title}</span> 
                                                <div className='flex gap-5'>
                                                    <svg style={{ cursor: "pointer" }} onClick={this.deleteDocument.bind(this, data.id)} width={15} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                        <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
                                                    </svg>
                                                    <a target="_blank" href={data.file_full_url}>
                                                        <svg width={19} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zM432 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"/>
                                                        </svg>
                                                    </a>
                                                </div>
                                            </li>
                                        )
                                    }else if(user.role !== activeBtn && data.upload_user_id !== user.id){
                                        return(
                                            <li key={i} className='flex justify-between mt-3'>
                                                <span>{data.title}</span> 
                                                <a  target="_blank" href={data.file_full_url}>
                                                    <svg width={19} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zM432 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"/>
                                                    </svg>
                                                </a>
                                            </li>
                                        )
                                    }
                                })
                            }
                           
                        </ul>
                    </div>
                        {
                            user.role == activeBtn || user.role == 'edu_consultant' && activeBtn != 'student'?
                            <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                            
                                <button aria-label='unirepRoleTranslate.clear' disabled={btnVisible} onClick={this.clearData.bind(this)} className='ttnc-ButtonThird text-neutral-700 border 
                                                    border-neutral-200 dark:text-neutral-200 
                                                    dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                                    items-center justify-center rounded-full transition-colors
                                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                                    px-4 py-2 sm:px-5'   >
                                    {unirepRoleTranslate?.clear}
                                </button>
                                <button aria-label='unirepRoleTranslate.upload_file' disabled={btnVisible} onClick={this.uploadData.bind(this, title, file)} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-2 sm:px-5  '>
                                    {unirepRoleTranslate?.upload_file}
                                </button>
                            </div>:null
                        }
                        
                    
                </div>
            </Popover.Panel></div>
            </Transition>:null
            }
            

    </Popover>
    )
  }
}
const mapStateToProps = (state) =>({
    languageId: state.Data.languageId,
    user: state.Data.user,
    studentDocuments: state.Data.studentDocuments,
    unirepRoleTranslate: state.Data.unirepRoleTranslate
});
const mapDispatchToProps = {uploadStudentDocuments, getStudentDocuments, deleteStudentDocument}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(DocumentsBtn))
