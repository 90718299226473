import React, { Component } from 'react'
import { connect } from 'react-redux'
import BgGlassmorphism from '../../../components/page_component/BgGlassmorphism'
import SectionHeader from '../../../components/page_component/SectionHeader'
import RegisterModal from './components/RegisterModal'
import { getTeachers } from '../../../actions/WorkerActions'
import avatar1 from '../../../images/default.jpg'
import EditSvg from '../../../components/page_component/EditSvg'
import EditModal from '../EditModal'
import { changeStateValue } from '../../../redux/MainReducer'
import SkeletonCard from '../../../components/page_component/SkeletonCard'
export class EduconTeacher extends Component {
    state={
        modalPosition: false,
        data: ''
    }
    registerStudent(){
        this.setState({
            modalPosition: true
        })
    }  
    
    closeModal = () =>{
        this.setState({
            modalPosition: false
        })
    }

    componentDidMount(){
        this.props.getTeachers()
    }
    getAfterEdit = () =>[
        this.props.getTeachers()
    ]
    editSvgBtn(data){
        this.props.changeStateValue({
            name: 'editUserState',
            value:{
                position: true,
                id: data.id,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                password: '',
                image: data.image_full_url
            }
        }) 
    }
  render() {
    const {mainTransateData, teachers, editUserState} = this.props;
    const {modalPosition, data} = this.state;
 
  
    return (
        <div className="nc-PageHome relative overflow-hidden">
        <BgGlassmorphism />
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
            <div className="relative py-16">
                <div className={`nc-SectionGridFilterCard pb-24 lg:pb-28`} data-nc-id="SectionGridFilterCard" >
                
                    <SectionHeader arialLabelTitle={'mainTransateData.teacher_nav'} title={mainTransateData?.teacher_nav} />
                    <div className="mb-8 lg:mb-11">
                        <div className="flex lg:space-x-4">
                            <div className="hidden lg:flex space-x-4">
                            <button onClick={this.registerStudent.bind(this)} className='ttnc-ButtonThird text-neutral-700 border 
                                                border-neutral-200 dark:text-neutral-200 
                                                dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                                items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                                px-4 py-1 sm:px-3' style={{ fontSize: '14px', fontWeight: '400' }}  aria-label="mainTransateData.add_teacher" >
                                {mainTransateData?.add_teacher}
                            </button>
                            </div>
                        </div>
                    </div>
                    <div  className={`grid mt-10 gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 `} >

                        {
                            teachers.map((data, i)=>{
                                return(
                                    <div key={i}
                                    className={`nc-CardAuthorBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}
                                    data-nc-id="CardAuthorBox"
                                >
                                    <div className='w-5 absolute left-3 top-3' >
                                        <EditSvg editSvgBtn={this.editSvgBtn.bind(this, data)} />
                                    </div>
                                    <div className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full w-20 h-20 text-2xlring-1 ring-white dark:ring-neutral-900`}>
                                        <img
                                        className={`absolute inset-0 w-full h-full object-cover rounded-full`}
                                        src={data.image_full_url&& data.image_full_url != '' ? data.image_full_url:avatar1}
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <h2 className={`text-base font-medium`}>
                                            <span className="line-clamp-1">{data.full_name}</span>
                                        </h2>
                                        <span className={`block mt-1.5  text-sm text-neutral-500 dark:text-neutral-400 wrap`} >
                                        {data.email.length > 20? `${data.email.substring(0, 20)}...`:data.email}
                                        </span>
                                        <div className={"p-4 space-y-4"}>
                                   
                                    </div>
                                            
                                    </div>
                                </div>
                                )
                            })
                        }
                         {
                        teachers?.length == 0?
                        <>
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        </>:null
                        } 
                     
                    </div>
                </div>
            </div>
            {
                modalPosition?
                <RegisterModal data={data} closeModal={this.closeModal} />:null
            }
            {
                editUserState.position?
                <EditModal getAfterEdit={this.getAfterEdit} />:null
            }
        </div>

      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    mainTransateData: state.Data.mainTransateData,
    teachers: state.Data.teachers,
    editUserState: state.Data.editUserState,
});
const mapDispatchToProps = {getTeachers, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(EduconTeacher)