import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getStudentTracking } from '../../actions/WorkerActions';
import ChatBox from '../worker_pages/student_information/components/ChatBox';
import ProgramInformation from '../worker_pages/student_information/components/ProgramInformation';
import {getUnirepRoleTranslate, mainDashboardTranslate} from '../../actions/TranslateAction';
import { getMessagesBox } from '../../actions/MainAction';
export class ApplicationInformation extends Component {
    state={
        selectedProgram: ''
    }
    componentDidMount(){
        window.scrollTo(0, 0)

        this.props.mainDashboardTranslate(this.props.languageId)
        this.props.getUnirepRoleTranslate(this.props.languageId)
        this.props.getStudentTracking(this.props.user?.id, this.props.languageId, '')
    }
    setProgram = (id) =>{
        this.props.getStudentTracking(this.props.user?.id, this.props.languageId, id)
        this.props.getMessagesBox('', id)
        
        this.setState({
            selectedProgram: id
        })
    
    }
  render() {
    const {studentTrackings} = this.props;
    const {selectedProgram} = this.state;
    let program = '';
    if(studentTrackings?.programs){
        if(selectedProgram !== ''){
            program =  studentTrackings?.programs?.find(data=> data.program_id == selectedProgram)
         }else{
            program =  studentTrackings?.programs?  studentTrackings?.programs[0]: ''
         }
    }
    
   

    return (
        <div   className={`ListingDetailPage nc-ListingCarDetailPage`}
        data-nc-id="ListingCarDetailPage">
            <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
                <input type={'checkbox'} style={{ display: 'none' }} id="app_data_check" />
               <div className='info_sidebar_container ' onClick={()=>document.querySelector('#app_data_check').checked = false}>
                    <div  className="info_bar w-full rounded-2xl p-3 lg:block  lg:w-3/5 xl:w-1/3 space-y-8 lg:pr-10 lg:space-y-10">

                        {
                            program !== ''?
                            <ProgramInformation data={program}/>:null
                        }

                        {/* <StudentData data={studentTrackings} /> */}


                    </div>
               </div>
               <ChatBox program_id={program!= ''?program?.program_id: ''} worker_id={program?.main?.user_id} programs={studentTrackings?.programs?studentTrackings?.programs:[]} setProgram={this.setProgram} />
            </main>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentTrackings: state.Data.studentTrackings,
    languageId: state.Data.languageId,
    user: state.Data.user,
  unirepRoleTranslate: state.Data.unirepRoleTranslate,

});
const mapDispatchToProps = {getStudentTracking, getUnirepRoleTranslate, mainDashboardTranslate, getMessagesBox}
export default connect(mapStateToProps, mapDispatchToProps)(ApplicationInformation)