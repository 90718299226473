import React, { Component } from 'react'
import { connect } from 'react-redux'
import BgGlassmorphism from '../components/page_component/BgGlassmorphism'
import Institutions from '../components/page_component/institutions/Institutions'
import SectionHero from '../components/page_component/SectionHero'
import WorkerInstitutions from './worker_pages/WorkerInstitutions'
import { mainDashboardTranslate, getUnirepRoleTranslate } from '../actions/TranslateAction'
export class Universities extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
    this.props.mainDashboardTranslate(this.props.languageId)
    this.props.getUnirepRoleTranslate(this.props.languageId);

  }
  render() {
    const {user} = this.props
    
    return (
        <div className="nc-PageHome relative overflow-hidden">
            <BgGlassmorphism />
            <div className="container relative space-y-24 lg:space-y-28 ">
                {/* <SectionHero /> */}
                <div className="relative ">
                  {
                    user ==="" || user != '' && user.role == 'student' || user != '' && user.role == 'edu_consultant' || user != '' && user.role == 'parent'?
                    <Institutions page={20} btn={'load_more'}  />:
                    user?.role === 'uni_rep' ||  user?.role === 'content'?
                    <WorkerInstitutions page={20} btn={'load_more'} />:null
                  }
                    
                </div>
            </div>

        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user,
  languageId: state.Data.languageId
});
const mapDispatchToProps = {mainDashboardTranslate, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(Universities)