import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import defaultUser  from '../../images/user.jpg'
import Top3 from '../../images/rankings/top3.png';
import Top5 from '../../images/rankings/top5.png';
import Top10 from '../../images/rankings/top10.png';
import Top20 from '../../images/rankings/top20.png';
import Top500More from '../../images/rankings/top500+.png';
import Top50 from '../../images/rankings/top50.png';
import Top100 from '../../images/rankings/top100.png';
import Top200 from '../../images/rankings/top200.png';
import Top500 from '../../images/rankings/top500.png';

export class AppliedCard extends Component {
  getRanking(rank){
    if(rank <200){
      return rank
    }else if(rank>=200 &&rank <500){
       return '200+';
    }else if(rank>=500 ){
      return '500+';
    }
  }
  
  getProgram(uni, program_id){

    let uniData = {};
    let programData = {};
    for(let i = 0; i <uni.length; i++){
      for(let j = 0; j < uni[i]?.programs.length; j++){
        if(uni[i]?.programs[j]?.id == program_id){
          programData = uni[i]?.programs[j];
          uniData = uni[i];
          
        }
      }
    }
    return{
      uni: uniData,
      program: programData
    }
  }
  getSvg(item){
    if(item<=3){
      return Top3 
    }else if( item<=5 &&  item>3){
      return Top5 
    }else if(item<=10 &&  item>5){
      return Top10 
    }else if( item<=20 &&  item>10){
      return Top20 
    }else if( item<=50 &&  item>20){
      return Top50 
    }else if(item<=100 &&  item>50){
      return Top100 
    }else if(item<=200 &&  item>100){
      return Top200 
    }else if(item<=500 &&  item>200){
      return Top500
    }else{
      return Top500More
    }
  }
  getUniName(name){
    if(name.length >30){
      return `${name.substring(0, 30)}...`
    }else{
      return name
    }
  }

  getSch(sch, programId){
    if(sch){
      const data = sch.find(x=>x.program?.id == programId);
      if(data){
        return data
      }else{
        return '';
      }
    }else{
      return ''
    }
    
  }
  render() {
    const {data, program_id} = this.props;
    // const item = this.getProgram(data?.institutions, program_id);
    // const scholarship = this.getSch(data?.scholarship, program_id);
    return (
        <NavLink
        to={`/accepted-student/${data?.user_id}`}
        className={`nc-CardAuthorBox2 flex relative flex-col overflow-hidden bg-white dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow border`}
        data-nc-id="CardAuthorBox2"
        style={{ backgroundColor: 'white' }}
      >
        <div className="relative flex-shrink-0 ">
          <div>
            <div className='nc-NcImage flex aspect-w-7 aspect-h-3 md:aspect-h-4 w-full h-0'>
                <img src={data?.inst_image?.image_full_url} />
            </div>
          
          </div>
          <div className="absolute top-3 inset-x-3 flex">
            <img style={{ width: '50px' }} src={this.getSvg(data?.ranking)} />
          </div>
        </div>
        <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
          <svg
            className="h-12 text-white dark:text-neutral-900 dark:group-hover:text-neutral-800"
            viewBox="0 0 135 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M101.911 19.8581C99.4421 17.4194 97.15 14.8065 94.6816 12.1935C94.3289 11.671 93.8 11.3226 93.271 10.8C92.9184 10.4516 92.7421 10.2774 92.3895 9.92903C85.8658 3.83226 76.8737 0 67.1763 0C57.4789 0 48.4868 3.83226 41.7868 9.92903C41.4342 10.2774 41.2579 10.4516 40.9053 10.8C40.3763 11.3226 40.0237 11.671 39.4947 12.1935C37.0263 14.8065 34.7342 17.4194 32.2658 19.8581C23.45 28.7419 11.6368 30.4839 0 30.8323V54H16.5737H32.2658H101.734H110.374H134.176V30.6581C122.539 30.3097 110.726 28.7419 101.911 19.8581Z"
              fill="currentColor"
            />
          </svg>
          <span className="absolute top-2">
            <div className='rounded-full w-12 h-12 text-2xl wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner '>
                <img className='absolute inset-0 w-full h-full object-fit  rounded-full' 
                src={data.user_image != ''? data.user_image:defaultUser} />
            </div>
            
          </span>
          <div className="mt-6 mb-4" >
            <h2 className={`text-base font-medium`}>
              <span className="line-clamp-1"> {data.first_name} {data.last_name.substring(0,1)}...</span>
            </h2>
            <span style={{ fontWeight: '600' }} className={`block mt-1 text-sm text-neutral-600 dark:text-neutral-600`}  >
                {this.getUniName(data?.inst_name)}
            </span>
            <span className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}  >
                {this.getUniName(data?.program_name)}
            </span>
            {/* {
              scholarship != ''?
              <span className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}  >
                {`${scholarship?.tracking?.title }: ${ scholarship?.amount? scholarship?.amount+ " "+ scholarship?.currency:''}`}
            </span>:null
            } */}
            
            {/* <span className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}  >
                {data?.scholarship?.tracking?.title}
            </span> */}
          </div>
        
        </div>
        <div style={{ background: '#ee7100', width:'30px', height: '30px', position: 'absolute', right: '0', bottom: '-0', display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={25}
              height={25}
              fill="none"
              viewBox="0 0 24 24"
            
            >
              <path
                fill="white"
                fillRule="evenodd"
                d="M17.65 11.25 12.79 6.54l1.045-1.077L20.577 12l-6.742 6.538-1.045-1.076 4.86-4.712H3v-1.5h14.65Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
      </NavLink>
    )
  }
}

export default AppliedCard