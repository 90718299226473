import React, { Component } from 'react'

export class SkeletonMiniCard extends Component {
  render() {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '30px', height: '150px' }} className="skeleton skeleton-loading">
            <div style={{minWidth: '100px', height:'100px', borderRadius: '50%' }} className="skeleton-image">
                
            </div>
            <div style={{ width: '100%' }} className="skeleton-content">
                <h4></h4>
                <div className="skeleton-description">
                
                </div>
            </div>
        </div>
    )
  }
}

export default SkeletonMiniCard