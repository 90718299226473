import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Slider from './Slider'
import { setFeature , deleteInst, insertNewData} from '../../../actions/WorkerActions'
import AutoConpleteText from '../AutoConpleteText'
import Swal from 'sweetalert2'
import { changeStateValue } from '../../../redux/MainReducer'
export class InstitutionsCard extends Component {
    state={
        btnDisabled: false
    }
    getFeeAmount(x){
        var parts = x.toString().split(".");
        parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");
        return parts.join(",");
    }
    setFeatureBtn(id, data, e){
        this.setState({
            btnDisabled: true
        })
        this.props.setFeature(id, data)
        .then(resp=>{
            this.props.getAfterSubmit();
            this.setState({
                btnDisabled: false
            })
        })
    }
    getColor(type){
        if(type == '0'){
            return 'red'
        }else{
            return 'green'
        }
    }
    deleteItem(id){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.props.deleteInst(id)
                .then(resp=>{
                    if(resp == 'success'){
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                          )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                          )
                    }
                    this.props.getAfterDelete();
                })
              
            }
          })
          
        
    }

    changeUni=(uni_id, name)=>{
        console.log(uni_id, name)
        this.props.changeStateValue({
            name:"uniChangeModal",
            value:{
                position:true,
                uni_id:uni_id,
                uni_name:name,
                afterCallFunc:this.props.getAfterDelete
            }
        })
    }

    onClickFree=(id, status)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!'
          }).then((result) => {
            if (result.isConfirmed) {
                let formData = new FormData();
                formData.append("status", status==0?1:0)
                formData.append("_method", "put")
                this.props.insertNewData(`/free-institutions/${id}`, formData)
                .then(resp=>{
                    if(resp?.msg == 'success'){
                        Swal.fire(
                            'Changed!',
                            'Institution changed',
                            'success'
                          )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                          )
                    }
                    this.props.getAfterDelete();
                })
              
            }
          })
          
    }
  render() {
    const {institutions, user, mainTransateData, activeUni} = this.props;
    const {btnDisabled} = this.state;
    return (
        // <Link to={`/institution/${institutions.institutions_id}`}>
            <div  className={`nc-ExperiencesCard rounded-2xl  group relative will-change-transform hover:shadow-xl transition-shadow dark:bg-neutral-900 border`}  data-nc-id="ExperiencesCard">
                <Slider activeUni={activeUni} waiting={institutions.active} galleryImgs={institutions?.institutions?.images} url={`/institution/${institutions.institutions_id}`} institutions={institutions} />
                <div className={ "py-4 space-y-4 px-2 "}>
                    <div className="space-y-2 ">
                    <div className="flex items-center space-x-2">
                        
                        <h2
                        className={` font-medium capitalize text-base `}
                        >
                        {/* <span className="line-clamp-1">{institutions.name}</span> */}
                        <AutoConpleteText text={institutions.name} />
                        
                        </h2>
                    </div>
                    <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                        
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>
                        
                        <span className="">{institutions.country} / {institutions.city}</span>
                    </div>

                   
                    </div>
                    <div className="border-b border-neutral-100 dark:border-neutral-800"></div>
                    {
                        user == '' || user != '' && user.role == 'student'?
                        <div className="flex justify-between items-center">
                            <span className="text-base font-semibold">
                               <span aria-label='mainTransateData.start_from'> {mainTransateData?.start_from}: </span>
                                
                                {institutions.minimum_fee? this.getFeeAmount(institutions.minimum_fee):0}
                                  {` `}
                                {institutions.currency? institutions.currency:'$'}
                              
                            
                                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                                    
                                </span>
                            
                            </span>
                            {/* <div
                                    className={`nc-StartRating flex items-center space-x-1 text-sm `}
                                    data-nc-id="StartRating"
                                    >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5 text-red-500">
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                    </svg>
                                    <span className="font-medium ">55</span>
                                    
                            </div> */}
                        </div>:null
                    }
                    
                    {
                        user!=='' && user.role == 'uni_rep' ||  user !== '' && user.role == 'content'?
                        <div className="flex justify-between items-center" >
                            <Link to={`/institution/${institutions.institutions_id}`} aria-label="mainTransateData.view" className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                            {mainTransateData?.view}
                            </Link>
                            <Link aria-label="mainTransateData.edit" to={`/institution/${institutions.institutions_translate_id? institutions.institutions_translate_id: institutions.id}/edit`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                            {mainTransateData?.edit}
                            </Link>
                            <Link aria-label="mainTransateData.add"  to={`/create-program/${institutions.institutions_id}`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                            {mainTransateData?.add}
                            </Link>
                            <button aria-label="mainTransateData.add" onClick={this.onClickFree.bind(this, institutions.institutions_id, institutions?.institutions?.free_status)} to={`/create-program/${institutions.institutions_id}`} className={`ttnc-ButtonPrimary  hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ${institutions?.institutions?.free_status == 0? "bg-gray-500":"bg-green-600"}`} style={{ fontSize: '12px', color: 'white' }}>
                            Free
                            </button>
                        </div>
                        :null
                    }
                    {
                        user !== '' && user.role == 'uni_rep' ||  user !== '' && user.role == 'content'?
                        <div className='flex justify-between'>

                            <Link  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }} 
                                            to={`/institution-data/${institutions.institutions_id}`}>{mainTransateData?.add_info}</Link>
                            <Link  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }} 
                                            to={`/programs-auto-update/${institutions.institutions_id}`}>Mass Up</Link>
                            
                     
                            {
                                institutions.institutions.feature_status?
                                <button disabled={btnDisabled} onClick={this.setFeatureBtn.bind(this, institutions.institutions_id,institutions.institutions.feature_status == '0'?'1':'0' )} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' 
                                            style={{ fontSize: '12px', color: 'white',  backgroundColor: this.getColor(institutions.institutions.feature_status) }}  >
                                            {institutions.institutions.feature_status == '0'? <span aria-label='mainTransateData.set_feature'>{mainTransateData?.set_feature}</span>: <span aria-label='mainTransateData.seted'>{mainTransateData?.seted}</span>}    
                            </button>:null
                            }
                            
                        </div>:null
                    }
                    {
                        user != '' && user?.role == 'uni_rep'?
                        <div className='flex justify-between'>
                            <button onClick={this.deleteItem.bind(this,institutions.institutions_id)} aria-label="mainTransateData.waiting" className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-red-600 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                Delete
                            </button>
                            <button onClick={this.changeUni.bind(this, institutions.institutions_id, institutions?.name)} aria-label="mainTransateData.waiting" className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-orange-600 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                Change
                            </button>

                            <Link  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }} 
                                        to={`/programs-auto-update-all/${institutions.institutions_id}`}>Update All</Link>
                        </div>:null
                    }
                    
                </div>
            </div>
        // </Link>
    )
  }
}
const mapStateToProps = (state)=>({
    user: state.Data.user,
    mainTransateData: state.Data.mainTransateData,
})
const mapDispatchToProps = {setFeature, deleteInst, changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(InstitutionsCard)