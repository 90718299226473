import React, { Component } from 'react'

export class TextComponent extends Component {
  render() {
    const {title, desc, label} = this.props
    return (
      
        <div className="listingSection__wrap mb-10">
            <h2 className="text-2xl font-semibold" aria-label={label}>{title}</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="text-neutral-6000 dark:text-neutral-300"  dangerouslySetInnerHTML={{ __html: desc}}>
            {/* <span>
                Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
                accommodation, an outdoor swimming pool, a bar, a shared lounge, a
                garden and barbecue facilities. Complimentary WiFi is provided.
            </span>
            <br />
            <br />
            <span>
                There is a private bathroom with bidet in all units, along with a
                hairdryer and free toiletries.
            </span>
            <br /> <br />
            <span>
                The Symphony 9 Tam Coc offers a terrace. Both a bicycle rental
                service and a car rental service are available at the accommodation,
                while cycling can be enjoyed nearby.
            </span> */}
            </div>
        </div>
    )
  }
}

export default TextComponent