import moment from 'moment';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import GetMsg from '../../../pagess/worker_pages/student_information/messages/msg_components/GetMsg';
import SendMsg from '../../../pagess/worker_pages/student_information/messages/msg_components/SendMsg';
import ChatHeader from './ChatHeader'
import MessagesFooter from './MessagesFooter';
import StartConversation from './StartConversation';

export class ChatBox extends Component {
  render() {
    const {messagePage, user, typingMsg, messagesBoxData} = this.props;
    // console.log(messagePage)
    return (
      <div>
       {messagePage.groupName != '' && messagePage.program_id != '' && messagePage.student_id != '' && messagePage.userName != ''?
        <>
         <ChatHeader />
          <div className="w-full h-[60vh] bg-white m-[0_auto] rounded-[14px] p-[0_10px]">
          <div className="bg-[#F5F5F5] h-[calc(60vh_-_55px)] overflow-auto flex rounded-[6px] pb-[10px] flex-col-reverse non-scroll">
          
          {
                  messagesBoxData?.map((message, i)=>{
                      if(message.user_id == user.id){
                          return(
                              <SendMsg status={0} key={i} message={message.message} date={message.created_at} />
                          )
                      }else{
                          return(
                              <GetMsg data={message} name={message?.user?.full_name} role={message?.user?.role_id} key={i} message={message.message} date={message.created_at} />
                          )
                      }
                  })
              }
                    
          </div>
          <MessagesFooter typingMsg={typingMsg} />
          </div>
        </>:<StartConversation />
       }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  messagePage: state.Data.messagePage,
  user: state.Data.user,
  messagesBoxData: state.Data.messagesBoxData,
});

export default connect(mapStateToProps)(ChatBox)