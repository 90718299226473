import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import I404Png from '../images/404.png';
export class NotFound extends Component {
  render() {
    return (
      <div className="nc-Page404">
     
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-2">
        <div className={`nc-NcImage `} data-nc-id="NcImage">
     
        <img src={I404Png} className={'object-cover w-full h-full'}alt="404 page" />
   
    </div>
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
            THE PAGE YOU WERE LOOKING FOR DOESN'T EXIST.{" "}
          </span>
          <div className="pt-8">
            <Link to="/"
            className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-5 py-3 sm:px-3' style={{ color: 'white' }}
            >Return Home Page</Link>
          </div>
        </header>
      </div>
    </div>
    )
  }
}

export default NotFound