import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Slider from './Slider'
import { applyToProogram } from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
import AutoConpleteText from '../AutoConpleteText';
import { deleteProgram } from '../../../actions/WorkerActions';
import Swal from 'sweetalert2';
function withHooks(Component){
    return props => <Component {...props} navigate={useNavigate()} />
}
export class ProgramCard extends Component {
    state={
        btnVisible: false
    }
    getInstName(name){
        if(name){
            if(name.length > 29){
                return name.substring(0, 29)+ "..."
            }else{
                return name
            }
        }
    }
    getFeeType(type, mainTransateData){
        if(type){
            return +type === 0? mainTransateData?.yearly: mainTransateData?.monthly
        }
    }
    getFeeLabel(type, mainTransateData){
        if(type){
            return +type === 0? 'mainTransateData.yearly': 'mainTransateData.monthly'
        }
    }
    applyProgram(user, program, e){
        if(user == ''){
            this.props.changeStateValue({
                name: 'simulatorLogin',
                value: true
            })
        }else{
            this.setState({
                btnVisible: true
            })
            let formData = new FormData();
            formData.append('program_id', program.program_id);
            formData.append('worker_id', program.user_id
            );
            this.props.applyToProogram(formData)
            .then(resp=>{
                this.setState({
                    btnVisible: false
                })
               this.props.getAfterApply();
               window.open(window.location.origin)
            })
        }
    }

    getFeeAmount(x){
        var parts = x.toString().split(".");
        parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");
        return parts.join(",");
    }

    deleteItem(id){
        // console.log(id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.props.deleteProgram(id)
                .then(resp=>{
                    if(resp == 'success'){
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                          )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                          )
                    }
                    this.props.getAfterApply()
                })
              
            }
          })
       
    }

    applyProgramEducon(program, e){
      
        this.props.changeStateValue({
            name:'educonApplyModal',
            value: {
                position:true,
                program_id: program?.program_id
            }
        })
    }
  render() {
    const {program, user, btns= true, mainTransateData, reloadAfterLike=()=>{}, country='', city=''} = this.props;
    const {btnVisible} = this.state

    return (
        <div>
            
        {/* <Link to={`/program/${program.program_id}`} > */}
            <div  className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow`}
                data-nc-id="StayCard"  >
                    <Slider reloadAfterLike={reloadAfterLike} url={`/program/${program.program_id}`} program={program}  />
                <div className={"p-4 space-y-4"}>
                    <div className="space-y-2">
                        <span className="text-sm text-neutral-500 dark:text-neutral-400">
                       
                        {program.education_degree}
                        </span>
                        <div className="flex items-center space-x-2">
                        
                        <h2
                            className={` font-medium capitalize text-lg `}
                        >
                            {/* <span className="line-clamp-1">{program.name}</span> */}
                            <AutoConpleteText text={program?.name} />
                        </h2>
                        </div>
                        <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            </svg>
                            <span className="">{this.getInstName(program.institution)}</span>
                        </div>
                        {
                            country != '' && city != ''?
                            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                                <span className="">{country} / {city}</span>  
                            </div>:null
                        }
                    </div>
                    <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                    {
                        user == '' || btns?
                        <div className="flex justify-between items-center">
                            <span className="text-base font-semibold">
                               
                                {this.getFeeAmount(program?.program?.fee_amount)}

                                {` `}
                                {program.currency}
                                {` `}
                                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                                    /
                                <span aria-label={this.getFeeLabel(program?.program?.fee_type)}> {this.getFeeType(program?.program?.fee_type, mainTransateData)}</span>
                                </span>
                            
                            </span>
                            {/* <div
                            className={`nc-StartRating flex items-center space-x-1 text-sm `}
                            data-nc-id="StartRating"
                            >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5 text-red-500">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                            </svg>
                            <span className="font-medium ">55</span>
                            
                            </div> */}
                        </div>:null
                    }
                    {
                         user == '' || user != '' && user.role == 'student'?
                         program.apply?
                         <div className="flex justify-between items-center">
                            <button aria-label="mainTransateData.applied" className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                            {mainTransateData?.applied} 
                            </button>
                            <Link target={'_blank'} to={`/program/${program.program_id}`} className='ttnc-ButtonThird text-neutral-700 border 
                                            border-neutral-200 dark:text-neutral-200 
                                            dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                            items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                            px-4 py-1 sm:px-3' style={{ fontSize: '12px' }} aria-label="mainTransateData.view_details">
                                    {mainTransateData?.view_details}
                                </Link>
                         </div>:

                         <div className="flex justify-between items-center">
                            <button aria-label="mainTransateData.apply" disabled={btnVisible} onClick={this.applyProgram.bind(this, user, program)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                            {mainTransateData?.apply} 
                            </button>
                            <Link target={'_blank'} to={`/program/${program.program_id}`} className='ttnc-ButtonThird text-neutral-700 border 
                                            border-neutral-200 dark:text-neutral-200 
                                            dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                            items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                            px-4 py-1 sm:px-3' style={{ fontSize: '12px' }} aria-label="mainTransateData.view_details">
                                    {mainTransateData?.view_details}
                                </Link>
                         </div>:null
                    }
                    {
                        user?.role == "edu_consultant"?
                        <div className="flex justify-between items-center">
                             <button aria-label="mainTransateData.apply" disabled={btnVisible} onClick={this.applyProgramEducon.bind(this, program)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                            {mainTransateData?.apply} 
                            </button>
                        </div>:null
                    }
                    {
                        user.role == 'uni_rep' && !btns || user.role == 'content' && !btns?
                       <>
                            <div className="flex justify-between items-center">
                                <Link to={`/program/${program.program_id}`} aria-label="mainTransateData.view" className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                    {mainTransateData?.view}
                                </Link>
                                <Link aria-label="mainTransateData.edit"  to={`/edit-program/${program.programs_translate_id? program.programs_translate_id:program.id}`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                    {mainTransateData?.edit}
                                </Link>
                            </div>                       
                            <div className="flex justify-between items-center">
                                <Link to={`/dublicate-program/${program.programs_translate_id? program.programs_translate_id:program.id}`}  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }} aria-label="mainTransateData.dublicate">
                                    {mainTransateData?.dublicate}
                                </Link>
                                {
                                    user?.role == 'uni_rep'?
                                    <button onClick={this.deleteItem.bind(this,program.program_id)} aria-label="mainTransateData.waiting" className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-red-600 hover:bg-primary-700 text-neutral-50
                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                    Delete
                                </button>:null
                                }
                                {
                                    program?.active == '0'?
                                    <button aria-label="mainTransateData.waiting" className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                        {mainTransateData?.waiting}
                                    </button>:
                                    program?.programs_translate_id?
                                    <button aria-label="mainTransateData.waiting" className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                        {mainTransateData?.waiting}
                                    </button>:null

                                }
                                
                            </div>                       
                       </>
                        :null
                    }
                </div>
            </div>
            
        {/* </Link> */}
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user,
    mainTransateData: state.Data.mainTransateData,
})
const mapDispatchToProps = {applyToProogram, changeStateValue, deleteProgram}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ProgramCard))