import { connect } from 'react-redux';
import React, { Component } from 'react'
import {changeStateValue} from '../../../redux/MainReducer';
export class AddRangeBtn extends Component {
    state={
        containerRef: React.createRef(),
        position: false
    }
    showModal(position, e){
        this.setState({
            position: !position
        })
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.position !== this.state.position){
            window.addEventListener('click', (e)=> {
                const {containerRef} = this.state;
                if(containerRef && containerRef.current){
                    const body = containerRef?.current.contains(e.target);
                    if(!body || e.target.className === 'add_range_container'){
                        this.setState({
                            position: false
                        })
                    }
                }
            })
        }
    }
    selectExam(id, array, name, e){
        let newArray = [...array];
        const checkExam = newArray.findIndex(data=> data == id);
        if(checkExam == -1){
            newArray.push(id)
        }else{
            newArray.splice(checkExam, 1);
           
            this.props.changeStateValue({
                name: 'filterExamGrade.'+name.toLowerCase(),
                value: ''
            })
        }
        localStorage.setItem('filterExamIds', JSON.stringify(newArray))
        this.props.changeStateValue({
            name: 'filterExamIds',
            value: newArray
        })
        this.setState({
            position: false
        })
    }
    checkValue(array, id){
        const check = array.find(data=>data == id);
        if(check){
            return true;
        }else{
            return false;
        }
    }

    componentDidMount(){
        if(localStorage.getItem('filterExamIds')){
            this.props.changeStateValue({
                name: 'filterExamIds',
                value: JSON.parse(localStorage.getItem('filterExamIds'))
            })
        }
    }
  render() {
    const {containerRef, position} = this.state;
    const {filterExamIds, mainTransateData} = this.props;
    const exams = [
        {
            id: 1,
            name: 'IELTS'
        },
        {
            id: 2,
            name: 'TOEFL'
        },
        {
            id: 3,
            name: 'PTE'
        },
        {
            id: 4,
            name: 'Duolingo'
        },
        {
            id: 5,
            name: 'SAT'
        },
        {
            id: 6,
            name: 'GRE'
        },
        {
            id: 7,
            name: 'GMAT'
        },
    ];

    return (
      <div ref={containerRef}  className='add_range_container'>
        <button onClick={this.showModal.bind(this, position)}  className='ttnc-ButtonThird text-neutral-700 border 
                                border-neutral-200 dark:text-neutral-200 
                                dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                items-center justify-center rounded-full transition-colors
                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                px-4 py-1 sm:px-3 ml-5 mt-4' style={{ fontSize: '12px' }} aria-label="mainTransateData.add_exam" >{mainTransateData?.add_exam} </button>
        {
            position?
            <div className='add_range_modal'>
                <ul className='filter_inner_dropdown_body'>
                    {
                        exams.map((exam, i)=>{
                            return(
                                <li key={i}><label htmlFor={exam.name+exam.id}><input checked={this.checkValue(filterExamIds, exam.id)} onChange={this.selectExam.bind(this, exam.id, filterExamIds, exam.name)} id={exam.name+exam.id} type={'checkbox'}/> <span>{exam.name}</span></label></li>
                            )
                        })
                    }
                </ul>
            </div>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    filterExamIds: state.Data.filterExamIds,
    mainTransateData: state.Data.mainTransateData,

});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AddRangeBtn)