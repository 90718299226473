import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCountriesFaqTranslator } from '../../actions/TranslateAction';
import SectionHeader from '../../components/page_component/SectionHeader';
import TranslatorCard2 from './components/TranslatorCard2';
export class TranslatorCountryFaq extends Component {

    componentDidMount(){
        this.props.getCountriesFaqTranslator()
    }

    getAvailableData(data, item){
        // console.log(data)
        let array = [];
        for(const property in data.default){
         
          if(property == 'question' || property == 'answer'){
            let x = []
            x.push(
              `${property}`, data[item]&&data[item][property]?data[item][property]:''
            )
            array.push(x)
    
          }
        }
    
        return array
      }

      getAfterSave = () =>{
        this.props.getCountriesFaqTranslator()
      }
  render() {
    const {countriesFaqTranslatorData, user} = this.props;

    return (
      <div className='container'>
         {
          user != ''?
          <SectionHeader  title={`Translate Country FAQs From English to ${user?.lang?.main_lang?.name}`} />:null
        }
        <ul>
            {
                countriesFaqTranslatorData.map((data, i)=>{
                    return(
                        <TranslatorCard2 
                           getAfterSave={this.getAfterSave}
                            url={`setCountryFaqTranslate/${countriesFaqTranslatorData[i].default.country_fags_id}`} 
                            key={i} 
                            data={this.getAvailableData(data, 'default')} 
                            translate={this.getAvailableData(data, 'translate')} 
                        />
                    )
                })
            }
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    countriesFaqTranslatorData: state.Data.countriesFaqTranslatorData,
    user: state.Data.user,
});
const mapDispatchToProps = { getCountriesFaqTranslator }

export default connect(mapStateToProps, mapDispatchToProps)(TranslatorCountryFaq)