import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoadMoreBtn from '../../../page_component/LoadMoreBtn'
import ProgramCard from '../../../page_component/programs/ProgramCard'
import SectionHeader from '../../../page_component/SectionHeader'
import LoginComponent from '../../../page_component/simulator_components/login_register/LoginComponent'
import ViewAllBtn from '../../../page_component/ViewAllBtn'
import FilterprogramByDegree from '../components/FilterprogramByDegree'
import FilterProgramByDiscipline from '../components/FilterProgramByDiscipline'

export class Programs extends Component {
  state={
    filterDiscipline: '',
    filterDegree: '',
    page: 1
  }
  setFilterDiscipline = (data) =>{
    this.setState({
      filterDiscipline: data
    })

    this.props.changeFilterData('filterDiscipline', data)
  }
  setFilterDegree = (data) =>{
    this.setState({
      filterDegree: data
    })
    this.props.changeFilterData('filterDegree', data)
  }

  loadMoreFunction = (page) => {
    this.props.loadMore(page)
    this.setState({page:page})
  }
  render() {
    const {programs, id = '', getAfterApply, simulatorLogin, user, mainTransateData, unirepRoleTranslate} = this.props;
    const {filterDiscipline, filterDegree, page} = this.state;
    return (
        <div className="nc-SectionGridFeaturePlaces relative" >
          <div className='mb-5 profile_header_btn gap-5'>
         
          <FilterprogramByDegree page={page} filterDiscipline={filterDiscipline} setFilterDegree={this.setFilterDegree}/>
          <FilterProgramByDiscipline page={page} filterDegree={filterDegree} setFilterDiscipline={this.setFilterDiscipline} />
         </div>
          {
            programs.length != 0?
            <>
              
            {/* <div className="flex flex-col mb-8 relative" >
            <SectionHeader btnTitle={unirepRoleTranslate?.add_program} title={mainTransateData?.program_title} btn={user !== '' && user.role == 'uni_rep'?`/create-program/${id}`: false} description={mainTransateData?.program_desc} />
            </div> */}
            <div  className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
            {
                programs &&
                programs.map((program, i)=>{
                // if(i< 8){
                    return(
                    <ProgramCard getAfterApply={getAfterApply} key={i} program={program} btns={false} />
                    )
                // }
                })
            }
            
            </div>
            {
              programs.length>=20?
              <LoadMoreBtn  position={programs.length} loadMoreFunction={this.loadMoreFunction}/>:null
            }
            
            {
              simulatorLogin?
              <LoginComponent />:null
            }
            </>:null
          }
         
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
  simulatorLogin: state.Data.simulatorLogin,
  user: state.Data.user,
  mainTransateData: state.Data.mainTransateData,
  unirepRoleTranslate: state.Data.unirepRoleTranslate,
})
export default connect(mapStateToProps)(Programs)