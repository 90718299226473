import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeTranslateData } from '../../actions/TranslateAction';
export class TranslatorCard extends Component {
    state={
        text: ''
    }
    changeData(data,text, e){
        let url = data.split('.')[0];
        let title = data.split('.')[1];

        let formData = new FormData();
        formData.append('title',title)
        formData.append('text',text)
       
        this.props.changeTranslateData(url, formData)
    }
    changeInpValue(e){
        this.setState({
            text: e.target.value
        })
    }
  render() {
    const { data } = this.props;
    const { text } = this.state;

    return (
      <li className='flex justify-between mt-4 border-b pb-2'>
        <span>{data.title}</span>
        <div className='flex gap-2'>
            <input style={{ width: '200px' }} onChange={this.changeInpValue.bind(this)} type={'text'} value={text == ''? this.props?.data?.value: text} />
            <button style={{ color: 'white' }} onClick={this.changeData.bind(this, data.property, text)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3'>Save</button>
        </div>

      </li>
    )
  }
}
const mapStateToProps = (state) => ({
    
});
const mapDispatchToProps = {changeTranslateData}
export default connect(mapStateToProps, mapDispatchToProps)(TranslatorCard)