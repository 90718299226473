import React, { Component } from 'react'
import { getWorkerPrograms } from '../../../actions/WorkerActions'
import { connect } from 'react-redux'
export class UniCard extends Component {
    state={
        progPage:1,
        uniId:'',
        programs: [],
        position: false,
        searchProg:''
    }
    getPrograms(data){
        this.setState({uniId: data?.institutions_id})
        this.props.getWorkerPrograms(1, 1, data?.institutions_id, '', '', this.state.searchProg)
        .then(resp=>{
            this.setState({programs: resp})
        })
    }
    loadMoreBtn(page){
        if(this.state.uniId != ''){
           let x = page;
            x++;
            this.props.getWorkerPrograms(x, 1, this.state.uniId, '', '', this.state.searchProg)
            .then(resp=>{
                this.setState({programs: resp})
            })
            this.setState({progPage:x}) 
        }
        
    }

    getSearchBtn(text,id,  e){
        e.preventDefault();
        this.setState({uniId: id})
        if(text != ''){
            this.props.getWorkerPrograms(this.state.progPage, 1, id, '', '', text)
            .then(resp=>{
                this.setState({programs: resp})
            })
        }
        
    }
  render() {
    const {data, changeBtn} = this.props;
    const {programs, progPage, position, searchProg} = this.state
    return (
        <div className='border  p-2 mt-2 rounded-xl' >
            <p  className='text-sm cursor-pointer flex justify-between items-center'><span style={{ width:'80%' }} onClick={()=>this.setState({position: !position})} >{data?.name}</span> <button className='text-sm p-1 rounded-xl border' onClick={this.getPrograms.bind(this, data)}>Get programs</button></p>
            {
                position?
                <div style={{ maxHeight:'40vh', overflow:'auto' }} className='bg-gray-200 p-3 rounded-xl mt-6'>
                    <form  className='flex justify-between items-center gap-3'>
                        <input value={searchProg} onChange={(e)=>this.setState({searchProg:e.target.value})} placeholder='Search Programs' type='text' />
                        <button onClick={this.getSearchBtn.bind(this, searchProg, data?.institutions_id)} className='rounded-full text-sm border bg-white p-2'>Search</button>
                    </form>
                    {
                        programs.map((item, j)=>{
                            return(
                                <div className='mt-2' key={j}>
                                    <p style={{ borderBottom:'1px solid white' }} className='text-sm p-1 flex justify-between items-center' >{j+1}. {item?.name} ({item?.education_degree}) 
                                        <button onClick={()=>changeBtn(item)} className='text-sm rounded-xl bg-blue-700 text-white font-semibold p-2'>Apply</button>
                                    </p>
                                </div>
                            )
                        })
                    }

                    {
                        programs.length %20  == 0?
                        <div className='flex justify-center mt-3'>
                            <button onClick={this.loadMoreBtn.bind(this, progPage)} className='text-sm bg-white p-1 rounded-xl'>Load more</button>
                        </div>:null
                    }
                    
                </div>:null
            }
            
        </div> 
    )
  }
}

const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {getWorkerPrograms}

export default connect(mapStateToProps, mapDispatchToProps)(UniCard)
