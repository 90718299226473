import React, { Component } from 'react'
import { connect } from 'react-redux'
import Background from '../components/page_component/Background'
import BgGlassmorphism from '../components/page_component/BgGlassmorphism'
import Program from '../components/page_component/programs/Program'
import SectionHero from '../components/page_component/SectionHero'
import LoginComponent from '../components/page_component/simulator_components/login_register/LoginComponent'
import { mainDashboardTranslate, getUnirepRoleTranslate } from '../actions/TranslateAction'
export class Programs extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
    this.props.mainDashboardTranslate(this.props.languageId)
    this.props.getUnirepRoleTranslate(this.props.languageId);

  }
  render() {
    const {simulatorLogin} = this.props
    return (
      <div className="nc-PageHome relative">
        <BgGlassmorphism />
        <div className="container relative space-y-24 lg:space-y-28 ">
          {/* <SectionHero /> */}
          <div className="relative ">
            {/* <Background /> */}
            <Program page={20} btn={'load_more'} />
          </div>
        </div>
        {
          simulatorLogin?
          <LoginComponent />:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  simulatorLogin: state.Data.simulatorLogin,
  languageId: state.Data.languageId,
  
});
const mapDispatchToProps = {mainDashboardTranslate, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(Programs)