import React from 'react';
import ReactDOM from 'react-dom/client';
//
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import App from './App';
import { Provider } from 'react-redux'; 
import { setupStore } from './redux/store'; 
const store = setupStore()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}> 
    <App />
  </Provider>
);

