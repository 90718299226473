// export const MAIN_API = 'http://127.0.0.1:8000/api/v1';
// export const MAIN_API = 'http://10.0.0.129:8000/api/v1';
// export const MAIN_API = ' http://localhost:8000/api/v1';
export const MAIN_API = 'https://goapi.celt.vip/api/v1';  //live
// export const MAIN_API = 'https://phplaravel-944849-3442399.cloudwaysapps.com/api/v1'; //stage


// export const MAIN_API = 'https://bck.celtgo.com/api/v1'; //plesk 
// export const MAIN_API = 'https://go.celt.network/public/api/v1'; 
// export const MAIN_API = 'http://69.48.156.129/backend/public/api/v1'; 
// 
// export const MAIN_API2 = 'http://127.0.0.1:8000/api';
// export const MAIN_API2 = 'http://10.0.0.150:8001/api';
export const MAIN_API2 = 'https://phplaravel-944849-3793935.cloudwaysapps.com/api';


// export const NODE_API = 'https://chat-kb2gz.ondigitalocean.app/' //digital
// export const NODE_API = 'https://nck.celtgo.com/' //digital //haziri budu
// export const NODE_API = 'https://nck.celtgo.com/' //digital //haziri budu

// export const NODE_API = 'https://www.ecstatic-galois.188-166-170-225.plesk.page/' //digital
// export const NODE_API = 'https://phpstack-944849-3288229.cloudwaysapps.com/' //cloud
// export const NODE_API = 'http://localhost:3300'

export const GOOGLE_CLIENT_ID =  '212314115775-r8hduk68tlj56ep30cq1rsrkq2m0g3p0.apps.googleusercontent.com'; // celtgo.com
// export const GOOGLE_CLIENT_ID =  '867464479957-no43e0nc3pe5blnpvr11n54n2ncq3v63.apps.googleusercontent.com'; // local
export const GOOGLE_SECRET_ID = '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'

export const img_path = 'https://cab.ams3.digitaloceanspaces.com/uploads/'