import React, { Component } from 'react'
import { connect } from 'react-redux'
import BgGlassmorphism from '../components/page_component/BgGlassmorphism'
import SectionHero from '../components/page_component/SectionHero'
import { mainDashboardTranslate, getUnirepRoleTranslate } from '../actions/TranslateAction'
import { getScholarships } from '../actions/MainAction'
import ProgramCard from '../components/page_component/programs/ProgramCard'
import LoginComponent from '../components/page_component/simulator_components/login_register/LoginComponent'
import SectionHeader from '../components/page_component/SectionHeader'
import LoadMoreBtn from '../components/page_component/LoadMoreBtn'
import Filter from '../components/page_component/scholarship/Filter'
import SkeletonCard from '../components/page_component/SkeletonCard'
export class Schoolarships extends Component {
  state={
    statePage: 1
  }
  componentDidMount(){
    window.scrollTo(0, 0);
    this.props.mainDashboardTranslate(this.props.languageId)
    this.props.getUnirepRoleTranslate(this.props.languageId);

    if(!localStorage.getItem('token')){
      this.props.getScholarships('public-program-schoolarship',1, this.props.languageId)
    }else{
      this.props.getScholarships('program-schoolarship',1, this.props.languageId)
    }
    
  }
  getAfterApply = () =>{
    if(!localStorage.getItem('token')){
      this.props.getScholarships('public-program-schoolarship',this.state.statePage, this.props.languageId)

    }else{
      this.props.getScholarships('program-schoolarship',this.state.statePage, this.props.languageId)
    }
  }
  loadMoreFunction = (page) =>{
   
    if(!localStorage.getItem('token')){
      this.props.getScholarships('public-program-schoolarship',page, this.props.languageId)

    }else{
      this.props.getScholarships('program-schoolarship',page, this.props.languageId)
    }
    this.setState({statePage: page})
  }

  
  render() {
    const {scholarshipPrograms, simulatorLogin, mainTransateData} = this.props;
    const {statePage} = this.state;
    return (
        <div className="nc-PageHome relative ">
            <BgGlassmorphism />
            <div className="flex flex-col mt-20 relative">
              <SectionHeader arialLabelTitle="mainTransateData.sch_title"  title={mainTransateData?.sch_title} />
            </div>
            <div className="container relative space-y-10 mb-24 lg:space-y-10 lg:mb-28">
            {/* <SectionHero /> */}
            <Filter  pageCount={statePage} />
              <div  className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
                {
                  scholarshipPrograms&&
                  scholarshipPrograms.map((program, i)=>{
                    return(
                      <ProgramCard getAfterApply={this.getAfterApply} key={i} program={program} />
                    )
                  })
                }
                {
                  scholarshipPrograms?.length == 0?
                  <>
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  </>:null
                }
              </div>
            </div>
            {
              scholarshipPrograms.length >= 20?
              <LoadMoreBtn position={scholarshipPrograms.length} loadMoreFunction={this.loadMoreFunction}  />:null
            }
            {
          simulatorLogin?
          <LoginComponent />:null
        }
        </div>
    )
  }
}

const mapStateToProps = (state) =>({
  languageId: state.Data.languageId,
  scholarshipPrograms: state.Data.scholarshipPrograms,
  simulatorLogin: state.Data.simulatorLogin,
  mainTransateData: state.Data.mainTransateData,
});
const mapDispatchToProps = {mainDashboardTranslate, getScholarships, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(Schoolarships)