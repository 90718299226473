import React, { Component } from 'react'
import { connect } from 'react-redux';

export class StudentData extends Component {
  render() {
    const {data, unirepRoleTranslate} = this.props;

    return (
        <div className="listingSection__wrap border rounded-2xl p-2">
            <h2 className="text-2xl font-semibold">{data.full_name}</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="text-neutral-6000 dark:text-neutral-300">
                {
                    data?.edu_back?.country?
                    <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            </svg>
                        <span className="">{data?.edu_back?.country?.name}</span>
                    </div>:null


                }
                
                {
                    data?.edu_back?.institution_name && data?.edu_back?.institution_name != ''?
                    <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2 mt-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width={'14px'} viewBox="0 0 512 512">
                            <path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8.1-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zm128-96c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/>
                        </svg>
                        <span className="">{ data?.edu_back?.institution_name}</span>
                    </div>:null
                }
                {   data?.edu_back?.degree?

                    <div className="flex justify-between items-center mt-5">
                        <span className="text-base font-semibold flex gap-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width={'22px'} viewBox="0 0 640 512">
                                <path d="M623.1 136.9l-282.7-101.2c-13.73-4.91-28.7-4.91-42.43 0L16.05 136.9C6.438 140.4 0 149.6 0 160s6.438 19.65 16.05 23.09L76.07 204.6c-11.89 15.8-20.26 34.16-24.55 53.95C40.05 263.4 32 274.8 32 288c0 9.953 4.814 18.49 11.94 24.36l-24.83 149C17.48 471.1 25 480 34.89 480H93.11c9.887 0 17.41-8.879 15.78-18.63l-24.83-149C91.19 306.5 96 297.1 96 288c0-10.29-5.174-19.03-12.72-24.89c4.252-17.76 12.88-33.82 24.94-47.03l190.6 68.23c13.73 4.91 28.7 4.91 42.43 0l282.7-101.2C633.6 179.6 640 170.4 640 160S633.6 140.4 623.1 136.9zM351.1 314.4C341.7 318.1 330.9 320 320 320c-10.92 0-21.69-1.867-32-5.555L142.8 262.5L128 405.3C128 446.6 213.1 480 320 480c105.1 0 192-33.4 192-74.67l-14.78-142.9L351.1 314.4z"/>
                            </svg>
                            {data?.edu_back?.degree?.education_type}
                        
                        </span>
                    
                    
                    </div>:null
                }
                 <div className='flex mt-3' style={{ flexDirection: 'column' }}>
                    <span className="text-base font-semibold">Email: <span style={{ fontWeight: '400' }}>{data?.email}</span></span>
                  <span className="text-base font-semibold">Phone: <span style={{ fontWeight: '400' }}>{data?.phone_number}</span></span>
                 </div>
                {
                    data?.exams && data?.exams.length != 0?

                    <div className="flex justify-between items-center mt-5">
                        <span className="text-base font-semibold ">
                            
                            {unirepRoleTranslate?.exams}
                            <ul>
                                {
                                    data?.exams.map((exam, i)=>{
                                        return(
                                            <li style={{ fontSize: '13px', fontWeight: '400' }} key={i}>{exam?.exam?.exam_type} ({exam?.over_all})</li>
                                        )
                                    })
                                }
                            </ul>
                        </span>
                    </div>:null
                }
                {
                   data?.activities && data?.activities.length != 0?

                    <div className="flex justify-between items-center mt-5">
                        <span className="text-base font-semibold ">
                            
                            {unirepRoleTranslate?.extra_act}
                            <ul>
                                {
                                    data?.activities.map((activity, i)=>{
                                        return(
                                            <li style={{ fontSize: '13px', fontWeight: '400' }} key={i}>{activity?.title} </li>
                                        )
                                    })
                                }
                            </ul>
                        </span>
                    </div>:null
                }
                {
                   data?.olympiads && data?.olympiads.length != 0?

                    <div className="flex justify-between items-center mt-5">
                        <span className="text-base font-semibold ">
                            
                            {unirepRoleTranslate?.olymp_medal}
                            <ul>
                                {
                                    data?.olympiads.map((olympiad, i)=>{
                                        return(
                                            <li style={{ fontSize: '13px', fontWeight: '400' }} key={i}>{olympiad?.title} </li>
                                        )
                                    })
                                }
                            </ul>
                        </span>
                    </div>:null
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    unirepRoleTranslate: state.Data.unirepRoleTranslate
})
export default connect(mapStateToProps)(StudentData)