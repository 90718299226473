export function getObject(data){
 
    let disciplines = [];
    let states = [];
    let cities = [];
    let institutions = [];
    let countries = [];
    let activities = [];
    let olympiads = [];
    let examId = [];
    let exams = {};

    if(data.students_exam.length !== 0){
        for(const item of data.students_exam){
            examId.push(+item.exam_id);
            exams[item.title.toLowerCase()] = +item.over_all
        }
    }
    let obj = {
        page:1,
        keyword: '' ,
        filterByCountry: '',
        filterByState:'',
        filterByCity:'',
        filterByStatus:1,
        filterByInstitution:'',
        lang_id: 1,
        filterByLevel:'',
        filterByDiscipline:'',
        filterByDuration:'',
        filterByFee: '',
        filterStudiedCountry: '',
        filterByCountryLevel: '',
        filterByGPA: '',
        filterByGradingScheme: '',
        filterByALevel: '',
        filterByOSSD: '',
        filterByIB: '',
        filterByAP: '',
        filterByExtraActivities: '',
        filterByInternationalOlympiads: '',
        gpaConverter: '',
        gradingSchemeId: ''
    }
    if(data.student_discipline.length !== 0){
        for(const item of data.student_discipline){
            disciplines.push(item.discipline_id)
        }
        obj.filterByDiscipline = disciplines.join(',')
    } 
    if(data.student_state.length !== 0){
        for(const item of data.student_state){
            states.push(item.state_id)
        }
        obj.filterByState = states.join(',')
    } 
    if(data.student_country.length !== 0){
        for(const item of data.student_country){
            countries.push(item.country_id)
        }
        obj.filterByCountry = countries.join(',')
    } 
    if(data.student_city.length !== 0){
        for(const item of data.student_city){
            cities.push(item.city_id)
        }
        obj.filterByCity = cities.join(',')
    } 
    if(data.student_institution.length !== 0){
        for(const item of data.student_institution){
            institutions.push(item.inst_id)
        }
        obj.filterByInstitution = institutions.join(',')
    } 

    if(data.student_activity.length !== 0){
        for(const item of data.student_activity){
            activities.push(item.activity_id)
        }
        obj.filterByExtraActivities = activities.join(',')
    } 
    if(data.student_olympiad.length !== 0){
        for(const item of data.student_olympiad){
            olympiads.push(item.olympiad_id)
        }
        obj.filterByInternationalOlympiads = olympiads.join(',')
    } 
    
    if(data.student_info){
        obj.filterStudiedCountry = data.student_info.study_country_id ? data.student_info.study_country_id : '';
        obj.filterByLevel = data.student_info.study_degree_id ? data.student_info.study_degree_id : '';
        obj.filterByCountryLevel = data.student_info.study_sub_degree_id ? data.student_info.study_sub_degree_id : '';
        obj.filterByGradingScheme = data.student_info.grade_interval ? data.student_info.grade_interval : '';
        obj.gradingSchemeId = data.student_info.grading_scheme_id ? data.student_info.grading_scheme_id : '';
        obj.gpaConverter = data.gpaConverter ?data.gpaConverter : '';

        if(data.student_info.study_sub_degree_id === 'a_level'){
            obj.filterByALevel = data.student_info.study_gpa;
        }else if(data.student_info.study_sub_degree_id === 'ossd'){
            obj.filterByOSSD = data.student_info.study_gpa;
        }else if(data.student_info.study_sub_degree_id === 'ib_diploma'){
            obj.filterByIB = data.student_info.study_gpa;
        }else if(data.student_info.study_sub_degree_id === 'ap'){
            obj.filterByAP = data.student_info.study_gpa;
        }else{
            obj.filterByGPA = data.student_info.study_gpa?data.student_info.study_gpa:'';
        }
    }

    return {obj, examId, exams}
    
}