import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
export class Search extends Component {
   
    searchBtn(e){
        e.preventDefault();
       
    }

    searchInp(e){
       this.props.changeStateValue({
        name: 'seachmsgUsers',
        value: e.target.value
       })
        
    }
  
  render() {
    const {seachmsgUsers} = this.props
    return (
        <form style={{ width:'80%' }}className='flex ml-1' >
            <button onClick={this.searchBtn.bind(this)}>
                <svg height={24} width={24} xmlSpace="preserve">
                    <path
                    fill="currentColor"
                    d="M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z"
                    />
                </svg>
            </button>
          
            <input onChange={this.searchInp.bind(this)}  value={seachmsgUsers} type={'text'} />
            
            
        </form>
    )
  }
}
const mapStateToProps = (state) => ({
    seachmsgUsers: state.Data.seachmsgUsers
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Search)