import React, { Component } from 'react'

export class CheckSvg extends Component {
  render() {
    const {color = "#323232"} = this.props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={25}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke={color}
          strokeWidth={2}
          d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="m9 12 1.683 1.683v0c.175.175.459.175.634 0v0L15 10"
        />
      </svg>
    )
  }
}

export default CheckSvg
