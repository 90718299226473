import React, { Component } from 'react'
import { connect } from 'react-redux';
import CheckFilter from './CheckFilter'
import { getAllCountries, getStateByCountry, getcityByCountryAndState, getPrograms, getcityBState } from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
import { approvePrograms, getInactivePrograms } from '../../../actions/WorkerActions';
export class Filter extends Component {
    state={
        country: '',
        state:'',
        city: '',
        manualRender: 0,
        search: ''
    } 
    componentDidMount(){
        this.props.getAllCountries(1, '', this.props.languageId)
        this.props.changeStateValue({
          name: 'stateByCountry',
          value: []
        })
        this.props.changeStateValue({
          name: 'citiesByCountryAndState',
          value: []
        })
        this.props.getInactivePrograms()
    }

    getFilterProgram(country, state, city, search){
      
        if(localStorage.getItem('token')){
            this.props.getPrograms(this.props.pageCount, this.props.languageId, '', '', 'programs', country, state, city, '', search)
      
        }else{
        this.props.getPrograms(this.props.pageCount, this.props.languageId, '', '', 'public-programs',  country, state, city, '', search)
        }
    }
    checkDataBtn = (data, type) =>{
        this.props.filterData(data.join(), type)
        this.setState({
            [type]: data.join()
        })
        if(type == 'country'){   
   
            if(data.length !== 0){
              this.props.getStateByCountry(null, this.props.languageId, data.join())
              this.props.getcityByCountryAndState( this.props.languageId, data.join(), this.state.state);
              this.setState({
                state:'',
                city: '',
                manualRender:1
              })
              setTimeout(()=>{
                this.setState({
                  manualRender:0
                })
              }, 100)
            }else{
              this.props.changeStateValue({
                name: 'stateByCountry',
                value: []
              })
              this.props.changeStateValue({
                name: 'citiesByCountryAndState',
                value: []
              })
            }
            
            this.getFilterProgram(data.join(), this.state.state, this.state.city, this.state.search)
        }else if(type == 'state'){
          if(data.length != 0){
            this.props.getcityBState( this.props.languageId, data.join());
          }else{
            this.props.changeStateValue({
              name: 'citiesByCountryAndState',
              value: []
            })
          }
            
          this.getFilterProgram( this.state.country, data.join(), this.state.city, this.state.search)

        }else if(type == 'city'){
            this.getFilterProgram( this.state.country, this.state.state, data.join(), this.state.search)

        }
     
    }

    searchProgram(search, e){
      e.preventDefault();
      if(search.trim() != ''){
        this.getFilterProgram( this.state.country, this.state.state, this.state.city, search)
      }

    }

    getInpValue(e){
      this.setState({search: e.target.value})
      if(e.target.value == ''){
        this.getFilterProgram( this.state.country, this.state.state, this.state.city, '')
      }
    }

    approvePrograms(count, e){
      if(count != 0){
        this.props.approvePrograms().then(resp=>{this.props.getInactivePrograms()})
      }
    }
  render() {
    const {allCountries, stateByCountry, citiesByCountryAndState, mainTransateData, user, inactivePrograms} = this.props;
    const { manualRender, search} = this.state;


    let countriesOptions = [];
    const stateOption = [];
    const cityOption = [];
    for(const country of  [...allCountries].sort((x, y)=> {  return y?.countries?.institution_count - x?.countries?.institution_count})){
      const params = {
        name: country.name,
        id: country.countries_id
      }
      countriesOptions.push(params)
    }
    for(const state of stateByCountry){
      const params = {
        name: state.name,
        id: state.state_id
      }
      stateOption.push(params)
    }
    for(const city of citiesByCountryAndState){
      const params = {
        name: city.name,
        id: city.city_id
      }
      cityOption.push(params)
    }

    return (
      <div className='filter_btn'>
        <CheckFilter array={countriesOptions} type="country" label="mainTransateData.countries" title={mainTransateData?.countries} checkDataBtn={this.checkDataBtn} />
        {
            stateOption.length !== 0 && manualRender == 0?
            <CheckFilter array={stateOption} type="state" label="mainTransateData.states" title={mainTransateData?.states} checkDataBtn={this.checkDataBtn} />:null
        }
        {
            cityOption.length !== 0 && manualRender == 0?
            <CheckFilter array={cityOption} type="city" label="mainTransateData.cities" title={mainTransateData?.cities} checkDataBtn={this.checkDataBtn} />:null
        }
        <form className='flex gap-4 search_container border rounded-2xl' style={{ borderRadius: '50px' }}>
          <input value={search} onChange={e=>this.getInpValue(e)} type={"text"} />
          <button onClick={this.searchProgram.bind(this, search)}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.1483 2.15152C9.41962 -0.618272 4.94621 -0.651706 2.17637 2.07686C-0.593479 4.80554 -0.62697 9.2789 2.10171 12.0487C4.41662 14.3985 7.88282 14.7777 10.6038 13.1731C10.6038 13.1731 10.8005 13.0578 10.9568 13.2166C11.8494 14.1227 14.5274 16.841 14.5274 16.841C15.238 17.5625 16.2379 17.7404 16.8778 17.1102L16.9878 17.0017C17.6277 16.3714 17.4648 15.3689 16.7541 14.6475C16.7541 14.6475 14.0817 11.9349 13.191 11.0307C13.0271 10.8643 13.1453 10.6694 13.1453 10.6694C14.7904 7.97284 14.4632 4.50136 12.1483 2.15152ZM10.7816 10.8118C8.73493 12.828 5.42961 12.8032 3.4134 10.7566C1.39724 8.70997 1.42193 5.40465 3.46856 3.3885C5.51514 1.37228 8.82051 1.39703 10.8367 3.44366C12.8528 5.49029 12.8281 8.79555 10.7816 10.8118Z" fill="black"/>
              </svg>
          </button>
        </form>
        {
          user != '' && user.role == 'uni_rep'?
          <button onClick={this.approvePrograms.bind(this, inactivePrograms)} className='border rounded-2xl pl-3 pr-3 text-sm'>Ativate programs ({inactivePrograms})</button>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    languageId: state.Data.languageId,
    allCountries: state.Data.allCountries,
    stateByCountry: state.Data.stateByCountry,
    citiesByCountryAndState: state.Data.citiesByCountryAndState,
    mainTransateData: state.Data.mainTransateData,
    user: state.Data.user,
    inactivePrograms: state.Data.inactivePrograms,
    
});
const mapDispatchToProps = {getAllCountries, getStateByCountry,approvePrograms, getcityByCountryAndState, getPrograms,getInactivePrograms, changeStateValue, getcityBState}
export default connect(mapStateToProps, mapDispatchToProps)(Filter)