import React, { Component } from 'react'
import { connect } from 'react-redux';
import {getAllCountries} from '../../actions/MainAction'
import Searchable from 'react-searchable-dropdown'; 
import { insertNewData } from '../../actions/WorkerActions';
import Loading from '../../components/page_component/Loading';
export class AddStateCityUniPage extends Component {
    state={
      files:'',
      countryId:'',
      cityFiles: '',
      btnDisable: false
    }
    componentDidMount(){
        this.props.getAllCountries(1, '', this.props.languageId);
    }

    getValue(e){
      // console.log(e.target.files[0])
      var reader = new FileReader();
      reader.onload = this.onReaderLoad;
      reader.readAsText(e.target.files[0]);
  }
  onReaderLoad=(event)=>{
      // console.log(event.target.result);
      var obj = JSON.parse(event.target.result);
      // console.log(obj?.results)
      this.setState({files: obj})
  }
  getCityJson(e){
      // console.log(e.target.files[0])
      var reader = new FileReader();
      reader.onload = this.onReaderLoadCity;
      reader.readAsText(e.target.files[0]);
  }
  onReaderLoadCity=(event)=>{
      // console.log(event.target.result);
      var obj = JSON.parse(event.target.result);
      // console.log(obj?.results)
      this.setState({cityFiles: obj})
  }
  selectDefaultData=(value)=>{
    this.setState({countryId:value})
  }

  uploadJsonBtn=(data, id)=>{
    if(data != '' && id != ''){
      // console.log(data, id)
      let formData = new FormData();
      formData.append('params', JSON.stringify(data));
      formData.append('countryId', id);
      formData.append('image',  "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg");
      this.props.insertNewData('/add-all-data', formData)
    }
  }
  uploadJsonBtnCity=(data)=>{
    if(data != ''){
      // console.log(data, id)
      this.setState({btnDisable:true})
      let formData = new FormData();
      formData.append('params', JSON.stringify(data));
      this.props.insertNewData('/update-city-images', formData)
      .then(resp=>{
        this.setState({btnDisable:false})
      })
    }
  }
  render() {
    const {files, countryId, cityFiles, btnDisable} = this.state;
    const {allCountries,   loader , loaderLong} = this.props;
  
    let countryOption = [];
    for(const params of allCountries){
      countryOption.push({
        label: params.name,
        value: params.countries_id
      })
    }

   
    return (
      <div className='container'>
        <div className='flex items-center gap-10 '>
          <label className='cursor-pointer mt-6' style={{ color: files != ''? 'blue':'' }} htmlFor='addAllState'>Upload Json</label>
          <input style={{ display: 'none' }} id='addAllState' type='file' onChange={this.getValue.bind(this)} accept='application/json' />
          <div >
            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                Select a Country
            </label>
            <div style={{ width: '300px' }} className={`block w-full border-neutral-200  
                focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
            > 
                <Searchable 
                    options={countryOption}
                    onSelect={this.selectDefaultData.bind(this)}
                    placeholder={"Select a Country"}
                
                />
            </div>
          </div>
          <button className='border rounded-xl bg-blue-700 text-white text-sm p-2 mt-4' onClick={this.uploadJsonBtn.bind(this, files, countryId)}>Add</button>
        </div>
        <hr className='mt-5' />
        <h4>City Image Update</h4>
        <div className='flex items-center gap-10 mt-4'>
          <label className='cursor-pointer mt-6' style={{ color: files != ''? 'blue':'' }} htmlFor='addAllStateCityImg'>Upload Json</label>
          <input style={{ display: 'none' }} id='addAllStateCityImg' type='file' onChange={this.getCityJson.bind(this)} accept='application/json' />
         
          <button disabled={btnDisable} className='border rounded-xl bg-blue-700 text-white text-sm p-2 mt-4' onClick={this.uploadJsonBtnCity.bind(this, cityFiles)}>Update</button>
        </div>
        {
           loader || loaderLong? 
           <Loading />:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    languageId: state.Data.languageId,
    allCountries: state.Data.allCountries,
    loader: state.Data.loader,
    loaderLong: state.Data.loaderLong,
});
const mapDispatchToProps = {getAllCountries, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(AddStateCityUniPage)
