import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import { insertData } from '../../../actions/WorkerActions';
import { mixinAlert } from '../../../actions/Alerts';
export class ModalFooter extends Component {
  state={
    btnVisible: false
  }
  closeModal(){
    this.props.changeStateValue({
      name: this.props.stateName+".position",
      value: false
    })
  }

  saveData(data){
    if(data.image && data.name != '' && data.image != '' || !data.image && data.name != ''){
 
      // data.description = data.name;
      let formData = new FormData();
      for(const property in data ){
        if(data[property])
        formData.append(property, data[property])
      }
      let url = this.props.url;
      if(data.id != ''){
        formData.append('_method', "put");
        url = `${this.props.url}/${data.id}`
      }
      this.setState({btnVisible: true})
      this.props.insertData(url, formData)
      .then(resp=>{
        this.setState({btnVisible: false})
        if(resp == 'success'){
          mixinAlert('success', `Data ${data.id == ''? 'added': 'edited'} successfully`)
          this.closeModal();
          this.props.getAfterChange()
        }else{
          mixinAlert('error', "Something went wronge. Please try again later")
        }
       
      })
    }
  }
  render() {
    const { modalData} = this.props;
    const {btnVisible} = this.state
    return (
      <div className='flex justify-between'>
        <button disabled={btnVisible} onClick={this.closeModal.bind(this)} className='text-sm bg-red-700 text-white rounded-2xl pl-2 pr-2 pt-1 pb-1'>Close</button>
        <button disabled={btnVisible} onClick={this.saveData.bind(this, modalData)} className='text-sm bg-blue-700 text-white rounded-2xl pl-2 pr-2 pt-1 pb-1'>Save</button>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {changeStateValue, insertData};
export default connect(mapStateToProps, mapDispatchToProps)(ModalFooter)