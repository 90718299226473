import React, { Component } from 'react'
import { connect } from 'react-redux';
import TextComponent from '../components/TextComponent';
export class About extends Component {
  
  render() {
    const {desc, mapLink, youtubeLink, visa, living, mainTransateData} = this.props;
    return (
      <div>
        <TextComponent label="mainTransateData.about_uni" title={mainTransateData?.about_uni} desc={desc} />
        
        {
            youtubeLink?
            <div className="listingSection__wrap mb-10">
                <h2  className="text-2xl font-semibold" aria-label='mainTransateData.video_title'>{mainTransateData?.video_title}</h2>
                <div className='embed_parent' dangerouslySetInnerHTML={{ __html: youtubeLink}}></div>
            </div>:null
        }
        {
            mapLink?
            <div className="listingSection__wrap mb-10">
                <h2  className="text-2xl font-semibold" aria-label='mainTransateData.location_title'>{mainTransateData?.location_title}</h2>
                <div className='embed_parent' dangerouslySetInnerHTML={{ __html: mapLink}}></div>
            </div>:null
        }
        {/* {
          youtubeLink?
          <YouTube videoId={youtubeLink.split('=')[1]}   className={'rounded-2xl mb-10'}
          opts={{  height: '650',  width: '100%',  borderRadius: '20px',
          playerVars: {
           autoplay: 1,
          }, }} onReady={e=>e.target.pauseVideo()} />:null
        } */}
       
        {
          visa.map((data, i)=>{
            return(
              <TextComponent key={i} title={data.question} desc={data.answer} />
            )
          })
        }
        {
          living.map((data, i)=>{
            return(
              <TextComponent key={i} title={data.question} desc={data.answer} />
            )
          })
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  mainTransateData: state.Data.mainTransateData
})
export default connect(mapStateToProps)(About)