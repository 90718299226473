import React, { Component } from 'react'
import FaqComponent from './FaqComponent'

export class Faqs extends Component {
   
   
  render() {
    const {data}=this.props
    return (
      <div>
       
        {
            data.map((faq, i)=>{
                if(faq.country_fags.type == 'faq'){
                    return(
                        <FaqComponent key={i} title={faq.question} desc={faq.answer}  />
                    )
                }
            })
        }
      </div>
    )
  }
}

export default Faqs