import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class SectionHeader extends Component {
  render() {
    const {arialLabelTitle, arialLabelDesc,  title = '', description = '', btn = false, btnTitle} = this.props;
    
    return (
        <div className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50`} >
          <div  className={ "text-center w-full max-w-2xl mx-auto" }  >
            <h2 aria-label={arialLabelTitle} className={`text-3xl md:text-4xl font-semibold`} >
              {title}
            </h2>
            {/* <span aria-label={arialLabelDesc} className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
              {description}
              
            </span> */}
            {
                btn?
                <Link to={btn} className='ttnc-ButtonSecondary font-medium border 
                                    bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 
                                    dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800
                                    !leading-none nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-neutral-700 dark:text-neutral-200 mt-5 px-4 py-3 sm:px-6 text-sm sm:text-base font-medium
                                    '>
                    <span>{btnTitle}</span>
                    <i className="ml-3 las la-arrow-right text-xl"></i>
                </Link>:null
              }
          </div>
        </div>
    )
  }
}

export default SectionHeader