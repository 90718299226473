import React, { Component } from 'react'
import { connect } from 'react-redux'
import StarIcon from './StarIcon'
import { changeStateValue } from '../../../../../redux/MainReducer'
export class Star extends Component {
    setRaiting = (index) => {
       
        this.props.changeStateValue({
            name: 'commentData.raiting',
            value: index
        })
    }
  render() {
   const {commentData} = this.props;
  
    return (
        <div className={`nc-FiveStartIconForRate flex items-center text-neutral-300 space-x-0.5`} data-nc-id="FiveStartIconForRate">
           {
             [1, 2, 3 ,4, 5].map((data, i)=>{
                return(
                    <StarIcon 
                    raiting={data} 
                    setRaiting={this.setRaiting} 
                    key={i} 
                    className={commentData.raiting>= data?"text-red-500":''}
                    />
                )
             })
           }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    commentData: state.Data.commentData
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Star)