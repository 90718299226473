import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import CheckSvg from './CheckSvg';
import { insertNewData } from '../../../actions/WorkerActions';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

export class ComissionCard extends Component {
    state={
        visible:false
    }
    changeStatus=(id)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it'
          }).then((result) => {
            if (result.isConfirmed) {
                let formData = new FormData();
                formData.append("_method", "put")
                this.props.insertNewData(`/change-commission-status/${id}`, formData)
                .then(resp=>{
                    this.props.getAfterChange()
                    if(resp.msg == 'success'){
                        Swal.fire(
                            'Changed!',
                            'Commission status changed successfully.',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                        )
                    }
                })
            }
          })
       
    }
  render() {
    const {data, user} = this.props;
    const {visible} = this.state;
    console.log(user)
    return (
        <div className='border p-[20px] rounded-[10px]'>
            <h3 onClick={(e)=>this.setState({visible:!visible})} className='cursor-pointer font-semibold text-[18px] flex gap-[20px] w-full'>{data?.university_name} {data?.students?.find(x=>x.status==1)?<CheckSvg/>:<CheckSvg color="green"  />}</h3>
           {
            visible?
            <div >
                <div  className='bg-white p-3 w-full rounded-xl '>
                    <table class="table-auto w-full">
                        <thead>
                            <tr>
                                <th className='border border-slate-300 w-1/3'>Student</th>
                                <th className='border border-slate-300 w-1/3'>Educon</th>
                                <th className='border border-slate-300 w-1/3'>Program</th>
                                <th className='border border-slate-300 w-1/3'>Paid</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.students?.map((data, i)=>{
                                    return(
                                        <tr key={i}>
                                            <td  className='border border-slate-300 w-1/3 text-sm p-2'>
                                                <Link target='_blank' to={user?.role == "uni_rep"? `/student-info/${data?.student?.id}`:""}>
                                                    {i+1}. {data?.student?.full_name}
                                                </Link>
                                            
                                            </td>
                                            <td  className='border border-slate-300 w-1/3 text-sm p-2'>{data?.worker?.full_name}</td>
                                            <td  className='border border-slate-300 w-1/3 text-sm p-2'>{data?.program?.translate?.name}</td>
                                            <td onClick={this.changeStatus.bind(this, data?.id)}  className='border border-slate-300 w-1/3 text-sm p-2'>{data?.status ==1?<CheckSvg />:<CheckSvg color="green" />}</td>
                                        </tr>
                                    )
                                })
                            }
                            
                        </tbody>
                    </table>
                  
                </div>
            </div>:null
           }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user
});
const mapDispatchToProps = {insertNewData}

export default connect(mapStateToProps, mapDispatchToProps)(ComissionCard)
