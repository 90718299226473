import React, { Component } from 'react'
import { connect } from 'react-redux';

export class Percentage extends Component {
  render() {
    const {prcntg = 0, mainTransateData} = this.props;
    // console.log(prcntg)
    return (
        <div className="circular">
            <div className="circular-progress" style={{ background: `conic-gradient(${prcntg> 90? '#00b300':'#EE7100'} calc(${360*prcntg/100}deg), rgb(202, 202, 207) 0deg)` }}>
                <span>{prcntg<=100? prcntg: '100'}%</span>
            </div>
            <h3  className="text-base font-semibold " style={{ fontSize: '14px' }} aria-label="mainTransateData.admission_posibility">{mainTransateData?.admission_posibility}</h3>
        </div>
    )
  }
}
const mapStateToProps =(state) =>({
  mainTransateData: state.Data.mainTransateData
})
export default connect(mapStateToProps)(Percentage)