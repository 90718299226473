import React, { Component } from 'react'

export class File extends Component {
  render() {
    const {name, getInpValue, value, title="Image"} = this.props
    return (
      <div>
        <label className='text-sm'>{title}</label>
        <label htmlFor={name} className='border flex gap-2 items-center p-2 text-sm rounded-3xl cursor-pointer'>
            <svg  width={20} height={20}  viewBox="0 0 24 24"  fill="none"   xmlns="http://www.w3.org/2000/svg" >
                <path
                d="M4.468 17.532C5.623 19.715 7.928 20.5 12 20.5c5.625 0 7.88-1.498 8.385-6.115M4.468 17.532C3.78 16.229 3.5 14.429 3.5 12c0-6.5 2-8.5 8.5-8.5s8.5 2 8.5 8.5c0 .874-.036 1.668-.115 2.385M4.468 17.532l3.118-3.118a2 2 0 0 1 2.828 0l.172.172a2 2 0 0 0 2.828 0l2.172-2.172a2 2 0 0 1 2.828 0l1.971 1.971m-9.694-5.539a1.847 1.847 0 1 1-3.693-.001 1.847 1.847 0 0 1 3.693.001Z"
                stroke="#000"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
                />
            </svg>
            Upload Image
        </label>
        <input accept='image/*' name={name} onChange={(e)=>getInpValue(e.target.name, e.target.files[0])} id={name} type={'file'} style={{ display: 'none' }} />
        {
          !value ||value == ''?
          <p className='text-red-700 text-xs text-bold'>This field is required</p>:null
        }
      </div>
    )
  }
}

export default File