import React, { Component } from 'react'
import Input from './Input';

export class TranslateCard extends Component {
    state={

    }
  render() {
    const {data, translate, url, getAfterSave} = this.props;

    return (
      <li style={{ borderBottom: '2px solid gray' }} className='border-b mb-3 '>
      {
        data.map((item, i)=>{
            return(
                <div key={i} className={`flex items-center justify-between ${i != data.length-1? 'translater_main_card':''}`}> 
                    <div className="mb-2 " style={{ maxWidth: '600px' }}>
                        <span > {item[1]}</span>
                    </div>
                    <Input getAfterSave={getAfterSave} url={url} property={translate[i][0]} value={translate[i][1]} />
                </div>
             
            )
        })
      }
      </li>
    )
  }
}

export default TranslateCard