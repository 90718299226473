import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCurrency } from '../../../actions/WorkerActions';
import MainModal from '../../../components/modals/MainModal';
import { changeStateValue } from '../../../redux/MainReducer';
import Maincard from './components/Maincard';
export class CurrencyPage extends Component {
    componentDidMount(){
        this.props.getCurrency()
    }
    getAfterSubmit=()=>{
        this.props.getCurrency()
    }
    editDataBtn=(data)=>{
        this.props.changeStateValue({
            name: 'newCountryData',
            value: {
              id: data?.id? data?.id: '',
              position: true,
              name: data?.name?data?.name:'',
              active: 1,
              lang_id: 1,
            }
          })
          this.props.changeStateValue({
            name: 'otherExamPosition',
            value: false
          })
    }
  render() {
    const {currency, newCountryData} = this.props;

    return (
        <div>
            <button onClick={this.editDataBtn.bind(this, '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a Currency</button>
            <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 sm:gap-6 md:gap-8`}>
            {
                currency.map((item ,i)=>{
                    return(
                        <Maincard
                            key={i}
                            name={item.currency}
                            id={item.id}
                            editDataBtn={this.editDataBtn}
                        />
                    )
                })
            }
            </div>
            {newCountryData.position?<MainModal getAfterChange={this.getAfterSubmit} url={'/currency'} stateName="newCountryData" modalData={newCountryData} />:null}
        </div>
    )
  }
}

const mapStateToProps = (state) =>({
    currency: state.Data.currency,
    newCountryData: state.Data.newCountryData,
});
const mapDispatchToProps = {getCurrency, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(CurrencyPage)