import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import { addToWishList } from '../../../actions/MainAction';
export class LikeIcon extends Component {
    state={
        likedState: false,
        disableBtn: false,
    }
    changeLike(id, type,user,  e){
      let lsWish = [];
      if(localStorage.getItem('lsWishList')){
        lsWish = JSON.parse(localStorage.getItem('lsWishList'))
      }
      if(user == ''){
        const check = lsWish.findIndex(data=> data == id);
        if(check != -1){
          lsWish.splice(check, 1)
        }else{
          lsWish.push(id)
        }
        localStorage.setItem('lsWishList', JSON.stringify(lsWish))
        this.setState({
          likedState: !this.state.likedState
        })
        this.props.reloadAfterLike()

      }else{
        this.setState({
          disableBtn: true
        })
        this.props.addToWishList('wish-list-add',id, this.state.likedState? '0': '1')
        .then(resp=>{
          if(resp == 'success'){
            this.props.reloadAfterLike()
            this.setState({
              likedState: !this.state.likedState,
              disableBtn: false
            })
          }
        })
        
        
        
      }
       
    }
    componentDidMount(){
      this.setState({
        likedState: this.props.defaultCheck
      })
    }
  render() {
    const {likedState, disableBtn} = this.state;
    const {id, type, user, defaultCheck} = this.props;
   
    return (
        <button
        className={`nc-BtnLikeIcon w-8 h-8 flex items-center justify-center rounded-full cursor-pointer text-white bg-black bg-opacity-30
        ${
        likedState ? "nc-BtnLikeIcon--liked" : ""
        } 
        hover:bg-opacity-50 absolute right-3 top-3 z-[1]`}
        data-nc-id="BtnLikeIcon"
        title="Save"
        disabled={disableBtn}
        onClick={this.changeLike.bind(this, id, type, user)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill={likedState   ? "currentColor" : "none"}
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
          />
        </svg>
      </button>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user
});
const mapDispatchToProps = {changeStateValue, addToWishList}
export default connect(mapStateToProps, mapDispatchToProps)(LikeIcon)