import React, { Component } from 'react'
import CloseIcon from '../../../../components/page_component/CloseIcon'
import { connect } from 'react-redux';
import { changeStateValue, resetStateValue } from '../../../../redux/MainReducer';
import Searchable from 'react-searchable-dropdown'; 
import PhoneInput from "react-phone-input-2";
import avatar1 from '../../../../images/default.jpg'
import "react-phone-input-2/lib/bootstrap.css";
import { insertNewData } from '../../../../actions/WorkerActions';
import Swal from 'sweetalert2';
import { getEduConsultantStudents } from '../../../../actions/WorkerActions';
export class StudentNewModal extends Component {
    state={
        btnDisabled:false,
        base64:this.props.studentNewModal?.image_full_url
    }
    closeModal=()=>{
        this.props.resetStateValue({name:"studentNewModal"})
    }
    changeInpValue=(e)=>{
        this.props.changeStateValue({
            name: e.target.name,
            value: e.target.value
        })
    }
    setEduYear=(data)=>{
        this.props.changeStateValue({
            name: "studentNewModal.education_year",
            value: data
        })
    }

    saveBtn=(data)=>{
        // console.log(data)
        if(data?.first_name !="" && data?.last_name !=""){
            let formData = new FormData();
            for(const property in data){
                if(data[property]){
                    formData.append(property, data[property])
                }
            }
            let url = "/student-add"
            if(data?.id && data?.id !==""){
                formData.append("_method", "put");
                url = `/student-add/${data?.id}`
            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
                if(resp?.msg === "success"){
                    this.closeModal();
                    this.props.getEduConsultantStudents('', this.props.education_year)
                    Swal.fire(
                        'Success',
                        'Created successfully',
                        'success'
                      )
                }else{
                    Swal.fire(
                        'Error',
                        'Something went wrong',
                        'error'
                      )
                }
            })
        }
    }
    uploadImg(e){
        let reader = new FileReader();

        reader.onload = () =>{
            this.setState({
                base64: reader.result
            })
        }
       
        this.props.changeStateValue({
            name:'studentNewModal.image',
            value:  e.target.files[0]
        })
        reader.readAsDataURL(e.target.files[0])
    }
  render() {
    const {mainTransateData, studentNewModal} = this.props;
    const {btnDisabled, base64} = this.state
    let yearOption = [
        {
            label: "2020-2021",
            value: "2020-2021"
        },
        {
            label: "2021-2022",
            value: "2021-2022"
        },
        {
            label: "2022-2023",
            value: "2022-2023"
        },
        {
            label: "2023-2024",
            value: "2023-2024"
        },
        {
            label: "2024-2025",
            value: "2024-2025"
        },
        {
            label: "2025-2026",
            value: "2025-2026"
        },
    ];
    return (
      <div className='background_shadow' >
        <div className="login_popup_container" style={{ overflow:  "auto" }}>
          <CloseIcon closeModalBtn={()=>this.closeModal()} />
            <div>
                <label htmlFor='st_upload_img' className='student_pr_image'>
                    <img  src={base64 != ''?base64:avatar1} />
                </label>
                <input onChange={this.uploadImg.bind(this)} type={'file'} id="st_upload_img" accept='image/jpg, image/png, image/jpeg, image/webp' style={{ display: 'none' }} />
            </div>
          <div className='grid grid-cols-2 gap-3'>
            <label className="block">
                <span aria-label='mainTransateData.first_name' className="text-neutral-800 dark:text-neutral-200">
                    {mainTransateData?.first_name}
                </span>
                
                <input type={'text'}
                className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                placeholder={mainTransateData?.first_name}
                value={studentNewModal?.first_name}
                name="studentNewModal.first_name"
                onChange={this.changeInpValue.bind(this)}
                />
            </label>
            <label className="block">
                <span aria-label='mainTransateData.last_name' className="text-neutral-800 dark:text-neutral-200">
                {mainTransateData?.last_name}
                </span>
                
                <input type={'text'}
                className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                placeholder={mainTransateData?.last_name}
                value={studentNewModal?.last_name}
                name="studentNewModal.last_name"
                onChange={this.changeInpValue.bind(this)}
                />
            </label>
            </div>
            <div className='mt-[20px]'>
                <PhoneInput
                country={"az"}
                enableSearch={true}
                value={studentNewModal?.phone_number}
                placeholder='(00) 000 00 00'
                onChange={(phone) => this.props.changeStateValue({name:"studentNewModal.phone_number", value: phone})}
                inputClass="h-11 rounded-2xl w-full"
                inputStyle={{ width:'100%', borderRadius:'12px', borderColor:'rgba(0,0,0,0.1)', outline:'none' }}
                
                />
                {
                studentNewModal?.phone_number?.length != 12?
                <span className='text-red-700' style={{ fontSize:12 }}>Phone format should: +994 (00) 000 00 00</span>:null
            }
            </div>
            <div className="block mt-[20px]">
                <span className="text-neutral-800 dark:text-neutral-200">
                    Education Year
                </span>
                
                <div className='border rounded-2xl'>
                    <Searchable 
                        options={yearOption}
                        onSelect={this.setEduYear.bind(this)}
                        placeholder={yearOption?.find(x=>x.value === studentNewModal?.education_year)?yearOption?.find(x=>x.value === studentNewModal?.education_year)?.value: "Select a education year"}
                    />
                </div>
            </div>
            <div className='mt-5 flex justify-end'>
                <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,studentNewModal)} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                        text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200' aria-label='mainTransateData.save'>
                        {mainTransateData?.save}
                    </button>
                </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    mainTransateData: state.Data.mainTransateData,
    studentNewModal: state.Data.studentNewModal,
});
const mapDispatchToProps = {changeStateValue, resetStateValue, insertNewData, getEduConsultantStudents}
export default connect(mapStateToProps, mapDispatchToProps)(StudentNewModal)
