import React, { Component } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { checkConfirmCode,resendConfirmCodeAction, updateStudentDataAfterLogin } from '../actions/MainAction';
import { changeStateValue } from '../redux/MainReducer'
function withHooks(Component){
    return props =><Component {...props} navigate={useNavigate()} />
}
export class ConfirmEmail extends Component {
   state={
    confirmCode: '',
    countErr: false,
    codeError: false,
    btnVisible: false
   }
   componentDidMount(){
    window.scrollTo(0, 0);
    window.addEventListener('beforeunload', (e)=>{
        e.preventDefault();
    
        localStorage.setItem('eKey', this.props.confirmEmail.email)
        localStorage.setItem('start', this.props.confirmEmail.sentTime)
        localStorage.setItem('deadline', this.props.confirmEmail.deadline)
        localStorage.setItem('path', this.props.confirmEmail.path)
        return '';
      })
   }
   checkConfirmCode(code,email, e){
    if(code.length < 6){
        this.setState({
            countErr: true
        })
    }else{
        this.setState({
            btnVisible: true
        })
        this.props.checkConfirmCode({code, email}, this.props.confirmEmail.path)
        .then(resp=>{
            this.setState({
                btnVisible: false
            })
            if(resp == 'success'){
                localStorage.removeItem('eKey');
                localStorage.removeItem('start');
                localStorage.removeItem('deadline');
                localStorage.removeItem('path');
                this.props.changeStateValue({
                    name: 'confirmEmail',
                    value: {
                        email: '',
                        sentTime: '',
                        deadline: '',
                        path: ''
                    }
                })
               
                if(this.props.confirmEmail.path == '/simulator'){
                    let formData = new FormData();
                    const filter = JSON.parse(localStorage.getItem('simulator'));
                    if(localStorage.getItem('filterExamGrade')){
                        const examFilter = JSON.parse(localStorage.getItem('filterExamGrade'));
                        for(const property in examFilter){
                            formData.append(property, examFilter[property])
                        }
                    }
                    
    
                    for(const property in filter){
                        formData.append(property, filter[property])
                    }
                    this.props.updateStudentDataAfterLogin(formData)
                }
            
                this.props.navigate(this.props.confirmEmail.path == '/signup'? '/':this.props.confirmEmail.path)
               
            }else{
                this.setState({
                    codeError: true
                })
            }
           
            
        })
        this.setState({
            countErr: false
        })
    }
   }
   alertModal(message, icon){
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: icon,
        title: message
      })
   }
   resendEmailBtn(email, e){
    this.props.resendConfirmCodeAction({email})
    .then(resp=>{
        if(resp == 'success'){
           this.alertModal('Code resent successfully.', 'success' );
        }else{
            this.alertModal('Something went wrong, please try again later', 'error' );
        }
    })
   }
  render() {
   const {confirmCode, countErr, codeError, btnVisible} = this.state;
   const {confirmEmail, } = this.props;
    return (
      <div className='container'>
        <div className='flex gap-5 mt-5'>
            <div className='confirm_container items-start'>
                <h3>Confirm your email</h3>
                <p>We've sent an email to <b>{atob(confirmEmail.email)}</b>  containing a 6 digit code - please enter this below</p>
                <span>*required information</span>
                <strong>Code*</strong>
                <input value={confirmCode} onChange={(e)=>e.target.value.length<=6?this.setState({confirmCode:e.target.value}):''} type={'number'} />
                {
                    countErr?
                    <span style={{ fontStyle: 'normal', color: '#d52a41', marginTop: '5px', fontWeight: '400' }}>Please enter a 6 digit code before continuing</span>
                    :null
                }
                {
                    codeError?
                    <span style={{ fontStyle: 'normal', color: '#d52a41', marginTop: '5px', fontWeight: '400' }}>The code you have entered is not valid. Try again or</span>
                    :null
                }
                <button disabled={btnVisible} onClick={this.checkConfirmCode.bind(this, confirmCode,atob(confirmEmail.email))} style={{ color: 'white' }}  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
              nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
              text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>Confirm and sign in</button>
            </div>
            <div className='confirm_container items-start'>
                <h2>Not received an email?</h2>
                <p>Please give your email a few minutes to arrive, and check your spam folder. If you still haven't received your email, please press the 'resend email' button below.</p>
                <button disabled={btnVisible} onClick={this.resendEmailBtn.bind(this, atob(confirmEmail.email))} style={{ color: 'white' }}  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
              nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
              text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>Resend email</button>
                <p>Used the wrong email address? <a href="#"><u>Change email address</u></a></p>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    confirmEmail: state.Data.confirmEmail,
    user: state.Data.user,
});
const mapDispatchToProps = {checkConfirmCode, changeStateValue, resendConfirmCodeAction, updateStudentDataAfterLogin}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ConfirmEmail))