import React, { Component } from 'react'
import Swal from 'sweetalert2';
import { insertNewData } from '../../../../../../actions/WorkerActions';
import { connect } from 'react-redux';

export class ComissionBtn extends Component {

    changeComissionStatus=(program_id, student_id, id="")=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it'
          }).then((result) => {
            if (result.isConfirmed) {
                this.setState({btnVisible:true})
                let formData = new FormData();
                formData.append("program_id", program_id);
                formData.append("student_id", student_id);
                formData.append("id", id);
                this.props.insertNewData("/change-commission-status", formData)
                .then(resp=>{
                    // this.setState({btnVisible:false})
                    this.props.getAfterChange()
                    if(resp.msg == 'success'){
                        Swal.fire(
                            'Changed!',
                            'Commission status changed successfully.',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                        )
                    }
                })
             
            }
          })
    }
  render() {
    const {program_id, student_id, comission, notPermision=false} = this.props;
    // console.log(program_id, student_id, comission)
    // console.log(comission)
    return (
      <label htmlFor='comBtnId'>
        {/* <input onChange={!notPermision? this.changeComissionStatus.bind(this, program_id, student_id, comission?.id):()=>{}} checked={comission?true:null}  type='checkbox' className='cursor-pointer' /> */}
        <button  onClick={!notPermision? this.changeComissionStatus.bind(this, program_id, student_id, comission?.id):()=>{}} className='w-[20px] h-[20px] min-w-[20px] border rounded-[4px] border-blue-600 inline-flex justify-center items-center'>
         {comission? <span className='inline-block w-[10px] h-[10px] bg-blue-600'></span>:null }
        </button>
        {/* <span>Will receive commission?</span> */}
      </label>
    )
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(ComissionBtn)
