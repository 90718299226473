import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class Card extends Component {
  render() {
    const {image, name, country, city, id, getAfterRedirect} = this.props
    return (
        <li className={`glide__slide `}>
                
            <Link  to={`/program/${id}`} onClick={()=>getAfterRedirect(id)} className={`nc-CardCategory4 flex flex-col`}  data-nc-id="CardCategory4"   >
                <div  className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-4 h-0 rounded-2xl overflow-hidden group`}    >
                
                <div  className={`nc-NcImage`} data-nc-id="NcImage"  >
                    
                        <img src={image} className={'object-cover w-full h-full rounded-2xl'}  />
                    
                    </div>
                {/* <span style={{ height: '200px' }} className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span> */}
                </div>
                <div className="mt-4 px-2 truncate text-center">
                <h2 className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}   >
                   {name}
                </h2>
                <span  className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}  >
                    {country}/{city}
                </span>
                </div>
            </Link>
        </li>
    )
  }
}

export default Card