import React, { Component } from 'react'
import { connect } from 'react-redux';
import InnerDropDown from './InnerDropDown';
import { getAllCountries, getEducationDegree, updateStudentData, getSimulatorPrograms } from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
import FooterBtns from './FooterBtns';

export class MultiDropDown extends Component {
  componentDidMount(){
    this.props.getAllCountries('','', this.props.languageId );
    this.props.getEducationDegree(this.props.languageId);


  }
  getMinMaxValue(value, type){
    if(type == 'min'){
      if(value !== ''){
        return +value.split('-')[0]
      }else{
        return 0
      }
    }else if(type == 'max'){
      if(value !== ''){
        return +value.split('-')[1]
      }else{
        return 100
      }
    }
  }

  resetData = ()=>{
    const simulator = JSON.parse(localStorage.getItem('simulator'));

    const obj = {
      filterByCountryLevel: '',
      filterByGPA: '',
      filterByGradingScheme: '',
      filterByIB: '',
      filterByLevel: '',
      filterByOSSD: '',
      gradingSchemeId: '',
      filterByAP: '',
      filterByALevel: '',
      filterStudiedCountry: '',
    }
    if(localStorage.getItem('token')){
     
      let formData = new FormData();
      for(const property in obj){
        formData.append(property, obj[property])
      }
      formData.append('_method', 'put');
      formData.append('type', 'personalinfo');
      formData.append('student_id', simulator.student_id)


      this.props.updateStudentData(formData)
  
    }

    for(const property in obj){
      simulator[property] = ''
    }
    localStorage.setItem('simulator', JSON.stringify(simulator));
    this.props.changeStateValue({
      name: 'filter',
      value: simulator
    })
    this.props.changeStateValue({
      name: 'gradingSchemeType',
      value: ''
    })
    this.props.changeStateValue({
      name: 'countryEducationDegree',
      value: []
    })
    this.props.getSimulatorPrograms(simulator)
  }
  updateData = ()=>{
   
      const simulator = JSON.parse(localStorage.getItem('simulator'));

      const obj = {
        filterByCountryLevel: simulator['filterByCountryLevel'],
        filterByGPA: simulator['filterByGPA'],
        filterByGradingScheme: simulator['filterByGradingScheme'],
        filterByIB: simulator['filterByIB'],
        filterByLevel: simulator['filterByLevel'],
        filterByOSSD: simulator['filterByOSSD'],
        gradingSchemeId: simulator['gradingSchemeId'],
        filterByAP: simulator['filterByAP'],
        filterByALevel: simulator['filterByALevel'],
        filterStudiedCountry: simulator['filterStudiedCountry'],
      }
      if(localStorage.getItem('token')){
      let formData = new FormData();
      for(const property in obj){
        formData.append(property, obj[property])
      }
      formData.append('_method', 'put');
      formData.append('type', 'personalinfo');
      formData.append('student_id', simulator.student_id)
      
      this.props.updateStudentData(formData)
    }
      this.props.getSimulatorPrograms(simulator)
    


  }
  render() {
    const {allCountries, 
          educationDegree, 
          filter, 
          countryEducationDegree, 
          gradingScheme,
          gradingSchemeType,
          mainTransateData,
        } = this.props;
    const countriesOption = [];
    const degreeOption = [];
    const countryDegreeOption = [];
    const gradingSchemeOption = [];

      for(const obj of allCountries){
        const params = {
          name: obj.name,
          id: obj.countries_id,
        }
        if(params.id == 1  ){
            countriesOption.push(params)
        }
    }
   
    for(const obj of allCountries){
        const params = {
          name: obj.name,
          id: obj.countries_id,
        }
        if( params.id == 5 ){
            countriesOption.push(params)
        }
    }
    for(const obj of allCountries){
        const params = {
          name: obj.name,
          id: obj.countries_id,
        }
        if( params.id == 3 ){
            countriesOption.push(params)
        }
    }
    for(const obj of allCountries){
      const params = {
        name: obj.name,
        id: obj.countries_id,
      }
      if( params.id == 33 ){
          countriesOption.push(params)
      }
    }
    for(const obj of allCountries){
      const params = {
        name: obj.name,
        id: obj.countries_id,
      }
      if( params.id == 34 ){
          countriesOption.push(params)
      }
    }
    

    for(const obj of educationDegree){
      const params = {
        id: obj.education_degree_id,
        name: obj.education_type
      }
      if(obj.education_degree_id != 6 && obj.education_degree_id != 7 && obj.education_degree_id != 8){
        degreeOption.push(params)
      }
      
    }
    for(const obj of countryEducationDegree){
      const params = {
        id: obj.country_education_degree_id,
        name: obj.name
      }
    
      countryDegreeOption.push(params)
      
      
    }
  
    for(const obj of gradingScheme){
      const params = {
        id: `${obj.min_value}-${obj.max_value}`,
        name: `${obj.min_value}-${obj.max_value}`,
        grade_id: obj.id
      }

      gradingSchemeOption.push(params)
    }

    if(filter.filterByLevel == 1 ){
      const degre = [
        {
          id: 'a_level',
          name: 'A Level'
        },
        {
          id: 'ib_diploma',
          name: 'IB Diploma'
        },
        {
          id: 'ossd',
          name: 'OSSD'
        },
        {
          id: 'ap',
          name: 'Advanced Placement'
        },
      ];
      countryDegreeOption.push(...degre)
    }

    const aLevelOption =[
      {
        id: 'A*A*A*',
        name: 'A*A*A*'
      },
      {
        id: 'A*AA',
        name: 'A*AA'
      },
      {
        id: 'AAA',
        name: 'AAA'
      },
      {
        id: 'AAB',
        name: 'AAB'
      },
      {
        id: 'AAC',
        name: 'AAC'
      },
      {
        id: 'ABB',
        name: 'ABB'
      },
      {
        id: 'ABC',
        name: 'ABC'
      },
      {
        id: 'BBB',
        name: 'BBB'
      },
      {
        id: 'BBC',
        name: 'BBC'
      },
      {
        id: 'CCC',
        name: 'CCC'
      },
      {
        id: 'DDD',
        name: 'DDD'
      },
      {
        id: 'EEE',
        name: 'EEE'
      },
    ];

    const apOptions=[
      {
        id: '5555',
        name: '5555'
      },
      {
        id: '5554',
        name: '5554'
      },
      {
        id: '5544',
        name: '5544'
      },
      {
        id: '5444',
        name: '5444'
      },
      {
        id: '4444',
        name: '4444'
      },
      {
        id: '4443',
        name: '4443'
      },
      {
        id: '4433',
        name: '4433'
      },
      {
        id: '4333',
        name: '4333'
      },
      {
        id: '3333',
        name: '3333'
      },
      {
        id: '555',
        name: '555'
      },
      {
        id: '554',
        name: '554'
      },
      {
        id: '544',
        name: '544'
      },
      {
        id: '444',
        name: '444'
      },
      {
        id: '443',
        name: '443'
      },
      {
        id: '433',
        name: '433'
      },
      {
        id: '333',
        name: '333'
      },
    ]

    return (
      <div>
        <ul>
            <InnerDropDown updateData={this.updateData} type="radio" array={countriesOption} title={mainTransateData?.studiet_country} ariaLabel="mainTransateData.studiet_country" property="filterStudiedCountry" />
            {
              filter.filterStudiedCountry !== ''?
              <InnerDropDown updateData={this.updateData} type="radio" array={degreeOption} title={mainTransateData?.education_degree} ariaLabel="mainTransateData.education_degree" property="filterByLevel" />:null
            }
            {
              countryDegreeOption.length !== 0 ?
              <InnerDropDown updateData={this.updateData} type="radio" array={countryDegreeOption} title={mainTransateData?.country_edu_deg} ariaLabel="mainTransateData.country_edu_deg" property="filterByCountryLevel" />:null
            }
            {
               gradingSchemeOption.length > 1 || 
               (gradingSchemeOption.length > 1 && filter.filterByCountryLevel !== '' &&
               filter.filterByCountryLevel !== 'a_level' &&
               filter.filterByCountryLevel !== 'ib_diploma' &&
               filter.filterByCountryLevel !== 'ap' &&
               filter.filterByCountryLevel !== 'ossd' )?
              <InnerDropDown updateData={this.updateData} type="radio" array={gradingSchemeOption} title={mainTransateData?.grading_schema} ariaLabel="mainTransateData.grading_schema" property="filterByGradingScheme" />:null
            }
            {
              ( gradingSchemeOption.length >1)||
              filter.filterByGradingScheme !== '' && 
              filter.filterByCountryLevel !== '' &&
              filter.filterByCountryLevel !== 'a_level' &&
              filter.filterByCountryLevel !== 'ib_diploma' &&
              filter.filterByCountryLevel !== 'ap' &&
              filter.filterByCountryLevel !== 'ossd' ||
              filter.filterByLevel != '1' &&
              filter.filterByLevel != ''
              // ||
              // filter.filterByGradingScheme !== ''
              ?
              <InnerDropDown 
                updateData={this.updateData}
                type="radio" 
                ariaLabel="mainTransateData.gpa"
                array={[]} 
                title={mainTransateData?.gpa}
                value={filter.filterByGPA} 
                min_value={this.getMinMaxValue(filter.filterByGradingScheme, 'min')}
                max_value={this.getMinMaxValue(filter.filterByGradingScheme, 'max')}
                property="filterByGPA" 
                child="input" />:null
            }
            {
               filter.filterByCountryLevel !== '' &&
               filter.filterByCountryLevel == 'a_level' ?
              <InnerDropDown updateData={this.updateData} type="radio" array={aLevelOption} title={mainTransateData?.select_a_level} ariaLabel="mainTransateData.select_a_level" property="filterByALevel" />:null
            }
            {
               filter.filterByCountryLevel !== '' &&
               filter.filterByCountryLevel == 'ib_diploma' ?
              <InnerDropDown updateData={this.updateData} child="input" type="radio" value={filter.filterByIB} min_value={1} max_value={45} array={[]} title={mainTransateData?.select_ib} ariaLabel="mainTransateData.select_ib" property="filterByIB" />:null
            }
            {
               filter.filterByCountryLevel !== '' &&
               filter.filterByCountryLevel == 'ossd' ?
              <InnerDropDown updateData={this.updateData} child="input" type="radio" value={filter.filterByOSSD} min_value={1} max_value={100} array={[]} title={mainTransateData?.select_ossd} ariaLabel="mainTransateData.select_ossd" property="filterByOSSD" />:null
            }
            {
               filter.filterByCountryLevel !== '' &&
               filter.filterByCountryLevel == 'ap' ?
              <InnerDropDown updateData={this.updateData} type="radio" array={apOptions} title={mainTransateData?.select_ap} ariaLabel="mainTransateData.select_ap" property="filterByAP" />:null
            }
            
            {/* <InnerDropDown />
            <InnerDropDown /> */}
           <FooterBtns resetData={this.resetData} updateData={this.updateData}/>
        </ul>
      </div>
    )
  }
}
const mapStateToProps = (state)=> ({
  languageId: state.Data.languageId,
  allCountries: state.Data.allCountries,
  educationDegree: state.Data.educationDegree,
  filter: state.Data.filter,
  countryEducationDegree: state.Data.countryEducationDegree,
  gradingScheme: state.Data.gradingScheme,
  gradingSchemeType: state.Data.gradingSchemeType,
  mainTransateData: state.Data.mainTransateData,
});
const mapDispatchToProps = { getAllCountries, getEducationDegree, updateStudentData,changeStateValue, getSimulatorPrograms }
export default connect(mapStateToProps, mapDispatchToProps)(MultiDropDown)