import React, { Component } from 'react'
import { connect } from 'react-redux';
import { registerByWorkerAction,  } from '../../../../actions/MainAction';
import { getTranslatorUsers, addUpdateData, getLanguages } from '../../../../actions/WorkerActions';
import Searchable from 'react-searchable-dropdown'; 

export class AddTranslatorModal extends Component {
    state={
        id: this.props.data.id,
        first_name: this.props.data.first_name,
        last_name: this.props.data.last_name,
        email: this.props.data.email,
        password: this.props.data.password,
        role_id: '7',
        lang_id: this.props?.data?.lang?.lang_id
    }
    changeInpValue(e){
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    registerManual(data, student_id, e){
        e.preventDefault()
        if(data.first_name !== '' && data.last_name !== '' && data.email !== '' && data.password !== '' &&  data.password !== ''){
            if(this.state.id == ''){
                this.props.registerByWorkerAction(data)
                .then(resp=>{
                    this.props.getTranslatorUsers()
                    this.props.closeModal()
                })
            }else{
                let formData = new FormData();
                formData.append('_method', 'put')
                formData.append('first_name', data.first_name)
                formData.append('last_name', data.last_name)
                formData.append('email', data.email)
                formData.append('password', data.password)
                formData.append('lang_id', data.lang_id)

                let url = `users/${data.id}`;
                this.props.addUpdateData(url, formData)
                .then(resp=>{
                    this.props.getTranslatorUsers()
                    this.props.closeModal()
                })
            }
           

        }
    }
    closeModal(e){
        if(e.target.className === 'background_shadow'){
            this.props.closeModal()
        }
    }

    componentDidMount(){
        this.props.getLanguages()
    }
    selectDropValue =(data)=>{
       
        this.setState({
            lang_id: data
        })
    }

    getPlaceholder(data, id){
       
        if(id == ''){
            return 'Select a Language'
        }else{
            const findData = data.find(lang=> lang.value == id);
            console.log(findData)
            if(findData){
                return findData.label
            }else{
            return 'Select a Language'
            }
        }
    }
  render() {
    const {student_id = '', allLanguages} = this.props;
    const {first_name, last_name, email, password, lang_id} = this.state;
    let langOptions = [];
    for(const data of allLanguages){
        langOptions.push({
            label: data.name,
            value: data.id
        })
    }

    return (
        <div className='background_shadow' onClick={this.closeModal.bind(this)}>
            <div className="login_popup_container">
                <div>
                    <h2 className="my-2 flex items-center  mb-3 text-3xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        Add a Translator User
                    </h2>
                    <form className="grid grid-cols-1 gap-6" action="#" method="post">
                    <div className='grid grid-cols-2 gap-3'>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                                First Name
                            </span>
                            
                            <input type={'text'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="First name"
                            value={first_name}
                            name="first_name"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                            Last Name
                            </span>
                            
                            <input type={'text'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="Last Name"
                            value={last_name}
                            name="last_name"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        </div>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                            Email address
                            </span>
                            
                            <input type={'email'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="example@example.com"
                            value={email}
                            name="email"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                            Select a Language
                            </span>
                            
                            <div className='border rounded-2xl'>
                                <Searchable 
                                    options={langOptions}
                                    onSelect={this.selectDropValue.bind( this.label)}
                                    placeholder={this.getPlaceholder(langOptions, lang_id)}
                                />
                            </div>
                        </label>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                                Password
                            </span>
                            
                            <input type={'password'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="*********"
                            value={password}
                            name="password"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                       
                        
                        <button onClick={this.registerManual.bind(this, this.state, student_id)} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                            text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>
                            Sign Up
                        </button>
                    </form>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    allLanguages: state.Data.allLanguages
});
const mapDispatchToProps = {registerByWorkerAction, getTranslatorUsers, addUpdateData, getLanguages}
export default connect(mapStateToProps, mapDispatchToProps)(AddTranslatorModal)