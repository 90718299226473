import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoadMoreBtn from '../../../page_component/LoadMoreBtn'
import ProgramCard from '../../../page_component/programs/ProgramCard'
import LoginComponent from '../../../page_component/simulator_components/login_register/LoginComponent'
export class Scholarship extends Component {
    state={
        filterDiscipline: '',
        filterDegree: '',
        page: 1
      }
      setFilterDiscipline = (data) =>{
        this.setState({
          filterDiscipline: data
        })
      }
      setFilterDegree = (data) =>{
        this.setState({
          filterDegree: data
        })
      }
    
      loadMoreFunction = (page) => {
        this.props.loadMore(page)
        this.setState({page:page})
      }
  render() {
    const {scholarships, simulatorLogin, getAfterApply, } = this.props
    return (
        <div className="nc-SectionGridFeaturePlaces relative" >
        <div className='mb-5 flex gap-5'>
        {/* <FilterProgramByDiscipline page={page} filterDegree={filterDegree} setFilterDiscipline={this.setFilterDiscipline} />
        <FilterprogramByDegree page={page} filterDiscipline={filterDiscipline} setFilterDegree={this.setFilterDegree}/> */}
       </div>
        {
          scholarships.length != 0?
          <>
            
          {/* <div className="flex flex-col mb-8 relative" >
          <SectionHeader btnTitle={unirepRoleTranslate?.add_program} title={mainTransateData?.program_title} btn={user !== '' && user.role == 'uni_rep'?`/create-program/${id}`: false} description={mainTransateData?.program_desc} />
          </div> */}
          <div  className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
          {
              scholarships &&
              scholarships.map((program, i)=>{
              // if(i< 8){
                  return(
                  <ProgramCard getAfterApply={getAfterApply} key={i} program={program} btns={false} />
                  )
              // }
              })
          }
          
          </div>
          {
            scholarships.length>=20?
            <LoadMoreBtn  position={scholarships.length} loadMoreFunction={this.loadMoreFunction}/>:null
          }
          
          {
            simulatorLogin?
            <LoginComponent />:null
          }
          </>:null
        }
       
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    simulatorLogin: state.Data.simulatorLogin
})
export default connect(mapStateToProps)(Scholarship)