import React, { Component } from 'react'
import { connect } from 'react-redux';
import DropDown from './components/DropDown';
import Input from './components/Input';
import InputPercentage from './components/InputPercentage'
import InputSelect from './components/InputSelect'
import MultiSelect from './components/MultiSelect';
import SelectBox from './components/SelectBox';
import { changeStateValue } from '../../redux/MainReducer';
import { getEducationDegree,getProgramData, getEducationLanguage, getCurrency,  getExtraCurricularActivity, getInternationalMedals, insertNewData } from '../../actions/WorkerActions';
import StudyDuration from './components/StudyDuration';
import ExamsInput from './components/ExamsInput';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HighSchoolExams from './components/HighSchoolExams';
import {getUnirepRoleTranslate, mainDashboardTranslate} from '../../actions/TranslateAction';
import HighSchoolSchExams from './components/HighSchoolSchExams';

function withParams(Component){
    return props=><Component {...props} params={useParams()} navigate={useNavigate()} />
}
export class EditProgram extends Component {
  state={
    part1Visible: false,
    part2Visible: false,
    part3Visible: false,
    part4Visible: false,
    exams: [],
    activityData: [],
    olympiadData: [],
    sch_olympiadData: [],
    sch_activityData: [],
    sch_exams: [],
    highSchoolExamVisible: false,
    btnVisible: false,
    addDate: [],
    setAsRequired: false,
    editProgram:'',
    cardVisible:false
    
  }

  getDates(startDate, deadline){
    if(startDate.length >1){
      let arr = [];
      for(let i = 0; i < startDate.length; i ++){
        if(i>0){
          if(startDate[i] != ''){
            arr.push({
              id: '',
              start_date: startDate[i],
              deadline: deadline[i]
            });
          }
          
        }
      }

      this.setState({addDate:arr})
      return arr;
    }else{
      return []
    }
  }

  componentDidMount(){

    // this.props.getUnirepRoleTranslate(this.props.languageId);
    // this.props.mainDashboardTranslate(this.props.languageId);
    // this.props.getEducationDegree(this.props.languageId);
    // this.props.getEducationLanguage(this.props.languageId);
    // this.props.getExtraCurricularActivity(this.props.languageId);
    // this.props.getInternationalMedals(this.props.languageId);
    // this.props.getCurrency();
    // this.props.getProgramData(this.props.params.id)

    const  params = {...this.props.programData};
    if(this.props.gpa && this.props.gpa != ''){
      params.program = {  ...params.program,  gpa : this.props.gpa  }
    }
    if(this.props.appFee && this.props.appFee != ''){
      params.program = {  ...params.program,  application_fee: this.props.appFee  }
    }
    if(this.props.gpaPercentage && this.props.gpaPercentage != '0'){
      params.program = {  ...params.program,  gpa_percentage:this.props.gpaPercentage  }
    }
    if(this.props.reqEduDegree && this.props.reqEduDegree != ''){
      params.program = {  ...params.program,  required_education_level : this.props.reqEduDegree }
    }
    if(this.props.currencyData && this.props.currencyData != ''){
      params.program = {  ...params.program,  fee_currency_id : this.props.currencyData  }
    }
    if(this.props.feeAmountAll && this.props.feeAmountAll != ''){
      params.program = {  ...params.program,  fee_amount : this.props.feeAmountAll }
    }
    if(this.props.feeType && this.props.feeType != '0'){
      params.program = {  ...params.program,  fee_type : this.props.feeType }
    }
    if(this.props.programFormat && this.props.programFormat != '')params.program_format = this.props.programFormat;
    if(this.props.schoolarship_option && this.props.schoolarship_option != ''){
      params.program = {  ...params.program,  schoolarship_option: this.props.schoolarship_option  }
    }
    if(this.props.schoolarship_type && this.props.schoolarship_type != ''){
      params.program = {  ...params.program,   schoolarship_type : this.props.schoolarship_type   }
    }
    if(this.props.studyDuration && this.props.studyDuration != ''){
      params.program = {  ...params.program,   study_duration :this.props.studyDuration  }
    }
    if(this.props.a_level && this.props.a_level != ''){
      params.program = {   ...params.program,   a_level : this.props.a_level  }
    }
    if(this.props.a_level_percentage && this.props.a_level_percentage != ''){
      params.program = {   ...params.program,   a_level_percentage :this.props.a_level_percentage  }
    }
    if(this.props.advanced_placement && this.props.advanced_placement != ''){
      params.program = {  ...params.program,  advanced_placement : this.props.advanced_placement  }
    }
    if(this.props.ap_percentage && this.props.ap_percentage != ''){
      params.program = {  ...params.program,  ap_percentage :this.props.ap_percentage }
    }
    if(this.props.ib_diploma && this.props.ib_diploma != ''){
      params.program = {  ...params.program,  ib_diploma : this.props.ib_diploma  }
    }
    if(this.props.ib_percentage && this.props.ib_percentage != ''){
      params.program = {  ...params.program,   ib_percentage : this.props.ib_percentage  }
    }
    if(this.props.ossd && this.props.ossd != ''){
      params.program = {  ...params.program,  ossd : this.props.ossd  }
    }
    if(this.props.ossd_percentage && this.props.ossd_percentage != ''){
      params.program = {  ...params.program,  ossd_percentage : this.props.ossd_percentage  }
    }
    if(this.props.examCheck && this.props.examCheck != ''){
      params.program = {  ...params.program,  exam_check : this.props.examCheck  }
    }

    if(!this.props.startDates.includes('')){
      params.program = {  ...params.program,  start_date : this.props.startDates[0]  }
    }
    if(!this.props.deadlines.includes('')){
      params.program = {  ...params.program,  deadline : this.props.deadlines[0]  }
    }
    this.setState({editProgram:params});
    const dates =  this.getDates(this.props.startDates,  this.props.deadlines);
    this.props.setSelectedDataAll(params, dates)
  }
  changeVisible(name, visible, e){
    this.setState({
      [name]: !visible
    })
  }

  saveChanges(data,dates, type, e){
    
    // if(
    //     data.name !== '' &&
    //     data.program_format !== '' &&
    //     data?.program?.gpa !== '' &&
    //     data?.program?.required_education_level !== '' &&
    //     data?.program?.start_date !== '' &&
    //     data?.program?.deadline !== '' &&
    //     // data?.program?.application_fee !== '' &&
    //     data?.program?.education_degree_id !== '' &&
    //     data?.program?.education_language_id !== '' &&
    //     // data?.program?.fee_amount !== '' &&
    //     data?.program?.fee_currency_id !== ''
    // ){
    //   this.setState({
    //     btnVisible: true
    //   })
    //     const params = {...data};

        




    //     if(this.state.activityData.length !== 0){
    //     params.activity_json = JSON.stringify(this.state.activityData);
    //     }else{
    //     params.activity_json = undefined
    //     }
        

        
    //     if(this.state.olympiadData.length !== 0){
    //     params.olympiad_json = JSON.stringify(this.state.olympiadData);
    //     }else{
    //     params.olympiad_json = undefined
    //     }
        
    //     // params.
    //     params.user_id = this.props.user.id
    //     if(this.state.exams){
    //     params.json_exams = JSON.stringify(this.state.exams);

    //     }
       
    //     // params.institution_id = this.props.params.id
                
    //     params.program_json = JSON.stringify(params.program)

    //     if(!this.state.setAsRequired){
    //       if(this.state.sch_activityData.length !== 0){
    //         params.activity_sch_json = JSON.stringify(this.state.sch_activityData);
    //       }else{
    //       params.activity_sch_json = undefined
    //       }
    //       if(this.state.sch_olympiadData.length !== 0){
    //         params.olympiad_sch_json = JSON.stringify(this.state.sch_olympiadData);
    //       }else{
    //       params.olympiad_sch_json = undefined
    //       }
    //       if(this.state.exams){
    //         params.json_sch_exams = JSON.stringify(this.state.sch_exams);
    //       }
    //     }else{
          
    //       let activity = [];
    //       if(params.activity.length !== 0){
    //         for(const data of params.activity){
    //           activity.push({
    //             data_id: data.activity_id,
    //             percentage: data.percentage
    //           })
    //         }
    //         params.activity_sch_json = JSON.stringify(activity)
    //       }else{
    //         params.activity_sch_json =  params.activity_json
    //       }

    //       if(params.olympiad.length !== 0){
    //         let olympiad = []
    //         for(const data of params.olympiad){
    //           olympiad.push({
    //             data_id: data.olympiad_id,
    //             percentage: data.percentage
    //           })
    //         }
    //         params.olympiad_sch_json = JSON.stringify(olympiad)
    //       }else{
    //         params.olympiad_sch_json = params.olympiad_json
    //       }

    //       let exams = [];
    //       if(params?.program?.specialty_exams.length != 0){
    //         for(const data of params?.program?.specialty_exams){
    //           exams.push({
    //             exam_type: data.exam.exam_type,
    //             exam_id: data.exam_id,
    //             sub_sections: data.sub_sections,
    //             all_sub_sections: data.sub_sections,
    //             over_all: data.over_all,
    //             percentage: data.percentage,
    //             visible: false
    //           })
    //         }
    //         params.json_sch_exams = JSON.stringify(exams)
    //       }else{
    //         params.json_sch_exams  = params.json_exams
    //       }
          
          
    //     }

    //     let formData = new FormData();
    //     for(const property in params){
    //         formData.append(property, params[property])
    //     }
    //     if(dates.length !== 0){
    //       formData.append('dates', JSON.stringify(dates))
    //     }else{
    //       formData.append('dates', '')
    //     }
    //     formData.append('_method', 'put')
    //     formData.append('setAsRequired', this.state.setAsRequired)
       
    //     this.props.insertNewData(`/programs/${params.id}`, formData)
    //     .then(resp=>{
    //       this.setState({
    //         btnVisible: false
    //       })
    //         if(resp.msg === 'success'){
    //           if(type === 'dublicate'){
    //             this.props.navigate(`/dublicate-program/${this.props.params.id}`)
    //           }else{
    //             this.props.navigate(`/institution/${params?.program?.institution_id}`)
    //             this.props.changeStateValue({
    //               name: 'editProgram',
    //               value: ''
    //             })
    //           }
    //         }
    //     })
    // }
    
  }

  setExams = (data, name)=>{
    // this.setState({
    //   [name]: data
    // })
  }

  setExtraActivity = (name, value) => {
    // this.setState({
    //   [name]: value
    // })
  }
  getOtherData = (type, id) =>{
    
  //   if(type === 'required'){
  //       if(id == 1){
  //           this.setState({
  //               highSchoolExamVisible: true
  //           })
  //       }else{
  //           this.setState({
  //               highSchoolExamVisible: false
  //           })
  //           this.props.changeStateValue({
  //               name: 'editProgram.program.ib_percentage',
  //               value: 0
  //           })
  //           this.props.changeStateValue({
  //               name: 'editProgram.program.ap_percentage',
  //               value: 0
  //           })
  //           this.props.changeStateValue({
  //               name: 'editProgram.program.ossd_percentage',
  //               value: 0
  //           })
  //           this.props.changeStateValue({
  //               name: 'editProgram.program.a_level_percentage',
  //               value: 0
  //           })

  //           this.props.changeStateValue({
  //               name: 'editProgram.program.ossd',
  //               value: ''
  //           })
  //           this.props.changeStateValue({
  //               name: 'editProgram.program.advanced_placement',
  //               value: ''
  //           })
  //           this.props.changeStateValue({
  //               name: 'editProgram.program.a_level',
  //               value: ''
  //           })
  //           this.props.changeStateValue({
  //               name: 'editProgram.program.ib_diploma',
  //               value: ''
  //           })
  //       }
  //   }else if(type === 'sch_required'){
     
  //     this.props.changeStateValue({
  //         name: 'editProgram.program.sch_requirements.ib_percentage',
  //         value: 0
  //     })
  //     this.props.changeStateValue({
  //         name: 'editProgram.program.sch_requirements.ap_percentage',
  //         value: 0
  //     })
  //     this.props.changeStateValue({
  //         name: 'editProgram.program.sch_requirements.ossd_percentage',
  //         value: 0
  //     })
  //     this.props.changeStateValue({
  //         name: 'editProgram.program.sch_requirements.a_level_percentage',
  //         value: 0
  //     })

  //     this.props.changeStateValue({
  //         name: 'editProgram.program.sch_requirements.ossd',
  //         value: ''
  //     })
  //     this.props.changeStateValue({
  //         name: 'editProgram.program.sch_requirements.advanced_placement',
  //         value: ''
  //     })
  //     this.props.changeStateValue({
  //         name: 'editProgram.program.sch_requirements.a_level',
  //         value: ''
  //     })
  //     this.props.changeStateValue({
  //         name: 'editProgram.program.sch_requirements.ib_diploma',
  //         value: ''
  //     })
  
  // }
  }
  getPlaceholder(array, id, placeholder){
    if(id && array.length !== 0){
        if(array.find(data=> data.value == id)){
            return array.find(data=> data.value == id).label
        }else{
            return placeholder
        }
    }else{
        return placeholder
    }
}

addDateBtn(data, e){
  // let arr = [...data];
  // arr.push({
  //   id: '',
  //   start_date: '',
  //   deadline: ''
  // });
  // this.setState({
  //   addDate: arr
  // })
}

removeDate(data,index, e){
  // let arr = [...data];
  // arr[index].deleted = true;
  // arr[index].start_date = '';
  // arr[index].deadline = '';
  // this.setState({
  //   addDate: arr
  // })
}

setOtherDate(index, data, type, e){
  // let arr = [...data];
  // arr[index][type] = e.target.value;
 
  // this.setState({
  //   addDate: arr
  // })
}
setAsRequired(e){
  // this.setState({
  //   setAsRequired: e.target.checked
  // })

}
changeExamCheck(value){
  // this.props.changeStateValue({
  //     name: 'editProgram.program.exam_check',
  //     value: value
  // })
  // if(value == 0){
  //   this.props.changeStateValue({
  //     name: 'editProgram.program.ib_percentage',
  //     value: 0
  // })
  // this.props.changeStateValue({
  //     name: 'editProgram.program.ap_percentage',
  //     value: 0
  // })
  // this.props.changeStateValue({
  //     name: 'editProgram.program.ossd_percentage',
  //     value: 0
  // })
  // this.props.changeStateValue({
  //     name: 'editProgram.program.a_level_percentage',
  //     value: 0
  // })

  // this.props.changeStateValue({
  //     name: 'editProgram.program.ossd',
  //     value: ''
  // })
  // this.props.changeStateValue({
  //     name: 'editProgram.program.advanced_placement',
  //     value: ''
  // })
  // this.props.changeStateValue({
  //     name: 'editProgram.program.a_level',
  //     value: ''
  // })
  // this.props.changeStateValue({
  //     name: 'editProgram.program.ib_diploma',
  //     value: ''
  // })
  // }
}

getSelectedprog(programs, id){
  const check = programs?.find(item=> item?.program_id == id);
  
  if(check){
    return true;
  }else{
    return false;
  }
}
  render() {
    const {part1Visible, part2Visible, part3Visible,part4Visible, setAsRequired, btnVisible, addDate, editProgram} = this.state;
    const { educationDegree, educationLanguage, currency, extraCurricularActivity, internationalMedals, unirepRoleTranslate} = this.props;



    const degreeOption = [];
    const languageOption = [];
    const currencyOption=[];
    const activityOption=[];
    const olympiadOption=[];
    let otherDates = [];
    if(addDate.length == 0){
      if(editProgram.program &&editProgram.program.other_dates){
        for(const date of editProgram.program.other_dates){
          otherDates.push({
            id: date.id,
            deadline: date.deadline,
            start_date: date.start_date
          })
        }
      } 
    }else{
      otherDates = [...addDate]
    }


    for(const property of internationalMedals){
      olympiadOption.push({
        label: property?.title,
        value: property?.olympiad_id
      })
    }
    for(const property of extraCurricularActivity){
      activityOption.push({
        label: property?.title,
        value: property?.activity_id
      })
    }
    for(const property of currency){
      currencyOption.push({
        label: property?.currency,
        value: property?.id
      })
    }
    degreeOption.push({
      label: 'Clear',
      value: ''
    })
    for(const property of educationDegree){
      
      degreeOption.push({
        label: property?.education_type,
        value: property?.education_degree_id
      })
    }
    for(const property of educationLanguage){
      languageOption.push({
        label: property?.language,
        value: property?.education_language_id
      })
    }
    let program_format = [
      {
          label: unirepRoleTranslate?.online,
          value: unirepRoleTranslate?.online
      },
      {
          label: unirepRoleTranslate?.full_time,
          value: unirepRoleTranslate?.full_time
      },
      {
          label: unirepRoleTranslate?.part_time,
          value: unirepRoleTranslate?.part_time
      },
      {
          label: unirepRoleTranslate?.nsu,
          value: unirepRoleTranslate?.nsu
      },
  ]
  let fee_type_options = [
    
    {
        label: unirepRoleTranslate?.yearly,
        value: '0'
    },
    {
      label: unirepRoleTranslate?.monthly,
      value: '1'
    },
  ]
  let scholarship_type =[
    {
        label: unirepRoleTranslate?.in_amount,
        value: 'amount'
    },
    {
        label: unirepRoleTranslate?.in_percentage,
        value: 'percentage'
    }
  ]
  // console.log(editProgram)
    return (
      <>
      <div className='flex'>
      {
          this.getSelectedprog(this.props.allProgramSelected, editProgram?.program_id)?
          <button onClick={()=>this.props.selectUnselectProg(editProgram?.program_id)} className='bg-green-500 p-1 text-sm text-white font-semibold rounded-xl cursor-pointer'>Selected</button>:
          <button onClick={()=>this.props.selectUnselectProg(editProgram?.program_id, this.state.editProgram, this.state.addDate)} className='bg-blue-500 p-1 text-sm text-white font-semibold rounded-xl cursor-pointer'>Select</button>
        }
        <h3 onClick={()=>this.setState({cardVisible:!this.state.cardVisible})} className='mt-2 ml-3 mb-2 text-xl font-semibold cursor-pointer'>{editProgram?.name}</h3>
      </div>
      {
        this.state.cardVisible?
        <div  className={`nc-PageAddListing1 max-w-6xl mx-auto pb-2 pt-1 g:pb-32`}  data-nc-id="PageAddListing1"  >
        <div className="space-y-11">
          
            {
              editProgram !== ''?
              <div className="listingSection__wrap ">
              <div className={`listingSection__wrap ${!part1Visible? ' h-5 pt-2':''}`}>
                <div onClick={this.changeVisible.bind(this, 'part1Visible', part1Visible)}>
                  <h3>{unirepRoleTranslate?.part_1}</h3>
                </div>
                {
                  part1Visible?
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5 justify-between">
                      <Input name="editProgram.name" value={editProgram?.name} placeholder={unirepRoleTranslate?.name} label={unirepRoleTranslate?.name} />
                      <DropDown placeholder={this.getPlaceholder(degreeOption, editProgram.program.education_degree_id, unirepRoleTranslate?.education_degree )} zIndex={101} type={'type'} name="editProgram.program.education_degree_id" option={degreeOption}  label={unirepRoleTranslate?.education_degree} />  
                    </div>
                    <MultiSelect translate={unirepRoleTranslate?.disciplines} />
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
                  
                      <DropDown type={'type'} name="editProgram.program_format" option={program_format} placeholder={this.getPlaceholder(program_format, editProgram.program_format, unirepRoleTranslate?.program_format )} label={unirepRoleTranslate?.program_format} />
                      <DropDown type={'type'} name="editProgram.program.education_language_id" option={languageOption} placeholder={this.getPlaceholder(languageOption, editProgram.program.education_language_id, unirepRoleTranslate?.education_language )} label={unirepRoleTranslate?.education_language} />
                      {/* <InputSelect /> */}
                      <StudyDuration value={editProgram?.program?.study_duration} />
                      <Input name="editProgram.program.start_date" value={editProgram?.program?.start_date} type="date" placeholder={unirepRoleTranslate?.start_date} label={unirepRoleTranslate?.start_date} />
                      <Input name="editProgram.program.deadline" value={editProgram?.program?.deadline} type="date" placeholder={unirepRoleTranslate?.deadline} label={unirepRoleTranslate?.deadline} />
                      {
                        editProgram?.program?.start_date != '' && editProgram?.program?.deadline != ''?
                        <button onClick={this.addDateBtn.bind(this, otherDates)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 mt-8
                                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '19px', color: 'white' ,  width: '25px', height: '25px' }}>
                        +
                        </button>:null
                      }
                     
                        {
                          otherDates.map((data, i)=>{
                            if(!data.deleted){
                              return(
                                < >
                                <div key={i+Math.random()}>
                                  <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                                  {unirepRoleTranslate?.start_date}
                                  </label>
                                  <input value={data.start_date} onChange={this.setOtherDate.bind(this, i, otherDates, 'start_date')}  type={'date'}
                                      className={`block w-full border-neutral-200 focus:border-primary-300 
                                      focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                                      dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                                      dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                                      
                                  /> 
                                </div>
                                <div  key={i+Math.random()}>
                                  <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                                  {unirepRoleTranslate?.deadline}
                                  </label>
                                  <input value={data.deadline} onChange={this.setOtherDate.bind(this, i, otherDates, 'deadline')} type={'date'}
                                      className={`block w-full border-neutral-200 focus:border-primary-300 
                                      focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                                      dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                                      dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                                      
                                  /> 
                              </div>
                              <button  key={i+Math.random()} onClick={this.removeDate.bind(this, otherDates, i)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 mt-8
                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '19px', color: 'white' ,  width: '25px', height: '25px' }}>
                                -
                                </button>
                                </>
                              )
                            }
                           
                          })
                        }
                      
                      {/* <InputPercentage /> */}
                      
                    </div>
                  </div>:null
                }
                
              </div>
              <div className={`listingSection__wrap ${!part2Visible? ' h-5 pt-2':''}`}>
                <div onClick={this.changeVisible.bind(this, 'part2Visible', part2Visible)}>
                  <h3 className='flex gap-5 items-center'>{unirepRoleTranslate?.part_2} 
                  <div className='flex gap-2 items-center'>
                    <input onChange={this.changeExamCheck.bind(this, editProgram?.program?.exam_check==0?1:0 )} type='checkbox' id='examCheckTypeBtn' defaultChecked={editProgram?.program?.exam_check == 0? false:true} />
                    <label htmlFor='examCheckTypeBtn'>Exam Requirement</label>
                  </div>
                  </h3>
                </div>
                {
                  part2Visible?
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12 md:gap-2">
                      <DropDown zIndex={1}  getOtherData={this.getOtherData} type={'required'} name="editProgram.program.required_education_level" option={degreeOption} placeholder={this.getPlaceholder(degreeOption, editProgram.program.required_education_level, unirepRoleTranslate?.required_edu_degree )} label={unirepRoleTranslate?.required_edu_degree} />  
                      <InputPercentage
                        label={unirepRoleTranslate?.required_gpa}
                        value={editProgram?.program?.gpa}
                        placeholder={unirepRoleTranslate?.required_gpa}
                        name="editProgram.program.gpa"
                        perValue={editProgram?.program?.gpa_percentage}
                        perName={'editProgram.program.gpa_percentage'}
                      /> 
                     <ExamsInput value={[...editProgram.program.specialty_exams]} name="exams" setExams={this.setExams} />
                    
                    </div>

                    <div  className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-12 mt-5">
                      <SelectBox idName="activity_id" value={editProgram.activity} setExtraActivity={this.setExtraActivity} name="activityData" options={activityOption} label={unirepRoleTranslate?.extra_act} />
                      <SelectBox idName="olympiad_id" value={editProgram.olympiad} setExtraActivity={this.setExtraActivity} name="olympiadData" options={olympiadOption} label={unirepRoleTranslate?.olymp_medal} />
                    </div>
                    <div className="w-auto border-b border-neutral-200 dark:border-neutral-700 mt-5"></div>

                    {
                        editProgram.program.required_education_level == 1 && editProgram.program.exam_check == 1 || editProgram.program.required_education_level == 2 && editProgram.program.exam_check == 1?
                        <HighSchoolExams editProgram={editProgram} />:null
                    }
                    
                  </div>:null
                }
                
              </div>
              <div className={`listingSection__wrap ${!part3Visible? ' h-5 pt-2':''}`}>
                <div onClick={this.changeVisible.bind(this, 'part3Visible', part3Visible)}>
                  <h3>{unirepRoleTranslate?.part_3}</h3>
                </div>
                {
                  part3Visible ?
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-12">
                      <DropDown type={'type'} placeholder={this.getPlaceholder(currencyOption, editProgram.program.fee_currency_id, unirepRoleTranslate?.currency )} name="editProgram.program.fee_currency_id" option={currencyOption}  label={unirepRoleTranslate?.currency} />  
                      <InputSelect 
                        label={unirepRoleTranslate?.fee_amount}
                        value={editProgram?.program?.fee_amount}
                        name="editProgram.program.fee_amount"
                        placeholder={unirepRoleTranslate?.fee_amount}
                        selectValue={editProgram?.program?.fee_type}
                        selectName={'editProgram.program.fee_type'}
                        options={fee_type_options}
                      />
                    </div>
                    <div className="grid grid-cols-1 mt-5 md:grid-cols-2 gap-12 md:gap-12">
                      <InputSelect 
                        label={unirepRoleTranslate?.schoolarship_option}
                        value={editProgram?.program?.schoolarship_option}
                        name="editProgram.program.schoolarship_option"
                        placeholder={unirepRoleTranslate?.schoolarship_option}
                        selectValue={editProgram?.program?.schoolarship_type}
                        selectName={'editProgram.program.schoolarship_type'}
                        options={scholarship_type}
                      />
                      <Input name="editProgram.program.application_fee" value={editProgram?.program?.application_fee} type="number" placeholder={unirepRoleTranslate?.application_fee} label={unirepRoleTranslate?.application_fee} />
                    </div>
                  </div>:null
                }
                
                
              </div>

              <div className={`listingSection__wrap ${!part4Visible? ' h-5 pt-2':''}`}>
              <div className='flex items-center gap-10' onClick={this.changeVisible.bind(this, 'part4Visible', part4Visible)}>
                  <h3>part 4</h3>
                 <div className='flex gap-4 items-center'>
                  <label htmlFor='setAsRequired'>Same with required</label>
                  <input onChange={this.setAsRequired.bind(this)} type={'checkbox'} id="setAsRequired" />
                 </div>
                </div>
                {
                  part4Visible && !setAsRequired?
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12 md:gap-2">
                      <DropDown zIndex={1}  getOtherData={this.getOtherData} type={'sch_required'} name="editProgram.program.sch_requirements.required_education_level" option={degreeOption} placeholder={this.getPlaceholder(degreeOption, editProgram.program.sch_requirements.required_education_level, unirepRoleTranslate?.required_edu_degree )} label={unirepRoleTranslate?.required_edu_degree} />  
                      <InputPercentage
                        label={unirepRoleTranslate?.required_gpa}
                        value={editProgram?.program?.sch_requirements.gpa}
                        placeholder={unirepRoleTranslate?.required_gpa}
                        name="editProgram.program.sch_requirements.gpa"
                        perValue={editProgram?.program?.sch_requirements.gpa_percentage}
                        perName={'editProgram.program.sch_requirements.gpa_percentage'}
                      /> 
                     <ExamsInput value={[...editProgram.program.sch_specialty_exams]} name="sch_exams" setExams={this.setExams} />
                    
                    </div>

                    <div  className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-12 mt-5">
                      <SelectBox idName="activity_id" value={editProgram.sch_activity} setExtraActivity={this.setExtraActivity} name="sch_activityData" options={activityOption} label={unirepRoleTranslate?.extra_act} />
                      <SelectBox idName="olympiad_id" value={editProgram.sch_olympiad} setExtraActivity={this.setExtraActivity} name="sch_olympiadData" options={olympiadOption} label={unirepRoleTranslate?.olymp_medal} />
                    </div>
                    <div className="w-auto border-b border-neutral-200 dark:border-neutral-700 mt-5"></div>

                    {
                        editProgram.program?.sch_requirements?.required_education_level == 1 || editProgram.program?.sch_requirements?.required_education_level == 2?
                        <HighSchoolSchExams />:null
                    }
                    
                  </div>:null
                }
                
              </div>
            </div>:null
            }
           
            <div className="flex justify-between items-center" >
                    
                    {/* <Link  to={`/institution/${editProgram?.program?.institution_id}`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                    {unirepRoleTranslate?.back}
                    </Link>
                    <button  disabled={btnVisible} onClick={this.saveChanges.bind(this, editProgram,otherDates, 'dublicate')} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                    {unirepRoleTranslate?.submit_clone}
                    </button>
                    <button disabled={btnVisible} onClick={this.saveChanges.bind(this, editProgram,otherDates)}  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                    {unirepRoleTranslate?.submit}
                    </button> */}
                </div>
        </div>
      </div>:null
      }
      </>
      
      
    )
  }
}

const mapStateToProps = (state)=>({
  editProgram: state.Data.editProgram,
  educationDegree: state.Data.educationDegree,
  languageId: state.Data.languageId,
  educationLanguage: state.Data.educationLanguage,
  currency: state.Data.currency,
  internationalMedals: state.Data.internationalMedals,
  extraCurricularActivity: state.Data.extraCurricularActivity,
  studyDurationValue: state.Data.studyDurationValue,
  user: state.Data.user,
  unirepRoleTranslate: state.Data.unirepRoleTranslate,
})
const mapDispatchToprops = {changeStateValue, 
  getEducationDegree, 
  getEducationLanguage,
  getCurrency, 
  getExtraCurricularActivity, 
  getInternationalMedals,
  getProgramData, 
  insertNewData,
  getUnirepRoleTranslate,
  mainDashboardTranslate
}
export default connect(mapStateToProps, mapDispatchToprops)(withParams(EditProgram))


