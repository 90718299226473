import React, { Component } from 'react'
import Editor from './Editor'
import { connect } from 'react-redux';
import {changeStateValue} from '../../../redux/MainReducer';
import { insertCountryInfo, getCountriesData } from '../../../actions/WorkerActions';
import { getCountryData } from '../../../actions/MainAction';
export class Modal extends Component {
  changeInpValue(e){
    this.props.changeStateValue({
        name: 'countryInfoModal.question',
        value: e.target.value
    })
  }

  closeModal(){
    const data={
      id: '',
      position: false,
      country_fags:{
          countries_id: '',
          type: '',
      },
      lang_id: 1,
      question: '',
      answer: '',
      active: '1'
    }
    this.props.changeStateValue({
        name: 'countryInfoModal',
        value: data
    })
  }

  saveData(data){
    if(data.question !== '' && data.answer !== ''){
      let url = 'country-faqs';
      let formdata = new FormData();
      for(const property in data){
        if(property === 'country_fags'){
            formdata.append(property, JSON.stringify(data[property]))
        }else{
            formdata.append(property, data[property])
        }
      }
      if(data.id !== ''){
          url = `country-faqs/${data.id}`;
          formdata.append("_method", "put");
      }
     this.props.insertCountryInfo(url, formdata)
      .then(resp=>{
        this.closeModal();
        this.props.getCountriesData(data.country_fags.countries_id, 1, data.country_fags.type)
        if(data.country_fags?.type == 'about'){
          this.props.getCountryData(data.country_fags.countries_id, this.props.languageId)

        }
      })
    }
  }
  render() {
    const {countryInfoModal} = this.props;
    
    return (
      <div  className='background_shadow'>
        <div className='modal_container'>
            <h2 className='mb-5'>{countryInfoModal.id == ''? 'Add': 'Edit'} Data</h2>
            {
              countryInfoModal.country_fags?.type !== 'about'?
              <input onChange={this.changeInpValue.bind(this)} value={countryInfoModal.question} type={'text'}  placeholder="Title"/>:null
              
            }
           {
                countryInfoModal.question == ''?
                <span style={{ fontSize: '12px', color:'red' }}>Input is required</span>:null
              }
            <Editor />

            <div className='flex justify-between mt-5'>
                <button  onClick={this.closeModal.bind(this)} className={`ttnc-ButtonThird text-neutral-700 border 
                    border-neutral-200 dark:text-neutral-200 
                    dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                    items-center justify-center rounded-full transition-colors
                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200 
                    px-4 py-1 sm:px-3  `} style={{ fontSize: '12px'  }}>
               Close
            </button>
                <button onClick={this.saveData.bind(this, countryInfoModal)} className={`ttnc-ButtonThird text-neutral-700 border 
                    border-neutral-200 dark:text-neutral-200 
                    dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                    items-center justify-center rounded-full transition-colors
                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200 
                    px-4 py-1 sm:px-3  bg-primary-6000`} style={{ fontSize: '12px', color: 'white' }}>
                
            Submit
            </button>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  countryInfoModal: state.Data.countryInfoModal,
  languageId: state.Data.languageId,
});
const mapDispatchToProps = {changeStateValue, insertCountryInfo, getCountriesData, getCountryData}
export default connect(mapStateToProps, mapDispatchToProps)(Modal)
