import React, { Component } from 'react'
import SvgUp from '../../../../components/page_component/simulator_components/SvgUp';
import SvgDown from '../../../../components/page_component/simulator_components/SvgDown';


export class CheckBox extends Component {
    state={
        position: false,
        modalRef: React.createRef(),
      
        applications: [],
        accomadation: [],
        visa: []
    }
    openCloseModal(position, e){
        this.setState({
            position: !position
        })
    }

    
    componentDidUpdate(prevProps, prevState){
        if(prevState.position !== this.state.position){
          const {modalRef} = this.state;
        
          window.addEventListener('click', (e)=> {
            if(modalRef && modalRef.current){
              const checkInside = modalRef.current.contains(e.target);
           
              if(!checkInside ){
                this.setState({
                    position: false,
                })
              }
            }
            
          })
        }
       
      }

      selectData(id, array, defaultData,  e){
    
        let data = [...array];
        let dataForBackend = [...defaultData];
      
        const index = data.findIndex(item=>item == id);
        if(index == -1){
            data.push(id)    
        }else{
           
            data.splice(index, 1)
        }
        const getId = id.split('-')[0];
        const checkData = defaultData.find(data=> data.track_id == getId);
        const checkDataIndex = defaultData.findIndex(data=> data.track_id == getId);
        if(checkData){
            if(checkData.deleted){
                checkData.deleted = false
            }else{
                checkData.deleted = true
            }
            
            defaultData[checkDataIndex] = checkData
        }else{
            dataForBackend.push({
                id: '',
                program_id: this.props.program_id,
                student_id: this.props.student_id,
                track_id: getId,
            })
        }



        this.setState({
            [this.props.type]: data
        })
        this.props.setData(dataForBackend, this.props.type)
      }

      checkChecked(id, array){
        let data = [...array];
        let index = data.findIndex(item=>item == id);
        if(index == -1){
            return false
        }else{
           return true
        }
       
      }
  render() {
    const {position, modalRef} = this.state;
    const {title, array = [], type, defaultData=[]} = this.props;
    let newData = [];
    if(this.state[type].length == 0){
        defaultData.forEach(item=>{
            if(!item.deleted){
                newData.push(item.track_id+`-${type}`)
            }
            
        })
    }else{
        newData = [...this.state[type]]
    }
    
  
    return (
      <div className='relative ' ref={modalRef}>
        <div className='checkbox_container' onClick={this.openCloseModal.bind(this, position)}>
            <span className='text-primary-700 dark:text-primary-400 leading-5 font-medium'>{title}</span>
            {
                position?
                <SvgUp />:
                <SvgDown />
            }
            
        </div>
        {
            position?
            <div  className='checkbox_body w-full ml-2 pr-4 '>
                <ul className='bg-primary-900/10 rounded-xl pt-2 pl-4 pb-2 space-y-3 '>
                    {
                        array.map((item, i)=>{
                            
                            return(
                                <li key={i} className='flex gap-2 items-center'>
                                    <label htmlFor={item.title+item.id} className='w-full flex gap-2 items-center cursor-pointer'>
                                        <input checked={this.checkChecked(item.track_id+`-${type}`, newData)} onChange={this.selectData.bind(this, item.track_id+`-${type}`, newData, defaultData)} id={item.title+item.id} type={'checkbox'} />
                                        <span>{item.title}</span>
                                    </label>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>:null
        }
        
      </div>
    )
  }
}

export default CheckBox