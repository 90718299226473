import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

export class MainCard extends Component {
  render() {
    const {url = '/', image, title, uniCount, mainTransateData, user, item, editData, type} = this.props
    return (
        <Link to={url}  className={`nc-CardCategory5 flex flex-col `}  data-nc-id="CardCategory5"
            >
            <div
                className={`flex-shrink-0 relative w-full aspect-w-4 aspect-h-3 h-0 rounded-2xl overflow-hidden group`}
            >
                
                 <div className={`nc-NcImage `} data-nc-id="NcImage"  >
                    
                        <img src={image} className="object-cover w-full h-full rounded-2xl" alt={title} />
                   
                    </div>
                <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
            </div>
            <div className="mt-4 px-3 truncate">
                <h2
                className={`text-center sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
                >
                {title}
                </h2>
                <span
                className={`block text-center mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}
                >
                {uniCount} <span aria-label='mainTransateData.universities'>{mainTransateData?.universities}</span>
                </span>
                {
                    user != '' && user.role == 'uni_rep'?
                    <div className='flex justify-center mt-2 '>
                        <button onClick={(e)=>{e.preventDefault(); editData(type, item)}} className='border text-sm rounded-2xl pl-2 pr-2 p-1'>Edit</button>
                    </div>:null
                }
               
            </div>
        </Link>
      
    )
  }
}
const mapStateToProps = (state) =>({
    mainTransateData: state.Data.mainTransateData,
    user: state.Data.user,
})
export default connect(mapStateToProps)(MainCard)