import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../../../actions/withHooks';
import { getStudentNotes } from '../../../../../actions/WorkerActions';
import { changeStateValue } from '../../../../../redux/MainReducer';
export class Notes extends Component {

    componentDidMount(){
        this.props.getStudentNotes(this.props.params.id)
    }

    addEditBtn=(data)=>{
        this.props.changeStateValue({
            name:'studentNoteModal',
            value:{
                position: true,
                student_id: this.props.params.id,
                note: data?.note?data?.note:'',
                id: data?.id?data?.id:''
            }
        })
    }
  render() {
    const {studentNotes} = this.props;
    return (
      <div>
        <div className='mt-[20px]'>
            <button onClick={this.addEditBtn.bind(this, '')} className='text-[13px] bg-blue-700 p-[3px_6px] rounded-[10px] text-white font-[500]'>
                Add Note
            </button>
        </div>
        <div className='grid grid-cols-3 gap-[20px] mt-[20px]'>
            {
                studentNotes?.map((data, i)=>{
                    return(
                        <div key={i} className='bg-white p-[20px] shadow-sm rounded-[10px]'>
                            <p className='text-[13px]' dangerouslySetInnerHTML={{ __html:data?.note }}></p>
                            <div className='flex justify-between mt-[20px]'>
                                <button className='text-[13px] bg-red-700 p-[3px_6px] rounded-[10px] text-white font-[500]'>
                                    Delete
                                </button>
                                <button onClick={this.addEditBtn.bind(this, data)} className='text-[13px] bg-blue-700 p-[3px_6px] rounded-[10px] text-white font-[500]'>
                                    Edit
                                </button>
                            </div>
                        </div>
                    )
                })
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    studentNotes: state.Data.studentNotes
});
const mapDispatchToProps = {getStudentNotes, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Notes))
