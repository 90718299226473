import React, { Component } from 'react'
import TextComponent from '../components/TextComponent'

export class Working extends Component {
  render() {
    const {data} = this.props
    return (
      <div>
        <TextComponent title={data?.question} desc={data?.answer} />
      </div>
    )
  }
}

export default Working