import React, { Component } from 'react'
import { connect } from 'react-redux';
import Button from './Button'

export class Buttons extends Component {
    state={
        buttonType: 'program'
    }
    changeData = (item) =>{
        this.props.changePage(item);
        this.setState({
            buttonType: item
        })
    }
  render() {
    const {buttonType} = this.state;
    const {mainTransateData, sch, student} = this.props; 
    return (
        <div className='profile_header_btn gap-3'>
            <Button label="mainTransateData.uni_programs" title={mainTransateData?.uni_programs} className={buttonType == 'program'? 'bg-primary-6000': ''} type="program" changeData={this.changeData} />
            {
                student?.length >0?
                <Button label="mainTransateData.uni_programs" title={'Students'} className={buttonType == 'student'? 'bg-primary-6000': ''} type="student" changeData={this.changeData} />:null
            }
            
            {
                sch.length >0?
                <Button label="mainTransateData.uni_sch" title={mainTransateData?.uni_sch} className={buttonType == 'scholarship'? 'bg-primary-6000': ''} type="scholarship" changeData={this.changeData} />:null
            }
            <Button label="mainTransateData.about_uni" title={mainTransateData?.about_uni} className={buttonType == 'about'? 'bg-primary-6000': ''} type="about" changeData={this.changeData} />
            <Button label="mainTransateData.faqs" title={mainTransateData?.faqs} className={buttonType == 'faq'? 'bg-primary-6000': ''} type="faq" changeData={this.changeData} /> 
            <Button label="mainTransateData.uni_comments" title={mainTransateData?.uni_comments} className={buttonType == 'comment'? 'bg-primary-6000': ''} type="comment" changeData={this.changeData} /> 

        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    mainTransateData: state.Data.mainTransateData
})
export default connect(mapStateToProps)(Buttons)