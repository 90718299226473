import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import LikeIcon from './LikeIcon'

export class Slider extends Component {
    state={
        galleryImgs:[
            {
                id: 1,
                src: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'
            },
            {
                id: 2,
                src: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'
            },
            {
                id: 3,
                src: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'
            },
        ]
    }
    getSchoolarship(currency, type, amount, fee){
        
        if(type == 'amount'){
           return currency+ " "+amount
        }else{
            return currency+ " "+ (fee*amount/100).toFixed(0)
        }
    }
  render() {
    const {galleryImgs} = this.state;
    const {program, url="/", height = '3', status , pending, accepted, statusText} = this.props;

    return (
        <div className="relative w-full">
            <div className={`nc-GallerySlider`} data-nc-id="GallerySlider">
                <div className={`gallerySlider__StayCard_1 relative group overflow-hidden`}>
                    <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {galleryImgs.map((item, index) => (
                        <li key={index} className="glide__slide">
                            <Link to={url} className={`block aspect-w-4 aspect-h-${height}`}>
                                <div className={`nc-NcImage  `} data-nc-id="NcImage" >
                                
                                    <img src={program?.program?.education_degree?.image_full_url} className={'object-cover w-full h-full'}  />
                                
                                </div>
                            </Link>
                        </li>
                        ))}
                    </ul>
                    </div>

                    {/* DOTS */}
                    <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
                    <div
                        className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
                        data-glide-el="controls[nav]"
                    >
                        {/* {galleryImgs.map((_, i) => (
                        <button
                            className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
                            key={i}
                            data-glide-dir={`=${i}`}
                        />
                        ))} */}
                    </div>

                    {/* NAV */}
                    <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
                    {/* <div
                        className={`nc-NextPrev relative flex items-center text-neutral-900 dark:text-neutral-300 w-full justify-between`}
                        data-nc-id="NextPrev"
                        data-glide-el="controls"
                        >
                        
                            <button
                            className={`w-8 h-8 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 `}
                            
                            onClick={()=>{}}
                            title="Prev"
                            data-glide-dir="<"
                            >
                            <i className="las la-angle-left"></i>
                            </button>
                        
                    
                            <button
                            className={`w-8 h-8 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300  `}
                            onClick={()=>{}}
                            
                            title="Next"
                            data-glide-dir=">"
                            >
                            <i className="las la-angle-right"></i>
                            </button>
                       
                        </div> */}
                    </div>
                </div>
            </div>
       
            {/* <LikeIcon /> */}
        {/*  bg-red-700 text-red-50 */}
        
            <div style={{ backgroundColor: 'white', fontWeight: '700', fontSize: '14px' }} className={`nc-SaleOffBadge flex items-center justify-center text-xs py-2 px-3 bg-black-700 text-black-50 rounded-full absolute left-3 top-3`}
                data-nc-id="SaleOffBadge" >
                {statusText}: {status}
            </div>
        </div>
    )
  }
}

export default Slider