import React, { Component } from 'react'
import { connect } from 'react-redux';
import AddRangeBtn from './AddRangeBtn';
import SliderCard from './SliderCard';
import { changeStateValue, resetStateValue } from '../../../redux/MainReducer';
import { updateStudentData, getExams } from '../../../actions/MainAction';
import FooterBtns from './FooterBtns';
export class SliderRange extends Component {
  updateData = ()=>{
    if(localStorage.getItem('token')){
    
      const lastExams = []
      for(const exam of this.props.examsData){
        const title = this.props.filterExamGrade[exam.exam_type.toLowerCase()];
        const params = {
          id: exam.id,
          exam_type: exam.exam_type,
          over_all: title?title: ''
        }
        lastExams.push(params)
      }
     
      let formData = new FormData();
     
      formData.append('type',  'filterExamGrade');
      formData.append('filterExamGrade', JSON.stringify(lastExams));
      const simulator = JSON.parse(localStorage.getItem('simulator'))
      formData.append('student_id', simulator.student_id)
      formData.append('_method', 'put')
      this.props.updateStudentData(formData)
    }
    localStorage.setItem('filterExamGrade', JSON.stringify(this.props.filterExamGrade));
    this.props.changeStateValue({
      name: 'filterExamGrade',
      value: this.props.filterExamGrade
    })
    this.props.refreshForExams()

  }

  resetData = () =>{
    this.props.resetStateValue({
      name: 'filterExamIds'
    })
    this.props.resetStateValue({
      name: 'filterExamGrade'
    })

    if(localStorage.getItem('token')){
    
      const lastExams = []
      for(const exam of this.props.examsData){
       
        const params = {
          id: exam.id,
          exam_type: exam.exam_type,
          over_all:  ''
        }
        lastExams.push(params)
      }
     
      let formData = new FormData();
      // console.log(this.props.filterExamGrade)
      formData.append('type',  'filterExamGrade');
      formData.append('filterExamGrade', JSON.stringify(lastExams));
      const simulator = JSON.parse(localStorage.getItem('simulator'))
      formData.append('student_id', simulator.student_id)

      formData.append('_method', 'put')
      this.props.updateStudentData(formData)
    }
    localStorage.setItem('filterExamIds', JSON.stringify([])); 
    this.props.refreshForExams()
  }
  componentDidMount(){
    if(localStorage.getItem('filterExamGrade')){
      this.props.changeStateValue({
        name: 'filterExamGrade',
        value: JSON.parse(localStorage.getItem('filterExamGrade'))
      })
    }
    this.props.getExams()
  }
  render() {
    const {filterExamIds, filterExamGrade, refreshForExams} = this.props;
    
    return (
        <div className="  bg-white dark:bg-neutral-900 ">
            <AddRangeBtn />
            <div className="relative flex flex-col px-5 py-6 space-y-8">
              {
                filterExamIds.includes(1)?
                <SliderCard  title="IELTS" id="1" property="ielts" min_value={3}  max_value={9} step={0.5} value={filterExamGrade.ielts}/>:null
              }
              {
                filterExamIds.includes(2)? 
                <SliderCard  title="TOEFL" id="2" property="toefl" min_value={0}  max_value={120} step={1} value={filterExamGrade.toefl}/>
                
                :null
              }
              {
                filterExamIds.includes(3)? 
                <SliderCard  title="PTE" id="3" property="pte" min_value={10}  max_value={90} step={1} value={filterExamGrade.pte}/>

                :null
              }
              {
                filterExamIds.includes(4)? 
                <SliderCard  title="Duolingo" id="4" property="duolingo" min_value={10}  max_value={160} step={1} value={filterExamGrade.duolingo}/>

                :null
              }
              {
                filterExamIds.includes(5)? 
                <SliderCard  title="SAT" id="5" property="sat" min_value={400}  max_value={1600} step={1} value={filterExamGrade.sat}/>

                :null
              }
              {
                filterExamIds.includes(6)? 
                <SliderCard  title="GRE" id="6" property="gre" min_value={0}  max_value={340} step={1} value={filterExamGrade.gre}/>

                :null
              }
              {
                filterExamIds.includes(7)? 
                <SliderCard  title="GMAT" id="7" property="gmat" min_value={200}  max_value={800} step={1} value={filterExamGrade.gmat}/>

                :null
              }
                
                
            </div>
            <FooterBtns resetData={this.resetData} updateBtn={true} updateData={this.updateData} />
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  filterExamIds: state.Data.filterExamIds,
  filterExamGrade: state.Data.filterExamGrade,
  examsData: state.Data.examsData,
});
const mapDispatchToProps = {changeStateValue, resetStateValue, updateStudentData, getExams}
export default connect(mapStateToProps, mapDispatchToProps)(SliderRange)