import React, { Component } from 'react'
import Searchable from 'react-searchable-dropdown'; 

export class DropDown extends Component {
    selectDropValue =(data, type) => {
      
        this.props.selectData(data, type)
    }
  render() {
    const { option=[], placeholder, type, otherClass=''} = this.props
    return (
        <div className='relative' >
            <div   className={`block w-full border-neutral-200  
                focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border  ${otherClass}`}
            > 
                <Searchable 
                    options={option}
                    onSelect={this.selectDropValue.bind( this.label, type)}
                    placeholder={placeholder}
                />
            </div>
        </div>
    )
  }
}

export default DropDown