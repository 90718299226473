import React, { Component } from 'react'
import { connect } from 'react-redux'
import Image from '../student_pages/components/Image';
import ProfileData from './components/ProfileData'
import avatar1 from '../../images/default.jpg'
import { getUnirepRoleTranslate,  mainDashboardTranslate} from '../../actions/TranslateAction';
export class ParentProfile extends Component {
    componentDidMount(){
        window.scrollTo(0, 0)
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.mainDashboardTranslate(this.props.languageId);
    }
  render() {
    const {parentPersonalInfo, mainTransateData} = this.props;
    return (
        <div  className={`ListingDetailPage nc-ListingCarDetailPage`} data-nc-id="ListingCarDetailPage">
        <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
         <div className="block flex-grow mb-24 lg:mb-0">
           <div className="lg:sticky lg:top-24">
             <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
               <Image
                 url={
                   parentPersonalInfo.image_full_url !== ''?
                   parentPersonalInfo.image_full_url: avatar1
                 }
               />
               <div className="space-y-3 text-center flex flex-col items-center">
                 <h2 className="text-3xl font-semibold">{parentPersonalInfo.full_name}</h2>
                
               </div>
               <ul style={{ flexDirection: 'column' }} className='flex items-start w-full gap-2'>
             
                 {
                   parentPersonalInfo.email && parentPersonalInfo.email !== '' && parentPersonalInfo.email !== 'null'?
                   <li className='flex gap-3'><b><span aria-label='mainTransateData.email'>{mainTransateData?.email}</span>: </b>{parentPersonalInfo.email}</li>:null
                 }
                 {
                   parentPersonalInfo.phone_number && parentPersonalInfo.phone_number !== '' && parentPersonalInfo.phone_number !== 'null'?
                   <li className='flex gap-3'><b><span aria-label='mainTransateData.phone_number'>{mainTransateData?.phone_number}</span>: </b>{parentPersonalInfo.phone_number}</li>:null
                 }
                 
                 
                
               </ul>
             </div>
           </div>
         </div>
         <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
              <div className="listingSection__wrap">
                <div>
                    <ProfileData />
                </div>
            </div>
        </div>
        </main>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    parentPersonalInfo: state.Data.parentPersonalInfo,
    languageId: state.Data.languageId,
    mainTransateData: state.Data.mainTransateData
});
const mapDispatchToProps = {getUnirepRoleTranslate,  mainDashboardTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(ParentProfile)