import React, { Component } from 'react'
import { connect } from 'react-redux';
import { insertNewData } from '../../../actions/WorkerActions';
import Swal from 'sweetalert2';
export class Modals extends Component {
    state={
        id:this.props.data?.id?this.props.data?.id:'',
        title: this.props.data?.title?this.props.data?.title:'',
        prompt: this.props.data?.prompt?this.props.data?.prompt:'',
        lang_id:this.props.data?.lang_id?this.props.data?.lang_id:1,
        disable: false
    }

    saveData=(data)=>{
        if(data?.title != ''){
            this.setState({disable:true})
            let formData = new FormData();
            formData.append("title", data?.title);
            formData.append("prompt", data?.prompt);
            formData.append("lang_id", data?.lang_id);
            let url = "/blog-topic";
            if(data?.id && data?.id !== ""){
                url = `/blog-topic/${data?.id}`
                formData.append("_method", "put")
            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({disable:false})
                if(resp?.msg === "success"){
                    this.setState({ id:'',  title:'',  prompt:'',  lang_id:1});
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.onmouseenter = Swal.stopTimer;
                          toast.onmouseleave = Swal.resumeTimer;
                        }
                      });
                      Toast.fire({
                        icon: "success",
                        title: "Blog Topic created successfully"
                      });
                      this.props.onClose()
                }else{
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.onmouseenter = Swal.stopTimer;
                          toast.onmouseleave = Swal.resumeTimer;
                        }
                      });
                      Toast.fire({
                        icon: "error",
                        title: "Something went wrong"
                      });
                }
            })
        }
    }
  render() {
    const {title, prompt, disable, id} = this.state;
    return (
      <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[700px] p-[20px] rounded-[12px]'>
                <h2 className='text-[20px] font-semibold'>{id===""?"Add":"Edit"} Topic</h2>
                <div className='mt-[12px]'>
                    <div>
                        <label className='text-[14px]' htmlFor='blogTitle'>Title</label>
                        <input value={title} onChange={(e)=>this.setState({title: e.target.value})} placeholder='Title' type='text' id='blogTitle' />
                    </div>
                    <div className='mt-[12px]'>
                        <label className='text-[14px]' htmlFor='blogPrompt'>Prompt</label>
                        <textarea placeholder='Prompt' value={prompt} onChange={(e)=>this.setState({prompt: e.target.value})} className='rounded-[12px] h-[120px]' id='blogPrompt'></textarea>
                    </div>
                    <div className='flex justify-between items-center mt-[12px]'>
                        <button disabled={disable} onClick={()=>{this.props.onClose()}} className='text-[12px] text-white font-semibold bg-red-600 p-[7px] rounded-full pl-[16px] pr-[16px]'>Close</button>
                        <button disabled={disable} onClick={this.saveData.bind(this, this.state)} className='text-[12px] text-white font-semibold bg-blue-600 p-[7px] rounded-full pl-[16px] pr-[16px]'>Save</button>
                    </div>
                </div>
            </div>
           
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(Modals)
