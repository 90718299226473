import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCountryFeature } from '../../../actions/WorkerActions';
export class CountriesCard extends Component {
  state={
    btnDisabled: false
  }
  getColor(type){
    if(type == '0'){
        return 'red'
    }else{
        return 'green'
    }
}

setFeatureBtn(id, data, e){
  this.setState({
      btnDisabled: true
  })
  this.props.setCountryFeature(id, data)
  .then(resp=>{
      this.props.getAfterSubmit();
      this.setState({
          btnDisabled: false
      })
  })
}
  render() {
    const {item, mainTransateData, user, editDataBtn} = this.props;
    const {btnDisabled} = this.state
    return (
        <li  className={`glide__slide `}>
                  
        {/* <Link
            to={`/country/${item?.countries?.id}`}
            className={`nc-CardCategory4 flex flex-col `}
            data-nc-id="CardCategory4"
          > */}
          <Link to={`/country/${item?.countries?.id}`}>
            <div
              className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-6 h-0 rounded-2xl overflow-hidden group`}
            >
             
               <div
                  className={`nc-NcImage`}
                  data-nc-id="NcImage"
                  
                >
                
                    <img src={item?.countries?.image_full_url} className={'object-cover w-full h-full rounded-2xl'}  />
             
                    
                  
                </div>
                
              <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
            </div>
            </Link>
            <div className="mt-4 px-2 truncate text-center">
              <h2
                className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
              >
                {item.name}
              </h2>
              <span
                className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}
              >
                {item.universities} <span aria-label='mainTransateData.universities'>{mainTransateData?.universities}</span>
              </span>
            </div>
            {
              user!== '' && user.role == 'uni_rep' || user!== '' && user.role == 'content'?
              <div className="mt-4 px-2 truncate text-center ">
              <Link aria-label='mainTransateData.add_info' className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                               nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                               text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }} to={`/country-data/${item?.countries?.id}`}>
                                {mainTransateData?.add_info}
                </Link>
                <button className='border p-2 rounded-2xl text-xs ml-2 mr-2' onClick={()=>editDataBtn(item)}>Edit</button>
                {
                    item.countries.feature_status?
                    <button disabled={btnDisabled} onClick={this.setFeatureBtn.bind(this, item?.countries?.id, item.countries.feature_status == '0'?'1':'0' )} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' 
                                style={{ fontSize: '12px', color: 'white',  backgroundColor: this.getColor(item.countries.feature_status) }}  >
                                {item.countries.feature_status == '0'? <span aria-label='mainTransateData.set_feature'>{mainTransateData?.set_feature}</span>: <span aria-label='mainTransateData.seted'>{mainTransateData?.seted}</span>}    
                </button>:null
                }
             </div>:null
            }
           
          {/* </Link> */}
      </li>
    )
  }
}
const mapStateToProps = (state) =>({
  mainTransateData: state.Data.mainTransateData,
  user: state.Data.user,
})
const mapDispatchToProps = {setCountryFeature}
export default connect(mapStateToProps, mapDispatchToProps)(CountriesCard)