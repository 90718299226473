import React, { Component } from 'react'
import { connect } from 'react-redux'
import FaqComponent from './FaqComponent';

export class Faqs extends Component {
  render() {
    const {institutionsInfoData} = this.props;
 
    return (
      <div>
        {
            institutionsInfoData.map((faq, i)=>{
                return(
                    <FaqComponent key={i} title={faq.question} desc={faq.answer} />
                )
            })
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    institutionsInfoData: state.Data.institutionsInfoData
})
export default connect(mapStateToProps)(Faqs)