import React, { Component, Fragment } from 'react'
import { Popover, Transition } from "@headlessui/react";
import { connect } from 'react-redux';
import {getStudentNotes, deleteStudentTracking } from '../../../../actions/WorkerActions'; 
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { changeStateValue } from '../../../../redux/MainReducer';
import StudentNote from '../../../../components/page_component/StudentNote';
function withParams(Component){
    return props => <Component {...props} params={useParams()} />
}
export class Notes extends Component {
    state={
        position: false,
    }
    openModal(){
        this.props.getStudentNotes(this.props.student_id)
        this.setState({
          position:true
        })
    }
    deleteNoteBtn(id){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.props.deleteStudentTracking(`student-note/${id}`)
                .then(resp=>{
                    Swal.fire(
                        resp =='success'? 'Deleted!':"Error!" ,
                        resp =='success'? 'Your note deleted!':"Something went wronge!" ,
                        resp
                      )
                      this.props.getStudentNotes(this.props.props?.student_id)
                })
             
            }
          })
    }
    editNoteBtn(data){
        this.props.changeStateValue({
            name:'studentNoteModal',
            value:{
                position: true,
                student_id: this.props.student_id,
                note: data?.note,
                id: data?.id
            }
        })
    }
  render() {
   const { position} = this.state;
    const {studentNotes} = this.props;

   
  
    return (
        <Popover >
                
            <Popover.Button 
            onClick={this.openModal.bind(this)}
            className={`flex items-center justify-between px-4  w-full h-11 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
            position ? "!border-primary-500 " : ""
            }`}
            >
            <span aria-label='unirepRoleTranslate.finance'>{'Notes'}</span>
            <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
        
            {
            position?
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
            <div id='popover_container' className={''}>     
            <Popover.Panel className=" z-10 w-screen max-w-sm px-4 mt-3 right-0 sm:px-0 lg:max-w-md">
                
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                    
                <StudentNote  student_id={this.props.student_id} />
                  
                   
                    <div className="relative flex flex-col px-5 py-6 space-y-5 " style={{ maxHeight: '300px', overflow: 'auto' }}>
                        {
                            studentNotes?.map((data, i)=>{
                                return(
                                    <div  className='note_card'>
                                        <p style={{ fontSize:'14px', wordBreak:'break-all' }} dangerouslySetInnerHTML={{ __html: data?.note}}></p>
                                        <div className='flex items-center justify-between mt-3'>
                                            <button onClick={this.deleteNoteBtn.bind(this, data.id)} style={{fontSize: '13px'}} className='border rounded-2xl bg-red-700 text-white text-sm p-1 pl-2 pr-2'>Delete</button>
                                            <button onClick={this.editNoteBtn.bind(this, data)} style={{fontSize: '13px'}} className='border rounded-2xl bg-blue-700 text-white text-sm p-1 pl-2 pr-2'>Edit</button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            studentNotes?.length == 0?
                            <p className='text-center'>There is not a note</p>:null
                        }

                    </div>
                </div>
            </Popover.Panel></div>
            </Transition>:null
            }
            

    </Popover>
    )
  }
}
const mapStateToProps = (state) =>({
    studentNotes: state.Data.studentNotes,
});
const mapDispatchToProps = {getStudentNotes, deleteStudentTracking, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(Notes))




