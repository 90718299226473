import React, { Component, Fragment } from 'react'
import { Popover, Transition } from "@headlessui/react";
import { connect } from 'react-redux';
import {addStudentFinance, getFinances, deleteData } from '../../../../actions/WorkerActions'; 
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import DeleteSvg from './DeleteSvg';
import EditBtn from './EditBtn';
function withParams(Component){
    return props => <Component {...props} params={useParams()} />
}
export class FinanceBtn extends Component {
    state={
        position: false,
        activeBtn: 'outs',
        amount: '',
        title: '',
        btnVisible: false,
        errorMsgTitle: false,
        errorMsgAmount: false,
        id: '',
        file: ''
    }
    openModal(){
        this.setState({
          position:true
        })
        this.props.getFinances(this.props.params.id?this.props.params.id:this.props.student_id)
    }
    changeBtn(type, e){
        this.setState({
            activeBtn: type
        })
    }

    componentDidMount(){
        this.props.getFinances(this.props.params.id?this.props.params.id:this.props.student_id)
    }
   
    changeInpValue(e){
        this.setState({
            [e.target.name]: e.target.value,
            errorMsg: false
        })

      
        if(e.target.name == 'amount'){
            this.setState({
                errorMsgAmount: false
            })
        }
        if(e.target.name == 'title'){
            this.setState({
                errorMsgTitle: false
            })
        }
    }
    
    
    deleteDocument(id, e){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                let url = `student-finance/${id}`;
                this.props.deleteData(url)
                .then(resp=>{
                    this.props.getFinances(this.props.params.id?this.props.params.id:this.props.student_id)
                    Swal.fire(
                        'Deleted!',
                        'Your data has been deleted.',
                        'success'
                    )
                })
              
            }
          })
    }

    addFnancesBtn(title, amount, type, id,file, e){
        if(title != '' && amount !== ''){
            this.setState({
                btnVisible: true
            })
            let formData = new FormData();
            formData.append('title', title);
            formData.append('amount', amount);
            formData.append('type', type);
            formData.append('file', file);
            formData.append('student_id', this.props.params.id?this.props.params.id:this.props.student_id);
            let url = 'student-finance'
            if(id != ''){
                url = `student-finance/${id}`;
                formData.append('_method', 'put')
            }
           
            this.props.addStudentFinance(url, formData)
            .then(resp=>{
                this.setState({
                    title: '',
                    amount: '',
                    btnVisible: false,
                    file: ''
                })
                this.props.getFinances(this.props.params.id?this.props.params.id:this.props.student_id)

            })
        }else{
            if(amount == ''){
                this.setState({
                    errorMsgAmount: true
                })
            }
            if(title == ''){
                this.setState({
                    errorMsgTitle: true
                })
            }
        }
    }

    editBtn(data){
        this.setState({
            title: data.title,
            amount: data.amount,
            id: data.id
        })
    }

    getTotalAmount(data, type){
        let total = 0;
        data.map(item=>{
            if(item.type == type){
                total+= +item.amount
            }
        })

        return total;
    }
    getFeeAmount(x){
        let y = +x;
       
        var parts = y.toFixed(2).toString().split(".");
        parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");
        return parts.join(",");
    }

    getRemaining(ins, outs){
        return +ins - +outs
    }
    uploadFile(e){
        this.setState({
            file: e.target.files[0]
        })
    }
    clearInp(){
        this.setState({
            title: '',
            amount: '',
            id: ''
        })
    }
  render() {
   const { position, activeBtn, title, amount, btnVisible, errorMsgTitle, errorMsgAmount, id, file} = this.state;
    const { user, studentFinances, unirepRoleTranslate, studentFinanceLock, commissionStatus} = this.props;

   
  
    return (
        <Popover >
                
            <Popover.Button 
            onClick={this.openModal.bind(this)}
            className={`flex items-center justify-between px-4  w-full h-11 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
            position ? "!border-primary-500 " : ""
            }`}
            >
            <span aria-label='unirepRoleTranslate.finance'>{unirepRoleTranslate?.finance}</span>
            <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
        
            {
            position?
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
            <div id='popover_container' className={this.props.params.id?`right-0`: ''}>     
            <Popover.Panel className=" z-10 w-screen max-w-sm px-4 mt-3 right-0 sm:px-0 lg:max-w-md">
                
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                    <div className='flex p-1 space-x-1 bg-primary-900/10 rounded-xl mt-1 ml-1 mr-1'>
                    <button onClick={this.changeBtn.bind(this, 'outs')}
                        className={`w-full py-2.5 text-sm leading-5 font-medium text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                        ${
                            activeBtn == 'outs'?
                            'bg-white dark:bg-neutral-800':
                            ' hover:bg-white/[0.15] dark:hover:bg-neutral-800'
                        }
                        `}
                        aria-label="unirepRoleTranslate.expenses"
                        >{unirepRoleTranslate?.expenses} </button>
                        <button onClick={this.changeBtn.bind(this, 'incomes')}
                        className={`w-full py-2.5 text-sm leading-5 font-medium text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                        ${
                            activeBtn == 'incomes'?
                            'bg-white dark:bg-neutral-800':
                            ' hover:bg-white/[0.15] dark:hover:bg-neutral-800'
                        }
                        
                        `}
                        aria-label="unirepRoleTranslate.incomes"
                        >{unirepRoleTranslate?.incomes} </button>
                        <button onClick={this.changeBtn.bind(this, 'commission')}
                        className={`w-full py-2.5 text-sm leading-5 font-medium text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                        ${
                            activeBtn == 'commission'?
                            'bg-white dark:bg-neutral-800':
                            ' hover:bg-white/[0.15] dark:hover:bg-neutral-800'
                        }
                        
                        `}
                        aria-label="unirepRoleTranslate.incomes"
                        >Commission</button>
                       
                        
                    </div>
                    {
                      studentFinanceLock == '1' && activeBtn !== "commission"?
                        <div className='flex mt-3 mx-3 gap-3 items-center'> 
                            <div className="w-full" >
                                <input style={{ border: errorMsgTitle?'1px solid red' : ''}} value={title} type={'text'} className="w-full" name="title" onChange={this.changeInpValue.bind(this)} aria-label="unirepRoleTranslate.title" placeholder={unirepRoleTranslate?.title} />    
                            </div>
                            <div className="w-30" >
                                <input value={amount} style={{ border: errorMsgAmount?'1px solid red' : ''}} type={'number'} className="w-full" name="amount" onChange={this.changeInpValue.bind(this)} placeholder="0,000.0" />    
                            </div>
                            <label htmlFor='uploadDocumnt' className='mt-1 cursor-pointer'>
                                <svg width={23} fill={file == ''? '': '#4f46e5'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456c13.3 0 24-10.7 24-24s-10.7-24-24-24s-24 10.7-24 24s10.7 24 24 24z"/>
                                </svg>
                            </label>
                            <input accept='image/png, image/jpeg, image/jpg, application/pdf' type={'file'} onChange={this.uploadFile.bind(this)}  id="uploadDocumnt" style={{ display: 'none' }} />
                                                
                            <button disabled={btnVisible} onClick={this.addFnancesBtn.bind(this,title, amount, activeBtn, id, file)}  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 
                                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '14px', color: 'white' ,  }}>
                           {id != ''? 'Update':'Add'} 
                            </button>
                            {
                                id != ''?
                                <button disabled={btnVisible} onClick={this.clearInp.bind(this,title, amount, activeBtn, id, file)}  className='border rounded-full p-1 pl-3 pr-3' style={{ fontSize: '19px',   }}>
                            x
                            </button>:null
                            }
                            
                            
                           
                        </div>:null
                    }
                  
                    <div className="relative flex flex-col px-5 py-6 space-y-5 " style={{ maxHeight: '300px', overflow: 'auto' }}>
                        <ul>
                            {
                                studentFinances.map((data, i)=>{
                                    if(data.type == activeBtn){
                                        return(
                                            <li key={i} className='finance_list'>
                                                <span className='flex gap-1 items-center justify-center'>{this.getFeeAmount(data.amount)}
                                                    <svg style={{ marginTop: '-2px', marginRight:'10px' }} xmlns="http://www.w3.org/2000/svg" fill='white' width={10} viewBox="0 0 384 512">
                                                         <path d="M192 32c-17.7 0-32 14.3-32 32v34.7C69.2 113.9 0 192.9 0 288v160c0 17.7 14.3 32 32 32s32-14.3 32-32V288c0-59.6 40.8-109.8 96-124v284c0 17.7 14.3 32 32 32s32-14.3 32-32V164c55.2 14.2 96 64.3 96 124v160c0 17.7 14.3 32 32 32s32-14.3 32-32V288c0-95.1-69.2-174.1-160-189.3V64c0-17.7-14.3-32-32-32z" />
                                                    </svg>
                                                </span>
                                                <p className='flex items-center'>{data.title}</p>
                                                <div className='flex finance_btn_container gap-2 ml-3 items-center' >
                                                {
                                                        studentFinanceLock == '1'?
                                                    <button onClick={this.deleteDocument.bind(this, data.id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 448 512" >
                                                            <path d="M135.2 17.7 128 32H32C14.3 32 0 46.3 0 64s14.3 32 32 32h384c17.7 0 32-14.3 32-32s-14.3-32-32-32h-96l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32l21.2 339c1.6 25.3 22.6 45 47.9 45h245.8c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                                        </svg>
                                                    </button>:null}
                                                    {
                                                        studentFinanceLock == '1'?
                                                        <button onClick={this.editBtn.bind(this, data)}>
                                                            <EditBtn />
                                                        </button>:null
                                                    }
                                                    
                                                    {
                                                        data.file?
                                                        <a  target="_blank" href={data.file.file_full_url}>
                                                            <svg fill={'#4f46e5'} width={19} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zM432 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"/>
                                                            </svg>
                                                        </a>:
                                                        null
                                                    }
                                                    
                                                </div>
                                            </li>
                                        )
                                    }
                                })
                            }
                          
                           
                        </ul>
                    </div>
                    {
                        activeBtn != 'commission'?
                        <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                        
                        <p  className='total_text'>Total: {this.getFeeAmount(this.getTotalAmount(studentFinances, activeBtn))}</p>     
                        {
                            activeBtn == 'outs'?
                            <p className='total_text'>Remaining: {this.getFeeAmount(this.getRemaining(this.getTotalAmount(studentFinances, 'incomes'), this.getTotalAmount(studentFinances, 'outs')))} </p>:
                            <p></p>

                        }   
                    </div>:null
                    }
                    {
                        activeBtn == 'commission'?
                        <div className='ml-4 mb-4 flex gap-3 items-center'>
                            <input onChange={()=>this.props.addComissionBtn()} defaultChecked={commissionStatus} id='getComCheck' type='checkbox' />
                            <label className='text-lg font-semibold cursor-pointer' htmlFor='getComCheck'>Will receive commission?</label>
                        </div>:null
                    }
                    
                </div>
            </Popover.Panel></div>
            </Transition>:null
            }
            

    </Popover>
    )
  }
}
const mapStateToProps = (state) =>({
    languageId: state.Data.languageId,
    user: state.Data.user,
    studentFinances: state.Data.studentFinances,
    unirepRoleTranslate: state.Data.unirepRoleTranslate,
    studentFinanceLock: state.Data.studentFinanceLock,
});
const mapDispatchToProps = {addStudentFinance, getFinances, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(FinanceBtn))

