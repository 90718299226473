import React, { Component } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {getInstitutionData} from '../../actions/MainAction'
import Button from './country_data_components/Button';
import AboutContainer from './institution_data_components/AboutContainer';
import FaqsContainer from './institution_data_components/FaqsContainer';
import GoogleMap from './institution_data_components/GoogleMap';
import YoutubeContainer from './institution_data_components/YoutubeContainer';
import {changeStateValue} from '../../redux/MainReducer'
import Modal from './institution_data_components/Modal';
import {getInstitutionFaq} from '../../actions/WorkerActions';
import {mainDashboardTranslate, getUnirepRoleTranslate} from '../../actions/TranslateAction';
import Ranking from './institution_data_components/Ranking';
import Loading from '../../components/page_component/Loading';

function withHooks(Component){
    return props => <Component {...props} params={useParams()} />
}
export class InstitutionData extends Component {
    state={
        buttonType:'faq'
    }
   componentDidMount(){
    window.scrollTo(0, 0)

        this.props.getInstitutionData(this.props.languageId, this.props.params.id)
        this.props.getInstitutionFaq( this.props.params.id, this.props.languageId)

        this.props.mainDashboardTranslate(this.props.languageId)
        this.props.getUnirepRoleTranslate(this.props.languageId);
   }
   changeData = (item) =>{
    this.setState({
        buttonType: item
    })

   }
   showModal(){
    const institutionInfoModal={
        id: '',
        position: true,
        institution_faqs:{
            institution_id: this.props.params.id,
            type: this.state.buttonType
        },
        lang_id: 1,
        question: '',
        answer: '',
        active: '1'
    };
    this.props.changeStateValue({
        name: 'institutionInfoModal',
        value: institutionInfoModal
    })
   }
  render() {
    const {buttonType} = this.state;
    const {institutionData, institutionInfoModal, institutionsInfoData, loader, loaderLong,}  = this.props;

    return (
      <div>
        <div className="container relative space-y-24 mb-24 lg:space-y-10 lg:mb-28">
            <h1 style={{ textAlign: 'center', fontSize: '25px', fontWeight: '600' }}>{institutionData?.name}</h1>
            <div className='flex gap-5'>
                <Button title="FAQs" className={buttonType == 'faq'? 'bg-primary-6000': ''} type="faq" changeData={this.changeData} />
                <Button title="About" className={buttonType == 'about'? 'bg-primary-6000': ''} type="about" changeData={this.changeData} />
                <Button title="YouTube" className={buttonType == 'youtube'? 'bg-primary-6000': ''} type="youtube" changeData={this.changeData} />
                <Button title="Google Map" className={buttonType == 'map'? 'bg-primary-6000': ''} type="map" changeData={this.changeData} /> 
                <Button title="Ranking" className={buttonType == 'ranking'? 'bg-primary-6000': ''} type="ranking" changeData={this.changeData} /> 
            </div>

            {
                buttonType == 'faq'?
                <div className='flex justify-end'>
                    <button onClick={this.showModal.bind(this)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>Add</button>
                </div>:null
            }

            {
               buttonType == 'faq'?
               <FaqsContainer data={institutionsInfoData} />:
               buttonType == 'about'?
               <AboutContainer data={institutionData}/>:
               buttonType == 'youtube'?
               <YoutubeContainer data={institutionData} />:
               buttonType == 'map'?
               <GoogleMap data={institutionData} />:
               buttonType == 'ranking'?
               <Ranking data={institutionData} />:null
            }
            {
                institutionInfoModal.position?
                <Modal />:null
            }
        </div>
        {
             loader || loaderLong? 
             <Loading />:null
            
           }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    institutionData: state.Data.institutionData,
    languageId: state.Data.languageId,
    institutionInfoModal: state.Data.institutionInfoModal,
    institutionsInfoData: state.Data.institutionsInfoData,
    loader: state.Data.loader,
    loaderLong: state.Data.loaderLong,
});
const mapDispatchToProps = {getInstitutionData, changeStateValue, getInstitutionFaq, mainDashboardTranslate, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(InstitutionData))