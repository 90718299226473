import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../../redux/MainReducer';
import { insertData } from '../../../../actions/WorkerActions';
import { mixinAlert } from '../../../../actions/Alerts';
export class Footer extends Component {
    state={
        btnVisible: false
    }

    saveData(newCountryData, required, e){
      let permission = true;
      for(const data of required){
        if(newCountryData[data] == ''){
          permission = false
        }
      }
      if(permission){
        let formData = new FormData();
        for(const property in newCountryData ){
          if(newCountryData[property])
          formData.append(property, newCountryData[property])
        }
        let url = this.props.url;
        if(newCountryData.id != ''){
          formData.append('_method', "put");
          url = `${this.props.url}/${newCountryData.id}`
        }
        this.setState({btnVisible: true})
        this.props.insertData(url, formData)
        .then(resp=>{
          this.setState({btnVisible: false})
          if(resp == 'success'){
            mixinAlert('success', `Data ${newCountryData.id == ''? 'added': 'edited'} successfully`)
            this.closeModal();
            this.props.getAfterChange()
          }else{
            mixinAlert('error', "Something went wronge. Please try again later")
          }
        
        })
      }
    }

    closeModal(){
        this.props.changeStateValue({
          name: 'newCountryData.position',
          value: false
        })
    }
  render() {
    const {btnVisible} = this.state;
    const {required,newCountryData} = this.props
    return (
        <div className='flex justify-between'>
            <button disabled={btnVisible} onClick={this.closeModal.bind(this)} className='text-sm bg-red-700 text-white rounded-2xl pl-2 pr-2 pt-1 pb-1'>Close</button>
            <button disabled={btnVisible} onClick={this.saveData.bind(this, newCountryData, required)} className='text-sm bg-blue-700 text-white rounded-2xl pl-2 pr-2 pt-1 pb-1'>Save</button>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  newCountryData: state.Data.newCountryData
});
const mapDispatchToProps = {changeStateValue,insertData}
export default connect(mapStateToProps, mapDispatchToProps)(Footer)