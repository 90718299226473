import React, { Component } from 'react'
import EditProgram from './EditProgram';

export class EditMassPrgoram extends Component {
  state={
    visible:false
  }

  getSelectedprog(programs, id){
    const check = programs?.find(item=> item?.program_id == id);
    
    if(check){
      return true;
    }else{
      return false;
    }
  }
  render() {
    const {programData, setSelectedDataAll, allProgramSelected, selectUnselectProg} = this.props;
    const {visible} = this.state;
    return (
      <div className='border rounded-xl p-2 mt-3'>
        
   
          <EditProgram 
            programData={{ ...programData }} 
            gpa={this.props.gpa}
            appFee={this.props.appFee}
            gpaPercentage={this.props.gpaPercentage}
            examPercentage={this.props.examPercentage}
            reqEduDegree={this.props.reqEduDegree}
            currencyData={this.props.currencyData}
            feeAmountAll={this.props.feeAmountAll}
            feeType={this.props.feeType}
            programFormat={this.props.programFormat}
            schoolarship_option={this.props.schoolarship_option}
            schoolarship_type={this.props.schoolarship_type}
            startDates={this.props.startDates}
            deadlines={this.props.deadlines}
            studyDuration={this.props.studyDuration}
            a_level={this.props?.a_level}
            a_level_percentage={this.props?.a_level_percentage}
            advanced_placement={this.props?.advanced_placement}
            ap_percentage={this.props?.ap_percentage}
            ib_diploma={this.props?.ib_diploma}
            ib_percentage={this.props?.ib_percentage}
            ossd={this.props?.ossd}
            ossd_percentage={this.props?.ossd_percentage}
            examCheck={this.props?.examCheck}
            setSelectedDataAll={setSelectedDataAll}
            allProgramSelected={allProgramSelected}
            selectUnselectProg={selectUnselectProg}
          />
        
      </div>
    )
  }
}

export default EditMassPrgoram
