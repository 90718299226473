import React, { Component } from 'react'
import { connect } from 'react-redux'
// import io from "socket.io-client";
// import { NODE_API } from '../../../MAIN_API';
// const socket = io.connect(NODE_API);
import { changeStateValue } from '../../../redux/MainReducer'
export class ChatHeader extends Component {
  getMsgHeader(msg, typing){
    if(typing == ''){
      return  msg?.groupName
    }else if(msg.program_id == typing.program_id && msg.student_id == typing.student_id && typing.user_id != this.props.user.id){
      return 'typing...'
    }else{
      return  msg?.groupName
    }
  }
  closeMsgBtn(){
    this.props.changeStateValue({
      name: 'messagePage',
      value: {
        groupName: '',
        userName: '',
        program_id: '',
        student_id: '',
        messages: []
      }
    })
  }
  render() {
    // get-typing
    const {messagePage, typingMsg} = this.props;
    
    return (
      <div className='flex justify-between bg-white rounded-[14px_14px_0_0] h-[90px] items-center p-[10px] '>
        <div className='flex gap-4 items-center'>
          {
            messagePage?.image != ''?
            <img className='w-10 h-10 rounded-3xl' src={messagePage?.image} />:
            <img className='w-10 h-10 rounded-3xl' src='https://i.pinimg.com/280x280_RS/2e/45/66/2e4566fd829bcf9eb11ccdb5f252b02f.jpg'  />
          }
            <div className='msg_header_user_name'>
                <h4>{messagePage?.userName}</h4>
                <p>{
                  this.getMsgHeader(messagePage,typingMsg )
                 
                }</p>
                {
                  messagePage?.uniName && messagePage?.uniName != ''?
                   <p>{messagePage?.uniName}</p>:null
                }
               
             
            </div>
           
        </div>
        {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 475.851 475.851"
                style={{
                enableBackground: "new 0 0 475.851 475.851",
                }}
                xmlSpace="preserve"
                width={24}
                height={24}
            >
                <path d="M151.549 145.274c0 23.39 9.145 50.385 24.462 72.214 17.389 24.78 39.376 38.427 61.911 38.427 22.534 0 44.521-13.647 61.91-38.428 15.317-21.828 24.462-48.824 24.462-72.213 0-47.626-38.746-86.372-86.372-86.372s-86.373 38.746-86.373 86.372zm86.373-71.372c39.354 0 71.372 32.018 71.372 71.372 0 20.118-8.33 44.487-21.74 63.598-14.29 20.364-32.38 32.043-49.632 32.043-17.252 0-35.342-11.679-49.632-32.043-13.41-19.11-21.741-43.479-21.741-63.598 0-39.355 32.018-71.372 71.373-71.372z" />
                <path d="M302.372 239.167a7.502 7.502 0 0 0-8.52 1.461c-16.775 16.728-36.117 25.569-55.935 25.569-19.821 0-39.158-8.841-55.923-25.567a7.5 7.5 0 0 0-8.521-1.463c-25.254 12.022-46.628 30.829-61.811 54.388-15.596 24.2-23.84 52.277-23.84 81.195v.121c0 2.116.894 4.134 2.461 5.556 40.492 36.722 92.922 56.945 147.633 56.945s107.141-20.224 147.632-56.945a7.499 7.499 0 0 0 2.462-5.556v-.121c0-28.918-8.242-56.995-23.834-81.194-15.179-23.561-36.551-42.368-61.804-54.389zm-64.454 183.205c-49.861 0-97.685-18.023-135.057-50.827.583-24.896 7.956-48.986 21.411-69.865 12.741-19.77 30.322-35.823 51.058-46.676 18.746 17.157 40.285 26.193 62.588 26.193 22.3 0 43.842-9.035 62.598-26.193 20.734 10.853 38.313 26.906 51.053 46.676 13.452 20.877 20.823 44.968 21.406 69.865-37.373 32.804-85.197 50.827-135.057 50.827z" />
                <path d="M455.077 243.89c-13.23-20.532-31.856-36.923-53.864-47.399a7.501 7.501 0 0 0-8.52 1.461c-14.312 14.271-30.79 21.815-47.654 21.815-9.142 0-18.184-2.205-26.873-6.553a7.5 7.5 0 0 0-6.712 13.414c10.793 5.401 22.093 8.139 33.586 8.139 19.335 0 38.004-7.737 54.288-22.437a116.088 116.088 0 0 1 43.141 39.685c11.445 17.763 17.756 38.243 18.338 59.416-18.524 16.158-40.553 28.449-63.91 35.634a7.5 7.5 0 0 0 4.41 14.338c26.509-8.154 51.435-22.362 72.082-41.088a7.499 7.499 0 0 0 2.462-5.556v-.105c-.001-25.204-7.185-49.674-20.774-70.764zM130.493 210.473c7.93 0 15.841-1.934 23.516-5.748a7.5 7.5 0 0 0-6.675-13.433c-5.582 2.774-11.248 4.18-16.841 4.18-14.541 0-29.836-9.914-41.964-27.2-11.449-16.318-18.562-37.112-18.562-54.266 0-33.375 27.152-60.527 60.526-60.527 15.752 0 30.67 6.022 42.006 16.958a7.499 7.499 0 0 0 10.604-.19 7.499 7.499 0 0 0-.19-10.604c-14.146-13.647-32.763-21.163-52.42-21.163-41.646 0-75.526 33.881-75.526 75.527 0 20.38 7.957 43.887 21.283 62.881 15.195 21.657 34.459 33.585 54.243 33.585z" />
                <path d="M61.034 340.143a175.333 175.333 0 0 1-45.989-29.004c.582-21.112 6.875-41.53 18.291-59.243a115.795 115.795 0 0 1 43.01-39.566c16.239 14.662 34.856 22.376 54.139 22.376 11.587 0 22.969-2.785 33.829-8.277a7.501 7.501 0 0 0-6.77-13.386c-8.742 4.421-17.846 6.663-27.059 6.663-16.811 0-33.238-7.522-47.504-21.754a7.501 7.501 0 0 0-8.521-1.462c-21.954 10.451-40.534 26.8-53.733 47.28C7.167 264.811 0 289.221 0 314.362v.103c0 2.116.894 4.134 2.461 5.556 15.629 14.174 33.338 25.579 52.634 33.897a7.481 7.481 0 0 0 2.966.615 7.503 7.503 0 0 0 2.973-14.39zM69.854 351.003c-2.671 6.443 4.532 12.832 10.617 9.387 3.238-1.834 4.683-5.937 3.227-9.385-2.407-6.145-11.378-5.952-13.844-.002z" />
                <path d="M83.698 351.005c.19.45-.18-.46 0 0zM303.345 70.438c11.336-10.936 26.254-16.958 42.006-16.958 33.374 0 60.526 27.152 60.526 60.527 0 17.154-7.112 37.947-18.563 54.266-12.128 17.286-27.424 27.2-41.964 27.2-5.593 0-11.259-1.406-16.841-4.18a7.5 7.5 0 0 0-6.676 13.433c7.675 3.814 15.587 5.748 23.517 5.748 19.783 0 39.048-11.927 54.243-33.585 13.327-18.994 21.283-42.501 21.283-62.881 0-41.646-33.881-75.527-75.526-75.527-19.657 0-38.273 7.516-52.42 21.163a7.5 7.5 0 0 0 10.415 10.794z" />
            </svg> */}
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384.97 384.97"
              style={{
                enableBackground: "new 0 0 384.97 384.97",
              }}
              width={24}
              xmlSpace="preserve"
              className='msg_back_btn'
              onClick={this.closeMsgBtn.bind(this)}
            >
              <path d="M192.485 0C86.185 0 0 86.185 0 192.485 0 298.797 86.173 384.97 192.485 384.97S384.97 298.797 384.97 192.485C384.97 86.185 298.797 0 192.485 0zm0 361.282c-92.874 0-168.424-75.923-168.424-168.797S99.611 24.061 192.485 24.061s168.424 75.55 168.424 168.424-75.55 168.797-168.424 168.797z" />
              <path d="M235.878 99.876c-4.704-4.74-12.319-4.74-17.011 0l-83.009 84.2c-4.572 4.62-4.584 12.56 0 17.191l82.997 84.2c4.704 4.74 12.319 4.74 17.011 0 4.704-4.752 4.704-12.439 0-17.191l-74.528-75.61 74.54-75.61c4.692-4.741 4.692-12.428 0-17.18z" />
            </svg>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  messagePage: state.Data.messagePage,
  typingMsg: state.Data.typingMsg,
  user: state.Data.user,
})
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ChatHeader)