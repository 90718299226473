import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CheckFilter from './CheckFilter'
import FilterRange from './FilterRange'
import MobileFilter from './MobileFilter'
import Filter from '../uni_filter/Filter'
export class TabFilters extends Component {
  
  render() {
    const {user, mainTransateData, type = '', getAfterFilter} = this.props;
   
    return (
       
            <div className="filter_btn items-center">
              {
                user !== '' && user?.role == 'uni_rep' ||  user !== '' && user?.role == 'content'?
                <Link to={`/institution/create`} className='ttnc-ButtonThird text-neutral-700 border 
                                    border-neutral-200 dark:text-neutral-200 
                                    dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                    items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                    px-4 py-1 sm:px-3' style={{ fontSize: '14px', fontWeight: '400' }} aria-label="mainTransateData.add_inst" >
                    {mainTransateData?.add_inst}
                </Link>:null
              }
                
                {/* <CheckFilter getAfterFilter={getAfterFilter} type={type} /> */}
                
                <Filter  getAfterFilter={getAfterFilter} />
                {/* <FilterRange /> */}
            </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user,
  mainTransateData: state.Data.mainTransateData,
})
export default connect(mapStateToProps)(TabFilters)