import React, { Component } from 'react'
import { connect } from 'react-redux'
import BgGlassmorphism from '../../../components/page_component/BgGlassmorphism'
import SectionHeader from '../../../components/page_component/SectionHeader'
import { getEduConsultantParent } from '../../../actions/WorkerActions'
import { mainDashboardTranslate, getUnirepRoleTranslate } from '../../../actions/TranslateAction'
import { changeStateValue } from '../../../redux/MainReducer'
import { Link } from 'react-router-dom'
import avatar1 from '../../../images/default.jpg'
import EditSvg from '../../../components/page_component/EditSvg'
import EditModal from '../EditModal'
import SkeletonCard from '../../../components/page_component/SkeletonCard'
export class Parents extends Component {
  
    componentDidMount(){
        window.scrollTo(0, 0)

        this.props.mainDashboardTranslate(this.props.languageId)
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.getEduConsultantParent()
    }
    getAfterEdit = () =>{
        this.props.getEduConsultantParent()
    }
    editSvgBtn = (data) =>{

        this.props.changeStateValue({
            name: 'editUserState',
            value:{
                position: true,
                id: data.id,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                phone_number: data.phone_number,
                password: ''
            }
        })
    }
  render() {
 
    const {eduConsultantParents, editUserState, mainTransateData} = this.props;
  
    return (
        <div className="nc-PageHome relative overflow-hidden">
            <BgGlassmorphism />
            <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
                <div className="relative py-16">
                    <div className={`nc-SectionGridFilterCard pb-24 lg:pb-28`} data-nc-id="SectionGridFilterCard" >
                    
                        <SectionHeader arialLabelTitle="mainTransateData.parent_title" title={mainTransateData?.parent_title} />
                       

                        <div  className={`grid mt-10 gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 `} >

                        
                       {
                        eduConsultantParents?.map((data, i)=>{
                            return(
                                <div key={i}
                                        className={`nc-CardAuthorBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}
                                        data-nc-id="CardAuthorBox"
                                    >
                                          <div className='w-5 absolute left-3 top-3' >
                                            <EditSvg editSvgBtn={this.editSvgBtn.bind(this, data)} />
                                        </div>
                                        <div className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full w-20 h-20 text-2xlring-1 ring-white dark:ring-neutral-900`}>
                                            <img
                                            className={`absolute inset-0 w-full h-full object-cover rounded-full`}
                                            src={data.image_full_url&& data.image_full_url != '' ? data.image_full_url:avatar1}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <h2 className={`text-base font-medium`}>
                                                <span className="line-clamp-1">{data.full_name}</span>
                                            </h2>
                                            <span className={`block mt-1.5  text-sm text-neutral-500 dark:text-neutral-400 wrap`} >
                                            {data.email.length > 20? `${data.email.substring(0, 20)}...`:data.email}
                                            </span>
                                            <div className={"p-4 space-y-4"}>
                                               <hr />
                                                <ul>
                                                    {
                                                        data?.parent_student?.map((student, j)=>{
                                                            return(
                                                                <li style={{ fontSize:'13px', lineHeight: '14px', marginTop:'5px', borderBottom: '1px dashed gray' }} key={j}>{student?.students?.full_name?.length>19?`${student?.students?.full_name?.substring(0, 19)}...`:student?.students?.full_name}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                        </div>
                                                
                                        </div>
                                        {/* <div className="flex justify-between gap-5">
                                            <Link onClick={this.resetData.bind(this, data.id)} to={`/check-info/${data.id}`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                            Apply 
                                            </Link>
                                            <button onClick={this.registerStudent.bind(this, data.id)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                            Add Parent 
                                            </button>
                                        </div>     */}
                                    </div>
                               
                            )
                        })
                       }
                            
                            {
                        eduConsultantParents?.length == 0?
                        <>
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        </>:null
                        } 
                     
                        
                        </div>
                    </div>
                </div>
            </div>
            {
                editUserState.position?
                <EditModal getAfterEdit={this.getAfterEdit} />:null
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
    eduConsultantParents: state.Data.eduConsultantParents,
    languageId: state.Data.languageId,
    user: state.Data.user,
    editUserState: state.Data.editUserState,
    mainTransateData: state.Data.mainTransateData
});
const mapDispatchToProps = {getEduConsultantParent,changeStateValue, mainDashboardTranslate, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(Parents)

