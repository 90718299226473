import React, { Component } from 'react'
import { connect } from 'react-redux'
import SvgDown from '../../../components/page_component/simulator_components/SvgDown'
import SvgUp from '../../../components/page_component/simulator_components/SvgUp'
import SliderRange from './SliderRange'
import { getExams,  } from '../../../actions/WorkerActions'
import { changeStateValue } from '../../../redux/MainReducer'
export class ExamsInputAll extends Component {
    state={
        visible: false,
        titleRef: React.createRef(),
        selectedData: [],
        examsData: []
    }
    changeVisible(visible, e){
     
        this.setState({
            visible: !visible
        })
    }

    componentDidMount(){
        window.addEventListener('click', (e)=>{
           if(this.state.visible && this.state.titleRef.current){
                if(!this.state.titleRef.current.contains(e.target)){
                    this.setState({
                        visible: false
                    })
                }
           }
            
        })
       

        this.props.getExams();
    }   
    selectData(exams,   index ,e){
    
        if(exams[index].visible){
            exams[index].visible = false;
            exams[index].over_all = '';
            exams[index].percentage = this.props.examPercentage;
            this.setState({
                examsData: exams
            })
        }else{
            exams[index].visible = true;
            this.setState({
                examsData: exams
            })
        }
       this.props.setExams(exams, this.props.name)
        
    }
    changePercentage = (value, index, exams) =>{
        // let exams = this.state.examsData;
        exams[index].percentage  = value;
        this.setState({
            examsData: exams
        })
       this.props.setExams(exams, this.props.name)
    }
    deleteItem = (index, exams)=>{
        // let exams = this.state.examsData;
        exams[index].percentage  = 0;
        exams[index].over_all  = '';
        exams[index].visible  = false;
        this.setState({
            examsData: exams
        })
       this.props.setExams(exams, this.props.name)
    }

    getSelectedValue(exam_id, exams){
        if(exams.find(exam=> exam.exam_id == exam_id)?.visible){
            return true
        }else{
            return false
        }
    
    }
    changeOverAll = (index, value, exams) =>{
        // let exams = this.state.examsData;
        exams[index].over_all  = value;
       
        this.setState({
            examsData: exams
        })
       this.props.setExams(exams, this.props.name)
    }
  render() {
    const {visible, titleRef, selectedData, examsData} = this.state;
    const {allExams, editProgram, value, type = ''} = this.props;
 
    let examsOption = [];
    let selectExams = [];
    for(const proprty of allExams){
        examsOption.push({
            exam_type: proprty.exam_type,
            exam_id: proprty.id,
            sub_sections: proprty.sub_sections,
            all_sub_sections: proprty.sub_sections,
            over_all: '',
            percentage: this.props.examPercentage,
            visible: false
        })
    }
    if(examsData.length == 0 && value.length === 0){
        selectExams = [...examsData]
    }
    if(examsData.length == 0){
        
        
        for(const exam of allExams){
            const findExam = value.find(data=> data.exam_id == exam.id);
            if(findExam){
                if(type !== '' && type == 'dublicate'){
                    selectExams.push({
                     
                        exam_type: findExam.exam.exam_type,
                        // approve_id: findExam.approve_id,
                        exam_id: findExam.exam_id,
                        sub_sections: findExam.sub_sections,
                        all_sub_sections: findExam.sub_sections,
                        over_all: findExam.over_all,
                        percentage:this.props.examPercentage,
                        visible: true
                    })
                }else{
                    selectExams.push({
                        id: findExam.id,
                        exam_type: findExam.exam.exam_type,
                        approve_id: findExam.approve_id,
                        exam_id: findExam.exam_id,
                        sub_sections: findExam.sub_sections,
                        all_sub_sections: findExam.sub_sections,
                        over_all: findExam.over_all,
                        percentage:this.props.examPercentage,
                        visible: true
                    })
                }
                
            }else{
                selectExams.push({
                    exam_type: exam.exam_type,
                    exam_id: exam.id,
                    sub_sections: exam.sub_sections,
                    all_sub_sections: exam.sub_sections,
                    over_all: '',
                    percentage: this.props.examPercentage,
                    visible: false
                })
            }
        }
        // selectExams = examsOption

    }else if(examsData.length !== 0 ){
        selectExams = examsData
    }


    return (
        <div >
        <div  ref={titleRef}>
            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
            {'Exams'}
            </label>
            <div className='border rounded-2xl input_select_more h-11 '>
            <div onClick={this.changeVisible.bind(this, visible)} className='select_more_title'>
                <h3 >{'Exams'}</h3>
                {
                    visible?
                    <SvgUp />:
                    <SvgDown />
                }
                
            </div>
            {
                visible?
                <ul className='filter_checkbox_container border rounded-2xl'>
                   {
                    examsOption.map((exam, i) => {
                        return(
                            <li key={i}>
                                <label htmlFor={exam.exam_type} >
                                    <div >
                                        <input checked={this.getSelectedValue(exam.exam_id, selectExams)} id={exam.exam_type} onChange={this.selectData.bind(this, selectExams, i)} type={'checkbox'} />
                                        <span>{exam.exam_type}</span>
                                    </div>
                                </label>
                            </li>
                        )
                    })
                   }
                                
                           
                </ul>:null
            }
            
            </div>
        </div>
   
        
        <div className="flow-root mt-5">
            {
                selectExams.map((exam, i)=>{
                    if(exam.visible){
                        return(
                            <SliderRange exams={selectExams} perValue={this.props.examPercentage} value={exam.over_all} changeOverAll={this.changeOverAll} deleteItem={this.deleteItem} changePercentage={this.changePercentage} key={i} index={i} title={exam.exam_type} />
                        )
                    }
                })
            }
            
        </div>
        
    </div>
    )
  }
}
const mapStateToProps = (state)=>({
    allExams: state.Data.allExams,
    editProgram: state.Data.editProgram
});
const mapDispatchToprops = {getExams, changeStateValue}
export default connect(mapStateToProps, mapDispatchToprops)(ExamsInputAll)

