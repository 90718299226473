import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

export class DisciplineCard extends Component {
  render() {
    const {item, translate, translateLabel, user, editDataBtn} = this.props
    return (
        <Link 
        to={`/discipline/${item.discipline_id}`}
        className={`nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}
        data-nc-id="CardCategoryBox1"
        >
        <span className={`nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs text-gray-800 bg-gray-100  absolute right-2 top-2`}>
            {item?.programs}
            </span>
        <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
          
            <div className={`nc-NcImage absolute inset-0`}  data-nc-id="NcImage"  >
              
                <img src={item?.discipline?.image_full_url} className={'object-cover w-full h-full'}  />
               
            </div>
        </div>
        <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="text-base font-medium">
            <span className="line-clamp-3">{item.name}</span>
            </h2>
            <span  className={`block mt-2 text-sm text-neutral-500 dark:text-neutral-400`} >
              {item?.scholarship} <span aria-label={translateLabel}>{translate}</span>
            </span>
            {
              user != '' && user.role == 'uni_rep' ||  user != '' && user.role == 'content'?
              <button onClick={(e)=>{e.preventDefault(); editDataBtn(item)}} className='border rounded-xl p-2 text-xs'>Edit</button>:null
            }
            
        </div>
    </Link>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user
})
export default connect(mapStateToProps)(DisciplineCard)