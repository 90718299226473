import React, { Component } from 'react'
import { connect } from 'react-redux'
// import Background from '../components/page_component/Background'
import BgGlassmorphism from '../components/page_component/BgGlassmorphism'
import Countries from '../components/page_component/countries/Countries'
import { mainDashboardTranslate, getUnirepRoleTranslate } from '../actions/TranslateAction'
export class Country extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
    this.props.mainDashboardTranslate(this.props.languageId)
    this.props.getUnirepRoleTranslate(this.props.languageId);
  }
  render() {
    return (
        <div className="nc-PageHome relative overflow-hidden">
            <BgGlassmorphism />
            <div className="container relative space-y-24  lg:space-y-28 ">
                {/* <SectionHero /> */}
                <div className="relative ">
                    {/* <Background /> */}
                    <Countries page={20} btn={'load_more'} />
                </div>
            </div>

        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  languageId: state.Data.languageId
});
const mapDispatchToProps = {mainDashboardTranslate, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(Country)