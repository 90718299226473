import React, { Component } from 'react'

export class Inp extends Component {
  render() {
    const {name, value, title, changeInpValue=()=>{}, type="text"} = this.props;
    return (
        <div>
            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
            {title}
            </label>
            <input name={name} type={type} onChange={(e)=>changeInpValue(e)} 
                className={`block w-full border-neutral-200 focus:border-primary-300 
                focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                placeholder={title}
                value={value}
            /> 
        </div>
    )
  }
}

export default Inp
