import React, { Component } from 'react'
import { Dialog } from "@headlessui/react";
import NextPrev from './NextPrev';

export class ModalPhotos extends Component {
    state={
        completeButtonRef: React.createRef()
    }

    onClickNextPrev = (position, currentItem) => {
        if(position == 'next'){
            if(currentItem<this.props.imgs.length-1){
                currentItem++;
            }else{
                currentItem = 0
            }
        }else{
            if(currentItem>0){
                currentItem--
            }else{
                currentItem = this.props.imgs.length-1
            }
        }

        this.props.changeImage(currentItem)
    }
  render() {
    const {completeButtonRef} = this.state;
    const {isOpen = true, onClose, imgs = [], currentItem = 0} = this.props;
 
    return (
        <Dialog
        initialFocus={completeButtonRef}
        as="div"
        className="fixed inset-0 z-max overflow-y-auto dark bg-neutral-800 text-neutral-200 hiddenScrollbar"
        onClose={()=>onClose()}
        open={isOpen}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-white dark:bg-neutral-800" />
          <div
            ref={completeButtonRef}
            className="absolute left-2 top-2 md:top-4 md:left-4 z-max"
          >
           
            <button
                className={
                    `w-8 h-8 flex items-center justify-center rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:w-11 sm:h-11 `}
                onClick={()=>onClose()}
                >
                <span className="sr-only">Close</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>

                {/* <XIcon className="w-5 h-5" /> */}
                </button>
          </div>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="relative inline-block w-full max-w-5xl py-8 h-screen align-middle mx-auto">
           {/*  */}
           <div
        className={`modalPhotos-single-gallery ModalPhotos_single_glide_nc-ListingStayDetailPage-modalPhotos group relative flex flex-col z-50 w-full h-full`}
      >
        {/*  */}
        <div
          className="controls_nav glide__bullets mt-8 mb-5"
          data-glide-el="controls[nav]"
        >
          
          <div  className="text-center ">
              <span className="text-3xl font-semibold"> {currentItem+1}</span>
              <span> / {imgs.length}</span>
            </div>
        </div>
        {/*  */}

            <div
                className="glide__track max-h-full h-full relative z-50"
                data-glide-el="track"
                >
                <ul className="glide__slides h-full">
                    {imgs.map((item, index) =>{
                        if(index == currentItem){
                           return(
                            <li className="glide__slide relative h-full" key={index}>
                                <div className={`nc-NcImage w-full h-full`}  data-nc-id="NcImage">
                                    <img src={item.image_full_url} className={'absolute object-contain w-full max-h-screen'} />
                                </div>
                            </li>
                           )
                        }
                    })}
                </ul>
                </div>
                {/*  */}
                <div className="xl:absolute z-20 xl:-inset-x-20 max-w-6xl my-2 mx-auto top-full xl:top-1/2 transform xl:-translate-y-1/2 flex xl:justify-between glide__arrows">
                <NextPrev
                    onClickNextPrev={this.onClickNextPrev}
                    onlyPrev
                    className="mr-1.5"
                    btnClassName="w-8 h-8 sm:w-10 sm:h-10 "
                    currentItem={currentItem}
                />
                <NextPrev
                    onClickNextPrev={this.onClickNextPrev}
                    onlyNext
                    className="ml-1.5"
                    btnClassName="w-8 h-8 sm:w-10 sm:h-10 "
                    currentItem={currentItem}
                />
                </div>
            </div>

           {/*  */}
          </div>
        </div>
      </Dialog>
    )
  }
}

export default ModalPhotos