import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
export class CloseIcon extends Component {
    closeModal(){
        this.props.changeStateValue({
            name: 'simulatorLogin',
            value: false
        })
        this.props.closeModalBtn()
    }
  render() {
    return (
      <div className='flex justify-end mt-3 mb-3 mr-5'>
        <span className="sr-only">Close</span>
        <svg onClick={this.closeModal.bind(this)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5 cursor-pointer"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                
      </div>
    )
  }
}
const  mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(CloseIcon)