import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAplliedPrograms } from '../../../actions/WorkerActions';
export class AllUsers extends Component {
    componentDidMount(){
        this.props.getAplliedPrograms('0', this.props.languageId)
    }
  render() {
    const {appliedPrograms, joinChatRoom, search, user} = this.props;
  
    return (
        <div className='usr_list_cont' style={{ height: '60vh', overflow: 'auto' }}>
        <ul className='messages_user_list'>
          {
            appliedPrograms.map((item, i)=>{
                if(search.trim() == '' || (item?.student?.full_name.toLowerCase().includes(search.trim().toLowerCase()) || item?.program?.name.toLowerCase().includes(search.trim().toLowerCase())))
              return(
                <li key={i} onClick={()=>joinChatRoom(item.student_id, item.program_id,user != '' && user?.role != 'student'? item?.student?.full_name:item?.program?.name,user != '' && user?.role != 'student'? item?.program?.name:item?.program?.institution,  user != '' && user?.role != "student"?
                item?.student?.image_full_url != ''?item?.student?.image_full_url:'https://i.pinimg.com/280x280_RS/2e/45/66/2e4566fd829bcf9eb11ccdb5f252b02f.jpg':item?.program?.logo,  user != '' && user?.role == "edu_consultant"?item?.program?.institution:'','', item?.student?.finance_lock)}>
                  {
                    user != '' && user?.role != "student"?
                      item?.student?.image_full_url != ''?
                      <img src={item?.student?.image_full_url} />:
                      <img src='https://i.pinimg.com/280x280_RS/2e/45/66/2e4566fd829bcf9eb11ccdb5f252b02f.jpg' alt="User" />:
                      <img src={item?.program?.logo} alt={item?.program?.institution} />
                    }
                  <div className='msg_user_info'>
                    <p>
                      {
                        user != '' && user?.role != "student"?
                        <span className='msg_pr_name'>{item?.student?.full_name}</span>:
                        <span className='msg_pr_name'>{item?.program?.name}</span>
                      }
                     
                      {/* <span className='msg_time'>{moment(new Date(item.date)).format('HH:mm')}</span> */}
                    </p>
                    <p>
                      {
                        user != '' && user?.role != "student"?
                        <span className='msg_user_name'>{item?.program?.name}</span>:
                        <span className='msg_user_name'>{item?.program?.institution}</span>
                      }
                      
                    </p>
                    {
                           user != '' && user?.role == "edu_consultant"?
                           <span className='msg_user_name'>{item?.program?.institution?.length >30? `${item?.program?.institution.substring(0, 30)}...`:item?.program?.institution}</span>:null
                        }
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    languageId: state.Data.languageId,
    appliedPrograms: state.Data.appliedPrograms,
    user: state.Data.user,
});
const mapDispatchToProps = {getAplliedPrograms}
export default connect(mapStateToProps, mapDispatchToProps)(AllUsers)