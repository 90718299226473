import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadMoreBtn from '../../../page_component/LoadMoreBtn';
import SectionHeader from '../../../page_component/SectionHeader';
import MainCard from '../components/MainCard';
import {mainDashboardTranslate, getUnirepRoleTranslate} from '../../../../actions/TranslateAction'
export class States extends Component {
    state={
        page: 1
    }
    componentDidMount(){
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.mainDashboardTranslate(this.props.languageId);
    }
    loadMoreFunction=(page) =>{
        this.setState({
            page: page
        })
    }
  render() {
    const {states, mainTransateData, editData}=this.props;
    const {page} = this.state
    return (
        <div className={`nc-SectionSliderNewCategories `}>
            <div className={`class="SectionSliderNewCategories__PageHome_s2nc__rv_ flow-root glide--ltr glide--slider glide--swipeable`}>
            <SectionHeader arialLabelDesc="mainTransateData.state_desc" arialLabelTitle={'mainTransateData.state_title'} title={mainTransateData?.state_title} description={mainTransateData?.state_desc} />
            <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides mini_grid_container" >
                {states.map((item, index) => {
                    if(index < 5*page){
                    return(
                        <MainCard 
                            url={`/state/${item.state_id}`} 
                            title={item.name} 
                            image={item?.state?.image_full_url} 
                            key={index}
                            uniCount={item?.universities}
                            editData={editData}
                            item={item}
                            type={'state'}
                         />
                    )
                    }
                })}
                </ul>
            </div>
            {
                states.length >5?
                <LoadMoreBtn loadMoreFunction={this.loadMoreFunction} position={[...states].splice(0, page*5)?.length} count={5}  />:null
            }
            
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    languageId: state.Data.languageId,
    mainTransateData: state.Data.mainTransateData

});
const mapDispatchToProps = {mainDashboardTranslate, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(States)