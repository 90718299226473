import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AutoConpleteText from '../../../components/page_component/AutoConpleteText'
import Slider from './Slider'

export class MainCard extends Component {
    getInstName(name){
        if(name){
            if(name.length > 29){
                return name.substring(0, 29)+ "..."
            }else{
                return name
            }
        }
    }
    getFeeType(type){
        if(type){
            return +type === 0? 'Yearly': 'Monthly'
        }
    }
   
  render() {
    const {program, status, student_id, user, unirepRoleTranslate} = this.props
    return (
        <div  className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow`}
        data-nc-id="StayCard"  >
            <Slider pending={unirepRoleTranslate?.pending} statusText={unirepRoleTranslate?.status} accepted={unirepRoleTranslate?.accepted} status={status} url={`/program/${program.program_id}`} program={program}  height={'3'} />
                <div className={"p-4 space-y-4"}>
                    <div className="space-y-2">
                        
                        <div className="flex items-center space-x-2">
                        
                        <h2 className={` font-bold capitalize text-lg `}>
                            <AutoConpleteText text={program?.name} />

                            {/* <span className="line-clamp-1">{program.name}</span> */}
                        </h2>
                        </div>
                        <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                       
                            <span className="font-medium mt-0 " style={{ marginTop: '-9px', color:'black', fontWeight:'600' }}>{this.getInstName(program.institution)}</span>
                        </div>
                    </div>
                    {/* <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div> */}
                    <div className="flex justify-between items-center">
                        <span className="text-base font-semibold flex gap-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width={'22px'} viewBox="0 0 640 512">
                            <path d="M623.1 136.9l-282.7-101.2c-13.73-4.91-28.7-4.91-42.43 0L16.05 136.9C6.438 140.4 0 149.6 0 160s6.438 19.65 16.05 23.09L76.07 204.6c-11.89 15.8-20.26 34.16-24.55 53.95C40.05 263.4 32 274.8 32 288c0 9.953 4.814 18.49 11.94 24.36l-24.83 149C17.48 471.1 25 480 34.89 480H93.11c9.887 0 17.41-8.879 15.78-18.63l-24.83-149C91.19 306.5 96 297.1 96 288c0-10.29-5.174-19.03-12.72-24.89c4.252-17.76 12.88-33.82 24.94-47.03l190.6 68.23c13.73 4.91 28.7 4.91 42.43 0l282.7-101.2C633.6 179.6 640 170.4 640 160S633.6 140.4 623.1 136.9zM351.1 314.4C341.7 318.1 330.9 320 320 320c-10.92 0-21.69-1.867-32-5.555L142.8 262.5L128 405.3C128 446.6 213.1 480 320 480c105.1 0 192-33.4 192-74.67l-14.78-142.9L351.1 314.4z"/>
                        </svg>
                            {program.education_degree}
                           
                        
                        </span>
                        <span className="text-base font-semibold flex gap-1">
                            <svg
                            
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 sm:h-6 w-4 sm:w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                            </svg>
                            {moment(program?.program?.deadline).format("DD/MM/YYYY")}
                        </span>
                        
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-base font-semibold flex gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width={'15px'} viewBox="0 0 512 512">
                                <path d="M320 96H192L144.6 24.88C137.5 14.24 145.1 0 157.9 0H354.1C366.9 0 374.5 14.24 367.4 24.88L320 96zM192 128H320C323.8 130.5 328.1 133.3 332.1 136.4C389.7 172.7 512 250.9 512 416C512 469 469 512 416 512H96C42.98 512 0 469 0 416C0 250.9 122.3 172.7 179 136.4C183.9 133.3 188.2 130.5 192 128V128zM276.1 224C276.1 212.9 267.1 203.9 255.1 203.9C244.9 203.9 235.9 212.9 235.9 224V230C230.3 231.2 224.1 232.9 220 235.1C205.1 241.9 192.1 254.5 188.9 272.8C187.1 283 188.1 292.9 192.3 301.8C196.5 310.6 203 316.8 209.6 321.3C221.2 329.2 236.5 333.8 248.2 337.3L250.4 337.9C264.4 342.2 273.8 345.3 279.7 349.6C282.2 351.4 283.1 352.8 283.4 353.7C283.8 354.5 284.4 356.3 283.7 360.3C283.1 363.8 281.2 366.8 275.7 369.1C269.6 371.7 259.7 373 246.9 371C240.9 370 230.2 366.4 220.7 363.2C218.5 362.4 216.3 361.7 214.3 361C203.8 357.5 192.5 363.2 189 373.7C185.5 384.2 191.2 395.5 201.7 398.1C202.9 399.4 204.4 399.9 206.1 400.5C213.1 403.2 226.4 407.4 235.9 409.6V416C235.9 427.1 244.9 436.1 255.1 436.1C267.1 436.1 276.1 427.1 276.1 416V410.5C281.4 409.5 286.6 407.1 291.4 405.9C307.2 399.2 319.8 386.2 323.1 367.2C324.9 356.8 324.1 346.8 320.1 337.7C316.2 328.7 309.9 322.1 303.2 317.3C291.1 308.4 274.9 303.6 262.8 299.9L261.1 299.7C247.8 295.4 238.2 292.4 232.1 288.2C229.5 286.4 228.7 285.2 228.5 284.7C228.3 284.3 227.7 283.1 228.3 279.7C228.7 277.7 230.2 274.4 236.5 271.6C242.1 268.7 252.9 267.1 265.1 268.1C269.5 269.7 283 272.3 286.9 273.3C297.5 276.2 308.5 269.8 311.3 259.1C314.2 248.5 307.8 237.5 297.1 234.7C292.7 233.5 282.7 231.5 276.1 230.3L276.1 224z"/>
                            </svg>
                                {program.currency}
                                {program?.program?.fee_amount}
                                
                        </span>
                        
                        <span className="text-base font-semibold flex gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width={'15px'} viewBox="0 0 448 512">
                                <path d="M448 336v-288C448 21.49 426.5 0 400 0H352v191.1c0 13.41-15.52 20.88-25.1 12.49L272 160L217.1 204.5C207.5 212.8 192 205.4 192 191.1V0H96C42.98 0 0 42.98 0 96v320c0 53.02 42.98 96 96 96h320c17.67 0 32-14.33 32-32c0-11.72-6.607-21.52-16-27.1v-81.36C441.8 362.8 448 350.2 448 336zM384 448H96c-17.67 0-32-14.33-32-32c0-17.67 14.33-32 32-32h288V448z"/>
                            </svg>
                            {program?.program_format}
                        </span>
                    
                    </div>
                    
                <div className="pl-0 pr-0 mt-4 flex items-center justify-between">  
                {
                    user !== '' && user.role == 'uni_rep' || user !== '' && user.role == 'edu_consultant' || user !== '' && user.role == 'parent' ?
                    <Link aria-label='unirepRoleTranslate.view_applicant' to={`/student-information/${student_id}`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                    {unirepRoleTranslate?.view_applicant}
                    </Link>:
                    user !== '' && user.role == 'student'?
                    <Link to={`/application-data`} aria-label="unirepRoleTranslate.view_application" className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                    {unirepRoleTranslate?.view_application}
                    </Link>:null
                }  
                        
                        <Link to={`/program/${program.program_id}`} aria-label="unirepRoleTranslate.view_program" className='ttnc-ButtonThird text-neutral-700 border 
                                            border-neutral-200 dark:text-neutral-200 
                                            dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                            items-center justify-center rounded-full transition-colors
                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                            px-4 py-1 sm:px-3' style={{ fontSize: '12px' }}  >
                            {unirepRoleTranslate?.view_program}
                        </Link>
                        
                    </div>
                </div>
            </div>


    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user,
    unirepRoleTranslate: state.Data.unirepRoleTranslate,
})
export default connect(mapStateToProps)(MainCard)