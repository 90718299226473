import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCountryData, getStateByCountry, getCityByCountry, getInstitutions, getCountriesFAQ } from '../../../actions/MainAction';
import TextComponent from './components/TextComponent';
import States from './states/States';
import Cities from './cities/Cities';
import Universities from './universities/Universities';
import ImageHeader from '../layouts/ImageHeader';
import {mainDashboardTranslate, getUnirepRoleTranslate} from '../../../actions/TranslateAction';
import Buttons from './components/Buttons';
import About from './pages/About';
import Visa from './pages/Visa';
import Working from './pages/Working';
import Faqs from './pages/Faqs';
import CountryHeaderImg from '../layouts/CountryHeaderImg';
import MainModal from '../../modals/MainModal';
import { changeStateValue } from '../../../redux/MainReducer';
function withParams(Component){
    return props => <Component {...props} params={useParams()} />
}
export class SingleCountry extends Component {

  state={
    page: 'universities',
    url: ''
  }
    componentDidMount(){
        window.scrollTo(0, 0)

        this.props.getCountryData(this.props.params.id, this.props.languageId)
        this.props.getStateByCountry(1, this.props.languageId, this.props.params.id)
        this.props.getCityByCountry(20, this.props.languageId, this.props.params.id)
        this.props.getInstitutions(1,'', this.props.languageId, this.props.params.id)
        this.props.getCountriesFAQ(this.props.params.id, this.props.languageId, '')
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.mainDashboardTranslate(this.props.languageId);
    }

    changePage = (page) =>{
      this.setState({
        page: page
      })
    }
    getAfterSubmit=()=>{
      this.props.getCityByCountry(1, this.props.languageId, this.props.params.id)
      this.props.getStateByCountry(1, this.props.languageId, this.props.params.id)
    }
    addNewData=(type, data) => {
      
      this.setState({
        url: `/${type}`
      })
      function getId(){
        if(data == ''){
          return ''
        }else{
          if(type == 'cities'){
            return data.city_id
          }else{
            return data.state_id
          }
        }
      }
      function getImg(){
        if(data == ''){
          return ''
        }else{
          if(type == 'cities'){
            return data.city.image_full_url
          }else{
            return data.state.image_full_url
          }
        }
      }
      this.props.changeStateValue({
        name: 'newCountryData',
        value:{
          position: true,
          id: getId(),
          name:  data.name?data.name: '',
          descriprion: data.description?data.description: '',
          country_id: this.props.params.id,
          state_id: data?.city?.state_id,
          lang_id: 1,
          active: 1,
          translate_id: data.id,
          image: getImg(),
          image_full_url: getImg()
        }
      })
    }
  render() {
    const {countryData, stateByCountry, cityByCountry, institutions, countryFAQ, newCountryData, user} = this.props;
    const {page, url} = this.state;
    let stateOption = [];
    for(const data of stateByCountry){
      stateOption.push({
        label: data.name,
        value: data.state_id
      })
    }
  
    return (
      <div  className={`ListingDetailPage nc-ListingStayDetailPage `} data-nc-id="ListingStayDetailPage">
        {
            countryData !== ''?
            <CountryHeaderImg title={countryData.name} description={countryData.description} image={countryData?.countries?.image_full_url} />:null
        }
        <div className='container relative mt-10'>
          
          <Buttons changePage={this.changePage}/>{
            user != '' && user?.role == "uni_rep"?
            <div className='mt-3 flex gap-2'>
            <button onClick={this.addNewData.bind(this, 'cities', '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a City</button>
            <button onClick={this.addNewData.bind(this, 'state', '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a State</button>
          </div>:null
          }
          
        </div>
        <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
         
          <div className='w-full'>
            {
              page == 'about'?
              <About 
                about={countryData.description} 
                living={countryFAQ.find(data=> data.country_fags.type == 'living')} 
                states={stateByCountry}
                editData={this.addNewData} 
                cities={cityByCountry}/>:
              page == 'universities'?
              <Universities countryId={this.props.params.id} institutions={institutions} />:
              page == 'visa'?
              <>
              {
                countryFAQ?.map((data, i)=>{
                  if(data?.country_fags?.type == 'visa'){
                    return(
                      <Visa key={i} data={data}  />
                    )
                  }
                })
              }
              
              </>
              :
              page == 'working'?
              <>
                {
                   countryFAQ?.map((data, i)=>{
                    if(data?.country_fags?.type == 'working'){
                      return(
                        <Working key={i} data={data}  />
                      )
                    }
                  })
                }
              </>:
              page == 'faq'?
              <Faqs data={countryFAQ}/>:null

            }
          {/* <TextComponent />
          <TextComponent /> */}
          
          {/* {
            institutions.length !== 0?
            <Universities institutions={institutions} />:null
          } */}
        </div>
        </main>
          {
            newCountryData?.position ? <MainModal dropDownOption={url == '/cities'?stateOption:[]} getAfterChange={this.getAfterSubmit} url={url} stateName="newCountryData" modalData={newCountryData} />:null
          }
        
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    countryData: state.Data.countryData,
    languageId: state.Data.languageId,
    stateByCountry: state.Data.stateByCountry,
    cityByCountry: state.Data.cityByCountry,
    institutions: state.Data.institutions,
    countryFAQ: state.Data.countryFAQ,
    newCountryData: state.Data.newCountryData,
    user: state.Data.user,
});
const mapDispatchToProps = { getCountryData,changeStateValue,  getStateByCountry, getCityByCountry, getInstitutions, getCountriesFAQ, mainDashboardTranslate, getUnirepRoleTranslate }
export default connect(mapStateToProps, mapDispatchToProps)(withParams(SingleCountry))