import React, { Component } from 'react'
import { getEduConsultantStudents, insertNewData } from '../../../actions/WorkerActions'
import { connect } from 'react-redux';
import BgGlassmorphism from '../../../components/page_component/BgGlassmorphism';
import SectionHeader from '../../../components/page_component/SectionHeader';
import UserCard from '../../../components/cards/UserCard';
import { changeStateValue } from '../../../redux/MainReducer';
import StudentNewModal from './student_info/StudentNewModal';
import Searchable from 'react-searchable-dropdown'; 
import { getObject } from '../../../actions/makeFilterObj';
import SimulatorModalForEducon from '../../../components/page_component/simulator_components/modal/SimulatorModalForEducon';
import { getStudentInfo } from '../../../actions/MainAction';
import Swal from 'sweetalert2';
export class StaffStudents extends Component {
    state={
        search:'',
        education_year:'2023-2024'
    }
    componentDidMount(){
        this.props.getEduConsultantStudents('', this.state.education_year)
    }

    openModal=(data)=>{
        this.props.changeStateValue({
            name:"studentNewModal",
            value:{
                position:true,
                id: data?.id?data?.id:'',
                first_name: data?.first_name?data?.first_name:'',
                image: data?.image?data?.image:'',
                image_full_url: data?.image_full_url?data?.image_full_url:'',
                last_name: data?.last_name?data?.last_name:'',
                education_year: data?.education_year?data?.education_year:'',
                phone_number: data?.phone_number?data?.phone_number:'',
            }
        })
    }
    onSearchData=(search, e)=>{
        this.setState({search:search})
        e.preventDefault();
        if(search.trim() !==""){
           this.props.getEduConsultantStudents(search, this.state.education_year) 
        }
    }
    onSearch(e){
        this.setState({search:e.target.value});
        if(e.target.value=== ""){
            this.setState({search:""})
            this.props.getEduConsultantStudents("", this.state.education_year)
        }
    }

    selectdata=(data)=>{
        this.setState({education_year:data})
        this.props.getEduConsultantStudents(this.state.search, data) 
    }

    onClickEditBg=(student)=>{
        if(localStorage.getItem('token')){
            // console.log("sss")
            this.props.getStudentInfo(student)
            .then(resp=>{
                this.props.changeStateValue({
                    name: 'simulatorModal',
                    value: true
                  })
              const data = getObject(resp);
              localStorage.setItem('filterExamIds', JSON.stringify(data.examId))
              localStorage.setItem('filterExamGrade', JSON.stringify(data.exams))
              data.obj.student_id = student
              localStorage.setItem('simulator', JSON.stringify(data.obj))
              this.props.changeStateValue({
                name: 'filter',
                value: data.obj
              })
              if(data?.obj?.filterByCountryLevel && data?.obj?.filterByCountryLevel != ''){
                this.props.getCountryEducationDegree(1, data?.obj?.filterStudiedCountry, data?.obj?.filterByLevel)
              }
              this.props.changeStateValue({
                name: 'filterExamGrade',
                value: data.exams
              })
              this.props.changeStateValue({
                name: 'filterExamIds',
                value: data.examId
              })
             
            })
          }
    }
    onClickReject=(id)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it'
          }).then((result) => {
            if (result.isConfirmed) {
                let formData = new FormData();
                formData.append("_method", "put")
                this.props.insertNewData(`/user-reject/${id}`, formData)
                .then(resp=>{
                    this.props.getEduConsultantStudents(this.state.search, this.state.education_year) 
                    if(resp.msg == 'success'){
                        Swal.fire(
                            'Changed!',
                            'The status changed successfully.',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                        )
                    }
                })
            }
          })
    }
  render() {
    const {eduConsultantStudents, mainTransateData, studentNewModal, simulatorModal} = this.props;
    const {search} = this.state;
    let option = [
        {
            label: "2020-2021",
            value: "2020-2021"
        },
        {
            label: "2021-2022",
            value: "2021-2022"
        },
        {
            label: "2022-2023",
            value: "2022-2023"
        },
        {
            label: "2023-2024",
            value: "2023-2024"
        },
        {
            label: "2024-2025",
            value: "2024-2025"
        },
    ];
    // console.log(eduConsultantStudents)
    return (
        <div className="nc-PageHome relative overflow-hidden">
            <BgGlassmorphism />
            <div className="container relative  mb-24 ">
                <div className="relative py-16">
                    {/* <div className={`nc-SectionGridFilterCard `} data-nc-id="SectionGridFilterCard" >
                        <SectionHeader arialLabelTitle={'mainTransateData.student_title'} title={mainTransateData?.student_title} />
                    </div> */}
                    <form onSubmit={this.onSearchData.bind(this, search)} className='flex items-center justify-center'>
                        <input value={search} onChange={this.onSearch.bind(this)} className='w-[550px] h-[50px]' placeholder='Search' type="text" />
                    </form>
                    
                    <div className='flex justify-between mt-[10px] mb-[20px] items-center'>
                        <div>
                            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                            Select Years
                            </label>
                            <div style={{ width: '300px' }} className={`block w-full border-neutral-200  
                                focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                                dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                                dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                            > 
                                <Searchable 
                                    options={option}
                                    onSelect={this.selectdata.bind(this)}
                                    placeholder={"2023-2024"}
                                
                                />
                            </div>
                        </div>
                        <button onClick={this.openModal.bind(this, "")} className='bg-blue-700 p-[2px_5px] text-[12px] text-white rounded-[4px]'>
                            Add a student
                        </button>
                    </div>
                    <div className='grid grid-cols-4 gap-[20px] items-start'>
                    {
                        eduConsultantStudents?.map((data, i)=>{
                            return(
                                <UserCard 
                                    key={i}
                                    name={data?.full_name}
                                    image={data?.image_full_url}
                                    phone={data?.phone_number?data?.phone_number:' '}
                                    // email={data?.email}
                                    info={`/student-info/${data?.id}`}
                                    blank={true}
                                    onClickEdit={this.openModal.bind(this, data)}
                                    onClickEditBg={this.onClickEditBg.bind(this, data?.id)}
                                    rejectBtn={true}
                                    onClickReject={this.onClickReject.bind(this, data?.id)}
                                    rejectStatus={data?.reject_status}
                                />
                            )
                        })
                    }
                    
                </div>
                </div>
                
            </div>
            {
                studentNewModal?.position?
                <StudentNewModal education_year={this.state.education_year} />:null
            }
            {
          simulatorModal?
          <SimulatorModalForEducon />:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    eduConsultantStudents: state.Data.eduConsultantStudents,
    mainTransateData: state.Data.mainTransateData,
    studentNewModal: state.Data.studentNewModal,
    simulatorModal: state.Data.simulatorModal,
});
const mapDispatchToProps = {getEduConsultantStudents,getStudentInfo, changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(StaffStudents)
