import React, { Component } from 'react'
import { connect } from 'react-redux';
import Input from '../../student_pages/components/Input';
import { getParentPersonalInfo, addNewData } from '../../../actions/ParentActions';
import Image from '../../student_pages/components/Image';
import avatar1 from "../../../images/default.jpg";
import { changeStateValue } from '../../../redux/MainReducer';
import RadioType from '../../student_pages/components/RadioType';
export class ProfileData extends Component {
    state={
        image: '',
        buttonVisible: false
    }
    componentDidMount(){
        this.props.getParentPersonalInfo()
    }
    uploadImage(e){
        let reader = new FileReader();
        reader.onload = ()=>{
            this.setState({
                image: reader.result
            })
        }
        this.props.changeStateValue({
            name: 'studentProfileData.image',
            value: e.target.files[0]
        })
        reader.readAsDataURL(e.target.files[0])
    }

    saveChanges(data, user, e){
        this.setState({
            buttonVisible: true
        })
        let formData = new FormData();
     
        for(const property in data){
            formData.append(property, data[property])
        }

        let url = `users/${user.id}`;
        formData.append('_method', 'PUT');
        this.props.addNewData(url, formData)
        .then(resp=>{
            this.setState({
                buttonVisible: false
            });
            // this.props.getParentPersonalInfo()

        })
    }   
  render() {
    const {parentPersonalInfo, user, mainTransateData} = this.props;
    const {image, buttonVisible} = this.state;
   
    return (
        <div>
            {/* <label style={{ cursor: 'pointer' }} htmlFor='stProfileImg'>
            </label> */}
            <div className="relative w-28 h-28 ml-auto mr-auto rounded-full overflow-hidden  flex">
            <Image  url={
                        image == '' && parentPersonalInfo.image_full_url == ''?
                        avatar1:
                        image !== ''?
                        image:
                        parentPersonalInfo.image_full_url !== ''?
                        parentPersonalInfo.image_full_url: avatar1
                
                } />

                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs" aria-label='mainTransateData.change_image'>{mainTransateData?.change_image}</span>
                </div>
                <input
                    onChange={this.uploadImage.bind(this)}
                  type="file"
                  accept='image/jpg, image/png, image/jpeg, image/webp'
                  className="absolute inset-0 opacity-0 cursor-pointer"
                />
              </div>
            <input type={'file'} id="stProfileImg" accept='image/jpg, image/png, image/jpeg, image/webp' style={{ display: 'none' }} />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5 justify-between mt-5">
            
                <Input 
                    ariaLabel="mainTransateData.first_name"
                    label={mainTransateData?.first_name} 
                    type="text" 
                    placeholder={mainTransateData?.first_name} 
                    value={parentPersonalInfo?.first_name} 
                    name="parentPersonalInfo.first_name" />
                <Input 
                    ariaLabel="mainTransateData.last_name"
                    label={mainTransateData?.last_name}
                    type="text" 
                    placeholder={mainTransateData?.last_name}
                    value={parentPersonalInfo?.last_name} 
                    name="parentPersonalInfo.last_name"/>
                <Input 
                    ariaLabel="mainTransateData.email"
                    label={mainTransateData?.email} 
                    type="email" 
                    placeholder={mainTransateData?.email}
                    value={parentPersonalInfo?.email} 
                    name="parentPersonalInfo.email"/>
                <Input 
                    ariaLabel="mainTransateData.phone_number"
                    label={mainTransateData?.phone_number} 
                    type="number" 
                    placeholder={mainTransateData?.phone_number} 
                    value={parentPersonalInfo?.phone_number} 
                    name="parentPersonalInfo.phone_number"/>
              
               
                <Input 
                    ariaLabel="mainTransateData.password"
                    label={mainTransateData?.password} 
                    type="password" 
                    placeholder={mainTransateData?.password}
                    value={parentPersonalInfo?.password} 
                    name="parentPersonalInfo.password"/>

               
            </div>
            <div className="flex justify-center items-center mt-10" >
                  
                    <button aria-label="mainTransateData.update" disabled={buttonVisible} onClick={this.saveChanges.bind(this, parentPersonalInfo, user)}  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                     {mainTransateData?.update}
                    </button>
                </div>
        </div>
        
    )
  }
}
const mapStateToProps = (state) =>({
    parentPersonalInfo: state.Data.parentPersonalInfo,
    user: state.Data.user,
    mainTransateData: state.Data.mainTransateData
});
const mapDispatchToProps = {getParentPersonalInfo,addNewData, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileData)