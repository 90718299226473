import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';
import { connect } from 'react-redux';
import { getObject } from '../../../../actions/makeFilterObj';
import { getWorkerPrograms } from '../../../../actions/WorkerActions';
import { changeStateValue } from '../../../../redux/MainReducer';
import { registerApply,  getPrograms, getStudentInfo, getSimulatorPrograms, getStateByCountry, getcityByCountryAndState, registerSocialsAction, updateStudentDataAfterLogin, getUniByCities } from '../../../../actions/MainAction';
import { useNavigate, useParams } from 'react-router-dom';
import EmailSvg from './EmailSvg';
import AppleIdLogin from '../../AppleIdLogin';
import { GOOGLE_CLIENT_ID } from '../../../../MAIN_API';
function withParams (Component){
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />
}
export class Register extends Component {
    state={
        clientId : GOOGLE_CLIENT_ID,
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        visible: false,
        errorState: false,
        errorStateSocial: false
    }
    redirectAfterRegister(filter, examFilter){
        if(this.props.params.id){
    
            if(localStorage.getItem('token')){
                this.props.getWorkerPrograms(this.props.languageId, this.props.params.id)
            }
        }else{
            if(window.location.pathname == '/programs'){
                if(localStorage.getItem('token')){
                    this.props.getPrograms(1, this.props.languageId, '', '', 'programs')
                   
                }
            }else if(window.location.pathname == '/simulator'){
                if(localStorage.getItem('token')){
                    let formData = new FormData();
                    for(const property in filter){
                        formData.append(property, filter[property])
                    }
                    for(const property in examFilter){
                        formData.append(property, examFilter[property])
                    }
                    this.props.updateStudentDataAfterLogin(formData)
                    .then(resp=>{
                        this.props.changeStateValue({
                            name: 'simulatorLogin',
                            value:false
                        })
                        this.props.getStudentInfo()
                        .then(resp=>{
                          const data = getObject(resp);
                          localStorage.setItem('filterExamIds', JSON.stringify(data.examId))
                          localStorage.setItem('filterExamGrade', JSON.stringify(data.exams))
                          localStorage.setItem('simulator', JSON.stringify(data.obj))
                          this.props.changeStateValue({
                            name: 'filter',
                            value: data.obj
                          })
                          
                          this.props.changeStateValue({
                            name: 'filterExamGrade',
                            value: data.exams
                          })
                          this.props.changeStateValue({
                            name: 'filterExamIds',
                            value: data.examId
                          })
                          this.props.getSimulatorPrograms(data.obj, 1, 'programs')
                          this.props.getStateByCountry(null, this.props.languageId, data.obj.filterByCountry);
                          this.props.getcityByCountryAndState(this.props.languageId, data.obj.filterByCountry, data.obj.filterByState);
                           this.props.getUniByCities(data.obj.filterByCity.split(','), this.props.languageId)
                        })
                    })
                    
                  }
            }
            
        }
    }
     onSuccess = (states, filter, examFilter, res)=>{
        const state = {
            first_name: res.profileObj.givenName? res.profileObj.givenName:'',
            last_name: res.profileObj.familyName? res.profileObj.familyName:'',
            email: res.profileObj.email,
            role_id:3
          }
  
        if(res.profileObj.email){
            
            this.props.registerSocialsAction(state)
            .then(resp=>{
                if(resp === 'success'){
                    
                    this.redirectAfterRegister(filter, examFilter)
                 }else{
                     this.setState({
                         errorStateSocial: true
                     })
                 }
            })
           
            }
        
      }
    changeInpValue(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    registerManual(state, filter, examFilter, e){
        e.preventDefault();
        if(state.first_name !== '' && state.last_name !== '' && state.email !== '' && state.password !== ''){
            let formData = new FormData();
            for(const property in filter){
                formData.append(property, filter[property])
            }
            for(const property in examFilter){
                formData.append(property, examFilter[property])
            }
            for(const property in state){
                formData.append(property, state[property])
            }
            this.props.registerApply(formData)
            .then(resp=>{
                if(resp == 'success'){
                    const x = new Date();
                    const y = x.getTime()
                    
                    this.props.changeStateValue({
                        name: 'confirmEmail',
                        value: {
                            email: btoa(state.email),
                            sentTime: String(x),
                            deadline: String(new Date(y+2 * 60 * 60 * 1000)),
                            path: window.location.pathname
                        }

                        
                    })
                    localStorage.setItem('eKey', btoa(state.email))
                    localStorage.setItem('start', String(x))
                    localStorage.setItem('deadline', String(new Date(y+2 * 60 * 60 * 1000)))
                    localStorage.setItem('path', window.location.pathname)
                    this.props.navigate('/confirm-email')
                }else{
                    this.setState({
                        errorState: true
                    })
                }
                
            })
        }

    }
    showManualInput(visible, e){
        this.setState({
            visible: !visible
        })
    }
  render() {
    const {clientId, first_name, last_name, email, password, visible, errorStateSocial, errorState} = this.state
    const {filter, filterExamGrade, mainTransateData} = this.props;

    return (
     
      <div>
        <h2 aria-label='mainTransateData.sign_up' className="my-2 flex items-center  mb-3 text-3xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            {/* {mainTransateData?.sign_up} */}
            Sign up first to apply
        </h2>
        <div className="grid gap-3" id='google_login'>
        {/* <div className="grid gap-3">
            <AppleIdLogin />
        </div> */}
        <GoogleLogin 
           
            clientId={clientId}
            buttonText="Continue with Google"
            onSuccess={this.onSuccess.bind(this, this.state, filter, filterExamGrade)}
            // onFailure={this.onFailure()}
            cookiePolicy="single_host_origin"
            isSignedIn={false}
            theme='dark'
        />
        {
            errorStateSocial?
            <p style={{ marginTop: '-10px' }} className='invalid_login'>You already have an existing CELT.go account. Login or change your password.</p>:null
        }
        </div>
        <div className="relative text-center">
            <span aria-label='mainTransateData.or' className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                {mainTransateData?.or}
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
        </div>
        <div className='manual_login_btn_container' onClick={this.showManualInput.bind(this, visible)}>
            <EmailSvg />
            <span aria-label='mainTransateData.signup_email'>{mainTransateData?.signup_email}</span>
        </div>
        {
            visible?
            <form className="grid grid-cols-1 gap-6" action="#" method="post">
                <div className='grid grid-cols-2 gap-3'>
                    <label className="block">
                        <span aria-label='mainTransateData.first_name' className="text-neutral-800 dark:text-neutral-200">
                        {mainTransateData?.first_name}
                        </span>
                        
                        <input type={'text'}
                        className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                        placeholder={mainTransateData?.first_name}
                        value={first_name}
                        name="first_name"
                        onChange={this.changeInpValue.bind(this)}
                        />
                    </label>
                    <label className="block">
                        <span aria-label='mainTransateData.first_name' className="text-neutral-800 dark:text-neutral-200">
                            {mainTransateData?.last_name}
                        </span>
                        
                        <input type={'text'}
                        className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                        placeholder={mainTransateData?.last_name}
                        value={last_name}
                        name="last_name"
                        onChange={this.changeInpValue.bind(this)}
                        />
                    </label>
                </div>
                <div className='grid grid-cols-2 gap-3'>
                <label className="block">
                    <span aria-label='mainTransateData.email' className="text-neutral-800 dark:text-neutral-200">
                    {mainTransateData?.email}
                    </span>
                    
                    <input type={'email'}
                    className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                    placeholder="example@example.com"
                    value={email}
                    name="email"
                    onChange={this.changeInpValue.bind(this)}
                    />
                </label>
                <label className="block">
                    <span aria-label='mainTransateData.password' className="text-neutral-800 dark:text-neutral-200">
                    {mainTransateData?.password}

                    </span>
                    
                    <input type={'password'}
                    className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                    placeholder="*********"
                    value={password}
                    name="password"
                    onChange={this.changeInpValue.bind(this)}
                    />
                </label>
                </div>
                {
                    errorState?
                    <p style={{ marginTop: '-20px' }} className='invalid_login'>You already have an existing CELT.go account. Login or change your password.</p>:null
                }
                <button aria-label='mainTransateData.sign_up' onClick={this.registerManual.bind(this, this.state, filter, filterExamGrade)} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>
                    {mainTransateData?.sign_up}
                </button>
               
                {/* <ButtonPrimary type="submit">Continue</ButtonPrimary> */}
            </form>:null
        }
         <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <span aria-label='{mainTransateData.already_account'>{mainTransateData?.already_account}</span> {` `}
            <button style={{ color: 'blue' }}  onClick={()=>this.props.changePage('login')} aria-label="mainTransateData.sign_in">{mainTransateData?.sign_in}</button>
        </span>
      </div> 
    )
  }
}

const mapStateToProps = (state) =>({
    filter: state.Data.filter,
    filterExamGrade: state.Data.filterExamGrade,
    languageId: state.Data.languageId,
    mainTransateData: state.Data.mainTransateData
});
const mapDispatchToProps  ={changeStateValue,updateStudentDataAfterLogin,getUniByCities,  registerApply,  getPrograms, getWorkerPrograms, getStudentInfo, getSimulatorPrograms, getStateByCountry, getcityByCountryAndState, registerSocialsAction}

export default connect(mapStateToProps, mapDispatchToProps)(withParams(Register))