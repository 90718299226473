import React, { Component } from 'react'
import FooterBtns from './FooterBtns';
import { changeStateValue } from '../../../redux/MainReducer';
import {connect} from 'react-redux';
import { getSimulatorPrograms, getStateByCountry, getcityByCountryAndState, updateStudentData, getUniByCities } from '../../../actions/MainAction';
import SvgDown from './SvgDown'
export class CheckBox extends Component {
  state={
    test: ''
  }
  addCheckBox(id,checkBoxValue, e){
    this.setState({
      test: '1'
    })
    this.props.getCheckedValue(id, checkBoxValue);
 
   this.updateData()
  }
  checkChecked(array, id){
    if(array.find(data=> data == id)){
      return true;
    }else{
      return false;
    }
  }
  resetData = () => {
    this.props.resetCheckBox();
    if(localStorage.getItem('simulator')){
      const filterData = JSON.parse(localStorage.getItem('simulator'));
      filterData[this.props.property] = '';
      if(this.props.property == 'filterByCity'){
        this.props.getUniByCities('', this.props.languageId);
          filterData['filterByInstitution'] = ''
      }
      localStorage.setItem('simulator', JSON.stringify(filterData));
      this.props.changeStateValue({
        name: 'filter',
        value: filterData
      })
      if(localStorage.getItem('token')){
        let formData = new FormData();
        formData.append('type',  this.props.property);
        formData.append(this.props.property, '');
        formData.append('student_id', filterData.student_id)

        formData.append('_method', 'put')
        
        this.props.updateStudentData(formData)
        this.props.getSimulatorPrograms(filterData, 1, 'programs')
      }else{
        this.props.getSimulatorPrograms(filterData)
      }
      
      this.props.getStateByCountry(null, this.props.languageId, filterData.filterByCountry);
      this.props.getcityByCountryAndState(this.props.languageId, filterData.filterByCountry, filterData.filterByState);
     
    }
  }
  updateData = () => {
    if(localStorage.getItem('simulator')){
      const filterData = JSON.parse(localStorage.getItem('simulator'));
      
      filterData[this.props.property] = this.props.checkBoxValue.join(',');
      
      localStorage.setItem('simulator', JSON.stringify(filterData));
      this.props.changeStateValue({
        name: 'filter',
        value: filterData
      })
      if(localStorage.getItem('token')){
        let formData = new FormData();
        formData.append('type',  this.props.property);
        formData.append(this.props.property, this.props.checkBoxValue.join(','));
        formData.append('student_id', filterData.student_id)
   
        formData.append('_method', 'put')
        this.props.updateStudentData(formData)
      }
      this.props.getSimulatorPrograms(filterData)
      this.props.getStateByCountry(null, this.props.languageId, filterData.filterByCountry);
      this.props.getcityByCountryAndState(this.props.languageId, filterData.filterByCountry, filterData.filterByState);
     

      if(this.props.property == 'filterByCity'){
        this.props.getUniByCities(this.props.checkBoxValue, this.props.languageId)
      }
    }
    
    
    // console.log(this.props.property, this.props.checkBoxValue);
  } 

 
  render() {
    const {array = [], checkBoxValue=[], property} = this.props;
   
    return (
        <div>
          <ul className='filter_checkbox_container'>
          {
            array.map((data, i)=> {
              return(
                <li key={i} >
                 <label htmlFor={`${property+data.id}`}>
                   <div className='w-full' >
                      <input checked={this.checkChecked(checkBoxValue, data.id)} onChange={this.addCheckBox.bind(this, data.id, checkBoxValue)} id={`${property+data.id}`} type={'checkbox'} />
                      <span className='w-full'>{data.name}</span>
                   </div>
                   {/* <SvgDown /> */}
                 </label>
                 {/* <ul className='checkbox_inner_drop_container'>
                    <li>
                      <label>
                        <div>
                          <input type={'checkbox'} />
                          <span>List</span>
                        </div>
                      </label>
                    </li>
                 </ul> */}
                </li>
              )
            })
          }
          
        </ul>
        <FooterBtns resetData={this.resetData} updateData={this.updateData} />
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
  languageId: state.Data.languageId,
});
const mapDispatchToProps = {changeStateValue, getSimulatorPrograms, getStateByCountry, getcityByCountryAndState, updateStudentData,getUniByCities}
export default connect(mapStateToProps, mapDispatchToProps)(CheckBox)