import axios from "axios"
import { MAIN_API, MAIN_API2 } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";

export const uploadJSON = (data, degreeId) => async dispatch => { 
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API2}/upload-data`, {data:JSON.stringify(data), degreeId:degreeId})
    .then(resp=>{
        // console.log("result", resp.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return "success";
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return "error";
    })
}
export const matchData = (data) => async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API2}/match-uni`, data)
    .then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return "success";
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return "error";
    })
}
export const changeProgramStatus = (url, data) => async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API2}/${url}`, data)
    .then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return "success";
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return "error";
    })
}

export const getAllUniversity = () => async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.get(`${MAIN_API}/public-institution?query_type=all`)
    .then(resp=>{
       dispatch(changeStateValue({
        name:'allInst',
        value: resp?.data
       }))
       dispatch({type: 'MAIN_SLICE/hideLoader'})
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return "error";
    })
}
export const getAllRoboUniversity = () => async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.get(`${MAIN_API2}/get-inst`)
    .then(resp=>{
       dispatch(changeStateValue({
        name:'allRoboInst',
        value: resp?.data
       }))
       dispatch({type: 'MAIN_SLICE/hideLoader'})
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return "error";
    })
}
export const getMatchUni = () => async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.get(`${MAIN_API2}/get-match-uni`)
    .then(resp=>{
       dispatch(changeStateValue({
        name:'matchedUni',
        value: resp?.data
       }))
       dispatch({type: 'MAIN_SLICE/hideLoader'})
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return "error";
    })
}


export const updateRanks = (data) => async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API}/update-all-rankings`, {params:data},{
        headers:{
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        }
    })
    .then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'});
        console.log(resp.data)
        return "success";
    }).catch(err=>{
        console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return "error";
    })
}