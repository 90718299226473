import React, { Component } from 'react'
import { connect } from 'react-redux'

export class FooterBtns extends Component {
  render() {
    const {mainTransateData, updateBtn = false} = this.props;
    return (
        <div className="pl-5 pr-5 mt-4 flex items-center justify-between">         
            <button onClick={()=>this.props.resetData()}  className='ttnc-ButtonThird text-neutral-700 border 
                                border-neutral-200 dark:text-neutral-200 
                                dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                items-center justify-center rounded-full transition-colors
                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                px-4 py-1 sm:px-3' style={{ fontSize: '12px' }} aria-label="mainTransateData.clear" >
                {mainTransateData?.clear}
            </button>
            {
              updateBtn?
              <button  onClick={()=>this.props.updateData()} aria-label="mainTransateData.update" className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color:'white' }}>
                {mainTransateData?.update}
            </button>:null
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  mainTransateData: state.Data.mainTransateData
})
export default connect(mapStateToProps)(FooterBtns)