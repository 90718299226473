import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
export class InputPercentage extends Component {
  changeInpValue(e){
    this.props.changeStateValue({
      name: e.target.name,
      value: e.target.value
    })
  }
  render() {
    const {label = '', value='', placeholder='', name='', perValue='', perName=''} = this.props
    return (
        <div>
            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
            {label}
            </label>
            <div className='multiple_drop_input border rounded-2xl h-11 '>
            <input onChange={this.changeInpValue.bind(this)} name={name} value={value == 0? '': value} placeholder={placeholder} type={'text'} /> 
            <input onChange={this.changeInpValue.bind(this)} placeholder='%' value={perValue == 0? '':perValue} name={perName} className='percentage_inp rounded-2xl' type={'number'} />
        </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToprops = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToprops)(InputPercentage)