import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { changeStateValue } from '../../redux/MainReducer'
import Logo from '../header_component/Logo'
import MenuBar from './MenuBar'
import { logOutAction } from '../../actions/MainAction'
function withParams(Component){
  return props => <Component {...props} navigate={useNavigate()} />
}
export class HeaderSearch extends Component {
    state={
        page:1,
        keyword: '' ,
        filterByCountry: '',
        filterByState:'',
        filterByCity:'',
        filterByStatus:'',
        filterByInstitution:'',
        lang_id: this.props.languageId,
        filterByLevel:'',
        filterByDiscipline:'',
        filterByDuration:'',
        filterByFee: '',
        filterStudiedCountry: '',
        filterByCountryLevel: '',
        filterByGPA: '',
        filterByGradingScheme: '',
        filterByALevel: '',
        filterByOSSD: '',
        filterByIB: '',
        filterByAP: '',
        filterByExtraActivities:'',
        filterByInternationalOlympiads: '',
        gpaConverter:'',
        gradingSchemeId: '',
        filterType: 'program',
        filterByDegree: ''
       
        
    }
    goSimulatorBtn(state, type, e){
        localStorage.removeItem('filterExamIds')
        localStorage.removeItem('filterExamGrade')
        state.filterType = type
        localStorage.setItem('simulator', JSON.stringify(state))
        this.props.changeStateValue({
          name: 'filterExamIds',
          value: []
        })
        this.props.changeStateValue({
          name: 'simulatorType',
          value: type
        })
        localStorage.setItem('simulatorType', type)
        this.props.changeStateValue({
          name: 'filterExamGrade',
          value: {
            ielts: '',
            toefl: '',
            pte: '',
            duolingo: '',
            sat: '',
            gre: '',
            gmat: ''
          }
        })
    
        localStorage.setItem('modalSimulator', 'true')
      }
      logOutFunction(){
        this.props.logOutAction()
        .then(resp=>{
            this.props.navigate('/')
        })
    }
  render() {
    const {mainTransateData, user} = this.props;
    return (
        <div className='nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg shadow-sm dark:border-b dark:border-neutral-700'>
            <div className='nc-MainNav1 relative z-10 '>
              {
                user == ''?
                <>
                  <div className='flex justify-center ' style={{ marginTop: '-10px' }}>
                    <Logo width="150px" />
                  </div>
                  <div className='px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center'>
                      <Link to={'/simulator'} type="button" onClick={this.goSimulatorBtn.bind(this, this.state, 'program')}  className="check_chance_btn border">
                          {mainTransateData?.check_admission}
                      </Link>
                      <Link to={'/simulator'} type="button" onClick={this.goSimulatorBtn.bind(this, this.state, 'scholarship')} className="check_chance_btn border">
                      {mainTransateData?.check_sch}
                      </Link>
                  </div>
                </>:
                <>
                 {/* <div className='flex justify-center ' style={{ marginTop: '-10px' }}>
                    <Logo width="150px" />
                  </div> */}
                   <div className="w-full max-w-lg flex justify-between mx-auto text-sm text-center pl-2">
               
               {
                   user == ''?
                   <Link  to={'/login'}   className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90   text-neutral-900 dark:text-neutral-100  `}  >
                 
                       <svg  style={{ marginBottom: '-14px' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-8 h-8 ">
                           <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z">
                           </path>
                       </svg>
                       <span className="text-[11px] leading-none ">login</span>
                   </Link>:
                    <button onClick={this.logOutFunction.bind(this)}   className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90   text-neutral-900 dark:text-neutral-100  `}  >
                 
                       <svg  style={{ marginBottom: '-14px' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-8 h-8 ">
                           <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z">
                           </path>
                       </svg>
                       <span className="text-[11px] leading-none ">log out</span>
                   </button>
               }
                <Logo width="150px" />
                  <div>
                      <div style={{ marginBottom: '-14px' }}>
                          <MenuBar />
                      </div>
                      <span  className="text-[11px] leading-none ">Menu</span>

                  </div>
              </div>
                </>
              
              }
              
              
            </div>
        </div>
      
    )
  }
}
const mapStateToProps = (state) =>({
    mainTransateData: state.Data.mainTransateData,
    user: state.Data.user
});
const mapDispatchToProps = {changeStateValue, logOutAction}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(HeaderSearch))