import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import { useParams } from 'react-router-dom';
import { getEducationDegree, getPrograms } from '../../actions/MainAction';
import {  getCurrency,  getExtraCurricularActivity, getInternationalMedals, insertNewData, getExams, getEducationLanguage, updateAllPrograms} from '../../actions/WorkerActions';
import Searchable from 'react-searchable-dropdown'; 
import {getUnirepRoleTranslate, mainDashboardTranslate} from '../../actions/TranslateAction';
import EditMassPrgoram from './EditMassPrgoram';
import Inp from './Inp';
import Drop from './Drop';
import InputSelect from '../auto_add/components/InputSelect';
import StudyDuration from '../auto_add/components/StudyDuration';
import HighSchoolExams from '../auto_add/components/HighSchoolExams';
import SelectBox from './SelectBox';
import ExamsInputAll from '../auto_add/components/ExamsInputAll';
import Loading from '../../components/page_component/Loading';
import Swal from 'sweetalert2';

function withHooks(Component){
  return props => <Component {...props} params={useParams()} />
}
export class AutoEditAllPrograms extends Component {
  state={
    programs:[],
    activityData: [],
    olympiadData: [],
    degreeId:'',
    gpa: '',
    appFee:'',
    gpaPercentage: '0',
    examPercentage:'',
    reqEduDegree:'',
    currencyData:'',
    feeAmountAll:'',
    feeType:'0',
    studyDuration:{
      program: {
          study_duration:''
      }
    },
    programHigh:{
      program:{
          a_level:'',
          a_level_percentage:'',
          advanced_placement:'',
          ap_percentage:'',
          ib_diploma:'',
          ib_percentage:'',
          ossd:'',
          ossd_percentage:'',
      }
    },

    programFormat:'',
    schoolarship_option:'', 
    schoolarship_type:'',
    startDates:[''],
    deadlines:[''],
    autoRender:true,
    examCheck:'',
    examsAll:[],
    allProgramSelected:[],
    allDates:[],

    schReqEduDegree: '',
    schGpa:'',
    schGpaPercentage:'',

    schOlympiadData:[],
    schActivityData:[],
    schExamsAll:[],
    programSchHigh:{
      program:{
          a_level:'',
          a_level_percentage:'',
          advanced_placement:'',
          ap_percentage:'',
          ib_diploma:'',
          ib_percentage:'',
          ossd:'',
          ossd_percentage:'',
      }
    },
    schExamPercentage:''
  }
  componentDidMount(){
    this.props.getEducationDegree(1);
    this.props.mainDashboardTranslate(1);
    this.props.getEducationLanguage(1);
    this.props.getExtraCurricularActivity(1);
    this.props.getInternationalMedals(1);
    // this.props.getAllDisciplines(1)
    this.props.getCurrency();
    this.props.getExams();
    this.props.getExtraCurricularActivity(1);
    this.props.getInternationalMedals(1);
  }

  selectFromSystem=( name, data)=>{
    this.setState({[name]:data})
  }

  fetchPrograms(degreeId){
    if(degreeId != ''){
      this.props.getPrograms(100, 1, this.props.params.id, '', 'public-programs', '', '', '', degreeId, '')
      .then(resp=>{this.setState({programs:[...resp]})})
    }
  }

  changeInpValueMulti=(name, value)=>{
    this.setState({[name]:value})
    this.setState({autoRender:false})
    this.setState({allProgramSelected:[]})
    setTimeout(()=>{
      this.setState({autoRender:true})
    }, 100)
  }
  changeInpValueDuration=(name, value)=>{
    this.setState({
        studyDuration:{
            program: {
                study_duration:value
            }
        },
    })
    this.setState({autoRender:false})
    this.setState({allProgramSelected:[]})
    setTimeout(()=>{
      this.setState({autoRender:true})
    }, 100)
  }
  changeInpValueHigh=(name, value)=>{
    let obj = {...this.state.programHigh};
    const check = name.includes('.');
    if(check){
        const groups = name.split('.');
        if(groups.length == 2){
        obj[groups[1]] = value
        }else if(groups.length == 3){
        obj[groups[1]][groups[2]] = value
        }
        
    }else{
        obj[name] = value
    }
    this.setState({
        programHigh:obj
    })
    this.setState({allProgramSelected:[]})
    this.setState({autoRender:false})
    
    setTimeout(()=>{
      this.setState({autoRender:true})
    }, 100)
}
changeInpValueSchHigh=(name, value)=>{
    let obj = {...this.state.programSchHigh};
    const check = name.includes('.');
    if(check){
        const groups = name.split('.');
        if(groups.length == 2){
        obj[groups[1]] = value
        }else if(groups.length == 3){
        obj[groups[1]][groups[2]] = value
        }
        
    }else{
        obj[name] = value
    }
    this.setState({
      programSchHigh:obj
    })
    this.setState({allProgramSelected:[]})
    this.setState({autoRender:false})
    
    setTimeout(()=>{
      this.setState({autoRender:true})
    }, 100)
}

  addStartDate(dates, name){
    let arr = [...dates];
    arr.push('');
    this.setState({[name]: arr})
    this.setState({allProgramSelected:[]})

    this.setState({autoRender:false})
    setTimeout(()=>{
      this.setState({autoRender:true})
    }, 100)
  }

  setStartDate(e, index, dates, name){
    dates[index] = e.target.value;
    this.setState({[name]:dates});
    this.setState({allProgramSelected:[]})

    this.setState({autoRender:false})
    setTimeout(()=>{
      this.setState({autoRender:true})
    }, 100)
  }
  deleteStartDate(index, dates, name){
    dates.splice(index, 1)
    this.setState({[name]: dates})
    this.setState({allProgramSelected:[]})

    this.setState({autoRender:false})
    setTimeout(()=>{
      this.setState({autoRender:true})
    }, 100)
  }
  changeInutpValue=(name, value)=>{
    if(name == 'examPercentage'){
      let newExams = []
      let exams = [...this.state.examsAll];
      for(let i = 0; i < exams.length; i++){
          exams[i].percentage = value;
          newExams.push(exams[i])
      }
      this.setState({examsAll: newExams})
    }
    if(name == 'schExamPercentage'){
      let newExams = []
      let exams = [...this.state.schExamsAll];
      for(let i = 0; i < exams.length; i++){
          exams[i].percentage = value;
          newExams.push(exams[i])
      }
      this.setState({schExamsAll: newExams})
    }
    if(name == 'gpaPercentage'){
      this.setState({
        programHigh: {
          program:{
            ...this.state.programHigh?.program,
              a_level_percentage:value,
              ap_percentage:value,
              ib_percentage:value,
              ossd_percentage:value,
          }
        }
      })
    }else if(name == 'schGpaPercentage'){
      this.setState({
        programSchHigh: {
          program:{
            ...this.state.programSchHigh?.program,
              a_level_percentage:value,
              ap_percentage:value,
              ib_percentage:value,
              ossd_percentage:value,
          }
        }
      })
    }
    this.setState({[name]:value})
    this.setState({allProgramSelected:[]})

    this.setState({autoRender:false})
    setTimeout(()=>{
      this.setState({autoRender:true})
    }, 100)
  } 
  setExtraActivity = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  setExams=(exams, name)=>{
       
    let exmass = [];
    for(let i = 0; i < exams.length; i++){
        if(exams[i].visible){
            exmass.push({
                ...exams[i],
                exam: {exam_type: exams[i]?.exam_type},
                percentage: this.state.examPercentage
            })
        }
    }
    this.setState({examsAll: exmass})
    this.setState({allProgramSelected:[]})

    this.setState({autoRender:false})
    setTimeout(()=>{
        this.setState({autoRender:true})
        
    }, 100)
  }
  setSchExams=(exams, name) =>{
    let exmass = [];
    for(let i = 0; i < exams.length; i++){
        if(exams[i].visible){
            exmass.push({
                ...exams[i],
                exam: {exam_type: exams[i]?.exam_type},
                percentage: this.state.schExamPercentage
            })
        }
    }
    this.setState({schExamsAll: exmass})
    this.setState({allProgramSelected:[]})

    this.setState({autoRender:false})
    setTimeout(()=>{
        this.setState({autoRender:true})
        
    }, 100)
  }

  setSelectedDataAll=(program, dates) => {
    let arr = this.state.allProgramSelected;
    arr.push(program)
    this.setState({allProgramSelected: arr})
    this.setState({allDates: dates})
  }

  addAllBtn(programs, extAct, intOlymp, exams){
    const obj = {
      programs, 
      extAct, 
      intOlymp, 
      exams,
      dates: this.state.allDates,
      sch:{
        ...this.state.programSchHigh.program,
        required_education_level:this.state.schReqEduDegree,
        gpa: this.state.schGpa,
        gpa_percentage: this.state.schGpaPercentage,
        exams: this.state.schExamsAll,
        intOlymp: this.state.schOlympiadData,
        extAct: this.state.schActivityData,
        
      }
    }

    this.props.updateAllPrograms(JSON.stringify(obj))
    .then(resp=>{
      if(resp == 'success'){
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        
        Toast.fire({
          icon: 'success',
          title: 'Update  successfully'
        })
      }else{
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        
        Toast.fire({
          icon: 'error',
          title: 'update error'
        })
      }
    })
  }

  selectUnselectProg=(id, program, dates)=>{
    let programs = this.state.allProgramSelected;
    const idnex = programs.findIndex(item=> item.program_id == id);
    if(idnex != -1){
      programs.splice(idnex, 1)
    }else{
      programs.push(program)
    }
    this.setState({allProgramSelected: programs})
  }
  render() {
    const { educationDegree, currency , unirepRoleTranslate, extraCurricularActivity, internationalMedals,loader , loaderLong} = this.props;
    const {degreeId, gpa, appFee,programs,programSchHigh,schExamPercentage, gpaPercentage,activityData,  schOlympiadData, schActivityData, olympiadData,examsAll, schReqEduDegree,schGpa,schGpaPercentage, examPercentage,autoRender,allProgramSelected,  examCheck, reqEduDegree, currencyData, feeAmountAll, feeType, studyDuration, programHigh, schoolarship_option, schoolarship_type, startDates, deadlines, programFormat} = this.state;
    const degreeOption = [];
    const currencyOption = [];
    const activityOption=[];
    const olympiadOption=[];
    
    for(const property of educationDegree){
        degreeOption.push({
          label: property?.education_type,
          value: property?.education_degree_id
        })
    }
    for(const property of currency){
      currencyOption.push({
        label: property?.currency,
        value: property?.id
      })
    }
    let fee_type_options = [
    
      {
        label: unirepRoleTranslate?.yearly,
        value: '0'
      },
      {
        label: unirepRoleTranslate?.monthly,
        value: '1'
      },
    ]
    let program_format = [
      {
          label: unirepRoleTranslate?.online,
          value: unirepRoleTranslate?.online
      },
      {
          label: unirepRoleTranslate?.full_time,
          value: unirepRoleTranslate?.full_time
      },
      {
          label: unirepRoleTranslate?.part_time,
          value: unirepRoleTranslate?.part_time
      },
      {
          label: unirepRoleTranslate?.nsu,
          value: unirepRoleTranslate?.nsu
      },
    ]
    let scholarship_type =[
      {
          label: unirepRoleTranslate?.in_amount,
          value: 'amount'
      },
      {
          label: unirepRoleTranslate?.in_percentage,
          value: 'percentage'
      }
    ]
    for(const property of internationalMedals){
      olympiadOption.push({
        label: property?.title,
        value: property?.olympiad_id
      })
    }
    for(const property of extraCurricularActivity){
      activityOption.push({
        label: property?.title,
        value: property?.activity_id
      })
    }

    return (
      <div className='container '>
          <div className='flex items-center gap-3'>
                  <div >
                    <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                       Select Education Degree
                    </label>
                    <div style={{ width: '300px' }} className={`block w-full border-neutral-200  
                        focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                        dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                        dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                    > 
                        <Searchable 
                            options={degreeOption}
                            onSelect={this.selectFromSystem.bind(this, 'degreeId')}
                            placeholder={"Select Education Degree"}
                            
                        />
                    </div>
                </div>
                <button className='bg-blue-500 p-2 rounded-2xl text-white mt-5' onClick={this.fetchPrograms.bind(this, degreeId)}>Fetch programs</button>
          </div>
          <div>
            <div  className='mt-7  grid grid-cols-4 gap-3'>
              <Inp name="gpa" value={gpa} title={'GPA For All'} changeInpValue={(e)=>this.changeInutpValue(e.target.name, e.target.value)} type="number" />
              <Inp name="appFee" value={appFee} title={'App Fee'} changeInpValue={(e)=>this.changeInutpValue(e.target.name, e.target.value)} type="number" />
              <Inp name="gpaPercentage" value={gpaPercentage} title={'GPA % for all'} changeInpValue={(e)=>this.changeInutpValue(e.target.name, e.target.value)} type="number" />
              <Inp name="examPercentage" value={examPercentage} title={'Exam % for all'} changeInpValue={(e)=>this.changeInutpValue(e.target.name, e.target.value)} type="number" />
              <Drop name="reqEduDegree" option={degreeOption} title="Req Edu Degree for All"  selectDropData={(name, value)=>this.changeInutpValue(name, value)} />
              <Drop name="currencyData" option={currencyOption} title="Currency for All"  selectDropData={(name, value)=>this.changeInutpValue(name, value)} />
              <InputSelect
                  label={'Fee Amunt'}
                  value={feeAmountAll}
                  name="feeAmountAll"
                  placeholder={'Fee Amount'}
                  selectValue={feeType}
                  selectName={'feeType'}
                  options={fee_type_options}
                  changeInpValue={this.changeInpValueMulti}
                  
                />
                <StudyDuration editProgram={studyDuration} changeInpValue={this.changeInpValueDuration} value={studyDuration?.program?.study_duration} />
                <Drop name="programFormat" option={program_format} title="Program Format"  selectDropData={(name, value)=>this.changeInutpValue(name, value)} />
                <InputSelect 
                    label={"Sch Opt. For All"}
                    value={schoolarship_option}
                    name="schoolarship_option"
                    placeholder={"Sch Opt. For All"}
                    selectValue={schoolarship_type}
                    selectName={'schoolarship_type'}
                    options={scholarship_type}
                    changeInpValue={this.changeInpValueMulti}
                  />
            </div>
            <div  className='mt-3 mb-12 grid grid-cols-3 gap-3 items-start'>
             <HighSchoolExams  changeInpValue={this.changeInpValueHigh} editProgram={programHigh}/>
             <div className='mt-11 rounded-xl flex  items-center gap-3 border p-2'>
                <input onChange={(e)=>this.changeInutpValue('examCheck', e.target.checked?1:0)} id='allProgrExamCheck' type='checkbox' />
                {/* examCheck */}
                <label className='cursor-pointer' htmlFor='allProgrExamCheck'>Exam check</label>
             </div>
            
            </div>
            <div  className="grid grid-cols-1 md:grid-cols-3 gap-12 md:gap-12 mt-5">
                <SelectBox idName="activity_id" value={[]} setExtraActivity={this.setExtraActivity} name="activityData" options={activityOption} label={unirepRoleTranslate?.extra_act} />
                <SelectBox idName="olympiad_id" value={[]} setExtraActivity={this.setExtraActivity} name="olympiadData" options={olympiadOption} label={unirepRoleTranslate?.olymp_medal} />
                <ExamsInputAll examPercentage={examPercentage} value={[]} name="exams" setExams={this.setExams} />
              </div>

            <div className='border rounded-xl p-2'>
              <h3 className='text-blue-700 cursor-pointer'>Scholarship requirements</h3>
              <div className='mt-7  grid grid-cols-4 gap-3'>
                <Drop name="schReqEduDegree" option={degreeOption} title="Sch Req Edu Degree for All"  selectDropData={(name, value)=>this.changeInutpValue(name, value)} />
                <Inp name="schGpa" value={schGpa} title={'Sch GPA For All'} changeInpValue={(e)=>this.changeInutpValue(e.target.name, e.target.value)} type="number" />
                <Inp name="schGpaPercentage" value={schGpaPercentage} title={'Sch GPA % for all'} changeInpValue={(e)=>this.changeInutpValue(e.target.name, e.target.value)} type="number" />
                <Inp name="schExamPercentage" value={schExamPercentage} title={'Sch Exam % for all'} changeInpValue={(e)=>this.changeInutpValue(e.target.name, e.target.value)} type="number" />
              </div>
              <div className='mt-7  grid grid-cols-3 gap-3'>
                <SelectBox idName="activity_id" value={[]} setExtraActivity={this.setExtraActivity} name="schActivityData" options={activityOption} label={unirepRoleTranslate?.extra_act} />
                <SelectBox idName="olympiad_id" value={[]} setExtraActivity={this.setExtraActivity} name="schOlympiadData" options={olympiadOption} label={unirepRoleTranslate?.olymp_medal} />
                <ExamsInputAll examPercentage={schExamPercentage} value={[]} name="exams" setExams={this.setSchExams} />
              </div>
              <div>
                {
                  schReqEduDegree == '1' || schReqEduDegree == '2'?
                  <HighSchoolExams  changeInpValue={this.changeInpValueSchHigh} editProgram={programSchHigh}/>:null
                }
                
              </div>
            </div>
            <div className='mt-5'>
                <button onClick={this.addStartDate.bind(this, startDates, 'startDates')} className='border rounded-xl bg-blue-700 text-white font-semibold p-2 mb-3'>Add Start Date</button>
                <div className='mt-3 grid grid-cols-3 gap-3'>
                {
                    startDates.map((data, i)=>{
                        return(
                            <div key={i} > 
                                <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                                    Start Date
                                </label>
                                <div className='flex items-center'>
                                    <input  onChange={(e)=>this.setStartDate(e, i, startDates, 'startDates')} type={'date'}
                                        className={`block w-full border-neutral-200 focus:border-primary-300 
                                        focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                                        dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                                        dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                                    
                                        value={data}
                                    /> 
                                    {
                                        i>0?
                                        <button onClick={this.deleteStartDate.bind(this, i, startDates, 'startDates')} className='border rounded-xl bg-blue-700 text-white font-semibold p-1 ml-2' >-</button>:null
                                    }
                                </div>
                            </div> 
                        )
                    })
                }
                </div>
            </div>
            <div className='mt-5'>
                <button onClick={this.addStartDate.bind(this, deadlines, 'deadlines')} className='border rounded-xl bg-blue-700 text-white font-semibold p-2 mb-3'>Add Deadline</button>
                <div className='mt-3 grid grid-cols-3 gap-3'>
                {
                    deadlines.map((data, i)=>{
                        return(
                            <div key={i} > 
                                <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                                    Deadline
                                </label>
                                <div className='flex items-center'>
                                    <input  onChange={(e)=>this.setStartDate(e, i, deadlines, 'deadlines')} type={'date'}
                                        className={`block w-full border-neutral-200 focus:border-primary-300 
                                        focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                                        dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                                        dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                                    
                                        value={data}
                                    /> 
                                    {
                                        i>0?
                                        <button onClick={this.deleteStartDate.bind(this, i, deadlines, 'deadlines')} className='border rounded-xl bg-blue-700 text-white font-semibold p-1 ml-2' >-</button>:null
                                    }
                                </div>
                            </div> 
                        )
                    })
                }
                </div>
                
            </div>
          </div>
          <div className='flex gap-3 mt-5 mb-5 items-center'>
            <h2 className='text-lg text-blue-600 ' >Selected Programs:  {allProgramSelected.length}</h2>
            <button onClick={this.addAllBtn.bind(this, allProgramSelected, activityData, olympiadData, examsAll)} className='bg-blue-600 text-sm font-semibold p-2 rounded-xl cursor-pointer text-white'>Add All</button>
          </div>

          {autoRender?
            programs?.map((data, i)=>{
              return(
                <EditMassPrgoram 
                  allProgramSelected={allProgramSelected}
                  key={i}
                  programData={{ ...data }}
                  gpa={gpa}
                  appFee={appFee}
                  gpaPercentage={gpaPercentage}
                  examPercentage={examPercentage}
                  reqEduDegree={reqEduDegree}
                  currencyData={currencyData}
                  feeAmountAll={feeAmountAll}
                  feeType={feeType}
                  programFormat={programFormat}
                  schoolarship_option={schoolarship_option}
                  schoolarship_type={schoolarship_type}
                  startDates={startDates}
                  deadlines={deadlines}
                  studyDuration={studyDuration.program.study_duration}
                  a_level={programHigh?.program?.a_level}
                  a_level_percentage={programHigh?.program?.a_level_percentage}
                  advanced_placement={programHigh?.program?.advanced_placement}
                  ap_percentage={programHigh?.program?.ap_percentage}
                  ib_diploma={programHigh?.program?.ib_diploma}
                  ib_percentage={programHigh?.program?.ib_percentage}
                  ossd={programHigh?.program?.ossd}
                  ossd_percentage={programHigh?.program?.ossd_percentage}
                  examCheck={examCheck}
                  setSelectedDataAll={this.setSelectedDataAll}
                  selectUnselectProg={this.selectUnselectProg}
                />
              )
            }):null
          }
      {
          loader || loaderLong? 
          <Loading />:null
      }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  educationDegree: state.Data.educationDegree,
  programs: state.Data.programs,
  educationLanguage: state.Data.educationLanguage,
  currency: state.Data.currency,
  unirepRoleTranslate: state.Data.unirepRoleTranslate,
  internationalMedals: state.Data.internationalMedals,
  extraCurricularActivity: state.Data.extraCurricularActivity,
  loader: state.Data.loader,
  loaderLong: state.Data.loaderLong,
});
const mapDispatchToProps = {changeStateValue, getEducationDegree, getPrograms, getEducationLanguage, getCurrency,updateAllPrograms,  getUnirepRoleTranslate, mainDashboardTranslate, getExtraCurricularActivity, getInternationalMedals, insertNewData, getExams,}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AutoEditAllPrograms))
