import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCountryWiseEdu } from '../../../actions/WorkerActions';
import { changeStateValue } from '../../../redux/MainReducer';
import { getAllCountries, getEducationDegree, getCountryEducationDegree } from '../../../actions/MainAction';
import MainModal from './modals/MainModal';

export class CountryWiseEducation extends Component {
    componentDidMount(){
        this.props.getCountryWiseEdu('1')
        this.props.getEducationDegree('1')
        this.props.getAllCountries('', '', '1')
    }
    getAfterChange=()=>{
        this.props.getCountryWiseEdu('1')
    }
    addEditBtn = (data)=>{
        this.props.changeStateValue({
            name: 'newCountryData',
            value: {
              id: data?.id? data?.id: '',
              position: true,
              residental_country_id: data.residental_country_id?data.residental_country_id:'',
              residental_degree_id: data.residental_degree_id?data.residental_degree_id:'',
              residental_sub_degree_id: data.residental_sub_degree_id?data.residental_sub_degree_id:'',
              destination_country_id: data.destination_country_id?data.destination_country_id:'',
              destination_degree_id: data.destination_degree_id?data.destination_degree_id:'',
            }
        })
        if(data.residental_sub_degree_id || data.residental_sub_degree_id  !=''){
            this.props.getCountryEducationDegree('1', data.residental_country_id, data.residental_degree_id)
        }
        this.props.changeStateValue({
            name: 'otherExamPosition',
            value: false
          })
    } 
    getPlaceholder(name, id, arr){
        if(id == '' || !id){
            return name
        }else{
            const check = arr.find(data=> data.value == id);
            if(check){
                return check.label
            }else{
               return name
            }
        }
    }  
  render() {
    const {countryWiseEdu, user, countryEducationDegree,educationDegree, allCountries, newCountryData} = this.props;
   
    let countryOpt = [];
    let degreeOpt = [];
    let countDegreeOpt = [];
    for(const data of allCountries){
        countryOpt.push({
            label: data.name,
            value: data.countries_id
        })
    }
    for(const data of educationDegree){
        degreeOpt.push({
            label: data.education_type,
            value: data.education_degree_id
        })
    }
    for(const data of countryEducationDegree){
        countDegreeOpt.push({
            label: data.name,
            value: data.country_education_degree_id

        })
    }
    
    return (
      <div>
        <button onClick={this.addEditBtn.bind(this, '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a Grade Scheme</button>

            <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 sm:gap-6 md:gap-8`}>
                {
                    countryWiseEdu.map((item,i)=>{
                        return(
                            <div key={i} className={`nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}  data-nc-id="CardCategoryBox1"  >
                                {
                                    user != ''&& user.role == 'uni_rep'?
                                    <button onClick={this.addEditBtn.bind(this, item)} className={`nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs text-gray-800 bg-gray-100  absolute right-2 top-2`}>
                                    Edit
                                </button>:null
                                }
                                
                                <div className="ml-4 flex-grow overflow-hidden">
                                    <h2 className="text-base font-medium flex" >
                                        <span>Residental Country </span>
                                        <span className="line-clamp-3"> - {item.residental_country}</span>
                                    </h2>
                                    <h2 className="text-base font-medium flex" >
                                        <span>Residental Degree </span>
                                        <span className="line-clamp-3"> - {item.residental_degree}</span>
                                    </h2>
                                    <h2 className="text-base font-medium flex" >
                                        <span>Destination Country</span>
                                        <span className="line-clamp-3"> - {item.destination_country}</span>
                                    </h2>
                                    <h2 className="text-base font-medium flex" >
                                        <span>Destination Degree</span>
                                        <span className="line-clamp-3"> - {item.destination_degree}</span>
                                    </h2>
                                </div>
                            </div>
                        )
                    })
                }
                
            </div>
            {newCountryData?.position ? <MainModal
                                        drop1option={countryOpt} 
                                        drop1Title={"Residental Country"} 
                                        drop1Name="residental_country_id"
                                        drop1placeholder={this.getPlaceholder("Residental Country", newCountryData.residental_country_id, countryOpt)}
                                        drop2option={degreeOpt} 
                                        drop2Title={"Residental degree"} 
                                        drop2Name="residental_degree_id"
                                        drop2placeholder={this.getPlaceholder("Residental degree", newCountryData.residental_degree_id, degreeOpt)}
                                        drop3option={countDegreeOpt} 
                                        drop3Title={"Residental sub degree"} 
                                        drop3Name="residental_sub_degree_id"
                                        drop3placeholder={this.getPlaceholder("Residental sub degree", newCountryData.residental_sub_degree_id, countDegreeOpt)}

                                        drop4option={countryOpt} 
                                        drop4Title={"Destination Country"} 
                                        drop4Name="destination_country_id"
                                        drop4placeholder={this.getPlaceholder("Destination Country", newCountryData.destination_country_id, countryOpt)}
                                        drop5option={degreeOpt} 
                                        drop5Title={"Destination degree"} 
                                        drop5Name="destination_degree_id"
                                        drop5placeholder={this.getPlaceholder("Destination degree", newCountryData.destination_degree_id, degreeOpt)}

                                        stateName="newCountryData"
                                        stateData={newCountryData}
                                        required={["residental_country_id", "residental_degree_id", "destination_country_id", "destination_degree_id"]}
                                        url={'/country-wise-education'}
                                        getAfterChange={this.getAfterChange}
                                    />:null}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    countryWiseEdu: state.Data.countryWiseEdu,
    user: state.Data.user,
    allCountries: state.Data.allCountries,
    educationDegree: state.Data.educationDegree,
    countryEducationDegree: state.Data.countryEducationDegree,
    newCountryData: state.Data.newCountryData,

});
const mapDispatchToProps = {changeStateValue, getCountryWiseEdu,  getAllCountries,getCountryEducationDegree,  getEducationDegree}
export default connect(mapStateToProps, mapDispatchToProps)(CountryWiseEducation)