import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import { addUpdateData, } from '../../actions/WorkerActions';
import CloseIcon from '../../components/page_component/CloseIcon';
import avatar1 from '../../images/default.jpg'
import { getCompanies } from '../../actions/MainAction';
import Searchable from 'react-searchable-dropdown'; 
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
export class EditModal extends Component {
    state={
        btnDisabled: false,
        base64: '',
        phone: '',
    }
    changeInpValue(e){
        this.props.changeStateValue({
            name: 'editUserState.'+e.target.name,
            value: e.target.value
        })
    }

    closeModal = (e) =>{
       
            this.props.changeStateValue({
                name: 'editUserState.position',
                value: false
            })
        
    }

    editDataBtn(data, e){
        e.preventDefault()
        let permission = true;
        for(const property in data){
            if(property !== 'password' && property !== 'image' && property !== 'pro_status'){
                if(data[property] == ''){
                    permission = false
                }
               
        
                if(property == 'phone_number'){
                  
                    if(data?.phone_number.length != 12){
                        permission = false
                    }
                }
            }
        }
       
        if(permission){
            this.setState({
                btnDisabled: true
            })
            let formData = new FormData();
            for(const property in data){
                if(data[property])
               formData.append(property, data[property])
            }
            let img = `${data.image}`;
            let newPath;
            const check = img.includes('https://phplaravel-944849-3287799.cloudwaysapps.com/storage/uploads/');
            if(check){
                newPath = img.replace('https://phplaravel-944849-3287799.cloudwaysapps.com/storage/uploads/', '');
            }else{
                newPath = img;
            }
           
           
            formData.append('_method', 'put')
            formData.append('image', newPath);
            let url = `users/${data.id}`;
            this.props.addUpdateData(url, formData)
            .then(resp=>{
                this.props.changeStateValue({
                    name: 'editUserState.position',
                    value: false
                })
                this.props.getAfterEdit()
            })
        }
        
    }

    uploadImg(e){
        let reader = new FileReader();

        reader.onload = () =>{
            this.setState({
                base64: reader.result
            })
        }
        this.props.changeStateValue({
            name: 'editUserState.image',
            value: e.target.files[0]
        })
        reader.readAsDataURL(e.target.files[0])
    }

    componentDidMount(){
        this.props.getCompanies()
    }
    setCompanyId = (id) =>{
        this.props.changeStateValue({
            name: 'editUserState.company_id',
            value: id
        })
    }
    setEduYear=(id)=>{
        this.props.changeStateValue({
            name: 'editUserState.education_year',
            value: id
        })
    }
    getPlacehollder(data, id){
        const f = data.find(x=> x.value == id);
      
        if(f){
            return f.label
        }else{
            return 'Select a Company'
        }
      
    }
    
  render() {
    const {editUserState, mainTransateData, companies, companyVisible=false, eduYear=false} = this.props;
    const {base64, phone} = this.state;
    // console.log(editUserState.phone_number.length)
    let compOption = [];
    for(const data of companies){
        compOption.push({
            label: data.name,
            value: data.id
        })
    }
    let yearOption = [
        {
            label: "2020-2021",
            value: "2020-2021"
        },
        {
            label: "2021-2022",
            value: "2021-2022"
        },
        {
            label: "2022-2023",
            value: "2022-2023"
        },
        {
            label: "2023-2024",
            value: "2023-2024"
        },
        {
            label: "2024-2025",
            value: "2024-2025"
        },
    ];
    console.log(editUserState)
    return (
        <div className='background_shadow' >
        <div className="login_popup_container">
            <CloseIcon closeModalBtn={this.closeModal} />
            <div>
                <h2 aria-label='mainTransateData.edit_user' className="my-2 flex items-center  mb-3 text-3xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                   {mainTransateData?.edit_user}
                </h2>
                
                <form className="grid grid-cols-1 gap-6" action="#" method="post">
                    <label htmlFor='st_upload_img' className='student_pr_image'>
                        <img  src={base64 != ''?base64:editUserState?.image != ''? editUserState?.image: avatar1} />
                    </label>
                    <input onChange={this.uploadImg.bind(this)} type={'file'} id="st_upload_img" accept='image/jpg, image/png, image/jpeg, image/webp' style={{ display: 'none' }} />
                    <div className='grid grid-cols-2 gap-3'>
                        <label className="block">
                            <span aria-label='mainTransateData.first_name' className="text-neutral-800 dark:text-neutral-200">
                                {mainTransateData?.first_name}
                            </span>
                            
                            <input type={'text'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="First name"
                            value={editUserState.first_name}
                            name="first_name"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        <label className="block">
                            <span aria-label='mainTransateData.last_name' className="text-neutral-800 dark:text-neutral-200">
                                {mainTransateData?.last_name}
                            </span>
                            
                            <input type={'text'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="Last Name"
                            value={editUserState.last_name}
                            name="last_name"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                    </div>
                    <label className="block">
                        <span aria-label='mainTransateData.email' className="text-neutral-800 dark:text-neutral-200">
                            {mainTransateData?.email}
                        </span>
                        
                        <input type={'email'}
                        className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                        placeholder="example@example.com"
                        value={editUserState.email}
                        name="email"
                        onChange={this.changeInpValue.bind(this)}
                        />
                    </label>
                   
                        <div>
                        <PhoneInput
                        country={"az"}
                        enableSearch={true}
                        value={editUserState.phone_number}
                        placeholder='(00) 000 00 00'
                        onChange={(phone) => this.props.changeStateValue({
                            name: 'editUserState.phone_number',
                            value: phone
                        })}
                        inputClass="h-11 rounded-2xl w-full"
                        inputStyle={{ width:'100%', borderRadius:'12px', borderColor:'rgba(0,0,0,0.1)', outline:'none' }}
                        
                        />
                        {
                            editUserState.phone_number?.length != 12?
                            <span className='text-red-700' style={{ fontSize:12 }}>Phone format should: +994 (00) 000 00 00</span>:null
                        }
                    </div>
                    
                    {
                            companyVisible?
                            <label htmlFor='educonProId' className='inline-flex border items-center p-2 gap-2 rounded-xl cursor-pointer'>
                                <input id='educonProId' type='checkbox' defaultChecked={editUserState.pro_status} onClick={(e)=>{this.props.changeStateValue({name: 'editUserState.pro_status', value: e.target.checked? '1':'0'}); console.log(e.target.checked)}} />
                                <span className='text-sm'>Educon Pro</span>
                            </label>:null
                        }
                    {
                            companyVisible?
                            <div className="block">
                                <span className="text-neutral-800 dark:text-neutral-200">
                                    Company
                                </span>
                                
                                <div className='border rounded-2xl'>
                                    <Searchable 
                                        options={compOption}
                                        onSelect={this.setCompanyId.bind(this)}
                                        placeholder={this.getPlacehollder(compOption, editUserState.company_id)}
                                    />
                                </div>
                            </div>:null
                        }
                    {
                            eduYear?
                            <div className="block">
                                <span className="text-neutral-800 dark:text-neutral-200">
                                    Education Year
                                </span>
                                
                                <div className='border rounded-2xl'>
                                    <Searchable 
                                        options={yearOption}
                                        onSelect={this.setEduYear.bind(this)}
                                        placeholder={this.getPlacehollder(yearOption, editUserState.education_year)}
                                    />
                                </div>
                            </div>:null
                        }
                    <label className="block">
                        <span aria-label='mainTransateData.password' className="text-neutral-800 dark:text-neutral-200">
                            {mainTransateData?.password}
                        </span>
                        
                        <input type={'password'}
                        className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                        placeholder="*********"
                        value={editUserState.password}
                        name="password"
                        onChange={this.changeInpValue.bind(this)}
                     
                        />
                    </label>
                    
                    <button aria-label='mainTransateData.edit' onClick={this.editDataBtn.bind(this, editUserState)} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                        text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>
                        {mainTransateData?.edit}
                    </button>
                </form>
            </div>
        </div>
  </div>
    )
  }
}
const mapStateToProps = (state) =>({
    editUserState: state.Data.editUserState,
    mainTransateData: state.Data.mainTransateData,
    companies: state.Data.companies
});
const mapDispatchToProps = {changeStateValue, addUpdateData, getCompanies}
export default connect(mapStateToProps, mapDispatchToProps)(EditModal)