import React, { Component } from 'react'
import GoogleLogin from 'react-google-login'
import { connect } from 'react-redux'
import AppleIdLogin from '../../../page_component/AppleIdLogin'
import EmailSvg from '../../../page_component/simulator_components/login_register/EmailSvg'
import Percentage from '../../../page_component/simulator_components/Percentage'
import { changeStateValue } from '../../../../redux/MainReducer'
import { GOOGLE_CLIENT_ID } from '../../../../MAIN_API'
const clientId = GOOGLE_CLIENT_ID;

export class BlurChances extends Component {
    onSuccess = (e)=>{
        
    }
    onFailure = ()=>{

    }

    showModal(){
        this.props.changeStateValue({
            name: 'simulatorLogin',
            value: true
        })
    }
  render() {
    const {mainTransateData} = this.props;
    return (
        <div className="listingSection__wrap"  >
            <h2 className='font-medium text-center ' style={{ fontSize: '18px'}} aria-label="mainTransateData.login_to_see_admission">{mainTransateData?.login_to_see_admission}</h2>
           <div className='flex justify-center items-center relative blur_chance_container'>
                <div className='blur_background'>
                    <Percentage prcntg={80} />
                </div>
                <button onClick={this.showModal.bind(this)} className='absolute program_sign_up_button'>
                    Sign Up
                </button>
           </div>
            {/* <div className="grid gap-3">
                <AppleIdLogin />
            </div> */}
            <div className="grid gap-3 relative" id='google_login' style={{ marginTop: '10px' }}>
                <GoogleLogin 
                // clientId={clientId}
                buttonText={mainTransateData?.continue_google}
                // onSuccess={this.onSuccess.bind(this)}
                // onFailure={this.onFailure.bind(this)}
                // cookiePolicy="single_host_origin"
                isSignedIn={false}
                theme='dark'
                />
                <div onClick={this.showModal.bind(this)} style={{ position: 'absolute', width: '100%', backgroundColor: 'rgba(0,0,0,0)', height: '100%', cursor: 'pointer' }} ></div>
            </div>
            <div className='manual_login_btn_container' onClick={this.showModal.bind(this)} style={{ marginTop: '10px' }} >
                <EmailSvg />
                <span aria-label='mainTransateData.signup_email'>{mainTransateData?.signup_email}</span>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    mainTransateData: state.Data.mainTransateData
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(BlurChances)