import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeStateValue } from '../../../redux/MainReducer'
export class GoogleMap extends Component {
  editDataBtn(data, e){
    // console.log('ddd', data)
    const info={
      id: 4,
      position: true,
      institution_faqs:{
          institution_id: data.institutions_id,
          type: 'map',
      },
      lang_id: 1,
      question: data.institutions.map,
      answer: 'll',
      active: '1'
  }
  this.props.changeStateValue({
      name: 'institutionInfoModal',
      value: info
  })
  }
  render() {
    const {data} = this.props
  
    return (
      <div>
        <div className="flex justify-between items-center">
            <button onClick={this.editDataBtn.bind(this, data)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200 mb-5 px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                Edit 
            </button>
        </div>
          <p dangerouslySetInnerHTML={{ __html: data.institutions.map}}></p>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(GoogleMap)