import axios from 'axios';
import { MAIN_API } from '../MAIN_API';
import {changeStateValue} from '../redux/MainReducer';

export const getParentStudents = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/users?query_type=parent_student`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'parentStudents', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getParentPersonalInfo = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/users/1`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'parentPersonalInfo',
            value: resp.data
        }))
    }).catch(err=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'err';
    })
}

export const addNewData = (url, data) => async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/${url}`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success';
    }).catch(err=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'err';
    })
}