import React, { Component } from 'react'
import { connect } from 'react-redux';
import BgGlassmorphism from '../../components/page_component/BgGlassmorphism'
import SectionHeader from '../../components/page_component/SectionHeader'
import { getEduCons } from '../../actions/WorkerActions';
import { mainDashboardTranslate, getUnirepRoleTranslate } from '../../actions/TranslateAction';
import RegisterModal from './components/RegisterModal'
import { Link } from 'react-router-dom';
import avatar1 from '../../images/default.jpg'
import { changeStateValue } from '../../redux/MainReducer';
import EditModal from '../edu_consultant_pages/EditModal';
import EditSvg from '../../components/page_component/EditSvg'

export class EduCons extends Component {
    state={
        registerVisible:false
    }
    componentDidMount(){
        window.scrollTo(0, 0)

        this.props.mainDashboardTranslate(this.props.languageId)
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.getEduCons()
    }
    getAfterEdit = () =>{
        this.props.getEduCons()
    }
    registerStudent(){
        this.setState({
            registerVisible: true,
        })
    }
    closeModal = () => {
        this.setState({
            registerVisible: false
        })
    }
    editSvgBtn = (data) =>{
        
        this.props.changeStateValue({
            name: 'editUserState',
            value:{
                position: true,
                id: data.id,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                password: '',
                company_id: data.company_id,
                phone_number: data?.phone_number,
                pro_status: data?.pro_status
            }
        }) 
    }
  render() {
    const {eduConsultants, editUserState} = this.props;
    const {registerVisible} = this.state;
   
    return (
        <div className="nc-PageHome relative overflow-hidden">
            <BgGlassmorphism />
            <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
                <div className="relative py-16">
                    <div className={`nc-SectionGridFilterCard pb-24 lg:pb-28`} data-nc-id="SectionGridFilterCard" >
                    
                        <SectionHeader title='Edu Cons' />
                        <div className="mb-8 lg:mb-11">
                            <div className="flex lg:space-x-4">
                                <div className="hidden lg:flex space-x-4">
                                    <button onClick={this.registerStudent.bind(this)} className='ttnc-ButtonThird text-neutral-700 border 
                                                        border-neutral-200 dark:text-neutral-200 
                                                        dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                                        items-center justify-center rounded-full transition-colors
                                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                                        px-4 py-1 sm:px-3' style={{ fontSize: '14px', fontWeight: '400' }}  >
                                        Add a Edu Con
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div  className={`grid mt-10 gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 `} >
                            {
                                eduConsultants.map((data, i)=>{
                                    return(
                                        <div key={i}
                                        className={`nc-CardAuthorBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}
                                        data-nc-id="CardAuthorBox"
                                    >
                                        <div className='w-5 absolute left-3 top-3' >
                                            <EditSvg editSvgBtn={this.editSvgBtn.bind(this, data)} />
                                        </div>
                                        <div className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full w-20 h-20 text-2xlring-1 ring-white dark:ring-neutral-900`}>
                                            <img
                                            className={`absolute inset-0 w-full h-full object-cover rounded-full`}
                                            src={data.image_full_url&& data.image_full_url != '' ? data.image_full_url:avatar1}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <h2 className={`text-base font-medium`}>
                                                <span className="line-clamp-1">{data.full_name}</span>
                                            </h2>
                                            <span className={`block mt-1.5  text-sm text-neutral-500 dark:text-neutral-400 wrap`} >
                                            {data.email.length > 20? `${data.email.substring(0, 20)}...`:data.email}
                                            </span>
                                            <div className={"p-4 space-y-4"}>
                                            {
                                                data?.pro_status == 1?
                                                'Educon Pro':null
                                            }
                                        </div>
                                                
                                        </div>
                                        <div className="flex justify-between gap-5">
                                            <Link to={`/edu-con-students/${data.id}`} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                                            Show Students 
                                            </Link>
                                           
                                        </div>    
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                registerVisible?
                <RegisterModal companyVisible={true} closeModal={this.closeModal} />:null
            }
            {
                editUserState.position?
                <EditModal companyVisible={true} getAfterEdit={this.getAfterEdit} />:null
            }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    eduConsultants: state.Data.eduConsultants,
    languageId: state.Data.languageId,
    editUserState: state.Data.editUserState,
});
const mapDispatchToProps = {getEduCons, mainDashboardTranslate, getUnirepRoleTranslate, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(EduCons)