import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import { getInstitutions, changeAppliedProg, getOneStudentApplys } from '../../../actions/WorkerActions';
import UniCard from './UniCard';
import Swal from 'sweetalert2';
export class ChangeAppModal extends Component {
    state={
        searchUni:'',
        position:false,
        changeProgId: '',
        changeStudentId: ''
    }

    getUniBtn(text, e){
        e.preventDefault()
        this.props.getInstitutions(100, '', 1, text)
    }

    changeProgBtn(data){
    
        this.props.changeStateValue({
            name:'workerInstitutions',
            value:[]
        })
        this.setState({
            changeProgId: data?.program_id,
            changeStudentId: data?.student_id,
            position:true
        })
    }

    changeBtn=(data)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!'
          }).then((result) => {
            if (result.isConfirmed) {
                const params = {
                    student_id: this.state.changeStudentId,
                    program_id: this.state.changeProgId,
                    new_program_id: data?.program_id,
                }
                this.props.changeAppliedProg(params)
                .then(resp=>{
                    if(resp = 'success'){
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', Swal.stopTimer)
                              toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                          })
                          
                          Toast.fire({
                            icon: 'success',
                            title: 'Changed successfully'
                          })
                        this.props.getOneStudentApplys(this.state.changeStudentId)
                        this.setState({
                            position:false,
                            changeProgId: '',
                            changeStudentId: ''
                        })
                    }else{
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                              toast.addEventListener('mouseenter', Swal.stopTimer)
                              toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                          })
                          
                          Toast.fire({
                            icon: 'error',
                            title: 'Something went wronge'
                          })
                    }
                    
                })
            }
          })
        
    }
  render() {
    const {oneStudentApplys, workerInstitutions, closeMainModal}  = this.props;
    const {searchUni, position} = this.state;
    return (
      <div className='background_shadow flex items-center justify-center'>
            <div style={{ maxHeight:'70vh', overflow:'auto' }} className='bg-white p-5 w-1/2 rounded-xl'>
                <ul className='flex flex-col gap-2'>
                    {
                        oneStudentApplys?.map((data, i)=>{
                            return(
                                <li className='flex justify-between border-b items-center pb-2' key={i}>{i+1}. {data?.program?.translate?.name} 
                                    <button onClick={this.changeProgBtn.bind(this, data)} className='bg-blue-600 text-[12px] font-semibold text-white p-[3px] rounded-xl'>Change</button>
                                </li>
                            )
                        })
                    }
                    
                </ul>
                <div className='flex justify-end mt-5'>
                <button onClick={()=>closeMainModal()} className='bg-red-600 text-white p-2 rounded-xl'>Close</button>
            </div>
            </div>
            {
                position?
                <div className='background_shadow flex items-center justify-center'>
                    <div style={{ maxHeight:'70vh', overflow:'auto' }} className='bg-white p-5 w-1/2 rounded-xl'>
                        <form className='flex justify-between  gap-3 items-center'>
                            <input value={searchUni} onChange={(e)=>this.setState({searchUni:e.target.value})} placeholder='Search university' type='text' />
                            <button onClick={this.getUniBtn.bind(this, searchUni)} className='bg-blue-600 text-white p-2 rounded-full font-semibold'>Search</button>
                        </form>
                        <div>
                            {
                                workerInstitutions?.map((data, i)=>{
                                    return(
                                    <UniCard changeBtn={this.changeBtn} data={data} key={i} />
                                    )
                                })
                            }
                            
                        </div>
                        <div className='flex justify-end mt-5'>
                            <button  onClick={()=>this.setState({position:false})} className='bg-red-600 text-white p-2 rounded-xl'>Close</button>
                        </div>
                    </div>
                </div>:null
            }
            
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    oneStudentApplys: state.Data.oneStudentApplys,
    workerInstitutions: state.Data.workerInstitutions,
});
const mapDispatchToProps  = {changeStateValue, getInstitutions, changeAppliedProg, getOneStudentApplys}
export default connect(mapStateToProps, mapDispatchToProps)(ChangeAppModal)
