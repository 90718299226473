import axios from 'axios';
import { MAIN_API } from '../MAIN_API';
import {changeStateValue} from '../redux/MainReducer';

export const getStudentProfilePercentage = (student_id='') => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/student-main-data?query_type=check_complete&student_id=${student_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'studentProfilePercentage',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
        return 'err';
    })
}
export const getstudentPersonalInfo = (student_id = '1') => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/users/${student_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'studentProfileData',
            value: resp.data
        }))
    }).catch(err=>{
        console.log(err.response)
        return 'err';
    })
}
export const getstudentEducationBacground = (lang_id=1, student_id = '') => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/student-main-data?query_type=education_background&lang_id=${lang_id}&student_id=${student_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'studentEduBackground',
            value: resp.data
        }))
        return resp.data
    }).catch(err=>{
        console.log(err.response)
        return 'err';
    })
}
export const getStudentExams = (lang_id=1, student_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/student-main-data?query_type=exam_score&lang_id=${lang_id}&student_id=${student_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'studentExams',
            value: resp.data
        }))
        return resp.data
    }).catch(err=>{
        console.log(err.response)
        return 'err';
    })
}
export const getStudentDestinations = (student_id = '') => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/student-main-data?query_type=study_destination&student_id=${student_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'studentDestinations',
            value: resp.data
        }))
        return resp.data
    }).catch(err=>{
        console.log(err.response)
        return 'err';
    })
}
export const getStudentActivities = (student_id='') => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/student-main-data?query_type=activities&student_id=${student_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'studentActivities',
            value: resp.data
        }))
        return resp.data
    }).catch(err=>{
        console.log(err.response)
        return 'err';
    })
}

export const addNewData = (url, data) => async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/${url}`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        console.log(resp.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        dispatch(getStudentProfilePercentage(data.get('student_id')))
        return 'success';
    }).catch(err=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'err';
    })
}