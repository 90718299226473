import React, { Component, Fragment } from 'react'
import { Dialog, Popover, Transition } from "@headlessui/react";
import { connect } from 'react-redux';
import { getInstitutions } from '../../actions/MainAction';
import Checkbox from './CheckBox';
export class CheckFilter extends Component {
  state={
    checkBoxData: [],
    position: false
  }
  checkDataProps = (id) => {
    const checkBoxData = this.state.checkBoxData;
    const findData = checkBoxData.findIndex(data=> data == id)
    
    if(findData === -1){
      if(this.props.type == 'country'){
        checkBoxData.splice(0, 1)
      }
      checkBoxData.push(id)
    }else{
      checkBoxData.splice(findData, 1)
    }
    this.setState({
      checkBoxData: checkBoxData
    })
    this.applyData(checkBoxData)

  }

  checkChecked(array, id){
    if(array.find(data=> data == id)){
      return true
    }else{
      return false
    }
  }

  cleraArray(){
    this.props.checkDataBtn([], this.props.type)
    this.setState({
      checkBoxData: []
    })
    this.applyData([])
  }

  applyData(checkBoxData, e){
   
    this.props.checkDataBtn(checkBoxData, this.props.type)
   
  }
  openModal(){
    this.setState({
      position:true
    })
    
  }

  render() {
   const {checkBoxData, position} = this.state;
   const {array, title, label, mainTransateData}  = this.props;


    return (
        <Popover>
                
            <Popover.Button 
            onClick={this.openModal.bind(this)}
            className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
              position ? "!border-primary-500 " : ""
            }`}
            >
            <span><span aria-label={label}>{title}</span> ({checkBoxData.length})</span>
            <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            {
              position?
              
                <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
              <div id='popover_container'>
            <Popover.Panel className=" z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                <div className="relative flex flex-col px-5 py-6 space-y-5 " style={{ maxHeight: '300px', overflow: 'auto' }}>
                    {array.map((item, i) => (
                    <div key={i} className="">
                        <Checkbox
                        name={item.name}
                        id={item.id}
                        checked={this.checkChecked(checkBoxData, item.id)}
                        checkDataProps={this.checkDataProps}
                        // label={item.name}
                        // subLabel={item.description}
                        />
                    </div>
                    ))}
                </div>
                <button onClick={this.cleraArray.bind(this)} className='ttnc-ButtonThird text-neutral-700 border 
                                        border-neutral-200 dark:text-neutral-200 
                                        dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                        items-center justify-center rounded-full transition-colors
                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                        px-4 py-2 sm:px-5'  aria-label='mainTransateData.clear' >
                        {mainTransateData?.clear}
                </button>
                {/* <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    
                  
                    
                    <button style={{ color: 'white' }} onClick={this.applyData.bind(this, checkBoxData)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-2 sm:px-5  ' aria-label='mainTransateData.apply'>
                        {mainTransateData?.apply}
                    </button>
                </div> */}
                </div>
            </Popover.Panel></div>
            </Transition>
              :null
            }
            
    
    </Popover>
    )
  }
}
const mapStateToProps = (state)=> ({
  languageId: state.Data.languageId,
  mainTransateData: state.Data.mainTransateData
});
const mapDispatchToProps = {getInstitutions}
export default connect(mapStateToProps, mapDispatchToProps)(CheckFilter)