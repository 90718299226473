import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import LikeIcon from './LikeIcon'

export class Slider extends Component {
    state={
        galleryImgs:[
            {
                id: 1,
                src: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'
            },
            {
                id: 2,
                src: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'
            },
            {
                id: 3,
                src: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'
            },
        ]
    }
    getFeeAmount(x){
    
        var parts = x.toString().split(".");
        parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");
        return parts.join(",");
    }
    getSchoolarship(currency, type, amount, fee){
        
        if(type == 'amount'){
           return this.getFeeAmount(amount)+ " "+ currency
        }else{
            return this.getFeeAmount((fee*amount/100).toFixed(0))+" "+currency
        }
    }

   
  render() {
    const {galleryImgs} = this.state;
    const {program, url="/", height = '3', user, reloadAfterLike = ()=>{}} = this.props;

    return (
        <div className="relative w-full">
            <div className={`nc-GallerySlider`} data-nc-id="GallerySlider">
                <div className={`gallerySlider__StayCard_1 relative group overflow-hidden`}>
                    <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {galleryImgs.map((item, index) => (
                        <li key={index} className="glide__slide">
                            <Link to={url} className={`block aspect-w-4 aspect-h-${height}`}>
                                <div className={`nc-NcImage  `} data-nc-id="NcImage" >
                                
                                    <img src={program?.program?.image_full_url !== ''?program?.program?.image_full_url:program?.program?.education_degree?.image_full_url} className={'object-cover w-full h-full'}  />
                                
                                </div>
                            </Link>
                        </li>
                        ))}
                    </ul>
                    </div>
                    <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
                </div>
            </div>
       
            <LikeIcon reloadAfterLike={reloadAfterLike} defaultCheck={program?.wish_list=='1'? true: false} id={program?.program?.id} type="program" />
            {
                program?.program?.schoolarship_option && program?.program?.schoolarship_option !== '' &&  program?.program?.schoolarship_option != 0?
                <div className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 bg-red-700 text-red-50 rounded-full absolute left-3 top-3`}
                    data-nc-id="SaleOffBadge" >
                    {/* {this.getSchoolarship(program.currency, program?.program?.schoolarship_type, program?.program?.schoolarship_option, program?.program?.fee_amount)} */}
                    {user !== '' && user.role == 'uni_rep' && program.active == '0'? 
                    'Waiting':
                    this.getSchoolarship(program.currency, program?.program?.schoolarship_type, program?.program?.schoolarship_option, program?.program?.fee_amount)
                }
                </div> :null
            }  
            
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
    user: state.Data.user
})
export default connect(mapStateToProps)(Slider)