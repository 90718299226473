import React, { Component } from 'react'
import { getInstitutions } from '../../../actions/MainAction';
import { changeStateValue, resetStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import LoadMoreBtn from '../LoadMoreBtn';
import { insertNewData } from '../../../actions/WorkerActions';
import Swal from 'sweetalert2';
export class ChangeUniModal extends Component {
    state={
        search:'',
        page:1,
        btnVisible:false
    }
    searchUni=(search) => {
        this.props.getInstitutions(this.state.page, search, 1)
    }
    loadMoreFunction=(page)=>{
        this.setState({page})
        this.props.getInstitutions(page, this.state.search, 1)
    }
    closeModal=()=>{
        this.props.resetStateValue({
            name:"uniChangeModal"
        })
    }
    componentDidMount(){
        this.props.changeStateValue({
            name:"institutions",
            value:[]
        })
    }
    changebtn=(data, uni_id)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it'
          }).then((result) => {
            if (result.isConfirmed) {
                this.setState({btnVisible:true})
                let formData  = new FormData();
                formData.append("uni_id", data?.uni_id);
                formData.append("new_uni_id", uni_id);
                this.props.insertNewData("/change-uni-programs", formData)
                .then(resp=>{
                    // console.log(resp)
                    data?.afterCallFunc()
                    this.setState({btnVisible:false})
                    if(resp.msg == 'success'){
                        this.closeModal()
                        Swal.fire(
                            'Changed!',
                            'Commission status changed successfully.',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                        )
                    }
                })
            }
          })
       
    }
  render() {
    const {search, btnVisible} = this.state;
    const {institutions, uniChangeModal} = this.props;
    // console.log(institutions)
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div className='bg-white p-[20px] shadow-md rounded-[10px] w-[800px]'>
            <h1 className='text-[18px] font-semibold'>
               {uniChangeModal?.uni_name}
            </h1>
            <div className='mt-[20px] mb-[20px] flex justify-between items-center gap-[20px]'>
                <input type='text' value={search} onChange={(e)=>this.setState({search:e.target.value})}/>
                <button onClick={this.searchUni.bind(this, search)} className='p-[4px_6px] text-white bg-blue-600 rounded-[6px]'>Search</button>
            </div>
            <div className='max-h-[60vh] overflow-auto flex flex-col gap-[20px]'>
                {
                    institutions?.map((data, i)=>{
                        if(data?.institutions_id != uniChangeModal?.uni_id){
                            return(
                                <div key={i} className='border-b flex justify-between gap-[10px] pb-[5px]'>
                                    <h3>{data?.name}</h3>
                                    <button disabled={btnVisible} onClick={this.changebtn.bind(this,uniChangeModal, data?.institutions_id)} className='p-[4px_6px] text-white bg-orange-500 rounded-[6px] text-[12px]'>Change</button>
                                </div>
                            )
                        }
                       
                    })
                }
                {
                   institutions.length >= 20?
                   <LoadMoreBtn  position={institutions.length} loadMoreFunction={this.loadMoreFunction} />:null
                }
            </div>
            <div className='flex justify-between items-center mt-[20px]'>
                <button disabled={btnVisible} onClick={this.closeModal.bind(this)} className='p-[4px_6px] text-white bg-red-600 rounded-[6px]'>
                    Close
                </button>
                {/* <button  disabled={btnVisible} className='p-[4px_6px] text-white bg-blue-600 rounded-[6px]'>
                    Save
                </button> */}
            </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) =>({
    institutions: state.Data.institutions,
    uniChangeModal: state.Data.uniChangeModal,
});
const mapDispatchToProps = {getInstitutions, changeStateValue, resetStateValue, insertNewData}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUniModal)
