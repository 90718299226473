import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeTranslateData, mainDashboardTranslate, getUnirepRoleTranslate } from '../../actions/TranslateAction';
import { changeStateValue } from '../../redux/MainReducer';
export class TranslateModal extends Component {

    saveData(data, e){
        if(data.url != '' && data.title != '' && data.text != ''){
            let formData = new FormData();
            formData.append('title', data.title)
            formData.append('text', data.text)

            this.props.changeTranslateData(data.url, formData)
            .then(resp=>{

                this.props.mainDashboardTranslate(1)
                this.props.getUnirepRoleTranslate(1)
                 
                this.props.changeStateValue({
                    name: 'translateModal',
                    value: {
                        position: false,
                        url: '',
                        title: '',
                        text: ''
                    }
                })
            })
        }
    }
    changeInpValue(e){
        this.props.changeStateValue({
            name: 'translateModal.text',
            value: e.target.value
        })
    }
    closeModal(e){
        if(e.target.className == 'background_shadow'){
            this.props.changeStateValue({
                name: 'translateModal',
                value: {
                    position: false,
                    url: '',
                    title: '',
                    text: ''
                }
            })
        }
    }
  render() {
    const {translateModal} = this.props;

    return (
      <div className='background_shadow' onClick={this.closeModal.bind(this)}>
            <div className='translate_modal_body'>
                <input
                onChange={this.changeInpValue.bind(this)}
                    className={`block border w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                    value={translateModal.text}
                 />
                <button  onClick={this.saveData.bind(this, translateModal)}>Save</button>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    translateModal: state.Data.translateModal
});
const mapDispatchToProps = {changeStateValue, changeTranslateData, mainDashboardTranslate, getUnirepRoleTranslate }
export default connect(mapStateToProps, mapDispatchToProps)(TranslateModal)