import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import MenuBar from './MenuBar'
import {logOutAction} from '../../actions/MainAction'
function withParams(Component){
    return props => <Component {...props} navigate={useNavigate()} />
}
export class FooterNav extends Component {
    logOutFunction(){
        this.props.logOutAction()
        .then(resp=>{
            this.props.navigate('/')
        })
    }
  render() {
     const {user} = this.props
    return (
       
        <div className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700  transition-transform duration-300 ease-in-out" >
            <div className="w-full max-w-lg flex justify-between mx-auto text-sm text-center ">
               
                {
                    user == ''?
                    <Link  to={'/login'}   className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90   text-neutral-900 dark:text-neutral-100  `}  >
                  
                        <svg  style={{ marginBottom: '-14px' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-8 h-8 ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z">
                            </path>
                        </svg>
                        <span className="text-[11px] leading-none ">login</span>
                    </Link>:
                     <button onClick={this.logOutFunction.bind(this)}   className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90   text-neutral-900 dark:text-neutral-100  `}  >
                  
                        <svg  style={{ marginBottom: '-14px' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="w-8 h-8 ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z">
                            </path>
                        </svg>
                        <span className="text-[11px] leading-none ">log out</span>
                    </button>
                }
               <div>
                    <div style={{ marginBottom: '-14px' }}>
                        <MenuBar />
                    </div>
                    <span  className="text-[11px] leading-none ">Menu</span>

               </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user
});
const mapDispatchToProps = {logOutAction}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(FooterNav))