import React, { Component } from 'react'
import { connect } from 'react-redux';
import SvgDown from '../../../components/page_component/simulator_components/SvgDown';
import SvgUp from '../../../components/page_component/simulator_components/SvgUp';

import { changeStateValue } from '../../../redux/MainReducer';
export class SelectBox extends Component {
    state={
        visible: false,
        titleRef: React.createRef(),
        selectedData: []
    }
    changeVisible(visible, e){
     
        this.setState({
            visible: !visible
        })
    }

    componentDidMount(){
        window.addEventListener('click', (e)=>{
           if(this.state.visible && this.state.titleRef.current){
                if(!this.state.titleRef.current.contains(e.target)){
                    this.setState({
                        visible: false
                    })
                }
           }
            
        })
     
        if(this.props.value  && this.props.value !== 0){
            let newData = []
            if(this.props.type && this.props.type == 'dublicate'){
                this.props.value.forEach(data=> {
                    newData.push({
                     
                        data_id: data[this.props.idName],
                        percentage: data.percentage
                    })
                })
            }else{
                this.props.value.forEach(data=> {
                    newData.push({
                        id: data.id,
                        data_id: data[this.props.idName],
                        percentage: data.percentage
                    })
                })
            }
         
            this.setState({
                selectedData: newData
            })
        }
    }
    selectValue(id, e){
        const data = [...this.state.selectedData];
        const index = data.findIndex(el=> el.data_id == id);
        const element = data.find(el=> el.data_id == id);
        if(index !== -1){
            if(element.delete){
                delete element.delete
            }else{
                element.delete = true;
                element.percentage = 0
            }
            data[index] = element
        }else{
            const params = {
                data_id: id,
                percentage: 0
            }

            data.push(params)
           
        }
        this.setState({
            selectedData: data
        })
        this.props.setExtraActivity(this.props.name, data)
    }

    checkSelected(id){
        const data = [...this.state.selectedData];
        const index = data.find(el=> el.data_id == id);
        if(index && !index.delete){
            return true
        }else{
            return false
        }
    }
    getSelectedLabel(id, options){
        return options.find(data=> data.value == id).label
    }
    setPercentage(data, index, e){
        data[index].percentage = e.target.value;
        this.setState({
            selectedData: data
        })
        this.props.setExtraActivity(this.props.name, data)
    }

    deleteValue(data, index, e){
        data[index].delete = true;
        data[index].percentage = 0;
        this.setState({
            selectedData: data
        })
        this.props.setExtraActivity(this.props.name, data)
    }
  render() {
    const {visible, titleRef, selectedData} = this.state;
    const {label = '', options = []} = this.props;
 
 
    return (
        <div>
            <div  ref={titleRef}>
                <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                {label}
                </label>
                <div className='border rounded-2xl input_select_more h-11 '>
                <div onClick={this.changeVisible.bind(this, visible)} className='select_more_title'>
                    <h3 >{label}</h3>
                    {
                        visible?
                        <SvgUp />:
                        <SvgDown />
                    }
                    
                </div>
                {
                    visible?
                    <ul className='filter_checkbox_container border rounded-2xl'>
                        {
                            options.map((opt, i)=>{
                                return(
                                    <li key={i}>
                                        <label >
                                            <div >
                                                <input checked={this.checkSelected(opt.value)} onChange={this.selectValue.bind(this, opt.value)} type={'checkbox'} />
                                                <span>{opt.label}</span>
                                            </div>
                                        </label>
                                    </li>
                                )
                            })
                        }
                    </ul>:null
                }
                
                </div>
            </div>
       
           {
            selectedData.length !== 0?
            <div className="flow-root mt-5">
                <div className="-my-3 divide-y divide-neutral-100 dark:divide-neutral-800">
                    {
                        selectedData.map((data, i)=>{
                            if(!data.delete){
                               return(
                                <div key={i} className="flex items-center justify-between py-3">
                                    <span className="text-neutral-6000 dark:text-neutral-400 font-medium">
                                        {this.getSelectedLabel(data.data_id, options)}
                                    </span>
                                    <div className='flex items-center justify-between gap-7'>
                                        <input onChange={this.setPercentage.bind(this, selectedData, i)} placeholder='%' value={data.percentage == 0?'': data.percentage} className='percentage_inp rounded-2xl slider_percentage' type={'number'} />
                                        <i onClick={this.deleteValue.bind(this, selectedData, i)} className="text-2xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"></i>
                                    </div>
                                </div>
                               )
                            }
                        })
                    }
                    
                
                {/* <SliderRange /> */}
                </div>
            </div>:null
           }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    editProgram: state.Data.editProgram
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SelectBox)