import React, { Component } from 'react'
import { connect } from 'react-redux'
import AboutContainer from './country_data_components/AboutContainer'
import Button from './country_data_components/Button'
import FaqsContainer from './country_data_components/FaqsContainer'
import LivingContainer from './country_data_components/LivingContainer'
import Modal from './country_data_components/Modal'
import VisaContainer from './country_data_components/VisaContainer'
import WorkingContainer from './country_data_components/WorkingContainer'
import { changeStateValue } from '../../redux/MainReducer';
import { useParams } from 'react-router-dom';
import {getCountriesData} from '../../actions/WorkerActions';
import {mainDashboardTranslate, getUnirepRoleTranslate} from '../../actions/TranslateAction';
import {getCountryData} from '../../actions/MainAction';
import Loading from '../../components/page_component/Loading'
function withHooks(Component){
    return props => <Component {...props} params={useParams()} />
}
export class CountryData extends Component {
    state={
        buttonType:'faq'
    }
    changeData = (type) => {
        this.setState({
            buttonType: type
        })
        this.props.getCountriesData(this.props.params.id, 1, type);

    }

    showModal(){
        const data={
            id: '',
            position: true,
            country_fags:{
                countries_id: this.props.params.id,
                type: this.state.buttonType,
            },
            lang_id: 1,
            question: '',
            answer: '',
            active: '1'
        }
        this.props.changeStateValue({
            name: 'countryInfoModal',
            value: data
        })
    }
    componentDidMount(){
        window.scrollTo(0, 0)

        this.props.getCountriesData(this.props.params.id, 1, this.state.buttonType)
        this.props.mainDashboardTranslate(this.props.languageId)
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.getCountryData(this.props.params.id, this.props.languageId)

    }
  render() {
    const {buttonType} = this.state;
    const {countryInfoModal, countryInfoData, countryData, loader, loaderLong,} = this.props;
    
    return (
        <div>
            <div className="container relative space-y-24 mb-24 lg:space-y-10 lg:mb-28">
                <h1 style={{ textAlign: 'center', fontSize: '25px', fontWeight: '600' }}>{countryData?.name}</h1>
                <div className='flex gap-5'>
                    <Button title="FAQs" className={buttonType == 'faq'? 'bg-primary-6000': ''} type="faq" changeData={this.changeData} />
                    <Button title="About" className={buttonType == 'about'? 'bg-primary-6000': ''} type="about" changeData={this.changeData} />
                    <Button title="Visa" className={buttonType == 'visa'? 'bg-primary-6000': ''} type="visa" changeData={this.changeData} />
                    <Button title="Living" className={buttonType == 'living'? 'bg-primary-6000': ''} type="living" changeData={this.changeData} />
                    <Button title="Working" className={buttonType == 'working'? 'bg-primary-6000': ''} type="working" changeData={this.changeData} />
                    
                </div>
                {
                    buttonType !== 'about'?
                    <div className='flex justify-end'>
                        <button onClick={this.showModal.bind(this)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>Add</button>
                    </div>:null
                }
                
            {
                buttonType == 'faq'?
                <FaqsContainer data={countryInfoData} />:
                buttonType == 'about'?
                <AboutContainer data={countryData} />:
                buttonType == 'visa'?
                <VisaContainer data={countryInfoData} />:
                buttonType == 'living'?
                <LivingContainer data={countryInfoData} />:
                buttonType == 'working'?
                <WorkingContainer data={countryInfoData} />:null
            }
           
            </div>
            {
                countryInfoModal.position?
                 <Modal />:null
            }
           {
             loader || loaderLong? 
             <Loading />:null
            
           }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    countryInfoModal: state.Data.countryInfoModal,
    countryInfoData: state.Data.countryInfoData,
    languageId: state.Data.languageId,
    countryData: state.Data.countryData,
    loader: state.Data.loader,
    loaderLong: state.Data.loaderLong,
});
const mapDispatchToProps = {changeStateValue, getCountriesData, mainDashboardTranslate,getCountryData, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(CountryData))