import React, { Component, Fragment } from 'react'
import { Dialog, Popover, Transition } from "@headlessui/react";
// import Checkbox from './Checkbox';
import { connect } from 'react-redux';
import Checkbox from '../../../page_component/institutions/Checkbox';
import {getEducationDegreeByUni, getPrograms} from '../../../../actions/MainAction'
import {getWorkerPrograms} from '../../../../actions/WorkerActions';
import { useParams } from 'react-router-dom';
function withHooks (Component){
  return props => <Component {...props} params={useParams()} />
}
export class FilterprogramByDegree extends Component {
  state={
    degree: [],
    position: false
  }
  checkDataProps = (id) => {
    const degree = this.state.degree;
    const findData = degree.findIndex(data=> data == id)
    
    if(findData === -1){
      degree.push(id);
      if(degree.length>1){
        degree.shift()
      }
    }else{
      
    }
    this.setState({
      degree: degree
    })
    if(localStorage.getItem('token')){
      this.props.getWorkerPrograms(this.props.page, this.props.languageId, this.props.params.id, this.props.filterDiscipline, degree)

    }else{
      this.props.getPrograms(this.props.page, this.props.languageId, this.props.params.id,  this.props.filterDiscipline, 'public-programs', '', '', '', degree)
    }
    this.props.setFilterDegree(degree)

  }

  checkChecked(array, id){
    if(array.find(data=> data == id)){
      return true
    }else{
      return false
    }
  }

  cleraArray(){
    if(localStorage.getItem('token')){
        this.props.getWorkerPrograms(this.props.page, this.props.languageId, this.props.params.id, this.props.filterDiscipline, '')
  
      }else{
        this.props.getPrograms(this.props.page, this.props.languageId, this.props.params.id,  this.props.filterDiscipline, 'public-programs', '', '', '', '')
      }
    this.setState({
      degree: []
    })
    this.props.setFilterDegree('')
  }

  applyData(degree, e){

    if(localStorage.getItem('token')){
      this.props.getWorkerPrograms(this.props.page, this.props.languageId, this.props.params.id, this.props.filterDiscipline, degree)

    }else{
      this.props.getPrograms(this.props.page, this.props.languageId, this.props.params.id,  this.props.filterDiscipline, 'public-programs', '', '', '', degree)
    }
    this.props.setFilterDegree(degree)

  }
  openModal(){
    this.setState({
      position:true
    })
    
  }
  componentDidMount(){
    this.props.getEducationDegreeByUni(this.props.params.id, this.props.languageId)
  }
  render() {
   const {degree, position} = this.state;
  
    const {educationDegreeByUni, mainTransateData} = this.props;

    let degreeOprion = []
    for(const data of educationDegreeByUni){
      degreeOprion.push({
        name: data.education_type,
        id: data.education_degree_id
      })
    }
    return (
      <div className='profile_header_btn gap-3'>
        {
          degreeOprion?.map((data, i)=>{
            return(
              <button style={{ color: degree.includes(data?.id)?'white':'black' }} onClick={this.checkDataProps.bind(this, data?.id)} className={`ttnc-ButtonThird text-neutral-700 border 
              border-neutral-200 dark:text-neutral-200 
              dark:border-neutral-700 nc-Button relative h-auto inline-flex 
              items-center justify-center rounded-xl transition-colors
              text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200 
              px-4 py-1 sm:px-3 ${degree.includes(data?.id)? 'bg-primary-6000 text-white':''}`} key={i}>{data?.name}</button>
            )
          })
        }
        
      </div>
    //     <Popover >
                
    //         <Popover.Button 
    //         onClick={this.openModal.bind(this)}
    //         className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
    //           position ? "!border-primary-500 " : ""
    //         }`}
    //         >
    //         <span><span aria-label='mainTransateData.degree'>{mainTransateData?.degree}</span> ({degree.length})</span>
    //         <i className="las la-angle-down ml-2"></i>
    //         </Popover.Button>
    //         {
    //           position?
    //           <Transition
    //             as={Fragment}
    //             enter="transition ease-out duration-200"
    //             enterFrom="opacity-0 translate-y-1"
    //             enterTo="opacity-100 translate-y-0"
    //             leave="transition ease-in duration-150"
    //             leaveFrom="opacity-100 translate-y-0"
    //             leaveTo="opacity-0 translate-y-1"
    //         >
    //           <div id='popover_container'> 
    //         <Popover.Panel className=" z-10 w-screen max-w-sm px-4 mt-3  sm:px-0 lg:max-w-md" >
    //             <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
    //             <div className="relative flex flex-col px-5 py-6 space-y-5 " style={{ maxHeight: '300px', overflow: 'auto' }}>
    //                 {degreeOprion.map((item, i) => (
    //                 <div key={i} className="">
    //                     <Checkbox
    //                     name={item.name}
    //                     id={item.id}
    //                     checked={this.checkChecked(degree, item.id)}
    //                     checkDataProps={this.checkDataProps}
    //                     // label={item.name}
    //                     // subLabel={item.description}
    //                     />
    //                 </div>
    //                 ))}
    //             </div>
    //             <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    
    //                 <button aria-label='mainTransateData.clear' onClick={this.cleraArray.bind(this)} className='ttnc-ButtonThird text-neutral-700 border 
    //                                     border-neutral-200 dark:text-neutral-200 
    //                                     dark:border-neutral-700 nc-Button relative h-auto inline-flex 
    //                                     items-center justify-center rounded-full transition-colors
    //                                     text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
    //                                     px-4 py-2 sm:px-5'   >
    //                     {mainTransateData?.clear}
    //                 </button>
    //                 {/* <button aria-label='mainTransateData.apply' style={{ color: 'white' }} onClick={this.applyData.bind(this, degree)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
    //                                     nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
    //                                     text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-2 sm:px-5  '>
    //                     {mainTransateData?.apply}
    //                 </button> */}
    //             </div>
    //             </div>
    //         </Popover.Panel></div>
    //         </Transition>:null
    //         }
            
    
    // </Popover>
    )
  }
}
const mapStateToProps = (state)=> ({
  languageId: state.Data.languageId,
  mainTransateData: state.Data.mainTransateData,
  educationDegreeByUni: state.Data.educationDegreeByUni,
});
const mapDispatchToProps = {getEducationDegreeByUni, getWorkerPrograms, getPrograms}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(FilterprogramByDegree))