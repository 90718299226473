import React, { Component } from 'react'
import { connect } from 'react-redux'
import BgGlassmorphism from '../components/page_component/BgGlassmorphism'
import SectionHeader from '../components/page_component/SectionHeader'
import {homeAppliedStudents} from '../actions/MainAction'
import AppliedCard from '../components/page_component/AppliedCard'
import LoadMoreBtn from '../components/page_component/LoadMoreBtn'
import Filter from '../components/page_component/accepted_students/Filter'
import SkeletonCard from '../components/page_component/SkeletonCard'
export class AcceptedStudents extends Component {
    state={
        page:1,
        country:'',
        discipline:'',
        company: '',
        degree:''
    }
    componentDidMount(){
        this.props.homeAppliedStudents("all", 1, this.props.languageId)
        window.scrollTo(0, 0)
    }

    loadMoreFunction = (page) => {
        this.setState({
            page: page
        })
        this.props.homeAppliedStudents("all", page, this.props.languageId, this.state.country, this.state.discipline, this.state.degree, this.state.company)

    }
    setMainStates=(name, value)=>{
        this.setState({[name]:value})
    }
  render() {
    const {appliedStudents, mainTransateData, loaderLong} = this.props;
    const {page} = this.state;
    // console.log(appliedStudents)
    return (
        <div className="nc-PageHome relative ">
            <BgGlassmorphism />
            <div className="container relative space-y-24 lg:space-y-28 ">
                {/* <SectionHero /> */}
                <div className="relative ">
                    <div className="nc-SectionGridFeaturePlaces relative pb-50 " >
                        <div className="flex flex-col mb-3 relative">
                            <SectionHeader title={mainTransateData?.accepted_students}  />
                        </div>
                        <Filter setMainStates={this.setMainStates} page={page} />
                        <div  className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
                        {
                            !loaderLong?
                            appliedStudents.map((data, i)=>{
                                return(
                                    <AppliedCard
                                        key={i}
                                        data={data}
                                        // program_id={data?.program_id}
                                    />
                                )
                            }):null
                            }

                            {
                               loaderLong ?
                                <>
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                </>:null
                                }
                        </div>
                        {
                             appliedStudents.length >= 20?
                             <LoadMoreBtn position={appliedStudents.length} loadMoreFunction={this.loadMoreFunction}  />:null
                        }
                    </div>
                </div>
            </div>

        </div>
    )
  }
}
const mapStateToProps = (state) => ({
    languageId: state.Data.languageId,
    appliedStudents: state.Data.appliedStudents,
    mainTransateData: state.Data.mainTransateData,
    loaderLong: state.Data.loaderLong,
});
const mapDispatchToProps = {homeAppliedStudents}
export default connect(mapStateToProps, mapDispatchToProps)(AcceptedStudents)