import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAllRoboUniversity } from '../../actions/AutoLoadActions';
import Searchable from 'react-searchable-dropdown'; 
import ProgramCard from './ProgramCard';
import { getEducationDegree, getEducationLanguage, getCurrency,  getExtraCurricularActivity, getInternationalMedals, insertNewData, getExams,  } from '../../actions/WorkerActions';
import { getAllDisciplines, getPrograms } from '../../actions/MainAction';
import {getUnirepRoleTranslate, mainDashboardTranslate} from '../../actions/TranslateAction';
import MultiSelect from './components/MultiSelect';
import MultiSelectAll from './components/MultiSelectAll';
import ExamsInputAll from './components/ExamsInputAll';
import InputSelect from './components/InputSelect';
import { addAllPrograms, changeStatusAllPrograms } from '../../actions/WorkerActions';
import Swal from 'sweetalert2';
import HighSchoolExams from './components/HighSchoolExams';
import StudyDuration from './components/StudyDuration';
import Loading from '../../components/page_component/Loading';
export class AutoAddPrograms extends Component {
    state={
        programs: [],
        instId:'',
        eduLangid:'',
        autoRender:true,
        deadline: '',
        requiredEduDegree:0,
        applicationFee: '',
        degreeId: '',
        prModalVisible: false,
        searchResult:'',
        gpa:'',
        gpaPercentage:0,
        examPercentage:'',
        disciplineAll: [],
        examsAll:[],
        currencyAll:'',
        feeAmountAll:'',
        feeType:0,
        allUploadProg:[],
        btnDisable:false,
        programHigh:{
            program:{
                a_level:'',
                a_level_percentage:'',
                advanced_placement:'',
                ap_percentage:'',
                ib_diploma:'',
                ib_percentage:'',
                ossd:'',
                ossd_percentage:'',
            }
        },
        studyDuration:{
            program: {
                study_duration:''
            }
        },
        examCheckStatus:0,
        startDates:[''],
        deadlines:[''],
        allProgramSelected: [],
        examRender:true
    }
    componentDidMount(){
        this.props.getAllRoboUniversity();
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.mainDashboardTranslate(this.props.languageId);
        this.props.getEducationDegree(this.props.languageId);
        this.props.getEducationLanguage(this.props.languageId);
        this.props.getExtraCurricularActivity(this.props.languageId);
        this.props.getInternationalMedals(this.props.languageId);
        this.props.getAllDisciplines(this.props.languageId)
        this.props.getCurrency();
        this.props.getExams();
    }
    selectFromSystem=(id) => {
        const programs = this.props.allRoboInst.find(data=> data.id == id);
        this.setState({programs: programs?.programs, instId:programs?.main_uni?.main_uni_id})
        // this.props.getPrograms(1, this.props.languageId, programs?.main_uni?.main_uni_id)
    }
    selectDefaultData=(name, data)=>{
        if(name == 'examPercentage'){
            let newExams = []
            let exams = [...this.state.examsAll];
            for(let i = 0; i < exams.length; i++){
                exams[i].percentage = data;
                newExams.push(exams[i])
            }
            this.setState({examsAll: newExams})
        }
        if(name == 'degreeId'){
            if(this.state.instId != ''){
                this.props.getPrograms(5, this.props.languageId, this.state.instId, '', 'public-programs', '', '', '', data)
            }
        }
        if(name == 'gpaPercentage'){
            let obj = {...this.state.programHigh};
            obj.program.a_level_percentage = data;
            obj.program.ap_percentage = data;
            obj.program.ib_percentage = data;
            obj.program.ossd_percentage = data;
           this.setState({
            programHigh:obj
           })

          
        }
        this.setState({[name]:data})
        this.setState({autoRender:false})
        this.setState({allProgramSelected:[]})

        setTimeout(()=>{
            this.setState({autoRender:true})
        }, 100)
    }

    getCheckResult(program, array){
      
       const incld = program?.name?.includes('(Taught)');
       let programName = ''
       if(incld){
        const splt = program?.name.split(' (Taught)');
      
        programName = splt[0]
       }else{
        programName = program?.name
       }
      
        const check = array.find(item=>item?.name.toLowerCase() == programName?.toLowerCase());
       return check
    }

    changeInpValue=(name, value)=>{
        
        this.setState({disciplineAll:value})
        this.setState({autoRender:false})
        this.setState({allProgramSelected:[]})

        setTimeout(()=>{
            this.setState({autoRender:true})
        }, 100)
    }

    setExams=(exams, name)=>{
       
        let exmass = [];
        for(let i = 0; i < exams.length; i++){
            if(exams[i].visible){
                exmass.push({
                    ...exams[i],
                    exam: {exam_type: exams[i]?.exam_type},
                    percentage: this.state.examPercentage
                })
            }
        }
        
        this.setState({examsAll: exmass})
        this.setState({autoRender:false})
        this.setState({allProgramSelected:[]})

        setTimeout(()=>{
            this.setState({autoRender:true})
        }, 100)
    }
    changeInpValueMulti=(name, value)=>{
       
        this.setState({[name]:value})
        this.setState({autoRender:false})
        this.setState({allProgramSelected:[]})

        setTimeout(()=>{
            this.setState({autoRender:true})
        }, 100)
    }

    setDataToArr=(data)=>{
       
        let arr = [...this.state.allUploadProg];
        
        const checkIndex = arr.findIndex(item=>item?.id == data?.id);
        if(checkIndex == -1){
            arr.push(data);
        }else{
            arr.splice(checkIndex, 1)
        }
        
        this.setState({allUploadProg:arr})
    }
    addAllPrograms(data){

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Added it!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.setState({btnDisable:true})
                let allData = [];
                let ids = [];
                for(let i = 0; i <data.length; i++){
                    let params = {
                        ...data[i]?.params,
                        dates: data[i]?.dates,
                        setAsRequired: data[i]?.setAsRequired
                    }
                    ids.push(data[i].id)
                    allData.push(params)
                }
              
                this.props.addAllPrograms(JSON.stringify(allData))
                .then(resp=>{
                    if(resp == 'success'){
                        this.props.changeStatusAllPrograms(ids)
                        .then(resp=>{
                            Swal.fire(
                                'Added!',
                                'Programs Added successfully.',
                                'success'
                              )
                            this.props.getAllRoboUniversity();
                            this.setState({btnDisable:true})
                            this.setState({allUploadProg:[]})
                        })
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                          )
                    }
                    
                })
              
            }
          })
       
        
    }
    changeInpValueHigh=(name, value)=>{
        let obj = {...this.state.programHigh};
        const check = name.includes('.');
        if(check){
            const groups = name.split('.');
            if(groups.length == 2){
            obj[groups[1]] = value
            }else if(groups.length == 3){
            obj[groups[1]][groups[2]] = value
            }
            
        }else{
            obj[name] = value
        }
        this.setState({
            programHigh:obj
        })
 
    }
    changeInpValueDuration=(name, value)=>{
     
        this.setState({
            studyDuration:{
                program: {
                    study_duration:value
                }
            },
        })
        this.setState({autoRender:false});
        this.setState({allProgramSelected:[]})

        setTimeout(()=>{
            this.setState({autoRender:true})
        }, 100)
    }
    changeExamCheckStatus(e){
        
        this.setState({examCheckStatus:e.target.checked})
        this.setState({autoRender:false})
        this.setState({allProgramSelected:[]})

        setTimeout(()=>{
            this.setState({autoRender:true})
        }, 100)
    }

    addStartDate(dates, name){
        let arr = [...dates];
        arr.push('');
        this.setState({[name]: arr})
      
        this.setState({autoRender:false});
        this.setState({allProgramSelected:[]})

        setTimeout(()=>{
            this.setState({autoRender:true})
        }, 100)
    }

    deleteStartDate(index, dates, name){
        dates.splice(index, 1)
        this.setState({[name]: dates})
        this.setState({autoRender:false});
        this.setState({allProgramSelected:[]})

        setTimeout(()=>{
            this.setState({autoRender:true})
        }, 100)
    }
    setStartDate(e, index, dates, name){
        dates[index] = e.target.value;
        this.setState({[name]:dates});
        this.setState({autoRender:false});
        this.setState({allProgramSelected:[]})
        setTimeout(()=>{
            this.setState({autoRender:true})
            
        }, 100)
    }

    selectAllPrograms = (data) => {
      
        let arr = this.state.allProgramSelected;
        arr.push(data)
        this.setState({allProgramSelected: arr})
        this.setState({allUploadProg:arr})
    }

    selectAllData(){
        if(this.state.allUploadProg.length === 0){
            this.setState({allUploadProg:this.state.allProgramSelected})
        }else{
            this.setState({allUploadProg:[]})
        }
        
    }
  render() {
    const {allRoboInst, educationLanguage, educationDegree, systemPrograms, currency, unirepRoleTranslate, loader, loaderLong} = this.props;
    const {programs,startDates, allUploadProg, examCheckStatus, instId, eduLangid,examRender, autoRender,studyDuration, deadlines,  deadline,disciplineAll,examsAll=[],programHigh,feeAmountAll,feeType, currencyAll, requiredEduDegree, applicationFee, degreeId, prModalVisible, searchResult, gpa, gpaPercentage, examPercentage}  = this.state;
    let roboInstOpt = [];
    let languageOption=[];
    const degreeOption = [];
    const currencyOption = [];
    let fee_type_options = [
    
        {
          label: unirepRoleTranslate?.yearly,
          value: '0'
        },
        {
          label: unirepRoleTranslate?.monthly,
          value: '1'
        },
      ]
    for(const data of allRoboInst){
      
        if(data?.main_uni){
          roboInstOpt.push({
                label: data?.name?.length >40?`${data?.name.substring(0, 40)}...`:data?.name,
                value: data?.id
            })  
        }
        
    }
   
    for(const property of educationLanguage){
        languageOption.push({
          label: property?.language,
          value: property?.education_language_id
        })
    }
    for(const property of educationDegree){
        degreeOption.push({
          label: property?.education_type,
          value: property?.education_degree_id
        })
    }
    for(const property of currency){
        currencyOption.push({
          label: property?.currency,
          value: property?.id
        })
      }
    return (
      <div className='container'>
          <div  className='flex gap-3 items-center'>
            <div style={{ zIndex:990 }}>
                    <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                        Select Available University
                    </label>
                    <div style={{ width: '400px' }} className={`block w-full border-neutral-200  
                        focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                        dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                        dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                    > 
                        <Searchable 
                            options={roboInstOpt}
                            onSelect={this.selectFromSystem.bind(this)}
                            placeholder={"Select Available University"}
                        
                        />
                    </div>
                </div>
                <div  style={{ zIndex:990 }}>
                    <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                        Select Degree
                    </label>
                    <div style={{ width: '400px' }} className={`block w-full border-neutral-200  
                        focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                        dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                        dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                    > 
                        <Searchable 
                            options={degreeOption}
                            onSelect={this.selectDefaultData.bind(this, "degreeId")}
                            placeholder={" Select Degree"}
                        
                        />
                    </div>
                </div>
                {
                    systemPrograms?.length != 0?
                    <button onClick={()=>this.setState({prModalVisible:true})} className='border rounded-full p-3 mt-4 text-sm bg-blue-700 text-white'>
                        Show System Program
                    </button>:null
                }
                
          </div>
            <div className='border p-4 rounded-2xl mt-4'>
                <h3>Set deafult data</h3>
                {/* <MultiSelectAll changeInpValue={this.changeInpValue} /> */}
                <div className='mt-3 grid grid-cols-3 gap-3'>
                    <div >
                        <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                            Select Education Language for all
                        </label>
                        <div style={{ width: '300px' }} className={`block w-full border-neutral-200  
                            focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                            dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                            dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                        > 
                            <Searchable 
                                options={languageOption}
                                onSelect={this.selectDefaultData.bind(this, "eduLangid")}
                                placeholder={"Select Education Language for all"}
                            
                            />
                        </div>
                    </div>
                    <div >
                        <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                            Select Required Edu Degree for all
                        </label>
                        <div style={{ width: '300px' }} className={`block w-full border-neutral-200  
                            focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                            dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                            dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                        > 
                            <Searchable 
                                options={degreeOption}
                                onSelect={this.selectDefaultData.bind(this, "requiredEduDegree")}
                                placeholder={"Select Required Edu Degree for all"}
                            
                            />
                        </div>
                    </div>
                    {/* <div>
                        <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                        Deadline for all
                        </label>
                        <input  onChange={(e)=>this.selectDefaultData("deadline", e.target.value)} type={'date'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 
                            focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                            dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                            dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                        
                            value={deadline}
                        /> 
                    </div> */}
                    <div>
                        <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                        App fee for all
                        </label>
                        <input  onChange={(e)=>this.selectDefaultData("applicationFee", e.target.value)} type={'number'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 
                            focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                            dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                            dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                        
                            value={applicationFee}
                        /> 
                    </div>
                    <div>
                        <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                        GPA for all
                        </label>
                        <input  onChange={(e)=>this.selectDefaultData("gpa", e.target.value)} type={'number'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 
                            focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                            dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                            dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                        
                            value={gpa}
                        /> 
                    </div>
                    <div>
                        <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                        GPA % for all
                        </label>
                        <input  onChange={(e)=>this.selectDefaultData("gpaPercentage", e.target.value)} type={'number'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 
                            focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                            dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                            dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                        
                            value={gpaPercentage}
                        /> 
                    </div>
                    <div>
                        <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                        Exam % for all
                        </label>
                        <input  onChange={(e)=>this.selectDefaultData("examPercentage", e.target.value)} type={'number'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 
                            focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                            dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                            dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                        
                            value={examPercentage}
                        /> 
                    </div>
                    <div >
                        <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                            Currency for all
                        </label>
                        <div style={{ width: '300px' }} className={`block w-full border-neutral-200  
                            focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                            dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                            dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                        > 
                            <Searchable 
                                options={currencyOption}
                                onSelect={this.selectDefaultData.bind(this, "currencyAll")}
                                placeholder={"Currency for all"}
                            
                            />
                        </div>
                    </div>
                   
                    <ExamsInputAll examPercentage={examPercentage} value={[]} name="exams" setExams={this.setExams} />
                    
                    
                    <InputSelect
                        label={'Fee Amunt'}
                        value={feeAmountAll}
                        name="feeAmountAll"
                        placeholder={'Fee Amount'}
                        selectValue={'0'}
                        selectName={'feeType'}
                        options={fee_type_options}
                        changeInpValue={this.changeInpValueMulti}
                        
                      />
                      <HighSchoolExams  changeInpValue={this.changeInpValueHigh} editProgram={programHigh}/>
                      <div className='flex gap-4 h-5'>
                        <input type='checkbox' onChange={this.changeExamCheckStatus.bind(this)} defaultChecked={examCheckStatus} id="exmCheckabc" />
                        <label className='cursor-pointer' htmlFor='exmCheckabc'>Exam check</label>
                      </div>
                      <StudyDuration editProgram={studyDuration} changeInpValue={this.changeInpValueDuration} value={studyDuration?.program?.study_duration} />
                </div>
                <div className='mt-5'>
                    <button onClick={this.addStartDate.bind(this, startDates, 'startDates')} className='border rounded-xl bg-blue-700 text-white font-semibold p-2 mb-3'>Add Start Date</button>
                    <div className='mt-3 grid grid-cols-3 gap-3'>
                    {
                        startDates.map((data, i)=>{
                            return(
                                <div key={i} > 
                                    <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                                        Start Date
                                    </label>
                                    <div className='flex items-center'>
                                        <input  onChange={(e)=>this.setStartDate(e, i, startDates, 'startDates')} type={'date'}
                                            className={`block w-full border-neutral-200 focus:border-primary-300 
                                            focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                                            dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                                            dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                                        
                                            value={data}
                                        /> 
                                        {
                                            i>0?
                                            <button onClick={this.deleteStartDate.bind(this, i, startDates, 'startDates')} className='border rounded-xl bg-blue-700 text-white font-semibold p-1 ml-2' >-</button>:null
                                        }
                                    </div>
                                </div> 
                            )
                        })
                    }
                    </div>
                    
                </div>
                <div className='mt-5'>
                    <button onClick={this.addStartDate.bind(this, deadlines, 'deadlines')} className='border rounded-xl bg-blue-700 text-white font-semibold p-2 mb-3'>Add Deadline</button>
                    <div className='mt-3 grid grid-cols-3 gap-3'>
                    {
                        deadlines.map((data, i)=>{
                            return(
                                <div key={i} > 
                                    <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                                        Deadline
                                    </label>
                                    <div className='flex items-center'>
                                        <input  onChange={(e)=>this.setStartDate(e, i, deadlines, 'deadlines')} type={'date'}
                                            className={`block w-full border-neutral-200 focus:border-primary-300 
                                            focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                                            dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                                            dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 `}
                                        
                                            value={data}
                                        /> 
                                        {
                                            i>0?
                                            <button onClick={this.deleteStartDate.bind(this, i, deadlines, 'deadlines')} className='border rounded-xl bg-blue-700 text-white font-semibold p-1 ml-2' >-</button>:null
                                        }
                                    </div>
                                </div> 
                            )
                        })
                    }
                    </div>
                    
                </div>
              
            </div>
            {
                allUploadProg?.length != 0?
            <div className='mt-5 flex items-center gap-5'>
                
                    <h3 className='text-xl font-semibold'> Selected programs  ({allUploadProg?.length})</h3>
                    <button onClick={this.addAllPrograms.bind(this, allUploadProg)} className='border rounded-xl text-sm font-semibold bg-blue-700 text-white p-2'> Add All</button>
            </div>:null
                }
                <div className='mt-4 mb-3'>
                    <button onClick={this.selectAllData.bind(this)} className='border rounded-xl text-sm font-semibold bg-blue-700 text-white p-2'> {allUploadProg.length == 0? 'Select': 'Unselect'} All</button>
                </div>
            <div>
                {/* {
                    autoRender &&programs?.map((data, i)=>{
                        return(
                            <ProgramCard requiredEduDegree={requiredEduDegree} deadline={deadline} eduLangid={eduLangid} institution_id={instId} data={data} key={i} />
                        )
                    })
                } */}
                {
                    autoRender && allRoboInst?.map((data, i)=>{
                        if(data?.main_uni?.main_uni_id == instId){
                            return(
                                data?.programs.map((item, j)=>{
                                    if(item?.deleted_status == 0 && item?.qualification_type == degreeId){
                                  
                                            return(
                                                <ProgramCard 
                                                    setDataToArr={this.setDataToArr}
                                                    examPercentage={examPercentage} 
                                                    gpaPercentage={gpaPercentage} 
                                                    gpa={gpa} 
                                                    checkExists={this.getCheckResult(item, systemPrograms)} 
                                                    applicationFee={applicationFee} 
                                                    requiredEduDegree={requiredEduDegree} 
                                                    deadline={deadline} eduLangid={eduLangid} 
                                                    institution_id={instId} 
                                                    disciplineAll={disciplineAll}
                                                    examsAll={examsAll}
                                                    currencyAll={currencyAll}
                                                    feeAmountAll={feeAmountAll}
                                                    feeType={feeType}
                                                    allUploadProg={allUploadProg}
                                                    highSchExAll={programHigh?.program}
                                                    examCheckStatus={examCheckStatus}
                                                    startDates={startDates}
                                                    deadlines={deadlines}
                                                    studyDuration={studyDuration?.program?.study_duration}
                                                    selectAllPrograms={this.selectAllPrograms}
                                                    data={item} key={j} />
                                            )
                                    
                                    }
                                    
                                })
                            )
                            
                        }
                    })
                }
            </div>
            {
                prModalVisible?
                <div className='background_shadow flex items-center justify-center'>
                    <div style={{ width: '600px', maxHeight: '70vh' }} className='bg-white p-5 rounded-xl'>
                        <div className='flex justify-between gap-3'>
                            <input value={searchResult} onChange={(e)=>this.setState({searchResult:e.target.value})} type='text' placeholder='search' />
                            <button onClick={()=>this.setState({prModalVisible:false})} className='border rounded-2xl p-3 text-sm'>Close</button>
                        </div>
                        <div style={{ overflow: 'auto', maxHeight: '60vh' }} >
                            <ul style={{ overflow: 'auto' }}>
                                {
                                    systemPrograms?.map((item, i)=>{
                                        if(item?.name?.toLowerCase()?.includes(searchResult.toLowerCase())){
                                            return(
                                                <li key={i} className='mt-2'>{item?.name}</li>
                                            )
                                        }
                                        
                                    })
                                }
                            
                            </ul>
                        </div>
                    </div>
                </div>:null
            }
            {
                 loader || loaderLong? 
                 <Loading />:null
            }
      </div>
    )
  }
}

const mapStateToProps = (state) =>({
    allRoboInst: state.Data.allRoboInst,
    languageId: state.Data.languageId,
    educationLanguage: state.Data.educationLanguage,
    educationDegree: state.Data.educationDegree,
    systemPrograms: state.Data.programs,
    currency: state.Data.currency,
    unirepRoleTranslate: state.Data.unirepRoleTranslate,
    loader: state.Data.loader,
    loaderLong: state.Data.loaderLong,
});
const mapDispatchToProps = {getAllRoboUniversity,   getEducationDegree, 
    getEducationLanguage, 
    getCurrency, 
    getExtraCurricularActivity,
     getInternationalMedals, 
     insertNewData,
     getUnirepRoleTranslate,
     getAllDisciplines,
     getExams,
     getPrograms,
     changeStatusAllPrograms,
     addAllPrograms,
     mainDashboardTranslate}

export default connect(mapStateToProps, mapDispatchToProps)(AutoAddPrograms)
