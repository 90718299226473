import React, { Component } from 'react'

export class File extends Component {
    uploadImage (e){
      for(const img of e.target.files){
        
        this.props.uploadImages(img, this.props.images)
      }
      
       
    }
  render() {
    const {images, translate} = this.props
    return (
        <div className="space-y-8">
        <div>
          <span className="text-lg font-semibold">{translate?.inst_images}</span>
          <div className="mt-5 ">
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    <span>{translate?.upload_file}</span>
                    <input
                      accept='image/jpg, image/png, image/jpeg, image/webp'
                      multiple={true}
                      disabled={images.filter(x=> !x.delete ).length === 5? true: false}
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={this.uploadImage.bind(this)}
                    />
                  </label>
                  <p className="pl-1">{translate?.drop_drag}</p>
                </div>
                <p className="text-xs text-neutral-500 dark:text-neutral-400">
                  {translate?.format}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default File