import React, { Component } from 'react'


export class NextPrev extends Component {
  render() {
    const {onlyNext, onlyPrev, className, btnClassName, onClickNextPrev, currentItem} = this.props;
    return (
        <div
        className={`nc-NextPrev relative flex items-center text-neutral-900 dark:text-neutral-300 ${className}`}
        data-nc-id="NextPrev"
        data-glide-el="controls"
      >
        {!onlyNext && (
          <button
            className={`${btnClassName} ${
              !onlyPrev ? "mr-[6px]" : ""
            } bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 `}
            onClick={()=>onClickNextPrev('prev', currentItem)}
            title="Prev"
            data-glide-dir="<"
          >
            <i className="las la-angle-left"></i>
          </button>
        )}
        {!onlyPrev && (
          <button
            className={`${btnClassName} bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 `}
            onClick={()=>onClickNextPrev('next', currentItem)}
            title="Next"
            data-glide-dir=">"
          >
            <i className="las la-angle-right"></i>
          </button>
        )}
      </div>
    )
  }
}

export default NextPrev