import React, { Component } from 'react'
import { connect } from 'react-redux';
import Button from './Button'

export class Buttons extends Component {
    state={
        buttonType: 'universities'
    }
    changeData = (item) =>{
        this.props.changePage(item);
        this.setState({
            buttonType: item
        })
    }
  render() {
    const {buttonType} = this.state;
    const {mainTransateData} = this.props;
    return (
        <div className='grid_btns'>
            <Button label="mainTransateData.country_universities" title={mainTransateData?.country_universities} className={buttonType == 'universities'? 'bg-primary-6000': ''} type="universities" changeData={this.changeData} />
            <Button label="mainTransateData.country_about" title={mainTransateData?.country_about} className={buttonType == 'about'? 'bg-primary-6000': ''} type="about" changeData={this.changeData} />
            
            <Button label="mainTransateData.country_visa" title={mainTransateData?.country_visa} className={buttonType == 'visa'? 'bg-primary-6000': ''} type="visa" changeData={this.changeData} />
            <Button label="mainTransateData.country_working" title={mainTransateData?.country_working} className={buttonType == 'working'? 'bg-primary-6000': ''} type="working" changeData={this.changeData} /> 
            <Button label="mainTransateData.faqs" title={mainTransateData?.faqs} className={buttonType == 'faq'? 'bg-primary-6000': ''} type="faq" changeData={this.changeData} /> 
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    mainTransateData: state.Data.mainTransateData,
})
export default connect(mapStateToProps)(Buttons)