import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProgramData, getStudentInfo, applyToProogram } from '../../../actions/MainAction';
import ImageHeader from '../layouts/ImageHeader';
import ProgramDataCard from './components/ProgramDataCard';
import {mainDashboardTranslate, getUnirepRoleTranslate} from '../../../actions/TranslateAction';
import HeaderImages from './components/HeaderImages';
import { getObject } from '../../../actions/makeFilterObj';
import { percentage } from './components/CalcPercentage';
import Percentage from '../../page_component/simulator_components/Percentage';
import BlurChances from './components/BlurChances';
import LoginComponent  from './components/login_register/LoginComponent';
import RelatedPrograms from './related_programs/RelatedPrograms'
import moment from 'moment';
import { getAllPublicConverters } from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
function withParams(Component){
    return props => <Component {...props} params={useParams()} />
}
export class SingleProgram extends Component {
    state={
        filter: '',
        exams: '',
        user: '',
        btnVisible: false
    }
    componentDidMount(){
      this.props.getAllPublicConverters();

        window.scrollTo(0, 0)
        if(localStorage.getItem('token')){
            this.props.getProgramData(this.props.languageId, this.props.params.id, 'program-data')
        }else{
            
            this.props.getProgramData(this.props.languageId, this.props.params.id, 'public-program-data')
        }
        
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.mainDashboardTranslate(this.props.languageId);
        
        
    }
    componentDidUpdate(prevProps, prevState){
       
        if(prevState.user != this.props.user){
            this.setState({
                user: this.props.user
            })
            if(this.props.user !== ''){
                if(this.props.user.role == 'student'){
                    if(localStorage.getItem('token')){
        
                        this.props.getStudentInfo()
                        .then(resp=>{
                          const data = getObject(resp)
                         
                          this.setState({
                              filter: data.obj,
                              exams: data.exams
                          })
                        })
              
                      }
                }
            }
        }
       

    }
    getDuration(data){
        if(data){
            if(data.includes('|')){
            const year = data.split('|')[0];
            const dr = data.split('|')[1] == '1'? 'Years': data.split('|')[1] == '2'? 'Months': 'Weeks'
            return year + " " +  dr
        }else{
            return data
        }
        }
        
    }
    getPay( fee, scholarship){
        if(scholarship !== ''){
            return +fee - +scholarship
        }else{
            return fee
        }
    }
    getFeeAmount(x){
        if(x){
           
             var parts = x?.toString().split(".");
            parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");
         
            return parts.join(",");
        }
    }

    getRequiredPosition(inPossibleChances, possibleChances){
        let position = false
        inPossibleChances?.map((data, i)=>{
            if(data.type == 'exam' && data.exam_type == '1' && !possibleChances.find(x=> x.exam_type == '1')){
                position = true
            }else if(data.type !== 'exam' || data.exam_type == '0'){
               position = true
            }
            
        })
    }

    getAfterRedirect = (id) => {
        window.scrollTo(0, 0)

        if(localStorage.getItem('token')){
            this.props.getProgramData(this.props.languageId, id, 'program-data')
        }else{
            
            this.props.getProgramData(this.props.languageId, id, 'public-program-data')
        }
        
    }

    applyNowBtn(program, e){
        this.setState({
            btnVisible: true
        })
        let formData = new FormData();
            formData.append('program_id', program.program_id);
            formData.append('worker_id', program.user_id
        );
        this.props.applyToProogram(formData)
        .then(resp=>{
            this.setState({
                btnVisible: false
            })
            window.open(window.location.origin)
            if(localStorage.getItem('token')){
                this.props.getProgramData(this.props.languageId, this.props.params.id, 'program-data')
            }else{
                
                this.props.getProgramData(this.props.languageId, this.props.params.id, 'public-program-data')
            }
            
        })
    }
    getSchoolarship(currency, type, amount, fee){

        if(type == 'amount'){
           return this.getFeeAmount(amount)+ " "+ currency
        }else{
            return this.getFeeAmount((fee*amount/100).toFixed(0))+" "+currency
        }
    }
    getSchoolarship2(currency, type, amount, fee){

        if(type == 'amount'){
           return amount
        }else{
            return (fee*amount/100).toFixed(0)
        }
    }

    applyNowBtnForEducon(program){
        this.props.changeStateValue({
            name:'educonApplyModal',
            value: {
                position:true,
                program_id: program?.program_id
            }
        })
    }
  render() {
    const {programData, user, mainTransateData, simulatorLogin, allPublicConverters} = this.props;
    const {filter, exams, btnVisible} = this.state;
  
    let prcntg = 0;
    let possibleChances = [];
    let inPossibleChances = [];
    if(programData !== ''){
        if(filter !== '' && exams !== ''){
            //    console.log( percentage(programData, filter, exams))
               prcntg = percentage(programData, filter, exams, allPublicConverters).percentage
               possibleChances = percentage(programData, filter, exams, allPublicConverters).possiblePercentages;
               inPossibleChances = percentage(programData, filter, exams, allPublicConverters).inPossiblePercentages;
            }else{
                prcntg = 0;
            }
    }
    return (
        <div  className={`ListingDetailPage nc-ListingStayDetailPage `} data-nc-id="ListingStayDetailPage">
        
        <HeaderImages image={programData?.program?.image_full_url} city_id={programData?.institution_information?.city_id} uni_id={programData?.institution_information?.id} country_id={programData?.institution_information?.country_id} title={programData.name} uni={programData?.institution} country={programData?.country} city={programData?.city} />
        
        {
            programData !== ''?

       
        <main className="container relative z-10 mt-11 flex flex-col lg:flex-row " >
            <div className="w-full lg:w-5/5 grid grid-cols-1 gap-5 xl:grid-cols-3 border rounded-2xl p-3" >
               
                {
                    user !== '' && user.role == 'student'?
                    <div className="listingSection__wrap  border rounded-2xl p-4">
                        <h2 className='font-medium' aria-label='mainTransateData.admission_chances'>{mainTransateData?.admission_chances}</h2>
                        <Percentage prcntg={prcntg} />
                        <div>
                            {/* {possibleChances.length !== 0?<h2 className='font-medium' style={{ fontWeight: '600' }} aria-label='mainTransateData.chances_made'>{mainTransateData?.chances_made}</h2>:null }
                            
                            <ul>
                                {
                                    possibleChances?.map((data, i)=>{
                                        return(
                                            <li style={{ fontSize: '14px',  borderBottom: '0.3px dashed gray', }} className='flex justify-between mt-4' key={i}>{data.title} <span>({data.percentage}%)</span></li>
                                        )
                                    })
                                }
                                
                            </ul> */}
                            {this.getRequiredPosition(inPossibleChances, possibleChances)? <h2 className='font-medium' style={{ fontWeight: '600' }} aria-label='mainTransateData.chances_made'>{mainTransateData?.chances_made}</h2>:null}
                            
                            <ul>
                                {
                                    inPossibleChances?.map((data, i)=>{
                                        if(data.type == 'exam' && data.exam_type == '1' && !possibleChances.find(x=> x.exam_type == '1')){
                                            return(
                                                <li style={{ fontSize: '14px',  borderBottom: '0.3px dashed gray', }} className='flex justify-between mt-4' key={i}>{data.title} </li>
                                            )
                                        }else if(data.type !== 'exam' || data.exam_type == '0'){
                                            return(
                                                <li style={{ fontSize: '14px',  borderBottom: '0.3px dashed gray', }} className='flex justify-between mt-4' key={i}>{data.title} </li>
                                            )
                                        }
                                        
                                    })
                                }
                                
                            </ul>
                            <div className='flex justify-center mt-5'>
                                {
                                    user !== '' && user.role == 'student' && !programData?.apply?
                                    <button disabled={btnVisible} onClick={this.applyNowBtn.bind(this, programData)} className='apply_now_btn'>{mainTransateData?.apply_now}</button>:
                                    <button className='apply_now_btn'>{mainTransateData?.applied}</button>
                                }
                                
                            </div>
                        </div>
                    </div>:user == ''? <BlurChances />:
                    user != '' && user?.role == "edu_consultant"?
                    <div className="listingSection__wrap  border rounded-2xl p-4">
                        <h2 className='font-medium' aria-label='mainTransateData.admission_chances'>{mainTransateData?.admission_chances}</h2>
                        <Percentage prcntg={0} />
                        <div>
                            <h3 className='text-center font-semibold text-xl'>Apply For Students</h3>
                           
                            <div className='flex justify-center mt-5'>
                            <button disabled={btnVisible} onClick={this.applyNowBtnForEducon.bind(this, programData)} className='apply_now_btn'>{mainTransateData?.apply_now}</button>
                            </div>
                        </div>
                    </div>:null
                }
                
                <div >
                    <div className='border rounded-2xl p-4'>
                        <h2 className='font-medium  ' style={{ fontWeight: '600' }} aria-label='mainTransateData.program_fact'>{mainTransateData?.program_fact}</h2>
                        <ol >
                            <li  style={{ fontSize: '14px', }} className='flex justify-between mt-4 '>
                               <span>1. <span aria-label='mainTransateData.program'>{mainTransateData?.program}</span>:</span>  <span>{programData?.education_degree}</span>
                            </li>
                            <li  style={{ fontSize: '14px',  }} className='flex justify-between mt-4 '>
                                <span>2. <span aria-label='mainTransateData.duration'>{mainTransateData?.duration}</span>:</span> <span>{this.getDuration(programData?.program?.study_duration)}</span>
                            </li>
                            <li  style={{ fontSize: '14px',  }} className='flex justify-between mt-4 '>
                                <span>3. <span aria-label='mainTransateData.language'>{mainTransateData?.language}</span>:</span> <span>{programData?.education_language}</span>
                            </li>
                        </ol>
                    </div>
                    <div className='mt-5 border rounded-2xl p-4'>
                        <h2 className='font-medium  ' style={{ fontWeight: '600' }} aria-label='mainTransateData.date_facts'>{mainTransateData?.date_facts}</h2>

                        <ol id='test' >
                            <li  style={{ fontSize: '14px', }} className='flex justify-between mt-4 '>
                                <span>1. <span aria-label='mainTransateData.start_date'>{mainTransateData?.start_date}</span>:</span> <span>{moment(programData?.program?.start_date).format("D MMMM")}</span>
                            </li>
                            <li  style={{ fontSize: '14px',  }} className='flex justify-between mt-4 '>
                                <span> 2. <span aria-label='mainTransateData.deadline'>{mainTransateData?.deadline}</span>:</span> <span>{moment(programData?.program?.deadline).format("D MMMM")}</span>
                            </li>
                            {
                                programData?.program?.other_dates.map((date, index)=>{
                                    return(
                                        <>
                                            <li key={date.id} style={{ fontSize: '14px', }} className='flex justify-between mt-4 '>
                                               <span> {index+3}. <span aria-label='mainTransateData.start_date'> {mainTransateData?.start_date}</span>:</span> <span>{moment(date?.start_date).format("D MMMM")}</span>
                                            </li>
                                            <li key={index} style={{ fontSize: '14px',  }} className='flex justify-between mt-4 '>
                                                <span>{index+4}. <span aria-label='mainTransateData.deadline'>{mainTransateData?.deadline}</span>:</span> <span>{moment(date?.deadline).format("D MMMM")}</span>
                                            </li>
                                        </>
                                    )
                                })
                            }
                        </ol>
                    </div>
                    <div className='mt-5 border rounded-2xl p-4'>
                        <h2 className='font-medium  ' style={{ fontWeight: '600' }} aria-label='mainTransateData.price_facts'>{mainTransateData?.price_facts}</h2>

                        <ol>
                            <li  style={{ fontSize: '14px', }} className='flex justify-between mt-4 '>
                                <span>1. <span aria-label='mainTransateData.tuition_fee'>{mainTransateData?.tuition_fee}</span>: </span><span>{this.getFeeAmount(programData?.program?.fee_amount)} {programData?.currency} </span>
                            </li>
                            <li  style={{ fontSize: '14px',  }} className='flex justify-between mt-4 '>
                                <span>2. <span aria-label='mainTransateData.schoolarship'>{mainTransateData?.schoolarship}</span>:</span> 
                                <span> {programData?.program?.schoolarship_option && programData?.program?.schoolarship_option != ''?this.getSchoolarship(programData?.currency, programData?.program?.schoolarship_type, programData?.program?.schoolarship_option, programData?.program?.fee_amount):'0 '+programData?.currency}</span>
                                {/* <span> {programData?.program?.schoolarship_option !== ''?this.getFeeAmount(programData?.program?.schoolarship_option): 0} {programData?.program?.schoolarship_option !== ''?programData?.currency: ''}</span> */}
                            </li>
                            <li  style={{ fontSize: '14px',  }} className='flex justify-between mt-4 '>
                                <span>3. <span aria-label='mainTransateData.you_pay'>{mainTransateData?.you_pay}</span>:</span> <span>{this.getFeeAmount(this.getPay(programData?.program?.fee_amount, programData?.program?.schoolarship_option != ''?this.getSchoolarship2(programData?.currency, programData?.program?.schoolarship_type, programData?.program?.schoolarship_option, programData?.program?.fee_amount):''))+ " "+programData?.currency}</span>
                            </li>
                            <li  style={{ fontSize: '14px',  }} className='flex justify-between mt-4 '>
                                <span>4. <span aria-label='mainTransateData.application_fee'>{mainTransateData?.application_fee}</span>:</span> <span>{programData?.program?.application_fee} {programData?.currency}</span>
                            </li>
                        </ol>
                    </div>
                    
                </div>
                <div >
                    <div className='border rounded-2xl p-4'>
                        <h2 className='font-medium ' style={{ fontWeight: '600' }} aria-label='mainTransateData.entry_requiremen'>{mainTransateData?.entry_requirement}</h2>
                        <ol style={{ listStyleType: 'decimal' }}>
                            <li  style={{ fontSize: '14px',  }} className='flex justify-between mt-4 '>
                                <span>1. <span aria-label='mainTransateData.req_edu_level'>{mainTransateData?.req_edu_level}</span>:</span> <span>{programData?.required_education_degree}</span>
                            </li>
                            {
                                programData?.program?.gpa !== ''?
                                <li  style={{ fontSize: '14px',  }} className='flex justify-between mt-4 '>
                                    <span>2. <span aria-label='mainTransateData.required_gpa'>{mainTransateData?.required_gpa}</span>:</span> <span>{ programData?.program?.gpa}</span>
                                </li>:null
                            }
                        </ol>
                    </div>
                    {
                        programData?.program?.exam_check == 1?
                        
                            programData?.program?.a_level !== '' ||
                            programData?.program?.advanced_placement !== '' ||
                            programData?.program?.ib_diploma !== '' ||
                            programData?.program?.ossd !== '' ?
                            <div className="relative text-center mt-2 mb-2">
                                <span aria-label='mainTransateData.or' className="relative z-10 inline-block px-4 font-medium text-sm bg-white mt-0 dark:text-neutral-400 dark:bg-neutral-900">
                                {mainTransateData?.or}
                                </span>
                                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
                            </div>:null
                        :null
                    }
                    
                    {
                         programData?.program?.exam_check == 1?
                        programData?.program?.a_level !== '' ||
                        programData?.program?.advanced_placement !== '' ||
                        programData?.program?.ib_diploma !== '' ||
                        programData?.program?.ossd !== '' ?
                        <div className='border rounded-2xl p-4'>
                            <h2 className='font-medium' style={{ fontWeight: '600' }} aria-label='mainTransateData.int_diplomas'>{mainTransateData?.int_diplomas}</h2>
                            <ol style={{ listStyleType: 'decimal' }}>
                                {
                                     programData?.program?.a_level !== '' ?
                                    <li  style={{ fontSize: '14px', }} className='flex justify-between mt-4 '>
                                       <span> 1. <span aria-label='mainTransateData.a_level'>{mainTransateData?.a_level}</span>: </span><span>{programData?.program?.a_level}</span>
                                    </li>:null
                                }
                                {
                                     programData?.program?.ib_diploma !== '' ?
                                    <li  style={{ fontSize: '14px', }} className='flex justify-between mt-4 '>
                                        <span>2. <span aria-label='mainTransateData.ib'>{mainTransateData?.ib}</span>:</span> <span>{programData?.program?.ib_diploma}</span>
                                    </li>:null
                                }
                                {
                                     programData?.program?.advanced_placement !== '' ?
                                    <li  style={{ fontSize: '14px', }} className='flex justify-between mt-4 '>
                                        <span>3. <span aria-label='mainTransateData.ap'>{mainTransateData?.ap}</span>: </span><span>{programData?.program?.advanced_placement}</span>
                                    </li>:null
                                }
                                {
                                     programData?.program?.ossd !== '' ?
                                    <li  style={{ fontSize: '14px', }} className='flex justify-between mt-4 '>
                                       <span> 4. <span aria-label='mainTransateData.ossd'>{mainTransateData?.ossd}</span>:</span> <span>{programData?.program?.ossd}</span>
                                    </li>:null
                                }
                                
                               
                            </ol>
                        </div>:null:null
                       
                    }
                    {
                       programData?.program?.specialty_exams?.length !== 0 || programData?.activity?.length !== 0 || programData?.olympiad?.length !== 0?
                       <div className="relative text-center mt-2 mb-2">
                        <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white mt-0 dark:text-neutral-400 dark:bg-neutral-900" aria-label='mainTransateData.and'>
                        {mainTransateData?.and}
                        </span>
                        <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
                    </div>:null
                    }
                      
                    {
                        programData?.program?.specialty_exams?.length !== 0?
                        <div className='border rounded-2xl p-4'>
                            <h2 className='font-medium' style={{ fontWeight: '600' }} aria-label='mainTransateData.following_exams'>{mainTransateData?.following_exams}</h2>
                            <ol style={{ listStyleType: 'decimal' }}>
                                {
                                    programData?.program?.specialty_exams.map((data, i)=>{
                                        return(
                                            <li key={i} style={{ fontSize: '14px', }} className='flex justify-between mt-4 '>
                                               {i+1}. {data?.exam?.exam_type} <span>{data?.over_all}</span>
                                            </li>
                                        )
                                    })
                                }
                                
                            </ol>
                        </div>:null
                    }
                    {
                        programData?.activity?.length !== 0 || programData?.olympiad?.length !== 0?
                        <div className="relative text-center mt-2 mb-2">
                            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900" aria-label='mainTransateData.opt_skills'>
                            {mainTransateData?.opt_skills}
                            </span>
                            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
                        </div>:null
                    }
                   
                    {
                        programData?.activity?.length !== 0?
                        <div className='border rounded-2xl p-4'>
                            <h2 className='font-medium' style={{ fontWeight: '600' }} aria-label='mainTransateData.ext_curc_actv'>{mainTransateData?.ext_curc_actv}</h2>
                            <ol style={{ listStyleType: 'decimal' }}>
                                {
                                    programData?.activity.map((data, i)=>{
                                        return(
                                            <li key={i} style={{ fontSize: '14px', }} className='flex justify-between mt-4 '>
                                               {i+1}. {data.title}
                                            </li>
                                        )
                                    })
                                }
                                
                            </ol>
                        </div>:null
                    }
                    
                    {
                        programData?.olympiad?.length !== 0?
                        <div className='border rounded-2xl p-4'>
                            <h2 className='font-medium' style={{ fontWeight: '600' }} aria-label='mainTransateData.int_olymp'>{mainTransateData?.int_olymp}</h2>
                            <ol style={{ listStyleType: 'decimal' }}>
                                {
                                    programData?.olympiad.map((data, i)=>{
                                        return(
                                            <li key={i} style={{ fontSize: '14px', }} className='flex justify-between mt-4 '>
                                               {i+1}. {data.title}
                                            </li>
                                        )
                                    })
                                }
                                
                            </ol>
                        </div>:null
                    }
                </div>
            </div>
        </main>:null
  }
  <RelatedPrograms getAfterRedirect={this.getAfterRedirect} />
  {
    simulatorLogin?
    <LoginComponent />:null
  }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    programData: state.Data.programData,
    languageId: state.Data.languageId,
    user: state.Data.user,
    mainTransateData: state.Data.mainTransateData,
    simulatorLogin: state.Data.simulatorLogin,
    allPublicConverters: state.Data.allPublicConverters,

});
const mapDispatchToProps = {getProgramData,getAllPublicConverters,changeStateValue,  mainDashboardTranslate, getUnirepRoleTranslate, getStudentInfo, applyToProogram}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(SingleProgram))