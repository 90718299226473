import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getStudents } from '../../../actions/WorkerActions';
import LoadMoreBtn from '../../../components/page_component/LoadMoreBtn';
import Card from './Card';
import EditModal from '../../edu_consultant_pages/EditModal';

export class Student extends Component {
    state={
        page:1
    }
    componentDidMount(){
        window.scrollTo(0, 0)

        this.props.getStudents('non_assign', 1)
    }
    loadMoreFunction=(page)=>{
        this.setState({
            page: page
        })
        this.props.getStudents('non_assign', page)

    }

    componentDidUpdate(prevProps, prevState){
      
        if(this.props.searchPr != prevProps.searchPr){
            this.props.getStudents('non_assign', this.state.page, this.props.searchPr)
        }
    }
    getAfterEdit=()=>{
        this.props.getStudents('non_assign', this.state.page, this.props.searchPr)
    }
  render() {
    const {unirepStudents, editUserState} = this.props;
    
    return (
        <div>
            <div className={`grid mt-10 gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 `}>
            {
                unirepStudents.map((data, i)=>{
                    return(
                        <Card button={true} key={i} data={data}/>
                    )
                })
            }
            
            </div>
            {
                unirepStudents.length >= 30?
                <LoadMoreBtn  count={30} position={unirepStudents.length} loadMoreFunction={this.loadMoreFunction} />:null
            }
            {
                editUserState.position?
                <EditModal getAfterEdit={this.getAfterEdit} />:null
            }
        </div>
        
    )
  }
}
const mapStateToProps = (state) =>({
    unirepStudents: state.Data.unirepStudents,
    editUserState: state.Data.editUserState,
});
const mapDispatchToProps = {getStudents}
export default connect(mapStateToProps, mapDispatchToProps)(Student)