import React, { Component } from 'react'
import Maincard  from './components/Maincard'
import { getEducationDegree } from '../../../actions/WorkerActions';
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import MainModal from '../../../components/modals/MainModal';
export class EducationDegree extends Component {
    componentDidMount(){
        this.props.getEducationDegree(1, '')
    }
    editDataBtn=(data)=>{
        this.props.changeStateValue({
            name: 'newCountryData',
            value: {
              id: data?.id? data?.id: '',
              position: true,
              image_full_url:  data?.image ? data?.image: '',
              image:  data?.image ? data?.image: '',
              name: data?.name?data?.name:'',
              description: '',
              active: 1,
              lang_id: 1,
              translate_id: data.translate_id
            }
          })
          this.props.changeStateValue({
            name: 'otherExamPosition',
            value: false
          })
    }
    getAfterSubmit=()=>{
        this.props.getEducationDegree(1, '')
    }
  render() {
    const {educationDegree, newCountryData} = this.props;

    return (
        <div>
            <button onClick={this.editDataBtn.bind(this, '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a Edu Degree</button>
            <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 sm:gap-6 md:gap-8`}>
            {
                educationDegree.map((item ,i)=>{
                    return(
                        <Maincard 
                            key={i}
                            name={item.education_type}
                            image={item.education_degree.image_full_url}
                            id={item.education_degree_id}
                            translate_id={item.id}
                            editDataBtn={this.editDataBtn}
                        />
                    )
                })
            }
            </div>
            {newCountryData.position?<MainModal getAfterChange={this.getAfterSubmit} url={'/educationDegree'} stateName="newCountryData" modalData={newCountryData} />:null}
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
    educationDegree: state.Data.educationDegree,
    newCountryData: state.Data.newCountryData,
});
const mapDispatchToProps = {getEducationDegree, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(EducationDegree)