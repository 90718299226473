import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
// import Input from '../components/page_component/login/Input';
import GoogleLogin from 'react-google-login';
import {gapi} from 'gapi-script';
import {useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 

import { registerAction, registerSocialsAction } from '../actions/MainAction';
import { mainDashboardTranslate } from '../actions/TranslateAction';
import AppleIdLogin from '../components/page_component/AppleIdLogin';
import { GOOGLE_CLIENT_ID } from '../MAIN_API';
import { changeStateValue } from '../redux/MainReducer';
const clientId = GOOGLE_CLIENT_ID;
function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const languageId = useSelector(state=> state.Data.languageId);
  const mainTransateData = useSelector(state=> state.Data.mainTransateData);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errorState, setErrorState]  = useState(false)
  const [errorStateSocial, setErrorStateSocial]  = useState(false)
  useEffect(()=>{
    window.scrollTo(0, 0)
    function start(){
      gapi.client.init({
        clientId: clientId,
        scope: ""
      })
    }
    gapi.load('client:auth2', start)
    dispatch(mainDashboardTranslate(languageId))

  }, [])


  function onSuccess(res){

    if(res.profileObj.email){
      const newUser = {
        first_name: res.profileObj.givenName? res.profileObj.givenName:'',
        last_name: res.profileObj.familyName? res.profileObj.familyName:'',
        email: res.profileObj.email,
        role_id:3
      }
      dispatch(registerSocialsAction(newUser)).then(resp=>{
        if(resp === 'success'){
          navigate('/')
        }else{
          setErrorStateSocial(true);
        }
      })
    }
    
  }
  const onFailure = (res)=>{
    // console.log('ff', res)
  }

  const register = (e)=>{
    e.preventDefault();
    if(password !== '' && username !== '' &&  firstName !== '' && lastName !== ''){
      const newUser = {
        first_name: firstName,
        last_name: lastName,
        email: username,
        password: password,
        role_id: '3'
      }
      dispatch(registerAction(newUser)).then(resp=>{
  
        if(resp === 'success'){
          const x = new Date();
          const y = x.getTime()
          
          dispatch(changeStateValue({
            name: 'confirmEmail',
            value: {
              email: btoa(username),
              sentTime: String(x),
              deadline: String(new Date(y+2 * 60 * 60 * 1000)),
              path: window.location.pathname
            }

            
          }))
          localStorage.setItem('eKey', btoa(username))
          localStorage.setItem('start', String(x))
          localStorage.setItem('deadline', String(new Date(y+2 * 60 * 60 * 1000)))
          localStorage.setItem('path', window.location.pathname)
          navigate('/confirm-email');

        }else{
          setErrorState(true)
        }
      })
    }
  }
  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
       
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            {mainTransateData?.sign_up}
          </h2>
          <div className="max-w-md mx-auto space-y-6">
            {/* <div className="grid gap-3">
              <AppleIdLogin />
            </div> */}
            <div className="grid gap-3" id='google_login'>
              <GoogleLogin 
                clientId={clientId}
                buttonText={mainTransateData?.continue_google}
                onSuccess={(res)=>onSuccess(res)}
                onFailure={onFailure}
                cookiePolicy="single_host_origin"
                isSignedIn={false}
                theme='dark'
              />
              {
                errorStateSocial?
                <p style={{ marginTop: '-10px' }} className='invalid_login'>You already have an existing CELT.go account. Login or change your password.</p>:null
              }
            </div>
            {/* OR */}
            <div className="relative text-center">
              <span aria-label='mainTransateData.or' className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              {mainTransateData?.or}
              </span>
              <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
            </div>
            {/* FORM */}
            <form className="grid grid-cols-1 gap-6" action="#" method="post">
              <div className='grid grid-cols-2 gap-3'>
                <label className="block">
                  <span aria-label='mainTransateData.first_name' className="text-neutral-800 dark:text-neutral-200">
                  {mainTransateData?.first_name}
                  </span>
                  
                  <input type={'text'}
                    className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                    placeholder={mainTransateData?.first_name}
                    value={firstName}
                    onChange={(event)=>setFirstName(event.target.value)}
                  />
                </label>
                <label className="block">
                  <span aria-label='mainTransateData.last_name' className="text-neutral-800 dark:text-neutral-200">
                    {mainTransateData?.last_name}
                  </span>
                  
                  <input type={'text'}
                    className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                    placeholder={mainTransateData?.last_name}
                    value={lastName}
                    onChange={(event)=>setLastName(event.target.value)}
                  />
                </label>
              </div>
              <label className="block">
                <span aria-label='mainTransateData.email' className="text-neutral-800 dark:text-neutral-200">
                  {mainTransateData?.email}
                </span>
                
                <input type={'email'}
                  className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                  placeholder="example@example.com"
                  value={username}
                  onChange={(event)=>setUsername(event.target.value)}
                />
              </label>
              <label className="block">
                <span aria-label='mainTransateData.password' className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                 {mainTransateData?.password}
            
                </span>
               
                <input type={'password'}
                  className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                  placeholder="********"
                  value={password}
                  onChange={(event)=>setPassword(event.target.value)}
                />
              </label>
              {
                errorState?
                <p style={{ marginTop: '-20px' }} className='invalid_login'>You already have an existing CELT.go account. Login or change your password.</p>:null
              }
              <button onClick={(event)=>register(event)} style={{ color: 'white' }} aria-label="mainTransateData.sign_up" className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
              nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
              text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>
                {mainTransateData?.sign_up}
              </button>
              {/* <ButtonPrimary type="submit">Continue</ButtonPrimary> */}
            </form>
  
            {/* ==== */}
            <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <span aria-label='mainTransateData.already_account'>{mainTransateData?.already_account} </span>{` `}
              <Link  style={{ color: 'blue' }} to="/login" aria-label='mainTransateData.sign_in'>{mainTransateData?.sign_in}</Link>
            </span>
          </div>
        </div>
      </div>
  )
}

export default Register


