import React, { Component } from 'react'
import { connect } from 'react-redux';
import Searchable from 'react-searchable-dropdown'; 
import { changeStateValue } from '../../../redux/MainReducer';
import { getSimulatorPrograms } from '../../../actions/MainAction';
export class PercentageFilter extends Component {
    selectDropValue  =(id) =>{
        this.props.filterProgramBtn(id)
    }

    getPlaceholder(option, data){
        if(data == ''){
            return this.props.mainTransateData?.filter_percentage
        }else{
            if(option.find(item=>item.value == data)){
                return option.find(item=>item.value == data).label
            }else{
                return this.props.mainTransateData?.filter_percentage
            }
        }

    }
  render() {
    const {mainTransateData, filterProgram} = this.props;
   const option = [
    {
        label: mainTransateData?.filter_percentage,
        value: ''
    },
    {
        label: mainTransateData?.high_to_low,
        value: 'desc'
    },
    {
        label: mainTransateData?.low_to_high,
        value: 'asc'
    },
   ]
    
    return (
      <div  className={`block border-neutral-200  
      focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
      dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
      dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `} style={{ width: '188px' }}>
        <Searchable 
            options={option}
            onSelect={this.selectDropValue.bind( this.label )}
            placeholder={this.getPlaceholder(option,filterProgram)}
        />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    filter: state.Data.filter,
    mainTransateData: state.Data.mainTransateData,
});
const mapDispatchToProps = {changeStateValue, getSimulatorPrograms}
export default connect(mapStateToProps, mapDispatchToProps)(PercentageFilter)
