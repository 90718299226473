import React, { Component } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
// import io from "socket.io-client";
// import { NODE_API } from '../../../../../MAIN_API';
// const socket = io.connect(NODE_API);
import { insertMessage , getMessagesBox} from '../../../../../actions/MainAction';
function withParams(Component){
    return props => <Component {...props} params={useParams()} />
  }
export class MessagesForm extends Component {
    state={
        messages: '',
        inpRef: React.createRef()
    }
    changeInpValue(e){
        // socket.emit('msg-typing', {userId: localStorage.getItem('msg'), typing: true})
        this.setState({
            messages: e.target.value
        })
    }
    sendMessages(messages, user,program_id, e){

        e.preventDefault();
        if(messages.trim() !== ''){
            const  data = {
                user_id: user.id,
                student_id: user.role == 'student'? user.id: this.props.params.id,
                message: messages,
                program_id: program_id,
                users: this.props.messagesUsers
            }
          
            this.props.insertMessage(data)
            .then(resp=>{
                this.props.getMessagesBox(data.student_id, data.program_id)
            })
            // socket.emit('new-msg', data)
            this.setState({
                messages: ''
            })
        }
        this.state.inpRef.current.focus()
    }
    componentDidMount(){
        
        
        this.state.inpRef.current.addEventListener('keyup', (e)=>{
            
            if(e.code == 'Enter'){
                if(this.state.messages.trim() !== ''){
                    const  data = {
                        user_id: this.props.user.id,
                        student_id: this.props.user.role == 'student'?this.props.user.id: this.props.params.id,
                        message: this.state.messages,
                        program_id: this.props.program_id,
                        users: this.props.messagesUsers
                    }
                    this.props.insertMessage(data)
                    .then(resp=>{
                        this.props.getMessagesBox(data.student_id, data.program_id)
                    })
                    // socket.emit('new-msg', data)
                    this.setState({
                        messages: ''
                    })
                }
            }
        })
    }
    stopTyping(e){
        // socket.emit('msg-typing', {userId: '', typing: false})

    }
  render() {
    const {messages, inpRef} = this.state;
    const {program_id, user} = this.props;
    // console.log(user)
    return (
        <form>
            <textarea ref={inpRef} onBlur={this.stopTyping.bind(this)} className="write-msg"  value={messages} onChange={this.changeInpValue.bind(this)}></textarea>
            <button className="send-btn"  onClick={this.sendMessages.bind(this, messages, user, program_id)} >
                <svg viewBox="0 0 24 24" width="24" height="24" className="">
                    <path fill="currentColor" d="M1.101 21.757 23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"></path>
                </svg>
            </button>
            {/* <button className="send-btn">
                <svg viewBox="0 0 24 24" width="24" height="24" className="">
                    <path fill="currentColor" d="M11.999 14.942c2.001 0 3.531-1.53 3.531-3.531V4.35c0-2.001-1.53-3.531-3.531-3.531S8.469 2.35 8.469 4.35v7.061c0 2.001 1.53 3.531 3.53 3.531zm6.238-3.53c0 3.531-2.942 6.002-6.237 6.002s-6.237-2.471-6.237-6.002H3.761c0 4.001 3.178 7.297 7.061 7.885v3.884h2.354v-3.884c3.884-.588 7.061-3.884 7.061-7.885h-2z"></path>
                </svg>
            </button> */}
        </form>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user,
    messagesUsers: state.Data.messagesUsers
})
const mapDispatchToProps = {insertMessage,getMessagesBox}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(MessagesForm))