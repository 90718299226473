import React, { Component, Fragment } from 'react'
import { Dialog, Transition } from "@headlessui/react";
import Slider from "rc-slider";
import Checkbox from './Checkbox';
import { connect } from 'react-redux';
import { getInstitutions } from '../../../actions/MainAction';

export class MobileFilter extends Component {
    state={
        isOpenMoreFilter: false,
        countries: [],
      
    }
    openModalMoreFilter(e){
        this.setState({
            isOpenMoreFilter: true
        })
    }
    closeModalMoreFilter(e){
        this.setState({
          countries: []
        })
    }
    checkChecked(array, id){
      if(array.find(data=> data == id)){
        return true
      }else{
        return false
      }
    }
    applyData(countries, e){
      // console.log(countries)
      this.props.getInstitutions(1, '', this.props.languageId, countries)
      this.setState({
        isOpenMoreFilter: false
    })
    }
    checkDataProps = (id) => {
      const countries = this.state.countries;
      const findData = countries.findIndex(data=> data == id)
      console.log(id)
      if(findData === -1){
        countries.push(id)
      }else{
        countries.splice(findData, 1)
      }
      this.setState({
        countries: countries
      })
     
  
    }
  render() {
    const {isOpenMoreFilter, countries} = this.state
    const {allCountries} = this.props;
    let countriesOptions = [];
    for(const country of allCountries){
      const params = {
        name: country.name,
        id: country.countries_id
      }
      countriesOptions.push(params)
    }
 
    return (
        <div>
        <div
          className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none cursor-pointer`}
          onClick={this.openModalMoreFilter.bind(this)}
        >
          <span>
            <span className="hidden sm:inline">Institutions</span> filters (1)
          </span>
          <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3"
                viewBox="0 0 20 20"
                fill="currentColor"
                >
                <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                />
                </svg>
            </span>
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={this.closeModalMoreFilter.bind(this)}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Institutions filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      {/* <ButtonClose onClick={closeModalMoreFilter} /> */}
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 sm:px-6 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7">
                        <h3 className="text-xl font-medium">
                          Countries
                        </h3>
                        <div className="mt-6 relative space-y-5">
                          {/* {renderMoreFilterItem(moreFilter1)} */}
                          {countriesOptions.map((item) => (
                            <div key={item.name} className="">
                                <Checkbox
                                name={item.name}
                                id={item.id}
                                checked={this.checkChecked(countries, item.id)}
                                checkDataProps={this.checkDataProps}
                                />
                            </div>
                            ))}
                        </div>
                      </div>
                     
                    </div>
                  </div>

                  <div className="p-4 sm:p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                  <button onClick={this.closeModalMoreFilter.bind(this)} className='ttnc-ButtonThird text-neutral-700 border 
                                        border-neutral-200 dark:text-neutral-200 
                                        dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                        items-center justify-center rounded-full transition-colors
                                        text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                        px-4 py-2 sm:px-5'   >
                        Clear
                    </button>

                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  allCountries: state.Data.allCountries,
  languageId: state.Data.languageId

})
const mapDispatchToProps = {getInstitutions}
export default connect(mapStateToProps, mapDispatchToProps)(MobileFilter)