const getFee=(fee)=>{
    if(fee){
        const splt = fee.split(' ');
        const commaReplace = splt[0].replaceAll(',', '');
        return commaReplace.substring(1, commaReplace.length)
    }else{
        return '0';
    }
}

const getExams = (exams, progExams, percentage) => {
    let examFormat = [];
    for(let i = 0; i < exams.length; i++){
        const findExam = progExams.find(item=> item.title.toLowerCase().includes(exams[i].exam_type.toLowerCase()));
        if(findExam){
            examFormat.push({
                all_sub_sections: [],
                exam: {exam_type: exams[i].exam_type},
                exam_id: exams[i].id,
                exam_type: exams[i].exam_type,
                over_all: findExam?.grade,
                percentage: percentage,
                sub_sections: [],
                visible:true
            })
        }
    }

    return examFormat;
}
export const getProgram = (defaultData, instId, exams)=>{
    if(defaultData?.qualification_type == '3'){
        const params ={
            id: '',
            description: 'a',
            institution_id: +instId,
            name: defaultData?.name,
            program_format: "Full Time",
            user_id: '',
            lang_id: '1',
            active: '0',
            program_id: '',
            dates:JSON.stringify([]),
            setAsRequired:0,
            disciplines: defaultData?.discipline.replaceAll('\n', ''),
            olympiad:JSON.stringify([]),
            activity: JSON.stringify([]),
            sch_olympiad:JSON.stringify([]),
            sch_activity: JSON.stringify([]),
            json_exams: JSON.stringify(getExams(exams, defaultData?.exams, 20)),
            exams: getExams(exams, defaultData?.exams, 20),
            json_sch_exams: JSON.stringify([]),
            program:{
                country_id: '', 
                state_id: '',
                city_id: '',
                institution_id: +instId,
                fee_amount: getFee(defaultData?.international_fee),
                fee_currency_id: 3,
                education_degree_id: 3,
                study_duration: "3|1",
                start_date: "2024-10-01",
                deadline: "2024-06-01",
                gpa: 81,
                schoolarship_option: '',
                user_id: '',
                video_link: '',
                active: '0',
                education_language_id: 1,
                application_fee: 125,
                fee_type: '0',
                schoolarship_type: 'amount',
                discipline:  defaultData?.discipline.replaceAll('\n', ''),
                ib_diploma: 32,
                a_level: 'BBB',
                advanced_placement:'4444',
                ossd: 80,
                required_education_level: 2,
                olympiad:[],
                activity: [],
                ib_percentage:50,
                ib_grade: '',
                a_level_percentage:50,
                a_level_grade: null,
                ossd_percentage:50,
                ossd_grade: '',
                exam_check: 1,
                ap_percentage: 50,
                ap_grade: null,
                gpa_percentage: 50,
                sch_requirements:{
                  id: '',
                  gpa: '',
                  required_education_level: '0',
                  ib_diploma: '',
                  a_level: '',
                  advanced_placement: '',
                  ossd: '',
                  a_level_grade: '',
                  ossd_grade: '',
                  ib_grade: '',
                  ap_grade: '',
                  a_level_percentage: 0,
                  ossd_percentage: 0,
                  ib_percentage: 0,
                  ap_percentage: 0,
                  gpa_percentage: 0,
                },
            },
            program_json: JSON.stringify({
                country_id: '', 
                state_id: '',
                city_id: '',
                institution_id: +instId,
                fee_amount: getFee(defaultData?.international_fee),
                fee_currency_id: 3,
                education_degree_id: 3,
                study_duration: "3|1",
                start_date: "2024-10-01",
                deadline: "2024-06-01",
                gpa: 81,
                schoolarship_option: '',
                user_id: '',
                video_link: '',
                active: '0',
                education_language_id: 1,
                application_fee: 125,
                fee_type: '0',
                schoolarship_type: 'amount',
                discipline:  defaultData?.discipline.replaceAll('\n', ''),
                ib_diploma: 32,
                a_level: 'BBB',
                advanced_placement:'4444',
                ossd: 80,
                required_education_level: 2,
                olympiad:[],
                activity: [],
                ib_percentage:50,
                ib_grade: '',
                a_level_percentage:50,
                a_level_grade: null,
                ossd_percentage:50,
                ossd_grade: '',
                exam_check: 1,
                ap_percentage: 50,
                ap_grade: null,
                gpa_percentage: 50,
                sch_requirements:{
                  id: '',
                  gpa: '',
                  required_education_level: '0',
                  ib_diploma: '',
                  a_level: '',
                  advanced_placement: '',
                  ossd: '',
                  a_level_grade: '',
                  ossd_grade: '',
                  ib_grade: '',
                  ap_grade: '',
                  a_level_percentage: 0,
                  ossd_percentage: 0,
                  ib_percentage: 0,
                  ap_percentage: 0,
                  gpa_percentage: 0,
                },
            })
        } 
        return params;
    }else if(defaultData?.qualification_type == '5'){
        const params ={
            id: '',
            description: 'a',
            name: defaultData?.name,
            program_format: "Full Time",
            user_id: '',
            lang_id: '1',
            active: '0',
            dates:JSON.stringify([]),
            institution_id: +instId,
            program_id: '',
            disciplines: defaultData?.discipline.replaceAll('\n', ''),
            olympiad:JSON.stringify([]),
            activity: JSON.stringify([]),
            sch_olympiad:JSON.stringify([]),
            sch_activity: JSON.stringify([]),
            json_sch_exams: JSON.stringify([]),
            setAsRequired:0,
            json_exams: JSON.stringify(getExams(exams, defaultData?.exams, 20)),
            exams: getExams(exams, defaultData?.exams, 20),
            program:{
                country_id: '', 
                state_id: '',
                city_id: '',
                institution_id: +instId,
                fee_amount: getFee(defaultData?.international_fee),
                fee_currency_id: 3,
                education_degree_id: 5,
                study_duration: "2|1",
                start_date: "2024-10-01",
                deadline: "2024-06-01",
                gpa: 81,
                schoolarship_option: '',
                user_id: '',
                video_link: '',
                active: '0',
                education_language_id: 1,
                application_fee: 125,
                fee_type: '0',
                schoolarship_type: 'amount',
                discipline:  defaultData?.discipline.replaceAll('\n', ''),
                ib_diploma:null,
                a_level: null,
                advanced_placement:null,
                ossd: null,
                required_education_level: 3,
                olympiad:[],
                activity: [],
                ib_percentage:0,
                ib_grade: '',
                a_level_percentage:0,
                a_level_grade: null,
                ossd_percentage:0,
                ossd_grade: '',
                exam_check: 0,
                ap_percentage: 0,
                ap_grade: null,
                gpa_percentage: 50,
                sch_requirements:{
                  id: '',
                  gpa: '',
                  required_education_level: '0',
                  ib_diploma: '',
                  a_level: '',
                  advanced_placement: '',
                  ossd: '',
                  a_level_grade: '',
                  ossd_grade: '',
                  ib_grade: '',
                  ap_grade: '',
                  a_level_percentage: 0,
                  ossd_percentage: 0,
                  ib_percentage: 0,
                  ap_percentage: 0,
                  gpa_percentage: 0,
                },
            },
            program_json: JSON.stringify({
                country_id: '', 
                state_id: '',
                city_id: '',
                institution_id: +instId,
                fee_amount: getFee(defaultData?.international_fee),
                fee_currency_id: 3,
                education_degree_id: 5,
                study_duration: "2|1",
                start_date: "2024-10-01",
                deadline: "2024-06-01",
                gpa: 81,
                schoolarship_option: '',
                user_id: '',
                video_link: '',
                active: '0',
                education_language_id: 1,
                application_fee: 125,
                fee_type: '0',
                schoolarship_type: 'amount',
                discipline:  defaultData?.discipline.replaceAll('\n', ''),
                ib_diploma:null,
                a_level: null,
                advanced_placement:null,
                ossd: null,
                required_education_level: 3,
                olympiad:[],
                activity: [],
                ib_percentage:0,
                ib_grade: '',
                a_level_percentage:0,
                a_level_grade: null,
                ossd_percentage:0,
                ossd_grade: '',
                exam_check: 0,
                ap_percentage: 0,
                ap_grade: null,
                gpa_percentage: 70,
                sch_requirements:{
                  id: '',
                  gpa: '',
                  required_education_level: '0',
                  ib_diploma: '',
                  a_level: '',
                  advanced_placement: '',
                  ossd: '',
                  a_level_grade: '',
                  ossd_grade: '',
                  ib_grade: '',
                  ap_grade: '',
                  a_level_percentage: 0,
                  ossd_percentage: 0,
                  ib_percentage: 0,
                  ap_percentage: 0,
                  gpa_percentage: 0,
                },
            })
            
        } 
        return params;
    }
    
}