import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getEduDegreeTranslator } from '../../actions/TranslateAction';
import SectionHeader from '../../components/page_component/SectionHeader';
import TranslateCard from './components/TranslateCard';

export class TranslatorEducationDegree extends Component {
    componentDidMount(){
        this.props.getEduDegreeTranslator()
    }
    getAvailableData(data, item){
        // console.log(data)
        let array = [];
        for(const property in data.default){
         
          if(property == 'education_type'){
            let x = []
            x.push(
              `${property}`, data[item]&&data[item][property]?data[item][property]:''
            )
          array.push(x)
    
          }
        }
    
        return array
      }
      getAfterSave = () =>{
        this.props.getEduDegreeTranslator()
      }
  render() {
    const {eduDegreeTranslate, user} = this.props;
 
    return (
        <div className='container'>
        {
          user != ''?
          <SectionHeader  title={`Translate Education Degree From English to ${user?.lang?.main_lang?.name}`} />:null
        }
        <ul>
          {
            eduDegreeTranslate.map((data, i)=>{
              return(
                <TranslateCard getAfterSave={this.getAfterSave} url={`setEduDegreeTranslate/${eduDegreeTranslate[i].default.education_degree_id}`} key={i} data={this.getAvailableData(data, 'default')} translate={this.getAvailableData(data, 'translate')} />
              )
            })
          }
        </ul>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    eduDegreeTranslate: state.Data.eduDegreeTranslate,
    user: state.Data.user,
});
const mapDispatchToProps = {getEduDegreeTranslator}
export default connect(mapStateToProps, mapDispatchToProps)(TranslatorEducationDegree)