import React, { Component } from 'react'

export class Input extends Component {
  render() {
    const {value, name, title, getInpValue = ()=>{}, type = "text"} = this.props;
    return (
      <div>
        <label className='text-sm'>{title}</label>
        <input type={type} onChange={(e)=>getInpValue(e.target.name, e.target.value)} value={value} name={name} placeholder={title} />
        {
            value ==''?
            <p className='text-red-700 text-xs text-bold'>This field is required</p>:null
        }
        
      </div>
    )
  }
}

export default Input