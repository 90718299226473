import React, { Component } from 'react'
import { connect } from 'react-redux';
import SvgDown from './SvgDown'
import SvgUp from './SvgUp';
import { changeStateValue, hideLongLoader, setLongLoader } from '../../../redux/MainReducer';
import { getCountryEducationDegree, getGradingScheme, getSimulatorPrograms, filterByGradingScheme } from '../../../actions/MainAction';
export class InnerDropDown extends Component {
    state={
        position: true
    }
    openDropDown(position, e){
        this.setState({
            position: !position
        })
    }
    getChecked(name, filter, id){
        if(filter[name] !== ''){
            if(filter[name] == id){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }
    changeRadioType(name, id, grade_id = '', e){
        // console.log(name)
        
        
        if(localStorage.getItem('simulator')){
            const filterData = JSON.parse(localStorage.getItem('simulator'));
            if(name == 'filterByGradingScheme'){
                filterData['gpaConverter'] = '';
                filterData['gradingSchemeId'] = grade_id
                filterData['filterByGPA'] = '';
            }
            if(name == 'filterStudiedCountry'){
                filterData['filterByGPA'] = '';
                filterData['filterByGradingScheme'] = '';
                filterData['filterByALevel'] = '';
                filterData['filterByAP'] = '';
                filterData['filterByCountryLevel'] = '';
                filterData['filterByIB'] = '';
                filterData['filterByLevel'] = '';
                filterData['filterByOSSD'] = '';
                filterData['gpaConverter'] = '';
                filterData['gradingSchemeId'] = '';
                this.props.changeStateValue({
                    name: 'countryEducationDegree',
                    value: []
                })
            }else if(name == 'filterByLevel'){
                filterData['filterByGPA'] = '';
                filterData['filterByGradingScheme'] = '';
                filterData['filterByALevel'] = '';
                filterData['filterByAP'] = '';
                filterData['filterByCountryLevel'] = '';
                filterData['filterByIB'] = '';
                filterData['filterByOSSD'] = '';
                filterData['gpaConverter'] = '';
                filterData['gradingSchemeId'] = '';
                filterData['filterByDegree'] = '';
                this.props.changeStateValue({
                    name: 'countryEducationDegree',
                    value: []
                })
            }else if(name == 'filterByCountryLevel'){
                filterData['filterByGPA'] = '';
                filterData['filterByGradingScheme'] = '';
                filterData['filterByALevel'] = '';
                filterData['filterByAP'] = '';
                filterData['filterByIB'] = '';
                filterData['filterByOSSD'] = '';
                filterData['gpaConverter'] = '';
                filterData['gradingSchemeId'] = '';

            }else{
                this.setmanualLoader()
            }
            filterData[name] = id;
            localStorage.setItem('simulator', JSON.stringify(filterData));
            this.props.changeStateValue({
              name: 'filter',
              value: filterData
            })
            // if(localStorage.getItem('token')){
            //     this.props.getSimulatorPrograms(filterData, 1, 'programs')
            // }else{
            //     this.props.getSimulatorPrograms(filterData);
            // }
            
            if(filterData['filterStudiedCountry'] !== '' && filterData['filterByLevel'] !== ''){
                this.props.getCountryEducationDegree(this.props.languageId, filterData['filterStudiedCountry'], filterData['filterByLevel']);
                this.props.getGradingScheme(this.props.languageId, filterData['filterStudiedCountry'], filterData['filterByLevel'], 'education_degree', '');
                this.props.changeStateValue({
                    name: 'gradingSchemeType',
                    value: id
                })
            }
            if(filterData['filterStudiedCountry'] !== '' && 
                filterData['filterByLevel'] !== '' && 
                filterData['filterByCountryLevel'] &&
                id !== 'a_level' && 
                id !== 'ib_diploma' && 
                id !== 'ossd' && 
                id !== 'ap'
            ){
                this.props.getGradingScheme(this.props.languageId, filterData['filterStudiedCountry'], filterData['filterByLevel'], 'education_sub_degree', filterData['filterByCountryLevel']);
                this.props.changeStateValue({
                    name: 'gradingSchemeType',
                    value: id
                })
            }else if(filterData['filterStudiedCountry'] !== '' && 
                    filterData['filterByLevel'] !== '' && 
                    filterData['filterByCountryLevel'] 
            ){
                if(
                    id !== 'a_level' ||
                    id !== 'ib_diploma' || 
                    id !== 'ossd' ||
                    id !== 'ap'
                ){
                    this.props.changeStateValue({
                        name: 'gradingSchemeType',
                        value: id
                    })
                }
            }
            
          }
        this.props.updateData()
    }
    componentDidMount(){
        if(localStorage.getItem('simulator')){
            const filterData = JSON.parse(localStorage.getItem('simulator'));
            if(filterData['filterStudiedCountry'] !== '' && filterData['filterByLevel'] !== ''){
                this.props.getCountryEducationDegree(this.props.languageId, filterData['filterStudiedCountry'], filterData['filterByLevel']);
            }
            

        }
    }
    setmanualLoader(){
        this.props.setLongLoader();
        setTimeout(()=>{
            this.props.hideLongLoader()
        },1000)
    }
    changeInpValue( min, max, e){
         
        this.setmanualLoader()
        if(e.target.value >= min && e.target.value <= max){
          
            if(localStorage.getItem('simulator')){
              const filterData = JSON.parse(localStorage.getItem('simulator'));
              filterData[this.props.property] = e.target.value;
              
             
              // this.props.getSimulatorPrograms(filterData)
              // this.props.getStateByCountry(null, this.props.languageId, filterData.filterByCountry);
              // this.props.getcityByCountryAndState(this.props.languageId, filterData.filterByCountry, filterData.filterByState);
          
        
    
              if(filterData.filterByGradingScheme.includes('-') && filterData.filterByGradingScheme.split('-')[1] == '100'){
               
                filterData['gpaConverter'] = e.target.value
              }else{
                if(this.props.gradeConverters){
                    const convert = this.props.gradeConverters.find(grade=> +grade.grade == e.target.value);
                    if(convert){
                        filterData['gpaConverter'] = convert.max_value
                    }
                }
              }
              this.props.changeStateValue({
                name: 'filter',
                value: filterData
              })
              localStorage.setItem('simulator', JSON.stringify(filterData));
            }
            
        }else if(e.target.value <= min ){
            if(localStorage.getItem('simulator')){
                const filterData = JSON.parse(localStorage.getItem('simulator'));
                filterData[this.props.property] ='';
               
                if(filterData.filterByGradingScheme.includes('-') && filterData.filterByGradingScheme.split('-')[1] == '100'){
                    filterData['gpaConverter'] = e.target.value
                 
                  }else{
                    if(this.props.gradeConverters){
                        const convert = this.props.gradeConverters.find(grade=> +grade.grade == e.target.value);
                        if(convert){
                            filterData['gpaConverter'] = convert.max_value
                        }
                    }
                  }
                this.props.changeStateValue({
                    name: 'filter',
                    value: filterData
                })
                localStorage.setItem('simulator', JSON.stringify(filterData));

                // this.props.getSimulatorPrograms(filterData)
                // this.props.getStateByCountry(null, this.props.languageId, filterData.filterByCountry);
                // this.props.getcityByCountryAndState(this.props.languageId, filterData.filterByCountry, filterData.filterByState);
        
               
            }
        }
        this.props.updateData()

    }
  render() {
    const {position} = this.state;
    const {type, array, title, property, filter, child = 'list', min_value= 0, max_value = 0, value = 0, ariaLabel}= this.props

    return (
        <li>
            <div onClick={this.openDropDown.bind(this, position)} className='filter_inner_dropdown'>
                <span aria-label={ariaLabel}>{title}</span>
                {
                    position?
                    <SvgUp />:
                    <SvgDown/>
                }
                
            </div>
            {
                position?
                <ul className='filter_inner_dropdown_body'>
                    {
                        child == 'list'?
                        array.map((data, i)=>{
                            return(
                                <li key={i}><label htmlFor={data.name+i}><input onChange={this.changeRadioType.bind(this, property, data.id, data.grade_id)} checked={this.getChecked(property, filter, data.id)} id={data.name+i} name={property} type={type}/> <span>{data.name}</span></label></li>
                            )
                        }):
                        child == 'input'?
                        <li className='inner_input_filter'>
                            <p>{min_value} - {max_value}</p>
                            <input value={value} onChange={this.changeInpValue.bind(this, min_value, max_value)} type={'number'} />
                        </li>:null
                    }
                </ul>:null
            }
                
            
        </li>
    )
  }
}
const mapStateToProps = (state)=> ({
    filter: state.Data.filter,
    languageId: state.Data.languageId,
    filterByGradingScheme: state.Data.filterByGradingScheme,
    gradeConverters: state.Data.gradeConverters
});
const mapDispatchToProps = {changeStateValue, getCountryEducationDegree, getGradingScheme, getSimulatorPrograms, hideLongLoader, setLongLoader }
export default connect(mapStateToProps, mapDispatchToProps)(InnerDropDown)