import { connect } from 'react-redux';
import React, { Component } from 'react';
import { getSimulatorPrograms, getAllCountries, getAllDisciplines, getStateByCountry, getcityByCountryAndState, getStudentInfo, getUniByCities } from '../actions/MainAction';
import {changeStateValue} from '../redux/MainReducer';
import Background from '../components/page_component/Background';
import DropDown from '../components/page_component/simulator_components/DropDown';
import { getEducationDegree, getExtraActivities, getOlympiadMedals } from '../actions/MainAction';
import ProgramCard from '../components/page_component/simulator_components/ProgramCard';
import LoginComponent from '../components/page_component/simulator_components/login_register/LoginComponent';
import { programs } from '../components/page_component/simulator_components/calcPercentage';
import { mainDashboardTranslate, getUnirepRoleTranslate } from '../actions/TranslateAction';
import { getObject } from '../actions/makeFilterObj';
import { useParams } from 'react-router-dom';
import SimulatorModal from '../components/page_component/simulator_components/modal/SimulatorModal';
import LoadMoreBtn from '../components/page_component/LoadMoreBtn';
import DegreeFilter from '../components/page_component/simulator_components/DegreeFilter';
import PercentageFilter from '../components/page_component/simulator_components/PercentageFilter';
import LanguageFilter from '../components/page_component/simulator_components/LanguageFilter';
import SearchComponent from '../components/page_component/simulator_components/SearchComponent';
import { getAllPublicConverters } from '../actions/MainAction';
function withHooks(Component){
  return props => <Component {...props} params={useParams()} />
}
export class StudentSimulator extends Component {
    state={
      filterprogram: '',
      filterForExm: 0,
      manualLoad: 0,
      manualLoadCity: 0
    }

    filterProgram = (id) =>{
      // console.log(id)
      this.setState({
        filterprogram: id
      })
    }
    componentDidMount(){
      this.props.getAllPublicConverters();
      window.scrollTo(0, 0)
      if(localStorage.getItem('simulatorType')){
        this.props.changeStateValue({
          name: 'simulatorType',
          value: localStorage.getItem('simulatorType')
        })
      }else{
        this.props.changeStateValue({
          name: 'simulatorType',
          value: 'program'
        })
        localStorage.setItem('simulatorType', 'program')
      }
      if(localStorage.getItem('modalSimulator')){
        this.props.changeStateValue({
          name: 'simulatorModal',
          value: true
        })
      }
        if(localStorage.getItem('token')){
          this.props.getStudentInfo(this.props.params.id? this.props.params.id: '')
          .then(resp=>{
            if(resp?.student_info?.study_country_id != '' && resp?.student_info?.study_country_id != null){
              localStorage.removeItem('modalSimulator')
              this.props.changeStateValue({
                name: 'simulatorModal',
                value: false
              })
            }else{
              localStorage.setItem('modalSimulator', 'true')
              this.props.changeStateValue({
                name: 'simulatorModal',
                value: true
              })
            }
            const data = getObject(resp);
            localStorage.setItem('filterExamIds', JSON.stringify(data.examId))
            localStorage.setItem('filterExamGrade', JSON.stringify(data.exams))
            if(this.props.params.id){
              data.obj.student_id = this.props.params.id
            }
            localStorage.setItem('simulator', JSON.stringify(data.obj))
            this.props.changeStateValue({
              name: 'filter',
              value: data.obj
            })
            this.props.changeStateValue({
              name: 'filterExamGrade',
              value: data.exams
            })
            this.props.changeStateValue({
              name: 'filterExamIds',
              value: data.examId
            })
            this.props.getSimulatorPrograms(data.obj, 1, 'programs')
            this.props.getStateByCountry(null, this.props.languageId, data.obj.filterByCountry);
            this.props.getcityByCountryAndState(this.props.languageId, data.obj.filterByCountry, data.obj.filterByState);
            this.props.getUniByCities(data.obj.filterByCity.split(','), this.props.languageId)
          })
        }
        if(localStorage.getItem('simulator')){
            const filterData = JSON.parse(localStorage.getItem('simulator'))
            this.props.changeStateValue({
                name: 'filter',
                value: filterData
            })
            this.props.getSimulatorPrograms(filterData);
            this.props.getStateByCountry(null, this.props.languageId, filterData.filterByCountry);
            this.props.getcityByCountryAndState(this.props.languageId, filterData.filterByCountry, filterData.filterByState);
        }

      this.props.getAllCountries('','', this.props.languageId );
      this.props.getAllDisciplines(this.props.languageId);
      this.props.getEducationDegree(this.props.languageId);
      this.props.getExtraActivities(this.props.languageId);
      this.props.getOlympiadMedals(this.props.languageId);
      this.props.mainDashboardTranslate(this.props.languageId);
      this.props.getUnirepRoleTranslate(this.props.languageId);
     
      if(localStorage.getItem('filterExamGrade')){
        this.props.changeStateValue({
          name: 'filterExamGrade',
          value: JSON.parse(localStorage.getItem('filterExamGrade'))
        })
      }
      this.setState();

        
    }
    refreshForExams = () =>{
        if(localStorage.getItem('simulator')){
          const filterData = JSON.parse(localStorage.getItem('simulator'))
          this.props.changeStateValue({
              name: 'filter',
              value: filterData
          })
          this.props.getSimulatorPrograms(filterData);
        }
    }

    changeSimulatorType(type, e){
      if(localStorage.getItem('simulator')){
        const filterData = JSON.parse(localStorage.getItem('simulator'))
        filterData['filterType'] = type;
        localStorage.setItem('simulator', JSON.stringify(filterData))
        this.props.changeStateValue({
            name: 'filter',
            value: filterData
        })
        this.props.getSimulatorPrograms(filterData);
      }
      this.props.changeStateValue({
        name: 'simulatorType',
        value: type
      })
      localStorage.setItem('simulatorType', type)
    }

    closeSimulator(e){
      if(e.target.className == 'filter_shadow_container'){
        document.querySelector('#simulatorCheck').checked = false
      }
    }

    loadMoreFunction = (page) =>{
      if(localStorage.getItem('simulator')){
        const filterData = JSON.parse(localStorage.getItem('simulator'))
        filterData['page'] = page
        this.props.changeStateValue({
            name: 'filter',
            value: filterData
        })
        this.props.getSimulatorPrograms(filterData);
    }
    }
    reloadAfterCountry = () =>{
      let x = this.state.manualLoad;
      
      this.setState({
        manualLoad: x+1,
        manualLoadCity:1,

      })
      setTimeout(()=>{
        this.setState({
          manualLoad: 0,
          manualLoadCity:0
        })
      }, 100)
    }
    reloadAfterState = () =>{
      let x = this.state.manualLoadCity;
      this.setState({
        manualLoadCity: x+1
      })
      setTimeout(()=>{
        this.setState({
          manualLoadCity: 0
        })
      }, 100)
    }
  render() {
    const {simulatorPrograms, 
            filter, 
            allCountries, 
            allDisciplines, 
            stateByCountry, 
            citiesByCountryAndState, 
            educationDegree, 
            countryEducationDegree,
            gradingScheme,
            gradingSchemeType,
            extraActivities,
            filterExamGrade,
            internationalOlympiad,
            simulatorLogin,
            mainTransateData,
            simulatorModal,
            simulatorType,
            uniByCities,
            allPublicConverters
          } = this.props;
    const {filterprogram, filterForExm, manualLoad, manualLoadCity} = this.state;

    const countriesOption = [];
    const disciplinesOption = [];
    const statesOption = [];
    const citiesOption = [];
    const degreeOption = [];
    const countryDegreeOption = [];
    const extraActivitiesOption = [];
    const internationalOlympiadOption = [];
    const institutionOption = [];

        
    for(const obj of [...allCountries].sort((x, y)=> {  return y?.countries?.institution_count - x?.countries?.institution_count})){
      const params = {
        id: obj.countries_id,
        name: <span  className='flex justify-between w-full'><span>{obj.name}</span> <span>({obj?.countries?.institution_count})</span></span>
      }
     
      countriesOption.push(params)
    }
    for(const obj of [...allDisciplines].sort((x, y)=> {  return y?.programs - x?.programs})){
      const params = {
        id: obj.discipline_id,
        name: obj.name
      }

      disciplinesOption.push(params)
    }
    for(const obj of [...statesOption].sort((x, y)=> {  return y?.universities - x?.universities})){
      const params = {
        id: obj.state_id,
        name: obj.name
      }

      disciplinesOption.push(params)
    }
    for(const obj of [...citiesByCountryAndState].sort((x, y)=> {  return y?.universities - x?.universities})){
      const params = {
        id: obj.city_id,
        name: obj.name
      }

      citiesOption.push(params)
    }
    for(const obj of educationDegree){
      const params = {
        id: obj.education_degree_id,
        name: obj.education_type
      }
      degreeOption.push(params)
    }
    for(const obj of uniByCities){
      const params = {
        id: obj.institutions_id,
        name: obj.name
      }
      institutionOption.push(params)
    }
    for(const obj of countryEducationDegree){
      const params = {
        id: obj.country_education_degree_id,
        name: obj.name
      }

      countryDegreeOption.push(params)
    }
    for(const obj of extraActivities){
      const params = {
        id: obj.activity_id,
        name: obj.title
      }

      extraActivitiesOption.push(params)
    }
    for(const obj of internationalOlympiad){
      const params = {
        id: obj.olympiad_id,
        name: obj.title
      }

      internationalOlympiadOption.push(params)
    }

    if(filter.filterByLevel == 1){
      const degre = [
        {
          id: 'a_level',
          name: 'A Level'
        },
        {
          id: 'ib_diploma',
          name: 'IB Diploma'
        },
        {
          id: 'ossd',
          name: 'OSSD'
        },
        {
          id: 'ap',
          name: 'Advanced Placement'
        },
      ];
      countryDegreeOption.push(...degre)
    }



    return (
      <div className="nc-PageHome relative overflow-hidden">
        <Background />
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
            <div className='filter_main_container relative'>
              <input type={'checkbox'} id="simulatorCheck" style={{ display: 'none' }} />
              <div className='filter_shadow_container' onClick={this.closeSimulator.bind(this)}>
                <div className='filter_container '>
                  
                    
                  {/* <p className='simulator_filter_description'>{mainTransateData?.simulator_desc}</p> */}
                  <div className='simulator_card'>
                  <div className='flex justify-end items-center '>
                     
                      <label className='mr-5 simulatorCloseBtnn mt-4 mb-4'  htmlFor='simulatorCheck'>
                        <span className="sr-only">Close</span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                
                        </label>
                  </div>
                    <DropDown reloadAfterCountry={this.reloadAfterCountry} positionDefault={true} property="filterByCountry" title={mainTransateData?.countries} ariaLabel="mainTransateData.countries" body="checkbox" defaultCheck={filter.filterByCountry} array={countriesOption} />
                    {
                      stateByCountry.length !== 0 && filter['filterByCountry'] != '' && manualLoad == 0?
                      <DropDown reloadAfterState={this.reloadAfterState} positionDefault={false} property="filterByState" title={mainTransateData?.states} ariaLabel="mainTransateData.states" body="checkbox" defaultCheck={filter.filterByState} array={stateByCountry} />
                      :null
                    }
                    {
                      citiesOption.length !== 0 && filter['filterByCountry'] != '' && manualLoadCity == 0 ?
                      <DropDown  reloadAfterState={this.reloadAfterState}  positionDefault={false} property="filterByCity" title={mainTransateData?.cities} ariaLabel="mainTransateData.cities" body="checkbox" defaultCheck={filter.filterByCity} array={citiesOption} />
                      :null
                    }
                    {
                      institutionOption.length !== 0 && filter['filterByCity'] != '' && manualLoadCity == 0 ?
                      <DropDown  positionDefault={false} property="filterByInstitution" title={mainTransateData?.inst_title} ariaLabel="mainTransateData.inst_title" body="checkbox" defaultCheck={filter.filterByInstitution} array={institutionOption} />
                      :null
                    }
                  </div>
                  <div className='simulator_card'>
                    <DropDown positionDefault={true} property="filterByDiscipline" title={mainTransateData?.disciplines} ariaLabel="mainTransateData.disciplines" body="checkbox" defaultCheck={filter.filterByDiscipline} array={disciplinesOption} />

                  </div>
                  <div className='simulator_card'>
                    <DropDown positionDefault={true} refreshForExams={this.refreshForExams} property="filterByCity" title={mainTransateData?.exams} ariaLabel="mainTransateData.exams" defaultCheck={filter.filterByCity} array={citiesOption} body="slider" />
                  </div> 
                
                  <div className='simulator_card'>
                    <DropDown positionDefault={true} body="multiDropDown"   property="filterByGPA" title={mainTransateData?.edu_background} ariaLabel="mainTransateData.edu_background" defaultCheck={filter.filterByGPA} array={[]} min_value={1} max_value={100} />
                  </div>
                  
                  <div className='simulator_card'>
                    <DropDown positionDefault={true} property="filterByExtraActivities" title={mainTransateData?.ext_curc_actv} ariaLabel="mainTransateData.ext_curc_actv" body="checkbox" defaultCheck={filter.filterByExtraActivities} array={extraActivitiesOption} />
                  </div>
                  <div className='simulator_card'>
                    
                    <DropDown positionDefault={true} property="filterByInternationalOlympiads" title={mainTransateData?.int_olymp} ariaLabel="mainTransateData.int_olymp" body="checkbox" defaultCheck={filter.filterByInternationalOlympiads} array={internationalOlympiadOption} />
                  </div>
              </div>
              </div>
                

                <div className='filtered_program_container'>
                <label htmlFor='simulatorCheck' id='simulatorFilterBtn'  style={{ fontSize: '12px', height: '40px' }} >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={30}
                    height={30}
                    fill="none"
                    viewBox="0 0 24 24"
                  
                  >
                    <path
                      stroke="#292D32"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit={10}
                      strokeWidth={1.5}
                      d="M14.32 19.07c0 .61-.4 1.41-.91 1.72L12 21.7c-1.31.81-3.13-.1-3.13-1.72v-5.35c0-.71-.4-1.62-.81-2.12L4.22 8.47c-.51-.51-.91-1.41-.91-2.02V4.13c0-1.21.91-2.12 2.02-2.12h13.34c1.11 0 2.02.91 2.02 2.02v2.22c0 .81-.51 1.82-1.01 2.32"
                    />
                    <path
                      stroke="#292D32"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M16.07 16.52a3.2 3.2 0 1 0 0-6.4 3.2 3.2 0 0 0 0 6.4ZM19.87 17.12l-1-1"
                    />
                  </svg>
                 
                       </label>     
                  <div className="flex mb-5 gap-3 profile_header_btn">   
                       
                       
                       <div className='flex mb-2 gap-3 profile_header_btn'>
                        <button style={{ fontSize: '12px' }} aria-label='mainTransateData.check_admission' onClick={this.changeSimulatorType.bind(this, 'program')} className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 
                            capitalize rounded-full   focus:outline-none ${simulatorType ==  'program'? 'bg-secondary-900 text-secondary-50':'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'} `}>
                            {mainTransateData?.check_admission}
                          </button>
                          <button style={{ fontSize: '12px' }} aria-label='mainTransateData.check_sch' onClick={this.changeSimulatorType.bind(this, 'scholarship')}  className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 
                            capitalize rounded-full   focus:outline-none ${simulatorType ==  'scholarship'? 'bg-secondary-900 text-secondary-50':'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'} `}>
                            {mainTransateData?.check_sch}
                          </button>
                     
                      
                       </div>
                       <div className='flex mb-5 gap-3 profile_header_btn'>
                        <PercentageFilter filterProgramBtn={this.filterProgram} filterprogram={filterprogram} />
                        <DegreeFilter option={educationDegree} />
                        <LanguageFilter />
                        <SearchComponent />
                       </div>
                       
                      
                    </div>
                  <div  className={`grid gap-6 md:gap-8 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 `} >
                 
                  {
                      
 
                    programs(simulatorPrograms, filter, filterExamGrade, filterprogram, simulatorType, allPublicConverters)&&
                    programs(simulatorPrograms, filter, filterExamGrade, filterprogram, simulatorType, allPublicConverters).map((program, i)=>{
                      return(
                        <ProgramCard student_id={this.props.params.id} key={i} program={program}  />
                      )
                    })
                  }
                  
                  </div>
                 
                  {
                     programs(simulatorPrograms, filter, filterExamGrade, filterprogram, simulatorType).length == 0?
                     <div style={{ height: '60vh' }} className='flex justify-center items-center'>
                     <h3 className='w-full nothing_found_text'>Nothing found for this degree.<br /> Please change your degree to see other options.</h3>    </div>:null
                  }
              
                  {
                    simulatorPrograms.length>=21?
                    <LoadMoreBtn count={21} position={programs(simulatorPrograms, filter, filterExamGrade, filterprogram, simulatorType).length} loadMoreFunction={this.loadMoreFunction} />:null

                  }
                </div>
            </div>
        </div>
        {
          simulatorLogin?
           <LoginComponent  />:null
        }
        {
          simulatorModal?
          <SimulatorModal />:null
        }
        
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    simulatorPrograms: state.Data.simulatorPrograms,
    languageId: state.Data.languageId,
    filter: state.Data.filter,
    allCountries: state.Data.allCountries,
    allDisciplines: state.Data.allDisciplines,
    stateByCountry: state.Data.stateByCountry,
    citiesByCountryAndState: state.Data.citiesByCountryAndState,
    educationDegree: state.Data.educationDegree,
    countryEducationDegree: state.Data.countryEducationDegree,
    gradingScheme: state.Data.gradingScheme,
    gradingSchemeType: state.Data.gradingSchemeType,
    internationalOlympiad: state.Data.internationalOlympiad,
    extraActivities: state.Data.extraActivities,
    filterExamGrade: state.Data.filterExamGrade,
    simulatorLogin: state.Data.simulatorLogin,
    mainTransateData: state.Data.mainTransateData,
    simulatorModal: state.Data.simulatorModal,
    simulatorType: state.Data.simulatorType,
    uniByCities: state.Data.uniByCities,
    allPublicConverters: state.Data.allPublicConverters,
})
const mapDispatchToProps = {getSimulatorPrograms, 
                            changeStateValue, 
                            getAllCountries, 
                            getAllDisciplines, 
                            getStateByCountry, 
                            getcityByCountryAndState,
                            getEducationDegree, 
                            getExtraActivities, 
                            getOlympiadMedals,
                            getStudentInfo,
                            mainDashboardTranslate,
                            getUnirepRoleTranslate,
                            getAllPublicConverters,
                            getUniByCities,
                          }
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StudentSimulator))