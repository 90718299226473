import React, { Component, Fragment } from 'react'
import ChangeSvg from '../../../../../../components/svg/ChangeSvg'
import { Popover, Transition } from '@headlessui/react'
import CheckBox from '../../../../../worker_pages/student_information/components/CheckBox'
import { connect } from 'react-redux'
import { setStudentTracking } from '../../../../../../actions/WorkerActions';
export class TrackingBtn extends Component {
    state={
        applications:[]
    }

    onClearBtn=(applications)=>{
        if(applications.length !=0){
            applications.map((data)=>{
                data.deleted = true
            })
            let formData = new FormData();
            formData.append('type', "applications");
            formData.append('student_id', this.props.student_id);
            formData.append('program_id', this.props.program_id);
            formData.append('data', JSON.stringify(applications));
          
            this.props.setStudentTracking(formData).then(resp=>{
                console.log(resp)
                this.props.getAfterChange()
                this.setState({
                    applications: []
                })
            })
        }
    }

    onChangeData=(trackingLine, data, e)=>{
        let arr = [...trackingLine];
        if(e.target.checked){
            arr.push({
                id: '',
                program_id: this.props.program_id,
                student_id: this.props.student_id,
                track_id: data?.track_id,
            })
        }else{
            let index = arr.findIndex(x=> x.track_id == data?.track_id);
            arr[index].deleted = true
        }
        let formData = new FormData();
        formData.append('type', "applications");
        formData.append('student_id', this.props.student_id);
        formData.append('program_id', this.props.program_id);
        formData.append('data', JSON.stringify(arr));
        console.log(arr)
        this.props.setStudentTracking(formData).then(resp=>{
            // console.log(resp)
            this.props.getAfterChange()
            this.setState({
                applications: []
            })
        })
    }
  render() {
    const {program_id, student_id, options=[], selectArray=[]} = this.props;
    const {applications} = this.state;
    let filterData = options;
    let trackingLine = [];
    let betweenLine=[];
    if(selectArray && applications.length==0){
        selectArray.forEach(item=>{
            betweenLine.push(item.track_id)
            trackingLine.push({
                id: item.id,
                program_id: item.program_id,
                student_id: item.student_id,
                track_id: item.track_id,
            })
        })
    }else{
        trackingLine = [...applications]
        applications.map((data, i)=>{
            if(!data.deleted){
                betweenLine.push(data.track_id)
            }
        })
    }
    // console.log(trackingLine)
    // console.log(selectArray)
    return (
        <Popover >
            <Popover.Button className={'outline-none flex flex-col items-center justify-center'}>
                <ChangeSvg />
            </Popover.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
            <div id='popover_container'> 
                <Popover.Panel className=" z-10 w-screen max-w-sm px-4 mt-3  sm:px-0 lg:max-w-md">
                    <div className='overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700 p-[4px] '>
                        {/* <div className='bg-gray-100 rounded-xl h-[40px] p-[4px]'>
                            <div className='w-full h-full bg-white rounded-xl flex items-center justify-center'>
                                <span className='text-blue-800 font-[400] text-[14px] '>Applications</span>
                            </div>
                        </div> */}
                        <div className='pb-[20px] max-h-[250px] overflow-auto p-[7px]'>
                            <ul className='flex flex-col gap-[10px]'>
                                {
                                    options?.map((data, i)=>{
                                        return(
                                            <li key={i} className='flex items-center gap-[10px] cursor-pointer'>
                                                <input onChange={this.onChangeData.bind(this, trackingLine, data)} checked={trackingLine.find(x=>x.track_id == data?.track_id)?true:false} className='cursor-pointer'  type='checkbox' id={data?.id} />
                                                <label className='cursor-pointer  w-full' htmlFor={data?.id}>{data?.title}</label>
                                            </li>
                                        )
                                    })
                                }
                                
                            </ul>
                        </div>
                        <div className='flex justify-between items-center p-[10px]'>
                            <button onClick={this.onClearBtn.bind(this, trackingLine)} className='border rounded-2xl text-[13px] font-[400] p-[4px_7px]'>Clear</button>
                            {/* <button onClick={this.onApplyBtn.bind(this, applications)} className='bg-blue-800 rounded-2xl text-[13px] font-[400] p-[4px_7px] text-white'>Apply</button> */}
                        </div>
                    </div>
                </Popover.Panel>
            </div>
            </Transition>
      </Popover>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {setStudentTracking}
export default connect(mapStateToProps, mapDispatchToProps)(TrackingBtn)
