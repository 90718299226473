import React, { Component } from 'react'
import Slider from "rc-slider";

export class SliderRange extends Component {
    changeValue(index, e){
        this.props.changePercentage(e.target.value, index, this.props.exams)
    }
    deleteItem(index, e){
        this.props.deleteItem(index, this.props.exams)
    }
    setSliderValue(index, e ){
       
        this.props.changeOverAll(index, e, this.props.exams)
    }
    getValue(title, type){
        if(title === 'IELTS'){
            if(type === 'max'){
                return 9
            }else if(type === 'min'){
                return 3
            }else if(type === 'step'){
                return 0.5
            }
        }else if(title === 'TOEFL'){
            if(type === 'max'){
                return 120
            }else if(type === 'min'){
                return 0
            }else if(type === 'step'){
                return 1
            }
        }else if(title === 'PTE'){
            if(type === 'max'){
                return 90
            }else if(type === 'min'){
                return 10
            }else if(type === 'step'){
                return 1
            }
        }else if(title === 'Duolingo'){
            if(type === 'max'){
                return 160
            }else if(type === 'min'){
                return 10
            }else if(type === 'step'){
                return 1
            }
        }else if(title === 'SAT'){
            if(type === 'max'){
                return 1600
            }else if(type === 'min'){
                return 400
            }else if(type === 'step'){
                return 1
            }
        }else if(title === 'GRE'){
            if(type === 'max'){
                return 340
            }else if(type === 'min'){
                return 0
            }else if(type === 'step'){
                return 1
            }
        }else if(title === 'GMAT'){
            if(type === 'max'){
                return 800
            }else if(type === 'min'){
                return 200
            }else if(type === 'step'){
                return 1
            }
        }
    }
    getInpValue(title, index, min_value, max_value, e){
       
        if(title== 'IELTS'){
            if(e.target.value >=0 && e.target.value <=max_value){
               
                if(e.target.value == 3 || 
                    e.target.value == 3.5 || 
                    e.target.value ==4 || 
                    e.target.value == 4.5 || 
                    e.target.value == 5 || 
                    e.target.value == 5.5 || 
                    e.target.value == 6 || 
                    e.target.value ==6.5 ||
                    e.target.value == 7 ||
                    e.target.value == 7.5 ||
                    e.target.value == 8 ||
                    e.target.value == 8.5 ||
                    e.target.value == 9
                    ){
                     
                        this.props.changeOverAll(index, e.target.value, this.props.exams)
                    }
                
            }else if(e.target.value <0){
                this.props.changeOverAll(index, '', this.props.exams)
            }
        }else{
            if(e.target.value >=0 && e.target.value <=max_value){
                this.props.changeOverAll(index, e.target.value, this.props.exams)
            }else if(e.target.value <0){
                this.props.changeOverAll(index, '', this.props.exams)
            }
        }
    }
  render() {
    const {title, index, value, perValue} = this.props;
   
    return (
        <div className="flex items-center justify-between py-3">
            <span className="text-neutral-6000 dark:text-neutral-400 font-medium">
            {title}
            </span>
           <div className='flex items-center gap-6'>
           <div className='inp-slider_main'>
                <div className='inp_slider' style={{ minWidth: '110px' }}>
                    <div className='flex items-center justify-between gap-7'>
                        
                        <p className='text-center flex items-center gap-1'>Score: 
                            <input onChange={this.getInpValue.bind(this, title, index, this.getValue(title, 'min'), this.getValue(title, 'max'))} value={value} type='number' placeholder='score' style={{ width: '70px', height: '30px' }} />
                        </p>
                    </div>
                    <Slider
                
                        min={this.getValue(title, 'min')}
                        max={this.getValue(title, 'max')}
                        step={this.getValue(title, 'step')}
                        allowCross={false}
                        onChange={this.setSliderValue.bind(this, index)}
                        value={value}
                    />
                    <p className='flex items-center justify-between'><span>{this.getValue(title, 'min')}</span> <span>{this.getValue(title, 'max')}</span></p>
                </div>
                <input placeholder='%' value={perValue == 0? '':perValue} onChange={this.changeValue.bind(this, index)} className='percentage_inp rounded-2xl slider_percentage' type={'number'} />
            </div>
            <i onClick={this.deleteItem.bind(this, index)} className="text-2xl text-neutral-400 las la-times-circle hover:text-neutral-900 dark:hover:text-neutral-100 cursor-pointer"></i>
           </div>
        </div>
    )
  }
}

export default SliderRange