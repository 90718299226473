import React, { Component } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDocuments, deleteData } from '../../../actions/WorkerActions';
import SectionHeader from '../../../components/page_component/SectionHeader';
import DocumentModal from './components/DocumentModal';
import { changeStateValue } from '../../../redux/MainReducer';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
function withHooks(Component){
    return props => <Component {...props} params={useParams()} />
}
export class Documents extends Component {

    componentDidMount(){
        this.props.getDocuments(this.props.params.countryId, this.props.params.categoryId);
    }

    openModalBtn(data, e){
        this.props.changeStateValue({
            name: 'documentsModal',
            value:{
                id:data?.id?data?.id:'',
                title:data?.title?data?.title:'',
                description:data?.description?data?.description:'',
                position:true,
                country_id:this.props.params.countryId,
                category_id:this.props.params.categoryId,
                status: this.props.user?.role == 'uni_rep'?'1':'0',
                file:''
            }
        })
    }

    deleteItem(id){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.deleteData(`delete-document/${id}`)
                .then(resp=>{
                    this.props.getDocuments(this.props.params.countryId, this.props.params.categoryId);
                    if(resp == 'success'){
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                        )
                    }
                })
            
            }
        })
    }
  render() {
    const {documents, documentsModal, user} = this.props;
    
    return (
      <div className='container'>
        <SectionHeader title={`${documents?.country} ${documents?.category} Documents`} />
        <button onClick={this.openModalBtn.bind(this, '')} className='border rounded-xl p-1 text-sm font-semibold cursor-pointer'>Add Document</button>
        <div className="grid gap-3 grid-cols-2 mt-4  sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 ">
            {
                documents?.data?.map((data, i)=>{
                    return(
                        <div  key={i} className='border rounded-xl p-3 bg-white'>
                            {
                                data?.status == 0?
                                 <span className='bg-blue-700 text-white text-sm font-semibold p-1 rounded-xl'> Waiting</span>:null
                            }
                            <h4 className='text-lg font-semibold'>{data?.title}</h4>
                            <div className='flex justify-between items-center mt-3'>
                                {
                                    user?.role == 'uni_rep'?
                                    <button onClick={this.deleteItem.bind(this, data?.id)} className='bg-red-700 text-sm font-semibold p-1 text-white rounded-xl cursor-pointer'>Delete</button>:
                                    <a className='bg-blue-600 p-2 inline-block rounded-xl text-sm font-bold text-white' href={data?.file_full_url} target='_blank'>Download File</a>
                                }
                                
                                <Link to={`/document-data/${data?.id}`} className='border rounded-xl text-sm font-semibold p-1 pl-2 pr-2'>View Document</Link>
                            </div>
                        </div>
                    )
                })
            }
        </div>
        {
            documentsModal?.position?
            <DocumentModal />:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    documents: state.Data.documents,
    documentsModal: state.Data.documentsModal,
    user: state.Data.user,
});
const mapDispatchToProps = {getDocuments, deleteData, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Documents))
