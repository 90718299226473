import React, { Component } from 'react'
import MainPage from './pagess/MainPage'
import SiteHeader from './pagess/SiteHeader'
import {
  BrowserRouter as Router , 
  Routes, 
  Route
} from 'react-router-dom';
import Programs from './pagess/Programs';
import Universities from './pagess/Universities';
import Country from './pagess/Country';
import Disciplines from './pagess/Disciplines';
import Schoolarships from './pagess/Schoolarships';
import SingleCountry from './components/single_pages/country/SingleCountry';
import States from './pagess/States';
import City from './pagess/City';
import SingleUniversity from './components/single_pages/universities/SingleUniversity';
import SingleProgram from './components/single_pages/programs/SingleProgram';
import SingleDiscipline from './components/single_pages/discipline/SingleDiscipline';
import StudentSimulator from './pagess/StudentSimulator';
import LoginPage from './pagess/LoginPage';
import Register from './pagess/Register';
import { connect } from 'react-redux';
import { loggedInUserInfo } from './actions/MainAction';
import EditUniversity from './pagess/worker_pages/EditUniversity';
import CreateUniversity from './pagess/worker_pages/CreateUniversity';
import EditPrograms from './pagess/worker_pages/EditPrograms';
import CreateProgram from './pagess/worker_pages/CreateProgram';
import DublicateProgram from './pagess/worker_pages/DublicateProgram';
import StudentInformation from './pagess/worker_pages/student_information/StudentInformation';
import ApplicationInformation from './pagess/student_pages/ApplicationInformation';
import Students from './pagess/edu_consultant_pages/students/Students';
import CountryData from './pagess/worker_pages/CountryData';
import InstitutionData from './pagess/worker_pages/InstitutionData';
import Footer from './components/page_component/Footer';
import StudentProfile from './pagess/student_pages/StudentProfile';
import AssignStudent from './pagess/worker_pages/AssignStudent';
import Parents from './pagess/edu_consultant_pages/parents/Parents';
import ParentProfile from './pagess/parent_pages/ParentProfile';
import EduCons from './pagess/worker_pages/EduCons';
import EduConStudents from './pagess/worker_pages/EduConStudents';
import { changeTranslateData, mainDashboardTranslate, getUnirepRoleTranslate } from './actions/TranslateAction';
import TranslateModal from './components/page_component/TranslateModal';
// import FooterNav from './components/header_component/FooterNav';
import { changeStateValue } from './redux/MainReducer';
import Loading from './components/page_component/Loading';
import FooterNav from './components/MenuBar/FooterNav';
import HeaderSearch from './components/MenuBar/HeaderSearch';
import useWindowSize from './components/MenuBar/useWindowSize';
import WishListPage from './pagess/WishListPage';
import AfterApplyModal from './components/page_component/AfterApplyModal';
import Messages from './pagess/Messages';
import NotFound from './pagess/NotFound';
import AppliedPrograms from './pagess/worker_pages/AppliedPrograms';
import Content from './pagess/worker_pages/Content';
import Translator from './pagess/worker_pages/Translator';
import TranslatorCountries from './pagess/translator_pages/TranslatorCountries';
import TranslatorCountryFaq from './pagess/translator_pages/TranslatorCountryFaq';
import TranslatorInstitutions from './pagess/translator_pages/TranslatorInstitutions';
import TranslatorInstitutionFaq from './pagess/translator_pages/TranslatorInstitutionFaq';
import TranslatorAccomodation from './pagess/translator_pages/TranslatorAccomodation';
import TranslatorApplication from './pagess/translator_pages/TranslatorApplication';
import TranslatorVisa from './pagess/translator_pages/TranslatorVisa';
import TestPage from './pagess/TestPage';
import TranslatorEducationDegree from './pagess/translator_pages/TranslatorEducationDegree';
import TranslatorExtraActivity from './pagess/translator_pages/TranslatorExtraActivity';
import TranslatorIntOlympiads from './pagess/translator_pages/TranslatorIntOlympiads';
import ConfirmEmail from './pagess/ConfirmEmail';
import EduconTeacher from './pagess/edu_consultant_pages/teachers/EduconTeacher';
import AcceptedStudents from './pagess/AcceptedStudents';
import WorkerOtherPages from './pagess/worker_pages/pages/WorkerOtherPages';
import UploadAutoMations from './pagess/UploadAutoMations';
import AutoAddPrograms from './pagess/auto_add/AutoAddPrograms';
import StudentNoteModal from './components/page_component/StudentNoteModal';
import SingleAcceptedStudent from './components/single_pages/accepted_student/SingleAcceptedStudent';
import ForgotPassword from './pagess/ForgotPassword';
import ResetPassword from './pagess/ResetPassword';
import DocumentsCountry from './pagess/worker_pages/DocumentsCountry';
import Documents from './pagess/worker_pages/pages/Documents';
import DocumentData from './pagess/worker_pages/pages/DocumentData';
import AddStateCityUniPage from './pagess/auto_add/AddStateCityUniPage';
import AutoEditAllPrograms from './pagess/auto_edit_programs/AutoEditAllPrograms';
import CountryRobo from './pagess/country_robo/CountryRobo';
import UniRobo from './pagess/country_robo/UniRobo';
import CompanyInOuts from './pagess/worker_pages/CompanyInOuts';
import UpdateAllProgramUni from './pagess/update_all_prog/UpdateAllProgramUni';
import ManagerCommission from './pagess/worker_pages/ManagerCommission';
import EduconStats from './pagess/edu_consultant_pages/stats/EduconStats';
import EduconProgramApplyModal from './components/EduconProgramApplyModal';
import BlogTopics from './pagess/worker_pages/blogs/BlogTopics';
import AdminBlogs from './pagess/worker_pages/blogs/AdminBlogs';
import StaffStudents from './pagess/edu_consultant_pages/students/StaffStudents';
import StudentInfo from './pagess/edu_consultant_pages/students/student_info/StudentInfo';
import RedirectPage from './pagess/RedirectPage';
import ChangeUniModal from './components/page_component/institutions/ChangeUniModal';
function withHooks(Component){
  return props => <Component {...props} windowWidth={useWindowSize()} />
}
export class App extends Component {
  componentDidMount(){
    document.addEventListener("wheel", (event) => {  
      if (document.activeElement.type === "number") {  
          document.activeElement.blur(); 
      }  
    });
    if(localStorage.getItem('token')){
      this.props.loggedInUserInfo()
    }
    this.props.mainDashboardTranslate(this.props.languageId)
    this.props.getUnirepRoleTranslate(this.props.languageId);

    

    this.props.changeStateValue({
      name: 'confirmEmail',
      value: {
        email: localStorage.getItem('eKey'),
        sentTime: localStorage.getItem('start'),
        deadline: localStorage.getItem('deadline'),
        path: localStorage.getItem('path'),
      }
    })

  
    // document.addEventListener('dblclick', (e)=>{
    //   // console.log(e.target.textContent)
    //   if(e.target.ariaLabel){
    //     const splt = e.target.ariaLabel.split('.')
    //     let url = splt[0];
    //     let title = splt[1];
    //     this.props.changeStateValue({
    //       name: 'translateModal',
    //       value:{
    //         position: true,
    //         url:url,
    //         title: title,
    //         text: e.target.textContent
    //       }
    //     })
    //   }
      
    //   // console.log(e.target.ariaLabel)
    // })

  }

  render() {
    const {user, translateModal, loader, windowWidth, loaderLong, afterApplyModalVisible, confirmEmail, studentNoteModal, educonApplyModal, uniChangeModal} = this.props;
    const WIN_WIDTH = windowWidth.width || window.innerWidth;
   
    return (
      <Router>
        <RedirectPage />
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        {
          user !==""?
          <SiteHeader />:null
        }
      
      {
        
        WIN_WIDTH < 768 && <HeaderSearch />
      }
  
      
       <Routes>
        {
          user?.role == "edu_consultant"?
          <Route forceRefresh={true} path="/"  element={<StaffStudents />} />:
          // <Route forceRefresh={true} path="/"  element={<MainPage />} />:
          <Route forceRefresh={true} path="/" exact element={<MainPage />} /> 
        }
         
          {/* <Route forceRefresh={true} path="/programs"  element={<Programs />} /> 
          <Route forceRefresh={true} path="/program/:id"  element={<SingleProgram />} /> 
          <Route forceRefresh={true} path="/institutions"  element={<Universities />} /> 
          <Route forceRefresh={true} path="/institution/:id"  element={<SingleUniversity />} /> 
          <Route forceRefresh={true} path="/institution-data/:id"  element={<InstitutionData />} /> 
          <Route forceRefresh={true} path="/countries"  element={<Country />} /> 
          <Route forceRefresh={true} path="/country-data/:id"  element={<CountryData />} /> 
          <Route forceRefresh={true} path="/country/:id"  element={<SingleCountry />} /> 
          <Route forceRefresh={true} path="/disciplines"  element={<Disciplines />} /> 
          <Route forceRefresh={true} path="/discipline/:id"  element={<SingleDiscipline />} /> 
          <Route forceRefresh={true} path="/schoolarships"  element={<Schoolarships />} /> 
          <Route forceRefresh={true} path="/students"  element={<Students />} /> 
          
         
          <Route forceRefresh={true} path="/accepted-students"  element={<AcceptedStudents />} /> 
          <Route forceRefresh={true} path="/accepted-student/:id"  element={<SingleAcceptedStudent />} /> 
          <Route forceRefresh={true} path="/forgot-pass"  element={<ForgotPassword />} /> 
          <Route forceRefresh={true} path="/reset-password"  element={<ResetPassword />} />  */}
        
          {/* <Route forceRefresh={true} path="/edituniversity"  element={<EditUniversity />} />  */}

          {/* <Route forceRefresh={true} path="/state/:id"  element={<States />} />  */}
          {/* <Route forceRefresh={true} path="/city/:id"  element={<City />} />  */}
          <Route forceRefresh={true} path="/accepted-students"  element={<AcceptedStudents />} /> 
        {
           user !== '' && user?.role == 'edu_consultant'|| user !== ''  && user?.role == 'uni_rep'?
           <>
            {/* <Route forceRefresh={true} path="/teachers"  element={<EduconTeacher />} />  */}
            <Route forceRefresh={true} path="/student-info/:id"  element={<StudentInfo />} /> 
           </>:null
        }
        {
           user !== '' && user?.role == 'teacher'?
           <>
            <Route forceRefresh={true} path="/student-info/:id"  element={<StudentProfile />} /> 
            
           </>:null
        }


          {
            user !== '' && user?.role == 'edu_consultant' ||  user !== '' && user?.role == 'teacher'?
            <Route forceRefresh={true} path="/check-info/:id"  element={<StudentSimulator />} />:
            <Route forceRefresh={true} path="/simulator"  element={<StudentSimulator />} />
          }
          
          <Route forceRefresh={true} path="/login"  element={<LoginPage />} /> 
          {/* <Route forceRefresh={true} path="/signup"  element={<Register />} />  */}
          {
            user != '' && user?.role == 'uni_rep' || user != '' && user?.role == 'content'?
            <>
              <Route forceRefresh={true} path="/institution/:id/edit"  element={<EditUniversity />} /> 
              <Route forceRefresh={true} path="/inbox-app"  element={<Messages />} /> 
              <Route forceRefresh={true} path="/institution/create"  element={<CreateUniversity />} /> 
              <Route forceRefresh={true} path="/edit"  element={<EditPrograms />} /> 
              <Route forceRefresh={true} path="/create-program/:id"  element={<CreateProgram />} /> 
              <Route forceRefresh={true} path="/edit-program/:id"  element={<EditPrograms />} /> 
              <Route forceRefresh={true} path="/dublicate-program/:id"  element={<DublicateProgram />} />
              <Route forceRefresh={true} path="/other-page"  element={<WorkerOtherPages />} />
              <Route forceRefresh={true} path="/upload-uni"  element={<UploadAutoMations />} /> 
             <Route forceRefresh={true} path="/add-programs-auto"  element={<AutoAddPrograms />} /> 
             <Route forceRefresh={true} path="/add-all-auto"  element={<AddStateCityUniPage />} /> 
             <Route forceRefresh={true} path="/programs-auto-update/:id"  element={<AutoEditAllPrograms />} /> 
             <Route forceRefresh={true} path="/programs-auto-update-all/:id"  element={<UpdateAllProgramUni />} /> 
             <Route forceRefresh={true} path="/country-robo"  element={<CountryRobo />} /> 
             <Route forceRefresh={true} path="/uni-robo"  element={<UniRobo />} /> 
             <Route forceRefresh={true} path="/company-stats"  element={<CompanyInOuts />} /> 
             
            
            </> :null
          }
          {
            user != '' && user?.role == 'uni_rep' ||  user != '' && user?.role == 'edu_consultant'?
            <>
              <Route forceRefresh={true} path="/documents"  element={<DocumentsCountry />} /> 
              <Route forceRefresh={true} path="/document/:countryId/:categoryId"  element={<Documents />} /> 
              <Route forceRefresh={true} path="/document-data/:id"  element={<DocumentData />} /> 
              <Route forceRefresh={true} path="/finance"  element={<EduconStats />} /> 
              <Route forceRefresh={true} path="/commissions"  element={<ManagerCommission />} /> 
              <Route forceRefresh={true} path="/blog-topics"  element={<BlogTopics />} /> 
              <Route forceRefresh={true} path="/blogs"  element={<AdminBlogs />} /> 
              <Route forceRefresh={true} path="/programs"  element={<Programs />} /> 
              <Route forceRefresh={true} path="/company-stats"  element={<CompanyInOuts />} /> 
          <Route forceRefresh={true} path="/program/:id"  element={<SingleProgram />} /> 
          <Route forceRefresh={true} path="/institutions"  element={<Universities />} /> 
          <Route forceRefresh={true} path="/institution/:id"  element={<SingleUniversity />} /> 
          <Route forceRefresh={true} path="/institution-data/:id"  element={<InstitutionData />} /> 
          <Route forceRefresh={true} path="/countries"  element={<Country />} /> 
          <Route forceRefresh={true} path="/country-data/:id"  element={<CountryData />} /> 
          <Route forceRefresh={true} path="/country/:id"  element={<SingleCountry />} /> 
          <Route forceRefresh={true} path="/disciplines"  element={<Disciplines />} /> 
          <Route forceRefresh={true} path="/discipline/:id"  element={<SingleDiscipline />} /> 
          <Route forceRefresh={true} path="/schoolarships"  element={<Schoolarships />} /> 
          <Route forceRefresh={true} path="/students"  element={<Students />} /> 
          
         
          <Route forceRefresh={true} path="/accepted-students"  element={<AcceptedStudents />} /> 
          <Route forceRefresh={true} path="/accepted-student/:id"  element={<SingleAcceptedStudent />} /> 
          <Route forceRefresh={true} path="/forgot-pass"  element={<ForgotPassword />} /> 
          <Route forceRefresh={true} path="/reset-password"  element={<ResetPassword />} /> 
            </>:null
          }
         
          <Route forceRefresh={true} path="/student-information/:id"  element={<StudentInformation />} /> 
          <Route forceRefresh={true} path="/application-data"  element={<ApplicationInformation />} /> 
          <Route forceRefresh={true} path="/assign-student"  element={<AssignStudent />} /> 
          <Route forceRefresh={true} path="/profile"  element={<StudentProfile />} /> 
          <Route forceRefresh={true} path="/parents"  element={<Parents />} /> 
          <Route forceRefresh={true} path="/parent-profile"  element={<ParentProfile />} /> 
          <Route forceRefresh={true} path="/edu-con"  element={<EduCons />} /> 
          <Route forceRefresh={true} path="/edu-con-students/:id"  element={<EduConStudents />} /> 
          <Route forceRefresh={true} path="/wish-list"  element={<WishListPage />} /> 
          <Route forceRefresh={true} path="/messages"  element={<Messages />} /> 
          <Route forceRefresh={true} path="/applications"  element={<AppliedPrograms />} /> 
          <Route forceRefresh={true} path="/content"  element={<Content />} /> 
          <Route forceRefresh={true} path="/translators"  element={<Translator />} /> 
          <Route forceRefresh={true} path="/confirm-email"  element={<ConfirmEmail />} /> 
          <Route forceRefresh={true} path="/test"  element={<TestPage />} /> 
          {
            user != '' && user?.role == 'translator'?
            <>
            <Route forceRefresh={true} path="/tr-countries"  element={<TranslatorCountries />} /> 
            <Route forceRefresh={true} path="/tr-countries-faq"  element={<TranslatorCountryFaq />} /> 
            <Route forceRefresh={true} path="/tr-institutions"  element={<TranslatorInstitutions />} /> 
            <Route forceRefresh={true} path="/tr-institutions-faq"  element={<TranslatorInstitutionFaq />} /> 
            <Route forceRefresh={true} path="/tr-accomodation"  element={<TranslatorAccomodation />} /> 
            <Route forceRefresh={true} path="/tr-application"  element={<TranslatorApplication />} /> 
            <Route forceRefresh={true} path="/tr-visa"  element={<TranslatorVisa />} /> 
            <Route forceRefresh={true} path="/tr-edu-degree"  element={<TranslatorEducationDegree />} /> 
            <Route forceRefresh={true} path="/tr-extra-activity"  element={<TranslatorExtraActivity />} /> 
            <Route forceRefresh={true} path="/tr-int-olympiads"  element={<TranslatorIntOlympiads />} /> 
            </>:null
          }
          
          <Route forceRefresh={true} path="*"  element={<NotFound />} /> 
        

       </Routes>
       { user == ''?
        WIN_WIDTH < 768 && <FooterNav />:null
       }
       
       {/* <Footer /> */}
       <div className='h-[100px]'></div>
       {translateModal.position? <TranslateModal />:null}
       
       { 
       user?.role == "uni_rep" && window.location.pathname == "/" || user == "" && window.location.pathname == "/"?
       <></>:
       loader || loaderLong? 
        <Loading />:null
       }
       {afterApplyModalVisible?
        <AfterApplyModal />:null
       }
       {
        studentNoteModal?.position?
        <StudentNoteModal />:null
       }
       {
        educonApplyModal?.position?
        <EduconProgramApplyModal />:null
       }
       {uniChangeModal?.position? <ChangeUniModal />:null}
       
       </div>
      </Router>
      
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user,
  translateModal: state.Data.translateModal,
  loader: state.Data.loader,
  loaderLong: state.Data.loaderLong,
  afterApplyModalVisible: state.Data.afterApplyModalVisible,
  languageId: state.Data.languageId,
  confirmEmail: state.Data.confirmEmail,
  studentNoteModal: state.Data.studentNoteModal,
  educonApplyModal: state.Data.educonApplyModal,
  uniChangeModal: state.Data.uniChangeModal,
});
const mapDispatchToProps = {loggedInUserInfo, changeTranslateData, changeStateValue, mainDashboardTranslate, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(App))