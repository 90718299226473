import React, { Component } from 'react'
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {getInstitutionData, getPrograms, getScholarships} from '../../../actions/MainAction';
import { getWorkerPrograms , getInstitutionFaq} from '../../../actions/WorkerActions';
import ImageHeader from '../layouts/ImageHeader';
import Programs from './programs/Programs';
import {getUnirepRoleTranslate, mainDashboardTranslate} from '../../../actions/TranslateAction';
import CommentsContainer from './comments/CommentsContainer';
import Buttons from './components/Buttons';
import About from './components/About';
import Faqs from './components/Faqs';
import RelatedUniversity from './related_university/RelatedUniversity';
import Scholarship from './components/Scholarship';
import Students from './students/Students';

function withParams(Component){
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />
}
export class SingleUniversity extends Component {
  state={
    page: 'program',
    filterDiscipline:'',
    filterDegree:'',
    pageIndex:1
  }
    componentDidMount(){
        window.scrollTo(0, 0)
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.mainDashboardTranslate(this.props.languageId);
        this.props.getInstitutionData(this.props.languageId, this.props.params.id)
        .then(resp=>{
          if(resp == 404){
            this.props.navigate("/*")
          }
          
        })
        this.props.getInstitutionFaq( this.props.params.id, this.props.languageId)
        if(localStorage.getItem('token')){
          this.props.getWorkerPrograms(1, this.props.languageId, this.props.params.id)

        }else{
          this.props.getPrograms(1, this.props.languageId, this.props.params.id)
        }
        this.props.getScholarships('public-program-schoolarship', 1, this.props.languageId, '', '', '', '', '',  this.props.params.id)
    }
    getAfterApply = () =>{
      if(localStorage.getItem('token')){
        this.props.getWorkerPrograms(this.state.pageIndex, this.props.languageId, this.props.params.id, this.state.filterDiscipline, this.state.filterDegree)

      }else{
        this.props.getPrograms(this.state.pageIndex, this.props.languageId, this.props.params.id)
      }
    }
    
    changePage = (page) =>{
      this.setState({
        page: page
      })
    }

    getAfterRedirect = (id) =>{
      window.scrollTo(0, 0)

      this.props.getInstitutionData(this.props.languageId,id)
    }

    loadMore = (page)=>{
      this.setState({pageIndex:page})
      if(localStorage.getItem('token')){
        this.props.getWorkerPrograms(page, this.props.languageId, this.props.params.id, this.state.filterDiscipline, this.state.filterDegree)

      }else{
        this.props.getPrograms(page, this.props.languageId, this.props.params.id, this.state.filterDiscipline, undefined,undefined, undefined, undefined,this.state.filterDegree)
      }
    }
    loadMoreScholarhip=(page)=>{
      this.props.getScholarships('public-program-schoolarship', page, this.props.languageId, '', '', '', '', '',  this.props.params.id)
    }
    getAfterApplySch = ()=>{
      this.props.getScholarships('public-program-schoolarship', 1, this.props.languageId, '', '', '', '', '',  this.props.params.id)
    }

    changeFilterData=(name, data)=>{
      this.setState({[name]: data})
    }
  render() {
    const {institutionData, programs, unirepRoleTranslate, scholarshipPrograms} = this.props;
    const {page} = this.state;
    // console.log(institutionData)
    return (  
      <div  className={`ListingDetailPage nc-ListingStayDetailPage `} data-nc-id="ListingStayDetailPage">
        {
            institutionData !== ''?
            <ImageHeader  title={institutionData.name} images={institutionData?.institutions?.images} description={institutionData.description} image={institutionData?.institutions?.image_full_url} />:null
        }
        <div className='container relative mt-10'>
          <Buttons student={institutionData?.students} sch={scholarshipPrograms} changePage={this.changePage}/>
        </div>
            <main className="container relative z-10 mt-11 flex flex-col ">
            <div >
                {
                  page == 'program'?
                  <Programs changeFilterData={this.changeFilterData} loadMore={this.loadMore} getAfterApply={this.getAfterApply} id={institutionData.institutions_id} programs={programs} />:
                  page == 'student'?
                  <Students  students={institutionData?.students} />:
                  page == 'scholarship'?
                  <Scholarship  loadMore={this.loadMoreScholarhip} getAfterApply={this.getAfterApplySch} scholarships={scholarshipPrograms} />:
                  page == 'comment'?
                  <CommentsContainer />:
                  page == 'about'?
                  <About visa={institutionData.visa} living={institutionData.living} youtubeLink={institutionData.institutions.video_link} mapLink={institutionData.institutions.map} desc={institutionData.description} />:
                  page == 'faq'?
                  <Faqs  />:null
                }
                </div>
                
            </main>
           <RelatedUniversity getAfterRedirect={this.getAfterRedirect} />
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    institutionData: state.Data.institutionData,
    languageId: state.Data.languageId,
    programs: state.Data.programs,
    unirepRoleTranslate: state.Data.unirepRoleTranslate,
    scholarshipPrograms: state.Data.scholarshipPrograms,

});
const mapDispatchToProps = {getInstitutionData, getPrograms, getWorkerPrograms, getUnirepRoleTranslate,getInstitutionFaq,  mainDashboardTranslate, getScholarships}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(SingleUniversity))