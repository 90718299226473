import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getDocumentCategory } from '../../../actions/WorkerActions';
import { changeStateValue } from '../../../redux/MainReducer';
import Maincard from './components/Maincard';
import MainModal from '../../../components/modals/MainModal';
export class DocumentCategory extends Component {
  componentDidMount(){
    this.props.getDocumentCategory();
  }
  getAfterSubmit=()=>{
    this.props.getDocumentCategory()
  }
  editDataBtn=(data)=>{
      this.props.changeStateValue({
          name: 'newCountryData',
          value: {
            id: data?.id? data?.id: '',
            position: true,
            title: data?.name?data?.name:'',
          }
        })
        this.props.changeStateValue({
          name: 'otherExamPosition',
          value: false
        })
  }
  render() {
    const {documentCategories, newCountryData} = this.props;
    return (
      <div>
      <button onClick={this.editDataBtn.bind(this, '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a Doc Category</button>
      <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 sm:gap-6 md:gap-8`}>
      {
          documentCategories.map((item ,i)=>{
              return(
                  <Maincard
                      key={i}
                      name={item.title}
                      id={item.id}
                      editDataBtn={this.editDataBtn}
                  />
              )
          })
      }
      </div>
      {newCountryData.position?<MainModal getAfterChange={this.getAfterSubmit} url={'/document-category'} stateName="newCountryData" modalData={newCountryData} />:null}
  </div>
    )
  }
}
const mapStateToProps = (state) =>({
  documentCategories: state.Data.documentCategories,
  newCountryData: state.Data.newCountryData,
});
const mapDispatchToProps = {getDocumentCategory, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentCategory)
