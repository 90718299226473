import React, { Component } from 'react'

export class ArchiveSVG extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={25}
        fill="none"
        viewBox="0 0 24 24"

      >
        <path
          fill={"#080341"}
          fillRule="evenodd"
          d="M21 9.75h-1.5v8.56l-1.19 1.19H5.69L4.5 18.31V9.75H3V4.5h18v5.25Zm-15 0h12v7.94l-.31.31H6.31L6 17.69V9.75ZM19.5 6v2.25h-15V6h15Zm-9.75 7.5H15V12H9.75v1.5Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default ArchiveSVG
