import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getEducationLanguage } from '../../../actions/WorkerActions';
import MainModal from '../../../components/modals/MainModal';
import { changeStateValue } from '../../../redux/MainReducer';
import Maincard from './components/Maincard';
export class EducationLanguage extends Component {
  componentDidMount(){
    this.props.getEducationLanguage('1');
  }
  getAfterSubmit=()=>{
    this.props.getEducationLanguage('1');
  }
  editDataBtn=(data)=>{
    this.props.changeStateValue({
        name: 'newCountryData',
        value: {
          id: data?.id? data?.id: '',
          position: true,
          name: data?.name?data?.name:'',
          description: '',
          active: 1,
          lang_id: 1,
          translate_id: data.translate_id
        }
      })
      this.props.changeStateValue({
        name: 'otherExamPosition',
        value: false
      })
}

  render() {
    const {educationLanguage, newCountryData} = this.props;
    // console.log(educationLanguage)
    return (
      <div>
        <button onClick={this.editDataBtn.bind(this, '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a Edu Lang</button>
            <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 sm:gap-6 md:gap-8`}>
            {
                educationLanguage.map((item ,i)=>{
                    return(
                        <Maincard
                            key={i}
                            name={item.language}
                            id={item.education_language_id}
                            translate_id={item.id}
                            editDataBtn={this.editDataBtn}
                        />
                    )
                })
            }
          </div>
         {newCountryData.position?<MainModal getAfterChange={this.getAfterSubmit} url={'/education-language'} stateName="newCountryData" modalData={newCountryData} />:null}
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  educationLanguage: state.Data.educationLanguage,
  newCountryData: state.Data.newCountryData,
});
const mapDispatchToProps = {getEducationLanguage, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(EducationLanguage)