import React, { Component } from 'react'
import img from '../../../images/noMsg.png'
export class StartConversation extends Component {
  render() {
    return (
      <div className='start_conversation_container'>
        <img src={img} />
        <p>Choose the group to start conversation</p>
      </div>
    )
  }
}

export default StartConversation