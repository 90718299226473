import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select from 'react-select';
import { getAllCountries, chatGptApi, addCountryInfo } from '../../actions/MainAction';
import ResultCard from './ResultCard';
import Swal from 'sweetalert2';
import Loading from '../../components/page_component/Loading';
export class CountryRobo extends Component {
    state={
        page: 'faqPrompt',
        faqPrompt: 'Create 10 FAQs for each country with answers for international students from these countries:',
        aboutPrompt:'Create brief description for each country for international students from these countries:',
        visaPrompt:'Create 10 FAQs for each country with answers  about student visa for international students from these countries:',
        livingPrompt:'Create 10 FAQs for each country with answers about the living for international students from these countries:',
        workingPrompt:'Create 10 FAQs for each country with answers about working during and after studies for international students from these countries:',

        lastPropmpt: "as json format. Example : [{'country_name':'', 'results': [{'title':'', 'description':''}]}]",
        textValue: '',
        selectedPrompt: 'Create 10 FAQs for each country with answers for international students from these countries:',
        countries:[],
        modalVisible:false,
        modalData:[]
    }

    setTextValue(firstPrompt, countries, lastPropmpt){
        let prompt=`${firstPrompt} ${countries.join(', ')} ${lastPropmpt}`;
        this.setState({textValue: prompt})
    }
    componentDidMount(){
        this.setTextValue(this.state.selectedPrompt, this.state.countries, this.state.lastPropmpt);
        this.props.getAllCountries('', '', 1)
    }
    selectCountries=(data)=>{
        let arr = [];
        for(const item of data){
            arr.push(item?.label)
        }
        this.setState({countries: arr})
        this.setTextValue(this.state.selectedPrompt,arr, this.state.lastPropmpt);
    }

    changePage(page){
        this.setState({page: page});
        this.setState({selectedPrompt: this.state[page]})
        this.setTextValue(this.state[page], this.state.countries, this.state.lastPropmpt);
    }

    fetchDataBtn(prompt){
        const obj = {
            "model": "gpt-3.5-turbo",
            "messages": [{
                "role": "user",
                "content": prompt
            }]
        }
        this.props.chatGptApi(obj)
        .then(resp=>{
            if(resp.msg == 'success'){  
                this.setState({
                    modalData:JSON.parse(resp.data?.choices[0]?.message?.content),
                    modalVisible:true
                })
            }else{
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wronge. please try again',
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
        })
    }
    saveData(data, type){
        if(type == "aboutPrompt"){
            
            let newData = [];
            for(let i = 0; i < data.length; i++){
                let html = '';
                for(let j = 0; j < data[i].results.length; j ++){
                    let cont = `
                        <div style="margin-top:12px;">
                            <h3 style="font-size:17px; font-weight: 600;">${data[i].results[j]?.title}</h3>
                            <p style="font-size:14px; color: gray; margin-top:5px">${data[i].results[j]?.description}</p>
                        </div>
                    `;
                    html+=cont;
                }
                newData.push({
                    country_name: data[i].country_name,
                    results: [{title: "about", description: `<div>${html}</div>`}]
                })
            }
            this.props.addCountryInfo({data:newData, type})
            .then(resp=>{
                if(resp == 'success'){
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Added data',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.setState({modalData:[], modalVisible:false})
                }
            })
        }else{
            this.props.addCountryInfo({data, type})
            .then(resp=>{
                if(resp == 'success'){
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Added data',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.setState({modalData:[], modalVisible:false})
                }
            })
        }
        
    }
  render() {
    const {page, textValue, countries, modalData, modalVisible} = this.state;
    const {allCountries, loader, loaderLong,} = this.props;
    const countriesOption = [];
    for(const property of [...allCountries].sort((x, y)=> {  return y?.countries?.institution_count - x?.countries?.institution_count})){
        countriesOption.push({
            label: property.name,
            value: property?.countries_id
        })
    }
    return (
      <div className='container'>
        <div className='flex gap-4 items-center'>
            <button onClick={()=>this.changePage("faqPrompt")} className={`${page == 'faqPrompt'? ' bg-blue-600 text-white ': 'border' } p-1 text-sm font-semibold cursor-pointer rounded-xl`}> FAQs</button>
            <button onClick={()=>this.changePage("aboutPrompt")} className={`${page == 'aboutPrompt'? ' bg-blue-600 text-white ': 'border' } p-1 text-sm font-semibold cursor-pointer rounded-xl`}> About</button>
            <button onClick={()=>this.changePage("visaPrompt")} className={`${page == 'visaPrompt'? ' bg-blue-600 text-white ': 'border' } p-1 text-sm font-semibold cursor-pointer rounded-xl`}> Visa</button>
            <button onClick={()=>this.changePage("livingPrompt")} className={`${page == 'livingPrompt'? ' bg-blue-600 text-white ': 'border' } p-1 text-sm font-semibold cursor-pointer rounded-xl`}> Living</button>
            <button onClick={()=>this.changePage("workingPrompt")} className={`${page == 'workingPrompt'? ' bg-blue-600 text-white ': 'border' } p-1 text-sm font-semibold cursor-pointer rounded-xl`}> Working</button>
        </div> 
        <div className='mt-5'>
            <textarea onChange={(e)=>this.setState({textValue:e.target.value})} className='rounded-lg text-lg' value={textValue}></textarea>
        </div>
        <div className='mt-4'>
        <Select
            isMulti={true}
            onChange={this.selectCountries}
            options={countriesOption}
            placeholder={"Select countries"}
            className="rounded-2xl "
        />
        </div>
        <button onClick={this.fetchDataBtn.bind(this, textValue)} className={`bg-blue-600 text-white mt-5 p-1 text-sm font-semibold cursor-pointer rounded-xl`}> Fetch Results</button>
        {
            modalVisible?
            <div className='background_shadow flex items-center justify-center'>
                <div style={{ maxHeight: '80vh', overflow: 'auto'  }} className='w-1/3 bg-white p-4 rounded-xl'>
                    <h2>Results</h2>
                    <div style={{ overflow: 'auto' }} className='mt-3 mb-3'>
                        {
                            modalData?.map((item, i)=>{
                                return(
                                    <ResultCard key={i} data={item} /> 
                                )
                            })
                        }
                    </div>
                    <div className='flex items-center justify-between'>
                        <button onClick={()=>this.setState({modalData:[], modalVisible:false})} className='bg-red-700 text-white text-sm p-1 rounded-xl cursor-pointer'>Close</button>
                        <button onClick={this.saveData.bind(this, modalData, page)} className='bg-blue-700 text-white text-sm p-1 rounded-xl cursor-pointer'>Save</button>
                    </div>
                </div>
            </div>:null
        }
         {
             loader || loaderLong? 
             <Loading />:null
            
           }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    allCountries: state.Data.allCountries,
    loader: state.Data.loader,
    loaderLong: state.Data.loaderLong,
});
const mapDispatchToProps = {getAllCountries, chatGptApi, addCountryInfo}
export default connect(mapStateToProps, mapDispatchToProps)(CountryRobo)
