import React, { Component } from 'react'

export class SvgUp extends Component {
  render() {
    return (
        <svg width="10" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.96502 0.841098C7.2565 0.53018 7.7435 0.53018 8.03573 0.841098L14.7786 8.01507C15.0738 8.32998 15.0738 8.84067 14.7786 9.15477C14.4834 9.46968 14.0038 9.46968 13.7086 9.15477L7.50003 2.55068L1.29219 9.15557C0.996222 9.47047 0.517449 9.47047 0.221479 9.15557C-0.0737436 8.84067 -0.0737435 8.32998 0.221479 8.01587L6.96502 0.841098Z" fill="#fff"/>
        </svg>
    )
  }
}

export default SvgUp