import React, { Component } from 'react'
import JoditEditor from "jodit-react"; 
import { connect } from 'react-redux';
import {changeStateValue} from '../../../redux/MainReducer';
import { changeTranslateData } from '../../../actions/TranslateAction';
import Swal from 'sweetalert2';
export class Editor extends Component {
    state={
        question: '',
        answer: '',
        visible: false
    }
    config = {
		zIndex: 0,
        readonly: false,
        activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
        toolbarButtonSize: 'middle',
        theme: 'default',
        saveModeInCookie: false,
        spellcheck: true,
        editorCssClass: false,
        triggerChangeEvent: true,
        width: 'auto',
        height: 300,
        minHeight: 100,
        direction: '',
        language: 'auto',
        debugLanguage: false,
        i18n: 'en',
        tabIndex: -1,
        toolbar: true,
        enter: "P",
        useSplitMode: false,
        colors: {
            greyscale:  ['#000000', '#ff0000', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
            palette:    ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
            full: [
                '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
                '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
                '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
                '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
            ]
        },
        colorPickerDefaultTab: 'text',
        imageDefaultWidth: 300,
        removeButtons: [
            'source', 
            'fullsize'
        ],
        disablePlugins: [],
        extraButtons: [
            'strikethrough',
            'underline',
            'hr'
        ],
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        
        buttons: [
           
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent',  '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'video',
            'table',
            'link', '|',
            'align', 'undo', 'redo', '|',
            'hr',
            'eraser',
            'copyformat', '|',
            'symbol',
            'print',
            'about'
        ],
        buttonsXS: [
            'bold',
            'image', '|',
            'brush',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots',
        ],
        events: {},
        textIcons: false,
        showWordsCounter: false,
        showCharsCounter: false
    }
    changeValue = (e)=>{
        this.setState({
            answer: e
        })
    }

    changeInpValue(e){
        this.setState({
            question: e.target.value
        })
    }

    saveData(data, question, answer, e){
        if(question != '' && answer != ''){
            this.setState({
                visible: true
            })
            let formData = new FormData();
            formData.append(data[0][0], question);
            formData.append(data[1][0], answer);
            let url = this.props.url
            this.props.changeTranslateData(url, formData)
            .then(resp=>{
                this.props.closeEditor()
                this.setState({
                    visible: false
                })

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                  })
                  
                  Toast.fire({
                    icon: 'success',
                    title: 'Data Added successfully'
                  })
                  this.props.getAfterSave()
            })
        }
    }

    closeModal(){
        this.props.closeEditor()

    }
  render() {
    const {question, answer, visible} = this.state
    const {data} = this.props;

    return (
      <div className='mt-10'>
        <input value={question !=''?question : data[0][1]} type={'text'} onChange={this.changeInpValue.bind(this)}  className="mb-3"/>
         <JoditEditor
                    config={this.config}
                    value={answer !=''?answer : data[1][1]}
                    onChange={this.changeValue.bind(this)}
                />
        <div className='flex justify-center mt-3 mb-3 gap-3'>
            <button disabled={visible} onClick={this.saveData.bind(this, data, question, answer)}  style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3' >
                Save
            </button>
            <button onClick={this.closeModal.bind(this)}  style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3' >
                Close
            </button>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
   
});
const mapDispatchToProps = {changeStateValue, changeTranslateData}
export default connect(mapStateToProps, mapDispatchToProps)(Editor)