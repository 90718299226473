import React, { Component } from 'react'
import { connect } from 'react-redux';
import Searchable from 'react-searchable-dropdown'; 
import { changeStateValue } from '../../../redux/MainReducer';
export class DropDown extends Component {
    selectDropValue = (  name, type, id) =>{
        if(type != 'student'){
            this.props.changeStateValue({
                name: name,
                value: id
            }); 
            if(type === 'country' || type == 'state'){
                this.props.getOtherData(type, id)
            }else if(type === 'required' || type === 'sch_required'){
                this.props.getOtherData(type, id)
            }
        }else{
            this.props.getOtherData(id)
        }
        
        
    }
  render() {
    const {placeholder = '', label = '', option = [], name, type, zIndex = 1} = this.props
    return (
        <div style={{ zIndex: zIndex }}>
            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                {label}
            </label>
            <div  className={`block w-full border-neutral-200  
                focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
            > 
                <Searchable 
                    options={option}
                    onSelect={this.selectDropValue.bind( this.label, name, type)}
                    placeholder={placeholder}
                />
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(DropDown)