import React, { Component } from 'react'

export class Image extends Component {
  render() {
    const {url} = this.props
    return (
        <div className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100   uppercase font-semibold shadow-inner rounded-full w-28 h-28 ring-1 ring-white dark:ring-neutral-900`}>  
            <img  className={`absolute inset-0 w-full h-full object-cover rounded-full`}  src={url} alt={'profile image'}/>
        </div>
    )
  }
}

export default Image