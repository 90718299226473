import React, { Component } from 'react'
import { Helmet } from "react-helmet";
import Header from "../components/header_component/Header";

export class SiteHeader extends Component {
  render() {
    return (
      <>
      <Helmet>
        <title>CELT Abroad</title>
      </Helmet>
      <Header  />
    </>
    )
  }
}

export default SiteHeader

