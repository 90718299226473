import React, { Component } from 'react'
import { connect } from 'react-redux';

export class LocationInput extends Component {
    state={
        showPopover: false,
        containerRef: React.createRef(),
        value: '',
        search: ''
    }
    setShowPopover(value, e){
        this.setState({
            showPopover: value
        })
    }
    

  componentDidUpdate(prevProps, prevState){
    if(prevState.showPopover !== this.state.showPopover){
      const {containerRef} = this.state;
    
      window.addEventListener('click', (e)=> {
        if(containerRef && containerRef.current){
          const checkInside = containerRef.current.contains(e.target);
       
          if(!checkInside ){
            this.setState({
              showPopover: false,
              search: ''
            })
          }
        }
        
      })
    }
   
  }

  changeInpValue(e){
    this.setState({
      value: e.target.value,
      search: e.target.value
    })
  }

  clearInput(e){
    this.setState({
      value: '',
      search: '',
      showPopover: false,
    })
  }

  selectOption(item, e){
 
    this.props.selectOption(item.value, this.props.type)
    this.setState({
      value: item.label,
      showPopover: false,
      search: ''
    })
  }
  render() {
    const {showPopover, containerRef, value, search} = this.state;
    const {description, title, options = [], mainTransateData} = this.props;
   
    return (
        <div className={`relative flex flex-[1.5]`}
         ref={containerRef}
         >
        <div
          onClick={this.setShowPopover.bind(this, true)}
          className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
            showPopover ? "nc-hero-field-focused" : "" 
       
          }`}
        >
          <div className="text-neutral-300 dark:text-neutral-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="nc-icon-field"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <div className="flex-grow">
            <input
              className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
              placeholder={title}
              onChange={this.changeInpValue.bind(this)}
              value={value}
              autoFocus={showPopover}
            />
            <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
              {
                value === ''?
                <span className="line-clamp-1">{description}</span>:
                <span className="line-clamp-1">{title}</span>
              }
              
            </span>{
              value !== '' && showPopover?
              <span
                onClick={this.clearInput.bind(this)}
                className=" absolute z-10 w-5 h-5 lg:w-6 lg:h-6 text-sm bg-neutral-200 dark:bg-neutral-800 rounded-full flex items-center justify-center right-1 lg:right-3 top-1/2 transform -translate-y-1/2"
              >
                <i className="las la-times"></i>
              </span>:null
            }
            
          </div>
        </div>
        {
          showPopover ?
          <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
            <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
                {mainTransateData?.recent_search}
              </h3>
              <div className="mt-2">
                {
                options.map((item) =>{
                  if(search !== '' && item.label.toLowerCase().includes(search.toLowerCase()) || search== '' ){
                    return(
                      <span
                          onClick={this.selectOption.bind(this, item)}
                          key={item.value}
                          className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                        >
                          <span className="block text-neutral-400">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 sm:h-6 w-4 sm:w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </span>
                          <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                            {item.label}
                          </span>
                        </span>
                    )
                  }
                })
                }
              </div>
          </div>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  mainTransateData: state.Data.mainTransateData
})
export default connect(mapStateToProps)(LocationInput)