import React, { Component } from 'react'

export class DotSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 25 25"
      
      >
        <circle
          cx={12.5}
          cy={12.5}
          r={1.5}
          fill="#121923"
          stroke="#121923"
          strokeWidth={1.2}
        />
      </svg>
    )
  }
}

export default DotSvg
