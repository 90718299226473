import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getstudentEducationBacground, addNewData } from '../../../actions/StudentActions';
import { getAllCountries, getEducationDegree, getCountryEducationDegree, getGradingScheme } from '../../../actions/MainAction';
import DropDowns from './DropDowns';
import InputNumber from './InputNumber';
import { changeStateValue } from '../../../redux/MainReducer';
export class EducationBacground extends Component {
  state={
    minValue: 0,
    maxValue: 100,
    gpaPosition: false,
    buttonVisible: false
  }
    componentDidMount(){
        this.props.getstudentEducationBacground(this.props.languageId, this.props.student_id)
        .then(resp=>{
          this.props.getCountryEducationDegree(this.props.languageId, resp.study_country_id, resp.study_degree_id)
          if(resp.study_sub_degree_id !== '' && resp.study_sub_degree_id !== null && resp.study_sub_degree_id !== 'null' &&
          resp.study_sub_degree_id !== 'a_level' &&
          resp.study_sub_degree_id !== 'ap' &&
          resp.study_sub_degree_id !== 'ossd' &&
          resp.study_sub_degree_id !== 'ib_diploma' 
          ){
            this.props.getGradingScheme(this.props.languageId, 
              resp.study_country_id, 
              resp.study_degree_id, 
              'education_sub_degree',  resp.study_sub_degree_id).then(response=>{
                if(response.length == 0){
                  this.setState({
                    minValue: 1,
                    maxValue: 100,
                    gpaPosition: true
                  })
                }else if(response.length == 1){
                  this.setState({
                    minValue: +response[0].min_value,
                    maxValue: +response[0].max_value,
                    gpaPosition: true
                  })
                  this.props.changeStateValue({
                    name: 'studentEduBackground.grading_scheme_id',
                    value: response[0].id
                  })
                  this.props.changeStateValue({
                    name: 'studentEduBackground.grade_interval',
                    value:`${+response[0].min_value}-${+response[0].max_value}`
                  })
                }
              })
          }else if(resp.study_degree_id !== '' && resp.study_degree_id !== null && resp.study_degree_id !== 'null'  ){
            this.props.getGradingScheme(this.props.languageId, 
              resp.study_country_id, 
              resp.study_degree_id,  'education_degree',  '')
              .then(response=>{
              
                if(response.length == 0){
                  this.setState({
                    minValue: 1,
                    maxValue: 100,
                    gpaPosition: true
                  })
                }else if(response.length == 1){
                  this.setState({
                    minValue: +response[0].min_value,
                    maxValue: +response[0].max_value,
                    gpaPosition: true
                  })
                  this.props.changeStateValue({
                    name: 'studentEduBackground.grading_scheme_id',
                    value: response[0].id
                  })
                  this.props.changeStateValue({
                    name: 'studentEduBackground.grade_interval',
                    value:`${+response[0].min_value}-${+response[0].max_value}`
                  })
                }
              })
          }
        })
        this.props.getAllCountries('', '', this.props.languageId)
        this.props.getEducationDegree(this.props.languageId)
    }

    getPlaceholder(id, array, placeholder){
      if(id != ''){
        if(array.find(data=> data.value == id)){
          return array.find(data=> data.value == id).label
        }else{
          return placeholder;
        }
         
      }else{
        return placeholder
      }
    }

    getOtherData = (id, type)=>{
    
      this.setState({
        minValue: 1,
        maxValue: 100,
        gpaPosition: false
      })
      if(type == 'countryEduDegree'){
        if(id !== 'a_level' && id !== 'ib_diploma' && id !== 'ossd' && id !== 'ap'){
            this.props.getGradingScheme(this.props.languageId, 
            this.props.studentEduBackground.study_country_id, 
            this.props.studentEduBackground.study_degree_id, 
            'education_sub_degree',  id).then(resp=>{
              if(resp.length == 0){
                this.setState({
                  minValue: 1,
                  maxValue: 100,
                  gpaPosition: true
                })
              }else if(resp.length == 1){
                this.setState({
                  minValue: +resp[0].min_value,
                  maxValue: +resp[0].max_value,
                  gpaPosition: true
                })
                this.props.changeStateValue({
                  name: 'studentEduBackground.grading_scheme_id',
                  value: resp[0].id
                })
                this.props.changeStateValue({
                  name: 'studentEduBackground.grade_interval',
                  value:`${+resp[0].min_value}-${+resp[0].max_value}`
                })
              }
            })
            

        }else{
          this.setState({
            minValue: 0,
            maxValue: 100,
            gpaPosition: false
          })
          this.props.changeStateValue({
            name: 'studentEduBackground.grading_scheme_id',
            value: ''
          })
          this.props.changeStateValue({
            name: 'studentEduBackground.grade_interval',
            value:``
          })
          this.props.changeStateValue({
            name: 'studentEduBackground.study_gpa',
            value:``
          })
          this.props.changeStateValue({
            name: 'studentEduBackground.study_sub_degree_id',
            value: id
          })
        }
      }else if(type == 'gradingScheme'){
        const scheme = this.props.gradingScheme.find(data=> data.id == id);
        if(scheme){
          this.setState({
            minValue: +scheme.min_value,
            maxValue: +scheme.max_value,
            gpaPosition: true
          })
          this.props.changeStateValue({
            name: 'studentEduBackground.grade_interval',
            value: `${+scheme.min_value}-${+scheme.max_value}`
          })
        }

      }else if(type == 'eduLevel'){
        this.props.getCountryEducationDegree(this.props.languageId, this.props.studentEduBackground.study_country_id, id)
        this.props.getGradingScheme(this.props.languageId, 
          this.props.studentEduBackground.study_country_id, 
          id,  'education_degree',  '').then(resp=>{
        
            if(resp.length == 0){
              this.setState({
                minValue: 1,
                maxValue: 100,
                gpaPosition: true
              })
            }else if(resp.length == 1){
              this.setState({
                minValue: +resp[0].min_value,
                maxValue: +resp[0].max_value,
                gpaPosition: true
              })
              this.props.changeStateValue({
                name: 'studentEduBackground.grading_scheme_id',
                value: resp[0].id
              })
              this.props.changeStateValue({
                name: 'studentEduBackground.grade_interval',
                value:`${+resp[0].min_value}-${+resp[0].max_value}`
              })
            }
            this.props.changeStateValue({
              name: 'studentEduBackground.study_sub_degree_id',
              value: ''
            })
          })
      }
      
      
    }

    saveChanges(data, e){
      this.setState({
        buttonVisible: true
      });
      let formData = new FormData();
      for(const property in data){
        formData.append(property, data[property])
      }
      formData.append('student_id', this.props.student_id)
      let url = `update-edu-background`;
       
      this.props.addNewData(url, formData)
      .then(resp=>{
          this.setState({
              buttonVisible: false
          });
          this.props.getstudentEducationBacground(this.props.languageId, this.props.student_id)

      })
    }
  render() {
    const {studentEduBackground, allCountries, educationDegree, countryEducationDegree, gradingScheme, unirepRoleTranslate, mainTransateData} = this.props;
    const {minValue, maxValue, gpaPosition, buttonVisible} = this.state;
    
    const countriesOption = [];
    const eduDegreeOption = [];
    const countryDegreeOption = [];
    const gradingSchemeOption = [];

    for(const item of gradingScheme){
      gradingSchemeOption.push({
        label: `${item.min_value} - ${item.max_value}`,
        value: item.id
      })
    }
    for(const item of countryEducationDegree){
      countryDegreeOption.push({
        label: item.name,
        value: item.country_education_degree_id
      })
    }
    for(const item of educationDegree){
      eduDegreeOption.push({
        label: item.education_type,
        value: item.education_degree_id
      })
    }
    for(const item of allCountries){
      if(item.countries_id == 1 || item.countries_id == 5 || item.countries_id == 3 ||  item.countries_id == 33 || item.countries_id == 34){
        countriesOption.push({
          label: item.name,
          value: item.countries_id
        })
      }
    }

    const subDegree = [
      {
        value: 'a_level',
        label: 'A Level'
      },
      {
        value: 'ib_diploma',
        label: 'IB Diploma'
      },
      {
        value: 'ossd',
        label: 'OSSD'
      },
      {
        value: 'ap',
        label: 'Advanced Placement'
      },
    ];

    const aLevelOption =[
      {
        label: 'A*A*A*',
        value: 'A*A*A*'
      },
      {
        label: 'A*AA',
        value: 'A*AA'
      },
      {
        label: 'AAA',
        value: 'AAA'
      },
      {
        label: 'AAB',
        value: 'AAB'
      },
      {
        label: 'AAC',
        value: 'AAC'
      },
      {
        label: 'ABB',
        value: 'ABB'
      },
      {
        label: 'ABC',
        value: 'ABC'
      },
      {
        label: 'BBB',
        value: 'BBB'
      },
      {
        label: 'BBC',
        value: 'BBC'
      },
      {
        label: 'CCC',
        value: 'CCC'
      },
      {
        label: 'DDD',
        value: 'DDD'
      },
      {
        label: 'EEE',
        value: 'EEE'
      },
    ];

    const apOptions=[
      {
        label: '5555',
        value: '5555'
      },
      {
        label: '5554',
        value: '5554'
      },
      {
        label: '5544',
        value: '5544'
      },
      {
        label: '5444',
        value: '5444'
      },
      {
        label: '4444',
        value: '4444'
      },
      {
        label: '4443',
        value: '4443'
      },
      {
        label: '4433',
        value: '4433'
      },
      {
        label: '4333',
        value: '4333'
      },
      {
        label: '3333',
        value: '3333'
      },
      {
        label: '555',
        value: '555'
      },
      {
        label: '554',
        value: '554'
      },
      {
        label: '544',
        value: '544'
      },
      {
        label: '444',
        value: '444'
      },
      {
        label: '443',
        value: '443'
      },
      {
        label: '433',
        value: '433'
      },
      {
        label: '333',
        value: '333'
      },
    ]

    return (
      <div>
        {
          studentEduBackground != ''?
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5 justify-between mt-5">
          <DropDowns 
            ariaLabel="mainTransateData.studiet_country"
            placeholder = {this.getPlaceholder(studentEduBackground.study_country_id, countriesOption, mainTransateData?.studiet_country)}
            label = {mainTransateData?.studiet_country} 
            option={countriesOption}
            name="studentEduBackground.study_country_id"
            zIndex={4}

          />
          <DropDowns 
            ariaLabel="mainTransateData.education_degree"
            placeholder = {this.getPlaceholder(studentEduBackground.study_degree_id, eduDegreeOption, mainTransateData?.education_degree)}
            label = {mainTransateData?.education_degree}
            option={eduDegreeOption}
            name="studentEduBackground.study_degree_id"
            type="eduLevel"
            getOtherData={this.getOtherData}
            zIndex={3}
          />
          {
           countryDegreeOption.length !== 0 && studentEduBackground?.study_sub_degree_id ||
           countryDegreeOption.length !== 0  &&  studentEduBackground.study_degree_id?
            <DropDowns 
              ariaLabel="mainTransateData.country_edu_deg"
              placeholder = {this.getPlaceholder(studentEduBackground.study_sub_degree_id, studentEduBackground.study_degree_id == 1?[...countryDegreeOption, ...subDegree]: countryDegreeOption, mainTransateData?.country_edu_deg)}
              label = {mainTransateData?.country_edu_deg}
              option={studentEduBackground.study_degree_id == 1?[...countryDegreeOption, ...subDegree]: countryDegreeOption}
              name="studentEduBackground.study_sub_degree_id"
              type="countryEduDegree"
              getOtherData={this.getOtherData}
            />:null
          }
          {
            studentEduBackground?.study_sub_degree_id == 'a_level'?
            <DropDowns 
              placeholder = {this.getPlaceholder(studentEduBackground.study_gpa, aLevelOption, 'A Level')}
              label = 'A Level' 
              option={aLevelOption}
              name="studentEduBackground.study_gpa"
            />:null
          }
          {
            studentEduBackground?.study_sub_degree_id == 'ap'?
            <DropDowns 
              placeholder = {this.getPlaceholder(studentEduBackground.study_gpa, apOptions, 'Advanced Placement')}
              label = 'Advanced Placement' 
              option={apOptions}
              name="studentEduBackground.study_gpa"
            />:null
          }
          {
            studentEduBackground?.study_sub_degree_id == 'ossd'?
            <InputNumber 
              placeholder="OSSD (1-100)"
              label="OSSD (1-100)"
              name="studentEduBackground.study_gpa"
              value={studentEduBackground.study_gpa}
              minValue={1}
              maxValue={100}
            />:null
          }
          {
            studentEduBackground?.study_sub_degree_id == 'ib_diploma'?
            <InputNumber 
              placeholder="IB Diploma (1-45)"
              label="IB Diploma (1-45)"
              name="studentEduBackground.study_gpa"
              value={studentEduBackground.study_gpa}
              minValue={1}
              maxValue={45}
            />:null
          }
          {
            studentEduBackground?.study_sub_degree_id !== 'a_level' && 
            studentEduBackground?.study_sub_degree_id !== 'ib_diploma' && 
            studentEduBackground?.study_sub_degree_id !== 'ossd' && 
            studentEduBackground?.study_sub_degree_id !== 'ossd' && gradingSchemeOption.length > 1?
            <DropDowns 
              ariaLabel="mainTransateData.grading_schema"
              placeholder = {this.getPlaceholder(studentEduBackground.grading_scheme_id, gradingSchemeOption, mainTransateData?.grading_schema)}
              label = {mainTransateData?.grading_schema} 
              option={gradingSchemeOption}
              name="studentEduBackground.grading_scheme_id"
              type="gradingScheme"
              getOtherData={this.getOtherData}
            />:null
          }

          {
            gpaPosition && studentEduBackground?.study_sub_degree_id != 'a_level' && 
            studentEduBackground?.study_sub_degree_id != 'ib_diploma'  && 
            studentEduBackground?.study_sub_degree_id != 'ossd' && 
            studentEduBackground?.study_sub_degree_id != 'ossd' 
            ?
            <InputNumber 
              placeholder={`GPA (${minValue} - ${maxValue})`}
              label={`GPA (${minValue} - ${maxValue})`}
              name="studentEduBackground.study_gpa"
              value={studentEduBackground.study_gpa}
              minValue={minValue}
              maxValue={maxValue}
            />:null
          }
          {/* {
            
          } */}
         </div>:null
        }
         
         <div className="flex justify-center items-center mt-10" >
                  
              <button aria-label="unirepRoleTranslate.update" disabled={buttonVisible} onClick={this.saveChanges.bind(this, studentEduBackground)}  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                              nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                              text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                {unirepRoleTranslate?.update}
              </button>
          </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    studentEduBackground: state.Data.studentEduBackground,
    languageId: state.Data.languageId,
    allCountries: state.Data.allCountries,
    educationDegree: state.Data.educationDegree,
    countryEducationDegree: state.Data.countryEducationDegree,
    gradingScheme: state.Data.gradingScheme,
    unirepRoleTranslate: state.Data.unirepRoleTranslate,
    mainTransateData: state.Data.mainTransateData,
});
const mapDispatchToProps = {getstudentEducationBacground, getAllCountries, getEducationDegree, getCountryEducationDegree, getGradingScheme, changeStateValue, addNewData}
export default connect(mapStateToProps, mapDispatchToProps)(EducationBacground)