import React, { Component } from 'react'
import { connect } from 'react-redux';
import { mainDashboardTranslate, getUnirepRoleTranslate } from '../actions/TranslateAction';
import ChatBox from '../components/page_component/messages_components/ChatBox';
import Search from '../components/page_component/messages_components/Search';
import UserList from '../components/page_component/messages_components/UserList';
// import { NODE_API } from '../MAIN_API';
import { changeStateValue } from '../redux/MainReducer';
// import io from "socket.io-client";
import AllUsers from '../components/page_component/messages_components/AllUsers';
import { getStudentTracking } from '../actions/WorkerActions';
import TrackingBtn from '../pagess/worker_pages/student_information/components/TrackingBtn'
import DocumentsBtn from './worker_pages/student_information/components/DocumentsBtn';
import FinanceBtn from './worker_pages/student_information/components/FinanceBtn';
import InformationContainer from '../components/page_component/messages_components/InformationContainer';
import { getMessagesUsers } from '../actions/MainAction';
import ScholarshipTracking from './worker_pages/student_information/components/ScholarshipTracking';
import { getMessagesBox, getMessagesUserList } from '../actions/MainAction';
import StudentNote from '../components/page_component/StudentNote';
import Notes from './worker_pages/student_information/components/Notes';
import { insertNewData, getComissionForMessage } from '../actions/WorkerActions';
import Swal from 'sweetalert2';
// const socket = io.connect(NODE_API);
export class Messages extends Component {
    state={
        userList: [],
        studentName: '',
        programName: '',
        program_id: '',
        student_id: '',
        msg: '',
        allusersVisible: false,
        informationVisible: false,
        image: '',
        uniName:'',
        listId:'',
        btnVisible: false
        
    }
    componentDidMount(){
        const checkSearch =  window.location.search;
        if(checkSearch != ''){
            if(checkSearch.split("=")[1]){
                this.props.changeStateValue({
                    name:'seachmsgUsers',
                    value: checkSearch.split("=")[1]?.replaceAll("%20", " ")
                })
            }
            // 
        }
        this.props.changeStateValue({
            name: 'messagePage',
            value:{
                groupName: '',
                userName: '',
                messages: '',
                program_id: '',
                student_id: '',
            }
        })
        window.scrollTo(0, 0);
        this.props.mainDashboardTranslate(this.props.languageId)
        this.props.getUnirepRoleTranslate(this.props.languageId);
        // socket.on('get-typing', (data)=>{
        //     this.props.changeStateValue({
        //         name: 'typingMsg',
        //         value: data
        //     })
        // })
        // const {user} = this.props;
        // if(user != ''){
            
        //     socket.emit('chat-users', user);
        //     socket.emit('onlineUsers', user.id)
        //     // socket.emit('remove-room', user.id)
        // }
        // socket.on('get-chat-users', (data)=>{
           
        //     this.setState({
        //         userList: data
        //     })
        // })
        // socket.on('get-all-msg', (data)=>{
            // this.props.changeStateValue({
            //     name: 'messagePage',
            //     value:{
            //         groupName: this.state.programName,
            //         userName: this.state.studentName,
            //         messages: data,
            //         program_id: this.state.program_id,
            //         student_id: this.state.student_id,
            //         image: this.state.image
            //     }
            // })
        // })
    
    }

    componentDidUpdate(prevProps, prevState){
       
        if(prevProps.user != this.props.user){
     
            const {user} = this.props;
            if(user != ''){
                
                // socket.emit('chat-users', user);
                // socket.emit('onlineUsers', user.id)
                // socket.emit('remove-room', user.id)
            }
        }
    }

    joinChatRoom = (student_id, program_id, studentName, programName, image, uniName='', listId='', studentFinanceLock = '')=>{
        this.props.getComissionForMessage(program_id, student_id)
        this.props.changeStateValue({name: 'studentFinanceLock', value: studentFinanceLock})
        this.setState({listId:listId})
        this.props.getMessagesBox(student_id, program_id)
        this.props.getMessagesUserList(this.props.languageId)

        this.props.getMessagesUsers(student_id)
        .then(resp=>{
            if(resp && resp.length != 0){
                this.props.getStudentTracking(student_id,this.props.languageId, program_id)
                this.props.changeStateValue({
                    name: 'message',
                    value: ''
                })
                this.setState({
                    studentName:studentName,
                    programName: programName,
                    program_id: program_id,
                    student_id: student_id,
                    image: image,
                    uniName:uniName
                })
                this.props.changeStateValue({
                    name: 'messagePage',
                    value:{
                        groupName: programName,
                        userName: studentName,
                        messages: [],
                        program_id: program_id,
                        student_id: student_id,
                        image: image,
                        uniName:uniName
                    }
                })
                // console.log(resp)
                // const {user} = this.props;
                // if(user != ''){
                //     socket.emit('remove-room', user.id)
                //     socket.emit('chat-users', user);
    
                //     socket.emit('connected', {user_id: this.props.user.id, student_id: student_id, program_id:program_id});
              
                // }
            
                // this.setState({
                //     allusersVisible: false
                // })
            }
          
        })
        
    }

    typingMsg = (msg) => {
    //     socket.emit('typing-msg', {user_id: this.props.user.id, student_id: this.state.student_id, program_id: this.state.program_id});
    //     this.setState({
    //         msg: msg
    //     })
    //    if(msg == ''){
    //         socket.emit('stop-msg', {user_id: this.props.user.id, student_id: this.state.student_id, program_id: this.state.program_id})
    //    }else{
    //         setTimeout(()=>{
    //             if(this.state.msg == msg){
    //                 socket.emit('stop-msg', {user_id: this.props.user.id, student_id: this.state.student_id, program_id: this.state.program_id})
    //             }
    //         }, 3000)
    //    }
        
      
    }

    getAllUsers(type, e){
        this.setState({
            allusersVisible: type
        })
    }

    closeInfoBtn = () =>{
        this.setState({
            informationVisible: false
        })
    }
    opeInfoBtn = () =>{
        this.setState({
            informationVisible: true
        })
    }

    changeStatus(id, program_id, student_id){
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, archive it!'
          }).then((result) => {
            if (result.isConfirmed) {
                let formData = new FormData();
                formData.append('program_id', program_id)
                formData.append('student_id', student_id)
                formData.append('_method', 'put');
                let url = `/messages/${id}`;
                this.props.insertNewData(url, formData)
                .then(resp=>{
                    Swal.fire(
                        'Archived!',
                        'Your Tracking archived successfully',
                        'success'
                      )
                    this.props.getMessagesUserList(this.props.languageId);
                    this.setState({
                        studentName:'',
                        programName: '',
                        program_id: '',
                        student_id:'',
                        image: '',
                        uniName:'',
                        listId:''
                    })
                    this.props.changeStateValue({
                        name: 'messagePage',
                        value:{
                            groupName: '',
                            userName: '',
                            messages: [],
                            program_id: '',
                            student_id: '',
                            image: '',
                            uniName:''
                        }
                    })
                })
             
            }
          })
       
        
    }

    addComissionBtn(program_id, student_id, id = ''){
        
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it'
          }).then((result) => {
            if (result.isConfirmed) {
                this.setState({btnVisible:true})
                let formData = new FormData();
                formData.append("program_id", program_id);
                formData.append("student_id", student_id);
                formData.append("id", id);
                this.props.insertNewData("/change-commission-status", formData)
                .then(resp=>{
                    this.setState({btnVisible:false})
                    this.props.getComissionForMessage(program_id, student_id)
                    if(resp.msg == 'success'){
                        Swal.fire(
                            'Changed!',
                            'Commission status changed successfully.',
                            'success'
                        )
                    }else{
                        Swal.fire(
                            'Error!',
                            'Something went wronge',
                            'error'
                        )
                    }
                })
             
            }
          })
        
    }
  render() {
    const {seachmsgUsers, studentTrackings, user, messagePage, messagesUserList, commissionForMessage} = this.props
    const {userList, allusersVisible, program_id, student_id, informationVisible, listId, btnVisible} = this.state;
    
    return (
        <div   className={`ListingDetailPage nc-ListingCarDetailPage pb-4`}
        data-nc-id="ListingCarDetailPage">
            {
                        messagePage.student_id != ''?
                        <div className='flex gap-5 mb-1 mt-5 items-center container relative msg_header_btns' style={{ zIndex: `${informationVisible? '10': '20'}` }}>
                            <button className='flex gap-2 border rounded-3xl p-2' onClick={this.opeInfoBtn.bind(this)}>
                                <span style={{ fontSize: '14px' }}>Student info</span>
                                <svg
                                width={24}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 612 612"
                                    style={{
                                    enableBackground: "new 0 0 612 612",
                                    }}
                                    xmlSpace="preserve"
                                >
                                    <path d="M609.608 315.426a19.767 19.767 0 0 0 0-18.853c-58.464-107.643-172.5-180.72-303.607-180.72S60.857 188.931 2.393 296.573a19.767 19.767 0 0 0 0 18.853C60.858 423.069 174.892 496.147 306 496.147s245.143-73.078 303.608-180.721zM306 451.855c-80.554 0-145.855-65.302-145.855-145.855S225.446 160.144 306 160.144 451.856 225.446 451.856 306 386.554 451.855 306 451.855z" />
                                    <path d="M306 231.67c-6.136 0-12.095.749-17.798 2.15 5.841 6.76 9.383 15.563 9.383 25.198 0 21.3-17.267 38.568-38.568 38.568-9.635 0-18.438-3.541-25.198-9.383a74.513 74.513 0 0 0-2.15 17.798c0 41.052 33.279 74.33 74.33 74.33s74.33-33.279 74.33-74.33S347.052 231.67 306 231.67z" />
                                </svg>
                            </button>
                            {
                                user != '' && user?.role == "edu_consultant"?
                                null:
                                <TrackingBtn program_id={messagePage.program_id} student_id={messagePage.student_id} />
                            }
                            {
                                user != '' && user?.role == "edu_consultant"?
                                null:
                                <DocumentsBtn student_id={messagePage.student_id}  />
                            }
                         
                            {
                               ( user !== '' && user.role == 'edu_consultant' ||
                                user !== '' && user.role == 'uni_rep') && messagePage.student_id?
                                <FinanceBtn commissionStatus={commissionForMessage == ''? 0:1} addComissionBtn={this.addComissionBtn.bind(this, program_id, student_id, commissionForMessage?.id)} student_id={messagePage.student_id} />:null
                            }
                            <ScholarshipTracking  program_id={messagePage.program_id} student_id={messagePage.student_id} />
                            {
                                user != '' && user?.role == "edu_consultant"?
                                <DocumentsBtn student_id={messagePage.student_id}  />:null
                                
                            }
                            {
                                user != '' && user?.role == "edu_consultant"?
                                <TrackingBtn program_id={messagePage.program_id} student_id={messagePage.student_id} />:null
                                
                            }
                            {
                                user != '' && user?.role == "edu_consultant" || user != '' && user?.role == "uni_rep"?
                                <Notes student_id={messagePage.student_id} />:null
                            }
                            
                            {/* {
                                user != '' && user?.role == "edu_consultant" || user != '' && user?.role == "uni_rep"?
                                <StudentNote  student_id={messagePage.student_id} />:null
                                
                            } */}
                            {
                                user?.role == 'edu_consultant' && listId != ''?
                                 <button onClick={this.changeStatus.bind(this, listId, program_id, student_id)} className='border rounded-full text-sm p-3'>
                                    {
                                        messagesUserList?.find(item=> item?.id ==listId && item?.archive_status == 1)?'Unarchive':'Archive'

                                    }
                                
                            </button>:null
                            }
                            {/* {
                                user?.role == "edu_consultant"?
                                commissionForMessage == ''?
                                <button disabled={btnVisible} onClick={this.addComissionBtn.bind(this, program_id, student_id)} className='border rounded-full text-sm p-3 font-semibold'>Add Commission</button>:
                                <button disabled={btnVisible} onClick={this.addComissionBtn.bind(this, program_id, student_id, commissionForMessage?.id)} className='border rounded-full text-sm p-3 text-white bg-blue-600 font-semibold'>Remove Commission</button>:null
                            } */}
                        </div>:null
                    }
                    
            <main className="container relative z-10 mt-3 gap-5  flex flex-col lg:flex-row  ">
                <input type={'checkbox'} style={{ display: 'none' }} id="app_data_check" />

                <div  className={`messages_side_bar ${messagePage.groupName !== '' && messagePage.program_id !== '' && messagePage.student_id !== '' && messagePage.userName !== ''?'messages_resp_body_hide':''}`} style={{ height: '68vh' }}>
                    {
                        !allusersVisible?
                        <div style={{ height: '68vh' }}  className="info_bar border   rounded-2xl lg:block  lg:w-3/5 xl:w-1/3 ">
                            <div className='flex justify-between chat_list_header'>
                                <Search />
                                <svg onClick={this.getAllUsers.bind(this, true)} style={{ cursor: 'pointer' }} height={24} width={24} xmlSpace="preserve" >
                                    <path
                                    fill="currentColor"
                                    d="M19.005 3.175H4.674C3.642 3.175 3 3.789 3 4.821V21.02l3.544-3.514h12.461c1.033 0 2.064-1.06 2.064-2.093V4.821c-.001-1.032-1.032-1.646-2.064-1.646zm-4.989 9.869H7.041V11.1h6.975v1.944zm3-4H7.041V7.1h9.975v1.944z"
                                    />
                                </svg>
                            </div>
                            <UserList  
                            data={userList}
                            joinChatRoom={this.joinChatRoom}
                            search={seachmsgUsers}
                            messagePage={messagePage}
                            />
                        </div>:
                        <div style={{ height: '68vh' }}  className="info_bar border   rounded-2xl lg:block  lg:w-3/5 xl:w-1/3 ">
                        <div className='flex justify-between chat_list_header'>
                            <Search />
                            <span className="sr-only">Close</span>
                            <svg  onClick={this.getAllUsers.bind(this, false)} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5 cursor-pointer"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </div>
                           
                        <AllUsers  search={seachmsgUsers} joinChatRoom={this.joinChatRoom} />
                    </div>
                    }
                    
                </div>
                <div className={`block flex-grow lg:mt-0 ${messagePage.groupName == '' && messagePage.program_id == '' && messagePage.student_id == '' && messagePage.userName == ''?'messages_resp_body_hide':''}`}>
                    
                    <div className="mt-0 sticky top-28">
                        <div className='border rounded-2xl '>
                            <ChatBox typingMsg={this.typingMsg} />
                        </div>
                    </div>
                </div>
                {
                    informationVisible?
                    <InformationContainer program_id={program_id} closeInfoBtn={this.closeInfoBtn} studentTrackings={studentTrackings} />:null
                }
                
            </main>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    languageId: state.Data.languageId,
    user: state.Data.user,
    seachmsgUsers: state.Data.seachmsgUsers,
    studentTrackings: state.Data.studentTrackings,
    messagePage: state.Data.messagePage,
    messagesUsers: state.Data.messagesUsers,
    messagesUserList: state.Data.messagesUserList,
    commissionForMessage: state.Data.commissionForMessage,
});
const mapDispatchToProps = {mainDashboardTranslate, getUnirepRoleTranslate,getMessagesBox,getComissionForMessage, insertNewData, changeStateValue, getStudentTracking, getMessagesUsers, getMessagesUserList}
export default connect(mapStateToProps, mapDispatchToProps)(Messages)