import React, { Component } from 'react'

export class ResultCard extends Component {
    state={
        visible:false
    }
  render() {
    const {data} = this.props;
    const {visible} = this.state
    return (
        <div className='border p-2 rounded-xl mt-3'>
            {
                data?.country_name?
                <h3 onClick={(e)=>this.setState({visible: !visible})} className='font-semibold cursor-pointer '>{data?.country_name}</h3>:
                <h3 onClick={(e)=>this.setState({visible: !visible})} className='font-semibold cursor-pointer '>{data?.university_name}</h3>
            }
            
            {visible?
                data?.results?.map((item , i)=>{
                    return(
                        <div key={i} className='flex flex-col ml-2 border-b'>
                            <span className='text-sm font-semibold'>{item?.title} </span>
                            <span className='text-sm'>{item?.description} </span>
                        </div>
                    )
                }):null
            }
            
        </div>
    )
  }
}

export default ResultCard
