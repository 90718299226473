import React, { Component } from 'react'
import { connect } from 'react-redux';
import SectionHeader from '../../../page_component/SectionHeader'
import { getRelatedUniversity } from '../../../../actions/MainAction';
import { useParams } from 'react-router-dom';
import Card from './Card';
function withHooks(Components){
    return props => <Components {...props} params={useParams()} />
}
export class RelatedUniversity extends Component {
    componentDidMount(){
        this.props.getRelatedUniversity(1, this.props.languageId, this.props.params.id )
    }
  render() {
    const {relatedUniversity, mainTransateData, getAfterRedirect} = this.props;

    return (
        <div className='container mt-20'>
        <SectionHeader arialLabelTitle='mainTransateData.related_uni_title' title={mainTransateData?.related_uni_title} />
        
            <div className="glide__track" data-glide-el="track">
            <ul className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
            {
                relatedUniversity.map((data, i)=>{
                   if(i<5){
                    return(
                        <Card 
                        getAfterRedirect={getAfterRedirect}
                            key={i}
                            image={data?.institutions?.images[0]?.image_full_url}
                            name={data.name}
                            country={data.country}
                            city={data.city}
                            id={data?.institutions?.id}
                        />
                    )
                   }
                })
            }
            </ul>
        
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    mainTransateData: state.Data.mainTransateData,
    languageId: state.Data.languageId,
    relatedUniversity: state.Data.relatedUniversity,
});
const mapDispatchToProps = {getRelatedUniversity}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(RelatedUniversity))