import React, { Component } from 'react'
import { connect } from 'react-redux'
import Login from './Login'
import Register from './Register'
import { changeStateValue } from '../../../../../redux/MainReducer'
import CloseIcon from '../../../../page_component/CloseIcon'
export class LoginComponent extends Component {
    state={
        page: 'register'
    }
    changePage = (type)=>{
        this.setState({
            page: type
        })
    }
    closeModal(e){
        if(e.target.className == 'background_shadow'){
            this.props.changeStateValue({
                name: 'simulatorLogin',
                value: false
            })
        }
        
    }
  render() {
    const {page} = this.state
    return (
        <div className='background_shadow' >
            <div className="login_popup_container">
            <CloseIcon closeModalBtn={()=>{}} />
                {
                    page == 'register'?
                    <Register changePage={this.changePage} />:
                    <Login changePage={this.changePage} />
                }
                
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent)