import React, { Component } from 'react'
import { connect } from 'react-redux'
import SectionHeader from '../../components/page_component/SectionHeader';
import AddTranslatorModal from './translator_comonent/modal/AddTranslatorModal';
import { getTranslatorUsers } from '../../actions/WorkerActions';
import EditSvg from '../../components/page_component/EditSvg'
import avatar1 from '../../images/default.jpg'
export class Translator extends Component {
    state={
        registerModalVisible: false,
        data: {
            id: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            lang:{
                lang_id: ''
            }
            
        }
    }
    closeModal = () =>{
        this.setState({
            registerModalVisible: false
        })
    }
    openRegisterModal (){
        this.setState({
            registerModalVisible: true,
            data: {
                id: '',
                first_name: '',
                last_name: '',
                email: '',
                password: ''
            }
        })
    }
    componentDidMount(){
        this.props.getTranslatorUsers()
    }
    editSvgBtn = (data) =>{
        this.setState({
            data:data,
            registerModalVisible: true
        })
    }
  render() {
    const {mainTransateData, translatorUsers} = this.props;
    const {registerModalVisible, data} = this.state;
    console.log(translatorUsers)
    return (
      <div className='container'>
        <div className="flex flex-col mt-20 relative">
            <SectionHeader arialLabelTitle="mainTransateData.translator_title"  title={mainTransateData?.translator_title} />
        </div>
        <div className="mb-8 lg:mb-11">
            <div className="flex lg:space-x-4">
                <div className="hidden lg:flex space-x-4">
                <button onClick={this.openRegisterModal.bind(this)} className='ttnc-ButtonThird text-neutral-700 border 
                                    border-neutral-200 dark:text-neutral-200 
                                    dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                    items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                    px-4 py-1 sm:px-3' style={{ fontSize: '14px', fontWeight: '400' }}  aria-label="mainTransateData.add_content" >
                    {/* {mainTransateData?.add_content} */}
                    Add a Translator
                </button>
                </div>
            </div>
        </div>
        <div  className={`grid mt-10 gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 `} >
            {
                translatorUsers.map((data, i)=>{
                    return(
                        <div key={i}
                        className={`nc-CardAuthorBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}
                        data-nc-id="CardAuthorBox"
                    >
                        <div className='w-5 absolute left-3 top-3' >
                            <EditSvg editSvgBtn={this.editSvgBtn.bind(this, data)} />
                        </div>
                        <div className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full w-20 h-20 text-2xlring-1 ring-white dark:ring-neutral-900`}>
                            <img
                            className={`absolute inset-0 w-full h-full object-cover rounded-full`}
                            src={data.image_full_url&& data.image_full_url != '' ? data.image_full_url:avatar1}
                            />
                        </div>
                        <div className="mt-3">
                            <h2 className={`text-base font-medium`}>
                                <span className="line-clamp-1">{data.full_name}</span>
                            </h2>
                            <span className={`block mt-1.5  text-sm text-neutral-500 dark:text-neutral-400 wrap`} >
                            {data.email.length > 20? `${data.email.substring(0, 20)}...`:data.email}
                            </span>
                            <span className={`block mt-1.5  text-sm text-neutral-500 dark:text-neutral-400 wrap`} >
                             {data.lang?.main_lang?.name}
                            </span>
                            
                                
                        </div>
                         
                    </div>
                    )
                })
            }
        </div>
        {
            registerModalVisible?
            <AddTranslatorModal data={data} closeModal={this.closeModal} />:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    mainTransateData: state.Data.mainTransateData,
    translatorUsers: state.Data.translatorUsers,
});
const mapDispatchToProps = {getTranslatorUsers}
export default connect(mapStateToProps, mapDispatchToProps)(Translator)
