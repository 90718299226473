import React, { Component } from 'react'
import ModalPhotos from '../universities/components/ModalPhotos';
import Images from './Images';

export class ImageHeader extends Component {
  state = {
    imagess: [
      {
        image_full_url: ""
      },
      {
        image_full_url:""
      },
      {
        image_full_url:  ""
      },
      {
        image_full_url: ""
      },
      {
        image_full_url: ""
      }, 
    ],
    isOpen: false,
    currentItem: 0
  }

  onClose = () =>{
    this.setState({
      isOpen: false
    })
  }

  onOpen = (index) =>{
    this.setState({
      isOpen: true,
      currentItem: index
    })
  }

  changeImage = (page) =>{
    this.setState({
      currentItem: page
    })
  }
  render() {
    const {title, description, image, images = []} = this.props;
    const { imagess, isOpen, currentItem } = this.state
    let allImages = [];
    if(images.length >0){
      allImages = images
    }else{
      allImages = imagess

    }
    return (
    <div >
      <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={this.onOpen.bind(this, 0)}
            >
              <Images  containerClassName="absolute inset-0"
                
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={images[0]? images[0]?.image_full_url: image}  />
                <div 
                
                    className="absolute  w-full md:flex md:items-center md:justify-center bottom-10  text-neutral-500 cursor-pointer z-10"
                  >
                    
                  <div className='px-9 py-6 rounded-xl bg-neutral-100 bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 mr-5 ml-5'>
                    <h1 style={{ fontSize: '30px', fontWeight: '600', lineHeight: '35px', }} className="ml-auto  text-neutral-800 text-sm ">
                      {title}
                      </h1>
                  </div>
                  </div>
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {allImages.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
              <div
              onClick={this.onOpen.bind(this, index+1)}

                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <Images
                
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item?.image_full_url || ""}
                />

           
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  // onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            
          </div>
        </header>
          {
            isOpen?
            <ModalPhotos changeImage={this.changeImage} currentItem={currentItem} imgs={allImages} isOpen={isOpen} onClose={this.onClose} />:null
          }
        
    </div>
    
    )
  }
}

export default ImageHeader
