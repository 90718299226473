import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { changeStateValue,  } from '../../../redux/MainReducer';
import { deleteData, getCountriesData, getInstitutionFaq } from '../../../actions/WorkerActions';
export class Card extends Component {

    editDataBtn(item, e){
       
        this.props.changeStateValue({
            name:'institutionInfoModal',
            value: item
        })
        this.props.changeStateValue({
            name:'institutionInfoModal.position',
            value: true
        })
    }
    deleteData(data){
        Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
        if (result.isConfirmed) {
            this.props.deleteData(`institution-faqs/${data.id}`)
            .then(resp=>{
                // country_fags
                this.props.getInstitutionFaq(data.institution_faqs.institution_id, this.props.languageId,)


            })
        }
        })
    }
  render() {
    const {item} = this.props
    return (
        <div className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow`}
            data-nc-id="StayCard"  >
            <div className={"p-4 space-y-4"}>
                <div className="flex justify-between items-center">
                    <span className="text-base font-semibold ">
                        {item.question}
                    </span>
                </div>
            </div>
            <div className={"p-4 space-y-4"}>
                <div className="flex justify-between items-center">
                    <span style={{ maxHeight: '40px', overflow: 'hidden' }} className="text-base font-semibold" dangerouslySetInnerHTML={{ __html: item.answer}}>
                    
                    </span>
                </div>
            </div>
            <div className={"p-4 space-y-4"}>
                <div className="flex justify-between items-center">
                    <button onClick={this.editDataBtn.bind(this, item)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                        Edit 
                    </button>
                    <button onClick={this.deleteData.bind(this, item)} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                    nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-1 sm:px-3  ' style={{ fontSize: '12px', color: 'white' }}>
                        Delete 
                    </button>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    languageId: state.Data.languageId
});

const mapDispatchToProps = {changeStateValue, deleteData, getCountriesData, getInstitutionFaq}
export default connect(mapStateToProps, mapDispatchToProps)(Card)