import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DisciplineCard from './DisciplineCard';
import { getDisciplines } from '../../../actions/MainAction';
import {connect} from 'react-redux'
import SectionHeader from '../SectionHeader';
import ViewAllBtn from '../ViewAllBtn';
import LoadMoreBtn from '../LoadMoreBtn';
import SkeletonCard from '../SkeletonCard';
import SkeletonMiniCard from '../SkeletonMiniCard';
import { changeStateValue } from '../../../redux/MainReducer';
import MainModal from '../../modals/MainModal';
export class Discipline extends Component {
   componentDidMount(){
    this.props.getDisciplines(1, '', this.props.languageId)
   }
   loadMoreFunction=(page)=>{
    console.log(page)
    this.props.getDisciplines(page, '', this.props.languageId)
   }
   addNewDataBtn = (data)=>{
    
    this.props.changeStateValue({
      name: 'newCountryData',
      value: {
        id: data?.discipline?.id ? data?.discipline?.id:'',
        position: true,
        image_full_url:  data?.discipline?.image_full_url?data?.discipline?.image_full_url:null,
        image:  data?.discipline?.image_full_url ? data?.discipline?.image_full_url:'',
        name: data?.name?data?.name:'',
        description: data?.descriprion?data?.descriprion:'',
        active: 1,
        lang_id: 1,
        translate_id: data.id
      }
    })
   }
   getAfterSubmit=()=>{
    this.props.getDisciplines(1, '', this.props.languageId)
   }
  render() {
    const {disciplines, page, btn, mainTransateData, user, newCountryData} = this.props;
    return (
      <div className={`nc-SectionGridCategoryBox relative `}>
        <SectionHeader arialLabelTitle="mainTransateData.discipline_title" arialLabelDesc="mainTransateData.discipline_desc" title={mainTransateData?.discipline_title} description={mainTransateData?.discipline_desc} />
        {
              user != '' && user.role == 'uni_rep'?
              <button onClick={this.addNewDataBtn.bind(this, '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a Discipline</button>:null
            }
            
        <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 sm:gap-6 md:gap-8`}>
          {disciplines.map((item, i) => {
            if(page == 8){
              if(i<page){
                return(
                  <DisciplineCard editDataBtn={this.addNewDataBtn} translateLabel="mainTransateData.scholarships" translate={mainTransateData?.scholarships} key={i} item={item} />
                )
              }
            }else {
              return(
                <DisciplineCard editDataBtn={this.addNewDataBtn} translateLabel="mainTransateData.scholarships" translate={mainTransateData?.scholarships} key={i} item={item} />
              )
            }
           
          })}
          {
            disciplines.length == 0?
            <>
              <SkeletonMiniCard />
              <SkeletonMiniCard />
              <SkeletonMiniCard />
              <SkeletonMiniCard />
              <SkeletonMiniCard />
              <SkeletonMiniCard />
              <SkeletonMiniCard />
              <SkeletonMiniCard />
            </>:null
          }
        </div>
        {
          btn === 'view_all'?
          <ViewAllBtn href="/disciplines" />:
          disciplines.length >= 20?
          <LoadMoreBtn position={disciplines.length} loadMoreFunction={this.loadMoreFunction} />:null
        }
        {newCountryData.position?<MainModal getAfterChange={this.getAfterSubmit} url={'/disciplines'} stateName="newCountryData" modalData={newCountryData} />:null}
      </div>
    )
  }
}
const mapStateToProps = (state)=> ({
  disciplines: state.Data.disciplines,
  languageId: state.Data.languageId,
  mainTransateData: state.Data.mainTransateData,
  user: state.Data.user,
  newCountryData: state.Data.newCountryData,
});
const mapDispatchToProps = {getDisciplines, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Discipline)