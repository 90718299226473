import React, { Component } from 'react'
import CloseIcon  from '../../../../components/page_component/CloseIcon'
import JoditEditor from "jodit-react"; 
import { connect } from 'react-redux';
import { changeStateValue } from '../../../../redux/MainReducer';
import Swal from 'sweetalert2';
import { getDocuments, insertNewData, getDocumentData } from '../../../../actions/WorkerActions';
export class DocumentModal extends Component {
    state={
        btnDisable:false
    }

    closeModalBtn=()=>{
        this.props.changeStateValue({
            name: 'documentsModal',
            value:{
                position:false,
                title: '',
                country_id:'',
                category_id:'',
                description:'',
                status:0
            }
        })
    }
    config = {
		zIndex: 0,
        readonly: false,
        activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
        toolbarButtonSize: 'middle',
        theme: 'default',
        saveModeInCookie: false,
        spellcheck: true,
        editorCssClass: false,
        triggerChangeEvent: true,
        width: 'auto',
        height: 300,
        minHeight: 100,
        direction: '',
        language: 'auto',
        debugLanguage: false,
        i18n: 'en',
        tabIndex: -1,
        toolbar: true,
        enter: "P",
        useSplitMode: false,
        colors: {
            greyscale:  ['#000000', '#ff0000', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
            palette:    ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
            full: [
                '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
                '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
                '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
                '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
            ]
        },
        colorPickerDefaultTab: 'text',
        imageDefaultWidth: 300,
        removeButtons: [
            'source', 
            'fullsize'
        ],
        disablePlugins: [],
        extraButtons: [
            'strikethrough',
            'underline',
            'hr'
        ],
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        
        buttons: [
           
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent',  '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'video',
            'table',
            'link', '|',
            'align', 'undo', 'redo', '|',
            'hr',
            'eraser',
            'copyformat', '|',
            'symbol',
            'print',
            'about'
        ],
        buttonsXS: [
            'bold',
            'image', '|',
            'brush',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots',
        ],
        events: {},
        textIcons: false,
        showWordsCounter: false,
        showCharsCounter: false
    }
    changeValue=(value)=>{
        this.props.changeStateValue({
            name: 'documentsModal.file',
            value:value
        })
    }
    getInpValue(e){
        this.props.changeStateValue({
            name: 'documentsModal.title',
            value:e.target.value
        })
    }

    saveBtn(data){
        if(data?.title != '' && data?.file != ''){
            this.setState({btnDisable:true});
            let formData = new FormData();
            let url = '/document'
            for(const property in data){
                if(data[property])
                    formData.append(property, data[property])
            }
            if(data?.id && data?.id != ''){
                formData.append('_method', 'put');
                url  =`/document/${data?.id}`
            }
            formData.append("description", '.')
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({btnDisable:false});
                if(resp?.msg == 'success'){
                    this.closeModalBtn();
                    if(data?.id && data?.id != ''){
                        this.props.getDocumentData(data?.id )
                    }else{
                        this.props.getDocuments(data?.country_id, data?.category_id);
                    }
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Your work has been saved',
                        showConfirmButton: false,
                        timer: 1500
                      })
                }else{
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Something went wronge',
                        showConfirmButton: false,
                        timer: 1500
                      })
                }
            })
        }
    }
  render() {
    const {documentsModal} = this.props;
   console.log(documentsModal)
    return (
      <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white p-5 shadow-xl rounded-xl w-6/12'>
                <div className='flex justify-between items-center'>
                    <h3 className='text-xl font-semibold'>Add a document</h3>
                    <CloseIcon closeModalBtn={this.closeModalBtn} />
                </div>
                <div>
                    <label className='text-sm font-semibold'>Title</label>
                    <input onChange={this.getInpValue.bind(this)} value={documentsModal?.title} type='text' placeholder='Title' />
                </div>
                <div className='mt-7'>
                    <label  className='text-sm font-semibold'>File</label>
                    {/* <JoditEditor
                        config={this.config}
                        value={documentsModal.description}
                        onChange={this.changeValue.bind(this)}
                    /> */}
                    <label htmlFor='docFileInpId' className={`flex w-40 h-40 rounded-xl items-center justify-center bg-gray-100 cursor-pointer ${documentsModal?.file != ''? 'text-blue-600':''}` }>
                        Upload file
                    </label>
                    <input onChange={(e)=>this.changeValue(e.target.files[0])} id='docFileInpId' type='file' accept='.doc, .docx,.pdf' style={{ display:'none' }} />
                </div>
                <div className='flex justify-between items-center mt-4'>
                    <button onClick={this.closeModalBtn.bind(this)} className='border rounded-xl p-2 text-sm font-semibold bg-red-700 text-white cursor-pointer'>Close</button>
                    <button onClick={this.saveBtn.bind(this, documentsModal)} className='border rounded-xl p-2 text-sm font-semibold bg-blue-700 text-white cursor-pointer'>Save</button>
                </div>
            </div>
      </div>
    )
  }
}

const mapStateToProps = (state) =>({
    documentsModal: state.Data.documentsModal
});
const mapDispatchToProps = {changeStateValue, getDocuments, insertNewData, getDocumentData}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentModal)
