import React, { Component } from 'react'

export class ModalHeader extends Component {
  render() {
    return (
      <h2 className='text-center text-xl font-semibold'>Add Data</h2>
    )
  }
}

export default ModalHeader