import React, { Component } from 'react'
import { connect } from 'react-redux';
import CheckFilter from './CheckFilter'
import { getAllCountries, getStateByCountry,getCompanies, homeAppliedStudents,  getcityByCountryAndState, getScholarships, getcityBState, getAllDisciplines, getEducationDegree } from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
export class Filter extends Component {
    state={
        country: '',
        state:'',
        city: '',
        manualRender:0,
        discipline: '',
        degree: '',
        company: ''
    }
    componentDidMount(){
        this.props.getAllCountries(1, '', this.props.languageId);
        this.props.getAllDisciplines(this.props.languageId)
        this.props.getEducationDegree(this.props.languageId);
        this.props.getCompanies();
    }

    getFilterProgram(country, discipline, degree, company){
      this.props.homeAppliedStudents("all", this.props.page, this.props.languageId, country, discipline, degree, company)
    }
    checkDataBtn = (data, type) =>{
      this.setState({
       
        [type]: data.join()
      })
      if(type == 'country'){   
          this.getFilterProgram(data.join(),  this.state.discipline, this.state.degree,  this.state.company)
      }else if(type == 'state'){
        this.getFilterProgram( '', data.join(), '', '', '')
      }else if(type == 'city'){
          this.getFilterProgram( '', '', data.join(), '','')
      }else if(type == 'discipline'){
        this.getFilterProgram( this.state.country,  data.join(), this.state.degree,  this.state.company)
      }else if(type == 'degree'){
        this.getFilterProgram( this.state.country, this.state.discipline, data.join(), this.state.company)
      }else if(type == 'company'){
        this.getFilterProgram( this.state.country, this.state.discipline,this.state.degree, data.join())
      }
      this.props.setMainStates(type, data.join())
      
    }
  render() {
    const {allCountries, stateByCountry, citiesByCountryAndState, mainTransateData, allDisciplines,companies,  educationDegree} = this.props;
    const {country, state, city, discipline, degree, company} = this.state;
    let countriesOptions = [];
    const stateOption = [];
    const cityOption = [];
    let disciplinesOption = [];
    let degreeOption = [];
    let companyOption = [];

    for(const company of companies){
      if(company.id != 17 && company.id != 18 && company.id != 19 && company.id != 22 && company.id != 24 && company.id != 25 && company.id != 26 && company.id != 27 && company.id != 28){
        const params = {
          name: company.name,
          id: company.id
        }
        companyOption.push(params)
      }
     
    }
    for(const degree of educationDegree){
      const params = {
        name: degree.education_type,
        id: degree.education_degree_id
      }
      degreeOption.push(params)
    }
    for(const country of [...allCountries].sort((x, y)=> {  return y?.countries?.institution_count - x?.countries?.institution_count})){
      const params = {
        name: country.name,
        id: country.countries_id
      }
      countriesOptions.push(params)
    }
    for(const discipline of [...allDisciplines].sort((x, y)=> {  return y?.programs - x?.programs})){
      const params = {
        name: discipline.name,
        id: discipline.discipline_id
      }
      disciplinesOption.push(params)
    }
    for(const state of stateByCountry){
      const params = {
        name: state.name,
        id: state.state_id
      }
      stateOption.push(params)
    }
    for(const city of citiesByCountryAndState){
      const params = {
        name: city.name,
        id: city.city_id
      }
      cityOption.push(params)
    }
    // console.log(companyOption)
    return (
      <div className='filter_btn'>
        <CheckFilter data={country ==''?[]:country.split(',')} array={countriesOptions} type="country" label="mainTransateData.countries" title={mainTransateData?.countries} checkDataBtn={this.checkDataBtn} />
        {/* <CheckFilter data={company == ''?[]:company.split(',')} array={companyOption} type="company" label="mainTransateData.companies" title={mainTransateData?.companies} checkDataBtn={this.checkDataBtn} /> */}
        <CheckFilter data={discipline==''?[]:discipline.split(',')} array={disciplinesOption} type="discipline" label="mainTransateData.disciplines" title={mainTransateData?.disciplines} checkDataBtn={this.checkDataBtn} />
        <CheckFilter data={degree==''?[]:degree.split(',')} array={degreeOption} type="degree" label="mainTransateData.degree" title={mainTransateData?.degree} checkDataBtn={this.checkDataBtn} />
        
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    languageId: state.Data.languageId,
    allCountries: state.Data.allCountries,
    stateByCountry: state.Data.stateByCountry,
    citiesByCountryAndState: state.Data.citiesByCountryAndState,
    mainTransateData: state.Data.mainTransateData,
    allDisciplines: state.Data.allDisciplines,
    educationDegree: state.Data.educationDegree,
    companies: state.Data.companies,
});
const mapDispatchToProps = {getAllCountries, getStateByCountry,getCompanies, homeAppliedStudents,  getcityByCountryAndState, getScholarships,getcityBState, changeStateValue, getAllDisciplines, getEducationDegree}
export default connect(mapStateToProps, mapDispatchToProps)(Filter)