
export const ranks = [
    {
     "2025 QS World University Rankings": 1,
     "Column2": 1,
     "Column3": "Massachusetts Institute of Technology (MIT) ",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 100,
     "Column11": 4,
     "Column12": 100,
     "Column13": 2,
     "Column14": 100,
     "Column15": 11,
     "Column16": 100,
     "Column17": 9,
     "Column18": 99.3,
     "Column19": 100,
     "Column20": 86.8,
     "Column21": 143,
     "Column22": 96,
     "Column23": 58,
     "Column24": 100,
     "Column25": 8,
     "Column26": 99,
     "Column27": "15=",
     "Column28": 100
    },
    {
     "2025 QS World University Rankings": 2,
     "Column2": 6,
     "Column3": "Imperial College London",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 98.5,
     "Column11": 22,
     "Column12": 99.5,
     "Column13": 11,
     "Column14": 98.2,
     "Column15": 45,
     "Column16": 93.9,
     "Column17": 54,
     "Column18": 100,
     "Column19": 66,
     "Column20": 99.6,
     "Column21": 44,
     "Column22": 97.4,
     "Column23": 34,
     "Column24": 93.4,
     "Column25": 61,
     "Column26": 99.7,
     "Column27": 6,
     "Column28": 98.5
    },
    {
     "2025 QS World University Rankings": 3,
     "Column2": 3,
     "Column3": "University of Oxford",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 100,
     "Column11": 2,
     "Column12": 100,
     "Column13": 5,
     "Column14": 100,
     "Column15": 8,
     "Column16": 84.8,
     "Column17": 93,
     "Column18": 98.1,
     "Column19": 120,
     "Column20": 97.7,
     "Column21": 73,
     "Column22": 100,
     "Column23": 1,
     "Column24": 100,
     "Column25": 3,
     "Column26": 85,
     "Column27": 126,
     "Column28": 96.9
    },
    {
     "2025 QS World University Rankings": 4,
     "Column2": 4,
     "Column3": "Harvard University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 100,
     "Column11": 1,
     "Column12": 100,
     "Column13": 1,
     "Column14": 96.3,
     "Column15": 53,
     "Column16": 100,
     "Column17": 1,
     "Column18": 74.1,
     "Column19": 269,
     "Column20": 69,
     "Column21": 215,
     "Column22": 99.6,
     "Column23": 5,
     "Column24": 100,
     "Column25": 1,
     "Column26": 84.4,
     "Column27": 130,
     "Column28": 96.8
    },
    {
     "2025 QS World University Rankings": 5,
     "Column2": 2,
     "Column3": "University of Cambridge",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 100,
     "Column11": 3,
     "Column12": 100,
     "Column13": 4,
     "Column14": 100,
     "Column15": 17,
     "Column16": 84.6,
     "Column17": 96,
     "Column18": 100,
     "Column19": 73,
     "Column20": 94.8,
     "Column21": 98,
     "Column22": 99.3,
     "Column23": 10,
     "Column24": 100,
     "Column25": 5,
     "Column26": 84.8,
     "Column27": "127=",
     "Column28": 96.7
    },
    {
     "2025 QS World University Rankings": 6,
     "Column2": 5,
     "Column3": "Stanford University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 100,
     "Column11": 5,
     "Column12": 100,
     "Column13": 3,
     "Column14": 100,
     "Column15": 12,
     "Column16": 99,
     "Column17": 27,
     "Column18": 70.3,
     "Column19": 284,
     "Column20": 60.8,
     "Column21": 244,
     "Column22": 96.8,
     "Column23": 45,
     "Column24": 100,
     "Column25": 2,
     "Column26": 81.2,
     "Column27": "148=",
     "Column28": 96.1
    },
    {
     "2025 QS World University Rankings": 7,
     "Column2": 7,
     "Column3": "ETH Zurich - Swiss Federal Institute of Technology",
     "Column4": "CH",
     "Column5": "Switzerland",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 98.8,
     "Column11": 19,
     "Column12": 87.2,
     "Column13": 57,
     "Column14": 65.9,
     "Column15": 199,
     "Column16": 97.9,
     "Column17": 35,
     "Column18": 100,
     "Column19": 25,
     "Column20": 98.6,
     "Column21": 63,
     "Column22": 95.7,
     "Column23": 64,
     "Column24": 90.5,
     "Column25": 73,
     "Column26": 98.8,
     "Column27": 18,
     "Column28": 93.9
    },
    {
     "2025 QS World University Rankings": 8,
     "Column2": 8,
     "Column3": "National University of Singapore (NUS)",
     "Column4": "SG",
     "Column5": "Singapore",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 99.5,
     "Column11": 15,
     "Column12": 91.1,
     "Column13": 48,
     "Column14": 68.8,
     "Column15": 189,
     "Column16": 93.1,
     "Column17": 61,
     "Column18": 100,
     "Column19": 41,
     "Column20": 88.9,
     "Column21": 129,
     "Column22": 91.6,
     "Column23": 146,
     "Column24": 100,
     "Column25": 6,
     "Column26": 97.7,
     "Column27": "26=",
     "Column28": 93.7
    },
    {
     "2025 QS World University Rankings": 9,
     "Column2": 9,
     "Column3": "UCL",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 99.5,
     "Column11": 14,
     "Column12": 98.3,
     "Column13": 18,
     "Column14": 95.9,
     "Column15": 56,
     "Column16": 72.2,
     "Column17": 145,
     "Column18": 99,
     "Column19": 106,
     "Column20": 100,
     "Column21": 21,
     "Column22": 99.9,
     "Column23": 2,
     "Column24": 70.3,
     "Column25": 157,
     "Column26": 74.8,
     "Column27": "190=",
     "Column28": 91.6
    },
    {
     "2025 QS World University Rankings": 10,
     "Column2": 15,
     "Column3": "California Institute of Technology (Caltech)",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 96.5,
     "Column11": 29,
     "Column12": 95.3,
     "Column13": 33,
     "Column14": 100,
     "Column15": 4,
     "Column16": 100,
     "Column17": 5,
     "Column18": 100,
     "Column19": 65,
     "Column20": 79.8,
     "Column21": 172,
     "Column22": 65.5,
     "Column23": 549,
     "Column24": 31,
     "Column25": 407,
     "Column26": 62.5,
     "Column27": 253,
     "Column28": 90.9
    },
    {
     "2025 QS World University Rankings": 11,
     "Column2": 12,
     "Column3": "University of Pennsylvania",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 96.3,
     "Column11": 31,
     "Column12": 91.9,
     "Column13": 45,
     "Column14": 99.8,
     "Column15": 28,
     "Column16": 74,
     "Column17": 138,
     "Column18": 90.9,
     "Column19": 188,
     "Column20": 66.2,
     "Column21": 226,
     "Column22": 91.9,
     "Column23": 137,
     "Column24": 100,
     "Column25": 7,
     "Column26": 93,
     "Column27": 71,
     "Column28": 90.3
    },
    {
     "2025 QS World University Rankings": 12,
     "Column2": 10,
     "Column3": "University of California, Berkeley (UCB)",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 100,
     "Column11": 6,
     "Column12": 100,
     "Column13": 6,
     "Column14": 23.5,
     "Column15": 615,
     "Column16": 98.2,
     "Column17": 33,
     "Column18": 91.5,
     "Column19": 182,
     "Column20": 61,
     "Column21": 242,
     "Column22": 97.2,
     "Column23": 39,
     "Column24": 98.4,
     "Column25": 23,
     "Column26": 100,
     "Column27": 2,
     "Column28": 90.1
    },
    {
     "2025 QS World University Rankings": 13,
     "Column2": 14,
     "Column3": "The University of Melbourne",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 98.5,
     "Column11": 21,
     "Column12": 93.9,
     "Column13": 39,
     "Column14": 15.4,
     "Column15": "701+",
     "Column16": 93,
     "Column17": 62,
     "Column18": 95.1,
     "Column19": 148,
     "Column20": 99.8,
     "Column21": 39,
     "Column22": 97.4,
     "Column23": 33,
     "Column24": 96.5,
     "Column25": 39,
     "Column26": 99.6,
     "Column27": 9,
     "Column28": 88.9
    },
    {
     "2025 QS World University Rankings": 14,
     "Column2": 17,
     "Column3": "Peking University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 99.5,
     "Column11": 13,
     "Column12": 96.6,
     "Column13": 28,
     "Column14": 92.6,
     "Column15": 75,
     "Column16": 97.7,
     "Column17": 40,
     "Column18": 50.3,
     "Column19": 365,
     "Column20": 23.6,
     "Column21": 497,
     "Column22": 79.8,
     "Column23": 329,
     "Column24": 94.2,
     "Column25": 56,
     "Column26": 46.9,
     "Column27": "330=",
     "Column28": 88.5
    },
    {
     "2025 QS World University Rankings": 15,
     "Column2": 26,
     "Column3": "Nanyang Technological University, Singapore (NTU)",
     "Column4": "SG",
     "Column5": "Singapore",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 91.9,
     "Column11": 42,
     "Column12": 73.3,
     "Column13": 92,
     "Column14": 80.6,
     "Column15": 130,
     "Column16": 92.4,
     "Column17": 66,
     "Column18": 100,
     "Column19": 42,
     "Column20": 83.5,
     "Column21": 160,
     "Column22": 89.4,
     "Column23": 178,
     "Column24": 83.9,
     "Column25": 104,
     "Column26": 95.6,
     "Column27": "50=",
     "Column28": 88.4
    },
    {
     "2025 QS World University Rankings": 16,
     "Column2": 13,
     "Column3": "Cornell University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 98.3,
     "Column11": 25,
     "Column12": 93.1,
     "Column13": 42,
     "Column14": 52.7,
     "Column15": 289,
     "Column16": 97.5,
     "Column17": 43,
     "Column18": 54.2,
     "Column19": 347,
     "Column20": 63.4,
     "Column21": 237,
     "Column22": 94,
     "Column23": 99,
     "Column24": 97.1,
     "Column25": 34,
     "Column26": 72.1,
     "Column27": "208=",
     "Column28": 87.9
    },
    {
     "2025 QS World University Rankings": 17,
     "Column2": 26,
     "Column3": "The University of Hong Kong",
     "Column4": "HK",
     "Column5": "Hong Kong SAR",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 97.4,
     "Column11": 27,
     "Column12": 59.4,
     "Column13": 135,
     "Column14": 81.2,
     "Column15": 124,
     "Column16": 86.4,
     "Column17": 89,
     "Column18": 100,
     "Column19": 33,
     "Column20": 99.3,
     "Column21": 52,
     "Column22": 81.4,
     "Column23": 300,
     "Column24": 99.7,
     "Column25": 14,
     "Column26": 89.2,
     "Column27": "101=",
     "Column28": 87.6
    },
    {
     "2025 QS World University Rankings": 18,
     "Column2": 19,
     "Column3": "The University of Sydney",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 96.4,
     "Column11": 30,
     "Column12": 90,
     "Column13": 51,
     "Column14": 10.9,
     "Column15": "701+",
     "Column16": 93.7,
     "Column17": 56,
     "Column18": 99.9,
     "Column19": 87,
     "Column20": 100,
     "Column21": 17,
     "Column22": 95.8,
     "Column23": 61,
     "Column24": 93.1,
     "Column25": 63,
     "Column26": 99.6,
     "Column27": 7,
     "Column28": 87.3
    },
    {
     "2025 QS World University Rankings": 19,
     "Column2": 19,
     "Column3": "The University of New South Wales (UNSW Sydney)",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 90.5,
     "Column11": 43,
     "Column12": 90.4,
     "Column13": 50,
     "Column14": 20.6,
     "Column15": 660,
     "Column16": 94.9,
     "Column17": 49,
     "Column18": 100,
     "Column19": 57,
     "Column20": 99.4,
     "Column21": 48,
     "Column22": 98.3,
     "Column23": 20,
     "Column24": 97.7,
     "Column25": 29,
     "Column26": 99.2,
     "Column27": "11=",
     "Column28": 87.1
    },
    {
     "2025 QS World University Rankings": 20,
     "Column2": 25,
     "Column3": "Tsinghua University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 99.2,
     "Column11": 16,
     "Column12": 97.7,
     "Column13": 22,
     "Column14": 95,
     "Column15": 62,
     "Column16": 99.1,
     "Column17": 26,
     "Column18": 18.1,
     "Column19": 623,
     "Column20": 13.4,
     "Column21": 654,
     "Column22": 79.2,
     "Column23": 337,
     "Column24": 92.3,
     "Column25": 68,
     "Column26": 40.4,
     "Column27": "363=",
     "Column28": 86.5
    },
    {
     "2025 QS World University Rankings": 21,
     "Column2": 11,
     "Column3": "University of Chicago",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 99.1,
     "Column11": 17,
     "Column12": 96.4,
     "Column13": 29,
     "Column14": 94.2,
     "Column15": 69,
     "Column16": 60.8,
     "Column17": 197,
     "Column18": 79,
     "Column19": 244,
     "Column20": 87,
     "Column21": 140,
     "Column22": 85.1,
     "Column23": 245,
     "Column24": 99.1,
     "Column25": 16,
     "Column26": 46.9,
     "Column27": "330=",
     "Column28": 86.2
    },
    {
     "2025 QS World University Rankings": 22,
     "Column2": 17,
     "Column3": "Princeton University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 99.8,
     "Column11": 10,
     "Column12": 98.3,
     "Column13": 19,
     "Column14": 57,
     "Column15": 258,
     "Column16": 100,
     "Column17": 3,
     "Column18": 9.6,
     "Column19": "701+",
     "Column20": 56.6,
     "Column21": 264,
     "Column22": 78.3,
     "Column23": 354,
     "Column24": 95.7,
     "Column25": 48,
     "Column26": 51.5,
     "Column27": 312,
     "Column28": 85.5
    },
    {
     "2025 QS World University Rankings": 23,
     "Column2": 16,
     "Column3": "Yale University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 99.9,
     "Column11": 9,
     "Column12": 99.9,
     "Column13": 7,
     "Column14": 100,
     "Column15": 3,
     "Column16": 38.6,
     "Column17": 363,
     "Column18": 91.5,
     "Column19": 181,
     "Column20": 63.3,
     "Column21": 238,
     "Column22": 93.9,
     "Column23": 100,
     "Column24": 98.5,
     "Column25": 21,
     "Column26": 92.2,
     "Column27": "76=",
     "Column28": 85.2
    },
    {
     "2025 QS World University Rankings": 24,
     "Column2": 24,
     "Column3": "Université PSL",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 74.4,
     "Column11": 81,
     "Column12": 97.6,
     "Column13": 23,
     "Column14": 98.1,
     "Column15": 46,
     "Column16": 87.6,
     "Column17": 85,
     "Column18": 62.3,
     "Column19": 315,
     "Column20": 65,
     "Column21": 230,
     "Column22": 99.8,
     "Column23": 3,
     "Column24": 97.1,
     "Column25": 33,
     "Column26": 73.4,
     "Column27": 197,
     "Column28": 84.7
    },
    {
     "2025 QS World University Rankings": 25,
     "Column2": 21,
     "Column3": "University of Toronto",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 99.7,
     "Column11": 11,
     "Column12": 96.9,
     "Column13": 27,
     "Column14": 44.9,
     "Column15": 344,
     "Column16": 50.8,
     "Column17": 257,
     "Column18": 96.9,
     "Column19": 132,
     "Column20": 96.1,
     "Column21": 90,
     "Column22": 97.7,
     "Column23": 29,
     "Column24": 98.7,
     "Column25": 19,
     "Column26": 100,
     "Column27": 1,
     "Column28": 84.1
    },
    {
     "2025 QS World University Rankings": 26,
     "Column2": 36,
     "Column3": "EPFL",
     "Column4": "CH",
     "Column5": "Switzerland",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 84.2,
     "Column11": 56,
     "Column12": 67.2,
     "Column13": 111,
     "Column14": 91.2,
     "Column15": 80,
     "Column16": 93.6,
     "Column17": 57,
     "Column18": 100,
     "Column19": 28,
     "Column20": 100,
     "Column21": 14,
     "Column22": 81.7,
     "Column23": 291,
     "Column24": 27.2,
     "Column25": 440,
     "Column26": 86.7,
     "Column27": "114=",
     "Column28": 83.5
    },
    {
     "2025 QS World University Rankings": 27,
     "Column2": 22,
     "Column3": "The University of Edinburgh",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 98.3,
     "Column11": 24,
     "Column12": 97.2,
     "Column13": 24,
     "Column14": 65.5,
     "Column15": 201,
     "Column16": 47.7,
     "Column17": 282,
     "Column18": 98.7,
     "Column19": 111,
     "Column20": 99.8,
     "Column21": 41,
     "Column22": 99.5,
     "Column23": 7,
     "Column24": 55.9,
     "Column25": 222,
     "Column26": 99,
     "Column27": "15=",
     "Column28": 83.3
    },
    {
     "2025 QS World University Rankings": 28,
     "Column2": 37,
     "Column3": "Technical University of Munich",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 83,
     "Column11": 61,
     "Column12": 98.6,
     "Column13": 17,
     "Column14": 76.8,
     "Column15": 155,
     "Column16": 75.9,
     "Column17": 133,
     "Column18": 80.4,
     "Column19": 236,
     "Column20": 98.6,
     "Column21": 64,
     "Column22": 95.6,
     "Column23": 68,
     "Column24": 34.5,
     "Column25": 365,
     "Column26": 93.1,
     "Column27": 70,
     "Column28": 83.2
    },
    {
     "2025 QS World University Rankings": 29,
     "Column2": 30,
     "Column3": "McGill University",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 94.3,
     "Column11": 34,
     "Column12": 87.6,
     "Column13": 56,
     "Column14": 62.3,
     "Column15": 224,
     "Column16": 57.9,
     "Column17": 212,
     "Column18": 83.7,
     "Column19": 225,
     "Column20": 89.6,
     "Column21": 124,
     "Column22": 94.2,
     "Column23": 94,
     "Column24": 98.3,
     "Column25": 24,
     "Column26": 99.1,
     "Column27": 13,
     "Column28": 83
    },
    {
     "2025 QS World University Rankings": 30,
     "Column2": 34,
     "Column3": "The Australian National University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 93.8,
     "Column11": 36,
     "Column12": 75.4,
     "Column13": 85,
     "Column14": 34.6,
     "Column15": 457,
     "Column16": 84.6,
     "Column17": 97,
     "Column18": 100,
     "Column19": 52,
     "Column20": 96.2,
     "Column21": 89,
     "Column22": 91.4,
     "Column23": 151,
     "Column24": 56.6,
     "Column25": 216,
     "Column26": 97.1,
     "Column27": "30=",
     "Column28": 82.4
    },
    {
     "2025 QS World University Rankings": 31,
     "Column2": 41,
     "Column3": "Seoul National University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 98.5,
     "Column11": 20,
     "Column12": 98.6,
     "Column13": 16,
     "Column14": 83.1,
     "Column15": 117,
     "Column16": 71.7,
     "Column17": 151,
     "Column18": 10.5,
     "Column19": "701+",
     "Column20": 16.9,
     "Column21": 586,
     "Column22": 73.2,
     "Column23": 435,
     "Column24": 100,
     "Column25": 4,
     "Column26": 96,
     "Column27": "46=",
     "Column28": 82.3
    },
    {
     "2025 QS World University Rankings": 32,
     "Column2": 28,
     "Column3": "Johns Hopkins University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 86,
     "Column11": 53,
     "Column12": 62.6,
     "Column13": 129,
     "Column14": 100,
     "Column15": 7,
     "Column16": 84.2,
     "Column17": 99,
     "Column18": 63.7,
     "Column19": 307,
     "Column20": 95.8,
     "Column21": 92,
     "Column22": 98.7,
     "Column23": 15,
     "Column24": 71.5,
     "Column25": 152,
     "Column26": 61.9,
     "Column27": "258=",
     "Column28": 82.1
    },
    {
     "2025 QS World University Rankings": 32,
     "Column2": 28,
     "Column3": "The University of Tokyo",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 100,
     "Column11": 7,
     "Column12": 99.8,
     "Column13": 9,
     "Column14": 89.3,
     "Column15": 89,
     "Column16": 57.3,
     "Column17": 215,
     "Column18": 10.1,
     "Column19": "701+",
     "Column20": 29.7,
     "Column21": 432,
     "Column22": 87.4,
     "Column23": 207,
     "Column24": 99.9,
     "Column25": 10,
     "Column26": 98.3,
     "Column27": 22,
     "Column28": 82.1
    },
    {
     "2025 QS World University Rankings": 34,
     "Column2": 23,
     "Column3": "Columbia University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 99.6,
     "Column11": 12,
     "Column12": 98.8,
     "Column13": 15,
     "Column14": 100,
     "Column15": 16,
     "Column16": 31.7,
     "Column17": 417,
     "Column18": 41.5,
     "Column19": 413,
     "Column20": 97,
     "Column21": 79,
     "Column22": 98.5,
     "Column23": 19,
     "Column24": 99.9,
     "Column25": 9,
     "Column26": 71.8,
     "Column27": "211=",
     "Column28": 82
    },
    {
     "2025 QS World University Rankings": 34,
     "Column2": 32,
     "Column3": "The University of Manchester",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 95.6,
     "Column11": 33,
     "Column12": 98.1,
     "Column13": 20,
     "Column14": 51.3,
     "Column15": 297,
     "Column16": 45.1,
     "Column17": 307,
     "Column18": 93.1,
     "Column19": 169,
     "Column20": 99.2,
     "Column21": 55,
     "Column22": 99.2,
     "Column23": 12,
     "Column24": 87.6,
     "Column25": 90,
     "Column26": 99.8,
     "Column27": 3,
     "Column28": 82
    },
    {
     "2025 QS World University Rankings": 36,
     "Column2": 47,
     "Column3": "The Chinese University of Hong Kong (CUHK)",
     "Column4": "HK",
     "Column5": "Hong Kong SAR",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 86.7,
     "Column11": 51,
     "Column12": 53.3,
     "Column13": 159,
     "Column14": 64.2,
     "Column15": 212,
     "Column16": 92.9,
     "Column17": 64,
     "Column18": 100,
     "Column19": 58,
     "Column20": 87.5,
     "Column21": 137,
     "Column22": 74.2,
     "Column23": 419,
     "Column24": 89,
     "Column25": 82,
     "Column26": 86.7,
     "Column27": "114=",
     "Column28": 81.3
    },
    {
     "2025 QS World University Rankings": 37,
     "Column2": 42,
     "Column3": "Monash University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 89.2,
     "Column11": 47,
     "Column12": 79.6,
     "Column13": 78,
     "Column14": 9.4,
     "Column15": "701+",
     "Column16": 87.6,
     "Column17": 84,
     "Column18": 100,
     "Column19": 59,
     "Column20": 100,
     "Column21": 24,
     "Column22": 94.8,
     "Column23": 81,
     "Column24": 79.4,
     "Column25": 126,
     "Column26": 98.2,
     "Column27": 23,
     "Column28": 81.2
    },
    {
     "2025 QS World University Rankings": 38,
     "Column2": 34,
     "Column3": "University of British Columbia",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 98.3,
     "Column11": 23,
     "Column12": 94.3,
     "Column13": 36,
     "Column14": 34.5,
     "Column15": 459,
     "Column16": 57.7,
     "Column17": 214,
     "Column18": 95.5,
     "Column19": 142,
     "Column20": 72.8,
     "Column21": 200,
     "Column22": 96.2,
     "Column23": 53,
     "Column24": 74.6,
     "Column25": 141,
     "Column26": 99.8,
     "Column27": 4,
     "Column28": 81
    },
    {
     "2025 QS World University Rankings": 39,
     "Column2": 50,
     "Column3": "Fudan University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 85.7,
     "Column11": 54,
     "Column12": 87.8,
     "Column13": 55,
     "Column14": 79.7,
     "Column15": 140,
     "Column16": 80.7,
     "Column17": 107,
     "Column18": 88.4,
     "Column19": 201,
     "Column20": 35.1,
     "Column21": 390,
     "Column22": 67.2,
     "Column23": 519,
     "Column24": 64.4,
     "Column25": 183,
     "Column26": 82.7,
     "Column27": 142,
     "Column28": 80.3
    },
    {
     "2025 QS World University Rankings": 40,
     "Column2": 40,
     "Column3": "King's College London",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 90.3,
     "Column11": 44,
     "Column12": 85.7,
     "Column13": 64,
     "Column14": 64.3,
     "Column15": 210,
     "Column16": 53.6,
     "Column17": 238,
     "Column18": 99.1,
     "Column19": 103,
     "Column20": 99.5,
     "Column21": 47,
     "Column22": 98.6,
     "Column23": 17,
     "Column24": 59.8,
     "Column25": 205,
     "Column26": 96.2,
     "Column27": "44=",
     "Column28": 80.2
    },
    {
     "2025 QS World University Rankings": 40,
     "Column2": 43,
     "Column3": "The University of Queensland",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 86.7,
     "Column11": 50,
     "Column12": 74,
     "Column13": 89,
     "Column14": 21.2,
     "Column15": 653,
     "Column16": 90.2,
     "Column17": 75,
     "Column18": 100,
     "Column19": 54,
     "Column20": 100,
     "Column21": 23,
     "Column22": 96.7,
     "Column23": 47,
     "Column24": 56.7,
     "Column25": 214,
     "Column26": 96.7,
     "Column27": 36,
     "Column28": 80.2
    },
    {
     "2025 QS World University Rankings": 42,
     "Column2": 29,
     "Column3": "University of California, Los Angeles (UCLA)",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 100,
     "Column11": 8,
     "Column12": 99.8,
     "Column13": 8,
     "Column14": 35.4,
     "Column15": 448,
     "Column16": 74,
     "Column17": 137,
     "Column18": 42.2,
     "Column19": 407,
     "Column20": 22.3,
     "Column21": 515,
     "Column22": 95.5,
     "Column23": 69,
     "Column24": 94.7,
     "Column25": 53,
     "Column26": 67.1,
     "Column27": "232=",
     "Column28": 79.8
    },
    {
     "2025 QS World University Rankings": 43,
     "Column2": 38,
     "Column3": "New York University (NYU)",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 96.3,
     "Column11": 32,
     "Column12": 98.8,
     "Column13": 14,
     "Column14": 90.5,
     "Column15": 83,
     "Column16": 28.6,
     "Column17": 453,
     "Column18": 30.2,
     "Column19": 492,
     "Column20": 98.1,
     "Column21": 68,
     "Column22": 92.6,
     "Column23": 126,
     "Column24": 98.4,
     "Column25": 22,
     "Column26": 95.4,
     "Column27": "55=",
     "Column28": 79.6
    },
    {
     "2025 QS World University Rankings": 44,
     "Column2": 33,
     "Column3": "University of Michigan-Ann Arbor",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 97.9,
     "Column11": 26,
     "Column12": 92.1,
     "Column13": 43,
     "Column14": 80.3,
     "Column15": 133,
     "Column16": 47.6,
     "Column17": 284,
     "Column18": 65.5,
     "Column19": 299,
     "Column20": 39.2,
     "Column21": 359,
     "Column22": 95.8,
     "Column23": 63,
     "Column24": 94.5,
     "Column25": 55,
     "Column26": 62.2,
     "Column27": "254=",
     "Column28": 79
    },
    {
     "2025 QS World University Rankings": 45,
     "Column2": 51,
     "Column3": "Shanghai Jiao Tong University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 84,
     "Column11": 57,
     "Column12": 86.3,
     "Column13": 62,
     "Column14": 58.6,
     "Column15": 251,
     "Column16": 99.6,
     "Column17": 17,
     "Column18": 23.1,
     "Column19": 558,
     "Column20": 19.6,
     "Column21": 553,
     "Column22": 73.7,
     "Column23": 424,
     "Column24": 73.5,
     "Column25": 144,
     "Column26": 80.3,
     "Column27": 154,
     "Column28": 77.8
    },
    {
     "2025 QS World University Rankings": 46,
     "Column2": 38,
     "Column3": "Institut Polytechnique de Paris",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 44.7,
     "Column11": 189,
     "Column12": 99.6,
     "Column13": 10,
     "Column14": 95.9,
     "Column15": 55,
     "Column16": 86.1,
     "Column17": 92,
     "Column18": 99.1,
     "Column19": 104,
     "Column20": 98,
     "Column21": 71,
     "Column22": 89.6,
     "Column23": 173,
     "Column24": 97.7,
     "Column25": 31,
     "Column26": 54.8,
     "Column27": "289=",
     "Column28": 77.5
    },
    {
     "2025 QS World University Rankings": 47,
     "Column2": 60,
     "Column3": "The Hong Kong University of Science and Technology",
     "Column4": "HK",
     "Column5": "Hong Kong SAR",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 81.1,
     "Column11": 68,
     "Column12": 50.3,
     "Column13": 179,
     "Column14": 56.7,
     "Column15": 261,
     "Column16": 99.7,
     "Column17": 16,
     "Column18": 100,
     "Column19": 22,
     "Column20": 95.4,
     "Column21": 94,
     "Column22": 46.7,
     "Column23": "701+",
     "Column24": 65.4,
     "Column25": 180,
     "Column26": 77.6,
     "Column27": "170=",
     "Column28": 77.1
    },
    {
     "2025 QS World University Rankings": 47,
     "Column2": 44,
     "Column3": "Zhejiang University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 75.3,
     "Column11": 79,
     "Column12": 95.4,
     "Column13": 32,
     "Column14": 54.7,
     "Column15": 277,
     "Column16": 99.5,
     "Column17": 19,
     "Column18": 95,
     "Column19": 149,
     "Column20": 15.9,
     "Column21": 608,
     "Column22": 91.8,
     "Column23": 138,
     "Column24": 55.5,
     "Column25": 224,
     "Column26": 30.7,
     "Column27": "440=",
     "Column28": 77.1
    },
    {
     "2025 QS World University Rankings": 49,
     "Column2": 47,
     "Column3": "Delft University of Technology",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 74.4,
     "Column11": 82,
     "Column12": 83,
     "Column13": 66,
     "Column14": 39.4,
     "Column15": 396,
     "Column16": 79.7,
     "Column17": 110,
     "Column18": 100,
     "Column19": 69,
     "Column20": 91.4,
     "Column21": 117,
     "Column22": 93.7,
     "Column23": 105,
     "Column24": 54.8,
     "Column25": 227,
     "Column26": 99.1,
     "Column27": 14,
     "Column28": 77
    },
    {
     "2025 QS World University Rankings": 50,
     "Column2": 46,
     "Column3": "Kyoto University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 98.8,
     "Column11": 18,
     "Column12": 99,
     "Column13": 13,
     "Column14": 94.2,
     "Column15": 68,
     "Column16": 39.3,
     "Column17": 357,
     "Column18": 15.9,
     "Column19": 653,
     "Column20": 19,
     "Column21": 566,
     "Column22": 85.7,
     "Column23": 236,
     "Column24": 72.8,
     "Column25": 147,
     "Column26": 85.2,
     "Column27": "124=",
     "Column28": 76
    },
    {
     "2025 QS World University Rankings": 50,
     "Column2": 47,
     "Column3": "Northwestern University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 84,
     "Column11": 58,
     "Column12": 68,
     "Column13": 107,
     "Column14": 97.6,
     "Column15": 48,
     "Column16": 67.7,
     "Column17": 161,
     "Column18": 45.4,
     "Column19": 393,
     "Column20": 52,
     "Column21": 283,
     "Column22": 89.4,
     "Column23": 179,
     "Column24": 96.8,
     "Column25": 37,
     "Column26": 55.4,
     "Column27": "285=",
     "Column28": 76
    },
    {
     "2025 QS World University Rankings": 50,
     "Column2": 45,
     "Column3": "The London School of Economics and Political Science (LSE)",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 67.2,
     "Column11": 101,
     "Column12": 97,
     "Column13": 26,
     "Column14": 35.1,
     "Column15": 452,
     "Column16": 69.4,
     "Column17": 158,
     "Column18": 100,
     "Column19": 40,
     "Column20": 100,
     "Column21": 12,
     "Column22": 81.2,
     "Column23": 305,
     "Column24": 99.6,
     "Column25": 15,
     "Column26": 91.3,
     "Column27": 85,
     "Column28": 76
    },
    {
     "2025 QS World University Rankings": 53,
     "Column2": 56,
     "Column3": "KAIST - Korea Advanced Institute of Science & Technology",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 87.7,
     "Column11": 48,
     "Column12": 91,
     "Column13": 49,
     "Column14": 60.2,
     "Column15": 238,
     "Column16": 96.2,
     "Column17": 46,
     "Column18": 21.1,
     "Column19": 587,
     "Column20": 10.9,
     "Column21": "701+",
     "Column22": 46.8,
     "Column23": "701+",
     "Column24": 63.3,
     "Column25": 187,
     "Column26": 60.3,
     "Column27": 268,
     "Column28": 75.7
    },
    {
     "2025 QS World University Rankings": 54,
     "Column2": 55,
     "Column3": "University of Bristol",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 75.8,
     "Column11": 78,
     "Column12": 82.5,
     "Column13": 69,
     "Column14": 36.7,
     "Column15": 437,
     "Column16": 75.9,
     "Column17": 134,
     "Column18": 87.5,
     "Column19": 206,
     "Column20": 89.8,
     "Column21": 121,
     "Column22": 94.6,
     "Column23": 84,
     "Column24": 51.2,
     "Column25": 250,
     "Column26": 98.9,
     "Column27": 17,
     "Column28": 75.4
    },
    {
     "2025 QS World University Rankings": 55,
     "Column2": 53,
     "Column3": "University of Amsterdam",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 83.8,
     "Column11": 60,
     "Column12": 52.1,
     "Column13": 163,
     "Column14": 21.4,
     "Column15": 648,
     "Column16": 92.2,
     "Column17": 67,
     "Column18": 96,
     "Column19": 139,
     "Column20": 87.5,
     "Column21": 138,
     "Column22": 96.3,
     "Column23": 50,
     "Column24": 79.8,
     "Column25": 124,
     "Column26": 38.2,
     "Column27": "384=",
     "Column28": 73.7
    },
    {
     "2025 QS World University Rankings": 56,
     "Column2": 76,
     "Column3": "Yonsei University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 81.5,
     "Column11": 67,
     "Column12": 97,
     "Column13": 25,
     "Column14": 84.2,
     "Column15": 112,
     "Column16": 44.1,
     "Column17": 312,
     "Column18": 20.7,
     "Column19": 590,
     "Column20": 51.2,
     "Column21": 287,
     "Column22": 78.7,
     "Column23": 343,
     "Column24": 87.8,
     "Column25": 88,
     "Column26": 88.9,
     "Column27": "103=",
     "Column28": 72.9
    },
    {
     "2025 QS World University Rankings": 57,
     "Column2": 65,
     "Column3": "The Hong Kong Polytechnic University",
     "Column4": "HK",
     "Column5": "Hong Kong SAR",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 68,
     "Column11": 99,
     "Column12": 35.6,
     "Column13": 265,
     "Column14": 65,
     "Column15": 204,
     "Column16": 94.9,
     "Column17": 51,
     "Column18": 100,
     "Column19": 39,
     "Column20": 91.9,
     "Column21": 110,
     "Column22": 78.9,
     "Column23": 338,
     "Column24": 64.5,
     "Column25": 181,
     "Column26": 77.4,
     "Column27": "173=",
     "Column28": 72.1
    },
    {
     "2025 QS World University Rankings": 58,
     "Column2": 52,
     "Column3": "Carnegie Mellon University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 69.8,
     "Column11": 92,
     "Column12": 80.1,
     "Column13": 75,
     "Column14": 40.9,
     "Column15": 383,
     "Column16": 98.9,
     "Column17": 28,
     "Column18": 33.7,
     "Column19": 472,
     "Column20": 99.1,
     "Column21": 58,
     "Column22": 79.9,
     "Column23": 324,
     "Column24": 61.5,
     "Column25": 196,
     "Column26": 24.5,
     "Column27": "492=",
     "Column28": 72
    },
    {
     "2025 QS World University Rankings": 59,
     "Column2": 54,
     "Column3": "Ludwig-Maximilians-Universität München",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 92.2,
     "Column11": 41,
     "Column12": 86.6,
     "Column13": 59,
     "Column14": 38.5,
     "Column15": 410,
     "Column16": 50,
     "Column17": 262,
     "Column18": 75.1,
     "Column19": 262,
     "Column20": 37.6,
     "Column21": 374,
     "Column22": 95.5,
     "Column23": 71,
     "Column24": 66.9,
     "Column25": 173,
     "Column26": 62.2,
     "Column27": "254=",
     "Column28": 71.6
    },
    {
     "2025 QS World University Rankings": 60,
     "Column2": 65,
     "Column3": "Universiti Malaya (UM)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 82.9,
     "Column11": 62,
     "Column12": 95.1,
     "Column13": 34,
     "Column14": 75.7,
     "Column15": 163,
     "Column16": 29.1,
     "Column17": 447,
     "Column18": 60.2,
     "Column19": 324,
     "Column20": 64.2,
     "Column21": 234,
     "Column22": 91.8,
     "Column23": 140,
     "Column24": 69.3,
     "Column25": 163,
     "Column26": 83.6,
     "Column27": "132=",
     "Column28": 71.2
    },
    {
     "2025 QS World University Rankings": 61,
     "Column2": 57,
     "Column3": "Duke University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 86,
     "Column11": 52,
     "Column12": 74.7,
     "Column13": 86,
     "Column14": 100,
     "Column15": 6,
     "Column16": 35.1,
     "Column17": 382,
     "Column18": 16.3,
     "Column19": 647,
     "Column20": 61,
     "Column21": 243,
     "Column22": 93.3,
     "Column23": 111,
     "Column24": 85.5,
     "Column25": 99,
     "Column26": 75,
     "Column27": "186=",
     "Column28": 70.8
    },
    {
     "2025 QS World University Rankings": 62,
     "Column2": 70,
     "Column3": "City University of Hong Kong",
     "Column4": "HK",
     "Column5": "Hong Kong SAR",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 60.9,
     "Column11": 120,
     "Column12": 30.8,
     "Column13": 305,
     "Column14": 89.5,
     "Column15": 88,
     "Column16": 100,
     "Column17": 6,
     "Column18": 100,
     "Column19": 13,
     "Column20": 100,
     "Column21": 7,
     "Column22": 62.9,
     "Column23": 588,
     "Column24": 37.5,
     "Column25": 342,
     "Column26": 72.9,
     "Column27": 201,
     "Column28": 70.7
    },
    {
     "2025 QS World University Rankings": 63,
     "Column2": 61,
     "Column3": "KU Leuven",
     "Column4": "BE",
     "Column5": "Belgium",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 84.6,
     "Column11": 55,
     "Column12": 49.6,
     "Column13": 181,
     "Column14": 7.1,
     "Column15": "701+",
     "Column16": 91,
     "Column17": 72,
     "Column18": 93.8,
     "Column19": 163,
     "Column20": 47.7,
     "Column21": 305,
     "Column22": 99.5,
     "Column23": 8,
     "Column24": 75.3,
     "Column25": 139,
     "Column26": 51.2,
     "Column27": "313=",
     "Column28": 70.3
    },
    {
     "2025 QS World University Rankings": 63,
     "Column2": 59,
     "Column3": "Sorbonne University",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 87.2,
     "Column11": 49,
     "Column12": 52,
     "Column13": 166,
     "Column14": 43,
     "Column15": 363,
     "Column16": 72.3,
     "Column17": 144,
     "Column18": 33.8,
     "Column19": 471,
     "Column20": 46.7,
     "Column21": 315,
     "Column22": 99.8,
     "Column23": 4,
     "Column24": 79.7,
     "Column25": 125,
     "Column26": 87.6,
     "Column27": 111,
     "Column28": 70.3
    },
    {
     "2025 QS World University Rankings": 65,
     "Column2": 68,
     "Column3": "The University of Auckland",
     "Column4": "NZ",
     "Column5": "New Zealand",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 82.2,
     "Column11": 65,
     "Column12": 44.7,
     "Column13": 205,
     "Column14": 15.5,
     "Column15": "701+",
     "Column16": 66.9,
     "Column17": 166,
     "Column18": 100,
     "Column19": 76,
     "Column20": 84,
     "Column21": 156,
     "Column22": 87.4,
     "Column23": 206,
     "Column24": 92.9,
     "Column25": 64,
     "Column26": 99.7,
     "Column27": 5,
     "Column28": 69.7
    },
    {
     "2025 QS World University Rankings": 66,
     "Column2": 58,
     "Column3": "University of Texas at Austin",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 93.5,
     "Column11": 38,
     "Column12": 86.6,
     "Column13": 60,
     "Column14": 8.1,
     "Column15": "701+",
     "Column16": 76.7,
     "Column17": 128,
     "Column18": 6.1,
     "Column19": "701+",
     "Column20": 11.7,
     "Column21": 685,
     "Column22": 91.7,
     "Column23": 144,
     "Column24": 81.7,
     "Column25": 113,
     "Column26": 52.1,
     "Column27": "305=",
     "Column28": 69.5
    },
    {
     "2025 QS World University Rankings": 67,
     "Column2": 79,
     "Column3": "Korea University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 78.2,
     "Column11": 72,
     "Column12": 93.8,
     "Column13": 40,
     "Column14": 73,
     "Column15": 178,
     "Column16": 45,
     "Column17": 308,
     "Column18": 15.2,
     "Column19": 665,
     "Column20": 45.7,
     "Column21": 321,
     "Column22": 65.7,
     "Column23": 547,
     "Column24": 89,
     "Column25": 81,
     "Column26": 85.2,
     "Column27": "124=",
     "Column28": 69
    },
    {
     "2025 QS World University Rankings": 68,
     "Column2": 69,
     "Column3": "National Taiwan University (NTU)",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 93.4,
     "Column11": 39,
     "Column12": 92.1,
     "Column13": 44,
     "Column14": 25.5,
     "Column15": 583,
     "Column16": 44.3,
     "Column17": 310,
     "Column18": 16.7,
     "Column19": 644,
     "Column20": 27.4,
     "Column21": 451,
     "Column22": 71.5,
     "Column23": 459,
     "Column24": 99.9,
     "Column25": 11,
     "Column26": 90,
     "Column27": "94=",
     "Column28": 68.7
    },
    {
     "2025 QS World University Rankings": 69,
     "Column2": 67,
     "Column3": "The University of Warwick",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 72.2,
     "Column11": 86,
     "Column12": 86.4,
     "Column13": 61,
     "Column14": 35.5,
     "Column15": 445,
     "Column16": 37.7,
     "Column17": 367,
     "Column18": 98.1,
     "Column19": 119,
     "Column20": 99.1,
     "Column21": 57,
     "Column22": 94.2,
     "Column23": 92,
     "Column24": 65.7,
     "Column25": 175,
     "Column26": 89.7,
     "Column27": 98,
     "Column28": 68.2
    },
    {
     "2025 QS World University Rankings": 69,
     "Column2": 64,
     "Column3": "University of Illinois at Urbana-Champaign",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 83.9,
     "Column11": 59,
     "Column12": 75.7,
     "Column13": 84,
     "Column14": 6.4,
     "Column15": "701+",
     "Column16": 77.5,
     "Column17": 124,
     "Column18": 14,
     "Column19": 686,
     "Column20": 56.3,
     "Column21": 266,
     "Column22": 90.4,
     "Column23": 165,
     "Column24": 81.7,
     "Column25": 114,
     "Column26": 65,
     "Column27": "241=",
     "Column28": 68.2
    },
    {
     "2025 QS World University Rankings": 71,
     "Column2": 95,
     "Column3": "Universidad de Buenos Aires (UBA)",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 94.1,
     "Column11": 35,
     "Column12": 94.1,
     "Column13": 38,
     "Column14": 71.7,
     "Column15": 183,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 63.9,
     "Column19": 305,
     "Column20": 64.6,
     "Column21": 232,
     "Column22": 73.4,
     "Column23": 430,
     "Column24": 99.8,
     "Column25": 13,
     "Column26": 51.8,
     "Column27": "310=",
     "Column28": 67.6
    },
    {
     "2025 QS World University Rankings": 72,
     "Column2": 62,
     "Column3": "University of California, San Diego (UCSD)",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 89.3,
     "Column11": 45,
     "Column12": 57.8,
     "Column13": 140,
     "Column14": 50.8,
     "Column15": 304,
     "Column16": 67,
     "Column17": 165,
     "Column18": 54.6,
     "Column19": 342,
     "Column20": 44.3,
     "Column21": 328,
     "Column22": 95.5,
     "Column23": 70,
     "Column24": 21.7,
     "Column25": 525,
     "Column26": 44.3,
     "Column27": "348=",
     "Column28": 67.1
    },
    {
     "2025 QS World University Rankings": 73,
     "Column2": 71,
     "Column3": "Université Paris-Saclay",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 60.7,
     "Column11": 121,
     "Column12": 99.1,
     "Column13": 12,
     "Column14": 99.8,
     "Column15": 25,
     "Column16": 35,
     "Column17": 384,
     "Column18": 35.7,
     "Column19": 448,
     "Column20": 58.6,
     "Column21": 253,
     "Column22": 98.6,
     "Column23": 16,
     "Column24": 70.8,
     "Column25": 155,
     "Column26": 72.6,
     "Column27": "202=",
     "Column28": 67
    },
    {
     "2025 QS World University Rankings": 74,
     "Column2": 73,
     "Column3": "KTH Royal Institute of Technology ",
     "Column4": "SE",
     "Column5": "Sweden",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 45.2,
     "Column11": 186,
     "Column12": 55.6,
     "Column13": 149,
     "Column14": 62.3,
     "Column15": 225,
     "Column16": 86.1,
     "Column17": 91,
     "Column18": 92,
     "Column19": 175,
     "Column20": 65.9,
     "Column21": 227,
     "Column22": 91.1,
     "Column23": 155,
     "Column24": 62.4,
     "Column25": 191,
     "Column26": 94.8,
     "Column27": 58,
     "Column28": 65.7
    },
    {
     "2025 QS World University Rankings": 75,
     "Column2": 85,
     "Column3": "Lund University",
     "Column4": "SE",
     "Column5": "Sweden",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 68.3,
     "Column11": 96,
     "Column12": 52.5,
     "Column13": 160,
     "Column14": 55.5,
     "Column15": 271,
     "Column16": 44.6,
     "Column17": 309,
     "Column18": 97.4,
     "Column19": 124,
     "Column20": 73.4,
     "Column21": 198,
     "Column22": 96.7,
     "Column23": 48,
     "Column24": 86.5,
     "Column25": 95,
     "Column26": 99.6,
     "Column27": 8,
     "Column28": 65.6
    },
    {
     "2025 QS World University Rankings": 76,
     "Column2": 63,
     "Column3": "University of Washington",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 81.9,
     "Column11": 66,
     "Column12": 55.5,
     "Column13": 150,
     "Column14": 28.6,
     "Column15": 542,
     "Column16": 78.8,
     "Column17": 119,
     "Column18": 11,
     "Column19": "701+",
     "Column20": 32.8,
     "Column21": 407,
     "Column22": 95.6,
     "Column23": 67,
     "Column24": 68.7,
     "Column25": 170,
     "Column26": 66.5,
     "Column27": 235,
     "Column28": 65.3
    },
    {
     "2025 QS World University Rankings": 77,
     "Column2": 72,
     "Column3": "The University of Western Australia",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 55.5,
     "Column11": 141,
     "Column12": 41.8,
     "Column13": 225,
     "Column14": 13.1,
     "Column15": "701+",
     "Column16": 98.8,
     "Column17": 30,
     "Column18": 100,
     "Column19": 37,
     "Column20": 99.8,
     "Column21": 40,
     "Column22": 94.2,
     "Column23": 95,
     "Column24": 78,
     "Column25": 128,
     "Column26": 50.8,
     "Column27": "315=",
     "Column28": 65.2
    },
    {
     "2025 QS World University Rankings": 78,
     "Column2": 76,
     "Column3": "University of Glasgow",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 77.4,
     "Column11": 75,
     "Column12": 67.7,
     "Column13": 110,
     "Column14": 38,
     "Column15": 418,
     "Column16": 32.5,
     "Column17": 405,
     "Column18": 95.7,
     "Column19": 141,
     "Column20": 99.4,
     "Column21": 49,
     "Column22": 97.6,
     "Column23": 30,
     "Column24": 33.5,
     "Column25": 378,
     "Column26": 98.5,
     "Column27": 20,
     "Column28": 65
    },
    {
     "2025 QS World University Rankings": 79,
     "Column2": 73,
     "Column3": "Brown University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 59.9,
     "Column11": 123,
     "Column12": 49.6,
     "Column13": 180,
     "Column14": 77.7,
     "Column15": 151,
     "Column16": 76.4,
     "Column17": 130,
     "Column18": 79.9,
     "Column19": 239,
     "Column20": 50.9,
     "Column21": 289,
     "Column22": 83.2,
     "Column23": 277,
     "Column24": 63.6,
     "Column25": 186,
     "Column26": 46.6,
     "Column27": 333,
     "Column28": 64.7
    },
    {
     "2025 QS World University Rankings": 80,
     "Column2": 84,
     "Column3": "University of Birmingham",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 68.9,
     "Column11": 94,
     "Column12": 69,
     "Column13": 105,
     "Column14": 39,
     "Column15": 402,
     "Column16": 40.5,
     "Column17": 348,
     "Column18": 92.6,
     "Column19": 171,
     "Column20": 89.3,
     "Column21": 127,
     "Column22": 97.5,
     "Column23": 31,
     "Column24": 44.5,
     "Column25": 285,
     "Column26": 96.8,
     "Column27": 35,
     "Column28": 64.1
    },
    {
     "2025 QS World University Rankings": 80,
     "Column2": 81,
     "Column3": "University of Southampton",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 50.1,
     "Column11": 161,
     "Column12": 44.3,
     "Column13": 208,
     "Column14": 40.7,
     "Column15": 385,
     "Column16": 83.4,
     "Column17": 100,
     "Column18": 92.5,
     "Column19": 173,
     "Column20": 98,
     "Column21": 70,
     "Column22": 97.1,
     "Column23": 40,
     "Column24": 49.2,
     "Column25": 261,
     "Column26": 96.9,
     "Column27": 34,
     "Column28": 64.1
    },
    {
     "2025 QS World University Rankings": 82,
     "Column2": 89,
     "Column3": "The University of Adelaide",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 56,
     "Column11": 138,
     "Column12": 38.9,
     "Column13": 242,
     "Column14": 17.9,
     "Column15": "701+",
     "Column16": 87,
     "Column17": 86,
     "Column18": 100,
     "Column19": 78,
     "Column20": 99.3,
     "Column21": 53,
     "Column22": 93.6,
     "Column23": 108,
     "Column24": 51.5,
     "Column25": 246,
     "Column26": 95.7,
     "Column27": 49,
     "Column28": 63.8
    },
    {
     "2025 QS World University Rankings": 82,
     "Column2": 75,
     "Column3": "University of Leeds",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 70.5,
     "Column11": 91,
     "Column12": 75.9,
     "Column13": 83,
     "Column14": 36.4,
     "Column15": 438,
     "Column16": 39.8,
     "Column17": 355,
     "Column18": 82.5,
     "Column19": 230,
     "Column20": 88.5,
     "Column21": 132,
     "Column22": 98.8,
     "Column23": 14,
     "Column24": 50.9,
     "Column25": 251,
     "Column26": 71.8,
     "Column27": "211=",
     "Column28": 63.8
    },
    {
     "2025 QS World University Rankings": 84,
     "Column2": 87,
     "Column3": "Ruprecht-Karls-Universität Heidelberg",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 76.9,
     "Column11": 76,
     "Column12": 59.4,
     "Column13": 136,
     "Column14": 99.4,
     "Column15": 32,
     "Column16": 32.7,
     "Column17": 401,
     "Column18": 54.2,
     "Column19": 346,
     "Column20": 53.6,
     "Column21": 275,
     "Column22": 94.5,
     "Column23": 88,
     "Column24": 25.2,
     "Column25": 463,
     "Column26": 77.6,
     "Column27": "170=",
     "Column28": 63.7
    },
    {
     "2025 QS World University Rankings": 84,
     "Column2": 91,
     "Column3": "Tokyo Institute of Technology (Tokyo Tech)",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 75.1,
     "Column11": 80,
     "Column12": 94.1,
     "Column13": 37,
     "Column14": 84.1,
     "Column15": 114,
     "Column16": 43.1,
     "Column17": 320,
     "Column18": 23.4,
     "Column19": 556,
     "Column20": 30.5,
     "Column21": 424,
     "Column22": 61.2,
     "Column23": 613,
     "Column24": 23.7,
     "Column25": 475,
     "Column26": 62.2,
     "Column27": "254=",
     "Column28": 63.7
    },
    {
     "2025 QS World University Rankings": 86,
     "Column2": 80,
     "Column3": "Osaka University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 82.4,
     "Column11": 64,
     "Column12": 87,
     "Column13": 58,
     "Column14": 45.2,
     "Column15": 343,
     "Column16": 54.9,
     "Column17": 232,
     "Column18": 23.9,
     "Column19": 549,
     "Column20": 12.2,
     "Column21": 675,
     "Column22": 68.2,
     "Column23": 512,
     "Column24": 33,
     "Column25": 387,
     "Column26": 68,
     "Column27": "228=",
     "Column28": 63.5
    },
    {
     "2025 QS World University Rankings": 87,
     "Column2": 81,
     "Column3": "Trinity College Dublin, The University of Dublin",
     "Column4": "IE",
     "Column5": "Ireland",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 69.5,
     "Column11": 93,
     "Column12": 47.4,
     "Column13": 195,
     "Column14": 20.2,
     "Column15": 668,
     "Column16": 58.4,
     "Column17": 210,
     "Column18": 99.3,
     "Column19": 101,
     "Column20": 88,
     "Column21": 133,
     "Column22": 91.3,
     "Column23": 153,
     "Column24": 88.6,
     "Column25": 83,
     "Column26": 57.7,
     "Column27": "276=",
     "Column28": 62.9
    },
    {
     "2025 QS World University Rankings": 88,
     "Column2": 90,
     "Column3": "University of Technology Sydney",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 43.9,
     "Column11": 194,
     "Column12": 55,
     "Column13": 153,
     "Column14": 9.8,
     "Column15": "701+",
     "Column16": 97.7,
     "Column17": 39,
     "Column18": 96.6,
     "Column19": 133,
     "Column20": 86,
     "Column21": 145,
     "Column22": 91.4,
     "Column23": 150,
     "Column24": 40.2,
     "Column25": 318,
     "Column26": 96.3,
     "Column27": 43,
     "Column28": 62.4
    },
    {
     "2025 QS World University Rankings": 89,
     "Column2": 78,
     "Column3": "Durham University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 59.6,
     "Column11": 126,
     "Column12": 83.9,
     "Column13": 65,
     "Column14": 26.3,
     "Column15": 567,
     "Column16": 40.9,
     "Column17": 342,
     "Column18": 97.3,
     "Column19": 125,
     "Column20": 84.9,
     "Column21": 153,
     "Column22": 89.7,
     "Column23": 172,
     "Column24": 39.4,
     "Column25": 326,
     "Column26": 98.6,
     "Column27": 19,
     "Column28": 61.7
    },
    {
     "2025 QS World University Rankings": 89,
     "Column2": 83,
     "Column3": "Pennsylvania State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 76.6,
     "Column11": 77,
     "Column12": 64.5,
     "Column13": 121,
     "Column14": 25,
     "Column15": 592,
     "Column16": 55.4,
     "Column17": 226,
     "Column18": 25.1,
     "Column19": 533,
     "Column20": 28,
     "Column21": 446,
     "Column22": 97.9,
     "Column23": 28,
     "Column24": 61.9,
     "Column25": 193,
     "Column26": 96.5,
     "Column27": "37=",
     "Column28": 61.7
    },
    {
     "2025 QS World University Rankings": 89,
     "Column2": 99,
     "Column3": "Purdue University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 63.3,
     "Column11": 107,
     "Column12": 81.1,
     "Column13": 71,
     "Column14": 11.4,
     "Column15": "701+",
     "Column16": 54.3,
     "Column17": 235,
     "Column18": 79.6,
     "Column19": 242,
     "Column20": 45.2,
     "Column21": 325,
     "Column22": 92.9,
     "Column23": 123,
     "Column24": 63.1,
     "Column25": 188,
     "Column26": 90.1,
     "Column27": 93,
     "Column28": 61.7
    },
    {
     "2025 QS World University Rankings": 92,
     "Column2": 85,
     "Column3": "Universidade de São Paulo",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 92.8,
     "Column11": 40,
     "Column12": 74.6,
     "Column13": 87,
     "Column14": 21.4,
     "Column15": 646,
     "Column16": 28.5,
     "Column17": 456,
     "Column18": 6.8,
     "Column19": "701+",
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 95.9,
     "Column23": 60,
     "Column24": 97.9,
     "Column25": 27,
     "Column26": 93.6,
     "Column27": 67,
     "Column28": 61.6
    },
    {
     "2025 QS World University Rankings": 93,
     "Column2": 103,
     "Column3": "Pontificia Universidad Católica de Chile (UC)",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 93.7,
     "Column11": 37,
     "Column12": 98,
     "Column13": 21,
     "Column14": 23.7,
     "Column15": 614,
     "Column16": 8.8,
     "Column17": "701+",
     "Column18": 26,
     "Column19": 524,
     "Column20": 3.7,
     "Column21": "701+",
     "Column22": 82.5,
     "Column23": 285,
     "Column24": 99.9,
     "Column25": 12,
     "Column26": 79.3,
     "Column27": "159=",
     "Column28": 61.5
    },
    {
     "2025 QS World University Rankings": 94,
     "Column2": 87,
     "Column3": "Lomonosov Moscow State University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 78,
     "Column11": 73,
     "Column12": 82.3,
     "Column13": 70,
     "Column14": 99.7,
     "Column15": 30,
     "Column16": 4.1,
     "Column17": "701+",
     "Column18": 19.7,
     "Column19": 604,
     "Column20": 91.5,
     "Column21": 114,
     "Column22": 88.1,
     "Column23": 196,
     "Column24": 95.8,
     "Column25": 47,
     "Column26": 3.5,
     "Column27": "701+",
     "Column28": 61.4
    },
    {
     "2025 QS World University Rankings": 94,
     "Column2": 93,
     "Column3": "Universidad Nacional Autónoma de México (UNAM)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 97,
     "Column11": 28,
     "Column12": 94.4,
     "Column13": 35,
     "Column14": 37.4,
     "Column15": 430,
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 7.5,
     "Column19": "701+",
     "Column20": 2.3,
     "Column21": "701+",
     "Column22": 93.2,
     "Column23": 112,
     "Column24": 93.2,
     "Column25": 62,
     "Column26": 83.4,
     "Column27": "136=",
     "Column28": 61.4
    },
    {
     "2025 QS World University Rankings": 96,
     "Column2": 111,
     "Column3": "University of Alberta",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 57,
     "Column11": 136,
     "Column12": 42.2,
     "Column13": 222,
     "Column14": 39.1,
     "Column15": 400,
     "Column16": 61.3,
     "Column17": 194,
     "Column18": 98.3,
     "Column19": 116,
     "Column20": 71,
     "Column21": 207,
     "Column22": 96,
     "Column23": 55,
     "Column24": 70,
     "Column25": 159,
     "Column26": 97.6,
     "Column27": 28,
     "Column28": 61.2
    },
    {
     "2025 QS World University Rankings": 97,
     "Column2": 98,
     "Column3": "Freie Universitaet Berlin",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 80.3,
     "Column11": 71,
     "Column12": 56.9,
     "Column13": 143,
     "Column14": 5,
     "Column15": "701+",
     "Column16": 71.7,
     "Column17": 150,
     "Column18": 62.4,
     "Column19": 314,
     "Column20": 43.7,
     "Column21": 330,
     "Column22": 93.1,
     "Column23": 113,
     "Column24": 32.8,
     "Column25": 389,
     "Column26": 31.3,
     "Column27": "435=",
     "Column28": 60.6
    },
    {
     "2025 QS World University Rankings": 98,
     "Column2": 100,
     "Column3": "Pohang University of Science And Technology (POSTECH)",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 45.9,
     "Column11": 178,
     "Column12": 80,
     "Column13": 76,
     "Column14": 99.9,
     "Column15": 24,
     "Column16": 96.9,
     "Column17": 45,
     "Column18": 27.6,
     "Column19": 511,
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 26.6,
     "Column23": "701+",
     "Column24": 11,
     "Column25": "701+",
     "Column26": 36.6,
     "Column27": "393=",
     "Column28": 60.3
    },
    {
     "2025 QS World University Rankings": 99,
     "Column2": 106,
     "Column3": "RWTH Aachen University",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 46.6,
     "Column11": 173,
     "Column12": 93.7,
     "Column13": 41,
     "Column14": 6.3,
     "Column15": "701+",
     "Column16": 71.9,
     "Column17": 149,
     "Column18": 48.6,
     "Column19": 373,
     "Column20": 85.5,
     "Column21": 149,
     "Column22": 86.1,
     "Column23": 233,
     "Column24": 33.4,
     "Column25": 379,
     "Column26": 84.8,
     "Column27": "127=",
     "Column28": 59.9
    },
    {
     "2025 QS World University Rankings": 100,
     "Column2": 107,
     "Column3": "University of Copenhagen",
     "Column4": "DK",
     "Column5": "Denmark",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 74,
     "Column11": 83,
     "Column12": 31.6,
     "Column13": 299,
     "Column14": 99.8,
     "Column15": 27,
     "Column16": 23.1,
     "Column17": 519,
     "Column18": 84.9,
     "Column19": 221,
     "Column20": 15.6,
     "Column21": 613,
     "Column22": 98.2,
     "Column23": 22,
     "Column24": 90.8,
     "Column25": 72,
     "Column26": 73.2,
     "Column27": "198=",
     "Column28": 59.6
    },
    {
     "2025 QS World University Rankings": 101,
     "Column2": 180,
     "Column3": "King Fahd University of Petroleum & Minerals",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 38,
     "Column11": 226,
     "Column12": 46.5,
     "Column13": 198,
     "Column14": 86,
     "Column15": 101,
     "Column16": 78,
     "Column17": 121,
     "Column18": 100,
     "Column19": 26,
     "Column20": 47.6,
     "Column21": 307,
     "Column22": 73.9,
     "Column23": 422,
     "Column24": 97.8,
     "Column25": 28,
     "Column26": 20.1,
     "Column27": "530=",
     "Column28": 59.5
    },
    {
     "2025 QS World University Rankings": 102,
     "Column2": 119,
     "Column3": "KIT, Karlsruhe Institute of Technology",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 44.9,
     "Column11": 188,
     "Column12": 80.9,
     "Column13": 74,
     "Column14": 39.4,
     "Column15": 395,
     "Column16": 68.4,
     "Column17": 160,
     "Column18": 70.5,
     "Column19": 282,
     "Column20": 42.9,
     "Column21": 337,
     "Column22": 86.4,
     "Column23": 225,
     "Column24": 50.8,
     "Column25": 252,
     "Column26": 75.3,
     "Column27": "184=",
     "Column28": 59.4
    },
    {
     "2025 QS World University Rankings": 103,
     "Column2": 105,
     "Column3": "Uppsala University",
     "Column4": "SE",
     "Column5": "Sweden",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 61.4,
     "Column11": 115,
     "Column12": 33.7,
     "Column13": 277,
     "Column14": 52,
     "Column15": 292,
     "Column16": 42.3,
     "Column17": 326,
     "Column18": 99.4,
     "Column19": 99,
     "Column20": 81.1,
     "Column21": 170,
     "Column22": 96.7,
     "Column23": 49,
     "Column24": 68.4,
     "Column25": 171,
     "Column26": 99.2,
     "Column27": "11=",
     "Column28": 59.3
    },
    {
     "2025 QS World University Rankings": 104,
     "Column2": 95,
     "Column3": "University of St Andrews",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 46.5,
     "Column11": 174,
     "Column12": 63.2,
     "Column13": 126,
     "Column14": 64.7,
     "Column15": 206,
     "Column16": 49.2,
     "Column17": 272,
     "Column18": 98.4,
     "Column19": 113,
     "Column20": 100,
     "Column21": 29,
     "Column22": 83.8,
     "Column23": 266,
     "Column24": 18.5,
     "Column25": 594,
     "Column26": 91.1,
     "Column27": "86=",
     "Column28": 59.2
    },
    {
     "2025 QS World University Rankings": 105,
     "Column2": 104,
     "Column3": "The University of Sheffield",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 58.3,
     "Column11": 132,
     "Column12": 48.7,
     "Column13": 187,
     "Column14": 52.5,
     "Column15": 290,
     "Column16": 43.9,
     "Column17": 313,
     "Column18": 83.3,
     "Column19": 228,
     "Column20": 96.8,
     "Column21": 80,
     "Column22": 95.8,
     "Column23": 62,
     "Column24": 33.8,
     "Column25": 374,
     "Column26": 97.7,
     "Column27": "24=",
     "Column28": 59.1
    },
    {
     "2025 QS World University Rankings": 105,
     "Column2": 107,
     "Column3": "Utrecht University",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 66.5,
     "Column11": 103,
     "Column12": 38.8,
     "Column13": 244,
     "Column14": 31,
     "Column15": 508,
     "Column16": 79.5,
     "Column17": 112,
     "Column18": 55.7,
     "Column19": 337,
     "Column20": 19.4,
     "Column21": 559,
     "Column22": 97.3,
     "Column23": 37,
     "Column24": 54.7,
     "Column25": 228,
     "Column26": 61.9,
     "Column27": "258=",
     "Column28": 59.1
    },
    {
     "2025 QS World University Rankings": 107,
     "Column2": 113,
     "Column3": "Tohoku University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 73.8,
     "Column11": 84,
     "Column12": 82.8,
     "Column13": 67,
     "Column14": 97.8,
     "Column15": 47,
     "Column16": 23.9,
     "Column17": 510,
     "Column18": 14.1,
     "Column19": 682,
     "Column20": 11.4,
     "Column21": 689,
     "Column22": 68.3,
     "Column23": 510,
     "Column24": 22.6,
     "Column25": 507,
     "Column26": 78.6,
     "Column27": "164=",
     "Column28": 58.8
    },
    {
     "2025 QS World University Rankings": 108,
     "Column2": 100,
     "Column3": "University of Nottingham",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 61.1,
     "Column11": 117,
     "Column12": 65.8,
     "Column13": 116,
     "Column14": 32.8,
     "Column15": 481,
     "Column16": 37.6,
     "Column17": 368,
     "Column18": 89.4,
     "Column19": 194,
     "Column20": 70.5,
     "Column21": 208,
     "Column22": 98,
     "Column23": 25,
     "Column24": 38,
     "Column25": 339,
     "Column26": 97,
     "Column27": 33,
     "Column28": 58.5
    },
    {
     "2025 QS World University Rankings": 109,
     "Column2": 121,
     "Column3": "Technical University of Denmark",
     "Column4": "DK",
     "Column5": "Denmark",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 33.2,
     "Column11": 265,
     "Column12": 22.1,
     "Column13": 421,
     "Column14": 99.1,
     "Column15": 34,
     "Column16": 84.4,
     "Column17": 98,
     "Column18": 100,
     "Column19": 55,
     "Column20": 87.7,
     "Column21": 135,
     "Column22": 88.1,
     "Column23": 194,
     "Column24": 43.6,
     "Column25": 293,
     "Column26": 47.9,
     "Column27": 328,
     "Column28": 58.3
    },
    {
     "2025 QS World University Rankings": 109,
     "Column2": 91,
     "Column3": "University of Zurich",
     "Column4": "CH",
     "Column5": "Switzerland",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 59.9,
     "Column11": 124,
     "Column12": 26.4,
     "Column13": 361,
     "Column14": 92.2,
     "Column15": 78,
     "Column16": 40.1,
     "Column17": 352,
     "Column18": 100,
     "Column19": 71,
     "Column20": 42.8,
     "Column21": 338,
     "Column22": 94.3,
     "Column23": 90,
     "Column24": 88.6,
     "Column25": 84,
     "Column26": 58.7,
     "Column27": "273=",
     "Column28": 58.3
    },
    {
     "2025 QS World University Rankings": 111,
     "Column2": 123,
     "Column3": "Politecnico di Milano",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 70.8,
     "Column11": 90,
     "Column12": 77,
     "Column13": 82,
     "Column14": 5.8,
     "Column15": "701+",
     "Column16": 40.2,
     "Column17": 351,
     "Column18": 67.4,
     "Column19": 292,
     "Column20": 56.8,
     "Column21": 262,
     "Column22": 88.1,
     "Column23": 197,
     "Column24": 52.9,
     "Column25": 239,
     "Column26": 72.6,
     "Column27": "202=",
     "Column28": 58.2
    },
    {
     "2025 QS World University Rankings": 108,
     "Column2": 93,
     "Column3": "Boston University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 61.9,
     "Column11": 111,
     "Column12": 69.1,
     "Column13": 104,
     "Column14": 38.3,
     "Column15": 413,
     "Column16": 41.3,
     "Column17": 337,
     "Column18": 34.3,
     "Column19": 468,
     "Column20": 88.9,
     "Column21": 130,
     "Column22": 86.9,
     "Column23": 216,
     "Column24": 77.2,
     "Column25": 132,
     "Column26": 65.9,
     "Column27": "236=",
     "Column28": 58.1
    },
    {
     "2025 QS World University Rankings": 113,
     "Column2": 109,
     "Column3": "Aalto University",
     "Column4": "FI",
     "Column5": "Finland",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 45.7,
     "Column11": 180,
     "Column12": 40.6,
     "Column13": 231,
     "Column14": 33.2,
     "Column15": 476,
     "Column16": 73.6,
     "Column17": 141,
     "Column18": 95.2,
     "Column19": 146,
     "Column20": 40.1,
     "Column21": 354,
     "Column22": 78.2,
     "Column23": 355,
     "Column24": 95.1,
     "Column25": 52,
     "Column26": 94.7,
     "Column27": 59,
     "Column28": 57.9
    },
    {
     "2025 QS World University Rankings": 114,
     "Column2": 97,
     "Column3": "Georgia Institute of Technology",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 67.1,
     "Column11": 102,
     "Column12": 82.6,
     "Column13": 68,
     "Column14": 20.3,
     "Column15": 665,
     "Column16": 52.8,
     "Column17": 243,
     "Column18": 21.3,
     "Column19": 584,
     "Column20": 66.4,
     "Column21": 224,
     "Column22": 75.5,
     "Column23": 397,
     "Column24": 43.1,
     "Column25": 298,
     "Column26": 39.5,
     "Column27": "371=",
     "Column28": 57.3
    },
    {
     "2025 QS World University Rankings": 115,
     "Column2": 112,
     "Column3": "University of Waterloo",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 40.4,
     "Column11": 211,
     "Column12": 64.2,
     "Column13": 124,
     "Column14": 6.5,
     "Column15": "701+",
     "Column16": 78.9,
     "Column17": 118,
     "Column18": 75.8,
     "Column19": 257,
     "Column20": 71.3,
     "Column21": 206,
     "Column22": 84.6,
     "Column23": 256,
     "Column24": 58.9,
     "Column25": 209,
     "Column26": 93.3,
     "Column27": "68=",
     "Column28": 57.2
    },
    {
     "2025 QS World University Rankings": 116,
     "Column2": 102,
     "Column3": "University of Wisconsin-Madison",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 77.7,
     "Column11": 74,
     "Column12": 47.8,
     "Column13": 193,
     "Column14": 74.3,
     "Column15": 170,
     "Column16": 23.7,
     "Column17": 513,
     "Column18": 35.3,
     "Column19": 454,
     "Column20": 23.6,
     "Column21": 498,
     "Column22": 91.6,
     "Column23": 149,
     "Column24": 81.6,
     "Column25": 116,
     "Column26": 59.6,
     "Column27": "269=",
     "Column28": 57.1
    },
    {
     "2025 QS World University Rankings": 117,
     "Column2": 115,
     "Column3": "University of Helsinki",
     "Column4": "FI",
     "Column5": "Finland",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 62.3,
     "Column11": 110,
     "Column12": 29.1,
     "Column13": 324,
     "Column14": 59.4,
     "Column15": 246,
     "Column16": 49.6,
     "Column17": 267,
     "Column18": 73.4,
     "Column19": 272,
     "Column20": 5.7,
     "Column21": "701+",
     "Column22": 96.9,
     "Column23": 43,
     "Column24": 90.5,
     "Column25": 74,
     "Column26": 94.5,
     "Column27": 60,
     "Column28": 56.8
    },
    {
     "2025 QS World University Rankings": 118,
     "Column2": 149,
     "Column3": "Indian Institute of Technology Bombay (IITB)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 58.5,
     "Column11": 131,
     "Column12": 86,
     "Column13": 63,
     "Column14": 14.4,
     "Column15": "701+",
     "Column16": 79.1,
     "Column17": 116,
     "Column18": 4.3,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 52.3,
     "Column23": "701+",
     "Column24": 64.5,
     "Column25": 182,
     "Column26": 52.5,
     "Column27": "302=",
     "Column28": 56.3
    },
    {
     "2025 QS World University Rankings": 119,
     "Column2": 117,
     "Column3": "University of Oslo",
     "Column4": "NO",
     "Column5": "Norway",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 55.6,
     "Column11": 139,
     "Column12": 16,
     "Column13": 534,
     "Column14": 73.8,
     "Column15": 175,
     "Column16": 55.7,
     "Column17": 225,
     "Column18": 91.6,
     "Column19": 180,
     "Column20": 31.7,
     "Column21": 416,
     "Column22": 97.3,
     "Column23": 35,
     "Column24": 80.9,
     "Column25": 121,
     "Column26": 72.1,
     "Column27": "208=",
     "Column28": 56.1
    },
    {
     "2025 QS World University Rankings": 120,
     "Column2": 145,
     "Column3": "Queen Mary University of London",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 48.3,
     "Column11": 170,
     "Column12": 36.9,
     "Column13": 254,
     "Column14": 30.4,
     "Column15": 515,
     "Column16": 62.8,
     "Column17": 184,
     "Column18": 99.5,
     "Column19": 96,
     "Column20": 99.2,
     "Column21": 54,
     "Column22": 92.5,
     "Column23": 127,
     "Column24": 21.4,
     "Column25": 534,
     "Column26": 95.6,
     "Column27": 54,
     "Column28": 55.8
    },
    {
     "2025 QS World University Rankings": 120,
     "Column2": 114,
     "Column3": "Western University",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 32.6,
     "Column11": 270,
     "Column12": 38.9,
     "Column13": 243,
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 93.4,
     "Column17": 60,
     "Column18": 99.8,
     "Column19": 94,
     "Column20": 49.5,
     "Column21": 299,
     "Column22": 81.5,
     "Column23": 296,
     "Column24": 96.7,
     "Column25": 38,
     "Column26": 99.3,
     "Column27": 10,
     "Column28": 55.8
    },
    {
     "2025 QS World University Rankings": 122,
     "Column2": 173,
     "Column3": "Qatar University",
     "Column4": "QA",
     "Column5": "Qatar",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 29.8,
     "Column11": 297,
     "Column12": 50.6,
     "Column13": 175,
     "Column14": 68.1,
     "Column15": 191,
     "Column16": 48.2,
     "Column17": 277,
     "Column18": 100,
     "Column19": 24,
     "Column20": 96.5,
     "Column21": 83,
     "Column22": 89.4,
     "Column23": 177,
     "Column24": 98.6,
     "Column25": 20,
     "Column26": 74,
     "Column27": 196,
     "Column28": 55.7
    },
    {
     "2025 QS World University Rankings": 123,
     "Column2": 140,
     "Column3": "RMIT University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 40.4,
     "Column11": 210,
     "Column12": 48,
     "Column13": 192,
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 77.7,
     "Column17": 122,
     "Column18": 100,
     "Column19": 45,
     "Column20": 91.4,
     "Column21": 116,
     "Column22": 83.2,
     "Column23": 276,
     "Column24": 38.4,
     "Column25": 334,
     "Column26": 93.9,
     "Column27": "62=",
     "Column28": 55.5
    },
    {
     "2025 QS World University Rankings": 123,
     "Column2": 145,
     "Column3": "Sungkyunkwan University(SKKU)",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 57.9,
     "Column11": 134,
     "Column12": 70.7,
     "Column13": 100,
     "Column14": 80.5,
     "Column15": 131,
     "Column16": 41.3,
     "Column17": 338,
     "Column18": 17.5,
     "Column19": 632,
     "Column20": 41.7,
     "Column21": 341,
     "Column22": 54.2,
     "Column23": "701+",
     "Column24": 36.5,
     "Column25": 348,
     "Column26": 79.1,
     "Column27": "161=",
     "Column28": 55.5
    },
    {
     "2025 QS World University Rankings": 125,
     "Column2": 116,
     "Column3": "University of Southern California",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 50.4,
     "Column11": 160,
     "Column12": 54.5,
     "Column13": 156,
     "Column14": 17.3,
     "Column15": "701+",
     "Column16": 58,
     "Column17": 211,
     "Column18": 26,
     "Column19": 523,
     "Column20": 74.1,
     "Column21": 192,
     "Column22": 85.8,
     "Column23": 235,
     "Column24": 92.6,
     "Column25": 66,
     "Column26": 97.7,
     "Column27": "24=",
     "Column28": 55.2
    },
    {
     "2025 QS World University Rankings": 126,
     "Column2": 120,
     "Column3": "Humboldt-Universität zu Berlin",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 89.2,
     "Column11": 46,
     "Column12": 69.9,
     "Column13": 102,
     "Column14": 7.4,
     "Column15": "701+",
     "Column16": 27,
     "Column17": 476,
     "Column18": 69.1,
     "Column19": 288,
     "Column20": 35.1,
     "Column21": 391,
     "Column22": 93.1,
     "Column23": 114,
     "Column24": 19,
     "Column25": 580,
     "Column26": 19.9,
     "Column27": "535=",
     "Column28": 55
    },
    {
     "2025 QS World University Rankings": 126,
     "Column2": 171,
     "Column3": "University College Dublin",
     "Column4": "IE",
     "Column5": "Ireland",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 51.4,
     "Column11": 158,
     "Column12": 43.5,
     "Column13": 210,
     "Column14": 16.6,
     "Column15": "701+",
     "Column16": 46.4,
     "Column17": 298,
     "Column18": 99.1,
     "Column19": 105,
     "Column20": 74.8,
     "Column21": 188,
     "Column22": 87.2,
     "Column23": 212,
     "Column24": 89.8,
     "Column25": 77,
     "Column26": 95.6,
     "Column27": "50=",
     "Column28": 55
    },
    {
     "2025 QS World University Rankings": 128,
     "Column2": 118,
     "Column3": "Stockholm University",
     "Column4": "SE",
     "Column5": "Sweden",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 52.5,
     "Column11": 155,
     "Column12": 21,
     "Column13": 436,
     "Column14": 26.7,
     "Column15": 563,
     "Column16": 64.5,
     "Column17": 174,
     "Column18": 80.7,
     "Column19": 235,
     "Column20": 89.8,
     "Column21": 122,
     "Column22": 95.7,
     "Column23": 65,
     "Column24": 86.6,
     "Column25": 94,
     "Column26": 58.7,
     "Column27": "273=",
     "Column28": 54.8
    },
    {
     "2025 QS World University Rankings": 129,
     "Column2": 110,
     "Column3": "Newcastle University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 50,
     "Column11": 164,
     "Column12": 59.3,
     "Column13": 137,
     "Column14": 39.2,
     "Column15": 399,
     "Column16": 38.7,
     "Column17": 362,
     "Column18": 89.5,
     "Column19": 193,
     "Column20": 68.6,
     "Column21": 217,
     "Column22": 97.1,
     "Column23": 42,
     "Column24": 31.3,
     "Column25": 403,
     "Column26": 97.1,
     "Column27": "30=",
     "Column28": 54.5
    },
    {
     "2025 QS World University Rankings": 130,
     "Column2": 118,
     "Column3": "University of California, Davis",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 63,
     "Column11": 109,
     "Column12": 48,
     "Column13": 191,
     "Column14": 23.5,
     "Column15": 617,
     "Column16": 52.5,
     "Column17": 246,
     "Column18": 72.7,
     "Column19": 275,
     "Column20": 51.3,
     "Column21": 286,
     "Column22": 90.6,
     "Column23": 163,
     "Column24": 22.8,
     "Column25": 501,
     "Column26": 76.1,
     "Column27": "178=",
     "Column28": 54.4
    },
    {
     "2025 QS World University Rankings": 131,
     "Column2": 124,
     "Column3": "University of Basel",
     "Column4": "CH",
     "Column5": "Switzerland",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 26.6,
     "Column11": 331,
     "Column12": 13.5,
     "Column13": "601+",
     "Column14": 61.4,
     "Column15": 232,
     "Column16": 94.5,
     "Column17": 53,
     "Column18": 100,
     "Column19": 44,
     "Column20": 85.8,
     "Column21": 147,
     "Column22": 89.4,
     "Column23": 176,
     "Column24": 69.9,
     "Column25": 160,
     "Column26": 44.6,
     "Column27": "343=",
     "Column28": 54.3
    },
    {
     "2025 QS World University Rankings": 132,
     "Column2": 134,
     "Column3": "Sapienza University of Rome",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 80.7,
     "Column11": 70,
     "Column12": 48.5,
     "Column13": 189,
     "Column14": 5,
     "Column15": "701+",
     "Column16": 45.3,
     "Column17": 305,
     "Column18": 3.1,
     "Column19": "701+",
     "Column20": 8,
     "Column21": "701+",
     "Column22": 97.1,
     "Column23": 41,
     "Column24": 88,
     "Column25": 87,
     "Column26": 72.3,
     "Column27": "205=",
     "Column28": 54.2
    },
    {
     "2025 QS World University Rankings": 133,
     "Column2": 154,
     "Column3": "Alma Mater Studiorum - University of Bologna",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 80.8,
     "Column11": 69,
     "Column12": 47.8,
     "Column13": 194,
     "Column14": 3.7,
     "Column15": "701+",
     "Column16": 46.6,
     "Column17": 296,
     "Column18": 14.5,
     "Column19": 672,
     "Column20": 12.3,
     "Column21": 672,
     "Column22": 96,
     "Column23": 56,
     "Column24": 53.8,
     "Column25": 232,
     "Column26": 88.4,
     "Column27": 108,
     "Column28": 54.1
    },
    {
     "2025 QS World University Rankings": 133,
     "Column2": 130,
     "Column3": "Macquarie University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 33.4,
     "Column11": 262,
     "Column12": 39.2,
     "Column13": 241,
     "Column14": 9.1,
     "Column15": "701+",
     "Column16": 79.6,
     "Column17": 111,
     "Column18": 99.9,
     "Column19": 88,
     "Column20": 91.6,
     "Column21": 112,
     "Column22": 90.9,
     "Column23": 157,
     "Column24": 54.1,
     "Column25": 231,
     "Column26": 95,
     "Column27": 57,
     "Column28": 54.1
    },
    {
     "2025 QS World University Rankings": 145,
     "Column2": 141,
     "Column3": "Nanjing University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 57.1,
     "Column11": 135,
     "Column12": 32.5,
     "Column13": 290,
     "Column14": 35.6,
     "Column15": 443,
     "Column16": 93,
     "Column17": 63,
     "Column18": 61.9,
     "Column19": 317,
     "Column20": 7.6,
     "Column21": "701+",
     "Column22": 67.1,
     "Column23": 520,
     "Column24": 29,
     "Column25": 430,
     "Column26": 16.5,
     "Column27": "567=",
     "Column28": 53
    },
    {
     "2025 QS World University Rankings": 136,
     "Column2": 124,
     "Column3": "Eindhoven University of Technology",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 28.7,
     "Column11": 306,
     "Column12": 72.1,
     "Column13": 96,
     "Column14": 35.7,
     "Column15": 442,
     "Column16": 79.3,
     "Column17": 114,
     "Column18": 100,
     "Column19": 75,
     "Column20": 82.7,
     "Column21": 164,
     "Column22": 70.8,
     "Column23": 472,
     "Column24": 24.2,
     "Column25": 468,
     "Column26": 29.8,
     "Column27": "445=",
     "Column28": 54
    },
    {
     "2025 QS World University Rankings": 137,
     "Column2": 130,
     "Column3": "University of Vienna",
     "Column4": "AT",
     "Column5": "Austria",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 70.9,
     "Column11": 89,
     "Column12": 40.4,
     "Column13": 235,
     "Column14": 23.2,
     "Column15": 619,
     "Column16": 13,
     "Column17": 667,
     "Column18": 100,
     "Column19": 70,
     "Column20": 92,
     "Column21": 109,
     "Column22": 96,
     "Column23": 57,
     "Column24": 89.7,
     "Column25": 78,
     "Column26": 60.9,
     "Column27": 265,
     "Column28": 53.9
    },
    {
     "2025 QS World University Rankings": 138,
     "Column2": 159,
     "Column3": "Universiti Kebangsaan Malaysia (UKM)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 61.7,
     "Column11": 113,
     "Column12": 66.4,
     "Column13": 114,
     "Column14": 80.4,
     "Column15": 132,
     "Column16": 14.1,
     "Column17": 651,
     "Column18": 45.7,
     "Column19": 392,
     "Column20": 78.8,
     "Column21": 175,
     "Column22": 83.7,
     "Column23": 269,
     "Column24": 22.5,
     "Column25": 510,
     "Column26": 64.4,
     "Column27": "243=",
     "Column28": 53.8
    },
    {
     "2025 QS World University Rankings": 139,
     "Column2": 129,
     "Column3": "Chalmers University of Technology",
     "Column4": "SE",
     "Column5": "Sweden",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 26.3,
     "Column11": 338,
     "Column12": 50.4,
     "Column13": 177,
     "Column14": 50.5,
     "Column15": 306,
     "Column16": 77.4,
     "Column17": 125,
     "Column18": 100,
     "Column19": 56,
     "Column20": 63.1,
     "Column21": 240,
     "Column22": 77,
     "Column23": 378,
     "Column24": 31.7,
     "Column25": 399,
     "Column26": 87,
     "Column27": 113,
     "Column28": 53.7
    },
    {
     "2025 QS World University Rankings": 139,
     "Column2": 159,
     "Column3": "Universidad de Chile",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 82.8,
     "Column11": 63,
     "Column12": 90,
     "Column13": 52,
     "Column14": 8,
     "Column15": "701+",
     "Column16": 11.2,
     "Column17": "701+",
     "Column18": 8.5,
     "Column19": "701+",
     "Column20": 4.5,
     "Column21": "701+",
     "Column22": 77.1,
     "Column23": 377,
     "Column24": 96.1,
     "Column25": 42,
     "Column26": 64.1,
     "Column27": "245=",
     "Column28": 53.7
    },
    {
     "2025 QS World University Rankings": 141,
     "Column2": 122,
     "Column3": "Lancaster University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 38.9,
     "Column11": 222,
     "Column12": 36.7,
     "Column13": 255,
     "Column14": 27.7,
     "Column15": 551,
     "Column16": 69.6,
     "Column17": 157,
     "Column18": 93.3,
     "Column19": 166,
     "Column20": 92.3,
     "Column21": 105,
     "Column22": 89.7,
     "Column23": 171,
     "Column24": 28.8,
     "Column25": 431,
     "Column26": 96.2,
     "Column27": "44=",
     "Column28": 53.6
    },
    {
     "2025 QS World University Rankings": 141,
     "Column2": 126,
     "Column3": "Leiden University",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 68.4,
     "Column11": 95,
     "Column12": 32.9,
     "Column13": 288,
     "Column14": 21.3,
     "Column15": 649,
     "Column16": 54.5,
     "Column17": 234,
     "Column18": 85,
     "Column19": 220,
     "Column20": 51.7,
     "Column21": 284,
     "Column22": 95.2,
     "Column23": 72,
     "Column24": 48.8,
     "Column25": 263,
     "Column26": 25.5,
     "Column27": "488=",
     "Column28": 53.6
    },
    {
     "2025 QS World University Rankings": 141,
     "Column2": 145,
     "Column3": "Rice University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 39.1,
     "Column11": 220,
     "Column12": 27.3,
     "Column13": 350,
     "Column14": 62.5,
     "Column15": 222,
     "Column16": 87.9,
     "Column17": 83,
     "Column18": 77.5,
     "Column19": 247,
     "Column20": 94.1,
     "Column21": 99,
     "Column22": 62.2,
     "Column23": 602,
     "Column24": 29.5,
     "Column25": 421,
     "Column26": 20.6,
     "Column27": 529,
     "Column28": 53.6
    },
    {
     "2025 QS World University Rankings": 144,
     "Column2": 143,
     "Column3": "Aarhus University",
     "Column4": "DK",
     "Column5": "Denmark",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 54.7,
     "Column11": 144,
     "Column12": 27.6,
     "Column13": 349,
     "Column14": 30.8,
     "Column15": 509,
     "Column16": 63.8,
     "Column17": 177,
     "Column18": 89.8,
     "Column19": 192,
     "Column20": 11.9,
     "Column21": 682,
     "Column22": 96.1,
     "Column23": 54,
     "Column24": 69.5,
     "Column25": 162,
     "Column26": 76.4,
     "Column27": "176=",
     "Column28": 53.3
    },
    {
     "2025 QS World University Rankings": 133,
     "Column2": 137,
     "Column3": "University of Science and Technology of China",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 50,
     "Column11": 162,
     "Column12": 23.7,
     "Column13": 402,
     "Column14": 94.7,
     "Column15": 63,
     "Column16": 97.7,
     "Column17": 38,
     "Column18": 11.6,
     "Column19": "701+",
     "Column20": 5.1,
     "Column21": "701+",
     "Column22": 75.7,
     "Column23": 393,
     "Column24": 31.9,
     "Column25": 395,
     "Column26": 11.9,
     "Column27": 622,
     "Column28": 54.1
    },
    {
     "2025 QS World University Rankings": 146,
     "Column2": 137,
     "Column3": "Universiti Sains Malaysia (USM)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 63.3,
     "Column11": 106,
     "Column12": 69.7,
     "Column13": 103,
     "Column14": 46.4,
     "Column15": 331,
     "Column16": 19.6,
     "Column17": 560,
     "Column18": 24.3,
     "Column19": 545,
     "Column20": 96.4,
     "Column21": 87,
     "Column22": 87.8,
     "Column23": 200,
     "Column24": 20.1,
     "Column25": 561,
     "Column26": 71.2,
     "Column27": "214=",
     "Column28": 52.7
    },
    {
     "2025 QS World University Rankings": 147,
     "Column2": 154,
     "Column3": "Technische Universität Berlin (TU Berlin)",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 55.3,
     "Column11": 142,
     "Column12": 74.3,
     "Column13": 88,
     "Column14": 58,
     "Column15": 252,
     "Column16": 22.1,
     "Column17": 530,
     "Column18": 63.2,
     "Column19": 311,
     "Column20": 74.8,
     "Column21": 187,
     "Column22": 86.1,
     "Column23": 232,
     "Column24": 37.7,
     "Column25": 341,
     "Column26": 35.4,
     "Column27": "400=",
     "Column28": 52.5
    },
    {
     "2025 QS World University Rankings": 148,
     "Column2": 158,
     "Column3": "Universiti Putra Malaysia (UPM)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 61,
     "Column11": 118,
     "Column12": 61.9,
     "Column13": 131,
     "Column14": 58.8,
     "Column15": 250,
     "Column16": 25.7,
     "Column17": 490,
     "Column18": 36.1,
     "Column19": 441,
     "Column20": 92,
     "Column21": 108,
     "Column22": 80.5,
     "Column23": 314,
     "Column24": 17.5,
     "Column25": 607,
     "Column26": 53.5,
     "Column27": "295=",
     "Column28": 52.3
    },
    {
     "2025 QS World University Rankings": 149,
     "Column2": 143,
     "Column3": "King Abdulaziz University (KAU)",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 59.8,
     "Column11": 125,
     "Column12": 61.4,
     "Column13": 133,
     "Column14": 41.6,
     "Column15": 374,
     "Column16": 34.4,
     "Column17": 387,
     "Column18": 89.3,
     "Column19": 195,
     "Column20": 9.5,
     "Column21": "701+",
     "Column22": 93.1,
     "Column23": 118,
     "Column24": 35.9,
     "Column25": 353,
     "Column26": 58.4,
     "Column27": 275,
     "Column28": 52.2
    },
    {
     "2025 QS World University Rankings": 150,
     "Column2": 197,
     "Column3": "Indian Institute of Technology Delhi (IITD)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 54.1,
     "Column11": 147,
     "Column12": 80.9,
     "Column13": 73,
     "Column14": 17.3,
     "Column15": "701+",
     "Column16": 77.4,
     "Column17": 126,
     "Column18": 3.1,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 63.3,
     "Column23": 582,
     "Column24": 35.1,
     "Column25": 361,
     "Column26": 32.7,
     "Column27": "424=",
     "Column28": 52.1
    },
    {
     "2025 QS World University Rankings": 150,
     "Column2": 148,
     "Column3": "University of Bath",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 35.8,
     "Column11": 245,
     "Column12": 67.9,
     "Column13": 109,
     "Column14": 18.3,
     "Column15": "701+",
     "Column16": 52,
     "Column17": 250,
     "Column18": 94.3,
     "Column19": 159,
     "Column20": 80,
     "Column21": 171,
     "Column22": 84.2,
     "Column23": 259,
     "Column24": 35.2,
     "Column25": 360,
     "Column26": 90.4,
     "Column27": 92,
     "Column28": 52.1
    },
    {
     "2025 QS World University Rankings": 152,
     "Column2": 136,
     "Column3": "Michigan State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 59.5,
     "Column11": 127,
     "Column12": 48.6,
     "Column13": 188,
     "Column14": 11.4,
     "Column15": "701+",
     "Column16": 50.3,
     "Column17": 259,
     "Column18": 54.5,
     "Column19": 344,
     "Column20": 12.4,
     "Column21": 668,
     "Column22": 97.5,
     "Column23": 32,
     "Column24": 57.1,
     "Column25": 211,
     "Column26": 96.3,
     "Column27": "41=",
     "Column28": 51.9
    },
    {
     "2025 QS World University Rankings": 152,
     "Column2": 176,
     "Column3": "Nagoya University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 63.2,
     "Column11": 108,
     "Column12": 65.8,
     "Column13": 117,
     "Column14": 84.7,
     "Column15": 107,
     "Column16": 25.4,
     "Column17": 494,
     "Column18": 14.5,
     "Column19": 671,
     "Column20": 19.6,
     "Column21": 550,
     "Column22": 64.8,
     "Column23": 559,
     "Column24": 21.5,
     "Column25": 533,
     "Column26": 75,
     "Column27": "186=",
     "Column28": 51.9
    },
    {
     "2025 QS World University Rankings": 154,
     "Column2": 134,
     "Column3": "Texas A&M University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 61.2,
     "Column11": 116,
     "Column12": 66,
     "Column13": 115,
     "Column14": 4.4,
     "Column15": "701+",
     "Column16": 64.3,
     "Column17": 176,
     "Column18": 21.9,
     "Column19": 569,
     "Column20": 12.1,
     "Column21": 678,
     "Column22": 92.6,
     "Column23": 125,
     "Column24": 44.4,
     "Column25": 287,
     "Column26": 39.5,
     "Column27": "371=",
     "Column28": 51.8
    },
    {
     "2025 QS World University Rankings": 155,
     "Column2": 128,
     "Column3": "University of Geneva",
     "Column4": "CH",
     "Column5": "Switzerland",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 45.5,
     "Column11": 183,
     "Column12": 17.2,
     "Column13": 499,
     "Column14": 40.4,
     "Column15": 387,
     "Column16": 62.4,
     "Column17": 189,
     "Column18": 100,
     "Column19": 51,
     "Column20": 97.6,
     "Column21": 74,
     "Column22": 94.2,
     "Column23": 93,
     "Column24": 49.5,
     "Column25": 258,
     "Column26": 42.3,
     "Column27": "355=",
     "Column28": 51.6
    },
    {
     "2025 QS World University Rankings": 155,
     "Column2": 132,
     "Column3": "University of North Carolina, Chapel Hill",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 61.4,
     "Column11": 114,
     "Column12": 36.5,
     "Column13": 258,
     "Column14": 72.9,
     "Column15": 179,
     "Column16": 47.2,
     "Column17": 288,
     "Column18": 23.5,
     "Column19": 555,
     "Column20": 11.1,
     "Column21": 696,
     "Column22": 91.6,
     "Column23": 147,
     "Column24": 55.2,
     "Column25": 225,
     "Column26": 44.6,
     "Column27": "343=",
     "Column28": 51.6
    },
    {
     "2025 QS World University Rankings": 155,
     "Column2": 151,
     "Column3": "Wageningen University & Research",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 42.1,
     "Column11": 204,
     "Column12": 23.8,
     "Column13": 401,
     "Column14": 98.6,
     "Column15": 43,
     "Column16": 41.3,
     "Column17": 336,
     "Column18": 59.4,
     "Column19": 328,
     "Column20": 81.2,
     "Column21": 169,
     "Column22": 97.9,
     "Column23": 27,
     "Column24": 21.1,
     "Column25": 542,
     "Column26": 92.3,
     "Column27": 75,
     "Column28": 51.6
    },
    {
     "2025 QS World University Rankings": 158,
     "Column2": 176,
     "Column3": "Erasmus University Rotterdam ",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 37,
     "Column11": 233,
     "Column12": 58.2,
     "Column13": 139,
     "Column14": 18.1,
     "Column15": "701+",
     "Column16": 63.3,
     "Column17": 182,
     "Column18": 76,
     "Column19": 256,
     "Column20": 70,
     "Column21": 211,
     "Column22": 85.4,
     "Column23": 240,
     "Column24": 89.3,
     "Column25": 80,
     "Column26": 28.7,
     "Column27": 455,
     "Column28": 51.5
    },
    {
     "2025 QS World University Rankings": 159,
     "Column2": 141,
     "Column3": "Université de Montréal ",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 55.5,
     "Column11": 140,
     "Column12": 32.8,
     "Column13": 289,
     "Column14": 76,
     "Column15": 160,
     "Column16": 19.3,
     "Column17": 566,
     "Column18": 87.6,
     "Column19": 205,
     "Column20": 54.1,
     "Column21": 273,
     "Column22": 84.9,
     "Column23": 247,
     "Column24": 54.2,
     "Column25": 229,
     "Column26": 91.4,
     "Column27": "81=",
     "Column28": 51.3
    },
    {
     "2025 QS World University Rankings": 159,
     "Column2": 139,
     "Column3": "University of Groningen",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 52.3,
     "Column11": 156,
     "Column12": 34.4,
     "Column13": 275,
     "Column14": 49.3,
     "Column15": 318,
     "Column16": 41.2,
     "Column17": 339,
     "Column18": 93.2,
     "Column19": 168,
     "Column20": 83.5,
     "Column21": 161,
     "Column22": 94.1,
     "Column23": 97,
     "Column24": 40,
     "Column25": 319,
     "Column26": 40.1,
     "Column27": 368,
     "Column28": 51.3
    },
    {
     "2025 QS World University Rankings": 161,
     "Column2": 126,
     "Column3": "University of Bern",
     "Column4": "CH",
     "Column5": "Switzerland",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 29.7,
     "Column11": 298,
     "Column12": 16.1,
     "Column13": 530,
     "Column14": 45.9,
     "Column15": 338,
     "Column16": 99.3,
     "Column17": 22,
     "Column18": 100,
     "Column19": 48,
     "Column20": 38,
     "Column21": 370,
     "Column22": 93.8,
     "Column23": 103,
     "Column24": 30.7,
     "Column25": 410,
     "Column26": 52.1,
     "Column27": "305=",
     "Column28": 51.2
    },
    {
     "2025 QS World University Rankings": 162,
     "Column2": 164,
     "Column3": "Hanyang University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 43,
     "Column11": 199,
     "Column12": 78,
     "Column13": 81,
     "Column14": 77.1,
     "Column15": 153,
     "Column16": 28.5,
     "Column17": 458,
     "Column18": 17.4,
     "Column19": 636,
     "Column20": 57.6,
     "Column21": 257,
     "Column22": 63,
     "Column23": 584,
     "Column24": 43.3,
     "Column25": 295,
     "Column26": 85.8,
     "Column27": "121=",
     "Column28": 51.1
    },
    {
     "2025 QS World University Rankings": 163,
     "Column2": 230,
     "Column3": "Al-Farabi Kazakh National University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 54.2,
     "Column11": 146,
     "Column12": 79.6,
     "Column13": 77,
     "Column14": 99.3,
     "Column15": 33,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 49,
     "Column19": 370,
     "Column20": 54.2,
     "Column21": 272,
     "Column22": 64.7,
     "Column23": 561,
     "Column24": 59.6,
     "Column25": 206,
     "Column26": 29.8,
     "Column27": "445=",
     "Column28": 51
    },
    {
     "2025 QS World University Rankings": 164,
     "Column2": 171,
     "Column3": "Complutense University of Madrid",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 68.1,
     "Column11": 98,
     "Column12": 68.1,
     "Column13": 106,
     "Column14": 28.7,
     "Column15": 538,
     "Column16": 7.9,
     "Column17": "701+",
     "Column18": 10.5,
     "Column19": "701+",
     "Column20": 34.3,
     "Column21": 395,
     "Column22": 91.8,
     "Column23": 139,
     "Column24": 96.3,
     "Column25": 41,
     "Column26": 88.9,
     "Column27": "103=",
     "Column28": 50.9
    },
    {
     "2025 QS World University Rankings": 165,
     "Column2": 164,
     "Column3": "Universitat de Barcelona",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 73.7,
     "Column11": 85,
     "Column12": 30.3,
     "Column13": 312,
     "Column14": 12.4,
     "Column15": "701+",
     "Column16": 38.1,
     "Column17": 364,
     "Column18": 23.1,
     "Column19": 559,
     "Column20": 26.3,
     "Column21": 461,
     "Column22": 96.8,
     "Column23": 44,
     "Column24": 71.5,
     "Column25": 153,
     "Column26": 92.2,
     "Column27": "76=",
     "Column28": 50.7
    },
    {
     "2025 QS World University Rankings": 165,
     "Column2": 176,
     "Column3": "University of Liverpool",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 46.1,
     "Column11": 176,
     "Column12": 39.4,
     "Column13": 239,
     "Column14": 37.8,
     "Column15": 422,
     "Column16": 36.6,
     "Column17": 373,
     "Column18": 88.3,
     "Column19": 203,
     "Column20": 85.4,
     "Column21": 150,
     "Column22": 97.2,
     "Column23": 38,
     "Column24": 36.3,
     "Column25": 351,
     "Column26": 95.6,
     "Column27": "50=",
     "Column28": 50.7
    },
    {
     "2025 QS World University Rankings": 167,
     "Column2": 164,
     "Column3": "Kyushu University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 58.3,
     "Column11": 133,
     "Column12": 66.5,
     "Column13": 113,
     "Column14": 78.4,
     "Column15": 149,
     "Column16": 27.9,
     "Column17": 466,
     "Column18": 18.7,
     "Column19": "613=",
     "Column20": 14.1,
     "Column21": 640,
     "Column22": 69.9,
     "Column23": 488,
     "Column24": 15.1,
     "Column25": 657,
     "Column26": 77.6,
     "Column27": "170=",
     "Column28": 50.3
    },
    {
     "2025 QS World University Rankings": 167,
     "Column2": 162,
     "Column3": "University of Wollongong",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 29.5,
     "Column11": 300,
     "Column12": 24.5,
     "Column13": 393,
     "Column14": 17,
     "Column15": "701+",
     "Column16": 94.6,
     "Column17": 52,
     "Column18": 91.8,
     "Column19": 178,
     "Column20": 56.3,
     "Column21": 265,
     "Column22": 89,
     "Column23": 185,
     "Column24": 18.3,
     "Column25": 596,
     "Column26": 93.9,
     "Column27": "62=",
     "Column28": 50.3
    },
    {
     "2025 QS World University Rankings": 169,
     "Column2": 159,
     "Column3": "Ghent University",
     "Column4": "BE",
     "Column5": "Belgium",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 53.2,
     "Column11": 150,
     "Column12": 24.8,
     "Column13": 384,
     "Column14": 78.5,
     "Column15": 148,
     "Column16": 21.5,
     "Column17": 538,
     "Column18": 79.7,
     "Column19": 241,
     "Column20": 21.4,
     "Column21": 522,
     "Column22": 99.5,
     "Column23": 9,
     "Column24": 72.9,
     "Column25": 145,
     "Column26": 98.3,
     "Column27": 21,
     "Column28": 50.1
    },
    {
     "2025 QS World University Rankings": 169,
     "Column2": 153,
     "Column3": "The University of Exeter",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 42.9,
     "Column11": 200,
     "Column12": 45,
     "Column13": 203,
     "Column14": 30.2,
     "Column15": 518,
     "Column16": 42.5,
     "Column17": 324,
     "Column18": 91,
     "Column19": 187,
     "Column20": 72.2,
     "Column21": 202,
     "Column22": 95.1,
     "Column23": 74,
     "Column24": 30.1,
     "Column25": 417,
     "Column26": 97.1,
     "Column27": "30=",
     "Column28": 50.1
    },
    {
     "2025 QS World University Rankings": 171,
     "Column2": 173,
     "Column3": "University of Cape Town",
     "Column4": "ZA",
     "Column5": "South Africa",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 45.2,
     "Column11": 185,
     "Column12": 51.8,
     "Column13": 170,
     "Column14": 13.8,
     "Column15": "701+",
     "Column16": 46.8,
     "Column17": 294,
     "Column18": 56.5,
     "Column19": 335,
     "Column20": 19.2,
     "Column21": 563,
     "Column22": 97.3,
     "Column23": 36,
     "Column24": 95.8,
     "Column25": 46,
     "Column26": 95.6,
     "Column27": "50=",
     "Column28": 50
    },
    {
     "2025 QS World University Rankings": 172,
     "Column2": 169,
     "Column3": "University of Reading",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 32.2,
     "Column11": 273,
     "Column12": 41.7,
     "Column13": 226,
     "Column14": 13.1,
     "Column15": "701+",
     "Column16": 65.7,
     "Column17": 169,
     "Column18": 86.2,
     "Column19": 214,
     "Column20": 78.6,
     "Column21": 177,
     "Column22": 91.7,
     "Column23": 142,
     "Column24": 47.8,
     "Column25": 268,
     "Column26": 94,
     "Column27": 61,
     "Column28": 49.9
    },
    {
     "2025 QS World University Rankings": 173,
     "Column2": 196,
     "Column3": "Hokkaido University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 59,
     "Column11": 128,
     "Column12": 63.3,
     "Column13": 125,
     "Column14": 76.2,
     "Column15": 158,
     "Column16": 26,
     "Column17": 485,
     "Column18": 18.7,
     "Column19": "613=",
     "Column20": 12.1,
     "Column21": 677,
     "Column22": 77.7,
     "Column23": 365,
     "Column24": 15.5,
     "Column25": 645,
     "Column26": 78.1,
     "Column27": 168,
     "Column28": 49.8
    },
    {
     "2025 QS World University Rankings": 174,
     "Column2": 183,
     "Column3": "Curtin University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 33.1,
     "Column11": 266,
     "Column12": 27.7,
     "Column13": 348,
     "Column14": 15.7,
     "Column15": "701+",
     "Column16": 84.7,
     "Column17": 95,
     "Column18": 100,
     "Column19": 49,
     "Column20": 24.3,
     "Column21": 485,
     "Column22": 94.2,
     "Column23": 96,
     "Column24": 31,
     "Column25": 405,
     "Column26": 90.7,
     "Column27": "89=",
     "Column28": 49.3
    },
    {
     "2025 QS World University Rankings": 175,
     "Column2": 149,
     "Column3": "Universitat Autònoma de Barcelona",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 64,
     "Column11": 105,
     "Column12": 35.8,
     "Column13": 263,
     "Column14": 11.5,
     "Column15": "701+",
     "Column16": 59.5,
     "Column17": 206,
     "Column18": 18.5,
     "Column19": 620,
     "Column20": 16.5,
     "Column21": 595,
     "Column22": 93.1,
     "Column23": 116,
     "Column24": 24,
     "Column25": 472,
     "Column26": 86.7,
     "Column27": "114=",
     "Column28": 49.2
    },
    {
     "2025 QS World University Rankings": 176,
     "Column2": 189,
     "Column3": "McMaster University",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 39.5,
     "Column11": 215,
     "Column12": 34.2,
     "Column13": 276,
     "Column14": 63.8,
     "Column15": 215,
     "Column16": 35.6,
     "Column17": 380,
     "Column18": 96.2,
     "Column19": 136,
     "Column20": 56.7,
     "Column21": 263,
     "Column22": 82.6,
     "Column23": 284,
     "Column24": 49.9,
     "Column25": 255,
     "Column26": 91.9,
     "Column27": "78=",
     "Column28": 49
    },
    {
     "2025 QS World University Rankings": 176,
     "Column2": 154,
     "Column3": "Washington University in St. Louis",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 36.3,
     "Column11": 241,
     "Column12": 12,
     "Column13": "601+",
     "Column14": 99.7,
     "Column15": 29,
     "Column16": 47.7,
     "Column17": 283,
     "Column18": 69.5,
     "Column19": 285,
     "Column20": 72.4,
     "Column21": 201,
     "Column22": 83.6,
     "Column23": 270,
     "Column24": 31.9,
     "Column25": 396,
     "Column26": 86.7,
     "Column27": "114=",
     "Column28": 49
    },
    {
     "2025 QS World University Rankings": 178,
     "Column2": 163,
     "Column3": "University of California, Santa Barbara (UCSB)",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 52.6,
     "Column11": 154,
     "Column12": 21.3,
     "Column13": 432,
     "Column14": 7.7,
     "Column15": "701+",
     "Column16": 97.8,
     "Column17": 37,
     "Column18": 75.4,
     "Column19": 261,
     "Column20": 17.2,
     "Column21": 583,
     "Column22": 71.2,
     "Column23": 466,
     "Column24": 7.9,
     "Column25": "701+",
     "Column26": 28.4,
     "Column27": "456=",
     "Column28": 48.9
    },
    {
     "2025 QS World University Rankings": 179,
     "Column2": 173,
     "Column3": "The University of Newcastle, Australia (UON)",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 28.3,
     "Column11": 312,
     "Column12": 24.5,
     "Column13": 392,
     "Column14": 28.7,
     "Column15": 537,
     "Column16": 91.7,
     "Column17": 70,
     "Column18": 99.9,
     "Column19": 89,
     "Column20": 24.2,
     "Column21": 487,
     "Column22": 82,
     "Column23": 289,
     "Column24": 14.8,
     "Column25": 670,
     "Column26": 92.4,
     "Column27": 74,
     "Column28": 48.7
    },
    {
     "2025 QS World University Rankings": 179,
     "Column2": 198,
     "Column3": "Universidad de los Andes",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 67.7,
     "Column11": 100,
     "Column12": 88.4,
     "Column13": 54,
     "Column14": 14.2,
     "Column15": "701+",
     "Column16": 5.9,
     "Column17": "701+",
     "Column18": 22.8,
     "Column19": 560,
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 55.2,
     "Column23": 692,
     "Column24": 98.2,
     "Column25": 26,
     "Column26": 80,
     "Column27": 155,
     "Column28": 48.7
    },
    {
     "2025 QS World University Rankings": 181,
     "Column2": 188,
     "Column3": "Universiti Teknologi Malaysia ",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 48.6,
     "Column11": 166,
     "Column12": 59.6,
     "Column13": 134,
     "Column14": 46,
     "Column15": 337,
     "Column16": 40.5,
     "Column17": 347,
     "Column18": 14.1,
     "Column19": 683,
     "Column20": 86.9,
     "Column21": 142,
     "Column22": 82.9,
     "Column23": 279,
     "Column24": 18,
     "Column25": 600,
     "Column26": 52.1,
     "Column27": "305=",
     "Column28": 48.5
    },
    {
     "2025 QS World University Rankings": 181,
     "Column2": 199,
     "Column3": "Waseda University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 60.7,
     "Column11": 122,
     "Column12": 96.1,
     "Column13": 30,
     "Column14": 23.5,
     "Column15": 616,
     "Column16": 3.8,
     "Column17": "701+",
     "Column18": 27.9,
     "Column19": 507,
     "Column20": 28,
     "Column21": 445,
     "Column22": 66.4,
     "Column23": 533,
     "Column24": 83.7,
     "Column25": 106,
     "Column26": 56.1,
     "Column27": "282=",
     "Column28": 48.5
    },
    {
     "2025 QS World University Rankings": 183,
     "Column2": 310,
     "Column3": "Hamad Bin Khalifa University",
     "Column4": "QA",
     "Column5": "Qatar",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.9,
     "Column11": "601+",
     "Column12": 4.7,
     "Column13": "601+",
     "Column14": 99.9,
     "Column15": 20,
     "Column16": 100,
     "Column17": 8,
     "Column18": 100,
     "Column19": 9,
     "Column20": 100,
     "Column21": 10,
     "Column22": 74.3,
     "Column23": 417,
     "Column24": 6,
     "Column25": "701+",
     "Column26": 2.6,
     "Column27": "701+",
     "Column28": 48.3
    },
    {
     "2025 QS World University Rankings": 184,
     "Column2": 167,
     "Column3": "University of York",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 42.9,
     "Column11": 201,
     "Column12": 30.1,
     "Column13": 313,
     "Column14": 43,
     "Column15": 364,
     "Column16": 40.1,
     "Column17": 353,
     "Column18": 87.8,
     "Column19": 204,
     "Column20": 75.6,
     "Column21": 184,
     "Column22": 92.5,
     "Column23": 128,
     "Column24": 23.5,
     "Column25": 480,
     "Column26": 97.4,
     "Column27": 29,
     "Column28": 48.2
    },
    {
     "2025 QS World University Rankings": 185,
     "Column2": 184,
     "Column3": "Tecnológico de Monterrey",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 53.8,
     "Column11": 149,
     "Column12": 91.4,
     "Column13": 47,
     "Column14": 62.5,
     "Column15": 223,
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 20.8,
     "Column19": 589,
     "Column20": 10.6,
     "Column21": "701+",
     "Column22": 56.6,
     "Column23": 670,
     "Column24": 96.1,
     "Column25": 43,
     "Column26": 54.4,
     "Column27": "291=",
     "Column28": 48.1
    },
    {
     "2025 QS World University Rankings": 186,
     "Column2": 154,
     "Column3": "Cardiff University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 46.8,
     "Column11": 171,
     "Column12": 43.2,
     "Column13": 213,
     "Column14": 36.1,
     "Column15": 441,
     "Column16": 30,
     "Column17": 437,
     "Column18": 82.8,
     "Column19": 229,
     "Column20": 67.7,
     "Column21": 219,
     "Column22": 92.3,
     "Column23": 132,
     "Column24": 18.9,
     "Column25": 584,
     "Column26": 96,
     "Column27": "46=",
     "Column28": 47.6
    },
    {
     "2025 QS World University Rankings": 187,
     "Column2": 184,
     "Column3": "École Normale Supérieure de Lyon",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 29.8,
     "Column11": 296,
     "Column12": 16.3,
     "Column13": 522,
     "Column14": 97,
     "Column15": 49,
     "Column16": 93.5,
     "Column17": 59,
     "Column18": 59,
     "Column19": 329,
     "Column20": 11.7,
     "Column21": 684,
     "Column22": 70.3,
     "Column23": 482,
     "Column24": 14,
     "Column25": 694,
     "Column26": 6.6,
     "Column27": "701+",
     "Column28": 47.5
    },
    {
     "2025 QS World University Rankings": 188,
     "Column2": 214,
     "Column3": "Keio University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 53,
     "Column11": 151,
     "Column12": 91.6,
     "Column13": 46,
     "Column14": 57.2,
     "Column15": 257,
     "Column16": 5.1,
     "Column17": "701+",
     "Column18": 9.4,
     "Column19": "701+",
     "Column20": 10.2,
     "Column21": "701+",
     "Column22": 57.5,
     "Column23": 658,
     "Column24": 91.4,
     "Column25": 69,
     "Column26": 59,
     "Column27": "271=",
     "Column28": 47.4
    },
    {
     "2025 QS World University Rankings": 189,
     "Column2": 203,
     "Column3": "University of Ottawa",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 26.2,
     "Column11": 340,
     "Column12": 23.3,
     "Column13": 406,
     "Column14": 3.5,
     "Column15": "701+",
     "Column16": 81.3,
     "Column17": 106,
     "Column18": 91.7,
     "Column19": 179,
     "Column20": 77.8,
     "Column21": 181,
     "Column22": 93,
     "Column23": 119,
     "Column24": 39.8,
     "Column25": 323,
     "Column26": 92.9,
     "Column27": 72,
     "Column28": 47.3
    },
    {
     "2025 QS World University Rankings": 190,
     "Column2": 184,
     "Column3": "Technische Universität Wien",
     "Column4": "AT",
     "Column5": "Austria",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 33.9,
     "Column11": 258,
     "Column12": 50.4,
     "Column13": 178,
     "Column14": 12.5,
     "Column15": "701+",
     "Column16": 73.8,
     "Column17": 139,
     "Column18": 85.5,
     "Column19": 218,
     "Column20": 90.4,
     "Column21": 120,
     "Column22": 71.6,
     "Column23": 457,
     "Column24": 19.1,
     "Column25": 577,
     "Column26": 11.3,
     "Column27": "636=",
     "Column28": 47.2
    },
    {
     "2025 QS World University Rankings": 191,
     "Column2": 205,
     "Column3": "Universität Hamburg",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 50.7,
     "Column11": 159,
     "Column12": 35.7,
     "Column13": 264,
     "Column14": 16.5,
     "Column15": "701+",
     "Column16": 54.7,
     "Column17": 233,
     "Column18": 40.2,
     "Column19": 418,
     "Column20": 20.4,
     "Column21": 532,
     "Column22": 93.4,
     "Column23": 109,
     "Column24": 42.8,
     "Column25": 300,
     "Column26": 90.6,
     "Column27": 91,
     "Column28": 47.1
    },
    {
     "2025 QS World University Rankings": 194,
     "Column2": 194,
     "Column3": "Wuhan University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 39.2,
     "Column11": 218,
     "Column12": 40.5,
     "Column13": 233,
     "Column14": 14,
     "Column15": "701+",
     "Column16": 91.9,
     "Column17": 69,
     "Column18": 39.2,
     "Column19": 423,
     "Column20": 4.9,
     "Column21": "701+",
     "Column22": 78.2,
     "Column23": 356,
     "Column24": 46.5,
     "Column25": 275,
     "Column26": 22.2,
     "Column27": "513=",
     "Column28": 46.7
    },
    {
     "2025 QS World University Rankings": 193,
     "Column2": 209,
     "Column3": "Queen's University at Kingston",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 40.8,
     "Column11": 209,
     "Column12": 56.7,
     "Column13": 144,
     "Column14": 5.4,
     "Column15": "701+",
     "Column16": 42.6,
     "Column17": 322,
     "Column18": 69.2,
     "Column19": 287,
     "Column20": 26.6,
     "Column21": 456,
     "Column22": 71.2,
     "Column23": 467,
     "Column24": 87.1,
     "Column25": 93,
     "Column26": 93.8,
     "Column27": "64=",
     "Column28": 46.8
    },
    {
     "2025 QS World University Rankings": 194,
     "Column2": 187,
     "Column3": "University of Gothenburg",
     "Column4": "SE",
     "Column5": "Sweden",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 35.4,
     "Column11": 247,
     "Column12": 18.3,
     "Column13": 481,
     "Column14": 60.9,
     "Column15": 235,
     "Column16": 33.6,
     "Column17": 394,
     "Column18": 90,
     "Column19": 191,
     "Column20": 78.2,
     "Column21": 180,
     "Column22": 94.2,
     "Column23": 91,
     "Column24": 63.1,
     "Column25": 189,
     "Column26": 92.8,
     "Column27": 73,
     "Column28": 46.7
    },
    {
     "2025 QS World University Rankings": 271,
     "Column2": 272,
     "Column3": "Beijing Normal University ",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 33.9,
     "Column11": 259,
     "Column12": 10.3,
     "Column13": "601+",
     "Column14": 24.2,
     "Column15": 605,
     "Column16": 92.6,
     "Column17": 65,
     "Column18": 6.6,
     "Column19": "701+",
     "Column20": 21.3,
     "Column21": 524,
     "Column22": 70.8,
     "Column23": 471,
     "Column24": 10.5,
     "Column25": "701+",
     "Column26": 19.7,
     "Column27": "537=",
     "Column28": 38.5
    },
    {
     "2025 QS World University Rankings": 196,
     "Column2": 166,
     "Column3": "Emory University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 25.2,
     "Column11": 349,
     "Column12": 19,
     "Column13": 469,
     "Column14": 93.8,
     "Column15": 70,
     "Column16": 78.4,
     "Column17": 120,
     "Column18": 52.2,
     "Column19": 355,
     "Column20": 33.9,
     "Column21": 399,
     "Column22": 85.6,
     "Column23": 237,
     "Column24": 22.8,
     "Column25": 502,
     "Column26": 37,
     "Column27": "391=",
     "Column28": 46.6
    },
    {
     "2025 QS World University Rankings": 197,
     "Column2": 233,
     "Column3": "Deakin University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 31.3,
     "Column11": 282,
     "Column12": 23.9,
     "Column13": 400,
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 73.6,
     "Column17": 140,
     "Column18": 96.4,
     "Column19": 135,
     "Column20": 63.8,
     "Column21": 236,
     "Column22": 82.7,
     "Column23": 280,
     "Column24": 36.8,
     "Column25": 347,
     "Column26": 93.7,
     "Column27": 66,
     "Column28": 46.4
    },
    {
     "2025 QS World University Rankings": 198,
     "Column2": 199,
     "Column3": "Universidad Autónoma de Madrid",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 61,
     "Column11": 119,
     "Column12": 64.3,
     "Column13": 123,
     "Column14": 29.1,
     "Column15": 534,
     "Column16": 21,
     "Column17": 546,
     "Column18": 7.7,
     "Column19": "701+",
     "Column20": 10.4,
     "Column21": "701+",
     "Column22": 92,
     "Column23": 136,
     "Column24": 43.1,
     "Column25": 297,
     "Column26": 79.6,
     "Column27": "156=",
     "Column28": 46.3
    },
    {
     "2025 QS World University Rankings": 198,
     "Column2": 182,
     "Column3": "University of Calgary",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 37.9,
     "Column11": 227,
     "Column12": 44.5,
     "Column13": 207,
     "Column14": 18.1,
     "Column15": "701+",
     "Column16": 40.3,
     "Column17": 350,
     "Column18": 77.7,
     "Column19": 246,
     "Column20": 66.2,
     "Column21": 225,
     "Column22": 85.2,
     "Column23": 243,
     "Column24": 53.3,
     "Column25": 236,
     "Column26": 93.3,
     "Column27": "68=",
     "Column28": 46.3
    },
    {
     "2025 QS World University Rankings": 200,
     "Column2": 179,
     "Column3": "Arizona State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 48.6,
     "Column11": 165,
     "Column12": 44.1,
     "Column13": 209,
     "Column14": 7.4,
     "Column15": "701+",
     "Column16": 47.1,
     "Column17": 289,
     "Column18": 52.1,
     "Column19": 357,
     "Column20": 37.2,
     "Column21": 378,
     "Column22": 95.7,
     "Column23": 66,
     "Column24": 23.6,
     "Column25": 479,
     "Column26": 96.3,
     "Column27": "41=",
     "Column28": 46.2
    },
    {
     "2025 QS World University Rankings": 200,
     "Column2": 203,
     "Column3": "King Saud University",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 39.4,
     "Column11": 216,
     "Column12": 28.7,
     "Column13": 332,
     "Column14": 54.3,
     "Column15": 281,
     "Column16": 40,
     "Column17": 354,
     "Column18": 99.4,
     "Column19": 98,
     "Column20": 15,
     "Column21": 622,
     "Column22": 95.1,
     "Column23": 75,
     "Column24": 99,
     "Column25": 17,
     "Column26": 33,
     "Column27": "420=",
     "Column28": 46.2
    },
    {
     "2025 QS World University Rankings": 202,
     "Column2": 230,
     "Column3": "Khalifa University of Science and Technology",
     "Column4": "AE",
     "Column5": "United Arab Emirates",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 22.7,
     "Column11": 393,
     "Column12": 21,
     "Column13": 437,
     "Column14": 80,
     "Column15": 137,
     "Column16": 60.7,
     "Column17": 199,
     "Column18": 100,
     "Column19": 12,
     "Column20": 78.9,
     "Column21": 174,
     "Column22": 78.8,
     "Column23": 341,
     "Column24": 9.3,
     "Column25": "701+",
     "Column26": 61.6,
     "Column27": "263=",
     "Column28": 46.1
    },
    {
     "2025 QS World University Rankings": 203,
     "Column2": 180,
     "Column3": "Université catholique de Louvain (UCLouvain)",
     "Column4": "BE",
     "Column5": "Belgium",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 48.4,
     "Column11": 168,
     "Column12": 25.9,
     "Column13": 367,
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 55,
     "Column17": 230,
     "Column18": 91,
     "Column19": 186,
     "Column20": 47.6,
     "Column21": 306,
     "Column22": 89.9,
     "Column23": 169,
     "Column24": 76.4,
     "Column25": 135,
     "Column26": 27.1,
     "Column27": "471=",
     "Column28": 46
    },
    {
     "2025 QS World University Rankings": 203,
     "Column2": 195,
     "Column3": "University of Minnesota Twin Cities",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 56.8,
     "Column11": 137,
     "Column12": 33.1,
     "Column13": 284,
     "Column14": 32.4,
     "Column15": 486,
     "Column16": 41.9,
     "Column17": 327,
     "Column18": 7.7,
     "Column19": "701+",
     "Column20": 8.7,
     "Column21": "701+",
     "Column22": 91.7,
     "Column23": 145,
     "Column24": 52.5,
     "Column25": 241,
     "Column26": 96.5,
     "Column27": "37=",
     "Column28": 46
    },
    {
     "2025 QS World University Rankings": 205,
     "Column2": 192,
     "Column3": "Ecole des Ponts ParisTech",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.9,
     "Column11": "601+",
     "Column12": 58.5,
     "Column13": 138,
     "Column14": 99,
     "Column15": 38,
     "Column16": 62.6,
     "Column17": 186,
     "Column18": 43.5,
     "Column19": 404,
     "Column20": 96.5,
     "Column21": 84,
     "Column22": 37.3,
     "Column23": "701+",
     "Column24": 59.5,
     "Column25": 207,
     "Column26": 10.8,
     "Column27": "650=",
     "Column28": 45.8
    },
    {
     "2025 QS World University Rankings": 206,
     "Column2": 202,
     "Column3": "Queen's University Belfast",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 35.4,
     "Column11": 249,
     "Column12": 33.6,
     "Column13": 279,
     "Column14": 28.6,
     "Column15": 543,
     "Column16": 40.6,
     "Column17": 345,
     "Column18": 100,
     "Column19": 74,
     "Column20": 98.2,
     "Column21": 67,
     "Column22": 92.8,
     "Column23": 124,
     "Column24": 9.9,
     "Column25": "701+",
     "Column26": 88.9,
     "Column27": "103=",
     "Column28": 45.7
    },
    {
     "2025 QS World University Rankings": 206,
     "Column2": 237,
     "Column3": "Universitas Indonesia",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 53.8,
     "Column11": 148,
     "Column12": 79.2,
     "Column13": 79,
     "Column14": 55.7,
     "Column15": 268,
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 88.3,
     "Column19": 202,
     "Column20": 7.1,
     "Column21": "701+",
     "Column22": 37.2,
     "Column23": "701+",
     "Column24": 77.3,
     "Column25": 131,
     "Column26": 32.7,
     "Column27": "424=",
     "Column28": 45.7
    },
    {
     "2025 QS World University Rankings": 208,
     "Column2": 151,
     "Column3": "The Ohio State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 58.6,
     "Column11": 130,
     "Column12": 50.4,
     "Column13": 176,
     "Column14": 39.5,
     "Column15": 392,
     "Column16": 31.2,
     "Column17": 423,
     "Column18": 13.4,
     "Column19": 696,
     "Column20": 10.6,
     "Column21": "701+",
     "Column22": 94.6,
     "Column23": 87,
     "Column24": 45.3,
     "Column25": 281,
     "Column26": 50.8,
     "Column27": "315=",
     "Column28": 45.6
    },
    {
     "2025 QS World University Rankings": 209,
     "Column2": 215,
     "Column3": "Tel Aviv University",
     "Column4": "IL",
     "Column5": "Israel",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 32,
     "Column11": 275,
     "Column12": 18.6,
     "Column13": 474,
     "Column14": 4.2,
     "Column15": "701+",
     "Column16": 99.4,
     "Column17": 20,
     "Column18": 39.7,
     "Column19": 419,
     "Column20": 7,
     "Column21": "701+",
     "Column22": 77.9,
     "Column23": 362,
     "Column24": 88.4,
     "Column25": 85,
     "Column26": 46.2,
     "Column27": "334=",
     "Column28": 45.2
    },
    {
     "2025 QS World University Rankings": 210,
     "Column2": 233,
     "Column3": "National Tsing Hua University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 54.8,
     "Column11": 143,
     "Column12": 71.2,
     "Column13": 99,
     "Column14": 28,
     "Column15": 549,
     "Column16": 49.6,
     "Column17": 268,
     "Column18": 9.5,
     "Column19": "701+",
     "Column20": 11.1,
     "Column21": 695,
     "Column22": 45,
     "Column23": "701+",
     "Column24": 16.3,
     "Column25": 629,
     "Column26": 32.1,
     "Column27": "428=",
     "Column28": 45.1
    },
    {
     "2025 QS World University Rankings": 211,
     "Column2": 225,
     "Column3": "Indian Institute of Science",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 45.7,
     "Column11": 181,
     "Column12": 26.3,
     "Column13": 362,
     "Column14": 37.9,
     "Column15": 419,
     "Column16": 99.9,
     "Column17": 11,
     "Column18": 3.7,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 39.1,
     "Column23": "701+",
     "Column24": 13.1,
     "Column25": "701+",
     "Column26": 23.7,
     "Column27": "500=",
     "Column28": 45
    },
    {
     "2025 QS World University Rankings": 212,
     "Column2": 192,
     "Column3": "Albert-Ludwigs-Universitaet Freiburg",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 43.1,
     "Column11": 198,
     "Column12": 24.5,
     "Column13": 394,
     "Column14": 24.6,
     "Column15": 597,
     "Column16": 52.2,
     "Column17": 248,
     "Column18": 67.6,
     "Column19": 290,
     "Column20": 33.7,
     "Column21": 401,
     "Column22": 92.3,
     "Column23": 131,
     "Column24": 32.5,
     "Column25": 391,
     "Column26": 90,
     "Column27": "94=",
     "Column28": 44.8
    },
    {
     "2025 QS World University Rankings": 213,
     "Column2": 189,
     "Column3": "Queensland University of Technology (QUT)",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 39.8,
     "Column11": 214,
     "Column12": 33.1,
     "Column13": 285,
     "Column14": 8.1,
     "Column15": "701+",
     "Column16": 59.9,
     "Column17": 202,
     "Column18": 97.9,
     "Column19": 122,
     "Column20": 20.7,
     "Column21": 530,
     "Column22": 83.4,
     "Column23": 274,
     "Column24": 19,
     "Column25": 581,
     "Column26": 89.4,
     "Column27": 100,
     "Column28": 44.7
    },
    {
     "2025 QS World University Rankings": 214,
     "Column2": 206,
     "Column3": "University of Otago",
     "Column4": "NZ",
     "Column5": "New Zealand",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 45,
     "Column11": 187,
     "Column12": 24.6,
     "Column13": 388,
     "Column14": 12.8,
     "Column15": "701+",
     "Column16": 38.8,
     "Column17": 361,
     "Column18": 100,
     "Column19": 50,
     "Column20": 37.6,
     "Column21": 375,
     "Column22": 79.5,
     "Column23": 332,
     "Column24": 59.1,
     "Column25": 208,
     "Column26": 96,
     "Column27": 48,
     "Column28": 44.4
    },
    {
     "2025 QS World University Rankings": 215,
     "Column2": 228,
     "Column3": "National Cheng Kung University (NCKU)",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 51.5,
     "Column11": 157,
     "Column12": 74,
     "Column13": 90,
     "Column14": 33.4,
     "Column15": 472,
     "Column16": 20.3,
     "Column17": 554,
     "Column18": 16.3,
     "Column19": 646,
     "Column20": 13.9,
     "Column21": 642,
     "Column22": 58.7,
     "Column23": 648,
     "Column24": 42.4,
     "Column25": 306,
     "Column26": 83.8,
     "Column27": 131,
     "Column28": 44.2
    },
    {
     "2025 QS World University Rankings": 215,
     "Column2": 168,
     "Column3": "University of Florida",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 46.7,
     "Column11": 172,
     "Column12": 42.3,
     "Column13": 220,
     "Column14": 62.9,
     "Column15": 220,
     "Column16": 32.1,
     "Column17": 411,
     "Column18": 17.8,
     "Column19": 630,
     "Column20": 10.3,
     "Column21": "701+",
     "Column22": 98.3,
     "Column23": 21,
     "Column24": 44.5,
     "Column25": 286,
     "Column26": 60.6,
     "Column27": "266=",
     "Column28": 44.2
    },
    {
     "2025 QS World University Rankings": 217,
     "Column2": 242,
     "Column3": "La Trobe University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.6,
     "Column11": 370,
     "Column12": 19.8,
     "Column13": 457,
     "Column14": 8,
     "Column15": "701+",
     "Column16": 77.7,
     "Column17": 123,
     "Column18": 92.6,
     "Column19": 172,
     "Column20": 95,
     "Column21": 97,
     "Column22": 78.6,
     "Column23": 345,
     "Column24": 19.6,
     "Column25": 570,
     "Column26": 78.4,
     "Column27": "166=",
     "Column28": 44.1
    },
    {
     "2025 QS World University Rankings": 218,
     "Column2": 169,
     "Column3": "University of Maryland, College Park",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 48.6,
     "Column11": 167,
     "Column12": 29.4,
     "Column13": 317,
     "Column14": 32.5,
     "Column15": 484,
     "Column16": 55,
     "Column17": 231,
     "Column18": 20.9,
     "Column19": 588,
     "Column20": 16.2,
     "Column21": 603,
     "Column22": 92.3,
     "Column23": 133,
     "Column24": 41.2,
     "Column25": 311,
     "Column26": 54.4,
     "Column27": "291=",
     "Column28": 44
    },
    {
     "2025 QS World University Rankings": 219,
     "Column2": 217,
     "Column3": "National Yang Ming Chiao Tung University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 32.9,
     "Column11": 267,
     "Column12": 62.7,
     "Column13": 128,
     "Column14": 51.1,
     "Column15": 300,
     "Column16": 52.7,
     "Column17": 245,
     "Column18": 19.6,
     "Column19": 607,
     "Column20": 19.2,
     "Column21": 564,
     "Column22": 53.7,
     "Column23": "701+",
     "Column24": 58.3,
     "Column25": 210,
     "Column26": 38.8,
     "Column27": "379=",
     "Column28": 43.9
    },
    {
     "2025 QS World University Rankings": 219,
     "Column2": 226,
     "Column3": "Universidad Nacional de Colombia",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 71,
     "Column11": 88,
     "Column12": 89.9,
     "Column13": 53,
     "Column14": 5.4,
     "Column15": "701+",
     "Column16": 3.4,
     "Column17": "701+",
     "Column18": 5.6,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 65.9,
     "Column23": 543,
     "Column24": 42.6,
     "Column25": 302,
     "Column26": 53.1,
     "Column27": "298=",
     "Column28": 43.9
    },
    {
     "2025 QS World University Rankings": 221,
     "Column2": 207,
     "Column3": "Vrije Universiteit Amsterdam",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 39.4,
     "Column11": 217,
     "Column12": 17.8,
     "Column13": 494,
     "Column14": 21.3,
     "Column15": 650,
     "Column16": 63.7,
     "Column17": 179,
     "Column18": 67.3,
     "Column19": 293,
     "Column20": 47.4,
     "Column21": 310,
     "Column22": 89,
     "Column23": 184,
     "Column24": 45.1,
     "Column25": 282,
     "Column26": 50.8,
     "Column27": "315=",
     "Column28": 43.8
    },
    {
     "2025 QS World University Rankings": 222,
     "Column2": 213,
     "Column3": "Eberhard Karls Universität Tübingen",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 46,
     "Column11": 177,
     "Column12": 30.7,
     "Column13": 307,
     "Column14": 87.1,
     "Column15": 98,
     "Column16": 20.5,
     "Column17": 550,
     "Column18": 76.1,
     "Column19": 255,
     "Column20": 26.2,
     "Column21": 463,
     "Column22": 93.8,
     "Column23": 102,
     "Column24": 30.9,
     "Column25": 408,
     "Column26": 33,
     "Column27": "420=",
     "Column28": 43.7
    },
    {
     "2025 QS World University Rankings": 222,
     "Column2": 271,
     "Column3": "Indian Institute of Technology Kharagpur (IIT-KGP)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 34,
     "Column11": 257,
     "Column12": 51.1,
     "Column13": 173,
     "Column14": 9,
     "Column15": "701+",
     "Column16": 90.2,
     "Column17": 76,
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 69.3,
     "Column23": 492,
     "Column24": 33,
     "Column25": 386,
     "Column26": 44.3,
     "Column27": "348=",
     "Column28": 43.7
    },
    {
     "2025 QS World University Rankings": 224,
     "Column2": 229,
     "Column3": "Friedrich-Alexander-Universität Erlangen-Nürnberg",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 25.8,
     "Column11": 345,
     "Column12": 27.7,
     "Column13": 346,
     "Column14": 3.2,
     "Column15": "701+",
     "Column16": 94.9,
     "Column17": 50,
     "Column18": 27.2,
     "Column19": 516,
     "Column20": 48.8,
     "Column21": 300,
     "Column22": 87.1,
     "Column23": 213,
     "Column24": 19.6,
     "Column25": 568,
     "Column26": 74.5,
     "Column27": "193=",
     "Column28": 43.6
    },
    {
     "2025 QS World University Rankings": 224,
     "Column2": 212,
     "Column3": "Loughborough University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 29.7,
     "Column11": 299,
     "Column12": 55.3,
     "Column13": 151,
     "Column14": 23.8,
     "Column15": 613,
     "Column16": 32.4,
     "Column17": 406,
     "Column18": 87.1,
     "Column19": 212,
     "Column20": 68.6,
     "Column21": 216,
     "Column22": 87.1,
     "Column23": 214,
     "Column24": 23.2,
     "Column25": 488,
     "Column26": 93.8,
     "Column27": "64=",
     "Column28": 43.6
    },
    {
     "2025 QS World University Rankings": 224,
     "Column2": 220,
     "Column3": "University of Lausanne",
     "Column4": "CH",
     "Column5": "Switzerland",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 28.4,
     "Column11": 310,
     "Column12": 18.8,
     "Column13": 471,
     "Column14": 36.8,
     "Column15": 435,
     "Column16": 61,
     "Column17": 195,
     "Column18": 100,
     "Column19": 67,
     "Column20": 54.2,
     "Column21": 271,
     "Column22": 79.8,
     "Column23": 328,
     "Column24": 51.8,
     "Column25": 244,
     "Column26": 51.2,
     "Column27": "313=",
     "Column28": 43.6
    },
    {
     "2025 QS World University Rankings": 227,
     "Column2": 285,
     "Column3": "Indian Institute of Technology Madras (IITM)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 42.4,
     "Column11": 203,
     "Column12": 63.2,
     "Column13": 127,
     "Column14": 20.3,
     "Column15": 663,
     "Column16": 70.3,
     "Column17": 154,
     "Column18": 3.9,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 37.5,
     "Column23": "701+",
     "Column24": 27,
     "Column25": 444,
     "Column26": 44.6,
     "Column27": "343=",
     "Column28": 43.5
    },
    {
     "2025 QS World University Rankings": 227,
     "Column2": 239,
     "Column3": "Rheinische Friedrich-Wilhelms-Universität Bonn",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 52.8,
     "Column11": 153,
     "Column12": 24.1,
     "Column13": 399,
     "Column14": 62.7,
     "Column15": 221,
     "Column16": 17.2,
     "Column17": 598,
     "Column18": 44.3,
     "Column19": 399,
     "Column20": 38.9,
     "Column21": 361,
     "Column22": 94.7,
     "Column23": 83,
     "Column24": 35.4,
     "Column25": 357,
     "Column26": 83.2,
     "Column27": 138,
     "Column28": 43.5
    },
    {
     "2025 QS World University Rankings": 229,
     "Column2": 211,
     "Column3": "Chulalongkorn University",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 68.2,
     "Column11": 97,
     "Column12": 35.9,
     "Column13": 261,
     "Column14": 31.7,
     "Column15": 499,
     "Column16": 7.5,
     "Column17": "701+",
     "Column18": 18.7,
     "Column19": 617,
     "Column20": 3,
     "Column21": "701+",
     "Column22": 79.6,
     "Column23": 331,
     "Column24": 93.6,
     "Column25": 60,
     "Column26": 73.2,
     "Column27": "198=",
     "Column28": 43.4
    },
    {
     "2025 QS World University Rankings": 230,
     "Column2": 256,
     "Column3": "Maastricht University ",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23,
     "Column11": 389,
     "Column12": 27.8,
     "Column13": 345,
     "Column14": 13.2,
     "Column15": "701+",
     "Column16": 62.8,
     "Column17": 185,
     "Column18": 99.4,
     "Column19": 97,
     "Column20": 100,
     "Column21": 20,
     "Column22": 80.5,
     "Column23": 312,
     "Column24": 38.2,
     "Column25": 337,
     "Column26": 52.5,
     "Column27": "302=",
     "Column28": 43
    },
    {
     "2025 QS World University Rankings": 230,
     "Column2": 189,
     "Column3": "Universite libre de Bruxelles",
     "Column4": "BE",
     "Column5": "Belgium",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 33.3,
     "Column11": 263,
     "Column12": 34.5,
     "Column13": 273,
     "Column14": 3,
     "Column15": "701+",
     "Column16": 49.9,
     "Column17": 263,
     "Column18": 98.7,
     "Column19": 110,
     "Column20": 84.4,
     "Column21": 155,
     "Column22": 87.5,
     "Column23": 203,
     "Column24": 65.5,
     "Column25": 179,
     "Column26": 25.2,
     "Column27": 490,
     "Column28": 43
    },
    {
     "2025 QS World University Rankings": 232,
     "Column2": 220,
     "Column3": "Universidade Estadual de Campinas (Unicamp)",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 71.8,
     "Column11": 87,
     "Column12": 40.3,
     "Column13": 236,
     "Column14": 14.7,
     "Column15": "701+",
     "Column16": 24.3,
     "Column17": 506,
     "Column18": 10.7,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 85.4,
     "Column23": 241,
     "Column24": 25.1,
     "Column25": 465,
     "Column26": 65.9,
     "Column27": "236=",
     "Column28": 42.9
    },
    {
     "2025 QS World University Rankings": 233,
     "Column2": 210,
     "Column3": "University of Twente",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.7,
     "Column11": 424,
     "Column12": 32,
     "Column13": 294,
     "Column14": 43.3,
     "Column15": 362,
     "Column16": 61.3,
     "Column17": 193,
     "Column18": 100,
     "Column19": 83,
     "Column20": 90.4,
     "Column21": 119,
     "Column22": 87.6,
     "Column23": 202,
     "Column24": 20,
     "Column25": 562,
     "Column26": 13.8,
     "Column27": 603,
     "Column28": 42.7
    },
    {
     "2025 QS World University Rankings": 234,
     "Column2": 246,
     "Column3": "Technische Universität Dresden",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 36.3,
     "Column11": 240,
     "Column12": 42.5,
     "Column13": 216,
     "Column14": 80.3,
     "Column15": 134,
     "Column16": 28.5,
     "Column17": 455,
     "Column18": 35.8,
     "Column19": 445,
     "Column20": 27.6,
     "Column21": 450,
     "Column22": 93.8,
     "Column23": 104,
     "Column24": 8.8,
     "Column25": "701+",
     "Column26": 75.8,
     "Column27": "181=",
     "Column28": 42.6
    },
    {
     "2025 QS World University Rankings": 235,
     "Column2": 250,
     "Column3": "University of Waikato",
     "Column4": "NZ",
     "Column5": "New Zealand",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.1,
     "Column11": 473,
     "Column12": 11.7,
     "Column13": "601+",
     "Column14": 12.4,
     "Column15": "701+",
     "Column16": 84.7,
     "Column17": 94,
     "Column18": 99,
     "Column19": 107,
     "Column20": 56.9,
     "Column21": 261,
     "Column22": 55.1,
     "Column23": 697,
     "Column24": 46.4,
     "Column25": 276,
     "Column26": 89.5,
     "Column27": 99,
     "Column28": 42.5
    },
    {
     "2025 QS World University Rankings": 236,
     "Column2": 219,
     "Column3": "Università di Padova",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 58.9,
     "Column11": 129,
     "Column12": 27.3,
     "Column13": 351,
     "Column14": 8.5,
     "Column15": "701+",
     "Column16": 45.7,
     "Column17": 302,
     "Column18": 9.2,
     "Column19": "701+",
     "Column20": 11.1,
     "Column21": 694,
     "Column22": 94.9,
     "Column23": 79,
     "Column24": 18.8,
     "Column25": 590,
     "Column26": 87.7,
     "Column27": 110,
     "Column28": 42.3
    },
    {
     "2025 QS World University Rankings": 236,
     "Column2": 208,
     "Column3": "University of Aberdeen",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 30.5,
     "Column11": 292,
     "Column12": 28.8,
     "Column13": 330,
     "Column14": 32.7,
     "Column15": 482,
     "Column16": 32,
     "Column17": 412,
     "Column18": 94.5,
     "Column19": 154,
     "Column20": 92.6,
     "Column21": 103,
     "Column22": 88.5,
     "Column23": 190,
     "Column24": 22.2,
     "Column25": 519,
     "Column26": 96.5,
     "Column27": "37=",
     "Column28": 42.3
    },
    {
     "2025 QS World University Rankings": 236,
     "Column2": 224,
     "Column3": "University of Rochester",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 22.6,
     "Column11": 396,
     "Column12": 20.8,
     "Column13": 441,
     "Column14": 99.1,
     "Column15": 35,
     "Column16": 33.6,
     "Column17": 395,
     "Column18": 63.9,
     "Column19": 306,
     "Column20": 93.8,
     "Column21": 100,
     "Column22": 64.3,
     "Column23": 568,
     "Column24": 40.4,
     "Column25": 316,
     "Column26": 63.7,
     "Column27": "247=",
     "Column28": 42.3
    },
    {
     "2025 QS World University Rankings": 239,
     "Column2": 263,
     "Column3": "Gadjah Mada University",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 54.4,
     "Column11": 145,
     "Column12": 73.5,
     "Column13": 91,
     "Column14": 57.6,
     "Column15": 255,
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 40.3,
     "Column19": 417,
     "Column20": 4.1,
     "Column21": "701+",
     "Column22": 38.1,
     "Column23": "701+",
     "Column24": 69,
     "Column25": 167,
     "Column26": 26.8,
     "Column27": "473=",
     "Column28": 41.8
    },
    {
     "2025 QS World University Rankings": 239,
     "Column2": 239,
     "Column3": "Massey University",
     "Column4": "NZ",
     "Column5": "New Zealand",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 30.1,
     "Column11": 293,
     "Column12": 14,
     "Column13": 585,
     "Column14": 48,
     "Column15": 324,
     "Column16": 27.4,
     "Column17": 473,
     "Column18": 98.5,
     "Column19": 112,
     "Column20": 95.2,
     "Column21": 96,
     "Column22": 85.1,
     "Column23": 244,
     "Column24": 49.2,
     "Column25": 259,
     "Column26": 91.4,
     "Column27": "81=",
     "Column28": 41.8
    },
    {
     "2025 QS World University Rankings": 241,
     "Column2": 252,
     "Column3": "Politecnico di Torino",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 40.2,
     "Column11": 213,
     "Column12": 52.1,
     "Column13": 165,
     "Column14": 3.1,
     "Column15": "701+",
     "Column16": 51.6,
     "Column17": 254,
     "Column18": 4.9,
     "Column19": "701+",
     "Column20": 41,
     "Column21": 349,
     "Column22": 81.4,
     "Column23": 297,
     "Column24": 30.6,
     "Column25": 412,
     "Column26": 77.9,
     "Column27": 169,
     "Column28": 41.7
    },
    {
     "2025 QS World University Rankings": 241,
     "Column2": 246,
     "Column3": "Technical University of Darmstadt",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20,
     "Column11": 444,
     "Column12": 35.9,
     "Column13": 260,
     "Column14": 6.7,
     "Column15": "701+",
     "Column16": 70,
     "Column17": 155,
     "Column18": 60.3,
     "Column19": 323,
     "Column20": 81.4,
     "Column21": 167,
     "Column22": 65.9,
     "Column23": 544,
     "Column24": 44.4,
     "Column25": 288,
     "Column26": 71.8,
     "Column27": "211=",
     "Column28": 41.7
    },
    {
     "2025 QS World University Rankings": 243,
     "Column2": 237,
     "Column3": "Dartmouth College",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 22.8,
     "Column11": 392,
     "Column12": 31,
     "Column13": 303,
     "Column14": 65.8,
     "Column15": 200,
     "Column16": 60.6,
     "Column17": 200,
     "Column18": 9.2,
     "Column19": "701+",
     "Column20": 40.9,
     "Column21": 351,
     "Column22": 42.5,
     "Column23": "701+",
     "Column24": 68.8,
     "Column25": 168,
     "Column26": 71.2,
     "Column27": "214=",
     "Column28": 41.3
    },
    {
     "2025 QS World University Rankings": 244,
     "Column2": 241,
     "Column3": "Victoria University of Wellington",
     "Column4": "NZ",
     "Column5": "New Zealand",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 42.8,
     "Column11": 202,
     "Column12": 21.4,
     "Column13": 429,
     "Column14": 10.1,
     "Column15": "701+",
     "Column16": 32.1,
     "Column17": 410,
     "Column18": 100,
     "Column19": 82,
     "Column20": 31,
     "Column21": 420,
     "Column22": 70.7,
     "Column23": 475,
     "Column24": 71.6,
     "Column25": 151,
     "Column26": 91.4,
     "Column27": "81=",
     "Column28": 41.2
    },
    {
     "2025 QS World University Rankings": 245,
     "Column2": 254,
     "Column3": "University of Macau",
     "Column4": "MO",
     "Column5": "Macau SAR",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.9,
     "Column11": "601+",
     "Column12": 10.5,
     "Column13": "601+",
     "Column14": 9.8,
     "Column15": "701+",
     "Column16": 99.1,
     "Column17": 23,
     "Column18": 100,
     "Column19": 21,
     "Column20": 99.9,
     "Column21": 36,
     "Column22": 57,
     "Column23": 664,
     "Column24": 50.3,
     "Column25": 253,
     "Column26": 7.7,
     "Column27": "701+",
     "Column28": 41.1
    },
    {
     "2025 QS World University Rankings": 246,
     "Column2": 248,
     "Column3": "Charles University",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 45.6,
     "Column11": 182,
     "Column12": 49.1,
     "Column13": 183,
     "Column14": 32.2,
     "Column15": 491,
     "Column16": 8,
     "Column17": "701+",
     "Column18": 25,
     "Column19": 535,
     "Column20": 50.5,
     "Column21": 291,
     "Column22": 96.3,
     "Column23": 51,
     "Column24": 65.5,
     "Column25": 178,
     "Column26": 77.4,
     "Column27": "173=",
     "Column28": 41
    },
    {
     "2025 QS World University Rankings": 246,
     "Column2": 218,
     "Column3": "University of Sussex",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 22.6,
     "Column11": 395,
     "Column12": 10.4,
     "Column13": "601+",
     "Column14": 15.5,
     "Column15": "701+",
     "Column16": 56.8,
     "Column17": 220,
     "Column18": 99.6,
     "Column19": 95,
     "Column20": 84.9,
     "Column21": 152,
     "Column22": 84.7,
     "Column23": 252,
     "Column24": 39.9,
     "Column25": 322,
     "Column26": 97.7,
     "Column27": "26=",
     "Column28": 41
    },
    {
     "2025 QS World University Rankings": 248,
     "Column2": 261,
     "Column3": "Vanderbilt University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 24.4,
     "Column11": 356,
     "Column12": 21.8,
     "Column13": 423,
     "Column14": 100,
     "Column15": 13,
     "Column16": 53,
     "Column17": 242,
     "Column18": 30.1,
     "Column19": 493,
     "Column20": 36,
     "Column21": 383,
     "Column22": 78.6,
     "Column23": 344,
     "Column24": 34.7,
     "Column25": 364,
     "Column26": 24.5,
     "Column27": "492=",
     "Column28": 40.8
    },
    {
     "2025 QS World University Rankings": 249,
     "Column2": 280,
     "Column3": "University of Navarra",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 30.6,
     "Column11": 291,
     "Column12": 68,
     "Column13": 108,
     "Column14": 49.6,
     "Column15": 315,
     "Column16": 14.7,
     "Column17": 642,
     "Column18": 24.8,
     "Column19": 537,
     "Column20": 73.8,
     "Column21": 196,
     "Column22": 63.6,
     "Column23": 578,
     "Column24": 75.8,
     "Column25": 137,
     "Column26": 42.3,
     "Column27": "355=",
     "Column28": 40.7
    },
    {
     "2025 QS World University Rankings": 250,
     "Column2": 226,
     "Column3": "American University of Beirut (AUB)",
     "Column4": "LB",
     "Column5": "Lebanon",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 28.6,
     "Column11": 307,
     "Column12": 29.5,
     "Column13": 315,
     "Column14": 48.5,
     "Column15": 322,
     "Column16": 28.9,
     "Column17": 449,
     "Column18": 63.2,
     "Column19": 310,
     "Column20": 37,
     "Column21": 379,
     "Column22": 73.1,
     "Column23": 438,
     "Column24": 97,
     "Column25": 35,
     "Column26": 80.5,
     "Column27": "152=",
     "Column28": 40.6
    },
    {
     "2025 QS World University Rankings": 251,
     "Column2": 284,
     "Column3": "Taylor's University",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 27,
     "Column11": 323,
     "Column12": 81,
     "Column13": 72,
     "Column14": 71.5,
     "Column15": 185,
     "Column16": 10.1,
     "Column17": "701+",
     "Column18": 64.8,
     "Column19": 303,
     "Column20": 99.6,
     "Column21": 43,
     "Column22": 41.4,
     "Column23": "701+",
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 22.2,
     "Column27": "513=",
     "Column28": 40.5
    },
    {
     "2025 QS World University Rankings": 192,
     "Column2": 216,
     "Column3": "Tongji University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 31.8,
     "Column11": 276,
     "Column12": 16.9,
     "Column13": 508,
     "Column14": 29.1,
     "Column15": 533,
     "Column16": 96.9,
     "Column17": 44,
     "Column18": 54.5,
     "Column19": 345,
     "Column20": 26.5,
     "Column21": 457,
     "Column22": 72.9,
     "Column23": 442,
     "Column24": 24,
     "Column25": 473,
     "Column26": 81.4,
     "Column27": 147,
     "Column28": 46.9
    },
    {
     "2025 QS World University Rankings": 252,
     "Column2": 295,
     "Column3": "Hong Kong Baptist University",
     "Column4": "HK",
     "Column5": "Hong Kong SAR",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.2,
     "Column11": 499,
     "Column12": 12,
     "Column13": "601+",
     "Column14": 60,
     "Column15": 242,
     "Column16": 61.4,
     "Column17": 192,
     "Column18": 98.9,
     "Column19": 109,
     "Column20": 100,
     "Column21": 19,
     "Column22": 37,
     "Column23": "701+",
     "Column24": 15.7,
     "Column25": 641,
     "Column26": 54.4,
     "Column27": "291=",
     "Column28": 40.3
    },
    {
     "2025 QS World University Rankings": 252,
     "Column2": 232,
     "Column3": "University of Göttingen",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 46.4,
     "Column11": 175,
     "Column12": 27.1,
     "Column13": 355,
     "Column14": 76.4,
     "Column15": 156,
     "Column16": 16.2,
     "Column17": 620,
     "Column18": 49.7,
     "Column19": 369,
     "Column20": 22.4,
     "Column21": 512,
     "Column22": 95.1,
     "Column23": 76,
     "Column24": 40.3,
     "Column25": 317,
     "Column26": 32.1,
     "Column27": "428=",
     "Column28": 40.3
    },
    {
     "2025 QS World University Rankings": 255,
     "Column2": 243,
     "Column3": "Griffith University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 27,
     "Column11": 324,
     "Column12": 13.7,
     "Column13": 593,
     "Column14": 8.4,
     "Column15": "701+",
     "Column16": 62.5,
     "Column17": 187,
     "Column18": 99.8,
     "Column19": 90,
     "Column20": 38.5,
     "Column21": 363,
     "Column22": 92.1,
     "Column23": 135,
     "Column24": 17.9,
     "Column25": 602,
     "Column26": 96.4,
     "Column27": 40,
     "Column28": 40.1
    },
    {
     "2025 QS World University Rankings": 256,
     "Column2": 281,
     "Column3": "Bandung Institute of Technology (ITB)",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 43.2,
     "Column11": 197,
     "Column12": 72.7,
     "Column13": 95,
     "Column14": 55.8,
     "Column15": 266,
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 94.4,
     "Column19": 158,
     "Column20": 3.8,
     "Column21": "701+",
     "Column22": 26.3,
     "Column23": "701+",
     "Column24": 49.2,
     "Column25": 260,
     "Column26": 37.6,
     "Column27": "388=",
     "Column28": 39.9
    },
    {
     "2025 QS World University Rankings": 256,
     "Column2": 235,
     "Column3": "Heriot-Watt University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.8,
     "Column11": 507,
     "Column12": 27.1,
     "Column13": 356,
     "Column14": 17.6,
     "Column15": "701+",
     "Column16": 65.2,
     "Column17": 171,
     "Column18": 100,
     "Column19": 79,
     "Column20": 98.1,
     "Column21": 69,
     "Column22": 75.4,
     "Column23": 402,
     "Column24": 26.3,
     "Column25": 451,
     "Column26": 25.7,
     "Column27": "483=",
     "Column28": 39.9
    },
    {
     "2025 QS World University Rankings": 258,
     "Column2": 262,
     "Column3": "University of Warsaw ",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 50,
     "Column11": 163,
     "Column12": 53.6,
     "Column13": 158,
     "Column14": 46.4,
     "Column15": 330,
     "Column16": 5.5,
     "Column17": "701+",
     "Column18": 10.5,
     "Column19": "701+",
     "Column20": 8.4,
     "Column21": "701+",
     "Column22": 84.1,
     "Column23": 262,
     "Column24": 60,
     "Column25": 203,
     "Column26": 69.8,
     "Column27": 222,
     "Column28": 39.8
    },
    {
     "2025 QS World University Rankings": 259,
     "Column2": 255,
     "Column3": "Case Western Reserve University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 17.2,
     "Column11": 523,
     "Column12": 15.4,
     "Column13": 546,
     "Column14": 82.3,
     "Column15": 119,
     "Column16": 61.6,
     "Column17": 191,
     "Column18": 35.7,
     "Column19": 447,
     "Column20": 71.7,
     "Column21": 205,
     "Column22": 55.6,
     "Column23": 681,
     "Column24": 29.3,
     "Column25": 424,
     "Column26": 53.1,
     "Column27": "298=",
     "Column28": 39.7
    },
    {
     "2025 QS World University Rankings": 260,
     "Column2": 266,
     "Column3": "University of Lisbon ",
     "Column4": "PT",
     "Column5": "Portugal",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 44,
     "Column11": 193,
     "Column12": 20.7,
     "Column13": 443,
     "Column14": 10.7,
     "Column15": "701+",
     "Column16": 36.5,
     "Column17": 376,
     "Column18": 9,
     "Column19": "701+",
     "Column20": 39.4,
     "Column21": 356,
     "Column22": 98.2,
     "Column23": 23,
     "Column24": 94,
     "Column25": 57,
     "Column26": 70.9,
     "Column27": "217=",
     "Column28": 39.6
    },
    {
     "2025 QS World University Rankings": 261,
     "Column2": 290,
     "Column3": "United Arab Emirates University",
     "Column4": "AE",
     "Column5": "United Arab Emirates",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 30.1,
     "Column11": 295,
     "Column12": 38.3,
     "Column13": 247,
     "Column14": 49.8,
     "Column15": 312,
     "Column16": 16.6,
     "Column17": 612,
     "Column18": 100,
     "Column19": 19,
     "Column20": 34.2,
     "Column21": 396,
     "Column22": 86.2,
     "Column23": 230,
     "Column24": 82,
     "Column25": 111,
     "Column26": 36.6,
     "Column27": "393=",
     "Column28": 39.4
    },
    {
     "2025 QS World University Rankings": 261,
     "Column2": 256,
     "Column3": "University of Canterbury | Te Whare W?nanga o Waitaha",
     "Column4": "NZ",
     "Column5": "New Zealand",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 34.7,
     "Column11": 253,
     "Column12": 27.3,
     "Column13": 352,
     "Column14": 10.4,
     "Column15": "701+",
     "Column16": 31.4,
     "Column17": 419,
     "Column18": 100,
     "Column19": 77,
     "Column20": 27.7,
     "Column21": 449,
     "Column22": 63,
     "Column23": 585,
     "Column24": 82.3,
     "Column25": 109,
     "Column26": 91.1,
     "Column27": "86=",
     "Column28": 39.4
    },
    {
     "2025 QS World University Rankings": 263,
     "Column2": 278,
     "Column3": "Indian Institute of Technology Kanpur (IITK)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 36.9,
     "Column11": 234,
     "Column12": 56.9,
     "Column13": 142,
     "Column14": 14,
     "Column15": "701+",
     "Column16": 73.3,
     "Column17": 142,
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 38.8,
     "Column23": "701+",
     "Column24": 19.6,
     "Column25": 567,
     "Column26": 22,
     "Column27": "516=",
     "Column28": 39.3
    },
    {
     "2025 QS World University Rankings": 264,
     "Column2": 292,
     "Column3": "Norwegian University of Science And Technology",
     "Column4": "NO",
     "Column5": "Norway",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 33.2,
     "Column11": 264,
     "Column12": 25.2,
     "Column13": 378,
     "Column14": 22.3,
     "Column15": 629,
     "Column16": 30.4,
     "Column17": 435,
     "Column18": 75.1,
     "Column19": 264,
     "Column20": 9.3,
     "Column21": "701+",
     "Column22": 94.4,
     "Column23": 89,
     "Column24": 89.4,
     "Column25": 79,
     "Column26": 85.8,
     "Column27": "121=",
     "Column28": 39.1
    },
    {
     "2025 QS World University Rankings": 265,
     "Column2": 300,
     "Column3": "UCSI University",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 27,
     "Column11": 326,
     "Column12": 65.1,
     "Column13": 120,
     "Column14": 78.1,
     "Column15": 150,
     "Column16": 3.6,
     "Column17": "701+",
     "Column18": 89.1,
     "Column19": 196,
     "Column20": 95.8,
     "Column21": 91,
     "Column22": 37.6,
     "Column23": "701+",
     "Column24": 10.2,
     "Column25": "701+",
     "Column26": 27.9,
     "Column27": "462=",
     "Column28": 38.8
    },
    {
     "2025 QS World University Rankings": 265,
     "Column2": 310,
     "Column3": "Universitat Pompeu Fabra (Barcelona)",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 31.7,
     "Column11": 279,
     "Column12": 29.4,
     "Column13": 319,
     "Column14": 12.1,
     "Column15": "701+",
     "Column16": 67.1,
     "Column17": 163,
     "Column18": 75.6,
     "Column19": 259,
     "Column20": 24.5,
     "Column21": 482,
     "Column22": 74,
     "Column23": 421,
     "Column24": 16.4,
     "Column25": 626,
     "Column26": 27.9,
     "Column27": "462=",
     "Column28": 38.8
    },
    {
     "2025 QS World University Rankings": 267,
     "Column2": 248,
     "Column3": "University of Antwerp",
     "Column4": "BE",
     "Column5": "Belgium",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 24.1,
     "Column11": 361,
     "Column12": 11.3,
     "Column13": "601+",
     "Column14": 37.5,
     "Column15": 427,
     "Column16": 48.1,
     "Column17": 278,
     "Column18": 94.4,
     "Column19": 157,
     "Column20": 32,
     "Column21": 415,
     "Column22": 94.6,
     "Column23": 85,
     "Column24": 44.8,
     "Column25": 284,
     "Column26": 74.8,
     "Column27": "190=",
     "Column28": 38.7
    },
    {
     "2025 QS World University Rankings": 267,
     "Column2": 264,
     "Column3": "University of Witwatersrand",
     "Column4": "ZA",
     "Column5": "South Africa",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 26.5,
     "Column11": 332,
     "Column12": 42.1,
     "Column13": 223,
     "Column14": 8.9,
     "Column15": "701+",
     "Column16": 30.5,
     "Column17": 432,
     "Column18": 75.1,
     "Column19": 263,
     "Column20": 5.8,
     "Column21": "701+",
     "Column22": 96.8,
     "Column23": 46,
     "Column24": 98.9,
     "Column25": 18,
     "Column26": 84.8,
     "Column27": "127=",
     "Column28": 38.7
    },
    {
     "2025 QS World University Rankings": 331,
     "Column2": 323,
     "Column3": "Sun Yat-sen University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 31.8,
     "Column11": 278,
     "Column12": 28.8,
     "Column13": 329,
     "Column14": 46.4,
     "Column15": 329,
     "Column16": 49.5,
     "Column17": 269,
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 3.6,
     "Column21": "701+",
     "Column22": 77.4,
     "Column23": 370,
     "Column24": 21.4,
     "Column25": 536,
     "Column26": 15.9,
     "Column27": "581=",
     "Column28": 33.7
    },
    {
     "2025 QS World University Rankings": 269,
     "Column2": 307,
     "Column3": "Universiti Teknologi PETRONAS (UTP)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 19.5,
     "Column11": 458,
     "Column12": 28.1,
     "Column13": 341,
     "Column14": 38.5,
     "Column15": 412,
     "Column16": 79,
     "Column17": 117,
     "Column18": 83.9,
     "Column19": 224,
     "Column20": 20.1,
     "Column21": 537,
     "Column22": 63.9,
     "Column23": 577,
     "Column24": 4.1,
     "Column25": "701+",
     "Column26": 18.4,
     "Column27": "549=",
     "Column28": 38.6
    },
    {
     "2025 QS World University Rankings": 252,
     "Column2": 256,
     "Column3": "Harbin Institute of Technology",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 27.1,
     "Column11": 321,
     "Column12": 21.1,
     "Column13": 435,
     "Column14": 33.7,
     "Column15": 468,
     "Column16": 99.8,
     "Column17": 14,
     "Column18": 8,
     "Column19": "701+",
     "Column20": 5,
     "Column21": "701+",
     "Column22": 68.6,
     "Column23": 507,
     "Column24": 28.3,
     "Column25": 434,
     "Column26": 14.6,
     "Column27": "597=",
     "Column28": 40.3
    },
    {
     "2025 QS World University Rankings": 272,
     "Column2": 222,
     "Column3": "Radboud University ",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 28,
     "Column11": 315,
     "Column12": 14.1,
     "Column13": 582,
     "Column14": 27,
     "Column15": 561,
     "Column16": 66,
     "Column17": 167,
     "Column18": 81.3,
     "Column19": 233,
     "Column20": 18.6,
     "Column21": 573,
     "Column22": 93.9,
     "Column23": 101,
     "Column24": 19.6,
     "Column25": 571,
     "Column26": 39.8,
     "Column27": "369=",
     "Column28": 38.4
    },
    {
     "2025 QS World University Rankings": 273,
     "Column2": 292,
     "Column3": "University College Cork",
     "Column4": "IE",
     "Column5": "Ireland",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 30.1,
     "Column11": 294,
     "Column12": 35.1,
     "Column13": 270,
     "Column14": 22.1,
     "Column15": 636,
     "Column16": 28.5,
     "Column17": 457,
     "Column18": 89,
     "Column19": 198,
     "Column20": 52.1,
     "Column21": 281,
     "Column22": 69.1,
     "Column23": 497,
     "Column24": 34,
     "Column25": 371,
     "Column26": 91,
     "Column27": 88,
     "Column28": 38.3
    },
    {
     "2025 QS World University Rankings": 273,
     "Column2": 289,
     "Column3": "University of Galway",
     "Column4": "IE",
     "Column5": "Ireland",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 26.2,
     "Column11": 339,
     "Column12": 30.3,
     "Column13": 311,
     "Column14": 63.2,
     "Column15": 217,
     "Column16": 13.2,
     "Column17": 664,
     "Column18": 95.2,
     "Column19": 144,
     "Column20": 43.2,
     "Column21": 333,
     "Column22": 81.3,
     "Column23": 301,
     "Column24": 46.6,
     "Column25": 274,
     "Column26": 85.9,
     "Column27": 120,
     "Column28": 38.3
    },
    {
     "2025 QS World University Rankings": 275,
     "Column2": 298,
     "Column3": "Dalhousie University",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.4,
     "Column11": 463,
     "Column12": 16.7,
     "Column13": 513,
     "Column14": 12.9,
     "Column15": "701+",
     "Column16": 53.5,
     "Column17": 239,
     "Column18": 99.9,
     "Column19": 86,
     "Column20": 74.6,
     "Column21": 189,
     "Column22": 77.4,
     "Column23": 369,
     "Column24": 30.1,
     "Column25": 416,
     "Column26": 87.9,
     "Column27": 109,
     "Column28": 38.2
    },
    {
     "2025 QS World University Rankings": 275,
     "Column2": 245,
     "Column3": "University of Massachusetts Amherst",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 38.2,
     "Column11": 224,
     "Column12": 17.2,
     "Column13": 498,
     "Column14": 10,
     "Column15": "701+",
     "Column16": 48.8,
     "Column17": 274,
     "Column18": 61,
     "Column19": 321,
     "Column20": 25.3,
     "Column21": 474,
     "Column22": 79.8,
     "Column23": 327,
     "Column24": 30.6,
     "Column25": 411,
     "Column26": 83.4,
     "Column27": "136=",
     "Column28": 38.2
    },
    {
     "2025 QS World University Rankings": 275,
     "Column2": 222,
     "Column3": "University of Pittsburgh",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 35,
     "Column11": 251,
     "Column12": 12.7,
     "Column13": "601+",
     "Column14": 96.3,
     "Column15": 52,
     "Column16": 31.3,
     "Column17": 420,
     "Column18": 9.5,
     "Column19": "701+",
     "Column20": 13.5,
     "Column21": 650,
     "Column22": 91.7,
     "Column23": 141,
     "Column24": 42.6,
     "Column25": 303,
     "Column26": 53.5,
     "Column27": "295=",
     "Column28": 38.2
    },
    {
     "2025 QS World University Rankings": 278,
     "Column2": 253,
     "Column3": "University of Porto",
     "Column4": "PT",
     "Column5": "Portugal",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 41.3,
     "Column11": 206,
     "Column12": 17.9,
     "Column13": 492,
     "Column14": 10.8,
     "Column15": "701+",
     "Column16": 59.6,
     "Column17": 205,
     "Column18": 6.6,
     "Column19": "701+",
     "Column20": 19.8,
     "Column21": 543,
     "Column22": 95.1,
     "Column23": 77,
     "Column24": 39.1,
     "Column25": 327,
     "Column26": 53.1,
     "Column27": "298=",
     "Column28": 38.1
    },
    {
     "2025 QS World University Rankings": 278,
     "Column2": 259,
     "Column3": "Vrije Universiteit Brussel (VUB)",
     "Column4": "BE",
     "Column5": "Belgium",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.3,
     "Column11": 381,
     "Column12": 13.7,
     "Column13": 592,
     "Column14": 76.1,
     "Column15": 159,
     "Column16": 18.2,
     "Column17": 581,
     "Column18": 93.6,
     "Column19": 165,
     "Column20": 58.2,
     "Column21": 255,
     "Column22": 93.1,
     "Column23": 115,
     "Column24": 51.8,
     "Column25": 243,
     "Column26": 72.1,
     "Column27": "208=",
     "Column28": 38.1
    },
    {
     "2025 QS World University Rankings": 280,
     "Column2": 266,
     "Column3": "Ulsan National Institute of Science and Technology (UNIST)",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.6,
     "Column11": "601+",
     "Column12": 13.5,
     "Column13": "601+",
     "Column14": 87.9,
     "Column15": 95,
     "Column16": 98.1,
     "Column17": 34,
     "Column18": 29.3,
     "Column19": 499,
     "Column20": 9.6,
     "Column21": "701+",
     "Column22": 24.8,
     "Column23": "701+",
     "Column24": 3.3,
     "Column25": "701+",
     "Column26": 16.1,
     "Column27": "577=",
     "Column28": 38
    },
    {
     "2025 QS World University Rankings": 281,
     "Column2": 251,
     "Column3": "The Hebrew University of Jerusalem",
     "Column4": "IL",
     "Column5": "Israel",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 32.8,
     "Column11": 269,
     "Column12": 18.2,
     "Column13": 484,
     "Column14": 79.3,
     "Column15": 143,
     "Column16": 19.4,
     "Column17": 563,
     "Column18": 53.2,
     "Column19": 349,
     "Column20": 23.8,
     "Column21": 492,
     "Column22": 77.8,
     "Column23": 364,
     "Column24": 94.6,
     "Column25": 54,
     "Column26": 34.2,
     "Column27": "413=",
     "Column28": 37.9
    },
    {
     "2025 QS World University Rankings": 281,
     "Column2": 276,
     "Column3": "University of Strathclyde",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 25.9,
     "Column11": 343,
     "Column12": 30,
     "Column13": 314,
     "Column14": 16.7,
     "Column15": "701+",
     "Column16": 31.8,
     "Column17": 416,
     "Column18": 93,
     "Column19": 170,
     "Column20": 64,
     "Column21": 235,
     "Column22": 85,
     "Column23": 246,
     "Column24": 33.6,
     "Column25": 376,
     "Column26": 88.6,
     "Column27": 107,
     "Column28": 37.9
    },
    {
     "2025 QS World University Rankings": 283,
     "Column2": 328,
     "Column3": "Université Paris 1 Panthéon-Sorbonne ",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 65.6,
     "Column11": 104,
     "Column12": 33.3,
     "Column13": 280,
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 2.9,
     "Column17": "701+",
     "Column18": 34.3,
     "Column19": 467,
     "Column20": 46.5,
     "Column21": 317,
     "Column22": 70.3,
     "Column23": 481,
     "Column24": 74.8,
     "Column25": 140,
     "Column26": 23.7,
     "Column27": "500=",
     "Column28": 37.5
    },
    {
     "2025 QS World University Rankings": 362,
     "Column2": 392,
     "Column3": "Xiamen University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.6,
     "Column11": 372,
     "Column12": 16.2,
     "Column13": 526,
     "Column14": 19,
     "Column15": "701+",
     "Column16": 72.2,
     "Column17": 146,
     "Column18": 5.1,
     "Column19": "701+",
     "Column20": 3.1,
     "Column21": "701+",
     "Column22": 62.6,
     "Column23": 594,
     "Column24": 46,
     "Column25": 277,
     "Column26": 15.4,
     "Column27": "585=",
     "Column28": 31.7
    },
    {
     "2025 QS World University Rankings": 285,
     "Column2": 336,
     "Column3": "Middle East Technical University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 31.2,
     "Column11": 286,
     "Column12": 70.2,
     "Column13": 101,
     "Column14": 9,
     "Column15": "701+",
     "Column16": 22.3,
     "Column17": 528,
     "Column18": 12,
     "Column19": "701+",
     "Column20": 18.3,
     "Column21": 576,
     "Column22": 74.7,
     "Column23": 412,
     "Column24": 77.5,
     "Column25": 130,
     "Column26": 69.5,
     "Column27": "223=",
     "Column28": 37.2
    },
    {
     "2025 QS World University Rankings": 285,
     "Column2": 319,
     "Column3": "Universidad Carlos III de Madrid (UC3M)",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 33.5,
     "Column11": 260,
     "Column12": 56.3,
     "Column13": 147,
     "Column14": 49.5,
     "Column15": 316,
     "Column16": 4.1,
     "Column17": "701+",
     "Column18": 64.8,
     "Column19": 302,
     "Column20": 52.3,
     "Column21": 279,
     "Column22": 66.3,
     "Column23": 534,
     "Column24": 20.7,
     "Column25": 551,
     "Column26": 68.3,
     "Column27": 227,
     "Column28": 37.2
    },
    {
     "2025 QS World University Rankings": 285,
     "Column2": 268,
     "Column3": "University of Cologne",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 35.2,
     "Column11": 250,
     "Column12": 30.4,
     "Column13": 310,
     "Column14": 15.7,
     "Column15": "701+",
     "Column16": 29.3,
     "Column17": 440,
     "Column18": 35.9,
     "Column19": 444,
     "Column20": 30.1,
     "Column21": 429,
     "Column22": 90.8,
     "Column23": 158,
     "Column24": 56.8,
     "Column25": 212,
     "Column26": 91.9,
     "Column27": "78=",
     "Column28": 37.2
    },
    {
     "2025 QS World University Rankings": 285,
     "Column2": 272,
     "Column3": "University of Leicester",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.8,
     "Column11": 453,
     "Column12": 13.7,
     "Column13": 594,
     "Column14": 30.8,
     "Column15": 511,
     "Column16": 43.4,
     "Column17": 316,
     "Column18": 80,
     "Column19": 238,
     "Column20": 95.3,
     "Column21": 95,
     "Column22": 88.8,
     "Column23": 187,
     "Column24": 22.7,
     "Column25": 504,
     "Column26": 75,
     "Column27": "186=",
     "Column28": 37.2
    },
    {
     "2025 QS World University Rankings": 285,
     "Column2": 276,
     "Column3": "University of Milan",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 52.8,
     "Column11": 152,
     "Column12": 17,
     "Column13": 505,
     "Column14": 6.4,
     "Column15": "701+",
     "Column16": 39.6,
     "Column17": 356,
     "Column18": 6.5,
     "Column19": "701+",
     "Column20": 6.8,
     "Column21": "701+",
     "Column22": 85.5,
     "Column23": 239,
     "Column24": 44.1,
     "Column25": 290,
     "Column26": 74.8,
     "Column27": "190=",
     "Column28": 37.2
    },
    {
     "2025 QS World University Rankings": 285,
     "Column2": 244,
     "Column3": "University of Surrey",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.7,
     "Column11": 454,
     "Column12": 17.9,
     "Column13": 490,
     "Column14": 22.8,
     "Column15": 625,
     "Column16": 55.2,
     "Column17": 227,
     "Column18": 97.1,
     "Column19": 129,
     "Column20": 87.1,
     "Column21": 139,
     "Column22": 86.4,
     "Column23": 226,
     "Column24": 20.4,
     "Column25": 557,
     "Column26": 28.4,
     "Column27": "456=",
     "Column28": 37.2
    },
    {
     "2025 QS World University Rankings": 291,
     "Column2": 285,
     "Column3": "Swinburne University of Technology",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.9,
     "Column11": "601+",
     "Column12": 11.4,
     "Column13": "601+",
     "Column14": 12.4,
     "Column15": "701+",
     "Column16": 86.5,
     "Column17": 88,
     "Column18": 94.9,
     "Column19": 150,
     "Column20": 77.3,
     "Column21": 183,
     "Column22": 66.1,
     "Column23": 538,
     "Column24": 13.9,
     "Column25": 696,
     "Column26": 15.4,
     "Column27": "585=",
     "Column28": 37.1
    },
    {
     "2025 QS World University Rankings": 291,
     "Column2": 281,
     "Column3": "University of Bergen",
     "Column4": "NO",
     "Column5": "Norway",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 31.6,
     "Column11": 280,
     "Column12": 8.3,
     "Column13": "601+",
     "Column14": 64.9,
     "Column15": 205,
     "Column16": 28.3,
     "Column17": 460,
     "Column18": 93.3,
     "Column19": 167,
     "Column20": 16.9,
     "Column21": 589,
     "Column22": 90.7,
     "Column23": 160,
     "Column24": 63.9,
     "Column25": 184,
     "Column26": 33.6,
     "Column27": "416=",
     "Column28": 37.1
    },
    {
     "2025 QS World University Rankings": 293,
     "Column2": 285,
     "Column3": "The University of Arizona",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 37.3,
     "Column11": 231,
     "Column12": 22.2,
     "Column13": 420,
     "Column14": 44.4,
     "Column15": 349,
     "Column16": 38.8,
     "Column17": 360,
     "Column18": 31.6,
     "Column19": 486,
     "Column20": 15.1,
     "Column21": 620,
     "Column22": 94.1,
     "Column23": 98,
     "Column24": 37.1,
     "Column25": 344,
     "Column26": 40.4,
     "Column27": "363=",
     "Column28": 37
    },
    {
     "2025 QS World University Rankings": 293,
     "Column2": 307,
     "Column3": "University of Tasmania",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16,
     "Column11": 552,
     "Column12": 7.4,
     "Column13": "601+",
     "Column14": 22.1,
     "Column15": 635,
     "Column16": 59.8,
     "Column17": 203,
     "Column18": 79.9,
     "Column19": 240,
     "Column20": 89.7,
     "Column21": 123,
     "Column22": 80,
     "Column23": 323,
     "Column24": 15.9,
     "Column25": 636,
     "Column26": 85.6,
     "Column27": 123,
     "Column28": 37
    },
    {
     "2025 QS World University Rankings": 302,
     "Column2": 340,
     "Column3": "Beijing Institute of Technology",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.2,
     "Column11": 384,
     "Column12": 24.3,
     "Column13": 396,
     "Column14": 43.4,
     "Column15": 361,
     "Column16": 82,
     "Column17": 103,
     "Column18": 5.7,
     "Column19": "701+",
     "Column20": 6.7,
     "Column21": "701+",
     "Column22": 68.6,
     "Column23": 505,
     "Column24": 14.3,
     "Column25": 681,
     "Column26": 10.1,
     "Column27": "661=",
     "Column28": 35.9
    },
    {
     "2025 QS World University Rankings": 296,
     "Column2": 283,
     "Column3": "Stellenbosch University",
     "Column4": "ZA",
     "Column5": "South Africa",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 26.1,
     "Column11": 342,
     "Column12": 35.8,
     "Column13": 262,
     "Column14": 3.5,
     "Column15": "701+",
     "Column16": 61.9,
     "Column17": 190,
     "Column18": 19.7,
     "Column19": 605,
     "Column20": 8,
     "Column21": "701+",
     "Column22": 94.8,
     "Column23": 80,
     "Column24": 85.2,
     "Column25": 101,
     "Column26": 22,
     "Column27": "516=",
     "Column28": 36.7
    },
    {
     "2025 QS World University Rankings": 297,
     "Column2": 260,
     "Column3": "University of Virginia",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 37.3,
     "Column11": 230,
     "Column12": 27.3,
     "Column13": 353,
     "Column14": 55.8,
     "Column15": 267,
     "Column16": 29.1,
     "Column17": 446,
     "Column18": 6.1,
     "Column19": "701+",
     "Column20": 11.3,
     "Column21": 690,
     "Column22": 81.6,
     "Column23": 295,
     "Column24": 77.9,
     "Column25": 129,
     "Column26": 35.4,
     "Column27": "400=",
     "Column28": 36.6
    },
    {
     "2025 QS World University Rankings": 298,
     "Column2": 319,
     "Column3": "Bauman Moscow State Technical University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 36.2,
     "Column11": 242,
     "Column12": 51.9,
     "Column13": 169,
     "Column14": 99.9,
     "Column15": 22,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 65.6,
     "Column19": 298,
     "Column20": 70,
     "Column21": 210,
     "Column22": 15.2,
     "Column23": "701+",
     "Column24": 7.7,
     "Column25": "701+",
     "Column26": 6.8,
     "Column27": "701+",
     "Column28": 36.5
    },
    {
     "2025 QS World University Rankings": 298,
     "Column2": 307,
     "Column3": "Swansea University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.1,
     "Column11": 387,
     "Column12": 43.1,
     "Column13": 214,
     "Column14": 25.1,
     "Column15": 591,
     "Column16": 27.9,
     "Column17": 467,
     "Column18": 71,
     "Column19": 278,
     "Column20": 59.6,
     "Column21": 249,
     "Column22": 82.3,
     "Column23": 286,
     "Column24": 8.3,
     "Column25": "701+",
     "Column26": 91.8,
     "Column27": 80,
     "Column28": 36.5
    },
    {
     "2025 QS World University Rankings": 300,
     "Column2": 275,
     "Column3": "Huazhong University of Science and Technology",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.4,
     "Column11": 431,
     "Column12": 28.2,
     "Column13": 337,
     "Column14": 7.9,
     "Column15": "701+",
     "Column16": 99.9,
     "Column17": 10,
     "Column18": 5.2,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 79.4,
     "Column23": 335,
     "Column24": 17.6,
     "Column25": 606,
     "Column26": 15,
     "Column27": "592=",
     "Column28": 36.4
    },
    {
     "2025 QS World University Rankings": 301,
     "Column2": 297,
     "Column3": "Georgetown University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 29.3,
     "Column11": 302,
     "Column12": 41.9,
     "Column13": 224,
     "Column14": 63,
     "Column15": 218,
     "Column16": 14,
     "Column17": 655,
     "Column18": 28,
     "Column19": 506,
     "Column20": 37.3,
     "Column21": 377,
     "Column22": 62.5,
     "Column23": 597,
     "Column24": 90.9,
     "Column25": 71,
     "Column26": 37,
     "Column27": "391=",
     "Column28": 36.3
    },
    {
     "2025 QS World University Rankings": 336,
     "Column2": 355,
     "Column3": "Sichuan University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.4,
     "Column11": 433,
     "Column12": 12.3,
     "Column13": "601+",
     "Column14": 10.3,
     "Column15": "701+",
     "Column16": 98.5,
     "Column17": 32,
     "Column18": 7.1,
     "Column19": "701+",
     "Column20": 4.9,
     "Column21": "701+",
     "Column22": 71.3,
     "Column23": 463,
     "Column24": 12.9,
     "Column25": "701+",
     "Column26": 11,
     "Column27": "644=",
     "Column28": 33.3
    },
    {
     "2025 QS World University Rankings": 302,
     "Column2": 236,
     "Column3": "Université Paris Cité",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 27.8,
     "Column11": 319,
     "Column12": 6.2,
     "Column13": "601+",
     "Column14": 9.2,
     "Column15": "701+",
     "Column16": 87,
     "Column17": 87,
     "Column18": 12,
     "Column19": "701+",
     "Column20": 35.2,
     "Column21": 388,
     "Column22": 99.2,
     "Column23": 11,
     "Column24": 15.2,
     "Column25": 655,
     "Column26": 18.4,
     "Column27": "549=",
     "Column28": 35.9
    },
    {
     "2025 QS World University Rankings": 304,
     "Column2": 268,
     "Column3": "Linköping University",
     "Column4": "SE",
     "Column5": "Sweden",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.5,
     "Column11": 489,
     "Column12": 26.6,
     "Column13": 360,
     "Column14": 37.9,
     "Column15": 420,
     "Column16": 45.4,
     "Column17": 304,
     "Column18": 81.9,
     "Column19": 231,
     "Column20": 32.6,
     "Column21": 409,
     "Column22": 84.6,
     "Column23": 253,
     "Column24": 42.4,
     "Column25": 305,
     "Column26": 41,
     "Column27": "360=",
     "Column28": 35.8
    },
    {
     "2025 QS World University Rankings": 304,
     "Column2": 371,
     "Column3": "Universidade Federal do Rio de Janeiro",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 61.9,
     "Column11": 112,
     "Column12": 23.3,
     "Column13": 407,
     "Column14": 13.7,
     "Column15": "701+",
     "Column16": 7.8,
     "Column17": "701+",
     "Column18": 7.6,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 79.7,
     "Column23": 330,
     "Column24": 81.4,
     "Column25": 117,
     "Column26": 59.6,
     "Column27": "269=",
     "Column28": 35.8
    },
    {
     "2025 QS World University Rankings": 306,
     "Column2": 336,
     "Column3": "Aalborg University",
     "Column4": "DK",
     "Column5": "Denmark",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.9,
     "Column11": 450,
     "Column12": 20.8,
     "Column13": 439,
     "Column14": 25.5,
     "Column15": 580,
     "Column16": 53.2,
     "Column17": 241,
     "Column18": 77.3,
     "Column19": 250,
     "Column20": 14.9,
     "Column21": 627,
     "Column22": 92.1,
     "Column23": 134,
     "Column24": 23.3,
     "Column25": 486,
     "Column26": 75.6,
     "Column27": 183,
     "Column28": 35.7
    },
    {
     "2025 QS World University Rankings": 307,
     "Column2": 268,
     "Column3": "University of California, Irvine",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 37.5,
     "Column11": 229,
     "Column12": 22.3,
     "Column13": 419,
     "Column14": 17.3,
     "Column15": "701+",
     "Column16": 40.7,
     "Column17": 344,
     "Column18": 43.9,
     "Column19": 401,
     "Column20": 58.7,
     "Column21": 252,
     "Column22": 84.1,
     "Column23": 260,
     "Column24": 11.2,
     "Column25": "701+",
     "Column26": 36,
     "Column27": 397,
     "Column28": 35.5
    },
    {
     "2025 QS World University Rankings": 308,
     "Column2": 345,
     "Column3": "Airlangga University",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 37.1,
     "Column11": 232,
     "Column12": 79.1,
     "Column13": 80,
     "Column14": 57,
     "Column15": 259,
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 58,
     "Column19": 330,
     "Column20": 6.7,
     "Column21": "701+",
     "Column22": 40.3,
     "Column23": "701+",
     "Column24": 18.9,
     "Column25": 585,
     "Column26": 16.3,
     "Column27": "570=",
     "Column28": 35.3
    },
    {
     "2025 QS World University Rankings": 309,
     "Column2": 302,
     "Column3": "Goethe-University Frankfurt am Main",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 43.2,
     "Column11": 196,
     "Column12": 33.2,
     "Column13": 282,
     "Column14": 24.4,
     "Column15": 600,
     "Column16": 15.9,
     "Column17": 621,
     "Column18": 37.4,
     "Column19": 432,
     "Column20": 24.1,
     "Column21": 489,
     "Column22": 90.4,
     "Column23": 166,
     "Column24": 20.2,
     "Column25": 560,
     "Column26": 75,
     "Column27": "186=",
     "Column28": 35.2
    },
    {
     "2025 QS World University Rankings": 309,
     "Column2": 362,
     "Column3": "Universität Innsbruck",
     "Column4": "AT",
     "Column5": "Austria",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 20.3,
     "Column11": 434,
     "Column12": 7.8,
     "Column13": "601+",
     "Column14": 73.1,
     "Column15": 177,
     "Column16": 9.3,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 68,
     "Column20": 100,
     "Column21": 27,
     "Column22": 71.5,
     "Column23": 461,
     "Column24": 55.8,
     "Column25": 223,
     "Column26": 61.6,
     "Column27": "263=",
     "Column28": 35.2
    },
    {
     "2025 QS World University Rankings": 311,
     "Column2": 274,
     "Column3": "North Carolina State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 26.7,
     "Column11": 330,
     "Column12": 25.4,
     "Column13": 374,
     "Column14": 9.8,
     "Column15": "701+",
     "Column16": 63.4,
     "Column17": 181,
     "Column18": 55,
     "Column19": 338,
     "Column20": 17,
     "Column21": 585,
     "Column22": 78.1,
     "Column23": 358,
     "Column24": 21.5,
     "Column25": 532,
     "Column26": 35.7,
     "Column27": "398=",
     "Column28": 35.1
    },
    {
     "2025 QS World University Rankings": 312,
     "Column2": 304,
     "Column3": "Jagiellonian University",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 37.8,
     "Column11": 228,
     "Column12": 46.4,
     "Column13": 199,
     "Column14": 65.1,
     "Column15": 203,
     "Column16": 5.5,
     "Column17": "701+",
     "Column18": 5.7,
     "Column19": "701+",
     "Column20": 15.4,
     "Column21": 615,
     "Column22": 88.6,
     "Column23": 188,
     "Column24": 51.3,
     "Column25": 249,
     "Column26": 35.4,
     "Column27": "400=",
     "Column28": 35
    },
    {
     "2025 QS World University Rankings": 312,
     "Column2": 306,
     "Column3": "University of Johannesburg",
     "Column4": "ZA",
     "Column5": "South Africa",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.7,
     "Column11": 513,
     "Column12": 17.9,
     "Column13": 491,
     "Column14": 40.3,
     "Column15": 388,
     "Column16": 24.9,
     "Column17": 499,
     "Column18": 96.5,
     "Column19": 134,
     "Column20": 51.4,
     "Column21": 285,
     "Column22": 98,
     "Column23": 26,
     "Column24": 63.1,
     "Column25": 190,
     "Column26": 65.6,
     "Column27": 238,
     "Column28": 35
    },
    {
     "2025 QS World University Rankings": 314,
     "Column2": 312,
     "Column3": "Universität Stuttgart",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.1,
     "Column11": 386,
     "Column12": 33,
     "Column13": 286,
     "Column14": 6.8,
     "Column15": "701+",
     "Column16": 55.2,
     "Column17": 228,
     "Column18": 36,
     "Column19": 442,
     "Column20": 46.8,
     "Column21": 314,
     "Column22": 70.4,
     "Column23": 479,
     "Column24": 21.7,
     "Column25": 527,
     "Column26": 65.3,
     "Column27": "239=",
     "Column28": 34.9
    },
    {
     "2025 QS World University Rankings": 315,
     "Column2": 315,
     "Column3": "Quaid-i-Azam University",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.3,
     "Column11": 520,
     "Column12": 42.4,
     "Column13": 218,
     "Column14": 3.2,
     "Column15": "701+",
     "Column16": 93.6,
     "Column17": 58,
     "Column18": 7,
     "Column19": "701+",
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 65,
     "Column23": 557,
     "Column24": 21,
     "Column25": 546,
     "Column26": 3.4,
     "Column27": "701+",
     "Column28": 34.8
    },
    {
     "2025 QS World University Rankings": 316,
     "Column2": 342,
     "Column3": "RUDN University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 24.4,
     "Column11": 357,
     "Column12": 48.7,
     "Column13": 186,
     "Column14": 92,
     "Column15": 79,
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 8,
     "Column19": "701+",
     "Column20": 92.2,
     "Column21": 107,
     "Column22": 89.7,
     "Column23": 170,
     "Column24": 10,
     "Column25": "701+",
     "Column26": 24,
     "Column27": "498=",
     "Column28": 34.7
    },
    {
     "2025 QS World University Rankings": 489,
     "Column2": 514,
     "Column3": "Shanghai University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.3,
     "Column11": 435,
     "Column12": 13.1,
     "Column13": "601+",
     "Column14": 50.8,
     "Column15": 303,
     "Column16": 30.5,
     "Column17": 429,
     "Column18": 40.9,
     "Column19": 415,
     "Column20": 5.3,
     "Column21": "701+",
     "Column22": 58.7,
     "Column23": 649,
     "Column24": 8.9,
     "Column25": "701+",
     "Column26": 10.5,
     "Column27": "655=",
     "Column28": 24.5
    },
    {
     "2025 QS World University Rankings": 316,
     "Column2": 304,
     "Column3": "University of Notre Dame",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 25.8,
     "Column11": 344,
     "Column12": 25.2,
     "Column13": 379,
     "Column14": 60.8,
     "Column15": 237,
     "Column16": 29.2,
     "Column17": 444,
     "Column18": 22.3,
     "Column19": 566,
     "Column20": 18.4,
     "Column21": 575,
     "Column22": 70.1,
     "Column23": 484,
     "Column24": 60.2,
     "Column25": 201,
     "Column26": 68.9,
     "Column27": 225,
     "Column28": 34.7
    },
    {
     "2025 QS World University Rankings": 319,
     "Column2": 318,
     "Column3": "Simon Fraser University",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 24,
     "Column11": 367,
     "Column12": 11.4,
     "Column13": "601+",
     "Column14": 6.2,
     "Column15": "701+",
     "Column16": 36.6,
     "Column17": 374,
     "Column18": 97,
     "Column19": 131,
     "Column20": 83.9,
     "Column21": 158,
     "Column22": 76.8,
     "Column23": 382,
     "Column24": 22.8,
     "Column25": 503,
     "Column26": 89.8,
     "Column27": "96=",
     "Column28": 34.6
    },
    {
     "2025 QS World University Rankings": 320,
     "Column2": 264,
     "Column3": "University of Colorado Boulder",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 36.1,
     "Column11": 244,
     "Column12": 17.5,
     "Column13": 495,
     "Column14": 38.3,
     "Column15": 414,
     "Column16": 34.9,
     "Column17": 385,
     "Column18": 32.2,
     "Column19": 482,
     "Column20": 4.9,
     "Column21": "701+",
     "Column22": 86.3,
     "Column23": 228,
     "Column24": 31.6,
     "Column25": 400,
     "Column26": 62.8,
     "Column27": 252,
     "Column28": 34.4
    },
    {
     "2025 QS World University Rankings": 321,
     "Column2": 355,
     "Column3": "L.N. Gumilyov Eurasian National University (ENU)",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 34.4,
     "Column11": 256,
     "Column12": 62,
     "Column13": 130,
     "Column14": 92.6,
     "Column15": 76,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 68.7,
     "Column19": 289,
     "Column20": 8.2,
     "Column21": "701+",
     "Column22": 22.8,
     "Column23": "701+",
     "Column24": 5.5,
     "Column25": "701+",
     "Column26": 10.5,
     "Column27": "655=",
     "Column28": 34.2
    },
    {
     "2025 QS World University Rankings": 321,
     "Column2": 347,
     "Column3": "National Technical University of Athens",
     "Column4": "GR",
     "Column5": "Greece",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 21.3,
     "Column11": 412,
     "Column12": 44.9,
     "Column13": 204,
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 76,
     "Column17": 132,
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 4.6,
     "Column21": "701+",
     "Column22": 72.7,
     "Column23": 444,
     "Column24": 39.6,
     "Column25": 325,
     "Column26": 5,
     "Column27": "701+",
     "Column28": 34.2
    },
    {
     "2025 QS World University Rankings": 321,
     "Column2": 349,
     "Column3": "Universidad Politécnica de Madrid (UPM)",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "XL",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 31.3,
     "Column11": 283,
     "Column12": 56.5,
     "Column13": 146,
     "Column14": 16.7,
     "Column15": "701+",
     "Column16": 11.4,
     "Column17": "701+",
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 32.1,
     "Column21": 414,
     "Column22": 81.1,
     "Column23": 308,
     "Column24": 60.7,
     "Column25": 199,
     "Column26": 86.3,
     "Column27": 119,
     "Column28": 34.2
    },
    {
     "2025 QS World University Rankings": 324,
     "Column2": 278,
     "Column3": "University of Miami",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 19,
     "Column11": 475,
     "Column12": 29.5,
     "Column13": 316,
     "Column14": 50,
     "Column15": 310,
     "Column16": 39.3,
     "Column17": 358,
     "Column18": 66.7,
     "Column19": 295,
     "Column20": 16.9,
     "Column21": 587,
     "Column22": 81.6,
     "Column23": 294,
     "Column24": 43.8,
     "Column25": 292,
     "Column26": 27.9,
     "Column27": "462=",
     "Column28": 34.1
    },
    {
     "2025 QS World University Rankings": 324,
     "Column2": 326,
     "Column3": "University of Southern Denmark (SDU)",
     "Column4": "DK",
     "Column5": "Denmark",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.2,
     "Column11": 440,
     "Column12": 15.9,
     "Column13": 538,
     "Column14": 22.3,
     "Column15": 631,
     "Column16": 49.3,
     "Column17": 270,
     "Column18": 85.5,
     "Column19": 217,
     "Column20": 24.1,
     "Column21": 491,
     "Column22": 81.1,
     "Column23": 309,
     "Column24": 21.3,
     "Column25": 540,
     "Column26": 73.2,
     "Column27": "198=",
     "Column28": 34.1
    },
    {
     "2025 QS World University Rankings": 326,
     "Column2": 307,
     "Column3": "Daegu Gyeongbuk Institute of Science and Technology (DGIST)",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.5,
     "Column11": "601+",
     "Column12": 11.8,
     "Column13": "601+",
     "Column14": 84.1,
     "Column15": 113,
     "Column16": 99.9,
     "Column17": 12,
     "Column18": 6.6,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 12.3,
     "Column23": "701+",
     "Column24": 2.7,
     "Column25": "701+",
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": 34
    },
    {
     "2025 QS World University Rankings": 326,
     "Column2": 404,
     "Column3": "Istanbul Technical University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 27,
     "Column11": 327,
     "Column12": 67.2,
     "Column13": 112,
     "Column14": 9.7,
     "Column15": "701+",
     "Column16": 17,
     "Column17": 602,
     "Column18": 8.3,
     "Column19": "701+",
     "Column20": 19.8,
     "Column21": 546,
     "Column22": 76.1,
     "Column23": 388,
     "Column24": 69.1,
     "Column25": 165,
     "Column26": 70.3,
     "Column27": 221,
     "Column28": 34
    },
    {
     "2025 QS World University Rankings": 328,
     "Column2": 332,
     "Column3": "Kyung Hee University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 23.2,
     "Column11": 383,
     "Column12": 37.1,
     "Column13": 253,
     "Column14": 82.1,
     "Column15": 121,
     "Column16": 15.1,
     "Column17": 636,
     "Column18": 14.3,
     "Column19": 676,
     "Column20": 53.4,
     "Column21": 276,
     "Column22": 54.5,
     "Column23": "701+",
     "Column24": 13.8,
     "Column25": 699,
     "Column26": 80.5,
     "Column27": "152=",
     "Column28": 33.8
    },
    {
     "2025 QS World University Rankings": 328,
     "Column2": 299,
     "Column3": "Rutgers University–New Brunswick",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 36.2,
     "Column11": 243,
     "Column12": 28.1,
     "Column13": 340,
     "Column14": 46.3,
     "Column15": 333,
     "Column16": 12.8,
     "Column17": 671,
     "Column18": 18,
     "Column19": 625,
     "Column20": 28.2,
     "Column21": 443,
     "Column22": 78,
     "Column23": 361,
     "Column24": 25.7,
     "Column25": 459,
     "Column26": 95.4,
     "Column27": "55=",
     "Column28": 33.8
    },
    {
     "2025 QS World University Rankings": 328,
     "Column2": 407,
     "Column3": "University of Delhi",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 38.1,
     "Column11": 225,
     "Column12": 35.3,
     "Column13": 269,
     "Column14": 4.3,
     "Column15": "701+",
     "Column16": 25.9,
     "Column17": 488,
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 75.1,
     "Column23": 406,
     "Column24": 96,
     "Column25": 44,
     "Column26": 70.6,
     "Column27": 220,
     "Column28": 33.8
    },
    {
     "2025 QS World University Rankings": "621-630",
     "Column2": 556,
     "Column3": "Renmin (People's) University of China",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.5,
     "Column11": 459,
     "Column12": 37.3,
     "Column13": 252,
     "Column14": 16.3,
     "Column15": "701+",
     "Column16": 7.9,
     "Column17": "701+",
     "Column18": 6.6,
     "Column19": "701+",
     "Column20": 4.9,
     "Column21": "701+",
     "Column22": 24.3,
     "Column23": "701+",
     "Column24": 81.2,
     "Column25": 120,
     "Column26": 9.7,
     "Column27": "676=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": 332,
     "Column2": 364,
     "Column3": "American University of Sharjah",
     "Column4": "AE",
     "Column5": "United Arab Emirates",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 20.9,
     "Column11": 421,
     "Column12": 38.6,
     "Column13": 245,
     "Column14": 19.1,
     "Column15": 696,
     "Column16": 23.9,
     "Column17": 511,
     "Column18": 100,
     "Column19": 3,
     "Column20": 100,
     "Column21": 8,
     "Column22": 48.1,
     "Column23": "701+",
     "Column24": 48,
     "Column25": 267,
     "Column26": 16.5,
     "Column27": "567=",
     "Column28": 33.6
    },
    {
     "2025 QS World University Rankings": 332,
     "Column2": 295,
     "Column3": "University of East Anglia (UEA)",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.2,
     "Column11": 522,
     "Column12": 7.2,
     "Column13": "601+",
     "Column14": 15.2,
     "Column15": "701+",
     "Column16": 58.7,
     "Column17": 209,
     "Column18": 66.8,
     "Column19": 294,
     "Column20": 32.2,
     "Column21": 412,
     "Column22": 87.9,
     "Column23": 199,
     "Column24": 19.2,
     "Column25": 575,
     "Column26": 91.4,
     "Column27": "81=",
     "Column28": 33.6
    },
    {
     "2025 QS World University Rankings": 334,
     "Column2": 294,
     "Column3": "Université Grenoble Alpes",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 44.7,
     "Column11": 190,
     "Column12": 14.3,
     "Column13": 574,
     "Column14": 7.9,
     "Column15": "701+",
     "Column16": 41.7,
     "Column17": 330,
     "Column18": 20.2,
     "Column19": 594,
     "Column20": 35.1,
     "Column21": 389,
     "Column22": 94.9,
     "Column23": 78,
     "Column24": 20.7,
     "Column25": 552,
     "Column26": 21.5,
     "Column27": "518=",
     "Column28": 33.5
    },
    {
     "2025 QS World University Rankings": 335,
     "Column2": 369,
     "Column3": "Indian Institute of Technology Roorkee (IITR)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.4,
     "Column11": 462,
     "Column12": 29,
     "Column13": 325,
     "Column14": 6.8,
     "Column15": "701+",
     "Column16": 93.8,
     "Column17": 55,
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 44.3,
     "Column23": "701+",
     "Column24": 6.4,
     "Column25": "701+",
     "Column26": 37.9,
     "Column27": "386=",
     "Column28": 33.4
    },
    {
     "2025 QS World University Rankings": 336,
     "Column2": 380,
     "Column3": "Flinders University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.5,
     "Column11": "601+",
     "Column12": 5.6,
     "Column13": "601+",
     "Column14": 7.2,
     "Column15": "701+",
     "Column16": 51.8,
     "Column17": 251,
     "Column18": 97.2,
     "Column19": 127,
     "Column20": 88.8,
     "Column21": 131,
     "Column22": 80.8,
     "Column23": 311,
     "Column24": 7.4,
     "Column25": "701+",
     "Column26": 87.4,
     "Column27": 112,
     "Column28": 33.3
    },
    {
     "2025 QS World University Rankings": 336,
     "Column2": 351,
     "Column3": "Lappeenranta-Lahti University of Technology LUT",
     "Column4": "FI",
     "Column5": "Finland",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.4,
     "Column11": "601+",
     "Column12": 9.4,
     "Column13": "601+",
     "Column14": 9.5,
     "Column15": "701+",
     "Column16": 97.6,
     "Column17": 41,
     "Column18": 56.8,
     "Column19": 334,
     "Column20": 28.5,
     "Column21": 440,
     "Column22": 55.6,
     "Column23": 682,
     "Column24": 7.9,
     "Column25": "701+",
     "Column26": 55.8,
     "Column27": 284,
     "Column28": 33.3
    },
    {
     "2025 QS World University Rankings": 295,
     "Column2": 291,
     "Column3": "Xi’an Jiaotong University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19,
     "Column11": 474,
     "Column12": 39.4,
     "Column13": 238,
     "Column14": 14,
     "Column15": "701+",
     "Column16": 89.5,
     "Column17": 77,
     "Column18": 3.9,
     "Column19": "701+",
     "Column20": 3.8,
     "Column21": "701+",
     "Column22": 80.5,
     "Column23": 315,
     "Column24": 26.4,
     "Column25": 448,
     "Column26": 18.2,
     "Column27": "553=",
     "Column28": 36.9
    },
    {
     "2025 QS World University Rankings": 336,
     "Column2": 404,
     "Column3": "University of the Philippines",
     "Column4": "PH",
     "Column5": "Philippines",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 36.3,
     "Column11": 239,
     "Column12": 65.3,
     "Column13": 119,
     "Column14": 31.1,
     "Column15": 506,
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 1.6,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 60.9,
     "Column23": 624,
     "Column24": 65.6,
     "Column25": 177,
     "Column26": 67.7,
     "Column27": 230,
     "Column28": 33.3
    },
    {
     "2025 QS World University Rankings": 340,
     "Column2": 345,
     "Column3": "University of Saskatchewan",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.1,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 18.2,
     "Column15": "701+",
     "Column16": 57,
     "Column17": 219,
     "Column18": 77.3,
     "Column19": 251,
     "Column20": 49.5,
     "Column21": 298,
     "Column22": 82.3,
     "Column23": 287,
     "Column24": 32.1,
     "Column25": 394,
     "Column26": 90.7,
     "Column27": "89=",
     "Column28": 33.2
    },
    {
     "2025 QS World University Rankings": 340,
     "Column2": 326,
     "Column3": "University of South Australia",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 21.3,
     "Column11": 411,
     "Column12": 12.7,
     "Column13": "601+",
     "Column14": 24.9,
     "Column15": 594,
     "Column16": 31.9,
     "Column17": 413,
     "Column18": 99.8,
     "Column19": 92,
     "Column20": 50.9,
     "Column21": 290,
     "Column22": 85.5,
     "Column23": 238,
     "Column24": 15.5,
     "Column25": 644,
     "Column26": 83.6,
     "Column27": "132=",
     "Column28": 33.2
    },
    {
     "2025 QS World University Rankings": 342,
     "Column2": 343,
     "Column3": "Brunel University London",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.1,
     "Column11": "601+",
     "Column12": 13.3,
     "Column13": "601+",
     "Column14": 7.1,
     "Column15": "701+",
     "Column16": 46.5,
     "Column17": 297,
     "Column18": 99.1,
     "Column19": 102,
     "Column20": 99.8,
     "Column21": 37,
     "Column22": 77.9,
     "Column23": 363,
     "Column24": 19.9,
     "Column25": 564,
     "Column26": 71.2,
     "Column27": "214=",
     "Column28": 33
    },
    {
     "2025 QS World University Rankings": 342,
     "Column2": 334,
     "Column3": "Sharif University of Technology",
     "Column4": "IR",
     "Column5": "Iran, Islamic Republic of",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.5,
     "Column11": "601+",
     "Column12": 33.6,
     "Column13": 278,
     "Column14": 5.1,
     "Column15": "701+",
     "Column16": 99.9,
     "Column17": 13,
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 61.4,
     "Column23": 610,
     "Column24": 28.2,
     "Column25": 436,
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": 33
    },
    {
     "2025 QS World University Rankings": 344,
     "Column2": 364,
     "Column3": "Indian Institute of Technology Guwahati (IITG)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.8,
     "Column11": 480,
     "Column12": 32,
     "Column13": 295,
     "Column14": 6.4,
     "Column15": "701+",
     "Column16": 97.6,
     "Column17": 42,
     "Column18": 2.3,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 51.2,
     "Column23": "701+",
     "Column24": 4.9,
     "Column25": "701+",
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": 32.9
    },
    {
     "2025 QS World University Rankings": 344,
     "Column2": 379,
     "Column3": "Tufts University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 16.9,
     "Column11": 533,
     "Column12": 7.7,
     "Column13": "601+",
     "Column14": 53.5,
     "Column15": 285,
     "Column16": 45.7,
     "Column17": 303,
     "Column18": 23.5,
     "Column19": 554,
     "Column20": 38.1,
     "Column21": 369,
     "Column22": 60.2,
     "Column23": 632,
     "Column24": 56.1,
     "Column25": 220,
     "Column26": 81,
     "Column27": 151,
     "Column28": 32.9
    },
    {
     "2025 QS World University Rankings": 344,
     "Column2": 313,
     "Column3": "University of Oulu",
     "Column4": "FI",
     "Column5": "Finland",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.1,
     "Column11": 582,
     "Column12": 13.5,
     "Column13": 600,
     "Column14": 25.3,
     "Column15": 587,
     "Column16": 59.4,
     "Column17": 207,
     "Column18": 65.3,
     "Column19": 300,
     "Column20": 8.6,
     "Column21": "701+",
     "Column22": 89.4,
     "Column23": 175,
     "Column24": 21.7,
     "Column25": 528,
     "Column26": 68.6,
     "Column27": 226,
     "Column28": 32.9
    },
    {
     "2025 QS World University Rankings": 347,
     "Column2": 371,
     "Column3": "Tilburg University",
     "Column4": "NL",
     "Column5": "Netherlands",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.7,
     "Column11": 594,
     "Column12": 32.5,
     "Column13": 291,
     "Column14": 10.5,
     "Column15": "701+",
     "Column16": 55.1,
     "Column17": 229,
     "Column18": 98.4,
     "Column19": 114,
     "Column20": 60.6,
     "Column21": 245,
     "Column22": 56.8,
     "Column23": 667,
     "Column24": 14.3,
     "Column25": 685,
     "Column26": 15.2,
     "Column27": "590=",
     "Column28": 32.8
    },
    {
     "2025 QS World University Rankings": 347,
     "Column2": 335,
     "Column3": "University of Naples - Federico II",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 36.6,
     "Column11": 236,
     "Column12": 19.7,
     "Column13": 461,
     "Column14": 4.2,
     "Column15": "701+",
     "Column16": 65.1,
     "Column17": 172,
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 92.5,
     "Column23": 129,
     "Column24": 7.3,
     "Column25": "701+",
     "Column26": 20.1,
     "Column27": "530=",
     "Column28": 32.8
    },
    {
     "2025 QS World University Rankings": 349,
     "Column2": 322,
     "Column3": "University of Victoria (UVic)",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17,
     "Column11": 530,
     "Column12": 7.7,
     "Column13": "601+",
     "Column14": 3.9,
     "Column15": "701+",
     "Column16": 62.5,
     "Column17": 188,
     "Column18": 94.7,
     "Column19": 152,
     "Column20": 40.9,
     "Column21": 350,
     "Column22": 54.2,
     "Column23": "701+",
     "Column24": 11.3,
     "Column25": "701+",
     "Column26": 82.9,
     "Column27": "139=",
     "Column28": 32.6
    },
    {
     "2025 QS World University Rankings": 350,
     "Column2": 371,
     "Column3": "Cairo University",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 45.8,
     "Column11": 179,
     "Column12": 41,
     "Column13": 229,
     "Column14": 8.8,
     "Column15": "701+",
     "Column16": 3,
     "Column17": "701+",
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 7.2,
     "Column21": "701+",
     "Column22": 86.4,
     "Column23": 223,
     "Column24": 95.5,
     "Column25": 50,
     "Column26": 47.2,
     "Column27": 329,
     "Column28": 32.5
    },
    {
     "2025 QS World University Rankings": 350,
     "Column2": 319,
     "Column3": "Sciences Po ",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16.8,
     "Column11": 535,
     "Column12": 34.8,
     "Column13": 271,
     "Column14": 81.1,
     "Column15": 125,
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 52.7,
     "Column19": 354,
     "Column20": 97.2,
     "Column21": 78,
     "Column22": 31.4,
     "Column23": "701+",
     "Column24": 97.7,
     "Column25": 30,
     "Column26": 7.2,
     "Column27": "701+",
     "Column28": 32.5
    },
    {
     "2025 QS World University Rankings": 352,
     "Column2": 328,
     "Column3": "City, University of London",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.8,
     "Column11": 422,
     "Column12": 21.3,
     "Column13": 430,
     "Column14": 5.6,
     "Column15": "701+",
     "Column16": 31.2,
     "Column17": 421,
     "Column18": 95.8,
     "Column19": 140,
     "Column20": 98.9,
     "Column21": 59,
     "Column22": 66.4,
     "Column23": 531,
     "Column24": 54.8,
     "Column25": 226,
     "Column26": 23.2,
     "Column27": "505=",
     "Column28": 32.4
    },
    {
     "2025 QS World University Rankings": 353,
     "Column2": 367,
     "Column3": "National University of Sciences And Technology (NUST) Islamabad",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.4,
     "Column11": 430,
     "Column12": 71.6,
     "Column13": 98,
     "Column14": 68.8,
     "Column15": 188,
     "Column16": 14.5,
     "Column17": 646,
     "Column18": 4.3,
     "Column19": "701+",
     "Column20": 4,
     "Column21": "701+",
     "Column22": 73.6,
     "Column23": 426,
     "Column24": 2.8,
     "Column25": "701+",
     "Column26": 44.9,
     "Column27": "338=",
     "Column28": 32.3
    },
    {
     "2025 QS World University Rankings": 354,
     "Column2": 323,
     "Column3": "University of Pretoria",
     "Column4": "ZA",
     "Column5": "South Africa",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.9,
     "Column11": 368,
     "Column12": 48.8,
     "Column13": 185,
     "Column14": 4,
     "Column15": "701+",
     "Column16": 15.5,
     "Column17": 629,
     "Column18": 33.3,
     "Column19": 474,
     "Column20": 8.2,
     "Column21": "701+",
     "Column22": 98.1,
     "Column23": 24,
     "Column24": 80.1,
     "Column25": 122,
     "Column26": 79.6,
     "Column27": "156=",
     "Column28": 32.2
    },
    {
     "2025 QS World University Rankings": 355,
     "Column2": 313,
     "Column3": "Indiana University Bloomington",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 33.4,
     "Column11": 261,
     "Column12": 23.6,
     "Column13": 403,
     "Column14": 23.3,
     "Column15": 618,
     "Column16": 29.1,
     "Column17": 445,
     "Column18": 36.3,
     "Column19": 438,
     "Column20": 10.8,
     "Column21": "701+",
     "Column22": 86.6,
     "Column23": 221,
     "Column24": 56.8,
     "Column25": 213,
     "Column26": 32.7,
     "Column27": "424=",
     "Column28": 32.1
    },
    {
     "2025 QS World University Rankings": 355,
     "Column2": 351,
     "Column3": "University of Coimbra",
     "Column4": "PT",
     "Column5": "Portugal",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 29.4,
     "Column11": 301,
     "Column12": 10.8,
     "Column13": "601+",
     "Column14": 10.3,
     "Column15": "701+",
     "Column16": 49.8,
     "Column17": 264,
     "Column18": 8.1,
     "Column19": "701+",
     "Column20": 22.9,
     "Column21": 504,
     "Column22": 91.2,
     "Column23": 154,
     "Column24": 31.1,
     "Column25": 404,
     "Column26": 75.8,
     "Column27": "181=",
     "Column28": 32.1
    },
    {
     "2025 QS World University Rankings": 355,
     "Column2": 381,
     "Column3": "University of Luxembourg",
     "Column4": "LU",
     "Column5": "Luxembourg",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column10": 9.2,
     "Column11": "601+",
     "Column12": 4.5,
     "Column13": "601+",
     "Column14": 80.8,
     "Column15": 128,
     "Column16": 25.2,
     "Column17": 495,
     "Column18": 100,
     "Column19": 16,
     "Column20": 100,
     "Column21": 26,
     "Column22": 70.1,
     "Column23": 486,
     "Column24": 52.8,
     "Column25": 240,
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": 32.1
    },
    {
     "2025 QS World University Rankings": 358,
     "Column2": 358,
     "Column3": "University of Tartu",
     "Column4": "EE",
     "Column5": "Estonia",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 22.9,
     "Column11": 391,
     "Column12": 10.5,
     "Column13": "601+",
     "Column14": 93.7,
     "Column15": 71,
     "Column16": 10.8,
     "Column17": "701+",
     "Column18": 48.6,
     "Column19": 372,
     "Column20": 15.1,
     "Column21": 621,
     "Column22": 88.8,
     "Column23": 186,
     "Column24": 85.7,
     "Column25": 98,
     "Column26": 18,
     "Column27": "555=",
     "Column28": 32
    },
    {
     "2025 QS World University Rankings": 359,
     "Column2": 328,
     "Column3": "Gwangju Institute of Science and Technology (GIST)",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.8,
     "Column11": "601+",
     "Column12": 9,
     "Column13": "601+",
     "Column14": 38.6,
     "Column15": 409,
     "Column16": 100,
     "Column17": 4,
     "Column18": 18.6,
     "Column19": 619,
     "Column20": 8.8,
     "Column21": "701+",
     "Column22": 27,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 9.9,
     "Column27": "667=",
     "Column28": 31.9
    },
    {
     "2025 QS World University Rankings": 359,
     "Column2": 336,
     "Column3": "Pontificia Universidad Católica del Perú",
     "Column4": "PE",
     "Column5": "Peru",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 48.4,
     "Column11": 169,
     "Column12": 61.6,
     "Column13": 132,
     "Column14": 33.2,
     "Column15": 475,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 4,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 38.1,
     "Column23": "701+",
     "Column24": 33,
     "Column25": 385,
     "Column26": 29.6,
     "Column27": "447=",
     "Column28": 31.9
    },
    {
     "2025 QS World University Rankings": 359,
     "Column2": 344,
     "Column3": "University of Aveiro",
     "Column4": "PT",
     "Column5": "Portugal",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.9,
     "Column11": "601+",
     "Column12": 5.6,
     "Column13": "601+",
     "Column14": 11.1,
     "Column15": "701+",
     "Column16": 90.6,
     "Column17": 73,
     "Column18": 4.2,
     "Column19": "701+",
     "Column20": 15.3,
     "Column21": 617,
     "Column22": 89,
     "Column23": 183,
     "Column24": 2.7,
     "Column25": "701+",
     "Column26": 57.7,
     "Column27": "276=",
     "Column28": 31.9
    },
    {
     "2025 QS World University Rankings": 362,
     "Column2": 454,
     "Column3": "Sultan Qaboos University",
     "Column4": "OM",
     "Column5": "Oman",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.6,
     "Column11": 484,
     "Column12": 17.1,
     "Column13": 503,
     "Column14": 55.6,
     "Column15": 269,
     "Column16": 21.9,
     "Column17": 533,
     "Column18": 100,
     "Column19": 72,
     "Column20": 3.2,
     "Column21": "701+",
     "Column22": 78.8,
     "Column23": 340,
     "Column24": 51.5,
     "Column25": 247,
     "Column26": 55.4,
     "Column27": "285=",
     "Column28": 31.7
    },
    {
     "2025 QS World University Rankings": 377,
     "Column2": 384,
     "Column3": "Nankai University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.5,
     "Column11": 492,
     "Column12": 9.8,
     "Column13": "601+",
     "Column14": 25.4,
     "Column15": 584,
     "Column16": 82.9,
     "Column17": 101,
     "Column18": 12.3,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 51.1,
     "Column23": "701+",
     "Column24": 37.9,
     "Column25": 340,
     "Column26": 11.5,
     "Column27": "632=",
     "Column28": 31.1
    },
    {
     "2025 QS World University Rankings": 362,
     "Column2": 353,
     "Column3": "York University",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 22.7,
     "Column11": 394,
     "Column12": 18.6,
     "Column13": 476,
     "Column14": 3.9,
     "Column15": "701+",
     "Column16": 22.6,
     "Column17": 524,
     "Column18": 53.1,
     "Column19": 353,
     "Column20": 78.7,
     "Column21": 176,
     "Column22": 65.5,
     "Column23": 548,
     "Column24": 76.3,
     "Column25": 136,
     "Column26": 86.5,
     "Column27": 118,
     "Column28": 31.7
    },
    {
     "2025 QS World University Rankings": 365,
     "Column2": 315,
     "Column3": "Saint Petersburg State University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 36.3,
     "Column11": 238,
     "Column12": 30.5,
     "Column13": 309,
     "Column14": 59.4,
     "Column15": 247,
     "Column16": 4.6,
     "Column17": "701+",
     "Column18": 4.9,
     "Column19": "701+",
     "Column20": 38.9,
     "Column21": 360,
     "Column22": 83.9,
     "Column23": 263,
     "Column24": 71.7,
     "Column25": 150,
     "Column26": 3.2,
     "Column27": "701+",
     "Column28": 31.6
    },
    {
     "2025 QS World University Rankings": 365,
     "Column2": 323,
     "Column3": "University of Illinois at Chicago (UIC)",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 38.5,
     "Column11": 223,
     "Column12": 28.3,
     "Column13": 336,
     "Column14": 31.1,
     "Column15": 505,
     "Column16": 23.3,
     "Column17": 518,
     "Column18": 8,
     "Column19": "701+",
     "Column20": 19.3,
     "Column21": 560,
     "Column22": 83.8,
     "Column23": 265,
     "Column24": 30.5,
     "Column25": 414,
     "Column26": 34.8,
     "Column27": "406=",
     "Column28": 31.6
    },
    {
     "2025 QS World University Rankings": 367,
     "Column2": 384,
     "Column3": "Westfälische Wilhelms-Universität Münster ",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 31.6,
     "Column11": 281,
     "Column12": 22.8,
     "Column13": 412,
     "Column14": 64.5,
     "Column15": 208,
     "Column16": 10,
     "Column17": "701+",
     "Column18": 30.5,
     "Column19": 490,
     "Column20": 4.5,
     "Column21": "701+",
     "Column22": 82,
     "Column23": 290,
     "Column24": 35.8,
     "Column25": 354,
     "Column26": 66.8,
     "Column27": 234,
     "Column28": 31.5
    },
    {
     "2025 QS World University Rankings": 368,
     "Column2": 382,
     "Column3": "Mahidol University",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 44.1,
     "Column11": 192,
     "Column12": 21.8,
     "Column13": 424,
     "Column14": 81.1,
     "Column15": 126,
     "Column16": 4.7,
     "Column17": "701+",
     "Column18": 4.9,
     "Column19": "701+",
     "Column20": 4.2,
     "Column21": "701+",
     "Column22": 57.2,
     "Column23": 659,
     "Column24": 9.8,
     "Column25": "701+",
     "Column26": 57.7,
     "Column27": "276=",
     "Column28": 31.4
    },
    {
     "2025 QS World University Rankings": 368,
     "Column2": 498,
     "Column3": "University of Jordan",
     "Column4": "JO",
     "Column5": "Jordan",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 32.9,
     "Column11": 268,
     "Column12": 46.7,
     "Column13": 197,
     "Column14": 19.5,
     "Column15": 685,
     "Column16": 3.5,
     "Column17": "701+",
     "Column18": 21.2,
     "Column19": 585,
     "Column20": 43.5,
     "Column21": 331,
     "Column22": 67,
     "Column23": 521,
     "Column24": 82.1,
     "Column25": 110,
     "Column26": 39.5,
     "Column27": "371=",
     "Column28": 31.4
    },
    {
     "2025 QS World University Rankings": 368,
     "Column2": 360,
     "Column3": "University of Tehran",
     "Column4": "IR",
     "Column5": "Iran, Islamic Republic of",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.6,
     "Column11": 429,
     "Column12": 26.8,
     "Column13": 357,
     "Column14": 3,
     "Column15": "701+",
     "Column16": 79.1,
     "Column17": 115,
     "Column18": 2,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 86.2,
     "Column23": 231,
     "Column24": 23.1,
     "Column25": 494,
     "Column26": 4.7,
     "Column27": "701+",
     "Column28": 31.4
    },
    {
     "2025 QS World University Rankings": 371,
     "Column2": 340,
     "Column3": "George Washington University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 25.2,
     "Column11": 351,
     "Column12": 28.8,
     "Column13": 331,
     "Column14": 27.3,
     "Column15": 555,
     "Column16": 27.5,
     "Column17": 471,
     "Column18": 24.6,
     "Column19": 542,
     "Column20": 34.4,
     "Column21": 394,
     "Column22": 79.5,
     "Column23": 333,
     "Column24": 74.5,
     "Column25": 142,
     "Column26": 27.3,
     "Column27": "468=",
     "Column28": 31.3
    },
    {
     "2025 QS World University Rankings": 371,
     "Column2": 362,
     "Column3": "Lincoln University ",
     "Column4": "NZ",
     "Column5": "New Zealand",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.2,
     "Column11": "601+",
     "Column12": 5,
     "Column13": "601+",
     "Column14": 31.8,
     "Column15": 496,
     "Column16": 52.1,
     "Column17": 249,
     "Column18": 100,
     "Column19": 61,
     "Column20": 96.3,
     "Column21": 88,
     "Column22": 20.2,
     "Column23": "701+",
     "Column24": 40,
     "Column25": 320,
     "Column26": 48.9,
     "Column27": "324=",
     "Column28": 31.3
    },
    {
     "2025 QS World University Rankings": 371,
     "Column2": 354,
     "Column3": "Universitat Politècnica de Catalunya · BarcelonaTech (UPC)",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 31.8,
     "Column11": 277,
     "Column12": 27.7,
     "Column13": 347,
     "Column14": 38.9,
     "Column15": 404,
     "Column16": 16.7,
     "Column17": 609,
     "Column18": 10.1,
     "Column19": "701+",
     "Column20": 19.8,
     "Column21": 544,
     "Column22": 85.3,
     "Column23": 242,
     "Column24": 26.3,
     "Column25": 450,
     "Column26": 82.5,
     "Column27": "143=",
     "Column28": 31.3
    },
    {
     "2025 QS World University Rankings": 371,
     "Column2": 364,
     "Column3": "University of Turin",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 41.5,
     "Column11": 205,
     "Column12": 7.2,
     "Column13": "601+",
     "Column14": 3.2,
     "Column15": "701+",
     "Column16": 33.3,
     "Column17": 398,
     "Column18": 4.1,
     "Column19": "701+",
     "Column20": 5.3,
     "Column21": "701+",
     "Column22": 87.5,
     "Column23": 204,
     "Column24": 51.7,
     "Column25": 245,
     "Column26": 83.6,
     "Column27": "132=",
     "Column28": 31.3
    },
    {
     "2025 QS World University Rankings": 375,
     "Column2": 358,
     "Column3": "University of Florence",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 34.6,
     "Column11": 255,
     "Column12": 8.4,
     "Column13": "601+",
     "Column14": 4.4,
     "Column15": "701+",
     "Column16": 46.6,
     "Column17": 295,
     "Column18": 7.3,
     "Column19": "701+",
     "Column20": 12.3,
     "Column21": 671,
     "Column22": 88.5,
     "Column23": 189,
     "Column24": 30.3,
     "Column25": 415,
     "Column26": 74.5,
     "Column27": "193=",
     "Column28": 31.2
    },
    {
     "2025 QS World University Rankings": 375,
     "Column2": 315,
     "Column3": "University of Turku",
     "Column4": "FI",
     "Column5": "Finland",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 27.8,
     "Column11": 317,
     "Column12": 12.1,
     "Column13": "601+",
     "Column14": 52.3,
     "Column15": 291,
     "Column16": 29.3,
     "Column17": 442,
     "Column18": 33.2,
     "Column19": 477,
     "Column20": 4.4,
     "Column21": "701+",
     "Column22": 86.3,
     "Column23": 229,
     "Column24": 39.9,
     "Column25": 321,
     "Column26": 52.5,
     "Column27": "302=",
     "Column28": 31.2
    },
    {
     "2025 QS World University Rankings": 269,
     "Column2": 285,
     "Column3": "Tianjin University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.2,
     "Column11": 527,
     "Column12": 13.5,
     "Column13": 597,
     "Column14": 38.8,
     "Column15": 407,
     "Column16": 99.5,
     "Column17": 18,
     "Column18": 24.8,
     "Column19": 538,
     "Column20": 25.4,
     "Column21": 473,
     "Column22": 65.2,
     "Column23": 554,
     "Column24": 32.4,
     "Column25": 392,
     "Column26": 16.9,
     "Column27": "563=",
     "Column28": 38.6
    },
    {
     "2025 QS World University Rankings": 377,
     "Column2": 347,
     "Column3": "Pontificia Universidad Javeriana",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 45.3,
     "Column11": 184,
     "Column12": 56.6,
     "Column13": 145,
     "Column14": 26.3,
     "Column15": 568,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 3.4,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 42.2,
     "Column23": "701+",
     "Column24": 78.3,
     "Column25": 127,
     "Column26": 11.3,
     "Column27": "636=",
     "Column28": 31.1
    },
    {
     "2025 QS World University Rankings": 377,
     "Column2": 355,
     "Column3": "University of Tsukuba",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 36.6,
     "Column11": 237,
     "Column12": 22.5,
     "Column13": 416,
     "Column14": 54.7,
     "Column15": 278,
     "Column16": 17.3,
     "Column17": 594,
     "Column18": 12.4,
     "Column19": "701+",
     "Column20": 19.3,
     "Column21": 561,
     "Column22": 66.9,
     "Column23": 525,
     "Column24": 9.8,
     "Column25": "701+",
     "Column26": 63.4,
     "Column27": 249,
     "Column28": 31.1
    },
    {
     "2025 QS World University Rankings": 380,
     "Column2": "1401+",
     "Column3": "Technische Universität Bergakademie Freiberg",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 4.8,
     "Column13": "601+",
     "Column14": 3.5,
     "Column15": "701+",
     "Column16": 99.1,
     "Column17": 24,
     "Column18": 41.4,
     "Column19": 414,
     "Column20": 100,
     "Column21": 25,
     "Column22": 41.6,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": 30.8
    },
    {
     "2025 QS World University Rankings": 380,
     "Column2": 336,
     "Column3": "University of Kent",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.4,
     "Column11": 571,
     "Column12": 10,
     "Column13": "601+",
     "Column14": 14.6,
     "Column15": "701+",
     "Column16": 45.7,
     "Column17": 301,
     "Column18": 95.1,
     "Column19": 147,
     "Column20": 49.8,
     "Column21": 296,
     "Column22": 78.8,
     "Column23": 339,
     "Column24": 34,
     "Column25": 372,
     "Column26": 40.4,
     "Column27": "363=",
     "Column28": 30.8
    },
    {
     "2025 QS World University Rankings": 382,
     "Column2": 349,
     "Column3": "University of Pisa",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 40.4,
     "Column11": 212,
     "Column12": 15.1,
     "Column13": 556,
     "Column14": 8.6,
     "Column15": "701+",
     "Column16": 38.8,
     "Column17": 359,
     "Column18": 5.9,
     "Column19": "701+",
     "Column20": 4.2,
     "Column21": "701+",
     "Column22": 88.3,
     "Column23": 193,
     "Column24": 15,
     "Column25": 665,
     "Column26": 56.7,
     "Column27": 281,
     "Column28": 30.7
    },
    {
     "2025 QS World University Rankings": 383,
     "Column2": 427,
     "Column3": "Anna University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10,
     "Column11": "601+",
     "Column12": 12,
     "Column13": "601+",
     "Column14": 5.5,
     "Column15": "701+",
     "Column16": 100,
     "Column17": 2,
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 89.2,
     "Column23": 181,
     "Column24": 5.9,
     "Column25": "701+",
     "Column26": 24.7,
     "Column27": 491,
     "Column28": 30.6
    },
    {
     "2025 QS World University Rankings": 384,
     "Column2": 375,
     "Column3": " Western Sydney University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.9,
     "Column11": 445,
     "Column12": 14.2,
     "Column13": 579,
     "Column14": 4.8,
     "Column15": "701+",
     "Column16": 31,
     "Column17": 425,
     "Column18": 100,
     "Column19": 53,
     "Column20": 45.5,
     "Column21": 323,
     "Column22": 87.4,
     "Column23": 208,
     "Column24": 8.9,
     "Column25": "701+",
     "Column26": 89.2,
     "Column27": "101=",
     "Column28": 30.5
    },
    {
     "2025 QS World University Rankings": 316,
     "Column2": 360,
     "Column3": "Shandong University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16.6,
     "Column11": 538,
     "Column12": 35.5,
     "Column13": 268,
     "Column14": 16.7,
     "Column15": "701+",
     "Column16": 88.2,
     "Column17": 81,
     "Column18": 8.2,
     "Column19": "701+",
     "Column20": 3.1,
     "Column21": "701+",
     "Column22": 64.1,
     "Column23": 572,
     "Column24": 20.7,
     "Column25": 553,
     "Column26": 19.7,
     "Column27": "537=",
     "Column28": 34.7
    },
    {
     "2025 QS World University Rankings": 385,
     "Column2": 387,
     "Column3": "Universiti Brunei Darussalam (UBD)",
     "Column4": "BN",
     "Column5": "Brunei",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.2,
     "Column11": 438,
     "Column12": 8.8,
     "Column13": "601+",
     "Column14": 92.9,
     "Column15": 73,
     "Column16": 9.9,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 62,
     "Column20": 46.5,
     "Column21": 318,
     "Column22": 35.4,
     "Column23": "701+",
     "Column24": 56.5,
     "Column25": 217,
     "Column26": 12.7,
     "Column27": "615=",
     "Column28": 30.4
    },
    {
     "2025 QS World University Rankings": 387,
     "Column2": 387,
     "Column3": "Belarusian State University",
     "Column4": "BY",
     "Column5": "Belarus",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 28.4,
     "Column11": 308,
     "Column12": 40.7,
     "Column13": 230,
     "Column14": 93,
     "Column15": 72,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 59.3,
     "Column21": 250,
     "Column22": 36.4,
     "Column23": "701+",
     "Column24": 38.2,
     "Column25": 338,
     "Column26": 1,
     "Column27": "701+",
     "Column28": 30.3
    },
    {
     "2025 QS World University Rankings": 388,
     "Column2": 400,
     "Column3": "Universidade Nova de Lisboa",
     "Column4": "PT",
     "Column5": "Portugal",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 28.7,
     "Column11": 305,
     "Column12": 21.8,
     "Column13": 425,
     "Column14": 15.9,
     "Column15": "701+",
     "Column16": 23.9,
     "Column17": 509,
     "Column18": 25.3,
     "Column19": 528,
     "Column20": 57,
     "Column21": 260,
     "Column22": 86.3,
     "Column23": 227,
     "Column24": 48.7,
     "Column25": 264,
     "Column26": 38.2,
     "Column27": "384=",
     "Column28": 30.2
    },
    {
     "2025 QS World University Rankings": 389,
     "Column2": 595,
     "Column3": "Università Vita-Salute San Raffaele",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 3.3,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 94.4,
     "Column15": 66,
     "Column16": 88.5,
     "Column17": 80,
     "Column18": 4.4,
     "Column19": "701+",
     "Column20": 4.3,
     "Column21": "701+",
     "Column22": 39.2,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": 30.1
    },
    {
     "2025 QS World University Rankings": 389,
     "Column2": 368,
     "Column3": "University of Cyprus (UCY)",
     "Column4": "CY",
     "Column5": "Cyprus",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10,
     "Column11": "601+",
     "Column12": 4.7,
     "Column13": "601+",
     "Column14": 4.5,
     "Column15": "701+",
     "Column16": 75,
     "Column17": 135,
     "Column18": 93.8,
     "Column19": 162,
     "Column20": 20,
     "Column21": 541,
     "Column22": 73.4,
     "Column23": 429,
     "Column24": 42.1,
     "Column25": 308,
     "Column26": 5.8,
     "Column27": "701+",
     "Column28": 30.1
    },
    {
     "2025 QS World University Rankings": 389,
     "Column2": 302,
     "Column3": "Virginia Polytechnic Institute and State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 26.4,
     "Column11": 336,
     "Column12": 26.2,
     "Column13": 364,
     "Column14": 18.9,
     "Column15": "701+",
     "Column16": 28.4,
     "Column17": 459,
     "Column18": 71.8,
     "Column19": 277,
     "Column20": 16.5,
     "Column21": 594,
     "Column22": 82.6,
     "Column23": 282,
     "Column24": 24,
     "Column25": 471,
     "Column26": 34.8,
     "Column27": "406=",
     "Column28": 30.1
    },
    {
     "2025 QS World University Rankings": 392,
     "Column2": 387,
     "Column3": "National Taiwan University of Science and Technology (Taiwan Tech)",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 27.6,
     "Column11": 320,
     "Column12": 37.9,
     "Column13": 248,
     "Column14": 43.5,
     "Column15": 358,
     "Column16": 29.4,
     "Column17": 439,
     "Column18": 19.7,
     "Column19": 603,
     "Column20": 25.5,
     "Column21": 471,
     "Column22": 29.8,
     "Column23": "701+",
     "Column24": 19.9,
     "Column25": 563,
     "Column26": 38.8,
     "Column27": "379=",
     "Column28": 30
    },
    {
     "2025 QS World University Rankings": 393,
     "Column2": 444,
     "Column3": "National and Kapodistrian University of Athens",
     "Column4": "GR",
     "Column5": "Greece",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 26.7,
     "Column11": 329,
     "Column12": 33.2,
     "Column13": 283,
     "Column14": 2.1,
     "Column15": "701+",
     "Column16": 31,
     "Column17": 426,
     "Column18": 9.9,
     "Column19": "701+",
     "Column20": 15.9,
     "Column21": 609,
     "Column22": 86.6,
     "Column23": 222,
     "Column24": 85.8,
     "Column25": 96,
     "Column26": 29.3,
     "Column27": "451=",
     "Column28": 29.9
    },
    {
     "2025 QS World University Rankings": 393,
     "Column2": 332,
     "Column3": "University of California, Santa Cruz",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.7,
     "Column11": 512,
     "Column12": 5.9,
     "Column13": "601+",
     "Column14": 7.4,
     "Column15": "701+",
     "Column16": 65.3,
     "Column17": 170,
     "Column18": 53.4,
     "Column19": 348,
     "Column20": 7,
     "Column21": "701+",
     "Column22": 64.5,
     "Column23": 566,
     "Column24": 6.1,
     "Column25": "701+",
     "Column26": 83.6,
     "Column27": "132=",
     "Column28": 29.9
    },
    {
     "2025 QS World University Rankings": 393,
     "Column2": 489,
     "Column3": "University of Rome \"Tor Vergata\"",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 31.3,
     "Column11": 285,
     "Column12": 15.4,
     "Column13": 547,
     "Column14": 9,
     "Column15": "701+",
     "Column16": 47.2,
     "Column17": 287,
     "Column18": 9.6,
     "Column19": "701+",
     "Column20": 30.8,
     "Column21": 422,
     "Column22": 69.1,
     "Column23": 495,
     "Column24": 6.3,
     "Column25": "701+",
     "Column26": 57.7,
     "Column27": "276=",
     "Column28": 29.9
    },
    {
     "2025 QS World University Rankings": 396,
     "Column2": 375,
     "Column3": "Northeastern University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 19.2,
     "Column11": 470,
     "Column12": 11.8,
     "Column13": "601+",
     "Column14": 15.4,
     "Column15": "701+",
     "Column16": 24.6,
     "Column17": 501,
     "Column18": 51.6,
     "Column19": 360,
     "Column20": 96.4,
     "Column21": 85,
     "Column22": 67.3,
     "Column23": 516,
     "Column24": 44,
     "Column25": 291,
     "Column26": 72.3,
     "Column27": "205=",
     "Column28": 29.7
    },
    {
     "2025 QS World University Rankings": 396,
     "Column2": 375,
     "Column3": "Ruhr-Universität Bochum",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.3,
     "Column11": 437,
     "Column12": 8.8,
     "Column13": "601+",
     "Column14": 2.5,
     "Column15": "701+",
     "Column16": 53.4,
     "Column17": 240,
     "Column18": 48.8,
     "Column19": 371,
     "Column20": 39.3,
     "Column21": 358,
     "Column22": 87.3,
     "Column23": 209,
     "Column24": 16.2,
     "Column25": 632,
     "Column26": 53.5,
     "Column27": "295=",
     "Column28": 29.7
    },
    {
     "2025 QS World University Rankings": 396,
     "Column2": 436,
     "Column3": "Sejong University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 10.8,
     "Column11": "601+",
     "Column12": 16.1,
     "Column13": 528,
     "Column14": 11.9,
     "Column15": "701+",
     "Column16": 76.9,
     "Column17": 127,
     "Column18": 23.8,
     "Column19": 551,
     "Column20": 31.2,
     "Column21": 418,
     "Column22": 64.1,
     "Column23": 570,
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 45.9,
     "Column27": "336=",
     "Column28": 29.7
    },
    {
     "2025 QS World University Rankings": 396,
     "Column2": 421,
     "Column3": "Université de Liège",
     "Column4": "BE",
     "Column5": "Belgium",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17,
     "Column11": 531,
     "Column12": 11.8,
     "Column13": "601+",
     "Column14": 9.6,
     "Column15": "701+",
     "Column16": 38,
     "Column17": 365,
     "Column18": 50.3,
     "Column19": 364,
     "Column20": 53,
     "Column21": 277,
     "Column22": 94.6,
     "Column23": 86,
     "Column24": 23.6,
     "Column25": 478,
     "Column26": 81.2,
     "Column27": "148=",
     "Column28": 29.7
    },
    {
     "2025 QS World University Rankings": 396,
     "Column2": 410,
     "Column3": "University of Southern Queensland ",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.8,
     "Column11": "601+",
     "Column12": 5.7,
     "Column13": "601+",
     "Column14": 4.8,
     "Column15": "701+",
     "Column16": 73.1,
     "Column17": 143,
     "Column18": 84.1,
     "Column19": 223,
     "Column20": 23,
     "Column21": 503,
     "Column22": 81,
     "Column23": 310,
     "Column24": 8.6,
     "Column25": "701+",
     "Column26": 43,
     "Column27": 353,
     "Column28": 29.7
    },
    {
     "2025 QS World University Rankings": 401,
     "Column2": 396,
     "Column3": "Kazan (Volga region) Federal University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.6,
     "Column11": 373,
     "Column12": 11.4,
     "Column13": "601+",
     "Column14": 95.6,
     "Column15": 58,
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 10.2,
     "Column19": "701+",
     "Column20": 95.7,
     "Column21": 93,
     "Column22": 88.3,
     "Column23": 191,
     "Column24": 15.4,
     "Column25": 647,
     "Column26": 22.2,
     "Column27": "513=",
     "Column28": 29.6
    },
    {
     "2025 QS World University Rankings": 401,
     "Column2": 431,
     "Column3": "Koç University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 24.3,
     "Column11": 359,
     "Column12": 54.6,
     "Column13": 155,
     "Column14": 19.1,
     "Column15": 697,
     "Column16": 32.4,
     "Column17": 407,
     "Column18": 24.7,
     "Column19": 540,
     "Column20": 13,
     "Column21": 659,
     "Column22": 51.6,
     "Column23": "701+",
     "Column24": 6.8,
     "Column25": "701+",
     "Column26": 34.8,
     "Column27": "406=",
     "Column28": 29.6
    },
    {
     "2025 QS World University Rankings": 403,
     "Column2": 375,
     "Column3": "Amirkabir University of Technology",
     "Column4": "IR",
     "Column5": "Iran, Islamic Republic of",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 20.5,
     "Column13": 445,
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 100,
     "Column17": 7,
     "Column18": 24.6,
     "Column19": 543,
     "Column20": 2.8,
     "Column21": "701+",
     "Column22": 70.2,
     "Column23": 483,
     "Column24": 5.8,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": 29.5
    },
    {
     "2025 QS World University Rankings": 403,
     "Column2": 421,
     "Column3": "University of Canberra ",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.3,
     "Column11": "601+",
     "Column12": 7.7,
     "Column13": "601+",
     "Column14": 2.4,
     "Column15": "701+",
     "Column16": 51.2,
     "Column17": 255,
     "Column18": 79.6,
     "Column19": 243,
     "Column20": 69.4,
     "Column21": 213,
     "Column22": 63.1,
     "Column23": 583,
     "Column24": 5.6,
     "Column25": "701+",
     "Column26": 70.9,
     "Column27": "217=",
     "Column28": 29.5
    },
    {
     "2025 QS World University Rankings": 405,
     "Column2": 392,
     "Column3": "Institut National des Sciences Appliquées de Lyon (INSA)",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.4,
     "Column11": "601+",
     "Column12": 28.7,
     "Column13": 334,
     "Column14": 33.7,
     "Column15": 469,
     "Column16": 60.6,
     "Column17": 201,
     "Column18": 29.2,
     "Column19": 500,
     "Column20": 58.3,
     "Column21": 254,
     "Column22": 59.5,
     "Column23": 638,
     "Column24": 14.4,
     "Column25": 680,
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": 29.3
    },
    {
     "2025 QS World University Rankings": 405,
     "Column2": 481,
     "Column3": "Satbayev University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 27.8,
     "Column11": 318,
     "Column12": 43.4,
     "Column13": 211,
     "Column14": 88.1,
     "Column15": 94,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 66.7,
     "Column19": 296,
     "Column20": 16.9,
     "Column21": 588,
     "Column22": 15,
     "Column23": "701+",
     "Column24": 23.6,
     "Column25": 477,
     "Column26": 3.8,
     "Column27": "701+",
     "Column28": 29.3
    },
    {
     "2025 QS World University Rankings": 405,
     "Column2": 328,
     "Column3": "USI - Università della Svizzera italiana",
     "Column4": "CH",
     "Column5": "Switzerland",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 4.6,
     "Column13": "601+",
     "Column14": 73.5,
     "Column15": 176,
     "Column16": 40.7,
     "Column17": 343,
     "Column18": 100,
     "Column19": 34,
     "Column20": 100,
     "Column21": 13,
     "Column22": 39.3,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": 29.3
    },
    {
     "2025 QS World University Rankings": 408,
     "Column2": 374,
     "Column3": "Birkbeck, University of London",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10,
     "Column11": "601+",
     "Column12": 3.6,
     "Column13": "601+",
     "Column14": 15.6,
     "Column15": "701+",
     "Column16": 57.1,
     "Column17": 218,
     "Column18": 97.7,
     "Column19": 123,
     "Column20": 99.5,
     "Column21": 46,
     "Column22": 55.5,
     "Column23": 686,
     "Column24": 8.8,
     "Column25": "701+",
     "Column26": 7.7,
     "Column27": "701+",
     "Column28": 29.1
    },
    {
     "2025 QS World University Rankings": 408,
     "Column2": 400,
     "Column3": "Masaryk University",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.5,
     "Column11": 376,
     "Column12": 31.7,
     "Column13": 298,
     "Column14": 9.5,
     "Column15": "701+",
     "Column16": 13.8,
     "Column17": 656,
     "Column18": 27.6,
     "Column19": 513,
     "Column20": 66.7,
     "Column21": 223,
     "Column22": 80.2,
     "Column23": 318,
     "Column24": 36,
     "Column25": 352,
     "Column26": 79.1,
     "Column27": "161=",
     "Column28": 29.1
    },
    {
     "2025 QS World University Rankings": 410,
     "Column2": 399,
     "Column3": "HSE University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 24,
     "Column11": 366,
     "Column12": 38.4,
     "Column13": 246,
     "Column14": 84.8,
     "Column15": 105,
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 6.6,
     "Column19": "701+",
     "Column20": 13.5,
     "Column21": 652,
     "Column22": 83.6,
     "Column23": 271,
     "Column24": 13,
     "Column25": "701+",
     "Column26": 44.6,
     "Column27": "343=",
     "Column28": 29
    },
    {
     "2025 QS World University Rankings": 410,
     "Column2": 415,
     "Column3": "The American University in Cairo",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 32.1,
     "Column11": 274,
     "Column12": 31.1,
     "Column13": 301,
     "Column14": 35.5,
     "Column15": 444,
     "Column16": 5.2,
     "Column17": "701+",
     "Column18": 97,
     "Column19": 130,
     "Column20": 3.8,
     "Column21": "701+",
     "Column22": 29.4,
     "Column23": "701+",
     "Column24": 75.6,
     "Column25": 138,
     "Column26": 14.6,
     "Column27": "597=",
     "Column28": 29
    },
    {
     "2025 QS World University Rankings": 412,
     "Column2": 407,
     "Column3": "Auckland University of Technology (AUT)",
     "Column4": "NZ",
     "Column5": "New Zealand",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.3,
     "Column11": 464,
     "Column12": 11.2,
     "Column13": "601+",
     "Column14": 10.6,
     "Column15": "701+",
     "Column16": 21.7,
     "Column17": 536,
     "Column18": 100,
     "Column19": 64,
     "Column20": 99.8,
     "Column21": 42,
     "Column22": 52.6,
     "Column23": "701+",
     "Column24": 12.4,
     "Column25": "701+",
     "Column26": 72.6,
     "Column27": "202=",
     "Column28": 28.9
    },
    {
     "2025 QS World University Rankings": 413,
     "Column2": 421,
     "Column3": "Graz University of Technology",
     "Column4": "AT",
     "Column5": "Austria",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.7,
     "Column11": 590,
     "Column12": 15.7,
     "Column13": 545,
     "Column14": 98.9,
     "Column15": 40,
     "Column16": 11.1,
     "Column17": "701+",
     "Column18": 81.8,
     "Column19": 232,
     "Column20": 59.7,
     "Column21": 247,
     "Column22": 59.9,
     "Column23": 634,
     "Column24": 11.9,
     "Column25": "701+",
     "Column26": 2.8,
     "Column27": "701+",
     "Column28": 28.8
    },
    {
     "2025 QS World University Rankings": 413,
     "Column2": 369,
     "Column3": "Yeshiva University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "S",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 3.1,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 65.3,
     "Column15": 202,
     "Column16": 91.6,
     "Column17": 71,
     "Column18": 31.4,
     "Column19": 488,
     "Column20": 13.5,
     "Column21": 651,
     "Column22": 6.6,
     "Column23": "701+",
     "Column24": 3,
     "Column25": "701+",
     "Column26": 15.8,
     "Column27": 583,
     "Column28": 28.8
    },
    {
     "2025 QS World University Rankings": 415,
     "Column2": 387,
     "Column3": "Concordia University",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.8,
     "Column11": 559,
     "Column12": 11.4,
     "Column13": "601+",
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 17.5,
     "Column17": 590,
     "Column18": 87.4,
     "Column19": 208,
     "Column20": 92.3,
     "Column21": 104,
     "Column22": 73.8,
     "Column23": 423,
     "Column24": 43.6,
     "Column25": 294,
     "Column26": 89.8,
     "Column27": "96=",
     "Column28": 28.7
    },
    {
     "2025 QS World University Rankings": 416,
     "Column2": 413,
     "Column3": "Oxford Brookes University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 30.7,
     "Column11": 289,
     "Column12": 37.6,
     "Column13": 250,
     "Column14": 13.7,
     "Column15": "701+",
     "Column16": 7,
     "Column17": "701+",
     "Column18": 67.5,
     "Column19": 291,
     "Column20": 67.4,
     "Column21": 220,
     "Column22": 68.4,
     "Column23": 508,
     "Column24": 20.5,
     "Column25": 554,
     "Column26": 13.2,
     "Column27": "606=",
     "Column28": 28.6
    },
    {
     "2025 QS World University Rankings": 416,
     "Column2": 392,
     "Column3": "Technion - Israel Institute of Technology",
     "Column4": "IL",
     "Column5": "Israel",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.9,
     "Column11": 369,
     "Column12": 24.8,
     "Column13": 385,
     "Column14": 52,
     "Column15": 293,
     "Column16": 20.1,
     "Column17": 555,
     "Column18": 74.2,
     "Column19": 266,
     "Column20": 4.3,
     "Column21": "701+",
     "Column22": 59.1,
     "Column23": 641,
     "Column24": 35.3,
     "Column25": 359,
     "Column26": 15,
     "Column27": "592=",
     "Column28": 28.6
    },
    {
     "2025 QS World University Rankings": 418,
     "Column2": 441,
     "Column3": " University of Dundee",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 18.4,
     "Column11": 494,
     "Column12": 13.7,
     "Column13": 590,
     "Column14": 30.8,
     "Column15": 512,
     "Column16": 14,
     "Column17": 654,
     "Column18": 91.1,
     "Column19": 185,
     "Column20": 89.4,
     "Column21": 126,
     "Column22": 75.2,
     "Column23": 405,
     "Column24": 7.4,
     "Column25": "701+",
     "Column26": 55.4,
     "Column27": "285=",
     "Column28": 28.5
    },
    {
     "2025 QS World University Rankings": 418,
     "Column2": 514,
     "Column3": "Bo?aziçi University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 24.9,
     "Column11": 353,
     "Column12": 65.3,
     "Column13": 118,
     "Column14": 4.1,
     "Column15": "701+",
     "Column16": 10.7,
     "Column17": "701+",
     "Column18": 10.7,
     "Column19": "701+",
     "Column20": 2.6,
     "Column21": "701+",
     "Column22": 46.8,
     "Column23": "701+",
     "Column24": 84.9,
     "Column25": 102,
     "Column26": 46.2,
     "Column27": "334=",
     "Column28": 28.5
    },
    {
     "2025 QS World University Rankings": 420,
     "Column2": 454,
     "Column3": "Czech Technical University in Prague",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 22,
     "Column11": 404,
     "Column12": 42.4,
     "Column13": 217,
     "Column14": 80.6,
     "Column15": 129,
     "Column16": 3.6,
     "Column17": "701+",
     "Column18": 19.9,
     "Column19": 598,
     "Column20": 50.2,
     "Column21": 293,
     "Column22": 59.4,
     "Column23": 639,
     "Column24": 15.6,
     "Column25": 642,
     "Column26": 5.8,
     "Column27": "701+",
     "Column28": 28.4
    },
    {
     "2025 QS World University Rankings": 421,
     "Column2": 436,
     "Column3": "Dublin City University",
     "Column4": "IE",
     "Column5": "Ireland",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 20.1,
     "Column11": 441,
     "Column12": 26,
     "Column13": 366,
     "Column14": 25.8,
     "Column15": 577,
     "Column16": 10.7,
     "Column17": "701+",
     "Column18": 75,
     "Column19": 265,
     "Column20": 22.6,
     "Column21": 508,
     "Column22": 75.4,
     "Column23": 401,
     "Column24": 35.3,
     "Column25": 358,
     "Column26": 82.5,
     "Column27": "143=",
     "Column28": 28.3
    },
    {
     "2025 QS World University Rankings": 421,
     "Column2": 426,
     "Column3": "University of Limerick",
     "Column4": "IE",
     "Column5": "Ireland",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 18.3,
     "Column11": 497,
     "Column12": 25.5,
     "Column13": 371,
     "Column14": 15.7,
     "Column15": "701+",
     "Column16": 23,
     "Column17": 520,
     "Column18": 87.1,
     "Column19": 209,
     "Column20": 30.8,
     "Column21": 421,
     "Column22": 72.5,
     "Column23": 447,
     "Column24": 8,
     "Column25": "701+",
     "Column26": 76.4,
     "Column27": "176=",
     "Column28": 28.3
    },
    {
     "2025 QS World University Rankings": 423,
     "Column2": 446,
     "Column3": "Aston University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.5,
     "Column11": "601+",
     "Column12": 18.5,
     "Column13": 478,
     "Column14": 9,
     "Column15": "701+",
     "Column16": 34.4,
     "Column17": 388,
     "Column18": 94.2,
     "Column19": 160,
     "Column20": 53.8,
     "Column21": 274,
     "Column22": 79.2,
     "Column23": 336,
     "Column24": 19.6,
     "Column25": 569,
     "Column26": 46.9,
     "Column27": "330=",
     "Column28": 28.1
    },
    {
     "2025 QS World University Rankings": 423,
     "Column2": 441,
     "Column3": "Université Laval",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.2,
     "Column11": 468,
     "Column12": 8.1,
     "Column13": "601+",
     "Column14": 26,
     "Column15": 572,
     "Column16": 23.8,
     "Column17": 512,
     "Column18": 55.8,
     "Column19": 336,
     "Column20": 41.6,
     "Column21": 344,
     "Column22": 80.2,
     "Column23": 317,
     "Column24": 36.4,
     "Column25": 349,
     "Column26": 78.4,
     "Column27": "166=",
     "Column28": 28.1
    },
    {
     "2025 QS World University Rankings": 425,
     "Column2": 431,
     "Column3": "National Taipei University of Technology",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.9,
     "Column11": "601+",
     "Column12": 54.7,
     "Column13": 154,
     "Column14": 24.3,
     "Column15": 602,
     "Column16": 41.6,
     "Column17": 334,
     "Column18": 19.8,
     "Column19": 602,
     "Column20": 20.6,
     "Column21": 531,
     "Column22": 21.3,
     "Column23": "701+",
     "Column24": 22.5,
     "Column25": 509,
     "Column26": 31.3,
     "Column27": "435=",
     "Column28": 28
    },
    {
     "2025 QS World University Rankings": 426,
     "Column2": 489,
     "Column3": "IPB University (a.k.a. Bogor Agricultural University)",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.5,
     "Column11": 375,
     "Column12": 46.9,
     "Column13": 196,
     "Column14": 71.4,
     "Column15": 186,
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 53.2,
     "Column19": 351,
     "Column20": 3.6,
     "Column21": "701+",
     "Column22": 19.1,
     "Column23": "701+",
     "Column24": 33.9,
     "Column25": 373,
     "Column26": 35.1,
     "Column27": "403=",
     "Column28": 27.9
    },
    {
     "2025 QS World University Rankings": 426,
     "Column2": 481,
     "Column3": "Leibniz University Hannover",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 21.2,
     "Column11": 415,
     "Column12": 32,
     "Column13": 293,
     "Column14": 17.9,
     "Column15": "701+",
     "Column16": 17.4,
     "Column17": 591,
     "Column18": 46.3,
     "Column19": 388,
     "Column20": 26.9,
     "Column21": 455,
     "Column22": 78.5,
     "Column23": 347,
     "Column24": 14.5,
     "Column25": 676,
     "Column26": 82.1,
     "Column27": 146,
     "Column28": 27.9
    },
    {
     "2025 QS World University Rankings": 428,
     "Column2": 440,
     "Column3": "Julius-Maximilians-Universität Würzburg",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.8,
     "Column11": 560,
     "Column12": 7,
     "Column13": "601+",
     "Column14": 5.5,
     "Column15": "701+",
     "Column16": 74.4,
     "Column17": 136,
     "Column18": 45,
     "Column19": 396,
     "Column20": 9.5,
     "Column21": "701+",
     "Column22": 78.5,
     "Column23": 348,
     "Column24": 8.7,
     "Column25": "701+",
     "Column26": 8.8,
     "Column27": "692=",
     "Column28": 27.8
    },
    {
     "2025 QS World University Rankings": 431,
     "Column2": 452,
     "Column3": "Central South University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.6,
     "Column11": "601+",
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 5.5,
     "Column15": "701+",
     "Column16": 99.7,
     "Column17": 15,
     "Column18": 6.4,
     "Column19": "701+",
     "Column20": 4,
     "Column21": "701+",
     "Column22": 67.5,
     "Column23": 515,
     "Column24": 14.1,
     "Column25": 689,
     "Column26": 3.9,
     "Column27": "701+",
     "Column28": 27.6
    },
    {
     "2025 QS World University Rankings": 430,
     "Column2": 436,
     "Column3": "University of Science and Technology Beijing",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.8,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 10.2,
     "Column15": "701+",
     "Column16": 99.4,
     "Column17": 21,
     "Column18": 3.1,
     "Column19": "701+",
     "Column20": 2.8,
     "Column21": "701+",
     "Column22": 56.8,
     "Column23": 668,
     "Column24": 4.9,
     "Column25": "701+",
     "Column26": 3.6,
     "Column27": "701+",
     "Column28": 27.7
    },
    {
     "2025 QS World University Rankings": 451,
     "Column2": 486,
     "Column3": "University of Electronic Science and Technology of China",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.3,
     "Column11": "601+",
     "Column12": 5,
     "Column13": "601+",
     "Column14": 8.2,
     "Column15": "701+",
     "Column16": 99.1,
     "Column17": 25,
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 73.3,
     "Column23": 433,
     "Column24": 7.2,
     "Column25": "701+",
     "Column26": 2.3,
     "Column27": "701+",
     "Column28": 26.5
    },
    {
     "2025 QS World University Rankings": 431,
     "Column2": 418,
     "Column3": "Tomsk State University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 22,
     "Column11": 403,
     "Column12": 13.5,
     "Column13": "601+",
     "Column14": 99,
     "Column15": 36,
     "Column16": 4.2,
     "Column17": "701+",
     "Column18": 21.6,
     "Column19": 574,
     "Column20": 91.6,
     "Column21": 113,
     "Column22": 64.1,
     "Column23": 571,
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": 27.6
    },
    {
     "2025 QS World University Rankings": 431,
     "Column2": 403,
     "Column3": "University of Granada",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 44.4,
     "Column11": 191,
     "Column12": 14.1,
     "Column13": 581,
     "Column14": 5.7,
     "Column15": "701+",
     "Column16": 26.2,
     "Column17": 482,
     "Column18": 3.1,
     "Column19": "701+",
     "Column20": 14.8,
     "Column21": 628,
     "Column22": 96.3,
     "Column23": 52,
     "Column24": 4.9,
     "Column25": "701+",
     "Column26": 26.3,
     "Column27": "478=",
     "Column28": 27.6
    },
    {
     "2025 QS World University Rankings": 434,
     "Column2": 465,
     "Column3": "University of Sharjah",
     "Column4": "AE",
     "Column5": "United Arab Emirates",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 18.1,
     "Column11": 501,
     "Column12": 17.3,
     "Column13": 496,
     "Column14": 6,
     "Column15": "701+",
     "Column16": 22,
     "Column17": 532,
     "Column18": 100,
     "Column19": 8,
     "Column20": 100,
     "Column21": 31,
     "Column22": 84.8,
     "Column23": 250,
     "Column24": 11.2,
     "Column25": "701+",
     "Column26": 11.8,
     "Column27": "623=",
     "Column28": 27.5
    },
    {
     "2025 QS World University Rankings": 435,
     "Column2": 428,
     "Column3": "IE University",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 8.1,
     "Column11": "601+",
     "Column12": 49.3,
     "Column13": 182,
     "Column14": 46.5,
     "Column15": 328,
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 95.2,
     "Column19": 145,
     "Column20": 100,
     "Column21": 4,
     "Column22": 3.5,
     "Column23": "701+",
     "Column24": 65.6,
     "Column25": 176,
     "Column26": 4.6,
     "Column27": "701+",
     "Column28": 27.4
    },
    {
     "2025 QS World University Rankings": 436,
     "Column2": 451,
     "Column3": "Iran University of Science and Technology",
     "Column4": "IR",
     "Column5": "Iran, Islamic Republic of",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.8,
     "Column11": "601+",
     "Column12": 14,
     "Column13": 584,
     "Column14": 2.7,
     "Column15": "701+",
     "Column16": 98.7,
     "Column17": 31,
     "Column18": 12,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 53.4,
     "Column23": "701+",
     "Column24": 5.3,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": 27.2
    },
    {
     "2025 QS World University Rankings": 436,
     "Column2": 431,
     "Column3": "Murdoch University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8,
     "Column11": "601+",
     "Column12": 4.5,
     "Column13": "601+",
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 57.3,
     "Column17": 216,
     "Column18": 85.6,
     "Column19": 216,
     "Column20": 73.9,
     "Column21": 194,
     "Column22": 74.9,
     "Column23": 410,
     "Column24": 14.8,
     "Column25": 668,
     "Column26": 11.3,
     "Column27": "636=",
     "Column28": 27.2
    },
    {
     "2025 QS World University Rankings": 436,
     "Column2": 476,
     "Column3": "Universitat Politècnica de València",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 26.5,
     "Column11": 335,
     "Column12": 16.3,
     "Column13": 521,
     "Column14": 36.1,
     "Column15": 440,
     "Column16": 19.7,
     "Column17": 559,
     "Column18": 5.5,
     "Column19": "701+",
     "Column20": 25,
     "Column21": 479,
     "Column22": 84.9,
     "Column23": 248,
     "Column24": 11.7,
     "Column25": "701+",
     "Column26": 76.1,
     "Column27": "178=",
     "Column28": 27.2
    },
    {
     "2025 QS World University Rankings": 439,
     "Column2": 473,
     "Column3": "Vilnius University ",
     "Column4": "LT",
     "Column5": "Lithuania",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 19.1,
     "Column11": 472,
     "Column12": 23.5,
     "Column13": 405,
     "Column14": 74.7,
     "Column15": 168,
     "Column16": 3.3,
     "Column17": "701+",
     "Column18": 12.4,
     "Column19": "701+",
     "Column20": 13.6,
     "Column21": 649,
     "Column22": 75.5,
     "Column23": 398,
     "Column24": 83.7,
     "Column25": 105,
     "Column26": 27.1,
     "Column27": "471=",
     "Column28": 27.1
    },
    {
     "2025 QS World University Rankings": 440,
     "Column2": 429,
     "Column3": "Singapore University of Technology and Design",
     "Column4": "SG",
     "Column5": "Singapore",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "VH",
     "Column10": 7.4,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 99,
     "Column15": 37,
     "Column16": 66,
     "Column17": 168,
     "Column22": 31.2,
     "Column23": "701+",
     "Column24": 4.6,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": 27
    },
    {
     "2025 QS World University Rankings": 440,
     "Column2": 469,
     "Column3": "Università degli Studi di Pavia",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 21.7,
     "Column11": 406,
     "Column12": 7.4,
     "Column13": "601+",
     "Column14": 8.3,
     "Column15": "701+",
     "Column16": 64.6,
     "Column17": 173,
     "Column18": 6.2,
     "Column19": "701+",
     "Column20": 12.6,
     "Column21": 666,
     "Column22": 82.7,
     "Column23": 281,
     "Column24": 13.5,
     "Column25": "701+",
     "Column26": 15.4,
     "Column27": "585=",
     "Column28": 27
    },
    {
     "2025 QS World University Rankings": 442,
     "Column2": 409,
     "Column3": "Colorado State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.3,
     "Column11": 498,
     "Column12": 20.4,
     "Column13": 447,
     "Column14": 60.9,
     "Column15": 236,
     "Column16": 17.1,
     "Column17": 599,
     "Column18": 18.5,
     "Column19": 621,
     "Column20": 3.7,
     "Column21": "701+",
     "Column22": 78.4,
     "Column23": 351,
     "Column24": 22.2,
     "Column25": 517,
     "Column26": 70.9,
     "Column27": "217=",
     "Column28": 26.8
    },
    {
     "2025 QS World University Rankings": 442,
     "Column2": 421,
     "Column3": "Novosibirsk State University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 24.7,
     "Column11": 354,
     "Column12": 18.4,
     "Column13": 480,
     "Column14": 91,
     "Column15": 81,
     "Column16": 9.5,
     "Column17": "701+",
     "Column18": 6.3,
     "Column19": "701+",
     "Column20": 57,
     "Column21": 259,
     "Column22": 56.5,
     "Column23": 671,
     "Column24": 5.8,
     "Column25": "701+",
     "Column26": 5.3,
     "Column27": "701+",
     "Column28": 26.8
    },
    {
     "2025 QS World University Rankings": 442,
     "Column2": 505,
     "Column3": "Università Cattolica del Sacro Cuore",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 24.2,
     "Column11": 360,
     "Column12": 35.5,
     "Column13": 267,
     "Column14": 27.6,
     "Column15": 552,
     "Column16": 19.2,
     "Column17": 567,
     "Column18": 15.5,
     "Column19": 661,
     "Column20": 7.1,
     "Column21": "701+",
     "Column22": 60.5,
     "Column23": 628,
     "Column24": 32.3,
     "Column25": 393,
     "Column26": 54.8,
     "Column27": "289=",
     "Column28": 26.8
    },
    {
     "2025 QS World University Rankings": 445,
     "Column2": 415,
     "Column3": "James Cook University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.4,
     "Column11": "601+",
     "Column12": 6.5,
     "Column13": "601+",
     "Column14": 17.5,
     "Column15": "701+",
     "Column16": 46.9,
     "Column17": 292,
     "Column18": 36.7,
     "Column19": 434,
     "Column20": 16,
     "Column21": 607,
     "Column22": 76,
     "Column23": 389,
     "Column24": 6.7,
     "Column25": "701+",
     "Column26": 88.9,
     "Column27": "103=",
     "Column28": 26.7
    },
    {
     "2025 QS World University Rankings": 445,
     "Column2": 396,
     "Column3": "Justus-Liebig-University Giessen",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.7,
     "Column11": "601+",
     "Column12": 5.9,
     "Column13": "601+",
     "Column14": 1.9,
     "Column15": "701+",
     "Column16": 79.3,
     "Column17": 113,
     "Column18": 14.2,
     "Column19": 679,
     "Column20": 18.8,
     "Column21": 569,
     "Column22": 83.9,
     "Column23": 264,
     "Column24": 10.5,
     "Column25": "701+",
     "Column26": 11,
     "Column27": "644=",
     "Column28": 26.7
    },
    {
     "2025 QS World University Rankings": 445,
     "Column2": 485,
     "Column3": "Universitat de Valencia",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 35.6,
     "Column11": 246,
     "Column12": 11,
     "Column13": "601+",
     "Column14": 12,
     "Column15": "701+",
     "Column16": 19.9,
     "Column17": 557,
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 24.3,
     "Column21": 484,
     "Column22": 89.3,
     "Column23": 180,
     "Column24": 8.4,
     "Column25": "701+",
     "Column26": 79.3,
     "Column27": "159=",
     "Column28": 26.7
    },
    {
     "2025 QS World University Rankings": 284,
     "Column2": 301,
     "Column3": "Southern University of Science and Technology",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.3,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 89.7,
     "Column15": 85,
     "Column16": 98.8,
     "Column17": 29,
     "Column18": 37.5,
     "Column19": 430,
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 48.3,
     "Column23": "701+",
     "Column24": 1.6,
     "Column25": "701+",
     "Column26": 31.6,
     "Column27": "432=",
     "Column28": 37.3
    },
    {
     "2025 QS World University Rankings": 385,
     "Column2": 392,
     "Column3": "South China University of Technology",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.1,
     "Column11": "601+",
     "Column12": 6.6,
     "Column13": "601+",
     "Column14": 20,
     "Column15": 673,
     "Column16": 97.8,
     "Column17": 36,
     "Column18": 10.7,
     "Column19": "701+",
     "Column20": 4.6,
     "Column21": "701+",
     "Column22": 51.3,
     "Column23": "701+",
     "Column24": 27.3,
     "Column25": 439,
     "Column26": 7.3,
     "Column27": "701+",
     "Column28": 30.4
    },
    {
     "2025 QS World University Rankings": 448,
     "Column2": 382,
     "Column3": "Université de Montpellier",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.6,
     "Column11": 486,
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 8.5,
     "Column15": "701+",
     "Column16": 57.2,
     "Column17": 217,
     "Column18": 22.8,
     "Column19": 562,
     "Column20": 29.9,
     "Column21": 431,
     "Column22": 99.5,
     "Column23": 6,
     "Column24": 13.1,
     "Column25": "701+",
     "Column26": 17.3,
     "Column27": 561,
     "Column28": 26.6
    },
    {
     "2025 QS World University Rankings": 448,
     "Column2": 494,
     "Column3": "Hunan University ",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.9,
     "Column11": "601+",
     "Column12": 10.3,
     "Column13": "601+",
     "Column14": 15.2,
     "Column15": "701+",
     "Column16": 90.6,
     "Column17": 74,
     "Column18": 4.6,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 46.5,
     "Column23": "701+",
     "Column24": 23.1,
     "Column25": 495,
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": 26.6
    },
    {
     "2025 QS World University Rankings": 565,
     "Column2": 600,
     "Column3": "Nanjing University of Science and Technology",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.7,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 8.9,
     "Column15": "701+",
     "Column16": 89.3,
     "Column17": 78,
     "Column22": 38.7,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 2.3,
     "Column27": "701+",
     "Column28": 22
    },
    {
     "2025 QS World University Rankings": 452,
     "Column2": 431,
     "Column3": "National Taiwan Normal University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 34.7,
     "Column11": 254,
     "Column12": 20.5,
     "Column13": 444,
     "Column14": 62.9,
     "Column15": 219,
     "Column16": 5.4,
     "Column17": "701+",
     "Column18": 14.5,
     "Column19": 673,
     "Column20": 25.2,
     "Column21": 478,
     "Column22": 23.3,
     "Column23": "701+",
     "Column24": 17.3,
     "Column25": 612,
     "Column26": 49.8,
     "Column27": "319=",
     "Column28": 26.4
    },
    {
     "2025 QS World University Rankings": 452,
     "Column2": 410,
     "Column3": "University of Kansas",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.4,
     "Column11": "601+",
     "Column12": 12.6,
     "Column13": "601+",
     "Column14": 86.7,
     "Column15": 99,
     "Column16": 9.2,
     "Column17": "701+",
     "Column18": 60.3,
     "Column19": 322,
     "Column20": 7.2,
     "Column21": "701+",
     "Column22": 84.6,
     "Column23": 255,
     "Column24": 28.2,
     "Column25": 435,
     "Column26": 32.4,
     "Column27": 427,
     "Column28": 26.4
    },
    {
     "2025 QS World University Rankings": 452,
     "Column2": 431,
     "Column3": "University of Stirling",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.2,
     "Column11": "601+",
     "Column12": 9.4,
     "Column13": "601+",
     "Column14": 9.3,
     "Column15": "701+",
     "Column16": 30.3,
     "Column17": 436,
     "Column18": 76.9,
     "Column19": 253,
     "Column20": 81.3,
     "Column21": 168,
     "Column22": 61.6,
     "Column23": 606,
     "Column24": 15.8,
     "Column25": 638,
     "Column26": 76.1,
     "Column27": "178=",
     "Column28": 26.4
    },
    {
     "2025 QS World University Rankings": 456,
     "Column2": 415,
     "Column3": "Moscow Institute of Physics and Technology (MIPT \/ Moscow Phystech)",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16.6,
     "Column11": 541,
     "Column12": 23.6,
     "Column13": 404,
     "Column14": 94.6,
     "Column15": 64,
     "Column16": 12,
     "Column17": 692,
     "Column18": 31.1,
     "Column19": 489,
     "Column20": 45.5,
     "Column21": 324,
     "Column22": 41.7,
     "Column23": "701+",
     "Column24": 17.6,
     "Column25": 605,
     "Column26": 1,
     "Column27": "701+",
     "Column28": 26.3
    },
    {
     "2025 QS World University Rankings": 456,
     "Column2": 465,
     "Column3": "Umea University",
     "Column4": "SE",
     "Column5": "Sweden",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.9,
     "Column11": 447,
     "Column12": 11.6,
     "Column13": "601+",
     "Column14": 71.8,
     "Column15": 182,
     "Column16": 15.2,
     "Column17": 631,
     "Column18": 51.1,
     "Column19": 362,
     "Column20": 21.8,
     "Column21": 518,
     "Column22": 78,
     "Column23": 360,
     "Column24": 9,
     "Column25": "701+",
     "Column26": 26.3,
     "Column27": "478=",
     "Column28": 26.3
    },
    {
     "2025 QS World University Rankings": 456,
     "Column2": 421,
     "Column3": "Université de Strasbourg",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 39.1,
     "Column11": 219,
     "Column12": 10.3,
     "Column13": "601+",
     "Column14": 5.1,
     "Column15": "701+",
     "Column16": 20.7,
     "Column17": 549,
     "Column18": 34.2,
     "Column19": 469,
     "Column20": 35.9,
     "Column21": 384,
     "Column22": 90.7,
     "Column23": 161,
     "Column24": 20.4,
     "Column25": 558,
     "Column26": 5.4,
     "Column27": "701+",
     "Column28": 26.3
    },
    {
     "2025 QS World University Rankings": 456,
     "Column2": 486,
     "Column3": "University of Guelph",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.8,
     "Column11": "601+",
     "Column12": 12.8,
     "Column13": "601+",
     "Column14": 5,
     "Column15": "701+",
     "Column16": 50.1,
     "Column17": 261,
     "Column18": 46.4,
     "Column19": 385,
     "Column20": 14.3,
     "Column21": 636,
     "Column22": 65.2,
     "Column23": 552,
     "Column24": 11.2,
     "Column25": "701+",
     "Column26": 82.9,
     "Column27": "139=",
     "Column28": 26.3
    },
    {
     "2025 QS World University Rankings": 460,
     "Column2": 396,
     "Column3": "Stony Brook University, State University of New York",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18,
     "Column11": 504,
     "Column12": 7.3,
     "Column13": "601+",
     "Column14": 32.2,
     "Column15": 489,
     "Column16": 23.6,
     "Column17": 515,
     "Column18": 34.7,
     "Column19": 462,
     "Column20": 43.5,
     "Column21": 332,
     "Column22": 76,
     "Column23": 390,
     "Column24": 21.2,
     "Column25": 541,
     "Column26": 79.1,
     "Column27": "161=",
     "Column28": 26.2
    },
    {
     "2025 QS World University Rankings": 461,
     "Column2": 410,
     "Column3": "Universidad de Santiago de Chile (USACH)",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 43.4,
     "Column11": 195,
     "Column12": 55,
     "Column13": 152,
     "Column14": 7.2,
     "Column15": "701+",
     "Column16": 4.2,
     "Column17": "701+",
     "Column18": 6.3,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 51.1,
     "Column23": "701+",
     "Column24": 12.7,
     "Column25": "701+",
     "Column26": 11.2,
     "Column27": "642=",
     "Column28": 26.1
    },
    {
     "2025 QS World University Rankings": 462,
     "Column2": 436,
     "Column3": "Tampere University",
     "Column4": "FI",
     "Column5": "Finland",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 21.7,
     "Column11": 407,
     "Column12": 15.4,
     "Column13": 548,
     "Column14": 20.4,
     "Column15": 662,
     "Column16": 33.1,
     "Column17": 400,
     "Column18": 44.5,
     "Column19": 398,
     "Column20": 5.9,
     "Column21": "701+",
     "Column22": 84.8,
     "Column23": 251,
     "Column24": 30.8,
     "Column25": 409,
     "Column26": 23,
     "Column27": 508,
     "Column28": 26
    },
    {
     "2025 QS World University Rankings": 462,
     "Column2": 494,
     "Column3": "Universidad de Sevilla",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 39.1,
     "Column11": 221,
     "Column12": 12.6,
     "Column13": "601+",
     "Column14": 18.4,
     "Column15": "701+",
     "Column16": 8.2,
     "Column17": "701+",
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 18.9,
     "Column21": 567,
     "Column22": 91.4,
     "Column23": 152,
     "Column24": 16,
     "Column25": 634,
     "Column26": 67.4,
     "Column27": 231,
     "Column28": 26
    },
    {
     "2025 QS World University Rankings": 464,
     "Column2": 505,
     "Column3": "Macau University of Science and Technology",
     "Column4": "MO",
     "Column5": "Macau SAR",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 3.9,
     "Column15": "701+",
     "Column16": 56.4,
     "Column17": 222,
     "Column18": 100,
     "Column19": 23,
     "Column20": 100,
     "Column21": 1,
     "Column22": 26.2,
     "Column23": "701+",
     "Column24": 29.5,
     "Column25": 423,
     "Column26": 11.3,
     "Column27": "636=",
     "Column28": 25.9
    },
    {
     "2025 QS World University Rankings": 465,
     "Column2": 476,
     "Column3": "Kobe University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 28.4,
     "Column11": 309,
     "Column12": 33.2,
     "Column13": 281,
     "Column14": 43.9,
     "Column15": 356,
     "Column16": 11.4,
     "Column17": "701+",
     "Column18": 7,
     "Column19": "701+",
     "Column20": 5.6,
     "Column21": "701+",
     "Column22": 55,
     "Column23": 698,
     "Column24": 15,
     "Column25": 662,
     "Column26": 49.8,
     "Column27": "319=",
     "Column28": 25.8
    },
    {
     "2025 QS World University Rankings": 466,
     "Column2": 478,
     "Column3": "Universität Konstanz",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.3,
     "Column11": 495,
     "Column12": 10.5,
     "Column13": "601+",
     "Column14": 37.8,
     "Column15": 421,
     "Column16": 18.3,
     "Column17": 578,
     "Column18": 85.9,
     "Column19": 215,
     "Column20": 14.5,
     "Column21": 634,
     "Column22": 66.1,
     "Column23": 537,
     "Column24": 13.1,
     "Column25": "701+",
     "Column26": 63.1,
     "Column27": "250=",
     "Column28": 25.7
    },
    {
     "2025 QS World University Rankings": 466,
     "Column2": "621-630",
     "Column3": "Universitat Ramon Llull",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 11,
     "Column11": "601+",
     "Column12": 52,
     "Column13": 167,
     "Column14": 8.7,
     "Column15": "701+",
     "Column16": 8.1,
     "Column17": "701+",
     "Column18": 93.6,
     "Column19": 164,
     "Column20": 43.1,
     "Column21": 336,
     "Column22": 32.6,
     "Column23": "701+",
     "Column24": 71.7,
     "Column25": 149,
     "Column26": 20.1,
     "Column27": "530=",
     "Column28": 25.7
    },
    {
     "2025 QS World University Rankings": 466,
     "Column2": 491,
     "Column3": "University at Buffalo SUNY",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18,
     "Column11": 503,
     "Column12": 10.7,
     "Column13": "601+",
     "Column14": 8.8,
     "Column15": "701+",
     "Column16": 33.6,
     "Column17": 393,
     "Column18": 20.1,
     "Column19": 596,
     "Column20": 36.9,
     "Column21": 380,
     "Column22": 68.6,
     "Column23": 504,
     "Column24": 33.3,
     "Column25": 383,
     "Column26": 82.5,
     "Column27": "143=",
     "Column28": 25.7
    },
    {
     "2025 QS World University Rankings": 466,
     "Column2": 419,
     "Column3": "Washington State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.3,
     "Column11": 379,
     "Column12": 20,
     "Column13": 452,
     "Column14": 15.5,
     "Column15": "701+",
     "Column16": 33.5,
     "Column17": 396,
     "Column18": 47.1,
     "Column19": 379,
     "Column20": 5,
     "Column21": "701+",
     "Column22": 77.3,
     "Column23": 373,
     "Column24": 8.6,
     "Column25": "701+",
     "Column26": 29.6,
     "Column27": "447=",
     "Column28": 25.7
    },
    {
     "2025 QS World University Rankings": 470,
     "Column2": 400,
     "Column3": "Iowa State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.7,
     "Column11": 425,
     "Column12": 12,
     "Column13": "601+",
     "Column14": 9.1,
     "Column15": "701+",
     "Column16": 47.9,
     "Column17": 280,
     "Column18": 7.5,
     "Column19": "701+",
     "Column20": 12.5,
     "Column21": 667,
     "Column22": 77.3,
     "Column23": 374,
     "Column24": 32.9,
     "Column25": 388,
     "Column26": 31.3,
     "Column27": "435=",
     "Column28": 25.6
    },
    {
     "2025 QS World University Rankings": 471,
     "Column2": 469,
     "Column3": "Ben-Gurion University of The Negev",
     "Column4": "IL",
     "Column5": "Israel",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.4,
     "Column11": "601+",
     "Column12": 14.5,
     "Column13": 569,
     "Column14": 11.1,
     "Column15": "701+",
     "Column16": 51.7,
     "Column17": 253,
     "Column18": 64,
     "Column19": 304,
     "Column20": 14.3,
     "Column21": 635,
     "Column22": 64.9,
     "Column23": 558,
     "Column24": 16.3,
     "Column25": 630,
     "Column26": 11.8,
     "Column27": "623=",
     "Column28": 25.5
    },
    {
     "2025 QS World University Rankings": 472,
     "Column2": 459,
     "Column3": "Essex, University of",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.5,
     "Column11": 568,
     "Column12": 5.2,
     "Column13": "601+",
     "Column14": 12.7,
     "Column15": "701+",
     "Column16": 22.6,
     "Column17": 523,
     "Column18": 99.8,
     "Column19": 93,
     "Column20": 98.6,
     "Column21": 62,
     "Column22": 75.1,
     "Column23": 408,
     "Column24": 14,
     "Column25": 693,
     "Column26": 13.2,
     "Column27": "606=",
     "Column28": 25.3
    },
    {
     "2025 QS World University Rankings": 472,
     "Column2": 446,
     "Column3": "Johannes Kepler University Linz",
     "Column4": "AT",
     "Column5": "Austria",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.8,
     "Column11": "601+",
     "Column12": 12.3,
     "Column13": "601+",
     "Column14": 75.9,
     "Column15": 162,
     "Column16": 14.3,
     "Column17": 649,
     "Column18": 83.5,
     "Column19": 226,
     "Column20": 33.5,
     "Column21": 402,
     "Column22": 59.7,
     "Column23": 636,
     "Column24": 12.2,
     "Column25": "701+",
     "Column26": 13.2,
     "Column27": "606=",
     "Column28": 25.3
    },
    {
     "2025 QS World University Rankings": 474,
     "Column2": 481,
     "Column3": "Bangor University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.6,
     "Column11": "601+",
     "Column12": 5.8,
     "Column13": "601+",
     "Column14": 12.1,
     "Column15": "701+",
     "Column16": 43,
     "Column17": 321,
     "Column18": 40.7,
     "Column19": 416,
     "Column20": 52.3,
     "Column21": 280,
     "Column22": 75,
     "Column23": 409,
     "Column24": 8.1,
     "Column25": "701+",
     "Column26": 82.9,
     "Column27": "139=",
     "Column28": 25.2
    },
    {
     "2025 QS World University Rankings": 474,
     "Column2": 472,
     "Column3": "Hiroshima University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 24.1,
     "Column11": 363,
     "Column12": 12.3,
     "Column13": "601+",
     "Column14": 75.9,
     "Column15": 161,
     "Column16": 8.9,
     "Column17": "701+",
     "Column18": 13.2,
     "Column19": "701+",
     "Column20": 12.9,
     "Column21": 660,
     "Column22": 70.1,
     "Column23": 485,
     "Column24": 6.9,
     "Column25": "701+",
     "Column26": 50.8,
     "Column27": "315=",
     "Column28": 25.2
    },
    {
     "2025 QS World University Rankings": 474,
     "Column2": 460,
     "Column3": "Universität Potsdam",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.8,
     "Column11": 557,
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 18.6,
     "Column15": "701+",
     "Column16": 36.5,
     "Column17": 375,
     "Column18": 51.8,
     "Column19": 358,
     "Column20": 21.2,
     "Column21": 526,
     "Column22": 92.9,
     "Column23": 121,
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 67.1,
     "Column27": "232=",
     "Column28": 25.2
    },
    {
     "2025 QS World University Rankings": 477,
     "Column2": 551,
     "Column3": "Ajman University",
     "Column4": "AE",
     "Column5": "United Arab Emirates",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 17.3,
     "Column11": 521,
     "Column12": 42.2,
     "Column13": 221,
     "Column14": 9.6,
     "Column15": "701+",
     "Column16": 3.2,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 6,
     "Column20": 100,
     "Column21": 3,
     "Column22": 48.4,
     "Column23": "701+",
     "Column24": 9.8,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": 25.1
    },
    {
     "2025 QS World University Rankings": 477,
     "Column2": 502,
     "Column3": "Bilkent University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 24,
     "Column11": 365,
     "Column12": 52.1,
     "Column13": 164,
     "Column14": 13.3,
     "Column15": "701+",
     "Column16": 15.2,
     "Column17": 632,
     "Column18": 61.4,
     "Column19": 319,
     "Column20": 3.7,
     "Column21": "701+",
     "Column22": 38.3,
     "Column23": "701+",
     "Column24": 19.3,
     "Column25": 573,
     "Column26": 10.6,
     "Column27": "653=",
     "Column28": 25.1
    },
    {
     "2025 QS World University Rankings": 477,
     "Column2": 454,
     "Column3": "Indian Institute of Technology Indore (IIT Indore)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 5,
     "Column13": "601+",
     "Column14": 27.1,
     "Column15": 558,
     "Column16": 95.6,
     "Column17": 47,
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 23.6,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 4.2,
     "Column27": "701+",
     "Column28": 25.1
    },
    {
     "2025 QS World University Rankings": 477,
     "Column2": 413,
     "Column3": "Royal Holloway University of London",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.9,
     "Column11": 585,
     "Column12": 5.7,
     "Column13": "601+",
     "Column14": 13,
     "Column15": "701+",
     "Column16": 27.2,
     "Column17": 474,
     "Column18": 94.1,
     "Column19": 161,
     "Column20": 86.9,
     "Column21": 141,
     "Column22": 73.2,
     "Column23": 434,
     "Column24": 7.2,
     "Column25": "701+",
     "Column26": 19.7,
     "Column27": "537=",
     "Column28": 25.1
    },
    {
     "2025 QS World University Rankings": 481,
     "Column2": 387,
     "Column3": "Aix-Marseille University",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 36.9,
     "Column11": 235,
     "Column12": 6.5,
     "Column13": "601+",
     "Column14": 7.9,
     "Column15": "701+",
     "Column16": 21.7,
     "Column17": 534,
     "Column18": 17.5,
     "Column19": 633,
     "Column20": 18.2,
     "Column21": 578,
     "Column22": 99.1,
     "Column23": 13,
     "Column24": 15.2,
     "Column25": 656,
     "Column26": 25.5,
     "Column27": "488=",
     "Column28": 25
    },
    {
     "2025 QS World University Rankings": 481,
     "Column2": 514,
     "Column3": "Pontificia Universidad Católica Argentina",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 19.9,
     "Column11": 446,
     "Column12": 29.4,
     "Column13": 320,
     "Column14": 99.9,
     "Column15": 19,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 1.9,
     "Column19": "701+",
     "Column20": 6.7,
     "Column21": "701+",
     "Column22": 12.5,
     "Column23": "701+",
     "Column24": 85.7,
     "Column25": 97,
     "Column26": 1,
     "Column27": "701+",
     "Column28": 25
    },
    {
     "2025 QS World University Rankings": 481,
     "Column2": 446,
     "Column3": "The University of Tennessee, Knoxville",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.1,
     "Column11": "601+",
     "Column12": 8.2,
     "Column13": "601+",
     "Column14": 9.5,
     "Column15": "701+",
     "Column16": 76.3,
     "Column17": 131,
     "Column18": 4.9,
     "Column19": "701+",
     "Column20": 2.4,
     "Column21": "701+",
     "Column22": 77.3,
     "Column23": 371,
     "Column24": 11,
     "Column25": "701+",
     "Column26": 15,
     "Column27": "592=",
     "Column28": 25
    },
    {
     "2025 QS World University Rankings": 484,
     "Column2": 534,
     "Column3": "China Agricultural University ",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.4,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 18.9,
     "Column15": "701+",
     "Column16": 88.8,
     "Column17": 79,
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 52.2,
     "Column23": "701+",
     "Column24": 4.5,
     "Column25": "701+",
     "Column26": 5.8,
     "Column27": "701+",
     "Column28": 24.9
    },
    {
     "2025 QS World University Rankings": 485,
     "Column3": "American University of Ras Al Khaimah ( AURAK )",
     "Column4": "AE",
     "Column5": "United Arab Emirates",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.6,
     "Column11": "601+",
     "Column12": 3.3,
     "Column13": "601+",
     "Column14": 35.5,
     "Column15": 446,
     "Column16": 46.8,
     "Column17": 293,
     "Column18": 100,
     "Column19": 10,
     "Column20": 100,
     "Column21": 15,
     "Column22": 15.4,
     "Column23": "701+",
     "Column24": 4.1,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": 24.8
    },
    {
     "2025 QS World University Rankings": 485,
     "Column2": 505,
     "Column3": "National Sun Yat-sen University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 26.5,
     "Column11": 334,
     "Column12": 24.9,
     "Column13": 383,
     "Column14": 32,
     "Column15": 493,
     "Column16": 22.1,
     "Column17": 529,
     "Column18": 22.7,
     "Column19": 563,
     "Column20": 10.8,
     "Column21": "701+",
     "Column22": 35.6,
     "Column23": "701+",
     "Column24": 11.4,
     "Column25": "701+",
     "Column26": 48.9,
     "Column27": "324=",
     "Column28": 24.8
    },
    {
     "2025 QS World University Rankings": 487,
     "Column2": 454,
     "Column3": "Universität Mannheim",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.3,
     "Column11": 572,
     "Column12": 53.9,
     "Column13": 157,
     "Column14": 3,
     "Column15": "701+",
     "Column16": 31,
     "Column17": 427,
     "Column18": 42.4,
     "Column19": 406,
     "Column20": 22.7,
     "Column21": 506,
     "Column22": 27.9,
     "Column23": "701+",
     "Column24": 34.9,
     "Column25": 363,
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": 24.7
    },
    {
     "2025 QS World University Rankings": 488,
     "Column2": 386,
     "Column3": "University of Hawai?i at M?noa",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.2,
     "Column11": 576,
     "Column12": 6.6,
     "Column13": "601+",
     "Column14": 29.7,
     "Column15": 525,
     "Column16": 41.7,
     "Column17": 332,
     "Column18": 41.7,
     "Column19": 412,
     "Column20": 12,
     "Column21": 679,
     "Column22": 82.9,
     "Column23": 278,
     "Column24": 7.9,
     "Column25": "701+",
     "Column26": 30.1,
     "Column27": "443=",
     "Column28": 24.6
    },
    {
     "2025 QS World University Rankings": 448,
     "Column2": 491,
     "Column3": "Dalian University of Technology",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.6,
     "Column11": "601+",
     "Column12": 3.8,
     "Column13": "601+",
     "Column14": 18.1,
     "Column15": "701+",
     "Column16": 88.1,
     "Column17": 82,
     "Column18": 11.5,
     "Column19": "701+",
     "Column20": 2.9,
     "Column21": "701+",
     "Column22": 55.9,
     "Column23": 680,
     "Column24": 14.4,
     "Column25": 678,
     "Column26": 8,
     "Column27": "701+",
     "Column28": 26.6
    },
    {
     "2025 QS World University Rankings": 489,
     "Column2": 494,
     "Column3": "Chung-Ang University (CAU)",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 16,
     "Column11": 555,
     "Column12": 31.9,
     "Column13": 296,
     "Column14": 67.8,
     "Column15": 195,
     "Column16": 9.3,
     "Column17": "701+",
     "Column18": 14.2,
     "Column19": 680,
     "Column20": 37.8,
     "Column21": 371,
     "Column22": 29.9,
     "Column23": "701+",
     "Column24": 13.5,
     "Column25": "701+",
     "Column26": 49.5,
     "Column27": "322=",
     "Column28": 24.5
    },
    {
     "2025 QS World University Rankings": 489,
     "Column3": "Isfahan University of Technology",
     "Column4": "IR",
     "Column5": "Iran, Islamic Republic of",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 15.7,
     "Column13": 541,
     "Column14": 5.1,
     "Column15": "701+",
     "Column16": 92.2,
     "Column17": 68,
     "Column18": 2.3,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 43.5,
     "Column23": "701+",
     "Column24": 5.6,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": 24.5
    },
    {
     "2025 QS World University Rankings": 428,
     "Column2": 454,
     "Column3": "Southeast University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.5,
     "Column11": "601+",
     "Column12": 6.6,
     "Column13": "601+",
     "Column14": 20.8,
     "Column15": 658,
     "Column16": 82,
     "Column17": 102,
     "Column18": 14.4,
     "Column19": 675,
     "Column20": 3.4,
     "Column21": "701+",
     "Column22": 69.2,
     "Column23": 494,
     "Column24": 5,
     "Column25": "701+",
     "Column26": 11.6,
     "Column27": "629=",
     "Column28": 27.8
    },
    {
     "2025 QS World University Rankings": 489,
     "Column2": 419,
     "Column3": "UNESP",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 41.1,
     "Column11": 207,
     "Column12": 25.8,
     "Column13": 368,
     "Column14": 13,
     "Column15": "701+",
     "Column16": 16.2,
     "Column17": 619,
     "Column18": 5.9,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 70.9,
     "Column23": 469,
     "Column24": 1.5,
     "Column25": "701+",
     "Column26": 14.5,
     "Column27": "600=",
     "Column28": 24.5
    },
    {
     "2025 QS World University Rankings": 489,
     "Column2": 446,
     "Column3": "University of Jyväskylä",
     "Column4": "FI",
     "Column5": "Finland",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16.2,
     "Column11": 548,
     "Column12": 11.4,
     "Column13": "601+",
     "Column14": 87.7,
     "Column15": 96,
     "Column16": 12.1,
     "Column17": 687,
     "Column18": 44,
     "Column19": 400,
     "Column20": 2.6,
     "Column21": "701+",
     "Column22": 81.2,
     "Column23": 303,
     "Column24": 10.4,
     "Column25": "701+",
     "Column26": 16.3,
     "Column27": "570=",
     "Column28": 24.5
    },
    {
     "2025 QS World University Rankings": 495,
     "Column2": 590,
     "Column3": "Central Queensland University (CQUniversity Australia)",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9,
     "Column11": "601+",
     "Column12": 6.7,
     "Column13": "601+",
     "Column14": 29.4,
     "Column15": 528,
     "Column16": 20.9,
     "Column17": 548,
     "Column18": 97.1,
     "Column19": 128,
     "Column20": 97.8,
     "Column21": 72,
     "Column22": 44,
     "Column23": "701+",
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 49.8,
     "Column27": "319=",
     "Column28": 24.4
    },
    {
     "2025 QS World University Rankings": 495,
     "Column2": 514,
     "Column3": "Duy Tan University",
     "Column4": "VN",
     "Column5": "Vietnam",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 11.9,
     "Column11": "601+",
     "Column12": 25.3,
     "Column13": 376,
     "Column14": 3.9,
     "Column15": "701+",
     "Column16": 49,
     "Column17": 273,
     "Column18": 37.7,
     "Column19": 428,
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 83.5,
     "Column23": 273,
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 29,
     "Column27": "453=",
     "Column28": 24.4
    },
    {
     "2025 QS World University Rankings": 489,
     "Column2": 561,
     "Column3": "Chongqing University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.6,
     "Column11": "601+",
     "Column12": 4.6,
     "Column13": "601+",
     "Column14": 12.1,
     "Column15": "701+",
     "Column16": 81.4,
     "Column17": 105,
     "Column18": 21.3,
     "Column19": 582,
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 59.6,
     "Column23": 637,
     "Column24": 13.6,
     "Column25": "701+",
     "Column26": 4.1,
     "Column27": "701+",
     "Column28": 24.5
    },
    {
     "2025 QS World University Rankings": 497,
     "Column2": 461,
     "Column3": "National Research Nuclear University MEPhI (Moscow Engineering Physics Institute)",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.2,
     "Column11": "601+",
     "Column12": 11.8,
     "Column13": "601+",
     "Column14": 98.9,
     "Column15": 39,
     "Column16": 8.1,
     "Column17": "701+",
     "Column18": 45.8,
     "Column19": 391,
     "Column20": 89.5,
     "Column21": 125,
     "Column22": 32.9,
     "Column23": "701+",
     "Column24": 14.6,
     "Column25": 675,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": 24.2
    },
    {
     "2025 QS World University Rankings": 497,
     "Column2": 544,
     "Column3": "Universidad de Costa Rica",
     "Column4": "CR",
     "Column5": "Costa Rica",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 35.4,
     "Column11": 248,
     "Column12": 24.3,
     "Column13": 397,
     "Column14": 32.3,
     "Column15": 487,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 45.5,
     "Column23": "701+",
     "Column24": 81.6,
     "Column25": 115,
     "Column26": 16.9,
     "Column27": "563=",
     "Column28": 24.2
    },
    {
     "2025 QS World University Rankings": 497,
     "Column2": 404,
     "Column3": "University of California, Riverside",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16.3,
     "Column11": 546,
     "Column12": 9.4,
     "Column13": "601+",
     "Column14": 6.6,
     "Column15": "701+",
     "Column16": 50.9,
     "Column17": 256,
     "Column18": 47.9,
     "Column19": 375,
     "Column20": 10.4,
     "Column21": "701+",
     "Column22": 77.3,
     "Column23": 372,
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 21.3,
     "Column27": "521=",
     "Column28": 24.2
    },
    {
     "2025 QS World University Rankings": 501,
     "Column2": 580,
     "Column3": "Abu Dhabi University",
     "Column4": "AE",
     "Column5": "United Arab Emirates",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 15.9,
     "Column11": 556,
     "Column12": 24.6,
     "Column13": 390,
     "Column14": 14.9,
     "Column15": "701+",
     "Column16": 16.7,
     "Column17": 610,
     "Column18": 100,
     "Column19": 5,
     "Column20": 99.8,
     "Column21": 38,
     "Column22": 27.3,
     "Column23": "701+",
     "Column24": 8.3,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": 24.1
    },
    {
     "2025 QS World University Rankings": 501,
     "Column2": 465,
     "Column3": "Colorado School of Mines",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.8,
     "Column11": "601+",
     "Column12": 12.5,
     "Column13": "601+",
     "Column14": 15.8,
     "Column15": "701+",
     "Column16": 72,
     "Column17": 148,
     "Column18": 29.7,
     "Column19": 496,
     "Column20": 7.4,
     "Column21": "701+",
     "Column22": 30.9,
     "Column23": "701+",
     "Column24": 17.7,
     "Column25": 604,
     "Column26": 18,
     "Column27": "555=",
     "Column28": 24.1
    },
    {
     "2025 QS World University Rankings": 511,
     "Column2": "621-630",
     "Column3": "Zhengzhou University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 22,
     "Column13": 422,
     "Column14": 4.9,
     "Column15": "701+",
     "Column16": 80.3,
     "Column17": 108,
     "Column20": 2.4,
     "Column21": "701+",
     "Column22": 56.3,
     "Column23": 674,
     "Column24": 5.7,
     "Column25": "701+",
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": 23.8
    },
    {
     "2025 QS World University Rankings": 501,
     "Column2": 464,
     "Column3": "Johannes Gutenberg Universität Mainz",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 22.3,
     "Column11": 400,
     "Column12": 11.4,
     "Column13": "601+",
     "Column14": 57.8,
     "Column15": 254,
     "Column16": 11.5,
     "Column17": "701+",
     "Column18": 42,
     "Column19": 411,
     "Column20": 15.5,
     "Column21": 614,
     "Column22": 87,
     "Column23": 215,
     "Column24": 11.4,
     "Column25": "701+",
     "Column26": 16.3,
     "Column27": "570=",
     "Column28": 24.1
    },
    {
     "2025 QS World University Rankings": 501,
     "Column2": 567,
     "Column3": "University of Huddersfield",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.7,
     "Column11": "601+",
     "Column12": 12.4,
     "Column13": "601+",
     "Column14": 7.5,
     "Column15": "701+",
     "Column16": 19.4,
     "Column17": 564,
     "Column18": 63.7,
     "Column19": 308,
     "Column20": 85.6,
     "Column21": 148,
     "Column22": 73,
     "Column23": 441,
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 72.3,
     "Column27": "205=",
     "Column28": 24.1
    },
    {
     "2025 QS World University Rankings": 506,
     "Column2": 498,
     "Column3": "University of Delaware",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.4,
     "Column11": "601+",
     "Column12": 7.9,
     "Column13": "601+",
     "Column14": 6.6,
     "Column15": "701+",
     "Column16": 53.9,
     "Column17": 237,
     "Column18": 30,
     "Column19": 495,
     "Column20": 12.6,
     "Column21": 665,
     "Column22": 66.5,
     "Column23": 530,
     "Column24": 16.9,
     "Column25": 617,
     "Column26": 65.3,
     "Column27": "239=",
     "Column28": 24
    },
    {
     "2025 QS World University Rankings": 506,
     "Column2": 429,
     "Column3": "University of Trento",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 24.9,
     "Column11": 352,
     "Column12": 10.5,
     "Column13": "601+",
     "Column14": 22,
     "Column15": 639,
     "Column16": 32.7,
     "Column17": 402,
     "Column18": 29.6,
     "Column19": 497,
     "Column20": 6.1,
     "Column21": "701+",
     "Column22": 85.8,
     "Column23": 234,
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 19,
     "Column27": 547,
     "Column28": 24
    },
    {
     "2025 QS World University Rankings": 508,
     "Column2": 530,
     "Column3": "Aristotle University of Thessaloniki",
     "Column4": "GR",
     "Column5": "Greece",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 28.2,
     "Column11": 313,
     "Column12": 26.1,
     "Column13": 365,
     "Column14": 4.3,
     "Column15": "701+",
     "Column16": 15.8,
     "Column17": 623,
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 4.1,
     "Column21": "701+",
     "Column22": 90.5,
     "Column23": 164,
     "Column24": 38.3,
     "Column25": 335,
     "Column26": 44.3,
     "Column27": "348=",
     "Column28": 23.9
    },
    {
     "2025 QS World University Rankings": "631-640",
     "Column2": "711-720",
     "Column3": "China University of Petroleum",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.4,
     "Column11": "601+",
     "Column12": 1.7,
     "Column13": "601+",
     "Column14": 8.6,
     "Column15": "701+",
     "Column16": 79.7,
     "Column17": 109,
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 2.8,
     "Column21": "701+",
     "Column22": 24.1,
     "Column23": "701+",
     "Column24": 21.1,
     "Column25": 543,
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": 508,
     "Column2": 511,
     "Column3": "SOAS University of London ",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.1,
     "Column11": 528,
     "Column12": 9.4,
     "Column13": "601+",
     "Column14": 14.8,
     "Column15": "701+",
     "Column16": 15.1,
     "Column17": 635,
     "Column18": 100,
     "Column19": 60,
     "Column20": 98.7,
     "Column21": 61,
     "Column22": 16.9,
     "Column23": "701+",
     "Column24": 22.4,
     "Column25": 513,
     "Column26": 39.1,
     "Column27": 378,
     "Column28": 23.9
    },
    {
     "2025 QS World University Rankings": 511,
     "Column2": 498,
     "Column3": "Ewha Womans University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 22.1,
     "Column11": 401,
     "Column12": 21.2,
     "Column13": 434,
     "Column14": 76.3,
     "Column15": 157,
     "Column16": 6.9,
     "Column17": "701+",
     "Column18": 7,
     "Column19": "701+",
     "Column20": 24.4,
     "Column21": 483,
     "Column22": 31.3,
     "Column23": "701+",
     "Column24": 23,
     "Column25": 496,
     "Column26": 34.2,
     "Column27": "413=",
     "Column28": 23.8
    },
    {
     "2025 QS World University Rankings": 452,
     "Column2": 473,
     "Column3": "Beihang University (former BUAA)",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.1,
     "Column11": "601+",
     "Column12": 7.8,
     "Column13": "601+",
     "Column14": 41.5,
     "Column15": 375,
     "Column16": 72.1,
     "Column17": 147,
     "Column18": 7,
     "Column19": "701+",
     "Column20": 4.5,
     "Column21": "701+",
     "Column22": 51.2,
     "Column23": "701+",
     "Column24": 12.8,
     "Column25": "701+",
     "Column26": 4.3,
     "Column27": "701+",
     "Column28": 26.4
    },
    {
     "2025 QS World University Rankings": 513,
     "Column2": 595,
     "Column3": "Imam Abdulrahman Bin Faisal University (IAU) (formerly UNIVERSITY OF DAMMAM)",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 10.5,
     "Column11": "601+",
     "Column12": 11.4,
     "Column13": "601+",
     "Column14": 89.5,
     "Column15": 87,
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 98.2,
     "Column19": 118,
     "Column20": 19.7,
     "Column21": 548,
     "Column22": 68.3,
     "Column23": 509,
     "Column24": 2.4,
     "Column25": "701+",
     "Column26": 18.4,
     "Column27": "549=",
     "Column28": 23.7
    },
    {
     "2025 QS World University Rankings": 513,
     "Column2": 590,
     "Column3": "Prince Mohammad Bin Fahd university",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 20.3,
     "Column11": 436,
     "Column12": 23,
     "Column13": 409,
     "Column14": 23.1,
     "Column15": 621,
     "Column16": 27.8,
     "Column17": 468,
     "Column18": 100,
     "Column19": 11,
     "Column20": 18.8,
     "Column21": 570,
     "Column22": 23.3,
     "Column23": "701+",
     "Column24": 2.8,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": 23.7
    },
    {
     "2025 QS World University Rankings": 513,
     "Column2": 481,
     "Column3": "University of Milano-Bicocca ",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.4,
     "Column11": 432,
     "Column12": 10.9,
     "Column13": "601+",
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 54.1,
     "Column17": 236,
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 4.3,
     "Column21": "701+",
     "Column22": 81.1,
     "Column23": 307,
     "Column24": 11.5,
     "Column25": "701+",
     "Column26": 14.8,
     "Column27": 596,
     "Column28": 23.7
    },
    {
     "2025 QS World University Rankings": 516,
     "Column2": 563,
     "Column3": "Ateneo de Manila University",
     "Column4": "PH",
     "Column5": "Philippines",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 21.4,
     "Column11": 410,
     "Column12": 64.5,
     "Column13": 122,
     "Column14": 24.4,
     "Column15": 601,
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 23,
     "Column23": "701+",
     "Column24": 70.6,
     "Column25": 156,
     "Column26": 15.6,
     "Column27": 584,
     "Column28": 23.6
    },
    {
     "2025 QS World University Rankings": 516,
     "Column2": 529,
     "Column3": "Edith Cowan University ",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.5,
     "Column11": "601+",
     "Column12": 6.8,
     "Column13": "601+",
     "Column14": 4.8,
     "Column15": "701+",
     "Column16": 49.8,
     "Column17": 265,
     "Column18": 78.5,
     "Column19": 245,
     "Column20": 57.7,
     "Column21": 256,
     "Column22": 64.5,
     "Column23": 565,
     "Column24": 4.7,
     "Column25": "701+",
     "Column26": 5.8,
     "Column27": "701+",
     "Column28": 23.6
    },
    {
     "2025 QS World University Rankings": 516,
     "Column2": 556,
     "Column3": "INTI International University",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 7.7,
     "Column11": "601+",
     "Column12": 19.9,
     "Column13": 453,
     "Column14": 89.1,
     "Column15": 90,
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 95.4,
     "Column19": 143,
     "Column20": 92.9,
     "Column21": 102,
     "Column22": 7,
     "Column23": "701+",
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": 23.6
    },
    {
     "2025 QS World University Rankings": 516,
     "Column2": 520,
     "Column3": "Kyungpook National University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.1,
     "Column11": "601+",
     "Column12": 16.7,
     "Column13": 514,
     "Column14": 66.9,
     "Column15": 196,
     "Column16": 20.5,
     "Column17": 551,
     "Column18": 11.1,
     "Column19": "701+",
     "Column20": 25.7,
     "Column21": 470,
     "Column22": 52,
     "Column23": "701+",
     "Column24": 10.6,
     "Column25": "701+",
     "Column26": 60.6,
     "Column27": "266=",
     "Column28": 23.6
    },
    {
     "2025 QS World University Rankings": 516,
     "Column2": 526,
     "Column3": "Sabanci University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 19.5,
     "Column11": 457,
     "Column12": 44.6,
     "Column13": 206,
     "Column14": 38.2,
     "Column15": 416,
     "Column16": 19.4,
     "Column17": 565,
     "Column18": 32,
     "Column19": 483,
     "Column20": 14.2,
     "Column21": 637,
     "Column22": 25.9,
     "Column23": "701+",
     "Column24": 7.2,
     "Column25": "701+",
     "Column26": 7.9,
     "Column27": "701+",
     "Column28": 23.6
    },
    {
     "2025 QS World University Rankings": 516,
     "Column2": 523,
     "Column3": "University of Hull",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.9,
     "Column11": "601+",
     "Column12": 4.5,
     "Column13": "601+",
     "Column14": 14.3,
     "Column15": "701+",
     "Column16": 21.5,
     "Column17": 537,
     "Column18": 63.3,
     "Column19": 309,
     "Column20": 81.5,
     "Column21": 166,
     "Column22": 76.9,
     "Column23": 380,
     "Column24": 20.5,
     "Column25": 555,
     "Column26": 68,
     "Column27": "228=",
     "Column28": 23.6
    },
    {
     "2025 QS World University Rankings": 516,
     "Column2": 473,
     "Column3": "Ural Federal University - UrFU",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.6,
     "Column11": 455,
     "Column12": 14.2,
     "Column13": 577,
     "Column14": 95.5,
     "Column15": 60,
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 5.4,
     "Column19": "701+",
     "Column20": 25.3,
     "Column21": 475,
     "Column22": 86.6,
     "Column23": 220,
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 12.7,
     "Column27": "615=",
     "Column28": 23.6
    },
    {
     "2025 QS World University Rankings": 523,
     "Column2": 551,
     "Column3": "Canadian University Dubai",
     "Column4": "AE",
     "Column5": "United Arab Emirates",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 20.1,
     "Column11": 442,
     "Column12": 19.9,
     "Column13": 455,
     "Column14": 33.5,
     "Column15": 471,
     "Column16": 2.7,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 4,
     "Column20": 100,
     "Column21": 6,
     "Column22": 14,
     "Column23": "701+",
     "Column24": 17.4,
     "Column25": 609,
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": 23.5
    },
    {
     "2025 QS World University Rankings": 524,
     "Column2": "611-620",
     "Column3": "Pusan National University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.7,
     "Column11": 482,
     "Column12": 16.5,
     "Column13": 516,
     "Column14": 43.5,
     "Column15": 359,
     "Column16": 19.5,
     "Column17": 562,
     "Column18": 10.5,
     "Column19": "701+",
     "Column20": 13.7,
     "Column21": 646,
     "Column22": 57.7,
     "Column23": 657,
     "Column24": 13.8,
     "Column25": 700,
     "Column26": 65,
     "Column27": "241=",
     "Column28": 23.4
    },
    {
     "2025 QS World University Rankings": 524,
     "Column2": 520,
     "Column3": "Universidad Austral",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 20.9,
     "Column11": 420,
     "Column12": 36.2,
     "Column13": 259,
     "Column14": 79.7,
     "Column15": 139,
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 2.7,
     "Column19": "701+",
     "Column20": 7.5,
     "Column21": "701+",
     "Column22": 10.2,
     "Column23": "701+",
     "Column24": 65.9,
     "Column25": 174,
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": 23.4
    },
    {
     "2025 QS World University Rankings": 526,
     "Column2": 461,
     "Column3": "Universität Jena",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.2,
     "Column11": 525,
     "Column12": 5.7,
     "Column13": "601+",
     "Column14": 99.9,
     "Column15": 23,
     "Column16": 8.8,
     "Column17": "701+",
     "Column20": 20.2,
     "Column21": 535,
     "Column22": 94.7,
     "Column23": 82,
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 13.1,
     "Column27": "613=",
     "Column28": 23.3
    },
    {
     "2025 QS World University Rankings": 527,
     "Column2": 480,
     "Column3": "Missouri University of Science and Technology",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4,
     "Column11": "601+",
     "Column12": 6.1,
     "Column13": "601+",
     "Column14": 5.7,
     "Column15": "701+",
     "Column16": 86.2,
     "Column17": 90,
     "Column18": 35.2,
     "Column19": 455,
     "Column20": 12.8,
     "Column21": 663,
     "Column22": 30.5,
     "Column23": "701+",
     "Column24": 4.8,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": 23.2
    },
    {
     "2025 QS World University Rankings": 527,
     "Column2": 479,
     "Column3": "Universität Leipzig",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.5,
     "Column11": 378,
     "Column12": 9.7,
     "Column13": "601+",
     "Column14": 74.8,
     "Column15": 167,
     "Column16": 8.4,
     "Column17": "701+",
     "Column20": 14.2,
     "Column21": 638,
     "Column22": 84.3,
     "Column23": 257,
     "Column24": 10.5,
     "Column25": "701+",
     "Column26": 21.3,
     "Column27": "521=",
     "Column28": 23.2
    },
    {
     "2025 QS World University Rankings": 527,
     "Column2": 509,
     "Column3": "University of Bayreuth",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.2,
     "Column11": "601+",
     "Column12": 15.7,
     "Column13": 544,
     "Column14": 50.3,
     "Column15": 307,
     "Column16": 25.8,
     "Column17": 489,
     "Column18": 54.8,
     "Column19": 339,
     "Column20": 21.2,
     "Column21": 525,
     "Column22": 75.1,
     "Column23": 407,
     "Column24": 9,
     "Column25": "701+",
     "Column26": 11.3,
     "Column27": "636=",
     "Column28": 23.2
    },
    {
     "2025 QS World University Rankings": 527,
     "Column2": 571,
     "Column3": "Warsaw University of Technology",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.3,
     "Column11": 380,
     "Column12": 51.9,
     "Column13": 168,
     "Column14": 42.6,
     "Column15": 366,
     "Column16": 4.7,
     "Column17": "701+",
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 10.3,
     "Column21": "701+",
     "Column22": 60.6,
     "Column23": 627,
     "Column24": 9.8,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": 23.2
    },
    {
     "2025 QS World University Rankings": 531,
     "Column2": 571,
     "Column3": "Coventry University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.7,
     "Column11": "601+",
     "Column12": 9.5,
     "Column13": "601+",
     "Column14": 16.9,
     "Column15": "701+",
     "Column16": 7.1,
     "Column17": "701+",
     "Column18": 77,
     "Column19": 252,
     "Column20": 99.9,
     "Column21": 35,
     "Column22": 82.6,
     "Column23": 283,
     "Column24": 10,
     "Column25": "701+",
     "Column26": 64.4,
     "Column27": "243=",
     "Column28": 23.1
    },
    {
     "2025 QS World University Rankings": 531,
     "Column2": 571,
     "Column3": "Indian Institute of Technology (BHU) Varanasi",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 5.9,
     "Column13": "601+",
     "Column14": 3.9,
     "Column15": "701+",
     "Column16": 95,
     "Column17": 48,
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 24.5,
     "Column23": "701+",
     "Column24": 6.5,
     "Column25": "701+",
     "Column26": 10.1,
     "Column27": "661=",
     "Column28": 23.1
    },
    {
     "2025 QS World University Rankings": 531,
     "Column2": 441,
     "Column3": "University of Utah",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.7,
     "Column11": 561,
     "Column12": 6.3,
     "Column13": "601+",
     "Column14": 50,
     "Column15": 309,
     "Column16": 30.5,
     "Column17": 431,
     "Column18": 15.5,
     "Column19": 659,
     "Column20": 10.3,
     "Column21": "701+",
     "Column22": 80.5,
     "Column23": 313,
     "Column24": 17.4,
     "Column25": 608,
     "Column26": 24.5,
     "Column27": "492=",
     "Column28": 23.1
    },
    {
     "2025 QS World University Rankings": 534,
     "Column2": 540,
     "Column3": "Universidad Nacional de La Plata (UNLP)",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 40.8,
     "Column11": 208,
     "Column12": 19.2,
     "Column13": 466,
     "Column14": 28.4,
     "Column15": 547,
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 5.3,
     "Column19": "701+",
     "Column20": 22.6,
     "Column21": 507,
     "Column22": 55.4,
     "Column23": 689,
     "Column24": 27.3,
     "Column25": 438,
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": 23
    },
    {
     "2025 QS World University Rankings": 535,
     "Column2": 540,
     "Column3": "Lahore University of Management Sciences (LUMS)",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 17.2,
     "Column11": 526,
     "Column12": 73.2,
     "Column13": 93,
     "Column14": 27.1,
     "Column15": 560,
     "Column16": 7.4,
     "Column17": "701+",
     "Column18": 12.2,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 19.5,
     "Column23": "701+",
     "Column24": 37,
     "Column25": 345,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": 22.9
    },
    {
     "2025 QS World University Rankings": 535,
     "Column2": 548,
     "Column3": "Northumbria University at Newcastle",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.9,
     "Column11": "601+",
     "Column12": 10.8,
     "Column13": "601+",
     "Column14": 11.8,
     "Column15": "701+",
     "Column16": 15.8,
     "Column17": 622,
     "Column18": 70.7,
     "Column19": 281,
     "Column20": 56.2,
     "Column21": 267,
     "Column22": 78.4,
     "Column23": 350,
     "Column24": 14,
     "Column25": 691,
     "Column26": 75.3,
     "Column27": "184=",
     "Column28": 22.9
    },
    {
     "2025 QS World University Rankings": 535,
     "Column2": 465,
     "Column3": "University of Bordeaux",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 31.3,
     "Column11": 284,
     "Column12": 5.5,
     "Column13": "601+",
     "Column14": 11.5,
     "Column15": "701+",
     "Column16": 17.6,
     "Column17": 588,
     "Column18": 27.1,
     "Column19": 517,
     "Column20": 23.3,
     "Column21": 500,
     "Column22": 92.9,
     "Column23": 120,
     "Column24": 16.7,
     "Column25": 620,
     "Column26": 20.1,
     "Column27": "530=",
     "Column28": 22.9
    },
    {
     "2025 QS World University Rankings": 535,
     "Column2": 548,
     "Column3": "University of Eastern Finland",
     "Column4": "FI",
     "Column5": "Finland",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.8,
     "Column11": "601+",
     "Column12": 7.6,
     "Column13": "601+",
     "Column14": 43.7,
     "Column15": 357,
     "Column16": 18.4,
     "Column17": 576,
     "Column18": 37.4,
     "Column19": 431,
     "Column20": 5.2,
     "Column21": "701+",
     "Column22": 86.4,
     "Column23": 224,
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 78.6,
     "Column27": "164=",
     "Column28": 22.9
    },
    {
     "2025 QS World University Rankings": 539,
     "Column2": 582,
     "Column3": "Applied Science University - Bahrain",
     "Column4": "BH",
     "Column5": "Bahrain",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 23.5,
     "Column11": 377,
     "Column12": 25,
     "Column13": 380,
     "Column14": 39.3,
     "Column15": 398,
     "Column16": 2,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 17,
     "Column20": 46.8,
     "Column21": 313,
     "Column22": 13.3,
     "Column23": "701+",
     "Column24": 11.3,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": 22.8
    },
    {
     "2025 QS World University Rankings": 539,
     "Column2": 481,
     "Column3": "Hitotsubashi University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.4,
     "Column11": "601+",
     "Column12": 73,
     "Column13": 94,
     "Column14": 30.8,
     "Column15": 510,
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 19.8,
     "Column19": 600,
     "Column20": 20,
     "Column21": 539,
     "Column22": 7.5,
     "Column23": "701+",
     "Column24": 51.9,
     "Column25": 242,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": 22.8
    },
    {
     "2025 QS World University Rankings": 539,
     "Column2": 586,
     "Column3": "Sunway University",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 13,
     "Column11": "601+",
     "Column12": 39.2,
     "Column13": 240,
     "Column14": 44,
     "Column15": 355,
     "Column16": 10.3,
     "Column17": "701+",
     "Column18": 35.9,
     "Column19": 443,
     "Column20": 33.2,
     "Column21": 404,
     "Column22": 51.1,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 27.3,
     "Column27": "468=",
     "Column28": 22.8
    },
    {
     "2025 QS World University Rankings": 539,
     "Column2": 563,
     "Column3": "Université de Fribourg",
     "Column4": "CH",
     "Column5": "Switzerland",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.3,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 7.5,
     "Column15": "701+",
     "Column16": 25.4,
     "Column17": 493,
     "Column18": 99.9,
     "Column19": 84,
     "Column20": 38.3,
     "Column21": 368,
     "Column22": 58.3,
     "Column23": 654,
     "Column24": 40.6,
     "Column25": 314,
     "Column26": 51.8,
     "Column27": "310=",
     "Column28": 22.8
    },
    {
     "2025 QS World University Rankings": 539,
     "Column2": 534,
     "Column3": "University of Salamanca",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 26.9,
     "Column11": 328,
     "Column12": 14.6,
     "Column13": 567,
     "Column14": 26,
     "Column15": 570,
     "Column16": 6.2,
     "Column17": "701+",
     "Column18": 6.9,
     "Column19": "701+",
     "Column20": 14.9,
     "Column21": 625,
     "Column22": 80,
     "Column23": 322,
     "Column24": 23.4,
     "Column25": 484,
     "Column26": 69.5,
     "Column27": "223=",
     "Column28": 22.8
    },
    {
     "2025 QS World University Rankings": 544,
     "Column2": 523,
     "Column3": "Universidad de Zaragoza",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 21.3,
     "Column11": 413,
     "Column12": 5.1,
     "Column13": "601+",
     "Column14": 56.1,
     "Column15": 262,
     "Column16": 7,
     "Column17": "701+",
     "Column18": 4.5,
     "Column19": "701+",
     "Column20": 9,
     "Column21": "701+",
     "Column22": 89.5,
     "Column23": 174,
     "Column24": 10.7,
     "Column25": "701+",
     "Column26": 77.1,
     "Column27": 175,
     "Column28": 22.7
    },
    {
     "2025 QS World University Rankings": 544,
     "Column2": 582,
     "Column3": "Universidad Pontificia Comillas",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 8.3,
     "Column11": "601+",
     "Column12": 71.6,
     "Column13": 97,
     "Column14": 38.3,
     "Column15": 415,
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 18.7,
     "Column19": 616,
     "Column20": 5,
     "Column21": "701+",
     "Column22": 9,
     "Column23": "701+",
     "Column24": 85.4,
     "Column25": 100,
     "Column26": 7.1,
     "Column27": "701+",
     "Column28": 22.7
    },
    {
     "2025 QS World University Rankings": 531,
     "Column2": "641-650",
     "Column3": "University of Bradford",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.1,
     "Column11": "601+",
     "Column12": 7.1,
     "Column13": "601+",
     "Column14": 6.8,
     "Column15": "701+",
     "Column16": 21.7,
     "Column17": 534,
     "Column18": 68.5,
     "Column19": 290,
     "Column20": 97.6,
     "Column21": 75,
     "Column22": 66,
     "Column23": 539,
     "Column24": 34.3,
     "Column25": 368,
     "Column26": 40.7,
     "Column27": 362,
     "Column28": 23.1
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column2": "681-690",
     "Column3": "East China University of Science and Technology",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 11.8,
     "Column15": "701+",
     "Column16": 69.7,
     "Column17": 156,
     "Column18": 2.5,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 39.6,
     "Column23": "701+",
     "Column24": 10.5,
     "Column25": "701+",
     "Column26": 2.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": 547,
     "Column3": "Tashkent Institute of Irrigation and Agricultural Mechanization Engineers - National Research University (TIIAME-NRU)",
     "Column4": "UZ",
     "Column5": "Uzbekistan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 30.6,
     "Column11": 290,
     "Column12": 19.9,
     "Column13": 456,
     "Column14": 79,
     "Column15": 144,
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 26.3,
     "Column19": 522,
     "Column20": 4.1,
     "Column21": "701+",
     "Column22": 8.6,
     "Column23": "701+",
     "Column24": 15.1,
     "Column25": 659,
     "Column26": 9.5,
     "Column27": "678=",
     "Column28": 22.6
    },
    {
     "2025 QS World University Rankings": 547,
     "Column2": 530,
     "Column3": "University of Genoa",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.8,
     "Column11": 479,
     "Column12": 4.9,
     "Column13": "601+",
     "Column14": 12.7,
     "Column15": "701+",
     "Column16": 43.7,
     "Column17": 314,
     "Column18": 5.4,
     "Column19": "701+",
     "Column20": 9.2,
     "Column21": "701+",
     "Column22": 81.2,
     "Column23": 304,
     "Column24": 10.5,
     "Column25": "701+",
     "Column26": 39.5,
     "Column27": "371=",
     "Column28": 22.6
    },
    {
     "2025 QS World University Rankings": 547,
     "Column2": "1201-1400",
     "Column3": "University of Iceland",
     "Column4": "IS",
     "Column5": "Iceland",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column10": 6.8,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 99.9,
     "Column15": 21,
     "Column16": 6.7,
     "Column17": "701+",
     "Column18": 25.7,
     "Column19": 525,
     "Column20": 10.3,
     "Column21": "701+",
     "Column22": 73.5,
     "Column23": 427,
     "Column24": 83.2,
     "Column25": 108,
     "Column26": 5.9,
     "Column27": "701+",
     "Column28": 22.6
    },
    {
     "2025 QS World University Rankings": 547,
     "Column2": "641-650",
     "Column3": "University of Windsor",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.9,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 2.2,
     "Column15": "701+",
     "Column16": 36.2,
     "Column17": 378,
     "Column18": 88.7,
     "Column19": 199,
     "Column20": 83.8,
     "Column21": 159,
     "Column22": 51.1,
     "Column23": "701+",
     "Column24": 25.8,
     "Column25": 458,
     "Column26": 38.5,
     "Column27": "381=",
     "Column28": 22.6
    },
    {
     "2025 QS World University Rankings": 552,
     "Column2": "611-620",
     "Column3": "Al Ain University",
     "Column4": "AE",
     "Column5": "United Arab Emirates",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 22.6,
     "Column11": 397,
     "Column12": 15.7,
     "Column13": 543,
     "Column14": 13.4,
     "Column15": "701+",
     "Column16": 5.9,
     "Column17": "701+",
     "Column18": 100,
     "Column19": "1=",
     "Column20": 100,
     "Column21": 18,
     "Column22": 27.5,
     "Column23": "701+",
     "Column24": 5.9,
     "Column25": "701+",
     "Column26": 5.2,
     "Column27": "701+",
     "Column28": 22.5
    },
    {
     "2025 QS World University Rankings": 552,
     "Column3": "University of Tabriz",
     "Column4": "IR",
     "Column5": "Iran, Islamic Republic of",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.9,
     "Column11": "601+",
     "Column12": 5,
     "Column13": "601+",
     "Column14": 3.4,
     "Column15": "701+",
     "Column16": 81.7,
     "Column17": 104,
     "Column18": 1.5,
     "Column19": "701+",
     "Column20": 6.8,
     "Column21": "701+",
     "Column22": 71.8,
     "Column23": 453,
     "Column24": 5.3,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": 22.5
    },
    {
     "2025 QS World University Rankings": 554,
     "Column2": 551,
     "Column3": "Hasselt University ",
     "Column4": "BE",
     "Column5": "Belgium",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 3.3,
     "Column13": "601+",
     "Column14": 82.1,
     "Column15": 120,
     "Column16": 15.4,
     "Column17": 630,
     "Column18": 74.2,
     "Column19": 268,
     "Column20": 25.3,
     "Column21": 476,
     "Column22": 69.6,
     "Column23": 490,
     "Column24": 2.4,
     "Column25": "701+",
     "Column26": 26.8,
     "Column27": "473=",
     "Column28": 22.4
    },
    {
     "2025 QS World University Rankings": 554,
     "Column2": "731-740",
     "Column3": "Jordan University of Science & Technology",
     "Column4": "JO",
     "Column5": "Jordan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 21,
     "Column11": 419,
     "Column12": 30.7,
     "Column13": 306,
     "Column14": 18.8,
     "Column15": "701+",
     "Column16": 5.6,
     "Column17": "701+",
     "Column18": 38.2,
     "Column19": 425,
     "Column20": 67.7,
     "Column21": 218,
     "Column22": 55.5,
     "Column23": 685,
     "Column24": 4.3,
     "Column25": "701+",
     "Column26": 24.5,
     "Column27": "492=",
     "Column28": 22.4
    },
    {
     "2025 QS World University Rankings": 554,
     "Column2": 538,
     "Column3": "Universiti Utara Malaysia (UUM)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 22,
     "Column11": 402,
     "Column12": 25.4,
     "Column13": 373,
     "Column14": 38.8,
     "Column15": 406,
     "Column16": 8.2,
     "Column17": "701+",
     "Column18": 19.4,
     "Column19": 609,
     "Column20": 79.3,
     "Column21": 173,
     "Column22": 34.1,
     "Column23": "701+",
     "Column24": 12.3,
     "Column25": "701+",
     "Column26": 5.3,
     "Column27": "701+",
     "Column28": 22.4
    },
    {
     "2025 QS World University Rankings": 554,
     "Column2": "691-700",
     "Column3": "University of Dhaka",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 23,
     "Column11": 390,
     "Column12": 45.6,
     "Column13": 201,
     "Column14": 11.2,
     "Column15": "701+",
     "Column16": 2.9,
     "Column17": "701+",
     "Column18": 1.6,
     "Column19": "701+",
     "Column20": 1,
     "Column21": "701+",
     "Column22": 52,
     "Column23": "701+",
     "Column24": 93.7,
     "Column25": 59,
     "Column26": 11.8,
     "Column27": "623=",
     "Column28": 22.4
    },
    {
     "2025 QS World University Rankings": 554,
     "Column2": 542,
     "Column3": "University Ulm",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.4,
     "Column11": "601+",
     "Column12": 5.5,
     "Column13": "601+",
     "Column14": 78.5,
     "Column15": 147,
     "Column16": 31.2,
     "Column17": 422,
     "Column18": 39.6,
     "Column19": 420,
     "Column20": 21,
     "Column21": 528,
     "Column22": 61.1,
     "Column23": 615,
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 2.1,
     "Column27": "701+",
     "Column28": 22.4
    },
    {
     "2025 QS World University Rankings": 559,
     "Column2": 534,
     "Column3": "Peter the Great St. Petersburg Polytechnic University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16,
     "Column11": 551,
     "Column12": 10.9,
     "Column13": "601+",
     "Column14": 74.3,
     "Column15": 171,
     "Column16": 2.9,
     "Column17": "701+",
     "Column18": 14.1,
     "Column19": 681,
     "Column20": 84,
     "Column21": 157,
     "Column22": 64.7,
     "Column23": 562,
     "Column24": 4.9,
     "Column25": "701+",
     "Column26": 11.2,
     "Column27": "642=",
     "Column28": 22.3
    },
    {
     "2025 QS World University Rankings": 559,
     "Column2": 498,
     "Column3": "Ulster University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.1,
     "Column11": "601+",
     "Column12": 11.3,
     "Column13": "601+",
     "Column14": 9.6,
     "Column15": "701+",
     "Column16": 11.5,
     "Column17": "701+",
     "Column18": 99.8,
     "Column19": 91,
     "Column20": 77.6,
     "Column21": 182,
     "Column22": 73.4,
     "Column23": 431,
     "Column24": 6.8,
     "Column25": "701+",
     "Column26": 62.2,
     "Column27": "254=",
     "Column28": 22.3
    },
    {
     "2025 QS World University Rankings": 559,
     "Column2": "601-610",
     "Column3": "Umm Al-Qura University ",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 20.7,
     "Column11": 423,
     "Column12": 12.8,
     "Column13": "601+",
     "Column14": 54.7,
     "Column15": 279,
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 80,
     "Column20": 16,
     "Column21": 606,
     "Column22": 59.7,
     "Column23": 635,
     "Column24": 6.7,
     "Column25": "701+",
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": 22.3
    },
    {
     "2025 QS World University Rankings": 562,
     "Column2": 452,
     "Column3": "Université Claude Bernard Lyon 1",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.1,
     "Column11": 502,
     "Column12": 3.7,
     "Column13": "601+",
     "Column14": 7.9,
     "Column15": "701+",
     "Column16": 48.3,
     "Column17": 276,
     "Column18": 7.4,
     "Column19": "701+",
     "Column20": 12,
     "Column21": 681,
     "Column22": 93.7,
     "Column23": 106,
     "Column24": 12.4,
     "Column25": "701+",
     "Column26": 11,
     "Column27": "644=",
     "Column28": 22.2
    },
    {
     "2025 QS World University Rankings": 562,
     "Column2": 525,
     "Column3": "Universiti Teknologi Brunei",
     "Column4": "BN",
     "Column5": "Brunei",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.4,
     "Column11": 570,
     "Column12": 6.3,
     "Column13": "601+",
     "Column14": 88.5,
     "Column15": 92,
     "Column16": 9.9,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 63,
     "Column20": 5.8,
     "Column21": "701+",
     "Column22": 15.9,
     "Column23": "701+",
     "Column24": 14.7,
     "Column25": 671,
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": 22.2
    },
    {
     "2025 QS World University Rankings": 564,
     "Column2": "701-710",
     "Column3": "Eötvös Loránd University",
     "Column4": "HU",
     "Column5": "Hungary",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.3,
     "Column11": 382,
     "Column12": 22.6,
     "Column13": 414,
     "Column14": 10.7,
     "Column15": "701+",
     "Column16": 6.1,
     "Column17": "701+",
     "Column18": 5.2,
     "Column19": "701+",
     "Column20": 11,
     "Column21": "701+",
     "Column22": 71.3,
     "Column23": 464,
     "Column24": 60.9,
     "Column25": 198,
     "Column26": 61.9,
     "Column27": "258=",
     "Column28": 22.1
    },
    {
     "2025 QS World University Rankings": "681-690",
     "Column2": "781-790",
     "Column3": "China University of Mining and Technology",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.5,
     "Column11": "601+",
     "Column12": 1.6,
     "Column13": "601+",
     "Column14": 14.1,
     "Column15": "701+",
     "Column16": 68.5,
     "Column17": 159,
     "Column22": 52.4,
     "Column23": "701+",
     "Column24": 10.1,
     "Column25": "701+",
     "Column26": 2.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": 565,
     "Column2": 444,
     "Column3": "University of Connecticut",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.4,
     "Column11": 600,
     "Column12": 8.1,
     "Column13": "601+",
     "Column14": 18.2,
     "Column15": "701+",
     "Column16": 29.8,
     "Column17": 438,
     "Column18": 27.4,
     "Column19": 514,
     "Column20": 19.4,
     "Column21": 556,
     "Column22": 88,
     "Column23": 198,
     "Column24": 23.3,
     "Column25": 487,
     "Column26": 37.6,
     "Column27": "388=",
     "Column28": 22
    },
    {
     "2025 QS World University Rankings": 567,
     "Column2": 571,
     "Column3": "Chiang Mai University",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 34.8,
     "Column11": 252,
     "Column12": 17.1,
     "Column13": 501,
     "Column14": 17.3,
     "Column15": "701+",
     "Column16": 4.2,
     "Column17": "701+",
     "Column18": 4.8,
     "Column19": "701+",
     "Column20": 4.2,
     "Column21": "701+",
     "Column22": 62.5,
     "Column23": 596,
     "Column24": 19.1,
     "Column25": 578,
     "Column26": 56.1,
     "Column27": "282=",
     "Column28": 21.9
    },
    {
     "2025 QS World University Rankings": 567,
     "Column2": 577,
     "Column3": "Lebanese University",
     "Column4": "LB",
     "Column5": "Lebanon",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 29,
     "Column11": 304,
     "Column12": 34.4,
     "Column13": 274,
     "Column14": 10.1,
     "Column15": "701+",
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 43.8,
     "Column19": 402,
     "Column20": 29.5,
     "Column21": 435,
     "Column22": 43.7,
     "Column23": "701+",
     "Column24": 29.9,
     "Column25": 418,
     "Column26": 5.8,
     "Column27": "701+",
     "Column28": 21.9
    },
    {
     "2025 QS World University Rankings": 567,
     "Column2": 526,
     "Column3": "MGIMO University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9.8,
     "Column11": "601+",
     "Column12": 25.4,
     "Column13": 372,
     "Column14": 100,
     "Column15": 18,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 11.3,
     "Column19": "701+",
     "Column20": 41.3,
     "Column21": 346,
     "Column22": 5.7,
     "Column23": "701+",
     "Column24": 60,
     "Column25": 204,
     "Column26": 1,
     "Column27": "701+",
     "Column28": 21.9
    },
    {
     "2025 QS World University Rankings": 570,
     "Column2": 556,
     "Column3": "University of Chemistry and Technology, Prague",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.6,
     "Column11": "601+",
     "Column12": 9.3,
     "Column13": "601+",
     "Column14": 98.4,
     "Column15": 44,
     "Column16": 13.5,
     "Column17": 661,
     "Column18": 23.9,
     "Column19": 547,
     "Column20": 78.4,
     "Column21": 179,
     "Column22": 34.9,
     "Column23": "701+",
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 3.4,
     "Column27": "701+",
     "Column28": 21.8
    },
    {
     "2025 QS World University Rankings": 570,
     "Column2": "601-610",
     "Column3": "University of Szeged",
     "Column4": "HU",
     "Column5": "Hungary",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 18.5,
     "Column11": 491,
     "Column12": 16.8,
     "Column13": 510,
     "Column14": 37,
     "Column15": 434,
     "Column16": 4.8,
     "Column17": "701+",
     "Column18": 11.8,
     "Column19": "701+",
     "Column20": 41.2,
     "Column21": 347,
     "Column22": 73.1,
     "Column23": 440,
     "Column24": 15,
     "Column25": 663,
     "Column26": 61.9,
     "Column27": "258=",
     "Column28": 21.8
    },
    {
     "2025 QS World University Rankings": 570,
     "Column2": "741-750",
     "Column3": "University of the Punjab",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.6,
     "Column11": 566,
     "Column12": 52.2,
     "Column13": 162,
     "Column14": 4.5,
     "Column15": "701+",
     "Column16": 11.6,
     "Column17": "701+",
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 62.2,
     "Column23": 603,
     "Column24": 81.4,
     "Column25": 118,
     "Column26": 4.3,
     "Column27": "701+",
     "Column28": 21.8
    },
    {
     "2025 QS World University Rankings": 573,
     "Column2": 461,
     "Column3": "Florida State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 25.6,
     "Column11": 346,
     "Column12": 18.1,
     "Column13": 485,
     "Column14": 7.2,
     "Column15": "701+",
     "Column16": 24.8,
     "Column17": 500,
     "Column18": 10,
     "Column19": "701+",
     "Column20": 4.2,
     "Column21": "701+",
     "Column22": 71.9,
     "Column23": 452,
     "Column24": 18.4,
     "Column25": 595,
     "Column26": 26.8,
     "Column27": "473=",
     "Column28": 21.6
    },
    {
     "2025 QS World University Rankings": 574,
     "Column2": 556,
     "Column3": "Florida International University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.3,
     "Column11": 573,
     "Column12": 22.5,
     "Column13": 417,
     "Column14": 11.8,
     "Column15": "701+",
     "Column16": 12.6,
     "Column17": 680,
     "Column18": 20.3,
     "Column19": 593,
     "Column20": 12.9,
     "Column21": 661,
     "Column22": 86.7,
     "Column23": 217,
     "Column24": 16.6,
     "Column25": 621,
     "Column26": 81.2,
     "Column27": "148=",
     "Column28": 21.5
    },
    {
     "2025 QS World University Rankings": 574,
     "Column2": "671-680",
     "Column3": "University of Debrecen",
     "Column4": "HU",
     "Column5": "Hungary",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 17.9,
     "Column11": 505,
     "Column12": 13.9,
     "Column13": 588,
     "Column14": 34,
     "Column15": 464,
     "Column16": 3.9,
     "Column17": "701+",
     "Column18": 10.6,
     "Column19": "701+",
     "Column20": 70,
     "Column21": 209,
     "Column22": 76.7,
     "Column23": 385,
     "Column24": 21.4,
     "Column25": 537,
     "Column26": 39.5,
     "Column27": "371=",
     "Column28": 21.5
    },
    {
     "2025 QS World University Rankings": 576,
     "Column2": 586,
     "Column3": "National Research Tomsk Polytechnic University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.9,
     "Column11": "601+",
     "Column12": 8.4,
     "Column13": "601+",
     "Column14": 95.1,
     "Column15": 61,
     "Column16": 3.7,
     "Column17": "701+",
     "Column18": 10.9,
     "Column19": "701+",
     "Column20": 82.8,
     "Column21": 163,
     "Column22": 62.7,
     "Column23": 592,
     "Column24": 2,
     "Column25": "701+",
     "Column26": 7.1,
     "Column27": "701+",
     "Column28": 21.4
    },
    {
     "2025 QS World University Rankings": 576,
     "Column2": 509,
     "Column3": "Sogang University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 17.5,
     "Column11": 516,
     "Column12": 45.5,
     "Column13": 202,
     "Column14": 56.7,
     "Column15": 260,
     "Column16": 5.1,
     "Column17": "701+",
     "Column18": 7.1,
     "Column19": "701+",
     "Column20": 19.6,
     "Column21": 552,
     "Column22": 13.8,
     "Column23": "701+",
     "Column24": 16,
     "Column25": 635,
     "Column26": 17.7,
     "Column27": "557=",
     "Column28": 21.4
    },
    {
     "2025 QS World University Rankings": 576,
     "Column2": "651-660",
     "Column3": "Southern Cross University ",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 2.9,
     "Column15": "701+",
     "Column16": 46.3,
     "Column17": 299,
     "Column18": 81.1,
     "Column19": 234,
     "Column20": 44.8,
     "Column21": 327,
     "Column22": 44.2,
     "Column23": "701+",
     "Column24": 4.8,
     "Column25": "701+",
     "Column26": 48.2,
     "Column27": 327,
     "Column28": 21.4
    },
    {
     "2025 QS World University Rankings": 576,
     "Column2": 567,
     "Column3": "Universidad Adolfo Ibáñez",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 20.6,
     "Column11": 428,
     "Column12": 57,
     "Column13": 141,
     "Column14": 4.7,
     "Column15": "701+",
     "Column16": 4.6,
     "Column17": "701+",
     "Column18": 36.9,
     "Column19": 433,
     "Column20": 3.9,
     "Column21": "701+",
     "Column22": 31.5,
     "Column23": "701+",
     "Column24": 53.3,
     "Column25": 237,
     "Column26": 2,
     "Column27": "701+",
     "Column28": 21.4
    },
    {
     "2025 QS World University Rankings": 580,
     "Column2": "601-610",
     "Column3": "Jawaharlal Nehru University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 31,
     "Column11": 287,
     "Column12": 14.4,
     "Column13": 571,
     "Column14": 10,
     "Column15": "701+",
     "Column16": 28.9,
     "Column17": 448,
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 47.4,
     "Column23": "701+",
     "Column24": 14.7,
     "Column25": 672,
     "Column26": 19.7,
     "Column27": "537=",
     "Column28": 21.3
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "781-790",
     "Column3": "Nanjing Agricultural University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 1.9,
     "Column13": "601+",
     "Column14": 7.5,
     "Column15": "701+",
     "Column16": 67.6,
     "Column17": 162,
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 48.2,
     "Column23": "701+",
     "Column24": 1.4,
     "Column25": "701+",
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": 580,
     "Column2": "621-630",
     "Column3": "Management and Science University",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 15.1,
     "Column11": 581,
     "Column12": 18.1,
     "Column13": 487,
     "Column14": 40.2,
     "Column15": 389,
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 97.3,
     "Column19": 126,
     "Column20": 99.4,
     "Column21": 51,
     "Column22": 12.1,
     "Column23": "701+",
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": 21.3
    },
    {
     "2025 QS World University Rankings": 580,
     "Column2": 556,
     "Column3": "Politecnico di Bari",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 4.8,
     "Column13": "601+",
     "Column14": 11.3,
     "Column15": "701+",
     "Column16": 76.5,
     "Column17": 129,
     "Column18": 4.6,
     "Column19": "701+",
     "Column20": 7.2,
     "Column21": "701+",
     "Column22": 46.6,
     "Column23": "701+",
     "Column24": 3.5,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": 21.3
    },
    {
     "2025 QS World University Rankings": 580,
     "Column2": 511,
     "Column3": "Université Paul Sabatier Toulouse III",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.7,
     "Column11": 510,
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 38.5,
     "Column15": 411,
     "Column16": 28,
     "Column17": 465,
     "Column18": 5.1,
     "Column19": "701+",
     "Column20": 21.5,
     "Column21": 521,
     "Column22": 98.5,
     "Column23": 18,
     "Column24": 6,
     "Column25": "701+",
     "Column26": 10.3,
     "Column27": "658=",
     "Column28": 21.3
    },
    {
     "2025 QS World University Rankings": 585,
     "Column2": "621-630",
     "Column3": "Institut Teknologi Sepuluh Nopember (ITS Surabaya)",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.3,
     "Column11": 519,
     "Column12": 48.9,
     "Column13": 184,
     "Column14": 47.8,
     "Column15": 325,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 47.5,
     "Column19": 376,
     "Column20": 5.3,
     "Column21": "701+",
     "Column22": 12,
     "Column23": "701+",
     "Column24": 13,
     "Column25": "701+",
     "Column26": 16.1,
     "Column27": "577=",
     "Column28": 21.2
    },
    {
     "2025 QS World University Rankings": 585,
     "Column2": 545,
     "Column3": "Singapore Management University",
     "Column4": "SG",
     "Column5": "Singapore",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.3,
     "Column11": "601+",
     "Column12": 14.2,
     "Column13": 578,
     "Column14": 10.2,
     "Column15": "701+",
     "Column16": 18.8,
     "Column17": 572,
     "Column18": 100,
     "Column19": 46,
     "Column20": 58.9,
     "Column21": 251,
     "Column22": 20.8,
     "Column23": "701+",
     "Column24": 27.1,
     "Column25": 443,
     "Column26": 26.3,
     "Column27": "478=",
     "Column28": 21.2
    },
    {
     "2025 QS World University Rankings": 587,
     "Column2": 567,
     "Column3": "Bond University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 4.3,
     "Column11": "601+",
     "Column12": 4.6,
     "Column13": "601+",
     "Column14": 47.8,
     "Column15": 326,
     "Column16": 28.8,
     "Column17": 450,
     "Column18": 70.4,
     "Column19": 283,
     "Column20": 86.6,
     "Column21": 144,
     "Column22": 26.5,
     "Column23": "701+",
     "Column24": 8.4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": 21.1
    },
    {
     "2025 QS World University Rankings": 587,
     "Column2": "771-780",
     "Column3": "Shoolini University of Biotechnology and Management Sciences",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 10,
     "Column11": "601+",
     "Column12": 14.9,
     "Column13": 561,
     "Column14": 8.1,
     "Column15": "701+",
     "Column16": 55.9,
     "Column17": 224,
     "Column18": 33.3,
     "Column19": 476,
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 44.8,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 16.3,
     "Column27": "570=",
     "Column28": 21.1
    },
    {
     "2025 QS World University Rankings": 587,
     "Column2": 514,
     "Column3": "The University of Georgia",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 20.6,
     "Column11": 427,
     "Column12": 18.6,
     "Column13": 473,
     "Column14": 9.8,
     "Column15": "701+",
     "Column16": 24.4,
     "Column17": 504,
     "Column18": 6.7,
     "Column19": "701+",
     "Column20": 4.5,
     "Column21": "701+",
     "Column22": 83.7,
     "Column23": 267,
     "Column24": 21.5,
     "Column25": 531,
     "Column26": 30.7,
     "Column27": "440=",
     "Column28": 21.1
    },
    {
     "2025 QS World University Rankings": 587,
     "Column2": 555,
     "Column3": "Universiti Teknologi MARA - UiTM",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 30.8,
     "Column11": 288,
     "Column12": 40.4,
     "Column13": 234,
     "Column14": 17.6,
     "Column15": "701+",
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 68.6,
     "Column23": 503,
     "Column24": 3.6,
     "Column25": "701+",
     "Column26": 15.2,
     "Column27": "590=",
     "Column28": 21.1
    },
    {
     "2025 QS World University Rankings": 587,
     "Column2": "621-630",
     "Column3": "University of Kwazulu-Natal",
     "Column4": "ZA",
     "Column5": "South Africa",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.6,
     "Column11": "601+",
     "Column12": 10.2,
     "Column13": "601+",
     "Column14": 5.5,
     "Column15": "701+",
     "Column16": 24.5,
     "Column17": 503,
     "Column18": 24.5,
     "Column19": 544,
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 93.7,
     "Column23": 107,
     "Column24": 71.2,
     "Column25": 154,
     "Column26": 37.9,
     "Column27": "386=",
     "Column28": 21.1
    },
    {
     "2025 QS World University Rankings": 592,
     "Column2": "721-730",
     "Column3": "Ain Shams University ",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 24,
     "Column11": 364,
     "Column12": 32.2,
     "Column13": 292,
     "Column14": 11.3,
     "Column15": "701+",
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 23.1,
     "Column19": 557,
     "Column20": 7.5,
     "Column21": "701+",
     "Column22": 74.2,
     "Column23": 418,
     "Column24": 56.3,
     "Column25": 219,
     "Column26": 9.8,
     "Column27": "672=",
     "Column28": 21
    },
    {
     "2025 QS World University Rankings": 592,
     "Column2": 595,
     "Column3": "Nottingham Trent University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 10.6,
     "Column11": "601+",
     "Column12": 15.1,
     "Column13": 557,
     "Column14": 10.6,
     "Column15": "701+",
     "Column16": 5.1,
     "Column17": "701+",
     "Column18": 57.1,
     "Column19": 333,
     "Column20": 74,
     "Column21": 193,
     "Column22": 84.6,
     "Column23": 254,
     "Column24": 12,
     "Column25": "701+",
     "Column26": 64.1,
     "Column27": "245=",
     "Column28": 21
    },
    {
     "2025 QS World University Rankings": 592,
     "Column2": 514,
     "Column3": "Universität Bremen",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.5,
     "Column11": 490,
     "Column12": 7.7,
     "Column13": "601+",
     "Column14": 13.1,
     "Column15": "701+",
     "Column16": 24.4,
     "Column17": 505,
     "Column18": 46.9,
     "Column19": 382,
     "Column20": 26.4,
     "Column21": 460,
     "Column22": 89.1,
     "Column23": 182,
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 19.9,
     "Column27": "535=",
     "Column28": 21
    },
    {
     "2025 QS World University Rankings": 595,
     "Column2": 542,
     "Column3": "ITMO University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.5,
     "Column11": "601+",
     "Column12": 13,
     "Column13": "601+",
     "Column14": 95.5,
     "Column15": 59,
     "Column16": 5.4,
     "Column17": "701+",
     "Column18": 20.3,
     "Column19": 592,
     "Column20": 36.2,
     "Column21": 382,
     "Column22": 65.3,
     "Column23": 550,
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": 20.9
    },
    {
     "2025 QS World University Rankings": 596,
     "Column2": 600,
     "Column3": "Thammasat University",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 24.6,
     "Column11": 355,
     "Column12": 19.4,
     "Column13": 465,
     "Column14": 26.6,
     "Column15": 564,
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 9.1,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 41,
     "Column23": "701+",
     "Column24": 84.6,
     "Column25": 103,
     "Column26": 33.6,
     "Column27": "416=",
     "Column28": 20.8
    },
    {
     "2025 QS World University Rankings": 596,
     "Column2": "661-670",
     "Column3": "Universitas Padjadjaran",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19,
     "Column11": 476,
     "Column12": 41.6,
     "Column13": 228,
     "Column14": 41.4,
     "Column15": 378,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 34.8,
     "Column19": 460,
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 19.6,
     "Column23": "701+",
     "Column24": 33.4,
     "Column25": 381,
     "Column26": 21.3,
     "Column27": "521=",
     "Column28": 20.8
    },
    {
     "2025 QS World University Rankings": 596,
     "Column2": 491,
     "Column3": "University of Iowa",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.4,
     "Column11": 493,
     "Column12": 8.7,
     "Column13": "601+",
     "Column14": 41.7,
     "Column15": 372,
     "Column16": 19.1,
     "Column17": 568,
     "Column18": 15.5,
     "Column19": 660,
     "Column20": 3.7,
     "Column21": "701+",
     "Column22": 75.6,
     "Column23": 396,
     "Column24": 26.6,
     "Column25": 446,
     "Column26": 20.8,
     "Column27": "527=",
     "Column28": 20.8
    },
    {
     "2025 QS World University Rankings": 596,
     "Column2": "621-630",
     "Column3": "University of Ljubljana",
     "Column4": "SI",
     "Column5": "Slovenia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.9,
     "Column11": 451,
     "Column12": 9.2,
     "Column13": "601+",
     "Column14": 33.3,
     "Column15": 474,
     "Column16": 5.9,
     "Column17": "701+",
     "Column18": 4.2,
     "Column19": "701+",
     "Column20": 9.7,
     "Column21": "701+",
     "Column22": 84.8,
     "Column23": 249,
     "Column24": 97.6,
     "Column25": 32,
     "Column26": 4.9,
     "Column27": "701+",
     "Column28": 20.8
    },
    {
     "2025 QS World University Rankings": 596,
     "Column2": 520,
     "Column3": "University of Texas Dallas",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.1,
     "Column11": "601+",
     "Column12": 15.9,
     "Column13": 537,
     "Column14": 3.4,
     "Column15": "701+",
     "Column16": 43.3,
     "Column17": 317,
     "Column18": 9.7,
     "Column19": "701+",
     "Column20": 47.4,
     "Column21": 309,
     "Column22": 60.4,
     "Column23": 630,
     "Column24": 4.9,
     "Column25": "701+",
     "Column26": 9.5,
     "Column27": "678=",
     "Column28": 20.8
    },
    {
     "2025 QS World University Rankings": "601-610",
     "Column2": "661-670",
     "Column3": "Ca' Foscari University of Venice ",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.6,
     "Column11": 456,
     "Column12": 15.8,
     "Column13": 539,
     "Column14": 5.7,
     "Column15": "701+",
     "Column16": 12.7,
     "Column17": 675,
     "Column18": 42,
     "Column19": 408,
     "Column20": 8.9,
     "Column21": "701+",
     "Column22": 64.6,
     "Column23": 564,
     "Column24": 12.3,
     "Column25": "701+",
     "Column26": 74.5,
     "Column27": "193=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "601-610",
     "Column2": 469,
     "Column3": "Illinois Institute of Technology",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 12.8,
     "Column11": "601+",
     "Column12": 11,
     "Column13": "601+",
     "Column14": 14.1,
     "Column15": "701+",
     "Column16": 35.9,
     "Column17": 379,
     "Column18": 19.2,
     "Column19": 610,
     "Column20": 97.3,
     "Column21": 77,
     "Column22": 18.9,
     "Column23": "701+",
     "Column24": 12.2,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "601-610",
     "Column2": "761-770",
     "Column3": "King Khalid University",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16.6,
     "Column11": 540,
     "Column12": 8.3,
     "Column13": "601+",
     "Column14": 49.8,
     "Column15": 313,
     "Column16": 2.7,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 27,
     "Column20": 18,
     "Column21": 579,
     "Column22": 78.3,
     "Column23": 353,
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 2.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "601-610",
     "Column2": "601-610",
     "Column3": "Kingston University, London",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 17.9,
     "Column11": 506,
     "Column12": 15.3,
     "Column13": 550,
     "Column14": 5.8,
     "Column15": "701+",
     "Column16": 7.6,
     "Column17": "701+",
     "Column18": 70.8,
     "Column19": 280,
     "Column20": 99.2,
     "Column21": 56,
     "Column22": 48.2,
     "Column23": "701+",
     "Column24": 12.6,
     "Column25": "701+",
     "Column26": 6.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "601-610",
     "Column2": 590,
     "Column3": "Manchester Metropolitan University (MMU)",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.8,
     "Column11": "601+",
     "Column12": 12.6,
     "Column13": "601+",
     "Column14": 8.1,
     "Column15": "701+",
     "Column16": 10.1,
     "Column17": "701+",
     "Column18": 42.9,
     "Column19": 405,
     "Column20": 37.7,
     "Column21": 373,
     "Column22": 91.7,
     "Column23": 143,
     "Column24": 12.3,
     "Column25": "701+",
     "Column26": 79.6,
     "Column27": "156=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "601-610",
     "Column2": 567,
     "Column3": "National Chengchi University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 20.1,
     "Column11": 443,
     "Column12": 37.8,
     "Column13": 249,
     "Column14": 17.1,
     "Column15": "701+",
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 26.5,
     "Column19": 521,
     "Column20": 21.8,
     "Column21": 517,
     "Column22": 13.3,
     "Column23": "701+",
     "Column24": 91.1,
     "Column25": 70,
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "601-610",
     "Column2": "681-690",
     "Column3": "Paris Lodron University of Salzburg",
     "Column4": "AT",
     "Column5": "Austria",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.7,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 20.8,
     "Column15": 659,
     "Column16": 6.8,
     "Column17": "701+",
     "Column18": 96.1,
     "Column19": 137,
     "Column20": 93.7,
     "Column21": 101,
     "Column22": 55.5,
     "Column23": 687,
     "Column24": 15.7,
     "Column25": 640,
     "Column26": 39.5,
     "Column27": "371=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "601-610",
     "Column2": 494,
     "Column3": "Rensselaer Polytechnic Institute",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 8,
     "Column11": "601+",
     "Column12": 8.4,
     "Column13": "601+",
     "Column14": 19.7,
     "Column15": 681,
     "Column16": 56.6,
     "Column17": 221,
     "Column18": 3.7,
     "Column19": "701+",
     "Column20": 29.9,
     "Column21": 430,
     "Column22": 25,
     "Column23": "701+",
     "Column24": 31.4,
     "Column25": 402,
     "Column26": 2.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "601-610",
     "Column2": 600,
     "Column3": "Universität des Saarlandes",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.2,
     "Column11": "601+",
     "Column12": 5,
     "Column13": "601+",
     "Column14": 92.2,
     "Column15": 77,
     "Column16": 7,
     "Column17": "701+",
     "Column18": 4.6,
     "Column19": "701+",
     "Column20": 35.9,
     "Column21": 385,
     "Column22": 71.4,
     "Column23": 462,
     "Column24": 29,
     "Column25": 428,
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "601-610",
     "Column2": 534,
     "Column3": "University of Crete",
     "Column4": "GR",
     "Column5": "Greece",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 13.9,
     "Column11": "601+",
     "Column12": 12.7,
     "Column13": "601+",
     "Column14": 2.4,
     "Column15": "701+",
     "Column16": 58.7,
     "Column17": 208,
     "Column18": 2.3,
     "Column19": "701+",
     "Column20": 3.5,
     "Column21": "701+",
     "Column22": 57,
     "Column23": 663,
     "Column24": 3.6,
     "Column25": "701+",
     "Column26": 1.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "601-610",
     "Column2": "651-660",
     "Column3": "University of Hohenheim",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.6,
     "Column11": "601+",
     "Column12": 6.1,
     "Column13": "601+",
     "Column14": 12,
     "Column15": "701+",
     "Column16": 47.5,
     "Column17": 285,
     "Column18": 25.2,
     "Column19": 530,
     "Column20": 25.9,
     "Column21": 466,
     "Column22": 76.9,
     "Column23": 381,
     "Column24": 5,
     "Column25": "701+",
     "Column26": 19.2,
     "Column27": "545=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "601-610",
     "Column2": 575,
     "Column3": "University of South Carolina",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.8,
     "Column11": "601+",
     "Column12": 9.4,
     "Column13": "601+",
     "Column14": 9.7,
     "Column15": "701+",
     "Column16": 28.3,
     "Column17": 461,
     "Column18": 30.4,
     "Column19": 491,
     "Column20": 3.2,
     "Column21": "701+",
     "Column22": 71.9,
     "Column23": 451,
     "Column24": 17.3,
     "Column25": 611,
     "Column26": 63.1,
     "Column27": "250=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "611-620",
     "Column2": "671-680",
     "Column3": "American University of the Middle East",
     "Column4": "KW",
     "Column5": "Kuwait",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 23.1,
     "Column11": 388,
     "Column12": 21.6,
     "Column13": 428,
     "Column14": 19.7,
     "Column15": 682,
     "Column16": 3.7,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 7,
     "Column20": 23,
     "Column21": 501,
     "Column22": 35.1,
     "Column23": "701+",
     "Column24": 5.1,
     "Column25": "701+",
     "Column26": 3.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "611-620",
     "Column2": "621-630",
     "Column3": "Asia Pacific University of Technology and Innovation (APU)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 9.3,
     "Column11": "601+",
     "Column12": 12.5,
     "Column13": "601+",
     "Column14": 62.2,
     "Column15": 228,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 92,
     "Column19": 177,
     "Column20": 100,
     "Column21": 16,
     "Column22": 6.8,
     "Column23": "701+",
     "Column24": 4,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "611-620",
     "Column2": "611-620",
     "Column3": "Brno University of Technology",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13,
     "Column11": "601+",
     "Column12": 29.3,
     "Column13": 322,
     "Column14": 25.7,
     "Column15": 579,
     "Column16": 7.3,
     "Column17": "701+",
     "Column18": 14.8,
     "Column19": 668,
     "Column20": 67.3,
     "Column21": 221,
     "Column22": 78.8,
     "Column23": 342,
     "Column24": 12.7,
     "Column25": "701+",
     "Column26": 3.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "611-620",
     "Column2": 530,
     "Column3": "Christian-Albrechts-University zu Kiel",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.7,
     "Column11": 511,
     "Column12": 10.8,
     "Column13": "601+",
     "Column14": 44.7,
     "Column15": 346,
     "Column16": 15.6,
     "Column17": 625,
     "Column20": 8.4,
     "Column21": "701+",
     "Column22": 90,
     "Column23": 167,
     "Column24": 14.3,
     "Column25": 682,
     "Column26": 23.5,
     "Column27": "503=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "611-620",
     "Column3": "Eastern Mediterranean University",
     "Column4": "NCY",
     "Column5": "Northern Cyprus",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 5.6,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 3.5,
     "Column15": "701+",
     "Column16": 26.5,
     "Column17": 480,
     "Column18": 90.1,
     "Column19": 190,
     "Column20": 100,
     "Column21": 9,
     "Column22": 55.2,
     "Column23": 693,
     "Column24": 24.1,
     "Column25": 469,
     "Column26": 1.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "611-620",
     "Column2": 595,
     "Column3": "Pontifícia Universidade Católica do Rio de Janeiro",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 32.4,
     "Column11": 271,
     "Column12": 16.2,
     "Column13": 525,
     "Column14": 10.8,
     "Column15": "701+",
     "Column16": 7.5,
     "Column17": "701+",
     "Column18": 12.6,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 44.5,
     "Column23": "701+",
     "Column24": 68,
     "Column25": 172,
     "Column26": 5.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "611-620",
     "Column2": 577,
     "Column3": "Taipei Medical University (TMU)",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 10.6,
     "Column11": "601+",
     "Column12": 12.5,
     "Column13": "601+",
     "Column14": 90.4,
     "Column15": 84,
     "Column16": 18.9,
     "Column17": 571,
     "Column18": 16,
     "Column19": 650,
     "Column20": 13.7,
     "Column21": 648,
     "Column22": 22.9,
     "Column23": "701+",
     "Column24": 8.1,
     "Column25": "701+",
     "Column26": 8.3,
     "Column27": "700=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "611-620",
     "Column2": 580,
     "Column3": "University of Klagenfurt",
     "Column4": "AT",
     "Column5": "Austria",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.7,
     "Column11": "601+",
     "Column12": 3.7,
     "Column13": "601+",
     "Column14": 51.3,
     "Column15": 298,
     "Column16": 11.3,
     "Column17": "701+",
     "Column18": 96,
     "Column19": 138,
     "Column20": 87.8,
     "Column21": 134,
     "Column22": 35.6,
     "Column23": "701+",
     "Column24": 9.2,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "611-620",
     "Column2": "611-620",
     "Column3": "University of Minho",
     "Column4": "PT",
     "Column5": "Portugal",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.6,
     "Column11": 565,
     "Column12": 7.1,
     "Column13": "601+",
     "Column14": 26.5,
     "Column15": 566,
     "Column16": 21,
     "Column17": 543,
     "Column18": 15.9,
     "Column19": 652,
     "Column20": 14.9,
     "Column21": 624,
     "Column22": 83.7,
     "Column23": 268,
     "Column24": 8.6,
     "Column25": "701+",
     "Column26": 52.1,
     "Column27": "305=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "621-630",
     "Column2": "601-610",
     "Column3": "Abo Akademi University",
     "Column4": "FI",
     "Column5": "Finland",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.5,
     "Column11": "601+",
     "Column12": 4.8,
     "Column13": "601+",
     "Column14": 18.9,
     "Column15": "701+",
     "Column16": 43.2,
     "Column17": 319,
     "Column18": 45.9,
     "Column19": 390,
     "Column20": 22.6,
     "Column21": 510,
     "Column22": 64.5,
     "Column23": 567,
     "Column24": 8.3,
     "Column25": "701+",
     "Column26": 6.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "621-630",
     "Column2": "601-610",
     "Column3": "American University in Dubai",
     "Column4": "AE",
     "Column5": "United Arab Emirates",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 10.3,
     "Column11": "601+",
     "Column12": 16.9,
     "Column13": 506,
     "Column14": 25.2,
     "Column15": 590,
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 81,
     "Column20": 100,
     "Column21": 2,
     "Column22": 4.5,
     "Column23": "701+",
     "Column24": 41.3,
     "Column25": 310,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "621-630",
     "Column2": "611-620",
     "Column3": "Auezov South Kazakhstan University (SKU)",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 18.5,
     "Column11": 488,
     "Column12": 28.7,
     "Column13": 333,
     "Column14": 89.5,
     "Column15": 86,
     "Column16": 1,
     "Column17": "701+",
     "Column18": 12.9,
     "Column19": "701+",
     "Column20": 16.2,
     "Column21": 601,
     "Column22": 4,
     "Column23": "701+",
     "Column24": 3.2,
     "Column25": "701+",
     "Column26": 2.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "621-630",
     "Column2": "601-610",
     "Column3": "Charles Darwin University ",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.7,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 4.5,
     "Column15": "701+",
     "Column16": 44.2,
     "Column17": 311,
     "Column18": 25.4,
     "Column19": 527,
     "Column20": 55.2,
     "Column21": 270,
     "Column22": 41.3,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 63.7,
     "Column27": "247=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "621-630",
     "Column2": "711-720",
     "Column3": "Istanbul University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 28.1,
     "Column11": 314,
     "Column12": 25.3,
     "Column13": 377,
     "Column14": 2.8,
     "Column15": "701+",
     "Column16": 3.5,
     "Column17": "701+",
     "Column18": 5.3,
     "Column19": "701+",
     "Column20": 9.9,
     "Column21": "701+",
     "Column22": 68.8,
     "Column23": 500,
     "Column24": 72.8,
     "Column25": 146,
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "701-710",
     "Column2": "721-730",
     "Column3": "Beijing University of Chemical Technology",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 19,
     "Column15": "701+",
     "Column16": 67.1,
     "Column17": 164,
     "Column18": 2.5,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 37.2,
     "Column23": "701+",
     "Column24": 2.4,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "621-630",
     "Column2": "651-660",
     "Column3": "Tallinn University of Technology (TalTech)",
     "Column4": "EE",
     "Column5": "Estonia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.8,
     "Column11": "601+",
     "Column12": 10.2,
     "Column13": "601+",
     "Column14": 31.5,
     "Column15": 502,
     "Column16": 10.8,
     "Column17": "701+",
     "Column18": 60,
     "Column19": 325,
     "Column20": 18.3,
     "Column21": 577,
     "Column22": 83.3,
     "Column23": 275,
     "Column24": 45.6,
     "Column25": 279,
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "621-630",
     "Column2": 538,
     "Column3": "Universidad de Alcalá",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9.7,
     "Column11": "601+",
     "Column12": 5.8,
     "Column13": "601+",
     "Column14": 60,
     "Column15": 243,
     "Column16": 5.6,
     "Column17": "701+",
     "Column18": 10.6,
     "Column19": "701+",
     "Column20": 44.9,
     "Column21": 326,
     "Column22": 77.1,
     "Column23": 376,
     "Column24": 7.7,
     "Column25": "701+",
     "Column26": 59,
     "Column27": "271=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "621-630",
     "Column2": 530,
     "Column3": "Universidad de Palermo (UP)",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 10.2,
     "Column11": "601+",
     "Column12": 9.8,
     "Column13": "601+",
     "Column14": 92.8,
     "Column15": 74,
     "Column16": 1,
     "Column17": "701+",
     "Column18": 35.6,
     "Column19": 449,
     "Column20": 91.9,
     "Column21": 111,
     "Column22": 2.6,
     "Column23": "701+",
     "Column24": 7.8,
     "Column25": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "621-630",
     "Column2": 563,
     "Column3": "University of South Florida",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.9,
     "Column11": "601+",
     "Column12": 9.5,
     "Column13": "601+",
     "Column14": 8.8,
     "Column15": "701+",
     "Column16": 36.3,
     "Column17": 377,
     "Column18": 19.9,
     "Column19": 599,
     "Column20": 22.2,
     "Column21": 516,
     "Column22": 86.7,
     "Column23": 219,
     "Column24": 6.1,
     "Column25": "701+",
     "Column26": 15.9,
     "Column27": "581=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "621-630",
     "Column2": 577,
     "Column3": "University of Tromsø The Arctic University of Norway",
     "Column4": "NO",
     "Column5": "Norway",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11,
     "Column11": "601+",
     "Column12": 4.3,
     "Column13": "601+",
     "Column14": 68.3,
     "Column15": 190,
     "Column16": 8.9,
     "Column17": "701+",
     "Column18": 69.3,
     "Column19": 286,
     "Column20": 7,
     "Column21": "701+",
     "Column22": 78.4,
     "Column23": 349,
     "Column24": 5.6,
     "Column25": "701+",
     "Column26": 10.5,
     "Column27": "655=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "621-630",
     "Column2": "701-710",
     "Column3": "Zayed University",
     "Column4": "AE",
     "Column5": "United Arab Emirates",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.6,
     "Column11": 515,
     "Column12": 18.4,
     "Column13": 479,
     "Column14": 24.3,
     "Column15": 604,
     "Column16": 8.6,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 15,
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 50.8,
     "Column23": "701+",
     "Column24": 20.9,
     "Column25": 550,
     "Column26": 2.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "631-640",
     "Column2": "631-640",
     "Column3": "Ajou University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 13.7,
     "Column11": "601+",
     "Column12": 15,
     "Column13": 558,
     "Column14": 68.9,
     "Column15": 187,
     "Column16": 17.9,
     "Column17": 584,
     "Column18": 9.3,
     "Column19": "701+",
     "Column20": 24.2,
     "Column21": 488,
     "Column22": 18.8,
     "Column23": "701+",
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 23.7,
     "Column27": "500=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "631-640",
     "Column2": "631-640",
     "Column3": "Boston College",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 21.3,
     "Column11": 414,
     "Column12": 16.2,
     "Column13": 523,
     "Column14": 21.2,
     "Column15": 652,
     "Column16": 16.6,
     "Column17": 613,
     "Column18": 12.5,
     "Column19": "701+",
     "Column20": 18.8,
     "Column21": 571,
     "Column22": 50.8,
     "Column23": "701+",
     "Column24": 42.2,
     "Column25": 307,
     "Column26": 7.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "631-640",
     "Column2": "621-630",
     "Column3": "Chang Gung University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 4.3,
     "Column13": "601+",
     "Column14": 71.6,
     "Column15": 184,
     "Column16": 41.9,
     "Column17": 328,
     "Column18": 5.1,
     "Column19": "701+",
     "Column20": 4.5,
     "Column21": "701+",
     "Column22": 27.7,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 13.2,
     "Column27": "606=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": 508,
     "Column2": 595,
     "Column3": "Shenzhen University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.7,
     "Column11": "601+",
     "Column12": 3.2,
     "Column13": "601+",
     "Column14": 42.1,
     "Column15": 369,
     "Column16": 64.5,
     "Column17": 175,
     "Column18": 11,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 65.1,
     "Column23": 555,
     "Column24": 15.8,
     "Column25": 637,
     "Column26": 7.4,
     "Column27": "701+",
     "Column28": 23.9
    },
    {
     "2025 QS World University Rankings": "631-640",
     "Column2": "641-650",
     "Column3": "Far Eastern Federal University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 12.8,
     "Column11": "601+",
     "Column12": 5.5,
     "Column13": "601+",
     "Column14": 96.7,
     "Column15": 50,
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 10.8,
     "Column19": "701+",
     "Column20": 63.1,
     "Column21": 239,
     "Column22": 41.7,
     "Column23": "701+",
     "Column24": 5.1,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "631-640",
     "Column2": "691-700",
     "Column3": "Inha University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 12.2,
     "Column11": "601+",
     "Column12": 14.3,
     "Column13": 573,
     "Column14": 77.2,
     "Column15": 152,
     "Column16": 10.9,
     "Column17": "701+",
     "Column18": 9.7,
     "Column19": "701+",
     "Column20": 13.7,
     "Column21": 647,
     "Column22": 23.9,
     "Column23": "701+",
     "Column24": 13.1,
     "Column25": "701+",
     "Column26": 41.4,
     "Column27": "358=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "631-640",
     "Column2": "711-720",
     "Column3": "Savitribai Phule Pune University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.9,
     "Column11": "601+",
     "Column12": 18.3,
     "Column13": 483,
     "Column14": 48.8,
     "Column15": 320,
     "Column16": 35.3,
     "Column17": 381,
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 44.2,
     "Column23": "701+",
     "Column24": 14.4,
     "Column25": 677,
     "Column26": 1.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "631-640",
     "Column2": 600,
     "Column3": "Tulane University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 9.8,
     "Column11": "601+",
     "Column12": 6,
     "Column13": "601+",
     "Column14": 84.7,
     "Column15": 106,
     "Column16": 9.7,
     "Column17": "701+",
     "Column18": 9.9,
     "Column19": "701+",
     "Column20": 10.8,
     "Column21": "701+",
     "Column22": 75.4,
     "Column23": 403,
     "Column24": 26,
     "Column25": 454,
     "Column26": 8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "631-640",
     "Column2": "601-610",
     "Column3": "Universidad de Concepción",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 26.2,
     "Column11": 341,
     "Column12": 22.4,
     "Column13": 418,
     "Column14": 14.8,
     "Column15": "701+",
     "Column16": 6.7,
     "Column17": "701+",
     "Column18": 10.6,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 61.1,
     "Column23": 617,
     "Column24": 12.5,
     "Column25": "701+",
     "Column26": 45.9,
     "Column27": "336=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "631-640",
     "Column2": 502,
     "Column3": "University of Portsmouth",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.1,
     "Column11": "601+",
     "Column12": 7.3,
     "Column13": "601+",
     "Column14": 8.6,
     "Column15": "701+",
     "Column16": 15.1,
     "Column17": 634,
     "Column18": 74,
     "Column19": 270,
     "Column20": 85.3,
     "Column21": 151,
     "Column22": 75.4,
     "Column23": 399,
     "Column24": 8.9,
     "Column25": "701+",
     "Column26": 21.1,
     "Column27": "525=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column2": "711-720",
     "Column3": "Beirut Arab University ",
     "Column4": "LB",
     "Column5": "Lebanon",
     "Column6": "S",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 17.7,
     "Column11": 509,
     "Column12": 14.8,
     "Column13": 563,
     "Column14": 19.1,
     "Column15": 698,
     "Column16": 3.3,
     "Column17": "701+",
     "Column18": 88.5,
     "Column19": 200,
     "Column20": 48.2,
     "Column21": 301,
     "Column22": 28,
     "Column23": "701+",
     "Column24": 38.2,
     "Column25": 336,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column2": "681-690",
     "Column3": "De La Salle University",
     "Column4": "PH",
     "Column5": "Philippines",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 22.6,
     "Column11": 398,
     "Column12": 52.4,
     "Column13": 161,
     "Column14": 10,
     "Column15": "701+",
     "Column16": 4.5,
     "Column17": "701+",
     "Column18": 1.9,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 22.2,
     "Column23": "701+",
     "Column24": 47.4,
     "Column25": 272,
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": 547,
     "Column2": "621-630",
     "Column3": "Northwestern Polytechnical University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 3.2,
     "Column13": "601+",
     "Column14": 44.8,
     "Column15": 345,
     "Column16": 63.8,
     "Column17": 178,
     "Column18": 16.7,
     "Column19": 643,
     "Column20": 5.2,
     "Column21": "701+",
     "Column22": 53.4,
     "Column23": "701+",
     "Column24": 6.6,
     "Column25": "701+",
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": 22.6
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column2": "761-770",
     "Column3": "Hacettepe University ",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 22.5,
     "Column11": 399,
     "Column12": 29.4,
     "Column13": 318,
     "Column14": 10.8,
     "Column15": "701+",
     "Column16": 7.1,
     "Column17": "701+",
     "Column18": 4,
     "Column19": "701+",
     "Column20": 3.5,
     "Column21": "701+",
     "Column22": 72.1,
     "Column23": 449,
     "Column24": 22.2,
     "Column25": 516,
     "Column26": 34.8,
     "Column27": "406=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column2": 548,
     "Column3": "Lehigh University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 4.8,
     "Column13": "601+",
     "Column14": 24.3,
     "Column15": 603,
     "Column16": 32.5,
     "Column17": 404,
     "Column18": 47.1,
     "Column19": 380,
     "Column20": 27.8,
     "Column21": 447,
     "Column22": 44.5,
     "Column23": "701+",
     "Column24": 29,
     "Column25": 429,
     "Column26": 33.3,
     "Column27": "418=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column2": "611-620",
     "Column3": "National Central University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 21.5,
     "Column11": 409,
     "Column12": 28.2,
     "Column13": 338,
     "Column14": 22.9,
     "Column15": 624,
     "Column16": 14.4,
     "Column17": 647,
     "Column18": 11.6,
     "Column19": "701+",
     "Column20": 10,
     "Column21": "701+",
     "Column22": 30.4,
     "Column23": "701+",
     "Column24": 10.2,
     "Column25": "701+",
     "Column26": 27.9,
     "Column27": "462=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column2": 505,
     "Column3": "Oregon State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.6,
     "Column11": 596,
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 15.6,
     "Column15": "701+",
     "Column16": 33.9,
     "Column17": 390,
     "Column18": 11.7,
     "Column19": "701+",
     "Column20": 8.1,
     "Column21": "701+",
     "Column22": 81.2,
     "Column23": 306,
     "Column24": 19.3,
     "Column25": 574,
     "Column26": 21.1,
     "Column27": "525=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column2": "631-640",
     "Column3": "Ritsumeikan University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 16.6,
     "Column11": 539,
     "Column12": 55.7,
     "Column13": 148,
     "Column14": 7.8,
     "Column15": "701+",
     "Column16": 3.1,
     "Column17": "701+",
     "Column18": 28.4,
     "Column19": 505,
     "Column20": 9.6,
     "Column21": "701+",
     "Column22": 30.9,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 39.5,
     "Column27": "371=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": 501,
     "Column2": 511,
     "Column3": "East China Normal University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.3,
     "Column11": "601+",
     "Column12": 4.7,
     "Column13": "601+",
     "Column14": 16.2,
     "Column15": "701+",
     "Column16": 63.5,
     "Column17": 180,
     "Column18": 24.1,
     "Column19": 546,
     "Column20": 9,
     "Column21": "701+",
     "Column22": 72,
     "Column23": 450,
     "Column24": 9,
     "Column25": "701+",
     "Column26": 6.8,
     "Column27": "701+",
     "Column28": 24.1
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column3": "Symbiosis International (Deemed University)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 95.6,
     "Column13": 31,
     "Column14": 19.7,
     "Column15": 680,
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 16.1,
     "Column19": 648,
     "Column20": 3.6,
     "Column21": "701+",
     "Column22": 16.7,
     "Column23": "701+",
     "Column24": 5.2,
     "Column25": "701+",
     "Column26": 2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column2": "761-770",
     "Column3": "Universiti Tenaga Nasional (UNITEN)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 8,
     "Column11": "601+",
     "Column12": 11.6,
     "Column13": "601+",
     "Column14": 62.2,
     "Column15": 227,
     "Column16": 17.3,
     "Column17": 593,
     "Column18": 18,
     "Column19": 626,
     "Column20": 65,
     "Column21": 229,
     "Column22": 32.9,
     "Column23": "701+",
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 9.1,
     "Column27": "686=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column2": 586,
     "Column3": "University of Missouri, Columbia",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.3,
     "Column11": "601+",
     "Column12": 8,
     "Column13": "601+",
     "Column14": 37.8,
     "Column15": 423,
     "Column16": 16.5,
     "Column17": 615,
     "Column18": 46.7,
     "Column19": 383,
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 66.5,
     "Column23": 529,
     "Column24": 30.5,
     "Column25": 413,
     "Column26": 12.3,
     "Column27": "618=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column2": "621-630",
     "Column3": "University of the Basque Country",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.5,
     "Column11": 461,
     "Column12": 7.3,
     "Column13": "601+",
     "Column14": 32.8,
     "Column15": 480,
     "Column16": 19,
     "Column17": 569,
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 3.5,
     "Column21": "701+",
     "Column22": 90.8,
     "Column23": 159,
     "Column24": 22.8,
     "Column25": 500,
     "Column26": 7.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "651-660",
     "Column2": 575,
     "Column3": "HUFS - Hankuk (Korea) University of Foreign Studies ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 11.5,
     "Column11": "601+",
     "Column12": 21.3,
     "Column13": 433,
     "Column14": 81.2,
     "Column15": 123,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 32,
     "Column19": 484,
     "Column20": 47.9,
     "Column21": 302,
     "Column22": 12.6,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 8.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "651-660",
     "Column2": "631-640",
     "Column3": "Palacký University Olomouc",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.2,
     "Column11": 579,
     "Column12": 13.9,
     "Column13": 586,
     "Column14": 29.9,
     "Column15": 523,
     "Column16": 8.5,
     "Column17": "701+",
     "Column18": 32.7,
     "Column19": 481,
     "Column20": 17.8,
     "Column21": 580,
     "Column22": 87.3,
     "Column23": 210,
     "Column24": 6.8,
     "Column25": "701+",
     "Column26": 33,
     "Column27": "420=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "651-660",
     "Column2": "701-710",
     "Column3": "Universidade de Santiago de Compostela",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 24.1,
     "Column11": 362,
     "Column12": 10.8,
     "Column13": "601+",
     "Column14": 12.4,
     "Column15": "701+",
     "Column16": 17,
     "Column17": 601,
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 5.5,
     "Column21": "701+",
     "Column22": 78,
     "Column23": 359,
     "Column24": 11.3,
     "Column25": "701+",
     "Column26": 36.6,
     "Column27": "393=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "651-660",
     "Column2": "641-650",
     "Column3": "University of Houston",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.7,
     "Column11": "601+",
     "Column12": 11.4,
     "Column13": "601+",
     "Column14": 5,
     "Column15": "701+",
     "Column16": 24.6,
     "Column17": 502,
     "Column18": 23.9,
     "Column19": 550,
     "Column20": 19.8,
     "Column21": 545,
     "Column22": 71.6,
     "Column23": 456,
     "Column24": 50.3,
     "Column25": 254,
     "Column26": 19.7,
     "Column27": "537=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "651-660",
     "Column2": "751-760",
     "Column3": "University of Zagreb",
     "Column4": "HR",
     "Column5": "Croatia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16.3,
     "Column11": 547,
     "Column12": 7.3,
     "Column13": "601+",
     "Column14": 23.8,
     "Column15": 612,
     "Column16": 4.1,
     "Column17": "701+",
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 91.6,
     "Column23": 148,
     "Column24": 96.9,
     "Column25": 36,
     "Column26": 27.9,
     "Column27": "462=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": "661-670",
     "Column3": "Aberystwyth University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.4,
     "Column11": "601+",
     "Column12": 5.9,
     "Column13": "601+",
     "Column14": 18.5,
     "Column15": "701+",
     "Column16": 20.3,
     "Column17": 553,
     "Column18": 44.6,
     "Column19": 397,
     "Column20": 46.5,
     "Column21": 316,
     "Column22": 65.9,
     "Column23": 541,
     "Column24": 7.1,
     "Column25": "701+",
     "Column26": 44.9,
     "Column27": "338=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": 545,
     "Column3": "City University of New York",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.9,
     "Column11": 452,
     "Column12": 35.6,
     "Column13": 266,
     "Column14": 8.9,
     "Column15": "701+",
     "Column16": 4.7,
     "Column17": "701+",
     "Column18": 6.1,
     "Column19": "701+",
     "Column20": 4.4,
     "Column21": "701+",
     "Column22": 93.1,
     "Column23": 117,
     "Column24": 22,
     "Column25": 522,
     "Column26": 13.2,
     "Column27": "606=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": "771-780",
     "Column3": "Comenius University Bratislava",
     "Column4": "SK",
     "Column5": "Slovakia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 12.8,
     "Column11": "601+",
     "Column12": 8.5,
     "Column13": "601+",
     "Column14": 56,
     "Column15": 265,
     "Column16": 3.5,
     "Column17": "701+",
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 22.9,
     "Column21": 505,
     "Column22": 60.2,
     "Column23": 631,
     "Column24": 79.8,
     "Column25": 123,
     "Column26": 5.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": "641-650",
     "Column3": "Free University of Bozen-Bolzano",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 1.5,
     "Column13": "601+",
     "Column14": 12.8,
     "Column15": "701+",
     "Column16": 42.6,
     "Column17": 323,
     "Column18": 72.3,
     "Column19": 276,
     "Column20": 37.4,
     "Column21": 376,
     "Column22": 49.3,
     "Column23": "701+",
     "Column24": 3.4,
     "Column25": "701+",
     "Column26": 2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": "711-720",
     "Column3": "International Islamic University Malaysia (IIUM)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 24.4,
     "Column11": 358,
     "Column12": 17,
     "Column13": 504,
     "Column14": 13.4,
     "Column15": "701+",
     "Column16": 3.1,
     "Column17": "701+",
     "Column18": 22.8,
     "Column19": 561,
     "Column20": 57.1,
     "Column21": 258,
     "Column22": 62.5,
     "Column23": 598,
     "Column24": 23,
     "Column25": 497,
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": "751-760",
     "Column3": "ISCTE - Instituto Universitario de Lisboa",
     "Column4": "PT",
     "Column5": "Portugal",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 5.9,
     "Column13": "601+",
     "Column14": 2,
     "Column15": "701+",
     "Column16": 47.8,
     "Column17": 281,
     "Column18": 18.7,
     "Column19": 618,
     "Column20": 49.6,
     "Column21": 297,
     "Column22": 49.7,
     "Column23": "701+",
     "Column24": 27.2,
     "Column25": 441,
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": "641-650",
     "Column3": "Memorial University of Newfoundland",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.2,
     "Column11": "601+",
     "Column12": 5.9,
     "Column13": "601+",
     "Column14": 10.1,
     "Column15": "701+",
     "Column16": 28.6,
     "Column17": 454,
     "Column18": 23.9,
     "Column19": 548,
     "Column20": 78.4,
     "Column21": 178,
     "Column22": 74.1,
     "Column23": 420,
     "Column24": 12.1,
     "Column25": "701+",
     "Column26": 16.9,
     "Column27": "563=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": "661-670",
     "Column3": "National Chung Hsing University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 21.1,
     "Column11": 416,
     "Column12": 23,
     "Column13": 408,
     "Column14": 25.3,
     "Column15": 586,
     "Column16": 11.5,
     "Column17": "701+",
     "Column18": 8.8,
     "Column19": "701+",
     "Column20": 8.4,
     "Column21": "701+",
     "Column22": 32.2,
     "Column23": "701+",
     "Column24": 28.4,
     "Column25": 432,
     "Column26": 32.1,
     "Column27": "428=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": "741-750",
     "Column3": "Sofia University \"St. Kliment Ohridski\"",
     "Column4": "BG",
     "Column5": "Bulgaria",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 14.2,
     "Column11": "601+",
     "Column12": 18,
     "Column13": 488,
     "Column14": 45.7,
     "Column15": 339,
     "Column16": 2,
     "Column17": "701+",
     "Column18": 3,
     "Column19": "701+",
     "Column20": 8.5,
     "Column21": "701+",
     "Column22": 61,
     "Column23": 618,
     "Column24": 81.8,
     "Column25": 112,
     "Column26": 8.9,
     "Column27": 691,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": "611-620",
     "Column3": "Tokyo Medical and Dental University (TMDU)",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.8,
     "Column11": "601+",
     "Column12": 6.5,
     "Column13": "601+",
     "Column14": 100,
     "Column15": 9,
     "Column16": 17.7,
     "Column17": 586,
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 13.4,
     "Column21": 653,
     "Column22": 21.9,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": "801-850",
     "Column3": "Universidad de la República (Udelar)",
     "Column4": "UY",
     "Column5": "Uruguay",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 27.1,
     "Column11": 322,
     "Column12": 13.7,
     "Column13": 591,
     "Column14": 14.2,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 7.9,
     "Column19": "701+",
     "Column20": 2.3,
     "Column21": "701+",
     "Column22": 62.4,
     "Column23": 599,
     "Column24": 87.7,
     "Column25": 89,
     "Column26": 2.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": "671-680",
     "Column3": "University of Manitoba",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.6,
     "Column11": "601+",
     "Column12": 15.9,
     "Column13": 536,
     "Column14": 29.6,
     "Column15": 526,
     "Column16": 12,
     "Column17": 694,
     "Column20": 43.2,
     "Column21": 334,
     "Column22": 73.1,
     "Column23": 439,
     "Column24": 32.8,
     "Column25": 390,
     "Column26": 16.3,
     "Column27": "570=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "661-670",
     "Column2": 561,
     "Column3": "University of Plymouth",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.9,
     "Column11": "601+",
     "Column12": 8.9,
     "Column13": "601+",
     "Column14": 11.6,
     "Column15": "701+",
     "Column16": 25.9,
     "Column17": 487,
     "Column18": 49.8,
     "Column19": 368,
     "Column20": 36.2,
     "Column21": 381,
     "Column22": 80.1,
     "Column23": 320,
     "Column24": 7.3,
     "Column25": "701+",
     "Column26": 21.5,
     "Column27": "518=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "671-680",
     "Column2": "681-690",
     "Column3": "Abai Kazakh National Pedagogical University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 26.5,
     "Column11": 333,
     "Column12": 22.8,
     "Column13": 413,
     "Column14": 53.8,
     "Column15": 283,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 37.8,
     "Column19": 427,
     "Column20": 3.7,
     "Column21": "701+",
     "Column22": 4.1,
     "Column23": "701+",
     "Column24": 12.6,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "671-680",
     "Column2": 545,
     "Column3": "Bar-Ilan University",
     "Column4": "IL",
     "Column5": "Israel",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.8,
     "Column11": "601+",
     "Column12": 6.1,
     "Column13": "601+",
     "Column14": 7.5,
     "Column15": "701+",
     "Column16": 48.1,
     "Column17": 279,
     "Column18": 46.4,
     "Column19": 386,
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 50.1,
     "Column23": "701+",
     "Column24": 22.9,
     "Column25": 498,
     "Column26": 4.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "671-680",
     "Column2": "661-670",
     "Column3": "Karl-Franzens-Universitaet Graz",
     "Column4": "AT",
     "Column5": "Austria",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.9,
     "Column11": 448,
     "Column12": 10.9,
     "Column13": "601+",
     "Column14": 11.7,
     "Column15": "701+",
     "Column16": 10.7,
     "Column17": "701+",
     "Column18": 45.4,
     "Column19": 394,
     "Column20": 26.1,
     "Column21": 465,
     "Column22": 63.9,
     "Column23": 576,
     "Column24": 31.9,
     "Column25": 397,
     "Column26": 12.3,
     "Column27": "618=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "671-680",
     "Column2": "641-650",
     "Column3": "Kazakh National Agrarian University KazNAU",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "LO",
     "Column9": "A",
     "Column10": 19.9,
     "Column11": 449,
     "Column12": 16.4,
     "Column13": 517,
     "Column14": 85.2,
     "Column15": 104,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 19.6,
     "Column19": 608,
     "Column20": 22.6,
     "Column21": 509,
     "Column22": 6.5,
     "Column23": "701+",
     "Column24": 3.3,
     "Column25": "701+",
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "671-680",
     "Column2": "611-620",
     "Column3": "Martin-Luther-Universität Halle-Wittenberg",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.1,
     "Column11": "601+",
     "Column12": 6.4,
     "Column13": "601+",
     "Column14": 80.1,
     "Column15": 136,
     "Column16": 7.4,
     "Column17": "701+",
     "Column20": 8.8,
     "Column21": "701+",
     "Column22": 90,
     "Column23": 168,
     "Column24": 4.8,
     "Column25": "701+",
     "Column26": 6.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "671-680",
     "Column2": "691-700",
     "Column3": "Universidade Federal de Minas Gerais      ",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 32.4,
     "Column11": 272,
     "Column12": 8.9,
     "Column13": "601+",
     "Column14": 13.8,
     "Column15": "701+",
     "Column16": 7,
     "Column17": "701+",
     "Column18": 8,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 64,
     "Column23": 573,
     "Column24": 39.7,
     "Column25": 324,
     "Column26": 6.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "671-680",
     "Column2": "651-660",
     "Column3": "University of Haifa",
     "Column4": "IL",
     "Column5": "Israel",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13,
     "Column11": "601+",
     "Column12": 5.9,
     "Column13": "601+",
     "Column14": 14.6,
     "Column15": "701+",
     "Column16": 32.1,
     "Column17": 409,
     "Column18": 13.4,
     "Column19": 699,
     "Column20": 3.6,
     "Column21": "701+",
     "Column22": 67.2,
     "Column23": 518,
     "Column24": 22.9,
     "Column25": 499,
     "Column26": 37.3,
     "Column27": 390,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "641-650",
     "Column2": "681-690",
     "Column3": "Soochow University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 15.9,
     "Column15": "701+",
     "Column16": 63,
     "Column17": 183,
     "Column18": 6.2,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 47.4,
     "Column23": "701+",
     "Column24": 16.8,
     "Column25": 618,
     "Column26": 2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "681-690",
     "Column2": 593,
     "Column3": "Goldsmiths, University of London",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.1,
     "Column11": "601+",
     "Column12": 5.1,
     "Column13": "601+",
     "Column14": 12.8,
     "Column15": "701+",
     "Column16": 12,
     "Column17": 691,
     "Column18": 92,
     "Column19": 176,
     "Column20": 91.5,
     "Column21": 115,
     "Column22": 25.1,
     "Column23": "701+",
     "Column24": 14.4,
     "Column25": 679,
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "681-690",
     "Column2": "691-700",
     "Column3": "Indian Institute of Technology Hyderabad",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.2,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 18.2,
     "Column15": "701+",
     "Column16": 71.1,
     "Column17": 152,
     "Column18": 1.2,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 13.7,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 4.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "681-690",
     "Column2": "721-730",
     "Column3": "Jeonbuk National University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.6,
     "Column11": 564,
     "Column12": 11.1,
     "Column13": "601+",
     "Column14": 61.3,
     "Column15": 234,
     "Column16": 10.6,
     "Column17": "701+",
     "Column18": 7.8,
     "Column19": "701+",
     "Column20": 6.8,
     "Column21": "701+",
     "Column22": 35.3,
     "Column23": "701+",
     "Column24": 2.8,
     "Column25": "701+",
     "Column26": 48.5,
     "Column27": 326,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "681-690",
     "Column3": "Prince Sultan University",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 6.5,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 14.7,
     "Column15": "701+",
     "Column16": 31.7,
     "Column17": 418,
     "Column18": 100,
     "Column19": 36,
     "Column20": 13.9,
     "Column21": 644,
     "Column22": 54.3,
     "Column23": "701+",
     "Column24": 11,
     "Column25": "701+",
     "Column26": 3.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "681-690",
     "Column2": "661-670",
     "Column3": "Princess Nourah bint Abdulrahman University",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16.6,
     "Column11": 543,
     "Column12": 14.8,
     "Column13": 565,
     "Column14": 18.9,
     "Column15": "701+",
     "Column16": 2.5,
     "Column17": "701+",
     "Column18": 94.5,
     "Column19": 155,
     "Column20": 38.4,
     "Column21": 364,
     "Column22": 64,
     "Column23": 574,
     "Column24": 2.3,
     "Column25": "701+",
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "681-690",
     "Column2": "721-730",
     "Column3": "Temple University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.3,
     "Column11": "601+",
     "Column12": 7.1,
     "Column13": "601+",
     "Column14": 29.2,
     "Column15": 531,
     "Column16": 16.9,
     "Column17": 603,
     "Column18": 3,
     "Column19": "701+",
     "Column20": 5.7,
     "Column21": "701+",
     "Column22": 77.2,
     "Column23": 375,
     "Column24": 23.2,
     "Column25": 490,
     "Column26": 61.9,
     "Column27": "258=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "681-690",
     "Column2": "741-750",
     "Column3": "University of Agriculture, Faisalabad ",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 11.6,
     "Column13": "601+",
     "Column14": 1.6,
     "Column15": "701+",
     "Column16": 61,
     "Column17": 196,
     "Column18": 2.5,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 66.7,
     "Column23": 527,
     "Column24": 2.4,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "681-690",
     "Column2": "711-720",
     "Column3": "University of New Brunswick",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 8.3,
     "Column13": "601+",
     "Column14": 19.9,
     "Column15": 677,
     "Column16": 27.5,
     "Column17": 470,
     "Column18": 61,
     "Column19": 320,
     "Column20": 34.1,
     "Column21": 397,
     "Column22": 48.7,
     "Column23": "701+",
     "Column24": 17.3,
     "Column25": 613,
     "Column26": 29,
     "Column27": "453=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "691-700",
     "Column2": "771-780",
     "Column3": "Chandigarh University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 14.1,
     "Column11": "601+",
     "Column12": 51.3,
     "Column13": 172,
     "Column14": 24,
     "Column15": 610,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 37.6,
     "Column19": 429,
     "Column20": 15.3,
     "Column21": 618,
     "Column22": 37.4,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "691-700",
     "Column2": "1201-1400",
     "Column3": "Norwegian University of Life Sciences (UMB)",
     "Column4": "NO",
     "Column5": "Norway",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 4.3,
     "Column13": "601+",
     "Column14": 100,
     "Column15": 14,
     "Column16": 8.5,
     "Column17": "701+",
     "Column22": 74.7,
     "Column23": 414,
     "Column24": 18.6,
     "Column25": 592,
     "Column26": 9.9,
     "Column27": "667=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "691-700",
     "Column2": "641-650",
     "Column3": "Pakistan Institute of Engineering and Applied Sciences (PIEAS)",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.5,
     "Column11": "601+",
     "Column12": 9.6,
     "Column13": "601+",
     "Column14": 66.1,
     "Column15": 198,
     "Column16": 41,
     "Column17": 340,
     "Column20": 1,
     "Column21": "701+",
     "Column22": 14.7,
     "Column23": "701+",
     "Column24": 3.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "691-700",
     "Column2": "681-690",
     "Column3": "Shiraz University",
     "Column4": "IR",
     "Column5": "Iran, Islamic Republic of",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 5.4,
     "Column13": "601+",
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 60.7,
     "Column17": 198,
     "Column18": 1.2,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 54.3,
     "Column23": "701+",
     "Column24": 14.1,
     "Column25": 688,
     "Column26": 7.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "691-700",
     "Column2": "631-640",
     "Column3": "Universidad Central de Venezuela",
     "Column4": "VE",
     "Column5": "Venezuela",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 21,
     "Column11": 417,
     "Column12": 40.6,
     "Column13": 232,
     "Column14": 27.1,
     "Column15": 559,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 21.5,
     "Column23": "701+",
     "Column24": 51.4,
     "Column25": 248,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "691-700",
     "Column2": "641-650",
     "Column3": "Universidad de La Habana",
     "Column4": "CU",
     "Column5": "Cuba",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 17,
     "Column11": 532,
     "Column12": 10.6,
     "Column13": "601+",
     "Column14": 72.8,
     "Column15": 180,
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column20": 3.7,
     "Column21": "701+",
     "Column22": 17.8,
     "Column23": "701+",
     "Column24": 76.5,
     "Column25": 134,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "691-700",
     "Column2": "661-670",
     "Column3": "Universidad Panamericana (UP)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 9.9,
     "Column11": "601+",
     "Column12": 20,
     "Column13": 451,
     "Column14": 75.4,
     "Column15": 164,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 17.4,
     "Column19": 637,
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 20.1,
     "Column23": "701+",
     "Column24": 68.7,
     "Column25": 169,
     "Column26": 2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "691-700",
     "Column2": "731-740",
     "Column3": "Universidade Federal de São Paulo",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.5,
     "Column11": "601+",
     "Column12": 5.1,
     "Column13": "601+",
     "Column14": 95.6,
     "Column15": 57,
     "Column16": 6,
     "Column17": "701+",
     "Column18": 4.7,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 54,
     "Column23": "701+",
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 30.1,
     "Column27": "443=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "691-700",
     "Column2": "691-700",
     "Column3": "Universidade Federal do Rio Grande Do Sul ",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 28,
     "Column11": 316,
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 12.8,
     "Column15": "701+",
     "Column16": 10.1,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 80.1,
     "Column23": 319,
     "Column24": 25.9,
     "Column25": 455,
     "Column26": 31.3,
     "Column27": "435=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "691-700",
     "Column2": "791-800",
     "Column3": "University of Brescia",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 2.2,
     "Column13": "601+",
     "Column14": 7.4,
     "Column15": "701+",
     "Column16": 56.3,
     "Column17": 223,
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 6.3,
     "Column21": "701+",
     "Column22": 55.5,
     "Column23": 688,
     "Column24": 3.3,
     "Column25": "701+",
     "Column26": 44.9,
     "Column27": "338=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "691-700",
     "Column2": "671-680",
     "Column3": "University of Greenwich",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.7,
     "Column11": "601+",
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 4.1,
     "Column15": "701+",
     "Column16": 11.7,
     "Column17": 700,
     "Column18": 91.5,
     "Column19": 183,
     "Column20": 100,
     "Column21": 30,
     "Column22": 78.2,
     "Column23": 357,
     "Column24": 14.9,
     "Column25": 666,
     "Column26": 5.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "691-700",
     "Column2": "731-740",
     "Column3": "University of Siena",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.6,
     "Column11": "601+",
     "Column12": 3.5,
     "Column13": "601+",
     "Column14": 9.7,
     "Column15": "701+",
     "Column16": 40.4,
     "Column17": 349,
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 10.8,
     "Column21": "701+",
     "Column22": 61,
     "Column23": 621,
     "Column24": 9.3,
     "Column25": "701+",
     "Column26": 27.9,
     "Column27": "462=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "851-900",
     "Column3": "Huazhong Agricultural University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.2,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 6,
     "Column15": "701+",
     "Column16": 59.7,
     "Column17": 204,
     "Column18": 3.7,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 31.6,
     "Column23": "701+",
     "Column24": 3,
     "Column25": "701+",
     "Column26": 2.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "701-710",
     "Column2": "671-680",
     "Column3": "Dongguk University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 7.9,
     "Column11": "601+",
     "Column12": 10.5,
     "Column13": "601+",
     "Column14": 83.6,
     "Column15": 116,
     "Column16": 9.7,
     "Column17": "701+",
     "Column18": 9,
     "Column19": "701+",
     "Column20": 23.8,
     "Column21": 496,
     "Column22": 36.5,
     "Column23": "701+",
     "Column24": 4.8,
     "Column25": "701+",
     "Column26": 20.8,
     "Column27": "527=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "701-710",
     "Column2": "661-670",
     "Column3": "Lebanese American University",
     "Column4": "LB",
     "Column5": "Lebanon",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 13.5,
     "Column11": "601+",
     "Column12": 20.3,
     "Column13": 448,
     "Column14": 29.1,
     "Column15": 532,
     "Column16": 3.7,
     "Column17": "701+",
     "Column18": 73.3,
     "Column19": 273,
     "Column20": 19.6,
     "Column21": 551,
     "Column22": 36.5,
     "Column23": "701+",
     "Column24": 19.9,
     "Column25": 565,
     "Column26": 19.5,
     "Column27": 544,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "701-710",
     "Column2": "781-790",
     "Column3": "National Institute of Technology Tiruchirappalli",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 9.1,
     "Column15": "701+",
     "Column16": 71,
     "Column17": 153,
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 34.5,
     "Column23": "701+",
     "Column24": 3,
     "Column25": "701+",
     "Column26": 5.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "701-710",
     "Column2": "621-630",
     "Column3": "Stevens Institute of Technology",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 2.6,
     "Column11": "601+",
     "Column12": 4,
     "Column13": "601+",
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 41.7,
     "Column17": 331,
     "Column18": 53.2,
     "Column19": 350,
     "Column20": 96.4,
     "Column21": 86,
     "Column22": 18,
     "Column23": "701+",
     "Column24": 7.8,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "701-710",
     "Column2": "681-690",
     "Column3": "Taras Shevchenko National University of Kyiv",
     "Column4": "UA",
     "Column5": "Ukraine",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.9,
     "Column11": 478,
     "Column12": 36.6,
     "Column13": 257,
     "Column14": 27,
     "Column15": 562,
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 1.5,
     "Column19": "701+",
     "Column20": 3.9,
     "Column21": "701+",
     "Column22": 40.3,
     "Column23": "701+",
     "Column24": 45.5,
     "Column25": 280,
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "701-710",
     "Column2": 551,
     "Column3": "University of Nebraska - Lincoln",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.6,
     "Column11": "601+",
     "Column12": 5.8,
     "Column13": "601+",
     "Column14": 16.9,
     "Column15": "701+",
     "Column16": 30.4,
     "Column17": 434,
     "Column18": 21.5,
     "Column19": 575,
     "Column20": 7.1,
     "Column21": "701+",
     "Column22": 73.1,
     "Column23": 437,
     "Column24": 7.2,
     "Column25": "701+",
     "Column26": 33,
     "Column27": "420=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "701-710",
     "Column2": "641-650",
     "Column3": "University of Oklahoma",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.2,
     "Column11": "601+",
     "Column12": 9.8,
     "Column13": "601+",
     "Column14": 15.6,
     "Column15": "701+",
     "Column16": 31.1,
     "Column17": 424,
     "Column18": 21.8,
     "Column19": 571,
     "Column20": 5.7,
     "Column21": "701+",
     "Column22": 69.3,
     "Column23": 493,
     "Column24": 22.3,
     "Column25": 514,
     "Column26": 17.7,
     "Column27": "557=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "751-760",
     "Column3": "Ahlia University",
     "Column4": "BH",
     "Column5": "Bahrain",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 16.7,
     "Column11": 536,
     "Column12": 8.4,
     "Column13": "601+",
     "Column14": 31.7,
     "Column15": 497,
     "Column16": 3.8,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 47,
     "Column20": 42.7,
     "Column21": 339,
     "Column22": 9.3,
     "Column23": "701+",
     "Column24": 12.7,
     "Column25": "701+",
     "Column26": 2.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "791-800",
     "Column3": "American University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 16.2,
     "Column11": 550,
     "Column12": 29.3,
     "Column13": 321,
     "Column14": 32.9,
     "Column15": 479,
     "Column16": 4.3,
     "Column17": "701+",
     "Column18": 8,
     "Column19": "701+",
     "Column20": 19.4,
     "Column21": 554,
     "Column22": 30.6,
     "Column23": "701+",
     "Column24": 40.5,
     "Column25": 315,
     "Column26": 4.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "651-660",
     "Column3": "COMSATS University Islamabad",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.7,
     "Column11": "601+",
     "Column12": 30.5,
     "Column13": 308,
     "Column14": 13.4,
     "Column15": "701+",
     "Column16": 18.2,
     "Column17": 580,
     "Column18": 1.5,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 88.3,
     "Column23": 192,
     "Column24": 6.1,
     "Column25": "701+",
     "Column26": 14.6,
     "Column27": "597=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "701-710",
     "Column3": "Czech University of Life Sciences in Prague",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.4,
     "Column11": "601+",
     "Column12": 6.7,
     "Column13": "601+",
     "Column14": 9.4,
     "Column15": "701+",
     "Column16": 10.8,
     "Column17": "701+",
     "Column18": 29,
     "Column19": 502,
     "Column20": 73.5,
     "Column21": 197,
     "Column22": 75.8,
     "Column23": 392,
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 49.5,
     "Column27": "322=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "801-850",
     "Column3": "De Montfort University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.3,
     "Column11": "601+",
     "Column12": 4.8,
     "Column13": "601+",
     "Column14": 5.6,
     "Column15": "701+",
     "Column16": 8,
     "Column17": "701+",
     "Column18": 74.2,
     "Column19": 267,
     "Column20": 97.5,
     "Column21": 76,
     "Column22": 62.8,
     "Column23": 591,
     "Column24": 6.4,
     "Column25": "701+",
     "Column26": 41.4,
     "Column27": "358=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "641-650",
     "Column3": "Lingnan University, Hong Kong",
     "Column4": "HK",
     "Column5": "Hong Kong SAR",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.9,
     "Column11": "601+",
     "Column12": 6.5,
     "Column13": "601+",
     "Column14": 13.9,
     "Column15": "701+",
     "Column16": 11,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 35,
     "Column20": 98.9,
     "Column21": 60,
     "Column22": 15.6,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 3.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "761-770",
     "Column2": "801-850",
     "Column3": "Nanjing University of Aeronautics and Astronautics",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 12.4,
     "Column15": "701+",
     "Column16": 57.8,
     "Column17": 213,
     "Column18": 5.3,
     "Column19": "701+",
     "Column20": 3,
     "Column21": "701+",
     "Column22": 39.7,
     "Column23": "701+",
     "Column24": 1.4,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "791-800",
     "Column3": "Plekhanov Russian University of Economics",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14,
     "Column11": "601+",
     "Column12": 19.1,
     "Column13": 468,
     "Column14": 49.8,
     "Column15": 311,
     "Column16": 2.3,
     "Column17": "701+",
     "Column18": 3,
     "Column19": "701+",
     "Column20": 16.2,
     "Column21": 602,
     "Column22": 51.9,
     "Column23": "701+",
     "Column24": 38.5,
     "Column25": 333,
     "Column26": 11.6,
     "Column27": "629=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "631-640",
     "Column3": "Saint Joseph University of Beirut (USJ) ",
     "Column4": "LB",
     "Column5": "Lebanon",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 8.2,
     "Column11": "601+",
     "Column12": 16.4,
     "Column13": 518,
     "Column14": 50.7,
     "Column15": 305,
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 91.3,
     "Column19": 184,
     "Column20": 25.8,
     "Column21": 467,
     "Column22": 13.1,
     "Column23": "701+",
     "Column24": 33.4,
     "Column25": 382,
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "681-690",
     "Column3": "The National University of Science and Technology MISIS",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.2,
     "Column11": "601+",
     "Column12": 9,
     "Column13": "601+",
     "Column14": 74.2,
     "Column15": 172,
     "Column16": 13.8,
     "Column17": 657,
     "Column18": 7.9,
     "Column19": "701+",
     "Column20": 43.1,
     "Column21": 335,
     "Column22": 44.7,
     "Column23": "701+",
     "Column24": 9.2,
     "Column25": "701+",
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "721-730",
     "Column3": "Ton Duc Thang University",
     "Column4": "VN",
     "Column5": "Vietnam",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 6.4,
     "Column13": "601+",
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 51.7,
     "Column17": 252,
     "Column18": 9.9,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 90.7,
     "Column23": 162,
     "Column24": 4.7,
     "Column25": "701+",
     "Column26": 3.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "1201-1400",
     "Column3": "Université de Franche-Comté",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.5,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 100,
     "Column15": 2,
     "Column16": 12.4,
     "Column17": 681,
     "Column22": 76.7,
     "Column23": 384,
     "Column24": 3.2,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "711-720",
     "Column2": "751-760",
     "Column3": "University of Mumbai",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.1,
     "Column11": "601+",
     "Column12": 28.8,
     "Column13": 327,
     "Column14": 2.8,
     "Column15": "701+",
     "Column16": 30.9,
     "Column17": 428,
     "Column18": 2,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 18.3,
     "Column23": "701+",
     "Column24": 81.4,
     "Column25": 119,
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "661-670",
     "Column3": "Brandeis University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 9.4,
     "Column11": "601+",
     "Column12": 4.8,
     "Column13": "601+",
     "Column14": 53.1,
     "Column15": 288,
     "Column16": 11.6,
     "Column17": "701+",
     "Column18": 18,
     "Column19": 627,
     "Column20": 75.1,
     "Column21": 186,
     "Column22": 32.3,
     "Column23": "701+",
     "Column24": 16.5,
     "Column25": 624,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "741-750",
     "Column3": "Budapest University of Technology and Economics",
     "Column4": "HU",
     "Column5": "Hungary",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.6,
     "Column11": 562,
     "Column12": 25.7,
     "Column13": 369,
     "Column14": 13.7,
     "Column15": "701+",
     "Column16": 8.5,
     "Column17": "701+",
     "Column18": 2.8,
     "Column19": "701+",
     "Column20": 14.8,
     "Column21": 630,
     "Column22": 56.6,
     "Column23": 669,
     "Column24": 33.2,
     "Column25": 384,
     "Column26": 26,
     "Column27": 482,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "791-800",
     "Column3": "Diponegoro University",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 19.2,
     "Column11": 467,
     "Column12": 41.7,
     "Column13": 227,
     "Column14": 19.3,
     "Column15": 690,
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 27.9,
     "Column19": 508,
     "Column20": 3,
     "Column21": "701+",
     "Column22": 27.4,
     "Column23": "701+",
     "Column24": 14.6,
     "Column25": 673,
     "Column26": 9.7,
     "Column27": "676=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "601-610",
     "Column3": "Drexel University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 8.1,
     "Column11": "601+",
     "Column12": 4.5,
     "Column13": "601+",
     "Column14": 22.4,
     "Column15": 628,
     "Column16": 28.7,
     "Column17": 451,
     "Column18": 12.6,
     "Column19": "701+",
     "Column20": 23.8,
     "Column21": 495,
     "Column22": 73.4,
     "Column23": 432,
     "Column24": 25.1,
     "Column25": 464,
     "Column26": 8.3,
     "Column27": "700=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column3": "Effat University",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 23.1,
     "Column15": 622,
     "Column16": 17.6,
     "Column17": 589,
     "Column18": 100,
     "Column19": 20,
     "Column20": 98.3,
     "Column21": 66,
     "Column22": 15.9,
     "Column23": "701+",
     "Column24": 3.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "741-750",
     "Column3": "Jadavpur University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.8,
     "Column11": 508,
     "Column12": 5.9,
     "Column13": "601+",
     "Column14": 9.1,
     "Column15": "701+",
     "Column16": 40.6,
     "Column17": 346,
     "Column20": 1,
     "Column21": "701+",
     "Column22": 32.5,
     "Column23": "701+",
     "Column24": 14.9,
     "Column25": 667,
     "Column26": 13.4,
     "Column27": 605,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "801-850",
     "Column3": "Konkuk University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 8.1,
     "Column13": "601+",
     "Column14": 61.8,
     "Column15": 231,
     "Column16": 17.1,
     "Column17": 600,
     "Column18": 9.5,
     "Column19": "701+",
     "Column20": 17.7,
     "Column21": 582,
     "Column22": 46.8,
     "Column23": "701+",
     "Column24": 9,
     "Column25": "701+",
     "Column26": 28.4,
     "Column27": "456=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": 580,
     "Column2": "631-640",
     "Column3": "Jinan University (China)",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 8.9,
     "Column15": "701+",
     "Column16": 52.7,
     "Column17": 244,
     "Column18": 34,
     "Column19": 470,
     "Column20": 83.1,
     "Column21": 162,
     "Column22": 52.8,
     "Column23": "701+",
     "Column24": 6.5,
     "Column25": "701+",
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": 21.3
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "661-670",
     "Column3": "Middlesex University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.3,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 8.1,
     "Column15": "701+",
     "Column16": 10.6,
     "Column17": "701+",
     "Column18": 84.4,
     "Column19": 222,
     "Column20": 99.5,
     "Column21": 45,
     "Column22": 58.3,
     "Column23": 653,
     "Column24": 15.2,
     "Column25": 651,
     "Column26": 4.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "751-760",
     "Column3": "Riga Technical University",
     "Column4": "LV",
     "Column5": "Latvia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.5,
     "Column11": "601+",
     "Column12": 26.8,
     "Column13": 358,
     "Column14": 22,
     "Column15": 638,
     "Column16": 3,
     "Column17": "701+",
     "Column18": 15.7,
     "Column19": 657,
     "Column20": 72,
     "Column21": 203,
     "Column22": 29.9,
     "Column23": "701+",
     "Column24": 35,
     "Column25": 362,
     "Column26": 6.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "631-640",
     "Column3": "Université de Lille",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.5,
     "Column11": 374,
     "Column12": 5.5,
     "Column13": "601+",
     "Column14": 4.8,
     "Column15": "701+",
     "Column16": 14.7,
     "Column17": 643,
     "Column18": 14.6,
     "Column19": 670,
     "Column20": 15.8,
     "Column21": 610,
     "Column22": 93.3,
     "Column23": 110,
     "Column24": 4.5,
     "Column25": "701+",
     "Column26": 10.9,
     "Column27": "648=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "711-720",
     "Column3": "Université de Rennes 1 (University of Rennes)",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.9,
     "Column11": 586,
     "Column12": 5.2,
     "Column13": "601+",
     "Column14": 11.6,
     "Column15": "701+",
     "Column16": 28.3,
     "Column17": 462,
     "Column18": 11.9,
     "Column19": "701+",
     "Column20": 11,
     "Column21": 700,
     "Column22": 88.1,
     "Column23": 195,
     "Column24": 6.8,
     "Column25": "701+",
     "Column26": 6.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "641-650",
     "Column3": "University of Modena and Reggio Emilia",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.6,
     "Column11": "601+",
     "Column12": 4,
     "Column13": "601+",
     "Column14": 2.9,
     "Column15": "701+",
     "Column16": 49.7,
     "Column17": 266,
     "Column18": 4,
     "Column19": "701+",
     "Column20": 3.6,
     "Column21": "701+",
     "Column22": 53.3,
     "Column23": "701+",
     "Column24": 15.2,
     "Column25": 653,
     "Column26": 23.2,
     "Column27": "505=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "691-700",
     "Column3": "University of Trieste",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 11.7,
     "Column11": "601+",
     "Column12": 4,
     "Column13": "601+",
     "Column14": 3.6,
     "Column15": "701+",
     "Column16": 43.3,
     "Column17": 318,
     "Column18": 7.3,
     "Column19": "701+",
     "Column20": 7.9,
     "Column21": "701+",
     "Column22": 70.4,
     "Column23": 480,
     "Column24": 14.6,
     "Column25": 674,
     "Column26": 2.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "731-740",
     "Column2": "661-670",
     "Column3": "Universitat Rovira i Virgili",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.4,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 15.2,
     "Column15": "701+",
     "Column16": 37.9,
     "Column17": 366,
     "Column18": 7.2,
     "Column19": "701+",
     "Column20": 19,
     "Column21": 565,
     "Column22": 77.5,
     "Column23": 368,
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 31.6,
     "Column27": "432=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "731-740",
     "Column2": "721-730",
     "Column3": "Université Côte d\\'Azur",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.5,
     "Column11": 567,
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 5.7,
     "Column15": "701+",
     "Column16": 18.3,
     "Column17": 577,
     "Column18": 29.3,
     "Column19": 498,
     "Column20": 38.3,
     "Column21": 367,
     "Column22": 78.4,
     "Column23": 352,
     "Column24": 8.9,
     "Column25": "701+",
     "Column26": 18.4,
     "Column27": "549=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "731-740",
     "Column2": "721-730",
     "Column3": "University of Belgrade",
     "Column4": "RS",
     "Column5": "Serbia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.6,
     "Column11": 514,
     "Column12": 8.4,
     "Column13": "601+",
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 5.3,
     "Column17": "701+",
     "Column18": 2.8,
     "Column19": "701+",
     "Column20": 4,
     "Column21": "701+",
     "Column22": 95.2,
     "Column23": 73,
     "Column24": 96.4,
     "Column25": 40,
     "Column26": 3.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": "701-710",
     "Column3": "Altai State University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.7,
     "Column11": "601+",
     "Column12": 3.3,
     "Column13": "601+",
     "Column14": 87.5,
     "Column15": 97,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 6.3,
     "Column19": "701+",
     "Column20": 60,
     "Column21": 246,
     "Column22": 18.8,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 10.1,
     "Column27": "661=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": "671-680",
     "Column3": "Carleton University",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.1,
     "Column11": "601+",
     "Column12": 13.5,
     "Column13": 599,
     "Column14": 3.8,
     "Column15": "701+",
     "Column16": 15.6,
     "Column17": 627,
     "Column18": 13.2,
     "Column19": "701+",
     "Column20": 43.7,
     "Column21": 329,
     "Column22": 67.3,
     "Column23": 517,
     "Column24": 34.3,
     "Column25": 367,
     "Column26": 26.3,
     "Column27": "478=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": 582,
     "Column3": "Universidad Católica Andres Bello",
     "Column4": "VE",
     "Column5": "Venezuela",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 28.4,
     "Column11": 311,
     "Column12": 50.8,
     "Column13": 174,
     "Column14": 4.3,
     "Column15": "701+",
     "Column16": 1,
     "Column17": "701+",
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column24": 18.9,
     "Column25": 583,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": "651-660",
     "Column3": "Universidad de Montevideo (UM)",
     "Column4": "UY",
     "Column5": "Uruguay",
     "Column6": "S",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 18.6,
     "Column11": 485,
     "Column12": 16.1,
     "Column13": 531,
     "Column14": 79.7,
     "Column15": 141,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 21.5,
     "Column19": 576,
     "Column20": 3.1,
     "Column21": "701+",
     "Column22": 3,
     "Column23": "701+",
     "Column24": 6.5,
     "Column25": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": "741-750",
     "Column3": "Universität Regensburg",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.3,
     "Column11": "601+",
     "Column12": 6,
     "Column13": "601+",
     "Column14": 80,
     "Column15": 138,
     "Column16": 6.1,
     "Column17": "701+",
     "Column20": 8.5,
     "Column21": "701+",
     "Column22": 69.1,
     "Column23": 496,
     "Column24": 15.3,
     "Column25": 650,
     "Column26": 2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": "781-790",
     "Column3": "Universiti Malaysia Pahang",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.8,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 15.8,
     "Column15": "701+",
     "Column16": 35,
     "Column17": 383,
     "Column18": 22.1,
     "Column19": 567,
     "Column20": 8.6,
     "Column21": "701+",
     "Column22": 54.7,
     "Column23": "701+",
     "Column24": 4,
     "Column25": "701+",
     "Column26": 17.7,
     "Column27": "557=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": 593,
     "Column3": "University of Central Florida",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.7,
     "Column11": "601+",
     "Column12": 23,
     "Column13": 410,
     "Column14": 2.9,
     "Column15": "701+",
     "Column16": 23.6,
     "Column17": 514,
     "Column18": 9.5,
     "Column19": "701+",
     "Column20": 4.3,
     "Column21": "701+",
     "Column22": 72.2,
     "Column23": 448,
     "Column24": 9.2,
     "Column25": "701+",
     "Column26": 12.1,
     "Column27": 621,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": "741-750",
     "Column3": "University of the West of England",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9,
     "Column11": "601+",
     "Column12": 9,
     "Column13": "601+",
     "Column14": 7.5,
     "Column15": "701+",
     "Column16": 11.9,
     "Column17": 695,
     "Column18": 46.9,
     "Column19": 381,
     "Column20": 75.3,
     "Column21": 185,
     "Column22": 76.1,
     "Column23": 387,
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 14.3,
     "Column27": 602,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": "711-720",
     "Column3": "University of Westminster",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 11.5,
     "Column11": "601+",
     "Column12": 6.8,
     "Column13": "601+",
     "Column14": 5.7,
     "Column15": "701+",
     "Column16": 5.1,
     "Column17": "701+",
     "Column18": 77.4,
     "Column19": 249,
     "Column20": 100,
     "Column21": 33,
     "Column22": 35.4,
     "Column23": "701+",
     "Column24": 21.7,
     "Column25": 526,
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": "691-700",
     "Column3": "V. N. Karazin Kharkiv National University",
     "Column4": "UA",
     "Column5": "Ukraine",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 12.6,
     "Column11": "601+",
     "Column12": 16.9,
     "Column13": 507,
     "Column14": 64.6,
     "Column15": 207,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 3.3,
     "Column19": "701+",
     "Column20": 55.7,
     "Column21": 269,
     "Column22": 29.4,
     "Column23": "701+",
     "Column24": 4,
     "Column25": "701+",
     "Column26": 4.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": "711-720",
     "Column3": "Victoria University ",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 6.1,
     "Column13": "601+",
     "Column14": 2.4,
     "Column15": "701+",
     "Column16": 34.1,
     "Column17": 389,
     "Column18": 32.7,
     "Column19": 480,
     "Column20": 41.5,
     "Column21": 345,
     "Column22": 44.9,
     "Column23": "701+",
     "Column24": 6.4,
     "Column25": "701+",
     "Column26": 34.8,
     "Column27": "406=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": "801-850",
     "Column3": "Vytautas Magnus University",
     "Column4": "LT",
     "Column5": "Lithuania",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.4,
     "Column11": "601+",
     "Column12": 16.8,
     "Column13": 512,
     "Column14": 41.8,
     "Column15": 371,
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 26.7,
     "Column19": 520,
     "Column20": 47.8,
     "Column21": 304,
     "Column22": 48.1,
     "Column23": "701+",
     "Column24": 21.6,
     "Column25": 529,
     "Column26": 6.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "741-750",
     "Column2": "701-710",
     "Column3": "Wake Forest University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 5.1,
     "Column11": "601+",
     "Column12": 6.3,
     "Column13": "601+",
     "Column14": 100,
     "Column15": 10,
     "Column16": 8.6,
     "Column17": "701+",
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 10.9,
     "Column21": "701+",
     "Column22": 42.8,
     "Column23": "701+",
     "Column24": 15.4,
     "Column25": 648,
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "751-760",
     "Column2": "651-660",
     "Column3": "Instituto Tecnológico Autónomo de México (ITAM)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "LO",
     "Column9": "B",
     "Column10": 15.2,
     "Column11": 578,
     "Column12": 31,
     "Column13": 302,
     "Column14": 13.9,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 51,
     "Column19": 363,
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 7.7,
     "Column23": "701+",
     "Column24": 77.1,
     "Column25": 133,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "751-760",
     "Column2": "801-850",
     "Column3": "Kaunas University of Technology",
     "Column4": "LT",
     "Column5": "Lithuania",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.2,
     "Column11": "601+",
     "Column12": 18.5,
     "Column13": 477,
     "Column14": 31.1,
     "Column15": 507,
     "Column16": 7.9,
     "Column17": "701+",
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 28.6,
     "Column21": 439,
     "Column22": 64.2,
     "Column23": 569,
     "Column24": 23.2,
     "Column25": 491,
     "Column26": 16.1,
     "Column27": "577=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "751-760",
     "Column2": "751-760",
     "Column3": "Technische Universität Braunschweig",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.9,
     "Column11": "601+",
     "Column12": 14.3,
     "Column13": 572,
     "Column14": 55.5,
     "Column15": 270,
     "Column16": 5.5,
     "Column17": "701+",
     "Column20": 24.2,
     "Column21": 486,
     "Column22": 66.4,
     "Column23": 532,
     "Column24": 12.6,
     "Column25": "701+",
     "Column26": 7.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "751-760",
     "Column2": "661-670",
     "Column3": "Tokyo University of Science",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 10.4,
     "Column11": "601+",
     "Column12": 51.5,
     "Column13": 171,
     "Column14": 9.4,
     "Column15": "701+",
     "Column16": 10,
     "Column17": "701+",
     "Column18": 12.5,
     "Column19": "701+",
     "Column20": 3.7,
     "Column21": "701+",
     "Column22": 20.3,
     "Column23": "701+",
     "Column24": 41,
     "Column25": 313,
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "751-760",
     "Column2": "801-850",
     "Column3": "Universidade de Brasília",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 25.3,
     "Column11": 348,
     "Column12": 7.9,
     "Column13": "601+",
     "Column14": 7.1,
     "Column15": "701+",
     "Column16": 4.2,
     "Column17": "701+",
     "Column18": 10.8,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 67.5,
     "Column23": 514,
     "Column24": 29.6,
     "Column25": 420,
     "Column26": 35.1,
     "Column27": "403=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "751-760",
     "Column2": "721-730",
     "Column3": "Universita' Politecnica delle Marche",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.9,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 10.1,
     "Column15": "701+",
     "Column16": 48.4,
     "Column17": 275,
     "Column18": 4.9,
     "Column19": "701+",
     "Column20": 6.3,
     "Column21": "701+",
     "Column22": 77.7,
     "Column23": 366,
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 6.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "751-760",
     "Column2": "801-850",
     "Column3": "University of Calcutta",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.1,
     "Column11": 529,
     "Column12": 6.5,
     "Column13": "601+",
     "Column14": 5.5,
     "Column15": "701+",
     "Column16": 21.4,
     "Column17": 539,
     "Column18": 24.7,
     "Column19": 541,
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 56,
     "Column23": 677,
     "Column24": 49.8,
     "Column25": 256,
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "751-760",
     "Column2": "791-800",
     "Column3": "University of Engineering & Technology (UET) Lahore",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10,
     "Column11": "601+",
     "Column12": 43.2,
     "Column13": 212,
     "Column14": 18,
     "Column15": "701+",
     "Column16": 6.7,
     "Column17": "701+",
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 41.6,
     "Column23": "701+",
     "Column24": 47.7,
     "Column25": 269,
     "Column26": 7.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "751-760",
     "Column2": "851-900",
     "Column3": "University of Malta",
     "Column4": "MT",
     "Column5": "Malta",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 4.6,
     "Column13": "601+",
     "Column14": 31.7,
     "Column15": 498,
     "Column16": 7.2,
     "Column17": "701+",
     "Column18": 18,
     "Column19": 624,
     "Column20": 19.2,
     "Column21": 562,
     "Column22": 75.9,
     "Column23": 391,
     "Column24": 95.9,
     "Column25": 45,
     "Column26": 1.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "751-760",
     "Column2": "851-900",
     "Column3": "University of Messina (UniME)",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.4,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 14.2,
     "Column15": "701+",
     "Column16": 36.7,
     "Column17": 372,
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 6,
     "Column21": "701+",
     "Column22": 75.6,
     "Column23": 394,
     "Column24": 1.6,
     "Column25": "701+",
     "Column26": 36.6,
     "Column27": "393=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "761-770",
     "Column2": "731-740",
     "Column3": "Adam Mickiewicz University, Pozna?",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16,
     "Column11": 553,
     "Column12": 9.1,
     "Column13": "601+",
     "Column14": 33.3,
     "Column15": 473,
     "Column16": 4.8,
     "Column17": "701+",
     "Column18": 7.7,
     "Column19": "701+",
     "Column20": 8.3,
     "Column21": "701+",
     "Column22": 86.7,
     "Column23": 218,
     "Column24": 7.5,
     "Column25": "701+",
     "Column26": 31.6,
     "Column27": "432=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "761-770",
     "Column2": "801-850",
     "Column3": "Bangladesh University of Engineering and Technology",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16.9,
     "Column11": 534,
     "Column12": 36.6,
     "Column13": 256,
     "Column14": 13.6,
     "Column15": "701+",
     "Column16": 14.9,
     "Column17": 640,
     "Column20": 1,
     "Column21": "701+",
     "Column22": 25.1,
     "Column23": "701+",
     "Column24": 22.2,
     "Column25": 518,
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "761-770",
     "Column2": "851-900",
     "Column3": "King Faisal University",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 16.4,
     "Column11": 545,
     "Column12": 12.7,
     "Column13": "601+",
     "Column14": 14.5,
     "Column15": "701+",
     "Column16": 2.7,
     "Column17": "701+",
     "Column18": 98,
     "Column19": 121,
     "Column20": 3.9,
     "Column21": "701+",
     "Column22": 59.3,
     "Column23": 640,
     "Column24": 8.7,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "901-950",
     "Column3": "Ocean University of China",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 9.4,
     "Column15": "701+",
     "Column16": 50.5,
     "Column17": 258,
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 51.1,
     "Column23": "701+",
     "Column24": 3,
     "Column25": "701+",
     "Column26": 4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "761-770",
     "Column2": "691-700",
     "Column3": "University of Cincinnati",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11,
     "Column11": "601+",
     "Column12": 6,
     "Column13": "601+",
     "Column14": 19.4,
     "Column15": 687,
     "Column16": 22.9,
     "Column17": 521,
     "Column18": 4.3,
     "Column19": "701+",
     "Column20": 12.4,
     "Column21": 670,
     "Column22": 69,
     "Column23": 499,
     "Column24": 25.3,
     "Column25": 462,
     "Column26": 22.5,
     "Column27": "510=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "761-770",
     "Column2": "721-730",
     "Column3": "University of Colorado, Denver ",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.2,
     "Column11": "601+",
     "Column12": 6.1,
     "Column13": "601+",
     "Column14": 100,
     "Column15": 5,
     "Column16": 3.6,
     "Column17": "701+",
     "Column18": 3.4,
     "Column19": "701+",
     "Column20": 8.1,
     "Column21": "701+",
     "Column22": 45.7,
     "Column23": "701+",
     "Column24": 5.6,
     "Column25": "701+",
     "Column26": 3.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "761-770",
     "Column2": "791-800",
     "Column3": "University of Patras",
     "Column4": "GR",
     "Column5": "Greece",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.7,
     "Column11": "601+",
     "Column12": 17.1,
     "Column13": 502,
     "Column14": 2.7,
     "Column15": "701+",
     "Column16": 27,
     "Column17": 475,
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 4.8,
     "Column21": "701+",
     "Column22": 60.6,
     "Column23": 626,
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 44.9,
     "Column27": "338=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "771-780",
     "Column2": "851-900",
     "Column3": "Addis Ababa University",
     "Column4": "ET",
     "Column5": "Ethiopia",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column10": 6.4,
     "Column11": "601+",
     "Column12": 9.3,
     "Column13": "601+",
     "Column14": 58.8,
     "Column15": 249,
     "Column16": 1.7,
     "Column17": "701+",
     "Column22": 55.2,
     "Column23": 694,
     "Column24": 95.5,
     "Column25": 49,
     "Column26": 3.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "771-780",
     "Column2": "731-740",
     "Column3": "Bournemouth University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 6.4,
     "Column13": "601+",
     "Column14": 6.1,
     "Column15": "701+",
     "Column16": 16.4,
     "Column17": 617,
     "Column18": 80.3,
     "Column19": 237,
     "Column20": 47.1,
     "Column21": 312,
     "Column22": 72.6,
     "Column23": 445,
     "Column24": 4.8,
     "Column25": "701+",
     "Column26": 5.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "771-780",
     "Column2": "701-710",
     "Column3": "Chiba University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16.6,
     "Column11": 542,
     "Column12": 8.5,
     "Column13": "601+",
     "Column14": 66.1,
     "Column15": 197,
     "Column16": 8.8,
     "Column17": "701+",
     "Column18": 7.5,
     "Column19": "701+",
     "Column20": 4.3,
     "Column21": "701+",
     "Column22": 28.9,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 8.7,
     "Column27": 695,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "Beijing University of Posts and Telecommunications",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.2,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 10.2,
     "Column15": "701+",
     "Column16": 49.3,
     "Column17": 271,
     "Column18": 4.7,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 18.7,
     "Column23": "701+",
     "Column24": 13.5,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "771-780",
     "Column2": "681-690",
     "Column3": "Colegio de México",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.9,
     "Column11": 584,
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 100,
     "Column15": 1,
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 8.5,
     "Column19": "701+",
     "Column20": 16.4,
     "Column21": 596,
     "Column22": 9.6,
     "Column23": "701+",
     "Column24": 3.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "771-780",
     "Column2": "661-670",
     "Column3": "Holy Spirit University of Kaslik ",
     "Column4": "LB",
     "Column5": "Lebanon",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 6.7,
     "Column11": "601+",
     "Column12": 6.4,
     "Column13": "601+",
     "Column14": 62.3,
     "Column15": 226,
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 98.9,
     "Column19": 108,
     "Column20": 37.8,
     "Column21": 372,
     "Column22": 6.5,
     "Column23": "701+",
     "Column24": 5.7,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "771-780",
     "Column2": "711-720",
     "Column3": "Universidad del Rosario",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 15.8,
     "Column11": 558,
     "Column12": 28.5,
     "Column13": 335,
     "Column14": 24.2,
     "Column15": 607,
     "Column16": 2.5,
     "Column17": "701+",
     "Column18": 7.2,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 31.8,
     "Column23": "701+",
     "Column24": 41.1,
     "Column25": 312,
     "Column26": 24.5,
     "Column27": "492=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "771-780",
     "Column2": "951-1000",
     "Column3": "Université de Tunis El Manar",
     "Column4": "TN",
     "Column5": "Tunisia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.4,
     "Column11": "601+",
     "Column12": 4.3,
     "Column13": "601+",
     "Column14": 26,
     "Column15": 573,
     "Column16": 5.1,
     "Column17": "701+",
     "Column18": 39.5,
     "Column19": 421,
     "Column20": 7.8,
     "Column21": "701+",
     "Column22": 82.1,
     "Column23": 288,
     "Column24": 54.1,
     "Column25": 230,
     "Column26": 16.3,
     "Column27": "570=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "771-780",
     "Column2": "711-720",
     "Column3": "University of Maryland, Baltimore County",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 10.2,
     "Column11": "601+",
     "Column12": 6.8,
     "Column13": "601+",
     "Column14": 14.1,
     "Column15": "701+",
     "Column16": 13.5,
     "Column17": 659,
     "Column18": 49.8,
     "Column19": 367,
     "Column20": 25.8,
     "Column21": 469,
     "Column22": 45,
     "Column23": "701+",
     "Column24": 4.5,
     "Column25": "701+",
     "Column26": 57.7,
     "Column27": "276=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "771-780",
     "Column2": "801-850",
     "Column3": "University of Pecs",
     "Column4": "HU",
     "Column5": "Hungary",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 14.3,
     "Column11": "601+",
     "Column12": 11.9,
     "Column13": "601+",
     "Column14": 41.4,
     "Column15": 377,
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 9.3,
     "Column19": "701+",
     "Column20": 64.3,
     "Column21": 233,
     "Column22": 40.3,
     "Column23": "701+",
     "Column24": 4,
     "Column25": "701+",
     "Column26": 11.3,
     "Column27": "636=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "771-780",
     "Column2": "801-850",
     "Column3": "Verona University",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.1,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 4.1,
     "Column15": "701+",
     "Column16": 41.6,
     "Column17": 333,
     "Column18": 4.5,
     "Column19": "701+",
     "Column20": 4.2,
     "Column21": "701+",
     "Column22": 71,
     "Column23": 468,
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 22.5,
     "Column27": "510=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "781-790",
     "Column2": "801-850",
     "Column3": "Babes-Bolyai University",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.5,
     "Column11": 569,
     "Column12": 19.5,
     "Column13": 463,
     "Column14": 5.3,
     "Column15": "701+",
     "Column16": 4.4,
     "Column17": "701+",
     "Column18": 7.8,
     "Column19": "701+",
     "Column20": 3.8,
     "Column21": "701+",
     "Column22": 80.1,
     "Column23": 321,
     "Column24": 26.7,
     "Column25": 445,
     "Column26": 42.7,
     "Column27": 354,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "781-790",
     "Column2": "751-760",
     "Column3": "Kasetsart University",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 25.2,
     "Column11": 350,
     "Column12": 18.6,
     "Column13": 475,
     "Column14": 6.5,
     "Column15": "701+",
     "Column16": 2.5,
     "Column17": "701+",
     "Column18": 4.3,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 51.9,
     "Column23": "701+",
     "Column24": 18.5,
     "Column25": 593,
     "Column26": 33.9,
     "Column27": 415,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "781-790",
     "Column3": "National University of Uzbekistan",
     "Column4": "UZ",
     "Column5": "Uzbekistan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 25.5,
     "Column11": 347,
     "Column12": 15.8,
     "Column13": 540,
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 89,
     "Column19": 197,
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 11.8,
     "Column23": "701+",
     "Column24": 20.4,
     "Column25": 559,
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "781-790",
     "Column2": "901-950",
     "Column3": "Qassim University",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 10.6,
     "Column11": "601+",
     "Column12": 5,
     "Column13": "601+",
     "Column14": 33.9,
     "Column15": 465,
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 98.3,
     "Column19": 117,
     "Column20": 7.9,
     "Column21": "701+",
     "Column22": 68.3,
     "Column23": 511,
     "Column24": 2.4,
     "Column25": "701+",
     "Column26": 9.3,
     "Column27": "683=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "781-790",
     "Column2": "801-850",
     "Column3": "Texas Tech University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 10.9,
     "Column11": "601+",
     "Column12": 10.6,
     "Column13": "601+",
     "Column14": 6.2,
     "Column15": "701+",
     "Column16": 11.3,
     "Column17": "701+",
     "Column18": 36.6,
     "Column19": 436,
     "Column20": 9.6,
     "Column21": "701+",
     "Column22": 70.5,
     "Column23": 478,
     "Column24": 13.7,
     "Column25": "701+",
     "Column26": 55.4,
     "Column27": "285=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "781-790",
     "Column2": "641-650",
     "Column3": "The New School",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 15.2,
     "Column11": 580,
     "Column12": 8.2,
     "Column13": "601+",
     "Column14": 41.1,
     "Column15": 381,
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 5.7,
     "Column19": "701+",
     "Column20": 92.3,
     "Column21": 106,
     "Column22": 19.6,
     "Column23": "701+",
     "Column24": 13.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "781-790",
     "Column2": "761-770",
     "Column3": "Universidad de Belgrano",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "LO",
     "Column9": "B",
     "Column10": 9.4,
     "Column11": "601+",
     "Column12": 12.7,
     "Column13": "601+",
     "Column14": 86.6,
     "Column15": 100,
     "Column16": 1,
     "Column17": "701+",
     "Column18": 5.4,
     "Column19": "701+",
     "Column20": 24.1,
     "Column21": 490,
     "Column22": 2.5,
     "Column23": "701+",
     "Column24": 37.4,
     "Column25": 343,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "781-790",
     "Column2": "801-850",
     "Column3": "Universidade Federal de Santa Catarina ",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.3,
     "Column11": 465,
     "Column12": 4.8,
     "Column13": "601+",
     "Column14": 19.2,
     "Column15": 693,
     "Column16": 8.5,
     "Column17": "701+",
     "Column18": 4.1,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 62.1,
     "Column23": 604,
     "Column24": 17.3,
     "Column25": 614,
     "Column26": 52.1,
     "Column27": "305=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "781-790",
     "Column2": "801-850",
     "Column3": "University of Latvia",
     "Column4": "LV",
     "Column5": "Latvia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 11.5,
     "Column11": "601+",
     "Column12": 16.7,
     "Column13": 515,
     "Column14": 5.9,
     "Column15": "701+",
     "Column16": 5.3,
     "Column17": "701+",
     "Column18": 12.1,
     "Column19": "701+",
     "Column20": 5.1,
     "Column21": "701+",
     "Column22": 71.5,
     "Column23": 460,
     "Column24": 90.4,
     "Column25": 75,
     "Column26": 9.8,
     "Column27": "672=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "781-790",
     "Column2": "691-700",
     "Column3": "University of Mons ",
     "Column4": "BE",
     "Column5": "Belgium",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 7,
     "Column13": "601+",
     "Column14": 3.4,
     "Column15": "701+",
     "Column16": 37.3,
     "Column17": 369,
     "Column18": 35,
     "Column19": 458,
     "Column20": 33.7,
     "Column21": 400,
     "Column22": 43.7,
     "Column23": "701+",
     "Column24": 6.1,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "781-790",
     "Column2": "1401+",
     "Column3": "Zurich University of Applied Sciences (ZHAW)",
     "Column4": "CH",
     "Column5": "Switzerland",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 6.6,
     "Column13": "601+",
     "Column14": 75,
     "Column15": 166,
     "Column16": 2.7,
     "Column17": "701+",
     "Column18": 65.9,
     "Column19": 297,
     "Column20": 12.8,
     "Column21": 664,
     "Column22": 41.4,
     "Column23": "701+",
     "Column24": 5.3,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "791-800",
     "Column2": "731-740",
     "Column3": "Alfaisal University",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 4.8,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 21.6,
     "Column15": 644,
     "Column16": 10.6,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 30,
     "Column20": 89,
     "Column21": 128,
     "Column22": 19.1,
     "Column23": "701+",
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "791-800",
     "Column2": "801-850",
     "Column3": "Ivane Javakhishvili Tbilisi State University",
     "Column4": "GE",
     "Column5": "Georgia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 10.4,
     "Column11": "601+",
     "Column12": 1.2,
     "Column13": "601+",
     "Column14": 73.9,
     "Column15": 173,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 1.9,
     "Column19": "701+",
     "Column20": 3.2,
     "Column21": "701+",
     "Column22": 22.7,
     "Column23": "701+",
     "Column24": 88.4,
     "Column25": 86,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "791-800",
     "Column2": "781-790",
     "Column3": "Keele University ",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 14.4,
     "Column15": "701+",
     "Column16": 26.2,
     "Column17": 484,
     "Column18": 51.6,
     "Column19": 359,
     "Column20": 30.7,
     "Column21": 423,
     "Column22": 62.9,
     "Column23": 586,
     "Column24": 6.9,
     "Column25": "701+",
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "791-800",
     "Column2": "641-650",
     "Column3": "New Jersey Institute of Technology (NJIT)",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.8,
     "Column11": "601+",
     "Column12": 6.7,
     "Column13": "601+",
     "Column14": 10.2,
     "Column15": "701+",
     "Column16": 30.4,
     "Column17": 433,
     "Column18": 31.5,
     "Column19": 487,
     "Column20": 52.9,
     "Column21": 278,
     "Column22": 29.1,
     "Column23": "701+",
     "Column24": 5.1,
     "Column25": "701+",
     "Column26": 26.5,
     "Column27": "476=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "791-800",
     "Column2": "771-780",
     "Column3": "Sechenov University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.3,
     "Column11": "601+",
     "Column12": 5,
     "Column13": "601+",
     "Column14": 57.4,
     "Column15": 256,
     "Column16": 4.3,
     "Column17": "701+",
     "Column18": 9,
     "Column19": "701+",
     "Column20": 63,
     "Column21": 241,
     "Column22": 83.6,
     "Column23": 272,
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "791-800",
     "Column2": "701-710",
     "Column3": "Universidad de Antioquia",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 29.1,
     "Column11": 303,
     "Column12": 20.8,
     "Column13": 440,
     "Column14": 4.1,
     "Column15": "701+",
     "Column16": 4.3,
     "Column17": "701+",
     "Column18": 3.7,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 48.1,
     "Column23": "701+",
     "Column24": 20.4,
     "Column25": 556,
     "Column26": 2.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "791-800",
     "Column2": "681-690",
     "Column3": "University of Kentucky",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.1,
     "Column11": "601+",
     "Column12": 9.8,
     "Column13": "601+",
     "Column14": 15.3,
     "Column15": "701+",
     "Column16": 23.5,
     "Column17": 516,
     "Column18": 12.9,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 75.4,
     "Column23": 400,
     "Column24": 13.9,
     "Column25": 695,
     "Column26": 10.1,
     "Column27": "661=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "791-800",
     "Column2": "631-640",
     "Column3": "University of New Mexico",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.5,
     "Column11": "601+",
     "Column12": 8,
     "Column13": "601+",
     "Column14": 43.5,
     "Column15": 360,
     "Column16": 14.9,
     "Column17": 637,
     "Column18": 8.2,
     "Column19": "701+",
     "Column20": 4,
     "Column21": "701+",
     "Column22": 73.1,
     "Column23": 436,
     "Column24": 7.3,
     "Column25": "701+",
     "Column26": 11,
     "Column27": "644=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "791-800",
     "Column2": "851-900",
     "Column3": "Vellore Institute of Technology (VIT)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 12.7,
     "Column11": "601+",
     "Column12": 16.1,
     "Column13": 527,
     "Column14": 7.3,
     "Column15": "701+",
     "Column16": 18.9,
     "Column17": 570,
     "Column18": 8.9,
     "Column19": "701+",
     "Column20": 2.9,
     "Column21": "701+",
     "Column22": 76.9,
     "Column23": 379,
     "Column24": 6.1,
     "Column25": "701+",
     "Column26": 29.6,
     "Column27": "447=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "791-800",
     "Column2": "751-760",
     "Column3": "Wayne State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.6,
     "Column11": "601+",
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 62.1,
     "Column15": 229,
     "Column16": 10.6,
     "Column17": "701+",
     "Column18": 13.3,
     "Column19": 700,
     "Column20": 5.4,
     "Column21": "701+",
     "Column22": 73.6,
     "Column23": 425,
     "Column24": 19.4,
     "Column25": 572,
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "Al-Ahliyya Amman University",
     "Column4": "JO",
     "Column5": "Jordan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 9.6,
     "Column11": "601+",
     "Column12": 10.1,
     "Column13": "601+",
     "Column14": 9.4,
     "Column15": "701+",
     "Column16": 2.7,
     "Column17": "701+",
     "Column18": 75.5,
     "Column19": 260,
     "Column20": 100,
     "Column21": 28,
     "Column22": 16.9,
     "Column23": "701+",
     "Column24": 4.6,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "901-950",
     "Column3": "Alexandria University ",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 23.1,
     "Column11": 385,
     "Column12": 18.3,
     "Column13": 482,
     "Column14": 4.5,
     "Column15": "701+",
     "Column16": 2,
     "Column17": "701+",
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 4.8,
     "Column21": "701+",
     "Column22": 76.4,
     "Column23": 386,
     "Column24": 24.3,
     "Column25": 467,
     "Column26": 6.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "901-950",
     "Column3": "Ankara Üniversitesi",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 20.2,
     "Column11": 439,
     "Column12": 16.8,
     "Column13": 511,
     "Column14": 7.8,
     "Column15": "701+",
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 2.3,
     "Column19": "701+",
     "Column20": 4.6,
     "Column21": "701+",
     "Column22": 70.6,
     "Column23": 477,
     "Column24": 53.5,
     "Column25": 234,
     "Column26": 2.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "901-950",
     "Column3": "Asia University Taiwan",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 5.9,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 3.6,
     "Column15": "701+",
     "Column16": 52.5,
     "Column17": 247,
     "Column18": 9.9,
     "Column19": "701+",
     "Column20": 3.2,
     "Column21": "701+",
     "Column22": 57.1,
     "Column23": 661,
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "901-950",
     "Column3": "Donghua University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 1.6,
     "Column13": "601+",
     "Column14": 25.5,
     "Column15": 582,
     "Column16": 47.3,
     "Column17": 286,
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 3.1,
     "Column21": "701+",
     "Column22": 31.1,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "Belarusian National Technical University (BNTU)",
     "Column4": "BY",
     "Column5": "Belarus",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 14.8,
     "Column11": 588,
     "Column12": 26.7,
     "Column13": 359,
     "Column14": 51,
     "Column15": 302,
     "Column16": 1,
     "Column17": "701+",
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 34.1,
     "Column21": 398,
     "Column22": 5.1,
     "Column23": "701+",
     "Column24": 5.6,
     "Column25": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "951-1000",
     "Column3": "Birla Institute of Technology and Science, Pilani",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 11,
     "Column11": "601+",
     "Column12": 19.7,
     "Column13": 459,
     "Column14": 8,
     "Column15": "701+",
     "Column16": 20.4,
     "Column17": 552,
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 37.6,
     "Column23": "701+",
     "Column24": 25.4,
     "Column25": 461,
     "Column26": 30.4,
     "Column27": 442,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "Edinburgh Napier University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 5,
     "Column13": "601+",
     "Column14": 4,
     "Column15": "701+",
     "Column16": 11.8,
     "Column17": 698,
     "Column18": 77.5,
     "Column19": 248,
     "Column20": 86,
     "Column21": 146,
     "Column22": 60.1,
     "Column23": 633,
     "Column24": 4.9,
     "Column25": "701+",
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "851-900",
     "Column3": "Gda?sk University of Technology",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.4,
     "Column11": "601+",
     "Column12": 15.2,
     "Column13": 553,
     "Column14": 29.6,
     "Column15": 527,
     "Column16": 12.7,
     "Column17": 676,
     "Column18": 5.3,
     "Column19": "701+",
     "Column20": 4,
     "Column21": "701+",
     "Column22": 65.8,
     "Column23": 546,
     "Column24": 3.2,
     "Column25": "701+",
     "Column26": 33.3,
     "Column27": "418=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "851-900",
     "Column3": "Wuhan University of Technology",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 11.5,
     "Column15": "701+",
     "Column16": 47,
     "Column17": 290,
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 51,
     "Column23": "701+",
     "Column24": 5.8,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "Immanuel Kant Baltic Federal University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.6,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 79.4,
     "Column15": 142,
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 21.8,
     "Column19": 572,
     "Column20": 40.2,
     "Column21": 353,
     "Column22": 35.2,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "741-750",
     "Column3": "Instituto Politécnico Nacional (IPN)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 19.1,
     "Column11": 471,
     "Column12": 28.8,
     "Column13": 328,
     "Column14": 9.1,
     "Column15": "701+",
     "Column16": 2,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 46,
     "Column23": "701+",
     "Column24": 6.8,
     "Column25": "701+",
     "Column26": 35.7,
     "Column27": "398=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "Jouf University",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 11.7,
     "Column11": "601+",
     "Column12": 5.1,
     "Column13": "601+",
     "Column14": 37.1,
     "Column15": 433,
     "Column16": 2.3,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 32,
     "Column20": 2.6,
     "Column21": "701+",
     "Column22": 43.5,
     "Column23": "701+",
     "Column24": 2.4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "781-790",
     "Column3": "Khoja Akhmet Yassawi International Kazakh-Turkish University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 16.7,
     "Column11": 537,
     "Column12": 27.9,
     "Column13": 343,
     "Column14": 44.6,
     "Column15": 347,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 7.1,
     "Column19": "701+",
     "Column20": 16.3,
     "Column21": 599,
     "Column22": 3.2,
     "Column23": "701+",
     "Column24": 7.7,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "851-900",
     "Column3": "Kuwait University",
     "Column4": "KW",
     "Column5": "Kuwait",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 14.7,
     "Column11": 593,
     "Column12": 7.9,
     "Column13": "601+",
     "Column14": 6.8,
     "Column15": "701+",
     "Column16": 2.9,
     "Column17": "701+",
     "Column18": 20.4,
     "Column19": 591,
     "Column20": 19.4,
     "Column21": 558,
     "Column22": 39.3,
     "Column23": "701+",
     "Column24": 93.8,
     "Column25": 58,
     "Column26": 17.1,
     "Column27": 562,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "751-760",
     "Column3": "Liverpool John Moores University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10,
     "Column11": "601+",
     "Column12": 6.8,
     "Column13": "601+",
     "Column14": 8,
     "Column15": "701+",
     "Column16": 12.3,
     "Column17": 683,
     "Column18": 46.7,
     "Column19": 384,
     "Column20": 22.4,
     "Column21": 513,
     "Column22": 87.7,
     "Column23": 201,
     "Column24": 5.4,
     "Column25": "701+",
     "Column26": 13.6,
     "Column27": 604,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "851-900",
     "Column3": "London South Bank University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.3,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 9.1,
     "Column15": "701+",
     "Column16": 4.4,
     "Column17": "701+",
     "Column18": 87.1,
     "Column19": 211,
     "Column20": 96.8,
     "Column21": 81,
     "Column22": 47.6,
     "Column23": "701+",
     "Column24": 8.5,
     "Column25": "701+",
     "Column26": 15.4,
     "Column27": "585=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "Maynooth University",
     "Column4": "IE",
     "Column5": "Ireland",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.4,
     "Column11": "601+",
     "Column12": 7.7,
     "Column13": "601+",
     "Column14": 9.2,
     "Column15": "701+",
     "Column16": 12.7,
     "Column17": 673,
     "Column18": 87.4,
     "Column19": 207,
     "Column20": 16.7,
     "Column21": 591,
     "Column22": 48.5,
     "Column23": "701+",
     "Column24": 9.2,
     "Column25": "701+",
     "Column26": 6.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "731-740",
     "Column3": "National Technical University of Ukraine \"Igor Sikorsky Kyiv Polytechnic Institute\"",
     "Column4": "UA",
     "Column5": "Ukraine",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 16.2,
     "Column11": 549,
     "Column12": 31.8,
     "Column13": 297,
     "Column14": 37.4,
     "Column15": 429,
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 1,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 20.4,
     "Column23": "701+",
     "Column24": 21.3,
     "Column25": 539,
     "Column26": 4.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "721-730",
     "Column2": "791-800",
     "Column3": "Lanzhou University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.1,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 25.2,
     "Column15": 589,
     "Column16": 45.2,
     "Column17": 306,
     "Column18": 2.3,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 64,
     "Column23": 575,
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 7.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "761-770",
     "Column3": "Philipps-Universität Marburg ",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 12.7,
     "Column11": "601+",
     "Column12": 8.8,
     "Column13": "601+",
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 25,
     "Column17": 498,
     "Column18": 14.9,
     "Column19": 666,
     "Column20": 20.7,
     "Column21": 529,
     "Column22": 61.6,
     "Column23": 607,
     "Column24": 4.8,
     "Column25": "701+",
     "Column26": 5.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "681-690",
     "Column3": "Pontificia Universidad Católica de Valparaíso",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 27,
     "Column11": 325,
     "Column12": 24.4,
     "Column13": 395,
     "Column14": 4.7,
     "Column15": "701+",
     "Column16": 6.6,
     "Column17": "701+",
     "Column18": 11.3,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 39.9,
     "Column23": "701+",
     "Column24": 8.7,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "771-780",
     "Column3": "Rutgers University–Newark",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.1,
     "Column11": "601+",
     "Column12": 4.9,
     "Column13": "601+",
     "Column14": 17.5,
     "Column15": "701+",
     "Column16": 17.6,
     "Column17": 587,
     "Column18": 25.3,
     "Column19": 529,
     "Column20": 32.7,
     "Column21": 408,
     "Column22": 27.9,
     "Column23": "701+",
     "Column24": 36.9,
     "Column25": 346,
     "Column26": 29.3,
     "Column27": "451=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "781-790",
     "Column3": "Syracuse University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 12.9,
     "Column11": "601+",
     "Column12": 8.7,
     "Column13": "601+",
     "Column14": 11.6,
     "Column15": "701+",
     "Column16": 10.6,
     "Column17": "701+",
     "Column18": 5.8,
     "Column19": "701+",
     "Column20": 39.3,
     "Column21": 357,
     "Column22": 55.5,
     "Column23": 684,
     "Column24": 42.7,
     "Column25": 301,
     "Column26": 11.6,
     "Column27": "629=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "781-790",
     "Column3": "The Catholic University of Korea ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 98.6,
     "Column15": 42,
     "Column16": 14.1,
     "Column17": 652,
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 22.5,
     "Column21": 511,
     "Column22": 10.5,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 6.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "851-900",
     "Column3": "Toronto Metropolitan University",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8,
     "Column11": "601+",
     "Column12": 12,
     "Column13": "601+",
     "Column14": 1.8,
     "Column15": "701+",
     "Column16": 26.4,
     "Column17": 481,
     "Column18": 35.4,
     "Column19": 453,
     "Column20": 8.1,
     "Column21": "701+",
     "Column22": 57,
     "Column23": 662,
     "Column24": 15.1,
     "Column25": 660,
     "Column26": 3.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "851-900",
     "Column3": "TU Dortmund University",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.1,
     "Column11": "601+",
     "Column12": 16,
     "Column13": 532,
     "Column14": 28.1,
     "Column15": 548,
     "Column16": 6.2,
     "Column17": "701+",
     "Column18": 22.4,
     "Column19": 564,
     "Column20": 17.1,
     "Column21": 584,
     "Column22": 61.1,
     "Column23": 616,
     "Column24": 6.8,
     "Column25": "701+",
     "Column26": 3.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "771-780",
     "Column3": "Universidad Anáhuac México",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 13.1,
     "Column11": "601+",
     "Column12": 20.4,
     "Column13": 446,
     "Column14": 50,
     "Column15": 308,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 5.3,
     "Column19": "701+",
     "Column20": 3,
     "Column21": "701+",
     "Column22": 5.8,
     "Column23": "701+",
     "Column24": 61.8,
     "Column25": 194,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "691-700",
     "Column3": "Universidad Iberoamericana IBERO",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 18.5,
     "Column11": 487,
     "Column12": 24.3,
     "Column13": 398,
     "Column14": 32.3,
     "Column15": 488,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 5.4,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 5.2,
     "Column23": "701+",
     "Column24": 63.6,
     "Column25": 185,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "Universidad San Francisco de Quito (USFQ)",
     "Column4": "EC",
     "Column5": "Ecuador",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 16,
     "Column11": 554,
     "Column12": 8.6,
     "Column13": "601+",
     "Column14": 40.1,
     "Column15": 390,
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 53.1,
     "Column19": 352,
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 24.9,
     "Column23": "701+",
     "Column24": 13.2,
     "Column25": "701+",
     "Column26": 21.5,
     "Column27": "518=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "661-670",
     "Column3": "Università degli Studi di Perugia",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.1,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 3.5,
     "Column15": "701+",
     "Column16": 40.9,
     "Column17": 341,
     "Column18": 6.4,
     "Column19": "701+",
     "Column20": 5.4,
     "Column21": "701+",
     "Column22": 65.9,
     "Column23": 542,
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 11.5,
     "Column27": "632=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "Universitas Brawijaya ",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 19.2,
     "Column11": 469,
     "Column12": 42.4,
     "Column13": 219,
     "Column14": 11.5,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 13.7,
     "Column19": 692,
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 19.6,
     "Column23": "701+",
     "Column24": 13.1,
     "Column25": "701+",
     "Column26": 9,
     "Column27": 690,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "771-780",
     "Column3": "Universität Duisburg-Essen",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.3,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 13.2,
     "Column15": "701+",
     "Column16": 11.2,
     "Column17": "701+",
     "Column18": 35,
     "Column19": 457,
     "Column20": 39.4,
     "Column21": 355,
     "Column22": 81.4,
     "Column23": 298,
     "Column24": 12.2,
     "Column25": "701+",
     "Column26": 5.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "761-770",
     "Column3": "Universität Rostock",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.3,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 84.4,
     "Column15": 110,
     "Column16": 5.2,
     "Column17": "701+",
     "Column20": 13.2,
     "Column21": 657,
     "Column22": 68.8,
     "Column23": 501,
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 3.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "721-730",
     "Column3": "Université de Lorraine",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.6,
     "Column11": "601+",
     "Column12": 3.3,
     "Column13": "601+",
     "Column14": 7,
     "Column15": "701+",
     "Column16": 12.9,
     "Column17": 668,
     "Column18": 27.6,
     "Column19": 512,
     "Column20": 27.4,
     "Column21": 453,
     "Column22": 95.9,
     "Column23": 59,
     "Column24": 14.8,
     "Column25": 669,
     "Column26": 11.8,
     "Column27": "623=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "Universiti Tunku Abdul Rahman (UTAR)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 14,
     "Column11": "601+",
     "Column12": 30.9,
     "Column13": 304,
     "Column14": 12.4,
     "Column15": "701+",
     "Column16": 5.8,
     "Column17": "701+",
     "Column18": 12.7,
     "Column19": "701+",
     "Column20": 4.9,
     "Column21": "701+",
     "Column22": 45.5,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 26.5,
     "Column27": "476=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "781-790",
     "Column3": "University of Alaska Fairbanks",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 2.7,
     "Column11": "601+",
     "Column12": 2,
     "Column13": "601+",
     "Column14": 98.8,
     "Column15": 41,
     "Column16": 12.9,
     "Column17": 670,
     "Column18": 4.8,
     "Column19": "701+",
     "Column20": 3,
     "Column21": "701+",
     "Column22": 42.6,
     "Column23": "701+",
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 2.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "University of Alicante",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.5,
     "Column11": "601+",
     "Column12": 6.7,
     "Column13": "601+",
     "Column14": 25.3,
     "Column15": 588,
     "Column16": 7.7,
     "Column17": "701+",
     "Column18": 7.3,
     "Column19": "701+",
     "Column20": 12.8,
     "Column21": 662,
     "Column22": 71.7,
     "Column23": 454,
     "Column24": 7.6,
     "Column25": "701+",
     "Column26": 31.3,
     "Column27": "435=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "951-1000",
     "Column3": "University of Baghdad",
     "Column4": "IQ",
     "Column5": "Iraq",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11,
     "Column11": "601+",
     "Column12": 16.9,
     "Column13": 509,
     "Column14": 24.6,
     "Column15": 598,
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 63.5,
     "Column23": 580,
     "Column24": 72.7,
     "Column25": 148,
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "741-750",
     "Column3": "University of Bari",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.4,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 25,
     "Column17": 497,
     "Column18": 4.9,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 81.6,
     "Column23": 292,
     "Column24": 7.7,
     "Column25": "701+",
     "Column26": 25.7,
     "Column27": "483=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "771-780",
     "Column3": "University of Brighton",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.9,
     "Column11": "601+",
     "Column12": 6.1,
     "Column13": "601+",
     "Column14": 8.5,
     "Column15": "701+",
     "Column16": 14.9,
     "Column17": 639,
     "Column18": 54.8,
     "Column19": 340,
     "Column20": 51.1,
     "Column21": 288,
     "Column22": 72.5,
     "Column23": 446,
     "Column24": 6.1,
     "Column25": "701+",
     "Column26": 6.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "851-900",
     "Column3": "University of Bucharest",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 15.2,
     "Column11": 577,
     "Column12": 19.5,
     "Column13": 462,
     "Column14": 7.1,
     "Column15": "701+",
     "Column16": 4.2,
     "Column17": "701+",
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 69.1,
     "Column23": 498,
     "Column24": 53.4,
     "Column25": 235,
     "Column26": 25.7,
     "Column27": "483=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "University of Hyderabad",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.2,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 14.5,
     "Column15": "701+",
     "Column16": 41.5,
     "Column17": 335,
     "Column18": 1.9,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 27,
     "Column23": "701+",
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "721-730",
     "Column3": "University of Oregon",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.3,
     "Column11": 496,
     "Column12": 9.4,
     "Column13": "601+",
     "Column14": 9.4,
     "Column15": "701+",
     "Column16": 18.5,
     "Column17": 573,
     "Column18": 7.6,
     "Column19": "701+",
     "Column20": 3.6,
     "Column21": "701+",
     "Column22": 53.4,
     "Column23": "701+",
     "Column24": 23.3,
     "Column25": 485,
     "Column26": 16.1,
     "Column27": "577=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "901-950",
     "Column3": "University of Petroleum and Energy Studies (UPES)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 21.8,
     "Column11": 405,
     "Column12": 6.1,
     "Column13": "601+",
     "Column14": 39.5,
     "Column15": 391,
     "Column16": 3.4,
     "Column17": "701+",
     "Column18": 18.2,
     "Column19": 622,
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 56.9,
     "Column23": 666,
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "University of Salerno",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.6,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 5.6,
     "Column15": "701+",
     "Column16": 37.1,
     "Column17": 370,
     "Column18": 3.3,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 87.4,
     "Column23": 205,
     "Column24": 4,
     "Column25": "701+",
     "Column26": 19.2,
     "Column27": "545=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "1401+",
     "Column3": "University of Stavanger",
     "Column4": "NO",
     "Column5": "Norway",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 5.8,
     "Column13": "601+",
     "Column14": 84.6,
     "Column15": 108,
     "Column16": 5,
     "Column17": "701+",
     "Column22": 69.6,
     "Column23": 489,
     "Column24": 10,
     "Column25": "701+",
     "Column26": 5.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "751-760",
     "Column3": "University of Ulsan ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 60,
     "Column15": 241,
     "Column16": 33.6,
     "Column17": 392,
     "Column18": 2,
     "Column19": "701+",
     "Column20": 2.4,
     "Column21": "701+",
     "Column22": 28.8,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 5.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "951-1000",
     "Column3": "Northwest Agriculture and Forestry University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 1.4,
     "Column13": "601+",
     "Column14": 28.5,
     "Column15": 544,
     "Column16": 36.8,
     "Column17": 371,
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 58.8,
     "Column23": 645,
     "Column24": 3,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "901-950",
     "Column3": "AGH University of Science and Technology",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.6,
     "Column11": "601+",
     "Column12": 13.9,
     "Column13": 587,
     "Column14": 41.5,
     "Column15": 376,
     "Column16": 6.5,
     "Column17": "701+",
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 5.3,
     "Column21": "701+",
     "Column22": 75.6,
     "Column23": 395,
     "Column24": 3.2,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "1001-1200",
     "Column3": "Al-Quds University",
     "Column4": "PS",
     "Column5": "Palestinian Territory, Occupied",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 14.6,
     "Column13": 568,
     "Column14": 12.7,
     "Column15": "701+",
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 61.5,
     "Column19": 318,
     "Column20": 66.8,
     "Column21": 222,
     "Column22": 30.6,
     "Column23": "701+",
     "Column24": 23.5,
     "Column25": 482,
     "Column26": 3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column3": "Applied Science Private University - Jordan",
     "Column4": "JO",
     "Column5": "Jordan",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 9.9,
     "Column11": "601+",
     "Column12": 15.7,
     "Column13": 542,
     "Column14": 28.6,
     "Column15": 541,
     "Column16": 3.1,
     "Column17": "701+",
     "Column18": 39.3,
     "Column19": 422,
     "Column20": 59.7,
     "Column21": 248,
     "Column22": 10.4,
     "Column23": "701+",
     "Column24": 4.6,
     "Column25": "701+",
     "Column26": 1.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "801-850",
     "Column3": "Charles Sturt University ",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.1,
     "Column11": "601+",
     "Column12": 5.4,
     "Column13": "601+",
     "Column14": 8.2,
     "Column15": "701+",
     "Column16": 8.1,
     "Column17": "701+",
     "Column18": 45.4,
     "Column19": 395,
     "Column20": 47.9,
     "Column21": 303,
     "Column22": 53.4,
     "Column23": "701+",
     "Column24": 7.4,
     "Column25": "701+",
     "Column26": 41.7,
     "Column27": 357,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "Chonnam National University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.3,
     "Column11": "601+",
     "Column12": 4.7,
     "Column13": "601+",
     "Column14": 45.4,
     "Column15": 341,
     "Column16": 12.4,
     "Column17": 682,
     "Column18": 9,
     "Column19": "701+",
     "Column20": 7.3,
     "Column21": "701+",
     "Column22": 28.5,
     "Column23": "701+",
     "Column24": 4.7,
     "Column25": "701+",
     "Column26": 44.3,
     "Column27": "348=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "Chungnam National University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.8,
     "Column11": "601+",
     "Column12": 7.8,
     "Column13": "601+",
     "Column14": 46.3,
     "Column15": 334,
     "Column16": 10.7,
     "Column17": "701+",
     "Column18": 7.2,
     "Column19": "701+",
     "Column20": 9.2,
     "Column21": "701+",
     "Column22": 32.7,
     "Column23": "701+",
     "Column24": 5,
     "Column25": "701+",
     "Column26": 25.7,
     "Column27": "483=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "791-800",
     "Column3": "Clarkson University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 1.3,
     "Column11": "601+",
     "Column12": 1.9,
     "Column13": "601+",
     "Column14": 13.5,
     "Column15": "701+",
     "Column16": 41.8,
     "Column17": 329,
     "Column18": 48.5,
     "Column19": 374,
     "Column20": 15,
     "Column21": 623,
     "Column22": 30.6,
     "Column23": "701+",
     "Column24": 7.9,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "CY Cergy Paris University",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.7,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 49.5,
     "Column15": 317,
     "Column16": 5,
     "Column17": "701+",
     "Column18": 47.2,
     "Column19": 378,
     "Column20": 45.9,
     "Column21": 320,
     "Column22": 46,
     "Column23": "701+",
     "Column24": 1.5,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "801-850",
     "Column2": "801-850",
     "Column3": "Beijing University of Technology",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.7,
     "Column11": "601+",
     "Column12": 4.9,
     "Column13": "601+",
     "Column14": 38.2,
     "Column15": 417,
     "Column16": 33.1,
     "Column17": 399,
     "Column18": 4.7,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 31.9,
     "Column23": "701+",
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "Georgia State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.3,
     "Column11": "601+",
     "Column12": 14.2,
     "Column13": 580,
     "Column14": 4.3,
     "Column15": "701+",
     "Column16": 22.4,
     "Column17": 526,
     "Column18": 4.8,
     "Column19": "701+",
     "Column20": 10.4,
     "Column21": "701+",
     "Column22": 62.3,
     "Column23": 600,
     "Column24": 18.2,
     "Column25": 599,
     "Column26": 7.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "Gulf University for Science and Technology",
     "Column4": "KW",
     "Column5": "Kuwait",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 14.6,
     "Column11": 597,
     "Column12": 9.3,
     "Column13": "601+",
     "Column14": 11.2,
     "Column15": "701+",
     "Column16": 4.8,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 18,
     "Column20": 27.7,
     "Column21": 448,
     "Column22": 9.3,
     "Column23": "701+",
     "Column24": 11,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "951-1000",
     "Column3": "Jamia Millia Islamia",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.3,
     "Column11": "601+",
     "Column12": 3.1,
     "Column13": "601+",
     "Column14": 33.6,
     "Column15": 470,
     "Column16": 26,
     "Column17": 486,
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 5.2,
     "Column21": "701+",
     "Column22": 43,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "Kansas State University ",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.2,
     "Column11": "601+",
     "Column12": 6.7,
     "Column13": "601+",
     "Column14": 10.3,
     "Column15": "701+",
     "Column16": 22.1,
     "Column17": 531,
     "Column18": 10.1,
     "Column19": "701+",
     "Column20": 4.6,
     "Column21": "701+",
     "Column22": 74.9,
     "Column23": 411,
     "Column24": 13.5,
     "Column25": "701+",
     "Column26": 10.2,
     "Column27": 660,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "801-850",
     "Column3": "Karaganda State Technical University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9.7,
     "Column11": "601+",
     "Column12": 7.7,
     "Column13": "601+",
     "Column14": 84.6,
     "Column15": 109,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 10,
     "Column19": "701+",
     "Column20": 18.8,
     "Column21": 572,
     "Column22": 2.4,
     "Column23": "701+",
     "Column24": 5.8,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column3": "Kyrgyz Russian Slavic University",
     "Column4": "KG",
     "Column5": "Kyrgyzstan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 9.3,
     "Column11": "601+",
     "Column12": 7,
     "Column13": "601+",
     "Column14": 99.8,
     "Column15": 26,
     "Column16": 1,
     "Column17": "701+",
     "Column22": 3.1,
     "Column23": "701+",
     "Column24": 24.1,
     "Column25": 470,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "901-950",
     "Column3": "Mendel University in Brno",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.2,
     "Column11": "601+",
     "Column12": 6.5,
     "Column13": "601+",
     "Column14": 55.1,
     "Column15": 276,
     "Column16": 4.1,
     "Column17": "701+",
     "Column18": 11.2,
     "Column19": "701+",
     "Column20": 32.1,
     "Column21": 413,
     "Column22": 59.1,
     "Column23": 643,
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "801-850",
     "Column3": "Nagasaki University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 81,
     "Column15": 127,
     "Column16": 6.3,
     "Column17": "701+",
     "Column18": 5.8,
     "Column19": "701+",
     "Column20": 4.7,
     "Column21": "701+",
     "Column22": 49.2,
     "Column23": "701+",
     "Column24": 3,
     "Column25": "701+",
     "Column26": 2.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "901-950",
     "Column3": "Beijing Jiaotong University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.7,
     "Column11": "601+",
     "Column12": 5.4,
     "Column13": "601+",
     "Column14": 15.2,
     "Column15": "701+",
     "Column16": 31.8,
     "Column17": 414,
     "Column18": 4.7,
     "Column19": "701+",
     "Column20": 9.6,
     "Column21": "701+",
     "Column22": 29.1,
     "Column23": "701+",
     "Column24": 9.6,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "801-850",
     "Column3": "North-West University",
     "Column4": "ZA",
     "Column5": "South Africa",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.4,
     "Column11": "601+",
     "Column12": 11,
     "Column13": "601+",
     "Column14": 4.9,
     "Column15": "701+",
     "Column16": 14.9,
     "Column17": 638,
     "Column18": 6.6,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 92.9,
     "Column23": 122,
     "Column24": 16.7,
     "Column25": 619,
     "Column26": 28.4,
     "Column27": "456=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "901-950",
     "Column3": "Okayama University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.9,
     "Column11": "601+",
     "Column12": 3.7,
     "Column13": "601+",
     "Column14": 61.3,
     "Column15": 233,
     "Column16": 7.7,
     "Column17": "701+",
     "Column18": 6.1,
     "Column19": "701+",
     "Column20": 6.3,
     "Column21": "701+",
     "Column22": 52.5,
     "Column23": "701+",
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 14.5,
     "Column27": "600=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "Oklahoma State University ",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.3,
     "Column11": "601+",
     "Column12": 8.1,
     "Column13": "601+",
     "Column14": 9.2,
     "Column15": "701+",
     "Column16": 13.3,
     "Column17": 663,
     "Column18": 49.9,
     "Column19": 366,
     "Column20": 6.1,
     "Column21": "701+",
     "Column22": 66.8,
     "Column23": 526,
     "Column24": 17.3,
     "Column25": 610,
     "Column26": 9.4,
     "Column27": "680=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "Pavol Jozef Šafárik University in Košice",
     "Column4": "SK",
     "Column5": "Slovakia",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.7,
     "Column11": "601+",
     "Column12": 5.1,
     "Column13": "601+",
     "Column14": 59.7,
     "Column15": 244,
     "Column16": 3,
     "Column17": "701+",
     "Column18": 5.1,
     "Column19": "701+",
     "Column20": 65.3,
     "Column21": 228,
     "Column22": 41.7,
     "Column23": "701+",
     "Column24": 4.6,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "801-850",
     "Column3": "Poznan University of Life Sciences",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.6,
     "Column11": "601+",
     "Column12": 2.2,
     "Column13": "601+",
     "Column14": 94.3,
     "Column15": 67,
     "Column16": 6.4,
     "Column17": "701+",
     "Column18": 1.9,
     "Column19": "701+",
     "Column20": 7.9,
     "Column21": "701+",
     "Column22": 31,
     "Column23": "701+",
     "Column24": 3.3,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "951-1000",
     "Column3": "Shahid Beheshti University (SBU)",
     "Column4": "IR",
     "Column5": "Iran, Islamic Republic of",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.9,
     "Column11": "601+",
     "Column12": 7.3,
     "Column13": "601+",
     "Column14": 4.1,
     "Column15": "701+",
     "Column16": 33.3,
     "Column17": 397,
     "Column18": 1.6,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 64.7,
     "Column23": 563,
     "Column24": 19,
     "Column25": 582,
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "741-750",
     "Column3": "Swarthmore College",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 3.4,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 88.8,
     "Column15": 91,
     "Column16": 11.7,
     "Column17": 699,
     "Column18": 8.7,
     "Column19": "701+",
     "Column20": 21.6,
     "Column21": 520,
     "Column22": 12.3,
     "Column23": "701+",
     "Column24": 15.2,
     "Column25": 652,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "Technological University Dublin",
     "Column4": "IE",
     "Column5": "Ireland",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 12.6,
     "Column11": "601+",
     "Column12": 13.1,
     "Column13": "601+",
     "Column14": 12.3,
     "Column15": "701+",
     "Column16": 3.1,
     "Column17": "701+",
     "Column18": 31.8,
     "Column19": 485,
     "Column20": 38.8,
     "Column21": 362,
     "Column22": 43.9,
     "Column23": "701+",
     "Column24": 16.2,
     "Column25": 631,
     "Column26": 28.2,
     "Column27": 461,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "951-1000",
     "Column3": "Thapar Institute of Engineering & Technology",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.7,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 8.8,
     "Column15": "701+",
     "Column16": 46.1,
     "Column17": 300,
     "Column18": 5.2,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 31.1,
     "Column23": "701+",
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 11.8,
     "Column27": "623=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "801-850",
     "Column3": "Tokyo University of Agriculture and Technology ",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.8,
     "Column11": "601+",
     "Column12": 7.4,
     "Column13": "601+",
     "Column14": 12.9,
     "Column15": "701+",
     "Column16": 30.5,
     "Column17": 430,
     "Column18": 5.4,
     "Column19": "701+",
     "Column20": 7.1,
     "Column21": "701+",
     "Column22": 40.4,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "951-1000",
     "Column3": "Universidad de Valladolid",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column10": 10.5,
     "Column11": "601+",
     "Column12": 3.8,
     "Column13": "601+",
     "Column14": 34.4,
     "Column15": 460,
     "Column16": 4.3,
     "Column17": "701+",
     "Column18": 7,
     "Column19": "701+",
     "Column20": 3.6,
     "Column21": "701+",
     "Column22": 80.2,
     "Column23": 316,
     "Column24": 11.9,
     "Column25": "701+",
     "Column26": 43.9,
     "Column27": 352,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "771-780",
     "Column3": "Universidad ORT Uruguay",
     "Column4": "UY",
     "Column5": "Uruguay",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 11.2,
     "Column11": "601+",
     "Column12": 12.4,
     "Column13": "601+",
     "Column14": 79,
     "Column15": 145,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 15.8,
     "Column19": 655,
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 3.4,
     "Column23": "701+",
     "Column24": 5.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "Universita' degli Studi di Ferrara",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.7,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 2.6,
     "Column15": "701+",
     "Column16": 34.6,
     "Column17": 386,
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 3.6,
     "Column21": "701+",
     "Column22": 70,
     "Column23": 487,
     "Column24": 4.1,
     "Column25": "701+",
     "Column26": 27.3,
     "Column27": "468=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "771-780",
     "Column3": "Université de Nantes",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.4,
     "Column11": "601+",
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 7.8,
     "Column15": "701+",
     "Column16": 21.4,
     "Column17": 540,
     "Column18": 19.8,
     "Column19": 601,
     "Column20": 15.1,
     "Column21": 619,
     "Column22": 87.3,
     "Column23": 211,
     "Column24": 2.8,
     "Column25": "701+",
     "Column26": 7.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "801-850",
     "Column3": "Université de Sherbrooke",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.8,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 18.8,
     "Column15": "701+",
     "Column16": 16.9,
     "Column17": 604,
     "Column18": 54.7,
     "Column19": 341,
     "Column20": 7.6,
     "Column21": "701+",
     "Column22": 43,
     "Column23": "701+",
     "Column24": 23.4,
     "Column25": 483,
     "Column26": 8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "751-760",
     "Column3": "Université du Québec",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 15.3,
     "Column11": 574,
     "Column12": 7.6,
     "Column13": "601+",
     "Column14": 1.8,
     "Column15": "701+",
     "Column16": 10.9,
     "Column17": "701+",
     "Column18": 32.9,
     "Column19": 479,
     "Column20": 11,
     "Column21": 699,
     "Column22": 62.6,
     "Column23": 593,
     "Column24": 21.6,
     "Column25": 530,
     "Column26": 9.9,
     "Column27": "667=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "771-780",
     "Column3": "University at Albany SUNY",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.1,
     "Column11": "601+",
     "Column12": 4.5,
     "Column13": "601+",
     "Column14": 5.4,
     "Column15": "701+",
     "Column16": 33.9,
     "Column17": 391,
     "Column18": 3.9,
     "Column19": "701+",
     "Column20": 7.2,
     "Column21": "701+",
     "Column22": 66,
     "Column23": 540,
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 13.2,
     "Column27": "606=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "801-850",
     "Column3": "University Duesseldorf",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.3,
     "Column11": "601+",
     "Column12": 8.5,
     "Column13": "601+",
     "Column14": 44.3,
     "Column15": 350,
     "Column16": 7.2,
     "Column17": "701+",
     "Column20": 14.1,
     "Column21": 639,
     "Column22": 66.2,
     "Column23": 536,
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "801-850",
     "Column3": "University of Dubai",
     "Column4": "AE",
     "Column5": "United Arab Emirates",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "LO",
     "Column9": "B",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 10.6,
     "Column15": "701+",
     "Column16": 11,
     "Column17": "701+",
     "Column18": 100,
     "Column19": "1=",
     "Column20": 72,
     "Column21": 204,
     "Column22": 8.2,
     "Column23": "701+",
     "Column24": 10.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "951-1000",
     "Column3": "UNIVERSITY OF GDANSK",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12,
     "Column11": "601+",
     "Column12": 6.2,
     "Column13": "601+",
     "Column14": 34.9,
     "Column15": 454,
     "Column16": 3.9,
     "Column17": "701+",
     "Column18": 4.8,
     "Column19": "701+",
     "Column20": 2.4,
     "Column21": "701+",
     "Column22": 70.6,
     "Column23": 476,
     "Column24": 14.1,
     "Column25": 687,
     "Column26": 25.7,
     "Column27": "483=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "951-1000",
     "Column3": "University of Ghana",
     "Column4": "GH",
     "Column5": "Ghana",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 11.5,
     "Column11": "601+",
     "Column12": 7.6,
     "Column13": "601+",
     "Column14": 1.9,
     "Column15": "701+",
     "Column16": 5.3,
     "Column17": "701+",
     "Column18": 4.5,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 72.8,
     "Column23": 443,
     "Column24": 95.4,
     "Column25": 51,
     "Column26": 7.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "University of Hertfordshire",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 3.7,
     "Column13": "601+",
     "Column14": 5.9,
     "Column15": "701+",
     "Column16": 5.2,
     "Column17": "701+",
     "Column18": 62,
     "Column19": 316,
     "Column20": 100,
     "Column21": 34,
     "Column22": 58.8,
     "Column23": 647,
     "Column24": 10.1,
     "Column25": "701+",
     "Column26": 2.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "University of Lincoln",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.9,
     "Column11": "601+",
     "Column12": 4.7,
     "Column13": "601+",
     "Column14": 7.9,
     "Column15": "701+",
     "Column16": 9.9,
     "Column17": "701+",
     "Column18": 74,
     "Column19": 271,
     "Column20": 47.2,
     "Column21": 311,
     "Column22": 58.5,
     "Column23": 650,
     "Column24": 4.8,
     "Column25": "701+",
     "Column26": 10.1,
     "Column27": "661=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "1001-1200",
     "Column3": "University of Mississippi",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.2,
     "Column11": "601+",
     "Column12": 2,
     "Column13": "601+",
     "Column14": 83,
     "Column15": 118,
     "Column16": 5.5,
     "Column17": "701+",
     "Column18": 6.1,
     "Column19": "701+",
     "Column20": 2.9,
     "Column21": "701+",
     "Column22": 58,
     "Column23": 655,
     "Column24": 3.6,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "University of Palermo",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.1,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 4.4,
     "Column15": "701+",
     "Column16": 32.2,
     "Column17": 408,
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 81.6,
     "Column23": 293,
     "Column24": 4.5,
     "Column25": "701+",
     "Column26": 5.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "801-850",
     "Column3": "University of Santo Tomas",
     "Column4": "PH",
     "Column5": "Philippines",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 12.8,
     "Column11": "601+",
     "Column12": 39.9,
     "Column13": 237,
     "Column14": 12.5,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 34.9,
     "Column19": 459,
     "Column20": 5.8,
     "Column21": "701+",
     "Column22": 18.1,
     "Column23": "701+",
     "Column24": 14.1,
     "Column25": 690,
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "851-900",
     "Column3": "University of South Africa",
     "Column4": "ZA",
     "Column5": "South Africa",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.6,
     "Column11": "601+",
     "Column12": 7.2,
     "Column13": "601+",
     "Column14": 1.1,
     "Column15": "701+",
     "Column16": 7.3,
     "Column17": "701+",
     "Column20": 7.3,
     "Column21": "701+",
     "Column22": 91,
     "Column23": 156,
     "Column24": 92.8,
     "Column25": 65,
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "901-950",
     "Column3": "University of Wroclaw",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.9,
     "Column11": 587,
     "Column12": 12.6,
     "Column13": "601+",
     "Column14": 32.1,
     "Column15": 492,
     "Column16": 3.9,
     "Column17": "701+",
     "Column18": 5,
     "Column19": "701+",
     "Column20": 8.4,
     "Column21": "701+",
     "Column22": 54.3,
     "Column23": "701+",
     "Column24": 12,
     "Column25": "701+",
     "Column26": 9.3,
     "Column27": "683=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "951-1000",
     "Column3": "Vietnam National University, Hanoi ",
     "Column4": "VN",
     "Column5": "Vietnam",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 15.3,
     "Column11": 575,
     "Column12": 18.7,
     "Column13": 472,
     "Column14": 7.6,
     "Column15": "701+",
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 5.5,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 61,
     "Column23": 620,
     "Column24": 60.1,
     "Column25": 202,
     "Column26": 6.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "801-850",
     "Column3": "Vilnius Gediminas Technical University",
     "Column4": "LT",
     "Column5": "Lithuania",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.7,
     "Column11": "601+",
     "Column12": 13.8,
     "Column13": 589,
     "Column14": 37.7,
     "Column15": 424,
     "Column16": 7.8,
     "Column17": "701+",
     "Column18": 14.7,
     "Column19": 669,
     "Column20": 32.9,
     "Column21": 406,
     "Column22": 41.4,
     "Column23": "701+",
     "Column24": 6.9,
     "Column25": "701+",
     "Column26": 2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "761-770",
     "Column3": "Virginia Commonwealth University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 8.5,
     "Column13": "601+",
     "Column14": 38.8,
     "Column15": 405,
     "Column16": 12.8,
     "Column17": 672,
     "Column18": 9.7,
     "Column19": "701+",
     "Column20": 3.2,
     "Column21": "701+",
     "Column22": 73.5,
     "Column23": 428,
     "Column24": 8.2,
     "Column25": "701+",
     "Column26": 8.5,
     "Column27": "696=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "851-900",
     "Column2": "951-1000",
     "Column3": "Yildiz Technical University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.7,
     "Column11": "601+",
     "Column12": 24.7,
     "Column13": 386,
     "Column14": 4.2,
     "Column15": "701+",
     "Column16": 12.2,
     "Column17": 684,
     "Column18": 9.1,
     "Column19": "701+",
     "Column20": 18.5,
     "Column21": 574,
     "Column22": 38.9,
     "Column23": "701+",
     "Column24": 19.9,
     "Column25": 566,
     "Column26": 35.1,
     "Column27": "403=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "801-850",
     "Column3": "Australian Catholic University",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.2,
     "Column11": "601+",
     "Column12": 8.9,
     "Column13": "601+",
     "Column14": 5.1,
     "Column15": "701+",
     "Column16": 12.1,
     "Column17": 688,
     "Column18": 71,
     "Column19": 279,
     "Column20": 41.7,
     "Column21": 340,
     "Column22": 33.9,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "771-780",
     "Column2": "801-850",
     "Column3": "China University of Geosciences",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.1,
     "Column11": "601+",
     "Column12": 1.4,
     "Column13": "601+",
     "Column14": 53.6,
     "Column15": 284,
     "Column16": 31.8,
     "Column17": 415,
     "Column18": 1.5,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 67,
     "Column23": 523,
     "Column24": 1.4,
     "Column25": "701+",
     "Column26": 4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Harbin Engineering University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 16.5,
     "Column15": "701+",
     "Column16": 28.1,
     "Column17": 464,
     "Column18": 2.3,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 32.4,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "Catania University",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.6,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 5.4,
     "Column15": "701+",
     "Column16": 23.4,
     "Column17": 517,
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 4.5,
     "Column21": "701+",
     "Column22": 71.3,
     "Column23": 465,
     "Column24": 5.7,
     "Column25": "701+",
     "Column26": 3.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "1001-1200",
     "Column3": "Future University in Egypt",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 4.8,
     "Column11": "601+",
     "Column12": 34.7,
     "Column13": 272,
     "Column14": 34.9,
     "Column15": 453,
     "Column16": 2,
     "Column17": "701+",
     "Column18": 11.7,
     "Column19": "701+",
     "Column20": 21.7,
     "Column21": 519,
     "Column22": 40,
     "Column23": "701+",
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "1001-1200",
     "Column3": "Gazi Üniversitesi",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 18.6,
     "Column11": 483,
     "Column12": 15.3,
     "Column13": 551,
     "Column14": 6,
     "Column15": "701+",
     "Column16": 4.4,
     "Column17": "701+",
     "Column18": 1,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 56,
     "Column23": 679,
     "Column24": 18.2,
     "Column25": 597,
     "Column26": 20.1,
     "Column27": "530=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "1401+",
     "Column3": "Georgian Technical University",
     "Column4": "GE",
     "Column5": "Georgia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 6.6,
     "Column13": "601+",
     "Column14": 1,
     "Column15": "701+",
     "Column16": 46.9,
     "Column17": 291,
     "Column22": 5.4,
     "Column23": "701+",
     "Column24": 25.9,
     "Column25": 457,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "1001-1200",
     "Column3": "German Jordanian University",
     "Column4": "JO",
     "Column5": "Jordan",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.6,
     "Column11": "601+",
     "Column12": 15.1,
     "Column13": 554,
     "Column14": 21.4,
     "Column15": 645,
     "Column16": 5.6,
     "Column17": "701+",
     "Column18": 65.3,
     "Column19": 301,
     "Column20": 16.3,
     "Column21": 600,
     "Column22": 17.8,
     "Column23": "701+",
     "Column24": 8.5,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "1001-1200",
     "Column3": "Imam Mohammad Ibn Saud Islamic University – IMSIU",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 14.8,
     "Column11": 589,
     "Column12": 7.6,
     "Column13": "601+",
     "Column14": 17.4,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 46.2,
     "Column19": 389,
     "Column20": 4.2,
     "Column21": "701+",
     "Column22": 41.9,
     "Column23": "701+",
     "Column24": 26.5,
     "Column25": 447,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "Kanazawa University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.5,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 55.1,
     "Column15": 275,
     "Column16": 12.2,
     "Column17": 686,
     "Column18": 8.1,
     "Column19": "701+",
     "Column20": 5.8,
     "Column21": "701+",
     "Column22": 31.9,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "Karaganda Buketov University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 15.6,
     "Column11": 563,
     "Column12": 24.5,
     "Column13": 391,
     "Column14": 41.3,
     "Column15": 379,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 13.1,
     "Column19": "701+",
     "Column20": 5.2,
     "Column21": "701+",
     "Column22": 7.5,
     "Column23": "701+",
     "Column24": 7.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "Kyrgyz-Turkish Manas University",
     "Column4": "KG",
     "Column5": "Kyrgyzstan",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 15.1,
     "Column11": 583,
     "Column12": 10.9,
     "Column13": "601+",
     "Column14": 10.7,
     "Column15": "701+",
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 90.5,
     "Column19": 189,
     "Column20": 19.7,
     "Column21": 549,
     "Column22": 7.1,
     "Column23": "701+",
     "Column24": 5.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "801-850",
     "Column3": "Louisiana State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.5,
     "Column11": "601+",
     "Column12": 6.7,
     "Column13": "601+",
     "Column14": 7.8,
     "Column15": "701+",
     "Column16": 12.9,
     "Column17": 669,
     "Column18": 8.6,
     "Column19": "701+",
     "Column20": 3.8,
     "Column21": "701+",
     "Column22": 52.3,
     "Column23": "701+",
     "Column24": 18.8,
     "Column25": 589,
     "Column26": 52.8,
     "Column27": 301,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "951-1000",
     "Column3": "Makerere University",
     "Column4": "UG",
     "Column5": "Uganda",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 11,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 3.4,
     "Column15": "701+",
     "Column16": 4.5,
     "Column17": "701+",
     "Column18": 9.6,
     "Column19": "701+",
     "Column20": 7.5,
     "Column21": "701+",
     "Column22": 64.8,
     "Column23": 560,
     "Column24": 98.3,
     "Column25": 25,
     "Column26": 3.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "951-1000",
     "Column3": "Manipal Academy of Higher Education, Manipal, Karnataka, India",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 10.9,
     "Column11": "601+",
     "Column12": 8.2,
     "Column13": "601+",
     "Column14": 42.9,
     "Column15": 365,
     "Column16": 4.4,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column20": 7.7,
     "Column21": "701+",
     "Column22": 61.2,
     "Column23": 614,
     "Column24": 6.2,
     "Column25": "701+",
     "Column26": 16.5,
     "Column27": "567=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "Michigan Technological University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.8,
     "Column11": "601+",
     "Column12": 3.7,
     "Column13": "601+",
     "Column14": 27.1,
     "Column15": 557,
     "Column16": 28.6,
     "Column17": 452,
     "Column18": 11.4,
     "Column19": "701+",
     "Column20": 11.1,
     "Column21": 697,
     "Column22": 52.1,
     "Column23": "701+",
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 1.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "National Research Saratov State University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 96.7,
     "Column15": 51,
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 3,
     "Column19": "701+",
     "Column20": 16.6,
     "Column21": 593,
     "Column22": 19.1,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "901-950",
     "Column3": "Nicolaus Copernicus University ",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.6,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 53.2,
     "Column15": 287,
     "Column16": 4.9,
     "Column17": "701+",
     "Column18": 3.1,
     "Column19": "701+",
     "Column20": 5.3,
     "Column21": "701+",
     "Column22": 81.3,
     "Column23": 302,
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "North South University",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 13.3,
     "Column11": "601+",
     "Column12": 48.1,
     "Column13": 190,
     "Column14": 6.1,
     "Column15": "701+",
     "Column16": 2.7,
     "Column17": "701+",
     "Column18": 7.9,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 24.5,
     "Column23": "701+",
     "Column24": 6.6,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "721-730",
     "Column3": "Osaka Metropolitan University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column10": 12.4,
     "Column11": "601+",
     "Column12": 11.6,
     "Column13": "601+",
     "Column14": 51.3,
     "Column15": 296,
     "Column16": 6.5,
     "Column17": "701+",
     "Column18": 4.6,
     "Column19": "701+",
     "Column20": 3.1,
     "Column21": "701+",
     "Column22": 29.3,
     "Column23": "701+",
     "Column24": 5.9,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "901-950",
     "Column3": "Princess Sumaya University for Technology",
     "Column4": "JO",
     "Column5": "Jordan",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 9.1,
     "Column11": "601+",
     "Column12": 25.3,
     "Column13": 375,
     "Column14": 11.9,
     "Column15": "701+",
     "Column16": 5.1,
     "Column17": "701+",
     "Column18": 47.3,
     "Column19": 377,
     "Column20": 50,
     "Column21": 294,
     "Column22": 6,
     "Column23": "701+",
     "Column24": 5.1,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "801-850",
     "Column3": "Ritsumeikan Asia Pacific University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "LO",
     "Column9": "C",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 8.3,
     "Column13": "601+",
     "Column14": 2.6,
     "Column15": "701+",
     "Column16": 2.5,
     "Column17": "701+",
     "Column18": 99.9,
     "Column19": 85,
     "Column20": 99.4,
     "Column21": 50,
     "Column22": 3.7,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "901-950",
     "Column3": "Robert Gordon University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.7,
     "Column11": "601+",
     "Column12": 11.5,
     "Column13": "601+",
     "Column14": 5.6,
     "Column15": "701+",
     "Column16": 5.3,
     "Column17": "701+",
     "Column18": 62.6,
     "Column19": 312,
     "Column20": 74.1,
     "Column21": 191,
     "Column22": 35.8,
     "Column23": "701+",
     "Column24": 5.6,
     "Column25": "701+",
     "Column26": 5.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "951-1000",
     "Column3": "South Ural State University (National Research University)",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.4,
     "Column11": "601+",
     "Column12": 4.7,
     "Column13": "601+",
     "Column14": 44.1,
     "Column15": 353,
     "Column16": 3.6,
     "Column17": "701+",
     "Column18": 4.5,
     "Column19": "701+",
     "Column20": 13.4,
     "Column21": 655,
     "Column22": 84.1,
     "Column23": 261,
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "Universidad Católica del Uruguay (UCU)",
     "Column4": "UY",
     "Column5": "Uruguay",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 12.5,
     "Column11": "601+",
     "Column12": 12.8,
     "Column13": "601+",
     "Column14": 64.4,
     "Column15": 209,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 9.5,
     "Column19": "701+",
     "Column20": 2.8,
     "Column21": "701+",
     "Column22": 6.4,
     "Column23": "701+",
     "Column24": 15.1,
     "Column25": 658,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "Universidad Central \"Marta Abreu\" de Las Villas",
     "Column4": "CU",
     "Column5": "Cuba",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.3,
     "Column11": "601+",
     "Column12": 1.4,
     "Column13": "601+",
     "Column14": 99.7,
     "Column15": 31,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 17.4,
     "Column23": "701+",
     "Column24": 21.8,
     "Column25": 523,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "731-740",
     "Column3": "Universidad EAFIT",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 12.9,
     "Column11": "601+",
     "Column12": 42.8,
     "Column13": 215,
     "Column14": 4.9,
     "Column15": "701+",
     "Column16": 2.3,
     "Column17": "701+",
     "Column18": 3.1,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 11.3,
     "Column23": "701+",
     "Column24": 44.4,
     "Column25": 289,
     "Column26": 4.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "751-760",
     "Column3": "Universidad Externado de Colombia ",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 13,
     "Column11": "601+",
     "Column12": 28.9,
     "Column13": 326,
     "Column14": 36.4,
     "Column15": 439,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 10.5,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 4.3,
     "Column23": "701+",
     "Column24": 26.4,
     "Column25": 449,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "951-1000",
     "Column3": "Universidad Nacional de Córdoba - UNC",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 26.4,
     "Column11": 337,
     "Column12": 14,
     "Column13": 583,
     "Column14": 6.2,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 44.4,
     "Column23": "701+",
     "Column24": 10.1,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "901-950",
     "Column3": "Universidad Nacional Mayor de San Marcos",
     "Column4": "PE",
     "Column5": "Peru",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 20.6,
     "Column11": 426,
     "Column12": 22.9,
     "Column13": 411,
     "Column14": 7.9,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column22": 33.2,
     "Column23": "701+",
     "Column24": 38.6,
     "Column25": 329,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "801-850",
     "Column3": "Universidad Pontificia Bolivariana ",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 8.4,
     "Column11": "601+",
     "Column12": 8.8,
     "Column13": "601+",
     "Column14": 74.7,
     "Column15": 169,
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 2.3,
     "Column21": "701+",
     "Column22": 10,
     "Column23": "701+",
     "Column24": 22,
     "Column25": 521,
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "901-950",
     "Column3": "Universidade Católica Portuguesa - UCP",
     "Column4": "PT",
     "Column5": "Portugal",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 13,
     "Column11": "601+",
     "Column12": 13.6,
     "Column13": 596,
     "Column14": 11.9,
     "Column15": "701+",
     "Column16": 6.1,
     "Column17": "701+",
     "Column18": 9.3,
     "Column19": "701+",
     "Column20": 30.2,
     "Column21": 428,
     "Column22": 27.8,
     "Column23": "701+",
     "Column24": 43.2,
     "Column25": 296,
     "Column26": 5.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "901-950",
     "Column3": "Universidade de Vigo",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.9,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 9.4,
     "Column15": "701+",
     "Column16": 18,
     "Column17": 583,
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 4.8,
     "Column21": "701+",
     "Column22": 79.9,
     "Column23": 325,
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 39.8,
     "Column27": "369=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "1001-1200",
     "Column3": "Università degli Studi della Tuscia (University of Tuscia)",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.6,
     "Column11": "601+",
     "Column12": 1.2,
     "Column13": "601+",
     "Column14": 5.6,
     "Column15": "701+",
     "Column16": 43.6,
     "Column17": 315,
     "Column18": 13.7,
     "Column19": 689,
     "Column20": 10.4,
     "Column21": "701+",
     "Column22": 32.4,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "901-950",
     "Column3": "Università degli studi Roma Tre",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 17.4,
     "Column11": 517,
     "Column12": 8,
     "Column13": "601+",
     "Column14": 2.2,
     "Column15": "701+",
     "Column16": 12.1,
     "Column17": 689,
     "Column18": 8.5,
     "Column19": "701+",
     "Column20": 4.2,
     "Column21": "701+",
     "Column22": 62.5,
     "Column23": 595,
     "Column24": 7.1,
     "Column25": "701+",
     "Column26": 19.7,
     "Column27": "537=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "901-950",
     "Column3": "University of Alabama, Birmingham",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 56,
     "Column15": 263,
     "Column16": 16.8,
     "Column17": 607,
     "Column22": 54.5,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "951-1000",
     "Column3": "University of Calabria",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.8,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 5.5,
     "Column15": "701+",
     "Column16": 22.6,
     "Column17": 525,
     "Column18": 8.3,
     "Column19": "701+",
     "Column20": 6.2,
     "Column21": "701+",
     "Column22": 81.4,
     "Column23": 299,
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 29.6,
     "Column27": "447=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "University of East London",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.6,
     "Column11": "601+",
     "Column12": 3.6,
     "Column13": "601+",
     "Column14": 3.1,
     "Column15": "701+",
     "Column16": 6.2,
     "Column17": "701+",
     "Column18": 73.1,
     "Column19": 274,
     "Column20": 100,
     "Column21": 32,
     "Column22": 33.3,
     "Column23": "701+",
     "Column24": 7.6,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column3": "University of Hail",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 1.9,
     "Column13": "601+",
     "Column14": 39.4,
     "Column15": 394,
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 43,
     "Column20": 3,
     "Column21": "701+",
     "Column22": 43.3,
     "Column23": "701+",
     "Column24": 2.3,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "951-1000",
     "Column3": "University of Lodz",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.5,
     "Column11": "601+",
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 39.5,
     "Column15": 393,
     "Column16": 3.4,
     "Column17": "701+",
     "Column18": 4,
     "Column19": "701+",
     "Column20": 11.3,
     "Column21": 692,
     "Column22": 70.9,
     "Column23": 470,
     "Column24": 16.3,
     "Column25": 628,
     "Column26": 1.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "University of Maribor",
     "Column4": "SI",
     "Column5": "Slovenia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8,
     "Column11": "601+",
     "Column12": 6.6,
     "Column13": "601+",
     "Column14": 29.2,
     "Column15": 530,
     "Column16": 6.8,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column20": 13,
     "Column21": 658,
     "Column22": 61,
     "Column23": 622,
     "Column24": 33.4,
     "Column25": 380,
     "Column26": 9.1,
     "Column27": "686=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "901-950",
     "Column3": "University of Murcia",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.7,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 24.5,
     "Column15": 599,
     "Column16": 6.8,
     "Column17": "701+",
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 3.9,
     "Column21": "701+",
     "Column22": 79.8,
     "Column23": 326,
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 44.9,
     "Column27": "338=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "1001-1200",
     "Column3": "University of Nairobi",
     "Column4": "KE",
     "Column5": "Kenya",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 10,
     "Column11": "601+",
     "Column12": 11.7,
     "Column13": "601+",
     "Column14": 20.1,
     "Column15": 671,
     "Column16": 1.8,
     "Column17": "701+",
     "Column22": 50.1,
     "Column23": "701+",
     "Column24": 92.5,
     "Column25": 67,
     "Column26": 1.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "951-1000",
     "Column3": "University of Peshawar",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 9.9,
     "Column13": "601+",
     "Column14": 34.3,
     "Column15": 462,
     "Column16": 21.4,
     "Column17": 541,
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 42.9,
     "Column23": "701+",
     "Column24": 15.3,
     "Column25": 649,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "University of Salford",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.8,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 6.4,
     "Column15": "701+",
     "Column16": 6.9,
     "Column17": "701+",
     "Column18": 36.7,
     "Column19": 435,
     "Column20": 69.8,
     "Column21": 212,
     "Column22": 63.6,
     "Column23": 579,
     "Column24": 9.6,
     "Column25": "701+",
     "Column26": 5.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "University Paris 2 Panthéon-Assas",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 3.5,
     "Column13": "601+",
     "Column14": 62.1,
     "Column15": 230,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 11.8,
     "Column19": "701+",
     "Column20": 26.1,
     "Column21": 464,
     "Column22": 4.3,
     "Column23": "701+",
     "Column24": 53.1,
     "Column25": 238,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "951-1000",
     "Column3": "Viet Nam National University Ho Chi Minh City (VNU-HCM)",
     "Column4": "VN",
     "Column5": "Vietnam",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 18.8,
     "Column11": 481,
     "Column12": 24.6,
     "Column13": 389,
     "Column14": 5.7,
     "Column15": "701+",
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 4.2,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 45.7,
     "Column23": "701+",
     "Column24": 24.9,
     "Column25": 466,
     "Column26": 4.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "851-900",
     "Column3": "Worcester Polytechnic Institute",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 3.5,
     "Column11": "601+",
     "Column12": 5.2,
     "Column13": "601+",
     "Column14": 17.4,
     "Column15": "701+",
     "Column16": 26.2,
     "Column17": 483,
     "Column18": 42,
     "Column19": 410,
     "Column20": 23.5,
     "Column21": 499,
     "Column22": 25.6,
     "Column23": "701+",
     "Column24": 8.3,
     "Column25": "701+",
     "Column26": 1.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "901-950",
     "Column3": "Wroclaw University of Science and Technology (WRUST)",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.7,
     "Column11": "601+",
     "Column12": 18.9,
     "Column13": 470,
     "Column14": 33.7,
     "Column15": 467,
     "Column16": 5.3,
     "Column17": "701+",
     "Column18": 3.4,
     "Column19": "701+",
     "Column20": 5,
     "Column21": "701+",
     "Column22": 55.5,
     "Column23": 683,
     "Column24": 3.2,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "1001-1200",
     "Column3": "Yerevan State University",
     "Column4": "AM",
     "Column5": "Armenia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 51.4,
     "Column15": 295,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 5.1,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 20,
     "Column23": "701+",
     "Column24": 87.4,
     "Column25": 92,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "901-950",
     "Column2": "801-850",
     "Column3": "Yokohama City University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.4,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 96.2,
     "Column15": 54,
     "Column16": 10.3,
     "Column17": "701+",
     "Column18": 5.4,
     "Column19": "701+",
     "Column20": 2.4,
     "Column21": "701+",
     "Column22": 10.5,
     "Column23": "701+",
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "801-850",
     "Column3": "Almaty Technological University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.2,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 88.1,
     "Column15": 93,
     "Column16": 1,
     "Column17": "701+",
     "Column18": 25,
     "Column19": 536,
     "Column20": 4.8,
     "Column21": "701+",
     "Column22": 2.9,
     "Column23": "701+",
     "Column24": 3.3,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "Athens University of Economics and Business",
     "Column4": "GR",
     "Column5": "Greece",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.6,
     "Column11": "601+",
     "Column12": 29.3,
     "Column13": 323,
     "Column14": 1.7,
     "Column15": "701+",
     "Column16": 20,
     "Column17": 556,
     "Column18": 1.6,
     "Column19": "701+",
     "Column20": 3.1,
     "Column21": "701+",
     "Column22": 14,
     "Column23": "701+",
     "Column24": 20.9,
     "Column25": 547,
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "801-850",
     "Column3": "Auburn University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 7.7,
     "Column13": "601+",
     "Column14": 7.6,
     "Column15": "701+",
     "Column16": 17.2,
     "Column17": 597,
     "Column18": 27.6,
     "Column19": 510,
     "Column20": 6,
     "Column21": "701+",
     "Column22": 54.7,
     "Column23": "701+",
     "Column24": 16.4,
     "Column25": 627,
     "Column26": 10.9,
     "Column27": "648=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1001-1200",
     "Column3": "Baku State University",
     "Column4": "AZ",
     "Column5": "Azerbaijan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 12.9,
     "Column11": "601+",
     "Column12": 5.5,
     "Column13": "601+",
     "Column14": 41.7,
     "Column15": 373,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 5.3,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 16.7,
     "Column23": "701+",
     "Column24": 47.7,
     "Column25": 271,
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1001-1200",
     "Column3": "Bina Nusantara University (BINUS)",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 12.5,
     "Column11": "601+",
     "Column12": 21.8,
     "Column13": 426,
     "Column14": 19.9,
     "Column15": 678,
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 34.7,
     "Column19": 463,
     "Column20": 4.3,
     "Column21": "701+",
     "Column22": 10.7,
     "Column23": "701+",
     "Column24": 12.9,
     "Column25": "701+",
     "Column26": 4.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "801-850",
     "Column3": "Clark University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 2.6,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 18.9,
     "Column15": "701+",
     "Column16": 16.7,
     "Column17": 608,
     "Column18": 13.2,
     "Column19": "701+",
     "Column20": 84.7,
     "Column21": 154,
     "Column22": 20.2,
     "Column23": "701+",
     "Column24": 13.6,
     "Column25": "701+",
     "Column26": 6.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "851-900",
     "Column3": "Clemson University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.3,
     "Column11": "601+",
     "Column12": 7,
     "Column13": "601+",
     "Column14": 12.6,
     "Column15": "701+",
     "Column16": 15.2,
     "Column17": 633,
     "Column18": 11.6,
     "Column19": "701+",
     "Column20": 5.2,
     "Column21": "701+",
     "Column22": 78.5,
     "Column23": 346,
     "Column24": 7.7,
     "Column25": "701+",
     "Column26": 12.3,
     "Column27": "618=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "D. Serikbayev East Kazakhstan state technical university",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 13,
     "Column11": "601+",
     "Column12": 7.7,
     "Column13": "601+",
     "Column14": 64.2,
     "Column15": 211,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 14.4,
     "Column19": 674,
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 2.4,
     "Column23": "701+",
     "Column24": 3.5,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1001-1200",
     "Column3": "Ferdowsi University of Mashhad",
     "Column4": "IR",
     "Column5": "Iran, Islamic Republic of",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 6,
     "Column13": "601+",
     "Column14": 3.5,
     "Column15": "701+",
     "Column16": 28.2,
     "Column17": 463,
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 7.1,
     "Column21": "701+",
     "Column22": 71.7,
     "Column23": 455,
     "Column24": 5.2,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1001-1200",
     "Column3": "Financial University under the Government of the Russian Federation",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.3,
     "Column11": "601+",
     "Column12": 9.9,
     "Column13": "601+",
     "Column14": 39.3,
     "Column15": 397,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 4.7,
     "Column19": "701+",
     "Column20": 19.4,
     "Column21": 557,
     "Column22": 32.4,
     "Column23": "701+",
     "Column24": 47.7,
     "Column25": 270,
     "Column26": 5.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "Hallym University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 2.8,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 94.4,
     "Column15": 65,
     "Column16": 6.9,
     "Column17": "701+",
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 5.7,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "731-740",
     "Column3": "Indian Institute of Technology Bhubaneswar",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.3,
     "Column11": "601+",
     "Column12": 3.5,
     "Column13": "601+",
     "Column14": 10,
     "Column15": "701+",
     "Column16": 50.2,
     "Column17": 260,
     "Column18": 1.5,
     "Column19": "701+",
     "Column20": 1,
     "Column21": "701+",
     "Column22": 6.8,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "791-800",
     "Column3": "Instituto Tecnológico de Buenos Aires (ITBA)",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 8.8,
     "Column11": "601+",
     "Column12": 24.6,
     "Column13": 387,
     "Column14": 39,
     "Column15": 401,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 17.3,
     "Column19": 638,
     "Column20": 12.3,
     "Column21": 674,
     "Column22": 2.7,
     "Column23": "701+",
     "Column24": 22.3,
     "Column25": 515,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "951-1000",
     "Column3": "Khon Kaen University",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 17.2,
     "Column11": 524,
     "Column12": 10.7,
     "Column13": "601+",
     "Column14": 7.4,
     "Column15": "701+",
     "Column16": 4.4,
     "Column17": "701+",
     "Column18": 5.3,
     "Column19": "701+",
     "Column20": 2.3,
     "Column21": "701+",
     "Column22": 50.9,
     "Column23": "701+",
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 31.8,
     "Column27": 431,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "951-1000",
     "Column3": "King Mongkut's University of Technology Thonburi ",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.2,
     "Column11": "601+",
     "Column12": 6.3,
     "Column13": "601+",
     "Column14": 13.3,
     "Column15": "701+",
     "Column16": 8.5,
     "Column17": "701+",
     "Column18": 9.5,
     "Column19": "701+",
     "Column20": 3.2,
     "Column21": "701+",
     "Column22": 36.9,
     "Column23": "701+",
     "Column24": 2.6,
     "Column25": "701+",
     "Column26": 41,
     "Column27": "360=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "Kumamoto University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 55.2,
     "Column15": 273,
     "Column16": 12.6,
     "Column17": 677,
     "Column18": 7.2,
     "Column19": "701+",
     "Column20": 3.5,
     "Column21": "701+",
     "Column22": 35.1,
     "Column23": "701+",
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1001-1200",
     "Column3": "Mansoura University",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.1,
     "Column11": "601+",
     "Column12": 12,
     "Column13": "601+",
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 6.7,
     "Column19": "701+",
     "Column20": 5.7,
     "Column21": "701+",
     "Column22": 74.7,
     "Column23": 413,
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 40.4,
     "Column27": "363=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "851-900",
     "Column3": "Niigata University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 3.2,
     "Column13": "601+",
     "Column14": 85.3,
     "Column15": 103,
     "Column16": 3.8,
     "Column17": "701+",
     "Column18": 4.6,
     "Column19": "701+",
     "Column20": 3.1,
     "Column21": "701+",
     "Column22": 18.4,
     "Column23": "701+",
     "Column24": 5.7,
     "Column25": "701+",
     "Column26": 1.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "Northern Borders University",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 13.1,
     "Column11": "601+",
     "Column12": 4.7,
     "Column13": "601+",
     "Column14": 20.2,
     "Column15": 666,
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 98.4,
     "Column19": 115,
     "Column20": 4.8,
     "Column21": "701+",
     "Column22": 13.1,
     "Column23": "701+",
     "Column24": 2.4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "Pontificia Universidad Católica del Ecuador (PUCE)",
     "Column4": "EC",
     "Column5": "Ecuador",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 17.3,
     "Column11": 518,
     "Column12": 16.2,
     "Column13": 524,
     "Column14": 11.5,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 14.2,
     "Column19": 677,
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 27.1,
     "Column23": "701+",
     "Column24": 34,
     "Column25": 370,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "Prince of Songkla University",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 21.6,
     "Column11": 408,
     "Column12": 5.6,
     "Column13": "601+",
     "Column14": 16.4,
     "Column15": "701+",
     "Column16": 3.3,
     "Column17": "701+",
     "Column18": 5.3,
     "Column19": "701+",
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 40.3,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 24.2,
     "Column27": 497,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "851-900",
     "Column3": "Queen Margaret University , Edinburgh",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.5,
     "Column11": "601+",
     "Column12": 4.3,
     "Column13": "601+",
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 18.3,
     "Column17": 579,
     "Column18": 59.9,
     "Column19": 326,
     "Column20": 73.3,
     "Column21": 199,
     "Column22": 3.3,
     "Column23": "701+",
     "Column24": 1.4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "Rhodes University ",
     "Column4": "ZA",
     "Column5": "South Africa",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 7.7,
     "Column13": "601+",
     "Column14": 12.9,
     "Column15": "701+",
     "Column16": 12.6,
     "Column17": 679,
     "Column20": 33.2,
     "Column21": 403,
     "Column22": 54.5,
     "Column23": "701+",
     "Column24": 35.5,
     "Column25": 356,
     "Column26": 2.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "Riga Stradins University",
     "Column4": "LV",
     "Column5": "Latvia",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 13.4,
     "Column13": "601+",
     "Column14": 14.1,
     "Column15": "701+",
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 22.3,
     "Column19": 565,
     "Column20": 90.8,
     "Column21": 118,
     "Column22": 15.1,
     "Column23": "701+",
     "Column24": 11.4,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "S.D. Asfendiyarov Kazakh National Medical University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "LO",
     "Column9": "A",
     "Column10": 6.7,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 59.7,
     "Column15": 245,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 10.4,
     "Column19": "701+",
     "Column20": 64.7,
     "Column21": 231,
     "Column22": 3.9,
     "Column23": "701+",
     "Column24": 3.4,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column3": "Saveetha Institute of Medical and Technical Sciences (deemed to be university)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 21.1,
     "Column15": 655,
     "Column16": 7.2,
     "Column17": "701+",
     "Column18": 87.1,
     "Column19": 210,
     "Column20": 7.2,
     "Column21": "701+",
     "Column22": 60.8,
     "Column23": 625,
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "Sophia University ",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 12.7,
     "Column11": "601+",
     "Column12": 19.9,
     "Column13": 454,
     "Column14": 21.9,
     "Column15": 640,
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 34.5,
     "Column19": 466,
     "Column20": 10.8,
     "Column21": "701+",
     "Column22": 8.6,
     "Column23": "701+",
     "Column24": 11.7,
     "Column25": "701+",
     "Column26": 5.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "851-900",
     "Column3": "Southern Federal University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 3.8,
     "Column13": "601+",
     "Column14": 67.9,
     "Column15": 194,
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 25.2,
     "Column21": 477,
     "Column22": 40.1,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "St. Louis University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 53.2,
     "Column15": 286,
     "Column16": 13.5,
     "Column17": 660,
     "Column18": 12.7,
     "Column19": "701+",
     "Column20": 11,
     "Column21": 698,
     "Column22": 28.7,
     "Column23": "701+",
     "Column24": 9,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "951-1000",
     "Column3": "The University of Alabama",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10,
     "Column11": "601+",
     "Column12": 20.3,
     "Column13": 449,
     "Column14": 5.3,
     "Column15": "701+",
     "Column16": 11.9,
     "Column17": 697,
     "Column18": 5.3,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 54.9,
     "Column23": 699,
     "Column24": 10.7,
     "Column25": "701+",
     "Column26": 6.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1001-1200",
     "Column3": "The University of Lahore",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 11.1,
     "Column11": "601+",
     "Column12": 19.4,
     "Column13": 464,
     "Column14": 12.3,
     "Column15": "701+",
     "Column16": 3.3,
     "Column17": "701+",
     "Column18": 4,
     "Column19": "701+",
     "Column20": 3,
     "Column21": "701+",
     "Column22": 61.4,
     "Column23": 611,
     "Column24": 2.7,
     "Column25": "701+",
     "Column26": 19.7,
     "Column27": "537=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "Universidad Autónoma Metropolitana (UAM)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 23.6,
     "Column11": 371,
     "Column12": 11.3,
     "Column13": "601+",
     "Column14": 3.7,
     "Column15": "701+",
     "Column16": 2.5,
     "Column17": "701+",
     "Column18": 7.8,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 45.6,
     "Column23": "701+",
     "Column24": 4.6,
     "Column25": "701+",
     "Column26": 9.4,
     "Column27": "680=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1001-1200",
     "Column3": "Universidad de Córdoba - España",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column10": 10.6,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 26.5,
     "Column15": 565,
     "Column16": 12,
     "Column17": 693,
     "Column22": 84.2,
     "Column23": 258,
     "Column24": 4.8,
     "Column25": "701+",
     "Column26": 5.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "791-800",
     "Column3": "Universidad de La Sabana",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 21,
     "Column11": 418,
     "Column12": 25.5,
     "Column13": 370,
     "Column14": 14.2,
     "Column15": "701+",
     "Column16": 2,
     "Column17": "701+",
     "Column18": 3.9,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 13.1,
     "Column23": "701+",
     "Column24": 10.5,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1001-1200",
     "Column3": "Universidad de León",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column10": 6.7,
     "Column11": "601+",
     "Column12": 3.6,
     "Column13": "601+",
     "Column14": 51.6,
     "Column15": 294,
     "Column16": 4.5,
     "Column17": "701+",
     "Column18": 2.3,
     "Column19": "701+",
     "Column20": 7.2,
     "Column21": "701+",
     "Column22": 44.9,
     "Column23": "701+",
     "Column24": 2.8,
     "Column25": "701+",
     "Column26": 38.5,
     "Column27": "381=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "951-1000",
     "Column3": "Universidad de los Andes - Chile",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 16.4,
     "Column11": 544,
     "Column12": 18.1,
     "Column13": 486,
     "Column14": 8.4,
     "Column15": "701+",
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 12.2,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 22.6,
     "Column23": "701+",
     "Column24": 38.6,
     "Column25": 331,
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "801-850",
     "Column3": "Universidad de San Andrés - UdeSA",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 7.7,
     "Column11": "601+",
     "Column12": 19.7,
     "Column13": 460,
     "Column14": 46,
     "Column15": 336,
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 7.5,
     "Column19": "701+",
     "Column20": 4.1,
     "Column21": "701+",
     "Column22": 13.2,
     "Column23": "701+",
     "Column24": 29.8,
     "Column25": 419,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "951-1000",
     "Column3": "Universidad Nacional de Rosario (UNR)",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 19.5,
     "Column11": 460,
     "Column12": 7.7,
     "Column13": "601+",
     "Column14": 31.2,
     "Column15": 504,
     "Column16": 1.1,
     "Column17": "701+",
     "Column20": 6.4,
     "Column21": "701+",
     "Column22": 26.8,
     "Column23": "701+",
     "Column24": 10.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "801-850",
     "Column3": "Universidad Técnica Federico Santa María (USM)",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 9.8,
     "Column11": "601+",
     "Column12": 33,
     "Column13": 287,
     "Column14": 3.2,
     "Column15": "701+",
     "Column16": 5,
     "Column17": "701+",
     "Column18": 13.8,
     "Column19": 688,
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 41.6,
     "Column23": "701+",
     "Column24": 13.1,
     "Column25": "701+",
     "Column26": 7.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "851-900",
     "Column3": "Universidad Torcuato Di Tella",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 12.4,
     "Column11": "601+",
     "Column12": 20.8,
     "Column13": 442,
     "Column14": 14.4,
     "Column15": "701+",
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 33.1,
     "Column19": 478,
     "Column20": 7.8,
     "Column21": "701+",
     "Column22": 4.5,
     "Column23": "701+",
     "Column24": 35.6,
     "Column25": 355,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1401+",
     "Column3": "Université Sorbonne Paris Nord",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column10": 9.5,
     "Column11": "601+",
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 4.2,
     "Column15": "701+",
     "Column16": 29.3,
     "Column17": 441,
     "Column22": 61,
     "Column23": 619,
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "951-1000",
     "Column3": "Université Toulouse 1 Capitole",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.4,
     "Column11": "601+",
     "Column12": 4.6,
     "Column13": "601+",
     "Column14": 1.7,
     "Column15": "701+",
     "Column16": 6.2,
     "Column17": "701+",
     "Column18": 25.5,
     "Column19": 526,
     "Column20": 50.2,
     "Column21": 292,
     "Column22": 52.6,
     "Column23": "701+",
     "Column24": 6.1,
     "Column25": "701+",
     "Column26": 1.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "951-1000",
     "Column3": "Universiti Pendidikan Sultan Idris (UPSI)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.1,
     "Column11": "601+",
     "Column12": 9.9,
     "Column13": "601+",
     "Column14": 27.5,
     "Column15": 554,
     "Column16": 2.7,
     "Column17": "701+",
     "Column18": 21.3,
     "Column19": 583,
     "Column20": 38.3,
     "Column21": 366,
     "Column22": 21.1,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "951-1000",
     "Column3": "University of Bahrain",
     "Column4": "BH",
     "Column5": "Bahrain",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9.7,
     "Column11": "601+",
     "Column12": 5.1,
     "Column13": "601+",
     "Column14": 7,
     "Column15": "701+",
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 87,
     "Column19": 213,
     "Column20": 8.9,
     "Column21": "701+",
     "Column22": 36.2,
     "Column23": "701+",
     "Column24": 36.3,
     "Column25": 350,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "University of Central Lancashire",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 4.9,
     "Column13": "601+",
     "Column14": 10.9,
     "Column15": "701+",
     "Column16": 3.8,
     "Column17": "701+",
     "Column18": 35.5,
     "Column19": 451,
     "Column20": 82,
     "Column21": 165,
     "Column22": 53.9,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1001-1200",
     "Column3": "University of Colombo",
     "Column4": "LK",
     "Column5": "Sri Lanka",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 13.1,
     "Column11": "601+",
     "Column12": 18,
     "Column13": 489,
     "Column14": 2.5,
     "Column15": "701+",
     "Column16": 3.2,
     "Column17": "701+",
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 19.3,
     "Column23": "701+",
     "Column24": 69.6,
     "Column25": 161,
     "Column26": 16.3,
     "Column27": "570=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "901-950",
     "Column3": "University of Denver",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 7.4,
     "Column13": "601+",
     "Column14": 42.2,
     "Column15": 368,
     "Column16": 7.8,
     "Column17": "701+",
     "Column18": 7.7,
     "Column19": "701+",
     "Column20": 5.8,
     "Column21": "701+",
     "Column22": 32.7,
     "Column23": "701+",
     "Column24": 25.6,
     "Column25": 460,
     "Column26": 9.9,
     "Column27": "667=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "851-900",
     "Column3": "University of Parma",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9.5,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 2.9,
     "Column15": "701+",
     "Column16": 25.4,
     "Column17": 492,
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 9.1,
     "Column21": "701+",
     "Column22": 69.4,
     "Column23": 491,
     "Column24": 9.2,
     "Column25": "701+",
     "Column26": 2.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1001-1200",
     "Column3": "University of Regina",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.3,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 3.9,
     "Column15": "701+",
     "Column16": 21,
     "Column17": 544,
     "Column18": 7.3,
     "Column19": "701+",
     "Column20": 38.3,
     "Column21": 365,
     "Column22": 39.1,
     "Column23": "701+",
     "Column24": 4.1,
     "Column25": "701+",
     "Column26": 44.6,
     "Column27": "343=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1001-1200",
     "Column3": "University of South Bohemia",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 56,
     "Column15": 264,
     "Column16": 5.7,
     "Column17": "701+",
     "Column18": 21.5,
     "Column19": 577,
     "Column20": 11.4,
     "Column21": 687,
     "Column22": 55.3,
     "Column23": 690,
     "Column24": 1.6,
     "Column25": "701+",
     "Column26": 5.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "951-1000",
     "Column3": "University of the Western Cape",
     "Column4": "ZA",
     "Column5": "South Africa",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.6,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 4.1,
     "Column15": "701+",
     "Column16": 14.1,
     "Column17": 653,
     "Column18": 25.2,
     "Column19": 531,
     "Column20": 3.7,
     "Column21": "701+",
     "Column22": 76.8,
     "Column23": 383,
     "Column24": 21,
     "Column25": 545,
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "1001-1200",
     "Column3": "Yarmouk University",
     "Column4": "JO",
     "Column5": "Jordan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 14.1,
     "Column11": "601+",
     "Column12": 20.1,
     "Column13": 450,
     "Column14": 3.1,
     "Column15": "701+",
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 7.3,
     "Column19": "701+",
     "Column20": 8.8,
     "Column21": "701+",
     "Column22": 45.3,
     "Column23": "701+",
     "Column24": 33.8,
     "Column25": 375,
     "Column26": 7.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "951-1000",
     "Column2": "951-1000",
     "Column3": "Yeungnam University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 13.4,
     "Column15": "701+",
     "Column16": 26.9,
     "Column17": 477,
     "Column18": 34.2,
     "Column19": 468,
     "Column20": 7.5,
     "Column21": "701+",
     "Column22": 43.9,
     "Column23": "701+",
     "Column24": 2.7,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "Al-Azhar University",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.1,
     "Column11": "601+",
     "Column12": 10.3,
     "Column13": "601+",
     "Column14": 11,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 9.5,
     "Column21": "701+",
     "Column22": 58.3,
     "Column23": 652,
     "Column24": 22.6,
     "Column25": 505,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Aligarh Muslim University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.1,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 16.2,
     "Column15": "701+",
     "Column16": 12.1,
     "Column17": 690,
     "Column18": 1,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 46,
     "Column23": "701+",
     "Column24": 2.7,
     "Column25": "701+",
     "Column26": 13.2,
     "Column27": "606=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Amity University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 9.8,
     "Column11": "601+",
     "Column12": 10.6,
     "Column13": "601+",
     "Column14": 10.7,
     "Column15": "701+",
     "Column16": 7.1,
     "Column17": "701+",
     "Column18": 3.7,
     "Column19": "701+",
     "Column20": 21.1,
     "Column21": 527,
     "Column22": 61.5,
     "Column23": 609,
     "Column24": 4.1,
     "Column25": "701+",
     "Column26": 10.8,
     "Column27": "650=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Amrita Vishwa Vidyapeetham",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 4.7,
     "Column13": "601+",
     "Column14": 24,
     "Column15": 611,
     "Column16": 5.7,
     "Column17": "701+",
     "Column18": 35.5,
     "Column19": 450,
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 39.7,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "An-Najah National University",
     "Column4": "PS",
     "Column5": "Palestinian Territory, Occupied",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 7.7,
     "Column11": "601+",
     "Column12": 12.1,
     "Column13": "601+",
     "Column14": 7.3,
     "Column15": "701+",
     "Column16": 2.3,
     "Column17": "701+",
     "Column18": 34.6,
     "Column19": 464,
     "Column20": 24.8,
     "Column21": 481,
     "Column22": 31.8,
     "Column23": "701+",
     "Column24": 21.8,
     "Column25": 524,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Arab Academy for Science, Technology and Maritime Transport",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.4,
     "Column11": "601+",
     "Column12": 8.4,
     "Column13": "601+",
     "Column14": 33,
     "Column15": 478,
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 6.8,
     "Column19": "701+",
     "Column20": 20.4,
     "Column21": 533,
     "Column22": 29.8,
     "Column23": "701+",
     "Column24": 11.4,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Assiut University ",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 11.4,
     "Column11": "601+",
     "Column12": 9.8,
     "Column13": "601+",
     "Column14": 13.6,
     "Column15": "701+",
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 11.8,
     "Column19": "701+",
     "Column20": 3.5,
     "Column21": "701+",
     "Column22": 56,
     "Column23": 678,
     "Column24": 2.5,
     "Column25": "701+",
     "Column26": 5.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "Azerbaijan State Oil and Industry University",
     "Column4": "AZ",
     "Column5": "Azerbaijan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.3,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 29.9,
     "Column15": 521,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 17.4,
     "Column19": 634,
     "Column20": 2.6,
     "Column21": "701+",
     "Column22": 6.6,
     "Column23": "701+",
     "Column24": 31.4,
     "Column25": 401,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Azerbaijan State University of Economics",
     "Column4": "AZ",
     "Column5": "Azerbaijan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 13,
     "Column11": "601+",
     "Column12": 14.5,
     "Column13": 570,
     "Column14": 26.1,
     "Column15": 569,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 14.2,
     "Column19": 678,
     "Column20": 3.4,
     "Column21": "701+",
     "Column22": 4.6,
     "Column23": "701+",
     "Column24": 38.6,
     "Column25": 330,
     "Column26": 1.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Banaras Hindu University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.5,
     "Column11": "601+",
     "Column12": 8,
     "Column13": "601+",
     "Column14": 12.4,
     "Column15": "701+",
     "Column16": 9,
     "Column17": "701+",
     "Column18": 1.2,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 56.4,
     "Column23": 673,
     "Column24": 8.8,
     "Column25": "701+",
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Baylor University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 9.1,
     "Column13": "601+",
     "Column14": 11.9,
     "Column15": "701+",
     "Column16": 7.5,
     "Column17": "701+",
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 3.8,
     "Column21": "701+",
     "Column22": 41.8,
     "Column23": "701+",
     "Column24": 11.6,
     "Column25": "701+",
     "Column26": 4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Bielefeld University",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 14.2,
     "Column11": "601+",
     "Column12": 3.3,
     "Column13": "601+",
     "Column14": 8,
     "Column15": "701+",
     "Column16": 6.7,
     "Column17": "701+",
     "Column18": 20,
     "Column19": 597,
     "Column20": 7,
     "Column21": "701+",
     "Column22": 67,
     "Column23": 524,
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 4.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Binghamton University SUNY",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 5.1,
     "Column13": "601+",
     "Column14": 5.7,
     "Column15": "701+",
     "Column16": 15.8,
     "Column17": 624,
     "Column18": 10.8,
     "Column19": "701+",
     "Column20": 10.3,
     "Column21": "701+",
     "Column22": 44.2,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Birmingham City University ",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.2,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 7.3,
     "Column15": "701+",
     "Column16": 3.2,
     "Column17": "701+",
     "Column18": 42,
     "Column19": 409,
     "Column20": 73.8,
     "Column21": 195,
     "Column22": 27.2,
     "Column23": "701+",
     "Column24": 6.5,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "BRAC University",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 11.6,
     "Column11": "601+",
     "Column12": 27.9,
     "Column13": 344,
     "Column14": 10,
     "Column15": "701+",
     "Column16": 2,
     "Column17": "701+",
     "Column18": 2,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 21.4,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Brigham Young University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 7.5,
     "Column11": "601+",
     "Column12": 7,
     "Column13": "601+",
     "Column14": 4.8,
     "Column15": "701+",
     "Column16": 10.4,
     "Column17": "701+",
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 3,
     "Column21": "701+",
     "Column22": 52.4,
     "Column23": "701+",
     "Column24": 22.2,
     "Column25": 520,
     "Column26": 3.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Canterbury Christ Church University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.1,
     "Column11": "601+",
     "Column12": 2.2,
     "Column13": "601+",
     "Column14": 4.7,
     "Column15": "701+",
     "Column16": 3.7,
     "Column17": "701+",
     "Column18": 34.5,
     "Column19": 465,
     "Column20": 87.6,
     "Column21": 136,
     "Column22": 17.7,
     "Column23": "701+",
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Chang Jung Christian University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 8.5,
     "Column11": "601+",
     "Column12": 4.6,
     "Column13": "601+",
     "Column14": 48.4,
     "Column15": 323,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 6.1,
     "Column19": "701+",
     "Column20": 4.4,
     "Column21": "701+",
     "Column22": 4.8,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Chung Yuan Christian University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 4.3,
     "Column15": "701+",
     "Column16": 15.6,
     "Column17": 626,
     "Column18": 3.9,
     "Column19": "701+",
     "Column20": 6.7,
     "Column21": "701+",
     "Column22": 15.1,
     "Column23": "701+",
     "Column24": 22.4,
     "Column25": 512,
     "Column26": 7.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Chungbuk National University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 34.1,
     "Column15": 463,
     "Column16": 10.3,
     "Column17": "701+",
     "Column18": 10.5,
     "Column19": "701+",
     "Column20": 7.7,
     "Column21": "701+",
     "Column22": 27.4,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 15.4,
     "Column27": "585=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "901-950",
     "Column3": "College of William and Mary",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.1,
     "Column11": "601+",
     "Column12": 9.1,
     "Column13": "601+",
     "Column14": 20.9,
     "Column15": 657,
     "Column16": 11.4,
     "Column17": "701+",
     "Column18": 6.3,
     "Column19": "701+",
     "Column20": 5.4,
     "Column21": "701+",
     "Column22": 33.1,
     "Column23": "701+",
     "Column24": 23.7,
     "Column25": 476,
     "Column26": 4.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Corvinus University of Budapest",
     "Column4": "HU",
     "Column5": "Hungary",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 10.3,
     "Column11": "601+",
     "Column12": 21.6,
     "Column13": 427,
     "Column14": 4.7,
     "Column15": "701+",
     "Column16": 3.1,
     "Column17": "701+",
     "Column18": 7.3,
     "Column19": "701+",
     "Column20": 34.8,
     "Column21": 393,
     "Column22": 19.3,
     "Column23": "701+",
     "Column24": 33.5,
     "Column25": 377,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Cracow University of Technology (Politechnika Krakowska)",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11,
     "Column11": "601+",
     "Column12": 21.3,
     "Column13": 431,
     "Column14": 21.7,
     "Column15": 642,
     "Column16": 4.7,
     "Column17": "701+",
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 46.5,
     "Column23": "701+",
     "Column24": 5.4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Dankook University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 4,
     "Column13": "601+",
     "Column14": 59.2,
     "Column15": 248,
     "Column16": 3,
     "Column17": "701+",
     "Column18": 4.2,
     "Column19": "701+",
     "Column20": 7.1,
     "Column21": "701+",
     "Column22": 13,
     "Column23": "701+",
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 4.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "EGE UNIVERSITY",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 10,
     "Column11": "601+",
     "Column12": 13.6,
     "Column13": 595,
     "Column14": 15.8,
     "Column15": "701+",
     "Column16": 3.2,
     "Column17": "701+",
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 7.8,
     "Column21": "701+",
     "Column22": 68.6,
     "Column23": 506,
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Escuela Superior Politécnica del Litoral (ESPOL)",
     "Column4": "EC",
     "Column5": "Ecuador",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 14.3,
     "Column11": "601+",
     "Column12": 8.4,
     "Column13": "601+",
     "Column14": 22.4,
     "Column15": 627,
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 12,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 27.5,
     "Column23": "701+",
     "Column24": 8.6,
     "Column25": "701+",
     "Column26": 22.7,
     "Column27": 509,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Fordham University ",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 6.8,
     "Column11": "601+",
     "Column12": 8.3,
     "Column13": "601+",
     "Column14": 14.8,
     "Column15": "701+",
     "Column16": 5.4,
     "Column17": "701+",
     "Column18": 6.3,
     "Column19": "701+",
     "Column20": 13.9,
     "Column21": 641,
     "Column22": 23.9,
     "Column23": "701+",
     "Column24": 29.1,
     "Column25": 427,
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "Gebze Technical University (GTU)",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 7.8,
     "Column15": "701+",
     "Column16": 18.5,
     "Column17": 574,
     "Column18": 6.2,
     "Column19": "701+",
     "Column20": 3.8,
     "Column21": "701+",
     "Column22": 37.3,
     "Column23": "701+",
     "Column24": 2.3,
     "Column25": "701+",
     "Column26": 3.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "George Mason University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.8,
     "Column11": "601+",
     "Column12": 4.8,
     "Column13": "601+",
     "Column14": 12.4,
     "Column15": "701+",
     "Column16": 9.2,
     "Column17": "701+",
     "Column18": 10,
     "Column19": "701+",
     "Column20": 15.3,
     "Column21": 616,
     "Column22": 60.9,
     "Column23": 623,
     "Column24": 11.9,
     "Column25": "701+",
     "Column26": 22.5,
     "Column27": "510=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Gifu University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 57.9,
     "Column15": 253,
     "Column16": 7.6,
     "Column17": "701+",
     "Column18": 4.3,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 20.6,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Glasgow Caledonian University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 5.4,
     "Column13": "601+",
     "Column14": 4.3,
     "Column15": "701+",
     "Column16": 6.3,
     "Column17": "701+",
     "Column18": 30.1,
     "Column19": 494,
     "Column20": 34.9,
     "Column21": 392,
     "Column22": 37.2,
     "Column23": "701+",
     "Column24": 6.8,
     "Column25": "701+",
     "Column26": 23.5,
     "Column27": "503=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Gunma University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.5,
     "Column11": "601+",
     "Column12": 1.9,
     "Column13": "601+",
     "Column14": 85.8,
     "Column15": 102,
     "Column16": 4.7,
     "Column17": "701+",
     "Column18": 4,
     "Column19": "701+",
     "Column20": 3.2,
     "Column21": "701+",
     "Column22": 8.7,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1401+",
     "Column3": "Guru Gobind Singh Indraprastha University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 6.6,
     "Column15": "701+",
     "Column16": 27.7,
     "Column17": 469,
     "Column18": 3.7,
     "Column19": "701+",
     "Column20": 5.1,
     "Column21": "701+",
     "Column22": 17.3,
     "Column23": "701+",
     "Column24": 6.4,
     "Column25": "701+",
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": 497,
     "Column2": 502,
     "Column3": "Jilin University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.1,
     "Column11": "601+",
     "Column12": 46.1,
     "Column13": 200,
     "Column14": 37.4,
     "Column15": 428,
     "Column16": 27.4,
     "Column17": 472,
     "Column18": 11.6,
     "Column19": "701+",
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 62.2,
     "Column23": 601,
     "Column24": 23.2,
     "Column25": 492,
     "Column26": 9.1,
     "Column27": "686=",
     "Column28": 24.2
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Nanjing Normal University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 15.8,
     "Column15": "701+",
     "Column16": 26.8,
     "Column17": 478,
     "Column20": 4.2,
     "Column21": "701+",
     "Column22": 53.3,
     "Column23": "701+",
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 2.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Howard University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 9.2,
     "Column13": "601+",
     "Column14": 38.9,
     "Column15": 403,
     "Column16": 3,
     "Column17": "701+",
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 4.1,
     "Column21": "701+",
     "Column22": 31.9,
     "Column23": "701+",
     "Column24": 18.8,
     "Column25": 588,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Hungarian University of Agriculture and Life Sciences",
     "Column4": "HU",
     "Column5": "Hungary",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 4.9,
     "Column13": "601+",
     "Column14": 30.4,
     "Column15": 514,
     "Column16": 3.2,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 29.2,
     "Column21": 437,
     "Column22": 53.5,
     "Column23": "701+",
     "Column24": 3.6,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Indiana University–Purdue University Indianapolis",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 4.5,
     "Column13": "601+",
     "Column14": 22.2,
     "Column15": 634,
     "Column16": 10,
     "Column17": "701+",
     "Column18": 28.8,
     "Column19": 503,
     "Column20": 6.6,
     "Column21": "701+",
     "Column22": 33.6,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 6.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "International Christian University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "C",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 11.5,
     "Column13": "601+",
     "Column14": 19.4,
     "Column15": 686,
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 76.3,
     "Column19": 254,
     "Column20": 4.5,
     "Column21": "701+",
     "Column22": 10.1,
     "Column23": "701+",
     "Column24": 12,
     "Column25": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "International Islamic University Islamabad (IIU)",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 10.7,
     "Column13": "601+",
     "Column14": 3.5,
     "Column15": "701+",
     "Column16": 8,
     "Column17": "701+",
     "Column18": 4.2,
     "Column19": "701+",
     "Column20": 6.1,
     "Column21": "701+",
     "Column22": 50.3,
     "Column23": "701+",
     "Column24": 4.3,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Islamic University of Madinah",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 1.9,
     "Column13": "601+",
     "Column14": 6.6,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 36.3,
     "Column19": 440,
     "Column20": 100,
     "Column21": 5,
     "Column22": 10.3,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "ITESO, Universidad Jesuita de Guadalajara",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 72.8,
     "Column15": 181,
     "Column16": 1,
     "Column17": "701+",
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 3.5,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Jiangnan University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 3.3,
     "Column13": "601+",
     "Column14": 20,
     "Column15": 672,
     "Column16": 26.7,
     "Column17": 479,
     "Column22": 44.4,
     "Column23": "701+",
     "Column24": 1.4,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Kagoshima University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 63.6,
     "Column15": 216,
     "Column16": 3.5,
     "Column17": "701+",
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 2.8,
     "Column21": "701+",
     "Column22": 26.6,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Kangwon National University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.8,
     "Column11": "601+",
     "Column12": 4.7,
     "Column13": "601+",
     "Column14": 22.2,
     "Column15": 632,
     "Column16": 8.2,
     "Column17": "701+",
     "Column18": 4.7,
     "Column19": "701+",
     "Column20": 2.6,
     "Column21": "701+",
     "Column22": 27.6,
     "Column23": "701+",
     "Column24": 2.8,
     "Column25": "701+",
     "Column26": 34.5,
     "Column27": "411=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Kazakh-British Technical University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 14.4,
     "Column11": "601+",
     "Column12": 28.2,
     "Column13": 339,
     "Column14": 16.1,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 6,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 13.8,
     "Column23": "701+",
     "Column24": 3.4,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Kent State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 6.2,
     "Column15": "701+",
     "Column16": 12.7,
     "Column17": 674,
     "Column18": 19.2,
     "Column19": 611,
     "Column20": 6.6,
     "Column21": "701+",
     "Column22": 57.1,
     "Column23": 660,
     "Column24": 7.6,
     "Column25": "701+",
     "Column26": 2.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Kyushu Institute of Technology ",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.6,
     "Column11": "601+",
     "Column12": 5.4,
     "Column13": "601+",
     "Column14": 9.6,
     "Column15": "701+",
     "Column16": 25.6,
     "Column17": 491,
     "Column18": 7.1,
     "Column19": "701+",
     "Column20": 3.5,
     "Column21": "701+",
     "Column22": 20.6,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Leeds Beckett University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 5.9,
     "Column13": "601+",
     "Column14": 3.8,
     "Column15": "701+",
     "Column16": 7.5,
     "Column17": "701+",
     "Column18": 39.1,
     "Column19": 424,
     "Column20": 27.4,
     "Column21": 452,
     "Column22": 24.8,
     "Column23": "701+",
     "Column24": 8.5,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Lobachevsky University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.9,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 41.1,
     "Column15": 382,
     "Column16": 2,
     "Column17": "701+",
     "Column18": 2,
     "Column19": "701+",
     "Column20": 22.3,
     "Column21": 514,
     "Column22": 18.1,
     "Column23": "701+",
     "Column24": 3.2,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Lodz University of Technology",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 46.2,
     "Column15": 335,
     "Column16": 8.9,
     "Column17": "701+",
     "Column18": 3.3,
     "Column19": "701+",
     "Column20": 4,
     "Column21": "701+",
     "Column22": 50.7,
     "Column23": "701+",
     "Column24": 3.2,
     "Column25": "701+",
     "Column26": 4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "901-950",
     "Column3": "London Metropolitan University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 3.8,
     "Column15": "701+",
     "Column16": 3.3,
     "Column17": "701+",
     "Column18": 59.5,
     "Column19": 327,
     "Column20": 100,
     "Column21": 22,
     "Column22": 16.1,
     "Column23": "701+",
     "Column24": 13.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "901-950",
     "Column3": "Loyola University Chicago",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 8.5,
     "Column11": "601+",
     "Column12": 17.3,
     "Column13": 497,
     "Column14": 23.2,
     "Column15": 620,
     "Column16": 7,
     "Column17": "701+",
     "Column18": 6.5,
     "Column19": "701+",
     "Column20": 3,
     "Column21": "701+",
     "Column22": 39.5,
     "Column23": "701+",
     "Column24": 13.2,
     "Column25": "701+",
     "Column26": 1.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Lviv Polytechnic National University",
     "Column4": "UA",
     "Column5": "Ukraine",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.4,
     "Column11": "601+",
     "Column12": 13.5,
     "Column13": 598,
     "Column14": 28.4,
     "Column15": 546,
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 18.9,
     "Column23": "701+",
     "Column24": 15.2,
     "Column25": 654,
     "Column26": 11.5,
     "Column27": "632=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "Marmara University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.1,
     "Column11": "601+",
     "Column12": 11.4,
     "Column13": "601+",
     "Column14": 4.4,
     "Column15": "701+",
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 4.2,
     "Column21": "701+",
     "Column22": 45.3,
     "Column23": "701+",
     "Column24": 23.9,
     "Column25": 474,
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Mississippi State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.2,
     "Column11": "601+",
     "Column12": 3.6,
     "Column13": "601+",
     "Column14": 7.4,
     "Column15": "701+",
     "Column16": 17.4,
     "Column17": 592,
     "Column18": 8.9,
     "Column19": "701+",
     "Column20": 3.1,
     "Column21": "701+",
     "Column22": 50.6,
     "Column23": "701+",
     "Column24": 7.1,
     "Column25": "701+",
     "Column26": 4.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Multimedia University (MMU)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 11,
     "Column11": "601+",
     "Column12": 15.4,
     "Column13": 549,
     "Column14": 10.9,
     "Column15": "701+",
     "Column16": 4.7,
     "Column17": "701+",
     "Column18": 28.5,
     "Column19": 504,
     "Column20": 30.3,
     "Column21": 426,
     "Column22": 19.6,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 3.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Mustansiriyah University",
     "Column4": "IQ",
     "Column5": "Iraq",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 8.4,
     "Column13": "601+",
     "Column14": 51.2,
     "Column15": 299,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 35.7,
     "Column19": 446,
     "Column20": 3.6,
     "Column21": "701+",
     "Column22": 14,
     "Column23": "701+",
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Mutah University",
     "Column4": "JO",
     "Column5": "Jordan",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 9.1,
     "Column11": "601+",
     "Column12": 11,
     "Column13": "601+",
     "Column14": 11,
     "Column15": "701+",
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 10.1,
     "Column19": "701+",
     "Column20": 32.5,
     "Column21": 410,
     "Column22": 25.7,
     "Column23": "701+",
     "Column24": 7.3,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Mykolas Romeris University",
     "Column4": "LT",
     "Column5": "Lithuania",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.2,
     "Column11": "601+",
     "Column12": 8.7,
     "Column13": "601+",
     "Column14": 13.9,
     "Column15": "701+",
     "Column16": 4.5,
     "Column17": "701+",
     "Column18": 14,
     "Column19": 685,
     "Column20": 35.3,
     "Column21": 387,
     "Column22": 15,
     "Column23": "701+",
     "Column24": 47.2,
     "Column25": 273,
     "Column26": 11.8,
     "Column27": "623=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Southwest University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3,
     "Column11": "601+",
     "Column12": 1.9,
     "Column13": "601+",
     "Column14": 13.3,
     "Column15": "701+",
     "Column16": 23.9,
     "Column17": 508,
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 44.1,
     "Column23": "701+",
     "Column24": 10.6,
     "Column25": "701+",
     "Column26": 2.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "Narxoz University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 9.6,
     "Column11": "601+",
     "Column12": 7.5,
     "Column13": "601+",
     "Column14": 12.9,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 14.9,
     "Column19": 667,
     "Column20": 6.2,
     "Column21": "701+",
     "Column22": 1,
     "Column23": "701+",
     "Column24": 60.3,
     "Column25": 200,
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "901-950",
     "Column3": "National Chung Cheng University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.5,
     "Column11": "601+",
     "Column12": 24.9,
     "Column13": 382,
     "Column14": 14.5,
     "Column15": "701+",
     "Column16": 4.9,
     "Column17": "701+",
     "Column18": 4.7,
     "Column19": "701+",
     "Column20": 3.5,
     "Column21": "701+",
     "Column22": 21.4,
     "Column23": "701+",
     "Column24": 2.7,
     "Column25": "701+",
     "Column26": 9.9,
     "Column27": "667=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "901-950",
     "Column3": "National Technical University \"Kharkiv Polytechnic Institute\"",
     "Column4": "UA",
     "Column5": "Ukraine",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.5,
     "Column11": "601+",
     "Column12": 10.6,
     "Column13": "601+",
     "Column14": 54.3,
     "Column15": 280,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 20.1,
     "Column21": 538,
     "Column22": 11.4,
     "Column23": "701+",
     "Column24": 4,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "National University of Kyiv-Mohyla Academy (NaUKMA)",
     "Column4": "UA",
     "Column5": "Ukraine",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.1,
     "Column11": "601+",
     "Column12": 10.6,
     "Column13": "601+",
     "Column14": 44.2,
     "Column15": 351,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 6.3,
     "Column23": "701+",
     "Column24": 13.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "NJSC KIMEP University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "S",
     "Column7": "SP",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 5.9,
     "Column11": "601+",
     "Column12": 14.3,
     "Column13": 576,
     "Column14": 13.8,
     "Column15": "701+",
     "Column16": 4,
     "Column17": "701+",
     "Column18": 75.6,
     "Column19": 258,
     "Column20": 15.7,
     "Column21": 611,
     "Column22": 2.5,
     "Column23": "701+",
     "Column24": 27.6,
     "Column25": 437,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Northern Arizona University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 6.2,
     "Column13": "601+",
     "Column14": 5.1,
     "Column15": "701+",
     "Column16": 13.1,
     "Column17": 665,
     "Column18": 3,
     "Column19": "701+",
     "Column20": 3.1,
     "Column21": "701+",
     "Column22": 55.1,
     "Column23": 695,
     "Column24": 4.6,
     "Column25": "701+",
     "Column26": 6.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Northwest University (China)",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4,
     "Column11": "601+",
     "Column12": 1.4,
     "Column13": "601+",
     "Column14": 48.8,
     "Column15": 321,
     "Column16": 17.2,
     "Column17": 596,
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 2.6,
     "Column21": "701+",
     "Column22": 33.9,
     "Column23": "701+",
     "Column24": 3,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "O.P. Jindal Global University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 8.8,
     "Column11": "601+",
     "Column12": 10,
     "Column13": "601+",
     "Column14": 53.8,
     "Column15": 282,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 18,
     "Column19": 628,
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 8.4,
     "Column23": "701+",
     "Column24": 3.2,
     "Column25": "701+",
     "Column26": 2.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Ohio University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.1,
     "Column11": "601+",
     "Column12": 5.5,
     "Column13": "601+",
     "Column14": 5.7,
     "Column15": "701+",
     "Column16": 10.5,
     "Column17": "701+",
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 41.4,
     "Column23": "701+",
     "Column24": 16.9,
     "Column25": 616,
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Panjab University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.8,
     "Column11": "601+",
     "Column12": 7.3,
     "Column13": "601+",
     "Column14": 6.2,
     "Column15": "701+",
     "Column16": 24.1,
     "Column17": 507,
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 30.9,
     "Column23": "701+",
     "Column24": 31.8,
     "Column25": 398,
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Paul Valéry University Montpellier",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.8,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 2.9,
     "Column15": "701+",
     "Column16": 5.3,
     "Column17": "701+",
     "Column18": 5,
     "Column19": "701+",
     "Column20": 32.2,
     "Column21": 411,
     "Column22": 48.3,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 2.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Pontifícia Universidade Católica de São Paulo",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 10.1,
     "Column11": "601+",
     "Column12": 14.8,
     "Column13": 562,
     "Column14": 37.6,
     "Column15": 426,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 1.8,
     "Column23": "701+",
     "Column24": 34.1,
     "Column25": 369,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Pozna? University of Technology",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.8,
     "Column11": "601+",
     "Column12": 6.8,
     "Column13": "601+",
     "Column14": 31.6,
     "Column15": 501,
     "Column16": 6.3,
     "Column17": "701+",
     "Column18": 2.3,
     "Column19": "701+",
     "Column20": 5.7,
     "Column21": "701+",
     "Column22": 44.9,
     "Column23": "701+",
     "Column24": 5.2,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Rochester Institute of Technology (RIT)",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 7.2,
     "Column11": "601+",
     "Column12": 10.7,
     "Column13": "601+",
     "Column14": 19.2,
     "Column15": 695,
     "Column16": 6.3,
     "Column17": "701+",
     "Column18": 4.7,
     "Column19": "701+",
     "Column20": 16.1,
     "Column21": 604,
     "Column22": 36.8,
     "Column23": "701+",
     "Column24": 9,
     "Column25": "701+",
     "Column26": 34.5,
     "Column27": "411=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Russian Presidential Academy of National Economy and Public Administration",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "XL",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 7.6,
     "Column13": "601+",
     "Column14": 47.8,
     "Column15": 327,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column20": 2.8,
     "Column21": "701+",
     "Column22": 17.7,
     "Column23": "701+",
     "Column24": 41.8,
     "Column25": 309,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Saint Petersburg Electrotechnical University ETU-LETI",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 3.6,
     "Column13": "601+",
     "Column14": 46.4,
     "Column15": 332,
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 4.2,
     "Column19": "701+",
     "Column20": 46.3,
     "Column21": 319,
     "Column22": 14.6,
     "Column23": "701+",
     "Column24": 5.6,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Saint-Petersburg Mining University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.6,
     "Column11": "601+",
     "Column12": 3.2,
     "Column13": "601+",
     "Column14": 64.1,
     "Column15": 213,
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 21.1,
     "Column19": 586,
     "Column20": 29.3,
     "Column21": 436,
     "Column22": 6.5,
     "Column23": "701+",
     "Column24": 8,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "901-950",
     "Column3": "Samara National Research University (Samara University)",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 2.2,
     "Column13": "601+",
     "Column14": 75.4,
     "Column15": 165,
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 12.3,
     "Column21": 673,
     "Column22": 17,
     "Column23": "701+",
     "Column24": 9.1,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "San Diego State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 14.3,
     "Column17": 648,
     "Column18": 5.2,
     "Column19": "701+",
     "Column20": 3.5,
     "Column21": "701+",
     "Column22": 68.7,
     "Column23": 502,
     "Column24": 15.8,
     "Column25": 639,
     "Column26": 9.4,
     "Column27": "680=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Sheffield Hallam University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.7,
     "Column11": "601+",
     "Column12": 8.6,
     "Column13": "601+",
     "Column14": 9.5,
     "Column15": "701+",
     "Column16": 4.5,
     "Column17": "701+",
     "Column18": 33.6,
     "Column19": 473,
     "Column20": 33.1,
     "Column21": 405,
     "Column22": 50.4,
     "Column23": "701+",
     "Column24": 8.6,
     "Column25": "701+",
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Shinshu University ",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.6,
     "Column11": "601+",
     "Column12": 3.2,
     "Column13": "601+",
     "Column14": 34.9,
     "Column15": 455,
     "Column16": 11.2,
     "Column17": "701+",
     "Column18": 5.5,
     "Column19": "701+",
     "Column20": 2.6,
     "Column21": "701+",
     "Column22": 24.1,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Siberian Federal University, SibFU",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.8,
     "Column11": "601+",
     "Column12": 4.5,
     "Column13": "601+",
     "Column14": 18.9,
     "Column15": "701+",
     "Column16": 3.8,
     "Column17": "701+",
     "Column18": 46.3,
     "Column19": 387,
     "Column20": 31.6,
     "Column21": 417,
     "Column22": 32.6,
     "Column23": "701+",
     "Column24": 8,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Silesian University of Technology",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.8,
     "Column11": "601+",
     "Column12": 3.8,
     "Column13": "601+",
     "Column14": 30.3,
     "Column15": 516,
     "Column16": 7,
     "Column17": "701+",
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 63.4,
     "Column23": 581,
     "Column24": 9.5,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column3": "Slovak University of Agriculture in Nitra",
     "Column4": "SK",
     "Column5": "Slovakia",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.5,
     "Column11": "601+",
     "Column12": 9,
     "Column13": "601+",
     "Column14": 25.8,
     "Column15": 576,
     "Column16": 7.2,
     "Column17": "701+",
     "Column18": 7.1,
     "Column19": "701+",
     "Column20": 4.4,
     "Column21": "701+",
     "Column22": 29,
     "Column23": "701+",
     "Column24": 4.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Slovak University of Technology in Bratislava",
     "Column4": "SK",
     "Column5": "Slovakia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.1,
     "Column11": "601+",
     "Column12": 9,
     "Column13": "601+",
     "Column14": 38.8,
     "Column15": 408,
     "Column16": 3.8,
     "Column17": "701+",
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 6.5,
     "Column21": "701+",
     "Column22": 48.3,
     "Column23": "701+",
     "Column24": 15,
     "Column25": 664,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column3": "Sohar University",
     "Column4": "OM",
     "Column5": "Oman",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 5,
     "Column13": "601+",
     "Column14": 2.2,
     "Column15": "701+",
     "Column16": 9,
     "Column17": "701+",
     "Column18": 100,
     "Column19": 38,
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 9,
     "Column23": "701+",
     "Column24": 53.7,
     "Column25": 233,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Soonchunhyang University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 5.6,
     "Column11": "601+",
     "Column12": 4.7,
     "Column13": "601+",
     "Column14": 55.2,
     "Column15": 274,
     "Column16": 5.7,
     "Column17": "701+",
     "Column18": 5.5,
     "Column19": "701+",
     "Column20": 3.9,
     "Column21": "701+",
     "Column22": 11.7,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 6.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Southern Methodist University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 3.6,
     "Column13": "601+",
     "Column14": 29.3,
     "Column15": 529,
     "Column16": 7.2,
     "Column17": "701+",
     "Column18": 6.8,
     "Column19": "701+",
     "Column20": 8.7,
     "Column21": "701+",
     "Column22": 30.2,
     "Column23": "701+",
     "Column24": 38.8,
     "Column25": 328,
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "Hohai University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 1.5,
     "Column13": "601+",
     "Column14": 7.9,
     "Column15": "701+",
     "Column16": 16.5,
     "Column17": 614,
     "Column22": 70.7,
     "Column23": 474,
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "SRM INSTITUTE OF SCIENCE AND TECHNOLOGY",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 8.6,
     "Column11": "601+",
     "Column12": 16,
     "Column13": 533,
     "Column14": 13.4,
     "Column15": "701+",
     "Column16": 4,
     "Column17": "701+",
     "Column18": 1.5,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 56.2,
     "Column23": 675,
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 12.7,
     "Column27": "615=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Sumy State University",
     "Column4": "UA",
     "Column5": "Ukraine",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 35.1,
     "Column15": 451,
     "Column16": 2.5,
     "Column17": "701+",
     "Column18": 3.1,
     "Column19": "701+",
     "Column20": 40.5,
     "Column21": 352,
     "Column22": 28.9,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 7.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "901-950",
     "Column3": "Sungshin Women's University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 5.6,
     "Column11": "601+",
     "Column12": 8.3,
     "Column13": "601+",
     "Column14": 81.9,
     "Column15": 122,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 2.7,
     "Column19": "701+",
     "Column20": 4.3,
     "Column21": "701+",
     "Column22": 4.2,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Széchenyi István University",
     "Column4": "HU",
     "Column5": "Hungary",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "C",
     "Column10": 9.2,
     "Column11": "601+",
     "Column12": 31.2,
     "Column13": 300,
     "Column14": 25,
     "Column15": 593,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 3.4,
     "Column19": "701+",
     "Column20": 6.7,
     "Column21": "701+",
     "Column22": 7.4,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 3.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Taibah University",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 4.5,
     "Column15": "701+",
     "Column16": 3.6,
     "Column17": "701+",
     "Column18": 94.4,
     "Column19": 156,
     "Column20": 2,
     "Column21": "701+",
     "Column22": 38.6,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Tallinn University ",
     "Column4": "EE",
     "Column5": "Estonia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9,
     "Column11": "601+",
     "Column12": 4.3,
     "Column13": "601+",
     "Column14": 7.9,
     "Column15": "701+",
     "Column16": 5.5,
     "Column17": "701+",
     "Column18": 43.8,
     "Column19": 403,
     "Column20": 19.4,
     "Column21": 555,
     "Column22": 49.4,
     "Column23": "701+",
     "Column24": 31,
     "Column25": 406,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Technical University of Kosice",
     "Column4": "SK",
     "Column5": "Slovakia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.5,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 19.8,
     "Column15": 679,
     "Column16": 5.5,
     "Column17": "701+",
     "Column18": 1.5,
     "Column19": "701+",
     "Column20": 56.2,
     "Column21": 268,
     "Column22": 37.2,
     "Column23": "701+",
     "Column24": 4.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Tecnológico de Costa Rica -TEC",
     "Column4": "CR",
     "Column5": "Costa Rica",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.4,
     "Column11": "601+",
     "Column12": 21,
     "Column13": 438,
     "Column14": 21.4,
     "Column15": 647,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 7,
     "Column19": "701+",
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 12.4,
     "Column23": "701+",
     "Column24": 8.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Telkom University",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 8.7,
     "Column11": "601+",
     "Column12": 12.1,
     "Column13": "601+",
     "Column14": 21.3,
     "Column15": 651,
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 35.5,
     "Column19": 452,
     "Column20": 6.5,
     "Column21": "701+",
     "Column22": 10,
     "Column23": "701+",
     "Column24": 5.7,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "The Hashemite University",
     "Column4": "JO",
     "Column5": "Jordan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9.4,
     "Column11": "601+",
     "Column12": 14.6,
     "Column13": 566,
     "Column14": 4.2,
     "Column15": "701+",
     "Column16": 3.6,
     "Column17": "701+",
     "Column18": 7.2,
     "Column19": "701+",
     "Column20": 14.6,
     "Column21": 632,
     "Column22": 39.1,
     "Column23": "701+",
     "Column24": 3.6,
     "Column25": "701+",
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "The University of Northampton",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.2,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 36.3,
     "Column19": 439,
     "Column20": 96.6,
     "Column21": 82,
     "Column22": 8.4,
     "Column23": "701+",
     "Column24": 4.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "The University of Texas at Arlington",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.6,
     "Column11": "601+",
     "Column12": 5.1,
     "Column13": "601+",
     "Column14": 4.5,
     "Column15": "701+",
     "Column16": 9.8,
     "Column17": "701+",
     "Column18": 1.9,
     "Column19": "701+",
     "Column20": 29.6,
     "Column21": 433,
     "Column22": 61.6,
     "Column23": 608,
     "Column24": 8.1,
     "Column25": "701+",
     "Column26": 40.4,
     "Column27": "363=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Tokushima University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 64,
     "Column15": 214,
     "Column16": 5.6,
     "Column17": "701+",
     "Column18": 2.5,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 18.4,
     "Column23": "701+",
     "Column24": 4.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Tokyo Metropolitan University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.8,
     "Column11": "601+",
     "Column12": 5.5,
     "Column13": "601+",
     "Column14": 32,
     "Column15": 494,
     "Column16": 11.4,
     "Column17": "701+",
     "Column18": 7.3,
     "Column19": "701+",
     "Column20": 4.5,
     "Column21": "701+",
     "Column22": 29.5,
     "Column23": "701+",
     "Column24": 4,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad Andrés Bello",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 12.3,
     "Column11": "601+",
     "Column12": 7.4,
     "Column13": "601+",
     "Column14": 6.9,
     "Column15": "701+",
     "Column16": 2.3,
     "Column17": "701+",
     "Column18": 7.1,
     "Column19": "701+",
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 51.2,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad Austral de Chile",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 13.9,
     "Column11": "601+",
     "Column12": 8,
     "Column13": "601+",
     "Column14": 3.1,
     "Column15": "701+",
     "Column16": 7.3,
     "Column17": "701+",
     "Column18": 15.6,
     "Column19": 658,
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 45.4,
     "Column23": "701+",
     "Column24": 3.5,
     "Column25": "701+",
     "Column26": 5.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad Autónoma Chapingo",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.7,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 80.2,
     "Column15": 135,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 3.8,
     "Column23": "701+",
     "Column24": 4.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad Autónoma de Nuevo León",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 14.5,
     "Column11": 599,
     "Column12": 8.5,
     "Column13": "601+",
     "Column14": 2.3,
     "Column15": "701+",
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 35.9,
     "Column23": "701+",
     "Column24": 13.4,
     "Column25": "701+",
     "Column26": 10.6,
     "Column27": "653=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad Autónoma del Estado de México (UAEMex)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 19.3,
     "Column11": 466,
     "Column12": 8.2,
     "Column13": "601+",
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 3,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 16,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 4.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Universidad de Castilla-La Mancha",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.9,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 16.9,
     "Column15": "701+",
     "Column16": 9.7,
     "Column17": "701+",
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 5.2,
     "Column21": "701+",
     "Column22": 71.6,
     "Column23": 458,
     "Column24": 4.9,
     "Column25": "701+",
     "Column26": 8.8,
     "Column27": "692=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "851-900",
     "Column3": "Universidad de Guadalajara (UDG)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 18.9,
     "Column11": 477,
     "Column12": 7.5,
     "Column13": "601+",
     "Column14": 17.1,
     "Column15": "701+",
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 47.1,
     "Column23": "701+",
     "Column24": 5.8,
     "Column25": "701+",
     "Column26": 6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "Universidad de La Frontera (UFRO)",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.3,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 4.1,
     "Column15": "701+",
     "Column16": 9.3,
     "Column17": "701+",
     "Column18": 6,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 53.3,
     "Column23": "701+",
     "Column24": 2.3,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Universidad de las Américas Puebla (UDLAP)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 7.9,
     "Column11": "601+",
     "Column12": 9.1,
     "Column13": "601+",
     "Column14": 44.2,
     "Column15": 352,
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 21.5,
     "Column19": 578,
     "Column20": 7.4,
     "Column21": "701+",
     "Column22": 6.9,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad de Lima",
     "Column4": "PE",
     "Column5": "Peru",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 9.6,
     "Column11": "601+",
     "Column12": 26.2,
     "Column13": 363,
     "Column14": 3.8,
     "Column15": "701+",
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 8.7,
     "Column23": "701+",
     "Column24": 20.9,
     "Column25": 549,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad de Los Andes - (ULA) Mérida",
     "Column4": "VE",
     "Column5": "Venezuela",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 14.6,
     "Column11": 595,
     "Column12": 8.5,
     "Column13": "601+",
     "Column14": 25.4,
     "Column15": 585,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 15.5,
     "Column23": "701+",
     "Column24": 18.8,
     "Column25": 587,
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad de Oviedo",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 12.5,
     "Column11": "601+",
     "Column12": 3.7,
     "Column13": "601+",
     "Column14": 20,
     "Column15": 674,
     "Column16": 6.5,
     "Column17": "701+",
     "Column18": 4.1,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 70.7,
     "Column23": 473,
     "Column24": 8.5,
     "Column25": "701+",
     "Column26": 5.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad de Puerto Rico",
     "Column4": "PR",
     "Column5": "Puerto Rico",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 10.2,
     "Column11": "601+",
     "Column12": 6.8,
     "Column13": "601+",
     "Column14": 14.4,
     "Column15": "701+",
     "Column16": 2,
     "Column17": "701+",
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 39.4,
     "Column23": "701+",
     "Column24": 62.2,
     "Column25": 192,
     "Column26": 2.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad de Talca",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.8,
     "Column11": "601+",
     "Column12": 5.6,
     "Column13": "601+",
     "Column14": 6.4,
     "Column15": "701+",
     "Column16": 6.2,
     "Column17": "701+",
     "Column18": 21.4,
     "Column19": 581,
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 35.7,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad del Norte ",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 9.6,
     "Column11": "601+",
     "Column12": 11.9,
     "Column13": "601+",
     "Column14": 10.8,
     "Column15": "701+",
     "Column16": 2.9,
     "Column17": "701+",
     "Column18": 7.7,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 29.6,
     "Column23": "701+",
     "Column24": 7.6,
     "Column25": "701+",
     "Column26": 21.3,
     "Column27": "521=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad del Pacífico",
     "Column4": "PE",
     "Column5": "Peru",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 28,
     "Column13": 342,
     "Column14": 3,
     "Column15": "701+",
     "Column16": 3.1,
     "Column17": "701+",
     "Column18": 9.4,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 5.3,
     "Column23": "701+",
     "Column24": 16.6,
     "Column25": 623,
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad del Valle",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 18.1,
     "Column11": 500,
     "Column12": 8.2,
     "Column13": "601+",
     "Column14": 7.7,
     "Column15": "701+",
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 20.5,
     "Column23": "701+",
     "Column24": 21.4,
     "Column25": 535,
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Universidad Diego Portales (UDP)",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 14.7,
     "Column11": 591,
     "Column12": 14.9,
     "Column13": 560,
     "Column14": 8.6,
     "Column15": "701+",
     "Column16": 3.7,
     "Column17": "701+",
     "Column18": 13.1,
     "Column19": "701+",
     "Column20": 2.9,
     "Column21": "701+",
     "Column22": 39.6,
     "Column23": "701+",
     "Column24": 6.2,
     "Column25": "701+",
     "Column26": 2.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "901-950",
     "Column3": "Universidad ICESI",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 5.7,
     "Column11": "601+",
     "Column12": 6.1,
     "Column13": "601+",
     "Column14": 78.6,
     "Column15": 146,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 3.7,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 8.7,
     "Column23": "701+",
     "Column24": 9.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad Nacional de Cuyo",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 12.9,
     "Column11": "601+",
     "Column12": 6.1,
     "Column13": "601+",
     "Column14": 23,
     "Column15": 623,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column20": 13.9,
     "Column21": 643,
     "Column22": 27.4,
     "Column23": "701+",
     "Column24": 10.3,
     "Column25": "701+",
     "Column26": 8.4,
     "Column27": "698=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad Nacional de la Asunción",
     "Column4": "PY",
     "Column5": "Paraguay",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 31.7,
     "Column15": 500,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 11.8,
     "Column23": "701+",
     "Column24": 69,
     "Column25": 166,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad Nacional de San Luis",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 1.4,
     "Column13": "601+",
     "Column14": 73.8,
     "Column15": 174,
     "Column16": 1.2,
     "Column17": "701+",
     "Column22": 9.8,
     "Column23": "701+",
     "Column24": 1.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad Nacional del Centro de la Provincia de Buenos Aires (UNICEN)",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 76.8,
     "Column15": 154,
     "Column16": 1.3,
     "Column17": "701+",
     "Column22": 13.9,
     "Column23": "701+",
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Universidad Peruana Cayetano Heredia (UPCH)",
     "Column4": "PE",
     "Column5": "Peru",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 9.5,
     "Column11": "601+",
     "Column12": 8.5,
     "Column13": "601+",
     "Column14": 45.5,
     "Column15": 340,
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 28.9,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 9.8,
     "Column27": "672=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Universidad Rey Juan Carlos",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.9,
     "Column11": "601+",
     "Column12": 8.3,
     "Column13": "601+",
     "Column14": 7.2,
     "Column15": "701+",
     "Column16": 4.5,
     "Column17": "701+",
     "Column18": 16.5,
     "Column19": 645,
     "Column20": 12.4,
     "Column21": 669,
     "Column22": 62.9,
     "Column23": 589,
     "Column24": 7,
     "Column25": "701+",
     "Column26": 24,
     "Column27": "498=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Universidad Simón Bolívar (USB)",
     "Column4": "VE",
     "Column5": "Venezuela",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13.7,
     "Column11": "601+",
     "Column12": 15.9,
     "Column13": 535,
     "Column14": 14.4,
     "Column15": "701+",
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 1,
     "Column21": "701+",
     "Column22": 11.5,
     "Column23": "701+",
     "Column24": 11.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Universidad Tecnológica de Panamá (UTP)",
     "Column4": "PA",
     "Column5": "Panama",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 7.8,
     "Column11": "601+",
     "Column12": 17.8,
     "Column13": 493,
     "Column14": 27.2,
     "Column15": 556,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 4.1,
     "Column19": "701+",
     "Column20": 3.9,
     "Column21": "701+",
     "Column22": 4.9,
     "Column23": "701+",
     "Column24": 6.1,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidad Tecnológica Nacional (UTN)",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "XL",
     "Column7": "SP",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 14.3,
     "Column13": 575,
     "Column14": 49.7,
     "Column15": 314,
     "Column16": 1.1,
     "Column17": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 11.4,
     "Column23": "701+",
     "Column24": 6.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidade da Coruña ",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.9,
     "Column11": "601+",
     "Column12": 4.8,
     "Column13": "601+",
     "Column14": 24.2,
     "Column15": 606,
     "Column16": 7.3,
     "Column17": "701+",
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 4.5,
     "Column21": "701+",
     "Column22": 56.1,
     "Column23": 676,
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 23.2,
     "Column27": "505=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidade Federal de Pernambuco (UFPE) ",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 11.6,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 15.5,
     "Column15": "701+",
     "Column16": 4.1,
     "Column17": "701+",
     "Column18": 1.5,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 55.1,
     "Column23": 696,
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidade Federal de São Carlos (UFSCar)",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.8,
     "Column11": "601+",
     "Column12": 6.4,
     "Column13": "601+",
     "Column14": 15.5,
     "Column15": "701+",
     "Column16": 17.8,
     "Column17": 585,
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 45.5,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universidade Federal do Paraná - UFPR",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 13,
     "Column11": "601+",
     "Column12": 5.8,
     "Column13": "601+",
     "Column14": 20.1,
     "Column15": 669,
     "Column16": 5.2,
     "Column17": "701+",
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 6,
     "Column21": "701+",
     "Column22": 52.6,
     "Column23": "701+",
     "Column24": 9.6,
     "Column25": "701+",
     "Column26": 2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "Universidade Federal Fluminense ",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9.6,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 12.8,
     "Column15": "701+",
     "Column16": 3.6,
     "Column17": "701+",
     "Column18": 6.3,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 52.2,
     "Column23": "701+",
     "Column24": 10.9,
     "Column25": "701+",
     "Column26": 13.1,
     "Column27": "613=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universita' degli Studi \"G. d'Annunzio\" Chieti Pescara",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.7,
     "Column11": "601+",
     "Column12": 1.6,
     "Column13": "601+",
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 29.3,
     "Column17": 443,
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 2.8,
     "Column21": "701+",
     "Column22": 58.5,
     "Column23": 651,
     "Column24": 1.2,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Università degli Studi di Udine",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 11.3,
     "Column15": "701+",
     "Column16": 16.7,
     "Column17": 611,
     "Column18": 4.2,
     "Column19": "701+",
     "Column20": 3.7,
     "Column21": "701+",
     "Column22": 53.5,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 4.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Università del Salento",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 1.7,
     "Column13": "601+",
     "Column14": 6.9,
     "Column15": "701+",
     "Column16": 21.7,
     "Column17": 535,
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 65.2,
     "Column23": 551,
     "Column24": 1.6,
     "Column25": "701+",
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universitas Hasanuddin",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 14.3,
     "Column11": "601+",
     "Column12": 13,
     "Column13": "601+",
     "Column14": 22.2,
     "Column15": 633,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 8.8,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 15.9,
     "Column23": "701+",
     "Column24": 15,
     "Column25": 661,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universitas Sebelas Maret",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 12.9,
     "Column11": "601+",
     "Column12": 16.3,
     "Column13": 520,
     "Column14": 16,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 17.1,
     "Column19": 640,
     "Column20": 2.8,
     "Column21": "701+",
     "Column22": 14.9,
     "Column23": "701+",
     "Column24": 2.4,
     "Column25": "701+",
     "Column26": 9.3,
     "Column27": "683=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universitat de Lleida",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 2,
     "Column13": "601+",
     "Column14": 19.2,
     "Column15": 691,
     "Column16": 13,
     "Column17": 666,
     "Column18": 5.5,
     "Column19": "701+",
     "Column20": 9.6,
     "Column21": "701+",
     "Column22": 51.8,
     "Column23": "701+",
     "Column24": 3,
     "Column25": "701+",
     "Column26": 38.5,
     "Column27": "381=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universität Siegen",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 1.7,
     "Column13": "601+",
     "Column14": 9.2,
     "Column15": "701+",
     "Column16": 6.8,
     "Column17": "701+",
     "Column18": 57.7,
     "Column19": 332,
     "Column20": 28,
     "Column21": 444,
     "Column22": 40,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Université de Caen Normandie",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.8,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 6.6,
     "Column15": "701+",
     "Column16": 6.3,
     "Column17": "701+",
     "Column18": 8.1,
     "Column19": "701+",
     "Column20": 6.2,
     "Column21": "701+",
     "Column22": 74.6,
     "Column23": 415,
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Université de Poitiers",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.9,
     "Column11": "601+",
     "Column12": 1.7,
     "Column13": "601+",
     "Column14": 5.4,
     "Column15": "701+",
     "Column16": 5.2,
     "Column17": "701+",
     "Column20": 26.4,
     "Column21": 458,
     "Column22": 75.3,
     "Column23": 404,
     "Column24": 6.7,
     "Column25": "701+",
     "Column26": 2.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Université de Sfax",
     "Column4": "TN",
     "Column5": "Tunisia",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 44,
     "Column15": 354,
     "Column16": 3.7,
     "Column17": "701+",
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 3.2,
     "Column21": "701+",
     "Column22": 50.8,
     "Column23": "701+",
     "Column24": 10.3,
     "Column25": "701+",
     "Column26": 8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Université de Sousse",
     "Column4": "TN",
     "Column5": "Tunisia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 51.1,
     "Column15": 301,
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 37,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Université de Toulouse II-Le Mirail",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.4,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 1.3,
     "Column15": "701+",
     "Column16": 7.1,
     "Column17": "701+",
     "Column18": 27.2,
     "Column19": 515,
     "Column20": 19.7,
     "Column21": 547,
     "Column22": 67,
     "Column23": 522,
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "Université de Tunis",
     "Column4": "TN",
     "Column5": "Tunisia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.2,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 19.4,
     "Column15": 688,
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 2,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 27,
     "Column23": "701+",
     "Column24": 48.1,
     "Column25": 266,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Université Lumière Lyon 2",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.1,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 4.5,
     "Column15": "701+",
     "Column16": 6.2,
     "Column17": "701+",
     "Column18": 26.7,
     "Column19": 519,
     "Column20": 35.5,
     "Column21": 386,
     "Column22": 59.1,
     "Column23": 642,
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "Université Mohammed V de Rabat",
     "Column4": "MA",
     "Column5": "Morocco",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.7,
     "Column11": "601+",
     "Column12": 3.3,
     "Column13": "601+",
     "Column14": 1.5,
     "Column15": "701+",
     "Column16": 4.5,
     "Column17": "701+",
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 2.6,
     "Column21": "701+",
     "Column22": 77.7,
     "Column23": 367,
     "Column24": 70.1,
     "Column25": 158,
     "Column26": 2.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "Université Paris-Nanterre",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.4,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 2.5,
     "Column17": "701+",
     "Column18": 8.6,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 56.9,
     "Column23": 665,
     "Column24": 29.1,
     "Column25": 426,
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universiti Malaysia Sabah (UMS)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 14.7,
     "Column11": 592,
     "Column12": 8.9,
     "Column13": "601+",
     "Column14": 8.5,
     "Column15": "701+",
     "Column16": 3.7,
     "Column17": "701+",
     "Column18": 10.2,
     "Column19": "701+",
     "Column20": 4.7,
     "Column21": "701+",
     "Column22": 28.2,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universiti Malaysia Sarawak (UNIMAS)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 13.2,
     "Column11": "601+",
     "Column12": 7.3,
     "Column13": "601+",
     "Column14": 13.1,
     "Column15": "701+",
     "Column16": 3.2,
     "Column17": "701+",
     "Column18": 34.8,
     "Column19": 461,
     "Column20": 10.5,
     "Column21": "701+",
     "Column22": 28.9,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 9.1,
     "Column27": "686=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universiti Malaysia Terengganu (UMT)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.5,
     "Column11": "601+",
     "Column12": 4.3,
     "Column13": "601+",
     "Column14": 12,
     "Column15": "701+",
     "Column16": 12.2,
     "Column17": 685,
     "Column18": 11.1,
     "Column19": "701+",
     "Column20": 12.1,
     "Column21": 676,
     "Column22": 50.2,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 18.2,
     "Column27": "553=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Universiti Tun Hussein Onn University of Malaysia (UTHM)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.3,
     "Column11": "601+",
     "Column12": 6.2,
     "Column13": "601+",
     "Column14": 9.9,
     "Column15": "701+",
     "Column16": 8.6,
     "Column17": "701+",
     "Column18": 6.3,
     "Column19": "701+",
     "Column20": 6.5,
     "Column21": "701+",
     "Column22": 43.8,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 10.3,
     "Column27": "658=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Arkansas Fayetteville ",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 3.5,
     "Column13": "601+",
     "Column14": 6.3,
     "Column15": "701+",
     "Column16": 11.9,
     "Column17": 696,
     "Column18": 4.8,
     "Column19": "701+",
     "Column20": 3,
     "Column21": "701+",
     "Column22": 51.6,
     "Column23": "701+",
     "Column24": 15.4,
     "Column25": 646,
     "Column26": 3.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Derby",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.8,
     "Column11": "601+",
     "Column12": 4.8,
     "Column13": "601+",
     "Column14": 10.7,
     "Column15": "701+",
     "Column16": 5.1,
     "Column17": "701+",
     "Column18": 29.1,
     "Column19": 501,
     "Column20": 45.5,
     "Column21": 322,
     "Column22": 45.7,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Deusto",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 24.9,
     "Column13": 381,
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 7.8,
     "Column17": "701+",
     "Column18": 4.7,
     "Column19": "701+",
     "Column20": 14.5,
     "Column21": 633,
     "Column22": 24.2,
     "Column23": "701+",
     "Column24": 45,
     "Column25": 283,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Hradec Kralove",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 22.4,
     "Column15": 626,
     "Column16": 5.4,
     "Column17": "701+",
     "Column18": 51.4,
     "Column19": 361,
     "Column20": 3.2,
     "Column21": "701+",
     "Column22": 41.3,
     "Column23": "701+",
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1401+",
     "Column3": "University of Ibadan",
     "Column4": "NG",
     "Column5": "Nigeria",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column10": 9.9,
     "Column11": "601+",
     "Column12": 7.8,
     "Column13": "601+",
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 3.5,
     "Column17": "701+",
     "Column22": 65.8,
     "Column23": 545,
     "Column24": 69.3,
     "Column25": 164,
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Idaho",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.1,
     "Column11": "601+",
     "Column12": 1.4,
     "Column13": "601+",
     "Column14": 9.9,
     "Column15": "701+",
     "Column16": 32.6,
     "Column17": 403,
     "Column18": 4.6,
     "Column19": "701+",
     "Column20": 5.9,
     "Column21": "701+",
     "Column22": 32.5,
     "Column23": "701+",
     "Column24": 3.5,
     "Column25": "701+",
     "Column26": 3.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Ioannina",
     "Column4": "GR",
     "Column5": "Greece",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column10": 7.2,
     "Column11": "601+",
     "Column12": 3.8,
     "Column13": "601+",
     "Column14": 1.5,
     "Column15": "701+",
     "Column16": 19.5,
     "Column17": 561,
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 61.3,
     "Column23": 612,
     "Column24": 3.6,
     "Column25": "701+",
     "Column26": 2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Karachi",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 11.6,
     "Column11": "601+",
     "Column12": 16.1,
     "Column13": 529,
     "Column14": 1.6,
     "Column15": "701+",
     "Column16": 4.9,
     "Column17": "701+",
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 36.8,
     "Column23": "701+",
     "Column24": 42.8,
     "Column25": 299,
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "University of Khartoum",
     "Column4": "SD",
     "Column5": "Sudan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 4,
     "Column13": "601+",
     "Column14": 6.7,
     "Column15": "701+",
     "Column16": 2.6,
     "Column17": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 45,
     "Column23": "701+",
     "Column24": 74.1,
     "Column25": 143,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1401+",
     "Column3": "University of Lagos",
     "Column4": "NG",
     "Column5": "Nigeria",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column10": 8.4,
     "Column11": "601+",
     "Column12": 7.5,
     "Column13": "601+",
     "Column14": 2.2,
     "Column15": "701+",
     "Column16": 2.6,
     "Column17": "701+",
     "Column22": 34,
     "Column23": "701+",
     "Column24": 87.6,
     "Column25": 91,
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Louisville",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.5,
     "Column11": "601+",
     "Column12": 3.2,
     "Column13": "601+",
     "Column14": 30.5,
     "Column15": 513,
     "Column16": 7.7,
     "Column17": "701+",
     "Column18": 24.8,
     "Column19": 539,
     "Column20": 3.2,
     "Column21": "701+",
     "Column22": 59,
     "Column23": 644,
     "Column24": 5.1,
     "Column25": "701+",
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "801-850",
     "Column3": "University of Massachusetts Boston",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11,
     "Column11": "601+",
     "Column12": 7.7,
     "Column13": "601+",
     "Column14": 9.8,
     "Column15": "701+",
     "Column16": 19.7,
     "Column17": 558,
     "Column18": 4.5,
     "Column19": "701+",
     "Column20": 14.8,
     "Column21": 629,
     "Column22": 32.2,
     "Column23": "701+",
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 8.4,
     "Column27": "698=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Missouri, Kansas City",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 14.3,
     "Column15": "701+",
     "Column16": 16.9,
     "Column17": 606,
     "Column18": 4.1,
     "Column19": "701+",
     "Column20": 20,
     "Column21": 542,
     "Column22": 29.3,
     "Column23": "701+",
     "Column24": 6.6,
     "Column25": "701+",
     "Column26": 1.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Naples Parthenope",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 1.9,
     "Column13": "601+",
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 42.4,
     "Column17": 325,
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 30.8,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "University of New England Australia",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.7,
     "Column11": "601+",
     "Column12": 7.2,
     "Column13": "601+",
     "Column14": 3.7,
     "Column15": "701+",
     "Column16": 14.7,
     "Column17": 641,
     "Column18": 4.6,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 62.9,
     "Column23": 587,
     "Column24": 9.8,
     "Column25": "701+",
     "Column26": 10.8,
     "Column27": "650=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of New Hampshire",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 12.2,
     "Column15": "701+",
     "Column16": 16.9,
     "Column17": 605,
     "Column18": 7,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 41.7,
     "Column23": "701+",
     "Column24": 7.1,
     "Column25": "701+",
     "Column26": 5.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of North Carolina at Charlotte",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.1,
     "Column11": "601+",
     "Column12": 5.8,
     "Column13": "601+",
     "Column14": 5,
     "Column15": "701+",
     "Column16": 13.4,
     "Column17": 662,
     "Column18": 8.7,
     "Column19": "701+",
     "Column20": 6.2,
     "Column21": "701+",
     "Column22": 65,
     "Column23": 556,
     "Column24": 7.8,
     "Column25": "701+",
     "Column26": 4.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of North Texas",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 5,
     "Column13": "601+",
     "Column14": 3.1,
     "Column15": "701+",
     "Column16": 17.2,
     "Column17": 595,
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 24.8,
     "Column21": 480,
     "Column22": 58,
     "Column23": 656,
     "Column24": 21.1,
     "Column25": 544,
     "Column26": 3.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Novi Sad",
     "Column4": "RS",
     "Column5": "Serbia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.4,
     "Column11": "601+",
     "Column12": 5.9,
     "Column13": "601+",
     "Column14": 8.8,
     "Column15": "701+",
     "Column16": 3.6,
     "Column17": "701+",
     "Column22": 79.4,
     "Column23": 334,
     "Column24": 21.3,
     "Column25": 538,
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Rhode Island ",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.6,
     "Column11": "601+",
     "Column12": 3.5,
     "Column13": "601+",
     "Column14": 6.7,
     "Column15": "701+",
     "Column16": 16.2,
     "Column17": 618,
     "Column18": 5.2,
     "Column19": "701+",
     "Column20": 2.6,
     "Column21": "701+",
     "Column22": 48.9,
     "Column23": "701+",
     "Column24": 11.8,
     "Column25": "701+",
     "Column26": 54.1,
     "Column27": 294,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "University of Sarajevo ",
     "Column4": "BA",
     "Column5": "Bosnia and Herzegovina",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 1.6,
     "Column13": "601+",
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 1.9,
     "Column17": "701+",
     "Column20": 2.8,
     "Column21": "701+",
     "Column22": 46.5,
     "Column23": "701+",
     "Column24": 83.6,
     "Column25": 107,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "University of Seoul ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11,
     "Column11": "601+",
     "Column12": 9.7,
     "Column13": "601+",
     "Column14": 36.7,
     "Column15": 436,
     "Column16": 8.8,
     "Column17": "701+",
     "Column18": 5.5,
     "Column19": "701+",
     "Column20": 6.9,
     "Column21": "701+",
     "Column22": 24,
     "Column23": "701+",
     "Column24": 5.3,
     "Column25": "701+",
     "Column26": 4.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column3": "University of Tabuk",
     "Column4": "SA",
     "Column5": "Saudi Arabia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 18.7,
     "Column15": "701+",
     "Column16": 3,
     "Column17": "701+",
     "Column18": 92.4,
     "Column19": 174,
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 38,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Texas at San Antonio",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 3.9,
     "Column15": "701+",
     "Column16": 22.8,
     "Column17": 522,
     "Column18": 8.8,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 60.5,
     "Column23": 629,
     "Column24": 5.1,
     "Column25": "701+",
     "Column26": 3.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Texas El Paso ",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 5.7,
     "Column15": "701+",
     "Column16": 14.2,
     "Column17": 650,
     "Column18": 9,
     "Column19": "701+",
     "Column20": 5.3,
     "Column21": "701+",
     "Column22": 66.2,
     "Column23": 535,
     "Column24": 3,
     "Column25": "701+",
     "Column26": 2.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of the Free State",
     "Column4": "ZA",
     "Column5": "South Africa",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 5.8,
     "Column13": "601+",
     "Column14": 3.4,
     "Column15": "701+",
     "Column16": 8.8,
     "Column17": "701+",
     "Column20": 4.4,
     "Column21": "701+",
     "Column22": 92.4,
     "Column23": 130,
     "Column24": 11.6,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of the Sunshine Coast ",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.6,
     "Column11": "601+",
     "Column12": 1.9,
     "Column13": "601+",
     "Column14": 2.6,
     "Column15": "701+",
     "Column16": 25.2,
     "Column17": 496,
     "Column18": 2.7,
     "Column19": "701+",
     "Column20": 16.4,
     "Column21": 598,
     "Column22": 48.3,
     "Column23": "701+",
     "Column24": 1.6,
     "Column25": "701+",
     "Column26": 6.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Toledo",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.7,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 9.6,
     "Column15": "701+",
     "Column16": 18.2,
     "Column17": 582,
     "Column18": 6,
     "Column19": "701+",
     "Column20": 10.4,
     "Column21": "701+",
     "Column22": 45.4,
     "Column23": "701+",
     "Column24": 7.7,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Tulsa",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 3.2,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 35.1,
     "Column15": 450,
     "Column16": 11.5,
     "Column17": "701+",
     "Column18": 7,
     "Column19": "701+",
     "Column20": 17.8,
     "Column21": 581,
     "Column22": 20.4,
     "Column23": "701+",
     "Column24": 13.7,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "901-950",
     "Column3": "University of Vermont",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.1,
     "Column11": "601+",
     "Column12": 2.2,
     "Column13": "601+",
     "Column14": 34.9,
     "Column15": 456,
     "Column16": 15.6,
     "Column17": 628,
     "Column18": 6.8,
     "Column19": "701+",
     "Column20": 2.4,
     "Column21": "701+",
     "Column22": 62.8,
     "Column23": 590,
     "Column24": 9.5,
     "Column25": "701+",
     "Column26": 8.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1201-1400",
     "Column3": "University of Warmia and Mazury in Olsztyn",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 8.9,
     "Column13": "601+",
     "Column14": 28.6,
     "Column15": 539,
     "Column16": 3.7,
     "Column17": "701+",
     "Column18": 2,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 40.6,
     "Column23": "701+",
     "Column24": 3.2,
     "Column25": "701+",
     "Column26": 7.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Wolverhampton",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.2,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 10.2,
     "Column15": "701+",
     "Column16": 8.3,
     "Column17": "701+",
     "Column18": 33.3,
     "Column19": 475,
     "Column20": 69.2,
     "Column21": 214,
     "Column22": 45.7,
     "Column23": "701+",
     "Column24": 4.7,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Wyoming",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.2,
     "Column11": "601+",
     "Column12": 1.6,
     "Column13": "601+",
     "Column14": 28.4,
     "Column15": 545,
     "Column16": 21.1,
     "Column17": 542,
     "Column18": 6.8,
     "Column19": "701+",
     "Column20": 4,
     "Column21": "701+",
     "Column22": 52.2,
     "Column23": "701+",
     "Column24": 6.5,
     "Column25": "701+",
     "Column26": 3.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "University of Žilina",
     "Column4": "SK",
     "Column5": "Slovakia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 60.1,
     "Column15": 239,
     "Column16": 6.6,
     "Column17": "701+",
     "Column18": 2,
     "Column19": "701+",
     "Column20": 6,
     "Column21": "701+",
     "Column22": 19.3,
     "Column23": "701+",
     "Column24": 4.6,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Utah State University ",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.2,
     "Column11": "601+",
     "Column12": 2,
     "Column13": "601+",
     "Column14": 5.4,
     "Column15": "701+",
     "Column16": 22.4,
     "Column17": 527,
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 54.7,
     "Column23": 700,
     "Column24": 7.6,
     "Column25": "701+",
     "Column26": 7.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "VSB - Technical University of Ostrava",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 10.5,
     "Column13": "601+",
     "Column14": 18.6,
     "Column15": "701+",
     "Column16": 4.7,
     "Column17": "701+",
     "Column18": 11.3,
     "Column19": "701+",
     "Column20": 28.3,
     "Column21": 442,
     "Column22": 50.2,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 4.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Warsaw University of Life Sciences – SGGW (WULS-SGGW)",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 18.8,
     "Column15": "701+",
     "Column16": 6.8,
     "Column17": "701+",
     "Column18": 2.3,
     "Column19": "701+",
     "Column20": 6.4,
     "Column21": "701+",
     "Column22": 74.4,
     "Column23": 416,
     "Column24": 3,
     "Column25": "701+",
     "Column26": 6.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "901-950",
     "Column3": "West Virginia University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.6,
     "Column11": "601+",
     "Column12": 4.7,
     "Column13": "601+",
     "Column14": 12.8,
     "Column15": "701+",
     "Column16": 18.5,
     "Column17": 575,
     "Column18": 5.8,
     "Column19": "701+",
     "Column20": 4.2,
     "Column21": "701+",
     "Column22": 66.7,
     "Column23": 528,
     "Column24": 14.3,
     "Column25": 686,
     "Column26": 6.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "1001-1200",
     "Column3": "Xi'an Jiaotong Liverpool University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 4.2,
     "Column11": "601+",
     "Column12": 1.9,
     "Column13": "601+",
     "Column14": 6.2,
     "Column15": "701+",
     "Column16": 5.6,
     "Column17": "701+",
     "Column18": 94.8,
     "Column19": 151,
     "Column20": 2.8,
     "Column21": "701+",
     "Column22": 37.8,
     "Column23": "701+",
     "Column24": 1.5,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1001-1200",
     "Column2": "951-1000",
     "Column3": "Yokohama National University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 9.3,
     "Column11": "601+",
     "Column12": 16.4,
     "Column13": 519,
     "Column14": 18.3,
     "Column15": "701+",
     "Column16": 6.6,
     "Column17": "701+",
     "Column18": 10.1,
     "Column19": "701+",
     "Column20": 7.8,
     "Column21": "701+",
     "Column22": 26.5,
     "Column23": "701+",
     "Column24": 17.8,
     "Column25": 603,
     "Column26": 2.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "Al-Balqa Applied University",
     "Column4": "JO",
     "Column5": "Jordan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 6.6,
     "Column13": "601+",
     "Column14": 4.8,
     "Column15": "701+",
     "Column16": 2.7,
     "Column17": "701+",
     "Column18": 7.6,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 32.9,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 6.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Alexandru Ioan Cuza University",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.5,
     "Column11": "601+",
     "Column12": 11.9,
     "Column13": "601+",
     "Column14": 5.5,
     "Column15": "701+",
     "Column16": 3,
     "Column17": "701+",
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 9.6,
     "Column21": "701+",
     "Column22": 40.2,
     "Column23": "701+",
     "Column24": 14.3,
     "Column25": 684,
     "Column26": 3.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Anadolu University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 13.1,
     "Column15": "701+",
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column20": 2.9,
     "Column21": "701+",
     "Column22": 17.5,
     "Column23": "701+",
     "Column24": 13.9,
     "Column25": 697,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Aoyama Gakuin University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 10.9,
     "Column13": "601+",
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 15.4,
     "Column19": 663,
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 6.2,
     "Column23": "701+",
     "Column24": 9.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "Ataturk University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "XL",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 2.9,
     "Column15": "701+",
     "Column16": 4.4,
     "Column17": "701+",
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 7.4,
     "Column21": "701+",
     "Column22": 39.4,
     "Column23": "701+",
     "Column24": 7.8,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Atma Jaya Catholic University Jakarta",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 6.8,
     "Column11": "601+",
     "Column12": 7.7,
     "Column13": "601+",
     "Column14": 8.7,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 3.4,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 7.5,
     "Column23": "701+",
     "Column24": 18,
     "Column25": 601,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Bahauddin Zakariya University ",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 6.2,
     "Column13": "601+",
     "Column14": 1.8,
     "Column15": "701+",
     "Column16": 14.6,
     "Column17": 644,
     "Column20": 1,
     "Column21": "701+",
     "Column22": 44.6,
     "Column23": "701+",
     "Column24": 4.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Beijing Foreign Studies University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.7,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 25.9,
     "Column15": 574,
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 19.6,
     "Column19": 606,
     "Column20": 25.8,
     "Column21": 468,
     "Column22": 3.4,
     "Column23": "701+",
     "Column24": 7.4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Beijing University of Chinese Medicine",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 1.6,
     "Column13": "601+",
     "Column14": 37.2,
     "Column15": 431,
     "Column16": 9.4,
     "Column17": "701+",
     "Column20": 8.8,
     "Column21": "701+",
     "Column22": 7.1,
     "Column23": "701+",
     "Column24": 3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Belarusian State University of Informatics and Radioelectronics",
     "Column4": "BY",
     "Column5": "Belarus",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 17.1,
     "Column13": 500,
     "Column14": 15.3,
     "Column15": "701+",
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 9.3,
     "Column21": "701+",
     "Column22": 7.4,
     "Column23": "701+",
     "Column24": 5.9,
     "Column25": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Benemérita Universidad Autónoma de Puebla",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 13.7,
     "Column11": "601+",
     "Column12": 5.4,
     "Column13": "601+",
     "Column14": 2.8,
     "Column15": "701+",
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 2.8,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 24.5,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Birzeit university",
     "Column4": "PS",
     "Column5": "Palestinian Territory, Occupied",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 7.6,
     "Column11": "601+",
     "Column12": 7.1,
     "Column13": "601+",
     "Column14": 4.7,
     "Column15": "701+",
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 13.4,
     "Column19": 697,
     "Column20": 4,
     "Column21": "701+",
     "Column22": 20.4,
     "Column23": "701+",
     "Column24": 23.2,
     "Column25": 489,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "British University in Egypt",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 5.5,
     "Column13": "601+",
     "Column14": 25.7,
     "Column15": 578,
     "Column16": 2.3,
     "Column17": "701+",
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 2.6,
     "Column21": "701+",
     "Column22": 12,
     "Column23": "701+",
     "Column24": 2.6,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Brock University",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4,
     "Column11": "601+",
     "Column12": 6.3,
     "Column13": "601+",
     "Column14": 3,
     "Column15": "701+",
     "Column16": 13.7,
     "Column17": 658,
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 23.8,
     "Column21": 493,
     "Column22": 30.9,
     "Column23": "701+",
     "Column24": 5.2,
     "Column25": "701+",
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Central Michigan University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.6,
     "Column11": "601+",
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 8.2,
     "Column15": "701+",
     "Column16": 6.5,
     "Column17": "701+",
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 6.9,
     "Column21": "701+",
     "Column22": 23.4,
     "Column23": "701+",
     "Column24": 7.7,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Chaoyang University of Technology",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 5.8,
     "Column13": "601+",
     "Column14": 4.3,
     "Column15": "701+",
     "Column16": 3.9,
     "Column17": "701+",
     "Column18": 5.6,
     "Column19": "701+",
     "Column20": 5.9,
     "Column21": "701+",
     "Column22": 26.5,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 4.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "China University of Political Science and Law",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 2.7,
     "Column11": "601+",
     "Column12": 37.6,
     "Column13": 251,
     "Column14": 8,
     "Column15": "701+",
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 3.7,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 6.1,
     "Column23": "701+",
     "Column24": 5.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Chitkara University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 7.4,
     "Column13": "601+",
     "Column14": 15.9,
     "Column15": "701+",
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 5.5,
     "Column19": "701+",
     "Column20": 3,
     "Column21": "701+",
     "Column22": 29.4,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 3.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Chittagong University of Engineering and Technology",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.9,
     "Column11": "601+",
     "Column12": 12.4,
     "Column13": "601+",
     "Column14": 8.3,
     "Column15": "701+",
     "Column16": 5.1,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column22": 6.7,
     "Column23": "701+",
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Cleveland State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 3.1,
     "Column13": "601+",
     "Column14": 9.2,
     "Column15": "701+",
     "Column16": 4,
     "Column17": "701+",
     "Column18": 4,
     "Column19": "701+",
     "Column20": 26.2,
     "Column21": 462,
     "Column22": 16.7,
     "Column23": "701+",
     "Column24": 7.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "Cukurova University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 3.8,
     "Column13": "601+",
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 1.9,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 46.2,
     "Column23": "701+",
     "Column24": 7.6,
     "Column25": "701+",
     "Column26": 2.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "CUNY The City College of New York",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.2,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 11.9,
     "Column15": "701+",
     "Column16": 11.3,
     "Column17": "701+",
     "Column18": 6.7,
     "Column19": "701+",
     "Column20": 4.7,
     "Column21": "701+",
     "Column22": 24.8,
     "Column23": "701+",
     "Column24": 1.5,
     "Column25": "701+",
     "Column26": 2.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Daffodil International University",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 9.5,
     "Column11": "601+",
     "Column12": 19.8,
     "Column13": 458,
     "Column14": 3.8,
     "Column15": "701+",
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 5,
     "Column19": "701+",
     "Column20": 2.9,
     "Column21": "701+",
     "Column22": 22,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 3.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Damascus University",
     "Column4": "SY",
     "Column5": "Syrian Arab Republic",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 3.7,
     "Column13": "601+",
     "Column14": 1.4,
     "Column15": "701+",
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 4,
     "Column19": "701+",
     "Column20": 6,
     "Column21": "701+",
     "Column22": 18.4,
     "Column23": "701+",
     "Column24": 90.2,
     "Column25": 76,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Dokuz Eylül Üniversitesi",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.8,
     "Column11": "601+",
     "Column12": 7.8,
     "Column13": "601+",
     "Column14": 3.5,
     "Column15": "701+",
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 2.5,
     "Column19": "701+",
     "Column20": 10.7,
     "Column21": "701+",
     "Column22": 34.7,
     "Column23": "701+",
     "Column24": 6,
     "Column25": "701+",
     "Column26": 18.8,
     "Column27": 548,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Doshisha University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 8.6,
     "Column11": "601+",
     "Column12": 11.4,
     "Column13": "601+",
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 17.6,
     "Column19": 631,
     "Column20": 3.7,
     "Column21": "701+",
     "Column22": 18.7,
     "Column23": "701+",
     "Column24": 19.1,
     "Column25": 576,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Erciyes Üniversitesi",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 2,
     "Column15": "701+",
     "Column16": 5.3,
     "Column17": "701+",
     "Column18": 3.1,
     "Column19": "701+",
     "Column20": 5.7,
     "Column21": "701+",
     "Column22": 45.1,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 8.8,
     "Column27": "692=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Escuela Politécnica Nacional",
     "Column4": "EC",
     "Column5": "Ecuador",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 9.2,
     "Column13": "601+",
     "Column14": 16.6,
     "Column15": "701+",
     "Column16": 3.4,
     "Column17": "701+",
     "Column18": 5,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 14.9,
     "Column23": "701+",
     "Column24": 5,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Feng Chia University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 10.8,
     "Column11": "601+",
     "Column12": 7.9,
     "Column13": "601+",
     "Column14": 4.4,
     "Column15": "701+",
     "Column16": 6.1,
     "Column17": "701+",
     "Column18": 3.4,
     "Column19": "701+",
     "Column20": 4.4,
     "Column21": "701+",
     "Column22": 11.2,
     "Column23": "701+",
     "Column24": 15.5,
     "Column25": 643,
     "Column26": 16.7,
     "Column27": 566,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Florida Atlantic University - Boca Raton",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.7,
     "Column11": "601+",
     "Column12": 6.2,
     "Column13": "601+",
     "Column14": 4.9,
     "Column15": "701+",
     "Column16": 9.5,
     "Column17": "701+",
     "Column18": 6.8,
     "Column19": "701+",
     "Column20": 4.4,
     "Column21": "701+",
     "Column22": 44.3,
     "Column23": "701+",
     "Column24": 5.8,
     "Column25": "701+",
     "Column26": 4.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Fu Jen Catholic University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 7.6,
     "Column11": "601+",
     "Column12": 6.9,
     "Column13": "601+",
     "Column14": 3.6,
     "Column15": "701+",
     "Column16": 7,
     "Column17": "701+",
     "Column18": 7.6,
     "Column19": "701+",
     "Column20": 4.2,
     "Column21": "701+",
     "Column22": 16.1,
     "Column23": "701+",
     "Column24": 27.1,
     "Column25": 442,
     "Column26": 4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Fundación CEU San Pablo",
     "Column4": "ES",
     "Column5": "Spain",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 9.4,
     "Column13": "601+",
     "Column14": 15.7,
     "Column15": "701+",
     "Column16": 2.3,
     "Column17": "701+",
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 47.5,
     "Column21": 308,
     "Column22": 15.3,
     "Column23": "701+",
     "Column24": 10.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "German University in Cairo",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 8.3,
     "Column11": "601+",
     "Column12": 15.2,
     "Column13": 552,
     "Column14": 7,
     "Column15": "701+",
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 13.4,
     "Column19": 698,
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 12.8,
     "Column23": "701+",
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Hanoi University of Science and Technology",
     "Column4": "VN",
     "Column5": "Vietnam",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.3,
     "Column11": "601+",
     "Column12": 7.3,
     "Column13": "601+",
     "Column14": 2.4,
     "Column15": "701+",
     "Column16": 4.7,
     "Column17": "701+",
     "Column18": 4.2,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 23.1,
     "Column23": "701+",
     "Column24": 23.1,
     "Column25": 493,
     "Column26": 3.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Harper Adams University",
     "Column4": "UK",
     "Column5": "United Kingdom",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.2,
     "Column11": "601+",
     "Column12": 10.3,
     "Column13": "601+",
     "Column14": 6.1,
     "Column15": "701+",
     "Column16": 6.9,
     "Column17": "701+",
     "Column18": 17.4,
     "Column19": 635,
     "Column20": 7.6,
     "Column21": "701+",
     "Column22": 11.6,
     "Column23": "701+",
     "Column24": 1.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Helwan University ",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.3,
     "Column11": "601+",
     "Column12": 12.4,
     "Column13": "601+",
     "Column14": 3.6,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 50.7,
     "Column23": "701+",
     "Column24": 13.2,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Hongik University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 4.2,
     "Column11": "601+",
     "Column12": 5.6,
     "Column13": "601+",
     "Column14": 9.8,
     "Column15": "701+",
     "Column16": 3,
     "Column17": "701+",
     "Column18": 16,
     "Column19": 651,
     "Column20": 9.6,
     "Column21": "701+",
     "Column22": 14.2,
     "Column23": "701+",
     "Column24": 4.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column3": "Hue University",
     "Column4": "VN",
     "Column5": "Vietnam",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.6,
     "Column11": "601+",
     "Column12": 12.9,
     "Column13": "601+",
     "Column14": 3,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 4.8,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 41.6,
     "Column23": "701+",
     "Column24": 4.5,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Instituto Tecnológico de Santo Domingo (INTEC)",
     "Column4": "DO",
     "Column5": "Dominican Republic",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 1.6,
     "Column11": "601+",
     "Column12": 4,
     "Column13": "601+",
     "Column14": 35.4,
     "Column15": 447,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 10,
     "Column19": "701+",
     "Column20": 4.1,
     "Column21": "701+",
     "Column22": 5.6,
     "Column23": "701+",
     "Column24": 38.5,
     "Column25": 332,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Istanbul Aydin University  ",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 12.3,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 6.7,
     "Column19": "701+",
     "Column20": 52.1,
     "Column21": 282,
     "Column22": 7.3,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Istanbul Bilgi Üniversitesi",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 4.3,
     "Column13": "601+",
     "Column14": 13.3,
     "Column15": "701+",
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 20.2,
     "Column21": 536,
     "Column22": 6.5,
     "Column23": "701+",
     "Column24": 13.3,
     "Column25": "701+",
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Ivan Franko National University of Lviv",
     "Column4": "UA",
     "Column5": "Ukraine",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.7,
     "Column11": "601+",
     "Column12": 12.8,
     "Column13": "601+",
     "Column14": 30.2,
     "Column15": 519,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 12.6,
     "Column23": "701+",
     "Column24": 18.6,
     "Column25": 591,
     "Column26": 2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Izmir Institute of Technology (IZTECH)",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.2,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 5.8,
     "Column15": "701+",
     "Column16": 8.2,
     "Column17": "701+",
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 15.5,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Jahangirnagar University",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column10": 9.7,
     "Column11": "601+",
     "Column12": 12.7,
     "Column13": "601+",
     "Column14": 4,
     "Column15": "701+",
     "Column16": 5.7,
     "Column17": "701+",
     "Column22": 29.4,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Jamia Hamdard",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.1,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 8.4,
     "Column15": "701+",
     "Column16": 12.6,
     "Column17": 678,
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 12.7,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Jeju National University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.6,
     "Column11": "601+",
     "Column12": 1.9,
     "Column13": "601+",
     "Column14": 30,
     "Column15": 520,
     "Column16": 7,
     "Column17": "701+",
     "Column18": 7.5,
     "Column19": "701+",
     "Column20": 5.5,
     "Column21": "701+",
     "Column22": 23.1,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 15,
     "Column27": "592=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Kazakh Ablai Khan University of International Relations and World Languages",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "LO",
     "Column9": "A",
     "Column10": 7.7,
     "Column11": "601+",
     "Column12": 9,
     "Column13": "601+",
     "Column14": 40.4,
     "Column15": 386,
     "Column16": 1,
     "Column17": "701+",
     "Column18": 6.6,
     "Column19": "701+",
     "Column20": 4.1,
     "Column21": "701+",
     "Column22": 1,
     "Column23": "701+",
     "Column24": 3.4,
     "Column25": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Kazan National Research Technological University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.7,
     "Column11": "601+",
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 18.9,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 2.7,
     "Column19": "701+",
     "Column20": 29.6,
     "Column21": 434,
     "Column22": 7.2,
     "Column23": "701+",
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Kenyatta University",
     "Column4": "KE",
     "Column5": "Kenya",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "HI",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 7.4,
     "Column13": "601+",
     "Column14": 1.5,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column22": 13.1,
     "Column23": "701+",
     "Column24": 28.4,
     "Column25": 433,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Kharkiv National University of Radio Electronics ",
     "Column4": "UA",
     "Column5": "Ukraine",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.9,
     "Column11": "601+",
     "Column12": 12.3,
     "Column13": "601+",
     "Column14": 21.6,
     "Column15": 643,
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 5.1,
     "Column21": "701+",
     "Column22": 11.9,
     "Column23": "701+",
     "Column24": 4.1,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "Khulna University",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "HI",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 5,
     "Column13": "601+",
     "Column14": 10.5,
     "Column15": "701+",
     "Column16": 5.3,
     "Column17": "701+",
     "Column22": 17.8,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Khulna University of Engineering and Technology",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 11.8,
     "Column13": "601+",
     "Column14": 8.7,
     "Column15": "701+",
     "Column16": 5.6,
     "Column17": "701+",
     "Column20": 1,
     "Column21": "701+",
     "Column22": 9.4,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Kindai University (Kinki University)",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 4.3,
     "Column13": "601+",
     "Column14": 8.4,
     "Column15": "701+",
     "Column16": 4.9,
     "Column17": "701+",
     "Column18": 15.7,
     "Column19": 656,
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 32.2,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "King Mongkut\\'s Institute of Technology Ladkrabang",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.3,
     "Column11": "601+",
     "Column12": 3.7,
     "Column13": "601+",
     "Column14": 4.2,
     "Column15": "701+",
     "Column16": 3.1,
     "Column17": "701+",
     "Column18": 16.7,
     "Column19": 642,
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 18.5,
     "Column23": "701+",
     "Column24": 6.2,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "King Mongkut's University of Technology North Bangkok",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.5,
     "Column11": "601+",
     "Column12": 4.9,
     "Column13": "601+",
     "Column14": 4.1,
     "Column15": "701+",
     "Column16": 3.3,
     "Column17": "701+",
     "Column18": 2.8,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 23.5,
     "Column23": "701+",
     "Column24": 2.5,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Kookmin University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 3.6,
     "Column11": "601+",
     "Column12": 4,
     "Column13": "601+",
     "Column14": 35.3,
     "Column15": 449,
     "Column16": 2.9,
     "Column17": "701+",
     "Column18": 4.9,
     "Column19": "701+",
     "Column20": 31.1,
     "Column21": 419,
     "Column22": 7.9,
     "Column23": "701+",
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 2.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "Kwame Nkrumah University of Science and Technology",
     "Column4": "GH",
     "Column5": "Ghana",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 6,
     "Column13": "601+",
     "Column14": 4.8,
     "Column15": "701+",
     "Column16": 2,
     "Column17": "701+",
     "Column22": 55.3,
     "Column23": 691,
     "Column24": 34.5,
     "Column25": 366,
     "Column26": 2.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Kyoto Institute of Technology",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 14.4,
     "Column15": "701+",
     "Column16": 11.7,
     "Column17": "701+",
     "Column18": 11.1,
     "Column19": "701+",
     "Column20": 9,
     "Column21": "701+",
     "Column22": 9.3,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column3": "Kyrgyz State Technical University",
     "Column4": "KG",
     "Column5": "Kyrgyzstan",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "LO",
     "Column9": "A",
     "Column10": 14.6,
     "Column11": 598,
     "Column12": 7,
     "Column13": "601+",
     "Column14": 12,
     "Column15": "701+",
     "Column16": 1,
     "Column17": "701+",
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 5.8,
     "Column21": "701+",
     "Column22": 1.2,
     "Column23": "701+",
     "Column24": 22.6,
     "Column25": 506,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Marquette University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 3.4,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 21.1,
     "Column15": 654,
     "Column16": 9.1,
     "Column17": "701+",
     "Column18": 6.2,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 35.6,
     "Column23": "701+",
     "Column24": 11.2,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Meiji University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 6.7,
     "Column11": "601+",
     "Column12": 19.2,
     "Column13": 467,
     "Column14": 4.4,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 10.5,
     "Column19": "701+",
     "Column20": 4.6,
     "Column21": "701+",
     "Column22": 13.4,
     "Column23": "701+",
     "Column24": 17.3,
     "Column25": 615,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Mendeleev University of Chemical Technology",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 24.2,
     "Column15": 608,
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 9.1,
     "Column21": "701+",
     "Column22": 15.3,
     "Column23": "701+",
     "Column24": 8.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Miami University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 5.4,
     "Column13": "601+",
     "Column14": 6.2,
     "Column15": "701+",
     "Column16": 7.8,
     "Column17": "701+",
     "Column18": 3.4,
     "Column19": "701+",
     "Column20": 5.5,
     "Column21": "701+",
     "Column22": 25.3,
     "Column23": "701+",
     "Column24": 26.1,
     "Column25": 453,
     "Column26": 2.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Moscow Pedagogical State University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.8,
     "Column11": "601+",
     "Column12": 3.6,
     "Column13": "601+",
     "Column14": 7.2,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 1.2,
     "Column19": "701+",
     "Column20": 23.8,
     "Column21": 494,
     "Column22": 6.8,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Nagoya Institute of Technology (NIT)",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.7,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 7.5,
     "Column15": "701+",
     "Column16": 14.5,
     "Column17": 645,
     "Column18": 5.1,
     "Column19": "701+",
     "Column20": 4.1,
     "Column21": "701+",
     "Column22": 18.3,
     "Column23": "701+",
     "Column24": 6,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "National Dong Hwa University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9.2,
     "Column11": "601+",
     "Column12": 4.5,
     "Column13": "601+",
     "Column14": 6.3,
     "Column15": "701+",
     "Column16": 5.4,
     "Column17": "701+",
     "Column18": 4.9,
     "Column19": "701+",
     "Column20": 10.2,
     "Column21": "701+",
     "Column22": 9.7,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "National Research University Moscow Power Engineering Institute (MPEI)",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 4,
     "Column13": "601+",
     "Column14": 12.8,
     "Column15": "701+",
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 6.8,
     "Column19": "701+",
     "Column20": 41.6,
     "Column21": 343,
     "Column22": 21,
     "Column23": "701+",
     "Column24": 9.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "National Taipei University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.6,
     "Column11": "601+",
     "Column12": 4.5,
     "Column13": "601+",
     "Column14": 7.8,
     "Column15": "701+",
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 4.5,
     "Column21": "701+",
     "Column22": 5,
     "Column23": "701+",
     "Column24": 18.9,
     "Column25": 586,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "National Taiwan Ocean University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 3.2,
     "Column13": "601+",
     "Column14": 11.9,
     "Column15": "701+",
     "Column16": 10.8,
     "Column17": "701+",
     "Column18": 7.5,
     "Column19": "701+",
     "Column20": 7.6,
     "Column21": "701+",
     "Column22": 22.6,
     "Column23": "701+",
     "Column24": 3.3,
     "Column25": "701+",
     "Column26": 17.5,
     "Column27": 560,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Novosibirsk State Technical University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 6.4,
     "Column13": "601+",
     "Column14": 27.6,
     "Column15": 553,
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 41.7,
     "Column21": 342,
     "Column22": 8.4,
     "Column23": "701+",
     "Column24": 5.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Óbuda University",
     "Column4": "HU",
     "Column5": "Hungary",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 4.9,
     "Column13": "601+",
     "Column14": 2.5,
     "Column15": "701+",
     "Column16": 7.8,
     "Column17": "701+",
     "Column18": 17.9,
     "Column19": 629,
     "Column20": 7.6,
     "Column21": "701+",
     "Column22": 29.1,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Odessa I.I. Mechnikov National University",
     "Column4": "UA",
     "Column5": "Ukraine",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.9,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 55.4,
     "Column15": 272,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 10.3,
     "Column23": "701+",
     "Column24": 6.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "OSMANIA UNIVERSITY",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 3.7,
     "Column13": "601+",
     "Column14": 3.5,
     "Column15": "701+",
     "Column16": 21,
     "Column17": 545,
     "Column20": 5.4,
     "Column21": "701+",
     "Column22": 17.2,
     "Column23": "701+",
     "Column24": 7.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Perm State National Research University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 1.7,
     "Column13": "601+",
     "Column14": 34.5,
     "Column15": 458,
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 8.3,
     "Column21": "701+",
     "Column22": 6.3,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Pondicherry University",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 5.4,
     "Column15": "701+",
     "Column16": 16.5,
     "Column17": 616,
     "Column18": 1.9,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 21.1,
     "Column23": "701+",
     "Column24": 2.7,
     "Column25": "701+",
     "Column26": 5.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Pontificia Universidad Catolica Madre y Maestra",
     "Column4": "DO",
     "Column5": "Dominican Republic",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 4.2,
     "Column11": "601+",
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 11.2,
     "Column15": "701+",
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 9.4,
     "Column19": "701+",
     "Column20": 11.4,
     "Column21": 688,
     "Column22": 4.1,
     "Column23": "701+",
     "Column24": 45.7,
     "Column25": 278,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Pontifícia Universidade Católica do Rio Grande do Sul (PUCRS)",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 12.3,
     "Column15": "701+",
     "Column16": 9.4,
     "Column17": "701+",
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 30.5,
     "Column23": "701+",
     "Column24": 9.2,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Portland State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 1.7,
     "Column13": "601+",
     "Column14": 9.3,
     "Column15": "701+",
     "Column16": 9.2,
     "Column17": "701+",
     "Column18": 1.6,
     "Column19": "701+",
     "Column20": 6.1,
     "Column21": "701+",
     "Column22": 44.4,
     "Column23": "701+",
     "Column24": 9.7,
     "Column25": "701+",
     "Column26": 6.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Pukyong National University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.7,
     "Column11": "601+",
     "Column12": 3.1,
     "Column13": "601+",
     "Column14": 12.7,
     "Column15": "701+",
     "Column16": 7,
     "Column17": "701+",
     "Column18": 6.2,
     "Column19": "701+",
     "Column20": 7.1,
     "Column21": "701+",
     "Column22": 32.3,
     "Column23": "701+",
     "Column24": 1.6,
     "Column25": "701+",
     "Column26": 28.4,
     "Column27": "456=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "Rajshahi University",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column10": 10.4,
     "Column11": "601+",
     "Column12": 9.8,
     "Column13": "601+",
     "Column14": 3.9,
     "Column15": "701+",
     "Column16": 3,
     "Column17": "701+",
     "Column18": 1,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 16.4,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Rikkyo University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 4,
     "Column11": "601+",
     "Column12": 9.6,
     "Column13": "601+",
     "Column14": 8.2,
     "Column15": "701+",
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 23.7,
     "Column19": 552,
     "Column20": 5.6,
     "Column21": "701+",
     "Column22": 13.7,
     "Column23": "701+",
     "Column24": 5.8,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Saitama University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 1.6,
     "Column13": "601+",
     "Column14": 11.5,
     "Column15": "701+",
     "Column16": 6.8,
     "Column17": "701+",
     "Column18": 9.7,
     "Column19": "701+",
     "Column20": 4.9,
     "Column21": "701+",
     "Column22": 18.1,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Saken Seifullin Kazakh Agrotechnical University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 9.7,
     "Column11": "601+",
     "Column12": 9.3,
     "Column13": "601+",
     "Column14": 27.8,
     "Column15": 550,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 10.1,
     "Column19": "701+",
     "Column20": 4.2,
     "Column21": "701+",
     "Column22": 2.6,
     "Column23": "701+",
     "Column24": 3.4,
     "Column25": "701+",
     "Column26": 2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "San Francisco State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 3.1,
     "Column13": "601+",
     "Column14": 5.7,
     "Column15": "701+",
     "Column16": 4,
     "Column17": "701+",
     "Column18": 3.4,
     "Column19": "701+",
     "Column20": 5,
     "Column21": "701+",
     "Column22": 35.6,
     "Column23": "701+",
     "Column24": 7.6,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Sathyabama Institute of Science and Technology (deemed to be university)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 6.8,
     "Column11": "601+",
     "Column12": 3.7,
     "Column13": "601+",
     "Column14": 24.8,
     "Column15": 596,
     "Column16": 4.3,
     "Column17": "701+",
     "Column18": 5.3,
     "Column19": "701+",
     "Column20": 3.5,
     "Column21": "701+",
     "Column22": 17,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Seattle University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 6.4,
     "Column13": "601+",
     "Column14": 32.2,
     "Column15": 490,
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 1.5,
     "Column19": "701+",
     "Column20": 13.2,
     "Column21": 656,
     "Column22": 6.3,
     "Column23": "701+",
     "Column24": 10.1,
     "Column25": "701+",
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Seoul National University of Science and Technology",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.4,
     "Column11": "601+",
     "Column12": 3.6,
     "Column13": "601+",
     "Column14": 12.8,
     "Column15": "701+",
     "Column16": 10.3,
     "Column17": "701+",
     "Column18": 9.6,
     "Column19": "701+",
     "Column20": 11.9,
     "Column21": 683,
     "Column22": 12.4,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 9.8,
     "Column27": "672=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "Shahjalal University of Science and Technology",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column10": 7.6,
     "Column11": "601+",
     "Column12": 11.8,
     "Column13": "601+",
     "Column14": 11.8,
     "Column15": "701+",
     "Column16": 5.3,
     "Column17": "701+",
     "Column22": 12.8,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Shanghai International Studies University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 16.7,
     "Column15": "701+",
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 26.9,
     "Column19": 518,
     "Column20": 27.3,
     "Column21": 454,
     "Column22": 5.4,
     "Column23": "701+",
     "Column24": 5.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Shanghai University of Finance and Economics",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 5.2,
     "Column13": "601+",
     "Column14": 8.9,
     "Column15": "701+",
     "Column16": 3.5,
     "Column17": "701+",
     "Column18": 15.5,
     "Column19": 662,
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 14.9,
     "Column23": "701+",
     "Column24": 42.6,
     "Column25": 304,
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Siksha 'O' Anusandhan (Deemed to be University)",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 7.5,
     "Column11": "601+",
     "Column12": 3.6,
     "Column13": "601+",
     "Column14": 34.3,
     "Column15": 461,
     "Column16": 5.3,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 23.7,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Sookmyung Women's University ",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 5.6,
     "Column13": "601+",
     "Column14": 29,
     "Column15": 536,
     "Column16": 3.4,
     "Column17": "701+",
     "Column18": 4,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 4.8,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 2.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Suez Canal University",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 13.1,
     "Column15": "701+",
     "Column16": 2.3,
     "Column17": "701+",
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 48.9,
     "Column23": "701+",
     "Column24": 2.4,
     "Column25": "701+",
     "Column26": 8.3,
     "Column27": "700=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Suleyman Demirel University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 3.9,
     "Column15": "701+",
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 4.6,
     "Column21": "701+",
     "Column22": 27.3,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Suleyman Demirel University, Kazakhstan",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 9,
     "Column11": "601+",
     "Column12": 11,
     "Column13": "601+",
     "Column14": 4.1,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 6.2,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 5.7,
     "Column23": "701+",
     "Column24": 3.3,
     "Column25": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Suranaree University of Technology ",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.4,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 3.7,
     "Column15": "701+",
     "Column16": 9.6,
     "Column17": "701+",
     "Column18": 5.8,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 30,
     "Column23": "701+",
     "Column24": 2.7,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Tamkang University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 5.7,
     "Column11": "601+",
     "Column12": 4.9,
     "Column13": "601+",
     "Column14": 4,
     "Column15": "701+",
     "Column16": 5,
     "Column17": "701+",
     "Column18": 6,
     "Column19": "701+",
     "Column20": 7.4,
     "Column21": "701+",
     "Column22": 14.2,
     "Column23": "701+",
     "Column24": 29.2,
     "Column25": 425,
     "Column26": 10.1,
     "Column27": "661=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Tanta University ",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.9,
     "Column11": "601+",
     "Column12": 5.5,
     "Column13": "601+",
     "Column14": 4.5,
     "Column15": "701+",
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 1.5,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 65.2,
     "Column23": 553,
     "Column24": 4.3,
     "Column25": "701+",
     "Column26": 3.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Technical University of Cluj-Napoca",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.6,
     "Column11": "601+",
     "Column12": 7.1,
     "Column13": "601+",
     "Column14": 7.6,
     "Column15": "701+",
     "Column16": 3.9,
     "Column17": "701+",
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 38.1,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 2.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Technical University of Liberec",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.9,
     "Column11": "601+",
     "Column12": 2.2,
     "Column13": "601+",
     "Column14": 37.6,
     "Column15": 425,
     "Column16": 4.6,
     "Column17": "701+",
     "Column18": 11.2,
     "Column19": "701+",
     "Column20": 8.7,
     "Column21": "701+",
     "Column22": 25.5,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Technical University of Lublin",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 18.4,
     "Column15": "701+",
     "Column16": 3.7,
     "Column17": "701+",
     "Column20": 10.2,
     "Column21": "701+",
     "Column22": 12.9,
     "Column23": "701+",
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "Technische Universität Kaiserslautern",
     "Column4": "DE",
     "Column5": "Germany",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 17,
     "Column15": "701+",
     "Column16": 4.6,
     "Column17": "701+",
     "Column22": 56.4,
     "Column23": 672,
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "The \"Gheorghe Asachi\" Technical University of Iasi",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.3,
     "Column11": "601+",
     "Column12": 9.7,
     "Column13": "601+",
     "Column14": 2.4,
     "Column15": "701+",
     "Column16": 6,
     "Column17": "701+",
     "Column20": 4.6,
     "Column21": "701+",
     "Column22": 34.1,
     "Column23": "701+",
     "Column24": 4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "The Herzen State Pedagogical University of Russia",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 2,
     "Column13": "601+",
     "Column14": 20.1,
     "Column15": 670,
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 41.1,
     "Column21": 348,
     "Column22": 12.7,
     "Column23": "701+",
     "Column24": 5.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "The Josip Juraj Strossmayer University of Osijek",
     "Column4": "HR",
     "Column5": "Croatia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.3,
     "Column11": "601+",
     "Column12": 2,
     "Column13": "601+",
     "Column14": 28.6,
     "Column15": 540,
     "Column16": 3.1,
     "Column17": "701+",
     "Column22": 54,
     "Column23": "701+",
     "Column24": 11.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "The National Research University \"Belgorod State University\"",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.5,
     "Column11": "601+",
     "Column12": 1.3,
     "Column13": "601+",
     "Column14": 15.6,
     "Column15": "701+",
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 49.8,
     "Column21": 295,
     "Column22": 21.4,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Tokai University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 10.5,
     "Column11": "601+",
     "Column12": 5.8,
     "Column13": "601+",
     "Column14": 13.6,
     "Column15": "701+",
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 5.6,
     "Column19": "701+",
     "Column20": 2.9,
     "Column21": "701+",
     "Column22": 24.5,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Tomas Bata University in Zlin",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 8.4,
     "Column13": "601+",
     "Column14": 9.3,
     "Column15": "701+",
     "Column16": 5.5,
     "Column17": "701+",
     "Column18": 23.6,
     "Column19": 553,
     "Column20": 18.8,
     "Column21": 568,
     "Column22": 25.8,
     "Column23": "701+",
     "Column24": 4,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Toraighyrov University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.5,
     "Column11": "601+",
     "Column12": 4.8,
     "Column13": "601+",
     "Column14": 17.8,
     "Column15": "701+",
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 6.8,
     "Column19": "701+",
     "Column20": 3.1,
     "Column21": "701+",
     "Column22": 3.9,
     "Column23": "701+",
     "Column24": 3.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Transilvania University of Brasov",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.4,
     "Column11": "601+",
     "Column12": 5.5,
     "Column13": "601+",
     "Column14": 5.1,
     "Column15": "701+",
     "Column16": 3.7,
     "Column17": "701+",
     "Column18": 5.4,
     "Column19": "701+",
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 48.1,
     "Column23": "701+",
     "Column24": 4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Tunghai University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 6.5,
     "Column11": "601+",
     "Column12": 5.4,
     "Column13": "601+",
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 5.4,
     "Column17": "701+",
     "Column18": 8.4,
     "Column19": "701+",
     "Column20": 8.8,
     "Column21": "701+",
     "Column22": 24.4,
     "Column23": "701+",
     "Column24": 13.8,
     "Column25": 698,
     "Column26": 7.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Universidad Autónoma de Guadalajara (UAG)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "C",
     "Column10": 6.5,
     "Column11": "601+",
     "Column12": 3.8,
     "Column13": "601+",
     "Column14": 19.6,
     "Column15": 684,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 37.9,
     "Column19": 426,
     "Column20": 23,
     "Column21": 502,
     "Column22": 2.1,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Universidad Autónoma del Estado de Hidalgo (UAEH)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 3.2,
     "Column11": "601+",
     "Column12": 6,
     "Column13": "601+",
     "Column14": 45.2,
     "Column15": 342,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 2,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 8.3,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 5.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad Católica del Norte",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 6.7,
     "Column15": "701+",
     "Column16": 5.9,
     "Column17": "701+",
     "Column18": 13.5,
     "Column19": 694,
     "Column20": 3.8,
     "Column21": "701+",
     "Column22": 46.2,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 8.5,
     "Column27": "696=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad Central del Ecuador",
     "Column4": "EC",
     "Column5": "Ecuador",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 8.1,
     "Column11": "601+",
     "Column12": 3.6,
     "Column13": "601+",
     "Column14": 11.3,
     "Column15": "701+",
     "Column16": 1.2,
     "Column17": "701+",
     "Column22": 10.1,
     "Column23": "701+",
     "Column24": 20.9,
     "Column25": 548,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad de Las Américas (UDLA) Ecuador",
     "Column4": "EC",
     "Column5": "Ecuador",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 8.3,
     "Column11": "601+",
     "Column12": 3.8,
     "Column13": "601+",
     "Column14": 13.3,
     "Column15": "701+",
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 19.2,
     "Column19": 612,
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 15.7,
     "Column23": "701+",
     "Column24": 4.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Universidad de Monterrey (UDEM)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 12.5,
     "Column11": "601+",
     "Column12": 13,
     "Column13": "601+",
     "Column14": 7.5,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 13.7,
     "Column19": 691,
     "Column20": 3.7,
     "Column21": "701+",
     "Column22": 4.4,
     "Column23": "701+",
     "Column24": 4.8,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Universidad de Oriente Santiago de Cuba",
     "Column4": "CU",
     "Column5": "Cuba",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 8.4,
     "Column11": "601+",
     "Column12": 5.2,
     "Column13": "601+",
     "Column14": 29.8,
     "Column15": 524,
     "Column16": 1.1,
     "Column17": "701+",
     "Column22": 7.3,
     "Column23": "701+",
     "Column24": 5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad de Panama",
     "Column4": "PA",
     "Column5": "Panama",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 5.5,
     "Column13": "601+",
     "Column14": 2.9,
     "Column15": "701+",
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 4.6,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 9,
     "Column23": "701+",
     "Column24": 49.1,
     "Column25": 262,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad de Piura",
     "Column4": "PE",
     "Column5": "Peru",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 7.5,
     "Column11": "601+",
     "Column12": 10.7,
     "Column13": "601+",
     "Column14": 5,
     "Column15": "701+",
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 5.7,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 3.3,
     "Column23": "701+",
     "Column24": 12.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad de San Carlos de Guatemala - USAC",
     "Column4": "GT",
     "Column5": "Guatemala",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "LO",
     "Column9": "A",
     "Column10": 10.2,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 4.7,
     "Column15": "701+",
     "Column16": 1,
     "Column17": "701+",
     "Column22": 9.4,
     "Column23": "701+",
     "Column24": 56,
     "Column25": 221,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "Universidad de Tarapaca",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.5,
     "Column11": "601+",
     "Column12": 1.6,
     "Column13": "601+",
     "Column14": 2.4,
     "Column15": "701+",
     "Column16": 9.8,
     "Column17": "701+",
     "Column22": 41.7,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad de Valparaíso (UV)",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 10.9,
     "Column11": "601+",
     "Column12": 4,
     "Column13": "601+",
     "Column14": 8.5,
     "Column15": "701+",
     "Column16": 2.9,
     "Column17": "701+",
     "Column18": 6.3,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 39.7,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad del Bío-Bío",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 3.2,
     "Column15": "701+",
     "Column16": 4,
     "Column17": "701+",
     "Column18": 8.7,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 30.3,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad del Desarrollo (UDD)",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 7.2,
     "Column11": "601+",
     "Column12": 8.6,
     "Column13": "601+",
     "Column14": 11.7,
     "Column15": "701+",
     "Column16": 2.3,
     "Column17": "701+",
     "Column18": 7.3,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 14.7,
     "Column23": "701+",
     "Column24": 4.9,
     "Column25": "701+",
     "Column26": 5.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad del Salvador",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 3.7,
     "Column11": "601+",
     "Column12": 5.8,
     "Column13": "601+",
     "Column14": 19.9,
     "Column15": 676,
     "Column16": 1,
     "Column17": "701+",
     "Column18": 4.9,
     "Column19": "701+",
     "Column20": 7.4,
     "Column21": "701+",
     "Column22": 1.2,
     "Column23": "701+",
     "Column24": 16.2,
     "Column25": 633,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad del Zulia",
     "Column4": "VE",
     "Column5": "Venezuela",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 10.1,
     "Column11": "601+",
     "Column12": 5.8,
     "Column13": "601+",
     "Column14": 4.1,
     "Column15": "701+",
     "Column16": 2.1,
     "Column17": "701+",
     "Column22": 13.4,
     "Column23": "701+",
     "Column24": 5.6,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Universidad Industrial de Santander - UIS",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 9.7,
     "Column11": "601+",
     "Column12": 14.8,
     "Column13": 564,
     "Column14": 4.6,
     "Column15": "701+",
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 4.1,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 12.4,
     "Column23": "701+",
     "Column24": 9,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Universidad La Salle (ULSA)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "C",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 15,
     "Column13": 559,
     "Column14": 22.3,
     "Column15": 630,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 1,
     "Column23": "701+",
     "Column24": 4.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column3": "Universidad Latinoamericana de Ciencia y Tecnología Costa Rica (ULACIT)",
     "Column4": "CR",
     "Column5": "Costa Rica",
     "Column6": "S",
     "Column7": "CO",
     "Column8": "LO",
     "Column9": "B",
     "Column10": 7.5,
     "Column11": "601+",
     "Column12": 8.8,
     "Column13": "601+",
     "Column14": 31.5,
     "Column15": 503,
     "Column16": 1,
     "Column17": "701+",
     "Column18": 8.5,
     "Column19": "701+",
     "Column20": 9.1,
     "Column21": "701+",
     "Column22": 1,
     "Column23": "701+",
     "Column24": 5.5,
     "Column25": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad Mayor de San Andrés (UMSA)",
     "Column4": "BO",
     "Column5": "Bolivia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "LO",
     "Column9": "A",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 1.2,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 6.4,
     "Column19": "701+",
     "Column20": 1,
     "Column21": "701+",
     "Column22": 15.1,
     "Column23": "701+",
     "Column24": 49.6,
     "Column25": 257,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Universidad Metropolitana",
     "Column4": "VE",
     "Column5": "Venezuela",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "LO",
     "Column9": "B",
     "Column10": 7.6,
     "Column11": "601+",
     "Column12": 15.1,
     "Column13": 555,
     "Column14": 4.4,
     "Column15": "701+",
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 4.4,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 1.1,
     "Column23": "701+",
     "Column24": 25.9,
     "Column25": 456,
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad Nacional Agraria la Molina",
     "Column4": "PE",
     "Column5": "Peru",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 6.4,
     "Column13": "601+",
     "Column14": 12.4,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 9.4,
     "Column23": "701+",
     "Column24": 6.7,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "Universidad Nacional Autónoma de Honduras (UNAH)",
     "Column4": "HN",
     "Column5": "Honduras",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "LO",
     "Column9": "A",
     "Column10": 5.1,
     "Column11": "601+",
     "Column12": 4,
     "Column13": "601+",
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 1,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column22": 18.5,
     "Column23": "701+",
     "Column24": 61.8,
     "Column25": 195,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "951-1000",
     "Column3": "Universidad Nacional de Ingeniería Peru",
     "Column4": "PE",
     "Column5": "Peru",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column10": 7.4,
     "Column11": "601+",
     "Column12": 27.2,
     "Column13": 354,
     "Column14": 9.1,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column22": 5.6,
     "Column23": "701+",
     "Column24": 19.1,
     "Column25": 579,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad Nacional de Mar del Plata",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.6,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 4.9,
     "Column15": "701+",
     "Column16": 3.5,
     "Column17": "701+",
     "Column22": 28.5,
     "Column23": "701+",
     "Column24": 1.2,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad Nacional de Río Cuarto - UNRC",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 1.6,
     "Column13": "601+",
     "Column14": 33.2,
     "Column15": 477,
     "Column16": 1.4,
     "Column17": "701+",
     "Column22": 10.7,
     "Column23": "701+",
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad Nacional de San Martín (UNSAM)",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 11.3,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 6.2,
     "Column15": "701+",
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 1.6,
     "Column19": "701+",
     "Column20": 3.7,
     "Column21": "701+",
     "Column22": 24.5,
     "Column23": "701+",
     "Column24": 1.2,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidad Nacional del Litoral",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 12.7,
     "Column11": "601+",
     "Column12": 5.7,
     "Column13": "601+",
     "Column14": 2.2,
     "Column15": "701+",
     "Column16": 2.5,
     "Column17": "701+",
     "Column18": 5,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 24.5,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 5.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "Universidad Nacional del Sur",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.7,
     "Column11": "601+",
     "Column12": 2,
     "Column13": "601+",
     "Column14": 17.8,
     "Column15": "701+",
     "Column16": 1.7,
     "Column17": "701+",
     "Column22": 27.2,
     "Column23": "701+",
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Universidad Nacional, Costa Rica",
     "Column4": "CR",
     "Column5": "Costa Rica",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 10.2,
     "Column11": "601+",
     "Column12": 10.5,
     "Column13": "601+",
     "Column14": 12.1,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 9.7,
     "Column19": "701+",
     "Column20": 2.3,
     "Column21": "701+",
     "Column22": 17.2,
     "Column23": "701+",
     "Column24": 4.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Universidad Peruana de Ciencias Aplicadas",
     "Column4": "PE",
     "Column5": "Peru",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 11.3,
     "Column11": "601+",
     "Column12": 22.6,
     "Column13": 415,
     "Column14": 3,
     "Column15": "701+",
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 13.6,
     "Column19": 693,
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 8,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidade do Estado do Rio de Janeiro (UERJ)",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9.5,
     "Column11": "601+",
     "Column12": 5.1,
     "Column13": "601+",
     "Column14": 17.4,
     "Column15": "701+",
     "Column16": 2.5,
     "Column17": "701+",
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 35.7,
     "Column23": "701+",
     "Column24": 18.2,
     "Column25": 598,
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidade Federal da Bahia",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 8.5,
     "Column15": "701+",
     "Column16": 3.1,
     "Column17": "701+",
     "Column18": 5.1,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 48.8,
     "Column23": "701+",
     "Column24": 12.4,
     "Column25": "701+",
     "Column26": 2.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidade Federal de Juiz de Fora- (UFJF)",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 2,
     "Column13": "601+",
     "Column14": 30.3,
     "Column15": 517,
     "Column16": 2.8,
     "Column17": "701+",
     "Column22": 35.2,
     "Column23": "701+",
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidade Federal de Pelotas ",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.7,
     "Column11": "601+",
     "Column12": 1.3,
     "Column13": "601+",
     "Column14": 26,
     "Column15": 571,
     "Column16": 4.7,
     "Column17": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 21.7,
     "Column23": "701+",
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidade Federal de Santa Maria",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.4,
     "Column11": "601+",
     "Column12": 1.5,
     "Column13": "601+",
     "Column14": 17.5,
     "Column15": "701+",
     "Column16": 5.2,
     "Column17": "701+",
     "Column18": 2.5,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 37.9,
     "Column23": "701+",
     "Column24": 6.3,
     "Column25": "701+",
     "Column26": 11.5,
     "Column27": "632=",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidade Federal de Viçosa (UFV)",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.1,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 10.9,
     "Column15": "701+",
     "Column16": 7.8,
     "Column17": "701+",
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 20.2,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidade Federal do Ceará (UFC)",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.4,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 6.3,
     "Column15": "701+",
     "Column16": 5.3,
     "Column17": "701+",
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 43.4,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "Universidade Federal do Rio Grande Do Norte",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 1.2,
     "Column13": "601+",
     "Column14": 9.5,
     "Column15": "701+",
     "Column16": 3.4,
     "Column17": "701+",
     "Column22": 45,
     "Column23": "701+",
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universidade Presbiteriana Mackenzie",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 3.5,
     "Column11": "601+",
     "Column12": 8.7,
     "Column13": "601+",
     "Column14": 12.3,
     "Column15": "701+",
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 2.4,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 12.5,
     "Column23": "701+",
     "Column24": 48.3,
     "Column25": 265,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Università degli studi di Bergamo",
     "Column4": "IT",
     "Column5": "Italy",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.7,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 1.9,
     "Column15": "701+",
     "Column16": 6.5,
     "Column17": "701+",
     "Column18": 4.3,
     "Column19": "701+",
     "Column20": 6.7,
     "Column21": "701+",
     "Column22": 27.8,
     "Column23": "701+",
     "Column24": 4.3,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universitas Islam Indonesia",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 11.2,
     "Column11": "601+",
     "Column12": 4.9,
     "Column13": "601+",
     "Column14": 2,
     "Column15": "701+",
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 10.7,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universitas Muhammadiyah Yogyakarta",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 3.2,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 57.9,
     "Column19": 331,
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 7.9,
     "Column23": "701+",
     "Column24": 2.3,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universitas Pendidikan Indonesia",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 10.9,
     "Column11": "601+",
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 12,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 13.2,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 13.1,
     "Column23": "701+",
     "Column24": 2.3,
     "Column25": "701+",
     "Column26": 3.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universitas Sumatera Utara",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.8,
     "Column11": "601+",
     "Column12": 8,
     "Column13": "601+",
     "Column14": 24.9,
     "Column15": 595,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 12.6,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 18.3,
     "Column23": "701+",
     "Column24": 5.5,
     "Column25": "701+",
     "Column26": 4.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universitatea de Vest din Timisoara \/ West University of Timisoara",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9.2,
     "Column11": "601+",
     "Column12": 9.7,
     "Column13": "601+",
     "Column14": 4.7,
     "Column15": "701+",
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 1.6,
     "Column19": "701+",
     "Column20": 6.7,
     "Column21": "701+",
     "Column22": 27.5,
     "Column23": "701+",
     "Column24": 23.5,
     "Column25": 481,
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Université de Limoges",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 17.2,
     "Column15": "701+",
     "Column16": 5.5,
     "Column17": "701+",
     "Column22": 58.8,
     "Column23": 646,
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Université Jean Moulin Lyon 3",
     "Column4": "FR",
     "Column5": "France",
     "Column6": "L",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 8.8,
     "Column11": "601+",
     "Column12": 5.2,
     "Column13": "601+",
     "Column14": 1.8,
     "Column15": "701+",
     "Column16": 2.3,
     "Column17": "701+",
     "Column18": 18.7,
     "Column19": 615,
     "Column20": 30.2,
     "Column21": 427,
     "Column22": 8.5,
     "Column23": "701+",
     "Column24": 4.4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Universiti Kuala Lumpur (UniKL)",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 32.7,
     "Column15": 483,
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 7.4,
     "Column19": "701+",
     "Column20": 3.9,
     "Column21": "701+",
     "Column22": 17.3,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Universiti Malaysia Perlis",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.8,
     "Column11": "601+",
     "Column12": 5.1,
     "Column13": "601+",
     "Column14": 19.2,
     "Column15": 694,
     "Column16": 6.7,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column20": 3.5,
     "Column21": "701+",
     "Column22": 31.1,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 2.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Universiti Teknikal Malaysia Melaka",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 5.7,
     "Column13": "601+",
     "Column14": 10.6,
     "Column15": "701+",
     "Column16": 5.8,
     "Column17": "701+",
     "Column18": 3.9,
     "Column19": "701+",
     "Column20": 4.8,
     "Column21": "701+",
     "Column22": 18.9,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 2.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Babylon",
     "Column4": "IQ",
     "Column5": "Iraq",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.6,
     "Column11": "601+",
     "Column12": 9.8,
     "Column13": "601+",
     "Column14": 14.5,
     "Column15": "701+",
     "Column16": 2,
     "Column17": "701+",
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 1,
     "Column21": "701+",
     "Column22": 29.9,
     "Column23": "701+",
     "Column24": 4.3,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Basrah",
     "Column4": "IQ",
     "Column5": "Iraq",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 9.3,
     "Column13": "601+",
     "Column14": 18.4,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 33.7,
     "Column23": "701+",
     "Column24": 10,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Bialystok",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 24,
     "Column15": 609,
     "Column16": 2.6,
     "Column17": "701+",
     "Column20": 4.7,
     "Column21": "701+",
     "Column22": 28.6,
     "Column23": "701+",
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of International Business and Economics",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.7,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 9.7,
     "Column15": "701+",
     "Column16": 3,
     "Column17": "701+",
     "Column18": 7,
     "Column19": "701+",
     "Column20": 30.3,
     "Column21": 425,
     "Column22": 5.3,
     "Column23": "701+",
     "Column24": 14,
     "Column25": 692,
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1401+",
     "Column3": "University of Kragujevac",
     "Column4": "RS",
     "Column5": "Serbia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.9,
     "Column11": "601+",
     "Column12": 3,
     "Column13": "601+",
     "Column14": 8.5,
     "Column15": "701+",
     "Column16": 3.7,
     "Column17": "701+",
     "Column18": 1,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 44.1,
     "Column23": "701+",
     "Column24": 9.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Kufa",
     "Column4": "IQ",
     "Column5": "Iraq",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 12.8,
     "Column13": "601+",
     "Column14": 29,
     "Column15": 535,
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 4.4,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 16.4,
     "Column23": "701+",
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Memphis",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.1,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 12.1,
     "Column15": "701+",
     "Column16": 8,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 8.3,
     "Column21": "701+",
     "Column22": 39.4,
     "Column23": "701+",
     "Column24": 7.6,
     "Column25": "701+",
     "Column26": 2.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Miskolc ",
     "Column4": "HU",
     "Column5": "Hungary",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 5.7,
     "Column13": "601+",
     "Column14": 18.6,
     "Column15": "701+",
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 3.1,
     "Column19": "701+",
     "Column20": 3.8,
     "Column21": "701+",
     "Column22": 19.2,
     "Column23": "701+",
     "Column24": 13,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Missouri Saint Louis",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.2,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 12.3,
     "Column15": "701+",
     "Column16": 10.5,
     "Column17": "701+",
     "Column18": 3.4,
     "Column19": "701+",
     "Column20": 3.1,
     "Column21": "701+",
     "Column22": 9.8,
     "Column23": "701+",
     "Column24": 7.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Niš",
     "Column4": "RS",
     "Column5": "Serbia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 5.5,
     "Column13": "601+",
     "Column14": 12.5,
     "Column15": "701+",
     "Column16": 2.4,
     "Column17": "701+",
     "Column20": 5.7,
     "Column21": "701+",
     "Column22": 49,
     "Column23": "701+",
     "Column24": 4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of North Carolina at Greensboro",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 2.6,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 8.9,
     "Column15": "701+",
     "Column16": 10.1,
     "Column17": "701+",
     "Column18": 5.9,
     "Column19": "701+",
     "Column20": 3.4,
     "Column21": "701+",
     "Column22": 50.7,
     "Column23": "701+",
     "Column24": 1.5,
     "Column25": "701+",
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Ostrava",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 1.1,
     "Column13": "601+",
     "Column14": 15.6,
     "Column15": "701+",
     "Column16": 3.6,
     "Column17": "701+",
     "Column18": 27.7,
     "Column19": 509,
     "Column20": 16.6,
     "Column21": 592,
     "Column22": 27,
     "Column23": "701+",
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Pannonia",
     "Column4": "HU",
     "Column5": "Hungary",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.2,
     "Column11": "601+",
     "Column12": 4,
     "Column13": "601+",
     "Column14": 22,
     "Column15": 637,
     "Column16": 8.5,
     "Column17": "701+",
     "Column18": 3.3,
     "Column19": "701+",
     "Column20": 8.3,
     "Column21": "701+",
     "Column22": 44.7,
     "Column23": "701+",
     "Column24": 4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "University of Pardubice",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 44.5,
     "Column15": 348,
     "Column16": 3.1,
     "Column17": "701+",
     "Column18": 9.7,
     "Column19": "701+",
     "Column20": 8.8,
     "Column21": "701+",
     "Column22": 18.7,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Peradeniya ",
     "Column4": "LK",
     "Column5": "Sri Lanka",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 9,
     "Column11": "601+",
     "Column12": 11.6,
     "Column13": "601+",
     "Column14": 12,
     "Column15": "701+",
     "Column16": 2,
     "Column17": "701+",
     "Column18": 1,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 22.7,
     "Column23": "701+",
     "Column24": 10.9,
     "Column25": "701+",
     "Column26": 5.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "University of Primorska",
     "Column4": "SI",
     "Column5": "Slovenia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.7,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 8.9,
     "Column15": "701+",
     "Column16": 4.1,
     "Column17": "701+",
     "Column18": 25,
     "Column19": 534,
     "Column20": 26.4,
     "Column21": 459,
     "Column22": 46.3,
     "Column23": "701+",
     "Column24": 4.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Rijeka",
     "Column4": "HR",
     "Column5": "Croatia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 4.3,
     "Column13": "601+",
     "Column14": 13.8,
     "Column15": "701+",
     "Column16": 4.3,
     "Column17": "701+",
     "Column18": 2.5,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 45.8,
     "Column23": "701+",
     "Column24": 12,
     "Column25": "701+",
     "Column26": 2.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Rzeszów",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 15.1,
     "Column15": "701+",
     "Column16": 2.7,
     "Column17": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 28.3,
     "Column23": "701+",
     "Column24": 3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of San Diego",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 6.2,
     "Column11": "601+",
     "Column12": 7,
     "Column13": "601+",
     "Column14": 25.5,
     "Column15": 581,
     "Column16": 2.7,
     "Column17": "701+",
     "Column18": 3.1,
     "Column19": "701+",
     "Column20": 6.8,
     "Column21": "701+",
     "Column22": 5.5,
     "Column23": "701+",
     "Column24": 12.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "University of San Francisco",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 5.9,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 18.7,
     "Column15": "701+",
     "Column16": 3.6,
     "Column17": "701+",
     "Column18": 2.3,
     "Column19": "701+",
     "Column20": 20.2,
     "Column21": 534,
     "Column22": 6,
     "Column23": "701+",
     "Column24": 29.5,
     "Column25": 422,
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Silesia in Katowice",
     "Column4": "PL",
     "Column5": "Poland",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 20.3,
     "Column15": 664,
     "Column16": 3.1,
     "Column17": "701+",
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 3.9,
     "Column21": "701+",
     "Column22": 52.5,
     "Column23": "701+",
     "Column24": 7.4,
     "Column25": "701+",
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of South Alabama (USA)",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.7,
     "Column11": "601+",
     "Column12": 5.9,
     "Column13": "601+",
     "Column14": 19,
     "Column15": 700,
     "Column16": 6.1,
     "Column17": "701+",
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 13.6,
     "Column23": "701+",
     "Column24": 2.3,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of Split",
     "Column4": "HR",
     "Column5": "Croatia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 4,
     "Column13": "601+",
     "Column14": 5,
     "Column15": "701+",
     "Column16": 4.2,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 47.5,
     "Column23": "701+",
     "Column24": 12.3,
     "Column25": "701+",
     "Column26": 4.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column3": "University of Sri Jayewardenepura",
     "Column4": "LK",
     "Column5": "Sri Lanka",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 10.4,
     "Column13": "601+",
     "Column14": 4.5,
     "Column15": "701+",
     "Column16": 3.3,
     "Column17": "701+",
     "Column20": 1,
     "Column21": "701+",
     "Column22": 14.1,
     "Column23": "701+",
     "Column24": 11,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of the Pacific",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 2.2,
     "Column11": "601+",
     "Column12": 3.5,
     "Column13": "601+",
     "Column14": 31.9,
     "Column15": 495,
     "Column16": 3,
     "Column17": "701+",
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 12,
     "Column21": 680,
     "Column22": 7.6,
     "Column23": "701+",
     "Column24": 8.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "University of Tyumen",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.6,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 37.1,
     "Column15": 432,
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 4.5,
     "Column19": "701+",
     "Column20": 13.8,
     "Column21": 645,
     "Column22": 27,
     "Column23": "701+",
     "Column24": 4.5,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University of West Bohemia ",
     "Column4": "CZ",
     "Column5": "Czech Republic",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.6,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 10.9,
     "Column15": "701+",
     "Column16": 3.8,
     "Column17": "701+",
     "Column22": 37.2,
     "Column23": "701+",
     "Column24": 5.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "University of Wisconsin Milwaukee ",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.6,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 9.7,
     "Column15": "701+",
     "Column16": 9.8,
     "Column17": "701+",
     "Column18": 5.7,
     "Column19": "701+",
     "Column20": 5.1,
     "Column21": "701+",
     "Column22": 39.5,
     "Column23": "701+",
     "Column24": 6.8,
     "Column25": "701+",
     "Column26": 4.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "University POLITEHNICA of Bucharest",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 12.7,
     "Column13": "601+",
     "Column14": 3.4,
     "Column15": "701+",
     "Column16": 6.9,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 2.6,
     "Column21": "701+",
     "Column22": 61.8,
     "Column23": 605,
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Vancouver Island University",
     "Column4": "CA",
     "Column5": "Canada",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "MD",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 3.7,
     "Column13": "601+",
     "Column14": 48.8,
     "Column15": 319,
     "Column16": 1.1,
     "Column17": "701+",
     "Column22": 2.6,
     "Column23": "701+",
     "Column24": 1.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Voronezh State University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.4,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 21.8,
     "Column15": 641,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 3,
     "Column19": "701+",
     "Column20": 21.4,
     "Column21": 523,
     "Column22": 12.1,
     "Column23": "701+",
     "Column24": 3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Western Michigan University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 4.9,
     "Column13": "601+",
     "Column14": 10,
     "Column15": "701+",
     "Column16": 5.8,
     "Column17": "701+",
     "Column18": 21.4,
     "Column19": 580,
     "Column20": 10.6,
     "Column21": "701+",
     "Column22": 33.3,
     "Column23": "701+",
     "Column24": 16.5,
     "Column25": 625,
     "Column26": 1.6,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1001-1200",
     "Column3": "Yamaguchi University ",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.9,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 41.8,
     "Column15": 370,
     "Column16": 4.2,
     "Column17": "701+",
     "Column18": 6.7,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 18.7,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Yogyakarta State University",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 11.7,
     "Column11": "601+",
     "Column12": 3.3,
     "Column13": "601+",
     "Column14": 8.7,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 13.5,
     "Column19": 695,
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 8.6,
     "Column23": "701+",
     "Column24": 2.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Youngsan University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "C",
     "Column10": 4,
     "Column11": "601+",
     "Column12": 10,
     "Column13": "601+",
     "Column14": 19.3,
     "Column15": 689,
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 2.5,
     "Column19": "701+",
     "Column20": 9.9,
     "Column21": "701+",
     "Column22": 2,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Yuan Ze University",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "C",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 5.6,
     "Column13": "601+",
     "Column14": 8.7,
     "Column15": "701+",
     "Column16": 9,
     "Column17": "701+",
     "Column18": 12.2,
     "Column19": "701+",
     "Column20": 11.7,
     "Column21": 686,
     "Column22": 12.4,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 2.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1201-1400",
     "Column2": "1201-1400",
     "Column3": "Zagazig University",
     "Column4": "EG",
     "Column5": "Egypt",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.7,
     "Column11": "601+",
     "Column12": 4.9,
     "Column13": "601+",
     "Column14": 3,
     "Column15": "701+",
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 67.7,
     "Column23": 513,
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 5.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": " Don State Technical University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.5,
     "Column11": "601+",
     "Column12": 3.5,
     "Column13": "601+",
     "Column14": 12.8,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 1.6,
     "Column19": "701+",
     "Column20": 14.9,
     "Column21": 626,
     "Column22": 11.4,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Akdeniz Üniversitesi",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.7,
     "Column11": "601+",
     "Column12": 4.8,
     "Column13": "601+",
     "Column14": 3.5,
     "Column15": "701+",
     "Column16": 3,
     "Column17": "701+",
     "Column18": 1.2,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 33.7,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "California Polytechnic State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.6,
     "Column11": "601+",
     "Column12": 9.1,
     "Column13": "601+",
     "Column14": 6.7,
     "Column15": "701+",
     "Column16": 3,
     "Column17": "701+",
     "Column22": 25.5,
     "Column23": "701+",
     "Column24": 8.7,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "California State University - Los Angeles",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 3.3,
     "Column13": "601+",
     "Column14": 3.8,
     "Column15": "701+",
     "Column16": 2.9,
     "Column17": "701+",
     "Column18": 3.6,
     "Column19": "701+",
     "Column20": 2.3,
     "Column21": "701+",
     "Column22": 14.9,
     "Column23": "701+",
     "Column24": 5.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "California State University, Long Beach",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.7,
     "Column11": "601+",
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 4.8,
     "Column15": "701+",
     "Column16": 2.9,
     "Column17": "701+",
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 3.4,
     "Column21": "701+",
     "Column22": 16.4,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Católica de Córdoba",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 5.3,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 17.1,
     "Column15": "701+",
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 3.6,
     "Column19": "701+",
     "Column20": 3.6,
     "Column21": "701+",
     "Column22": 2.3,
     "Column23": "701+",
     "Column24": 4.6,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "East West University",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 6.8,
     "Column11": "601+",
     "Column12": 12.8,
     "Column13": "601+",
     "Column14": 2.8,
     "Column15": "701+",
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 1,
     "Column21": "701+",
     "Column22": 7.4,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Fundación Universidad De Bogotá-Jorge Tadeo Lozano",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "C",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 3.3,
     "Column13": "601+",
     "Column14": 10.2,
     "Column15": "701+",
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 5.9,
     "Column23": "701+",
     "Column24": 12.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Humboldt State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 9.7,
     "Column15": "701+",
     "Column16": 3.7,
     "Column17": "701+",
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 9.9,
     "Column23": "701+",
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Illinois State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column10": 3.4,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 5.6,
     "Column15": "701+",
     "Column16": 4.1,
     "Column17": "701+",
     "Column18": 4.7,
     "Column19": "701+",
     "Column20": 3.1,
     "Column21": "701+",
     "Column22": 20,
     "Column23": "701+",
     "Column24": 7.6,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column3": "Indian Institute of Information Technology, Allahabad",
     "Column4": "IN",
     "Column5": "India",
     "Column6": "S",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 2,
     "Column15": "701+",
     "Column16": 10.7,
     "Column17": "701+",
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 2.6,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Indiana State University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.2,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 8.5,
     "Column15": "701+",
     "Column16": 3,
     "Column17": "701+",
     "Column18": 1.7,
     "Column19": "701+",
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 4.2,
     "Column23": "701+",
     "Column24": 6.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "International University of Business, Agriculture and Technology (IUBAT)",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 2.6,
     "Column15": "701+",
     "Column16": 2.3,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 8.6,
     "Column23": "701+",
     "Column24": 3.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Irkutsk State University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.3,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 14.1,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 1.9,
     "Column19": "701+",
     "Column20": 3.8,
     "Column21": "701+",
     "Column22": 26.4,
     "Column23": "701+",
     "Column24": 5.2,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Korkyt Ata Kyzylorda University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 7.9,
     "Column13": "601+",
     "Column14": 5.7,
     "Column15": "701+",
     "Column16": 1,
     "Column17": "701+",
     "Column18": 3.4,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 1,
     "Column23": "701+",
     "Column24": 3.3,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Kwansei Gakuin University",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 5.1,
     "Column13": "601+",
     "Column14": 5,
     "Column15": "701+",
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 21.9,
     "Column19": 570,
     "Column20": 3.4,
     "Column21": "701+",
     "Column22": 13,
     "Column23": "701+",
     "Column24": 7.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Lucian Blaga University of Sibiu",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 3.6,
     "Column11": "601+",
     "Column12": 3.2,
     "Column13": "601+",
     "Column14": 8.5,
     "Column15": "701+",
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 13.9,
     "Column19": 687,
     "Column20": 2.9,
     "Column21": "701+",
     "Column22": 17,
     "Column23": "701+",
     "Column24": 4.1,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "M.Kh. Dulaty Taraz Regional University",
     "Column4": "KZ",
     "Column5": "Kazakhstan",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 7.6,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 12,
     "Column15": "701+",
     "Column16": 1,
     "Column17": "701+",
     "Column18": 1.1,
     "Column19": "701+",
     "Column20": 6.7,
     "Column21": "701+",
     "Column22": 2.6,
     "Column23": "701+",
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "MIREA - Russian Technological University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 7,
     "Column13": "601+",
     "Column14": 6.6,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 9.5,
     "Column23": "701+",
     "Column24": 5.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Moscow City University",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 1.9,
     "Column13": "601+",
     "Column14": 10.7,
     "Column15": "701+",
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 2.7,
     "Column19": "701+",
     "Column20": 3.3,
     "Column21": "701+",
     "Column22": 2.5,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Naresuan University",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 2.8,
     "Column13": "601+",
     "Column14": 6.6,
     "Column15": "701+",
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 7.3,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 10.1,
     "Column23": "701+",
     "Column24": 2.5,
     "Column25": "701+",
     "Column26": 1.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "National University of Life and Environmental Sciences of Ukraine",
     "Column4": "UA",
     "Column5": "Ukraine",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.8,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 15.8,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 1.2,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 20,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "National University of Modern Languages (NUML), Islamabad",
     "Column4": "PK",
     "Column5": "Pakistan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "MD",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 7.3,
     "Column13": "601+",
     "Column14": 5.2,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 3.4,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 8.1,
     "Column23": "701+",
     "Column24": 2.6,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Nova Southeastern University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column10": 2.7,
     "Column11": "601+",
     "Column12": 3.5,
     "Column13": "601+",
     "Column14": 11.6,
     "Column15": "701+",
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 4.7,
     "Column19": "701+",
     "Column20": 3.9,
     "Column21": "701+",
     "Column22": 13.2,
     "Column23": "701+",
     "Column24": 8.9,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Pontifícia Universidade Católica de Minas Gerais – PUC Minas",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 4.6,
     "Column13": "601+",
     "Column14": 1.9,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 1.9,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 8.3,
     "Column23": "701+",
     "Column24": 9.4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Pontificia Universidade Católica do Campinas - PUC Campinas",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "C",
     "Column10": 4.2,
     "Column11": "601+",
     "Column12": 3.8,
     "Column13": "601+",
     "Column14": 7,
     "Column15": "701+",
     "Column16": 2,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 2.3,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Pontifícia Universidade Católica do Paraná",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 7.6,
     "Column15": "701+",
     "Column16": 3.9,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 28.9,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 1.4,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Russian State Agrarian University - Moscow Timiryazev Agricultural Academy",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7,
     "Column11": "601+",
     "Column12": 4.1,
     "Column13": "601+",
     "Column14": 6.4,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 1.9,
     "Column19": "701+",
     "Column20": 4.4,
     "Column21": "701+",
     "Column22": 9,
     "Column23": "701+",
     "Column24": 5.6,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Russian State University for the Humanities ",
     "Column4": "RU",
     "Column5": "Russia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 5.1,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 14.2,
     "Column15": "701+",
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 1.8,
     "Column19": "701+",
     "Column20": 4,
     "Column21": "701+",
     "Column22": 7.5,
     "Column23": "701+",
     "Column24": 3.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Sakarya University",
     "Column4": "TR",
     "Column5": "Turkey",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.6,
     "Column11": "601+",
     "Column12": 3.1,
     "Column13": "601+",
     "Column14": 3.1,
     "Column15": "701+",
     "Column16": 3.3,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 11.3,
     "Column21": 691,
     "Column22": 27.6,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Shanghai Normal University",
     "Column4": "CN",
     "Column5": "China (Mainland)",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5,
     "Column11": "601+",
     "Column12": 1.6,
     "Column13": "601+",
     "Column14": 10.9,
     "Column15": "701+",
     "Column16": 4.3,
     "Column17": "701+",
     "Column18": 4.5,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 26.5,
     "Column23": "701+",
     "Column24": 1.5,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Shibaura Institute of Technology",
     "Column4": "JP",
     "Column5": "Japan",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 3.1,
     "Column15": "701+",
     "Column16": 5.1,
     "Column17": "701+",
     "Column18": 13,
     "Column19": "701+",
     "Column20": 8.7,
     "Column21": "701+",
     "Column22": 14.5,
     "Column23": "701+",
     "Column24": 1.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Silpakorn University",
     "Column4": "TH",
     "Column5": "Thailand",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 5.4,
     "Column13": "601+",
     "Column14": 4.4,
     "Column15": "701+",
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 4.3,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 24.7,
     "Column23": "701+",
     "Column24": 2.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Soochow University (Taiwan)",
     "Column4": "TW",
     "Column5": "Taiwan",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 4.7,
     "Column11": "601+",
     "Column12": 5.3,
     "Column13": "601+",
     "Column14": 4.1,
     "Column15": "701+",
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 2.9,
     "Column21": "701+",
     "Column22": 3.4,
     "Column23": "701+",
     "Column24": 3.2,
     "Column25": "701+",
     "Column26": 1.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Soongsil University",
     "Column4": "KR",
     "Column5": "South Korea",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 3.5,
     "Column11": "601+",
     "Column12": 6,
     "Column13": "601+",
     "Column14": 8.3,
     "Column15": "701+",
     "Column16": 4.5,
     "Column17": "701+",
     "Column18": 5.2,
     "Column19": "701+",
     "Column20": 11.2,
     "Column21": 693,
     "Column22": 14.9,
     "Column23": "701+",
     "Column24": 2.8,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Stamford University Bangladesh",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "MD",
     "Column10": 9.7,
     "Column11": "601+",
     "Column12": 4.6,
     "Column13": "601+",
     "Column14": 1.2,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column22": 2.2,
     "Column23": "701+",
     "Column24": 3.6,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "State University of Malang",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 8.7,
     "Column15": "701+",
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 13.7,
     "Column19": 690,
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 11.5,
     "Column23": "701+",
     "Column24": 5.4,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Stefan cel Mare University of Suceava",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 4.5,
     "Column15": "701+",
     "Column16": 4.2,
     "Column17": "701+",
     "Column18": 2.3,
     "Column19": "701+",
     "Column20": 25.5,
     "Column21": 472,
     "Column22": 19.3,
     "Column23": "701+",
     "Column24": 4.1,
     "Column25": "701+",
     "Column26": 1.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "The University of Notre Dame, Australia",
     "Column4": "AU",
     "Column5": "Australia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 3,
     "Column11": "601+",
     "Column12": 3.7,
     "Column13": "601+",
     "Column14": 3.5,
     "Column15": "701+",
     "Column16": 7.2,
     "Column17": "701+",
     "Column18": 21.4,
     "Column19": 579,
     "Column20": 2.2,
     "Column21": "701+",
     "Column22": 10.6,
     "Column23": "701+",
     "Column24": 1.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Udayana University",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 6.4,
     "Column11": "601+",
     "Column12": 4.7,
     "Column13": "601+",
     "Column14": 5.7,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column18": 7.4,
     "Column19": "701+",
     "Column20": 3.4,
     "Column21": "701+",
     "Column22": 12.3,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "United International University",
     "Column4": "BD",
     "Column5": "Bangladesh",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "C",
     "Column10": 6.3,
     "Column11": "601+",
     "Column12": 8.7,
     "Column13": "601+",
     "Column14": 3,
     "Column15": "701+",
     "Column16": 2.5,
     "Column17": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 8.8,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad Alberto Hurtado",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 8.6,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 3.1,
     "Column15": "701+",
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 12.7,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 8.2,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad Autónoma de Aguascalientes",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 3.1,
     "Column11": "601+",
     "Column12": 1.6,
     "Column13": "601+",
     "Column14": 32.5,
     "Column15": 485,
     "Column16": 1.2,
     "Column17": "701+",
     "Column22": 10.8,
     "Column23": "701+",
     "Column24": 1.6,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad Autónoma de Baja California",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 4.4,
     "Column13": "601+",
     "Column14": 4.4,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 12.5,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 7.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Autónoma de Chile",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 5.3,
     "Column11": "601+",
     "Column12": 2,
     "Column13": "601+",
     "Column14": 4.2,
     "Column15": "701+",
     "Column16": 3.1,
     "Column17": "701+",
     "Column18": 12.4,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 32.6,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 2.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Autónoma de Querétaro (UAQ)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 6,
     "Column15": "701+",
     "Column16": 2.1,
     "Column17": "701+",
     "Column18": 3.8,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 7.3,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Autónoma de San Luis de Potosí",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 5.9,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 5.4,
     "Column15": "701+",
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 1.6,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 21.4,
     "Column23": "701+",
     "Column24": 1.6,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Autonoma de Yucatan",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 1.5,
     "Column13": "601+",
     "Column14": 4.9,
     "Column15": "701+",
     "Column16": 2.4,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 10.1,
     "Column23": "701+",
     "Column24": 1.6,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Autónoma del Estado de Morelos (UAEM)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 3.5,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 15.5,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 2.6,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 17.4,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad Bernardo O'Higgins",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "B",
     "Column10": 4.6,
     "Column11": "601+",
     "Column12": 4.3,
     "Column13": "601+",
     "Column14": 9.9,
     "Column15": "701+",
     "Column16": 2,
     "Column17": "701+",
     "Column18": 13.3,
     "Column19": "701+",
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 13.1,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Católica Boliviana \"San Pablo\"",
     "Column4": "BO",
     "Column5": "Bolivia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 3.9,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 4.2,
     "Column15": "701+",
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 2.3,
     "Column19": "701+",
     "Column20": 2.7,
     "Column21": "701+",
     "Column22": 7.7,
     "Column23": "701+",
     "Column24": 26.2,
     "Column25": 452,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Católica de Colombia ",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 3.2,
     "Column13": "601+",
     "Column14": 5.1,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 3.3,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 5,
     "Column23": "701+",
     "Column24": 2.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Católica de La Santísima Concepción - UCSC",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "M",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 3.4,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 8.2,
     "Column15": "701+",
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 5.2,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 28.3,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Católica de Santiago de Guayaquil",
     "Column4": "EC",
     "Column5": "Ecuador",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "B",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 1.3,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 16.7,
     "Column19": 641,
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 10.3,
     "Column23": "701+",
     "Column24": 16.6,
     "Column25": 622,
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad Católica de Temuco",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 1.4,
     "Column13": "601+",
     "Column14": 2,
     "Column15": "701+",
     "Column16": 4.8,
     "Column17": "701+",
     "Column18": 35.1,
     "Column19": 456,
     "Column22": 14.3,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Central de Chile",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "C",
     "Column10": 4.4,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 3,
     "Column15": "701+",
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 4.6,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 9.4,
     "Column23": "701+",
     "Column24": 2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad de Caldas",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 3.5,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column22": 6.5,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad de Carabobo",
     "Column4": "VE",
     "Column5": "Venezuela",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "LO",
     "Column9": "A",
     "Column10": 4.7,
     "Column11": "601+",
     "Column12": 6.1,
     "Column13": "601+",
     "Column14": 17.4,
     "Column15": "701+",
     "Column16": 1,
     "Column17": "701+",
     "Column22": 3.1,
     "Column23": "701+",
     "Column24": 5.4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad de Cartagena",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 4.8,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 4,
     "Column15": "701+",
     "Column16": 3.2,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 11,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad de Córdoba - Colombia",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 11.8,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 2.9,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column22": 5.3,
     "Column23": "701+",
     "Column24": 1.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad de Cuenca",
     "Column4": "EC",
     "Column5": "Ecuador",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 7.2,
     "Column11": "601+",
     "Column12": 2.2,
     "Column13": "601+",
     "Column14": 4.9,
     "Column15": "701+",
     "Column16": 2,
     "Column17": "701+",
     "Column18": 2.2,
     "Column19": "701+",
     "Column20": 1.4,
     "Column21": "701+",
     "Column22": 24.4,
     "Column23": "701+",
     "Column24": 7.8,
     "Column25": "701+",
     "Column26": 2.7,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad de Guanajuato",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 5,
     "Column15": "701+",
     "Column16": 2.8,
     "Column17": "701+",
     "Column18": 5.9,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 22.9,
     "Column23": "701+",
     "Column24": 6.8,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad de La Salle",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 6.4,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 2.9,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 5,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad de La Serena",
     "Column4": "CL",
     "Column5": "Chile",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 5.6,
     "Column17": "701+",
     "Column18": 4.4,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 14.7,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad de las Fuerzas Armadas ESPE (Ex - Escuela Politécnica del Ejército)",
     "Column4": "EC",
     "Column5": "Ecuador",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 3.9,
     "Column13": "601+",
     "Column14": 10.6,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 2.7,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 11,
     "Column23": "701+",
     "Column24": 4.5,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad de Medellín",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 6.5,
     "Column11": "601+",
     "Column12": 3.2,
     "Column13": "601+",
     "Column14": 7.9,
     "Column15": "701+",
     "Column16": 2.2,
     "Column17": "701+",
     "Column18": 2.7,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 5.9,
     "Column23": "701+",
     "Column24": 7.8,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad de Santander - UDES",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "C",
     "Column10": 3.3,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 8,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 3.7,
     "Column19": "701+",
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 11.9,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad de Sonora",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.6,
     "Column11": "601+",
     "Column12": 1.6,
     "Column13": "601+",
     "Column14": 4.4,
     "Column15": "701+",
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 3.4,
     "Column19": "701+",
     "Column20": 1.5,
     "Column21": "701+",
     "Column22": 9.2,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad del Cauca",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 7.9,
     "Column11": "601+",
     "Column12": 2,
     "Column13": "601+",
     "Column14": 5.3,
     "Column15": "701+",
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 21.7,
     "Column19": 573,
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 11.8,
     "Column23": "701+",
     "Column24": 4.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad del Valle de Mexico (UVM)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "LO",
     "Column9": "C",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 9.9,
     "Column13": "601+",
     "Column14": 3.9,
     "Column15": "701+",
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 2,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 4.8,
     "Column23": "701+",
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Michoacana de San Nicolás de Hidalgo",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 1.8,
     "Column13": "601+",
     "Column14": 2.5,
     "Column15": "701+",
     "Column16": 2,
     "Column17": "701+",
     "Column22": 14,
     "Column23": "701+",
     "Column24": 1.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Nacional de Quilmes",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.7,
     "Column11": "601+",
     "Column12": 1.7,
     "Column13": "601+",
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 1.7,
     "Column17": "701+",
     "Column18": 2.8,
     "Column19": "701+",
     "Column20": 1.8,
     "Column21": "701+",
     "Column22": 11.9,
     "Column23": "701+",
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Nacional de Tucumán",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 7.4,
     "Column11": "601+",
     "Column12": 2.1,
     "Column13": "601+",
     "Column14": 2.6,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column22": 31,
     "Column23": "701+",
     "Column24": 3.6,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad Nacional del Comahue",
     "Column4": "AR",
     "Column5": "Argentina",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 5.1,
     "Column11": "601+",
     "Column12": 2.4,
     "Column13": "601+",
     "Column14": 20.4,
     "Column15": 661,
     "Column16": 1.3,
     "Column17": "701+",
     "Column22": 13.1,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "UNIVERSIDAD POLITECNICA SALESIANA",
     "Column4": "EC",
     "Column5": "Ecuador",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 6.4,
     "Column11": "601+",
     "Column12": 3.3,
     "Column13": "601+",
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 6.2,
     "Column19": "701+",
     "Column20": 1.7,
     "Column21": "701+",
     "Column22": 6.8,
     "Column23": "701+",
     "Column24": 4.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad Popular Autónoma del Estado de Puebla (UPAEP)",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 5.2,
     "Column11": "601+",
     "Column12": 3.4,
     "Column13": "601+",
     "Column14": 13,
     "Column15": "701+",
     "Column16": 1.2,
     "Column17": "701+",
     "Column18": 3.9,
     "Column19": "701+",
     "Column20": 2,
     "Column21": "701+",
     "Column22": 5.1,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad San Ignacio de Loyola",
     "Column4": "PE",
     "Column5": "Peru",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 6.1,
     "Column11": "601+",
     "Column12": 9,
     "Column13": "601+",
     "Column14": 2,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 4.1,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 7.6,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Tecnica Particular De Loja (UTPL)",
     "Column4": "EC",
     "Column5": "Ecuador",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 6,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 1.4,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 7.9,
     "Column19": "701+",
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 15.7,
     "Column23": "701+",
     "Column24": 4.6,
     "Column25": "701+",
     "Column26": 1.5,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Tecnológica de Bolívar",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 2.2,
     "Column11": "601+",
     "Column12": 3.5,
     "Column13": "601+",
     "Column14": 2.9,
     "Column15": "701+",
     "Column16": 2.7,
     "Column17": "701+",
     "Column18": 3.6,
     "Column19": "701+",
     "Column20": 2.3,
     "Column21": "701+",
     "Column22": 6.8,
     "Column23": "701+",
     "Column24": 6.4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad Tecnológica de la Habana José Antonio Echeverría, Cujae",
     "Column4": "CU",
     "Column5": "Cuba",
     "Column6": "M",
     "Column7": "SP",
     "Column8": "LO",
     "Column9": "A",
     "Column10": 4.9,
     "Column11": "601+",
     "Column12": 1.3,
     "Column13": "601+",
     "Column14": 19.1,
     "Column15": 699,
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 3.1,
     "Column19": "701+",
     "Column20": 4.1,
     "Column21": "701+",
     "Column22": 4.8,
     "Column23": "701+",
     "Column24": 5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidad Tecnológica de Pereira",
     "Column4": "CO",
     "Column5": "Colombia",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 3.6,
     "Column13": "601+",
     "Column14": 3.4,
     "Column15": "701+",
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 9.6,
     "Column23": "701+",
     "Column24": 2.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universidad Veracruzana",
     "Column4": "MX",
     "Column5": "Mexico",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 7.7,
     "Column11": "601+",
     "Column12": 2.7,
     "Column13": "601+",
     "Column14": 7.3,
     "Column15": "701+",
     "Column16": 1.4,
     "Column17": "701+",
     "Column22": 13.6,
     "Column23": "701+",
     "Column24": 1.5,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidade do Estado de Santa Catarina",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.7,
     "Column11": "601+",
     "Column12": 1.3,
     "Column13": "601+",
     "Column14": 15.4,
     "Column15": "701+",
     "Column16": 1.7,
     "Column17": "701+",
     "Column22": 9,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidade Estadual de Londrina",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.9,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 13.7,
     "Column15": "701+",
     "Column16": 3.3,
     "Column17": "701+",
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 13.4,
     "Column23": "701+",
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 2.8,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidade Federal da Paraíba",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 7.3,
     "Column15": "701+",
     "Column16": 3.1,
     "Column17": "701+",
     "Column18": 2.5,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 40.8,
     "Column23": "701+",
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidade Federal de Goiás",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 1.3,
     "Column13": "601+",
     "Column14": 6.8,
     "Column15": "701+",
     "Column16": 4.2,
     "Column17": "701+",
     "Column18": 3.5,
     "Column19": "701+",
     "Column20": 1.2,
     "Column21": "701+",
     "Column22": 32.7,
     "Column23": "701+",
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 1.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidade Federal de Uberlândia",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.8,
     "Column11": "601+",
     "Column12": 1.7,
     "Column13": "601+",
     "Column14": 13.8,
     "Column15": "701+",
     "Column16": 3.5,
     "Column17": "701+",
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 1.3,
     "Column21": "701+",
     "Column22": 28.6,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 3.2,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidade Federal do Estado do Rio de Janeiro - UNIRIO",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 2.9,
     "Column11": "601+",
     "Column12": 2,
     "Column13": "601+",
     "Column14": 8.1,
     "Column15": "701+",
     "Column16": 2.1,
     "Column17": "701+",
     "Column22": 8.6,
     "Column23": "701+",
     "Column24": 1.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universidade Federal do Parà - UFPA",
     "Column4": "BR",
     "Column5": "Brazil",
     "Column6": "XL",
     "Column7": "FC",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3.6,
     "Column11": "601+",
     "Column12": 1.7,
     "Column13": "601+",
     "Column14": 6.3,
     "Column15": "701+",
     "Column16": 2.6,
     "Column17": "701+",
     "Column18": 2.5,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 35.4,
     "Column23": "701+",
     "Column24": 1.3,
     "Column25": "701+",
     "Column26": 1.9,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universitas Andalas",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 8.1,
     "Column11": "601+",
     "Column12": 5.1,
     "Column13": "601+",
     "Column14": 7,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 6.2,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 4.5,
     "Column23": "701+",
     "Column24": 3.7,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universitas Kristen Petra",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "M",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "C",
     "Column10": 7.1,
     "Column11": "601+",
     "Column12": 5.2,
     "Column13": "601+",
     "Column14": 7.8,
     "Column15": "701+",
     "Column16": 1.6,
     "Column17": "701+",
     "Column18": 3.2,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 6,
     "Column23": "701+",
     "Column24": 2.5,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Universitas Muhammadiyah Surakarta",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "HI",
     "Column9": "B",
     "Column10": 5.5,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 6.5,
     "Column15": "701+",
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 8.4,
     "Column19": "701+",
     "Column20": 1.1,
     "Column21": "701+",
     "Column22": 4.5,
     "Column23": "701+",
     "Column24": 2.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "Universiti Sains Islam Malaysia",
     "Column4": "MY",
     "Column5": "Malaysia",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 6.6,
     "Column11": "601+",
     "Column12": 2.5,
     "Column13": "601+",
     "Column14": 8.8,
     "Column15": "701+",
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 8.4,
     "Column19": "701+",
     "Column20": 6.1,
     "Column21": "701+",
     "Column22": 12.5,
     "Column23": "701+",
     "Column24": 3.3,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "University of Central Oklahoma",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 5.6,
     "Column11": "601+",
     "Column12": 2.9,
     "Column13": "601+",
     "Column14": 6.5,
     "Column15": "701+",
     "Column16": 1.5,
     "Column17": "701+",
     "Column18": 15.9,
     "Column19": 654,
     "Column20": 9.9,
     "Column21": "701+",
     "Column22": 5.2,
     "Column23": "701+",
     "Column24": 2.9,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "University of Craiova",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 4.5,
     "Column11": "601+",
     "Column12": 2.2,
     "Column13": "601+",
     "Column14": 3.2,
     "Column15": "701+",
     "Column16": 2.1,
     "Column17": "701+",
     "Column22": 21.8,
     "Column23": "701+",
     "Column24": 13.4,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "University of Lampung",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column10": 4.7,
     "Column11": "601+",
     "Column12": 2.3,
     "Column13": "601+",
     "Column14": 5.1,
     "Column15": "701+",
     "Column16": 1.3,
     "Column17": "701+",
     "Column18": 1.2,
     "Column19": "701+",
     "Column20": 1,
     "Column21": "701+",
     "Column22": 3.4,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "University of Mataram",
     "Column4": "ID",
     "Column5": "Indonesia",
     "Column6": "XL",
     "Column7": "FO",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 6.4,
     "Column11": "601+",
     "Column12": 1.7,
     "Column13": "601+",
     "Column14": 3.8,
     "Column15": "701+",
     "Column16": 1.1,
     "Column17": "701+",
     "Column18": 1.5,
     "Column19": "701+",
     "Column20": 1,
     "Column21": "701+",
     "Column22": 3.7,
     "Column23": "701+",
     "Column24": 2.2,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "University of Montana Missoula ",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "HI",
     "Column9": "A",
     "Column10": 3,
     "Column11": "601+",
     "Column12": 2.2,
     "Column13": "601+",
     "Column14": 10.6,
     "Column15": "701+",
     "Column16": 6.1,
     "Column17": "701+",
     "Column18": 1.3,
     "Column19": "701+",
     "Column20": 1.9,
     "Column21": "701+",
     "Column22": 6.5,
     "Column23": "701+",
     "Column24": 3.1,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "University of Oradea",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "L",
     "Column7": "FC",
     "Column8": "MD",
     "Column9": "A",
     "Column10": 5.6,
     "Column11": "601+",
     "Column12": 2.2,
     "Column13": "601+",
     "Column14": 4,
     "Column15": "701+",
     "Column16": 1.9,
     "Column17": "701+",
     "Column18": 1.5,
     "Column19": "701+",
     "Column20": 5.2,
     "Column21": "701+",
     "Column22": 34.5,
     "Column23": "701+",
     "Column24": 6.2,
     "Column25": "701+",
     "Column26": 2.3,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1201-1400",
     "Column3": "University of San Carlos",
     "Column4": "PH",
     "Column5": "Philippines",
     "Column6": "M",
     "Column7": "CO",
     "Column8": "MD",
     "Column9": "C",
     "Column10": 7.2,
     "Column11": "601+",
     "Column12": 9.4,
     "Column13": "601+",
     "Column14": 3.3,
     "Column15": "701+",
     "Column16": 1.8,
     "Column17": "701+",
     "Column18": 2.1,
     "Column19": "701+",
     "Column20": 2.1,
     "Column21": "701+",
     "Column22": 6.4,
     "Column23": "701+",
     "Column24": 9.6,
     "Column25": "701+",
     "Column26": 1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "University Politehnica of Timisoara, UPT",
     "Column4": "RO",
     "Column5": "Romania",
     "Column6": "L",
     "Column7": "FO",
     "Column8": "VH",
     "Column9": "A",
     "Column10": 4.1,
     "Column11": "601+",
     "Column12": 4.2,
     "Column13": "601+",
     "Column14": 7.2,
     "Column15": "701+",
     "Column16": 3.9,
     "Column17": "701+",
     "Column18": 1.4,
     "Column19": "701+",
     "Column20": 2.5,
     "Column21": "701+",
     "Column22": 18.6,
     "Column23": "701+",
     "Column24": 3.9,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    },
    {
     "2025 QS World University Rankings": "1401+",
     "Column2": "1401+",
     "Column3": "Western Washington University",
     "Column4": "US",
     "Column5": "United States",
     "Column6": "L",
     "Column7": "CO",
     "Column8": "HI",
     "Column10": 2.6,
     "Column11": "601+",
     "Column12": 2.6,
     "Column13": "601+",
     "Column14": 7.3,
     "Column15": "701+",
     "Column16": 3.5,
     "Column17": "701+",
     "Column18": 9.7,
     "Column19": "701+",
     "Column20": 1.6,
     "Column21": "701+",
     "Column22": 12.4,
     "Column23": "701+",
     "Column24": 1.5,
     "Column25": "701+",
     "Column26": 1.1,
     "Column27": "701+",
     "Column28": "-"
    }
   ]
   