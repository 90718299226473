import React, { Component } from 'react'
import { connect } from 'react-redux';
import CloseIcon from '../../../components/page_component/CloseIcon';
import { changeStateValue } from '../../../redux/MainReducer';
import DropDown from './DropDown';
import Input from './Input';
import { getInstitutionTypes,insertNewData, getCityByCountry, getStatesByCountry, getCityByState  } from '../../../actions/WorkerActions';
import { getAllCountries } from '../../../actions/MainAction';
export class ApproveIntModal extends Component {
    state={
        btnVisible: false
    }
    componentDidMount(){
        if(this.props.instApproveData?.approve?.institutions?.state_id){
            this.props.getStatesByCountry(this.props.instApproveData?.approve?.institutions?.country_id, this.props.languageId)
        }
        if(this.props.instApproveData?.approve?.institutions?.city_id){
            this.props.getCityByCountry(this.props.instApproveData?.approve?.institutions?.country_id, this.props.languageId)
        }
        this.props.getInstitutionTypes(this.props.languageId)
        this.props.getAllCountries('', '',this.props.languageId)
    }
    closeModal=()=>{
        this.props.changeStateValue({
            name: 'instApproveData',
            value: ''
        })
    }

    getPlaceholder(array, id, placeholder){
        if(id && array.length !== 0){
            if(array.find(data=> data.value == id)){
                return array.find(data=> data.value == id).label
            }else{
                return placeholder
            }
        }else{
            return placeholder
        }
    }

    saveChanges(data){
       
        if(data.name != ''){
            this.setState({btnVisible: true})
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property])
            }
            formData.append("institutions_json", JSON.stringify(data.institutions));
            formData.append("active", "1");
            let url = '/institution'
            if(data.id && data.id !== ''){
                formData.append("_method", "put");
                url = `/institution/${data.id}`;
            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
            this.setState({btnVisible: false})
                this.props.getAfterSubmit()
                this.closeModal()
            })
        }
    }

    getOtherData=(type, id)=>{
        if(type == "country"){
            this.props.getStatesByCountry(id, this.props.languageId);
            this.props.getCityByCountry(id, this.props.languageId);
        }else if(type == "state"){
            this.props.getCityByState(id, this.props.languageId);

        }
        console.log(type, id)
    }
  render() {
    const {instApproveData, institutionTypes, allCountries, stateByCountry, cityByCountry, } = this.props;
    const {btnVisible} = this.state;
    const instTypeOption = [];
    const countriesOption = [];
    const citiesOption = [];
    const stateOption = [];
    for(const property of stateByCountry){
        const data={
            label: property.name,
            value: property.state_id
        };
        stateOption.push(data)
    }
    for(const property of cityByCountry){
        const data={
            label: property.name,
            value: property.city_id
        };
        citiesOption.push(data)
    }
    for(const property of institutionTypes){
        const data={
            label: property.type,
            value: property.institutional_type_id
        };
        instTypeOption.push(data)
    }
    for(const property of allCountries){
        const data={
            label: property.name,
            value: property.countries_id
        };
        countriesOption.push(data)
    }


    return (
      <div className='background_shadow' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div className='approve_modal_body'>
            <div className='flex justify-between items-center text-lg'>
                <h2>{instApproveData.name}</h2>
                <CloseIcon closeModalBtn={()=>this.closeModal()} />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5 justify-between">
                <div>
                    <Input name="instApproveData.approve.name" value={instApproveData?.approve?.name} placeholder={"Institution Title"} label={"Institution Title"} />
                    {
                        instApproveData?.approve?.name != instApproveData?.name ?
                        <p className='text-red-700 text-sm'>changed</p>:null
                    }
                    
                </div>
               <div>
                <DropDown getOtherData={this.getOtherData} placeholder={this.getPlaceholder(instTypeOption, instApproveData?.approve?.institutions?.type, "Institution Type" )} zIndex={101} type={'type'} name="instApproveData.approve.institutions.type" option={instTypeOption}  label={"Institution Type"} />  
                {
                    instApproveData?.approve?.institutions?.type != instApproveData?.institutions?.type?
                    <p className='text-red-700 text-sm'>changed</p>:null
                }    
               </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5 justify-between mt-4">
                <div>
                    <DropDown getOtherData={this.getOtherData} placeholder={this.getPlaceholder(countriesOption, instApproveData?.approve?.institutions?.country_id, "Country" )} zIndex={101} type={'country'} name="instApproveData.approve.institutions.country_id" option={countriesOption}  label={"Country"} />  
                    {
                        instApproveData?.approve?.institutions?.country_id != instApproveData?.institutions?.country_id?
                        <p className='text-red-700 text-sm'>changed</p>:null
                    }    
                </div>
                {
                    stateOption.length != 0?
                    <div>
                        <DropDown getOtherData={this.getOtherData} placeholder={this.getPlaceholder(stateOption, instApproveData?.approve?.institutions?.state_id, "State" )} zIndex={101} type={'state'} name="instApproveData.approve.institutions.state_id" option={stateOption}  label={"State"} />  
                        {
                            instApproveData?.approve?.institutions?.state_id != instApproveData?.institutions?.state_id?
                            <p className='text-red-700 text-sm'>changed</p>:null
                        }    
                    </div>
                    :null
                }
                <div>
                    <DropDown getOtherData={this.getOtherData} placeholder={this.getPlaceholder(citiesOption, instApproveData?.approve?.institutions?.city_id, "City" )} zIndex={101} type={'type'} name="instApproveData.approve.institutions.city_id" option={citiesOption}  label={"City"} />  
                    {
                        instApproveData?.approve?.institutions?.city_id != instApproveData?.institutions?.city_id?
                        <p className='text-red-700 text-sm'>changed</p>:null
                    }    
                </div>
            </div>
            <div className="flex justify-between items-center mt-5" >
                    
                
                <button disabled={btnVisible} onClick={()=>this.closeModal()}   className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                Close
                </button>
                <button disabled={btnVisible} onClick={this.saveChanges.bind(this, instApproveData.approve)}  className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-6 py-3 sm:px-6  ' style={{ fontSize: '16px', color: 'white' }}>
                Save
                </button>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    instApproveData: state.Data.instApproveData,
    institutionTypes: state.Data.institutionTypes,
    languageId: state.Data.languageId,
    allCountries: state.Data.allCountries,
    stateByCountry: state.Data.stateByCountry,
    cityByCountry: state.Data.cityByCountry,
});
const mapDispatchToProps = {
    changeStateValue, 
    getInstitutionTypes, 
    getAllCountries, 
    getStatesByCountry, 
    getCityByCountry, 
    getCityByState,
    insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(ApproveIntModal)