import React, { Component } from 'react'

export class EditSvg extends Component {
  render() {
    const {editSvgBtn=()=>{}} = this.props
    return (
        <svg
        onClick={()=>editSvgBtn()}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 423.278 423.278"
        style={{
          enableBackground: "new 0 0 423.278 423.278",
          cursor: 'pointer'
        }}
        xmlSpace="preserve"
 
      >
        <path
          style={{
            fill: "none",
          }}
          d="m186.95 343.077-39.367 11.362h146.668c6.629 0 12 5.373 12 12s-5.371 12-12 12H66.541a11.92 11.92 0 0 1-1.883-.163c-.643.105-1.287.163-1.928.163a12.002 12.002 0 0 1-11.737-14.51l27.934-130.598a11.999 11.999 0 0 1 3.25-5.976L228.255 81.277H26.412v318.001h318.001v-211.55L192.108 340.033a12.012 12.012 0 0 1-5.158 3.044z"
        />
        <path
          style={{
            fill: "none",
          }}
          d="m163.321 324.917-65.442-65.443-19.225 89.878zM327.089 24c-4.922 0-8.928 1.6-12.604 5.035-.145.137-.293.268-.443.395l-8.666 8.666 77.363 77.362 8.471-8.47c.041-.042.084-.082.125-.123l.086-.085c.123-.145.25-.288.381-.428 16.182-17.318-11.684-47.783-20.609-56.707C354.897 33.346 338.821 24 327.089 24z"
        />
        <path
          style={{
            fill: "#73d0f4",
          }}
          d="m107.91 235.564 77.362 77.362 180.496-180.498-77.363-77.363L107.91 235.564zm201.47-124.11c4.686 4.686 4.686 12.284 0 16.97L182.606 255.197a11.968 11.968 0 0 1-8.486 3.514 11.96 11.96 0 0 1-8.484-3.514c-4.686-4.687-4.686-12.285 0-16.971l126.773-126.772c4.686-4.686 12.285-4.686 16.971 0z"
        />
        <path
          style={{
            fill: "#3d6889",
          }}
          d="M165.636 255.197a11.963 11.963 0 0 0 8.484 3.514c3.072 0 6.142-1.172 8.486-3.514L309.38 128.424c4.686-4.687 4.686-12.285 0-16.97-4.685-4.686-12.285-4.686-16.971 0L165.636 238.226c-4.686 4.687-4.686 12.284 0 16.971z"
        />
        <path
          style={{
            fill: "#3d6889",
          }}
          d="M417.848 76.476c-4.635-13.872-14.898-29.019-29.686-43.803C373.259 17.768 350.565 0 327.089 0c-10.531 0-20.234 3.703-28.135 10.721-.412.316-.805.661-1.176 1.032l-45.525 45.524H14.412c-6.627 0-12 5.373-12 12v342.001c0 6.627 5.373 12 12 12h342.001c6.627 0 12-5.373 12-12V166.123c0-.748-.078-1.476-.209-2.186l39.853-39.854c.08-.079.16-.157.238-.237l.006-.005.781-.782a11.86 11.86 0 0 0 1.017-1.157c10.913-12.241 13.595-27.923 7.749-45.426zM163.321 324.917l-84.667 24.436 19.225-89.878 65.442 65.442zm21.951-11.991-77.362-77.362L288.405 55.066l77.363 77.363-180.496 180.497zm159.141 86.352H26.412V81.277h201.843L82.178 227.355a11.999 11.999 0 0 0-3.25 5.976L50.994 363.928a12 12 0 0 0 11.737 14.51h231.52c6.629 0 12-5.373 12-12s-5.371-12-12-12H147.583l39.367-11.362a12.012 12.012 0 0 0 5.158-3.044l152.305-152.305v211.551zm47.389-292.927c-.131.14-.258.283-.381.428l-.086.085-.125.123-8.471 8.47-77.363-77.362 8.666-8.666c.15-.127.299-.258.443-.395C318.161 25.6 322.167 24 327.089 24c11.732 0 27.809 9.346 44.103 25.644 8.926 8.924 36.791 39.389 20.61 56.707z"
        />
      </svg>
    )
  }
}

export default EditSvg