import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import SectionHeader from '../../../page_component/SectionHeader'
import { getRelatedPrograms } from '../../../../actions/MainAction';
import Card from './Card';

function withHooks(Component){
    return props => <Component {...props} params={useParams()} />
}
export class RelatedPrograms extends Component {
    componentDidMount(){
        this.props.getRelatedPrograms(1, this.props.languageId, this.props.params.id)
    }
  render() {
    const {relatedPrograms, mainTransateData, getAfterRedirect} = this.props;

    return (
      <div className='container mt-20'>
        <SectionHeader arialLabelTitle='mainTransateData.related_program_title' title={mainTransateData?.related_program_title} />
      
        <div className="glide__track" data-glide-el="track">
          <ul className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5">
           {
            relatedPrograms.map((data, i)=>{
              if(i<5){
                return(
                  <Card 
                      key={i}
                      image={data?.program?.image_full_url !== ''? data?.program?.image_full_url:data?.program?.education_degree?.image_full_url}
                      name={data.name}
                      country={data.country}
                      city={data.city}
                      id={data?.program?.id}
                      getAfterRedirect={getAfterRedirect}
                  />
              )
              }
               
            })
           }
          </ul>
      
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    relatedPrograms: state.Data.relatedPrograms,
    languageId: state.Data.languageId,
    mainTransateData: state.Data.mainTransateData,

});
const mapDispatchToProps = {getRelatedPrograms}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(RelatedPrograms))