import axios from 'axios';
import { MAIN_API, MAIN_API2 } from '../MAIN_API';
import {changeStateValue} from '../redux/MainReducer';
import { getMessagesBox } from './MainAction';

export const getAplliedPrograms = (status, lang_id, id='', page='')=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLongLoader'})

    return await axios.get(`${MAIN_API}/student-apply?status=${status}&lang_id=${lang_id}&query_type=applied&student_id=${id}&page=${page}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({type: 'MAIN_SLICE/hideLongLoader'})
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'appliedPrograms', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}


export const getInstitutions = (page, countryId, lang_id, keyword='', stateId='', cityId='', rank_exists='')=> async (dispatch) =>{
   
    dispatch({type: 'MAIN_SLICE/setLoader'})
 
    return await axios.get(`${MAIN_API}/institution?page=${page}&keyword=${keyword.toLowerCase()}&filterByCountry=${countryId}&filterByType=&filterByState=${stateId}&filterByCity=${cityId}&lang_id=${lang_id}&query_type=&rank_exists=${rank_exists}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{ 
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'workerInstitutions', value: response.data}
        })
        return response.data
    }).catch(err=>{console.log(err.response)})
}


export const getInstitutionData = (id)=> async (dispatch) =>{

    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/institution/${id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
  
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'editInstitution', value: response.data}
        })
        return response.data
    }).catch(err=>{console.log(err.response)})
}
export const getInstitutionTypes = (lang_id)=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/institutionalType?lang_id=${lang_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'institutionTypes', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getStatesByCountry = (country_id, lang_id)=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/state?country_id=${country_id}&lang_id=${lang_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'stateByCountry', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getCityByCountry = (country_id, lang_id)=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/cities?country_id=${country_id}&lang_id=${lang_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'cityByCountry', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getCityByState = (stateId, lang_id)=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/cities?state_id=${stateId}&lang_id=${lang_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'cityByCountry', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getCommissionForManager = (year)=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/get-commission-manager?year=${year}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'comissionForManager', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}


export const insertNewData = (url, data) => async (dispatch) =>{
  
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(MAIN_API+url, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        // console.log(resp.data)
        return {msg:'success', id: resp?.data?.data?.id}
    }).catch(err=>{console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        console.log(err)
        return {msg: 'error'}
    })
}


export const getEducationDegree = (lang_id, country_id='')=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/educationDegree?lang_id=${lang_id}&country_id=${country_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'educationDegree', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getEducationLanguage= (lang_id)=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-education-language?lang_id=${lang_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'educationLanguage', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getCurrency= ()=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/currency`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'currency', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getExtraCurricularActivity= (lang_id)=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/extra-curricular-activities?lang_id=${lang_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'extraCurricularActivity', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getInternationalMedals= (lang_id)=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/international-olympiad?lang_id=${lang_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'internationalMedals', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getExams= ()=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/exams`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'allExams', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getFreeInst= ()=> async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/free-institutions`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'freeInstitutions', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}



export const getWorkerPrograms= (page=1,lang_id, institutionId = '', disciplineId="", filterByLevel = '', keyword='') => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    
    return await axios.get(`${MAIN_API}/programs?page=${page}&keyword=${keyword}
    &filterByCountry=&filterByState=&filterByCity=
    &filterByStatus=1&filterByInstitution=${institutionId}&lang_id=${lang_id}
    &filterByLevel=&filterByDegree=${filterByLevel}&filterByDiscipline=${disciplineId}
    &filterByDuration=&filterByFee=`, {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'programs', value: response.data}
        })
        return response.data
    }).catch(err=>{console.log(err.response); return []})
}
export const getProgramData= (id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/programs/${id}`, {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'editProgram', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getTracings= (lang_id,) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/tracking?lang_id=${lang_id}`, {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'trackingData', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getStudentTracking= (student_id, lang_id = 1, program_id) => async (dispatch) => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    
    return await axios.get(`${MAIN_API}/get-student-tracking?student_id=${student_id}&lang_id=${lang_id}&program_id=${program_id}`, {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer  ${localStorage.getItem('token')}`
      } 
    }).then(response=> {
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'studentTrackings', value: response.data}
        })
      
        if(response.data.programs.length != 0 && program_id == ''){
            dispatch(getMessagesBox(response.data.id,response.data.programs[0].program_id ))
        }
    }).catch(err=>{console.log(err.response)})
}

export const setStudentTracking = (data) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API}/student-tracking`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
    }).catch(err=>{console.log(err.response);dispatch({type: 'MAIN_SLICE/hideLoader'})})
}
export const uploadStudentDocuments = (data) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/student-documents`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response);dispatch({type: 'MAIN_SLICE/hideLoader'})})
}
export const deleteStudentDocument = (id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.delete(`${MAIN_API}/student-documents/${id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response);dispatch({type: 'MAIN_SLICE/hideLoader'})})
}
export const getStudentDocuments = (id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/student-documents?student_id=${id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'studentDocuments', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

// ////////////// EDU CONSULTANT 
export const getEduConsultantStudents = (keyword='', education_year="") => async (dispatch) =>{
    console.log(keyword, education_year)
    dispatch({type: 'MAIN_SLICE/setLoader'})
    
    return await axios.get(`${MAIN_API}/users?query_type=student&keyword=${keyword.toLowerCase()}&education_year=${education_year}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'eduConsultantStudents', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getEduConsultantParent = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/users?query_type=parent_student`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'eduConsultantParents', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}



// ////////////countries data
export const getCountriesData = (country_id, lang_id, type) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/country-faqs?country_id=${country_id}&lang_id=${lang_id}&type=${type}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'countryInfoData', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getInstitutionFaq = (institution_id, lang_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/public-institution-faqs?institution_id=${institution_id}&lang_id=${lang_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'institutionsInfoData', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}


export const insertCountryInfo = (url, data) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/${url}`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response);dispatch({type: 'MAIN_SLICE/hideLoader'})})
}

export const deleteData = (url) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.delete(`${MAIN_API}/${url}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response); dispatch({type: 'MAIN_SLICE/hideLoader'})})
}

export const getStudents = (type, page=1, keyword='') => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
    
    return await axios.get(`${MAIN_API}/users?query_type=student&student_type=${type}&page=${page}&keyword=${keyword}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'unirepStudents', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getEduCons = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/users?query_type=edu_con`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'eduConsultants', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getContentUsers = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/users?query_type=content`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'contentUsers', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getTranslatorUsers = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/users?query_type=translator`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'translatorUsers', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getEduConStudents = (worker_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/users?query_type=educon_student&worker_id=${worker_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'eduConsultantStudents', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}


export const assignStudent = (data) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/student-assign`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response);dispatch({type: 'MAIN_SLICE/hideLoader'})})
}
export const addUpdateData = (url, data) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/${url}`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        console.log(response.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response);dispatch({type: 'MAIN_SLICE/hideLoader'})})
}
export const removeAssign = ( id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/remove-assign`, {id: id}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response); dispatch({type: 'MAIN_SLICE/hideLoader'})})
}
export const setFeature = ( id, status) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/set-feature`, {id: id, status: status}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response);dispatch({type: 'MAIN_SLICE/hideLoader'})})
}
export const setCountryFeature = ( id, status) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/set-country-feature`, {id: id, status: status}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response);dispatch({type: 'MAIN_SLICE/hideLoader'})})
}


export const assignStudentToParent = (student_id, parent_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
   
    return await axios.post(`${MAIN_API}/assign-parent`, {student_id: student_id, parent_id: parent_id}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{dispatch({type: 'MAIN_SLICE/hideLoader'})

        return 'success'
    }).catch(err=>{console.log(err.response); dispatch({type: 'MAIN_SLICE/hideLoader'})})
}
export const addStudentFinance = (url, data) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/${url}`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{console.log(response.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response); dispatch({type: 'MAIN_SLICE/hideLoader'})})
}

export const getFinances = (stduent_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/student-finance?student_id=${stduent_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'studentFinances', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getTotalIncomes = (stduent_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/student-total-finance?student_id=${stduent_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        // if(response.data.length ==0){
        //     dispatch(changeStateValue({name:"studentTotalIncModal.position", value:true}))
        // }
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'studentTotalIncomes', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getStudentFreeUni = (stduent_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/student-free-uni?student_id=${stduent_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
       
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'studentFreeUni', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}




export const getLanguages = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/language`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'allLanguages', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}



export const getTeachers = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/teachers`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'teachers', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}


export const getScholarhipTracking = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/scholarship-tracking?type=trackings`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'scholarhipTrackings', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getStudentScholarhipTracking = (student_id, program_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
    
    return await axios.get(`${MAIN_API}/scholarship-tracking?student_id=${student_id}&program_id=${program_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'studentScholarhipTrackings', value: response.data}
        })
        return response?.data
    }).catch(err=>{console.log(err.response)})
}


export const addStudentTrancking = (url, data) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/${url}`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{console.log(response.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response); dispatch({type: 'MAIN_SLICE/hideLoader'})})
}

export const getPostStudyTracking = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/post-study-tracking?type=trackings`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'postStudyTracking', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getStudentPostStudy = (student_id, program_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/post-study-tracking?student_id=${student_id}&program_id=${program_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
   
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'studentPostStudy', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const deleteStudentTracking = (url) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.delete(`${MAIN_API}/${url}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response);dispatch({type: 'MAIN_SLICE/hideLoader'})})
}

export const getInactivePrograms = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/inactive-program-count`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'inactivePrograms', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const approvePrograms = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(`${MAIN_API}/public-act-pr`, {}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response); dispatch({type: 'MAIN_SLICE/hideLoader'})})
}
export const activeDeactiveAction = (url, data) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.put(`${MAIN_API}/${url}`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
       
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{console.log(err.response); dispatch({type: 'MAIN_SLICE/hideLoader'})})
}
export const insertData = (url, data) => async (dispatch) =>{
  
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.post(MAIN_API+url, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        console.log(resp.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return "success"
    }).catch(err=>{console.log(err.response)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return "error"
    })
}


export const getCountryEduDegree = (lang_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/country-education-degree?lang_id=${lang_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'countEduDegree', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getAllGradingScheme = (lang_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/gradingScheme?lang_id=${lang_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'allGradingScheme', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getAllGradeConverter = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/grade-converter`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'gradeConverter', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getCountryWiseEdu = (lang_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/country-wise-education?lang_id=${lang_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'countryWiseEdu', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getInstApproveData = (id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/institution-approve?id=${id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'instApproveData', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getStudentNotes = (id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/student-note?student_id=${id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'studentNotes', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const getDocumentCategory = () => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/get-document-category`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'documentCategories', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getDocuments = (country_id, category_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/get-document`, {
        params:{country_id, category_id},
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'documents', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getDocumentData = (id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/get-document/${id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'documentData', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getCompanyInOuts = (year) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/company-in-outs-stats?year=${year}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'companyInOuts', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getOneCompanyInOuts = (year, company_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/one-company-in-out?year=${year}&company_id=${company_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'oneCompanyInOuts', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const searchDocument = (keyword) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/search-document?keyword=${keyword}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'documentSearch', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getComissionForMessage = (program_id, student_id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/get-commission?program_id=${program_id}&student_id=${student_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'commissionForMessage', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getWorkerInOuts = (year) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/get-worker-in-out?year=${year}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'workerInOuts', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getWorkerCompanyInOuts = (year) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/company-finance?year=${year}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'workerFinanceInOuts', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}
export const getOneStudentApplys = (id) => async (dispatch) =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})

    return await axios.get(`${MAIN_API}/worker-student-applys?student_id=${id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(response=>{
        
        dispatch({
            type: "MAIN_SLICE/changeStateValue",
            payload: {name: 'oneStudentApplys', value: response.data}
        })
    }).catch(err=>{console.log(err.response)})
}

export const addAllPrograms = (data) =>async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API}/add-all-programs`, {params:data}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        console.log('all',resp.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{
        console.log('all error', err.response);
        return 'error'
    })
}
export const changeStatusAllPrograms = (data) =>async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API2}/change-status-all`, {params:data}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{
        console.log('all error', err.response);
        return 'error'
    })
}
export const updateAllPrograms = (data) =>async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API}/update-all-programs`,data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        console.log('all ddd',resp.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{
        console.log('all error', err.response);
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}
export const updateProgrUni = (data) =>async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API}/update-name-discipline`,data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{
        console.log('all error', err.response);
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}
export const changeAppliedProg = (data) =>async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.post(`${MAIN_API}/change-applied-program`,data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        console.log('all ddd',resp.data)
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{
        console.log('all error', err.response);
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}
export const deleteProgram = (id) =>async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.delete(`${MAIN_API}/programs/${id}`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}
export const deleteInst = (id) =>async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.delete(`${MAIN_API}/institution/${id}`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'success'
    }).catch(err=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return 'error'
    })
}
export const deleteUser = (id) =>async dispatch =>{
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.delete(`${MAIN_API}/users/${id}`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'});
        console.log(resp.data)
        return 'success'
    }).catch(err=>{
        dispatch({type: 'MAIN_SLICE/hideLoader'});
        console.log(err.response)
        return 'error'
    })
}



export const getBlogs = ()=> async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.get(`${MAIN_API}/blog-topic`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        dispatch(changeStateValue({name:'blogTopics', value:resp?.data}))
    }).catch(err=>{
        console.log(err.response)
        return "error"
    })
}
export const generateBlogs = ()=> async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.get(`${MAIN_API}/generate-blogs`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        // dispatch(changeStateValue({name:'blogTopics', value:resp?.data}))
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        console.log(resp?.data)
    }).catch(err=>{
        console.log(err.response)
        return "error"
    })
}
export const getBlogsAdmin = (lang_id)=> async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.get(`${MAIN_API}/blogs?lang_id=${lang_id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        dispatch(changeStateValue({name:'adminBlogs', value:resp?.data}))
        dispatch({type: 'MAIN_SLICE/hideLoader'})
     
    }).catch(err=>{
        console.log(err.response)
        return "error"
    })
}
export const getUserData = (id)=> async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.get(`${MAIN_API}/users/${id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
       
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        return "error"
    })
}
export const getStudentPrograms = (id)=> async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.get(`${MAIN_API}/student-programs?student_id=${id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        dispatch(changeStateValue({name:'studentPrograms', value:resp?.data}))
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        return "error"
    })
}
export const getStudentArchivePrograms = (id)=> async dispatch => {
    dispatch({type: 'MAIN_SLICE/setLoader'})
    return await axios.get(`${MAIN_API}/student-archive-programs?student_id=${id}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
        } 
    }).then(resp=>{
        dispatch(changeStateValue({name:'studentArchivePrograms', value:resp?.data}))
        dispatch({type: 'MAIN_SLICE/hideLoader'})
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        return "error"
    })
}