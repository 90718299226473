import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getEduconStudentsForApply } from '../actions/MainAction';
import { changeStateValue } from '../redux/MainReducer';
import { applyToProogram } from '../actions/MainAction';
import moment from 'moment';
export class EduconProgramApplyModal extends Component {
    state={
        btnDisable: false,
        search:''
    }
    componentDidMount(){
        this.props.getEduconStudentsForApply(this.props.educonApplyModal?.program_id)
    }
    closeModal(){
        this.props.changeStateValue({
            name:'educonApplyModal',
            value: {
                position:false,
                program_id:''
            }
        })
    }
    applyPriogram(student_id){
     
        this.setState({btnDisable:true})
        let formData = new FormData();
        formData.append('program_id', this.props.educonApplyModal?.program_id);
        formData.append('student_id', student_id);
        formData.append('id', student_id);
        formData.append('worker_id', this.props.user?.id);
        formData.append('date', new Date())
        formData.append('current_date', moment(new Date()).format("DD-MM-YYYY HH:mm:ss"))
        this.props.applyToProogram(formData, false)
        .then(resp=>{
            this.props.getEduconStudentsForApply(this.props.educonApplyModal?.program_id);
            this.setState({btnDisable:false})
        })
    }
  render() {
    const {educonStudentForApply} = this.props;
    const {btnDisable, search} = this.state;
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div style={{ maxHeight:'70vh', overflow:'auto' }} className='bg-white w-1/3 p-4 rounded-xl'>
            <div className='flex justify-between items-center'>
                <h3 className='text-lg font-semibold'>Apply to program for students</h3>
                <button onClick={this.closeModal.bind(this)} className='bg-white-600 text-sm text-black border font-semibold rounded-xl p-2' >Close</button>
            </div>
            
            <div>
                <form className='flex mt-5 items-center gap-4' >
                        <input value={search} onChange={(e)=>this.setState({search:e.target.value})} placeholder='Search Students'  type={'text'} />
                        <button className='border p-2 rounded-2xl'>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.1483 2.15152C9.41962 -0.618272 4.94621 -0.651706 2.17637 2.07686C-0.593479 4.80554 -0.62697 9.2789 2.10171 12.0487C4.41662 14.3985 7.88282 14.7777 10.6038 13.1731C10.6038 13.1731 10.8005 13.0578 10.9568 13.2166C11.8494 14.1227 14.5274 16.841 14.5274 16.841C15.238 17.5625 16.2379 17.7404 16.8778 17.1102L16.9878 17.0017C17.6277 16.3714 17.4648 15.3689 16.7541 14.6475C16.7541 14.6475 14.0817 11.9349 13.191 11.0307C13.0271 10.8643 13.1453 10.6694 13.1453 10.6694C14.7904 7.97284 14.4632 4.50136 12.1483 2.15152ZM10.7816 10.8118C8.73493 12.828 5.42961 12.8032 3.4134 10.7566C1.39724 8.70997 1.42193 5.40465 3.46856 3.3885C5.51514 1.37228 8.82051 1.39703 10.8367 3.44366C12.8528 5.49029 12.8281 8.79555 10.7816 10.8118Z" fill="gray"/>
                            </svg>
                        </button>

                    </form>
                </div>
            <ul className='mt-4 flex flex-col gap-3'>
                {
                    educonStudentForApply?.map((data, i)=>{
                        if(data?.full_name.toLowerCase().includes(search.toLowerCase())){
                            return(
                                <li key={i}  className='flex justify-between items-center bg-gray-100 p-1 rounded-xl'>
                                    <span className='font-semibold'>{data?.full_name}</span>
                                    {
                                        !data?.applied?
                                        <button disabled={btnDisable} onClick={this.applyPriogram.bind(this, data?.id)} className='bg-blue-600 text-sm text-white font-semibold rounded-xl p-2'>Apply</button>:
                                        <button className='bg-blue-600 text-sm text-white font-semibold rounded-xl p-2'>Already Apply</button>
                                    }
                                    
                                </li>
                            )
                        }
                        
                    })
                }
                
                
            </ul>
            <div className='mt-5'>
                <button onClick={this.closeModal.bind(this)} className='bg-red-600 text-sm text-white font-semibold rounded-xl p-2' >Close</button>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    educonStudentForApply: state.Data.educonStudentForApply,
    educonApplyModal: state.Data.educonApplyModal,
    user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getEduconStudentsForApply, applyToProogram}
export default connect(mapStateToProps, mapDispatchToProps)(EduconProgramApplyModal)
