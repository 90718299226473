import React, { Component } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'
import {getEduConStudents} from '../../actions/WorkerActions'
import {mainDashboardTranslate, getUnirepRoleTranslate} from '../../actions/TranslateAction';
import avatar1 from '../../images/default.jpg'
import SectionHeader from '../../components/page_component/SectionHeader';

function withHook(Component){
    return props =><Component {...props} params={useParams()} />
}
export class EduConStudents extends Component {
    componentDidMount(){
        window.scrollTo(0, 0)

        this.props.mainDashboardTranslate(this.props.languageId)
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.getEduConStudents(this.props.params.id)
    }
  render() {
    const {eduConsultantStudents} = this.props;

    return (
        <div>
             <SectionHeader title='Edu Con Students' />
            <div className='container grid mt-10 gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
        
                {
                    eduConsultantStudents.map((data, i)=>{
                        return(
                            <div key={i}
                                className={`nc-CardAuthorBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}
                                data-nc-id="CardAuthorBox"
                            >
                                
                                <div className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner rounded-full w-20 h-20 text-2xlring-1 ring-white dark:ring-neutral-900`}>
                                    <img
                                    className={`absolute inset-0 w-full h-full object-cover rounded-full`}
                                    src={data.image_full_url&& data.image_full_url != '' ? data.image_full_url:avatar1}
                                    />
                                </div>
                                <div className="mt-3">
                                    <h2 className={`text-base font-medium`}>
                                        <span className="line-clamp-1">{data.full_name}</span>
                                    </h2>
                                    <span className={`block mt-1.5  text-sm text-neutral-500 dark:text-neutral-400 wrap`} >
                                    {data.email.length > 20? `${data.email.substring(0, 20)}...`:data.email}
                                    </span>
                                    <div className={"p-4 space-y-4"}>
                                
                                </div>
                                        
                                </div>
                                
                            </div>
                        )
                    })
                }
            </div>
        </div>
      
    )
  }
}
const mapStateToProps = (state) =>({
  languageId: state.Data.languageId,
  eduConsultantStudents: state.Data.eduConsultantStudents,
});
const mapDispatchToProps = {getEduConStudents, mainDashboardTranslate, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(withHook(EduConStudents))