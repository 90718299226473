import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAllGradingScheme } from '../../../actions/WorkerActions';
import Maincard from './components/Maincard';
import MainModal from './modals/MainModal';
import { getAllCountries, getEducationDegree, getCountryEducationDegree } from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';

export class GradingScheme extends Component {
    componentDidMount(){
        this.props.getAllGradingScheme('1')
        this.props.getEducationDegree('1')
        this.props.getAllCountries('', '', '1')
    }
    getAfterChange=()=>{
        this.props.getAllGradingScheme('1')
    }
    editDataBtn=(data)=>{
      
        this.props.changeStateValue({
            name: 'newCountryData',
            value: {
              id: data?.id? data?.id: '',
              position: true,
              active: 1,
              lang_id: 1,
              countries_id: data.countries_id,
              education_degree_id: data.education_degree_id,
              education_sub_degree_id: data.education_sub_degree_id,
              max_value: data?.max_value?data?.max_value:'',
              min_value: data?.min_value?data?.min_value:'',
            }
        })
        if(data != '' ){
        
            this.props.getCountryEducationDegree('1',data.countries_id,  data.education_degree_id)
        }
        this.props.changeStateValue({
            name: 'otherExamPosition',
            value: false
          })
    }
    getPlaceholder(name, id, arr){
        if(id == '' || !id){
            return name
        }else{
            const check = arr.find(data=> data.value == id);
            if(check){
                return check.label
            }else{
               return name
            }
        }
    }
  render() {
    const {allGradingScheme, newCountryData,  allCountries, educationDegree, countryEducationDegree} = this.props
  
    let countryOpt = [];
    let degreeOpt = [];
    let countDegreeOpt = [];
    for(const data of allCountries){
        countryOpt.push({
            label: data.name,
            value: data.countries_id
        })
    }
    for(const data of educationDegree){
        degreeOpt.push({
            label: data.education_type,
            value: data.education_degree_id
        })
    }
    for(const data of countryEducationDegree){
        countDegreeOpt.push({
            label: data.name,
            value: data.country_education_degree_id

        })
    }
    
    return (
        <div>
        <button onClick={this.editDataBtn.bind(this, '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a Grade Scheme</button>
        <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 sm:gap-6 md:gap-8`}>
        {
            allGradingScheme.map((item ,i)=>{
                return(
                    <Maincard
                        key={i}
                        name={item.country_name +"/"+ item.education_degree+ `(${ item.min_value+ "-"+item.max_value })` }
                        id={item.id}
                        countries_id={item.countries_id}
                        education_degree_id={item.education_degree_id}
                        education_sub_degree_id={item.education_sub_degree_id}
                        min_value={item.min_value}
                        max_value={item.max_value}
                        editDataBtn={this.editDataBtn}
                    />
                )
            })
        }
        </div>
        {newCountryData?.position ? <MainModal  
                                        drop1option={countryOpt} 
                                        drop1Title={"Select a Country"} 
                                        drop1Name="countries_id"
                                        drop1placeholder={this.getPlaceholder("Select a Country", newCountryData.countries_id, countryOpt)}
                                        drop2option={degreeOpt} 
                                        drop2Title={"Select a Edu Degree"} 
                                        drop2Name="education_degree_id"
                                        drop2placeholder={this.getPlaceholder("Select a Edu Degree", newCountryData.education_degree_id, degreeOpt)}
                                        drop3option={countDegreeOpt} 
                                        drop3Title={"Select a Country Edu Degree"} 
                                        drop3Name="education_sub_degree_id"
                                        drop3placeholder={this.getPlaceholder("Select a Country Edu Degree", newCountryData.education_sub_degree_id, countDegreeOpt)}
                                        stateName="newCountryData"
                                        stateData={newCountryData}
                                        required={["min_value","max_value", "countries_id", "education_degree_id"]}
                                        url={'/gradingScheme'}
                                        getAfterChange={this.getAfterChange}
                                    />:null}
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    allGradingScheme: state.Data.allGradingScheme,
    newCountryData: state.Data.newCountryData,
    allCountries: state.Data.allCountries,
    educationDegree: state.Data.educationDegree,
    countryEducationDegree: state.Data.countryEducationDegree,
});
const mapDispatchToProps = {getAllGradingScheme,changeStateValue, getCountryEducationDegree,  getAllCountries, getEducationDegree}
export default connect(mapStateToProps, mapDispatchToProps)(GradingScheme)