import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { loggedInUserInfo } from '../actions/MainAction';

function RedirectPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(()=>{
        if(localStorage.getItem('token')){
            dispatch(loggedInUserInfo())
            .then(resp=>{
                if(resp === "error"){
                    navigate("/login")
                }
            })
        }else{
            navigate("/login")
        }
    }, [])
  return (
    <div>
      
    </div>
  )
}

export default RedirectPage
