import React, { Component } from 'react'
import Filter from './Filter';
import { connect } from 'react-redux';
import Select from 'react-select';
import { chatGptApi, addUniInfo } from '../../actions/MainAction';
import Swal from 'sweetalert2';
import ResultCard from './ResultCard';
import Loading from '../../components/page_component/Loading';
export class UniRobo extends Component {
    state={
        page: 'faqPrompt',
        faqPrompt: 'Create 10 FAQs for each university with answers for international students from these universities: ',
        aboutPrompt:'Create a description about university advantages for international students from these universities:',
        lastPropmpt: "as json format. Example : [{'university_name':'', 'results': [{'title':'', 'description':''}]}]",
        textValue: '',
        selectedPrompt: 'Create 10 FAQs for each university with answers for international students from these universities:',
        universities:[],
        modalVisible:false,
        modalData:[]
    }
    setTextValue(firstPrompt, universities, lastPropmpt){
        let prompt=`${firstPrompt} ${universities.join(', ')} ${lastPropmpt}`;
        this.setState({textValue: prompt})
    }
    changePage(page){
        this.setState({page: page});
        this.setState({selectedPrompt: this.state[page]})
        this.setTextValue(this.state[page], this.state.universities, this.state.lastPropmpt);
    }
    componentDidMount(){
        this.setTextValue(this.state.selectedPrompt, this.state.universities, this.state.lastPropmpt);
    }
    selectUni=(data)=>{
        let arr = [];
        for(const item of data){
            arr.push(item?.label)
        }
        this.setState({universities: arr})
        this.setTextValue(this.state.selectedPrompt,arr, this.state.lastPropmpt);
    }
    fetchDataBtn(prompt){
        const obj = {
            "model": "gpt-3.5-turbo",
            "messages": [{
                "role": "user",
                "content": prompt
            }]
        }
        this.props.chatGptApi(obj)
        .then(resp=>{
            if(resp.msg == 'success'){  
                this.setState({
                    modalData:JSON.parse(resp.data?.choices[0]?.message?.content),
                    modalVisible:true
                })
            }else{
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Something went wronge. please try again',
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
        })
    }

    saveData(data, type){
        if(type == "aboutPrompt"){
            
            let newData = [];
            for(let i = 0; i < data.length; i++){
                let html = '';
                for(let j = 0; j < data[i].results.length; j ++){
                    let cont = `
                        <div style="margin-top:12px;">
                            <h3 style="font-size:17px; font-weight: 600;">${data[i].results[j]?.title}</h3>
                            <p style="font-size:14px; color: gray; margin-top:5px">${data[i].results[j]?.description}</p>
                        </div>
                    `;
                    html+=cont;
                }
                newData.push({
                    university_name: data[i].university_name,
                    results: [{title: "about", description: `<div>${html}</div>`}]
                })
            }
            this.props.addUniInfo({data:newData, type})
            .then(resp=>{
                if(resp == 'success'){
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Added data',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.setState({modalData:[], modalVisible:false})
                }
            })
        }else{
            this.props.addUniInfo({data, type})
            .then(resp=>{
                if(resp == 'success'){
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Added data',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.setState({modalData:[], modalVisible:false})
                }
            })
        }
    }
    // chatGptApi
  render() {
    const {page, textValue, modalVisible, modalData} = this.state;
    const {workerInstitutions, loader, loaderLong,} = this.props;
    const uniOption = [];
    for(const property of workerInstitutions){
        uniOption.push({
            label: property.name,
            value: property?.institutions_id
        })
    }
    return (
      <div className='container'>
         <div className='flex gap-4 items-center'>
            <button onClick={()=>this.changePage("faqPrompt")} className={`${page == 'faqPrompt'? ' bg-blue-600 text-white ': 'border' } p-1 text-sm font-semibold cursor-pointer rounded-xl`}> FAQs</button>
            <button onClick={()=>this.changePage("aboutPrompt")} className={`${page == 'aboutPrompt'? ' bg-blue-600 text-white ': 'border' } p-1 text-sm font-semibold cursor-pointer rounded-xl`}> About</button>
           
        </div> 
        <div className='mt-5'>
            <textarea onChange={(e)=>this.setState({textValue:e.target.value})} className='rounded-lg text-lg' value={textValue}></textarea>
        </div>

        <div>
            <Filter getAfterFilter={()=>{}} />
        </div>
        <div className='mt-4'>
        <Select
            isMulti={true}
            onChange={this.selectUni}
            options={uniOption}
            placeholder={"Select uni"}
            className="rounded-2xl "
        />
        </div>
        <button onClick={this.fetchDataBtn.bind(this, textValue)} className={`bg-blue-600 text-white mt-5 p-1 text-sm font-semibold cursor-pointer rounded-xl`}> Fetch Results</button>

        {
            modalVisible?
            <div className='background_shadow flex items-center justify-center'>
                <div style={{ maxHeight: '80vh', overflow: 'auto'  }} className='w-1/3 bg-white p-4 rounded-xl'>
                    <h2>Results</h2>
                    <div style={{ overflow: 'auto' }} className='mt-3 mb-3'>
                        {
                            modalData?.map((item, i)=>{
                                return(
                                    <ResultCard key={i} data={item} /> 
                                )
                            })
                        }
                    </div>
                    <div className='flex items-center justify-between'>
                        <button onClick={()=>this.setState({modalData:[], modalVisible:false})} className='bg-red-700 text-white text-sm p-1 rounded-xl cursor-pointer'>Close</button>
                        <button onClick={this.saveData.bind(this, modalData, page)} className='bg-blue-700 text-white text-sm p-1 rounded-xl cursor-pointer'>Save</button>
                    </div>
                </div>
            </div>:null
        }
        {
             loader || loaderLong? 
             <Loading />:null
            
           }
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    workerInstitutions: state.Data.workerInstitutions,
    loader: state.Data.loader,
    loaderLong: state.Data.loaderLong,
});
const mapDispatchToProps = {chatGptApi, addUniInfo}
export default connect(mapStateToProps, mapDispatchToProps)(UniRobo)
