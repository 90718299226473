import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import Input from '../components/page_component/login/Input';
import GoogleLogin from 'react-google-login';
import {gapi} from 'gapi-script';
import {useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 

import { loginAction, loginSocialsAction } from '../actions/MainAction';
import { mainDashboardTranslate } from '../actions/TranslateAction';
import AppleIdLogin from '../components/page_component/AppleIdLogin';
import { GOOGLE_CLIENT_ID } from '../MAIN_API';
import EyeCloseSvg from '../components/svg/EyeCloseSvg';
import EyeSvg from '../components/svg/EyeSvg';
const clientId = GOOGLE_CLIENT_ID;
function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mainTransateData = useSelector(state=> state.Data.mainTransateData);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorState, setErrorState] = useState(false)
  const [errorStateSocial, setErrorStateSocial] = useState(false)

  const [inpType, setInpType] = useState("password")
  const languageId = useSelector(state=> state.Data.languageId);
  useEffect(()=>{
    window.scrollTo(0, 0)
    function start(){
      gapi.client.init({
        clientId: clientId,
        scope: ""
      })
    }
    gapi.load('client:auth2', start)
    dispatch(mainDashboardTranslate(languageId))
  }, [])


  function onSuccess (res){
   
    if(res.profileObj.email){

      dispatch(loginSocialsAction(res.profileObj.email)).then(resp=>{
        if(resp === 'success'){
          navigate('/')
        }else{
          setErrorStateSocial(true)
        }
      })
    }
    
  }
  const onFailure = (res)=>{
    // console.log('ff', res)
  }

  const loginBtn = (e)=>{
    e.preventDefault();
    if(password !== '' && username !== ''){
      dispatch(loginAction(username, password)).then(resp=>{
       
        if(resp === 'success'){
          navigate('/')
        }else{
          setErrorState(true)
        }
      })
    }
  }
  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
       
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {mainTransateData?.sign_in}
          </h2>
          <div className="max-w-md mx-auto space-y-6">
            {/* <div className="grid gap-3">
              <AppleIdLogin />
            </div> */}
            {/* <div className="grid gap-3" id='google_login'>
              <GoogleLogin 
                clientId={clientId}
                buttonText={mainTransateData?.already_account}
                onSuccess={(res)=>onSuccess(res)}
                onFailure={onFailure}
                cookiePolicy="single_host_origin"
                isSignedIn={false}
                theme='dark'
              />
              {
                errorStateSocial?
                <p style={{ marginTop: '-10px' }} className='invalid_login'>Invalid incredintials</p>:null
              }
            </div> */}
            {/* OR */}
            {/* <div className="relative text-center">
              <span aria-label='mainTransateData.or' className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              {mainTransateData?.or}
              </span>
              <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
            </div> */}
            {/* FORM */}
            <form className="grid grid-cols-1 gap-6" action="#" method="post">
              <label className="block">
                <span aria-label='mainTransateData.email' className="text-neutral-800 dark:text-neutral-200">
                {mainTransateData?.email}
                </span>
                
                <input type={'email'}
                  className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                  placeholder="example@example.com"
                  value={username}
                  onChange={(event)=>setUsername(event.target.value)}
                />
              </label>
              <label className="block">
                {/* <span aria-label='mainTransateData.password' className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {mainTransateData?.password}
                  <Link to="/forgot-pass" className="text-sm">
                  {mainTransateData?.forgot_password}
                  </Link>
                </span> */}
               
                <div className='flex justify-between gap-[10px]'>
                <input type={inpType}
                  className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                  placeholder="********"
                  value={password}
                  onChange={(event)=>setPassword(event.target.value)}
                />
                {
                  inpType==="text"?
                  <button onClick={(e)=>{e.preventDefault(); setInpType("password")}}>
                    <EyeCloseSvg />
                  </button>:
                   <button  onClick={(e)=>{e.preventDefault(); setInpType("text")}}>
                    <EyeSvg />
                  </button>
                }
                  
               
                </div>
              
              </label>
              {
                errorState?
                <p style={{ marginTop: '-20px' }} className='invalid_login'>Invalid incredintials</p>:null
              }
              
              <button onClick={(event)=>loginBtn(event)} style={{ color: 'white' }} aria-label="mainTransateData.sign_in" className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
              nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
              text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>
                {mainTransateData?.sign_in}
              </button>
              {/* <ButtonPrimary type="submit">Continue</ButtonPrimary> */}
            </form>
  
            {/* ==== */}
            {/* <span className="block text-center text-neutral-700 dark:text-neutral-300">
              <span aria-label='mainTransateData.new_user'>{mainTransateData?.new_user}</span> {` `}
              <Link style={{ color: 'blue' }} to="/signup" aria-label='mainTransateData.create_acc'>{mainTransateData?.create_acc}</Link>
            </span>  */}
          </div>
        </div>
      </div>
  )
}

export default LoginPage

