import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select from 'react-select';
import { getAllDisciplines } from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
export class MultiSelect extends Component {
  state={
    selectedOption: null,
    disciplines: []
}
selectedDisciplinesValue = selectedOption =>{
    let id = [];
    let disciplinesOption = []
    this.setState({ selectedOption })
    
    for(const discipline of selectedOption){
        id.push(discipline.value.discipline_id)
    }
    for(const nonSelect of this.props.allDisciplines){
       
        if(!selectedOption.find(data => +data.value.discipline_id === +nonSelect.discipline_id)){
            const params = {
                label: nonSelect.name,
                value: nonSelect
            }
            disciplinesOption.push(params)
        }
    }

    this.setState({
        disciplines: disciplinesOption
    })
    this.props.changeStateValue({
        name: 'editProgram.disciplines',
        value: id
    })
    // console.log(id)
}
  componentDidMount(){
    this.props.getAllDisciplines(this.props.languageId)
  }
  render() {
    const {allDisciplines, editProgram, translate} = this.props;
    const { selectedOption, disciplines } = this.state;

    let selectedDisciplines = [];
        let disciplinesOption = [];
       
        for(let i = 0; i < allDisciplines.length; i++){
            if(editProgram && editProgram.program && editProgram.program.discipline){
               
                if(!editProgram.program.discipline.find(data => +data.discipline_id === +allDisciplines[i].discipline_id) && disciplines.length === 0){
                    
                    const params = {
                        label: allDisciplines[i].name,
                        value: allDisciplines[i]
                    }
                    disciplinesOption.push(params);
    
                }else{
                   
                    disciplinesOption = [...disciplines]
                }
               
                if(editProgram.program.discipline.find(data => +data.discipline_id === +allDisciplines[i].discipline_id) && selectedOption === null) {
                  
                    const params = {
                        label: allDisciplines[i].name,
                        value: allDisciplines[i]
                    }
                    selectedDisciplines.push(params)
                }
            }
            
        }
        if(selectedOption){
            selectedDisciplines = [... selectedOption]
        }
    return (
        <div className='mt-5 mb-5 ' style={{zIndex: '100', position: 'relative'}}>
        <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
        {translate}
        </label>
         <Select
            isMulti={true}
            value={selectedDisciplines}
            onChange={this.selectedDisciplinesValue}
            options={disciplinesOption}
            placeholder={translate}
            className="rounded-2xl "
            
        />
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  allDisciplines: state.Data.allDisciplines,
  languageId: state.Data.languageId,
  editProgram: state.Data.editProgram,
});
const mapDispatchToprops = {getAllDisciplines, changeStateValue}
export default connect(mapStateToProps, mapDispatchToprops)(MultiSelect)