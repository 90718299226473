import React, { Component } from 'react'
import { connect } from 'react-redux'

export class LoadMoreBtn extends Component {
  state={
    page: 1
  }
  loadMoreBtn = (page, position,count, e) =>{
    if(position % count== 0){
      let data =  page+1
      this.setState({
        page: data
      })
  
      this.props.loadMoreFunction(data)
    }else{
      let data =  1
      this.setState({
        page: data
      })
  
      this.props.loadMoreFunction(data)
    }
   
  }
  render() {
    const {mainTransateData, position, count = 20} = this.props;
    const {page} = this.state;
    // console.log('sssss',position)
    return (
        <div className="flex items-center justify-between mt-10">
            <span className=" sm:block flex-shrink-0 ml-auto mr-auto">
                <button onClick={this.loadMoreBtn.bind(this, page, position, count)} className='ttnc-ButtonSecondary font-medium border 
                                    bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 
                                    dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800
                                    !leading-none nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                    text-neutral-700 dark:text-neutral-200 px-4 py-3 sm:px-6 text-sm sm:text-base font-medium
                                    '>
                                      {
                                        position % count == 0?
                                        <span aria-label='mainTransateData.load_more'>{mainTransateData?.load_more}</span>:
                                        <span aria-label='mainTransateData.show_less'>{mainTransateData?.show_less}</span>
                                      }
                    <i className="ml-3 las la-arrow-right text-xl"></i>
                </button>
            </span>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  mainTransateData: state.Data.mainTransateData
})
export default connect(mapStateToProps)(LoadMoreBtn)