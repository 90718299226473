import React, { Component } from 'react'

export class Manat2Svg extends Component {
  render() {
    return (
        <svg width={12} height={13} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" >
        <path d="M192 32c-17.7 0-32 14.3-32 32v34.7C69.2 113.9 0 192.9 0 288v160c0 17.7 14.3 32 32 32s32-14.3 32-32V288c0-59.6 40.8-109.8 96-124v284c0 17.7 14.3 32 32 32s32-14.3 32-32V164c55.2 14.2 96 64.3 96 124v160c0 17.7 14.3 32 32 32s32-14.3 32-32V288c0-95.1-69.2-174.1-160-189.3V64c0-17.7-14.3-32-32-32z" />
      </svg>
    )
  }
}

export default Manat2Svg
