import React, { Component } from 'react'

export class EmailSvg extends Component {
  render() {
    return (
        <div className='email_icon_background'>
          <svg width={19} version="1.1" id="Capa_1" x="0px" y="0px"
             viewBox="0 0 410 410" enableBackground="new 0 0 410 410">
                
        <g id="XMLID_1256_">
            <polygon id="XMLID_1257_" fill="#D80027" points="345.01,207.769 345.01,207.77 250,297.5 385,410 385,170 	"/>
            <polygon id="XMLID_1258_" fill="#FF5023" points="25,170 25,410 160,297.5 	"/>
            <polygon id="XMLID_1259_" fill="#D80027" points="160,297.5 25,410 205,410 205,340 	"/>
            <polygon id="XMLID_1260_" fill="#A2001D" points="205,340 205,410 385,410 250,297.5 	"/>
            <polygon id="XMLID_1261_" fill="#FF5023" points="64.993,132.229 25,170 64.99,170 	"/>
            <polygon id="XMLID_1262_" fill="#FF5023" points="385,170 345.005,132.227 345.008,170 	"/>
            <polygon id="XMLID_1263_" fill="#FF5023" points="205,0 141.213,60.243 268.626,60.092 	"/>
            <polygon id="XMLID_1264_" fill="#A2001D" points="345.01,207.769 385,170 345.008,170 	"/>
            <polygon id="XMLID_1265_" fill="#A2001D" points="205,340 64.986,207.765 64.99,170 25,170 160,297.5 	"/>
            <path id="XMLID_1266_" fill="#DEDDE0" d="M64.986,207.765L205,340l45-42.5l95.01-89.73v-0.001L345.008,170l-0.003-37.773
                L345,60l-76.374,0.092l-127.413,0.151L65,60.334l-0.007,71.895L64.99,170L64.986,207.765z M115,180h180v30H115V180z M115,150v-30
                h180v30H115z"/>
            <rect id="XMLID_1270_" x="115" y="120" fill="#FFFFFF" width="180" height="30"/>
            <rect id="XMLID_1271_" x="115" y="180" fill="#FFFFFF" width="180" height="30"/>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        </svg>
        </div>
    )
  }
}

export default EmailSvg