import React, { Component } from 'react'
import { connect } from 'react-redux';
import Searchable from 'react-searchable-dropdown'; 
import { changeStateValue } from '../../../redux/MainReducer';
import InputPercentage from './InputPercentage';
export class HighSchoolSchExams extends Component {
    selectValue = (name, value)=>{
        
        // this.props.changeStateValue({
        //     name: 'editProgram.program.sch_requirements.'+name,
        //     value: value
        // })
        this.props.changeInpValue('editProgram.program.sch_requirements.'+name, value)
    }
    changeInpValue(e){
        // this.props.changeStateValue({
        //     name: 'editProgram.program.sch_requirements.'+e.target.name,
        //     value: e.target.value
        // })
        this.props.changeInpValue('editProgram.program.sch_requirements.'+e.target.name, e.target.value)
    }
  render() {
    const {editProgram} = this.props
    
    const aLevelOption =[
        {
          label: 'A*A*A*',
          value: 'A*A*A*'
        },
        {
          label: 'A*AA',
          value: 'A*AA'
        },
        {
          label: 'AAA',
          value: 'AAA'
        },
        {
          label: 'AAB',
          value: 'AAB'
        },
        {
          label: 'AAC',
          value: 'AAC'
        },
        {
          label: 'ABB',
          value: 'ABB'
        },
        {
          label: 'ABC',
          value: 'ABC'
        },
        {
          label: 'BBB',
          value: 'BBB'
        },
        {
          label: 'BBC',
          value: 'BBC'
        },
        {
          label: 'CCC',
          value: 'CCC'
        },
        {
          label: 'DDD',
          value: 'DDD'
        },
        {
          label: 'EEE',
          value: 'EEE'
        },
      ];
  
      const apOptions=[
        {
          label: '5555',
          value: '5555'
        },
        {
          label: '5554',
          value: '5554'
        },
        {
          label: '5544',
          value: '5544'
        },
        {
          label: '5444',
          value: '5444'
        },
        {
          label: '4444',
          value: '4444'
        },
        {
          label: '4443',
          value: '4443'
        },
        {
          label: '4433',
          value: '4433'
        },
        {
          label: '4333',
          value: '4333'
        },
        {
          label: '3333',
          value: '3333'
        },
        {
          label: '555',
          value: '555'
        },
        {
          label: '554',
          value: '554'
        },
        {
          label: '544',
          value: '544'
        },
        {
          label: '444',
          value: '444'
        },
        {
          label: '443',
          value: '443'
        },
        {
          label: '433',
          value: '433'
        },
        {
          label: '333',
          value: '333'
        },
      ]
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-12 mt-5">
            <div >
                <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                   A Level
                </label>
                <div  className={`block w-full border-neutral-200  
                    focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                    dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                    dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border gap-0 flex items-center justify-between`}
                > 
                    <Searchable 
                        options={aLevelOption}
                        onSelect={this.selectValue.bind(this.label, 'a_level')}
                        placeholder={editProgram?.program?.sch_requirements?.a_level == '' ? 'A Level': editProgram?.program?.sch_requirements?.a_level}
                    /> 
                    <input placeholder='%' value={editProgram?.program?.sch_requirements?.a_level_percentage==0?'':editProgram?.program?.sch_requirements?.a_level_percentage} onChange={this.changeInpValue.bind(this)} name="a_level_percentage" className='percentage_inp rounded-2xl  h-11' type={'number'} />
                </div>
            </div>
            <div >
                <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                   AP
                </label>
                <div  className={`block w-full border-neutral-200  
                    focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                    dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                    dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border gap-0 flex items-center justify-between`}
                > 
                    <Searchable 
                        options={apOptions}
                        onSelect={this.selectValue.bind(this.label, 'advanced_placement')}
                        placeholder={editProgram?.program?.sch_requirements?.advanced_placement == '' ? 'Advanced Placement': editProgram?.program?.sch_requirements?.advanced_placement}
                    /> 
                    <input placeholder='%' value={editProgram?.program?.sch_requirements?.ap_percentage == 0?'':editProgram?.program?.sch_requirements?.ap_percentage} onChange={this.changeInpValue.bind(this)} name="ap_percentage" className='percentage_inp rounded-2xl  h-11' type={'number'} />
                </div>
            </div>
            <InputPercentage 
                label={'IB Diploma'}
                value={editProgram?.program?.sch_requirements?.ib_diploma}
                placeholder={'IB Diploma'}
                name="editProgram.program.sch_requirements.ib_diploma"
                perValue={editProgram?.program?.sch_requirements?.ib_percentage}
                perName={'editProgram.program.sch_requirements.ib_percentage'}
            />
            <InputPercentage 
                label={'OSSD'}
                value={editProgram?.program?.sch_requirements?.ossd}
                placeholder={'OSSD'}
                name="editProgram.program.sch_requirements.ossd"
                perValue={editProgram?.program?.sch_requirements?.ossd_percentage}
                perName={'editProgram.program.sch_requirements.ossd_percentage'}
            />
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    // editProgram: state.Data.editProgram
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(HighSchoolSchExams)
