import { connect } from 'react-redux';
import React, { Component } from 'react';
import SectionHeader from '../SectionHeader';
import InstitutionsCard from './InstitutionsCard';
import TabFilters from './TabFilters';
import { getInstitutions } from '../../../actions/MainAction';
import ViewAllBtn from '../ViewAllBtn'
import LoadMoreBtn from '../LoadMoreBtn';
import SkeletonCard from '../SkeletonCard';
export class Institutions extends Component {
    state={
        page: 1,
        country: '',
        state:'',
        city:'',
        search:''
    }
    componentDidMount(){
        // if(!localStorage.getItem('instCountries')){
        //     this.props.getInstitutions(1, '', this.props.languageId, this.state.countries, '', '', this.props.type)
        // }
        this.props.getInstitutions(1, '', this.props.languageId, '', '', '', this.props.type)
        //
    }

    loadMoreFunction = (page) =>{   
        this.setState({page:page})
        this.props.getInstitutions(page, this.state.search, this.props.languageId, this.state.country, this.state.state, this.state.city, this.props.type)
    }

    getAfterFilter = (name, data)=>{
        this.setState({
            [name]: data
        })
    }

    getAfterDelete=()=>{
        this.props.getInstitutions(this.state.page, this.state.search, this.props.languageId, this.state.country, this.state.state, this.state.city, this.props.type)
    }
    changeInpValue(e){
        this.setState({
            search: e.target.value
        })
        if(e.target.value == ''){
            this.props.getInstitutions(this.state.page, '', this.props.languageId, this.state.country, this.state.state, this.state.city, this.props.type)
        }
    }
    searchInst(search, e){
        e.preventDefault()
        if(search != ''){
            this.props.getInstitutions(this.state.page, search, this.props.languageId, this.state.country, this.state.state, this.state.city, this.props.type)
        }
        
    }
  render() {
    const {institutions, page, btn, mainTransateData, type, filter = true, user} = this.props;
    const {search} = this.state;
    return (
        <div style={{ minHeight: '70vh' }} className={`nc-SectionGridFilterCard `} data-nc-id="SectionGridFilterCard" >
            
            <SectionHeader arialLabelTitle="mainTransateData.inst_title" arialLabelDesc="mainTransateData.inst_desc" title={mainTransateData?.inst_title} description={mainTransateData?.inst_desc} />
            <div className="flex items-center gap-5 mb-8 lg:mb-11">
                {
                    filter?
                    <TabFilters getAfterFilter={this.getAfterFilter} type={type}/>:null
                }
            {
                user != '' && user.role == 'edu_consultant' ?
                <form className='flex mt-0 items-center gap-4' style={{ marginTop: '-17px' }}>
                    <input value={search} onChange={this.changeInpValue.bind(this)} type={'text'} />
                    <button onClick={this.searchInst.bind(this, search)} className='border p-2 rounded-2xl'>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1483 2.15152C9.41962 -0.618272 4.94621 -0.651706 2.17637 2.07686C-0.593479 4.80554 -0.62697 9.2789 2.10171 12.0487C4.41662 14.3985 7.88282 14.7777 10.6038 13.1731C10.6038 13.1731 10.8005 13.0578 10.9568 13.2166C11.8494 14.1227 14.5274 16.841 14.5274 16.841C15.238 17.5625 16.2379 17.7404 16.8778 17.1102L16.9878 17.0017C17.6277 16.3714 17.4648 15.3689 16.7541 14.6475C16.7541 14.6475 14.0817 11.9349 13.191 11.0307C13.0271 10.8643 13.1453 10.6694 13.1453 10.6694C14.7904 7.97284 14.4632 4.50136 12.1483 2.15152ZM10.7816 10.8118C8.73493 12.828 5.42961 12.8032 3.4134 10.7566C1.39724 8.70997 1.42193 5.40465 3.46856 3.3885C5.51514 1.37228 8.82051 1.39703 10.8367 3.44366C12.8528 5.49029 12.8281 8.79555 10.7816 10.8118Z" fill="gray"/>
                        </svg>
                    </button>

                </form>:null
            }
            </div>
            <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
                {page == 8?
                    institutions && institutions.map((data, i)=> {
                        if(i < page){
                            return(
                                <InstitutionsCard getAfterDelete={this.getAfterDelete} key={i} institutions={data} />
                            )
                        }
                    }):institutions && institutions.map((data, i)=> {
                        return(
                            <InstitutionsCard getAfterDelete={this.getAfterDelete} key={i} institutions={data} />
                        )
                    })

                }
                {
                  institutions?.length == 0?
                  <>
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  </>:null
                }
            </div>
            {
                btn === 'view_all'?
                <ViewAllBtn href="/institutions" />:
                institutions.length >= 20?
                <LoadMoreBtn position={institutions.length} loadMoreFunction={this.loadMoreFunction} />:null
            }
              
        </div>
    )
  }
}
const mapStateToProps = (state)=> ({
    institutions: state.Data.institutions,
    languageId: state.Data.languageId,
    mainTransateData: state.Data.mainTransateData,
    user: state.Data.user,
});
const mapDispatchToProps = {getInstitutions}
export default connect(mapStateToProps, mapDispatchToProps)(Institutions)