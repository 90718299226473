import React, { Component } from 'react'
import SvgDown from '../../../components/page_component/simulator_components/SvgDown'
import SvgUp from '../../../components/page_component/simulator_components/SvgUp'

export class ExamDrop extends Component {
    state={
        dropVisible: false,
        contRef: React.createRef()
    }
    selectData(data, id, e){
        this.props.getOtherData(id, data)
        this.setState({
            dropVisible: false
        })
    }  
    componentDidUpdate( prevProps, prevState){
        if(prevState.dropVisible != this.state.dropVisible){
            window.addEventListener('click', (e)=>{
                if(e.target.contains(this.state.contRef.current)){
                    this.setState({
                        dropVisible: false
                    })
                }
            })
        }
    } 
  render() {
    const {dropVisible, contRef} = this.state;
    const {placeholder, ariaLabel, option, data} = this.props;
    return (
      <div ref={contRef} className='exam_drop_container relative'>
        <div  onClick={()=>this.setState({dropVisible: true})} className='border flex justify-between rounded-2xl items-center h-10 pl-5 pr-5 cursor-pointer'>
            <span aria-label={ariaLabel}>{placeholder}</span>
           
            {
                dropVisible?
                <SvgUp />:
                <SvgDown />
            }
           
        </div>
        {
            dropVisible?
            <div className='absolute bg-white w-full border mt-3 rounded-2xl p-2 exam_drop_list_container'>
               
                   
                <ul>
                    {
                        option.map((item, i)=>{
                            return(
                                <li key={i} onClick={this.selectData.bind(this, data, item.value)} className='cursor-pointer'>{item.label}</li>
                            )
                        })
                    }
                </ul>
            </div>:null
        }
        
      </div>
    )
  }
}

export default ExamDrop