import React, { Component, Fragment } from 'react'
import { Popover, Transition } from "@headlessui/react";
import { connect } from 'react-redux';
import { getScholarhipTracking, getCurrency,deleteStudentTracking,  addStudentTrancking, getStudentScholarhipTracking, getPostStudyTracking, getStudentPostStudy} from '../../../../actions/WorkerActions'; 
import { useParams } from 'react-router-dom';
import DropDown from './DropDown';
import { changeStateValue } from '../../../../redux/MainReducer';
import Swal from 'sweetalert2';
function withParams(Component){
    return props => <Component {...props} params={useParams()} />
}
export class ScholarshipTracking extends Component {
    state={
        position: false,
        activeBtn: 'track_scholarship',
        track_id: '',
        track_label: '',
        trackType: 0,
        amount: '',
        currency: '',
        description: '',
        descVisible: false

    }

    errorModal(text){
        const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          Toast.fire({
            icon: 'error',
            title: text
          })
    }
    openModal(){
        this.props.getStudentScholarhipTracking(this.props.student_id, this.props.program_id);
        this.setState({
          position:true,
          track_id: '',
          track_label: ''
        })
        
    }
    changeBtn(type, e){
        this.setState({
            activeBtn: type,
            track_id: '',
            track_label: '',
            trackType: 0,
            amount: '',
            currency: '',
            description: '',
            descVisible: false
        })
    }

    componentDidMount(){
        this.props.getCurrency();
        this.props.getScholarhipTracking();
        this.props.getStudentScholarhipTracking(this.props.student_id, this.props.program_id);
        this.props.getPostStudyTracking();
        this.props.getStudentPostStudy(this.props.student_id, this.props.program_id);
    }
    selectData = (type, data) => {
     
        if(type == "track_scholarship"){
            this.setState({
                track_id: data.id,
                track_label: this.props.scholarhipTrackings.find(track=> track.id == data.id)?.title,
                trackType: this.props.scholarhipTrackings.find(track=> track.id == data.id)?.type,
                currency: '',
                amount: '',
                description: ''
            })
            if(this.props.studentScholarhipTrackings != ''){
                this.props.changeStateValue({
                    name: "studentScholarhipTrackings.tracking.type",
                    value: this.props.scholarhipTrackings.find(track=> track.id == data.id)?.type
                })
            }
            
        }else if(type == "currency"){
            this.setState({
                currency: data
            })
        }else if(type == "track_status"){
            this.setState({
                track_id: data.id,
                track_label: this.props.scholarhipTrackings.find(track=> track.id == data.id)?.title,
                trackType: this.props.scholarhipTrackings.find(track=> track.id == data.id)?.type,
                currency: '',
                amount: '',
                description: '',
                descVisible: true
            })
        }
        
    }
    saveDataBtn(type){
      
        if(type == "track_scholarship"){
            if(this.state.track_id != ''){
                let formData = new FormData();
                formData.append("student_id", this.props.student_id);
                formData.append("program_id", this.props.program_id)
                formData.append("track_id", this.state.track_id);
                formData.append("currency", this.state.currency);
                formData.append("amount", this.state.amount)
                let btnPermission = true;
                let url = "scholarship-tracking";
                if(this.state.trackType == 1){
                    if(this.state.currency != '' && this.state.amount != ''){
                        formData.append("currency", this.state.currency);
                        formData.append("amount", this.state.amount)
                    }else{
                        this.errorModal("Please, add amount and currency")
                        btnPermission=false
                    }
                }
                if(btnPermission){
                    this.props.addStudentTrancking(url, formData)
                    .then(resp=>{
                        this.props.getStudentScholarhipTracking(this.props.student_id, this.props.program_id)
                        this.setState({
                            track_id: '',
                            track_label: '',
                            trackType: 0,
                            amount: '',
                            currency: '',
                            description: '',
                            descVisible: false
                        })
                    })
                }
            }else{
                this.errorModal("Please, select a scholarship type")
            }
            
        }else{
            if(this.state.track_id != '' && this.state.description != ''){
                let formData = new FormData();
                formData.append("student_id", this.props.student_id);
                formData.append("program_id", this.props.program_id)
                formData.append("track_id", this.state.track_id);
                formData.append("description", this.state.description);
                let url = "post-study-tracking";
                this.props.addStudentTrancking(url, formData)
                    .then(resp=>{
                        this.props.getStudentPostStudy(this.props.student_id, this.props.program_id)
                        this.setState({
                            track_id: '',
                            track_label: '',
                            trackType: 0,
                            amount: '',
                            currency: '',
                            description: '',
                            descVisible: false
                        })
                    })
            }else{
                this.errorModal("Please, select a tracking type and add description")
            }
        }
    }

    getTrackingLabel(label, sch, post, type){
        if(type == "track_scholarship"){
            if(label != ''){
                return label
            }else if(sch != ''){
                return sch?.tracking?.title
            }else{
                return ''
            }
        }else {
            if(label != ''){
                return label
            }else if(post != ''){
                return post?.tracking?.title
            }else{
                return ''
            }
        }
    }
    editBtn(data, e){
        this.setState({
            track_id: data.track_id,
            description: data.description,
            descVisible:true,
        })
    }
    clearBtn(post, sch, type){
        if(type == 'track_scholarship'){
            if(sch != ''){
                let url = `scholarship-tracking/${sch.id}`;
                this.props.deleteStudentTracking(url)
                .then(resp=>{
                    this.props.getStudentScholarhipTracking(this.props.student_id, this.props.program_id)
                })
            }
        }else{
            if(post != ''){
                let url = `post-study-tracking/${post.id}`;
                this.props.deleteStudentTracking(url)
                .then(resp=>{
                    this.props.getStudentPostStudy(this.props.student_id, this.props.program_id)
                })
            }
        }
    }
  render() {
   const { position, activeBtn, track_label,trackType, amount, descVisible, description} = this.state;
    const {user, unirepRoleTranslate, scholarhipTrackings, currency, studentScholarhipTrackings, postStudyTracking, mainTransateData, studentPostStudy} = this.props;

    let schOption = [];
    let currencyOption = [];
    let postStudyOpt = [];
    let opt = []
    for(const data of scholarhipTrackings){
        schOption.push({
            label: data.title,
            value: data
        })
    }
    for(const data of postStudyTracking){
        postStudyOpt.push({
            label: data.title,
            value: data
        })
    }
    for(const data of currency){
        currencyOption.push({
            label: data.currency,
            value: data.currency
        })
    }
    if(activeBtn == "track_scholarship"){
        opt = schOption
    }else{
        opt = postStudyOpt
    }
  
    return (
        <Popover >
                
            <Popover.Button 
            
            onClick={this.openModal.bind(this)}
            className={`flex items-center justify-between px-4  w-full h-11 py-2 mr-0 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
            position ? "!border-primary-500 " : ""
            }`}
            >
            <span>{mainTransateData?.track_scholarship}</span>
            <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
        
            {
            position?
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
                
            >
            <div id='popover_container' className={this.props.params.id?`right-0`: ''} > 
            <Popover.Panel  className=" z-10 w-screen max-w-sm px-4 mt-3  sm:px-0 lg:max-w-md pb-5">
                
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700 ">
                    <div className='flex p-1 space-x-1 bg-primary-900/10 rounded-xl mt-1 ml-1 mr-1'>
                        <button onClick={this.changeBtn.bind(this, 'track_scholarship')}
                        className={`w-full py-2.5 text-sm leading-5 font-medium text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                        ${
                            activeBtn == 'track_scholarship'?
                            'bg-white dark:bg-neutral-800':
                            ' hover:bg-white/[0.15] dark:hover:bg-neutral-800'
                        }
                        
                        `}
                        aria-label="mainTransateData.track_scholarship"
                        >{mainTransateData?.track_scholarship} </button>
                        <button onClick={this.changeBtn.bind(this, 'track_status')}
                        className={`w-full py-2.5 text-sm leading-5 font-medium text-primary-700 dark:text-primary-400 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60
                        ${
                            activeBtn == 'track_status'?
                            'bg-white dark:bg-neutral-800':
                            ' hover:bg-white/[0.15] dark:hover:bg-neutral-800'
                        }
                        `}
                        aria-label="mainTransateData.track_status"
                        >{mainTransateData?.post_studies}</button>
                       
                    </div>
                    {
                         user !== '' &&user.role == 'uni_rep' || user !== '' &&user.role == 'edu_consultant'?
                         <div className='p-3'>
                            <DropDown type={activeBtn} selectData={this.selectData} option={opt} placeholder={activeBtn == "track_scholarship"?"Select a Scholarship type":"Select a Post Study Type"} />
                        </div>:null
                    }
                   
                    
                         <div style={{ minHeight: "200px"}}>
                         {
                          this.getTrackingLabel(track_label, studentScholarhipTrackings, studentPostStudy,  activeBtn) != ''?
                             <p className='p-5 flex items-center gap-2'>
                                 <svg  height={20}  width={20} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512" xmlSpace="preserve" >
                                     <path  d="M469.402 35.492C334.09 110.664 197.114 324.5 197.114 324.5L73.509 184.176 0 254.336l178.732 222.172 65.15-2.504C327.414 223.414 512 55.539 512 55.539l-42.598-20.047z"
                                     style={{ fill: "green",  }} />
                                 </svg>
                                {this.getTrackingLabel(track_label, studentScholarhipTrackings, studentPostStudy,  activeBtn)}
                                {
                                    activeBtn == "track_status"?
                                    <svg onClick={this.editBtn.bind(this, studentPostStudy)} width={20} className="cursor-pointer"  height={20}  viewBox="0 0 24 24"  fill="none"  xmlns="http://www.w3.org/2000/svg" >
                                        <path
                                        d="M12 20h9M3 20h1.675c.489 0 .733 0 .963-.055a2 2 0 0 0 .579-.24c.201-.123.374-.296.72-.642L19.5 6.5a2.121 2.121 0 1 0-3-3L3.937 16.063c-.346.346-.519.519-.642.72-.11.18-.19.375-.24.579-.055.23-.055.475-.055.964V20Z"
                                        stroke="#000"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        />
                                    </svg>:null
                                }
                                 {
                                     activeBtn == "track_scholarship" && studentScholarhipTrackings?.tracking?.type == 1?
                                     <span>{studentScholarhipTrackings.amount}/{studentScholarhipTrackings.currency}</span>:null
                                 }
                                 
                             </p>:null
                         }
                         {
                             trackType == 1?
                             <div className='flex gap-4 p-3'>
                                 <input value={amount} onChange={(e)=>this.setState({amount: e.target.value})} style={{ width: '50%' }} type={"number"} placeholder="0,000.0" />
                                 <DropDown otherClass="currencyDropDown" type="currency" selectData={this.selectData} option={currencyOption} placeholder="Select a Currency"  />
                             </div>:null
                         }
                         {
                            activeBtn == "track_status" && descVisible?
                            <div className='p-5 '>
                                <textarea value={description} onChange={(e)=>this.setState({description: e.target.value})}></textarea>
                            </div>
                            :null
                         }
                         {
                            !descVisible && activeBtn == "track_status" && studentPostStudy?.description && studentPostStudy?.description != ''?
                            <p className='pl-5 pr-5'>{studentPostStudy?.description}</p>:null
                         }
                         
                     </div>
                        
                    
                    {
                         user !== '' &&user.role == 'uni_rep' || user !== '' &&user.role == 'edu_consultant'?
                         <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                        
                            <button onClick={this.clearBtn.bind(this, studentPostStudy, studentScholarhipTrackings, activeBtn)} aria-label='unirepRoleTranslate.clear'  className='ttnc-ButtonThird text-neutral-700 border 
                                                border-neutral-200 dark:text-neutral-200 
                                                dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                                                items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200
                                                px-4 py-2 sm:px-5'   >
                                {unirepRoleTranslate?.clear}
                            </button>
                            <button onClick={this.saveDataBtn.bind(this, activeBtn)} aria-label='unirepRoleTranslate.apply' style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                                                nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                                                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200  px-4 py-2 sm:px-5  '>
                                {unirepRoleTranslate?.apply}
                            </button>
                        </div>:null
                    }
                    
                </div>
            </Popover.Panel></div> 
            </Transition>:null
            }
            

    </Popover>
    )
  }
}
const mapStateToProps = (state) =>({
    languageId: state.Data.languageId,
    user: state.Data.user,
    unirepRoleTranslate: state.Data.unirepRoleTranslate,
    scholarhipTrackings: state.Data.scholarhipTrackings,
    currency: state.Data.currency,
    studentScholarhipTrackings: state.Data.studentScholarhipTrackings,
    studentPostStudy: state.Data.studentPostStudy,
    postStudyTracking: state.Data.postStudyTracking,
    mainTransateData: state.Data.mainTransateData,
});
const mapDispatchToProps = {getScholarhipTracking,deleteStudentTracking,  getCurrency, addStudentTrancking, getStudentScholarhipTracking, changeStateValue, getPostStudyTracking, getStudentPostStudy}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(ScholarshipTracking))