import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getSingleAcceptedStudent } from '../../../actions/MainAction';
import { useParams } from 'react-router-dom';
import MainCard from './MainCard';
import DotSvg from './DotSvg';
function withHooks(Component){
  return props => <Component {...props} params={useParams()} />
}
export class SingleAcceptedStudent extends Component {
  state={
    page: 'uni'
  }
  componentDidMount(){
    this.props.getSingleAcceptedStudent(this.props.params.id, 1)
  }
  render() {
    const {singleAcptStudent} = this.props;
    const {page} = this.state;
    console.log(singleAcptStudent)
  
    return (
      <div className='container'>
          {
            singleAcptStudent != ''?
            <>
            <div className='bg-white rounded-xl shadow-md pb-5'>
            <div className='relative'>
              <img className='rounded-tl-xl rounded-tr-xl w-full h-96' src={singleAcptStudent?.applications[0]?.program?.image_full_url} />
              <div className='acp_student_img'>
                <img src={singleAcptStudent?.image_full_url != ''?singleAcptStudent?.image_full_url:'https://cdn-icons-png.flaticon.com/512/149/149071.png'} className='w-40 h-40 rounded-full' />
              </div>
              <h3 className='text-2xl font-semibold text-center'>{singleAcptStudent?.first_name} {singleAcptStudent?.last_name?.substring(0, 1)}...</h3>
              <div className='flex justify-center items-center gap-4 w-full mt-3 mb-2'>
                <button onClick={()=>this.setState({page:'uni'})} className={`border rounded-xl text-sm p-2 cursor-pointer font-medium ${page == 'uni'? ' bg-blue-700 text-white':''}`}>
                  Universities
                </button>
                {
                  singleAcptStudent?.scholarships.length != 0?
                  <button onClick={()=>this.setState({page:'sch'})} className={`border rounded-xl text-sm p-2 cursor-pointer font-medium ${page == 'sch'? ' bg-blue-700 text-white':''}`}>
                  Scholarships
                </button>:null
                }
                
                <button onClick={()=>this.setState({page:'edu'})} className={`border rounded-xl text-sm p-2 cursor-pointer font-medium ${page == 'edu'? ' bg-blue-700 text-white':''}`}>
                  Education
                </button>
              </div>
            </div>
          </div>
          <div className='mt-5 '>

             {page == 'uni'?
              singleAcptStudent?.applications?.map((data, i)=>{
                return (
                  <MainCard
                    key={i}
                    title={data?.program?.institution?.translate?.name}
                    subtitle={data?.program?.degree?.translate?.education_type+': '+ data?.program?.translate?.name}
                    img={data?.program?.image_full_url}
                  />
                )
              }):null
             }
             {page == 'sch'?
              singleAcptStudent?.scholarships?.map((data, i)=>{
                return (
                  <MainCard
                    key={i}
                    title={data?.program?.institution?.translate?.name}
                    subtitle={data?.program?.translate?.name}
                    img={data?.program?.image_full_url}
                    subTitle2={`${data?.tracking?.title}`+ `${data?.amount?` ( ${data?.amount +' / '+ data?.currency} )`:''}`}
                  />
                )
              }):null
             }
             {
              page == 'edu'?
              <div >
                <div className='shadow-md p-2 rounded-xl mb-3'>
                  <h3 className='text-lg font-semibold mt-4'>Education Background</h3>
                  <p className='text-sm ml-3 flex items-center'><DotSvg /> Education degree: {singleAcptStudent?.degree} { singleAcptStudent?.sub_degree != ''? `( ${singleAcptStudent?.sub_degree} )`:null}</p>
                  <p className='text-sm ml-3 flex items-center'><DotSvg /> GPA: {singleAcptStudent?.education_background?.study_gpa}</p>
                </div>
                {
                    singleAcptStudent?.student_exams?.length != 0?
                <div className='shadow-md p-2 rounded-xl mb-3'>
                 
                    <h3 className='text-lg font-semibold mt-4'>Exams</h3>
                  {
                    singleAcptStudent?.student_exams?.map((data, i)=>{
                      return(
                        <p key={i} className='text-sm ml-3 flex items-center'><DotSvg /> {data?.exam?.exam_type} ({data?.over_all})</p>
                      )
                    })
                  }
                </div>:null
                  }
              {
                    singleAcptStudent?.activities?.length != 0?    
                <div className='shadow-md p-2 rounded-xl mb-3'>
                  
                    <h3 className='text-lg font-semibold'>Extra Curricual Activities</h3>
                  
                  {
                    singleAcptStudent?.activities?.map((data, i)=>{
                      return(
                        <p key={i} className='text-sm ml-3 flex items-center'><DotSvg /> {data?.title}</p>
                      )
                    })
                  }
                </div>:null
                  }
                {
                    singleAcptStudent?.olympiads?.length != 0?
                <div className='shadow-md p-2 rounded-xl mb-3'>
                  
                    <h3 className='text-lg font-semibold mt-4'>International olympiads</h3>
                  
                  {
                    singleAcptStudent?.olympiads?.map((data, i)=>{
                      return(
                        <p key={i} className='text-sm ml-3 flex items-center'><DotSvg /> {data?.title}</p>
                      )
                    })
                  }
                </div>:null
                  }
                
              
              </div>:null
             }
          </div></>:null
          }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  singleAcptStudent: state.Data.singleAcptStudent
});
const mapDispatchToProps = {getSingleAcceptedStudent};
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleAcceptedStudent))
