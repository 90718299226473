import React, { Component } from 'react'
import Searchable from 'react-searchable-dropdown'; 
import { getEduConsultantParent, assignStudentToParent,getEduConsultantStudents  } from '../../../../actions/WorkerActions';
import { registerByWorkerAction } from '../../../../actions/MainAction';
import { connect } from 'react-redux';
import CloseIcon from '../../../../components/page_component/CloseIcon';
import avatar1 from '../../../../images/default.jpg'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { changeStateValue } from '../../../../redux/MainReducer';
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2';
function withHooks(Component){
    return props => <Component {...props} navigate={useNavigate()} />
}
export class AddParentModal extends Component {
    state={
        page: 'exists',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        role_id: '3',
        parent_id: '',
        btnDisabled: false,
        image: '',
        base64: '',
        education_year:'',
        phone_number:''
    }
    componentDidMount(){
        this.props.getEduConsultantParent()
    }
    closeModal(e){
        if(e.target.className === 'background_shadow'){
            this.props.closeModal()
        }
    }
    changePage(type, e){
        this.setState({
            page: type,
            parent_id: ''
        })
    }
    selectDropValue = (data) =>{
        this.setState({
            parent_id: data.id
        })
    }   
    changeInpValue(e){
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    addParent(student_id, parent_id, state,  e){
        if(parent_id != ''){
            this.setState({
                btnDisabled: true
            })
            this.props.assignStudentToParent(student_id, parent_id)
            .then(resp=>{
                this.props.getEduConsultantStudents()
                this.props.closeModal();
                this.setState({
                    btnDisabled: false
                })
            })
        }else{
            if(state.first_name != '' && state.last_name != '' && state.email != '' && state.password != '' && state?.phone_number?.length == 12){
                if(this.props.eduYear){
                    if(state.education_year == ''){
                        return
                    }
                }
                this.setState({
                    btnDisabled: true
                })
                if(student_id !== ''){
                    state.role_id = '5';
                    state.student_id = student_id;
                }
                
                let formData = new FormData();
                for(const pr in state){
                 
                    formData.append(pr, state[pr])
                }
                this.props.registerByWorkerAction(formData)
                .then(resp=>{
                    this.setState({
                        btnDisabled: false
                    })
                    this.props.closeModal()
                    this.props.getEduConsultantStudents();
                    if(resp == "success"){
                          const x = new Date();
                            const y = x.getTime()
                        this.props.changeStateValue({
                            name: 'confirmEmail',
                            value: {
                              email: btoa(state.email),
                              sentTime: String(x),
                              deadline: String(new Date(y+2 * 60 * 60 * 1000)),
                              path: window.location.pathname
                            }
                
                            
                          })
                          localStorage.setItem('eKey', btoa(state.email))
                          localStorage.setItem('start', String(x))
                          localStorage.setItem('deadline', String(new Date(y+2 * 60 * 60 * 1000)))
                          localStorage.setItem('path', window.location.pathname)
                          this.props.navigate('/confirm-email');
                    }else{
                        Swal.fire(
                            'Error',
                            'Email already exists',
                            'error'
                          )
                    }

                })
            }
        }
        
    }
    uploadImg(e){
        let reader = new FileReader();

        reader.onload = () =>{
            this.setState({
                base64: reader.result
            })
        }
        this.setState({
            image: e.target.files[0]
        })
        reader.readAsDataURL(e.target.files[0])
    }
    setEduYear=(id)=>{
        this.setState({education_year: id})
    }
  render() {
    const {eduConsultantParents,student_id = '', parents = [], mainTransateData, type, closeModal, eduYear=false} = this.props;
    const {page, first_name, last_name, email, password, parent_id, btnDisabled, base64, phone_number} = this.state;
    const option = [];
    for(const data of eduConsultantParents){
        if(!parents.find(item=>item.parent_id == data.id)){
            option.push({
                label: data.full_name,
                value: data
            })
        }
        
    } 
    let yearOption = [
        {
            label: "2020-2021",
            value: "2020-2021"
        },
        {
            label: "2021-2022",
            value: "2021-2022"
        },
        {
            label: "2022-2023",
            value: "2022-2023"
        },
        {
            label: "2023-2024",
            value: "2023-2024"
        },
        {
            label: "2024-2025",
            value: "2024-2025"
        },
    ]
    
    return (
        <div className='background_shadow' >
            <div className="login_popup_container" style={{ overflow:   page == 'exists' && type == 'parent'? 'visible': 'auto' }}>
                <CloseIcon closeModalBtn={closeModal} />
                {
                    type == 'parent'?
                    <ul className='flex gap-2'>
                        <li><button onClick={this.changePage.bind(this, 'exists')} 
                        className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base 
                        sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${page ==  'exists'? 'bg-secondary-900 text-secondary-50':'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'}`}
                        aria-label="mainTransateData.add_a_parent">{mainTransateData?.add_a_parent}</button></li>
                        <li><button onClick={this.changePage.bind(this, 'new')} 
                        aria-label="mainTransateData.create_new_parent"
                        className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 
                        capitalize rounded-full  
                         focus:outline-none ${page ==  'new'? 'bg-secondary-900 text-secondary-50':'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'} `}>
                        {mainTransateData?.create_new_parent}</button></li>
                       
                </ul>:null
                }
                
                {
                    page == 'exists' && type == 'parent'?
                    <div className='mt-5'>
                         <div style={{ zIndex: 200 }}>
                            <label aria-label='mainTransateData.select_parent' className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                                {mainTransateData?.select_parent}
                            </label>
                            <div  className={`block w-full border-neutral-200  
                                focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                                dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                                dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                            > 
                                <Searchable 
                                    options={option}
                                    onSelect={this.selectDropValue.bind( this.label)}
                                    placeholder={mainTransateData?.select_parent}
                                />
                            </div>
                        </div>
                    </div>:
                    page=='new' && type == 'parent' || page=='exists'&& type == 'student'?
                   <div className='mt-5'>
                    <form className="grid grid-cols-1 gap-6" action="#" method="post">
                    <label htmlFor='st_upload_img' className='student_pr_image'>
                        <img  src={base64 != ''?base64:avatar1} />
                    </label>
                    <input onChange={this.uploadImg.bind(this)} type={'file'} id="st_upload_img" accept='image/jpg, image/png, image/jpeg, image/webp' style={{ display: 'none' }} />
                        <div className='grid grid-cols-2 gap-3'>
                        <label className="block">
                            <span aria-label='mainTransateData.first_name' className="text-neutral-800 dark:text-neutral-200">
                                {mainTransateData?.first_name}
                            </span>
                            
                            <input type={'text'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder={mainTransateData?.first_name}
                            value={first_name}
                            name="first_name"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        <label className="block">
                            <span aria-label='mainTransateData.last_name' className="text-neutral-800 dark:text-neutral-200">
                            {mainTransateData?.last_name}
                            </span>
                            
                            <input type={'text'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder={mainTransateData?.last_name}
                            value={last_name}
                            name="last_name"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        </div>
                        <label className="block">
                            <span aria-label='mainTransateData.email' className="text-neutral-800 dark:text-neutral-200">
                                {mainTransateData?.email}
                            </span>
                            
                            <input type={'email'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="example@example.com"
                            value={email}
                            name="email"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        <div>
                            <PhoneInput
                            country={"az"}
                            enableSearch={true}
                            value={phone_number}
                            placeholder='(00) 000 00 00'
                            onChange={(phone) => this.setState({phone_number:phone})}
                            inputClass="h-11 rounded-2xl w-full"
                            inputStyle={{ width:'100%', borderRadius:'12px', borderColor:'rgba(0,0,0,0.1)', outline:'none' }}
                            
                            />
                            {
                           phone_number?.length != 12?
                            <span className='text-red-700' style={{ fontSize:12 }}>Phone format should: +994 (00) 000 00 00</span>:null
                        }
                        </div>
                        
                        {
                            eduYear?
                            <div className="block">
                                <span className="text-neutral-800 dark:text-neutral-200">
                                    Education Year
                                </span>
                                
                                <div className='border rounded-2xl'>
                                    <Searchable 
                                        options={yearOption}
                                        onSelect={this.setEduYear.bind(this)}
                                        placeholder={"Select a education year"}
                                    />
                                </div>
                            </div>:null
                        }
                        <label className="block">
                            <span aria-label='mainTransateData.password' className="text-neutral-800 dark:text-neutral-200">
                                {mainTransateData?.password}
                            </span>
                            
                            <input type={'password'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="*********"
                            value={password}
                            name="password"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                       
                    </form>
                   </div>:null
                }
               

                <div className='mt-5 flex justify-end'>
                <button disabled={btnDisabled} onClick={this.addParent.bind(this,student_id, parent_id, this.state, type == 'parent'? '5': '3')} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                        nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                        text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200' aria-label='mainTransateData.save'>
                        {mainTransateData?.save}
                    </button>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    eduConsultantParents: state.Data.eduConsultantParents,
    mainTransateData: state.Data.mainTransateData,
});
const mapDispatchToProps = {getEduConsultantParent, assignStudentToParent,changeStateValue, registerByWorkerAction, getEduConsultantStudents}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AddParentModal))