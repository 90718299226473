import React, { Component } from 'react'
import MessagesBody from './msg_components/MessagesBody'
import MessagesFooter from './msg_components/MessagesFooter'
import MessagesHeader from './msg_components/MessagesHeader'
// import { NODE_API } from '../../../../MAIN_API'
// import io from "socket.io-client";
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {changeStateValue} from '../../../../redux/MainReducer'
import { getMessagesUsers } from '../../../../actions/MainAction'
// const socket = io.connect(NODE_API);
function withParams(Component){
  return props => <Component {...props} params={useParams()} />
}
export class MessagesBox extends Component {
    componentDidMount(){
      // socket.on('get-all-msg', (data)=>{
 
      //   this.props.changeStateValue({
      //     name: 'messages',
      //     value: data
      //   })
      // })
      this.props.getMessagesUsers(this.props.params.id)
    }
  render() {
    const {program_id, user, worker_id, params} = this.props;
    if(user !== '' && program_id != ''){
     
      if(user.role == 'student'){
        // socket.emit('connected', {user_id: user.id, student_id: user.id, program_id:program_id})
      }else{
        // socket.emit('connected', {user_id: user.id, student_id: params.id, program_id:program_id})
      }
    }
    
    
    return (
        <div className="msg-container">
            {/* <MessagesHeader /> */}
            <MessagesBody />
            <MessagesFooter program_id={program_id} worker_id={worker_id} />
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
  user: state.Data.user
})
const mapDispatchToProps = {changeStateValue, getMessagesUsers}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(MessagesBox))