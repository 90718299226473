import React, { Component } from 'react'

export class SkeletonText extends Component {
  render() {
    return (
        <div  className="skeleton-text ">
            <h4></h4>
            <div className="skeleton-description"></div>
            <div className="skeleton-description"></div>
            <div className="skeleton-description"></div>
            <div className="skeleton-description"></div>
        </div>
    )
  }
}

export default SkeletonText