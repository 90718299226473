import React, { Component } from 'react'
import { connect } from 'react-redux';

export class Maincard extends Component {
  render() {
    const {editDataBtn, user, id, name, image="", translate_id, countries_id='', education_degree_id='', education_sub_degree_id, min_value='', max_value='', grading_schemes_id, grade='', other_exam } = this.props;

    return (
        <div className={`nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}  data-nc-id="CardCategoryBox1"  >
          {
            user != ''&& user.role == 'uni_rep'?
            <button onClick={()=>editDataBtn({id, name, image, translate_id, countries_id, education_degree_id,education_sub_degree_id, min_value, max_value, grading_schemes_id, grade, other_exam})} className={`nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs text-gray-800 bg-gray-100  absolute right-2 top-2`}>
            Edit
           </button>:null
          }
         {
          image != ''?
        <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
          
            <div className={`nc-NcImage absolute inset-0`}  data-nc-id="NcImage"  >
                
                  <img src={image} className={'object-cover w-full h-full'}  />
                
               
            </div>
        </div>:null
        }
        <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="text-base font-medium">
            <span className="line-clamp-3">{name}</span>
            </h2>
        </div>
    </div>
    )
  }
}
const mapStateToProps = (state)=>({
    user: state.Data.user
})
export default connect(mapStateToProps)(Maincard)