import React, { Component } from 'react'
import { connect } from 'react-redux'
import TranslatorCard from '../components/page_component/TranslatorCard';
import SectionHeader from '../components/page_component/SectionHeader';
import { getTranslatorUnirep, getTranslatorDashboard } from '../actions/TranslateAction';
export class TranslatorMainPage extends Component {
   componentDidMount(){
    this.props.getTranslatorUnirep()
    this.props.getTranslatorDashboard()
   }
  render() {
    const {mainTransateData, unirepRoleTranslate, user, translatorDashboardData, translatorUnirepData} = this.props;
    
    let mainData = [];
    for(const pr in mainTransateData){
        mainData.push({
            property: `mainTransateData.${pr}`,
            value: translatorDashboardData[pr]?translatorDashboardData[pr]: '',
            title: mainTransateData[pr]
        })
    }
    for(const pr in unirepRoleTranslate){
        mainData.push({
            property: `unirepRoleTranslate.${pr}`,
            value: translatorUnirepData[pr]?translatorUnirepData[pr]: '',
            title: unirepRoleTranslate[pr]
        })
    }


    return (
      <div className='container' > 
      {
        user != ''?
        <SectionHeader  title={`Translate From English to ${user?.lang?.main_lang?.name}`} />:null
      }
        

        <ul>
            {
                mainData.map((data, i)=>{
                    return(
                        <TranslatorCard key={i} data={data} />
                    )
                })
            }
          
        </ul>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    mainTransateData: state.Data.mainTransateData,
    unirepRoleTranslate: state.Data.unirepRoleTranslate,
    user: state.Data.user,
    translatorDashboardData: state.Data.translatorDashboardData,
    translatorUnirepData: state.Data.translatorUnirepData,
});
const mapDispatchToProps = {getTranslatorUnirep, getTranslatorDashboard}
export default connect(mapStateToProps, mapDispatchToProps)(TranslatorMainPage)