import axios from 'axios'
import React, { Component } from 'react'
import { MAIN_API } from '../MAIN_API'

export class TestPage extends Component {

    sendEmail(){
        axios.post(`${MAIN_API}/send-mail`, {}, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer  ${localStorage.getItem('token')}`
              } 
        }).then(resp=>{
            // console.log(resp.data)
        }).catch(err=>{console.log(err.response)})
    }
  render() {
    return (
      <div className='container'>
        <button onClick={this.sendEmail.bind(this)}>Send</button>
      </div>
    )
  }
}

export default TestPage