import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from './components/Button'
import CommentList from './components/CommentList'
import Input from './components/Input'
import Star from './components/Star'
import {getComments} from '../../../../actions/MainAction';
import { useParams } from 'react-router-dom'
import { changeStateValue } from '../../../../redux/MainReducer'
import LoginComponent from '../../../page_component/simulator_components/login_register/LoginComponent'
function withHooks (Component){
    return props => <Component {...props} params={useParams()} />
  }
export class CommentsContainer extends Component {
    componentDidMount(){
        this.props.getComments(this.props.params.id)
    }

    openModal(){
      this.props.changeStateValue({
        name: 'simulatorLogin',
        value: true
      })
    }
  render() {
    const {universityComments, user, mainTransateData, simulatorLogin} = this.props;
  
    return (
        <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold" aria-label='mainTransateData.reviews'>{mainTransateData?.reviews} </h2>
        {
            user == ''?
            <h3  className="text-1xl font-semibold" aria-label="mainTransateData.sign_in_to_comment">{`${mainTransateData?.sign_in_to_comment.split(' ')[0]} `} 
              <button onClick={this.openModal.bind(this)} style={{ color: 'blue' }}> sign in</button>
             {` ${mainTransateData?.sign_in_to_comment.split(' ').slice(3, mainTransateData?.sign_in_to_comment.split(' ').length-1).join(' ')}`}</h3>:
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          }
        

        {/* Content */}
        {
          user !== '' && user.role == 'student'?
          <div className="space-y-5">
            <Star  />

            <div className="relative">
              <Input />
              <Button />
            </div>
          </div>:null
        }
        
       
        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
            {
                universityComments.map((comment, i)=>{
                    return (
                        <CommentList comment={comment} key={i} />
                    )
                })
            }
            
          {/* <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div> */}
        </div>
        {
          simulatorLogin?
          <LoginComponent />:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    universityComments: state.Data.universityComments,
    user: state.Data.user,
    mainTransateData: state.Data.mainTransateData,
    simulatorLogin: state.Data.simulatorLogin,
});
const mapDispatchToProps = {getComments, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(CommentsContainer))