import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAplliedPrograms } from '../../actions/WorkerActions';
import MainCard from './components/MainCard';
import SectionHeader from '../../components/page_component/SectionHeader'
import DropDown from './components/DropDown';
import {getParentStudents} from '../../actions/ParentActions'
import LoadMoreBtn from '../../components/page_component/LoadMoreBtn';
export class AppliedPrograms extends Component {
    componentDidMount(){
      window.scrollTo(0, 0)

        this.props.getAplliedPrograms('0', this.props.languageId, '', 1)
        this.props.getParentStudents()
    }

    getStudentApplications = (id) =>{
   
      this.props.getAplliedPrograms('0', this.props.languageId, id, 1)
    }
    loadMoreFunction=(page)=>{
      this.props.getAplliedPrograms('0', this.props.languageId, '', page)

    }
  render() {
    const {appliedPrograms, unirepRoleTranslate, parentStudents, user, mainTransateData} = this.props;

    const option = [];
    // console.log(appliedPrograms)
    for(const data of parentStudents){
      option.push({
        label: data.full_name,
        value: data.id,
      })
    }
    return (
      <div className="nc-PageHome relative overflow-hidden">
      {/* <BgGlassmorphism /> */}
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <div className="nc-SectionGridFeaturePlaces relative">
        <div className="flex flex-col mb-8 relative">
          <SectionHeader arialLabelTitle="unirepRoleTranslate.applications" arialLabelDesc="unirepRoleTranslate.application_desc" title={unirepRoleTranslate?.applications} description={unirepRoleTranslate?.application_desc} />
        </div>
        {
          option.length != 0 && user.role == 'parent'?
          <div style={{ width: '250px' }} className="mb-5">
            <DropDown 
              ariaLabel="mainTransateData.select_a_student"
              placeholder = {mainTransateData?.select_a_student}
              label = {mainTransateData?.select_a_student}
              option = {option}
              type="student"
              getOtherData={this.getStudentApplications}
              // name, type, zIndex = 1
            />
          </div>:null
        }
        
        <div  className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
            {
                appliedPrograms &&
                appliedPrograms.map((program, i)=>{
                    return(
                        <MainCard key={i} student_id={program.student_id} program={program.program} status={program.tracking} />
                    )
                })
            }
        </div>
      </div>
      </div>
      {
            appliedPrograms.length >= 20?
            <LoadMoreBtn  position={appliedPrograms.length} loadMoreFunction={this.loadMoreFunction} />:null
      }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    appliedPrograms: state.Data.appliedPrograms,
    languageId: state.Data.languageId,
    unirepRoleTranslate: state.Data.unirepRoleTranslate,
    parentStudents: state.Data.parentStudents,
    user: state.Data.user,
    mainTransateData: state.Data.mainTransateData,
});
const mapDispatchToProps = {getAplliedPrograms, getParentStudents}
export default connect(mapStateToProps, mapDispatchToProps)(AppliedPrograms)