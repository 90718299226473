import React, { Component } from 'react'
import Select from 'react-select';
export class PrgoramCard extends Component {
    state={
        visible:false,
        name: '',
        discipline: [],
        selectedDiscipline: []
    }

    componentDidMount(){
        this.setState({name: this.props.programData?.name});
        let disciplines = []
        let selectDis = []
        for(const data of this.props.programData?.program?.discipline){
            disciplines.push(data?.discipline_id);
            const findDis = this.props.disciplinesOption.find(item=> item.value == data?.discipline_id);
            if(findDis){
                selectDis.push(findDis)
            }
        }
        this.setState({discipline: disciplines})
        this.setState({selectedDiscipline: selectDis});
        console.log("mopint")
        this.props.selectAllPrograms(-1,{name: this.props.programData?.name, discipline: disciplines, program_id: this.props.programData.program_id, id: this.props.programData?.id})
    }
    selectedDisciplinesValue=(data)=>{
     
        this.setState({
            selectedDiscipline:data
        })
        let disIds = [];
        for(const item of data){
            disIds.push(item?.value)
        }
        this.props.selectAllPrograms(this.props.index, {name: this.state.name, discipline: disIds,  program_id: this.props.programData.program_id, id: this.props.programData?.id})
        this.setState({discipline: disIds})
    }

    getInpValue=(e)=>{
        console.log(e.target.value)
        this.setState({name:e.target.value})
        this.props.selectAllPrograms(this.props.index, {name: e.target.value, discipline: this.state.discipline,  program_id: this.props.programData.program_id, id: this.props.programData?.id})
    }
  render() {
    const {programData, disciplinesOption, index, deleteProgram} = this.props;
    const {name, visible, discipline, selectedDiscipline} = this.state;
    // console.log(disciplinesOption)
    return (
      <div className='border rounded-xl p-2 mt-4'>
        <div className='flex items-center justify-between'>
            <h3 style={{  width:'90%', cursor:'pointer' }} className='font-semibold' onClick={()=>this.setState({visible: !visible})}>{programData?.name}</h3>
            <button onClick={()=>deleteProgram(programData?.program_id)} className='p-2 text-sm text-white bg-red-700 rounded'>Delete</button>
        </div>
        {
            visible?
            <div>
                <div className='mt-5'>
                    <label className='text-sm font-semibold' htmlFor='inpName'>Name</label>
                    <input type='text' value={name} onChange={this.getInpValue.bind(this)} />
                </div>
                <div className='mt-5 mb-5 ' style={{zIndex: 400-index, position: 'relative'}} >
                    <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                    Disciplines
                    </label>
                    <Select
                        isMulti={true}
                        value={selectedDiscipline}
                        onChange={this.selectedDisciplinesValue}
                        options={disciplinesOption}
                        placeholder={"Select Disciplines"}
                        className="rounded-2xl "
                        
                    />
                </div>
            </div>:null
        }
        
      </div>
    )
  }
}

export default PrgoramCard
