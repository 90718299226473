import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../../../actions/withHooks';
import { getFinances, getTotalIncomes } from '../../../../../actions/WorkerActions';
import CardLine from '../../../../../components/cards/CardLine';
import CardTitleSvg from '../../../../../components/svg/CardTitleSvg';
import DeleteSvg from '../../../../../components/svg/DeleteSvg';
import EditSvg from '../../../../../components/svg/EditSvg';
import DownloadSvg from '../../../../../components/svg/DownloadSvg';
import Swal from 'sweetalert2';
import { deleteData } from '../../../../../actions/WorkerActions';
import { addStudentFinance } from '../../../../../actions/WorkerActions';
import { mixinAlert } from '../../../../../actions/Alerts';
import { changeStateValue } from '../../../../../redux/MainReducer';
import StudentTotalModal from './components/StudentTotalModal';
import CardEditSvg from '../../../../../components/svg/CardEditSvg';
import FinancePrevPage from './FinancePrevPage';
import ManatSvg from '../../../stats/ManatSvg';
import moment from 'moment';
import Manat2Svg from '../../../stats/Manat2Svg';
export class Finance extends Component {
  state={
    amountOuts:'',
    titleOuts:'',
    fileOuts:'',
    idOuts:'',
    amountIns:'',
    titleIns:'',
    fileIns:'',
    idIns:'',
    btnDisable:false,
    section:'contracts'
  }
  componentDidMount(){
    this.props.getFinances(this.props.params.id);
    this.props.getTotalIncomes(this.props.params.id);
  }
  getAfterCall=()=>{
    this.props.getTotalIncomes(this.props.params.id);
  }
  getFinanceTypes(array, type){
    return array.filter(x=>x.type == type)
  }
  getFeeAmount(x){
    let y = +x;
   
    var parts = y.toFixed(2).toString().split(".");
    parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");
    return parts.join(".");
  }
  getTotalAmount(data, type){
    let total = 0;
    data.map(item=>{
        if(item.type == type){
            total+= +item.amount
        }
    })

    return total;
  }
  getRemaining(ins, outs){
    return +ins - +outs
  }
  deleteDocument(id, e){
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
            let url = `student-finance/${id}`;
            this.props.deleteData(url)
            .then(resp=>{
              this.setState({
                amountOuts:'',
                titleOuts:'',
                fileOuts:'',
                idOuts:'',
                amountIns:'',
                titleIns:'',
                fileIns:'',
                idIns:'',
                btnDisable:false,
              })
                this.props.getFinances(this.props.params.id)
                Swal.fire(
                    'Deleted!',
                    'Your data has been deleted.',
                    'success'
                )
            })
          
        }
      })
  }

  addBtn=(title, amount, file, type, id="")=>{
    if(title !="" && amount !="" && file !=""){
      this.props.changeStateValue({name:"loaderLong", value:true})
      this.setState({btnDisable:true})
      let formData = new FormData();
      formData.append('title', title);
      formData.append('amount', amount);
      formData.append('type', type);
      formData.append('file', file);
      formData.append('student_id', this.props.params.id);
      let url = 'student-finance'
      if(id != ''){
          url = `student-finance/${id}`;
          formData.append('_method', 'put')
      }
      this.props.addStudentFinance(url, formData)
      .then(resp=>{
        this.props.changeStateValue({name:"loaderLong", value:false})
        mixinAlert("success", "Finance added successfully");
        this.props.getFinances(this.props.params.id)
        this.setState({
          amountOuts:'',
          titleOuts:'',
          fileOuts:'',
          idOuts:'',
          amountIns:'',
          titleIns:'',
          fileIns:'',
          idIns:'',
          btnDisable:false,
        })
      })
    }else{
      if(title ==""){
        mixinAlert("warning", "Please, fill the title fields")
      }else if(amount == ""){
        mixinAlert("warning", "Please, fill the amount fields")
      }else if(file == ""){
        mixinAlert("warning", "Please, upload filed")
      }
    }
  }

  getTotal(arr){
    let total = 0;
    for(const x of arr){
      total+= +x?.amount
    }
    return total;
  } 
  onEditTotal=(data)=>{
    this.props.changeStateValue({
      name:"studentTotalIncModal",
      value:{
        position:true,
        ...data
      }
    })
  }

  onDeleteTotal=(id)=>{
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deleteData(`student-total-finance/${id}`)
        .then(resp=>{
          if(resp == "success"){
            mixinAlert('success', `Data deleted successfully`);
            this.getAfterCall()
          }else{
            mixinAlert('error', `Something went wrong`)
          }
        })
          // this.props.deleteStudentTracking(`student-note/${id}`)
          // .then(resp=>{
          //     Swal.fire(
          //         resp =='success'? 'Deleted!':"Error!" ,
          //         resp =='success'? 'Your note deleted!':"Something went wronge!" ,
          //         resp
          //       )
          //       this.props.getStudentNotes(this.props.studentNoteModal?.student_id)
          //       this.closeModal()
          // })
       
      }
    })
  }
  render() {
    const {studentFinances, finance_lock, studentTotalIncomes, studentTotalIncModal, user} = this.props;
    const {amountOuts, titleOuts, btnDisable, fileOuts, idOuts,amountIns, titleIns, idIns, fileIns, section } = this.state
    // console.log( studentTotalIncomes)
    return (
     <div>
      {/* <div className='mt-[20px] inline-flex items-center gap-[10px] bg-white p-[6px] rounded-[10px] shadow-md'>
        <input className='cursor-pointer' id='commissionCheckBtnId' type='checkbox' />
        <label htmlFor='commissionCheckBtnId' className='cursor-pointer text-[14px]'>Will receive commission?</label>
      </div> */}
      <div className='mt-[20px] flex gap-[20px] items-center'>
        <button onClick={()=>this.setState({section:"contracts"})} className={`${section=="contracts"?"text-white  bg-blue-700 ":"text-blue-700 bg-white "} text-[12px] p-[4px_5px]  font-semibold rounded-[5px]`}>Contract</button>
        <button onClick={()=>this.setState({section:"free"})} className={`${section=="free"?"text-white  bg-blue-700 ":"text-blue-700 bg-white "} text-[12px] p-[4px_5px]  font-semibold rounded-[5px]`}>Free university</button>
      </div>
      {
        section == "contracts"?
        <div className='flex flex-col gap-[20px] mt-[20px] grid grid-cols-2 max-[840px]:grid-cols-1 items-start'>
          <div className='bg-white p-[20px] rounded-[10px] shadow-md'>
            <h3 className='text-[15px]  mt-[10px] text-center mb-[10px] text-[20px] font-semibold'>Expenses</h3>
            {
                finance_lock==1?
                <>
            <div className='flex  gap-[10px]'>
              <input onChange={(e)=>this.setState({titleOuts: e.target.value})} value={titleOuts} type='text' className='outline-none rounded-[10px] font-[400]  h-[35px]' placeholder='Title' />
              <input onChange={(e)=>this.setState({amountOuts: e.target.value})} value={amountOuts} type='number' placeholder='0,000.0' className='outline-none rounded-[10px] font-[400] h-[35px]' />
              <input onChange={(e)=>this.setState({fileOuts: e.target.files[0]})} type='file' id='docUploadFileId' className='hidden' />
              <div className='flex justify-between gap-[10px]'>
                <label htmlFor='docUploadFileId' className='border h-[35px] rounded-[10px] text-[13px] p-[5px_12px] font-[400] cursor-pointer inline-flex justify-between'>
                    <svg width={18} fill={fileOuts == ''? '': '#4f46e5'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456c13.3 0 24-10.7 24-24s-10.7-24-24-24s-24 10.7-24 24s10.7 24 24 24z"/>
                      </svg>
                  {
                    fileOuts !=""?
                    <button onClick={(e)=>{e.preventDefault(); this.setState({fileOuts:''})}}>
                      <DeleteSvg />
                    </button>:null
                  }
                  
                </label>
                <button disabled={btnDisable} onClick={this.addBtn.bind(this, titleOuts, amountOuts, fileOuts, "outs", idOuts)} className='border h-[35px] rounded-[10px] text-[13px] text-left pl-[12px] pr-[12px] font-[400] cursor-pointer'>
                  {idOuts==""?"Add":"Update"}
                </button>
              </div>
            </div>
            </>:null}
            <div className=' w-full h-[1px] bg-gray-100 mt-[10px]'></div>
            <div>
              <div className='bg-gray-100 rounded-[10px] mt-[10px] flex flex-col gap-[10px] p-[4px]'>
                {
                  this.getFinanceTypes(studentFinances, "outs")
                  .map((data, i)=>{
                    return(
                      <div key={i} className='p-[10px] bg-white rounded-[10px] flex justify-between '>
                          <span className='w-[50%] text-[14px] font-[500] border-r'>{data?.title}</span>
                          <span className='w-[30%] text-[14px] font-[500] pl-[4px] border-r flex items-center gap-[4px] justify-center'>{this.getFeeAmount(data?.amount)}
                          <svg width={12} height={13} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" >
                            <path d="M192 32c-17.7 0-32 14.3-32 32v34.7C69.2 113.9 0 192.9 0 288v160c0 17.7 14.3 32 32 32s32-14.3 32-32V288c0-59.6 40.8-109.8 96-124v284c0 17.7 14.3 32 32 32s32-14.3 32-32V164c55.2 14.2 96 64.3 96 124v160c0 17.7 14.3 32 32 32s32-14.3 32-32V288c0-95.1-69.2-174.1-160-189.3V64c0-17.7-14.3-32-32-32z" />
                          </svg>
                          </span>
                          <span className=' text-[12px] font-[500] pl-[4px] border-l text-right  w-[20%]  justify-center flex items-center gap-[4px] '>{moment(data?.created_at).format("DD/MM/YY")}</span>
                          <div className='w-[20%] flex items-center gap-[10px] border-l ml-[10px] justify-center'>
                            {
                              finance_lock==1?
                              <>
                               <button onClick={this.deleteDocument.bind(this, data.id)}>
                                <DeleteSvg />
                              </button>
                              <button onClick={(e)=>this.setState({titleOuts: data?.title, amountOuts:data?.amount, fileOuts:data?.file?data?.file:'', idOuts:data?.id})}>
                                <EditSvg />
                              </button>
                              </>:null
                            }
                           
                            <a target="_blank" href={data.file?.file_full_url}>
                              <DownloadSvg />
                            </a>
                          </div>
                      </div>
                    )
                  })
                }
               
              </div>
                <div className='flex justify-between items-center mt-[20px]'>
                  <p  className='font-[600] text-[13px]'>Total: {this.getFeeAmount(this.getTotalAmount(studentFinances, "outs"))}</p>     
                  <p className='font-[600] text-[13px]'>Remaining: {this.getFeeAmount(this.getRemaining(this.getTotalAmount(studentFinances, 'incomes'), this.getTotalAmount(studentFinances, 'outs')))} </p>
                </div>
            </div>
          </div>
          <div className='bg-white p-[20px] rounded-[10px] shadow-md'>
            <div className='border-b border-b-[2px] border-b-black flex flex-col items-center gap-[20px]'>

               <div className='flex items-center gap-[20px]'>
                <button onClick={()=>{this.props.changeStateValue({name:"studentTotalIncModal.position", value:true})}} className='bg-[#e5f0ff] inline-flex justify-center items-center w-[60px] h-[60px] rounded-full'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" class="icon" viewBox="0 0 1024 1024"><path fill="#1E398F" d="M182.52 146.2h585.14v256h73.15V73.06H109.38v877.71h256v-73.14H182.52z"></path><path fill="#1E398F" d="M255.67 219.34h438.86v73.14H255.67zm0 146.29h365.71v73.14H255.67zm0 146.28H475.1v73.14H255.67zm519.55-53.67L439.04 794.42l-.52 154.64 155.68.52L930.38 613.4 775.22 458.24zm51.72 155.16-25.43 25.43-51.73-51.72 25.44-25.44 51.72 51.73zm-77.14 77.15L620.58 819.77l-51.72-51.72 129.22-129.22 51.72 51.72zM511.91 876.16l.17-51.34 5.06-5.06 51.72 51.72-4.85 4.85-52.1-.17z"></path></svg>
                </button>
                <div className='flex items-center gap-[10px] '>
                  {
                    studentTotalIncomes.map((data, i)=>{
                      return(
                        <div>
                          <a key={i} download={true} target='_blank' href={data?.file_full_url}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 52 52" fill="#1E398F"><path d="M28.6 11.4h5.1a1.11 1.11 0 0 0 1.1-1.1 1.32 1.32 0 0 0-.3-.8l-7.2-7.1a.89.89 0 0 0-.7-.3 1.11 1.11 0 0 0-1.1 1.1v5.1a3.12 3.12 0 0 0 3.1 3.1zM49.83 32.45A1.43 1.43 0 0 0 48.44 31h-12A1.43 1.43 0 0 0 35 32.44v1.44a1.43 1.43 0 0 0 1.4 1.44h6.14l-11 11a1.42 1.42 0 0 0 0 2l1 1a1.54 1.54 0 0 0 1.09.45 1.32 1.32 0 0 0 .94-.38l11-11v6A1.43 1.43 0 0 0 47 45.83h1.53a1.43 1.43 0 0 0 1.47-1.4zM18.35 20.68h-1.46v4.93h1.46a1.56 1.56 0 0 0 1.56-1.1 3.93 3.93 0 0 0 .24-1.44 3.61 3.61 0 0 0-.36-1.77 1.53 1.53 0 0 0-1.44-.62zM11.29 20.93a1.27 1.27 0 0 0-.84-.25H9v2.23h1.46a1.18 1.18 0 0 0 .84-.28 1.09 1.09 0 0 0 .3-.86 1 1 0 0 0-.33-.84z"></path><path d="M33.24 16H25.4a4.67 4.67 0 0 1-4.7-4.6V3.6a1.42 1.42 0 0 0-1.34-1.5H6.8a4.67 4.67 0 0 0-4.7 4.6v29.4a4.6 4.6 0 0 0 4.62 4.6H24.6v-.1a15.92 15.92 0 0 1 10.2-14.9v-8.1a1.58 1.58 0 0 0-1.56-1.6zm-20.75 7.65a2.93 2.93 0 0 1-1.89.56H9v2.71H7.49v-7.55h3.24a2.64 2.64 0 0 1 1.76.63 2.3 2.3 0 0 1 .66 1.8 2.35 2.35 0 0 1-.66 1.85zm8.66 1.9A2.73 2.73 0 0 1 18.62 27h-3.26v-7.63h3.26a3.85 3.85 0 0 1 1.17.17 2.49 2.49 0 0 1 1.28.95 3.43 3.43 0 0 1 .54 1.22 5.6 5.6 0 0 1 .14 1.29 5 5 0 0 1-.6 2.55zm7.92-4.84h-3.81v1.74h3.34v1.31h-3.34v3.16h-1.57v-7.54h5.38z"></path></svg>
                        </a>
                        {
                          user?.role == "uni_rep"?
                          <div className='flex items-center gap-[9px]'>
                          <button onClick={this.onEditTotal.bind(this, data)}>
                            <CardEditSvg />
                          </button>
                          <button onClick={this.onDeleteTotal.bind(this, data?.id)}>
                            <DeleteSvg />
                          </button>
                        </div>:null
                        }
                        </div>
                      )
                    })
                  }
                </div>
               </div>

            {
                studentTotalIncomes.length == 0?
                <button onClick={()=>{this.props.changeStateValue({name:"studentTotalIncModal.position", value:true})}} className='mt-[10px] mb-[10px] text-[12px] font-semibold bg-blue-600 p-[3px_5px] text-white rounded-[6px]'>Add contract</button>:
                <h2 className='font-semibold mb-[10px] text-center'>
                 <span className='inline-flex items-center gap-[6px]'> Total to be paid: {this.getFeeAmount(this.getTotal(studentTotalIncomes))} <Manat2Svg /> </span> 
                <br /> <span className='inline-flex items-center gap-[6px]'>Total paid: { this.getFeeAmount(this.getTotalAmount(studentFinances, "incomes"))} <Manat2Svg /></span>
                <br /> <span className='inline-flex items-center gap-[6px]'>Remaining: { this.getFeeAmount(this.getTotal(studentTotalIncomes) - this.getTotalAmount(studentFinances, "incomes"))} <Manat2Svg /></span>
                
                </h2>
              }
            
              
            
            </div>
            <h3 className='text-[15px]  mt-[10px] text-center mb-[10px] text-[20px] font-semibold'>Payments</h3>
            
               {
                finance_lock==1?
                <>
            <div className='flex gap-[10px]'>
              <input onChange={(e)=>this.setState({titleIns: e.target.value})} value={titleIns} type='text' className='outline-none rounded-[10px] font-[400]' placeholder='Title' />
              <input onChange={(e)=>this.setState({amountIns: e.target.value})} value={amountIns} type='number' placeholder='0,000.0' className='outline-none rounded-[10px] font-[400]' />
              <input onChange={(e)=>this.setState({fileIns: e.target.files[0]})} type='file' id='docUploadFileId2' className='hidden' />
              <div className='flex justify-between gap-[10px]'>
                <label htmlFor='docUploadFileId2' className='border h-[35px] rounded-[10px] text-[13px] p-[5px_12px] font-[400] cursor-pointer inline-flex justify-between'>
                <svg width={18} fill={fileIns == ''? '': '#4f46e5'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456c13.3 0 24-10.7 24-24s-10.7-24-24-24s-24 10.7-24 24s10.7 24 24 24z"/>
                      </svg>
                  {
                    fileIns !=""?
                    <button onClick={(e)=>{e.preventDefault(); this.setState({fileIns:''})}}>
                      <DeleteSvg />
                    </button>:null
                  }
                  
                </label>
                <button disabled={btnDisable} onClick={this.addBtn.bind(this, titleIns, amountIns, fileIns, "incomes", idIns)} className='border h-[35px] rounded-[10px] text-[13px] text-left pl-[12px] pr-[12px] font-[400] cursor-pointer'>
                  {idIns==""?"Add":"Update"}
                </button>
              </div>
            </div>
            </>:null}
            <div className=' w-full h-[1px] bg-gray-100 mt-[10px]'></div>
            <div>
              <div className='bg-gray-100 rounded-[10px] mt-[10px] flex flex-col gap-[10px] p-[4px]'>
                {
                  this.getFinanceTypes(studentFinances, "incomes")
                  .map((data, i)=>{
                    return(
                      <div key={i} className='p-[10px] bg-white rounded-[10px] flex justify-between '>
                          <span className='w-[40%] text-[14px] font-[500] border-r'>{data?.title}</span>
                          <span className='w-[20%] text-[14px] b  font-[500] pl-[4px] border-r flex justify-center items-center gap-[4px]'>{this.getFeeAmount(data?.amount)}
                            <svg width={12} height={13} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" >
                              <path d="M192 32c-17.7 0-32 14.3-32 32v34.7C69.2 113.9 0 192.9 0 288v160c0 17.7 14.3 32 32 32s32-14.3 32-32V288c0-59.6 40.8-109.8 96-124v284c0 17.7 14.3 32 32 32s32-14.3 32-32V164c55.2 14.2 96 64.3 96 124v160c0 17.7 14.3 32 32 32s32-14.3 32-32V288c0-95.1-69.2-174.1-160-189.3V64c0-17.7-14.3-32-32-32z" />
                            </svg>
                         
                          </span>
                          <span className=' text-[12px] font-[500] pl-[4px] border-l text-right  w-[20%]  justify-center flex items-center gap-[4px] '>{moment(data?.created_at).format("DD/MM/YY")}</span>
                          <div className='w-1/4  gap-[10px] border-l ml-[10px]  flex items-center justify-center'>
                          {
                              finance_lock==1?
                              <>
                            <button onClick={this.deleteDocument.bind(this, data.id)}>
                              <DeleteSvg />
                            </button>
                            <button onClick={(e)=>this.setState({titleIns: data?.title, amountIns:data?.amount, fileIns:data?.file, idIns:data?.id})}>
                              <EditSvg />
                            </button>
                            </>:null
                          }
                            <a  target="_blank" href={data.file?.file_full_url}>
                              <DownloadSvg />
                            </a>
                          </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className='flex justify-between items-center mt-[20px]'>
                <p  className='font-[600] text-[13px]'>Total: {this.getFeeAmount(this.getTotalAmount(studentFinances, "incomes"))}</p>     
              </div>
          </div>
      </div>:
      <FinancePrevPage student_id={this.props.params.id} />
      }
       
      {
        studentTotalIncModal.position?
        <StudentTotalModal student_id={this.props.params.id} getAfterCall={()=>this.getAfterCall()} />:null
      }
     </div>
    )
  }
}
const mapStateToProps = (state)=>({
  studentFinances: state.Data.studentFinances,
  studentTotalIncomes: state.Data.studentTotalIncomes,
  studentTotalIncModal: state.Data.studentTotalIncModal,
  user: state.Data.user,
});
const mapDispatchToProps = {getFinances, deleteData, addStudentFinance, changeStateValue, getTotalIncomes, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Finance))
