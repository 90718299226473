import React, { Component } from 'react'
import { connect } from 'react-redux'
import File from './components/File'
import Input from './components/Input'
import ModalFooter from './components/ModalFooter'
import ModalHeader from './components/ModalHeader'
import { changeStateValue } from '../../redux/MainReducer'
import DropDown from './components/DropDown'

export class MainModal extends Component {

    getBase64(file){
        return new Promise((res, rej)=>{
            let reader = new FileReader();
            reader.onload = () => {
                res(reader.result)
            }
            reader.readAsDataURL(file)
        })
    }
    getInpValue = (name, value) => {
        this.props.changeStateValue({
            name: this.props.stateName+"."+name,
            value: value
        })
        if(name == 'image'){
            this.getBase64(value)
            .then(resp=>{
                this.props.changeStateValue({
                    name: this.props.stateName+".image_full_url",
                    value: resp
                })
            })
           
        }
        if(name == 'background_image'){
            this.getBase64(value)
            .then(resp=>{
                this.props.changeStateValue({
                    name: this.props.stateName+".background_full_url",
                    value: resp
                })
            })
           
        }
    }
    getPlaceholder(state_id, arr){
        if(state_id && state_id != ''){
            const check = arr.find(data=> data.value == state_id);
            if(check){
                return check.label
            }else{
                return 'Select a State'
            }
        }else{
            return 'Select a State'
        }
    }
  render() {
    const {modalData, stateName, url, getAfterChange, dropDownOption=[]} = this.props;
    console.log(modalData)
    return (
      <div className='modal_background_shadow'>
        <div className='add-edit_modal'>
            <ModalHeader />
            <div className='grid grid-cols-2 gap-5 mb-5 mt-5'>
                {
                    modalData.name || modalData.name == ''?
                    <Input getInpValue={this.getInpValue} name="name" value={modalData.name} title="Name" />:null
                }
                {
                    modalData.title || modalData.title == ''?
                    <Input getInpValue={this.getInpValue} name="title" value={modalData.title} title="title" />:null
                }
                
                {
                    modalData.image || modalData.image == '' ?
                    <File value={modalData.image_full_url} name="image" getInpValue={this.getInpValue}  />
                    :null
                }
                {
                    modalData.background_full_url || modalData.background_full_url == '' ?
                    <File title="Flag" value={modalData.background_full_url} name="background_image" getInpValue={this.getInpValue}  />
                    :null
                }
                
                {
                    dropDownOption.length != 0?
                    <DropDown placeholder={this.getPlaceholder(modalData.state_id, dropDownOption)} getInpValue={this.getInpValue} option={dropDownOption}/>:null
                }
                
            </div> 
            {  modalData.image_full_url?  <img className='w-full rounded-xl mb-3' src={modalData.image_full_url}/>:null  }
            {  modalData.background_full_url?  <img className='w-full rounded-xl mb-3' src={modalData.background_full_url}/>:null  }
            <ModalFooter url={url} getAfterChange={getAfterChange} modalData={modalData} stateName={stateName} />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({ });
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(MainModal)