import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getInstitutionFaqTranslator } from '../../actions/TranslateAction';
import SectionHeader from '../../components/page_component/SectionHeader';
import TranslatorCard2 from './components/TranslatorCard2';
export class TranslatorInstitutionFaq extends Component {
    componentDidMount(){
        this.props.getInstitutionFaqTranslator()
    }

    getAvailableData(data, item){
        // console.log(data)
        let array = [];
        for(const property in data.default){
         
          if(property == 'question' || property == 'answer'){
            let x = []
            x.push(
              `${property}`, data[item]&&data[item][property]?data[item][property]:''
            )
          array.push(x)
    
          }
        }
    
        return array
      }

      getAfterSave = () =>{
        this.props.getInstitutionFaqTranslator()

      }
  render() {
    const {institutionFaqTranslate, user} = this.props
    return (
        <div className='container'>
            {
            user != ''?
            <SectionHeader  title={`Translate Institutions FAQs From English to ${user?.lang?.main_lang?.name}`} />:null
            }
            <ul>
                {
                    institutionFaqTranslate.map((data, i)=>{
                        return(
                            <TranslatorCard2 
                                getAfterSave={this.getAfterSave}
                                url={`setInstitutionsFaqTranslate/${institutionFaqTranslate[i].default.institution_faqs_id}`} 
                                key={i} 
                                data={this.getAvailableData(data, 'default')} 
                                translate={this.getAvailableData(data, 'translate')} 
                            />
                        )
                    })
                }
            </ul>
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
    institutionFaqTranslate: state.Data.institutionFaqTranslate,
    user: state.Data.user,
});
const mapDispatchToProps = {getInstitutionFaqTranslator}
export default connect(mapStateToProps, mapDispatchToProps)(TranslatorInstitutionFaq)