import axios from 'axios';
import { MAIN_API } from '../MAIN_API';
import {changeStateValue} from '../redux/MainReducer';


export const mainDashboardTranslate = (id) => async (dispatch) =>{
  
    return await axios.get(`${MAIN_API}/main-dashboard-tr-data?lang_id=${id}`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'mainTransateData',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}
export const getUnirepRoleTranslate = (id) => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/unirep-role-tr-data?lang_id=${id}`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'unirepRoleTranslate',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}
export const getTranslatorDashboard = () => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/translator-dashboard-data`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'translatorDashboardData',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}
export const getTranslatorUnirep = () => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/translator-unirep-data`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'translatorUnirepData',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}



export const changeTranslateData = (url, data) => async (dispatch) =>{
  
    return await axios.post(`${MAIN_API}/${url}`,data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{ console.log(resp.data)
        // dispatch(changeStateValue({
        //     name: 'unirepRoleTranslate',
        //     value: resp.data
        // }))

        return 'success'
    }).catch(err=>{console.log(err.response)})
}


export const getCountriesTranslator = () => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/tr-countries`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'countriesTranslatorData',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}

export const getCountriesFaqTranslator = () => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/tr-country-faq`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'countriesFaqTranslatorData',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}


export const getInstitutionTranslator = () => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/tr-institution`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'institutionTranslate',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}

export const getInstitutionFaqTranslator = () => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/tr-institutions-faq`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'institutionFaqTranslate',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}

export const getAccTrackTranslator = () => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/tr-acc-track`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'accTrackTranslate',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}
export const getAppTrackTranslator = () => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/tr-app-track`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'appTrackTranslate',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}
export const getVisaTrackTranslator = () => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/tr-visa-track`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'visaTrackTranslate',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}
export const getEduDegreeTranslator = () => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/tr-education-degree`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'eduDegreeTranslate',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}
export const getExtraActivityTranslator = () => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/tr-extra-activity`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'extraActivityTranslate',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}
export const getIntOlmpiadTranslator = () => async (dispatch) =>{
    
    return await axios.get(`${MAIN_API}/tr-int-olympiad`,{
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer  ${localStorage.getItem('token')}`
          } 
    }).then(resp=>{
        dispatch(changeStateValue({
            name: 'intOlympiadTranslate',
            value: resp.data
        }))
    }).catch(err=>{console.log(err.response)})
}

