import React, { Component } from 'react'
import { changeStateValue } from '../../../../../redux/MainReducer';
import { connect } from 'react-redux';
import { getFreeInst, getStudentFreeUni, insertNewData } from '../../../../../actions/WorkerActions';

export class FinancePrevPage extends Component {
    state={
        selected_id:''
    }
    componentDidMount(){
        this.props.getFreeInst();
        this.props.getStudentFreeUni(this.props.student_id);
    }

    setUniBtn=(uni_id, data="")=>{
        let formData = new FormData();
        formData.append("student_id", this.props.student_id);
        formData.append("uni_id", uni_id)
        let url = "/student-free-uni";
        if(data && data !=""){
            url = `/student-free-uni/${data?.id}`;
            formData.append("_method", "put")
        }
        this.props.insertNewData(url, formData)
        .then(resp=>{
            this.props.getStudentFreeUni(this.props.student_id);
        })
    }
  render() {
    const {freeInstitutions, studentFreeUni} = this.props;
    const {selected_id} = this.state;
    // console.log(studentFreeUni)
    return (
      <div className='bg-white p-[10px] mt-[20px] rounded-[10px]'>
        <h3 className='text-[18px] font-semibold text-blue-700'>Free universities</h3>
        <h3 className='text-[16px] font-regular mt-[20px] '>{studentFreeUni?.uni?.translate?.name}</h3>
        
        <ul className='mt-[20px] flex flex-col gap-[8px] max-h-[500px] overflow-auto'>
            {
                freeInstitutions?.map((data, i)=>{
                    return(
                        <li className='text-[14px] flex items-center gap-[6px] cursor-pointer ml-[4px]'>
                            <input onChange={()=>this.setState({selected_id:data?.id})} checked={data?.id == selected_id || selected_id == "" && data?.id == studentFreeUni?.uni_id} className='cursor-pointer' name="free_inst " type='checkbox' id={"id"+data?.id} />
                            <label className='cursor-pointer' htmlFor={"id"+data?.id}>{data?.translate?.name}</label>
                        </li>
                    )
                })
            }
            
        </ul>
        <button onClick={this.setUniBtn.bind(this, selected_id, studentFreeUni)} className='mt-[20px] bg-blue-600 text-[12px] font-semibold p-[3px_6px] rounded-[5px] text-white'>Submit</button>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    freeInstitutions: state.Data.freeInstitutions,
    studentFreeUni: state.Data.studentFreeUni,
});
const mapDispatchToProps = {changeStateValue, getFreeInst, getStudentFreeUni, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(FinancePrevPage)
