import React, { Component } from 'react'
import moment from 'moment'
import defImg from "../../../../../images/user.jpg"
export class GetMsg extends Component {
  getColor(role){
    if(role == '1'){
        return '#06cf9c';
    }else if(role == '2'){
        return '#c89631';
    }else if(role == '3'){
        return '#7f66ff';
    }else if(role == '4'){
        return '#53bdeb';
    }else if(role == '5'){
        return '#007bfc';
    }else if(role == '8'){
        return '#ee7100';
    }else {
        return '#ee7100';
    }
}
getRole(role){
  if(role == '1'){
    return 'Admin';
  }else if(role == '2'){
      return 'Uni Rep';
  }else if(role == '3'){
      return 'Student';
  }else if(role == '4'){
      return 'Edu Con';
  }else if(role == '5'){
      return 'Parent';
  }else if(role == '8'){
    return 'Teacher';
}else {
      return '';
  }
}
  render() {
    const {message, date, role, name, data} = this.props;
    return (
        <div className="get_msg">
            {
                data?.user?.image_full_url && data?.user?.image_full_url !="" ?
                <img src={data?.user?.image_full_url } className='w-[40px] h-[40x] rounded-[50%]' />:
                <img src={defImg} className='w-[40px] h-[40x] rounded-[50%]' />

            }
            <div className="max-w-[70%] inline-flex flex-col gap-[0px] rounded-[6px]">
         
                  <p style={{ color: this.getColor(role)}} className='msg_user_name'>{name}</p>
                  <span className='msg_user_role'>{this.getRole(role)}</span>
             
                <div className='bg-white rounded-[5px] p-[10px] mr-[10px] flex flex-col items-start max-w-[80%]'>
                  <p  className='text-[12px]'> {message}</p>
                  <span  className='text-[10px]'>{moment(date).format('HH:mm')}</span>
                </div>
            </div>
        </div>
    )
  }
}

export default GetMsg