import React, { Component } from 'react'
import { connect } from 'react-redux'
// import io from "socket.io-client";
// import { NODE_API } from '../../../MAIN_API';
import { changeStateValue } from '../../../redux/MainReducer';
// const socket = io.connect(NODE_API);
import { insertMessage,getMessagesBox, getMessagesUserList } from '../../../actions/MainAction';
export class MessagesFooter extends Component {
    state={
        message: '',
        inpRef: React.createRef()
    }
    sendMessage(messages, e){
       
        e.preventDefault();
        if(messages.trim() !== ''){
            const  data = {
                user_id: this.props.user.id,
                student_id: this.props.messagePage.student_id,
                message: messages,
                program_id:  this.props.messagePage.program_id,
                users: this.props.messagesUsers
            }
            // console.log(data)
            this.props.insertMessage(data)
            .then(resp=>{
                this.props.getMessagesBox(this.props.messagePage.student_id, this.props.messagePage.program_id);
                this.props.getMessagesUserList(this.props.languageId)
                this.setState({message: ''})
            })
            // socket.emit('new-msg', data)
            // this.props.changeStateValue({
            //     name: 'message',
            //     value: ''
            //    })
            // this.props.typingMsg('')

        }
        this.state.inpRef.current.focus()
        
    }
    componentDidMount(){
        this.state.inpRef.current.addEventListener('keyup', (e)=>{
            
            if(e.code == 'Enter'){
                if(this.props.message.trim() !== ''){
                    const  data = {
                        user_id: this.props.user.id,
                        student_id: this.props.messagePage.student_id,
                        message: this.props.message,
                        program_id: this.props.messagePage.program_id,
                        users: this.props.messagesUsers
                    }
                    this.props.insertMessage(data)
                    .then(resp=>{
                        this.props.getMessagesBox(this.props.messagePage.student_id, this.props.messagePage.program_id);
                        this.setState({message: ''})
                    })
                    this.props.changeStateValue({
                        name: 'message',
                        value: ''
                       })
                    // this.props.typingMsg('')
                }
            }
        })
       
    }

    getInpValue(e){
       this.props.changeStateValue({
        name: 'message',
        value: e.target.value
       })
        this.setState({message: e.target.value})
        // this.props.typingMsg(e.target.value)
        
    }
  render() {
    const { inpRef, message} = this.state;
    // const {message} = this.props

    return (
        <div className="flex justify-between h-[55px] w-full bg-white rounded-[0_0_10px_10px]">
            <form className='flex items-center justify-between w-full'>
                <textarea ref={inpRef} placeholder='Type a message' value={message} onChange={this.getInpValue.bind(this)}  className="write-msg" ></textarea>
                <button onClick={this.sendMessage.bind(this, message)} className="send-btn"  >
                <svg  xmlns="http://www.w3.org/2000/svg"  width={20}  height={20}  fill="none"  viewBox="0 0 24 24">
                    <path  stroke="#fff"  strokeLinecap="round"  strokeLinejoin="round"  strokeWidth={1.5}  d="m10 14 2.273 5.303c.313.73 1.286.807 1.676.116A54.175 54.175 0 0 0 17 13c2-5 3-9 3-9M10 14l-5.303-2.273c-.73-.313-.807-1.286-.116-1.676A54.175 54.175 0 0 1 11 7c5-2 9-3 9-3M10 14 20 4"/>
                </svg>
                </button>
            </form>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    messagePage: state.Data.messagePage,
    user: state.Data.user,
    message: state.Data.message,
    messagesUsers: state.Data.messagesUsers,
    languageId: state.Data.languageId,
})
const mapDispatchToProps = {changeStateValue, insertMessage, getMessagesBox, getMessagesUserList}
export default connect(mapStateToProps,mapDispatchToProps)(MessagesFooter)