import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendResetUrl } from '../actions/MainAction'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
function withHooks(Component){
  return props => <Component {...props} navigate={useNavigate()} />
}
export class ForgotPassword extends Component {
  state={
    email: '',
    btnDisable: false
  }
  sendBtn(email){
    if(email != ''){
      this.setState({btnDisable:true})
      this.props.sendResetUrl({email})
      .then(resp=>{
        this.setState({btnDisable:false})
        if(resp == 'success'){
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Reset link sent your email',
            showConfirmButton: false,
            timer: 1500
          })
          this.props.navigate('/login')
        }else{
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Email does not exixst',
            showConfirmButton: false,
            timer: 1500
          })
        }
      })
    }
  }
  render() {
    const {email, btnDisable} = this.state;
    return (
      <div style={{ height: '60vh' }} className='container flex items-center justify-center'>
        <div style={{ flexDirection: 'column', width: '450px' }} className='flex gap-3 items-center border pt-20 pl-10 pr-10 pb-20 rounded-xl shadow-xl'>
          <h2 className='text-2xl font-semibold'>Reset your password</h2>
            <input value={email} onChange={(e)=>this.setState({email:e.target.value})} type='email' placeholder='Example@gmail.com' />
            <button disabled={btnDisable} onClick={this.sendBtn.bind(this, email)} className='text-sm border rounded-xl p-2 bg-blue-500 text-white font-semibold cursor-pointer'>
                Reset password
            </button>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {sendResetUrl}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ForgotPassword))
