import React, { Component } from 'react';
import { connect } from 'react-redux';
import SectionHeader from '../SectionHeader';
import ViewAllBtn from '../ViewAllBtn';
import ProgramCard from './ProgramCard';
import { getPrograms } from '../../../actions/MainAction';
import LoadMoreBtn from '../LoadMoreBtn';
import LoginComponent from '../simulator_components/login_register/LoginComponent';
import Filter from './Filter';
import SkeletonCard from '../SkeletonCard';
import Loading from '../Loading';
export class Program extends Component {
  state={
    pageCount: 1,
    country: '',
    state: '',
    city: ''
  }
  componentDidMount(){
    if(localStorage.getItem('token')){
      this.props.getPrograms(1, this.props.languageId, '', '', 'programs',this.state.country, this.state.state, this.state.city)

    }else{
      this.props.getPrograms(1, this.props.languageId, '', '', 'public-programs', this.state.country, this.state.state, this.state.city)
    }
  }
  loadMoreFunction = (page) =>{
    
    this.setState({pageCount: page})
    if(localStorage.getItem('token')){
      this.props.getPrograms(page, this.props.languageId, '', '', 'programs', this.state.country, this.state.state, this.state.city)

    }else{
      this.props.getPrograms(page, this.props.languageId, '', '', 'public-programs', this.state.country, this.state.state, this.state.city)
    }
  }
  getAfterApply = () =>{
    if(localStorage.getItem('token')){
      this.props.getPrograms(this.state.pageCount, this.props.languageId, '', '', 'programs', this.state.country, this.state.state, this.state.city)

    }
  }
  filterData = (data, type) =>{
    
    this.setState({
      [type]: data
    })
  }
  render() {
    const {programs, page, btn, simulatorLogin, mainTransateData, filter = true,  loader, loaderLong,} = this.props;
    const {pageCount} = this.state;
    let data = [];
    if(localStorage.getItem('token')){
        data = programs
    }else{
        const ls = JSON.parse(localStorage.getItem('lsWishList'));
        if(ls){
          for(const item of programs){
            if(ls.find(wish=> wish == item?.program?.id)){
              data.push({...item, wish_list:1 })
            }else{
              data.push({...item, wish_list:0 })
            }
              
          }
        }else{
        data = programs
            
        }
       
    }
    return (
        <div className="nc-SectionGridFeaturePlaces relative pb-50 " >
          <div className="flex flex-col mb-8 relative">
            <SectionHeader arialLabelTitle="mainTransateData.program_title" arialLabelDesc="mainTransateData.program_desc" title={mainTransateData?.program_title} description={mainTransateData?.program_desc} />
          </div>
          {
            filter?
            <Filter filterData={this.filterData} pageCount={pageCount} />:null
          }
          
          <div  className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
            {
              page == 8?
              data &&
              data.map((program, i)=>{
                if(i< page){
                  return(
                    <ProgramCard getAfterApply={this.getAfterApply} key={i} program={program} />
                  )
                }
              }):
              data &&
              data.map((program, i)=>{
                  return(
                    <ProgramCard getAfterApply={this.getAfterApply} key={i} program={program} />
                  )
              })
            }
            {
                  data?.length == 0?
                  <>
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  </>:null
                }
            
          </div>
          {
            btn === 'view_all'?
            <ViewAllBtn href="/programs" />:
            programs.length >= 20?
            <LoadMoreBtn position={programs.length} loadMoreFunction={this.loadMoreFunction}  />:null
          }
          {
          simulatorLogin?
          <LoginComponent />:null
        }
        {
            loader || loaderLong? 
            <Loading />:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  programs: state.Data.programs,
  languageId: state.Data.languageId,
  simulatorLogin: state.Data.simulatorLogin,
  mainTransateData: state.Data.mainTransateData,
  loader: state.Data.loader,
  loaderLong: state.Data.loaderLong,
})
const mapDispatchToProps = {getPrograms}
export default connect(mapStateToProps, mapDispatchToProps)(Program)