import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LikeIcon from './LikeIcon'

export class Slider extends Component {
    state={
       
        visibleImg: 0
    }
    changeSlider(position, count, current,  e){
  
        if(position == 'right'){
           if(current < count-1){
                current++;
           }else{
            current = 0;
           }
        }else{
            if(current >0){
                current--;
           }else{
            current = count-1;
           }
        }
        this.setState({
            visibleImg: current
        })
    }
    getPointImage(index, e){
        this.setState({
            visibleImg: index
        })
    }
    getFeeAmount(x){
        var parts = x.toString().split(".");
        parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");
        return parts.join(",");
    }
    activeUniBtn(id,translate_id, data,  e){
      
        this.props.activeUni(id, translate_id, data)
    }
  render() {
    const { visibleImg} = this.state;
    const {galleryImgs, institutions, url="/", waiting, user} = this.props;

    return (
        <div className="relative w-full rounded-2xl p-1 overflow-hidden will-change-transform">
           <div className={`nc-GallerySlider`} data-nc-id="GallerySlider">
                <div className={`gallerySlider__StayCard_1 relative group overflow-hidden`}>
                    <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {
                            galleryImgs.length !== 0?
                            galleryImgs.map((item, index) => {
                                if(visibleImg === index){
                                    return(
                                        <li key={index} className="glide__slide">
                                            <Link to={url} className={`block aspect-w-3 aspect-h-3`}>
                                                <div className={`nc-NcImage  `} data-nc-id="NcImage" >
                                                
                                                    {/* <img src={institutions?.institutions?.image_full_url} className={'object-cover w-full h-full'}  /> */}
                                                    <img src={item?.image_full_url} className={'object-cover w-full h-full'} style={{ borderRadius: '10px 10px 0 0' }} />
                                                
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                }
                           
                            }): <li className="glide__slide">
                                    <Link to={url} className={`block aspect-w-3 aspect-h-3`}>
                                        <div className={`nc-NcImage  `} data-nc-id="NcImage" >
                                        
                                            <img src={institutions?.institutions?.image_full_url} className={'object-cover w-full h-full'}  style={{ borderRadius: '10px 10px 0 0' }}  />
                                            {/* <img src={item?.image_full_url} className={'object-cover w-full h-full'}  /> */}
                                        
                                        </div>
                                    </Link>
                                </li>

                        }
                        
                    </ul>
                    </div>

                    {/* DOTS */}
                    <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
                    <div
                        className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
                        data-glide-el="controls[nav]"
                    >
                        {galleryImgs.map((_, i) => (
                        <button
                            id='slider_btn_dot'
                            onClick={this.getPointImage.bind(this, i)}
                            className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300 "
                            key={i}
                            data-glide-dir={`=${i}`}
                        />
                        ))}
                    </div>

                    {/* NAV */}
                    <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
                    <div
                        className={`nc-NextPrev relative flex items-center text-neutral-900 dark:text-neutral-300 w-full justify-between`}
                        data-nc-id="NextPrev"
                        data-glide-el="controls"
                        >
                        
                            <button
                            className={`w-8 h-8 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300 `}
                            
                            onClick={this.changeSlider.bind(this, 'left', galleryImgs.length, visibleImg)}

                            title="Prev"
                            data-glide-dir="<"
                            >
                            <i className="las la-angle-left"></i>
                            </button>
                        
                    
                            <button
                            className={`w-8 h-8 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full flex items-center justify-center hover:border-neutral-300  `}
                            onClick={this.changeSlider.bind(this, 'right', galleryImgs.length, visibleImg)}
                            
                            title="Next"
                            data-glide-dir=">"
                            >
                            <i className="las la-angle-right"></i>
                            </button>
                       
                        </div>
                    </div>
                        {
                            user == '' || user !== '' && user.role !== 'uni_rep'?
                            institutions.max_scholarship &&  institutions.max_scholarship != null &&  institutions.max_scholarship != 0?
                            <div style={{ height: '30px', borderRadius: '6px',  marginTop: '-1px' }} className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 bg-red-700 text-red-50  absolute left-3 top-2`}
                            data-nc-id="SaleOffBadge" >
                            {/* {this.getSchoolarship(program.currency, program?.program?.schoolarship_type, program?.program?.schoolarship_option, program?.program?.fee_amount)} */}
                            {
                            institutions.max_scholarship &&  institutions.max_scholarship != null &&  institutions.max_scholarship != 0?
                            this.getFeeAmount(institutions.max_scholarship) +" "+ institutions.currency: ''
                            }  
                        
                            </div> :null:null
                        }
                       
                     
                  
                </div>
            </div>
            {/* <LikeIcon id={institutions?.institutions?.id} type="university" /> */}
           {
            institutions?.institutions?.national_ranking && institutions?.institutions?.national_ranking != ''?
             <div style={{ backgroundColor:'#EE7100', height: '30px', borderRadius: '6px'}} className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 bg-red-700 text-red-50  absolute right-3 top-3`}
                    data-nc-id="SaleOffBadge" >
                   <span style={{ fontWeight: '600' }}>QS Rank: {institutions?.institutions?.national_ranking<=200?institutions?.institutions?.national_ranking: institutions?.institutions?.national_ranking>200 && institutions?.institutions?.national_ranking<=500? '200+': '500+'}</span>
                </div>:null
           }
           
            {user !== '' && user.role == 'uni_rep'?
                waiting == '0'?
                <div className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 bg-red-700 text-red-50 rounded-full absolute left-3 top-3`}
                    data-nc-id="SaleOffBadge" >
                    <button onClick={this.activeUniBtn.bind(this, institutions?.institutions?.id,institutions.id, institutions)}>waiting</button>
                </div>:null:null
            }
           
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user
})
export default connect(mapStateToProps)(Slider)