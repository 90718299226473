import React, { Component } from 'react'

export class AppleIcon extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 291.538 291.538"
        style={{
          enableBackground: "new 0 0 291.538 291.538",
        }}
        xmlSpace="preserve"
        width={25}
      >
        <path
          style={{
            fill: "#fff",
          }}
          d="M188.373 44.308c10.97-11.744 18.39-28.049 16.359-44.308-15.804.564-34.958 9.277-46.302 21.02-10.169 10.396-19.072 27.011-16.696 42.951 17.635 1.193 35.651-7.938 46.639-19.663zm33.657 112.285c-.337-36.451 28.859-53.958 30.152-54.804-16.414-24.753-41.977-28.14-51.081-28.522-21.767-2.285-42.442 13.182-53.493 13.182-11.006 0-28.03-12.882-46.083-12.536-23.733.391-45.601 14.211-57.79 36.114-24.654 44.063-6.319 109.336 17.705 145.077 11.744 17.497 25.727 37.125 44.089 36.415 17.725-.71 24.407-11.789 45.792-11.789 21.394 0 27.384 11.789 46.101 11.434 19.045-.355 31.098-17.834 42.742-35.386 13.473-20.292 19.027-39.938 19.345-40.93-.409-.237-37.088-14.666-37.479-58.255z"
        />
      </svg>
    )
  }
}

export default AppleIcon