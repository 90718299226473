import React, { Component } from 'react'
import moment from 'moment'
import ReadSvg from './ReadSvg';
import UnreadSvg from './UnreadSvg';
import { connect } from 'react-redux';
import defImg from "../../../../../images/user.jpg"
export class SendMsg extends Component {
  render() {
    const {message, date, status, user} = this.props;
    // console.log(date)
    return (
        <div className="send_msg">
          <div className='bg-white rounded-[5px] p-[10px] mr-[10px] flex flex-col items-end max-w-[80%]'>
            <p className='text-[12px]'>{message}</p>
            <span className='text-[10px]'>{moment(date).format("DD/MM/YY HH:mm")}</span>
          </div>
          <img className='w-[40px] h-[40x] rounded-[50%]' src={user?.image_full_url && user?.image_full_url !="" ? user?.image_full_url: defImg } />
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user
})
export default connect(mapStateToProps)(SendMsg)