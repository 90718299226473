import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CountriesCard from './CountriesCard'
import { getCountries } from '../../../actions/MainAction'
import SectionHeader from '../SectionHeader'
import ViewAllBtn from '../ViewAllBtn';
import LoadMoreBtn from '../LoadMoreBtn'
import SkeletonCard from '../SkeletonCard'
import MainModal from '../../modals/MainModal'
import { changeStateValue } from '../../../redux/MainReducer'
export class Countries extends Component {
   componentDidMount(){
    if(localStorage.getItem("token")){
      this.props.getCountries(4, '', this.props.languageId, undefined, "countries");
    }else{
      this.props.getCountries(4, '', this.props.languageId);
    }
    
    // console.log(this.props.type)
   }
   getAfterSubmit = () =>{
    if(localStorage.getItem("token")){
      this.props.getCountries(4, '', this.props.languageId, undefined, "countries");
    }else{
      this.props.getCountries(4, '', this.props.languageId);
    }

   }

   loadMoreFunction = (page) =>{
   
    if(localStorage.getItem("token")){
      this.props.getCountries(page, '', this.props.languageId, undefined, "countries");
    }else{
      this.props.getCountries(page, '', this.props.languageId);
    }
   }

   filterCountry(data){
    return [...data].sort((x, y)=> {  return y?.universities - x?.universities})
   }

   addNewDataBtn = (data)=> {

    this.props.changeStateValue({
      name: 'newCountryData',
      value: {
        id: data?.countries?.id ? data?.countries?.id:'',
        position: true,
        image_full_url:  data?.countries?.image_full_url?data?.countries?.image_full_url:null,
        image:  data?.countries?.image_full_url ? data?.countries?.image_full_url:'',
        background_full_url:  data?.countries?.background_full_url?data?.countries?.background_full_url:null,
        background_image:  data?.countries?.background_full_url ? data?.countries?.background_full_url:'',
        name: data?.name?data?.name:'',
        description: data?.descriprion?data?.descriprion:'',
        active: 1,
        lang_id: 1,
        translate_id: data.id
      }
    })
   }

  render() {
    const {countries, page, btn, mainTransateData, user,newCountryData} = this.props;
   
    return (
        <div className={`nc-SectionSliderNewCategories `}>
        <div className={`class="SectionSliderNewCategories__PageHome_s2nc__rv_ flow-root glide--ltr glide--slider glide--swipeable`}>
          <SectionHeader arialLabelTitle="mainTransateData.country_title" arialLabelDesc="mainTransateData.country_description" title={mainTransateData?.country_title} description={mainTransateData?.country_description} />
          <div className="glide__track" data-glide-el="track">
            {
              user != '' && user.role == 'uni_rep'?
              <button onClick={this.addNewDataBtn.bind(this, '')} className='border rounded-2xl p-2 mb-5 text-sm'>Add a Country</button>:null
            }
            
            <ul className="glide__slides countries_container" >
              {
                page != 20?
                this.filterCountry(countries).map((item, index) => {
                  if(index < page){
                    return(
                      <CountriesCard getAfterSubmit={this.getAfterSubmit} item={item} key={index} />
                    )
                  }
                }):
                this.filterCountry(countries).map((item, index) => {
                    return(
                      <CountriesCard editDataBtn={this.addNewDataBtn} getAfterSubmit={this.getAfterSubmit} item={item} key={index} />
                    )
                })
              }
              {
                countries.length ==0?
                <>
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                  <SkeletonCard />
                </>:null
              }
            </ul>
          </div>
          {
            btn === 'view_all'?
            <ViewAllBtn href="/countries" />:
            countries.length >=20?
            <></>:null
            // <LoadMoreBtn position={countries.length} loadMoreFunction={this.loadMoreFunction} />:null
          }
          {newCountryData?.position? <MainModal getAfterChange={this.getAfterSubmit} url={'/countries'} stateName="newCountryData" modalData={newCountryData} />:null}
          
          
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=> ({
  countries: state.Data.countries,
  languageId: state.Data.languageId,
  mainTransateData: state.Data.mainTransateData,
  user: state.Data.user,
  newCountryData: state.Data.newCountryData,
});
const mapDispatchToProps = {getCountries, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Countries)