import React, { Component } from 'react'
import { getBlogsAdmin } from '../../../actions/WorkerActions';
import { connect } from 'react-redux';
import SectionHeader from '../../../components/page_component/SectionHeader';
import BlogsCard from './BlogsCard';
import BlogModal from './BlogModal';
import { changeStateValue } from '../../../redux/MainReducer';
export class AdminBlogs extends Component {
    componentDidMount(){
        this.props.getBlogsAdmin(1);
    }
    editData=(data)=>{
        this.props.changeStateValue({
            name: "blogModalData",
            value:{
                position:true,
                ...data
            }
        })
    }
  render() {
    const {adminBlogs, blogModalData} = this.props;
    console.log(adminBlogs);
    return (
        <div className='container'>
            <div className="flex flex-col mt-10 relative">
                <SectionHeader arialLabelTitle="mainTransateData.content_title"  title={"Blogs"} />
            </div>
            <div className='flex flex-col gap-[20px]'>
                {
                    adminBlogs.map((data, i)=>{
                        return(
                            <BlogsCard 
                                key={i}
                                data={data}
                                onClickEdit={this.editData.bind(this, data)}
                            />
                        )
                    })
                }
            </div>
            {blogModalData?.position?<BlogModal /> :null}
            
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    adminBlogs: state.Data.adminBlogs,
    blogModalData: state.Data.blogModalData
});
const mapDispatchToProps = {getBlogsAdmin, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AdminBlogs)
