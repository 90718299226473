import React, { Component } from 'react'

export class RejectSvg extends Component {
  render() {
    const {color="#333"} = this.props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={25}
        style={{ minWidth:25 }}
        height={25}
        viewBox="0 0 512 512"
      >
        <style>
          {
            ".st1{fill:none;stroke:#333;stroke-width:32;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
          }
        </style>
        <path
          id="Layer_2"
          d="M263.24 43.5c-117.36 0-212.5 95.14-212.5 212.5s95.14 212.5 212.5 212.5 212.5-95.14 212.5-212.5S380.6 43.5 263.24 43.5zm104.59 254.86c17.18 17.18 17.18 45.04 0 62.23-17.18 17.18-45.04 17.18-62.23 0l-42.36-42.36-42.36 42.36c-17.18 17.18-45.04 17.18-62.23 0-17.18-17.18-17.18-45.04 0-62.23L201.01 256l-42.36-42.36c-17.18-17.18-17.18-45.04 0-62.23 17.18-17.18 45.04-17.18 62.23 0l42.36 42.36 42.36-42.36c17.18-17.18 45.04-17.18 62.23 0 17.18 17.18 17.18 45.04 0 62.23L325.46 256l42.37 42.36z"
          style={{
            fill: color,
          }}
        />
      </svg>
    )
  }
}

export default RejectSvg
