import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCommissionForManager } from '../../actions/WorkerActions';
import SectionHeader from "../../components/page_component/SectionHeader"
import Searchable from 'react-searchable-dropdown'; 
import ComissionCard from './components/ComissionCard';
export class ManagerCommission extends Component {
    state={
        years: '2023-2024',
        btnDisable: false,
        modalData: []
    }
    componentDidMount(){
        this.props.getCommissionForManager("2023-2024")
    }
    selectdata=(value)=>{
        this.props.getCommissionForManager(value)
        this.setState({years: value})

    }
    getStudents(uni_id, students){
        return students?.filter(x=>x.program?.institution_id == uni_id)
    }

    getUniversities(data){
        let universities = [];
        for(const item of data){
            for(const uni of item?.universities){
                universities.push({
                    university_name: uni?.name,
                    students: this.getStudents(uni?.institutions_id, item?.students)
                })
            }
        }
        return universities;
    }

    getAfterChange=()=>{
        this.props.getCommissionForManager(this.state.years)
    }
  render() {
    const {comissionForManager} = this.props;
    const {modalData} = this.state;
    // console.log(this.getUniversities(comissionForManager))
    let option = [
        {
            label: "2020-2021",
            value: "2020-2021"
        },
        {
            label: "2021-2022",
            value: "2021-2022"
        },
        {
            label: "2022-2023",
            value: "2022-2023"
        },
        {
            label: "2023-2024",
            value: "2023-2024"
        },
        {
            label: "2024-2025",
            value: "2024-2025"
        },
    ];
    return (
      <div className='container'>
        <SectionHeader title="Commissions" />
        <div>
            <div>
                <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
                Select Years
                </label>
                <div style={{ width: '300px' }} className={`block w-full border-neutral-200  
                    focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                    dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                    dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
                > 
                    <Searchable 
                        options={option}
                        onSelect={this.selectdata.bind(this)}
                        placeholder={"2023-2024"}
                    
                    />
                </div>
            </div>
        </div>
        <div className='flex flex-col gap-[20px] mt-[20px]'>
            {
                this.getUniversities(comissionForManager)?.map((data, i)=>{
                    return(
                        <ComissionCard getAfterChange={this.getAfterChange.bind(this)} data={data} key={i}/>
                      
                    )
                })
            }
        </div>
       
        {
            modalData?.length != 0?
            <div className='background_shadow flex justify-center items-center'>
                <div style={{ maxHeight: '70vh', overflow: 'auto' }} className='bg-white p-3 w-1/2 rounded-xl '>
                    <table class="table-auto w-full">
                        <thead>
                            <tr>
                                <th className='border border-slate-300 w-1/3'>Student Name</th>
                                <th className='border border-slate-300 w-1/3'>Educon</th>
                                <th className='border border-slate-300 w-1/3'>Program</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                modalData?.map((data, i)=>{
                                    return(
                                        <tr key={i}>
                                            <td  className='border border-slate-300 w-1/3 text-sm p-2'>
                                                <a target='_blank' href={`${window.location.origin}/inbox-app?student=${data?.student?.full_name}`}>
                                                    {i+1}. {data?.student?.full_name}
                                                </a>
                                               
                                            </td>
                                            <td  className='border border-slate-300 w-1/3 text-sm p-2'>{data?.worker?.full_name}</td>
                                            <td  className='border border-slate-300 w-1/3 text-sm p-2'>{data?.program?.translate?.name}</td>
                                        </tr>
                                    )
                                })
                            }
                            
                        </tbody>
                    </table>
                    <div className='flex items-center justify-end mt-3'>
                        <button onClick={()=>this.setState({modalData:[]})} className='text-sm font-semibold bg-red-600 text-white p-2 rounded-xl'>Close</button>
                    </div>
                </div>
            </div>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    comissionForManager: state.Data.comissionForManager
});
const mapDispatchToProps = {getCommissionForManager}
export default connect(mapStateToProps, mapDispatchToProps)(ManagerCommission)
