import React, { Component } from 'react'
import { connect } from 'react-redux';
import Searchable from 'react-searchable-dropdown'; 
import { changeStateValue } from '../../../redux/MainReducer';
import { getSimulatorPrograms } from '../../../actions/MainAction';
import {getEducationLanguage} from '../../../actions/WorkerActions'
export class LanguageFilter extends Component {
    selectDropValue  =(id) =>{

        if(localStorage.getItem('simulator')){
            const filterData = JSON.parse(localStorage.getItem('simulator'));
            filterData['filterByLanguage'] = id;
            localStorage.setItem('simulator', JSON.stringify(filterData));
            this.props.changeStateValue({
                name: 'filter',
                value: filterData
            });
            if(localStorage.getItem('token')){
                this.props.getSimulatorPrograms(filterData, 1, 'programs');
            }else{
                this.props.getSimulatorPrograms(filterData);
            }
        }
    }

    getPlaceholder(option){
        if(localStorage.getItem('simulator')){
            const filterData = JSON.parse(localStorage.getItem('simulator'));
            if(option.find(data=> data.value == filterData.filterByLanguage)){
                return option.find(data=> data.value == filterData.filterByLanguage).label
            }else{
                return this.props.mainTransateData.filter_lang;
            }
        }else{
            return this.props.mainTransateData.filter_lang;
        }
        
    }

    componentDidMount(){
        this.props.getEducationLanguage(this.props.languageId)
    }   
  render() {
    const { filter, educationLanguage, mainTransateData} = this.props;
 
    let option = [];
    for(const data of educationLanguage){
        option.push({
            label: data.language,
            value: data.education_language_id
        })
    }
    return (
      <div  className={`block border-neutral-200  
      focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
      dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
      dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}>
        <Searchable 
            options={option}
            onSelect={this.selectDropValue.bind( this.label )}
            placeholder={mainTransateData.filter_lang}
        />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    filter: state.Data.filter,
    languageId: state.Data.languageId,
    educationLanguage: state.Data.educationLanguage,
    mainTransateData: state.Data.mainTransateData,

});
const mapDispatchToProps = {changeStateValue, getSimulatorPrograms, getEducationLanguage}
export default connect(mapStateToProps, mapDispatchToProps)(LanguageFilter)