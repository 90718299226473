import React, { Component } from 'react'
import SectionHeader from '../SectionHeader'
import clientSayMain from "../../../images/clientSayMain.png";
import clientSay1 from "../../../images/clientSay1.png";
import clientSay2 from "../../../images/clientSay2.png";
import clientSay3 from "../../../images/clientSay3.png";
import clientSay4 from "../../../images/clientSay4.png";
import clientSay5 from "../../../images/clientSay5.png";
import clientSay6 from "../../../images/clientSay6.png";
import quotationImg from "../../../images/quotation.png";
import quotationImg2 from "../../../images/quotation2.png";
import { connect } from 'react-redux';
import {getComments} from '../../../actions/MainAction'
export class Comments extends Component {
    state={
       
        slidePage: 0,
    }

    componentDidMount(){
        this.props.getComments('');
        setInterval(()=>{
            const {universityComments} = this.props;
            const {slidePage} = this.state;
            let page = slidePage;
            if(page < universityComments.length-1){
                page++
                this.setState({
                    slidePage: page
                })
            }else{
                this.setState({
                    slidePage: 0
                })
            }

        }, 3000)
    }
    getRnd(){
        return Math.floor(Math.random()*150)+1;
    }
    getImg(data, className){
       if(data){
        let color = `rgb(${this.getRnd()}, ${this.getRnd()}, ${this.getRnd()})`;
        if(data.image_full_url){
            return 'img'
        }else{
            return(
                <span style={{ backgroundColor: color }} className={`wil-avatar__name 
                    wil-avatar 
                    inline-flex items-center 
                    justify-center text-neutral-100 
                    uppercase font-semibold shadow-inner 
                    rounded-full h-10 w-10 text-lg ring-1 
                    ring-white dark:ring-neutral-900 absolute 
                    ${className}`} 
                    >{data.first_name.substring(0, 1)}</span>
            )
        }
       }
        
       
    }
  render() {
    const { slidePage} = this.state;
    const UNIQUE_CLASS = `SectionClientSay_glide_PageHome_`;
    const {universityComments, mainTransateData} = this.props;
    
    return (
        <div
        className={`nc-SectionClientSay relative  `}
        data-nc-id="SectionClientSay"
      >
        
        <SectionHeader arialLabelTitle="mainTransateData.comment_title" arialLabelDesc="mainTransateData.comment_desc"  title={mainTransateData?.comment_title} description={mainTransateData?.comment_desc}/>
        <div className="relative md:mb-16 max-w-2xl mx-auto">
        <div className="hidden md:block">
        {/* <img className="absolute top-9 -left-20" src={clientSay1} alt="" /> */}
        {
            this.getImg(universityComments[0]?.user) == 'img'?
            <img
                className="absolute top-9 -left-20" style={{ width: '70px', height: '70px', borderRadius: '50%' }}
                src={universityComments[0]?.user?.image_full_url}
                alt=""
                />:
            this.getImg(universityComments[0]?.user, 'absolute top-9 -left-20')
        }
        {
            this.getImg(universityComments[0+1]?.user) == 'img'?
            <img
                className="absolute bottom-[100px] right-full mr-40" style={{ width: '70px', height: '70px', borderRadius: '50%'  }}
                src={universityComments[0+1]?.user?.image_full_url}
                alt=""
                />:
            this.getImg(universityComments[0+1]?.user, 'absolute bottom-[100px] right-full mr-40')
        }
       
       {
            this.getImg(universityComments[1+1]?.user) == 'img'?
            <img
                className="absolute top-full left-[140px]" style={{ width: '70px', height: '70px',  borderRadius: '50%'  }}
                src={universityComments[1+1]?.user?.image_full_url}
                alt=""
            />:
            this.getImg(universityComments[1+1]?.user, 'absolute top-full left-[140px]')
        }

       {
            this.getImg(universityComments[2+1]?.user) == 'img'?
           
            <img
                className="absolute -bottom-10 right-[140px]" style={{ width: '70px', height: '70px',  borderRadius: '50%'  }}
                src={universityComments[2+1]?.user?.image_full_url}
                alt=""
            />:
            this.getImg(universityComments[2+1]?.user, 'absolute -bottom-10 right-[140px]')
        }
       
       {
            this.getImg(universityComments[3+1]?.user) == 'img'?
           
            <img
                className="absolute left-full ml-32 bottom-[80px]" style={{ width: '70px', height: '70px',  borderRadius: '50%'  }}
                src={universityComments[3+1]?.user?.image_full_url}
                alt=""
            />:
            this.getImg(universityComments[3+1]?.user, 'absolute left-full ml-32 bottom-[80px]')
        }
       
       {
            this.getImg(universityComments[4+1]?.user) == 'img'?
           
            <img
                className="absolute -right-10 top-10 " style={{ width: '70px', height: '70px',  borderRadius: '50%'  }}
                src={universityComments[4+1]?.user?.image_full_url}
                alt=""
            />:
            this.getImg(universityComments[4+1]?.user, 'absolute -right-10 top-10 ')
        }
       
       
        {/* <img className="absolute -right-10 top-10 " src={clientSay6} alt="" /> */}
      </div>
      {
            this.getImg(universityComments[slidePage]?.user) == 'img'?
           
            <img
                className="mx-auto" style={{ width: '150px', height: '150px',  borderRadius: '50%'  }}
                src={universityComments[slidePage]?.user?.image_full_url}
                alt=""
            />:
            <img className="mx-auto" src={clientSayMain} alt="" />
        }
         
          <div className={`mt-12 lg:mt-16 relative ${UNIQUE_CLASS}`}>
            <img
              className="opacity-50 md:opacity-100 absolute -mr-16 lg:mr-3 right-full top-1"
              src={quotationImg}
              alt=""
            />
            <img
              className="opacity-50 md:opacity-100 absolute -ml-16 lg:ml-3 left-full top-1"
              src={quotationImg2}
              alt=""
            />
            <div className="glide__track " data-glide-el="track">
              <ul className="glide__slides ">
                {universityComments.map((item, i) => {
                    if(slidePage === i){
                        return(
                            <li
                                key={i}
                                className="glide__slide flex flex-col items-center text-center"
                            >
                                <span className="block text-2xl">{item.comment}</span>
                                <span className="block mt-8 text-2xl font-semibold">
                                {item.user.full_name}
                                </span>
                                <div className="flex items-center space-x-2 text-lg mt-2 text-neutral-400">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                                {/* <span>{item.clientAddress}</span> */}
                                </div>
                            </li>
                        )
                    }
                  
                })}
              </ul>
            </div>
            <div
              className="mt-10 glide__bullets flex items-center justify-center"
              data-glide-el="controls[nav]"
            >
              {universityComments.map((item, index) => (
                <button
                  key={item.id}
                  className="glide__bullet w-2 h-2 rounded-full bg-neutral-300 mx-1 focus:outline-none"
                  data-glide-dir={`=${index}`}
                ></button>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    universityComments: state.Data.universityComments,
    mainTransateData: state.Data.mainTransateData
});
const mapDispatchToProps = {getComments}
export default connect(mapStateToProps, mapDispatchToProps)(Comments)