import React, { Component } from 'react'
import Editor from './Editor'
import { connect } from 'react-redux';
import {changeStateValue} from '../../../redux/MainReducer';
import { insertCountryInfo, getInstitutionFaq } from '../../../actions/WorkerActions';
import { getInstitutionData } from '../../../actions/MainAction';
export class Modal extends Component {
  changeInpValue(e){
    this.props.changeStateValue({
        name: 'institutionInfoModal.question',
        value: e.target.value
    })
  }

  closeModal(){
    const institutionInfoModal={
        id: '',
        position: false,
        institution_faqs:{
            institution_id: '',
        },
        lang_id: 1,
        question: '',
        answer: '',
        active: '1'
    };
    this.props.changeStateValue({
        name: 'institutionInfoModal',
        value: institutionInfoModal
    })
   
  }

  saveData(data){
    if(data.question !== '' && data.answer !== ''){
      let url = 'institution-faqs';
      let formdata = new FormData();
      // console.log(data)
      for(const property in data){
        if(property === 'institution_faqs'){
            formdata.append(property, JSON.stringify(data[property]))
        }else{
            formdata.append(property, data[property])
        }
      }
      if(data.id !== ''){
          url = `institution-faqs/${data.id}`;
          formdata.append("_method", "put");
      }
     this.props.insertCountryInfo(url, formdata)
      .then(resp=>{
        this.closeModal();
        this.props.getInstitutionFaq(data.institution_faqs.institution_id, this.props.languageId,)
        this.props.getInstitutionData(this.props.languageId, data.institution_faqs.institution_id)

        // if(data.country_fags?.type == 'about'){
        //   this.props.getCountryData(data.institution_faqs.countries_id, this.props.languageId)

        // }
      })
    }
  }
  render() {
    const {institutionInfoModal} = this.props;
    // console.log(institutionInfoModal)
    return (
      <div  className='background_shadow'>
        <div className='modal_container'>
            <h2 className='mb-5'>{institutionInfoModal.id == ''? 'Add': 'Edit'} Data</h2>
            {
              institutionInfoModal.institution_faqs?.type !== 'about'?
              <input onChange={this.changeInpValue.bind(this)} value={institutionInfoModal.question} type={'text'}  placeholder="Title"/>:null
              
            }
           {
                institutionInfoModal.question == ''?
                <span style={{ fontSize: '12px', color:'red' }}>Input is required</span>:null
              }
              {
                 institutionInfoModal.institution_faqs?.type == 'faq' ||  institutionInfoModal.institution_faqs?.type == 'about'?
                 <Editor />:null
              }
            

            <div className='flex justify-between mt-5'>
                <button  onClick={this.closeModal.bind(this)} className={`ttnc-ButtonThird text-neutral-700 border 
                    border-neutral-200 dark:text-neutral-200 
                    dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                    items-center justify-center rounded-full transition-colors
                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200 
                    px-4 py-1 sm:px-3  `} style={{ fontSize: '12px'  }}>
               Close
            </button>
                <button onClick={this.saveData.bind(this, institutionInfoModal)} className={`ttnc-ButtonThird text-neutral-700 border 
                    border-neutral-200 dark:text-neutral-200 
                    dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                    items-center justify-center rounded-full transition-colors
                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200 
                    px-4 py-1 sm:px-3  bg-primary-6000`} style={{ fontSize: '12px', color: 'white' }}>
                
            Submit
            </button>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  institutionInfoModal: state.Data.institutionInfoModal,
  languageId: state.Data.languageId,
});
const mapDispatchToProps = {changeStateValue, insertCountryInfo, getInstitutionData, getInstitutionFaq}
export default connect(mapStateToProps, mapDispatchToProps)(Modal)
