import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import { insertCountryInfo } from '../../../actions/WorkerActions';
export class Ranking extends Component {
    changeInpValue(e){
        this.props.changeStateValue({
            name: 'institutionData.institutions.'+e.target.name,
            value: e.target.value
        })
    }

    saveChanges(name, value,id,  e){
        if(value != ''){
            const data = {
                id: 4,
                institution_faqs:{
                    institution_id: id,
                    type: name,
                },
                lang_id: 1,
                question: value,
                answer: 'll',
                active: '1'
            }

            let url = `institution-faqs/4`;
            let formdata = new FormData();
        
            for(const property in data){
              if(property === 'institution_faqs'){
                  formdata.append(property, JSON.stringify(data[property]))
              }else{
                  formdata.append(property, data[property])
              }
            }
            formdata.append("_method", "put");
            this.props.insertCountryInfo(url, formdata)
        }
    }
  render() {
    const {data} = this.props;

    return (
      <div>
        <div className='flex items-center gap-10'>
            <div>
                <label>QS Ranking</label>
                <input onChange={this.changeInpValue.bind(this)} name="national_ranking" value={data?.institutions?.national_ranking} placeholder='QS Ranking' type={'number'}  /> 
            </div>
            <button
            className={`ttnc-ButtonThird text-neutral-700 border mt-5
            border-neutral-200 dark:text-neutral-200 
            dark:border-neutral-700 nc-Button relative h-auto inline-flex 
            items-center justify-center rounded-full transition-colors
            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200 
            px-4 py-1 sm:px-3 bg-primary-6000`} style={{ fontSize: '12px', color:  'white' }}
            onClick={this.saveChanges.bind(this, 'national_ranking', data?.institutions?.national_ranking, data.institutions_id)}
            >Update</button>
        </div>
        {/* <div className='flex items-center gap-10 mt-5'>
            <div>
                <label>Times Ranking</label>
                <input onChange={this.changeInpValue.bind(this)} name="international_ranking" value={data?.institutions?.international_ranking}  placeholder='Times Ranking' type={'number'}  /> 
            </div>
            <button
            className={`ttnc-ButtonThird text-neutral-700 border mt-5
            border-neutral-200 dark:text-neutral-200 
            dark:border-neutral-700 nc-Button relative h-auto inline-flex 
            items-center justify-center rounded-full transition-colors
            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200 
            px-4 py-1 sm:px-3 bg-primary-6000`} style={{ fontSize: '12px', color:  'white' }}
            onClick={this.saveChanges.bind(this, 'international_ranking', data?.institutions?.international_ranking, data.institutions_id)}
            >Update</button>
        </div>
        <div className='flex items-center gap-10 mt-5'>
            <div>
                <label>US News Ranking</label>
                <input onChange={this.changeInpValue.bind(this)} name="us_new_ranking" value={data?.institutions?.us_new_ranking?data?.institutions?.us_new_ranking:''}  placeholder='US News Ranking' type={'number'}  /> 
            </div>
            <button
            className={`ttnc-ButtonThird text-neutral-700 border mt-5
            border-neutral-200 dark:text-neutral-200 
            dark:border-neutral-700 nc-Button relative h-auto inline-flex 
            items-center justify-center rounded-full transition-colors
            text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200 
            px-4 py-1 sm:px-3 bg-primary-6000`} style={{ fontSize: '12px', color:  'white' }}
            onClick={this.saveChanges.bind(this, 'us_new_ranking', data?.institutions?.us_new_ranking, data.institutions_id)}
            >Update</button>
        </div> */}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue, insertCountryInfo}
export default connect(mapStateToProps, mapDispatchToProps)(Ranking)