import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {getDisciplineData, getPrograms, getStateByCountry, getcityByCountryAndState, getEducationDegree } from '../../../actions/MainAction';
import ProgramCard from '../../page_component/programs/ProgramCard';
import SectionHeader from '../../page_component/SectionHeader';
import LoadMoreBtn from '../../page_component/LoadMoreBtn';
import {mainDashboardTranslate, getUnirepRoleTranslate} from '../../../actions/TranslateAction'
import LoginComponent from '../programs/components/login_register/LoginComponent';
import CheckFilter from '../../page_component/programs/CheckFilter';
import { getAllCountries } from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
function withParams(Component){
    return props => <Component {...props} params={useParams()} />
}

export class SingleDiscipline extends Component {
    state={
        page: 1,
        filterCountry: '',
        state:'',
        city:'',
        degree:''
    }
    componentDidMount(){
        window.scrollTo(0, 0)
        this.props.getAllCountries(1, '', this.props.languageId)
        this.props.getEducationDegree(this.props.languageId)

        this.props.getDisciplineData(this.props.params.id, this.props.languageId)
        if(localStorage.getItem('token')){
            this.props.getPrograms(this.state.page, this.props.languageId, '',this.props.params.id, 'programs', this.state.filterCountry)
        }else{
            this.props.getPrograms(this.state.page, this.props.languageId, '',this.props.params.id, 'public-programs', this.state.filterCountry)
            // public-programs
        }
        
        this.props.getUnirepRoleTranslate(this.props.languageId);
        this.props.mainDashboardTranslate(this.props.languageId);
    }

    getAfterApply= ()=>{
        if(localStorage.getItem('token')){
            this.props.getPrograms(this.state.page, this.props.languageId, '',this.props.params.id, 'programs',this.state.filterCountry, this.state.state, this.state.city, this.state.degree)
        }else{
            this.props.getPrograms(this.state.page, this.props.languageId, '',this.props.params.id, 'public-programs', this.state.filterCountry, this.state.state, this.state.city, this.state.degree)
        }
    }
    loadMoreFunction = (page) => {
        this.setState({
            page: page
        })
        if(localStorage.getItem('token')){
            this.props.getPrograms(page, this.props.languageId, '',this.props.params.id, 'programs', this.state.filterCountry, this.state.state, this.state.city, this.state.degree)
        }else{
            this.props.getPrograms(page, this.props.languageId, '',this.props.params.id, 'public-programs', this.state.filterCountry, this.state.state, this.state.city, this.state.degree)
        }
    }

    getFilterData=(country, state, city, degree)=>{
        if(localStorage.getItem('token')){
            this.props.getPrograms(this.state.page, this.props.languageId, '',this.props.params.id, 'programs',  country, state, city, degree )
        }else{
            this.props.getPrograms(this.state.page, this.props.languageId, '',this.props.params.id, 'public-programs',  country, state, city, degree)
        }
    }
    checkDataBtn=(data, type)=>{
      
        if(type == 'country'){
            this.setState({
                filterCountry:data.join(','),
                state:'',
                city:''
            })
            this.props.getStateByCountry(3,this.props.languageId, data.join());
            this.props.getcityByCountryAndState( this.props.languageId, data.join(), '');
            this.props.changeStateValue({name:'stateByCountry', value:[]});
            this.props.changeStateValue({name:'citiesByCountryAndState', value:[]});
            this.getFilterData(data.join(''),'', '', this.state.degree)
        }else if(type =='state'){
            this.props.getcityByCountryAndState( this.props.languageId,this.state.filterCountry, data.join());
            this.props.changeStateValue({name:'citiesByCountryAndState', value:[]});
            this.setState({
                city:'',
                state: data.join('')
            });
            this.getFilterData(this.state.filterCountry, data.join(''),'', this.state.degree)
        }else if(type == 'city'){
            this.setState({
                city: data.join('')
            });
            this.getFilterData(this.state.filterCountry, this.state.state, data.join(''), this.state.degree)
        }else if(type == 'degree'){
            this.setState({
                degree: data.join('')
            });
            this.getFilterData(this.state.filterCountry, this.state.state, this.state.city, data.join(''))
        }
       
       
      
    }
  render() {
    const {disciplineData, programs, mainTransateData, simulatorLogin, allCountries, stateByCountry, citiesByCountryAndState, educationDegree} = this.props;
    let countriesOptions = [];
    let stateOption = [];
    let cityOption=[]
    let degreeOption = []
    for(const country of [...allCountries].sort((x, y)=> {  return y?.countries?.institution_count - x?.countries?.institution_count})){
        const params = {
          name: country.name,
          id: country.countries_id
        }
        countriesOptions.push(params)
    }
    for(const data of stateByCountry){
        const params = {
          name: data.name,
          id: data.state_id
        }
        stateOption.push(params)
    }
    for(const data of citiesByCountryAndState){
        const params = {
          name: data.name,
          id: data.city_id
        }
        cityOption.push(params)
    }
    for(const data of educationDegree){
        const params = {
          name: data.education_type,
          id: data.education_degree_id
        }
        degreeOption.push(params)
    }
    return (
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <div className="relative py-16">
            <div className={`nc-SectionGridFilterCard pb-24 lg:pb-28`} data-nc-id="SectionGridFilterCard" >
                
                <SectionHeader arialLabelTitle={'mainTransateData.program_in'} title={`${mainTransateData.program_in} ${disciplineData.name}`} description={disciplineData.description} />
                <div className='filter_btn'>
                    <CheckFilter array={countriesOptions} type="country" label="mainTransateData.countries" title={mainTransateData?.countries} checkDataBtn={this.checkDataBtn} />
                    {
                        stateOption.length != 0?
                        <CheckFilter array={stateOption} type="state" label="mainTransateData.countries" title={mainTransateData?.states} checkDataBtn={this.checkDataBtn} />:null
                    }
                    {
                        cityOption.length != 0?
                        <CheckFilter array={cityOption} type="city" label="mainTransateData.countries" title={mainTransateData?.cities} checkDataBtn={this.checkDataBtn} />:null
                    }
                    <CheckFilter array={degreeOption} type="degree" label="mainTransateData.countries" title={mainTransateData?.degree} checkDataBtn={this.checkDataBtn} />
                </div>
                <div  className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
                    {
                        programs &&
                        programs.map((program, i)=>{
                            
                            return(
                                <ProgramCard country={program.country} city={program.city} getAfterApply={this.getAfterApply} key={i} program={program} />
                            )
                           
                        })
                    }
                    
                </div>
                
                {
                      programs.length >= 20?
                      <LoadMoreBtn position={programs.length} loadMoreFunction={this.loadMoreFunction}  />:null
                }
                    
                
            </div>
            {
          simulatorLogin?
          <LoginComponent />:null
        }
        </div>
    </div>
    )
  }
}
const mapStateToProps = (state)=> ({
    disciplineData: state.Data.disciplineData,
    languageId: state.Data.languageId,
    programs: state.Data.programs,
    mainTransateData: state.Data.mainTransateData,
    simulatorLogin: state.Data.simulatorLogin,
    allCountries: state.Data.allCountries,
    stateByCountry: state.Data.stateByCountry,
    citiesByCountryAndState: state.Data.citiesByCountryAndState,
    educationDegree: state.Data.educationDegree,
});
const mapDispatchToProps = {getDisciplineData, getPrograms,changeStateValue,getEducationDegree,getAllCountries,getStateByCountry,  mainDashboardTranslate,getcityByCountryAndState, getUnirepRoleTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(withParams(SingleDiscipline))