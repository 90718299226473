import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeStateValue } from '../../../redux/MainReducer'
export class StudyDuration extends Component {
    state={
        type: '1',
        value: ''
    }
    changeInputValue(type, e){
        if(type === 'number'){
            this.props.changeStateValue({
                name: 'editProgram.program.study_duration',
                value: `${e.target.value}|${this.state.type}`
            })

            
            this.setState({
                value: e.target.value
            })
        }else{
            
            this.setState({
                type: e.target.value
            })
            if(this.state.value !== ''){
                this.props.changeStateValue({
                    name: 'editProgram.program.study_duration',
                    value: `${this.state.value}|${e.target.value}`
                })
    
                
            }else{
                if(this.props.editProgram.program.study_duration.includes('|')){
                    this.props.changeStateValue({
                        name: 'editProgram.program.study_duration',
                        value: `${this.props.editProgram.program.study_duration.split('|')[0]}|${e.target.value}`
                    })
                }else if(this.props.editProgram.program.study_duration.includes(' ')){
                    this.props.changeStateValue({
                        name: 'editProgram.program.study_duration',
                        value: `${this.props.editProgram.program.study_duration.split(' ')[0]}|${e.target.value}`
                    })
                }
            }
        }
        
    }
    getValue(value){
        if(value.includes(' ')){
            return value.split(' ')[0]
        }else if(value.includes('|')){
            return value.split('|')[0]

        }
    }

    getDefault(value){
        if(value.includes(' ')){
            return value.split(' ')[1]
        }else if(value.includes('|')){
            return value.split('|')[1]

        }
    }
  render() {
    const {value, unirepRoleTranslate} = this.props
    let options = [
        {
            name: unirepRoleTranslate?.years,
            value: '1'
        },
        {
            name: unirepRoleTranslate?.month,
            value: '2'
        },
        {
            name: unirepRoleTranslate?.weeks,
            value: '3'
        }
    ]
    return (
        <div>
        <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
        {unirepRoleTranslate?.study_duration}
        </label>
        <div className='multiple_drop_input border rounded-2xl h-11  '>
            <input  onChange={this.changeInputValue.bind(this, 'number')}  placeholder={unirepRoleTranslate?.study_duration} value={this.getValue(value)} type={'number'} /> 
            <select onChange={this.changeInputValue.bind(this, 'type')} defaultValue={this.getDefault(value)} className='rounded-2xl'>
                {
                    options.map((data, i) =>{
                        return(
                            <option key={i}  value={data.value}>{data.name}</option>
                        )
                    })
                }
            </select>
        </div>
    </div>
    )
  }
}
const mapStateToProps = (state) =>({
    editProgram: state.Data.editProgram,
    unirepRoleTranslate: state.Data.unirepRoleTranslate
});
const mapDispatchToprops = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToprops)(StudyDuration)