import React, { Component } from 'react'
import { connect } from 'react-redux';
import Cities from '../cities/Cities';
import TextComponent from '../components/TextComponent';
import States from '../states/States';

export class About extends Component {
  render() {
    const {states, cities, about, living} = this.props;
    const {mainTransateData, editData} = this.props;
    return (
      <div >
        {
            states.length !== 0?
            <States states={states} editData={editData} />:null
          }
          {
            cities.length !== 0?
            <Cities editData={editData} cities={cities} />:null
          }
          <div className='mt-5 '>
            {
              about?
              <TextComponent label="mainTransateData.country_about" title={mainTransateData?.country_about} desc={about} />:null
            }
            
            {
              living?
              <TextComponent title={living?.question} desc={living?.answer} />:null
            }
            
          </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  mainTransateData: state.Data.mainTransateData
})
export default connect(mapStateToProps)(About)