import React, { Component } from 'react'
import { connect } from 'react-redux';
import CheckFilter from './CheckFilter'
import { getAllCountries, getStateByCountry, getcityByCountryAndState, getScholarships, getcityBState, getAllDisciplines, getEducationDegree } from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
export class Filter extends Component {
    state={
        country: '',
        state:'',
        city: '',
        manualRender:0,
        discipline: '',
        degree: ''
    }
    componentDidMount(){
        this.props.getAllCountries(1, '', this.props.languageId);
        this.props.getAllDisciplines(this.props.languageId)
        this.props.getEducationDegree(this.props.languageId)
        this.props.changeStateValue({
          name: 'stateByCountry',
          value: []
        })
        this.props.changeStateValue({
          name: 'citiesByCountryAndState',
          value: []
        })
    }

    getFilterProgram(country, state, city, discipline, degree){
    
        if(localStorage.getItem('token')){
            this.props.getScholarships( 'program-schoolarship',this.props.pageCount, this.props.languageId, country, state, city, discipline, degree)
      
        }else{
          this.props.getScholarships( 'public-program-schoolarship',this.props.pageCount, this.props.languageId, country, state, city, discipline, degree)
        }
    }
    checkDataBtn = (data, type) =>{
      this.setState({
        [type]: data.join()
      })
      if(type == 'country'){   
          if(data.length !== 0){
            this.props.getStateByCountry(null, this.props.languageId, data.join())
            this.props.getcityByCountryAndState( this.props.languageId, data.join(), this.state.state)
            this.setState({
              state:'',
              city: '',
              manualRender: 1
            })
            setTimeout(()=>{
              this.setState({
                manualRender:0
              })
            }, 100)
          }else{
   

            this.props.changeStateValue({
              name: 'stateByCountry',
              value: []
            })
            this.props.changeStateValue({
              name: 'citiesByCountryAndState',
              value: []
            })
          }
          
          this.getFilterProgram(data.join(), this.state.state, this.state.city, this.state.discipline, this.state.degree)
      }else if(type == 'state'){
        if(data.length != 0){
          this.props.getcityBState( this.props.languageId, data.join());
        }else{
          this.props.changeStateValue({
            name: 'citiesByCountryAndState',
            value: []
          })
        }
          
        this.getFilterProgram( this.state.country, data.join(), this.state.city, this.state.discipline, this.state.degree)

      }else if(type == 'city'){
          this.getFilterProgram( this.state.country, this.state.state, data.join(), this.state.discipline, this.state.degree)

      }else if(type == 'discipline'){
        this.getFilterProgram( this.state.country, this.state.state,this.state.city, data.join(), this.state.degree)
      }else if(type == 'degree'){
        this.getFilterProgram( this.state.country, this.state.state,this.state.city,this.state.discipline, data.join())
      }
    }
  render() {
    const {allCountries, stateByCountry, citiesByCountryAndState, mainTransateData, allDisciplines, educationDegree} = this.props;
    const {manualRender} = this.state;
    let countriesOptions = [];
    const stateOption = [];
    const cityOption = [];
    let disciplinesOption = [];
    let degreeOption = [];

    for(const degree of educationDegree){
      const params = {
        name: degree.education_type,
        id: degree.education_degree_id
      }
      degreeOption.push(params)
    }
    for(const country of [...allCountries].sort((x, y)=> {  return y?.countries?.institution_count - x?.countries?.institution_count})){
      const params = {
        name: country.name,
        id: country.countries_id
      }
      countriesOptions.push(params)
    }
    for(const discipline of allDisciplines){
      const params = {
        name: discipline.name,
        id: discipline.discipline_id
      }
      disciplinesOption.push(params)
    }
    for(const state of stateByCountry){
      const params = {
        name: state.name,
        id: state.state_id
      }
      stateOption.push(params)
    }
    for(const city of citiesByCountryAndState){
      const params = {
        name: city.name,
        id: city.city_id
      }
      cityOption.push(params)
    }
// console.log(educationDegree)
    return (
      <div className='filter_btn'>
        <CheckFilter array={countriesOptions} type="country" label="mainTransateData.countries" title={mainTransateData?.countries} checkDataBtn={this.checkDataBtn} />
        {
            stateOption.length !== 0 && manualRender == 0?
            <CheckFilter array={stateOption} type="state" label="mainTransateData.states" title={mainTransateData?.states} checkDataBtn={this.checkDataBtn} />:null
        }
        {
            cityOption.length !== 0 && manualRender == 0?
            <CheckFilter array={cityOption} type="city" label="mainTransateData.cities" title={mainTransateData?.cities} checkDataBtn={this.checkDataBtn} />:null
        }
        <CheckFilter array={disciplinesOption} type="discipline" label="mainTransateData.disciplines" title={mainTransateData?.disciplines} checkDataBtn={this.checkDataBtn} />
        <CheckFilter array={degreeOption} type="degree" label="mainTransateData.degree" title={mainTransateData?.degree} checkDataBtn={this.checkDataBtn} />
        
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    languageId: state.Data.languageId,
    allCountries: state.Data.allCountries,
    stateByCountry: state.Data.stateByCountry,
    citiesByCountryAndState: state.Data.citiesByCountryAndState,
    mainTransateData: state.Data.mainTransateData,
    allDisciplines: state.Data.allDisciplines,
    educationDegree: state.Data.educationDegree,
});
const mapDispatchToProps = {getAllCountries, getStateByCountry, getcityByCountryAndState, getScholarships,getcityBState, changeStateValue, getAllDisciplines, getEducationDegree}
export default connect(mapStateToProps, mapDispatchToProps)(Filter)