import React from 'react'


// import MenuBar from "shared/MenuBar/MenuBar";

import { Link } from 'react-router-dom';
import MenuBar from '../MenuBar/MenuBar';
import LangDropdown from './LangDropdown';
import Logo from './Logo';
import Navigation from './Navigation';
import { logOutAction } from '../../actions/MainAction';
import {useSelector} from 'react-redux';
import { useDispatch } from 'react-redux'; 
import {useNavigate} from 'react-router-dom';
import HeartIcon from './HeartIcon';

function MainNav() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  function logOutbtn(){
    dispatch(logOutAction()).then(resp=>{
      navigate('/login')
    })
  }
  const state = useSelector((state)=>state.Data);
  const {user, mainTransateData} = state
  return (
    <div className={`nc-MainNav1 relative z-10 `}>
    <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
      <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
        <Logo />
        <Navigation />
      </div>
      <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
        <div className="hidden xl:flex items-center space-x-1">
        {/* {
          user != ''?
          <Link  to={'/messages'} className='mr-1'>
            <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" >
              <path d="M20 2H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h3v3.767L13.277 18H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14h-7.277L9 18.233V16H4V4h16v12z" />
            </svg>
          </Link>:null
        } */}
          <Link className='mr-1' to={'/wish-list'}>
            <HeartIcon />
          </Link>
          {
            user !== '' && user.role == 'student'?
            <Link to={'/profile'}>
              <svg width="16" height="16" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5104 11.3665C13.5846 10.4408 12.4827 9.75541 11.2804 9.34054C12.5681 8.45365 13.4141 6.96937 13.4141 5.29102C13.4141 2.58141 11.2096 0.376953 8.5 0.376953C5.79039 0.376953 3.58594 2.58141 3.58594 5.29102C3.58594 6.96937 4.43192 8.45365 5.71964 9.34054C4.51735 9.75541 3.41541 10.4408 2.4896 11.3665C0.884166 12.972 0 15.1065 0 17.377H1.32812C1.32812 13.4224 4.54541 10.2051 8.5 10.2051C12.4546 10.2051 15.6719 13.4224 15.6719 17.377H17C17 15.1065 16.1158 12.972 14.5104 11.3665ZM8.5 8.87695C6.52272 8.87695 4.91406 7.26833 4.91406 5.29102C4.91406 3.3137 6.52272 1.70508 8.5 1.70508C10.4773 1.70508 12.0859 3.3137 12.0859 5.29102C12.0859 7.26833 10.4773 8.87695 8.5 8.87695Z" fill="gray"/>
              </svg>
            </Link>:null
          }
          {
            user !== '' && user.role == 'parent'?
            <Link to={'/parent-profile'}>
              <svg width="16" height="16" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5104 11.3665C13.5846 10.4408 12.4827 9.75541 11.2804 9.34054C12.5681 8.45365 13.4141 6.96937 13.4141 5.29102C13.4141 2.58141 11.2096 0.376953 8.5 0.376953C5.79039 0.376953 3.58594 2.58141 3.58594 5.29102C3.58594 6.96937 4.43192 8.45365 5.71964 9.34054C4.51735 9.75541 3.41541 10.4408 2.4896 11.3665C0.884166 12.972 0 15.1065 0 17.377H1.32812C1.32812 13.4224 4.54541 10.2051 8.5 10.2051C12.4546 10.2051 15.6719 13.4224 15.6719 17.377H17C17 15.1065 16.1158 12.972 14.5104 11.3665ZM8.5 8.87695C6.52272 8.87695 4.91406 7.26833 4.91406 5.29102C4.91406 3.3137 6.52272 1.70508 8.5 1.70508C10.4773 1.70508 12.0859 3.3137 12.0859 5.29102C12.0859 7.26833 10.4773 8.87695 8.5 8.87695Z" fill="gray"/>
              </svg>
            </Link>:null
          }
          
          {/* <LangDropdown /> */}
          <div className="px-1" />
       
          {
            user === ''?
            <Link to={'/login'} aria-label="mainTransateData.sign_in"  className={`nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 `}
                rel="noopener noreferrer">
             {mainTransateData?.sign_in}
            </Link>:
            <button onClick={()=>logOutbtn()} aria-label="mainTransateData.sign_out" className={`nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0 `}
                rel="noopener noreferrer">
              {mainTransateData?.sign_out}
            </button>
          }
          
        </div>
        <div className="flex xl:hidden items-center">
      
          <div className="px-0.5" />
          <MenuBar />
        </div>
      </div>
    </div>
  </div>
  )
}

export default MainNav