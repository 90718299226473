import React, { Component } from 'react'
import JoditEditor from "jodit-react"; 
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import { insertNewData, getBlogsAdmin } from '../../../actions/WorkerActions';
import Swal from 'sweetalert2';
export class BlogModal extends Component {
    state={
        disable:false
    }
    config = {
		zIndex: 0,
        readonly: false,
        activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about', 'dots'],
        toolbarButtonSize: 'middle',
        theme: 'default',
        saveModeInCookie: false,
        spellcheck: true,
        editorCssClass: false,
        triggerChangeEvent: true,
        width: 'auto',
        height: 300,
        minHeight: 100,
        direction: '',
        language: 'auto',
        debugLanguage: false,
        i18n: 'en',
        tabIndex: -1,
        toolbar: true,
        enter: "P",
        useSplitMode: false,
        colors: {
            greyscale:  ['#000000', '#ff0000', '#666666', '#999999', '#B7B7B7', '#CCCCCC', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#FFFFFF'],
            palette:    ['#980000', '#FF0000', '#FF9900', '#FFFF00', '#00F0F0', '#00FFFF', '#4A86E8', '#0000FF', '#9900FF', '#FF00FF'],
            full: [
                '#E6B8AF', '#F4CCCC', '#FCE5CD', '#FFF2CC', '#D9EAD3', '#D0E0E3', '#C9DAF8', '#CFE2F3', '#D9D2E9', '#EAD1DC',
                '#DD7E6B', '#EA9999', '#F9CB9C', '#FFE599', '#B6D7A8', '#A2C4C9', '#A4C2F4', '#9FC5E8', '#B4A7D6', '#D5A6BD',
                '#CC4125', '#E06666', '#F6B26B', '#FFD966', '#93C47D', '#76A5AF', '#6D9EEB', '#6FA8DC', '#8E7CC3', '#C27BA0',
                '#A61C00', '#CC0000', '#E69138', '#F1C232', '#6AA84F', '#45818E', '#3C78D8', '#3D85C6', '#674EA7', '#A64D79',
                '#85200C', '#990000', '#B45F06', '#BF9000', '#38761D', '#134F5C', '#1155CC', '#0B5394', '#351C75', '#733554',
                '#5B0F00', '#660000', '#783F04', '#7F6000', '#274E13', '#0C343D', '#1C4587', '#073763', '#20124D', '#4C1130'
            ]
        },
        colorPickerDefaultTab: 'text',
        imageDefaultWidth: 300,
        removeButtons: [
            'source', 
            'fullsize'
        ],
        disablePlugins: [],
        extraButtons: [
            'strikethrough',
            'underline',
            'hr'
        ],
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
        
        buttons: [
           
            'bold',
            'strikethrough',
            'underline',
            'italic', '|',
            'ul',
            'ol', '|',
            'outdent', 'indent',  '|',
            'font',
            'fontsize',
            'brush',
            'paragraph', '|',
            'image',
            'video',
            'table',
            'link', '|',
            'align', 'undo', 'redo', '|',
            'hr',
            'eraser',
            'copyformat', '|',
            'symbol',
            'print',
            'about'
        ],
        buttonsXS: [
            'bold',
            'image', '|',
            'brush',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots',
        ],
        events: {},
        textIcons: false,
        showWordsCounter: false,
        showCharsCounter: false
    }
    changeValue=(value)=>{
        this.props.changeStateValue({
            name: 'blogModalData.description',
            value: value
        })
    }
    getInpValue=(e)=>{
        this.props.changeStateValue({
            name: e.target.name,
            value: e.target.value
        })
    }
    closeModal=()=>{
        this.props.changeStateValue({
            name: 'blogModalData',
            value: {
                position:false,
                id:'',
                description:'',
                title:'',
            }
        })
    }

    saveData=(data)=>{
        this.setState({disable:true})
        let formData = new FormData();
        for(const property in data){
            if(data[property]){
                formData.append(property, data[property])
            }
        }
        formData.append("_method", "put");
        this.props.insertNewData(`/blogs/${data?.id}`, formData)
        .then(resp=>{
            this.props.getBlogsAdmin(1);
            this.setState({disable:false});
            if(resp?.msg === 'success'){
                this.closeModal();
                Swal.fire({
                    title: "Good job!",
                    text: "Blog edited successfully!",
                    icon: "success"
                  });
            }else{
                Swal.fire({
                    title: "Error!",
                    text: "Something went wrong!",
                    icon: "error"
                  });
            }
        })
    }
  render() {
    const {blogModalData} = this.props;
    const {disable} = this.state;
    return (
      <div className='background_shadow flex items-center justify-center'>
            <div className='w-[80%] max-h-[70vh] overflow-auto bg-white p-[20px] rounded-[12px] '>
                <div>
                    <div>
                        <label className='text-[14px] font-semibold' htmlFor='blogTitleInp'>Title</label>
                        <input value={blogModalData?.title} name="blogModalData.title" onChange={this.getInpValue.bind(this)} id='blogTitleInp' type='text'  />
                    </div>
                    <div className='mt-[40px]'>
                    <label className='text-[14px] font-semibold'>Description</label>
                    <JoditEditor
                        config={this.config}
                        value={blogModalData.description}
                        onChange={this.changeValue.bind(this)}
                    />
                    </div>
                </div>
                <div className='flex justify-between items-center mt-[12px]'>
                    <button disabled={disable} onClick={this.closeModal.bind(this)} className='text-[12px] text-white font-semibold bg-red-600 p-[7px] rounded-full pl-[16px] pr-[16px]'>Close</button>
                    <button disabled={disable} onClick={this.saveData.bind(this,blogModalData)} className='text-[12px] text-white font-semibold bg-blue-600 p-[7px] rounded-full pl-[16px] pr-[16px]'>Save</button>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    blogModalData: state.Data.blogModalData
});
const mapDispatchToProps = {changeStateValue, insertNewData, getBlogsAdmin}
export default connect(mapStateToProps, mapDispatchToProps)(BlogModal)
