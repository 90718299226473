import React, { Component } from 'react'
import BgGlassmorphism from '../components/page_component/BgGlassmorphism'
import Discipline from '../components/page_component/disciplines/Discipline'
// import SectionHero from '../components/page_component/SectionHero'
import { mainDashboardTranslate } from '../actions/TranslateAction'
import { connect } from 'react-redux';

export class Disciplines extends Component {
  componentDidMount(){
    window.scrollTo(0, 0);
    this.props.mainDashboardTranslate(this.props.languageId)

  }
  render() {
    return (
        <div className="nc-PageHome relative overflow-hidden">
            <BgGlassmorphism />
            <div className="container relative space-y-24  lg:space-y-28 ">
                {/* <SectionHero /> */}
                <div className="relative ">
                    <Discipline page={20} btn={'load_more'} />
                </div>
            </div>

        </div>
    )
  }
}

const mapStateToProps = (state) =>({
  languageId: state.Data.languageId
});
const mapDispatchToProps = {mainDashboardTranslate}
export default connect(mapStateToProps, mapDispatchToProps)(Disciplines)