import React, { Component } from 'react'

export class Button extends Component {
  render() {
    const {className, title, changePage, type} = this.props
    return (
        <button onClick={()=>changePage(type)} className={`ttnc-ButtonThird text-neutral-700 border 
                border-neutral-200 dark:text-neutral-200 
                dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                items-center justify-center rounded-full transition-colors
                text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200 
                px-4 py-1 sm:px-3  ${className}`} style={{ fontSize: '16px', fontWeight:'400', color: className != ''? 'white': '' }}>
            {title}

        </button>
    )
  }
}

export default Button