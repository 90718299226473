function getALevelGrade(grade){
    if(grade == 'A*A*A*'){
        return 100
    }else if(grade == 'A*AA'){
        return 89
    }
    else if(grade == 'AAA'){
        return 85

    }
    else if(grade == 'AAB'){
        return 82

    }
    else if(grade == 'AAC'){
        return 81

    }
    else if(grade == 'ABB'){

        return 79
    }
    else if(grade == 'ABC'){
        return 78

    }
    else if(grade == 'BBB'){
        return 75

    }
    else if(grade == 'BBC'){
        return 74

    }
    else if(grade == 'CCC'){
        return 71

    }
    else if(grade == 'DDD'){
        return 64

    }
    else if(grade == 'EEE'){
        return 64
    }
}


function getPercentage(program, filter, exams,allPublicConverters=[] ){
       

    // console.log(program)
    let percentage = 0;
    let possiblePercentages = [];
    let inPossiblePercentages = [];
    function possible(title, percentage, type, exam_type){
        possiblePercentages.push({
            type: type,
            title: title,
            percentage: percentage,
            exam_type: exam_type
        })
    }
    function impossible(title, percentage, type, exam_type){
        inPossiblePercentages.push({
            type: type,
            title: title,
            percentage: percentage,
            exam_type: exam_type
        })   
    }
    // if(localStorage.getItem('filterExamGrade')){
        // let exams = JSON.parse(localStorage.getItem('filterExamGrade'));
        // let exams = this.props.filterExamGrade;
        if(program?.program?.specialty_exams.length !== 0){
            program?.program?.specialty_exams.forEach(exam=>{
                if(exam?.exam?.exam_type == 'IELTS' && exams.ielts !== '' && +exam?.over_all <= +exams.ielts){
                    
                    percentage+= +exam?.percentage;
                    if(+exam?.percentage !== 0){
                        possible('IELTS', +exam?.percentage, 'exam', '1')
                    }
                }else if(exam?.exam?.exam_type == 'IELTS'){
                    if(+exam?.percentage !== 0){
                        impossible('IELTS', +exam?.percentage, 'exam', '1')
                    }      
                }
                if(exam?.exam?.exam_type == 'SAT' && exams.sat !== '' &&  +exam?.over_all <= +exams.sat){
                    percentage+= +exam?.percentage;
                    if(+exam?.percentage !== 0){
                        possible('SAT', +exam?.percentage, 'exam', '0')
                    }
                }else if(exam?.exam?.exam_type == 'SAT'){
                    if(+exam?.percentage !== 0){
                        impossible('SAT', +exam?.percentage, 'exam', '0')
                    }      
                }
                if(exam?.exam?.exam_type == 'TOEFL' && exams.toefl !== '' &&  +exam?.over_all <= +exams.toefl){
                    percentage+= +exam?.percentage;
                    if(+exam?.percentage !== 0){
                        possible('TOEFL', +exam?.percentage, 'exam', '1')
                    }
                }else if(exam?.exam?.exam_type == 'TOEFL'){
                    if(+exam?.percentage !== 0){
                        impossible('TOEFL', +exam?.percentage, 'exam', '1')
                    }      
                }
                if(exam?.exam?.exam_type == 'Duolingo' && exams.duolingo !== '' &&  +exam?.over_all <= +exams.duolingo){
                    percentage+= +exam?.percentage;
                    if(+exam?.percentage !== 0){
                        possible('Duolingo', +exam?.percentage, 'exam', '1')
                    }
                }else if(exam?.exam?.exam_type == 'Duolingo'){
                    if(+exam?.percentage !== 0){
                        impossible('Duolingo', +exam?.percentage, 'exam', '1')
                    }      
                }
                if(exam?.exam?.exam_type == 'PTE' && exams.pte !== '' &&  +exam?.over_all <= +exams.pte){
                    percentage+= +exam?.percentage;
                    if(+exam?.percentage !== 0){
                        possible('PTE', +exam?.percentage, 'exam', '1')
                    }
                }else if(exam?.exam?.exam_type == 'PTE'){
                    if(+exam?.percentage !== 0){
                        impossible('PTE', +exam?.percentage, 'exam', '1')
                    }      
                }
                if(exam?.exam?.exam_type == 'GRE' && exams.gre !== '' &&  +exam?.over_all <= +exams.gre){
                    percentage+= +exam?.percentage;
                    if(+exam?.percentage !== 0){
                        possible('GRE', +exam?.percentage, 'exam', '0')
                    }
                }else if(exam?.exam?.exam_type == 'GRE'){
                    if(+exam?.percentage !== 0){
                        impossible('GRE', +exam?.percentage, 'exam', '0')
                    }      
                }
                if(exam?.exam?.exam_type == 'GMAT' && exams.gmat !== '' &&  +exam?.over_all <= +exams.gmat){
                    percentage+= +exam?.percentage;
                    if(+exam?.percentage !== 0){
                        possible('GMAT', +exam?.percentage, 'exam', '0')
                    }
                }else if(exam?.exam?.exam_type == 'GMAT'){
                    if(+exam?.percentage !== 0){
                        impossible('GMAT', +exam?.percentage, 'exam', '0')
                    }      
                }
                
            })
        }
    // }
    // if(localStorage.getItem('simulator')){
        // const filter = this.props.filter;
        if(filter){
            if(filter.filterByExtraActivities && filter.filterByExtraActivities !== ''){
                const activities = filter.filterByExtraActivities.split(',');
                if(program?.activity.length !== 0){
                    program?.activity.forEach((activity=>{
                        if(activities.find(data=> +data == activity.activity_id)){
                            percentage+= +activity.percentage;
                            possible(activity.title, +activity?.percentage, 'activity')
                        }else{
                            impossible(activity.title, +activity?.percentage, 'activity')
                        }
                        

                    }))
                }
            }
            if(filter.filterByInternationalOlympiads && filter.filterByInternationalOlympiads !== ''){
                const olympiads = filter.filterByInternationalOlympiads.split(',');
                if(program?.olympiad.length !== 0){
                    program?.olympiad.forEach((olympiad=>{
                        if(olympiads.find(data=> +data == olympiad.olympiad_id)){
                            percentage+= +olympiad.percentage;
                            possible(olympiad.title, +olympiad?.percentage, 'olympiad')
                        }else{
                            impossible(olympiad.title, +olympiad?.percentage, 'olympiad')
                        }
                        

                    }))
                }
            }
            if(program?.program?.exam_check == 1){
                // if(filter.filterByGPA !== '' && program?.program?.gpa !== ''){
                
                //     if(+filter.gpaConverter >= +program?.program?.gpa){   
                //         percentage+= +program?.program?.gpa_percentage;
                //         possible('GPA', +program?.program?.gpa_percentage, 'gpa')
                //     }else{
                //         impossible('GPA', +program?.program?.gpa_percentage, 'gpa')
                //     }
                // }
    
                if(filter.filterByALevel !== '' && program?.program?.a_level !== '' && program?.program?.a_level){
                    const check = allPublicConverters.find(grrade=> grrade.other_exam == 'a_level' && grrade.grade == filter.filterByALevel)
                    if(check && check?.max_value){
                        if(+check?.max_value >= +program?.program?.a_level_grade){
                            percentage+= +program?.program?.a_level_percentage;
                            possible('A Level', +program?.program?.a_level_percentage, 'gpa')
                        }else{
                            impossible('A Level', +program?.program?.a_level_percentage, 'gpa')
                        }
                    }
                    
                }
                if(filter.filterByAP !== '' && program?.program?.advanced_placement !== '' && program?.program?.ap_grade){
                    if(+filter.filterByAP >= +program?.program?.ap_grade){
                        percentage+= +program?.program?.ap_percentage
                        possible('Advanced Placement', +program?.program?.advanced_placement, 'gpa')
                    }else{
                        impossible('Advanced Placement', +program?.program?.advanced_placement, 'gpa')
                    }
                }
                if(filter.filterByOSSD !== '' && program?.program?.ossd !== '' && program?.program?.ossd_grade){
                    if(+filter.filterByOSSD >= +program?.program?.ossd_grade){
                        percentage+= +program?.program?.ossd_percentage
                        possible('OSSD', +program?.program?.ossd_percentage, 'gpa')
                    }else{
                        impossible('OSSD', +program?.program?.ossd_percentage, 'gpa')
                    }
                }
                if(filter.filterByIB !== '' && program?.program?.ib_diploma !== '' && program?.program?.ib_grade){
                    if(+filter.filterByIB >= +program?.program?.ib_grade){
                        percentage+= +program?.program?.ib_percentage
                        possible('IB Diploma', +program?.program?.a_level_percentage, 'gpa')
                    }else{
                        impossible('IB Diploma', +program?.program?.a_level_percentage, 'gpa')
                    }
                }
            }else{
                if(filter.filterByGPA !== '' && program?.program?.gpa !== ''){
                
                    if(+filter.gpaConverter >= +program?.program?.gpa){   
                        percentage+= +program?.program?.gpa_percentage;
                        possible('GPA', +program?.program?.gpa_percentage, 'gpa')
                    }else{
                        impossible('GPA', +program?.program?.gpa_percentage, 'gpa')
                    }
                }
    
                if(filter.filterByALevel !== '' && program?.program?.a_level !== '' && program?.program?.a_level){
                    const check = allPublicConverters.find(grrade=> grrade.other_exam == 'a_level' && grrade.grade == filter.filterByALevel)
                    if(check && check?.max_value){
                        if(+check?.max_value >= +program?.program?.gpa){
                            percentage+= +program?.program?.gpa_percentage;
                            possible('A Level', +program?.program?.gpa_percentage, 'gpa')
                        }else{
                            impossible('A Level', +program?.program?.gpa_percentage, 'gpa')
                        }
                    }
                }
                if(filter.filterByAP !== '' && program?.program?.advanced_placement !== '' && program?.program?.ap_grade){
                    const check = allPublicConverters.find(grrade=> grrade.other_exam == 'advanced_placement' && grrade.grade == filter.filterByAP)
                    if(check && check?.max_value){
                        if(+check?.max_value >= +program?.program?.gpa){
                            percentage+= +program?.program?.gpa_percentage
                            possible('Advanced Placement', +program?.program?.gpa_percentage, 'gpa')
                        }else{
                            impossible('Advanced Placement', +program?.program?.gpa_percentage, 'gpa')
                        }
                    }
                }
                if(filter.filterByOSSD !== '' && program?.program?.ossd !== '' && program?.program?.ossd_grade){
                    if(+filter.filterByOSSD >= +program?.program?.gpa){
                        percentage+= +program?.program?.gpa_percentage
                        possible('OSSD', +program?.program?.gpa_percentage, 'gpa')
                    }else{
                        impossible('OSSD', +program?.program?.gpa_percentage, 'gpa')
                    }
                }
                if(filter.filterByIB !== '' && program?.program?.ib_diploma !== '' && program?.program?.ib_grade){
                    const check = allPublicConverters.find(grrade=> grrade.other_exam == 'ib_diploma' && grrade.grade == filter.filterByIB)
                    if(check && check?.max_value){
                        if(+filter.filterByIB >= +program?.program?.gpa){
                            percentage+= +program?.program?.gpa_percentage
                            possible('IB Diploma', +program?.program?.gpa_percentage, 'gpa')
                        }else{
                            impossible('IB Diploma', +program?.program?.gpa_percentage, 'gpa')
                        }
                    }
                }
            }
            
            
            // if(filter.filterByIB !== '' && program?.program?.ib_diploma !== '' && program?.program?.ib_grade){
            //     if(+filter.filterByIB >= +program?.program?.ib_grade){
            //         percentage+= +program?.program?.ib_percentage
            //         possible('A Level', +program?.program?.a_level_percentage, 'gpa')
            //     }else{
            //         impossible('A Level', +program?.program?.a_level_percentage, 'gpa')
            //     }
            // }
        }
    // }
    return {percentage, inPossiblePercentages, possiblePercentages}
}

export const percentage = (program, filter, exam, allPublicConverters) => {
  
    return getPercentage(program, filter, exam, allPublicConverters)
}