import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
export class InputFilter extends Component {
  changeInputValue(min, max, e){
    if(e.target.value >= min && e.target.value < max){
     
      if(localStorage.getItem('simulator')){
        const filterData = JSON.parse(localStorage.getItem('simulator'));
        filterData[this.props.property] = e.target.value;
        localStorage.setItem('simulator', JSON.stringify(filterData));
        this.props.changeStateValue({
          name: 'filter',
          value: filterData
        })
       
      }
    }else if(e.target.value <= min ){
      if(localStorage.getItem('simulator')){
        const filterData = JSON.parse(localStorage.getItem('simulator'));
        filterData[this.props.property] ='';
        localStorage.setItem('simulator', JSON.stringify(filterData));
        this.props.changeStateValue({
          name: 'filter',
          value: filterData
        })
        
      }
    }
  }
  render() {
    const {min_value, max_value, property, title, defaultValue} = this.props
    
    return (
      <ul className='filter_input_container'>
        <li>
            <p>{min_value } - {max_value}</p>
            <input value={defaultValue} onChange={this.changeInputValue.bind(this, min_value, max_value)} type={'number'} />
        </li>
        
      </ul>
    )
  }
}
const mapStateToProps = (state)=> ({
  
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(InputFilter)