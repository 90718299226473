import React, { Component } from 'react'
import { connect } from 'react-redux';
import {registerByWorkerAction, getCompanies} from '../../../actions/MainAction';
import { getEduCons } from '../../../actions/WorkerActions';
import Searchable from 'react-searchable-dropdown'; 
import CloseIcon from '../../../components/page_component/CloseIcon';

export class RegisterModal extends Component {
    state={
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        role_id: '4',
        company_id: '',
        pro_status: 0
    }
    componentDidMount(){
        this.props.getCompanies()
    }
    changeInpValue(e){
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    registerManual(data, student_id, e){
        e.preventDefault();
        if(data.first_name !== '' && data.last_name !== '' && data.email !== '' && data.password !== ''){
            
            this.props.registerByWorkerAction(data)
            .then(resp=>{
                this.props.getEduCons()
                this.props.closeModal()
            })

        }
    }
   

    setCompanyId(id){
        this.setState({company_id: id})
    }
  render() {
    const {student_id = '', companies, companyVisible=false, closeModal} = this.props;
    const {first_name, last_name, email, password, pro_status} = this.state;
    
    let compOption = [];
    for(const data of companies){
        compOption.push({
            label: data.name,
            value: data.id
        })
    }
    
    return (
        <div className='background_shadow'>
            <div className="login_popup_container">
                <CloseIcon closeModalBtn={()=>closeModal()} />

                <div>
                    <h2 className="my-2 flex items-center  mb-3 text-3xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        Add a Edu Con
                    </h2>
                    <form className="grid grid-cols-1 gap-6" action="#" method="post">
                    <div className='grid grid-cols-2 gap-3'>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                                First Name
                            </span>
                            
                            <input type={'text'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="First name"
                            value={first_name}
                            name="first_name"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                            Last Name
                            </span>
                            
                            <input type={'text'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="Last Name"
                            value={last_name}
                            name="last_name"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        </div>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                            Email address
                            </span>
                            
                            <input type={'email'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="example@example.com"
                            value={email}
                            name="email"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        {
                            companyVisible?
                            <div className="block">
                                <span className="text-neutral-800 dark:text-neutral-200">
                                    Company
                                </span>
                                
                                <div className='border rounded-2xl'>
                                    <Searchable 
                                        options={compOption}
                                        onSelect={this.setCompanyId.bind(this)}
                                        placeholder={"Select a company"}
                                    />
                                </div>
                            </div>:null
                        }
                        {
                            companyVisible?
                            <label htmlFor='educonProId' className='inline-flex border items-center p-2 gap-2 rounded-xl cursor-pointer'>
                                <input id='educonProId' type='checkbox' value={pro_status} onChange={(e)=>e.target.checked? this.setState({pro_status:1}): this.setState({pro_status:0})} />
                                <span className='text-sm'>Educon Pro</span>
                            </label>:null
                        }
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                                Password
                            </span>
                            
                            <input type={'password'}
                            className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1`}
                            placeholder="*********"
                            value={password}
                            name="password"
                            onChange={this.changeInpValue.bind(this)}
                            />
                        </label>
                        
                        <button onClick={this.registerManual.bind(this, this.state, student_id)} style={{ color: 'white' }} className='ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50
                            nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors
                            text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>
                            Sign Up
                        </button>
                    </form>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    companies: state.Data.companies
});
const mapDispatchToProps = {registerByWorkerAction, getEduCons, getCompanies}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal)