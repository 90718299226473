import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getUnirepRoleTranslate, mainDashboardTranslate } from '../../actions/TranslateAction';
import Image from './components/Image';
import Input from './components/Input';
import ProfileData from './components/ProfileData';
import { getStudentProfilePercentage } from '../../actions/StudentActions';
import EducationBacground from './components/EducationBacground';
import Exams from './components/Exams';
import StudentActivity from './components/StudentActivity';
import Destination from './components/Destination';
import avatar1 from "../../images/default.jpg";
import { useParams } from 'react-router-dom';
function withHooks(Component){
  return props => <Component {...props} params={useParams()} />
}
export class StudentProfile extends Component {

  state={
    page: 'personal_info'
  }
  componentDidMount(){
    window.scrollTo(0, 0)
    this.props.getUnirepRoleTranslate(this.props.languageId);
    this.props.mainDashboardTranslate(this.props.languageId);
    this.props.getStudentProfilePercentage(this.props.params.id);
  }
  changePage(type, e){
    this.setState({
      page: type
    })
  }
  getPageTitle(type, unirepRoleTranslate){
    if(type == 'personal_info'){
      return unirepRoleTranslate?.student_prof_data
    }else if(type == 'education_background'){
      return unirepRoleTranslate?.student_edu_back
    }else if(type == 'exams'){
      return unirepRoleTranslate?.student_exams
    }else if(type == 'activity'){
      return unirepRoleTranslate?.student_opt_act
    }else if(type == 'destination'){
      return unirepRoleTranslate?.student_destination
    }
  }
  getPageTitleLabel(type){
    if(type == 'personal_info'){
      return 'unirepRoleTranslate.student_prof_data'
    }else if(type == 'education_background'){
      return 'unirepRoleTranslate.student_edu_back'
    }else if(type == 'exams'){
      return 'unirepRoleTranslate.student_exams'
    }else if(type == 'activity'){
      return 'unirepRoleTranslate.student_opt_act'
    }else if(type == 'destination'){
      return 'unirepRoleTranslate.student_destination'
    }
  }
  render() {
    const {studentProfilePercentage, studentProfileData, unirepRoleTranslate, params} = this.props;
    const {page} = this.state;
    let percentage = 0;
    percentage = studentProfilePercentage[page]
    return (
      <div  className={`ListingDetailPage nc-ListingCarDetailPage`} data-nc-id="ListingCarDetailPage">
           <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
            <div className="block flex-grow mb-24 lg:mb-0">
              <div className="lg:sticky lg:top-24  ">
                <div className=" w-full flex flex-col items-center text-center rounded-2xl p-4 border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
                  <Image
                    url={
                      studentProfileData.image_full_url !== ''?
                      studentProfileData.image_full_url: avatar1
                    }
                  />
                  <div className="space-y-3 text-center flex flex-col items-center">
                    <h2 className="text-3xl font-semibold">{studentProfileData.full_name}</h2>
                   
                  </div>
                  <ul style={{ flexDirection: 'column' }} className='flex items-start  w-full gap-2'>
                    {
                      studentProfileData.country && studentProfileData.country !== '' && studentProfileData.country != 'null'?
                      <li className='flex justify-between' ><b style={{ width: '90px', textAlign: 'start',  }} ><span aria-label='unirepRoleTranslate.student_country'>{unirepRoleTranslate?.student_country}</span> :</b> {studentProfileData.country}</li>:null
                    }
                    {
                      studentProfileData.state && studentProfileData.state !== '' && studentProfileData.state != 'null'?
                      <li className='flex justify-between' ><b style={{ width: '90px', textAlign: 'start',  }}><span aria-label='unirepRoleTranslate.student_state'>{unirepRoleTranslate?.student_state}</span>: </b>{studentProfileData.state}</li>:null
                    }
                    {
                      studentProfileData.city && studentProfileData.city !== '' && studentProfileData.city != 'null'?
                      <li className='flex justify-between' ><b style={{ width: '90px', textAlign: 'start',  }}><span aria-label='unirepRoleTranslate.student_city'>{unirepRoleTranslate?.student_city}</span>: </b>{studentProfileData.city}</li>:null
                    }
                    {
                      studentProfileData.email && studentProfileData.email !== '' && studentProfileData.email != 'null'?
                      <li className='flex justify-between' ><b style={{ width: '90px', textAlign: 'start',  }}><span aria-label='unirepRoleTranslate.student_email'>{unirepRoleTranslate?.student_email}</span>: </b>{studentProfileData.email}</li>:null
                    }
                    {
                      studentProfileData.phone_number && studentProfileData.phone_number !== '' && studentProfileData.phone_number != 'null'?
                      <li className='flex justify-between' ><b style={{ width: '90px', textAlign: 'start',  }}><span aria-label='unirepRoleTranslate.student_phone'>{unirepRoleTranslate?.student_phone}</span>: </b>{studentProfileData.phone_number}</li>:null
                    }
                    {
                      studentProfileData.date_of_birth && studentProfileData.date_of_birth !== '' && studentProfileData.date_of_birth != 'null'?
                      <li className='flex justify-between' ><b style={{ width: '90px', textAlign: 'start',  }}><span aria-label='unirepRoleTranslate.student_date'>{unirepRoleTranslate?.student_date}</span>: </b>{studentProfileData.date_of_birth}</li>:null
                    }
                    {/* {
                      studentProfileData.address && studentProfileData.address !== '' && studentProfileData.address != 'null'?
                      <li className='flex justify-between' ><b style={{ width: '90px', textAlign: 'start',  }}><span aria-label='unirepRoleTranslate.student_addres'>{unirepRoleTranslate?.student_addres}</span>: </b>{studentProfileData.address}</li>:null
                    } */}
                    {
                      studentProfileData.postal_code && studentProfileData.postal_code !== '' && studentProfileData.postal_code != 'null'?
                      <li className='flex justify-between' ><b style={{ width: '90px', textAlign: 'start',  }}><span aria-label='unirepRoleTranslate.student_postal'>{unirepRoleTranslate?.student_postal}</span>: </b>{studentProfileData.postal_code}</li>:null
                    }
                     {
                      studentProfileData.gender && studentProfileData.gender !== '' && studentProfileData.gender != 'null'?
                      <li className='flex justify-between' ><b style={{ width: '90px', textAlign: 'start',  }}><span aria-label='unirepRoleTranslate.student_gender'>{unirepRoleTranslate?.student_gender}</span>: </b>{studentProfileData.gender}</li>:null
                    }
                    
                   
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
              <div className="listingSection__wrap border rounded-2xl p-5">
                <div>
                  {/* <h2 className="text-2xl font-semibold">Kevin Francis's listings</h2>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    Kevin Francis's listings is very rich, 5 star reviews help him to be
                    more branded.
                  </span> */}
                  <ul className='profile_header_btn'>
                    <li><button onClick={this.changePage.bind(this, 'personal_info')} 
                    className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base 
                    sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${page ==  'personal_info'? 'bg-secondary-900 text-secondary-50':'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'}`}
                    aria-label="unirepRoleTranslate.student_pr_btn">{unirepRoleTranslate?.student_pr_btn}</button></li>
                    <li><button onClick={this.changePage.bind(this, 'education_background')} 
                    className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 
                    capitalize rounded-full   focus:outline-none ${page ==  'education_background'? 'bg-secondary-900 text-secondary-50':'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'} `} aria-label="unirepRoleTranslate.student_eduback_btn" >{unirepRoleTranslate?.student_eduback_btn}</button></li>
                    
                    <li><button  onClick={this.changePage.bind(this, 'exams')} className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 
                    capitalize rounded-full   focus:outline-none ${page ==  'exams'? 'bg-secondary-900 text-secondary-50':'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'} `} aria-label="unirepRoleTranslate.student_exams_btn">{unirepRoleTranslate?.student_exams_btn}</button></li>
                    
                    <li><button  onClick={this.changePage.bind(this, 'activity')}  className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 
                    capitalize rounded-full   focus:outline-none ${page ==  'activity'? 'bg-secondary-900 text-secondary-50':'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'} `} aria-label="unirepRoleTranslate.student_opt_act_btn">{unirepRoleTranslate?.student_opt_act_btn}</button></li>
                  
                    <li><button  onClick={this.changePage.bind(this, 'destination')}  className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 
                    capitalize rounded-full   focus:outline-none ${page ==  'destination'? 'bg-secondary-900 text-secondary-50':'text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800'} `} aria-label="unirepRoleTranslate.student_dest_btn" >{unirepRoleTranslate?.student_dest_btn}</button></li>
                  
                  </ul>
                  <h2 className="text-2xl font-semibold mt-10" aria-label={this.getPageTitleLabel(page)}>{this.getPageTitle(page, unirepRoleTranslate)}</h2>
                  {
                    page !== 'activity' && page !== 'destination'?
                    <div className='flex gap-5 mt-5 ' style={{ alignItems: 'center' }}>
                    
                    <div className='percentage_line_container'>
                      <span className='line_background'>
                        <span className='line_color' style={{ width: `${percentage}%` }}></span>
                      </span>
                    </div>
                    <span>{`${percentage.toFixed(0)}%`}</span>
                  </div>:null
                  }
                  
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                {
                 page ==  'personal_info'?
                 <ProfileData student_id={params.id} />:
                 page == 'education_background'?
                 <EducationBacground student_id={params.id} />:
                 page == 'exams'?
                 <Exams student_id={params.id} />:
                 page == 'activity'?
                 <StudentActivity student_id={params.id} />:
                 page == 'destination'?
                 <Destination student_id={params.id} />:null
                }
                  
              </div>
            </div>
          </main>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  languageId: state.Data.languageId,
  studentProfilePercentage: state.Data.studentProfilePercentage,
  studentProfileData: state.Data.studentProfileData,
  unirepRoleTranslate: state.Data.unirepRoleTranslate,
});
const mapDispatchToProps = {getUnirepRoleTranslate,mainDashboardTranslate, getStudentProfilePercentage }
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StudentProfile))