import React, { Component } from 'react'

export class FaqComponent extends Component {
    state={
        position: false
    }
  render() {
    const { openCard = ()=>{}, title, desc} = this.props;
    const {position} = this.state;
    return (
        <div className="listingSection__wrap mb-10"  style={{width: '100%' }}>
            <div  className='flex justify-between items-start' onClick={()=>this.setState({position: !position})} style={{ cursor: 'pointer', width: '100%' }}>
                <h2 className="text-2xl font-semibold resp_faq_title" style={{width: '100%' }}>{title}</h2>
                
                    <button  className={`ttnc-ButtonThird text-neutral-700 border 
                    border-neutral-200 dark:text-neutral-200 
                    dark:border-neutral-700 nc-Button relative h-auto inline-flex 
                    items-center justify-center rounded-full transition-colors
                    text-sm sm:text-base font-medium text-neutral-700 dark:text-neutral-200 
                    px-4 py-1 sm:px-3 bg-primary-6000`} style={{ fontSize: '18px', color: 'white', minHeight: '40px', width: '40px'}}>
                {
                position?'-':'+'
                }

                </button>
            </div>
           

            
            {
                position?
                <>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="text-neutral-6000 dark:text-neutral-300"   dangerouslySetInnerHTML={{ __html: desc}}>
                    {/* <span>
                        Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
                        accommodation, an outdoor swimming pool, a bar, a shared lounge, a
                        garden and barbecue facilities. Complimentary WiFi is provided.
                    </span>
                    <br />
                    <br />
                    <span>
                        There is a private bathroom with bidet in all units, along with a
                        hairdryer and free toiletries.
                    </span>
                    <br /> <br />
                    <span>
                        The Symphony 9 Tam Coc offers a terrace. Both a bicycle rental
                        service and a car rental service are available at the accommodation,
                        while cycling can be enjoyed nearby.
                    </span> */}
                    </div>
                </>:null
            }
         
        </div>
    )
  }
}

export default FaqComponent