import React, { Component } from 'react'

export class Images extends Component {
  
  render() {
    const {containerClassName, className, src} = this.props;
    
    return (
        <div   className={`nc-NcImage ${containerClassName}`}  data-nc-id="NcImage" >
          <img src={src} className={className}  />
      </div>
    )
  }
}

export default Images