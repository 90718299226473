import { connect } from 'react-redux';
import React, { Component } from 'react'
import imagePng from "../../images/hero-right.png";
import LocationInput from './LocationInput';
// import LocationInput from './LocationInput';
import { getAllCountries, getAllDisciplines, homeAppliedStudents } from '../../actions/MainAction';
import { changeStateValue } from '../../redux/MainReducer';
import { Link } from 'react-router-dom';
import AppliedCard from './AppliedCard';
import SkeletonText from './SkeletonText';
import SkeletonCard from './SkeletonCard';
export class SectionHero extends Component {
  state={
    page:1,
    keyword: '' ,
    filterByCountry: '',
    filterByState:'',
    filterByCity:'',
    filterByStatus:'',
    filterByInstitution:'',
    lang_id: this.props.languageId,
    filterByLevel:'',
    filterByDiscipline:'',
    filterByDuration:'',
    filterByFee: '',
    filterStudiedCountry: '',
    filterByCountryLevel: '',
    filterByGPA: '',
    filterByGradingScheme: '',
    filterByALevel: '',
    filterByOSSD: '',
    filterByIB: '',
    filterByAP: '',
    filterByExtraActivities:'',
    filterByInternationalOlympiads: '',
    gpaConverter:'',
    gradingSchemeId: '',
    filterType: 'program',
    filterByDegree: '',
    filterByLanguage: ''
    
  }
  componentDidMount(){
    this.props.getAllCountries('','', this.props.languageId );
    this.props.getAllDisciplines(this.props.languageId);
    this.props.homeAppliedStudents("home", 1, this.props.languageId)
  }

  selectOption = (id, type)=> {
    
    this.setState({
      [type]: id
    })
    
  }
  goSimulatorBtn(state, type, e){
    localStorage.removeItem('filterExamIds')
    localStorage.removeItem('filterExamGrade')
    state.filterType = type
    localStorage.setItem('simulator', JSON.stringify(state))
    this.props.changeStateValue({
      name: 'filterExamIds',
      value: []
    })
    this.props.changeStateValue({
      name: 'simulatorType',
      value: type
    })
    localStorage.setItem('simulatorType', type)
    this.props.changeStateValue({
      name: 'filterExamGrade',
      value: {
        ielts: '',
        toefl: '',
        pte: '',
        duolingo: '',
        sat: '',
        gre: '',
        gmat: ''
      }
    })

    localStorage.setItem('modalSimulator', 'true')
  }
  render() {
    const {allCountries, allDisciplines, mainTransateData, appliedStudents} = this.props;
    const {filterByDiscipline, filterByCountry} = this.state;
    let countriesOption = [];
    let disciplineOption = []
    for(const country of allCountries){
      const params = {
        label: country.name,
        value: country.countries_id
      }
      countriesOption.push(params)
    }
    for(const discipline of allDisciplines){
      const params = {
        label: discipline.name,
        value: discipline.discipline_id
      }
      disciplineOption.push(params)
    }

    return (
        <div
        className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative pt-10 lg:pt-16 lg:pb-16`}
        data-nc-id="SectionHero"
      >
        <div className="flex flex-col lg:flex-row ">
          {
            mainTransateData != ''?
            <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-1 pb-14 mb-4 lg:pb-4 xl:pr-14 lg:mr-10 xl:mr-0"  >
              <h2 aria-label='mainTransateData.main_title' className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
              {mainTransateData?.main_title}
              </h2>
              <span aria-label='mainTransateData.main_paragraph' className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
              {mainTransateData?.main_paragraph}
              </span>
              {/* <ButtonPrimary>Start your search</ButtonPrimary> */}
            </div>:
            <div className='flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-1 pb-14 mb-4 lg:pb-4 xl:pr-14 lg:mr-10 xl:mr-0'>
              <SkeletonText />
            </div>
            
          }
          
          
          <div className='w-full'>
          <div className="grid grid gap-2 md:gap-8 w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2" >
            {
              appliedStudents.length != 0?

              appliedStudents.map((data, i)=>{
                if(i<2){
                  return(
                    <AppliedCard 
                      key={i}
                      data={data}
                    />
                  )
                }
                
              }):
              <>
                <SkeletonCard />
                <SkeletonCard />
              </>
            }
            
           
          </div>
            <div className='flex w-full items-center justify-center '>
            <Link to={'/accepted-students'}
            style={{ height: '40px' }}
                className=' ml-4  md:h-16 pr-5 pl-5 mt-4 mr-4 rounded-full bg-primary-6000 
                          hover:bg-primary-700 flex   items-center justify-center 
                          text-neutral-50 focus:outline-none'>View all</Link>

              </div>
            </div>
        </div>
            
        <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full" style={{ marginTop: '0px' }}>
          {/* <HeroSearchForm /> */}
          <div  className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 `}  >
    
        <form className="w-full relative mt- flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
          {/* <SearchLocation /> */}
          {/* <LocationInput
            defaultValue={''}
            title={mainTransateData.country}
            description={mainTransateData.where_to_study}
            className="flex-[1.5]"
            options={countriesOption}
            selectOption={this.selectOption}
            type={'filterByCountry'}
          /> */}
           <Link to={'/simulator'} type="button" onClick={this.goSimulatorBtn.bind(this, this.state, 'program')} className="h-14 ml-4 w-full md:h-16 pr-5 pl-5 mt-4 mr-4 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none" href="#">
              <span className="mr-3 " aria-label='mainTransateData.check_admission'>{mainTransateData?.check_admission}</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </Link>
           <Link to={'/simulator'} type="button" onClick={this.goSimulatorBtn.bind(this, this.state, 'scholarship')} className="h-14 w-full mb-3 md:h-16 pr-5 pl-5 mt-4 mr-4 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none" href="#">
              <span className="mr-3 " aria-label='mainTransateData.check_sch'>{mainTransateData?.check_sch}</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </Link>
          {/* <LocationInput
            defaultValue={''}
            title={mainTransateData.program}
            description={mainTransateData.what_to_study}
            className="flex-[1.5]"
            options={disciplineOption}
            type={'filterByDiscipline'}
            selectOption={this.selectOption}
          /> */}
          {
            filterByCountry !== '' && filterByDiscipline !== ''?
            <Link to={'/simulator'} type="button" onClick={this.goSimulatorBtn.bind(this, this.state)} className="h-14 md:h-16 pr-5 pl-5 mt-4 mr-4 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none" href="#">
              <span className="mr-3 ">{mainTransateData.start_search}</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </Link>:null
          }
         
         </form>
         </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=> ({
  allCountries: state.Data.allCountries,
  languageId: state.Data.languageId,
  allDisciplines: state.Data.allDisciplines,
  mainTransateData: state.Data.mainTransateData,
  appliedStudents: state.Data.appliedStudents,
});
const mapDispatchToProps = {getAllCountries, getAllDisciplines,homeAppliedStudents,  changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SectionHero)