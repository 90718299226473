import React, { Component } from 'react'
import { connect } from 'react-redux';
import ProgramInformation from '../../../pagess/worker_pages/student_information/components/ProgramInformation'
import StudentData from '../../../pagess/worker_pages/student_information/StudentData'

export class InformationContainer extends Component {
    closeData(e){
        
        if(e.target.className == 'background_shadow flex items-center justify-center'){
            this.props.closeInfoBtn()
        }
    }
  render() {
    const {studentTrackings, program_id, user} = this.props;

    return (
      <div className='background_shadow flex items-center justify-center' onClick={this.closeData.bind(this)}  >
        <div className='info_container'>
            
            {
                user != '' && user.role != 'student'?
                <StudentData data={studentTrackings} />:null
            }
            {
                studentTrackings?.programs?.find(item=> item.program_id == program_id)?
                <ProgramInformation fontSize="18px" data={studentTrackings?.programs?.find(item=> item.program_id == program_id)}/>:null
            }
            
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user
})
export default connect(mapStateToProps)(InformationContainer)