import React, { Component } from 'react'
import Searchable from 'react-searchable-dropdown'; 

export class DropDown extends Component {
  selectDropValue=(data, ) => {
    
    this.props.getInpValue('state_id', data)
  }
  render() {
    const {option, placeholder} = this.props
    return (
      <div style={{ zIndex: 222 }}>
            <label className={`nc-Label text-sm font-medium text-neutral-700 dark:text-neutral-300`} data-nc-id="Label" >
              Select a State
            </label>
            <div  className={`block w-full border-neutral-200  
                focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white 
                dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 
                dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11  border `}
            > 
                <Searchable 
                    options={option}
                    onSelect={this.selectDropValue.bind(this)}
                    placeholder={placeholder}
                />
            </div>
        </div>
    )
  }
}

export default DropDown